import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { processTextSegments } from '../../../util/markdown';

import OutputMarkDown from '../../OutputMarkdown/OutputMarkDown';
import { useNotificationStore } from '../../../store/storeNotifications';
import { useAuthStore } from '../../../store/storeAuth';
const Output = ({ data, output }: any) => {
  const value: any = output?.text;
  const setNotification = useNotificationStore(state => state.setNotification);
  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);

    setNotification({
      type: 'success',
      message: 'Text copied to clipboard',
    });
  };
  const user = useAuthStore(state => state.user);
  const processedSegments = useMemo(
    () => processTextSegments(output?.text),
    [output?.text],
  );
  return (
    <Box
      sx={{
        padding: '16px',
        height: 'fit-content',
        bgcolor: data?.fullData?.bgColor || '#fff',
        borderRadius: data?.fullData?.borderRadius || '0px',
        ...data?.style,
        width: '100%',
      }}
    >
      {data?.label && (
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#475467',
            ...data?.fullData?.titleStyle,
          }}
          variant="h1"
          mb={'8px'}
        >
          {data?.label ? data.label : 'Title Component'}
        </Typography>
      )}

      {data?.subtitle && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#98A2B3',
            ...data?.fullData?.subtitleStyle,
          }}
          mb={'8px'}
          variant="h1"
        >
          {data?.subtitle ? data.subtitle : 'Title Component'}
        </Typography>
      )}

      {value &&
        processedSegments?.map((segment: any, index: number) => (
          <OutputMarkDown
            onCopy={onCopy}
            segment={segment}
            user={user!}
            key={index}
            index={index}
            value={value}
            segments={processedSegments}
          />
        ))}
    </Box>
  );
};

export default Output;
