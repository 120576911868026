import { Box, FormLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { cat, frequency, geoTrendsArray, hl } from '../../util/webTrendsNow';
import { WebTrendsFrom } from '../nodes/WebTrends';
import Label from '../UI/Label/Label';
import { useAuthStore } from '../../store/storeAuth';
type Props = {
  formData: WebTrendsFrom;
  handleInputChange: (e: any) => void;
};

const WebTrendsNowForm = ({ formData, handleInputChange }: Props) => {
  const { user } = useAuthStore(state => state);

  return (
    <Box>
      <Label labelName='Frequency' isAdvanced />
      <Select
        value={formData.frequency}
        name='frequency'
        onChange={handleInputChange}
        className='nodrag'
        size='small'
        sx={{
          '& .MuiList-root': {
            backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        <MenuItem disabled value='no-value' sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>
          select frequency
        </MenuItem>
        {frequency.map((freq, index) => (
          <MenuItem key={index} value={freq} sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}>
            {freq}
          </MenuItem>
        ))}
      </Select>
      <Label labelName='Category' isAdvanced />
      <Select
        name='catTrends'
        className='nodrag'
        value={formData.catTrends}
        onChange={handleInputChange}
        size='small'
        sx={{
          '& .MuiList-root': {
            backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        {cat.map((type, index) => (
          <MenuItem key={index} value={type.code} sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}>
            {type.name}
          </MenuItem>
        ))}
      </Select>
      <Label labelName={'Geo'} isAdvanced />
      <Select
        name='geo'
        className='nodrag'
        value={formData.geo}
        onChange={handleInputChange}
        native
        size='small'
        sx={{
          bgcolor: '#EAECF0',
          borderRadius: '8px',
          border: 'none',
          height: '30px',
          textTransform: 'capitalize',
          mb: '16px',

          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        {geoTrendsArray.map((test, index) => (
          <option className='nodrag' key={index} value={test.code}>
            {test.name}
          </option>
        ))}
      </Select>

      <Label labelName='Language' isAdvanced />
      <Select
        value={formData.hl}
        name='hl'
        onChange={handleInputChange}
        placeholder='Select Language'
        className='nodrag'
        size='small'
        sx={{
          '& .MuiList-root': {
            backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        <MenuItem disabled value='no-value' sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>
          Select language
        </MenuItem>
        {Object.entries(hl).map((geo, index) => (
          <MenuItem key={index} value={geo[0]} sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>
            {geo[1]}
          </MenuItem>
        ))}
      </Select>

      <Label labelName='Date' isAdvanced />
      <TextField
        type='date'
        name='dateTrendsNow'
        className='nodrag'
        value={formData.dateTrendsNow}
        onChange={handleInputChange}
      />
    </Box>
  );
};

export default WebTrendsNowForm;
