export const findArrayDifference = (
  previousArray: any[],
  updatedArray: any[]
) => {
  // Check for added elements
  const added = updatedArray.find(
    updatedItem =>
      !previousArray.some(prevItem => prevItem.value === updatedItem.value)
  );

  // Check for deleted elements
  const deleted = previousArray.find(
    prevItem =>
      !updatedArray.some(updatedItem => updatedItem.value === prevItem.value)
  );

  if (added) {
    return {
      added: true,
      object: added,
    };
  } else if (deleted) {
    return {
      added: false,
      object: deleted,
    };
  } else {
    return {
      added: false,
      object: null,
    };
  }
};

export const updateOptions = (
  prevArr: any[],
  updatedArray: any[],
  options: { value: string; label: string }[],
  customText: string
) => {
  let customTextArray = customText.split(',');

  const { added, object } = findArrayDifference(prevArr, updatedArray);

  if (added) {
    customTextArray.push(object?.value);
  } else {
    customTextArray = customTextArray.filter(item => item !== object?.value);
  }

  return customTextArray;
};
