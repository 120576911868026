import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
export class ImageService extends ApiService {
  static pathName = '/image';

  static getImage = (): Promise<
    IGetOneResponse<{
      message: {
        description: string;
        id: string;
        name: string;
        type: string;
      }[];
    }>
  > => {
    return ApiService.get(`${this.pathName}`);
  };

  static textToImage = (body: {
    engineId: string;
    name: string;
    prompt: any[];
    cfg_scale: number;
    height: number;
    width: number;
    steps: number;
    clip_guidance_preset: string;
    style_present?: string | null;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/text-to-image`, body);
  };

  static imageToImage = (
    body: FormData | any,
    query: {
      engineId: string;
      prompt: string;
      promptWeight: number;
      cfg_scale: number;
      steps: number;
      image_strength: number;
      clip_guidance_preset: string;
      src?: string;
      negativePrompt?: string;
      flowId?: string;
    },
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/image-to-image?engineId=${query.engineId}&prompt=${
        query.prompt
      }&promptWeight=${query.promptWeight}&cfg_scale=${query.cfg_scale}&steps=${
        query.steps
      }&image_strength=${query.image_strength}&clip_guidance_preset=${
        query.clip_guidance_preset
      }${query.src ? `&src=${query.src}` : ''}${
        query.negativePrompt ? `&negativePrompt=${query.negativePrompt}` : ''
      }${query.flowId ? `&flowId=${query.flowId}` : ''}`,
      body,
    );
  };
  static upscaleImage = (
    query: {
      engineId: string;
      height: number;
      width: number;
      src?: string;
      flowId?: string;
    },
    body?: FormData | any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/upscale-image?engineId=${query.engineId}&height=${
        query.height
      }&width=${query.width}${query.src ? `&src=${query.src}` : ''}`,
      body,
    );
  };

  static removeBackground = (
    body?: FormData | any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/remove-background`, body);
  };

  static removeBackgroundUrl = (url: string): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/remove-background?fileUrl=${url}`);
  };

  static inpaint = (
    url: string,
    maskUrl: string,
    prompt: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/inpaint?image=${url}&mask=${maskUrl}&prompt=${prompt}`,
    );
  };

  static searchReplace = ({
    fileUrl,
    prompt,
    searchPrompt,
  }: {
    fileUrl: string;
    prompt: string;
    searchPrompt: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/search-replace?fileUrl=${fileUrl}&searchPrompt=${searchPrompt}&prompt=${prompt}`,
    );
  };

  static erase = ({
    image,
    mask,
  }: {
    image: string;
    mask: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/erase?image=${image}&mask=${mask}`,
    );
  };

  static enhanceResolution = ({
    src,
    prompt,
  }: {
    src: string;
    prompt: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/conservative?src=${src}&prompt=${prompt}`,
    );
  };

  static outpaint = ({
    fileUrl,
    left,
    right,
    up,
    down,
  }: {
    fileUrl: string;
    left: string;
    right: string;
    up: string;
    down: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/outpaint?fileUrl=${fileUrl}&left=${left}&right=${right}&up=${up}&down=${down}`,
    );
  };

  static imageToImageSketch = ({
    fileUrl,
    prompt,
    negativePrompt,
    controlStrength,
    aspectRatio,
  }: {
    fileUrl: string;
    prompt: string;
    negativePrompt: string;
    controlStrength?: number;
    aspectRatio: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/sketch?fileUrl=${fileUrl}&prompt=${prompt}&negativePrompt=${negativePrompt}&controlStrength=${controlStrength}&aspectRatio=${aspectRatio}`,
    );
  };

  static imageToImageStyle = ({
    fileUrl,
    prompt,
    negativePrompt,
    controlStrength,
    aspectRatio,
  }: {
    fileUrl: string;
    prompt: string;
    negativePrompt: string;
    controlStrength?: number;
    aspectRatio: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/style?fileUrl=${fileUrl}&prompt=${prompt}&negativePrompt=${negativePrompt}&controlStrength=${controlStrength}&aspectRatio=${aspectRatio}`,
    );
  };

  static imageToImageStructure = ({
    fileUrl,
    prompt,
    negativePrompt,
    controlStrength,
    aspectRatio,
  }: {
    fileUrl: string;
    prompt: string;
    negativePrompt: string;
    controlStrength?: number;
    aspectRatio: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/structure?fileUrl=${fileUrl}&prompt=${prompt}&negativePrompt=${negativePrompt}&controlStrength=${controlStrength}&aspectRatio=${aspectRatio}`,
    );
  };

  static recolorUsingSearch = ({
    fileUrl,
    prompt,
    selectPrompt,
  }: {
    fileUrl: string;
    prompt: string;
    selectPrompt: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/search-recolor?fileUrl=${fileUrl}&prompt=${prompt}&selectPrompt=${selectPrompt}`,
    );
  };

  static imageToVideo = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/image-to-video?&cfg_scale=${body.cfg_scale}&seed=${
        body.seed
      }&motion_bucket_id=${body.motion_bucket_id}${
        body.src ? `&src=${body.src}` : ''
      }${body.flowId ? `&flowId=${body.flowId}` : ''}`,
    );
  };

  static getImageToVideoResult = (
    id: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/image-to-video-result/${id}`);
  };
}

export class StabilityAiImageService extends ApiService {
  static pathName = '/image';

  static stabilitySd3 = (
    body: FormData | any,
    query: {
      flowId: string;
      fileUrl: string;
      prompt: string;
      aspect_ratio?: string;
      mode?: string;
      negative_prompt?: string;
      model: string;
      output_format?: string;
    },
  ): Promise<IGetOneResponse<any>> => {
    const {
      flowId,
      fileUrl,
      prompt,
      aspect_ratio,
      mode,
      negative_prompt,
      model,
      output_format,
    } = query;
    return ApiService.post(
      `${
        this.pathName
      }/sd3?flowId=${flowId}&fileUrl=${fileUrl}&prompt=${prompt}&mode=${mode}&negative_prompt=${negative_prompt}&model=${model}${
        output_format ? `&output_format=${output_format}` : ''
      }${aspect_ratio ? `&aspect_ratio=${aspect_ratio}` : ''}`,
      body,
    );
  };

  static stabilityUltra = (body: {
    prompt: string;
    flowId: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/stability-ultra`, body);
  };

  static stabilityCore = (body: {
    flowId: string;
    fileUrl: string;
    prompt: string;
    aspect_ratio?: string;
    negative_prompt?: string;
    style_preset?: string;
    output_format?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/core`, body);
  };
}
