import React, { useState } from 'react';
import KitModal from '../UI/KitModal';
import { Box, Button, TextField } from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';

type ChatBotSessionModalProps = {
  show: boolean;
  onHide: () => void;
  onCreateNewUserSession: (name: string) => void;
  sessionName: string;
  setSessionName: (sessionName: string) => void;
};

const ChatBotSessionModal = ({
  sessionName,
  setSessionName,
  show,
  onHide,
  onCreateNewUserSession,
}: ChatBotSessionModalProps) => {
  const { user } = useAuthStore(state => state);

  return (
    <KitModal
      sx={{
        '.MuiPaper-root': {
          width: '345px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
      }}
      footer={
        <Box display={'flex'} gap={2} width={'100%'} padding={'0px 10px'}>
          <Button sx={{ flex: 1 }} onClick={onHide} variant="outlined">
            Cancel
          </Button>
          <Button
            // variant="contained"
            variant="contained"
            sx={{
              flex: 3,
              // backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              // color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            }}
            disabled={sessionName === ''}
            onClick={() => {
              // if (sessionName === '') return;
              onCreateNewUserSession(sessionName);
              setSessionName('');
              onHide();
            }}
          >
            Save
          </Button>
        </Box>
      }
      show={show}
      onHide={onHide}
      title="Create New Session"
    >
      {/* <Box> */}
      <TextField
        label="Session Name"
        name="sessionName"
        value={sessionName}
        onChange={e => setSessionName(e.target.value)}
        fullWidth
        InputProps={{
          style: {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
        }}
        sx={{
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
        }}
      />
      {/* </Box> */}
    </KitModal>
  );
};

export default ChatBotSessionModal;
