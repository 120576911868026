import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MailService } from '../../service/MailService';
import { useNotificationStore } from '../../store/storeNotifications';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box, Collapse, Typography } from '@mui/material';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import Node from '../UI/Node/Node';
import { TextAlignCenter } from '@phosphor-icons/react';
import Label from '../UI/Label/Label';
import TagInput from '../Test/TagInput';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useEdges, useReactFlow } from 'reactflow';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';

function OutputObjectNode({ data, type, id }: NodeProps) {
  const [formData, setFormData] = useState({
    enabledEmail: false,
    callbackUrl: '',
    emails: '',
    subject: '',
    text: '',
    urls: '',
  });
  const { setNodes: setNodeState } = useReactFlow();
  const setNotification = useNotificationStore(state => state.setNotification);
  const params = useParams();

  const { setSchema, setSaving, flowId } = useFlowsStore(state => state);
  const edges = useEdges();

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: string | boolean;
    name: string;
  }>({
    value: '',
    name: '',
  });

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setFormData({
          enabledEmail: data?.enabledEmail || false,
          callbackUrl:
            data.callbackUrlInputData?.[0]?.text || data.callbackUrl || '',
          emails: data.emails || '',
          subject: data.subject || '',
          text: data.text || '',
          urls: Array.isArray(data.urls) ? data.urls : [],
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onChange = (e: { target: { name: string; value: any } }) => {
    const { name, value } = e.target;

    if (
      name === 'emails' ||
      name === 'subject' ||
      name === 'urls' ||
      name === 'callbackUrl'
    ) {
      let processedValue = value;

      if (name === 'emails') {
        const regex = /(\s|,)+/g;
        processedValue = value.replace(regex, ',');
      }

      if (name === 'urls') {
        processedValue = value.split(',').map((url: string) => url.trim()); // Splitting input into an array of URLs
      }

      setFormData({
        ...formData,
        [name]: processedValue,
      });

      setIsChangingDirectly(true);
      setWhoIsChanging({
        value: processedValue,
        name,
      });
    }
  };

  const sendEmails = async (mailData: {
    emails: string;
    subject: string;
    text: string;
    urlsData: { text: string }[];
    callbackUrl: string;
  }) => {
    try {
      const types = ['emails', 'subject', 'text', 'urls', 'callbackUrl'];

      let newMailData: any = { ...mailData };

      types.forEach(type => {
        if (data?.[`${type}Data`] && data?.[`${type}Data`].length > 0) {
          newMailData[type] = data[`${type}Data`]
            .map((item: { text: string }) => item.text)
            .join(',');
        }
      });

      let emails = newMailData?.emails?.trim()?.split(',');
      if (!params.flowId) {
        return setNotification({
          type: 'error',
          message: 'No flow id found',
        });
      }

      if (!emails || emails.length === 0) {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          objectData: {
            canSendMail: false,
          },
          setSchema,
        });
        return;
      }

      const result =
        data?.urlsData && data?.urlsData?.length > 0
          ? data?.urlsData
              ?.filter((url: { text: string }) => url && url.text)
              .map((url: { text: string }) => url.text)
          : data && data.urls && data.urls.length > 0
          ? data.urls
          : [];

      if (emails.length === 1 && emails[0] === '') {
        emails = [];
      }

      await MailService.send({
        emails,
        subject: newMailData.subject,
        text: newMailData.text,
        flowId: params.flowId,
        urls: result,
        callbackUrl: newMailData.callbackUrl,
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        setSaving,
        id,
        objectData: {
          canSendMail: false,
        },
        setSchema,
      });
    } catch (error: any) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        setSaving,
        id,
        objectData: {
          canSendMail: false,
        },
        setSchema,
      });

      setNotification({
        type: 'error',
        message: error?.message || 'Error sending emails',
      });
    }
  };

  const onExecuteNode = useMemo(() => {
    const values = {
      ...data,
    };
    return values;
  }, [data]);

  const debounceTimeoutRef = useRef<any>(null);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,

          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  useEffect(() => {
    if (data?.canSendMail) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        const myValues = onExecuteNode;

        if (myValues) {
          sendEmails(myValues);
        }
      }, 300);
    } else {
      clearTimeout(debounceTimeoutRef.current);
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [onExecuteNode, data?.canSendMail]);

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  return (
    <Node
      id={id}
      type={type}
      data={data}
      isLoading={false}
      showTokensUsed={false}
    >
      <Box
        sx={{
          maxWidth: '400px',
          p: '16px',
        }}
      >
        <TagInput
          labelName="Output"
          handleId={'input'}
          value={formData.text}
          dataConnected={data?.textData || []}
          readOnly={true}
          hideTextArea={formData.text ? false : true}
          isAdvanced={true}
          disabled={isActiveEdge(edges, id, 'input', 'target')}
          findColor={findColor}
          advancedCollapsed={true}
          isCollapsed={true}
          height={'100px'}
          nodeId={id}
          nodeLabel={data?.label}
          removeIcons={formData.text ? false : true}
        />
        {(data?.textData?.length === 0 || !data?.textData) && (
          <Box
            width={'360px'}
            mt={'-14px'}
            height={'100px'}
            sx={{
              padding: '16px',
              borderRadius: '8px',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <TextAlignCenter size={32} color="#98A2B3" />
              <Typography fontSize={'14px'} color="#98A2B3">
                Your output will be displayed here
              </Typography>
            </Box>
          </Box>
        )}

        <Box mt={'24px'} />

        <TagInput
          name="callbackUrl"
          placeholder="Url"
          onChange={onChange}
          className="nodrag"
          handleId={'callbackUrlInput'}
          labelName="Callback URL"
          nodeId={id}
          isAdvanced={true}
          advancedCollapsed={true}
          disabled={isActiveEdge(edges, id, 'callbackUrlInput', 'target')}
          findColor={findColor}
          removeIcons
          nodeLabel={data?.label}
          isCollapsed={true}
          value={formData.callbackUrl}
        />

        <Label
          labelName="Send to Email"
          collapsed={formData.enabledEmail}
          onCollapse={() => {
            setFormData({
              ...formData,
              enabledEmail: !formData.enabledEmail,
            });

            setIsChangingDirectly(true);

            setWhoIsChanging({
              value: !formData.enabledEmail,
              name: 'enabledEmail',
            });
          }}
        />

        <Collapse in={formData.enabledEmail}>
          <TagInput
            name="emails"
            placeholder="Connect or type email(s) separated by “,”"
            onChange={onChange}
            className="nodrag"
            handleId={'emails'}
            labelName="Email Address"
            nodeId={id}
            dataConnected={data?.emailsData || []}
            isAdvanced={true}
            advancedCollapsed={true}
            disabled={isActiveEdge(edges, id, 'emails', 'target')}
            findColor={findColor}
            removeIcons
            nodeLabel={data?.label}
            isCollapsed={true}
            labelStyle={{
              fontWeight: 400,
            }}
            value={formData.emails}
          />
          <TagInput
            name="subject"
            placeholder="Connect or type subject of email"
            onChange={onChange}
            handleId={'subject'}
            value={formData.subject}
            dataConnected={data?.subjectData || []}
            isAdvanced={true}
            labelName="Subject"
            advancedCollapsed={true}
            disabled={isActiveEdge(edges, id, 'subject', 'target')}
            findColor={findColor}
            nodeId={id}
            removeIcons
            isCollapsed={true}
            labelStyle={{
              fontWeight: 400,
            }}
            nodeLabel={data?.label}
          />
          <TagInput
            name="urls"
            placeholder="Attach one or more URLs that you want to send in the email."
            onChange={onChange}
            handleId={'urls'}
            value={formData.urls}
            dataConnected={data?.urlsData || []}
            isAdvanced={true}
            labelName="Attachments"
            advancedCollapsed={true}
            disabled={isActiveEdge(edges, id, 'urls', 'target')}
            findColor={findColor}
            nodeId={id}
            removeIcons
            isCollapsed={true}
            labelStyle={{
              fontWeight: 400,
            }}
            nodeLabel={data?.label}
          />
        </Collapse>
      </Box>
    </Node>
  );
}

export default memo(OutputObjectNode);
