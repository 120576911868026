import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import ReactConfetti from 'react-confetti';
import { ReactComponent as FluxPrompt } from '../../assets/icons/FluxPrompt.svg';
import { base64Decode } from '../../helpers/decodeToken';
import SendInvite from './SendInvite';
import { useNavigate } from 'react-router-dom';

const AdminRegister: React.FC = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const decodedToken = token ? base64Decode(token) : null;

  const [showSendInvite, setShowSendInvite] = useState(false);

  if (showSendInvite)
    return (
      <SendInvite
        decodedToken={decodedToken}
        setShowSendInvite={setShowSendInvite}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <ReactConfetti
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          // height: '670px',
          width: '40%',
          padding: '32px',
          gap: '48px',
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid var(--Neutral-300, #D0D5DD)',
          boxShadow: '10px 10px 40px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <FluxPrompt />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box>
            <Typography
              fontSize={'32px'}
              fontWeight={500}
              color={'var(--Text-Secondary, #475467)'}
              lineHeight={'116.7%'}
            >
              Welcome to your FluxPrompt
            </Typography>
            <Typography
              fontSize={'32px'}
              fontWeight={500}
              color={'var(--Primary-500, #3650DE)'}
              lineHeight={'116.7%'}
            >
              {decodedToken?.plan || 'Starter'} Account
            </Typography>
          </Box>
          <Typography
            fontSize={'16px'}
            fontWeight={400}
            color={'var(--Text-Secondary, #475467)'}
            lineHeight={'175%'}
          >
            Create the admin account with this email:{' '}
            <a href={`mailto:${decodedToken?.email}`}>
              {decodedToken && decodedToken.email}
            </a>
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setShowSendInvite(true)}
          >
            No, use another email as the admin
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate(`/auth/register?token=${token}`)}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default AdminRegister;
