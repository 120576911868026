import {
  Box,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormData, openAiModelsImage } from './constants';

const types = [
  {
    name: 'Create Image',
    value: 'createImage',
  },
  {
    name: 'Edit Image',
    value: 'editImage',
  },
  {
    name: 'Image Variation',
    value: 'imageVariation',
  },
];

type OpenAiImgProps = {
  formData: FormData;
  onChange: (e: any) => void;
};

const OpenAiImg = ({ formData, onChange }: OpenAiImgProps) => {

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      <Box mt={'8px'}>
        <InputLabel htmlFor="openAiModels">Open AI Models</InputLabel>
        <Select
          value={formData.model}
          onChange={onChange}
          className="nodrag"
          fullWidth
          name="model"
          sx={{
            width: '100%',
          }}
        >
          {openAiModelsImage.map(type => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {formData.model === 'dall-e-3' && (
        <Box mt={'8px'}>
          <InputLabel htmlFor="quality">Quality</InputLabel>
          <Select
            value={formData.quality}
            onChange={onChange}
            className="nodrag"
            fullWidth
            name="quality"
            sx={{
              width: '100%',
            }}
          >
            <MenuItem key={'standard'} value={'standard'}>
              standard
            </MenuItem>

            <MenuItem key={'hd'} value={'hd'}>
              hd
            </MenuItem>
          </Select>
        </Box>
      )}

      {formData.model !== 'dall-e-3' && (
        <Box>
          <FormLabel htmlFor="numbersOfVariations">
            Number of variations
          </FormLabel>
          <TextField
            type="text"
            value={formData.numbersOfVariations}
            onChange={onChange}
            fullWidth
            name="numbersOfVariations"
            placeholder="Number of variations"
          />
        </Box>
      )}

      <Box>
        <FormLabel htmlFor="size">Size</FormLabel>
        <Select
          value={formData.size}
          onChange={onChange}
          fullWidth
          className="nodrag"
          name="size"
          sx={{
            marginLeft: '8px',
            width: '200px',
          }}
        >
          <MenuItem value={'1024x1024'}>1024x1024</MenuItem>
          <MenuItem value={'1024x1792'}>1024x1792</MenuItem>
          <MenuItem value={'1792x1024'}>1792x1024</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default OpenAiImg;
