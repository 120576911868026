type LocalStorageKey =
  | 'accessToken'
  | 'targetTime'
  | 'saved-tabs'
  | 'googleSheetsAccessToken'
  | 'googleRefreshToken';

export class LocalStorageService {
  static getItem = (keyItem: LocalStorageKey): string | null => {
    return localStorage.getItem(keyItem);
  };

  static setItem = (keyItem: LocalStorageKey, keyValue: string) => {
    localStorage.setItem(keyItem, keyValue);
  };

  static removeItem = (item: LocalStorageKey) => {
    localStorage.removeItem(item);
  };
}
