import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SidebarFolders from '../../SidebarFolders';
import { FolderService } from '../../../../service/FolderService';
import { useAuthStore } from '../../../../store/storeAuth';
import CreateFolderModal from './FolderModal/FolderModal';
import { updateIndexForNewFlowOrFolder } from '../../../../util/folderIndexCreater';
import { FlowService } from '../../../../service/FlowService';
import CreateFlowModal from '../../../Flow/CreateFlowModal';
import {
  CaretDown,
  ChatCircleText,
  Plus,
  Link as LinkIcon,
  PencilSimpleLine,
  ArrowSquareOut,
  Copy,
  Trash,
  ChatText,
  CaretRight,
  BoundingBox,
} from '@phosphor-icons/react';
import { ReactComponent as DotsThreeOutlineGrayIcon } from '../../../../assets/icons/DotsThreeOutlineGrayIcon.svg';
import { ReactComponent as DotsThreeOutlineWhiteIcon } from '../../../../assets/icons/DotsThreeOutlineWhiteIcon.svg';
import { useParams } from 'react-router-dom';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';
import useFlowsStore from '../../../../store/storeFlows';
import { useStoreTabs } from '../../../../store/storeTabs';

type Props = {
  sidebarOpen: boolean;
  active: string;
  setSidebarOpen: (open: boolean) => void;
  setNotification: (notification: any) => void;
  updateTab: (tab: any) => void;
  addTab: (tab: any) => void;
  navigate: (path: string) => void;
  setIsFlowModalOpen: (body: { isOpen: boolean; flowId: string }) => void;
  callGetFolders: (folderId: string, isCreating?: boolean) => void;
  setCreateOrEditFLow: (flow: any) => void;
  onCreateFlowOrUpdate: (
    name: string,
    isEditing?: boolean,
    id?: string,
    folderId?: string,
    isCopy?: boolean,
    type?: string,
  ) => void;
  createOrEditFLow: any;
  openAddFlowModal: boolean;
  setOpenAddFlowModal: (open: boolean) => void;
  handleCloseSideBarMobile: () => void;
  collapsePrivate: boolean;
  setCollapsePrivate: (collapse: boolean) => void;
};

const PrivateFlows = ({
  sidebarOpen,
  setNotification,
  active,
  addTab,
  navigate,
  setSidebarOpen,
  setIsFlowModalOpen,
  callGetFolders,
  setCreateOrEditFLow,
  onCreateFlowOrUpdate,
  createOrEditFLow,
  openAddFlowModal,
  setOpenAddFlowModal,
  handleCloseSideBarMobile,
  collapsePrivate,
  setCollapsePrivate,
}: Props) => {
  const [folderUpdate, setFolderUpdate] = useState({
    isEditing: false,
    isSubfolder: false,
    isSubfolderEditing: false,
    folder: {
      id: '',
      name: '',
    },
  });

  const {
    folders,
    setFolders,
    user: authUser,
    setAuthUser,
  } = useAuthStore(state => state);
  const tabs = useStoreTabs((state: any) => state.tabs);

  const { setFlowId } = useFlowsStore(state => state);

  const [dragStarted, setDragStarted] = useState<any>({
    started: false,
    randomValue: '',
  });
  const [chatCollapsed, setChatCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const params = useParams();
  const [onHoverPrivate, setOnHoverPrivate] = useState(false);
  const onDragStart = (e: any) => {
    setDragStarted({
      started: true,
      randomValue: Math.random().toString(),
    });
  };
  const [onOpenCreateFolder, setOnOpenCreateFolder] = useState(false);
  const [collapseFlows, setCollapseFlows] = useState(true);

  const onShareFlow = async (
    id: string,
    email: string,
    name: string,
    companyId?: string,
  ) => {
    try {
      let body: any = { email, name };
      if (companyId) {
        body = { email, companyId, name };
      }
      await FlowService.shareFlow(id, body);
      setNotification({
        type: 'success',
        message: 'Flow shared successfully',
      });
      setOpenAddFlowModal(false);
    } catch (err) {
      let error = err as any;
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong , sharing failed',
      });
    }
  };
  const handleDragAndDrop = async (results: any) => {
    const { source, destination } = results;

    setDragStarted({
      started: false,
      randomValue: '',
    });
    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const newFolders = [
      ...folders?.filter(folder => folder.name !== 'Chatbot'),
    ];

    const chatbotFolder = folders?.find(folder => folder.name === 'Chatbot');

    if (source.droppableId === 'ROOT' && destination.droppableId === 'ROOT') {
      const spliceSource = newFolders.splice(source.index - 1, 1);

      newFolders.splice(destination.index - 1, 0, ...spliceSource);

      const newFoldersWithIndex = newFolders.map((folder, index) => {
        return {
          ...folder,
          index: index + 1,
        };
      });

      setFolders([...(newFoldersWithIndex ?? []), chatbotFolder as any]);

      try {
        await FolderService.updateOrder({
          type: 'ROOT',
          newOrder: newFoldersWithIndex.map(({ id, index }) => ({ id, index })),
        });
      } catch (error) {
        console.log(error);
      }
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const findFolder = newFolders.find(
        folder => folder.id === source.droppableId,
      );

      const newFlows = [...findFolder?.flows!];

      const spliceSource = newFlows.splice(source.index - 1, 1);

      newFlows.splice(destination.index - 1, 0, ...spliceSource);

      const newFlowsWithIndex = newFlows.map((flow, index) => {
        return {
          ...flow,
          index: index + 1,
        };
      });

      const newFoldersWithUpdatedFlows = newFolders.map(folder => {
        if (folder.id === source.droppableId) {
          return {
            ...folder,
            flows: newFlowsWithIndex,
          };
        }
        return folder;
      });
      setFolders([...newFoldersWithUpdatedFlows, chatbotFolder as any]);

      try {
        await FolderService.updateOrder({
          type: 'flowToFlow',
          newOrder: newFlowsWithIndex.map(({ id, index }) => ({
            id,
            index,
          })),
          folderId: source.droppableId,
        });
      } catch (error) {
        console.log(error);
      }
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      if (source.droppableId !== destination.droppableId) {
        const findSourceFolder = newFolders.find(
          folder => folder.id === source.droppableId,
        );

        const findDestinationFolder = newFolders.find(
          folder => folder.id === destination.droppableId,
        );

        if (!findSourceFolder || !findDestinationFolder) return; // Handle the case where folders are not found

        const newSourceFlows = [...findSourceFolder?.flows!];
        // const findFlow = newSourceFlows.find(
        //   flow => flow.index === source.index,
        // );

        const newDestinationFlows = [...findDestinationFolder?.flows!];

        const spliceSource = newSourceFlows.splice(source.index - 1, 1);

        // If the destination folder is not open, append the flow to the end
        if (
          findDestinationFolder.flows.length ===
          findDestinationFolder.flowsLength
        ) {
          newDestinationFlows.push(...spliceSource);
        } else {
          newDestinationFlows.splice(destination.index - 1, 0, ...spliceSource);
        }

        const newSourceFlowsWithIndex = newSourceFlows.map((flow, index) => {
          return {
            ...flow,
            index: index + 1,
          };
        });

        const newDestinationFlowsWithIndex = newDestinationFlows.map(
          (flow, index) => {
            return {
              ...flow,
              index: index + 1,
            };
          },
        );

        const newFoldersWithUpdatedFlows = newFolders.map(folder => {
          if (folder.id === source.droppableId) {
            return {
              ...folder,
              flows: newSourceFlowsWithIndex,
            };
          }

          if (folder.id === destination.droppableId) {
            return {
              ...folder,
              flows: newDestinationFlowsWithIndex,
              flowsLength: newDestinationFlows.length, // Update the flowsLength of the destination folder
            };
          }

          return folder;
        });

        setFolders([...newFoldersWithUpdatedFlows, chatbotFolder as any]);

        try {
          await FolderService.updateOrder({
            type: 'flowToFolder',
            sourceFolderId: source.droppableId,
            targetFolderId: destination.droppableId,
            newSourceOrder: newSourceFlowsWithIndex.map(({ id, index }) => ({
              id,
              index,
            })),
            newTargetOrder: newDestinationFlowsWithIndex.map(
              ({ id, index }) => ({
                id,
                index,
              }),
            ),
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    setDragStarted({
      started: false,
      randomValue: '',
    });
  };

  const onCreateFolderOrUpdate = async (
    name: string,
    isEditing = false,
    id = '',
    isSubfolder = false,
    isSubfolderEditing = false,
  ) => {
    try {
      if (isEditing || isSubfolderEditing) {
        await FolderService.updateFolder(id, { name });

        callGetFolders(id);
      } else if (isSubfolder) {
        const responseFolder = await FolderService.createFolder({ name });

        await FolderService.createSubFolder(id, responseFolder.data.id);
      } else {
        if (name.toLocaleLowerCase() === 'chatbot') {
          setNotification({
            type: 'error',
            message: 'Chatbot is a reserved name',
          });
          return;
        }
        const index = updateIndexForNewFlowOrFolder(folders);

        const folder = await FolderService.createFolder({
          name,
          index,
        });

        callGetFolders(folder?.data?.id, true);
      }

      setOnOpenCreateFolder(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [clickedFluxBotstId, setClickedFluxBotstId] = useState<string | null>(
    null,
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setClickedFluxBotstId(id === clickedFluxBotstId ? null : id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedFluxBotstId(null);
  };

  const onDelete = async (flowId: string, type: string) => {
    try {
      const result = window.confirm(
        'Are you sure you want to delete the flow?',
      );

      if (!result) return;

      await FlowService.deleteFlow(flowId, type);
      setAuthUser({
        ...authUser!,
      });

      const modifyFolders = folders.map(folder => {
        return {
          ...folder,
          flows: folder.flows.filter(flow => flow.id !== flowId),
        };
      });

      setFolders(modifyFolders);

      if (params.flowId === flowId) {
        navigate('/');
      }
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong , deleting failed',
      });
    }
  };

  const openUpdateFlowModal = (
    flow: { id: string; name: string },
    folderId: any,
    isSharing: boolean,
    isEditing: boolean,
  ) => {
    setCreateOrEditFLow({
      isSharing: isSharing,
      isEditing: isEditing,
      flow,
      folderId: folderId,
    });
    setOpenAddFlowModal(true);
  };

  const onOpenFolder = async (id: string) => {
    // c(true);
    try {
      const res = await FolderService.getOne(id);

      const modifyFolders = folders.map(folder => {
        if (folder.id === id) {
          return res.data;
        }
        return folder;
      });

      setFolders(modifyFolders);

      setChatCollapsed(true);
    } catch (error) {
      console.log(error);
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box
        style={{
          padding: '5px 5px',
          borderRadius: '4px',
          width: '100%',
          background: onHoverPrivate ? '#F2F4F7' : 'transparent',
        }}
        onMouseEnter={() => setOnHoverPrivate(true)}
        onMouseLeave={() => setOnHoverPrivate(false)}
      >
        {onHoverPrivate ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              position: 'relative',
            }}
          >
            <Typography
              style={{
                paddingLeft: '10px',
                fontSize: '12px',
                fontWeight: 400,
                color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
              }}
              className="menu"
            >
              Private
            </Typography>
            <Box
              position={'absolute'}
              right={'10px'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <IconButtonTooltip
                onClick={() => setOnOpenCreateFolder(true)}
                title="add a new folder"
                componentProps={{
                  tooltip: {
                    sx: {
                      bgcolor:
                        authUser?.theme === 'dark' ? '#101828' : '#475467',
                    },
                  },
                  arrow: {
                    sx: {
                      color: authUser?.theme === 'dark' ? '#101828' : '#475467',
                    },
                  },
                }}
              >
                <Plus
                  className="top-menu-icons"
                  size={16}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  weight="bold"
                  style={{ alignSelf: 'center', cursor: 'pointer' }}
                />
              </IconButtonTooltip>

              <IconButtonTooltip
                onClick={() => setCollapsePrivate(!collapsePrivate)}
                title="Collapse"
                componentProps={{
                  tooltip: {
                    sx: {
                      bgcolor:
                        authUser?.theme === 'dark' ? '#101828' : '#475467',
                    },
                  },
                  arrow: {
                    sx: {
                      color: authUser?.theme === 'dark' ? '#101828' : '#475467',
                    },
                  },
                }}
              >
                <CaretDown
                  className="top-menu-icons"
                  size={16}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  weight="bold"
                  style={{ alignSelf: 'center', cursor: 'pointer' }}
                />
              </IconButtonTooltip>
            </Box>
          </Box>
        ) : (
          <Divider
            sx={{
              width: '100%',
              color: authUser?.theme === 'dark' ? '#fff' : '#475467',
            }}
            textAlign="center"
          >
            <Typography
              style={{
                paddingLeft: '10px',
                fontSize: '12px',
                fontWeight: 400,
                color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
              }}
              className="menu"
            >
              Private
            </Typography>
          </Divider>
        )}
      </Box>
      <Collapse in={collapsePrivate}>
        <Box
          className="menu menu--on-hover"
          onClick={() => {
            setCollapseFlows(!collapseFlows);
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
            paddingLeft: '15px',
            // width: '14.8rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              padding: '4px',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingRight: '8px',
              '&:hover': {
                backgroundColor:
                  authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                borderRadius: '4px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <BoundingBox
                style={{
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                  fontSize: '18px',
                }}
              />
              {sidebarOpen && (
                <>
                  <Typography
                    className="active-menu"
                    sx={{
                      color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                    }}
                  >
                    Flows
                  </Typography>
                </>
              )}
            </Box>

            <Box sx={{ alignSelf: 'center', display: 'flex', gap: '8px' }}>
              <IconButtonTooltip
                title="Add Folder"
                onClick={event => {
                  event.stopPropagation();
                  setOnOpenCreateFolder(true);
                }}
              >
                <Plus
                  size={16}
                  className="menu-icons active-menu"
                  style={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                    cursor: 'pointer',
                  }}
                />
              </IconButtonTooltip>

              {sidebarOpen && (
                <IconButtonTooltip title="Collapse">
                  {!collapseFlows ? (
                    <CaretDown
                      className="active-menu"
                      size={16}
                      color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    />
                  ) : (
                    <CaretRight
                      className="active-menu"
                      size={16}
                      color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    />
                  )}
                </IconButtonTooltip>
              )}
            </Box>
          </Box>
        </Box>
        <Collapse in={collapseFlows}>
          <Box
            style={{
              marginLeft: sidebarOpen ? '20px' : 10,
            }}
          >
            <Box
              style={{
                marginLeft: sidebarOpen ? '15px' : 0,
              }}
            >
              <DragDropContext
                onDragEnd={handleDragAndDrop}
                onDragStart={onDragStart}
                onDragUpdate={e => {
                  setDragStarted({
                    randomValue: e.destination?.droppableId,
                    started: e.source?.droppableId,
                  });
                }}
              >
                <Droppable droppableId="ROOT" type="group">
                  {provided => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <>
                        {folders
                          ?.filter(folder => folder.name !== 'Chatbot')
                          ?.map(folder => {
                            return (
                              <Draggable
                                draggableId={folder.id}
                                index={folder?.index}
                                key={folder.id}
                              >
                                {provided => (
                                  <div
                                    className={`item-container`}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                  >
                                    <SidebarFolders
                                      theme={authUser?.theme}
                                      key={folder.id}
                                      active={active}
                                      folder={folder}
                                      dragStarted={dragStarted}
                                      setFolderUpdate={setFolderUpdate}
                                      setCreateOrEditFLow={setCreateOrEditFLow}
                                      setOpenAddFlowModal={setOpenAddFlowModal}
                                      setOnOpenCreateFolder={
                                        setOnOpenCreateFolder
                                      }
                                      sidebarOpen={sidebarOpen}
                                      setSidebarOpen={setSidebarOpen}
                                      onCreateFlow={onCreateFlowOrUpdate}
                                      onClick={handleCloseSideBarMobile}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                      </>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        </Collapse>

        <CreateFolderModal
          theme={authUser?.theme}
          show={onOpenCreateFolder}
          onCreateFolderOrUpdate={onCreateFolderOrUpdate}
          data={folderUpdate}
          onHide={() => {
            setOnOpenCreateFolder(false);
            setFolderUpdate(null as any);
          }}
        />
        <Box
          className="menu menu--on-hover"
          onClick={() => {
            if (chatCollapsed) {
              setChatCollapsed(false);
            } else {
              onOpenFolder(
                folders.filter((folder: any) => folder.name === 'Chatbot')[0]
                  ?.id,
              );
            }
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
            paddingLeft: '15px',
            // width: '14.8rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              padding: '4px',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingRight: '8px',
              '&:hover': {
                backgroundColor:
                  authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                borderRadius: '4px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <ChatText
                style={{
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                  fontSize: '18px',
                }}
              />
              {sidebarOpen && (
                <>
                  <Typography
                    className="active-menu"
                    sx={{
                      color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                    }}
                  >
                    FluxBots
                  </Typography>
                </>
              )}
            </Box>

            <Box sx={{ alignSelf: 'center', display: 'flex', gap: '8px' }}>
              <IconButtonTooltip
                title="Add fluxbot"
                onClick={event => {
                  event.stopPropagation();
                  setCreateOrEditFLow({
                    isSharing: false,
                    isEditing: false,
                    type: 'chat',
                    flow: {
                      name: '',
                      id: '',
                    },
                    folderId: folders.filter(
                      (folder: any) => folder.name === 'Chatbot',
                    )[0]?.id,
                  });
                  setOpenAddFlowModal(true);
                }}
              >
                <Plus
                  size={16}
                  className="menu-icons active-menu"
                  style={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                    cursor: 'pointer',
                  }}
                />
              </IconButtonTooltip>

              {sidebarOpen && (
                <IconButtonTooltip title="Collapse">
                  {!chatCollapsed ? (
                    <CaretDown
                      className="active-menu"
                      size={16}
                      color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    />
                  ) : (
                    <CaretRight
                      className="active-menu"
                      size={16}
                      color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    />
                  )}
                </IconButtonTooltip>
              )}
            </Box>
          </Box>
        </Box>

        <Collapse in={chatCollapsed} timeout={500}>
          <Box pl={'33px'}>
            {folders
              ?.filter(folder => folder.name === 'Chatbot')
              ?.map((folder, index) => (
                <Box
                  key={index}
                  gap={'8px'}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  {folder?.flows?.map((flow, idx) => (
                    <Box
                      key={`${flow.id}-${idx}`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems={'center'}
                      className="menu menu--on-hover"
                      sx={{
                        height: '32px',
                        paddingLeft: '12px',
                        '&:hover': {
                          backgroundColor:
                            authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                          borderRadius: '4px',
                        },
                      }}
                    >
                      <ChatCircleText
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                        size={25}
                        weight="regular"
                        className="active-menu"
                      />
                      <Typography
                        className="active-menu"
                        style={{
                          color:
                            authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                          fontSize: '16px',
                          margin: '0',
                          cursor: 'pointer',
                          width: '13rem',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          paddingLeft: '8px',
                        }}
                        onClick={() => {
                          handleCloseSideBarMobile();
                          navigate(
                            `/flow/${flow?.id}?folderId=${folder.id}&type=chat`,
                          );
                          setFlowId('');
                        }}
                      >
                        {flow?.name}
                      </Typography>
                      <IconButton
                        size="medium"
                        className="top-menu-icons"
                        key={`${flow.id}-icon`}
                        onClick={e => {
                          handleClick(e, flow.id);
                        }}
                        style={{
                          color:
                            authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                        }}
                      >
                        {authUser?.theme === 'dark' ? (
                          <DotsThreeOutlineWhiteIcon />
                        ) : (
                          <DotsThreeOutlineGrayIcon />
                        )}
                      </IconButton>
                      <Popover
                        key={`${flow.id}-popover`}
                        open={clickedFluxBotstId === flow.id}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: '8px',
                            background:
                              authUser?.theme === 'dark' ? '#101828' : '#FFFF',
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  authUser?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              handleClose();
                              setOpenAddFlowModal(true);
                              setCreateOrEditFLow({
                                isSharing: false,
                                isEditing: true,
                                type: 'flow',
                                flow,
                                folderId: folder.id,
                              });
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <PencilSimpleLine
                                size={20}
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#475467'
                                }
                              >
                                Rename
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  authUser?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              handleClose();
                              openUpdateFlowModal(flow, folder.id, true, false);
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <LinkIcon
                                size={20}
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#475467'
                                }
                              >
                                Share
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant="contained"
                            disabled={tabs.length >= 10}
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  authUser?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              handleClose();

                              if (tabs.length <= 9) {
                                addTab({
                                  flowName: flow.name,
                                  flowId: flow.id,
                                  folderId: folder.id,
                                });
                                navigate(`/flow/${flow.id}`);
                              } else {
                                navigate(`/flow/${flow.id}`);
                              }

                              setFlowId('');
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <ArrowSquareOut
                                size={20}
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#475467'
                                }
                              >
                                Open in new tab
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  authUser?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              handleClose();
                              onCreateFlowOrUpdate(
                                flow.name,
                                true,
                                flow.id,
                                folder.id,
                                true,
                                'chat',
                              );
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Copy
                                size={20}
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  authUser?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#475467'
                                }
                              >
                                Duplicate
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              color: '#b91c1c',
                              px: '8px',
                              gap: '8px',
                              ':hover': {
                                background:
                                  authUser?.theme === 'dark'
                                    ? '#FFADB7'
                                    : '#fecdd3',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              handleClose();
                              onDelete(flow.id, 'chat');
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Trash size={20} color="#b91c1c" />
                              <Typography>Delete</Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Popover>
                    </Box>
                  ))}
                </Box>
              ))}
          </Box>
        </Collapse>
      </Collapse>

      <CreateFlowModal
        theme={authUser?.theme}
        show={openAddFlowModal}
        setIsFlowModalOpen={setIsFlowModalOpen}
        onCreateFlow={onCreateFlowOrUpdate}
        data={createOrEditFLow}
        onShareFlow={onShareFlow}
        onHide={() => {
          setOpenAddFlowModal(false);
          setCreateOrEditFLow({
            isSharing: false,
            isEditing: false,
            type: '',
            flow: {
              name: '',
              id: '',
            },
            folderId: '',
          });
        }}
      />
    </Box>
  );
};

export default PrivateFlows;
