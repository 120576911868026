import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class FormsService extends ApiService {
  static pathName = '/forms';

  static getForms = (flowId: string): Promise<IGetAllResponse<any>> => {
    // return this.get(`${this.pathName}?page=1&pageSize=10000`, signal);
    return this.get(`${this.pathName}?flowId=${flowId}`);
  };

  static getForm = (
    id: string,
    signal?: AbortSignal,
  ): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/${id}`);
  };

  static getFormPublished = (
    id: string,
    signal?: AbortSignal,
  ): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/published/${id}`);
  };

  static createForm = (body: any): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}`, body);
  };

  static update(id: string, body: any): Promise<IGetOneResponse<any>> {
    return this.patch(`${this.pathName}/${id}`, body);
  }

  static deleteForm(id: string) {
    return this.delete(`${this.pathName}/${id}`);
  }
}
