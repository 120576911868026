import React, { useEffect, useState } from 'react';
import Image from '.././../assets/images/Logo.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Legal.scss';
import PrivacyPolicy from './PrivacyPolicy';

import { Box } from '@mui/material';
import { CaretRight } from '@phosphor-icons/react';

const Legal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const links = [
    {
      name: 'Privacy Policy',
      path: '/legal/privacy-policy',
    },
    {
      name: 'Terms of Service',
      path: '/legal/terms-of-service',
    },
    {
      name: 'Contact Us',
      onClick: () => {
        window.open('mailto:supportAi@enhancedai.com', '_blank');
      },
    },
  ];

  useEffect(() => {
    const path = location.pathname;

    setActiveLink(path);
  }, [location.pathname]);

  return (
    <div className='layout-legal'>
      <div
        className='layout-legal-nav d-flex  align-items-center'
        style={{
          background: '#2a3044',
          padding: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '3',
          }}
          className='layout-legal-nav__logo '
        >
          <img
            src={Image}
            width={'40px'}
            height={'40px'}
            alt='logo'
            role='button'
            onClick={() => navigate('/')}
            className='layout-legal-nav__logo--img'
          />
          <h2
            className='mb-0 text-white fs-3'
            onClick={() => navigate('/')}
            role='button'
          >
            Enhanced Ai
          </h2>
        </Box>
      </div>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
        className='layout-legal-container'
      >
        <Box
          className='layout-legal-container-sidebar '
          sx={{
            background: '#565d72',
            maxWidth: '300px',
            width: '100%',
            color: 'white !important',
            padding: '20px',
            height: 'calc(100vh - 80px)',
          }}
        >
          <div className='layout-legal-container-sidebar__menu'>
            {links.map(item => (
              <div
                key={item.name}
                className={`layout-legal-container-sidebar__menu--link  ${
                  activeLink === item.path
                    ? 'layout-legal-container-sidebar__menu--link--active'
                    : ''
                }`}
                onClick={() => {
                  if (item.path) {
                    navigate(item.path);
                  } else {
                    item.onClick && item.onClick();
                  }
                }}
              >
                <span>{item.name}</span>
                {activeLink === item.path && <CaretRight fontSize={15} />}
              </div>
            ))}
          </div>
        </Box>

        <Box width={'100%'} className='w-100 layout-legal-container-view'>
          {activeLink === '/legal' ? <PrivacyPolicy /> : <Outlet />}
        </Box>
      </Box>
    </div>
  );
};

export default Legal;
