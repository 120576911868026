import { Box, MenuItem, Select, Typography } from '@mui/material';
import { TextAlignCenter } from '@phosphor-icons/react';
import React from 'react';
import { useAuthStore } from '../../../store/storeAuth';

const SelectDocument = ({
  onChangeDocument,
  documents,
  selectedDocument,
  isLoading,
  spaceId,
}: {
  onChangeDocument: (e: any) => void;
  documents: any;
  selectedDocument: any;
  isLoading: boolean;
  spaceId: string;
}) => {
  const { user } = useAuthStore(state => state);

  return (
    <>
      <Select
        sx={{
          '& .MuiList-root': {
            backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
          border: 'none',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
        className="nodrag"
        disabled={isLoading}
        value={selectedDocument?.id || 'none'}
        onChange={onChangeDocument}
      >
        <MenuItem
          value={'none'}
          disabled
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}
        >
          <em>None</em>
        </MenuItem>

        {!spaceId && (
          <MenuItem
            value={'private'}
            disabled
            sx={{
              color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
            }}
          >
            <em>Private Docs</em>
          </MenuItem>
        )}
        {documents
          ?.filter((doc: any) => !doc?.spaceName)
          .map((doc: any, i: number) => (
            <MenuItem
              value={doc?.id}
              key={i}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
              }}
            >
              {doc?.name}
            </MenuItem>
          ))}

        {!spaceId && (
          <MenuItem
            value={'space'}
            disabled
            sx={{
              color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
            }}
          >
            <em>Space Docs</em>
          </MenuItem>
        )}
        {documents
          ?.filter((doc: any) => doc?.spaceName)
          .map((doc: any, i: number) => (
            <MenuItem
              value={doc?.id}
              key={i}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
              }}
            >
              {doc?.name} -- ({doc?.spaceName})
            </MenuItem>
          ))}
      </Select>
      {!selectedDocument?.id && (
        <Box
          sx={{
            p: '24px 20px',
            mb: '24px',
            borderRadius: '4px',
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            display: 'flex',
            width: '386px',
            height: '186px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextAlignCenter color="#98A2B3" size={32} />
          <Typography
            sx={{
              fontSize: '14px',
              color: '#98A2B3',
            }}
          >
            Easily inject your stored data into workflows.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SelectDocument;
