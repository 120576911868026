import React from 'react';
import NoTemplatesIcon from '../../assets/icons/NoTemplates.png';
import './TemplateSystemModal.css';

const NoTemplates: React.FC = () => {
  return (
    <div className="no-templates-container">
      <img
        src={NoTemplatesIcon}
        alt="No Templates"
        className="no-templates-icon"
      />
      <h2 className="no-templates-text">No templates were found!</h2>
      <h2 className="no-templates-text">
        Try searching using another keyword.
      </h2>
    </div>
  );
};

export default NoTemplates;
