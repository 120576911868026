import { create } from 'zustand';

type Tab = {
  flowName: string;
  flowId: string;
  folderId: string;
};

type TabState = {
  tabs: Tab[];
};

interface TabStore {
  tabs: Tab[];
  setTabs: (tabs: Tab[]) => void;
  addTab: (tab: Tab) => void;
  updateTab: (updatedTab: { flowId: string; flowName: string }) => void;
  removeTab: (tabId: string) => void;
}

export const useStoreTabs = create<TabStore>(set => ({
  tabs: [],

  setTabs: (tabs: Tab[]) =>
    set(() => {
      return { tabs: tabs };
    }),

  addTab: (tab: Tab) =>
    set((state: TabState) => {
      const newTabs = [tab, ...state.tabs];

      return { ...state, tabs: [...newTabs] };
    }),

  updateTab: (updatedTab: { flowId: string; flowName: string }) =>
    set((state: TabState) => {
      const tabs = [...state.tabs];
      const updatedTabs = tabs.map(tab => {
        if (tab.flowId === updatedTab.flowId) {
          return {
            ...updatedTab,
            folderId: tab.folderId,
          };
        }
        return tab;
      });

      return { ...state, tabs: updatedTabs };
    }),

  removeTab: (tabId: string) =>
    set((state: TabState) => {
      const newTabs = state.tabs.filter(tab => tab.flowId !== tabId);

      return { ...state, tabs: [...newTabs] };
    }),
}));
