export const fluxTutorialsVideos = [
  {
    title: 'Intro to Flux Prompt',
    videoUrl: 'https://youtu.be/SzrNf_8nrfw?si=b9deUXeGhVSk4TC0',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNugLv6pOEK220yBJVeJiE13b',
  },
  {
    title: 'Articles,  Videos, and Templates',
    videoUrl: 'https://youtu.be/Yd0eFxfB7aY?si=2Twrk5HbAyvy97hk',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNugLv6pOEK220yBJVeJiE13b',
  },
  {
    title: 'FluxPrompt AI Flows for Marketers - Creating Powerful Ads',
    videoUrl: 'https://youtu.be/A21H0x18aqc?si=Zd9gyXhlL7U9Nf12',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNuj-ahzXM7T8hUk4cF1qONPj',
  },
  {
    title: 'Understanding FluxPrompt Text Features 🌟',
    videoUrl: 'https://youtu.be/CfSHX_CjMJA?si=cmGXP1Ec4CpIAGTZ',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNugLv6pOEK220yBJVeJiE13b',
  },
  {
    title: 'Explaining the Image Generator',
    videoUrl: 'https://youtu.be/xqbPFomZL-Q?si=pmHN9myobOkJt6-m',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNugLv6pOEK220yBJVeJiE13b',
  },
  {
    title:
      'FluxPrompt AI Flows for Consultants - Researching Competitors and Potential Clients',
    videoUrl: 'https://youtu.be/pkCzCiqO46o?si=zoYcjaIvMk61Rdp5',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNuj-ahzXM7T8hUk4cF1qONPj',
  },

  {
    title: 'Explanation of the AI Text Generator',
    videoUrl: 'https://youtu.be/dP1afBnEe2c?si=f9JJBWsxWBLa6V5T',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNugLv6pOEK220yBJVeJiE13b',
  },
  {
    title: 'AI Image Reader',
    videoUrl: 'https://youtu.be/py_ISzO3jAA?si=_txuFGVnATPtK63o',
    playlistUrl:
      'https://www.youtube.com/playlist?list=PLAzgf3S2dNugLv6pOEK220yBJVeJiE13b',
  },
];

export const fluxBlogs = [
  {
    title: 'Ai Text Generator',
    link: 'https://intercom.help/enhanced-ai/en/articles/9067270-ai-text-generator',
    previewUrl:
      'https://downloads.intercomcdn.com/i/o/1032869628/0b815d94718bbd277a2ef276/Ai+Text+Generator.png',
    description: `In the rapidly evolving world of artificial intelligence, AI text generators have become invaluable tools for content creation, offering users the ability generate...`,
    author: 'Will Frazier',
  },
  {
    title: 'Ai Image Generator',
    link: 'https://intercom.help/enhanced-ai/en/articles/9067918-ai-image-generator',
    previewUrl:
      'https://downloads.intercomcdn.com/i/o/1032840979/5e88ec7dad6ebc891953ece8/Ai+Image+Generator.png',
    description: `In today's digital age, AI image generators have become a fascinating tool for creators, designers, and anyone looking to bring their imaginative concepts to life...`,
    author: 'Will Frazier',
  },
  {
    title: 'Ai Image Reader',
    link: 'https://intercom.help/enhanced-ai/en/articles/9076942-ai-image-reader',
    previewUrl:
      'https://downloads.intercomcdn.com/i/o/1031651890/450596b7bd6605a3a1275d00/Ai+Image+Reader.png',
    description: `AI Image Reader is a powerful tool designed to analyze and interpret images using advanced artificial intelligence. Whether you're a professional looking to...`,
    author: 'Will Frazier',
  },
  {
    title: 'Web Search',
    link: 'https://intercom.help/enhanced-ai/en/articles/9213409-web-search',
    previewUrl:
      'https://downloads.intercomcdn.com/i/o/1024766167/55a259434c3eccfd190ec6ae/Web+Search.png',
    description: `How to Use the Web Search Tool: A Comprehensive Guide`,
    author: 'Will Frazier',
  },
];
