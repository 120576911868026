import KitModal from '../../UI/KitModal';
import { Box, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import OpenAi from '../OpenAi';
import Blip2 from '../Blip2';
import Gemini from '../../AiTextGenerator/Gemini';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '680px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      showCloseButton={false}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant="h6">Settings</Typography>
        <Box mt={'16px'} p={'8px'} bgcolor={'#EAECF0'} borderRadius={'4px'}>
          <Box>
            <FormLabel
              sx={{
                mb: '4px',
              }}
            >
              System
            </FormLabel>

            <Select
              className="nodrag"
              fullWidth
              value={formData.model}
              size="small"
              onChange={onChange}
              name="model"
            >
              <MenuItem value="gpt-4o">Open AI</MenuItem>
              <MenuItem value="gemini">Gemini</MenuItem>
              <MenuItem value="blip2">Blip 2</MenuItem>
              <MenuItem value="anthropic">Anthropic</MenuItem>
              <MenuItem value="ideogram">Ideogram</MenuItem>
            </Select>
          </Box>

          {formData.model === 'gpt-4o' && (
            <OpenAi formData={formData} onChange={onChange} />
          )}

          {formData.model === 'blip2' && (
            <Blip2 formData={formData} onChange={onChange} />
          )}
          {formData.model === 'gemini' && (
            <Gemini imageSystem={true} watch={formData} onChange={onChange} />
          )}
          {formData.model === 'ideogram' && <></>}
          {/* {formData.model === 'anthropic' && (
            <Anthropic formData={formData} onChange={onChange} />
          )} */}
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
