import ReactFlow, { ControlButton, Controls, Panel } from 'reactflow';
import { useAuthStore } from '../../../store/storeAuth';
import { formNodeTypes } from '../../../util/nodeTypes';
import HelperLinesRenderer from './HelperLines';
import { PaintBrush } from '@phosphor-icons/react';
import { useState } from 'react';
import FormStyleSettings from '../../../components/Forms/FormStyleSettings';
import { Button } from '@mui/material';
import useCopyPaste from '../useCopyPaste';

const FormBuilderFlow = ({
  nodes,
  onNodesChange,
  onDragOver,
  onDrop,
  setReactFlowInstance,
  helperLineHorizontal,
  helperLineVertical,
  form,
  setForm,
}: any) => {
  const { user, setAuthUser } = useAuthStore(state => state);
  const [formStyle, setFormStyle] = useState({
    backgroundColor: form?.formStyle?.backgroundColor || 'rgb(231, 240, 255)',
    backgroundImage: form?.formStyle?.backgroundImage || '',
    horizontalSettings: {
      enabled: form?.formStyle?.horizontalSettings?.enabled ?? true,
      gap: form?.formStyle?.horizontalSettings?.gap || 16,
    },
    verticalSettings: {
      enabled: form?.formStyle?.verticalSettings?.enabled ?? true,
      gap: form?.formStyle?.verticalSettings?.gap || 16,
    },
  });

  const [colorModalOpen, setColorModalOpen] = useState(false);

  const { cut, copy, paste, bufferedNodes } = useCopyPaste();

  const canCopy = nodes.some(({ selected }: any) => selected);
  const canPaste = bufferedNodes.length > 0;

  const theme = user?.theme;
  return (
    <ReactFlow
      style={{
        background: theme === 'dark' ? '#475467' : formStyle?.backgroundColor,
        borderRadius: '8px',
      }}
      // className={theme === 'dark' ? 'dark-theme' : 'light-theme'}
      nodes={nodes}
      onNodesChange={onNodesChange}
      snapToGrid
      nodeTypes={formNodeTypes as any}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onInit={setReactFlowInstance}
      proOptions={{ account: 'paid-pro', hideAttribution: true }}
      fitView //   edgeTypes={edgeTypes as any}Learn more about JS/TS refactorings
      maxZoom={1}
      minZoom={0.3}
      // onPointerMove={onMouseMove}
      // panOnScroll={true}
      // panOnScrollMode={PanOnScrollMode.Vertical}
      // panOnDrag={false}

      // background fixed
    >
      <HelperLinesRenderer
        horizontal={helperLineHorizontal}
        vertical={helperLineVertical}
      />

      {/* <Panel
        style={{
          display: 'flex',
        }}
        position="top-left"
      >
        <Button onClick={() => cut()} disabled={!canCopy}>
          cut
        </Button>
        <Button onClick={() => copy()} disabled={!canCopy}>
          copy
        </Button>
        <Button onClick={() => paste({ x: 0, y: 0 })} disabled={!canPaste}>
          paste
        </Button>
      </Panel> */}
      <Controls
        position="top-right"
        showFitView={false}
        showInteractive={false}
        showZoom={false}
        style={{
          boxShadow: 'none',
          // size: '40px',
        }}
      >
        <ControlButton
          onClick={() => {
            setColorModalOpen(true);
          }}
        >
          <PaintBrush style={{}} size={24} />
        </ControlButton>
      </Controls>
      <FormStyleSettings
        colorModalOpen={colorModalOpen}
        setColorModalOpen={setColorModalOpen}
        formStyle={formStyle}
        setFormStyle={setFormStyle}
        setAuthUser={setAuthUser}
        form={form}
        setForm={setForm}
        isForm={true}
      />
    </ReactFlow>
  );
};

export default FormBuilderFlow;
