import React, { useEffect } from 'react';
import { useAuthStore } from '../../../store/storeAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageService } from '../../../service/LocalStorageService';
import { base64Decode } from '../../../helpers/decodeToken';
import { CompanyService } from '../../../service/CompanyService';
import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';
import { getAPIErrorMessage } from '../../../helpers/helpers';

const CompanyAccept = () => {
  const { user: authUser, setAuthUser, setAccessToken } = useAuthStore(
    state => state,
  );
  const location = useLocation();
  const { setNotification } = useNotificationStore(state => state);
  const navigate = useNavigate();
  const token = LocalStorageService.getItem('accessToken');

  const acceptCompanyInvite = async (
    token: string,
    companyId: string,
    email: string,
  ) => {
    try {
      if (email !== authUser?.email) {
        return setNotification({
          type: NotificationType.Error,
          message: 'Email is not correct',
        });
      }
      await CompanyService.acceptCompanyInvite(token);

      setAuthUser({
        ...authUser!,
        selectedCompanyId: companyId,
      });
    } catch (err) {
      let error = err as any;
      if (error.statusCode === 404) {
        setNotification({
          type: NotificationType.Success,
          message: 'You have accepted this invite.',
        });
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
    navigate('/', { replace: true, state: {} });
  };

  useEffect(() => {
    const urlParamToken = new URLSearchParams(location.search).get('token');

    if (urlParamToken && token && authUser?.email) {
      try {
        const decoded = base64Decode(urlParamToken);

        const lowerCaseEmail = decoded.email?.toLowerCase();
        const lowerCaseAuthUserEmail = authUser?.email?.toLowerCase();

        if (lowerCaseEmail !== lowerCaseAuthUserEmail) {
          setAccessToken(null);
          LocalStorageService.removeItem('accessToken');
          setAuthUser(null);
          navigate('/company-user-login?token=' + urlParamToken, {
            replace: true,
            state: location.state,
          });
        } else {
          acceptCompanyInvite(
            urlParamToken,
            decoded.companyId,
            decoded.email?.toLowerCase(),
          );
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else if (!token && urlParamToken) {
      navigate('/company-user-login?token=' + urlParamToken, {
        replace: true,
        state: location.state,
      });
    }
  }, [authUser, location, token]);

  return <></>;
};

export default CompanyAccept;
