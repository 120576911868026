import { memo, useEffect, useRef, useState } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';

import { SerpService } from '../../service/SerpService';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { useNotificationStore } from '../../store/storeNotifications';
import { Box, MenuItem, Select } from '@mui/material';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { checkExecuteTrigger } from '../../util/checkExecute';
import WebTrendsForm from '../WebTrends/WebTrendsForm';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import TagInput from '../Test/TagInput';
import Label from '../UI/Label/Label';
import { Gear } from '@phosphor-icons/react';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import SettingsModal from '../WebTrends/SettingsModal/SettingsModal';
import WebTrendsNowForm from '../WebTrends/WebTrendsNowForm';
import { isActiveEdge } from '../../util/findActiveHandle';
import InputHandle from '../UI/InputHandle/InputHandle';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { useObserveElementWidth } from '../../util/useObserveElement';

const searchEngines = [
  { value: 'google', label: 'Google' },
  { value: 'bing', label: 'Bing', disabled: true },
  { value: 'yahoo', label: 'Yahoo', disabled: true },
  { value: 'duckduckgo', label: 'DuckDuckGo', disabled: true },
];

export interface WebTrendsFrom {
  engine: string;
  query: string;
  geo: string;
  region: string;
  data_type: string;
  tz: number;
  cat: number;
  group: string;
  date: string;
  output: string;
  text: string;
  type: string;
  frequency: string;
  hl: string;
  catTrends: string;
  dateTrendsNow: string;
}

function WebTrends({ id, data, isConnectable, type }: NodeProps) {
  const setNotification = useNotificationStore(state => state.setNotification);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [formData, setFormData] = useState<WebTrendsFrom>({
    engine: 'google',
    query: '',
    type: 'trends',
    geo: 'US',
    region: '',
    data_type: 'TIMESERIES',
    tz: 420,
    cat: 0,
    catTrends: 'all',
    group: 'Web Search',
    date: 'now 1-H',
    output: 'json',
    text: '',
    frequency: 'daily',
    hl: 'en',
    dateTrendsNow: new Date().toISOString().split('T')[0],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });

  const edges = useEdges();

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();

  const {
    schema,
    setSocketNodeResponse,
    socketNodeResponse,
    flowId,
    setSaving,
    setSchema,
  } = useFlowsStore(state => state);

  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);

  const handleInputChange = (e: {
    target: { name: any; value: any; checked?: any };
  }) => {
    const { name, value } = e.target;

    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    setWhoIsChanging({
      name,
      value,
    });

    setIsChangingDirectly(true);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      let query = formData.query;

      if (data?.queryData?.length) {
        query = data?.queryData?.map((item: any) => item.text).join(' \n');
      }

      if (formData.type === 'trends') {
        const newFormData: any = {
          query: replacePlaceholders(query, schema) || undefined,
          // geo: formData.geo || undefined,
          geo: undefined,
          region: formData.region || undefined,
          data_type: formData.data_type || undefined,
          tz: formData.tz ? +formData.tz : undefined,
          cat: formData.cat ? +formData.cat : undefined,
          group: formData.group?.includes('Search')
            ? undefined
            : formData.group || undefined,
          date: formData.date || undefined,
          // csv: true,
          output: formData.output || undefined,
          flowId,
        };

        Object.keys(newFormData).forEach(
          key => newFormData[key] === undefined && delete newFormData[key],
        );

        const response = await SerpService.trends(newFormData);
      }

      if (formData.type === 'trends-now') {
        // Parameter defines the date of the results you want to retrieve. It defaults to the current day. The date format is yyyymmdd (e.g., 20231015).

        const convDate = (date: string) => {
          const dateArray = date.split('-');
          const year = dateArray[0];
          const month = dateArray[1];
          const day = dateArray[2];
          return `${year}${month}${day}`;
        };
        const response = await SerpService.trendsNow({
          frequency: formData.frequency,
          geo: formData.geo === 'no-value' ? null : formData.geo,
          hl: formData.hl,
          date:
            formData.frequency === 'daily'
              ? convDate(formData.dateTrendsNow)
              : null,
          cat: formData.frequency === 'daily' ? null : formData.catTrends,
          output: formData.output,
          flowId,
        });
      }

      setSocketNodeResponse(id, null);
      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
      setIsLoading(false);
    } finally {
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        setSaving,
        objectData: {
          text: data.text,
        },
        setSchema,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
      setIsLoading(false);
      setSocketNodeResponse(null, null);
    }
  }, [socketNodeResponse?.nodeId, socketNodeResponse?.finished, data.text]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setFormData({
          engine: data.engine ?? formData.engine,
          query: data.query ?? formData.query,
          geo: data.geo ?? formData.geo,
          region: data.region ?? formData.region,
          data_type: data.data_type ?? formData.data_type,
          tz: data.tz ?? formData.tz,
          cat: data.cat ?? formData.cat,
          group: data.group ?? formData.group,
          date: data.date ?? formData.date,
          output: data.output ?? formData.output,
          text: data.text ?? formData.text,
          type: data.type ?? formData.type,
          frequency: data.frequency ?? formData.frequency,
          hl: data.hl ?? formData.hl,
          catTrends: data.catTrends ?? formData.catTrends,
          dateTrendsNow: data.dateTrendsNow ?? formData.dateTrendsNow,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
        setIsChangingDirectly(false);
      }
    },
    [isChangingDirectly, whoIsChanging],
    1500,
  );

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };
  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          onChangePlacement={onChangePlacement}
          btnText="Run Prompt"
          onSubmit={onSubmit}
          isLoading={isLoading}
          handleRun={handleRun}
          data={data}
          id={id}
        >
          {!data?.collapsed && (
            <Box display={'flex'}>
              <div>
                <Box
                  p={'16px'}
                  borderRight={`1px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`}
                  ref={inputRef}
                >
                  <Box
                    mb={'8px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Label
                      sx={{
                        mb: '0',
                      }}
                      isAdvanced
                      labelName="System"
                    />

                    <IconButtonTooltip
                      title={'Trends Settings'}
                      onClick={() => setSettingsModalOpen(true)}
                    >
                      <Gear size={15} color="#98A2B3" />
                    </IconButtonTooltip>
                  </Box>
                  <Select
                    value={formData.type}
                    onChange={handleInputChange}
                    name="type"
                    className="nodrag"
                    size="small"
                    sx={{
                      '& .MuiList-root': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      },
                      '& .MuiSvgIcon-root': {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="trends"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      Trends
                    </MenuItem>
                    <MenuItem
                      value="trends-now"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      Trends Now
                    </MenuItem>
                  </Select>

                  <Label labelName="Search Engine" isAdvanced />
                  <Select
                    labelId="engine"
                    label="Search Engine"
                    className="nodrag"
                    value={formData.engine}
                    onChange={handleInputChange}
                    size="small"
                    sx={{
                      '& .MuiList-root': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      },
                      '& .MuiSvgIcon-root': {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      mb: '16px',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                  >
                    {searchEngines.map((engine, index) => (
                      <MenuItem
                        key={index}
                        disabled={engine.disabled}
                        value={engine.value}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        {engine.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formData.type === 'trends' ? (
                    <TagInput
                      name="query"
                      placeholder="Enter query"
                      labelName="Query"
                      isCollapsed={true}
                      dataConnected={data.queryData || []}
                      handleId={'query'}
                      nodeId={id}
                      isAdvanced={true}
                      advancedCollapsed={true}
                      removeIcons
                      findColor={findColor}
                      isActive={isActiveEdge(edges, id, 'query', 'target')}
                      onChange={handleInputChange}
                      onChangePlacement={onChangePlacement}
                      placement={data?.placement}
                      value={formData.query}
                      nodeLabel={data?.label}
                    />
                  ) : (
                    <Box position={'absolute'}>
                      <InputHandle
                        activeColor={findColor?.color}
                        handleId={'query'}
                        isActive={isActiveEdge(edges, id, 'query', 'target')}
                        type={'target'}
                        left={-26}
                        top={'10px'}
                      />
                    </Box>
                  )}

                  {formData.type === 'trends' ? (
                    <WebTrendsForm
                      formData={formData}
                      handleInputChange={handleInputChange}
                      // onSubmit={onSubmit}
                      // data={data}
                    />
                  ) : (
                    <WebTrendsNowForm
                      formData={formData}
                      handleInputChange={handleInputChange}
                    />
                  )}
                </Box>
              </div>
              <Box p={'16px 24px 16px 16px'}>
                <OutputTextarea
                  // previewResponses={previewResponses}
                  maxOutputHeight={maxOutputHeight}
                  previewIndex={data.previewIndex}
                  value={data.text}
                  activeHandle={isActiveEdge(
                    edges,
                    id,
                    'web-trends-source',
                    'source',
                  )}
                  placement={data?.placement}
                  labelName={'Output'}
                  // onPreview={onPreview}
                  handleId={'web-trends-source'}
                  findColor={findColor}
                  nodeLabel={data?.label}
                  onChangePlacement={onChangePlacement}
                />
              </Box>
            </Box>
          )}

          <SettingsModal
            formData={formData}
            onChange={handleInputChange}
            onHide={() => setSettingsModalOpen(false)}
            show={settingsModalOpen}
          />
        </Node>
      </Box>
    </>
  );
}

export default memo(WebTrends);
