import React, { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { ReportService } from '../../service/ReportService';
import { useAuthStore } from '../../store/storeAuth';
import ReactApexChart from 'react-apexcharts';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { FilterBy, ViewType } from './TokenUsage';
import { TokenUsage } from '../../util/Types/TokenUsage';
import { generateData, generateLabels, getChartOptions } from './helpers';
import { Box } from '@mui/material';

interface Props {
  selectedDateRange: Range;
  queryString: string;
  differentYearsSelected: number[];
  filteredBy: FilterBy;
  viewType: ViewType;
}

const ImagesUsage: React.FC<Props> = ({
  selectedDateRange,
  queryString,
  differentYearsSelected,
  filteredBy,
  viewType,
}) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const authUser = useAuthStore(state => state.user);

  const [imagesUsage, setImagesUsage] = useState<TokenUsage[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getImagesUsage = async () => {
      setLoaded(false);
      try {
        if (differentYearsSelected.length > 0) {
          let firstQueryString =
            viewType === ViewType.Company
              ? `companyId=${authUser?.selectedCompanyId}&year=${differentYearsSelected[0]}&months=1,2,3,4,5,6,7,8,9,10,11,12`
              : `year=${differentYearsSelected[0]}&months=1,2,3,4,5,6,7,8,9,10,11,12`;
          let secondQueryString =
            viewType === ViewType.Company
              ? `companyId=${authUser?.selectedCompanyId}&year=${differentYearsSelected[1]}&months=1,2,3,4,5,6,7,8,9,10,11,12`
              : `year=${differentYearsSelected[1]}&months=1,2,3,4,5,6,7,8,9,10,11,12`;
          const [firstResult, secondResult] = await Promise.all([
            ReportService.getImagesUsage(authUser!.id, firstQueryString),
            ReportService.getImagesUsage(authUser!.id, secondQueryString),
          ]);

          setImagesUsage([...firstResult.data, ...secondResult.data]);
        } else {
          const response = await ReportService.getImagesUsage(
            authUser!.id,
            queryString,
          );
          setImagesUsage(response.data);
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }

      setLoaded(true);
    };

    getImagesUsage();
  }, [authUser, queryString, differentYearsSelected, viewType]);

  const { xAxisLabels, selectBy } = generateLabels(
    filteredBy,
    selectedDateRange,
    differentYearsSelected,
  );

  const series = [
    {
      name: 'Images',
      data: generateData(selectBy, xAxisLabels, imagesUsage, 'Image').map(
        el => el.usedTokens,
      ),
    },
  ];

  return loaded ? (
    <Box
      sx={{
        border: '1px solid rgba(234, 236, 240, 1)',
        padding: '16px',
        borderRadius: '4px',
        marginTop: '10px',
      }}
    >
      <ReactApexChart
        options={getChartOptions(
          xAxisLabels,
          ['#A969FD'],
          generateData(selectBy, xAxisLabels, imagesUsage, 'Image').map(
            el => el.price,
          ),
        )}
        series={series}
        type="bar"
        height={350}
      />
    </Box>
  ) : null;
};

export default ImagesUsage;
