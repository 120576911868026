import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class YoutubeService extends ApiService {
  static pathName = '/youtube-transcript';

  static response = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, body);
  };
}
