import { Box } from '@mui/material';
import { ArrowRight, Plus } from '@phosphor-icons/react';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { useAuthStore } from '../../../store/storeAuth';
type OutputHandleProps = {
  isActive: boolean;
  colorActive?: { background: string; color: string };
  handleId?: string;
  style?: React.CSSProperties;
};

const OutputHandle = ({
  isActive,
  colorActive,
  handleId = 'outputFlow',
  style,
}: OutputHandleProps) => {
  const { user } = useAuthStore(state => state);

  return (
    <Handle
      type='source'
      position={Position.Right}
      id={handleId}
      //   isConnectable={isConnectable}
      style={{
        background: isActive ? colorActive?.background : (user?.theme === 'dark' ? '#1D2939' : '#fff'),
        width: isActive ? '32px' : '29px',
        height: isActive ? '32px' : '29px',
        border: isActive ? '0px ' : '2px solid #667085',
        borderColor: isActive ? 'none' : '#667085',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        right: '-16px',
        ...style,
      }}
    >
      <Plus
        pointerEvents={'none'}
        color={isActive ? '#fff' : '#667085'}
        size={14}
        weight='bold'
      />
    </Handle>
  );
};

export default OutputHandle;
