import { replaceNtoBr } from './markdown';

export const modifyTheStringifiedData = (stringifiedData: any) => {
  const dataTest = JSON.stringify(stringifiedData, null, 2);

  const textResult = dataTest
    .replace(/\\n/g, ' ')
    .replace(/[\{\}\[\]"',]/g, '') // Remove curly braces, square brackets, quotes, and commas
    .split('\n') // Split the string into lines
    .map(line => line.trim() + '\n') // Add a line break at the end of each line
    .join('');

  const cleanedData = replaceNtoBr(textResult);
  return cleanedData;
};
