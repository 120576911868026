import { UserPlan } from './Types/User';

export const getNextPlan = (currentPlan: UserPlan | null) => {
  switch (currentPlan) {
    case 'Starter':
      return 'Advanced';
    case 'Advanced':
      return 'Professional';
    case 'Professional':
      return 'Organization';
    case 'Organization':
      return 'Enterprise';
    default:
      return null;
  }
};

export const getNextPlanAtLeastProfessional = (
  currentPlan: UserPlan | null,
) => {
  switch (currentPlan) {
    case 'Intro':
    case 'Starter':
    case 'Advanced':
      return 'Professional';
    case 'Professional':
      return 'Organization';
    case 'Organization':
      return 'Enterprise';
    default:
      return null;
  }
};

export const getNextPlanToAdvanced = (currentPlan: UserPlan | null) => {
  switch (currentPlan) {
    case 'Intro':
    case 'Starter':
      return 'Advanced';
    case 'Advanced':
    case 'Professional':
    case 'Organization':
    case 'Enterprise':
      return currentPlan;
    default:
      return null;
  }
};

export const getNextPlanUsers = (currentPlan: UserPlan | null) => {
  switch (currentPlan) {
    case 'Intro':
    case 'Starter':
    case 'Advanced':
      return 'Professional';
    case 'Professional':
      return 'Organization';
    case 'Organization':
      return 'Enterprise';
    case 'Enterprise':
      return currentPlan;
    default:
      return null;
  }
};

export const getMaxUsers = (plan: UserPlan | null) => {
  switch (plan) {
    case 'Intro':
    case 'Starter':
    case 'Advanced':
      return 1;
    case 'Professional':
      return 3;
    case 'Organization':
      return 15;
    default:
      return null;
  }
};

export const getMaxSpaces = (plan: UserPlan | null) => {
  switch (plan) {
    case 'Intro':
      return null;
    case 'Starter':
      return null;
    case 'Advanced':
      return null;
    case 'Professional':
      return 3;
    case 'Organization':
      return 'Unlimited';
    default:
      return null;
  }
};

export const getSpacePlan = (plan: UserPlan | null) => {
  switch (plan) {
    case 'Intro':
      return 'Professional';
    case 'Starter':
      return 'Professional';
    case 'Advanced':
      return 'Professional';
    case 'Professional':
      return 'Organization';
    case 'Organization':
      return 'Organization';
    default:
      return null;
  }
};
