import React, { useState } from 'react';
import SpacesModal from './SpacesModal';
import { Button, TextField } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { useAuthStore } from '../../store/storeAuth';

interface Props {
  onClose: () => void;
  getCompanySpaces: () => Promise<void>;
}

const CreateSpaceModal: React.FC<Props> = ({ onClose, getCompanySpaces }) => {
  const authUser = useAuthStore(state => state.user);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [spaceName, setSpaceName] = useState('');

  const saveHandler = async () => {
    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      await CompanySpacesService.createCompanySpace({
        company: { id: authUser.selectedCompanyId },
        name: spaceName,
      });
      await getCompanySpaces();

      onClose();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  return (
    <SpacesModal
      modalTitle="Create a New Space"
      onClose={onClose}
      footer={
        <>
          <Button
            autoFocus
            fullWidth
            variant="contained"
            sx={{ background: 'rgba(54, 80, 222, 1)' }}
            onClick={saveHandler}
          >
            Save
          </Button>
        </>
      }
    >
      <TextField
        label="Space Name"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 20 }}
        onChange={e => setSpaceName(e.target.value)}
        value={spaceName}
        placeholder="Enter Space Name"
      />
    </SpacesModal>
  );
};

export default CreateSpaceModal;
