import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import KitModal from '../UI/KitModal';
import { useNotificationStore } from '../../store/storeNotifications';
import { FormsService } from '../../service/FormService';
import { FileService } from '../../service/FileService';
import { UploadService } from '../../service/UploadService';
import LoadingSpinner from '../UI/LoadingSpinner';
import { UploadSimple } from '@phosphor-icons/react';
import { useAuthStore } from '../../store/storeAuth';

const FormStyleSettings = ({
  colorModalOpen,
  setColorModalOpen,
  formStyle,
  setFormStyle,
  form,
  setForm,
  setAuthUser,
}: any) => {
  const [oldStyle, setOldStyle] = useState(formStyle);
  const setNotification = useNotificationStore(state => state.setNotification);
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const user = useAuthStore(state => state.user);
  const [horizontalSettings, setHorizontalSettings] = useState({
    enabled: formStyle?.horizontalSettings?.enabled || false,
    gap: formStyle?.horizontalSettings?.gap || 16,
  });

  const [verticalSettings, setVerticalSettings] = useState({
    enabled: formStyle?.verticalSettings?.enabled || false,
    gap: formStyle?.verticalSettings?.gap || 16,
  });

  useEffect(() => {
    if (colorModalOpen) {
      setOldStyle(formStyle);
      setHorizontalSettings({
        enabled: formStyle?.horizontalSettings?.enabled || false,
        gap: formStyle?.horizontalSettings?.gap || 16,
      });
      setVerticalSettings({
        enabled: formStyle?.verticalSettings?.enabled || false,
        gap: formStyle?.verticalSettings?.gap || 16,
      });
    }
  }, [colorModalOpen, formStyle]);

  const onSave = async () => {
    try {
      await FormsService.update(form?.id, {
        formStyle: {
          backgroundColor: oldStyle?.backgroundColor,
          backgroundImage: oldStyle?.backgroundImage,
          horizontalSettings: {
            enabled: horizontalSettings.enabled,
            gap: horizontalSettings.gap,
          },
          verticalSettings: {
            enabled: verticalSettings.enabled,
            gap: verticalSettings.gap,
          },
        },
      });

      setFormStyle({
        backgroundColor: oldStyle?.backgroundColor,
        backgroundImage: oldStyle?.backgroundImage,
        horizontalSettings: {
          enabled: horizontalSettings.enabled,
          gap: horizontalSettings.gap,
        },
        verticalSettings: {
          enabled: verticalSettings.enabled,
          gap: verticalSettings.gap,
        },
      });

      setForm({
        ...form,
        formStyle: {
          backgroundColor: oldStyle?.backgroundColor,
          backgroundImage: oldStyle?.backgroundImage,
          horizontalSettings: {
            enabled: horizontalSettings.enabled,
            gap: horizontalSettings.gap,
          },
          verticalSettings: {
            enabled: verticalSettings.enabled,
            gap: verticalSettings.gap,
          },
        },
      });

      setColorModalOpen(false);
    } catch (error) {
      console.log(error);

      setNotification({
        type: 'error',
        message: 'Failed to update background color',
      });
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    setUploadingLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file!);

      const res = await UploadService.uploadFile(formData);

      await FileService.createFormFileToUpload({
        name: res?.data.name,
        src: res.data.src,
        formsId: form?.id!,
      });

      setOldStyle({
        ...oldStyle,
        backgroundImage: res.data.src,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setUploadingLoading(false);
    }
  };

  const onChangeHorizontalSettings = (e: any) => {
    const value = e.target.name === 'gap' ? e.target.value : e.target.checked;

    setHorizontalSettings({
      ...horizontalSettings,
      [e.target.name]: value,
    });
  };
  const onChangeVerticalSettings = (e: any) => {
    const value = e.target.name === 'gap' ? e.target.value : e.target.checked;
    setVerticalSettings({
      ...verticalSettings,
      [e.target.name]: value,
    });
  };

  const onChangeBackgroundColor = (e: any) => {
    setOldStyle({
      ...oldStyle,
      backgroundColor: e.target.value,
    });
  };

  const onCancel = () => {
    setColorModalOpen(false);
  };

  return (
    <div>
      <KitModal
        show={colorModalOpen}
        fullWidth
        onHide={onCancel}
        title={`Form Style Settings`}
        footer={
          <>
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          </>
        }
      >
        <div>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <FormLabel
                sx={{
                  mb: '4px',
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
                htmlFor="horizontalSettings"
              >
                Horizontal
              </FormLabel>
              <Switch
                name="enabled"
                checked={horizontalSettings.enabled}
                onChange={onChangeHorizontalSettings}
              />
            </Box>
            {horizontalSettings.enabled && (
              <TextField
                type="number"
                name="gap"
                value={horizontalSettings.gap}
                onChange={onChangeHorizontalSettings}
                label="Gap"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 100,
                  },
                }}
                fullWidth
                style={{
                  maxWidth: '15%',
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              mt: '14px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <FormLabel
                sx={{
                  mb: '4px',
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
                htmlFor="horizontalSettings"
              >
                Vertical
              </FormLabel>
              <Switch
                name="enabled"
                checked={verticalSettings.enabled}
                onChange={onChangeVerticalSettings}
              />
            </Box>
            {verticalSettings.enabled && (
              <TextField
                type="number"
                name="gap"
                value={verticalSettings.gap}
                onChange={onChangeVerticalSettings}
                label="Gap"
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 100,
                  },
                }}
                fullWidth
                style={{
                  maxWidth: '15%',
                }}
              />
            )}
          </Box>

          <TextField
            type="color"
            value={oldStyle?.backgroundColor}
            onChange={onChangeBackgroundColor}
            defaultValue={formStyle?.backgroundColor}
            label="Background Color"
            fullWidth
            style={{
              maxWidth: '15%',
            }}
          />
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              gap: '10px',
            }}
          >
            {uploadingLoading && (
              <Box>
                <LoadingSpinner height="200px" />
              </Box>
            )}

            {!oldStyle?.backgroundImage && (
              <FormLabel
                htmlFor={'image-upload-file'}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  p: '24px',
                  border: '1px solid #EAECF0',
                  flexDirection: 'column',
                }}
              >
                <UploadSimple
                  size={'32px'}
                  // color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                />

                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#98A2B3',
                    fontWeight: 600,
                  }}
                >
                  {'Click here to upload an Image.'}
                </Typography>
              </FormLabel>
            )}

            {oldStyle?.backgroundImage && (
              <img
                style={{
                  maxWidth: '100%',
                  width: '100%',
                }}
                src={oldStyle?.backgroundImage}
                alt="test"
              />
            )}

            <input
              id={'image-upload-file'}
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
          {oldStyle?.backgroundImage && (
            <Box display={'flex'} justifyContent={'end'}>
              <Button
                onClick={() => {
                  setOldStyle({
                    ...oldStyle,
                    backgroundImage: '',
                  });
                }}
              >
                Delete
              </Button>

              <Button
                onClick={() => {
                  document.getElementById('image-upload-file')?.click();
                }}
                variant="outlined"
              >
                Replace Image
              </Button>
            </Box>
          )}
        </div>
      </KitModal>
    </div>
  );
};

export default FormStyleSettings;
