import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as AccessTimedOutIcon } from '../../assets/icons/AccessTimedOut.svg';
import { ReactComponent as HubSpotIcon } from '../../assets/icons/HubSpot.svg';
import { ReactComponent as Connected } from '../../assets/icons/connected.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash.svg';
import { ReactComponent as MoreIcon } from '../../assets/icons/more.svg';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { useStoreHubSpotData } from '../../store/storeHubSpotData';
import LogoutModal from '../Integrations/LogoutModal';
import { HubSpotService } from '../../service/HubSpotService';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { UserService } from '../../service/UserService';

enum ConnectionState {
  NotConnected = 'Not Connected',
  JustConnected = 'Just Connected',
  Connected = 'Connected',
  AccessTimedOut = 'Access Timed Out',
}

const HubSpotIntegration = () => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const { isHubSpotConnected, setIsHubSpotConnected } = useStoreHubSpotData();
  const [installUrl, setInstallUrl] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState('');
  const [showLogoutBox, setShowLogoutBox] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.NotConnected,
  );

  const loginErrorHandler = (err: any) => {
    setNotification({
      type: NotificationType.Error,
      message: getAPIErrorMessage(err as any),
    });
  };

  const exchangeCodeForToken = async (code: string) => {
    try {
      await HubSpotService.getHubSpotAccessToken(code);
      setIsHubSpotConnected(true);
      setConnectionState(ConnectionState.JustConnected);
    } catch (err: any) {
      loginErrorHandler(err);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      const handleCode = (event: any) => {
        if (event.data.type === 'integration_completed') {
          exchangeCodeForToken(event.data.code);
          setIsPopupOpen(false);

          window.removeEventListener('message', handleCode);
        }
      };

      window.addEventListener('message', handleCode);
    }
  }, [isPopupOpen]);

  const connectHandler = () => {
    window.open(installUrl, 'hubSpotpopup', 'popup');
    setIsPopupOpen(true);
  };

  useEffect(() => {
    const getConnectedEmailHandler = async () => {
      if (
        connectionState === ConnectionState.Connected ||
        connectionState === ConnectionState.JustConnected
      ) {
        try {
          const connectedEmail = await HubSpotService.getHubSpotUserInfo();
          setConnectedEmail(connectedEmail.data.user);
        } catch (error) {
          setNotification({
            type: NotificationType.Error,
            message:
              'Something went wrong fetching connected email from HubSpot',
          });
        }
      } else {
        try {
          const response = await HubSpotService.getHubSpotInstallUrl();
          setInstallUrl(response.installUrl);
        } catch (error) {
          setNotification({
            type: NotificationType.Error,
            message: 'Something went wrong fetching HubSpot APP',
          });
        }
      }
    };

    getConnectedEmailHandler();
  }, [connectionState]);

  useEffect(() => {
    const validateExistingHubSpotAccessToken = async () => {
      if (isHubSpotConnected) {
        setConnectionState(ConnectionState.Connected);
      }
    };

    validateExistingHubSpotAccessToken();
  }, [isHubSpotConnected]);

  const logoutHandler = async () => {
    setConnectionState(ConnectionState.NotConnected);
    setIsHubSpotConnected(false);
    setShowLogoutBox(false);
    setShowLogoutModal(false);

    await UserService.updateUser({
      hubspotAccessToken: null,
      hubspotRefreshToken: null,
    });
  };

  const renderLogoutBox = () => {
    if (showLogoutBox) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            right: '-105px',
            boxShadow: '2px 4px 35px 0px rgba(0, 0, 0, 0.25)',
            background: 'rgba(255, 255, 255, 1)',
            padding: '20px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            cursor: 'pointer',
          }}
          onClick={() => setShowLogoutModal(true)}
        >
          <TrashIcon />
          <Typography>Log out</Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  const renderConnectionState = () => {
    if (connectionState === ConnectionState.NotConnected) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <HubSpotIcon />
            <Typography
              sx={{
                color: 'rgba(71, 84, 103, 1)',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '19.2px',
                letterSpacing: '0.15px',
              }}
            >
              HubSpot Account
            </Typography>
          </Box>

          <Typography
            sx={{
              color: 'rgba(102, 112, 133, 1)',
              fontSize: '12px',
              lineHeight: '19.2px',
              letterSpacing: '0.15px',
              marginTop: '20px',
            }}
          >
            Easily sync HubSpot in FluxPrompt
          </Typography>

          {installUrl && (
            <Button
              variant="text"
              sx={{
                color: 'rgba(54, 80, 222, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: 400,
                marginTop: '10px',
              }}
              onClick={() => connectHandler()}
            >
              Connect
            </Button>
          )}
        </Box>
      );
    }

    if (connectionState === ConnectionState.JustConnected) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <HubSpotIcon />

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    color: 'rgba(71, 84, 103, 1)',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                  }}
                >
                  HubSpot Account
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                    color: 'rgba(102, 112, 133, 1)',
                  }}
                >
                  {connectedEmail}
                </Typography>
              </Box>
              <Box
                sx={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setShowLogoutBox(show => !show)}
              >
                <MoreIcon />
                {renderLogoutBox()}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Connected />
              <Typography
                sx={{
                  color: 'rgba(102, 112, 133, 1)',
                  fontSize: '12px',
                  lineHeight: '19.2px',
                  letterSpacing: '0.15px',
                  marginTop: '20px',
                }}
              >
                You are successfully logged in to your HubSpot account.
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }

    if (connectionState === ConnectionState.Connected) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <HubSpotIcon />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    color: 'rgba(71, 84, 103, 1)',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                  }}
                >
                  HubSpot Account
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '19.2px',
                    letterSpacing: '0.15px',
                    color: 'rgba(102, 112, 133, 1)',
                  }}
                >
                  {connectedEmail}
                </Typography>
              </Box>
              <Box
                sx={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setShowLogoutBox(show => !show)}
              >
                <MoreIcon />
                {renderLogoutBox()}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginTop: '5px',
            }}
          >
            <Connected />
            <Typography
              sx={{
                color: 'rgba(102, 112, 133, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                marginTop: '5px',
              }}
            >
              You are currently connected to HubSpot.
            </Typography>
          </Box>
        </Box>
      );
    }

    if (connectionState === ConnectionState.AccessTimedOut) {
      return (
        <Box
          sx={{
            width: 'min-content',
            minWidth: '250px',
            border: '1px solid rgba(196, 207, 255, 1)',
            padding: '24px',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              borderBottom: '.5px solid rgba(234, 236, 240, 1)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <HubSpotIcon />
              <Typography
                sx={{
                  color: 'rgba(71, 84, 103, 1)',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '19.2px',
                  letterSpacing: '0.15px',
                }}
              >
                HubSpot Account
              </Typography>

              <Box
                sx={{ marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => setShowLogoutBox(show => !show)}
              >
                <MoreIcon />
                {renderLogoutBox()}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <AccessTimedOutIcon />
              <Typography
                sx={{
                  color: 'rgba(102, 112, 133, 1)',
                  fontSize: '12px',
                  lineHeight: '19.2px',
                  letterSpacing: '0.15px',
                  marginTop: '20px',
                }}
              >
                Access timed out. Reconnect to continue using HubSpot
                connections in your workflows.
              </Typography>
            </Box>
          </Box>

          {installUrl && (
            <Button
              variant="text"
              sx={{
                color: 'rgba(54, 80, 222, 1)',
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: 400,
                marginTop: '10px',
              }}
              onClick={() => connectHandler()}
            >
              Connect
            </Button>
          )}
        </Box>
      );
    }
  };

  return (
    <>
      {showLogoutModal && (
        <LogoutModal
          onHide={() => setShowLogoutModal(false)}
          logoutHandler={logoutHandler}
        />
      )}

      {renderConnectionState()}
    </>
  );
};

export default HubSpotIntegration;
