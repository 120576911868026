import axios, { AxiosError, AxiosResponse } from 'axios';
import { LocalStorageService } from './LocalStorageService';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // 'http://localhost:3000/'
});

axiosInstance.interceptors.request.use(config => {
  const accessToken = LocalStorageService.getItem('accessToken');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  // Adding the security headers
  config.headers['Strict-Transport-Security'] =
    'max-age=31536000; includeSubDomains';
  config.headers['Content-Security-Policy'] = `default-src 'self'`;
  config.headers['X-Frame-Options'] = 'sameorigin';
  config.headers['X-Content-Type-Options'] = 'nosniff';
  config.headers['Referrer-Policy'] = 'no-referrer-when-downgrade';
  config.headers['Permissions-Policy'] = 'microphone=()';

  return config;
});

export { axiosInstance };

export class ApiService {
  static async get<T>(
    path: string,
    headers?: Record<string, string>,
    signal?: AbortSignal,
    params?: Record<string, string | number>,
    options?: any
  ): Promise<T> {
    try {
      const response = await axiosInstance.get(path, {
        params: params,
        headers: headers,
        signal: signal,
        ...options,
      });

      return ApiService.handleResponse(response);
    } catch (error: any) {
      return ApiService.handleError(error);
    }
  }

  static async post<T>(
    path: string,
    data?: any,
    params?: Record<string, string | number>,
    headers?: Record<string, string>,
    signal?: AbortSignal
  ): Promise<T> {
    try {
      const response = await axiosInstance.post(path, data, {
        params: params,
        headers: headers,
        signal: signal,
      });

      return ApiService.handleResponse(response);
    } catch (error: any) {
      return ApiService.handleError(error);
    }
  }

  static async patch<T>(
    path: string,
    data: any,
    params?: Record<string, string | number>,
    headers?: Record<string, string>,
    signal?: AbortSignal
  ): Promise<T> {
    try {
      const response = await axiosInstance.patch(path, data, {
        params: params,
        headers: headers,
        signal: signal,
      });

      return ApiService.handleResponse(response);
    } catch (error: any) {
      return ApiService.handleError(error);
    }
  }

  static async delete(
    path: string,
    params?: Record<string, string | number>,
    headers?: Record<string, string>,
    signal?: AbortSignal
  ): Promise<Record<string, never>> {
    try {
      const response = await axiosInstance.delete(path, {
        params: params,
        headers: headers,
        signal: signal,
      });

      return ApiService.handleResponse(response);
    } catch (error: any) {
      return ApiService.handleError(error);
    }
  }

  static handleResponse(response: AxiosResponse): any {
    return response.data;
  }

  static handleError(error: AxiosError): never {
    const statusCode: any = error.response?.status;

    if (statusCode === 422) {
      const throwCode422 = {
        statusCode: 422,
        status: 'unprocessable_entity',
        message: 'Validation Failed',
      };
      throw throwCode422;
    } else {
      const responseData: any = error.response?.data;

      const checkIfResponseIsArray = Array.isArray(responseData?.message);

      const throwError = {
        statusCode: statusCode as number,
        status: responseData?.error,
        message: checkIfResponseIsArray
          ? responseData?.message?.join(',')
          : responseData?.message || responseData.data.message,
      };
      throw throwError;
    }
  }
}
