import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image as KonvaImage, Line, Rect } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import KitModal from '../UI/KitModal';
import { Box, Button, Slider, Typography } from '@mui/material';
import { ArrowUUpLeft, Trash } from '@phosphor-icons/react';

interface Props {
  imageUrl: string;
  onHideModal: () => void;
  onSave: (modifiedUrl: string, url: string) => void;
  type: 'using mask' | 'remove objects';
}

interface LineData {
  points: number[];
  brushSize: number;
}

const MaskImageEditModal: React.FC<Props> = (props: Props) => {
  const { onHideModal, onSave, imageUrl, type } = props;
  const [tool, setTool] = useState<'brush' | 'eraser'>('brush');
  const [lines, setLines] = useState<LineData[]>([]);
  const [brushSize, setBrushSize] = useState(5);
  const isDrawing = useRef(false);
  const [image] = useImage(
    `${process.env.REACT_APP_API_URL}/proxy?url=${imageUrl}`,
    'anonymous',
  );

  const stageRef = useRef<any>(null);
  const [undoStack, setUndoStack] = useState<any>([]);
  const [redoStack, setRedoStack] = useState<any>([]);

  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth * 0.9);
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight * 0.9);

  useEffect(() => {
    const handleResize = () => {
      setCanvasWidth(window.innerWidth * 0.9);
      setCanvasHeight(window.innerHeight * 0.9);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseDown = () => {
    isDrawing.current = true;
    setLines(prevLines => [...prevLines, { points: [], brushSize }]);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing.current) return;
    const stage = e.target.getStage();
    if (!stage) return;
    const point = stage.getPointerPosition();
    if (!point) return;

    setLines(prevLines => {
      const lastLine = prevLines[prevLines.length - 1];
      const newPoints = lastLine.points.concat([point.x, point.y]);
      const newLines = prevLines
        .slice(0, -1)
        .concat([{ ...lastLine, points: newPoints }]);
      return newLines;
    });
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
    setUndoStack((prevUndoStack: any) => [
      ...prevUndoStack,
      lines[lines.length - 1],
    ]);
    setRedoStack([]);
  };

  const handleUndo = () => {
    if (lines.length === 0) return;
    const newLines = lines.slice(0, -1);
    const undoneLine = lines[lines.length - 1];
    setLines(newLines);
    setUndoStack((prevUndoStack: any) => prevUndoStack.slice(0, -1));
    setRedoStack((prevRedoStack: any) => [...prevRedoStack, undoneLine]);
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;
    const redoneLine = redoStack[redoStack.length - 1];
    setLines(prevLines => [...prevLines, redoneLine]);
    setRedoStack((prevRedoStack: any) => prevRedoStack.slice(0, -1));
  };

  const handleReset = () => {
    setLines([]);
    setTool('brush');
    setBrushSize(5);
    if (stageRef.current) {
      stageRef.current.find('Line').forEach((line: any) => line.remove());
      stageRef.current.batchDraw();
    }
  };

  const processCanvas = (canvas: any, width: number, height: number) => {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, width, height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      if (r === 0 && g === 0 && b === 0) {
        data[i] = 255;
        data[i + 1] = 255;
        data[i + 2] = 255;
      } else {
        data[i] = 0;
        data[i + 1] = 0;
        data[i + 2] = 0;
      }
    }

    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
  };

  const handleExport = () => {
    const stage = stageRef.current;
    let originalDataUrl = stageRef.current
      ?.getStage()
      .toDataURL({ mimeType: 'image/png', quality: 1 });
    const imageLayer = stage.findOne('#imageLayer');
    const linesLayer = stage.findOne('#linesLayer');

    const konvaImage = imageLayer.findOne('Image');
    konvaImage.cache();
    konvaImage.filters([Konva.Filters.RGB]);
    konvaImage.red(255);
    konvaImage.blue(255);
    konvaImage.green(255);
    konvaImage.getLayer().batchDraw();

    lines.forEach((line, i) => {
      const konvaLine = linesLayer.findOne(`#line-${i}`);
      konvaLine.stroke('black');
    });

    linesLayer.batchDraw();

    const dataURL = stage.toDataURL();
    const img = new Image();
    img.src = dataURL;

    img.onload = () => {
      const hiddenCanvas = document.createElement('canvas');
      hiddenCanvas.width = img.width;
      hiddenCanvas.height = img.height;
      const hiddenCtx = hiddenCanvas.getContext('2d');
      hiddenCtx?.drawImage(img, 0, 0);

      const processedDataURL = processCanvas(
        hiddenCanvas,
        img.width,
        img.height,
      );

      onSave(processedDataURL, originalDataUrl);
    };
  };

  // const handleExport2 = async () => {
  //   // const stage = stageRef.current;
  //   // const layer = stage.findOne('Layer');

  //   if (stageRef.current) {
  //     let dataUrl = stageRef.current
  //       ?.getStage()
  //       .toDataURL({ mimeType: 'image/png', quality: 1 });

  //     // onSave(dataUrl);

  //     // const link2 = document.createElement('a');
  //     // link2.href = dataUrl;
  //     // link2.download = 'drawing.png';
  //     // document.body.appendChild(link2);
  //     // link2.click();
  //     // document.body.removeChild(link2);
  //   }
  // };

  let imageWidth = canvasWidth * 0.85;
  let imageHeight = canvasHeight * 0.76;

  if (image) {
    const aspectRatio = image.width / image.height;

    if (imageWidth / imageHeight > aspectRatio) {
      imageWidth = imageHeight * aspectRatio;
    } else {
      imageHeight = imageWidth / aspectRatio;
    }
  }

  const x = (canvasWidth * 0.85 - imageWidth) / 2;
  const y = (canvasHeight * 0.76 - imageHeight) / 2;

  return (
    <KitModal
      maxWidth={'md'}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '80%',
          maxHeight: '97%',
          width: '90%',
          height: '97%',
          borderRadius: '16px',
        },
      }}
      fullWidth
      onHide={onHideModal}
      show
    >
      <div>
        <Typography
          sx={{ fontSize: '24px', fontWeight: 500, padding: '7px 0' }}
        >
          {type === 'remove objects' ? 'Remove objects' : 'Ai edit using mask'}
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '32px',
            letterSpacing: '0.15',
            padding: '7px 0',
          }}
        >
          {type === 'remove objects'
            ? 'Highlight the objects you want to remove, then let AI fill in the gaps seamlessly.'
            : `Highlight the objects you want to change. Then, type a prompt in the
          node to edit those areas of the image.`}
        </Typography>

        <div>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              margin: '10px 0',
            }}
          >
            <Button
              onClick={handleUndo}
              disabled={lines.length === 0}
              sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <Typography
                sx={{ fontWeight: 500, color: 'rgba(71, 84, 103, 1)' }}
              >
                Undo
              </Typography>
              <ArrowUUpLeft color="rgba(71, 84, 103, 1)" />
            </Button>
            <Button
              onClick={handleRedo}
              disabled={redoStack.length === 0}
              sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <Typography
                sx={{ fontWeight: 500, color: 'rgba(71, 84, 103, 1)' }}
              >
                Redo
              </Typography>
              <ArrowUUpLeft color="rgba(71, 84, 103, 1)" />
            </Button>

            <Button
              onClick={handleReset}
              sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            >
              <Typography
                sx={{ fontWeight: 500, color: 'rgba(71, 84, 103, 1)' }}
              >
                Reset
              </Typography>
              <Trash color="rgba(71, 84, 103, 1)" />
            </Button>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Typography
                sx={{
                  color: 'rgba(71, 84, 103, 1)',
                  fontWeight: 500,
                }}
              >
                Brush Size:
              </Typography>

              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Slider
                  size="small"
                  defaultValue={brushSize}
                  aria-label="Small"
                  onChange={(e: any) => {
                    setBrushSize(e.target.value);
                  }}
                  value={brushSize}
                  valueLabelDisplay="auto"
                  color="primary"
                  sx={{ width: '150px' }}
                />

                <Typography
                  sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontWeight: 500,
                    fontSize: '14px',
                    marginTop: '5px',
                  }}
                >
                  {brushSize} %
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>

        <Stage
          width={canvasWidth * 0.85}
          height={canvasHeight * 0.76}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          ref={stageRef}
        >
          <Layer>
            {/* White background */}
            <Rect
              x={0}
              y={0}
              width={canvasWidth * 0.85}
              height={canvasHeight * 0.76}
              fill="white"
            />
          </Layer>
          <Layer id="imageLayer">
            <KonvaImage
              image={image}
              width={imageWidth}
              height={imageHeight}
              x={x}
              y={y}
            />
          </Layer>
          <Layer id="linesLayer">
            {lines.map((line, i) => (
              <Line
                key={i}
                id={`line-${i}`}
                points={line.points}
                stroke="rgba(255, 255, 255, 0.6)"
                strokeWidth={line.brushSize}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            ))}
          </Layer>
        </Stage>

        <Box sx={{ textAlign: 'right', marginTop: '10px' }}>
          <Button variant="contained" onClick={handleExport}>
            Continue
          </Button>
        </Box>
      </div>
    </KitModal>
  );
};

export default MaskImageEditModal;
