import { Box, Switch, Typography } from '@mui/material';
import {
  CaretLeft,
  CaretRight,
  Copy,
  FileArrowDown,
  FileAudio,
  ImageSquare,
  Info,
  TextAlignLeft,
} from '@phosphor-icons/react';
import InputHandle from '../InputHandle/InputHandle';
import { Position } from 'reactflow';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';
import axios from 'axios';
import AudioComponents from '../AudioComponent/AudioComponent';
import { useAuthStore } from '../../../store/storeAuth';

interface Tag {
  tag: string;
  text: string;
}

const OutputAudio = ({
  value,
  placeholder = '',
  labelName = '',
  previewResponses,
  onPreview,
  previewIndex,
  findColor,
  handleId = 'output',
  activeHandle = false,
  placement,
  onChangePlacement,
  switchImage,
  hasClearOnExecute = false,
  clearOnExecution,
  onChangeOnExecute,
}: any) => {
  const onCopy = () => {
    navigator.clipboard.writeText(value);
  };

  const onDownload = () => {
    // download image from url with axios

    axios({
      url: value,
      method: 'GET',
      responseType: 'arraybuffer',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `image_${new Date().getTime() + Math.random() * 1000}.${'mp3'}`
      );
      document.body.appendChild(link);
      link.click();
    });
  };
  const { user } = useAuthStore(state => state);

  return (
    <Box position={'relative'} mb={'16px'} height={'100%'}>
      <InputHandle
        activeColor={findColor?.color}
        handleId={handleId}
        isActive={activeHandle}
        position={Position.Right}
        type='source'
      />

      {placement === 'Prompt' && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,

            '& :hover': {
              '&.change-placement': {
                border: '1px solid ' + findColor?.color,
                '& svg': {
                  fill: findColor?.color,
                },
              },
            },
          }}
          onClick={() => onChangePlacement?.('placement', 'Output')}
        >
          <Box
            className='change-placement'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              alignSelf: 'center',
              border: '1px solid #98A2B3',
              maxWidth: 'fit-content',
            }}
          >
            <TextAlignLeft size={16} color='#98A2B3' />
          </Box>
          <Typography fontSize={'12px'} color='#475467'>
            {labelName?.split(' ')[0]}
          </Typography>
        </Box>
      )}

      {placement !== 'Prompt' && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              position: 'relative',
              '& svg': { cursor: 'pointer' },
              mb: '8px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                fontSize={'16px'}
                color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                fontWeight={600}
              >
                {labelName}
              </Typography>
              <TextAlignLeft size={16} color='#98A2B3' />
            </Box>

            {hasClearOnExecute && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Switch
                  size='small'
                  onChange={onChangeOnExecute}
                  checked={clearOnExecution}
                />
                <IconButtonTooltip title={'Clear  output upon run'}>
                  <Info color='#98A2B3' size={12} />
                </IconButtonTooltip>
              </Box>
            )}
          </Box>
          <>
            <Box
              sx={{
                borderRadius: '4px',
                border: value ? '1px solid #98A2B3' : '',

                backgroundImage: value
                  ? ``
                  : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                padding: '15px 8px',

                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',

                height: placement === 'Output' ? '480px' : '60%',
                width: placement === 'Output' ? '680px' : '360px',
                maxHeight: '480px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!value ? (
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  gap={'8px'}
                >
                  <FileAudio color='#98A2B3' size={32} />
                  <Typography fontSize={'14px'} color={'#98A2B3'}>
                    Your audio file will be generated here
                  </Typography>
                </Box>
              ) : (
                <AudioComponents audioUrl={value} />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              mt={'8px'}
            >
              <Box style={{}}>
                {previewResponses?.length !== 0 && (
                  <Box display={'flex'} alignItems={'center'} gap={''}>
                    <CaretLeft
                      fontSize={16}
                      cursor={'pointer'}
                      color='#667085'
                      onClick={() => onPreview('prev')}
                    />
                    <Typography
                      lineHeight={'166%'}
                      fontSize={'12px'}
                      color={'#667085'}
                      m={'0'}
                      p='0'
                      mb={'-2px'}
                    >
                      {previewIndex !== undefined
                        ? previewIndex + 1
                        : previewResponses?.length}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        -
                      </span>
                      {previewResponses?.length}
                    </Typography>
                    <CaretRight
                      fontSize={16}
                      color='#667085'
                      cursor={'pointer'}
                      onClick={() => onPreview('next')}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <IconButtonTooltip
                  onClick={() => onDownload()}
                  title='Download Audio'
                >
                  <FileArrowDown color='#98A2B3' size={16} />
                </IconButtonTooltip>
                {switchImage && (
                  <IconButtonTooltip
                    onClick={() => switchImage(value)}
                    title='Switch Image'
                  >
                    <ImageSquare color='#98A2B3' size={16} />
                  </IconButtonTooltip>
                )}
                <IconButtonTooltip onClick={onCopy} title='Copy audio url'>
                  <Copy color='#98A2B3' size={16} />
                </IconButtonTooltip>
              </Box>
            </Box>
          </>
        </>
      )}
    </Box>
  );
};

export default OutputAudio;
