import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { NodeResizer, useEdges, useReactFlow } from 'reactflow';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import {
  Box,
  Button,
  Divider,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import TagInput from '../Test/TagInput';

import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { CaretDown, Faders } from '@phosphor-icons/react';
import Node from '../UI/Node/Node';
import {
  FormData as FormDataType,
  defaultFormData,
} from '../VideoGenerator/constants';
import SettingsModal from '../VideoGenerator/SettingsModal';
import OutputImage from '../UI/OutputImage/OutputImage';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { isActiveEdge } from '../../util/findActiveHandle';
import { aiImageGeneratorInputs } from '../ImageGenerator/constants';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import UploadBox from '../UI/UploadBox/UploadBox';
import { UploadService } from '../../service/UploadService';
import { FileService } from '../../service/FileService';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { LumaLabsService } from '../../service/LumaLabsService';
import { ImageService } from '../../service/ImageService';
import { RunwayService } from '../../service/RunwayService';

export const animationNodeTargetTypes = {
  animationPromptInput: 'prompt',
  animationNegativePromptInput: 'negativePrompt',
};

function AiVideoGenerator({ data, type, id }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();
  const { flowId, setSaving, setSchema } = useFlowsStore(state => state);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const [hasToExecute, setHasToExecute] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  const [hasVideoApiResponded, setHasVideoApiResponded] = useState({
    videoId: '',
    responded: false,
  });
  const [isVideoApiLoading, setIsVideoApiLoading] = useState(false);

  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [formData, setFormData] = useState<FormDataType>({
    ...defaultFormData,
  });
  const [previewResponses, setPreviewResponses] = useState([]);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);

  const debounceTimeoutRef = useRef<any>(null);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          ...defaultFormData,
          ...data,
        });
        if (data?.previewResponses?.length > 0) {
          setPreviewResponses(data.previewResponses);
        }
      }
    },
    [data, isChangingDirectly],
    300,
  );

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useEffect(() => {
    let interval: any;
    if (hasVideoApiResponded.videoId) {
      if (formData.baseModel === 'luma-labs') {
        interval = setInterval(async () => {
          const res = await LumaLabsService.getGeneration(
            hasVideoApiResponded.videoId,
          );
          if (res?.data?.state === 'completed') {
            setHasVideoApiResponded({ responded: true, videoId: '' });
            setFormData(prevState => ({
              ...prevState,
              text: res?.data?.assets?.video,
            }));
            nodeDataAutoSaveDynamic({
              newEdges: edges,
              setNodeState,
              setSaving,
              id,
              flowId,
              objectCallerData: [
                ...(user?.spaceObjects || []),
                ...(user?.objects || []),
              ],
              changeType: 'execute',
              flowTriggerData: checkExecuteTrigger(data, id),
              objectData: {
                text: res?.data?.assets?.video,
                videoResId: res?.data?.id,
              },
              setSchema,
            });
            setExecuteCounter(0);
            setIsVideoApiLoading(false);
          } else if (res?.data?.state === 'failed') {
            setHasVideoApiResponded({ responded: true, videoId: '' });
            setFormData(prevState => ({
              ...prevState,
              text: 'Failed to generate video',
            }));
            nodeDataAutoSaveDynamic({
              newEdges: edges,
              setNodeState,
              setSaving,
              id,
              flowId,
              objectCallerData: [
                ...(user?.spaceObjects || []),
                ...(user?.objects || []),
              ],
              changeType: 'execute',
              flowTriggerData: checkExecuteTrigger(data, id),
              objectData: {
                text: 'Failed to generate video',
                videoResId: undefined,
              },
              setSchema,
            });
            setExecuteCounter(0);
            setIsVideoApiLoading(false);
          }
        }, 5000);
      } else if (formData.baseModel === 'stability') {
        interval = setInterval(async () => {
          const res = await ImageService.getImageToVideoResult(
            hasVideoApiResponded.videoId,
          );
          if (res?.data?.message !== 'in-progress') {
            setHasVideoApiResponded({ responded: true, videoId: '' });
            setFormData(prevState => ({
              ...prevState,
              text: res?.data?.message,
            }));
            nodeDataAutoSaveDynamic({
              newEdges: edges,
              setNodeState,
              setSaving,
              id,
              flowId,
              objectCallerData: [
                ...(user?.spaceObjects || []),
                ...(user?.objects || []),
              ],

              flowTriggerData: checkExecuteTrigger(data, id),
              objectData: {
                text: res?.data?.message,
                videoResId: undefined,
              },
              setSchema,
            });
            setExecuteCounter(0);
            setIsVideoApiLoading(false);
          }
        }, 5000);
      } else {
        interval = setInterval(async () => {
          const res = await RunwayService.getId(hasVideoApiResponded.videoId);
          console.log(res?.data);
          if (res?.data?.status !== 'RUNNING') {
            setHasVideoApiResponded({ responded: true, videoId: '' });
            setFormData(prevState => ({
              ...prevState,
              text: res?.data?.output?.[0],
            }));
            nodeDataAutoSaveDynamic({
              newEdges: edges,
              setNodeState,
              setSaving,
              id,
              flowId,
              objectCallerData: [
                ...(user?.spaceObjects || []),
                ...(user?.objects || []),
              ],

              flowTriggerData: checkExecuteTrigger(data, id),
              objectData: {
                text: res?.data?.output?.[0],
                videoResId: undefined,
              },
              setSchema,
            });
            setExecuteCounter(0);
            setIsVideoApiLoading(false);
          }
        }, 5000);
      }
    } else {
      clearInterval(interval);
      setHasVideoApiResponded({ responded: false, videoId: '' });

      setIsVideoApiLoading(false);
    }

    return () => {
      if (!hasVideoApiResponded.responded) {
        clearInterval(interval);
        setExecuteCounter(0);
      }
    };
  }, [
    hasVideoApiResponded.responded,
    hasVideoApiResponded.videoId,
    formData.baseModel,
    data.flowTrigger,
  ]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (formData.baseModel === 'luma-labs') {
        setIsVideoApiLoading(true);
        const res = await LumaLabsService.createGeneration({
          aspectRatio: formData.aspect_ratio || undefined,
          loop: formData.loop === 'false' ? false : true,
          prompt: formData.prompt || undefined,
          ...(formData.type === 'Video to Video' ||
          formData.type === 'Image to Video'
            ? {
                keyframes: {
                  ...(data?.startImageInputData?.[0]?.text ||
                  formData.start_image
                    ? {
                        frame0: {
                          type:
                            formData.type === 'Video to Video'
                              ? 'generation'
                              : 'image',
                          ...(formData.type === 'Video to Video'
                            ? {
                                id:
                                  data?.startImageInputData?.[0]?.text ??
                                  formData.start_image,
                              }
                            : {
                                url:
                                  data?.startImageInputData?.[0]?.text ??
                                  formData.start_image,
                              }),
                        },
                      }
                    : {}),
                  ...(formData.type === 'Video to Video' &&
                  (data.endImageInputData?.[0]?.text || formData.end_image)
                    ? {
                        frame1: {
                          type:
                            formData.type === 'Video to Video'
                              ? 'generation'
                              : 'image',
                          ...(formData.type === 'Video to Video'
                            ? {
                                id:
                                  data?.endImageInputData?.[0]?.text ??
                                  formData.end_image,
                              }
                            : {
                                url:
                                  data?.endImageInputData?.[0]?.text ??
                                  formData.end_image,
                              }),
                        },
                      }
                    : {}),
                },
              }
            : {}),
        });
        setHasVideoApiResponded({ responded: false, videoId: res.data.id });
        return;
      }

      if (formData.baseModel === 'stability') {
        setIsVideoApiLoading(true);
        const res = await ImageService.imageToVideo({
          flowId: flowId,
          seed: formData.seed,
          cfg_scale: formData.cfg_scale,
          motion_bucket_id: formData.motion_bucket_id,
          src: data.startImageInputData?.[0]?.text || formData.start_image,
        });
        setHasVideoApiResponded({
          responded: false,
          videoId: res.data.message,
        });
        return;
      }

      if (formData.baseModel === 'runway-ml') {
        setIsVideoApiLoading(true);
        if (formData.type === 'Text to Video') {
          const res = await RunwayService.create({
            flowId: flowId,
            prompt: formData.prompt,
            duration: formData.duration,
          });
          setHasVideoApiResponded({
            responded: false,
            videoId: res?.data?.message,
          });
        }

        if (formData.type === 'Image to Video') {
          const res = await RunwayService.create({
            flowId: flowId,
            prompt: formData.prompt,
            duration: formData.duration,
            url: data.startImageInputData?.[0]?.text || formData.start_image,
          });
          setHasVideoApiResponded({
            responded: false,
            videoId: res?.data?.message,
          });
        }

        if (formData.type === 'Video to Video') {
          const res = await RunwayService.createVideoToVideo({
            flowId: flowId,
            prompt: formData.prompt,
            duration: formData.duration,
            promptImage: [
              {
                url:
                  data.startImageInputData?.[0]?.text || formData.start_image,
                position: 'first',
              },
              {
                url: data.endImageInputData?.[0]?.text || formData.end_image,
                position: 'last',
              },
            ],
          });
          setHasVideoApiResponded({
            responded: false,
            videoId: res?.data?.message,
          });
        }

        return;
      }
      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
      setIsLoading(false);
      setIsVideoApiLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    if (evt.target.name === 'loop') {
      const val = evt.target.value === 'true' ? true : false;
      setFormData(prevState => ({
        ...prevState,
        [evt.target.name]: !val,
      }));

      setWhoIsChanging({
        name: evt.target.name,
        //@ts-ignore
        value: !val,
      });
    } else {
      setFormData(prevState => ({
        ...prevState,
        [evt.target.name]: evt.target.value,
      }));
      setWhoIsChanging({
        name: evt.target.name,
        value: evt.target.value,
      });
    }

    setIsChangingDirectly(true);
  }, []);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          if (setNodeState) {
            setNodeState((nds: any) => {
              return nds?.map((nd: any) => {
                if (nd.id === id) {
                  return {
                    ...nd,
                    data: {
                      ...nd.data,
                      text: previewResponses[nextIndex],
                      previewIndex: nextIndex,
                    },
                  };
                }
                return nd;
              });
            });
          }
        }
      }
    }
  };

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  const handleStartImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        console.log('No file selected');
        return;
      }
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);
      await FileService.createFormFIle({
        name: response?.data.name,
        src: response.data.src,
        flowId: flowId,
      });
      if (response.data && response.data.src) {
        setFormData(prevState => {
          const newState = {
            ...prevState,
            start_image: response.data.src,
          };
          return newState;
        });

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            start_image: response.data.src,
          },
          setSchema,
          newEdges: edges,
        });
      } else {
        console.error('Invalid response from server:', response);
        throw new Error('Invalid server response');
      }
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const deleteStartImage = () => {
    setFormData({
      ...formData,
      start_image: '',
    });

    if (formData.start_image) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          start_image: '',
        },
        setSchema,
      });
    }
  };

  const handleEndImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        console.log('No file selected');
        return;
      }
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);
      await FileService.createFormFIle({
        name: response?.data.name,
        src: response.data.src,
        flowId: flowId,
      });
      if (response.data && response.data.src) {
        setFormData(prevState => {
          const newState = {
            ...prevState,
            end_image: response.data.src,
          };
          return newState;
        });

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            end_image: response.data.src,
          },
          setSchema,
          newEdges: edges,
        });
        console.log('end');
      } else {
        console.error('Invalid response from server:', response);
        throw new Error('Invalid server response');
      }
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const deleteEndImage = () => {
    setFormData({
      ...formData,
      end_image: '',
    });

    if (formData.end_image) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          end_image: '',
        },
        setSchema,
      });
    }
  };

  const generationTypeCategories = [
    {
      title: 'Create Video',
      options: [
        { label: 'Text to Video' },
        { label: 'Image to Video' },
        { label: 'Video to Video' },
      ],
    },
  ];

  return (
    <div className={isLoading || isVideoApiLoading ? 'node' : ''}>
      <Node
        type={type}
        btnText="Create Video"
        isLoading={isLoading || isVideoApiLoading}
        id={id}
        data={data}
        handleRun={handleRun}
        onSubmit={onSubmit}
        edges={edges}
        onChangePlacement={onChangePlacement}
      >
        <NodeResizer isVisible={false} />
        {!data?.collapsed && (
          <Box display={'flex'}>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
            >
              <Typography
                variant="h6"
                sx={{ mb: '8px', color: 'gray', width: '350px' }}
              >
                This model can only be used with videos previously generated
                from luma-labs. (You can use the video id at the bottom of
                output)
              </Typography>
              {formData?.baseModel !== 'stability' && (
                <Select
                  value={formData?.type ?? 'Text To Image'}
                  name="type"
                  onChange={onChange}
                  size="small"
                  className="nodrag"
                  sx={{
                    mb: '8px',
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: '1px solid rgba(234, 236, 240, 1)',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    bgcolor: user?.theme === 'dark' ? '#101828' : 'white',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    width: '200px',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : 'white',
                      },
                    },
                  }}
                >
                  {generationTypeCategories.map((el: any, i: number) => {
                    const {
                      title,
                      options,
                    }: {
                      title: string;
                      options: any;
                    } = el;
                    return [
                      <ListSubheader
                        sx={{
                          fontWeight: 600,
                          color: 'rgba(16, 24, 40, 1)',
                        }}
                        key={i + title}
                      >
                        {title}
                      </ListSubheader>,

                      options.map((option: any, index: number) => [
                        <MenuItem
                          key={index + option.label}
                          value={option.label}
                          sx={{
                            color:
                              user?.theme === 'dark'
                                ? '#D0D5DD'
                                : 'rgba(71, 84, 103, 1)',
                            background: 'transparent',
                            margin: '5px 0',
                          }}
                        >
                          {option.label}
                        </MenuItem>,
                        index === options.length - 1 && i === 0 && <Divider />,
                      ]),
                    ];
                  })}
                </Select>
              )}

              {formData?.baseModel !== 'stability' && (
                <TagInput
                  value={formData.prompt}
                  name="prompt"
                  isCollapsed={true}
                  labelName="Prompt"
                  handleId={'animationPromptInput'}
                  onChange={onChange}
                  placement={data?.placement}
                  findColor={findColor}
                  nodeId={id}
                  disabled={isActiveEdge(
                    edges,
                    id,
                    'animationPromptInput',
                    'target',
                  )}
                  nodeLabel={data?.label}
                  dataConnected={data?.['promptData'] || []}
                  onChangePlacement={onChangePlacement}
                  placeholder={
                    aiImageGeneratorInputs.find(
                      input => input.name === 'videoPrompt',
                    )?.placeholder
                  }
                />
              )}
              {formData?.baseModel !== 'stability' &&
                formData.baseModel !== 'luma-labs' &&
                formData.baseModel !== 'runway-ml' && (
                  <TagInput
                    value={formData.negativePrompt}
                    name="negativePrompt"
                    onChange={onChange}
                    handleId={'animationNegativePromptInput'}
                    labelName={'Negative Prompt'}
                    placement={data?.placement}
                    dataConnected={data?.['negativePromptData'] || []}
                    findColor={findColor}
                    nodeId={id}
                    nodeLabel={data?.label}
                    disabled={isActiveEdge(
                      edges,
                      id,
                      'animationNegativePromptInput',
                      'target',
                    )}
                    onChangePlacement={onChangePlacement}
                  />
                )}

              {(formData?.baseModel === 'stability' ||
                (formData.baseModel === 'runway-ml' &&
                  formData.type === 'Image to Video') ||
                (formData.baseModel === 'runway-ml' &&
                  formData.type === 'Video to Video') ||
                (formData.baseModel === 'luma-labs' &&
                  formData.type === 'Image to Video') ||
                (formData.baseModel === 'luma-labs' &&
                  formData.type === 'Video to Video')) && (
                <>
                  <Box
                    sx={{
                      borderTop: '1px solid rgba(234, 236, 240, 1)',
                      paddingTop: '10px',
                    }}
                  >
                    <UploadBox
                      labelName="Start Image"
                      value={
                        data.startImageInputData?.[0]?.text ||
                        formData.start_image
                      }
                      onChange={onChange}
                      name="start_image"
                      isActive={isActiveEdge(
                        edges,
                        id,
                        'startImageInput',
                        'target',
                      )}
                      id={id}
                      onChangeUploadImage={handleStartImageUpload}
                      findColor={findColor}
                      uploadedFile={
                        data.startImageInputData?.[0]?.text ||
                        formData.start_image
                      }
                      handleId="startImageInput"
                      deleteImage={deleteStartImage}
                      type={
                        formData.type !== 'Video to Video' ? 'image' : 'video'
                      }
                    />
                  </Box>

                  {(formData?.baseModel === 'stability' ||
                    (formData.baseModel === 'runway-ml' &&
                      formData.type === 'Video to Video') ||
                    (formData.baseModel === 'luma-labs' &&
                      formData.type === 'Video to Video')) && (
                    <Box
                      sx={{
                        borderTop: '1px solid rgba(234, 236, 240, 1)',
                        paddingTop: '10px',
                      }}
                    >
                      <UploadBox
                        labelName="End Image"
                        value={
                          data.endImageInputData?.[0]?.text ||
                          formData.end_image
                        }
                        onChange={onChange}
                        name="end_image"
                        isActive={isActiveEdge(
                          edges,
                          id,
                          'endImageInput',
                          'target',
                        )}
                        id={id}
                        onChangeUploadImage={handleEndImageUpload}
                        findColor={findColor}
                        uploadedFile={
                          data.endImageInputData?.[0]?.text ||
                          formData.end_image
                        }
                        handleId="endImageInput"
                        deleteImage={deleteEndImage}
                        type={
                          formData.type !== 'Video to Video' ? 'image' : 'video'
                        }
                      />
                    </Box>
                  )}
                </>
              )}

              <SettingsModal
                formData={formData}
                onChange={onChange}
                show={settingsModalOpen}
                onHide={() => setSettingsModalOpen(false)}
              />
              {data?.placement !== 'Output' && (
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                    borderRadius={'4px'}
                    sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                    onClick={() => setSettingsModalOpen(true)}
                  >
                    <Faders size={18} color="#667085" />
                    <Typography fontSize={'12px'} color="#667085" variant="h6">
                      {formData.baseModel}
                    </Typography>

                    <CaretDown size={'16px'} color="#667085" />
                  </Box>
                </Box>
              )}
            </Box>
            <Box p={'16px 24px 16px 16px'}>
              <OutputImage
                previewResponses={previewResponses}
                previewIndex={data.previewIndex}
                value={data.text}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                placement={data?.placement}
                labelName={'Output'}
                onPreview={onPreview}
                findColor={findColor}
                onChangePlacement={onChangePlacement}
                type={'video'}
              />
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>
                  {data.videoResId ? `Video ID: ${data.videoResId}` : ''}
                </Typography>
                {!!data.videoResId && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(data.videoResId);
                      setNotification({
                        type: NotificationType.Success,
                        message: 'Video ID copied to clipboard!',
                      });
                    }}
                  >
                    Copy
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Node>
    </div>
  );
}

export default memo(AiVideoGenerator);
