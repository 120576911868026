import { Box, FormLabel } from '@mui/material';
import React from 'react';
import { PrettoSlider } from '../../assets/styles/styles';
type Props = {
  formData: {
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
  };
  onChange: (
    event: Event | React.InputHTMLAttributes<HTMLInputElement>
  ) => void;
};

const EvenLabs = ({ formData, onChange }: Props) => {
  const flexColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2px',
  };

  const makePercentage = (value: number) => {
    return Math.round(value * 100);
  };

  return (
    <Box mt={'12px'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: '10px',
          gap: '15px',
        }}
      >
        <Box
          sx={{
            ...flexColumnStyle,
            width: '100%',
          }}
        >
          <PrettoSlider
            className="nodrag"
            value={formData.stability}
            min={0}
            max={1}
            step={0.1}
            valueLabelDisplay="auto"
            name="stability"
            onChange={onChange}
          />
          <FormLabel htmlFor="stability">
            Stability: {makePercentage(formData.stability)}%
          </FormLabel>
        </Box>
        <Box
          sx={{
            ...flexColumnStyle,
            width: '100%',
          }}
        >
          <PrettoSlider
            className="nodrag"
            value={formData.similarity_boost}
            min={0}
            max={1}
            valueLabelDisplay="auto"
            step={0.01}
            name="similarity_boost"
            onChange={onChange}
          />
          <FormLabel htmlFor="similarity_boost">
            Similarity Boost: {makePercentage(formData.similarity_boost)}%
          </FormLabel>
        </Box>
        <Box
          sx={{
            ...flexColumnStyle,
            width: '100%',
          }}
        >
          <PrettoSlider
            className="nodrag"
            value={formData.style}
            valueLabelDisplay="auto"
            min={0}
            max={1}
            step={0.01}
            name="style"
            onChange={onChange}
          />
          <FormLabel htmlFor="style">
            Style : {makePercentage(formData.style)}%
          </FormLabel>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
          mt: '20px',
        }}
      >
        <input
          type="checkbox"
          className="nodrag"
          checked={formData.use_speaker_boost}
          name="use_speaker_boost"
          onChange={onChange}
        />
        <FormLabel htmlFor="use_speaker_boost">Use Speaker Boost</FormLabel>
      </Box>
    </Box>
  );
};

export default EvenLabs;
