import { memo } from 'react';
import {
  NodeResizer,
  NodeToolbar,
  Position,
  getRectOfNodes,
  useStore,
  useStoreApi,
} from 'reactflow';
import styled from 'styled-components';
import ChangeLabel from '../UI/ChangeLabel/ChangeLabel';
import DeleteNode from '../UI/DeleteNode/DeleteNode';
import { useDetachNodes } from '../../util/useDetachNodes';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box, Button } from '@mui/material';

const Node = styled.div<{ selected?: boolean }>`
  padding: 10px 20px;
  border-radius: 5px;
  background: transparent;
  color: ${props => props.theme.nodeTxtColor};
  // border: 5px solid green;
  // width: 100%;
  // height: 100%;
`;

function NodesGroup({
  position,
  id,
  data,
  isConnectable,
  selected,
  type,
}: NodeProps) {
  const store = useStoreApi();
  const detachNodes = useDetachNodes();

  const { minWidth, minHeight, hasChildNodes } = useStore(store => {
    const childNodes = Array.from(store.nodeInternals.values()).filter(
      n => n.parentNode === id
    );
    const rect = getRectOfNodes(childNodes);

    return {
      minWidth: data.collapsed ? rect.width + 25 * 1 : rect.width + 25 * 2,
      minHeight: data.collapsed ? rect.height + 25 * 1 : rect.height + 25 * 2,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const onDetach = () => {
    const childNodeIds = Array.from(store.getState().nodeInternals.values())
      .filter(n => n.parentNode === id)
      .map(n => n.id);

    detachNodes(childNodeIds, id);
  };

  const onCollapse = () => {
    // setStoreDataForSelectedNode({
    //   myId: id,
    //   data: {
    //     collapsed: !data.collapsed,
    //   },
    // });
  };

  return (
    <Node>
      <NodeResizer
        minWidth={minWidth + 200}
        minHeight={minHeight + 200}
        maxWidth={data.collapsed ? minWidth : undefined}
        maxHeight={data.collapsed ? minHeight + 150 : undefined}
        color='#f24822'
      />

      <NodeToolbar position={Position.Top}>
        {hasChildNodes && (
          <Button onClick={onDetach} size='small'>
            Ungroup
          </Button>
        )}
      </NodeToolbar>
      <DeleteNode nodeId={id} />
      <Box>
        <ChangeLabel data={data} />
        <Button
          style={{
            marginTop: '10px',
          }}
          onClick={onCollapse}
        >
          {data.collapsed ? 'Expand' : 'Collapse'}
        </Button>
      </Box>
    </Node>
  );
}

export default memo(NodesGroup);

function isEqual(
  prev: { minWidth: any; minHeight: any; hasChildNodes: any },
  next: { minWidth: any; minHeight: any; hasChildNodes: any }
) {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}
