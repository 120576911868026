import React, { useEffect } from 'react';

import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';

interface Props {
  inputId: string;
  data: any;
}

const isValidGoogleDocId = (docId: string) => {
  const pattern = /^[a-zA-Z0-9_-]{30,60}$/;
  return pattern.test(docId);
};

const PullPublicGoogleDocsData: React.FC<Props> = ({ inputId, data }) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  useEffect(() => {
    if (!inputId) return;

    if (!isValidGoogleDocId(inputId))
      return setNotification({
        type: NotificationType.Error,
        message: 'Google Doc ID is wrong!',
      });

    data?.updateSelectedPublicDoc(inputId);
  }, [inputId]);

  return <></>;
};

export default PullPublicGoogleDocsData;
