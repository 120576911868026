import { memo, useEffect, useRef, useState } from 'react';
import { useEdges } from 'reactflow';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import { countries } from '../../util/countries';
import { SerpService } from '../../service/SerpService';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { Box, Divider, Typography, Chip } from '@mui/material';
import TagInput from '../Test/TagInput';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import { CaretDown, GoogleLogo, Pipe } from '@phosphor-icons/react';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import SettingsModal from '../WebSearch/SettingsModal/SettingsModal';
import { modifyTheStringifiedData } from '../../util/modifyTheStringifiedData';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { useNotificationStore } from '../../store/storeNotifications';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { useObserveElementWidth } from '../../util/useObserveElement';

export const searchEngines = [
  { value: 'google', label: 'Google' },
  { value: 'bing', label: 'Bing' },
  { value: 'yahoo', label: 'Yahoo', disabled: true },
  { value: 'duckduckgo', label: 'DuckDuckGo', disabled: true },
];

function WebSearch({ id, data, type }: NodeProps) {
  const edges: any = useEdges();
  const [formData, setFormData] = useState<{
    query: string;
    country: string;
    language: string;
    page: number;
    results: number;
    autocorrect: true;
    type: string;
    engine: string;
    switch: boolean;
    start: number | null;
    lat: string | null;
    lng: string | null;
    patentId: string;
  }>({
    engine: 'google',
    query: '',
    country: 'United States',
    language: 'United States',
    type: 'Web Search',
    page: 1,
    results: 10,
    autocorrect: true,
    switch: false,
    start: null,
    lat: null,
    lng: null,
    patentId: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });

  const { setNodeState, setSchema, flowId, setSaving, schema } = useFlowsStore(
    state => state,
  );
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [previewResponses, setPreviewResponses] = useState([]);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const handleInputChange = (e: {
    target: { name: any; value: any; checked?: any };
  }) => {
    const { name, value } = e.target;

    if (name === 'autocorrect') {
      setFormData({
        ...formData,
        [name]: e.target?.checked,
      });
      setIsChangingDirectly(true);

      setWhoIsChanging({
        name,
        value: e.target?.checked,
      });
      return;
    }

    if (name === 'switch') {
      setWhoIsChanging({
        name,
        value: e.target?.checked,
      });
      setIsChangingDirectly(true);

      setFormData({
        ...formData,
        [name]: e.target?.checked,
      });
      return;
    }

    if (name === 'engine') {
      const newFormData = { ...formData, [name]: value };
      if (value === 'bing') {
        newFormData.type = 'Web Search';
      }
      // if (newFormData.language === 'en') {
      //   newFormData.language = 'en-US';
      // }
      setFormData(newFormData);
    } else if (name === 'type') {
      const newFormData = { ...formData, [name]: value };
      if (formData.engine === 'bing' && !bingTypes.includes(value)) {
        newFormData.engine = 'google';
      }
      setFormData(newFormData);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setIsChangingDirectly(true);
    setWhoIsChanging({
      name,
      value,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      let query = formData.query;

      if (data?.queryData?.length) {
        query = data.queryData.map((item: any) => item.text).join(' \n');
      }

      let newFormData;

      if (formData.type === 'Patents') {
        newFormData = {
          query: replacePlaceholders(query, schema),
          page: parseInt(formData.page.toString()),
          results: parseInt(formData.results.toString()),
          language: 'ENGLISH',
          country: 'US',
          flowId,
        };
      } else if (formData.type === 'Patent Details') {
        newFormData = {
          query: `patent/${replacePlaceholders(query, schema).toUpperCase()}/${
            countries.find(country => country.name === formData.language)
              ?.langCode
          }`,
          page: parseInt(formData.page.toString()),
          results: parseInt(formData.results.toString()),
          language: countries.find(
            country => country.name === formData.language,
          )?.langCode,
          country: countries
            .find(country => country.name === formData.country)
            ?.code?.toLowerCase(),
          patentId: `patent/${replacePlaceholders(
            query,
            schema,
          ).toUpperCase()}/${
            countries.find(country => country.name === formData.language)
              ?.langCode
          }`,
          flowId,
        };
      } else {
        newFormData = {
          query: replacePlaceholders(query, schema),
          page: parseInt(formData.page.toString()),
          results: parseInt(formData.results.toString()),
          language: countries.find(
            country => country.name === formData.language,
          )?.langCode,
          country: countries
            .find(country => country.name === formData.country)
            ?.code?.toLowerCase(),
          lat: formData.lat || undefined,
          lng: formData.lng || undefined,
          start: +formData?.start! || undefined,
          flowId,
        };
      }

      let response: any = null;

      if (formData.engine === 'google') {
        if (formData.type === 'Web Search') {
          response = await SerpService.search(newFormData);
        } else if (formData.type === 'Location') {
          response = await SerpService.places(newFormData);
        } else if (formData.type === 'News') {
          response = await SerpService.news(newFormData);
        } else if (formData.type === 'Images') {
          response = await SerpService.image(newFormData);
        } else if (formData.type === 'Patents') {
          response = await SerpService.patents(newFormData);
        } else if (formData.type === 'Patent Details') {
          response = await SerpService.patentDetails(newFormData);
        } else if (formData.type === 'Lens') {
          response = await SerpService.googleLens(newFormData);
        } else if (formData.type === 'Shop') {
          response = await SerpService.googleShop(newFormData);
        }
      } else if (formData.engine === 'bing') {
        if (formData.type === 'Web Search') {
          response = await SerpService.bingSearch(newFormData);
        } else if (formData.type === 'News') {
          response = await SerpService.bingNews(newFormData);
        } else if (formData.type === 'Images') {
          response = await SerpService.bingImage(newFormData);
        } else if (formData.type === 'Shopping') {
          response = await SerpService.bingShopping(newFormData);
        }
      }
      const text = modifyTheStringifiedData(response?.data);
      let responses = [...previewResponses];

      if (responses.length === 10) {
        responses.shift();
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          text,
          previewResponses: [...responses, text],
          previewIndex: responses.length,
          usedTokens: data?.flowTrigger?.id
            ? response?.data.usedTokens
            : undefined,
          nodeUsedTokens: response?.data.usedTokens,
        },
        setSchema,
      });
      setIsLoading(false);
      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
    } catch (error: any) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        message: error?.message || 'Something went wrong',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const types = [
    'Web Search',
    'Location',
    'News',
    'Images',
    'Patents',
    'Patent Details',
    'Lens',
    'Shop',
  ];

  const bingTypes = ['Web Search', 'News', 'Images', 'Shopping'];

  useEffect(() => {
    if (!isChangingDirectly) {
      setFormData({
        ...formData,
        ...data,
        query: data.query ? data?.query : '',
      });
    }
    setPreviewResponses(data.previewResponses || []);
  }, [data, isChangingDirectly]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            setSaving,
            changeType: 'changingPreviewIndex',
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          onChangePlacement={onChangePlacement}
          btnText="Run Prompt"
          handleRun={handleRun}
          onSubmit={onSubmit}
          isLoading={isLoading}
          data={data}
          id={id}
        >
          <Box display={'flex'}>
            <div>
              <Box
                p={'16px'}
                borderRight={`1px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`}
                ref={inputRef}
              >
                <TagInput
                  name="query"
                  placeholder={
                    formData.type === 'Patent Details'
                      ? 'Enter a patent ID'
                      : 'Enter query'
                  }
                  labelName={
                    formData.type === 'Patent Details' ? 'Patent ID' : 'Query'
                  }
                  isCollapsed={true}
                  handleId={'query'}
                  dataConnected={data?.queryData || []}
                  isAdvanced={true}
                  findColor={findColor}
                  isActive={isActiveEdge(edges, id, 'query', 'target')}
                  nodeId={id}
                  advancedCollapsed={true}
                  onChange={handleInputChange}
                  onChangePlacement={onChangePlacement}
                  placement={data?.placement}
                  value={formData.query}
                  nodeLabel={data?.label}
                />

                {data?.placement !== 'Output' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '360px',
                        gap: '8px',
                        mb: '24px',
                        flexWrap: 'wrap',
                      }}
                    >
                      {formData.engine === 'bing'
                        ? bingTypes.map(value => (
                            <Chip
                              key={value}
                              sx={{
                                borderRadius: '8px',
                                fontSize: '12px',
                                color:
                                  formData.type === value
                                    ? '#101828'
                                    : user?.theme === 'dark'
                                    ? '#F2F4F7'
                                    : '#101828',
                                p: '4px 4px',
                                height: 'unset',
                                background:
                                  formData.type === value
                                    ? '#FECDD2'
                                    : user?.theme === 'dark'
                                    ? '#101828'
                                    : '#F2F4F7',
                                '&:hover': {
                                  background: '#FECDD2',
                                  color: '#101828',
                                },
                              }}
                              onClick={() => {
                                handleInputChange({
                                  target: {
                                    value: value,
                                    name: 'type',
                                  },
                                });
                              }}
                              label={value}
                            />
                          ))
                        : types.map(value => (
                            <Chip
                              key={value}
                              sx={{
                                borderRadius: '8px',
                                fontSize: '12px',
                                color:
                                  formData.type === value
                                    ? '#101828'
                                    : user?.theme === 'dark'
                                    ? '#F2F4F7'
                                    : '#101828',
                                p: '4px 4px',
                                height: 'unset',
                                background:
                                  formData.type === value
                                    ? '#FECDD2'
                                    : user?.theme === 'dark'
                                    ? '#101828'
                                    : '#F2F4F7',
                                '&:hover': {
                                  background: '#FECDD2',
                                  color: '#101828',
                                },
                              }}
                              onClick={() => {
                                handleInputChange({
                                  target: {
                                    value: value,
                                    name: 'type',
                                  },
                                });
                              }}
                              label={value}
                            />
                          ))}
                    </Box>
                    <Divider
                      sx={{
                        // mt: !collapsed ? '20px' : '200px',
                        mt: '114px',
                        mb: '10px',
                      }}
                    />
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box
                        bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                        borderRadius={'4px'}
                        sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                        onClick={() => setSettingsModalOpen(true)}
                      >
                        {formData.engine === 'google' ? (
                          <GoogleLogo size={18} color="#667085" />
                        ) : formData.engine === 'bing' ? (
                          <svg
                            className="svg-icon"
                            style={{
                              width: '1em',
                              height: '1em',
                              verticalAlign: 'middle',
                              fill: 'currentColor',
                              overflow: 'hidden',
                            }}
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M445.6 422.4L491.1 534c7.2 17.7 20.9 31.9 38.4 39.7l107.9 48.6-270.1 154.3v-599c0-35.9-22.9-67.8-56.8-79.3l-97-32.9c-19.2-6.5-39.1 7.8-39.1 28v679.7c0 68.3 37.7 131.1 98 163.2l2.4 1.3c57.8 30.8 126.8 30.1 183.4-1.8 88.9-50 223.2-125.5 309-173.7 50.9-28.6 82.3-82.4 82.3-140.7 0-69.3-44.2-130.8-109.8-153l-256.2-86.5c-24.7-8.3-47.8 16.3-37.9 40.5z m-25.2-61.8"
                              fill="#667085"
                            />
                          </svg>
                        ) : null}
                        <Typography
                          fontSize={'12px'}
                          color={user?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                          variant="h6"
                        >
                          {formData.engine === 'google'
                            ? 'Google'
                            : formData.engine === 'bing'
                            ? 'Bing'
                            : ''}
                        </Typography>

                        <CaretDown size={'16px'} color="#667085" />
                      </Box>
                    </Box>
                  </>
                )}
                <SettingsModal
                  onHide={() => setSettingsModalOpen(false)}
                  show={settingsModalOpen}
                  formData={formData}
                  onChange={handleInputChange}
                />
              </Box>
            </div>
            <Box p={'16px 24px 16px 16px'}>
              <OutputTextarea
                maxOutputHeight={maxOutputHeight}
                previewResponses={previewResponses}
                previewIndex={data.previewIndex}
                value={data.text}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                placement={data?.placement}
                labelName={'Output'}
                onPreview={onPreview}
                findColor={findColor}
                nodeLabel={data?.label}
                onChangePlacement={onChangePlacement}
              />
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
}

export default memo(WebSearch);
