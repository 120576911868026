import Y, { Doc } from 'yjs';
import { WebrtcProvider } from 'y-webrtc';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../../store/storeAuth';
import * as awarenessProtocol from 'y-protocols/awareness.js';
import { useLocation } from 'react-router-dom';
import useFlowsStore from '../../../store/storeFlows';

// Custom hook for Yjs document management
export const useYDoc = (isLoading: boolean, spaceId: string) => {
  const user = useAuthStore(state => state.user);
  const [doc, setDoc] = useState<Y.Doc>(new Doc());
  const [provider, setProvider] = useState<WebrtcProvider | null>(null);
  const [currentRoom, setCurrentRoom] = useState<string | null>(null);

  const { setColorsIndex } = useFlowsStore(state => state);

  const location = useLocation();

  useEffect(() => {
    const setupProvider = (roomName: string) => {
      const awareness = new awarenessProtocol.Awareness(doc);
      const newProvider = new WebrtcProvider(roomName, doc, {
        awareness: awareness,
        signaling: ['wss://xnf9caipnl.execute-api.us-east-1.amazonaws.com/dev'],
      });

      // Emit join room event via socket
      // Log connection status
      newProvider.on('status', (event: any) => {
        console.log('Connection status:', event);
      });

      // Handle awareness updates (e.g., user presence)
      awareness.on('update', () => {
        // (2) [Array(2), Array(2)]

        setColorsIndex(Array.from(awareness.getStates()));
      });

      setCurrentRoom(roomName);
      setProvider(newProvider);
    };

    if (user?.id && !provider && !currentRoom && spaceId) {
      if (location.pathname?.includes('spaceFlow')) {
        const roomName =
          window.location?.pathname?.split('/')?.pop() || 'default';
        setupProvider(roomName);
      }
    }

    return () => {
      if (provider) {
        provider.destroy();
      }
      if (currentRoom) {
        doc.destroy();
      }
    };
  }, [user, location.pathname, doc, provider, currentRoom, spaceId]);

  useEffect(() => {
    const newRoom = location.pathname?.split('/')?.pop() || 'default';
    if (
      provider &&
      currentRoom &&
      (newRoom !== currentRoom || !location.pathname?.includes('spaceFlow')) &&
      spaceId
    ) {
      provider.disconnect();
      provider.destroy();
      setProvider(null);
      setCurrentRoom(null);
      doc.destroy();
      setDoc(new Doc());
    } else if (provider && currentRoom && newRoom === currentRoom) {
      provider.connect();
    }
  }, [location.pathname, provider, currentRoom, doc, spaceId]);

  return doc;
};
