import { Slider, styled } from '@mui/material';
import styledV from 'styled-components';

export const StyledNode = styledV.div<{ bgColor: string; selected?: boolean }>`
  background: ${props => props?.bgColor};
  color: ${props => props?.theme?.nodeTxtColor};
  font-size: ${props => props?.theme?.nodeFntSize};
  border: 5px solid ${props => props?.bgColor};
  padding: 20px;
  border-radius: 5px;

  &:hover .custom-target-handle {
    padding: 8px;
  }

  &:hover .custom-target-handle:before {
    content: '';
    opacity: 1;
  }

  &:hover .custom-source-handle:after {
    width: 17px;
    height: 17px;
  }
  &:hover .custom-source-handle:before {
    content: '';
    opacity: 1;
  }

  .react-flow__handle {
    background: ${props => props?.theme?.primary};
    width: 8px;
    height: 10px;
    border-radius: 3px;
  }

  select,
  textarea {
    width: 100%;
    height: 100%;
    background: ${props => props?.theme?.txtBg};
    color: ${props => props?.theme?.txtColor};
    font-size: ${props => props?.theme?.txtFntSize};
    font-family: inherit;
    overflow: hidden;
  }

  .scrollable-table {
    width: 100%;
    height: 100%;
    background: ${props => props?.theme?.txtBg};
    color: ${props => props.theme?.txtColor};
    border: 1px solid
      ${props =>
        props.selected ? props.theme?.primary : props.theme?.nodeBorder};
    font-size: ${props => props.theme?.txtFntSize};
    font-family: inherit;
    overflow: hidden;
  }

  .scrollable-table table {
    border-collapse: collapse;
    width: 100%;
  }

  .scrollable-table thead th,
  .scrollable-table tbody td {
    border: 1px solid gray;
    padding: 3px;
  }

  .scrollable-table thead th {
    background: gray;
    color: white;
  }

  .scrollable-table tbody tr.selected {
    background-color: ${props => props?.theme?.nodeBg};
    cursor: pointer;
  }

  .scrollable-table tbody tr:hover {
    color: ${props => props?.theme?.nodeTxtColor};
    cursor: pointer;
  }
`;

export const StyledButton = styledV.button`
  padding: 0.25rem;
  margin: 5px;
  background-color: ${props => props?.theme?.primary};
  color: ${props => props?.theme?.textOnPrimary};
  font-size: ${props => props?.theme?.nodeFntSize};
  background-color: ${props => props?.theme?.primary};
  color: ${props => props?.theme?.textOnPrimary};
  font-size: ${props => props?.theme?.nodeFntSize};
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${props => props?.theme?.primaryHover};
  }
`;

//LOADING SPINNER
export const LoadSpinner = styledV.div<{ isLoading: boolean; name: string }>`
  animation: ${props => props?.theme?.loadSpinner?.animation};
  border: ${props => props?.theme?.loadSpinner?.border};
  border-top: ${props => props?.theme?.loadSpinner?.borderTop};
  border-right: ${props => props?.theme?.loadSpinner?.borderRight};
  border-bottom: ${props => props?.theme?.loadSpinner?.borderBottom};
  border-radius: ${props => props?.theme?.loadSpinner?.borderRadius};
  width: ${props => props?.theme?.loadSpinner?.width};
  height: ${props => props?.theme?.loadSpinner?.height};
  margin-right: ${props => props?.theme?.loadSpinner?.marginRight};
  margin-top: ${props => props?.theme?.loadSpinner?.marginTop};
  display: ${props =>
    props.isLoading
      ? 'block'
      : 'none'}; // conditionally render based on isLoading

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PrettoSlider = styled(Slider)({
  '&. MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#fff',
  },

  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#546BEA',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});
