export const frequency = ['daily', 'realtime'];
export enum GeoTrend {
  AR = 'Argentina',
  AU = 'Australia',
  AT = 'Austria',
  BE = 'Belgium',
  BR = 'Brazil',
  CA = 'Canada',
  CL = 'Chile',
  CO = 'Colombia',
  CZ = 'Czechia',
  DK = 'Denmark',
  EG = 'Egypt',
  FI = 'Finland',
  FR = 'France',
  DE = 'Germany',
  GR = 'Greece',
  HK = 'Hong Kong',
  HU = 'Hungary',
  IN = 'India',
  ID = 'Indonesia',
  IE = 'Ireland',
  IL = 'Israel',
  IT = 'Italy',
  JP = 'Japan',
  KE = 'Kenya',
  MY = 'Malaysia',
  MX = 'Mexico',
  NL = 'Netherlands',
  NZ = 'New Zealand',
  NG = 'Nigeria',
  NO = 'Norway',
  PE = 'Peru',
  PH = 'Philippines',
  PL = 'Poland',
  PT = 'Portugal',
  RO = 'Romania',
  RU = 'Russia',
  SA = 'Saudi Arabia',
  SG = 'Singapore',
  ZA = 'South Africa',
  KR = 'South Korea',
  ES = 'Spain',
  SE = 'Sweden',
  CH = 'Switzerland',
  TW = 'Taiwan',
  TH = 'Thailand',
  TR = 'Türkiye',
  UA = 'Ukraine',
  GB = 'United Kingdom',
  US = 'United States',
  VN = 'Vietnam',
}

export const geoTrendsArray = Object.keys(GeoTrend).map(key => ({
  code: key,
  name: GeoTrend[key as keyof typeof GeoTrend],
}));

export const hl = {
  af: 'Afrikaans',
  ak: 'Akan',
  sq: 'Albanian',
  ws: 'Samoa',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  az: 'Azerbaijani',
  eu: 'Basque',
  be: 'Belarusian',
  bem: 'Bemba',
  bn: 'Bengali',
  bh: 'Bihari',
  xx_bork: 'Bork, bork, bork!',
  bs: 'Bosnian',
  br: 'Breton',
  bg: 'Bulgarian',
  bt: 'Bhutanese',
  km: 'Cambodian',
  ca: 'Catalan',
  chr: 'Cherokee',
  ny: 'Chichewa',
  zh_cn: 'Chinese (Simplified)',
  zh_tw: 'Chinese (Traditional)',
  co: 'Corsican',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  xx_elmer: 'Elmer Fudd',
  en: 'English',
  eo: 'Esperanto',
  et: 'Estonian',
  ee: 'Ewe',
  fo: 'Faroese',
  tl: 'Filipino',
  fi: 'Finnish',
  fr: 'French',
  fy: 'Frisian',
  gaa: 'Ga',
  gl: 'Galician',
  ka: 'Georgian',
  de: 'German',
  el: 'Greek',
  kl: 'Greenlandic',
  gn: 'Guarani',
  gu: 'Gujarati',
  xx_hacker: 'Hacker',
  ht: 'Haitian Creole',
  ha: 'Hausa',
  haw: 'Hawaiian',
  iw: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  is: 'Icelandic',
  ig: 'Igbo',
  id: 'Indonesian',
  ia: 'Interlingua',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  jw: 'Javanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  rw: 'Kinyarwanda',
  rn: 'Kirundi',
  xx_klingon: 'Klingon',
  kg: 'Kongo',
  ko: 'Korean',
  kri: 'Krio (Sierra Leone)',
  ku: 'Kurdish',
  ckb: 'Kurdish (Soranî)',
  ky: 'Kyrgyz',
  lo: 'Laothian',
  la: 'Latin',
  lv: 'Latvian',
  ln: 'Lingala',
  lt: 'Lithuanian',
  loz: 'Lozi',
  lg: 'Luganda',
  ach: 'Luo',
  mk: 'Macedonian',
  mg: 'Malagasy',
  my: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mv: 'Maldives',
  mi: 'Maori',
  mr: 'Marathi',
  mfe: 'Mauritian Creole',
  mo: 'Moldavian',
  mn: 'Mongolian',
  sr_me: 'Montenegrin',
  ne: 'Nepali',
  pcm: 'Nigerian Pidgin',
  nso: 'Northern Sotho',
  no: 'Norwegian',
  nn: 'Norwegian (Nynorsk)',
  oc: 'Occitan',
  or: 'Oriya',
  om: 'Oromo',
  ps: 'Pashto',
  fa: 'Persian',
  xx_pirate: 'Pirate',
  pl: 'Polish',
  pt: 'Portuguese',
  pt_br: 'Portuguese (Brazil)',
  pt_pt: 'Portuguese (Portugal)',
  pa: 'Punjabi',
  qu: 'Quechua',
  ro: 'Romanian',
  rm: 'Romansh',
  nyn: 'Runyakitara',
  ru: 'Russian',
  gd: 'Scots Gaelic',
  sr: 'Serbian',
  sh: 'Serbo-Croatian',
  st: 'Sesotho',
  tn: 'Setswana',
  crs: 'Seychellois Creole',
  sn: 'Shona',
  sd: 'Sindhi',
  si: 'Sinhalese',
  sk: 'Slovak',
  sl: 'Slovenian',
  so: 'Somali',
  es: 'Spanish',
  es_419: 'Spanish (Latin American)',
  su: 'Sundanese',
  sw: 'Swahili',
  sv: 'Swedish',
  tg: 'Tajik',
  ta: 'Tamil',
  tt: 'Tatar',
  te: 'Telugu',
  th: 'Thai',
  ti: 'Tigrinya',
  to: 'Tonga',
  lua: 'Tshiluba',
  tum: 'Tumbuka',
  tr: 'Turkish',
  tk: 'Turkmen',
  tw: 'Twi',
  ug: 'Uighur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vu: 'Vanuatu',
  vi: 'Vietnamese',
  cy: 'Welsh',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  zu: 'Zulu',
};

export const cat = [
  { name: 'All', code: 'all' },
  { name: 'Business', code: 'b' },
  { name: 'Entertainment', code: 'e' },
  { name: 'Health', code: 'm' },
  { name: 'Science', code: 't' },
  { name: 'Sports', code: 's' },
  { name: 'Top Stories', code: 't' },
];

export const output = [
  {
    name: 'JSON',
    code: 'json',
  },
  {
    name: 'HTML',
    code: 'html',
  },
];
