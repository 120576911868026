import React from 'react';
import CustomerUsage from '../../components/TokenUsage/CustomerUsage';

const CustomerUsagePage: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#3650DE', minHeight: '100vh' }}>
      <h1 style={{ color: '#fff', marginTop: 0, textAlign: 'center', paddingTop: '20px' }}>Customer Usage</h1>
      <CustomerUsage />
    </div>
  );
};

export default CustomerUsagePage;