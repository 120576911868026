import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';

export class AnthropicService extends ApiService {
  static pathName = '/anthropic';

  static response = (
    body: any,
    type: string
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/${type}`, body);
  };

  static anthropicVision = (
    query: {
      prompt: string; 
      flowId: string;
      url?: string;
    },
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/vision`, body, query);
  };
}