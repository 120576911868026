import { Edge, Node } from 'reactflow';
import { conditionsCheck, singleConditionCheck } from '../conditionsCheck';

export const findConnectedNodes = (
  nodes: Node[],
  edges: Edge[],
  flowNodeId: string,
) => {
  const findAllCommanderNodes = edges.filter(
    edge => edge.source === flowNodeId,
  );

  const findFlowNodes = (
    edges: Edge[],
    nodeId: string,

    combinedIds: string[] = [],
    visited = new Set(),
  ) => {
    let connectedNodes: string[] = [];

    edges.forEach(edge => {
      const { source, target, sourceHandle } = edge;

      const findNode = nodes?.find(
        node => node?.id === nodeId && node.type === 'conditionNode',
      );

      let statement = '';

      if (findNode?.id) {
        const conditions: {
          logicOperator: string;
          condition: string;
          value: string;
          type: string;
        }[] = findNode?.data?.conditions;
        const getOperator = findNode?.data?.conditions?.[1]?.logicOperator;

        if (conditions?.length > 1 && getOperator === 'SWITCH') {
          const mapConditions = conditions.map((condition, index: number) => {
            let text = findNode?.data?.text;

            if (findNode?.data?.textData) {
              text = findNode?.data?.textData
                ?.map((item: { text: string }) => item.text)
                .join('\n');
            }

            const conditionCheck = singleConditionCheck(
              { ...condition },
              index,
              text,
            );

            return {
              statement: conditionCheck,
              index: index,
            };
          });

          // check the first statement which is true or if no statement is true then get the last statement

          const findStatement = mapConditions.findIndex(
            condition => condition.statement === true,
          );

          if (findStatement === -1) {
            statement = 'conditionHandle-F' + (mapConditions.length - 1);
          } else {
            statement = `conditionHandle-T${findStatement}`;
          }
        } else {
          let text = findNode?.data?.text;

          if (findNode?.data?.textData) {
            text = findNode?.data?.textData
              ?.map((item: { text: string }) => item.text)
              .join('\n');
          }
          const statementT = conditionsCheck(conditions, text);

          statement = statementT ? 'outputHandle-0' : 'outputHandle-1';
        }
      }

      const statementTarget = edges.filter(
        edge => edge.source === findNode?.id && edge.sourceHandle === statement,
      );

      if (statementTarget.length > 0) {
        const statementTargetId = statementTarget[0].target;

        if (!combinedIds.includes(nodeId)) {
          combinedIds.push(nodeId);
          visited.add(nodeId);
        }

        if (!visited.has(statementTargetId)) {
          visited.add(statementTargetId);

          combinedIds.push(statementTargetId);

          const recursivelyConnectedNodes = findFlowNodes(
            edges,
            statementTargetId,
            combinedIds,
            visited,
          );

          connectedNodes.push(statementTargetId, ...recursivelyConnectedNodes);
        }
      } else {
        if (source === nodeId && sourceHandle === 'outputFlow') {
          if (!visited.has(target)) {
            visited.add(target);
            combinedIds.push(target);

            const recursivelyConnectedNodes = findFlowNodes(
              edges,
              target,
              combinedIds,
              visited,
            );

            connectedNodes.push(target, ...recursivelyConnectedNodes);
          }
        }
      }
    });

    return connectedNodes;
  };

  const combinedIds: {
    [target: string]: string[];
  } = {};
  findAllCommanderNodes.forEach(edge => {
    if (!combinedIds[edge.target]) {
      combinedIds[edge.target] = [];
    }

    findFlowNodes(edges, edge.target, combinedIds[edge.target]);
  });

  const keys = Object.keys(combinedIds);

  const allIds = keys
    .map(key => {
      return [key, ...combinedIds[key]];
    })
    ?.flat();

  return { allIds, combinedIds };
};

export const findFlowNodesIncludingAllBranches = (
  nodes: Node[],
  edges: { source: string; target: string }[],
  nodeId: string,
  combinedIds: string[] = [],
  visited = new Set(),
) => {
  let connectedNodes: string[] = [];

  edges.forEach(edge => {
    const { source, target } = edge;

    // Only process the current node's connections
    if (source === nodeId) {
      const findNode = nodes?.find(node => node.id === source);

      if (findNode?.type === 'conditionNode') {
        // For condition nodes, follow both true and false branches
        const conditionTargets = edges.filter(edge => edge.source === source);

        conditionTargets.forEach(condEdge => {
          const conditionTargetId = condEdge.target;

          if (!visited.has(conditionTargetId)) {
            visited.add(conditionTargetId);
            combinedIds.push(conditionTargetId);

            const recursivelyConnectedNodes = findFlowNodesIncludingAllBranches(
              nodes,
              edges,
              conditionTargetId,
              combinedIds,
              visited,
            );

            connectedNodes.push(
              conditionTargetId,
              ...recursivelyConnectedNodes,
            );
          }
        });
      } else {
        // For non-condition nodes, follow the normal flow
        if (!visited.has(target)) {
          visited.add(target);
          combinedIds.push(target);

          const recursivelyConnectedNodes = findFlowNodesIncludingAllBranches(
            nodes,
            edges,
            target,
            combinedIds,
            visited,
          );

          connectedNodes.push(target, ...recursivelyConnectedNodes);
        }
      }
    }
  });

  return connectedNodes;
};
