import {
  Box,
  Button,
  Chip,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useEdges } from 'reactflow';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { useAuthStore } from '../../store/storeAuth';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import {
  commandTextNodeDefaultValues,
  systemDisplayNameMap,
  systemValueMap,
} from '../AiConfigs/constants';
import Gemini from '../AiTextGenerator/Gemini';
import GroqAi from '../AiTextGenerator/GroqAi';
import OpenAi from '../AiTextGenerator/OpenAi';
import OpenSources from '../AiTextGenerator/OpenSources';

function DynamicTextSettingsNode({ id, data }: any) {
  const [formData, setFormData] = useState({
    ...commandTextNodeDefaultValues,
    ...data,
  });
  const [tokenCount, setTokenCount] = useState(0);
  const edges = useEdges();
  const { setNodeState, setSchema, setEdges, flowId, setSaving } =
    useFlowsStore(state => state);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const {
    user,
    technologies,
    companySpaceTechnologies,
    setCompanySpaceTechnologies,
  } = useAuthStore(state => state);
  const { spaceId } = useFlowsStore();

  const fetchAllTechnologies = async () => {
    try {
      const res = await CompanySpacesService.getSpaceTechnologies(
        spaceId as string,
      );
      setCompanySpaceTechnologies(res.data);
    } catch (error) {
      console.error('Error fetching company technologies:', error);
    }
    return;
  };

  useEffect(() => {
    if (spaceId) {
      fetchAllTechnologies();
    }
  }, [spaceId]);

  const textSystems = [
    'anthropic',
    'openAi',
    'groq',
    'gemini',
    'perplexity',
    'replicate',
  ];

  const filteredTextSystems = spaceId
    ? companySpaceTechnologies
        ?.filter(system => textSystems?.includes(system?.system))
        ?.map(system => ({
          ...system,
          models: system?.models?.filter((model: any) => model.type === 'Text'),
        }))
    : technologies
        ?.filter(system => textSystems.includes(system.system))
        ?.map(system => ({
          ...system,
          models: system?.models?.filter((model: any) => model.type === 'Text'),
        }));

  const setValue = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChange = (e: { target: { name: any; value: any } }) => {
    setValue(e.target.name, e.target.value);

    setWhoIsChanging({
      value: e.target.value,
      name: e.target.name,
    });

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          setEdges,
          setNodeState,
          newEdges: edges,
          id,
          setSaving,
          changeType: 'execute',
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  useEffect(() => {
    if (data && !isChangingDirectly) {
      setFormData({
        ...commandTextNodeDefaultValues,
        ...data,
      });
    }
  }, [data, isChangingDirectly]);

  const sendData = (e: any) => {
    // e.preventDefault();
    // console.log(formData)
  };

  const TestTest = useMemo(() => {
    if (formData?.AiSystem === 'openSources') {
      return JSON.stringify({
        AISystem: formData?.AiSystem,
        AiModel: formData?.openSourceModelName,
        MaxLength: formData?.max_length,
        Temperature: formData?.temperature,
        TopP: formData?.top_p,
        RepetitionPenalty: formData?.repetition_penalty,
        LengthPenalty: formData?.length_penalty,
        NoRepeatNgramSize: formData?.no_repeat_ngram_size,
        Seed: formData?.seed,
      });
    } else if (formData?.AiSystem === 'Gemini') {
      return JSON.stringify({
        AISystem: formData?.AiSystem,
        AiModel: formData?.geminiModel,
        Tokens: formData?.maxOutputTokens,
        Temperature: formData?.temperature,
        TopP: formData?.topP,
        TopK: formData?.topK,
      });
    } else if (formData?.AiSystem === 'anthropicAi') {
      return JSON.stringify({
        AISystem: formData?.AiSystem,
        AiModel: formData?.anthropicModel,
        Tokens: formData?.maxOutputTokens,
      });
    } else if (formData?.AiSystem === 'perplexityAi') {
      return JSON.stringify({
        AISystem: formData?.AiSystem,
        AiModel: formData?.perplexityType,
      });
    } else {
      return JSON.stringify({
        AiSystem: formData?.AiSystem,
        AiModel: formData?.aiModel,
        Temperature: formData?.aiTemperature,
        PPenalty: formData?.aiPp,
        FPenalty: formData?.aiFp,
        Tokens: formData?.aiTokens,
      });
    }
  }, [
    formData?.AiSystem,
    formData?.openSourceModelName,
    formData?.max_length,
    formData?.temperature,
    formData?.top_p,
    formData?.repetition_penalty,
    formData?.length_penalty,
    formData?.no_repeat_ngram_size,
    formData?.seed,
    formData?.modelName,
    formData?.topP,
    formData?.topK,
    formData?.maxOutputTokens,
    formData?.geminiModel,
    formData?.anthropicModel,
    formData?.perplexityType,
    formData?.aiModel,
    formData?.aiTemperature,
    formData?.aiPp,
    formData?.aiFp,
    formData?.aiTokens,
  ]);

  return (
    <>
      {!data?.collapsed && (
        <>
          <form>
            <Box className="content">
              <Box
              //   style={modelInputBoxStyle}
              >
                <Typography
                  color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                >
                  System
                </Typography>
                <Select
                  className="nodrag"
                  defaultValue={'OpenAI'}
                  name="AiSystem"
                  onChange={onChange}
                  value={data?.AiSystem}
                  sx={{
                    marginLeft: '8px',
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  {filteredTextSystems?.map((opt, index) => {
                    const disabled = opt?.models?.every(
                      (model: any) => model.enabled === false,
                    );
                    const disabledByCompany = opt?.models?.every(
                      (model: any) => model.disabledByCompany,
                    );
                    const systemValue =
                      systemValueMap[opt.system] || opt.system;
                    const displayName =
                      systemDisplayNameMap[systemValue] || systemValue;
                    return (
                      <MenuItem
                        key={index}
                        value={systemValue}
                        disabled={disabled}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>{displayName}</Typography>
                          {disabledByCompany && (
                            <Chip
                              label="Disabled by admin"
                              color="error"
                              size="small"
                              variant="filled"
                              sx={{
                                borderRadius: '8px',
                              }}
                            />
                          )}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              {formData.AiSystem === 'OpenAI' && (
                <OpenAi
                  watch={formData}
                  onChange={onChange}
                  systems={filteredTextSystems}
                  // setIsChangingDirectly={setIsChangingDirectly}
                  tokenCount={tokenCount}
                />
              )}

              {formData.AiSystem === 'Groq' && (
                <GroqAi onChange={onChange} watch={formData} />
              )}

              {formData.AiSystem === 'perplexityAi' && (
                <Box>
                  <FormLabel
                    sx={{
                      mb: '8px',
                      mt: '8px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    Type
                  </FormLabel>
                  <Select
                    className="nodrag"
                    value={formData.perplexityType}
                    fullWidth
                    size="small"
                    onChange={onChange}
                    name={'perplexityType'}
                    sx={{
                      '& .MuiList-root': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                  >
                    {filteredTextSystems
                      ?.filter((item: any) => item.system === 'perplexity')?.[0]
                      ?.models?.map((opt: any) => (
                        <MenuItem
                          key={opt.id}
                          value={opt.model}
                          disabled={opt.disabledByCompany}
                        >
                          {opt.model}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              )}

              {formData.AiSystem === 'anthropicAi' && (
                <>
                  <Box>
                    <FormLabel
                      sx={{
                        mb: '4px',
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      }}
                    >
                      Model
                    </FormLabel>

                    <Select
                      className="nodrag"
                      value={formData.anthropicModel}
                      size="small"
                      fullWidth
                      onChange={onChange}
                      name={'anthropicModel'}
                      sx={{
                        '& .MuiList-root': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        border: `2px solid ${
                          user?.theme === 'dark' ? '#475467' : '#EAECF0'
                        }`,
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .Mui-selected': {
                              backgroundColor:
                                user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                            },
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          },
                        },
                      }}
                    >
                      {filteredTextSystems
                        ?.filter(
                          (item: any) => item.system === 'anthropic',
                        )?.[0]
                        .models?.map((opt: any) => (
                          <MenuItem
                            key={opt.id}
                            value={opt.model}
                            disabled={opt.disabledByCompany}
                          >
                            {opt.model}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      mt: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormLabel
                      sx={{
                        mb: '4px',
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      Max Output
                    </FormLabel>
                    <TextField
                      type={'number'}
                      onChange={e => {
                        onChange(e);
                      }}
                      value={formData.max_tokens}
                      name={'max_tokens'}
                      style={{ marginLeft: '8px', width: '80px' }}
                      className="nodrag"
                      InputProps={{
                        style: {
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        },
                      }}
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        border: `2px solid ${
                          user?.theme === 'dark' ? '#475467' : '#EAECF0'
                        }`,
                      }}
                    />
                  </Box>
                </>
              )}

              {formData.AiSystem === 'Gemini' && (
                <Gemini
                  imageSystem
                  watch={formData}
                  onChange={onChange}
                  systems={filteredTextSystems}
                />
              )}

              {formData.AiSystem === 'openSources' && (
                <OpenSources watch={formData} onChange={onChange} />
              )}
            </Box>
            <TextField
              className="nodrag"
              disabled
              value={TestTest}
              multiline
              InputProps={{
                style: {
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
              }}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                border: `2px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`,
              }}
            />
            <Button> Send Data</Button>
          </form>
        </>
      )}
    </>
  );
}

export default DynamicTextSettingsNode;
