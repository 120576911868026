import { memo, useEffect, useCallback, useState } from 'react';
import { NodeResizer, Position, useEdges } from 'reactflow';

import { WordCount } from './AiTextGenerator';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box } from '@mui/material';
import TagInput from '../Test/TagInput';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useParams } from 'react-router-dom';
import Node from '../UI/Node/Node';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import InputHandle from '../UI/InputHandle/InputHandle';
import { isActiveEdge } from '../../util/findActiveHandle';
import { arrayDataToText } from '../../util/dataToText';

function TextBoxNode({ id, data, type }: NodeProps) {
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const { setNodeState, setSaving, setSchema } = useFlowsStore(state => state);
  const [textBoxValue, setTextBoxValue] = useState(data?.text);

  const { flowId } = useParams();
  const edges = useEdges();

  const onChange = useCallback((evt: { target: { value: any } }) => {
    setIsChangingDirectly(true);
    setTextBoxValue(evt.target.value);
  }, []);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          changeType: 'changeText',
          objectData: { text: textBoxValue },
          setSchema,
        });
      }
    },
    [isChangingDirectly, textBoxValue],
    500,
  );

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setTextBoxValue(data.text || '');
      }
    },
    [data?.text, isChangingDirectly],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  return (
    <Node
      id={id}
      data={data}
      isLoading={false}
      type={type}
      showTokensUsed={false}
    >
      <NodeResizer isVisible={false} />

      <Box position={'relative'} p={'16px'}>
        {!data.collapsed && (
          <Box>
            <TagInput
              name={'text'}
              disabled={data?.disabled}
              value={
                data?.['textData']?.length > 0
                  ? arrayDataToText(data?.['textData'])
                  : textBoxValue
              }
              findColor={findColor}
              isActive={isActiveEdge(edges, id, 'inputText', 'target')}
              labelName={'Text'}
              isCollapsed={true}
              readOnly={data?.['textData']?.length > 0}
              handleId={'inputText'}
              nodeLabel={data?.label}
              nodeId={id}
              onChange={onChange}
            />

            <WordCount
              text={
                data?.['textData']?.length > 0
                  ? arrayDataToText(data?.['textData'])
                  : textBoxValue
              }
            />
            <InputHandle
              activeColor={findColor?.color}
              handleId={'textOutput'}
              isActive={isActiveEdge(edges, id, 'textOutput', 'source')}
              right={-10}
              top={'50%'}
              position={Position.Right}
              type="source"
            />
          </Box>
        )}
      </Box>
    </Node>
  );
}

export default memo(TextBoxNode);
