import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { CensusService } from '../../service/CensusService';
import { Select, MenuItem, Box } from '@mui/material';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import Label from '../UI/Label/Label';
import TagInput from '../Test/TagInput';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { useObserveElementWidth } from '../../util/useObserveElement';

const getCensusCodes = {
  B12001_001E: 'TOTAL_POPULATION_IN_SPECIFIED_AREA',
  B12001_002E: 'POPULATION_WHO_ARE_CURRENTLY_MARRIED',
  B12001_003E: 'POPULATION_WHO_ARE_DIVORCED',
  B12001_004E: 'POPULATION_WHO_HAVE_NEVER_MARRIED',
  B09001_001E: 'TOTAL_POPULATION_IN_HOUSEHOLDS',
  B09001_002E: 'POPULATION_UNDER_18_YEARS',
  B09001_003E: 'POPULATION_UNDER_6_YEARS',
  B02001_001E: 'TOTAL_POPULATION',
  B02001_002E: 'RACE_WHITE_ALONE',
  B02001_003E: 'RACE_BLACK_OR_AMERICAN_ALONE',
  B02001_004E: 'RACE_AMERICAN_INDIAN_AND_ALASKA_NATIVE_ALONE',
  B02001_005E: 'RACE_ASIAN_ALONE',
  B02001_006E: 'RACE_NATIVE_HAWAIIAN_AND_OTHER_PACIFIC_ISLANDER_ALONE',
  B02001_007E: 'SOME_OTHER_RACE_ALONE',
  B02001_008E: 'POPULATION_OF_TWO_OR_MORE_RACES',
  B03002_001E: 'TOTAL_POPULATION_HISPANIC_OR_LATINO',
  B03002_002E: 'MEXICAN_POPULATION',
  B03002_003E: 'PUERTO_RICAN_POPULATION',
  B03002_004E: 'CUBAN_POPULATION',
  B03002_005E: 'DOMINICAN_POPULATION',
  B03002_006E: 'CENTRAL_AMERICAN_POPULATION',
  B03002_007E: 'SOUTH_AMERICAN_POPULATION',
  B03002_008E: 'OTHER_HISPANIC_OR_LATINO_POPULATION',
  B19001_001E: 'TOTAL_POPULATION_WITH_INCOME_DATA',
  B19001_002E: 'INCOME_BELOW_$10000',
  B19001_003E: 'INCOME_$10000_TO_$14999',
  B19001_004E: 'INCOME_$15000_TO_$19999',
  B19001_005E: 'INCOME_$20000_TO_$24999',
  B19001_006E: 'INCOME_$25000_TO_$29999',
  B19001_007E: 'INCOME_$30000_TO_$34999',
  B19001_008E: 'INCOME_$35000_TO_$39999',
  B19001_009E: 'INCOME_$40000_TO_$44999',
  B19001_010E: 'INCOME_$45000_TO_$49999',
  B19001_011E: 'INCOME_$50000_TO_$59999',
  B19001_012E: 'INCOME_$60000_TO_$74999',
  B19001_013E: 'INCOME_$75000_TO_$99999',
  B19001_014E: 'INCOME_$100000_TO_$124999',
  B19001_015E: 'INCOME_$125000_TO_$149999',
  B19001_016E: 'INCOME_$150000_TO_$199999',
  B19001_017E: 'INCOME_$200000_OR_MORE',
  B15003_002E: 'LESS_THAN_HIGH_SCHOOL',
  B15003_017E: 'HIGH_SCHOOL_GRADUATE',
  B15003_018E: 'SOME_COLLEGE_OR_ASSOCIATES_DEGREE',
  B15003_019E: 'BACHELOR_DEGREE',
  B15003_020E: 'GRADUATE_OR_PROFESSIONAL_DEGREE',
  B01002_001E: 'MEDIAN_AGE_TOTAL_POPULATION',
};

const forCensusCodes = {
  UNITED_STATES: 'us',
  TEXAS: 'state:48',
  ALABAMA: 'state:01',
  ALASKA: 'state:02',
  ARKANSAS: 'state:05',
  CALIFORNIA: 'state:06',
  COLORADO: 'state:08',
  CONNECTICUT: 'state:09',
  DELAWARE: 'state:10',
  DISTRICT_OF_COLUMBIA: 'state:11',
  FLORIDA: 'state:12',
  GEORGIA: 'state:13',
  HAWAII: 'state:15',
  IDAHO: 'state:16',
  ILLINOIS: 'state:17',
  INDIANA: 'state:18',
  IOWA: 'state:19',
  KANSAS: 'state:20',
  KENTUCKY: 'state:21',
  LOUISIANA: 'state:22',
  MAINE: 'state:23',
  MARYLAND: 'state:24',
  MASSACHUSETTS: 'state:25',
  MICHIGAN: 'state:26',
  MINNESOTA: 'state:27',
  MISSISSIPPI: 'state:28',
  MISSOURI: 'state:29',
  MONTANA: 'state:30',
  NEBRASKA: 'state:31',
  NEVADA: 'state:32',
  NEW_HAMPSHIRE: 'state:33',
  NEW_JERSEY: 'state:34',
  NEW_MEXICO: 'state:35',
  NEW_YORK: 'state:36',
  NORTH_CAROLINA: 'state:37',
  NORTH_DAKOTA: 'state:38',
  OHIO: 'state:39',
  OKLAHOMA: 'state:40',
  OREGON: 'state:41',
  PENNSYLVANIA: 'state:42',
  RHODE_ISLAND: 'state:44',
  SOUTH_CAROLINA: 'state:45',
  SOUTH_DAKOTA: 'state:46',
  TENNESSEE: 'state:47',
  UTAH: 'state:49',
  VERMONT: 'state:50',
  VIRGINIA: 'state:51',
  WASHINGTON: 'state:53',
  WEST_VIRGINIA: 'state:54',
  WISCONSIN: 'state:55',
  WYOMING: 'state:56',
  PUERTO_RICO: 'state:72',
};

function UsCensusNode({ data, id, type }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();

  const {
    setSchema,
    flowId,
    setSaving,
    setSocketNodeResponse,
    socketNodeResponse,
  } = useFlowsStore(state => state);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });

  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);

  const [formData, setFormData] = useState<{
    year: number;
    get: string;
    for: string;
    text: string;
  }>({
    year: 2005,
    get: 'B12001_001E',
    for: 'us',
    text: '',
  });

  const debounceTimeoutRef = useRef<any>(null);

  const [hasToExecute, setHasToExecute] = useState(true);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useEffect(() => {
    if (data && !isChangingDirectly) {
      setFormData({
        year: data.year ?? formData.year,
        get: data.get ?? formData.get,
        for: data.for ?? formData.for,
        text: data.text ?? formData.text,
      });
    }
  }, [data, isChangingDirectly]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await CensusService.census({
        year: +formData.year,
        get: formData.get,
        for: formData.for,
        flowId,
      });

      setSocketNodeResponse(id, null);
      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          text: data.text,
        },
        setSchema,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 1500);
      setIsLoading(false);

      setSocketNodeResponse(null, null);
    }
  }, [socketNodeResponse?.nodeId, socketNodeResponse?.finished, data?.text]);

  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));

    setWhoIsChanging({
      name: evt.target.name,
      value: evt.target.value,
    });
    setIsChangingDirectly(true);
  }, []);
  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        id={id}
        type={type}
        data={data}
        edges={edges}
        isLoading={isLoading}
        onSubmit={onSubmit}
        handleRun={handleRun}
        btnText="Send"
      >
        <Box display={'flex'}>
          <div>
            <Box p={'16px'} borderRight={'1px solid #EAECF0'} ref={inputRef}>
              <Label labelName="Category" isAdvanced={true} />
              <Select
                className="nodrag"
                name="get"
                value={formData.get}
                onChange={onChange}
                size="small"
                sx={{
                  '& .MuiList-root': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  },
                  '& .MuiSvgIcon-root': {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                  borderRadius: '8px',
                  border: 'none',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  mb: '10px',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                      },
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                  },
                }}
              >
                {Object.entries(getCensusCodes).map(([code, name]) => (
                  <MenuItem
                    key={code}
                    value={code}
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <Box mb={'16px'} />

              <TagInput
                type="number"
                name="year"
                className="nodrag"
                value={formData.year}
                nodeId={id}
                onChange={onChange}
                removeIcons
                isAdvanced
                isCollapsed
                advancedCollapsed
                labelName="Year"
                nodeLabel={data?.label}
                placeholder="Enter a year"
              />

              <Label labelName="State" isAdvanced={true} />
              <Select
                name="for"
                value={formData.for}
                onChange={onChange}
                className="nodrag"
                size="small"
                sx={{
                  '& .MuiList-root': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  },
                  '& .MuiSvgIcon-root': {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                  borderRadius: '8px',
                  border: 'none',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  mb: '10px',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                      },
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                  },
                }}
              >
                {Object.entries(forCensusCodes).map(([name, code]) => (
                  <MenuItem
                    key={code}
                    value={code}
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    {name?.replaceAll('_', ' ')}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </div>

          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              // previewResponses={previewResponses}
              maxOutputHeight={maxOutputHeight}
              previewIndex={data.previewIndex}
              value={data.text}
              activeHandle={
                edges?.find(
                  (edge: any) =>
                    edge?.source === id && edge?.sourceHandle === 'output',
                )?.source
                  ? true
                  : false
              }
              placement={data?.placement}
              labelName={'Output'}
              nodeLabel={data?.label}
              // onPreview={onPreview}
              findColor={findColor}
              // onChangePlacement={onChangePlacement}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
}

export default memo(UsCensusNode);
