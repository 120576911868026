import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';

export class ScriptingService extends ApiService {

  static scripting = (
    body: any
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${body.endpoint}`, body);
  };
}
