import {
  Autocomplete,
  createFilterOptions,
  Checkbox,
  TextField,
} from '@mui/material';
import React, { CSSProperties, useState } from 'react';

// eslint-disable-next-line import/no-anonymous-default-export

type Props = {
  onChange: (e: any) => void;
  defaultValues: any;
  defaultOptions: {
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  }[];
  customOptions: {
    label: string;
    options: {
      value: string;
      label: string;
    }[];
  };
  onAddCustomOptions: (value: any) => void;
};

const MultiSelectTone = ({
  onChange,
  defaultValues,
  defaultOptions,
  customOptions,
  onAddCustomOptions,
}: Props) => {
  const [value, setValue] = useState('');
  const [customOption, setCustomOption] = useState<{
    options: { value: string; label: string }[] | any[];
    label: string;
  }>(
    customOptions || {
      options: [],
      label: 'Custom Options',
    }
  );

  const addCustomOption = (value: string) => {
    setCustomOption({
      options: [...customOption.options, { value, label: value }],
      label: 'Custom Options',
    });

    onAddCustomOptions({
      options: [...customOption.options, { value, label: value }],
      label: 'Custom Options',
    });
  };

  return (
    <Autocomplete
      options={[
        ...defaultOptions.flatMap(group => group.options),
        ...customOption.options,
      ]}
      groupBy={option => {
        const allItems = [...defaultOptions, customOption];
        const group = allItems.find(group =>
          group.options.some(opt => opt.value === option.value)
        );

        return group ? group.label : '';
      }}
      getOptionLabel={option => option.label}
      onChange={(e, value) => {
        if (onChange) {
          onChange(value);
        }
      }}
      noOptionsText={
        <div style={{ padding: '16px', textAlign: 'center' }}>
          No options {value}
          <button
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => {
              addCustomOption(value);
              setValue('');
            }}
          >
            Add {value} as an option
          </button>
        </div>
      }
      renderInput={params => (
        <TextField
          {...params}
          id={Math.random().toString(36).substring(7)}
          variant='outlined'
          onChange={e => setValue(e.target.value)}
        />
      )}
      renderGroup={(params: any) => [
        <li key={params.key} style={{ listStyleType: 'none' }}>
          <ul style={{ padding: 0 }}>
            <li
              style={{
                padding: '10px 16px',
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #e0e0e0',
              }}
            >
              {params.group}
            </li>
            {params.children}
          </ul>
        </li>,
      ]}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} readOnly />
          {option.label}
        </li>
      )}
      // show only selected options in the tags area but only 2 or 3 tags
      value={defaultValues}
      style={{ width: '360px' }}
      multiple
    />
  );
};

export default MultiSelectTone;
