import React, { SyntheticEvent, useEffect, useState } from 'react';
import SpacesModal from './SpacesModal';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  TextField,
} from '@mui/material';
import { User } from '../../util/Types/User';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { useAuthStore } from '../../store/storeAuth';

interface Props {
  onClose: () => void;
  spaceId: string;
  getCompanySpace: () => Promise<void>;
  setShowUsersAddedModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUsersModal: React.FC<Props> = ({
  onClose,
  spaceId,
  getCompanySpace,
  setShowUsersAddedModal,
}) => {
  const authUser = useAuthStore(state => state.user);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [allCompanyUsers, setAllCompanyUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  useEffect(() => {
    const getCompanyUsers = async () => {
      try {
        const { data } = await CompanySpacesService.getAllCompanyUsers(spaceId);

        setAllCompanyUsers(data);
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as string),
        });
      }
    };

    getCompanyUsers();
  }, []);

  const handleUserChange = (
    event: SyntheticEvent<Element, Event>,
    value: User[],
  ) => {
    setSelectedUsers(value.map(user => user.id));
  };

  const addUsers = async () => {
    if (selectedUsers.length === 0) return;

    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      await Promise.all(
        selectedUsers.map(userId =>
          CompanySpacesService.addUser({
            company: { id: authUser.selectedCompanyId as string },
            user: { id: userId },
            id: spaceId,
          }),
        ),
      );

      await getCompanySpace();
      setShowUsersAddedModal(true);
      onClose();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }
  };

  return (
    <SpacesModal
      modalTitle="Add Users to you Space"
      onClose={onClose}
      footer={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Button variant="outlined" sx={{ flex: 1 }} onClick={onClose}>
            Go Back
          </Button>
          <Button
            variant="contained"
            sx={{ flex: 1 }}
            onClick={addUsers}
            disabled={selectedUsers.length === 0}
          >
            Continue
          </Button>
        </Box>
      }
    >
      <Autocomplete
        multiple
        onChange={handleUserChange}
        options={allCompanyUsers}
        getOptionLabel={option => option.name}
        renderOption={(props, option) => (
          <li {...props} key={option?.id} >
            <Avatar
              src={option.cover}
              alt={option.name}
              sx={{ marginRight: 1 }}
            />
            {option.name}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              avatar={<Avatar src={option.cover} />}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Add users"
            placeholder="Enter Email"
          />
        )}
      />
    </SpacesModal>
  );
};

export default AddUsersModal;
