import {
  Box,
  Checkbox,
  MenuItem,
  Radio,
  Select,
  Typography,
} from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import React, { useState } from 'react';

const List = ({ data, input, setInputs }: any) => {
  const [valueLabelDisplay, setValueLabelDisplay] = useState(
    data?.type === 'dropdown' ? 'none' : '',
  );

  const findInput = input?.find(
    (inp: any) => inp?.inputId === data?.fullData?.selectedFlow,
  );

  const [multipleSelection, setMultipleSelection] = useState<string[]>([]);

  const onChange = (e: any) => {
    setValueLabelDisplay(e.target.value);

    setInputs((prev: any) =>
      prev?.map?.((inp: any) => {
        if (inp?.inputId === findInput?.inputId) {
          return {
            ...inp,
            inputText: e.target.value,
          };
        }

        return inp;
      }),
    );
  };

  return (
    <Box
      sx={{
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        height: 'fit-content',

        borderRadius: data?.fullData?.borderRadius || '0px',
        ...data?.style,
        bgcolor: data?.fullData?.bgColor || '#fff',
      }}
    >
      {data?.label && (
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#475467',
            ...data?.fullData?.titleStyle,
          }}
        >
          {data?.label ? data.label : 'Title Component'}
        </Typography>
      )}

      {data?.question && (
        <Typography
          sx={{
            fontSize: '12',
            fontWeight: '400',
            color: '#475467',
            ...data?.fullData?.questionStyle,
          }}
        >
          {data?.question ? data.question : 'Question '}
        </Typography>
      )}
      {data?.type === 'dropdown' && (
        <>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            name="type"
            value={valueLabelDisplay || ''}
            onChange={onChange}
            fullWidth
            IconComponent={() => (
              <Box mt={'2px'} mr="8px">
                <CaretDown size={20} />
              </Box>
            )}
            sx={{
              color: '#475467',
              bgcolor: '#fff',
              maxWidth: '200px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MenuItem disabled value={'none'}>
              Select an option
            </MenuItem>
            {data?.options?.map((option: any, index: any) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.value}
                </MenuItem>
              );
            })}
          </Select>
        </>
      )}

      {data?.type === 'radio' && (
        <>
          {data?.options
            ?.filter((opt: any) => opt?.type !== 'add')
            .map((option: any, index: any) => {
              return (
                <Box key={index} display={'flex'} alignItems={'center'}>
                  <Radio
                    id={option.id}
                    name={option.id}
                    value={option.value}
                    checked={option.value === valueLabelDisplay}
                    onChange={e => {
                      setValueLabelDisplay(e.target.value ? option.value : '');

                      setInputs((prev: any) =>
                        prev?.map?.((inp: any) => {
                          if (inp?.inputId === findInput?.inputId) {
                            return {
                              ...inp,
                              inputText: e.target.value ? option.value : '',
                            };
                          }

                          return inp;
                        }),
                      );
                    }}
                  />
                  <label htmlFor={option.id}>{option.value}</label>
                </Box>
              );
            })}
        </>
      )}

      {data?.type === 'multipleChoice' && (
        <>
          {data?.options
            ?.filter((opt: any) => opt?.type !== 'add')
            .map((option: any, index: any) => {
              return (
                <Box key={index} display={'flex'} alignItems={'center'}>
                  <Checkbox
                    id={option.id}
                    name={option.id}
                    value={option.value}
                    checked={multipleSelection.includes(option.value)}
                    onChange={e => {
                      if (multipleSelection.includes(option.value)) {
                        setMultipleSelection(
                          multipleSelection.filter(
                            (item: any) => item !== option.value,
                          ),
                        );

                        setInputs((prev: any) =>
                          prev?.map?.((inp: any) => {
                            if (inp?.inputId === findInput?.inputId) {
                              return {
                                ...inp,
                                inputText: multipleSelection
                                  .filter((item: any) => item !== option.value)
                                  ?.join(','),
                              };
                            }

                            return inp;
                          }),
                        );
                      } else {
                        setMultipleSelection([
                          ...multipleSelection,
                          option.value,
                        ]);

                        setInputs((prev: any) =>
                          prev?.map?.((inp: any) => {
                            if (inp?.inputId === findInput?.inputId) {
                              return {
                                ...inp,
                                inputText: [
                                  ...multipleSelection,
                                  option.value,
                                ]?.join(', '),
                              };
                            }

                            return inp;
                          }),
                        );
                      }
                    }}
                  />
                  <label htmlFor={option.id}>{option.value}</label>
                </Box>
              );
            })}
        </>
      )}
    </Box>
  );
};

export default List;
