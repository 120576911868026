import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Position, useEdges, useReactFlow } from 'reactflow';
import { Editor } from '@tinymce/tinymce-react';
import { useAuthStore } from '../../store/storeAuth';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import useFlowsStore from '../../store/storeFlows';
import { useParams } from 'react-router-dom';
import Node from '../UI/Node/Node';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import InputHandle from '../UI/InputHandle/InputHandle';
import { arrayDataToText } from '../../util/dataToText';
import { isActiveEdge } from '../../util/findActiveHandle';

function RichTextBoxNode({ data, type, id, selected }: NodeProps) {
  const { user, setAuthUser } = useAuthStore(state => state);
  const editorRef = useRef(null);
  const [whoIsChanging, setWhoIsChanging] = useState<any>({
    isChangingDirectly: null,
    value: '',
  });
  const [size, setSize] = useState({
    height: data?.size?.height || 400,
    width: data?.size?.width || 600,
  });

  const { flowId } = useParams();
  const edges = useEdges();
  const { setNodes: setNodeState } = useReactFlow();

  const { setSaving, setSchema } = useFlowsStore(state => state);

  const [myValue, setMyValue] = useState('');

  useDebounceEffect(
    () => {
      if (!whoIsChanging.isChangingDirectly) {
        setMyValue(
          data?.['textData']?.length > 0
            ? arrayDataToText(data?.['textData'])
            : data?.text,
        );
      }
    },
    [data.text, data?.textData, whoIsChanging.isChangingDirectly],
    300,
  );

  const handleEditorChange = useCallback(
    (content: React.SetStateAction<string>, editor: any) => {
      try {
        if ((data?.['textData'] || [])?.length === 0) {
          setMyValue(content);
          setWhoIsChanging({
            isChangingDirectly: true,
            value: content as string,
          });
        } else {
          editor.setContent(myValue);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [edges.length, data?.['textData'], myValue],
  );

  useDebounceEffect(
    () => {
      if (whoIsChanging.isChangingDirectly) {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          changeType: 'changeText',
          flowId,
          objectData: {
            text: whoIsChanging.value,
            size: {
              height: size.height,
              width: size.width,
            },
          },
          setSchema,
        });
        setWhoIsChanging({
          isChangingDirectly: false,
          value: '',
        });
      }
    },
    [whoIsChanging?.isChangingDirectly, whoIsChanging?.value, size],
    500,
  );

  const onChangeInput = useCallback(
    (content: {
      target: { value: string | number; name: string | number };
    }) => {
      if ((content?.target?.value as any) > 1000) {
        return;
      }
      let nodes: any[] = [];

      setNodeState(nds => {
        nodes = [...nds];
        return nds;
      });

      const filteredNodes = nodes?.filter(node => node.type === 'richTextBox');
      const findIndex = filteredNodes?.findIndex(node => node.id === id);

      if (findIndex === -1) return;

      setSize(state => ({
        ...state,
        [content.target.name]: Number(content.target.value),
      }));

      setWhoIsChanging({
        isChangingDirectly: true,
      });

      const getElement: any = document?.querySelectorAll('.tox-tinymce');
      if (getElement) {
        setTimeout(() => {
          getElement[findIndex].style[content.target.name] =
            content.target.value + 'px';
        }, 1000);
      }
    },
    [],
  );

  useEffect(() => {
    if (!selected) {
      const getElement: any = document?.querySelectorAll(
        '.tox-toolbar__overflow',
      );
      const toxMenu = document?.querySelectorAll('.tox-menu');
      if (getElement) {
        getElement.forEach((item: any) => {
          item.style.display = 'none';
        });
      }
      if (toxMenu) {
        toxMenu.forEach((item: any) => {
          item.style.display = 'none';
        });
      }
    }
  }, [selected]);

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  return (
    <Node
      id={id}
      data={data}
      isLoading={false}
      type={type}
      showTokensUsed={false}
    >
      {!data?.collapsed && (
        <Box p="16px">
          <InputHandle
            activeColor={findColor?.color}
            handleId={'input'}
            isActive={isActiveEdge(edges, id, 'input', 'target')}
            left={-12}
            top={'50%'}
            position={Position.Left}
            type="target"
          />

          <InputHandle
            activeColor={findColor?.color}
            handleId={'textOutput'}
            isActive={isActiveEdge(edges, id, 'textOutput', 'source')}
            right={-12}
            top={'50%'}
            position={Position.Right}
            type="source"
          />

          <Editor
            key={user?.theme}
            id={data.myId}
            apiKey="zd089kdfun2xrkm6rcxpbh2j7kum3t9yrdfi76di2wfavied"
            cloudChannel="5"
            ref={editorRef}
            scriptLoading={{
              async: true,
            }}
            value={myValue}
            init={{
              branding: false,
              skin: user?.theme === 'dark' ? 'oxide-dark' : '',
              content_css: user?.theme === 'dark' ? 'dark' : '',
              resize: false,
              width: size.width,
              height: size.height,
              plugins: [
                'advlist autolink lists link image',
                'wordcount',
                'paste',
                'code',
                'table',
                'charmap print preview anchor help',
                'searchreplace visualblocks code',
                'insertdatetime media table paste wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
              alignleft aligncenter alignright | \
              bullist numlist outdent indent | wordcount | code | tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
            }}
            onEditorChange={handleEditorChange}
          />
          <Box className="d-flex justify-content-end">
            <input
              type="number"
              name="width"
              placeholder="Enter Width"
              min={200}
              value={size.width}
              onChange={onChangeInput}
              max={1000}
              className="nodrag"
              style={{
                width: '60px',
                border: `1px solid ${
                  user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                }`,
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#98A2B3' : 'black',
              }}
            />
            <input
              type="number"
              name="height"
              min={200}
              placeholder="Enter Height"
              max={1000}
              value={size.height}
              onChange={onChangeInput}
              style={{
                width: '60px',
                border: `1px solid ${
                  user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                }`,
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#98A2B3' : 'black',
              }}
              className="nodrag "
            />
          </Box>
        </Box>
      )}
    </Node>
  );
}

export default memo(RichTextBoxNode);
