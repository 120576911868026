import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { SecService } from '../../service/SecService';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import TagInput from '../Test/TagInput';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import Label from '../UI/Label/Label';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { useObserveElementWidth } from '../../util/useObserveElement';
import { replaceNtoBr } from '../../util/markdown';

const modelTypes = [
  'sec-query',
  'sec-full-text-search',
  'sec-extractor',
  'sec-trading',
  'sec-form-d',
  'sec-adv-firm-search',
  'sec-adv-direct-owners',
  'sec-indirect-owners',
  'sec-adv-financial-industry-affiliations',
  'sec-adv-private-funds',
  'sec-adv-individual-registered-adviser',
  'sec-adv-brochure-api',
  'sec-cik',
  'sec-ticker',
  'sec-cusip',
  'sec-company-name',
];

function SecApiNode({ data, type, id }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();

  const {
    setSchema,
    flowId,
    schema,
    setSaving,
    socketNodeResponse,
    setSocketNodeResponse,
  } = useFlowsStore(state => state);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);
  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);

  const [formData, setFormData] = useState<{
    model: string;
    query: string;
    from: number;
    size: number;
    formTypes: string;
    startDate: string;
    endDate: string;
    section: string;
    type: string;
    response: string;
  }>({
    model: 'sec-query',
    query: '',
    from: 10,
    size: 1,
    formTypes: '',
    startDate: '',
    endDate: '',
    section: '',
    type: '',
    response: '',
  });

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          model: data.model ?? formData.model,
          query: data.query ?? formData.query,
          from: data.from ?? formData.from,
          size: data.size ?? formData.size,
          formTypes: data.formTypes ?? formData.formTypes,
          startDate: data.startDate ?? formData.startDate,
          endDate: data.endDate ?? formData.endDate,
          section: data.section ?? formData.section,
          type: data.type ?? formData.type,

          response: data.text ?? formData.response,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const types = ['query', 'type', 'section'];

      let newFormData: any = { ...formData, flowId };

      const processText = (inputText: string) => {
        // Regular expression to match URLs

        // replcale any /n with space or any extra spaces with single space

        const text = inputText.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim();

        var urlRegex = /(https?:\/\/[^\s]+)/g;

        // Check if the input text contains a URL
        if (urlRegex?.test?.(text)) {
          // If a URL is found, remove extra spaces
          var processedText = inputText?.replace(/\s+/g, '').trim();

          // remove spaces
          return processedText?.replace(/\s+/g, '').trim();
        } else {
          // If no URL is found, return the original text
          return inputText;
        }
      };
      // replace for spaces

      types.forEach(type => {
        if (data?.[type + 'Data']?.length) {
          newFormData[type] = data?.[type + 'Data']
            ?.map((item: any) => item.text)
            .join(' ');
        }
      });

      const query = replacePlaceholders(processText(newFormData.query), schema);

      if (formData.model === 'sec-query') {
        await SecService.query({
          query: query.toLowerCase(),
          from: +formData.from,
          size: +formData.size,
          flowId,
        });
      }

      if (formData.model === 'sec-full-text-search') {
        await SecService.fullTextSearch({
          query: query.toLowerCase(),
          formTypes: formData?.formTypes.split(',').map((item: string) => {
            return item.trim();
          }),
          startDate: formData.startDate,
          endDate: formData.endDate,
          flowId,
        });
      }

      if (formData.model === 'sec-extractor') {
        await SecService.extractor({
          url: query.toLowerCase(),
          section: newFormData.section,
          type: newFormData.type,
          flowId,
        });
      }

      if (formData.model === 'sec-trading') {
        await SecService.trading({
          query: query.toLowerCase(),
          from: +formData.from,
          size: +formData.size,
          flowId,
        });
      }

      if (formData.model === 'sec-form-d') {
        await SecService.formD({
          query: query.toLowerCase(),
          from: +formData.from,
          size: +formData.size,
          flowId,
        });
      }

      if (formData.model === 'sec-adv-firm-search') {
        await SecService.advFirmSearch({
          query: query.toLowerCase(),
          from: +formData.from,
          size: +formData.size,
          flowId,
        });
      }

      if (formData.model === 'sec-adv-individual-registered-adviser') {
        await SecService.advIndividualRegisteredAdviser({
          query: query.toLowerCase(),
          from: +formData.from,
          size: +formData.size,
          flowId,
        });
      }

      if (formData.model === 'sec-adv-direct-owners') {
        await SecService.advDirectOwners(query.toLowerCase());
      }

      if (formData.model === 'sec-indirect-owners') {
        await SecService.advIndirectOwners(query.toLowerCase());
      }

      if (formData.model === 'sec-adv-financial-industry-affiliations') {
        await SecService.advFinancialIndustryAffiliations(query.toLowerCase());
      }

      if (formData.model === 'sec-adv-private-funds') {
        await SecService.advPrivateFunds(query.toLowerCase());
      }

      if (formData.model === 'sec-adv-brochure-api') {
        await SecService.advBrochureApi(query.toLowerCase());
      }

      if (formData.model === 'sec-cik') {
        await SecService.cik(query.toLowerCase());
      }

      if (formData.model === 'sec-ticker') {
        await SecService.ticker(query.toLowerCase());
      }

      if (formData.model === 'sec-cusip') {
        await SecService.cusip(query.toLowerCase());
      }

      if (formData.model === 'sec-company-name') {
        await SecService.companyName(query.toLowerCase());
      }

      setSocketNodeResponse(id, null);
      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowId,
        objectData: {
          text: data.text.message,
          usedTokens: data?.flowTrigger?.id
            ? data?.text?.usedTokens
            : undefined,
          nodeUsedTokens: data?.text?.usedTokens,
        },
        setSchema,
      });

      setIsLoading(false);

      setTimeout(() => {
        setHasToExecute(true);
      }, 1500);

      setSocketNodeResponse(null, null);
    }
  }, [
    socketNodeResponse?.nodeId,
    socketNodeResponse?.finished,
    data?.text,
    data?.flowTrigger,
    data?.previewResponses,
    data?.previewIndex,
  ]);

  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    if (evt.target.name === 'startDate' || evt.target.name === 'endDate') {
      if (new Date(evt.target.value) > new Date(formData.endDate)) {
        setNotification({
          type: 'error',
          message: 'Start date must be less than end date',
        });

        return;
      }

      if (new Date(evt.target.value) > new Date()) {
        setNotification({
          type: 'error',
          message: 'Start date must be less than current date',
        });

        return;
      }
    }

    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));

    setWhoIsChanging({
      value: evt.target.value,
      name: evt.target.name,
    });

    setIsChangingDirectly(true);
  }, []);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <div className={isLoading ? 'node' : ''}>
      <Node
        type={type}
        data={data}
        id={id}
        edges={edges}
        handleRun={handleRun}
        isLoading={isLoading}
        onSubmit={onSubmit}
        btnText="Run Prompt"
      >
        <Box display={'flex'}>
          <div>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
              ref={inputRef}
            >
              <Box mb={'16px'}>
                <Label labelName="Select Model" isAdvanced={true} />
                <Select
                  className="nodrag"
                  name="model"
                  value={formData.model}
                  onChange={onChange}
                  size="small"
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    mb: '10px',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  {modelTypes.map(type => (
                    <MenuItem
                      key={type}
                      value={type}
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <TagInput
                onChange={onChange}
                labelName={
                  formData.model === 'sec-extractor'
                    ? 'Url'
                    : formData.model === 'sec-adv-direct-owners' ||
                      formData.model === 'sec-indirect-owners' ||
                      formData.model ===
                        'sec-adv-financial-industry-affiliations' ||
                      formData.model === 'sec-adv-private-funds' ||
                      formData.model === 'sec-adv-brochure-api'
                    ? 'Crd'
                    : formData.model === 'sec-cik'
                    ? 'Cik'
                    : formData.model === 'sec-ticker'
                    ? 'Ticker'
                    : formData.model === 'sec-cusip'
                    ? 'Cusip'
                    : formData.model === 'sec-company-name'
                    ? 'Company Name'
                    : 'Query'
                }
                value={formData.query}
                dataConnected={data?.queryData || []}
                isCollapsed={true}
                findColor={findColor}
                isActive={isActiveEdge(edges, id, 'secNodeTarget', 'target')}
                nodeId={id}
                handleId={'secNodeTarget'}
                isAdvanced={true}
                removeIcons
                advancedCollapsed
                name="query"
                nodeLabel={data?.label}
                placeholder={
                  formData.model === 'sec-extractor'
                    ? 'URL of any form type from the SEC'
                    : 'Enter Company Name'
                }
              />
              <>
                {(formData.model === 'sec-query' ||
                  formData.model === 'sec-trading' ||
                  formData.model === 'sec-form-d' ||
                  formData.model === 'sec-adv-firm-search' ||
                  formData.model ===
                    'sec-adv-individual-registered-adviser') && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: '16px',
                      }}
                    >
                      <Label labelName="From" isAdvanced />

                      <TextField
                        type="number"
                        placeholder="How many results"
                        className="nodrag"
                        onChange={onChange}
                        fullWidth
                        size="small"
                        value={formData.from}
                        name="from"
                        InputProps={{
                          style: {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                        }}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          bgcolor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          border: `2px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: '16px',
                      }}
                    >
                      <Label labelName="Size" isAdvanced />

                      <TextField
                        type="number"
                        className="nodrag"
                        placeholder="How many pages of results"
                        value={formData.size}
                        onChange={onChange}
                        name="size"
                        InputProps={{
                          style: {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                        }}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          bgcolor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          border: `2px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                      />
                    </Box>
                  </>
                )}

                {formData.model === 'sec-full-text-search' && (
                  <>
                    <TagInput
                      nodeLabel={data?.label}
                      isAdvanced
                      advancedCollapsed
                      removeIcons
                      isCollapsed
                      nodeId={id}
                      findColor={findColor}
                      isActive={isActiveEdge(edges, id, 'formTypes', 'target')}
                      labelName={'Form type'}
                      onChange={onChange}
                      value={formData.formTypes}
                      className="nodrag"
                      name="formTypes"
                      placeholder="Enter form (Ex: “10-Q”)"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '16px',
                      }}
                    >
                      <Label
                        isAdvanced
                        labelStyle={{
                          fontSize: '12px',
                        }}
                        sx={{
                          mb: '0px',
                        }}
                        labelName="Start Date"
                      />
                      <TextField
                        type="date"
                        onChange={onChange}
                        value={formData.startDate}
                        size="small"
                        name="startDate"
                        className="nodrag"
                        inputProps={{
                          sx: {
                            fontSize: '12px',
                            bgcolor: '#EAECF0',
                            borderRadius: '8px',
                            border: 'none',
                            height: '24px',
                            p: '2px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          },
                        }}
                        sx={{
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: '16px',
                      }}
                    >
                      <Label
                        isAdvanced
                        labelStyle={{
                          fontSize: '12px',
                        }}
                        sx={{
                          mb: '0px',
                        }}
                        labelName="End Date"
                      />
                      <TextField
                        type="date"
                        className="nodrag"
                        onChange={onChange}
                        value={formData.endDate}
                        name="endDate"
                        inputProps={{
                          sx: {
                            fontSize: '12px',
                            bgcolor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            borderRadius: '8px',
                            border: 'none',
                            height: '24px',
                            p: '2px 8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          },
                        }}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                      />
                    </Box>
                  </>
                )}
                {formData.model === 'sec-extractor' && (
                  <>
                    <TagInput
                      nodeLabel={data?.label}
                      onChange={onChange}
                      className="nodrag"
                      name="section"
                      value={formData.section}
                      handleId={'section'}
                      isAdvanced={true}
                      nodeId={id}
                      advancedCollapsed
                      findColor={findColor}
                      isActive={isActiveEdge(edges, id, 'section', 'target')}
                      dataConnected={data?.sectionData || []}
                      removeIcons
                      isCollapsed
                      placeholder={'(Ex: “1A”)'}
                      labelName={'Section'}
                    />

                    <TagInput
                      nodeLabel={data?.label}
                      className="nodrag"
                      onChange={onChange}
                      name="type"
                      value={formData.type}
                      handleId={'type'}
                      dataConnected={data?.typeData || []}
                      isAdvanced={true}
                      removeIcons
                      findColor={findColor}
                      isActive={isActiveEdge(edges, id, 'type', 'target')}
                      nodeId={id}
                      advancedCollapsed
                      isCollapsed
                      placeholder={'Format to receive the data (Ex: “Text”)'}
                      labelName={'Type'}
                    />
                  </>
                )}
              </>
            </Box>
          </div>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              maxOutputHeight={maxOutputHeight}
              // previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data.text}
              activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              placement={data?.placement}
              labelName={'Output'}
              nodeLabel={data?.label}
              // onPreview={onPreview}
              findColor={findColor}
              // onChangePlacement={onChangePlacement}
            />
          </Box>
        </Box>
      </Node>
    </div>
  );
}

export default memo(SecApiNode);
