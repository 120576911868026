import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class AuthService extends ApiService {
  static pathName = '/auth';

  static login = (
    email: string,
    password: string
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/login`, {
      email,
      password,
    });
  };

  static register = (body: any, token: string): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/register?token=${token}`, body);
  };

  static getMe = (signal?: AbortSignal): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/me`);
  };

  static confirmAccount = (userId: string, signal?: AbortSignal) => {
    return this.get(`${this.pathName}/confirm-account/${userId}`);
  };

  static loginWithGoogle = (
    accessToken: string
  ): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/google?accessToken=${accessToken}`);
  };
  static registerWithGoogle = (
    accessToken: string,
    token: string
  ): Promise<IGetOneResponse<any>> => {
    return this.post(
      `${this.pathName}/google-register?accessToken=${accessToken}&token=${token}`
    );
  };
  static loginWithApple = (body: any): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/apple?idToken=${body.accessToken}`);
  };

  static registerWithApple = (body: any, token: string): Promise<IGetOneResponse<any>> => {
    return this.get(
      `${this.pathName}/apple-register?idToken=${body.accessToken}&name=${body.name}&token=${token}`
    );
  };

  static forgotPassword = (
    email: string
  ): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    return this.post(`${this.pathName}/forgot-password`, { email });
  };

  static changePassword = (
    id: string,
    body: {
      password: string;
      confirmPassword: string;
    }
  ): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    return this.post(`${this.pathName}/reset-password/${id}`, body);
  };

  static updatePassword = (body: {
    password: string;
    confirmNewPassword: string;
    newPassword: string;
  }): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    return this.post(`${this.pathName}/change-password`, body);
  };

  static getGoogleRefreshToken = (body: {
    code: string;
    redirect_uri?: string;
  }): Promise<
    IGetOneResponse<{
      access_token: string;
      refresh_token: string;
      expiry_date: number;
    }>
  > => {
    return this.post(`${this.pathName}/google-get-refresh-token`, body);
  };

  static googleRefreshToken = (body: {
    refresh_token: string;
  }): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/google-refresh-token`, body);
  };
}
