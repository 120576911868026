import React from 'react';

const LightIcon = ({ strokeColor = '#475467' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_745_31655)'>
        <path
          d='M9.99967 14.1668C12.3009 14.1668 14.1663 12.3013 14.1663 10.0002C14.1663 7.69898 12.3009 5.8335 9.99967 5.8335C7.69849 5.8335 5.83301 7.69898 5.83301 10.0002C5.83301 12.3013 7.69849 14.1668 9.99967 14.1668Z'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 0.833496V2.50016'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 17.5V19.1667'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5166 3.5166L4.69993 4.69993'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.2998 15.2998L16.4831 16.4831'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.833008 10H2.49967'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.5 10H19.1667'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5166 16.4831L4.69993 15.2998'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.2998 4.69993L16.4831 3.5166'
          stroke={strokeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_745_31655'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightIcon;
