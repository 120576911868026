import React, { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Clipboard, Copy, FilePlus, FlowArrow } from '@phosphor-icons/react';

export interface ContextMenuProps {
  id: string;
  top: number;
  left: number;
  setMenu: (menu: Partial<ContextMenuProps>) => void;
  onCopy: (node: any) => void;
  onPaste: (event: any) => void;
  onDelete: () => void;
  onAddNode: (type: any) => void;
  type?: string;
  setType: (type: string) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  id,
  top,
  left,
  setMenu,
  onCopy,
  onPaste,
  onDelete,
  onAddNode,
  type,
  setType,
}) => {
  const { getNode, addNodes, setEdges, getEdge } = useReactFlow();

  const handleMenuClose = () => {
    setMenu({});
  };

  const handleCopy = useCallback(() => {
    const node = getNode(id);
    const edge = getEdge(id);
    // console.log('edge', edge);
    if (node) {
      onCopy(node);
    }
    handleMenuClose();
  }, [getNode, id, getEdge, handleMenuClose, onCopy]);

  const handleDuplicate = useCallback(() => {
    const node = getNode(id);
    if (!node) return;
    const position = { x: node.position.x + 150, y: node.position.y + 150 };
    const type = {
      type: node.type,
      label: node.data.label,
      position: position,
      data: {
        ...node.data,
        text: null,
        previewResponses: null,
        promptData: null,
        previewIndex: 0,
      },
    };
    onAddNode(type);
    handleMenuClose();
  }, [getNode, id, onAddNode, handleMenuClose]);

  const excludedIdPrefix = 'flow-global-starter';

  return (
    <>
      {type === 'copy' ? (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={{ top, left }}
          open={Boolean(id) && !id.startsWith(excludedIdPrefix)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleCopy}>
            <ListItemIcon>
              <Clipboard size={18} color="black" />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDuplicate}>
            <ListItemIcon>
              <Copy size={18} color="black" />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
          {/* <MenuItem onClick={handleCopy}>
            <ListItemIcon>
              <FlowArrow size={18} color="black" />
            </ListItemIcon>
            <ListItemText>Copy with Connections</ListItemText>
          </MenuItem> */}
        </Menu>
      ) : (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={{ top, left }}
          open={Boolean(id)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={onPaste}>
            <ListItemIcon>
              <FilePlus size={18} color="black" />
            </ListItemIcon>
            <ListItemText>Paste</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default ContextMenu;
