import React from 'react';
import { Link } from 'react-router-dom';

const AdminDashboard: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#3650DE', minHeight: '100vh' }}>
      <h1 style={{ color: '#fff', marginTop: 0, textAlign: 'center', paddingTop: '20px' }}>Admin Dashboard</h1>
      <nav style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Link to="/admin/customer-usage" style={{ color: '#fff', marginRight: '20px', textDecoration: 'none' }}>
          Customer Usage
        </Link>
        <Link to="/admin/user-plan-management" style={{ color: '#fff', textDecoration: 'none' }}>
          User Plan Management
        </Link>
      </nav>
    </div>
  );
};

export default AdminDashboard;
