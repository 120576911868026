import { Box, Typography } from '@mui/material';
import React from 'react';

const ShortText = ({ data }: any) => {
  return (
    <Box
      sx={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        height: 'fit-content',

        borderRadius: data?.fullData?.borderRadius || '0px',
        ...data?.style,
        bgcolor: data?.fullData?.bgColor || '#fff',
      }}
    >
      {data?.label && (
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#475467',

            ...data?.fullData?.titleStyle,
          }}
          variant="h1"
        >
          {data?.label ? data.label : 'Title Component'}
        </Typography>
      )}

      {data?.subtitle && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#98A2B3',
            ...data?.fullData?.subtitleStyle,
          }}
          variant="h1"
        >
          {data?.subtitle ? data.subtitle : 'Title Component'}
        </Typography>
      )}

      {data?.text && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#475467',
            ...data?.fullData?.textStyle,
          }}
          variant="h1"
        >
          {data?.text ? data.text : 'Title Component'}
        </Typography>
      )}
    </Box>
  );
};

export default ShortText;
