export const imgDefaultValues: FormData = {
  engineId: 'sd3',
  name: '',
  cfg_scale: 9,
  height: 1024,
  width: 1024,
  steps: 50,
  clip_guidance_preset: 'NONE',
  type: 'Text to Image',
  src: '',
  promptWeight: 1.0,
  image_strength: 0.0,
  aiModels: 'stabilityAi',
  openSourceModel: 'kandinsky2',
  negativePrompt: '',
  magicPrompt: 'AUTO',
  style_present: 'None',
  style: '',
  style_type: 'GENERAL',
  resolution: 'RESOLUTION_1024_1024',
  view: '',
  subject: '',
  environment: '',
  tone: '',
  sampler: 'AUTO',
  toneDefaultValues: [],
  viewDefaultValues: [],
  openAiModels: 'createImage',
  ideogramModel: 'V_2_TURBO',
  falModel: 'pro',
  imageSize: 'landscape_4_3',
  inference_steps: 28,
  fal_seed: 10,
  guidance_scalefloat: 3.5,
  sync_mode: false,
  num_images: 1,
  safety_tolerance: 2,
  image_weight: 50,
  model: 'dall-e-3',
  prompt: '',
  numbersOfVariations: '',
  size: '1024x1024',
  quality: 'standard',
  sizeGenerate: '1024x1024',
  mode: 'default',
  aspect_ratio: '',
  aspect_ratio_ideogram: 'ASPECT_10_16',
  output_format: 'png',
  influenceStrength: 0,
};

export const versions = [
  {
    version: 'stable-diffusion-512-v2-1',
    dimensions: ['512x896', '896x512'],
  },
  {
    version: 'stable-diffusion-xl-1024-v1-0',
    dimensions: [
      '1024x1024',
      '1152x896',
      '1216x832',
      '1344x768',
      '1536x640',
      '640x1536',
      '768x1344',
      '832x1216',
      '896x1152',
    ],
  },
  {
    version: 'stable-diffusion-xl-1024-v1-0',
    dimensions: [
      '1024x1024',
      '1152x896',
      '1216x832',
      '1344x768',
      '1536x640',
      '640x1536',
      '768x1344',
      '832x1216',
      '896x1152',
    ],
  },
  { version: 'stable-diffusion-v1-6', dimensions: ['320x320', '1536x1536'] },
];

export const openAiModelsImage = [
  {
    value: 'dall-e-2',
    name: 'DALL-E-2',
  },
  {
    value: 'dall-e-3',
    name: 'DALL-E-3',
  },
];

export const ideogramStyleTypes = [
  'GENERAL',
  'REALISTIC',
  'DESIGN',
  'RENDER_3D',
  'ANIME',
];

export const ideogramResolutions = [
  { label: '512x1536', value: 'RESOLUTION_512_1536' },
  { label: '576x1408', value: 'RESOLUTION_576_1408' },
  { label: '576x1472', value: 'RESOLUTION_576_1472' },
  { label: '576x1536', value: 'RESOLUTION_576_1536' },
  { label: '640x1024', value: 'RESOLUTION_640_1024' },
  { label: '640x1344', value: 'RESOLUTION_640_1344' },
  { label: '640x1408', value: 'RESOLUTION_640_1408' },
  { label: '640x1472', value: 'RESOLUTION_640_1472' },
  { label: '640x1536', value: 'RESOLUTION_640_1536' },
  { label: '704x1152', value: 'RESOLUTION_704_1152' },
  { label: '704x1216', value: 'RESOLUTION_704_1216' },
  { label: '704x1280', value: 'RESOLUTION_704_1280' },
  { label: '704x1344', value: 'RESOLUTION_704_1344' },
  { label: '704x1408', value: 'RESOLUTION_704_1408' },
  { label: '704x1472', value: 'RESOLUTION_704_1472' },
  { label: '720x1280', value: 'RESOLUTION_720_1280' },
  { label: '736x1312', value: 'RESOLUTION_736_1312' },
  { label: '768x1024', value: 'RESOLUTION_768_1024' },
  { label: '768x1088', value: 'RESOLUTION_768_1088' },
  { label: '768x1152', value: 'RESOLUTION_768_1152' },
  { label: '768x1216', value: 'RESOLUTION_768_1216' },
  { label: '768x1232', value: 'RESOLUTION_768_1232' },
  { label: '768x1280', value: 'RESOLUTION_768_1280' },
  { label: '768x1344', value: 'RESOLUTION_768_1344' },
  { label: '832x960', value: 'RESOLUTION_832_960' },
  { label: '832x1024', value: 'RESOLUTION_832_1024' },
  { label: '832x1088', value: 'RESOLUTION_832_1088' },
  { label: '832x1152', value: 'RESOLUTION_832_1152' },
  { label: '832x1216', value: 'RESOLUTION_832_1216' },
  { label: '832x1248', value: 'RESOLUTION_832_1248' },
  { label: '864x1152', value: 'RESOLUTION_864_1152' },
  { label: '896x960', value: 'RESOLUTION_896_960' },
  { label: '896x1024', value: 'RESOLUTION_896_1024' },
  { label: '896x1088', value: 'RESOLUTION_896_1088' },
  { label: '896x1120', value: 'RESOLUTION_896_1120' },
  { label: '896x1152', value: 'RESOLUTION_896_1152' },
  { label: '960x832', value: 'RESOLUTION_960_832' },
  { label: '960x896', value: 'RESOLUTION_960_896' },
  { label: '960x1024', value: 'RESOLUTION_960_1024' },
  { label: '960x1088', value: 'RESOLUTION_960_1088' },
  { label: '1024x640', value: 'RESOLUTION_1024_640' },
  { label: '1024x768', value: 'RESOLUTION_1024_768' },
  { label: '1024x832', value: 'RESOLUTION_1024_832' },
  { label: '1024x896', value: 'RESOLUTION_1024_896' },
  { label: '1024x960', value: 'RESOLUTION_1024_960' },
  { label: '1024x1024', value: 'RESOLUTION_1024_1024' },
  { label: '1088x768', value: 'RESOLUTION_1088_768' },
  { label: '1088x832', value: 'RESOLUTION_1088_832' },
  { label: '1088x896', value: 'RESOLUTION_1088_896' },
  { label: '1088x960', value: 'RESOLUTION_1088_960' },
  { label: '1120x896', value: 'RESOLUTION_1120_896' },
  { label: '1152x704', value: 'RESOLUTION_1152_704' },
  { label: '1152x768', value: 'RESOLUTION_1152_768' },
  { label: '1152x832', value: 'RESOLUTION_1152_832' },
  { label: '1152x864', value: 'RESOLUTION_1152_864' },
  { label: '1152x896', value: 'RESOLUTION_1152_896' },
  { label: '1216x704', value: 'RESOLUTION_1216_704' },
  { label: '1216x768', value: 'RESOLUTION_1216_768' },
  { label: '1216x832', value: 'RESOLUTION_1216_832' },
  { label: '1232x768', value: 'RESOLUTION_1232_768' },
  { label: '1248x832', value: 'RESOLUTION_1248_832' },
  { label: '1280x704', value: 'RESOLUTION_1280_704' },
  { label: '1280x720', value: 'RESOLUTION_1280_720' },
  { label: '1280x768', value: 'RESOLUTION_1280_768' },
  { label: '1280x800', value: 'RESOLUTION_1280_800' },
  { label: '1312x736', value: 'RESOLUTION_1312_736' },
  { label: '1344x640', value: 'RESOLUTION_1344_640' },
  { label: '1344x704', value: 'RESOLUTION_1344_704' },
  { label: '1344x768', value: 'RESOLUTION_1344_768' },
  { label: '1408x576', value: 'RESOLUTION_1408_576' },
  { label: '1408x640', value: 'RESOLUTION_1408_640' },
  { label: '1408x704', value: 'RESOLUTION_1408_704' },
  { label: '1472x576', value: 'RESOLUTION_1472_576' },
  { label: '1472x640', value: 'RESOLUTION_1472_640' },
  { label: '1472x704', value: 'RESOLUTION_1472_704' },
  { label: '1536x512', value: 'RESOLUTION_1536_512' },
  { label: '1536x576', value: 'RESOLUTION_1536_576' },
  { label: '1536x640', value: 'RESOLUTION_1536_640' },
];

export const magicPromptTypes = ['AUTO', 'ON', 'OFF'];

export interface FormData {
  whoIsChanging?: any;
  engineId: string;
  name: string;
  aiModels: string;
  cfg_scale: number;
  height: number;
  width: number;
  steps: number;
  clip_guidance_preset: string;
  type: string;
  src: string;
  promptWeight: number;
  image_strength: number;
  negativePrompt: string;
  style_present: string;
  style: string;
  style_type: string;
  resolution: string;
  image_weight: number;
  openSourceModel: string;
  view: string;
  subject: string;
  environment: string;
  tone: string;
  sampler: string;
  toneDefaultValues: [];
  viewDefaultValues: [];
  openAiModels: string;
  prompt: string;
  numbersOfVariations: string;
  size: string;
  quality: string;
  sizeGenerate: string;
  inputPreview?: string;
  uploadImage?: File | null;
  model: string;
  ideogramModel: string;
  aspect_ratio_ideogram: string;
  magicPrompt: string;
  falModel: string;
  imageSize: string;
  inference_steps: number;
  fal_seed: number;
  guidance_scalefloat: number;
  sync_mode: boolean | string;
  num_images: number;
  safety_tolerance: number;
  aiEditSelectedType?: string;

  expandedImagePreview?: string;
  dimensions?: { top: number; left: number; right: number; bottom: number };

  aiEraseSelectedType?: string;
  selectedEnhancedResolution?: string;

  maskInputPreview?: string;
  maskInputPreviewToShow?: string;
  Data?: any;
  searchPrompt?: string;

  imageToImageSelectedType?: string;
  influenceStrength?: number;

  aspect_ratio?: string;
  mode?: string;
  output_format?: string;
  selectedImageToImageAspectRatio?: string;
}

export const imageTypes = [
  'prompt',
  'subject',
  'environment',
  'tone',
  'view',
  'style',
  'negativePrompt',
];
export const aiImageGeneratorInputs = [
  {
    name: 'videoPrompt',
    labelName: 'Prompt',
    placeholder:
      'Connect or type a description of the video you want to generate.',
    handleId: 'videoPrompt',
    isCollapsed: true,
  },
  {
    name: 'prompt',
    labelName: 'Prompt',
    placeholder:
      'Connect or type a description of the image you want to generate.',
    handleId: 'prompt',
    isCollapsed: true,
  },
  {
    name: 'subject',
    labelName: 'Subject',
    placeholder: 'Connect or type the subject of your image.',
    handleId: 'subject',
    isCollapsed: false,
  },
  {
    name: 'environment',
    labelName: 'Environment',
    placeholder: 'Connect or type the environment your subject will be in.', // Add placeholder if needed
    handleId: 'environment',
    isCollapsed: false,
  },
  {
    name: 'tone',
    labelName: 'Tone',
    placeholder: 'Connect or type the color and lighting of your image.', // Add placeholder if needed
    handleId: 'tone',
    isCollapsed: false,
  },
  {
    name: 'view',
    labelName: 'View',
    placeholder: 'Connect or type the angle of view of your image.', // Add placeholder if needed
    handleId: 'view',
    isCollapsed: false,
  },
  {
    name: 'style',
    labelName: 'Style',
    placeholder: 'Connect or type the style you want your image to follow.', // Add placeholder if needed
    handleId: 'style',
    isCollapsed: false,
  },
  {
    name: 'negativePrompt',
    labelName: 'Negative Prompt',
    placeholder: 'Connect or type ',
    handleId: 'negativePrompt',
    isCollapsed: false,
  },
];
