export const isActiveEdge = (
  edges: any[],
  id: any,
  inputName: any,
  customSourceOrTarget: string
) => {
  return edges?.find(edge => {
    const customHandle =
      customSourceOrTarget === 'target' ? 'targetHandle' : 'sourceHandle';

    const customMatch =
      edge?.[customSourceOrTarget] === id && edge?.[customHandle] === inputName;
    return customMatch;
  })
    ? true
    : false;
};
