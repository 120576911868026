import { Box } from '@mui/material';
import React from 'react';
import { Handle, HandleType, Position } from 'reactflow';
import { useAuthStore } from '../../../store/storeAuth';

const InputHandle = ({
  handleId,
  isActive = false,
  activeColor,
  type,
  position = Position.Left,
  left = -16,
  right = -33,
  top = '10px',
  backgroundColorDefault = '',
}: {
  handleId?: string;
  isActive?: boolean;
  activeColor?: string;
  type: HandleType;
  position?: string;
  left?: number | string;
  right?: number | string;
  top?: number | string;
  backgroundColorDefault?: string;
}) => {
  const { user } = useAuthStore(state => state);
  return (
    <Handle
      id={handleId}
      style={{
        top: top,
        left: position === 'left' ? left : 'unset',
        right: position === 'right' ? right : 'unset',
        backgroundColor: backgroundColorDefault
          ? backgroundColorDefault
          : isActive
          ? user?.theme === 'dark'
            ? '#1D2939'
            : '#E7F0FF'
          : user?.theme === 'dark'
          ? '#344054'
          : '#EAECF0',
        backgroundImage: isActive
          ? 'inherit'
          : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23667085FF' stroke-width='3' stroke-dasharray='30%25%2c 13%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e")`,

        border: isActive ? `1px solid ${activeColor}` : 'none',
        width: '18px',
        height: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      position={position as Position}
      type={type}
    >
      {/* {(!isActive || isActive === undefined) && (
        <Box
          width={'95%'}
          height={'95%'}
          bgcolor={'#EAECF0'}
          borderRadius={'100%'}
        />
      )} */}
    </Handle>
  );
};

export default InputHandle;
