import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { voicePlayHt } from './constants';
import { UserConfigService } from '../../service/UserConfigService';
import { useAuthStore } from '../../store/storeAuth';
import KitModal from '../UI/KitModal';
import { Box, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import PlayHtConfigForm from '../settings/AiConfig/PlayHtConfigForm';
import ElevenlabsConfigForm from '../settings/AiConfig/ElevenlabsConfigForm';
import { useNotificationStore } from '../../store/storeNotifications';

type ConfigValues = {
  name: string;
  voice: string;
  speed: string;
  quality: string;
  outputFormat: string;
  sampleRate: string;
  voiceId: string;
  stability: string;
  similarity_boost: string;
  style: string;
  use_speaker_boost: string;
  voiceModel: string;
};
type Props = {
  show: boolean;
  onHide: () => void;
  isEditingConfig?: {
    id: string;
    edit: boolean;
  };
  setIsEditingConfig?: (editing: { id: string; edit: boolean }) => void;
  saveChanges: () => void;
  audioSystems: string[];
};

export type VoiceModalHandle = {
  submitForm: () => void;
};

const VoiceModal = forwardRef<VoiceModalHandle, Props> (({
  show,
  onHide,
  isEditingConfig,
  setIsEditingConfig,
  saveChanges,
  audioSystems,
}: Props, ref) => {
  const [systemType, setSystemType] = useState('evenlabs.io');
  const { setConfigs, configs } = useAuthStore(state => state);

  const setNotification = useNotificationStore(state => state.setNotification);

  const audioConfig = configs?.find(config => config.type === 'voice');

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      // name: isEditingConfig?.id ? audioConfig?.name : '',
      ...voicePlayHt,
      ...audioConfig?.aiConfig,
      evenLabsModel: 'even-labs-clone'
    },
  });

  useEffect(() => {
    if (isEditingConfig?.edit) {
      if (audioConfig) {
        setSystemType(audioConfig.modelType);
        reset({
          // name: audioConfig?.name,
          ...voicePlayHt,
          ...audioConfig?.aiConfig,
          evenLabsModel: 'even-labs-clone'
        });
      }
    }
  }, [isEditingConfig]);

  const getAiConfigsToBeSaved = (systemType: string, values: ConfigValues) => {
    if (systemType === 'play.ht') {
      return {
        voice: values.voice,
        voiceModel: values.voiceModel,
        speed: values.speed,
        quality: values.quality,
        outputFormat: values.outputFormat,
        sampleRate: values.sampleRate,
      };
    } else if (systemType === 'elevenlabs.io') {
      return {
        voiceId: values.voiceId,
        stability: values.stability,
        similarity_boost: values.similarity_boost,
        style: values.style,
        use_speaker_boost: values.use_speaker_boost,
      };
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onFinish),
  }));

  const onFinish = async (values: ConfigValues) => {
    try {
      if (isEditingConfig?.edit && isEditingConfig?.id) {
        const response = await UserConfigService.updateConfig(
          isEditingConfig.id,
          {
            aiConfig: getAiConfigsToBeSaved(systemType, values),
            modelType: systemType,
            name: 'voice',
          }
        );

        const newConfigs = configs.map((config) => {
          if (config.id === isEditingConfig?.id) {
            return {
              ...config,
              name: 'voice',
              aiConfig: response.data.aiConfig,
              type: "voice",
              modelType: systemType,
            };
          }
          return config;
        });
        setConfigs(newConfigs);
        setIsEditingConfig!({
          id: "",
          edit: false,
        });

        return;
      }

      const response = await UserConfigService.createConfig({
        aiConfig: getAiConfigsToBeSaved(systemType, values),
        name: 'voice',
        type: "voice",
        modelType: systemType,
      });

      setConfigs([...configs, response.data]);
      setIsEditingConfig!({
        id: "",
        edit: false,
      });
    } catch (error: any) {
      setNotification({
        message: error?.response?.data?.message || "Something went wrong",
        type: "error",
      });
    }
  };

  const revertToDefault = () => {
    setSystemType('play.ht');
    reset({
      ...voicePlayHt,
    });
  }

  const onClose = () => {
    setIsEditingConfig!({
      id: '',
      edit: false,
    });
    onHide();
  };

  const confirmChanges = () => {
    onHide();
    saveChanges();
  };

  return (
    <KitModal
      onHide={() => {
        onClose();
      }}
      show={show}
      // sx={{
      //   "& .MuiPaper-root": {
      //     width: "540px",
      //   },
      // }}
    >
      <Typography
        fontSize={'20px'}
        fontWeight={500}
        lineHeight={'32px'}
        color={'#000'}
        // mb={"32px"}
      >
        Audio Configurations
      </Typography>
      <form
        action="submit"
        onSubmit={handleSubmit(onFinish)}
        style={{
          paddingTop: '10px',
          minWidth: '500px',
          overflow: 'hidden',
        }}
      >
        {systemType === 'play.ht' && (
          <PlayHtConfigForm
            systems={audioSystems}
            errors={errors}
            register={register}
            systemType={systemType}
            setSystemType={setSystemType}
            watch={watch}
            control={control}
            setValue={setValue}
          />
        )}

        {systemType === 'elevenlabs.io' && (
          <ElevenlabsConfigForm
            systems={audioSystems}
            errors={errors}
            register={register}
            systemType={systemType}
            setSystemType={setSystemType}
            watch={watch}
            control={control}
            setValue={setValue}
          />
        )}

        {/* <Box>
          <KitInput
            label='Name'
            name='name'
            type='text'
            errorsStatus={errors}
            register={register}
          />
          <Box
            mt={'16px'}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '32px',
            }}
          >
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id='demo-simple-select-label'>Ai System</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Ai System'
                value={systemType}
                name='aiSystem'
                onChange={e => {
                  setSystemType(e.target.value);
                }}
                sx={{
                  width: '100%',
                }}
              >
                <MenuItem value={'play.ht'} disabled={false}>
                  play.ht
                </MenuItem>
                <MenuItem value={'elevenlabs.io'} disabled={false}>
                  elevenlabs.io
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                width: '100%',
              }}
            >
              <InputLabel id='demo-simple-select-label'>Voice</InputLabel>
              <Select
                value={watch()?.voice}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Voice'
                {...register('voice')}
              >
                {voices
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map(voice => (
                    <MenuItem key={voice.id} value={voice.name}>
                      {voice.name} ({voice.language})
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Divider
            sx={{
              marginY: '16px',
            }}
          />

          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <InputLabel id='demo-simple-select-label'>Quality</InputLabel>
            <Select
              value={watch()?.quality}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Quality'
              {...register('quality')}
            >
              <MenuItem value='draft'>Draft</MenuItem>
              <MenuItem value='low'>Low</MenuItem>
              <MenuItem value='medium'>Medium</MenuItem>
              <MenuItem value='high'>High</MenuItem>
              <MenuItem value='premium'>Premium</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: '100%',
              mt: '16px',
            }}
          >
            <InputLabel id='demo-simple-select-label'>Output Format</InputLabel>
            <Select
              value={watch()?.outputFormat}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Output Format'
              {...register('outputFormat')}
            >
              <MenuItem value='mp3'>MP3</MenuItem>
              <MenuItem value='wav'>WAV</MenuItem>
              <MenuItem value='ogg'>OGG</MenuItem>
              <MenuItem value='flac'>FLAC</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label='Speed'
            {...register('speed')}
            sx={{
              mt: '16px',
              width: '100%',
            }}
            type='number'
          />
          <TextField
            label='Sample Rate'
            {...register('sampleRate')}
            sx={{
              mt: '16px',
              width: '100%',
            }}
            type='number'
          />
        </Box> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '32px',
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: '100%', mt: '16px' }}
            onClick={revertToDefault}
          >
            Revert to default
          </Button>
          <Button
            variant="contained"
            sx={{ width: '100%', mt: '16px' }}
            onClick={confirmChanges}
          >
            Continue
          </Button>
        </Box>
      </form>
    </KitModal>
  );
});

export default VoiceModal;
