export const downloadCSVFile = function (csvData: Blob, fileName: string) {
  const a = document.createElement('a');
  const url = URL.createObjectURL(csvData);
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

export const getAPIErrorMessage = function (error: any): string {
  if (typeof error === 'string') return error;

  if (typeof error === 'object') {
    if (typeof error.message === 'string') {
      return error.message;
    } else if (Array.isArray(error.message)) {
      return error.message[0];
    }
  }

  return 'Something went wrong!';
};

export function base64ToBlob(base64: string, contentType: string = ''): Blob {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
