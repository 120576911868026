import React, { useState, useEffect } from 'react';
import { UserService } from '../../service/UserService';
import { AuthService } from '../../service/AuthService';
import { Button } from '@mui/material';
import KitModal from '../UI/KitModal';
import { useNotificationStore } from '../../store/storeNotifications';

const ColorModal = ({
  colorModalOpen,
  setColorModalOpen,
  colors,
  setColors,
  setAuthUser,
}: any) => {
  const [oldColors, setOldColors] = useState(colors);
  const setNotification = useNotificationStore(state => state.setNotification);

  useEffect(() => {
    if (colorModalOpen) {
      setOldColors(colors);
    }
  }, [colorModalOpen]);

  const handleColorChange = (index: number, newColor: any) => {
    const updatedColors = [...colors];
    updatedColors[index] = newColor;
    setColors(updatedColors);
  };

  const addColor = () => {
    setColors([...colors, '#000000']);
  };

  const removeColor = (index: any) => {
    const updatedColors = colors.filter((_: any, i: any) => i !== index);
    setColors(updatedColors);
  };

  const onSave = async () => {
    try {
      await UserService.updateUser({
        backgroundColor: colors,
      });
      const res = await AuthService.getMe();

      setAuthUser(res.data);
      setColorModalOpen(false);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Failed to update background color',
      });
    }
  };

  const onCancel = () => {
    setColors(oldColors);
    setColorModalOpen(false);
  };

  return (
    <div>
      <KitModal
        show={colorModalOpen}
        fullWidth
        onHide={onCancel}  
        title={`Background Color`}
        footer={
          <>
            <Button variant='contained' onClick={onSave}>
              Save
            </Button>
          </>
        }
      >
        <div>
          {colors.map((color: any, index: any) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '5px',
              }}
            >
              <input
                type='color'
                value={color}
                onChange={e => handleColorChange(index, e.target.value)}
              />
              {colors.length > 2 && (
                <button onClick={() => removeColor(index)}>Remove</button>
              )}
            </div>
          ))}
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button variant='contained' onClick={addColor}>
              Add Color
            </Button>
            <Button variant='contained' onClick={() => setColors([])}>
              Add Default FluxPrompt Color
            </Button>
          </div>
        </div>
      </KitModal>
    </div>
  );
};

export default ColorModal;
