import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import {
  Serp,
  SerpResponseLocations,
  SerpResponseMedia,
  SerpResponseNews,
  SerpResponseSearch,
  SerpResponseTrends,
  SerpResponsePatents,
  SerpResponsePatentDetails,
} from '../util/Types/Serp';
// import { User } from '../Utilis/Types/User';
export class SerpService extends ApiService {
  static pathName = '/serp';

  static search = (
    body: Partial<Serp>,
  ): Promise<IGetOneResponse<SerpResponseSearch>> => {
    return this.post(`${this.pathName}/search`, body);
  };

  static bingSearch = (
    body: Partial<Serp>,
  ): Promise<IGetOneResponse<SerpResponseSearch>> => {
    return this.post(`${this.pathName}/bing-search`, body);
  };

  static image = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseMedia>> => {
    return this.post(`${this.pathName}/image`, body);
  };

  static bingImage = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseMedia>> => {
    return this.post(`${this.pathName}/bing-images`, body);
  };

  static places = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseLocations>> => {
    return this.post(`${this.pathName}/places`, body);
  };

  static patents = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponsePatents>> => {
    return this.post(`${this.pathName}/patents`, body);
  };

  static patentDetails = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponsePatentDetails>> => {
    return this.post(`${this.pathName}/patents-details`, body);
  };

  static news = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseNews>> => {
    return this.post(`${this.pathName}/news`, body);
  };

  static bingNews = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseNews>> => {
    return this.post(`${this.pathName}/bing-news`, body);
  };

  static bingShopping = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseNews>> => {
    return this.post(`${this.pathName}/bing-shopping`, body);
  };

  static trends = (
    body: Partial<Serp>,
  ): Promise<IGetAllResponse<SerpResponseTrends>> => {
    return this.post(`${this.pathName}/trends`, body);
  };

  static trendsNow = (body: any): Promise<IGetAllResponse<any>> => {
    return this.post(`${this.pathName}/trends-now`, body);
  };
  static googleLens = (body: any): Promise<IGetAllResponse<any>> => {
    return this.post(`${this.pathName}/google-lens`, body);
  };
  static googleShop = (body: any): Promise<IGetAllResponse<any>> => {
    return this.post(`${this.pathName}/google-shop`, body);
  };
}
