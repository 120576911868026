import { Box, MenuItem, Select, Typography } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import ConnectToGoogleSheets from '../../GoogleSheetsIntegration/ConnectToGoogleSheets';
import Label from '../../UI/Label/Label';
import { GoogleService } from '../PullDataNode';
import PullPublicGoogleSheetsData from './PullPublicGoogleSheetsData';
import PullPublicGoogleDocsData from './PullPublicGoogleDocsData';
import TagInput from '../../Test/TagInput';

const NoConnection = ({
  connection,
  nodeType,
  data,
}: {
  connection: boolean;
  nodeType: 'pullData' | 'pushData';
  data: any;
}) => {
  const [inputId, setInputId] = useState('');

  const inputIdChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputId(e.target.value);
  };

  useEffect(() => {
    if (connection) setInputId('');
  }, [connection]);

  return (
    <>
      {!connection && (
        <>
          {nodeType === 'pullData' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Select
                className="nodrag"
                name="selectedGoogleService"
                value={data?.selectedPublicGoogleService}
                onChange={e =>
                  data?.setSelectedPublicGoogleService(
                    e.target.value as GoogleService,
                  )
                }
              >
                {Object.values(GoogleService).map(googleService => (
                  <MenuItem key={googleService} value={googleService}>
                    {googleService}
                  </MenuItem>
                ))}
              </Select>

              <TagInput
                placeholder="Insert Public Google Doc/Spreadsheet ID"
                onChange={inputIdChangeHandler}
                name="rangeFilter"
                value={inputId}
                isAdvanced
                showCopy
                removeIcons
                labelName={'Public Google Doc/Spreadsheet ID'}
                isCollapsed
                labelStyle={{
                  fontSize: '14px',
                  color: '#101828',
                  fontWeight: 400,
                  margin: 0,
                }}
                advancedCollapsed
              />

              {data?.selectedPublicGoogleService ===
                GoogleService.GoogleSheets && (
                <PullPublicGoogleSheetsData data={data} inputId={inputId} />
              )}

              {data?.selectedPublicGoogleService ===
                GoogleService.GoogleDocs && (
                <PullPublicGoogleDocsData inputId={inputId} data={data} />
              )}
            </Box>
          )}

          <Label isAdvanced labelName="Database" />
          <Box
            sx={{
              p: '24px 20px',
              mb: '24px',
              borderRadius: '4px',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              display: 'flex',
              width: '386px',

              height: '146px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Info color="#98A2B3" size={32} />
            <Typography
              sx={{
                fontSize: '12px',
                color: '#98A2B3',
                textAlign: 'center',
              }}
            >
              <ConnectToGoogleSheets /> to log into Google so you can access
              your spreadsheets/docs and put them in workflows.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default NoConnection;
