import KitModal from '../../UI/KitModal';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { searchEngines } from '../../nodes/WebSearch';
import { countries } from '../../../util/countries';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant="h6">Settings</Typography>
        <Box>
          <Select
            name="engine"
            label="Search Engine"
            className="nodrag"
            value={formData.engine}
            onChange={onChange}
          >
            {searchEngines.map((engine, index) => (
              <MenuItem
                key={index}
                disabled={engine.disabled}
                value={engine.value}
              >
                {engine.label}
              </MenuItem>
            ))}
          </Select>

          <InputLabel htmlFor="country">Country</InputLabel>
          <Select
            name="country"
            className="nodrag"
            value={formData.country}
            onChange={onChange}
          >
            {countries.map((country, index) => (
              <MenuItem key={index} value={country.name}>
                {country.flag} {country.name} ({country.code})
              </MenuItem>
            ))}
          </Select>
          <TextField
            name="results"
            type="number"
            placeholder="Results"
            onChange={onChange}
            value={formData.results}
          />

          <InputLabel htmlFor="language">Language</InputLabel>
          <Select
            name="language"
            className="nodrag"
            value={formData.language}
            onChange={onChange}
          >
            {countries.map((country, index) => (
              <MenuItem key={index} value={country.name}>
                {country.name} ({country.langCode})
              </MenuItem>
            ))}
          </Select>

          <FormControlLabel
            control={
              <Checkbox
                name="autocorrect"
                checked={formData.autocorrect}
                onChange={onChange}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}
              />
            }
            label="Auto Correct"
          />
        </Box>

        {formData.type === 'Location' && (
          <>
            <TextField
              name="start"
              type="number"
              placeholder="Starting Pages multiply by 20"
              onChange={onChange}
              value={formData.start}
            />
            <TextField
              name="lat"
              type="text"
              placeholder="Latitude"
              onChange={onChange}
              value={formData.lat}
            />
            <TextField
              name="lng"
              type="text"
              placeholder="Longitude"
              onChange={onChange}
              value={formData.lng}
            />
          </>
        )}
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
