import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useAuthStore } from '../../../store/storeAuth';
import {
  AiTextGeneratorFormValues,
  systemDisplayNameMap,
  systemValueMap,
} from '../../AiConfigs/constants';
import KitModal from '../../UI/KitModal';
import Gemini from '../Gemini';
import GroqAi from '../GroqAi';
import OpenAi from '../OpenAi';
import OpenSources from '../OpenSources';
import useFlowsStore from '../../../store/storeFlows';
import { useEffect, useState } from 'react';
import { CompanySpacesService } from '../../../service/CompanySpacesService';
import { PrettoSlider } from '../../../assets/styles/styles';
type Props = {
  onHide: () => void;
  show: boolean;
  formData: AiTextGeneratorFormValues;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  const {
    user,
    technologies,
    companySpaceTechnologies,
    setCompanySpaceTechnologies,
  } = useAuthStore(state => state);
  const { spaceId } = useFlowsStore();
  const [anthropicAutoSettings, setAnthropicAutoSettings] = useState(false);
  const [perplexityAutoSettings, setPerplexityAutoSettings] = useState(false);

  useEffect(() => {
    if (!spaceId) {
      return;
    }
    const fetchAllTechnologies = async () => {
      try {
        // setIsLoading(true);
        const res = await CompanySpacesService.getSpaceTechnologies(
          spaceId as string,
        );
        setCompanySpaceTechnologies(res.data);
      } catch (error) {
        console.log('Error fetching company technologies:', error);
      }

      return;
    };

    fetchAllTechnologies();
  }, []);

  const textSystems = [
    'anthropic',
    'openAi',
    'groq',
    'gemini',
    'perplexity',
    'replicate',
  ];

  const filteredTextSystems =
    spaceId && companySpaceTechnologies.length > 0
      ? companySpaceTechnologies
          ?.filter(system => textSystems?.includes(system?.system))
          ?.map(system => ({
            ...system,
            models: system?.models?.filter(
              (model: any) => model.type === 'Text',
            ),
          }))
      : technologies
          ?.filter(system => textSystems.includes(system.system))
          ?.map(system => ({
            ...system,
            models: system?.models?.filter(
              (model: any) => model.type === 'Text',
            ),
          }));

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '650px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography
          variant="h6"
          color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
        >
          Advanced Settings
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          mt={'16px'}
          p={'8px'}
          borderRadius={'4px'}
        >
          <Typography
            fontSize={'14px'}
            variant="h6"
            color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
          >
            Prompt Input
          </Typography>

          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
            <Select
              name="AiSystem"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Ai System"
              value={formData.AiSystem}
              onChange={onChange}
              sx={{
                width: '100%',
                '& .MuiList-root': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                border: `2px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`,
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                    },
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                },
              }}
            >
              {filteredTextSystems?.map((opt, index) => {
                const disabled = opt?.models?.every(
                  (model: any) => model.enabled === false,
                );
                const disabledByCompany = opt?.models?.every(
                  (model: any) => model.disabledByCompany,
                );
                const systemValue = systemValueMap[opt.system] || opt.system;
                const displayName =
                  systemDisplayNameMap[systemValue] || systemValue;
                return (
                  <MenuItem key={index} value={systemValue} disabled={disabled}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>{displayName}</Typography>
                      {disabledByCompany && (
                        <Chip
                          label="Disabled by admin"
                          color="error"
                          size="small"
                          variant="filled"
                          sx={{
                            borderRadius: '8px',
                          }}
                        />
                      )}
                    </Box>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {formData.AiSystem === 'OpenAI' && (
            <OpenAi
              systems={filteredTextSystems}
              onChange={onChange}
              watch={formData}
              tokenCount={0}
            />
          )}

          {formData.AiSystem === 'Groq' && (
            <GroqAi
              onChange={onChange}
              watch={formData}
              systems={filteredTextSystems}
            />
          )}
          {formData.AiSystem === 'Google' && (
            <>
              <FormLabel
                sx={{
                  mb: '4px',
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
                htmlFor="googleType"
              >
                Google System
              </FormLabel>
              <br />
              <Select
                className="nodrag"
                value={formData.googleType}
                size="small"
                onChange={onChange}
                name={'googleType'}
                sx={{
                  '& .MuiList-root': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                      },
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                  },
                }}
              >
                <MenuItem
                  key={'Gemini'}
                  value={'Gemini'}
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  {'Gemini'}
                </MenuItem>
              </Select>
            </>
          )}

          {formData.AiSystem === 'Gemini' && (
            <Gemini
              imageSystem
              watch={formData}
              onChange={onChange}
              systems={filteredTextSystems}
            />
          )}

          {formData.AiSystem === 'openSources' && (
            <OpenSources
              watch={formData}
              onChange={onChange}
              systems={filteredTextSystems}
            />
          )}

          {formData.AiSystem === 'perplexityAi' && (
            <>
              <Box>
                <FormLabel
                  sx={{
                    mb: '8px',
                    mt: '8px',
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  Type
                </FormLabel>
                <Select
                  className="nodrag"
                  value={formData.perplexityType}
                  fullWidth
                  size="small"
                  onChange={onChange}
                  name={'perplexityType'}
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  {filteredTextSystems
                    ?.filter((item: any) => item.system === 'perplexity')?.[0]
                    ?.models?.map((opt: any) => (
                      <MenuItem
                        key={opt.id}
                        value={opt.model}
                        disabled={opt.disabledByCompany}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                          <Typography
                            variant="body2"
                            color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                          >
                            {opt.model}
                          </Typography>
                          {opt.disabledByCompany && (
                            <Typography fontSize={'12px'} color="red">
                              (disabled by admin)
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: '20px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={e => {
                        onChange({
                          target: {
                            name: 'autoToken',
                            value: !formData.autoToken,
                          },
                        });

                        setPerplexityAutoSettings(!formData.autoToken);
                      }}
                      name="autoToken"
                      checked={formData.autoToken}
                      sx={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    />
                  }
                  label="Auto setting"
                />
                <FormControl>
                  <TextField
                    id="demo-simple-select"
                    type={'number'}
                    onChange={onChange}
                    value={
                      perplexityAutoSettings ? 0 : formData.perplexityTokens
                    }
                    disabled={perplexityAutoSettings}
                    className="nodrag"
                    label={'Output Token Max'}
                    name={'max_tokens'}
                    InputProps={{
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    sx={{
                      width: '290px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Top P
                </FormLabel>
                <PrettoSlider
                  step={1}
                  min={1}
                  name={'perplexityTopP'}
                  value={Number(formData.perplexityTopP)}
                  max={40}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
                <TextField
                  type={'number'}
                  value={
                    formData[
                      'perplexityTopP' as keyof AiTextGeneratorFormValues
                    ]
                  }
                  name={'perplexityTopP'}
                  onChange={e => {
                    onChange(e);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 40,
                      step: 1,
                    },
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  style={{ marginLeft: '8px', width: '80px' }}
                  className="nodrag"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Temperature (Creativity of LLM response)
                </FormLabel>
                <PrettoSlider
                  step={0.1}
                  min={0}
                  name={'perplexityTemperature'}
                  value={Number(formData.perplexityTemperature)}
                  max={1}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
                <TextField
                  type={'number'}
                  value={
                    formData[
                      'perplexityTemperature' as keyof AiTextGeneratorFormValues
                    ]
                  }
                  name={'perplexityTemperature'}
                  onChange={e => {
                    onChange(e);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 1,
                      step: 0.1,
                    },
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  style={{ marginLeft: '8px', width: '80px' }}
                  className="nodrag"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Top K
                </FormLabel>
                <PrettoSlider
                  step={1}
                  min={1}
                  name={'perplexityTopK'}
                  value={Number(formData.perplexityTopK)}
                  max={40}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
                <TextField
                  type={'number'}
                  value={
                    formData[
                      'perplexityTopK' as keyof AiTextGeneratorFormValues
                    ]
                  }
                  name={'perplexityTopK'}
                  onChange={e => {
                    onChange(e);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 40,
                      step: 1,
                    },
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  style={{ marginLeft: '8px', width: '80px' }}
                  className="nodrag"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
            </>
          )}

          {formData.AiSystem === 'anthropicAi' && (
            <>
              <Box>
                <FormLabel
                  sx={{
                    mb: '4px',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Model
                </FormLabel>

                <Select
                  className="nodrag"
                  value={formData.anthropicModel}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  name={'anthropicModel'}
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  {filteredTextSystems
                    ?.filter((item: any) => item?.system === 'anthropic')?.[0]
                    ?.models?.map((opt: any) => (
                      <MenuItem
                        key={opt.id}
                        value={opt.model}
                        disabled={opt.disabledByCompany}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                          <Typography variant="body2">{opt?.model}</Typography>
                          {opt.disabledByCompany && (
                            <Typography fontSize={'12px'} color="red">
                              (disabled by admin)
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: '20px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={e => {
                        onChange({
                          target: {
                            name: 'autoToken',
                            value: !formData.autoToken,
                          },
                        });

                        setAnthropicAutoSettings(!formData.autoToken);
                      }}
                      name="autoToken"
                      checked={formData.autoToken}
                      sx={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    />
                  }
                  label="Auto setting"
                />
                <FormControl>
                  <TextField
                    id="demo-simple-select"
                    type={'number'}
                    onChange={onChange}
                    value={anthropicAutoSettings ? 0 : formData.anthropicToken}
                    disabled={anthropicAutoSettings}
                    className="nodrag"
                    label={'Output Token Max'}
                    name={'max_tokens'}
                    InputProps={{
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    sx={{
                      width: '290px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Top K
                </FormLabel>
                <PrettoSlider
                  step={1}
                  min={1}
                  name={'anthropicTopK'}
                  value={Number(formData.anthropicTopK)}
                  max={40}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
                <TextField
                  type={'number'}
                  value={
                    formData['anthropicTopK' as keyof AiTextGeneratorFormValues]
                  }
                  name={'anthropicTopK'}
                  onChange={e => {
                    onChange(e);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 40,
                      step: 1,
                    },
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  style={{ marginLeft: '8px', width: '80px' }}
                  className="nodrag"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Top P
                </FormLabel>
                <PrettoSlider
                  step={1}
                  min={1}
                  name={'anthropicTopP'}
                  value={Number(formData.anthropicTopP)}
                  max={40}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
                <TextField
                  type={'number'}
                  value={
                    formData['anthropicTopP' as keyof AiTextGeneratorFormValues]
                  }
                  name={'anthropicTopP'}
                  onChange={e => {
                    onChange(e);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 40,
                      step: 1,
                    },
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  style={{ marginLeft: '8px', width: '80px' }}
                  className="nodrag"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormLabel
                  sx={{
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  Temperature (Creativity of LLM response)
                </FormLabel>
                <PrettoSlider
                  step={0.1}
                  min={0}
                  name={'anthropicTemperature'}
                  value={Number(formData.anthropicTemperature)}
                  max={1}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
                <TextField
                  type={'number'}
                  value={
                    formData[
                      'anthropicTemperature' as keyof AiTextGeneratorFormValues
                    ]
                  }
                  name={'anthropicTemperature'}
                  onChange={e => {
                    onChange(e);
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 1,
                      step: 0.1,
                    },
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  style={{ marginLeft: '8px', width: '80px' }}
                  className="nodrag"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
