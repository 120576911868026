import { memo, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Handle, NodeResizer, Position, useEdges } from 'reactflow';
import ChangeLabel from '../UI/ChangeLabel/ChangeLabel';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { useStore as useStoreReactflow } from 'reactflow';
import { CustomHandle } from '../CustomHandle';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import DeleteNode from '../UI/DeleteNode/DeleteNode';
import { NodeProps } from '../../util/Types/NodeProps';

import { StyledNode } from '../../assets/styles/styles';
import SourceHandle from '../SourceHandle';
import { CustomSourceHandle } from '../CustomSourceHandle';
import Table, { generateUniqueId } from '../TableProcessor/Table';
import { CustomTargetHandle } from '../CustomTargetHandle';
import TargetHandle from '../TargetHandle';
import { getNodesConnectedToflow } from '../../util/getNodesConnectedToflow';
import useFlowsStore from '../../store/storeFlows';
import { getIdForTableGen } from '../../util/getIdsForTableGen';
import { Select, InputLabel, MenuItem, Box } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';

export const sourceTypes = {
  promptIn: 'prompt',
  contentIn: 'content',
  instructionIn: 'instruction',
  personaIn: 'persona',
};

export interface tableDataTypes {
  id: string;
  columns: {
    id: string;
    name: string;
    value: string;
    selected: boolean;
  }[];
}

function TableProcessor({
  id,
  data,
  isConnectable,
  selected,
  type,
}: NodeProps) {
  const edges: any = useEdges();
  const [connectedHandle, setConnectedHandle] = useState<{
    mainSourceHandleConnected?: boolean;
    mainTargetHandleConnected?: boolean;
  }>({});

  const setNotifications = useNotificationStore(state => state.setNotification);
  const [isLoading, setIsLoading] = useState(false);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: null,
    name: '',
  });

  const [tableData, setTableData] = useState<tableDataTypes[]>([]);
  const [hasToExecute, setHasToExecute] = useState(true);
  const [formData, setFormData] = useState({
    executing: '',
    selectOutputTable: '',
  });
  const { setNodeState, setSaving, flowId, setSchema } = useFlowsStore(
    state => state,
  );

  const flowNodes = getNodesConnectedToflow(edges);

  const hasParent = useStoreReactflow(
    store => !!store.nodeInternals.get(id)?.parentNode,
  );

  const outputHandle = (
    <CustomSourceHandle>
      <SourceHandle
        isConnectable={isConnectable}
        nodeId={id}
        sourceName={'output'}
        position={Position.Right}
        className="custom-source-handle"
      />
    </CustomSourceHandle>
  );
  const inputHandle = (
    <Handle
      className="flow-global-handle"
      type="target"
      position={Position.Top}
      id="input"
      isConnectable={isConnectable}
    />
  );
  const outputFlowHandle = (
    <CustomSourceHandle>
      <SourceHandle
        isConnectable={isConnectable}
        nodeId={id}
        sourceName={'outputFlow'}
        position={Position.Bottom}
        className={'flow-global-handle'}
      />
    </CustomSourceHandle>
  );

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  const onSubmit = useCallback(() => {
    setIsLoading(false);

    const outputId =
      flowNodes?.filter(flowIds => flowIds.includes('outputTable'))?.length ===
        1 && !formData.selectOutputTable
        ? flowNodes?.filter(flowIds => flowIds.includes('outputTable'))?.[0]
        : formData.selectOutputTable;

    const table = tableData?.map(col => {
      const columns = col?.columns?.filter(col => col?.selected);

      return {
        id: generateUniqueId(),
        columns: columns?.map((col, index) => {
          return {
            ...col,
            id: generateUniqueId(),
            value: '',
            selected: true,
            name: `Response ${col.name}`,
          };
        }),
      };
    });

    const { getAllIndexesThatAreSelected, processedIds } =
      getIdForTableGen(table);

    let tableDataTest: any[] = [];

    if (setNodeState) {
      setNodeState((nds: any) => {
        return nds?.map((nd: any) => {
          if (nd.id === id) {
            return {
              ...nd,
              data: {
                ...nd.data,
                completed: 0,
              },
            };
          }

          if (nd.id === outputId) {
            const tableData = nd?.data?.tableData || [];
            const newTableData = [...tableData];
            const newTable = [...table];

            // Ensure both tableData and table have the same number of rows
            while (newTableData.length < newTable.length) {
              newTableData.push({
                id: generateUniqueId(), // Replace with your desired ID
                columns: newTableData?.[0]?.columns?.map(
                  (col: { name: any }) => ({
                    id: generateUniqueId(), // Replace with your desired ID
                    name: col.name,
                    value: '',
                    selected: true,
                  }),
                ),
              });
            }

            while (newTable.length < newTableData.length) {
              newTable?.push({
                id: generateUniqueId(), // Replace with your desired ID
                columns: newTable?.[0]?.columns.map(col => ({
                  id: generateUniqueId(), // Replace with your desired ID
                  name: col.name,
                  value: '',
                  selected: true,
                })),
              });
            }

            const tableDataGen = newTableData?.map(
              (row: any, rowIndex: number) => {
                const rowColumns = row?.columns || [];
                const tableColumns = newTable[rowIndex]?.columns || [];

                const mergedColumns = [...rowColumns, ...tableColumns];

                return {
                  ...row,
                  columns: mergedColumns,
                };
              },
            );

            tableDataTest = [...tableDataGen];

            return {
              ...nd,
              data: {
                ...nd.data,
                tableData: tableDataGen,
              },
            };
          }
          return nd;
        });
      });
    }

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id: outputId,
      setSaving,
      flowTriggerData: {
        ...data?.flowTrigger,
        arrayExecute: {
          id: id,
          startingPoint: getAllIndexesThatAreSelected.length,
          completed: 0,
          endingPoint: processedIds?.length,
          firstId: flowNodes?.[flowNodes?.findIndex(node => node === id) + 1],
          loopingToId:
            formData?.executing ||
            flowNodes?.[flowNodes?.findIndex(node => node === id) + 1] ||
            '',
        },
      },
      flowId,
      objectData: {
        tableData: tableDataTest,
      },
      setSchema,
    });

    setTimeout(() => {
      setHasToExecute(true);
    }, 1000);
  }, [
    flowNodes,
    formData?.executing,
    formData.selectOutputTable,
    id,
    tableData,
  ]);

  useEffect(() => {
    if (data.tableData && !isChangingDirectly) {
      setTableData(data.tableData);
    } else if (!data.tableData && !isChangingDirectly) {
      const newConditions = [
        {
          id: '1',
          columns: [
            { id: Math.random(), name: 'Column 1', value: '' },
            { id: Math.random(), name: 'Column 2', value: '' },
          ],
        },
      ];
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowId,
        setSaving,
        objectData: {
          tableData: newConditions,
        },
        setSchema,
      });
    }
  }, [data]);

  useMemo(() => {
    if (!isChangingDirectly) {
      setFormData({
        executing: data.executing || '',
        selectOutputTable: data?.selectOutputTable || '',
      });
    }
  }, [data?.executing, data?.selectOutputTable]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );
  const onChangeOptions = (e: { target: { name: any; value: string } }) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
    });

    setIsChangingDirectly(true);
  };

  const inputPersona = (
    <CustomTargetHandle>
      <TargetHandle
        isConnectable={isConnectable}
        nodeId={id}
        targetName="tableInput"
        style={{
          background: '#555',
        }}
        className="custom-target-handle"
        position={Position.Left}
      />
    </CustomTargetHandle>
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const nodeIcon = nodeType.nodeIcon;

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <StyledNode
          id={data.myId}
          bgColor="#FFE8A3"
          style={{
            border: isLoading ? 'initial' : '5px solid #FFCD29',
            outline: selected && !isLoading ? '1px solid red' : 'none',
          }}
        >
          <NodeResizer isVisible={false} />
          <ChangeLabel data={data} icon={nodeIcon} />
          <DeleteNode nodeId={id} />
          {!data?.collapsed && (
            <>
              <Box>
                <br />
                <InputLabel htmlFor="executing">Executing Type</InputLabel>
                <Select
                  name="executing"
                  value={formData.executing}
                  onChange={onChangeOptions}
                  className="nodrag"
                >
                  {flowNodes
                    ?.filter(flowId => flowId !== id)
                    .map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>

                <InputLabel htmlFor="selectOutputTable">
                  Select Output Table
                </InputLabel>
                <Select
                  name="selectOutputTable"
                  value={formData.selectOutputTable}
                  onChange={onChangeOptions}
                  className="nodrag"
                >
                  {flowNodes
                    ?.filter(
                      flowId => flowId !== id && flowId.includes('outputTable'),
                    )
                    .map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Table
                id={id}
                setIsChangingDirectly={setIsChangingDirectly}
                tableData={tableData}
                setTableData={setTableData}
                setWhoIsChanging={setWhoIsChanging}
              />
            </>
          )}
          {outputHandle}
          {inputPersona}
        </StyledNode>
      </Box>

      <CustomHandle
        isConnected={connectedHandle?.mainSourceHandleConnected}
        isLoading={isLoading}
        bottom={'-7.5px'}
        left={'90%'}
      >
        {outputFlowHandle}
      </CustomHandle>

      <CustomHandle
        left={'10%'}
        top={'-7.5px'}
        isConnected={connectedHandle?.mainTargetHandleConnected}
        isLoading={isLoading}
      >
        {inputHandle}
      </CustomHandle>
    </>
  );
}

export default memo(TableProcessor);
