import { memo, useState, useEffect, useRef } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { NodeProps } from '../../util/Types/NodeProps';

import useFlowsStore from '../../store/storeFlows';
import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import axios, { AxiosResponse } from 'axios';
import prettyBytes from 'pretty-bytes';
import { QueryParam } from '../ApiCaller/constants';
import { isActiveEdge } from '../../util/findActiveHandle';
import TagInput from '../Test/TagInput';
import Label from '../UI/Label/Label';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { CaretDown, PlusCircle, TrashSimple } from '@phosphor-icons/react';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { ApiCallerService } from '../../service/ApiCallerService';
import { useObserveElementWidth } from '../../util/useObserveElement';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { useSocketStore } from '../../store/storeSocket';

export const sourceTypes = {
  promptIn: 'prompt',
  contentIn: 'content',
  instructionIn: 'instruction',
  personaIn: 'persona',
};

export interface tableDataTypes {
  id: string;
  columns: {
    id: string;
    name: string;
    value: string;
    selected: boolean;
  }[];
}

function ApiCaller({ id, data, isConnectable, type, selected }: NodeProps) {
  const edges = useEdges();
  const { activeNodeSockets, setActiveNodeSockets, socket } = useSocketStore(
    state => state,
  );

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuthStore(state => state);
  const [formData, setFormData] = useState<{
    method: string;
    url: string;
    queryParams: QueryParam[];
    jsonRequestBody: string;
    jsonAuth: string;
    response: AxiosResponse | null | any;
    text: string;
    type: number;
    username: string;
    password: string;
  }>({
    method: data?.method || 'GET',
    url: data.url || '',
    type: data.type || 1,
    queryParams: data.queryParams || [{ key: '', value: '' }],
    jsonRequestBody: data.jsonRequestBody || '',
    jsonAuth: data.jsonAuth || '',
    response: data.response || null,
    text: data.text || '',
    username: data.username || '',
    password: data.password || '',
  });

  const setNotification = useNotificationStore(state => state.setNotification);

  const [hasError, setHasError] = useState(false);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const { setNodes: setNodeState } = useReactFlow();
  const { setSchema, setSaving, flowId, schema } = useFlowsStore(
    state => state,
  );

  type CollapsedSections = 'authentication' | 'queryParams';
  const [collapsed, setCollapsedStates] = useState<
    Record<CollapsedSections, boolean>
  >({
    authentication: false,
    queryParams: false,
  });

  const toggleCollapse = (section: CollapsedSections) => {
    setCollapsedStates(prevStates => ({
      ...prevStates,
      [section]: !prevStates[section],
    }));
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setSaving,
          setNodeState,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });
        setIsChangingDirectly(false);

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const onSave = (
    object: { [key: string]: string | QueryParam[] | AxiosResponse | null },
    changeType: string = 'default',
  ) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowTriggerData: checkExecuteTrigger(data, id),
      changeType,
      flowId,
      objectData: {
        ...object,
      },
      setSchema,
    });
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (request: any) => {
        request.customData = request.customData || {};
        request.customData.startTime = new Date().getTime();
        return request;
      },
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => {
        response = updateEndTime(response);
        return response;
      },
      error => {
        if (error.response) {
          error.response = updateEndTime(error.response);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const updateEndTime = (response: AxiosResponse | any) => {
    const endTime = new Date().getTime();
    response.config.customData = response.config.customData || {};
    response.config.customData.time =
      endTime - response.config.customData.startTime;
    response.config.customData.size = prettyBytes(
      JSON.stringify(response.data).length,
      {
        bits: true,
      },
    );

    return response;
  };

  const handleSendRequest = async () => {
    let url = replacePlaceholders(formData.url, schema);
    if (data?.urlData?.length) {
      url = replacePlaceholders(
        data?.urlData?.map((item: { text: string }) => item.text)?.join(''),
        schema,
      );
    }

    let headers = formData.jsonAuth;

    let jsonRequestBody = formData.jsonRequestBody;

    if (data?.jsonAuthData?.length) {
      headers = data?.jsonAuthData
        ?.map((item: { text: string }) => item.text)
        ?.join('');
    }

    if (data?.jsonRequestBodyData?.length) {
      jsonRequestBody = replacePlaceholders(
        data?.jsonRequestBodyData
          ?.map((item: { text: string }) => item.text)
          ?.join(''),
        schema,
      );
    }

    if (!url) {
      setNotification({
        message: 'Url is required',
        type: 'error',
      });
      return;
    }

    if (!url.includes('http')) {
      setNotification({
        message: 'Invalid URL',
        type: 'error',
      });
      return;
    }

    if (jsonRequestBody) {
      try {
        JSON.parse(jsonRequestBody);
      } catch (error) {
        setNotification({
          message: 'Invalid JSON Request Body',
          type: 'error',
        });
        return;
      }
    }

    if (headers) {
      try {
        JSON.parse(headers);
      } catch (error) {
        setNotification({
          message: 'Invalid JSON Auth',
          type: 'error',
        });
        return;
      }
    }

    setIsLoading(true);

    try {
      const params: Record<string, any> = {};
      if (formData.queryParams) {
        for (const param of formData.queryParams) {
          if (param.key && param.value !== undefined) {
            params[param.key] = param.value;
          }
        }
      }

      let auth = undefined;

      let authUsPw = {
        username: formData.username,
        password: formData.password,
      };

      if (data?.usernameData?.length) {
        authUsPw.username = data?.usernameData
          ?.map((item: { text: string }) => item.text)
          ?.join('');
      }

      if (data?.passwordData?.length) {
        authUsPw.password = data?.passwordData
          ?.map((item: { text: string }) => item.text)
          ?.join('');
      }

      if (authUsPw.username) {
        if (authUsPw.password) {
          auth = {
            username: authUsPw.username,
            password: authUsPw.password,
          };
        } else {
          auth = {
            username: authUsPw.username,
          };
        }
      }

      let axiosConfig = {
        method: formData.method?.toLocaleLowerCase(),
        url: url,
        params: params,
        data: jsonRequestBody ? JSON.parse(jsonRequestBody) : undefined,
        headers: headers
          ? JSON.parse(replacePlaceholders(headers, schema))
          : undefined,
      } as any;

      if (auth) {
        axiosConfig.auth = auth;
      }

      // const res = await axios(axiosConfig);

      await ApiCallerService.request(axiosConfig);
      setActiveNodeSockets([...activeNodeSockets, id]);
    } catch (error: any) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setFormData({
        ...formData,
        // response: error?.response,
        text: error.message ?? 'Something went wrong',
      });
      onSave(
        {
          text: error.message ?? 'Something went wrong',
        },
        'execute',
      );
      setNotification({
        message: error.message ?? 'Something went wrong',
        type: 'error',
      });
      setHasError(true);
      // debugger;      setHasToExecute(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id && socket?.connected && activeNodeSockets.includes(id)) {
      socket.on('api-call', async (resData: any) => {
        onSave(
          {
            text: JSON.stringify(resData.data, null, 2),
          },
          'execute',
        );

        setActiveNodeSockets(activeNodeSockets.filter(item => item !== id));
        setIsLoading(false);
        setHasToExecute(true);

        setFormData({
          ...formData,
          text: JSON.stringify(resData?.data, null, 2),
        });
      });
    }

    return () => {
      if (socket?.connected) {
        socket?.off('api-call');
      }
    };
  }, [socket, user?.id, activeNodeSockets]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          handleSendRequest();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [hasToExecute, formData, activeNodeSockets, data]);

  const addQueryParam = () => {
    setFormData({
      ...formData,
      queryParams: [...formData.queryParams, { key: '', value: '' }],
    });

    onSave({ queryParams: [...formData.queryParams, { key: '', value: '' }] });
  };

  const removeQueryParam = (index: number) => {
    const updatedQueryParams = [...formData.queryParams];
    updatedQueryParams.splice(index, 1);

    setFormData({
      ...formData,
      queryParams: updatedQueryParams,
    });

    onSave({ queryParams: updatedQueryParams });
  };

  const setQueryParamKey = (index: number, key: string) => {
    const updatedQueryParams = [...formData.queryParams];
    updatedQueryParams[index].key = key;
    setFormData({
      ...formData,
      queryParams: updatedQueryParams,
    });
    setIsChangingDirectly(true);

    setWhoIsChanging({ name: 'queryParams', value: updatedQueryParams });
  };

  const setQueryParamValue = (index: number, value: string) => {
    const updatedQueryParams = [...formData.queryParams];
    updatedQueryParams[index].value = value;
    setFormData({
      ...formData,
      queryParams: updatedQueryParams,
    });
    setIsChangingDirectly(true);
    setWhoIsChanging({ name: 'queryParams', value: updatedQueryParams });
  };

  const onChange = (e: {
    target: { name: string; value: string | number };
  }) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setWhoIsChanging({ name: e.target.name, value: e.target.value });
    setIsChangingDirectly(true);
  };

  const jsonFileErrosOnChange = (value: string, type?: string) => {
    try {
      JSON.parse(value);
      setHasError(false);
    } catch (error) {
      setHasError(true);
    }
    if (type === 'auth') {
      setFormData({
        ...formData,
        jsonAuth: value,
      });

      setWhoIsChanging({ name: 'jsonAuth', value });
      setIsChangingDirectly(true);

      return;
    }

    setFormData({
      ...formData,
      jsonRequestBody: value,
    });

    setWhoIsChanging({ name: 'jsonRequestBody', value });
    setIsChangingDirectly(true);
  };

  const onBeautify = (type: string) => {
    if (type === 'auth') {
      try {
        setFormData({
          ...formData,
          jsonAuth: JSON.stringify(JSON.parse(formData.jsonAuth), null, 2),
        });

        setWhoIsChanging({
          name: 'jsonAuth',
          value: JSON.stringify(JSON.parse(formData.jsonAuth), null, 2),
        });
        setIsChangingDirectly(true);
      } catch (error) {
        setHasError(true);
      }
    } else {
      try {
        setFormData({
          ...formData,
          jsonRequestBody: JSON.stringify(
            JSON.parse(formData.jsonRequestBody),
            null,
            2,
          ),
        });

        setWhoIsChanging({
          name: 'jsonRequestBody',
          value: JSON.stringify(JSON.parse(formData.jsonRequestBody), null, 2),
        });

        setIsChangingDirectly(true);
      } catch (error) {
        setHasError(true);
      }
    }
  };

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();
  // console.log('data@ApiCaller:', data);

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          id={id}
          edges={edges}
          type={type}
          data={data}
          isLoading={isLoading}
          btnText="Send Request"
          onSubmit={handleSendRequest}
          handleRun={handleRun}
          showTokensUsed={false}
        >
          <Box display={'flex'}>
            <div>
              <Box
                p={'16px'}
                borderRight={`1px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`}
                ref={inputRef}
              >
                <Box>
                  <TagInput
                    name="url"
                    placeholder="https://example.com"
                    labelName={'URL'}
                    handleId={'url'}
                    isCollapsed={true}
                    nodeId={id}
                    isActive={isActiveEdge(edges, id, 'url', 'target')}
                    isAdvanced={true}
                    findColor={findColor}
                    removeIcons
                    advancedCollapsed={true}
                    onChange={onChange}
                    dataConnected={data?.urlData || []}
                    placement={data?.placement}
                    value={formData.url}
                    nodeLabel={data?.label}
                  />

                  <Label isAdvanced labelName="Select Event " />
                  <Select
                    value={formData.method}
                    name="method"
                    onChange={onChange}
                    sx={{
                      '& .MuiList-root': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      },
                      '& .MuiSvgIcon-root': {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                      height: '30px',
                      textTransform: 'capitalize',
                      mb: '16px',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                    className="nodrag"
                  >
                    <MenuItem
                      value="GET"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      GET
                    </MenuItem>
                    <MenuItem
                      value="POST"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      POST
                    </MenuItem>
                    <MenuItem
                      value="PUT"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      PUT
                    </MenuItem>
                    <MenuItem
                      value="PATCH"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      PATCH
                    </MenuItem>
                    <MenuItem
                      value="DELETE"
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      DELETE
                    </MenuItem>
                  </Select>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'top',
                      gap: 1,
                      position: 'relative',
                    }}
                  >
                    <TagInput
                      placeholder="Headers (JSON)"
                      name="jsonAuth"
                      labelName={'Headers (JSON)'}
                      handleId={'jsonAuth'}
                      isCollapsed={true}
                      multiline
                      nodeId={id}
                      isActive={isActiveEdge(edges, id, 'jsonAuth', 'target')}
                      findColor={findColor}
                      removeIcons
                      advancedCollapsed={true}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        jsonFileErrosOnChange(e.target.value, 'auth')
                      }
                      dataConnected={data?.jsonAuthData || []}
                      placement={data?.placement}
                      value={formData.jsonAuth}
                      nodeLabel={data?.label}
                    />
                    {!data?.jsonAuthData?.length && (
                      <Button
                        sx={{
                          alignSelf: 'flex-start',
                        }}
                        onClick={() => {
                          onBeautify('auth');
                        }}
                      >
                        Beautify
                      </Button>
                    )}
                  </Box>

                  {hasError && (
                    <FormHelperText
                      sx={{
                        color: 'red',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      Invalid JSON
                    </FormHelperText>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'top',
                      gap: 1,
                      position: 'relative',
                      mb: '10px',
                    }}
                  >
                    <TagInput
                      placeholder="JSON Request Body"
                      name="jsonRequestBody"
                      labelName={'JSON Request Body'}
                      handleId={'jsonRequestBody'}
                      handleTab={true}
                      isCollapsed={false}
                      multiline
                      nodeId={id}
                      isActive={isActiveEdge(
                        edges,
                        id,
                        'jsonRequestBody',
                        'target',
                      )}
                      findColor={findColor}
                      removeIcons
                      // advancedCollapsed={true}
                      onChange={onChange}
                      dataConnected={data?.jsonRequestBodyData || []}
                      placement={data?.placement}
                      value={
                        (formData?.jsonRequestBody as keyof typeof formData) ||
                        ''
                      }
                      // value={formData.jsonRequestBody}
                      nodeLabel={data?.label}
                    />
                    {!data?.jsonRequestBodyData?.length && (
                      <Button
                        sx={{
                          alignSelf: 'flex-start',
                        }}
                        onClick={() => {
                          onBeautify('request');
                        }}
                      >
                        Beautify
                      </Button>
                    )}
                  </Box>
                  {hasError && (
                    <FormHelperText
                      sx={{
                        color: 'red',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      Invalid JSON
                    </FormHelperText>
                  )}

                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        position: 'relative',
                        '& svg': { cursor: 'pointer' },
                        mb: !collapsed.queryParams ? 2 : '8px',
                      }}
                    >
                      <CaretDown
                        onClick={() => toggleCollapse('queryParams')}
                        size={16}
                        color={!collapsed.queryParams ? '#3650DE' : '#667085'}
                      />
                      <Typography
                        fontSize={'16px'}
                        color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                        fontWeight={600}
                      >
                        {'Query Params '}
                      </Typography>
                      <IconButtonTooltip
                        title="Add query param "
                        onClick={addQueryParam}
                      >
                        <PlusCircle size={16} color="#98A2B3" />
                      </IconButtonTooltip>
                    </Box>
                    {collapsed.queryParams && (
                      <>
                        {formData.queryParams.map((param, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              mb: '8px',
                            }}
                          >
                            {/* <InputHandle
                                key={index}
                                position={Position.Left}
                                type='target'
                                left={-20}
                                isActive={isActiveEdge(edges, data?.myId, index, 'target')}
                                activeColor={findColor?.color}
                                top={!collapsed.queryParams ? '-8px' : '50%'}
                                handleId={`${index}`}
                              /> */}
                            <TextField
                              type="text"
                              placeholder="Key"
                              value={param.key}
                              className="nodrag"
                              name={param.key + index}
                              onChange={e =>
                                setQueryParamKey(index, e.target.value)
                              }
                              InputProps={{
                                style: {
                                  color:
                                    user?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#101828',
                                },
                              }}
                              sx={{
                                width: '168px',
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#101828',
                                bgcolor:
                                  user?.theme === 'dark'
                                    ? '#1D2939'
                                    : '#F9FAFB',
                                border: `2px solid ${
                                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                                }`,
                              }}
                            />
                            <TextField
                              type="text"
                              placeholder="Value"
                              className="nodrag"
                              name={param.value + index}
                              value={param.value}
                              InputProps={{
                                style: {
                                  color:
                                    user?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#101828',
                                },
                              }}
                              sx={{
                                width: '168px',
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#101828',
                                bgcolor:
                                  user?.theme === 'dark'
                                    ? '#1D2939'
                                    : '#F9FAFB',
                                border: `2px solid ${
                                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                                }`,
                              }}
                              onChange={e =>
                                setQueryParamValue(index, e.target.value)
                              }
                            />
                            <IconButtonTooltip
                              onClick={() => removeQueryParam(index)}
                              title="Remove query param"
                            >
                              <TrashSimple
                                color={
                                  user?.theme === 'dark' ? '#F9FAFB' : '#667065'
                                }
                              />
                            </IconButtonTooltip>
                          </Box>
                        ))}
                      </>
                    )}
                  </Box>
                </Box>

                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      position: 'relative',
                      '& svg': { cursor: 'pointer' },
                      mb: !collapsed.authentication ? 2 : '8px',
                    }}
                  >
                    <CaretDown
                      onClick={() => toggleCollapse('authentication')}
                      size={16}
                      color={!collapsed ? '#3650DE' : '#667085'}
                    />
                    <Typography
                      fontSize={'16px'}
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                      fontWeight={600}
                    >
                      {'Authentication '}
                    </Typography>
                  </Box>
                  <>
                    <TagInput
                      onChange={onChange}
                      name="username"
                      value={formData.username}
                      handleId={'username'}
                      nodeId={id}
                      isActive={isActiveEdge(edges, id, 'username', 'target')}
                      findColor={findColor}
                      dataConnected={data?.usernameData || []}
                      placement={data?.placement}
                      nodeLabel={data?.label}
                      isAdvanced
                      removeIcons
                      labelName={'Username'}
                      isCollapsed={false}
                      labelStyle={{
                        fontSize: '14px',
                        color: '#101828',
                        fontWeight: 400,
                        margin: 0,
                      }}
                      advancedCollapsed={collapsed.authentication}
                    />
                    <TagInput
                      onChange={onChange}
                      name="password"
                      value={formData.password}
                      handleId={'password'}
                      nodeId={id}
                      isActive={isActiveEdge(edges, id, 'password', 'target')}
                      findColor={findColor}
                      dataConnected={data?.passwordData || []}
                      placement={data?.placement}
                      nodeLabel={data?.label}
                      isCollapsed={false}
                      isAdvanced
                      removeIcons
                      labelName={'Password'}
                      labelStyle={{
                        fontSize: '14px',
                        color: '#101828',
                        fontWeight: 400,
                        margin: 0,
                      }}
                      advancedCollapsed={collapsed.authentication}
                    />
                  </>
                </Box>
              </Box>
            </div>
            <Box
              p={'16px 24px 16px 16px'}
              display="flex"
              flexDirection="column"
            >
              <OutputTextarea
                // previewResponses={previewResponses}
                maxOutputHeight={maxOutputHeight}
                previewIndex={data.previewIndex}
                value={data.text}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                placement={data?.placement}
                labelName={'Output'}
                nodeLabel={data?.label}
                type={data.myId}
                // onPreview={onPreview}
                // findColor={findColor}
              />
              {/* {formData.response && (
                <Box className="mt-5" data-response-section>
                  <Typography variant="h6">Response status</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                    className="d-flex my-2"
                  >
                    <Box className="me-3">
                      Status:{' '}
                      <span data-status>{formData.response.status}</span>
                    </Box>
                    <Box className="me-3">
                      Time:{' '}
                      <span data-time>
                        {formData.response?.config?.customData?.time}ms
                      </span>
                    </Box>
                    <Box className="me-3">
                      Size:{' '}
                      <span data-size>
                        {formData.response?.config?.customData?.size}
                      </span>
                    </Box>
                  </Box>
                </Box>
              )} */}
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
}

export default memo(ApiCaller);
