import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlowService } from '../../service/FlowService';
import { useNotificationStore } from '../../store/storeNotifications';

import { Position, useEdges, useReactFlow } from 'reactflow';

import { FluxService } from '../../service/FluxService';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import Node from '../UI/Node/Node';
import TagInput from '../Test/TagInput';
import Label from '../UI/Label/Label';
import { FlowArrow } from '@phosphor-icons/react';
import { isActiveEdge } from '../../util/findActiveHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';

const FlowCaller = ({ id, data, isConnectable, selected, type }: NodeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();

  const { flowId, setSaving, setSchema, flows } = useFlowsStore(state => state);
  const { setNotification } = useNotificationStore(state => state);
  const edges = useEdges();

  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const [hasToExecute, setHasToExecute] = useState(true);
  const [formData, setFormData] = useState({});

  const [flow, setFlow] = useState<{
    nodes: any[];
    edges: any[];
  }>({
    nodes: [],
    edges: [],
  });

  useEffect(() => {
    const getFlows = async () => {
      setIsLoading(true);
      try {
        const response = await FlowService.getFlow(data.selectedFlow);

        if (!selectedFlow) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            setSaving,
            flowId,
            objectData: {
              selectedFlow: response.data.id,
            },
            setSchema,
          });
        }

        setFlow(response.data);
      } catch (error) {
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (data.selectedFlow) {
      getFlows();
    }
  }, [data.selectedFlow]);

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setFormData({
          ...data,
        });
      }

      if (data?.selectedFlow) {
        setSelectedFlow(data.selectedFlow);
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onClick = async () => {
    setBtnLoading(true);
    try {
      const map = flow?.nodes;
      const varInputNodes = map?.filter(
        (item: { type: string }) => item.type === 'varInputNode',
      );
      const getOutputObjectNode = map?.filter(
        (item: { type: string }) => item.type === 'outputObjectNode',
      );

      const modifyData = varInputNodes?.map((item: { id: string }) => {
        return {
          inputId: item.id,
          inputText: formData?.[item.id as keyof typeof formData],
        };
      });

      const response = await FluxService.FluxApi(data?.selectedFlow, {
        variableInputs: modifyData,
      });

      if (response.data.message?.length !== 0) {
        const object: { [target: string]: string } = {};

        getOutputObjectNode?.forEach((item: { id: any }) => {
          const output = response.data.message.find(
            (output: { outputId: any }) => output.outputId === item.id,
          );

          if (!object[item.id]) {
            object[item.id] = output.text;
          }

          object[item.id] = output.text;
        });

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowTriggerData: checkExecuteTrigger(data, id),
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],

          flowId,
          objectData: {
            ...object,
          },
          setSchema,
          setSaving,
        });

        setExecuteCounter(0);

        setTimeout(() => {
          setHasToExecute(true);
        }, 1000);
      }
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const onExecuteNode = useMemo(() => {
    const values = {
      ...data,
    };
    return values;
  }, [data]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          const myValues = onExecuteNode;

          if (myValues) {
            onClick();
          }
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, onExecuteNode, data?.canceled]);

  const onChange = useCallback(
    (event: { target: { name: string; value: any } }) => {
      if (event.target.name === 'selectedFlow') {
        setSelectedFlow(event.target.value);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            selectedFlow: event.target.value,
          },
          setSchema,
        });
      } else {
        setWhoIsChanging({
          value: event.target.value,
          name: event.target.name,
        });

        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });

        setIsChangingDirectly(true);
      }
    },
    [id],
  );

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };
  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  return (
    <>
      <Box className={isLoading || btnLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          btnText="Run Flow"
          handleRun={handleRun}
          onSubmit={onClick}
          isLoading={isLoading || btnLoading}
          data={data}
          id={id}
          showTokensUsed={false}
        >
          {!data?.collapsed && <></>}

          <Box display={'flex'}>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
            >
              <Label isAdvanced={true} labelName="Select Flow to Run " />

              {flows?.length !== 0 && (
                <Select
                  value={selectedFlow ?? 'Select Flow'}
                  name="selectedFlow"
                  className="nodrag"
                  onChange={onChange}
                  disabled={btnLoading}
                  size="small"
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  <MenuItem
                    disabled
                    value={'Select Flow'}
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    <em>Select Flow</em>
                  </MenuItem>

                  <MenuItem
                    disabled
                    value={'Spaces Flow'}
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    <em>Private Flows</em>
                  </MenuItem>

                  {flows
                    ?.filter(flow => !flow?.spaceName)
                    .map((flow, i) => (
                      <MenuItem
                        key={i}
                        value={flow.id}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        {flow.name}
                      </MenuItem>
                    ))}

                  {flows?.filter(flow => flow?.spaceName)?.length !== 0 && (
                    <MenuItem
                      disabled
                      value={'Spaces Flow'}
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      <em>Space Flows</em>
                    </MenuItem>
                  )}
                  {flows
                    ?.filter(flow => flow?.spaceName)
                    .map((flow, i) => (
                      <MenuItem
                        key={i}
                        value={flow.id}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        {flow.name}
                      </MenuItem>
                    ))}
                </Select>
              )}

              <Box mt={'24px'}>
                <Label isAdvanced={true} labelName="Api inputs" />

                {flow?.nodes
                  ?.filter(
                    (item: { type: string }) => item.type === 'varInputNode',
                  )
                  ?.map((varInputNode: any, idx: any) => (
                    <Fragment key={varInputNode?.id}>
                      <TagInput
                        className="nodrag"
                        width={'360px'}
                        isCollapsed={true}
                        isAdvanced={true}
                        advancedCollapsed={true}
                        handleId={varInputNode?.id}
                        removeIcons
                        findColor={findColor}
                        maxLength={500}
                        nodeId={id}
                        labelName={varInputNode.data.label}
                        disabled={isActiveEdge(
                          edges,
                          id,
                          varInputNode?.id,
                          'target',
                        )}
                        name={varInputNode?.id}
                        value={
                          formData?.[varInputNode?.id as keyof typeof formData]
                        }
                        labelStyle={{
                          fontSize: '12px',
                          fontWeight: 400,
                          color: '#101828',
                        }}
                        onChange={onChange}
                        style={{
                          resize: 'both',
                          overflow: 'auto',
                        }}
                        nodeLabel={data?.label}
                      />
                    </Fragment>
                  ))}
              </Box>
            </Box>
            <Box p={'16px 24px 16px 16px'}>
              <Label isAdvanced={true} labelName="Output" />

              {!flow?.nodes.filter(
                (item: { type: string }) => item.type === 'outputObjectNode',
              ).length && (
                <Box
                  width={'360px'}
                  height={'170px'}
                  sx={{
                    padding: '16px',
                    borderRadius: '8px',
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <FlowArrow size={32} color="#98A2B3" />
                    <Typography fontSize={'14px'} color="#98A2B3">
                      The output(s) from the flow you run will appear here.
                    </Typography>
                  </Box>
                </Box>
              )}
              {flow?.nodes?.length > 0 &&
                flow?.nodes
                  .filter(
                    (item: { type: string }) =>
                      item.type === 'outputObjectNode',
                  )
                  .map((varInputNode: any, idx: number) => (
                    <Box key={idx} position={'relative'}>
                      <TagInput
                        className="nodrag"
                        isCollapsed={true}
                        isAdvanced={true}
                        advancedCollapsed={true}
                        removeIcons
                        maxLength={500}
                        nodeId={id}
                        findColor={findColor}
                        labelName={varInputNode.data.label}
                        handleId={varInputNode?.id}
                        disabled={isActiveEdge(
                          edges,
                          id,
                          varInputNode?.id,
                          'source',
                        )}
                        name={varInputNode?.id}
                        value={
                          formData?.[varInputNode?.id as keyof typeof formData]
                        }
                        labelStyle={{
                          fontSize: '12px',
                          fontWeight: 400,
                          color: '#101828',
                        }}
                        readyOnly={true}
                        handleSettings={{
                          position: Position.Right,
                          right: -33,
                          type: 'source',
                          top: '50%',
                        }}
                        nodeLabel={data?.label}
                      />
                    </Box>
                  ))}
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
};

export default memo(FlowCaller);
