import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import { User } from '../util/Types/User';

export class CompanyService extends ApiService {
  static pathName = '/company';

  static getCompanies = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}`);
  };

  static getOne = (id: string): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/one/${id}`);
  };

  static getUserCompanies = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/user-companies`);
  };

  static getUserOfCompany = (id: string): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/user-company?companyId=${id}`);
  };

  static transferData = (id: string) => {
    return ApiService.post(
      `${this.pathName}/transfer-user-data?companyId=${id}`,
    );
  };

  static getUserDetailsInsideCompany = (body: {
    userId: string;
    companyId: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/user?userId=${body.userId}&companyId=${body.companyId}`,
    );
  };

  static getCompanyStats = (id: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/data?companyId=${id}`);
  };

  static getCompanyUsersStats = (id: string): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/${id}`);
  };

  static createCompany = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, { name: body });
  };

  static createCompanyUser = (body: {
    email: string;
    companyId: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/add-user/${body.companyId}`, {
      email: body.email,
    });
  };

  static updateCompany = (
    body: {
      name: string;
      voice?: string[];
      logo?: string | null;
      domain?: string | undefined;
    },
    id: string,
  ): Promise<IGetAllResponse<any>> => {
    return ApiService.patch(`${this.pathName}/update/${id}`, body);
  };

  static updateCompanyEnableDisable = (body: {
    companyId: string;
    userId: string;
    status?: string | null;
    limit?: number | null;
    spaceId?: string | null;
  }): Promise<IGetAllResponse<any>> => {
    return ApiService.patch(`${this.pathName}/user/`, body);
  };

  static removeCompanyUser = (
    companyId: string,
    userEmail: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/remove-user/${companyId}`, {
      email: userEmail,
    });
  };

  static tokenLimitCompany = (
    body: {
      limit: number;
      spaceId?: string;
    },
    companyId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.patch(`company-spaces/${companyId}`, {
      limit: body.limit,
    });
  };

  static getCompanyUser = (
    companyId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/user-company?${companyId}`);
  };

  static acceptCompanyInvite = (
    token: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/accept-invite?token=${token}`);
  };

  static getCompany = (id: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/${id}`);
  };

  static getOneCompany = (id?: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/one/${id}`);
  };

  static getFlows = (companyId: string): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/flow-data?companyId=${companyId}`);
  };

  static getFlowStats = (
    companyId: string,
    flowId: string,
    query: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/flow-stats?companyId=${companyId}&flowId=${flowId}${query}`,
    );
  };

  static getCompanyTechnologies = (
    companyId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/technologies?companyId=${companyId}`,
    );
  };

  static getAllCompanyUsers = (
    companyId: string,
  ): Promise<
    IGetAllResponse<{ id: string; role: string; status: string; user: User }>
  > => {
    return ApiService.get(`${this.pathName}/users?companyId=${companyId}`);
  };

  static enableCompanyTechnology = (body: {
    companyId: string;
    modelName: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/enable-model`, body);
  };

  static disableCompanyTechnology = (body: {
    companyId: string;
    modelName: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/disable-model`, body);
  };

  static getCompanyConfigs = (
    companyId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/configs?companyId=${companyId}`);
  };

  static getEnabledTechnologies = (
    companyId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/enabled-technologies?companyId=${companyId}`,
    );
  };

  static getDisabledTechnologies = (
    companyId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/disabled-technologies?companyId=${companyId}`,
    );
  };
}
