// dateUtils.ts

/**
 * Converts an ISO 8601 date string to the desired format.
 * @param isoString - The ISO 8601 date string.
 * @returns The formatted date string in the format 'DD / MM / YYYY'.
 */
export function formatActualDate(isoString: string): string {
  const date = new Date(isoString);

  // Extract date components
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const year = date.getUTCFullYear();

  // Format the date string
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}
