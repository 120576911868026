import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';

export class ReplicateService extends ApiService {
  static pathName = '/replicate';

  static falcon40bInstruct = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/falcon-40b-instruct`, body);
  };
  static mpt30bChat = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/mpt-30b-chat`, body);
  };
  static mpt7bStorywriter = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/mpt-7b-storywriter`, body);
  };
  static llama70bChat = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/llama-2-70b-chat`, body);
  };

  static kandinsky = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/kandinsky`, body);
  };

  static codeLlama34b = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/code-llama-34b`, body);
  };
  static codeLlama34bInstruct = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/code-llama-34b-instruct`, body);
  };

  static generateMusic = (body: {
    model_version: string;
    prompt: string;
    duration: number;
    normalization_strategy: string;
    top_k: number;
    top_p: number;
    temperature: number;
    classifier_free_guidance: number;
    outputFormat: string;
    seed: number;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/music-gen`, body);
  };

  static imageToTextConvert = (
    body: {
      caption: string;
      question: string;
      content: string;
      use_nucleus_sampling: boolean;
      temperature: number;
      file: File;
      src?: any;
      flowId?: string;
    },
    file: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/blip2?caption=${body.caption}&question=${
        body.question
      }&content=${body.content}&use_nucleus_sampling=${
        body.use_nucleus_sampling
      }&temperature=${body.temperature}${body.src ? `&url=${body.src}` : ''}${
        body.flowId ? `&flowId=${body.flowId}` : ''
      }`,
      file,
    );
  };

  static animate = (body: {
    prompt: string;
    negativePrompt: string;
    baseModel: string;
    steps: number;
    guidanceScale: number;
    frames: number;
    width: number;
    height: number;
    seed: number;
    zoomInMotionStrength: number;
    zoomOutMotionStrength: number;
    penRightMotionStrength: number;
    penLeftMotionStrength: number;
    panUpMotionStrength: number;
    panDownMotionStrength: number;
    rollingClockwiseMotionStrength: number;
    rollingCounterclockwiseMotionStrength: number;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/animate`, body);
  };
}
