import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { AuthService } from '../../service/AuthService';
import { useStoreGoogleData } from '../../store/storeGoogleData';
import { UserService } from '../../service/UserService';

const scope =
  'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/documents.readonly https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/presentations.readonly';

const ConnectToGoogleSheets: React.FC = () => {
  const {
    setGoogleRefreshToken,
    setGoogleAccessToken,
    loginSuccessHandler,
    loginErrorHandler,
  } = useStoreGoogleData();

  const loginHandler = useGoogleLogin({
    onSuccess: async codeResponse => {
      const tokens = await AuthService.getGoogleRefreshToken({
        code: codeResponse.code,
        // redirect_uri: 'test',
      });

      setGoogleAccessToken(tokens.data.access_token);
      setGoogleRefreshToken(tokens.data.refresh_token);
      await UserService.updateUser({
        googleAccessToken: tokens.data.access_token,
        googleRefreshToken: tokens.data.refresh_token,
      });
      const loginSuccessTokens: TokenResponse = {
        access_token: tokens.data.access_token,
        expires_in: tokens.data.expiry_date,
        prompt: 'consent',
        token_type: '',
        scope: scope,
      };

      loginSuccessHandler(loginSuccessTokens);
    },
    onError: loginErrorHandler,
    scope,
    flow: 'auth-code',
  });

  return (
    <span
      style={{
        fontSize: '14px',
        color: '#3650DE',
        fontWeight: 700,
      }}
      onClick={() => loginHandler()}
    >
      Click here
    </span>
  );
};

export default ConnectToGoogleSheets;
