import React from 'react';
import {
  AiTextGeneratorFormValues,
  falcon400bInputs,
  llama7bInputs,
  openSourcesModels,
} from '../AiConfigs/constants';
import { Box, FormLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { PrettoSlider } from '../../assets/styles/styles';
import { useAuthStore } from '../../store/storeAuth';

type Props = {
  watch: AiTextGeneratorFormValues;
  onChange: (event: any) => void;
  systems?: any[];
};
const modelInputBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '4px',
  mt: '8px',
};

const OpenSources = ({ watch, onChange, systems }: Props) => {
  const watchFor =
    watch?.openSourceModelName === 'falcon-40b-instruct' ||
    watch?.openSourceModelName === 'mpt-7b-storywriter';
    const { user } = useAuthStore(state => state);

  return (
    <Box>
      <Box sx={modelInputBoxStyle}>
        <FormLabel
          htmlFor="openSourceModelName"
          sx={{
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
        >
          Ai Model
        </FormLabel>

        <Select
          className="nodrag"
          name="openSourceModelName"
          value={watch?.openSourceModelName}
          style={{ marginLeft: '8px' }}
          onChange={(e: any) => {
            onChange(e);
          }}
          sx={{
            height: 'unset',
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            border: `2px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
            style: {
              maxHeight: '700px',
            },
          }}
        >
          {systems
            ?.filter((item: any) => item.system === 'replicate')[0]
            .models?.map((opt: any) => (
              <MenuItem
                key={opt.id}
                value={opt.model}
                disabled={opt.disabledByCompany}
              >
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <Typography
                    variant="body2"
                    color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                  >
                    {opt.model}
                  </Typography>
                  {opt.disabledByCompany && (
                    <Typography fontSize={'12px'} color="red">
                      (disabled by admin)
                    </Typography>
                  )}
                </Box>
              </MenuItem>
            ))}
        </Select>
      </Box>

      {watchFor && (
        <div>
          {falcon400bInputs.map(opt => {
            return (
              <Box key={opt.name} style={modelInputBoxStyle}>
                <FormLabel
                  htmlFor={opt.name}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  {opt.label}
                </FormLabel>
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <PrettoSlider
                    value={Number(
                      watch?.[opt.name as keyof AiTextGeneratorFormValues],
                    )}
                    name={opt.name}
                    step={Number(opt.step)}
                    min={Number(opt.min)}
                    max={Number(opt.max)}
                    sx={{ marginLeft: '8px', width: '350px' }}
                    onChange={e => {
                      onChange(e);
                    }}
                    className="nodrag"
                  />

                  <TextField
                    type={'number'}
                    onChange={e => {
                      onChange(e);
                    }}
                    value={watch?.[opt.name as keyof AiTextGeneratorFormValues]}
                    name={opt.name}
                    style={{ marginLeft: '8px', width: '80px' }}
                    className="nodrag"
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                    InputProps={{
                      inputProps: {
                        min: opt.min,
                        max: opt.max,
                        step: opt.step,
                      },
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </div>
      )}

      {watch.openSourceModelName === 'mpt-30b-chat' && (
        <>
          <Box key={'max_new_tokens'} style={modelInputBoxStyle}>
            <FormLabel
              htmlFor={'max_new_tokens'}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
              }}
            >
              Max New Tokens
            </FormLabel>
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <TextField
                type={'number'}
                value={watch?.max_new_tokens}
                onChange={e => {
                  onChange(e);
                }}
                name={'max_new_tokens'}
                style={{ marginLeft: '8px', width: '80px' }}
                className="nodrag"
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 70000,
                    step: 1,
                  },
                  style: {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                }}
              />
            </Box>
          </Box>
        </>
      )}

      {watch.openSourceModelName === 'llama-2-70b-chat' && (
        <div>
          {llama7bInputs.map(opt => {
            return (
              <Box key={opt.name} style={modelInputBoxStyle}>
                <FormLabel
                  htmlFor={opt.name}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                >
                  {opt.label}
                </FormLabel>
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <PrettoSlider
                    step={Number(opt.step)}
                    min={Number(opt.min)}
                    value={Number(
                      watch?.[opt.name as keyof AiTextGeneratorFormValues],
                    )}
                    max={Number(opt.max)}
                    sx={{ marginLeft: '8px', width: '350px' }}
                    name={opt.name}
                    onChange={e => {
                      onChange(e);
                    }}
                    className="nodrag"
                  />

                  <TextField
                    type={'number'}
                    onChange={e => {
                      onChange(e);
                    }}
                    value={watch?.[opt.name as keyof AiTextGeneratorFormValues]}
                    name={opt.name}
                    style={{ marginLeft: '8px', width: '80px' }}
                    className="nodrag"
                    InputProps={{
                      inputProps: {
                        min: opt.min,
                        max: opt.max,
                        step: opt.step,
                      },
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default OpenSources;
