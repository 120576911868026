import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useAuthStore } from "../../store/storeAuth";
import {
  NotificationType,
  useNotificationStore,
} from "../../store/storeNotifications";
import { useForm } from "react-hook-form";
import { imageAi } from "./constants";
import { UserConfigService } from "../../service/UserConfigService";
import { getAPIErrorMessage } from "../../helpers/helpers";
import KitModal from "../UI/KitModal";
import { Box, Button, Typography } from "@mui/material";
import StabilityAiConfigForm from "../settings/AiConfig/StabilityAiConfigForm";
import ImageOpenSourceConfigForm from "../settings/AiConfig/ImageOpenSourceConfigForm";
import ImageOpenAiConfigForm from "../settings/AiConfig/ImageOpenAiConfigForm";

interface ConfigValues {
  name: string;
  aiSystem: string,
  engineId: string,
  clip_guidance_preset: string,
  sampler: string,
  width: string,
  height: string,
  cfgScale: string,
  steps: string,
  image_Strength: string,
  promptWeight: string
  openSourceModel: string,
  openAiModels: string,
  numbersOfVariations: string,
  quality: string
  size: string;
  negativePrompt: string;
}

type Props = {
  show: boolean;
  onHide: () => void;
  isEditingConfig?: {
    id: string;
    edit: boolean;
  };
  setIsEditingConfig?: (editing: { id: string; edit: boolean }) => void;
  saveChanges: () => void;
  imageSystems: string[];
};

export type ImageModalHandle = {
  submitForm: () => void;
};

const ImageModal = forwardRef<ImageModalHandle, Props>(
  (
    { show, onHide, isEditingConfig, setIsEditingConfig, saveChanges, imageSystems }: Props,
    ref
  ) => {
    const { setNotification } = useNotificationStore();
    const [systemType, setSystemType] = useState("stabilityAi");
    const { setConfigs, configs } = useAuthStore((state) => state);
    const imageConfig = configs?.find((config) => config.type === "image");

    const {
      register,
      handleSubmit,
      watch,
      reset,
      control,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: {
        name: isEditingConfig?.id ? imageConfig?.name : "",
        ...imageAi,
        ...imageConfig?.aiConfig,
      },
    });

    useEffect(() => {
      if (isEditingConfig?.edit) {
        if (imageConfig) {
          setSystemType(imageConfig.modelType);
          reset({
            name: imageConfig?.name,
            ...imageAi,
            ...imageConfig?.aiConfig,
          });
        }
      }
    }, [isEditingConfig]);

    const getAiConfigsToBeSaved = (
      systemType: string,
      values: ConfigValues
    ) => {
      if (systemType === "stabilityAi") {
        return {
          engineId: values.engineId,
          clip_guidance_preset: values.clip_guidance_preset,
          sampler: values.sampler,
          width: values.width,
          height: values.height,
          cfgScale: values.cfgScale,
          steps: values.steps,
          image_Strength: values.image_Strength,
          promptWeight: values.promptWeight,
        };
      } else if (systemType === "replicate") {
        return {
          openSourceModel: values.openSourceModel,
        };
      } else if (systemType === "openAi") {
        if (values.openAiModels === "createImage") {
          return {
            openAiModels: values.openAiModels,
            size: values.size,
            quality: values.quality,
          };
        } else if (values.openAiModels === "editImage") {
          return {
            openAiModels: values.openAiModels,
            numbersOfVariations: values.numbersOfVariations,
            size: values.size,
          };
        } else if (values.openAiModels === "imageVariation") {
          return {
            openAiModels: values.openAiModels,
            numbersOfVariations: values.numbersOfVariations,
            size: values.size,
          };
        }
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onFinish),
    }));

    const onFinish = async (values: ConfigValues) => {
      try {
        if (isEditingConfig?.edit && isEditingConfig?.id) {
          const response = await UserConfigService.updateConfig(
            isEditingConfig.id,
            {
              aiConfig: getAiConfigsToBeSaved(systemType, values),
              name: 'image',
              type: "image",
              modelType: systemType,
            }
          );

          const newConfigs = configs.map((config) => {
            if (config.id === isEditingConfig?.id) {
              return {
                ...config,
                name: 'image',
                aiConfig: response.data.aiConfig,
                type: "image",
                modelType: systemType,
              };
            }
            return config;
          });
          setConfigs(newConfigs);
          setIsEditingConfig!({
            id: "",
            edit: false,
          });
          return;
        }

        const response = await UserConfigService.createConfig({
          name: 'image',
          type: "image",
          modelType: systemType,
          aiConfig: getAiConfigsToBeSaved(systemType, values),
        });

        setConfigs([...configs, response.data]);
        setIsEditingConfig!({
          id: "",
          edit: false,
        });
      } catch (error) {
        console.log(error)
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    const revertToDefault = () => {
      setSystemType("stabilityAi");
      reset({
        ...imageAi,
      });
    }

    const onClose = () => {
      setIsEditingConfig!({
        id: "",
        edit: false,
      });
      onHide();
    };

    const confirmChanges = () => {
      onHide();
      saveChanges();
    };

    return (
      <KitModal
        onHide={() => {
          onClose();
        }}
        show={show}
      >
        <Typography
          fontSize={"20px"}
          fontWeight={500}
          lineHeight={"32px"}
          color={"#000"}
        >
          Image Configurations
        </Typography>
        <form
          action="submit"
          onSubmit={handleSubmit(onFinish)}
          style={{
            paddingTop: "10px",
            minWidth: "500px",
            overflow: "hidden",
          }}
        >
          {systemType === "stabilityAi" && (
            <StabilityAiConfigForm
              systems={imageSystems}
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              setSystemType={setSystemType}
              systemType={systemType}
              watch={watch}
            />
          )}

          {systemType === "openSources" && (
            <ImageOpenSourceConfigForm
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              setSystemType={setSystemType}
              systemType={systemType}
              watch={watch}
            />
          )}

          {systemType === "openAi" && (
            <ImageOpenAiConfigForm
              systems={imageSystems}
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
              setSystemType={setSystemType}
              systemType={systemType}
              watch={watch}
            />
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "32px",
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: "100%", mt: "16px" }}
              onClick={revertToDefault}
            >
              Revert to default
            </Button>
            <Button
              variant="contained"
              sx={{ width: "100%", mt: "16px" }}
              onClick={confirmChanges}
            >
              Continue
            </Button>
          </Box>
        </form>
      </KitModal>
    );
  }
);

export default ImageModal;
