import { memo, useEffect, useCallback, useState } from 'react';
import {
  NodeResizer,
  NodeToolbar,
  useEdges,
  useReactFlow,
  useStore,
} from 'reactflow';

import { useDebounceEffect } from '../../util/useDebounceEffect';
import DeleteNode from '../UI/DeleteNode/DeleteNode';
import { useDetachNodes } from '../../util/useDetachNodes';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box, Paper } from '@mui/material';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useParams } from 'react-router-dom';
import { useAuthStore } from '../../store/storeAuth';

function TextBoxNode({ id, data }: NodeProps) {
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();
  const { user: authUser } = useAuthStore(state => state);

  const { setSaving, setSchema } = useFlowsStore(state => state);
  const [textBoxValue, setTextBoxValue] = useState(data?.text);
  const detachNodes = useDetachNodes();
  const hasParent = useStore(
    store => !!store.nodeInternals.get(id)?.parentNode,
  );
  const edges = useEdges();
  const { flowId } = useParams();

  const onDetach = () => detachNodes([id]);

  const onChange = useCallback((evt: { target: { value: any } }) => {
    setIsChangingDirectly(true);
    setTextBoxValue(evt.target.value);
  }, []);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: { text: textBoxValue },
          setSchema,
        });
      }
    },
    [isChangingDirectly, textBoxValue],
    500,
  );

  useEffect(() => {
    if (!isChangingDirectly) {
      setTextBoxValue(data.text);
    }
  }, [data?.text, isChangingDirectly]);

  return (
    <>
      {authUser?.theme === 'dark' && (
        <Box
          sx={{
            backgroundColor: authUser?.theme !== 'dark' ? '#FDDF82' : '#FDDF82', // yellow
            height: '10px',
            borderRadius: '4px 4px 0px 0px',
          }}
        />
      )}
      <Paper
        sx={{
          padding: theme =>
            authUser?.theme !== 'dark' ? theme.spacing(2) : '0 16px 16px 16px',
          backgroundColor: authUser?.theme !== 'dark' ? ' #FDDF82' : '#1D2939', // yellow
          borderRadius: authUser?.theme !== 'dark' ? '4px' : '0 0 4px 4px', // no curves
          position: 'relative',
          boxShadow:
            '0px 8px 38px 7px rgba(0, 0, 0, 0.12), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 10px 13px -6px rgba(0, 0, 0, 0.20)',
        }}
      >
        <NodeResizer isVisible={false} />

        {/* <br /> */}
        <NodeToolbar className="nodrag">
          {hasParent && <button onClick={onDetach}>Detach</button>}
        </NodeToolbar>
        <Box display={'flex'} justifyContent={'end'}>
          <DeleteNode nodeId={id} />
        </Box>
        <Box>
          {!data.collapsed && (
            <>
              <textarea
                placeholder={'Add any comments you would like'}
                disabled={data?.disabled}
                value={textBoxValue}
                onChange={onChange}
                name="text"
                className="nodrag"
                maxLength={200}
                style={{
                  resize: 'none',
                  color: authUser?.theme !== 'dark' ? '#344054' : '#D0D5DD',
                  overflow: 'hidden',
                  width: '252px',
                  textAlign: 'center',
                  fontFamily: 'inherit',
                  fontSize: '20px',
                  height: '220px',
                  backgroundColor: 'transparent',
                  border: 'none',
                  outline: 'none',
                }}
              ></textarea>
            </>
          )}
        </Box>
      </Paper>
    </>
  );
}

export default memo(TextBoxNode);
