import React from 'react';
import { useNodesStore } from '../../../store/storeNodesEdeges';
import { Box } from '@mui/material';
import { XCircle } from '@phosphor-icons/react';
type Props = {
  nodeId: string;
};

const DeleteNode = ({ nodeId }: Props) => {
  const setDelete = useNodesStore(state => state.setDeleteNode);

  return (
    <XCircle
      style={{
        cursor: 'pointer',
      }}
      color='#667085'
      size={20}
      onClick={() => setDelete(nodeId)}
    >
      X
    </XCircle>
  );
};

export default DeleteNode;
