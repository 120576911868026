import React from 'react';
import {
  List,
  ListSubheader,
  ListItemButton,
  Typography,
  Box,
} from '@mui/material';
import { format, parseISO, isToday, isYesterday } from 'date-fns';

type ChatSession = {
  id: string;
  name: string;
  lastTimestamp: string | null;
  entries: any[];
};

type HistoryListProps = {
  groupedSections: { [key: string]: ChatSession[] };
  onSelectSession: (sessionId: string) => void;
  selectedSessionId: string;
};

export const HistoryList: React.FC<HistoryListProps> = ({
  groupedSections,
  onSelectSession,
  selectedSessionId,
}) => {
  const formatTime = (timestamp: string) => {
    try {
      const date = parseISO(timestamp);
      return format(date, 'h:mm a');
    } catch (error) {
      console.error('Invalid date:', timestamp);
      return '';
    }
  };

  const sectionOrder = ['Today', 'Yesterday', 'Previous days'];

  const sortedSections = Object.keys(groupedSections)
    .filter(key => !sectionOrder.includes(key))
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  const allSections = [...sectionOrder, ...sortedSections];

  return (
    <List
      sx={{
        width: '100%',
        maxHeight: 'calc(100vh - 200px)',
        backgroundColor: '#F9FAFB',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      {allSections.map(sectionKey => {
        const sessions = groupedSections[sectionKey];
        if (!sessions || sessions.length === 0) return null;

        return (
          <React.Fragment key={sectionKey}>
            <ListSubheader
              sx={{
                backgroundColor: '#F9FAFB',
                color: '#344054',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              {sectionKey}
            </ListSubheader>
            {sessions.map(session => (
              <ListItemButton
                key={session.id}
                onClick={() => onSelectSession(session.id)}
                sx={{
                  fontWeight: session.id === selectedSessionId ? '600' : '400',
                  color:
                    session.id === selectedSessionId ? '#7B4CFE' : '#344054',
                  pl: 2,
                  backgroundColor: '#F9FAFB',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography>
                  {session.id === 'default' ? 'Primary' : session.name}
                </Typography>
                {session.lastTimestamp && (
                  <Box
                    component="span"
                    sx={{ fontSize: '12px', color: '#667085' }}
                  >
                    {formatTime(session.lastTimestamp)}
                  </Box>
                  // ) : (
                  //   <Box
                  //     component="span"
                  //     sx={{ fontSize: '12px', color: '#667085' }}
                  //   >
                  //     {session.id === 'default' ? '' : 'New'}
                  //   </Box>
                )}
              </ListItemButton>
            ))}
          </React.Fragment>
        );
      })}
    </List>
  );
};
