import { create } from 'zustand';

type HubSpotData = {
  isHubSpotConnected: boolean;
  setIsHubSpotConnected: (isHubSpotConnected: boolean) => void;
};

export const useStoreHubSpotData = create<HubSpotData>(set => ({
  isHubSpotConnected: false,

  setIsHubSpotConnected: (isHubSpotConnected: boolean) =>
    set(() => {
      return { isHubSpotConnected };
    }),
}));
