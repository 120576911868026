import { memo, useEffect, useRef, useState } from 'react';
import { NodeProps } from '../../util/Types/NodeProps';
import { useEdges, useReactFlow } from 'reactflow';
import { OpenAiService } from '../../service/OpenAiService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { Box, Chip, Divider, Typography } from '@mui/material';
import useFlowsStore from '../../store/storeFlows';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { YoutubeService } from '../../service/YoutubeService';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import TagInput from '../Test/TagInput';
import UploadBox from '../UI/UploadBox/UploadBox';
import { CaretDown, Faders } from '@phosphor-icons/react';
import SettingsModal from '../AiAudioTranscriber/SettingsModal/SettingsModal';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { GeminiService } from '../../service/GeminiService';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { UploadService } from '../../service/UploadService';
import { FileService } from '../../service/FileService';
import { GroqService } from '../../service/GroqService';

export interface FormDataTranscriber {
  youtubeUrl: string;
  type: string;
  ytType: string;
  inputPreview: string;
  text: string;
  uploadAudio?: undefined | File;
  aiModel: string;
}

function normalizedText(text: string | { message: string }) {
  return typeof text === 'string' ? text : text?.message || '';
}

const AudioReader = ({ id, data, isConnectable, type }: NodeProps) => {
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
    isChangingDirectly: false,
  });
  const { user } = useAuthStore(state => state);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<FormDataTranscriber>({
    youtubeUrl: data?.youtubeUrl || '',
    type: data?.type
      ? data?.type === 'openAi'
        ? 'audio'
        : data.type
      : 'audio',
    ytType: data?.ytType || 'text',
    text: data?.text || '',
    inputPreview: data?.inputPreview || '',
    aiModel: data?.aiModel || 'openAi',
    uploadAudio: undefined,
  });
  const setNotification = useNotificationStore(state => state.setNotification);
  const [previewResponses, setPreviewResponses] = useState(
    data?.previewResponses || [],
  );

  const {
    setSocketNodeResponse,
    socketNodeResponse,
    flowId,
    setSchema,
    setSaving,
  } = useFlowsStore(state => state);

  const edges = useEdges();

  const debounceTimeoutRef = useRef<any>(null);

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            changeType: 'changingPreviewIndex',
            id,
            setSaving,
            flowId,
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          handleSubmit();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [hasToExecute, data, formData, edges]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const inputPreview = isActiveEdge(edges, id, 'inputPreview', 'target')
        ? data.inputPreview
        : formData.inputPreview;

      if (formData.type === 'ytTranscript') {
        let text = data.youtubeUrl || '';

        if (data?.youtubeUrlData?.length) {
          text = data?.youtubeUrlData?.map((item: any) => item?.text).join('');
        }

        const response = await YoutubeService.response({
          url: text,
        });

        let txtResponse = '';

        if (data?.ytType === 'textMtd') {
          txtResponse = Object.values(response.data)
            .map((test: any) => {
              return `Text:${test?.text} \n Duration: ${test?.duration}\n Offset: ${test?.start} \n \n`;
            })
            .join(' ');
        } else {
          txtResponse = Object.values(response?.data)
            ?.map((test: any) => test?.text)
            ?.join(' ');
        }

        let responses: any[] = [];

        if (previewResponses?.length) {
          responses = [...previewResponses];
        }

        if (responses.length === 10) {
          responses.shift();
        }

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],
          flowTriggerData: checkExecuteTrigger(data, id),
          flowId,
          objectData: {
            text: txtResponse,
            previewResponses: [...responses, txtResponse],
            previewIndex: responses.length,
          },
          setSchema,
        });

        setExecuteCounter(0);

        setTimeout(() => {
          setHasToExecute(true);
        }, 2500);

        setIsLoading(false);
        return;
      }
      if (formData.type === 'audio') {
        if (formData.aiModel === 'openAi') {
          if (inputPreview) {
            const res = await OpenAiService.convertAudioToText({
              url: inputPreview,
            });
          }
        }
        if (formData.aiModel === 'gemini') {
          const res = await GeminiService.speechToText({
            fileUrl: inputPreview,
          });

          let responses = [...(previewResponses || [])];
          if (responses.length === 10) {
            responses.shift();
          }

          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            setSaving,
            flowTriggerData: checkExecuteTrigger(data, id),
            flowId,
            changeType: 'execute',
            objectCallerData: [
              ...(user?.spaceObjects || []),
              ...(user?.objects || []),
            ],
            objectData: {
              text: res?.data?.message,
              previewResponses: [...responses, res?.data?.message],
              previewIndex: responses.length,
              usedTokens: data?.flowTrigger?.id
                ? res?.data.usedTokens
                : undefined,
              nodeUsedTokens: res?.data?.usedTokens,
            },
            setSchema,
          });

          setExecuteCounter(0);

          setIsLoading(false);
          return;
          // }
        }
        if (formData.aiModel === 'groq') {
          if (
            inputPreview &&
            /\.(mp3|flac|mp4|mpeg|mpga|ogg|wav|webm|m4a)$/.test(inputPreview)
          ) {
            const res = await GroqService.whisperLargeV3({
              fileUrl: inputPreview,
              flowId,
            });

            let responses = [...(previewResponses || [])];
            if (responses.length === 10) {
              responses.shift();
            }

            nodeDataAutoSaveDynamic({
              newEdges: edges,
              setNodeState,
              id,
              setSaving,
              flowTriggerData: checkExecuteTrigger(data, id),
              flowId,
              changeType: 'execute',
              objectCallerData: [
                ...(user?.spaceObjects || []),
                ...(user?.objects || []),
              ],
              objectData: {
                text: res?.data?.message,
                previewResponses: [...responses, res?.data?.message],
                previewIndex: responses.length,
                usedTokens: data?.flowTrigger?.id
                  ? res?.data.usedTokens
                  : undefined,
                nodeUsedTokens: res?.data?.usedTokens,
              },
              setSchema,
            });

            setExecuteCounter(0);

            setIsLoading(false);
            return;
          } else {
            setIsLoading(false);
            setNotification({
              type: 'error',
              message:
                'Invalid file type, only mp3, flac, mp4, m4a, mpeg, mpga, ogg, wav or webm files are supported',
            });
          }
        }
      }
      setSocketNodeResponse(id, null);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setIsLoading(false);
      setNotification({
        message: getAPIErrorMessage(error as any),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
        objectData: {
          text: normalizedText(data?.text),
          previewResponses: data?.previewResponses,
          previewIndex: data?.previewIndex,
          usedTokens: data?.flowTrigger?.id
            ? data?.text?.usedTokens
            : undefined,
          nodeUsedTokens: data?.text?.usedTokens,
        },
        setSchema,
      });

      setHasToExecute(true);
      setIsLoading(false);
      setSocketNodeResponse(null, null);
    }
  }, [
    socketNodeResponse?.nodeId,
    socketNodeResponse?.finished,
    data?.flowTrigger,
    data?.text,
    data?.previewResponses,
    data?.previewIndex,
    user?.objects,
  ]);

  useDebounceEffect(
    () => {
      if (whoIsChanging.isChangingDirectly) {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setSaving,
          setNodeState,
          id,

          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
          isChangingDirectly: false,
        });
      }
    },
    [whoIsChanging.isChangingDirectly],
    300,
  );

  const onChange = (evt: { target: { name: any; value: any } }) => {
    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));
    setWhoIsChanging({
      name: evt.target.name,
      value: evt.target.value,
      isChangingDirectly: true,
    });
  };

  const deleteImage = () => {
    setFormData({
      ...formData,
      uploadAudio: undefined,
      inputPreview: '',
    });

    if (!formData.inputPreview?.includes('blob')) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          inputPreview: '',
        },
        setSchema,
      });
    }
  };

  const onChangeOnExecute = (e: any) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        clearOnExecution: e.target.checked,
      },
      setSchema,
    });
  };

  const handleAudioUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);

      const fileService = await FileService.createFormFIle({
        name: response?.data.name,
        src: response.data.src,
        flowId: flowId,
      });

      if (response.data && response.data.src) {
        setFormData(prevState => {
          const newState = {
            ...prevState,
            uploadAudio: file,
            inputPreview: response.data.src,
          };
          // console.log("New form data state:", newState);
          return newState;
        });

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            uploadAudio: file,
            inputPreview: response.data.src,
          },
          setSchema,
          newEdges: edges,
        });
      } else {
        console.error('Invalid response from server:', response);
        throw new Error('Invalid server response');
      }
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
    setIsLoading(false);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          type={type}
          btnText="Run Prompt"
          id={id}
          isLoading={isLoading}
          data={data}
          edges={edges}
          onSubmit={handleSubmit}
        >
          <Box display={'flex'}>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
            >
              {data.placement !== 'Output' && (
                <>
                  <Typography
                    fontSize={'16px'}
                    color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                    fontWeight={600}
                  >
                    {'Media File'}
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      mb: '24px',
                      mt: '8px',
                    }}
                  >
                    <Chip
                      sx={{
                        borderRadius: '8px',
                        fontSize: '12px',
                        color: '#fff',

                        background:
                          formData.type !== 'audio' ? '#C9E7CA' : '#67BB6A',
                        '&:hover': {
                          background: '#67BB6A',
                        },
                      }}
                      onClick={() => {
                        onChange({
                          target: {
                            value: 'audio',
                            name: 'type',
                          },
                        });
                      }}
                      label={'Audio File'}
                    />
                    <Chip
                      sx={{
                        borderRadius: '8px',
                        fontSize: '12px',
                        color: '#fff',
                        background:
                          formData.type === 'audio' ? '#C9E7CA' : '#67BB6A',
                        '&:hover': {
                          background: '#67BB6A',
                        },
                      }}
                      onClick={() => {
                        onChange({
                          target: {
                            value: 'ytTranscript',
                            name: 'type',
                          },
                        });
                      }}
                      label={'Youtube Video'}
                    />
                  </Box>
                </>
              )}
              {formData.type === 'audio' ? (
                <UploadBox
                  labelName="Audio Recording"
                  value={data?.inputPreview || formData.inputPreview}
                  onChange={onChange}
                  name="inputPreview"
                  type={'audio'}
                  disabled={isActiveEdge(edges, id, 'inputPreview', 'target')}
                  id={id}
                  // noUrl={formData.aiModel === 'gemini'}
                  placement={data?.placement}
                  onChangeUploadImage={handleAudioUpload}
                  findColor={findColor}
                  uploadedFile={formData.uploadAudio}
                  handleId="inputPreview"
                  deleteImage={deleteImage}
                />
              ) : (
                <TagInput
                  type="text"
                  name="youtubeUrl"
                  handleId="youtubeUrl"
                  isCollapsed={true}
                  isAdvanced={true}
                  findColor={findColor}
                  nodeId={id}
                  dataConnected={data?.youtubeUrlData || []}
                  placement={data?.placement}
                  advancedCollapsed={true}
                  labelName="Youtube Url"
                  disabled={isActiveEdge(edges, id, 'youtubeUrl', 'target')}
                  value={formData.youtubeUrl}
                  onChange={onChange}
                  nodeLabel={data?.label}
                  placeholder="Youtube Url"
                />
              )}

              {data?.placement !== 'Output' && (
                <>
                  <Divider
                    sx={{
                      // mt: !collapsed ? '20px' : '200px',
                      mt: '24px',
                      mb: '10px',
                    }}
                  />
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                      borderRadius={'4px'}
                      sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                      onClick={() => setSettingsModalOpen(true)}
                    >
                      <Faders size={18} color="#667085" />
                      <Typography
                        fontSize={'12px'}
                        color="#667085"
                        variant="h6"
                      >
                        {formData.type === 'audio'
                          ? formData.aiModel === 'openAi'
                            ? 'OpenAI'
                            : formData.aiModel === 'gemini'
                            ? 'Gemini'
                            : formData.aiModel === 'groq'
                            ? 'Groq'
                            : 'Unknown AI'
                          : 'Youtube Transcriber'}
                      </Typography>

                      <CaretDown size={'16px'} color="#667085" />
                    </Box>
                  </Box>
                </>
              )}
              <SettingsModal
                onHide={() => setSettingsModalOpen(false)}
                show={settingsModalOpen}
                formData={formData}
                onChange={onChange}
              />
            </Box>
            <Box p={'16px 24px 16px 16px'}>
              <OutputTextarea
                previewResponses={previewResponses}
                previewIndex={data.previewIndex}
                onChangeOnExecute={onChangeOnExecute}
                value={normalizedText(data.text)}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                placement={data?.placement}
                labelName={'Output'}
                clearOnExecution={data.clearOnExecution || false}
                onPreview={onPreview}
                nodeLabel={data?.label}
                findColor={findColor}
                hasClearOnExecute={true}
              />
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
};

export default memo(AudioReader);
