import KitModal from '../../UI/KitModal';
import {
  Box,
  Chip,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { openSourceModelsImage } from '../../AiConfigs/constants';
import { ImageService } from '../../../service/ImageService';
import { useEffect, useState } from 'react';
import { useNotificationStore } from '../../../store/storeNotifications';
import { FormData } from '../constants';
import StabilityAi from '../StabilityAi';
import OpenAiImg from '../OpenAiImg';
import { useAuthStore } from '../../../store/storeAuth';
import { getAPIErrorMessage } from '../../../helpers/helpers';
import IdeogramAi from '../IdeogramAi';
import Fal from '../Fal';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: FormData;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  const setNotifications = useNotificationStore(state => state.setNotification);
  const { technologies } = useAuthStore(state => state);

  const imageSystems =
    formData.type === 'Text to Image'
      ? ['stabilityAi', 'openAi', 'replicate', 'ideogram', 'fal']
      : ['stabilityAi', 'ideogram'];

  const filteredImageSystems = technologies
    ?.filter(system => imageSystems.includes(system.system))
    .map(system => ({
      ...system,
      models: system?.models?.filter((model: any) => model.type === 'Image'),
    }));

  const [imageModels, setImageModels] = useState<
    {
      description: string;
      id: string;
      name: string;
      type: string;
    }[]
  >([]);
  useEffect(() => {
    const getImageLL = async () => {
      try {
        const response = await ImageService.getImage();

        setImageModels(response.data.message);
      } catch (error) {
        setNotifications({
          type: 'error',
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    getImageLL();
  }, []);

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '680px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      showCloseButton={false}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant="h6">Advanced Settings</Typography>
        <Box mt={'16px'} p={'8px'} borderRadius={'4px'}>
          <Typography fontSize={'14px'} variant="h6">
            Prompt Input
          </Typography>

          <Box>
            <FormLabel
              sx={{
                mb: '4px',
              }}
            >
              System
            </FormLabel>

            <Select
              className="nodrag"
              fullWidth
              value={formData.aiModels}
              size="small"
              onChange={(e: any) => {
                onChange?.(e);
              }}
              name={'aiModels'}
              sx={{
                width: '100%',
              }}
            >
              {filteredImageSystems?.map((opt, index) => {
                const disabled =
                  opt.models.length > 0
                    ? opt.models?.every((model: any) => model.disabledByCompany)
                    : false;
                return (
                  <MenuItem
                    key={index}
                    value={opt.system}
                    disabled={disabled}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {opt.system.charAt(0).toUpperCase() + opt.system.slice(1)}
                    {disabled && (
                      <Chip
                        label="Disabled by admin"
                        color="error"
                        size="small"
                        variant="filled"
                        sx={{
                          borderRadius: '8px',
                        }}
                      />
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          {formData.aiModels === 'stabilityAi' && (
            <StabilityAi
              onChange={onChange}
              formData={formData}
              imageModels={imageModels}
            />
          )}

          {formData.aiModels === 'openSources' && (
            <Box>
              <FormLabel htmlFor="openSourceModel">Models </FormLabel>
              <Select
                name="openSourceModel"
                className="nodrag"
                fullWidth
                value={formData.openSourceModel}
                onChange={onChange}
              >
                {openSourceModelsImage?.map((imageModel, index) => (
                  <MenuItem key={index} value={imageModel.value}>
                    {imageModel.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {formData.aiModels === 'openAi' && (
            <OpenAiImg onChange={onChange} formData={formData} />
          )}
          {formData.aiModels === 'ideogram' && (
            <IdeogramAi onChange={onChange} formData={formData} />
          )}
          {formData.aiModels === 'fal' && (
            <Fal onChange={onChange} formData={formData} />
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
