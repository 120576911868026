const voices = [ 
    {
        id: "en-US-Wavenet-A",
        name: "an OK Voice",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "larry",
        name: "Larry",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "jordan",
        name: "Jordan",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "susan",
        name: "Susan",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "william",
        name: "William",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "oliver",
        name: "Oliver",
        language: "English (UK)",
        description: "— Sample",
      },
      {
        id: "alfonso",
        name: "Alfonso",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "daniel",
        name: "Daniel",
        language: "English (CA)",
        description: "— Sample",
      },
      {
        id: "charlotte",
        name: "Charlotte",
        language: "English (CA)",
        description: "— Sample",
      },
      {
        id: "adrian",
        name: "Adrian",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "alexander",
        name: "Alexander",
        language: "English (UK)",
        description: "— Sample",
      },
      {
        id: "anthony",
        name: "Anthony",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "aurora",
        name: "Aurora",
        language: "English (UK)",
        description: "— Sample",
      },
      {
        id: "axel",
        name: "Axel",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "carter",
        name: "Carter",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "Daisy",
        name: "Daisy",
        language: "English (UK)",
        description: "— Sample",
      },
      {
        id: "darcie",
        name: "Darcie",
        language: "English (CA)",
        description: "— Sample",
      },
      {
        id: "ellie",
        name: "Ellie",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "evelyn",
        name: "Evelyn",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "frankie",
        name: "Frankie",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "frederick",
        name: "Frederick",
        language: "English (UK)",
        description: "— Sample",
      },
      {
        id: "harrison",
        name: "Harrison",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: "hudson",
        name: "Hudson",
        language: "English (US)",
        description: "— Sample",
      },
      {
        id: 'Hunter',
        name: 'Hunter',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'lillian',
        name: 'Lillian',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'lottie',
        name: 'Lottie',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'maverick',
        name: 'Maverick',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'bret',
        name: 'Bret',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'nolan',
        name: 'Nolan',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'nova',
        name: 'Nova',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'owen',
        name: 'Owen',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'phoebe',
        name: 'Phoebe',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'stella',
        name: 'Stella',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'theodore',
        name: 'Theodore',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'arthur',
        name: 'Arthur',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'bruce',
        name: 'Bruce',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'bryan',
        name: 'Bryan',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'Carlo',
        name: 'Carlo',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'domenic',
        name: 'Domenic',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'hayden',
        name: 'Hayden(Cooper)',
        language: 'English (US)',
        gender: '—',
        sample: 'Sample',
      },
      {
        id: 'reynaldo',
        name: 'Reynaldo',
        language: 'English (UK)',
        gender: '—',
        sample: 'Sample',
      },
  ];
  
  export default voices;