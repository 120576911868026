import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FlowIcon } from '../../assets/icons/flow-dashboard.svg';
import moment from 'moment';
import { BoundingBox, FileText } from '@phosphor-icons/react';

interface Props {
  flows: {
    id: string;
    name: string;
    folder: { name: string };
    createdAt: Date;
    isForm: boolean;
    spaceId: string;
  }[];
}
const FlowsTable: React.FC<Props> = ({ flows }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table size={'medium'}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Folder</TableCell>
                <TableCell align="right">Creation Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flows.map(flow => {
                return (
                  <TableRow
                    hover
                    onClick={() => {
                      if (flow?.isForm) {
                        navigate(`/form/${flow.id}?spaceId=${flow?.spaceId}`);
                        return;
                      }

                      navigate(`/flow/${flow.id}`);
                    }}
                    tabIndex={-1}
                    key={flow?.id}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell
                      sx={{
                        color: 'rgba(71, 84, 103, 1)',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      {flow?.isForm ? (
                        <FileText size={16} weight="fill" color="#3650DE" />
                      ) : (
                        <BoundingBox size={16} weight="fill" color="#3650DE" />
                      )}
                      <Typography>{flow?.name}</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '14px' }}
                    >
                      {flow?.folder?.name}
                    </TableCell>
                    <TableCell
                      sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                      align="right"
                    >
                      {moment(flow?.createdAt).format('MM/DD/YYYY')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default FlowsTable;
