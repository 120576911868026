import { Box, FormControl, FormLabel, InputLabel, MenuItem, Select, Slider, TextField, Typography } from "@mui/material";
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, WatchInternal } from "react-hook-form";
import { additionalOptionsArray, groqFields, groqOptions, systemDisplayNameMap, systemValueMap } from "../../AiConfigs/constants";

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[]
}

const GroqConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems
}: Props) => {

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
      {/* <KitInput
        label="Name"
        name="name"
        type="text"
        errorsStatus={errors}
        register={register}
      /> */}
      <Box
        mt={"16px"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "32px",
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
          <Select
            name="AiSystem"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            onChange={(e) => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem
                  key={index}
                  value={systemValue}
                  disabled={disabled}
                >
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <InputLabel id="demo-simple-select-label">Ai Model</InputLabel>
          <Select
            value={watch().groqModel}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            {...register("groqModel")}
            sx={{
              width: "100%",
            }}
          >
            {groqOptions.map((opt) => (
              <MenuItem
                key={opt.value}
                value={opt.value}
                disabled={opt.disabled}
              >
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        {groqFields.map((field) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              {field.label}
            </Typography>
            <Slider
              {...register(field.name)}
              step={Number(field.step)}
              min={Number(field.min)}
              max={Number(field.max)}
              value={Number(watch()?.[field.name])}
              onChange={(event, value) => setValue(field.name, value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.[field.name]}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box>
        <FormLabel
          sx={{
            mb: "4px",
            color: "#101828",
          }}
          htmlFor="groqStop"
        >
          Groq Stop
        </FormLabel>
        <TextField
          type={"text"}
          fullWidth
          value={watch()?.groqStop}
          className="nodrag"
          InputProps={{
            style: {
              color: "#101828",
            },
          }}
          sx={{
            color: "#101828",
            bgcolor: "#F9FAFB",
            border: "#EAECF0",
          }}
          {...register("groqStop")}
        />
      </Box>
    </Box>
  );
};

export default GroqConfigForm;