import OutputControllerNode from '../components/nodes/OutputControllerNode';
import SmallMemory from '../components/nodes/SmallMemory';
import ApiInput from '../components/nodes/ApiInput';
import ApiOutput from '../components/nodes/ApiOutput';
import TextBoxNode from '../components/nodes/TextBoxNode';
import RichTextBoxNode from '../components/nodes/RichTextBoxNode';
import AiAudioGenerator from '../components/nodes/AiAudioGenerator';
import FlowGlobalNode from '../components/nodes/FlowGlobalNode';
import DecisionPaths from '../components/nodes/DecisionPaths';
import LargeMemory from '../components/nodes/LargeMemory';
import ObjectCaller from '../components/nodes/ObjectCaller';
import FlowCaller from '../components/nodes/FlowCaller';
import FlowGlobalEdge from '../components/edges/FlowGlobalEdge';
import ImageGenerator from '../components/nodes/ImageGenerator';
import NodesGroup from '../components/nodes/NodesGroup';
import SequenceNode from '../components/nodes/SequenceNode';
import WebSearch from '../components/nodes/WebSearch';
import FlowDataCaller from '../components/nodes/FlowDataCaller';
import ProcessCounter from '../components/nodes/ProcessCounter';
import MathFunction from '../components/nodes/MathFunction';
import Scripting from '../components/nodes/Scripting';
import TableProcessor from '../components/nodes/TableProcessor';
import TableOutput from '../components/nodes/TableOutput';
import DynamicSettingNode from '../components/nodes/DynamicSettingNode';
import AiAudioTranscriber from '../components/nodes/AiAudioTranscriber';
import AiMusicGenerator from '../components/nodes/AiMusicGenerator';
import ApiCaller from '../components/nodes/ApiCaller';
import SqlQuerier from '../components/nodes/SqlQuerier';
import ImageReader from '../components/nodes/ImageReader';
import WebCrawling from '../components/nodes/WebCrawling';
import WebTrends from '../components/nodes/WebTrends';
import AiVideoGenerator from '../components/nodes/AiVideoGenerator';
import UsCensusNode from '../components/nodes/UsCensusNode';
import AiTextSummarizer from '../components/nodes/AiTextSummarizer';
import SecApiNode from '../components/nodes/SecApiNode';
import WebAmazon from '../components/nodes/WebAmazon';
import StickyNote from '../components/nodes/StickyNote';
import PullData from '../components/PullDataNode/PullDataNode';
import PushData from '../components/PushDataNode/PushDataNode';
import AiTextGenerator from '../components/nodes/AiTextGenerator';
import StoredDataCaller from '../components/nodes/StoredDataCaller';
import TitleNode from '../components/FormNodes/TitleNode/TitleNode';
import ShortText from '../components/FormNodes/ShortText/ShortText';
import ImageComponent from '../components/FormNodes/ImageComponent/ImageComponent';
import SubmitButtonNode from '../components/FormNodes/SubmitButtonNode/SubmitButtonNode';
import InputNodeDisplay from '../components/FormNodes/InputNodeDisplay/InputNodeDisplay';
import OutputNodeDisplay from '../components/FormNodes/OutputNodeDisplay/OutputNodeDisplay';
import FileSave from '../components/FileSave/FileSave';
import List from '../components/FormNodes/ListNode/List';
import FlowCreator from '../components/nodes/FlowCreator';
import LinesNode from '../components/FormNodes/LinesNode';
import PullHubSpotData from '../components/HubSpotDataNode/PullHubSpotDataNode';

export const nodeTypes = {
  globalStarter: FlowGlobalNode,
  commandTextNode: AiTextGenerator,
  outputController: OutputControllerNode,
  fluxObject: SmallMemory,
  varInputNode: ApiInput,
  outputObjectNode: ApiOutput,
  conditionNode: DecisionPaths,
  textBox: TextBoxNode,
  richTextBox: RichTextBoxNode,
  voiceBoxNode: AiAudioGenerator,
  commandContentNode: LargeMemory,
  nodesBox: ObjectCaller,
  fluxBox: FlowCaller,
  storedDataCaller: StoredDataCaller,
  imageGenerator: ImageGenerator,
  nodesGroup: NodesGroup,
  arrayNode: SequenceNode,
  webSearch: WebSearch,
  // webMedia: WebMedia,
  // webLocations: WebLocations,
  // webNews: WebNews,
  deepRefNode: FlowDataCaller,
  mathFunction: MathFunction,
  scripting: Scripting,
  counterNode: ProcessCounter,
  tableProcessor: TableProcessor,
  outputTable: TableOutput,
  dynamicSettingsNode: DynamicSettingNode,
  audioReaderNode: AiAudioTranscriber,
  commandMusicNode: AiMusicGenerator,
  apiCaller: ApiCaller,
  sqlQuerier: SqlQuerier,
  imageReaderNode: ImageReader,
  webCrawling: WebCrawling,
  webTrends: WebTrends,
  animationNode: AiVideoGenerator,
  usCensusNode: UsCensusNode,
  documentSummarizer: AiTextSummarizer,
  secApiNode: SecApiNode,
  webAmazon: WebAmazon,
  stickyNote: StickyNote,
  pullData: PullData,
  pushData: PushData,
  fileSave: FileSave,
  nodesAdd: FlowCreator,
  hubSpotCRM: PullHubSpotData,
};

export const edgeTypes = {
  custom: FlowGlobalEdge,
};

export const formNodeTypes = {
  title: TitleNode,
  shortText: ShortText,
  listNode: List,
  image: ImageComponent,
  submit: SubmitButtonNode,
  inputDisplay: InputNodeDisplay,
  outputDisplay: OutputNodeDisplay,
  linesNode: LinesNode,
};
