import { Box, FormControl, FormLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {
    Control,
    FieldErrors,
    UseFormRegister,
    UseFormSetValue,
    WatchInternal,
} from "react-hook-form";
import {
    imageAiModels,
    imageOpenAiModels
} from "../../AiConfigs/constants";

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[];
}

const ImageOpenAiConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems,
}: Props) => {
  return (
    <Box
      mt={'16px'}
      sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}
    >
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
        <Select
          name="AiSystem"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Ai System"
          value={systemType}
          onChange={e => {
            setSystemType(e.target.value);
          }}
          sx={{
            width: '100%',
          }}
        >
          {systems.map((opt, index) => (
            <MenuItem
              key={index}
              value={opt.system}
              // disabled={opt.disabled}
            >
              {opt.system.charAt(0).toUpperCase() + opt.system.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <InputLabel id="demo-simple-select-label">Model</InputLabel>
        <Select
          value={watch().openAiModels}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Ai System"
          {...register('openAiModels')}
          sx={{
            width: '100%',
          }}
        >
          {imageOpenAiModels.map(opt => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {watch().openAiModels === 'createImage' ? (
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Model</InputLabel>
          <Select
            value={watch().quality}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            {...register('quality')}
            sx={{
              width: '100%',
            }}
          >
            <MenuItem value={'hd'}>HD</MenuItem>
            <MenuItem value={'standard'}>Standard</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Box>
          <FormLabel
            sx={{
              mb: '4px',
              bgcolor: '#F9FAFB',
              color: '#101828',
            }}
            htmlFor="groqStop"
          >
            Number of Variations
          </FormLabel>
          <TextField
            type={'number'}
            fullWidth
            value={watch()?.numbersOfVariations}
            className="nodrag"
            InputProps={{
              style: {
                color: '#101828',
              },
            }}
            sx={{
              color: '#101828',
              bgcolor: '#F9FAFB',
              border: '#EAECF0',
            }}
            {...register('numbersOfVariations')}
          />
        </Box>
      )}
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <InputLabel id="demo-simple-select-label">Size</InputLabel>
        <Select
          value={watch().size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Size"
          {...register('size')}
          sx={{
            width: '100%',
          }}
        >
          <MenuItem value={'1024x1024'}>1024x1024</MenuItem>
          <MenuItem value={'1024x1792'}>1024x1792</MenuItem>
          <MenuItem value={'1792x1024'}>1792x1024</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ImageOpenAiConfigForm;
