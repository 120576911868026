import KitModal from '../../UI/KitModal';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { searchEngines } from '../../nodes/WebSearch';
import { countries } from '../../../util/countries';
import { domains } from '../constants';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant='h6'>Settings</Typography>
        <Box>
          <Box>
            <InputLabel htmlFor={'start_page'}>{'Start Page'}</InputLabel>
            <TextField
              onChange={onChange}
              name={'start_page'}
              value={formData.start_page}
            />
          </Box>

          <Box>
            <InputLabel htmlFor={'pages'}>{'Pages'}</InputLabel>
            <TextField
              onChange={onChange}
              name={'pages'}
              value={formData.pages}
            />
          </Box>

          <Box>
            <InputLabel htmlFor={'domain'}>{'Domain'}</InputLabel>

            <Select
              id='domain'
              name='domain'
              value={formData.domain}
              onChange={onChange}
              className='nodrag'
            >
              {domains.map((domain, index) => (
                <MenuItem key={index} value={domain}>
                  {domain}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
