import axios from 'axios';

interface Doc {
  id: string;
  name: string;
}

interface Drive {
  id: string;
  name: string;
}

const queryDocs = async (
  accessToken: string,
  query: string,
  driveId?: string,
): Promise<Doc[]> => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const params: Record<string, any> = {
    q: query,
    fields: 'files(id, name)',
    includeItemsFromAllDrives: true,
    supportsAllDrives: true,
    orderBy: 'viewedByMeTime desc',
  };

  if (driveId) {
    params.driveId = driveId;
    params.corpora = 'drive';
  }

  try {
    const response = await axios.get(
      'https://www.googleapis.com/drive/v3/files',
      { params, headers },
    );
    return response.data.files;
  } catch (error) {
    throw error;
  }
};

const listSharedDrives = async (accessToken: string): Promise<Drive[]> => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      'https://www.googleapis.com/drive/v3/drives',
      {
        params: {
          fields: 'drives(id, name)',
        },
        headers,
      },
    );
    return response.data.drives;
  } catch (error) {
    throw error;
  }
};

export const getDocs = async (
  accessToken: string,
  title: string = '',
): Promise<Doc[]> => {
  const queryUserDrive = `mimeType='application/vnd.google-apps.document' and name contains '${title}'`;

  try {
    const userDriveDocs = await queryDocs(accessToken, queryUserDrive);

    const sharedDrives = await listSharedDrives(accessToken);

    const sharedDriveDocsPromises = sharedDrives.map(drive =>
      queryDocs(accessToken, queryUserDrive, drive.id),
    );

    const sharedDriveDocsArrays = await Promise.all(sharedDriveDocsPromises);

    const sharedDriveDocs = sharedDriveDocsArrays.flat();

    const allDocs = [...userDriveDocs, ...sharedDriveDocs];

    return allDocs;
  } catch (error) {
    throw error;
  }
};

export const getDocContent = async (
  accessToken: string,
  documentId: string,
  pageIndex: number = 0,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  let nextPageToken: string | null = null;
  let currentPageIndex = 0;
  let fullContent = '';

  try {
    do {
      let requestUrl = `https://docs.googleapis.com/v1/documents/${documentId}?fields=body.content`;

      if (nextPageToken) {
        requestUrl += `&pageToken=${nextPageToken}`;
      }

      const response = await axios.get(requestUrl, {
        headers,
      });

      const content = response.data.body.content;

      if (content && content.length > 0) {
        // eslint-disable-next-line no-loop-func
        content.forEach((element: any) => {
          if (element.paragraph && element.paragraph.elements) {
            element.paragraph.elements.forEach((el: any) => {
              if (el.textRun && el.textRun.content) {
                fullContent += el.textRun.content.replace(/\\n/g, '\n');
              }
            });
          }
        });

        if (currentPageIndex === pageIndex) {
          break;
        }
      }

      nextPageToken = response.data.nextPageToken;
      currentPageIndex++;
    } while (nextPageToken && currentPageIndex <= pageIndex);

    return fullContent.trim();
  } catch (error) {
    throw error;
  }
};

export const getPublicDocContent = async (documentId: string) => {
  try {
    const requestUrl = `https://docs.google.com/document/d/${documentId}/export?format=txt`;

    return await axios.get(requestUrl);
  } catch (error) {
    throw error;
  }
};

export const getDocContentFilteredByParagraphs = async (
  accessToken: string,
  documentId: string,
  paragraphStart: number,
  paragraphEnd: number,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  let nextPageToken: string | null = null;
  let currentParagraphIndex = 0;
  let fullContent = '';

  try {
    do {
      let requestUrl = `https://docs.googleapis.com/v1/documents/${documentId}?fields=body.content`;

      if (nextPageToken) {
        requestUrl += `&pageToken=${nextPageToken}`;
      }

      const response = await axios.get(requestUrl, {
        headers,
      });

      const content = response.data.body.content;

      if (content && content.length > 0) {
        // eslint-disable-next-line no-loop-func
        content.forEach((element: any) => {
          if (element.paragraph && element.paragraph.elements) {
            currentParagraphIndex++;

            if (
              currentParagraphIndex >= paragraphStart &&
              currentParagraphIndex <= paragraphEnd
            ) {
              element.paragraph.elements.forEach((el: any) => {
                if (el.textRun && el.textRun.content) {
                  fullContent += el.textRun.content.replace(/\\n/g, '\n');
                }
              });
            }
          }
        });

        if (currentParagraphIndex > paragraphEnd) {
          break;
        }
      }

      nextPageToken = response.data.nextPageToken;
    } while (nextPageToken);

    return fullContent;
  } catch (error) {
    throw error;
  }
};

export const appendDataToEndOfDoc = async (
  accessToken: string,
  documentId: string,
  text: string,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const requests = [
    {
      insertText: {
        endOfSegmentLocation: {},
        text,
      },
    },
  ];

  const requestBody = {
    requests,
  };

  try {
    const response = await axios.post(
      `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
      requestBody,
      {
        headers,
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const prependDataToBeginningOfDoc = async (
  accessToken: string,
  documentId: string,
  text: string,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const requests = [
    {
      insertText: {
        location: {
          index: 1,
        },
        text,
      },
    },
  ];

  const requestBody = {
    requests,
  };

  try {
    const response = await axios.post(
      `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
      requestBody,
      {
        headers,
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendDataToEndOfDocAsNewPage = async (
  accessToken: string,
  documentId: string,
  text: string,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const requests = [
    {
      insertPageBreak: {
        endOfSegmentLocation: {},
      },
    },
    {
      insertText: {
        endOfSegmentLocation: {},
        text,
      },
    },
  ];

  const requestBody = {
    requests,
  };

  try {
    const response = await axios.post(
      `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
      requestBody,
      {
        headers,
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendDataToBeginningOfDocAsNewPage = async (
  accessToken: string,
  documentId: string,
  text: string,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const requests = [
    {
      insertPageBreak: {
        location: {
          index: 1,
        },
      },
    },
    {
      insertText: {
        location: {
          index: 1,
        },
        text,
      },
    },
  ];
  const requestBody = {
    requests,
  };
  try {
    const response = await axios.post(
      `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
      requestBody,
      {
        headers,
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendDataAsNewPageAfterSpecifiedPage = async (
  accessToken: string,
  documentId: string,
  text: string,
  pageNumber: number,
) => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  const requests = [
    {
      insertPageBreak: {
        location: {
          index: pageNumber + 1,
        },
      },
    },
    {
      insertText: {
        location: {
          index: pageNumber + 2,
        },
        text,
      },
    },
  ];

  const requestBody = {
    requests,
  };

  try {
    const response = await axios.post(
      `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
      requestBody,
      {
        headers,
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createNewDoc = async (
  accessToken: string,
  title: string,
  folderId?: string,
) => {
  const requestBody = {
    title,
  };
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.post(
      'https://docs.googleapis.com/v1/documents',
      requestBody,
      { headers },
    );

    const documentId = response.data.documentId;

    if (folderId) {
      await axios.patch(
        `https://www.googleapis.com/drive/v2/files/${documentId}?addParents=${folderId}`,
        {},
        {
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
        },
      );
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
