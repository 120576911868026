import { Box, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { useAuthStore } from '../../../store/storeAuth';

const PodSelect = ({
  onChangeMemoryPod,
  namespaces,
  selectVector,
}: {
  onChangeMemoryPod: (e: any) => void;
  namespaces: any;
  selectVector: any;
}) => {
  const { user } = useAuthStore(state => state);

  return (
    <>
      {namespaces?.length ? (
        <Select
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
          className="nodrag"
          value={selectVector?.name || 'none'}
          onChange={onChangeMemoryPod}
        >
          <MenuItem
            value={'none'}
            disabled
            sx={{
              color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
            }}
          >
            <em>None</em>
          </MenuItem>

          {namespaces?.map((namespace: any, i: number) => (
            <MenuItem
              value={namespace?.name}
              key={i}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
              }}
            >
              {namespace?.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Box
          sx={{
            p: '24px 20px',
            mb: '24px',
            borderRadius: '4px',
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              color: '#0090F9',
            }}
          >
            Create a memory pod to store and query docs.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default PodSelect;
