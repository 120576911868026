import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { LocalStorageService } from '../service/LocalStorageService';
import { useAuthStore } from '../store/storeAuth';

const PublicRoutes = () => {
  const authUser = useAuthStore((state: any) => state.user);
  const token = LocalStorageService.getItem('accessToken');

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (
      location.state?.from === 'company-accept-invite' &&
      location.state?.token
    ) {
      navigate(`/login${'?token=' + location.state?.token}`, {
        replace: true,
        state: location.state,
      });
      setIsLoading(false);
      return;
    }

    if (authUser && token) {
      if (
        authUser?.registrationMethod !== 'invite' &&
        !authUser?.hasFinishedOnboarding
      ) {
        setIsLoading(false);
        return navigate('/welcome');
      }

      if (
        authUser?.registrationMethod === 'invite' &&
        !authUser?.companyUserHasFinishedOnboarding
      ) {
        setIsLoading(false);
        return navigate('/onboarding');
      }

      navigate('/');
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [token, authUser]);

  if (isLoading) {
    return <div>...loading</div>;
  }

  return <Outlet />;
};

export default PublicRoutes;
