import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { BuildWithApiService } from '../../service/BuildWithApiService';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { ScraperService } from '../../service/ScraperService';
import { modifyTheStringifiedData } from '../../util/modifyTheStringifiedData';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { CaretDown, Faders } from '@phosphor-icons/react';
import SettingsModal from '../WebCrawler/SettingsModal/SettingsModal';
import TagInput from '../Test/TagInput';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { useObserveElementWidth } from '../../util/useObserveElement';

export const targetWebCrawlTypes = {
  webCrawlUrl: 'url',
  webCrawlTechnology: 'technology',
  webCrawlSince: 'since',
  webCrawlCompanyName: 'companyName',
  webCrawlWords: 'words',
};

const builtWithApiTypes = [
  'free',
  'domain',
  'technology',
  'relationship',
  'keywords',
  'trends',
  'companyToUrl',
  'trust',
];
type InputFieldNames =
  | 'system'
  | 'type'
  | 'url'
  | 'since'
  | 'technology'
  | 'companyName'
  | 'words';

type InputField = {
  name: InputFieldNames;
  label: string;
  handleId?: string;
};

const scrapingBeeTypes = [
  {
    label: 'Text',
    value: 'scrapingbee-text',
  },
  {
    label: 'Links',
    value: 'scrapingbee-links',
  },
];

const technologyInputFields: InputField[] = [
  {
    name: 'technology',
    label: 'Technology',
    handleId: 'webCrawlTechnology',
  },
  {
    name: 'since',
    label: 'Since',
    handleId: 'webCrawlSince',
  },
];

const trendsInputFields: InputField[] = [
  {
    name: 'technology',
    label: 'Technology',
  },
];

const companyInputFields: InputField[] = [
  {
    name: 'companyName',
    label: 'Company Name',
  },
];

const trustInputFields: InputField[] = [
  {
    name: 'url',
    label: 'URL',
    handleId: 'webCrawlUrl',
  },
  {
    name: 'words',
    label: 'Words',
    handleId: 'webCrawlWords',
  },
];

function WebCrawling({ data, isConnectable, type, id }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();

  const {
    setSchema,
    flowId,
    setSaving,
    setSocketNodeResponse,
    socketNodeResponse,
  } = useFlowsStore(state => state);

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [formData, setFormData] = useState<{
    system: string;
    type: string;
    url: string;
    since: string;
    technology: string;
    companyName: string;
    words: string;
    text: string;
    scrapingBeeType: string;
  }>({
    system: '',
    type: '',
    url: '',
    since: '',
    technology: '',
    companyName: '',
    words: '',
    text: '',
    scrapingBeeType: 'scrapingbee-text',
  });

  const [hasToExecute, setHasToExecute] = useState(true);
  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useEffect(() => {
    if (data && !isChangingDirectly) {
      setFormData({
        system: data.system ?? 'scrapingBee',
        type: data.type ?? 'free',
        url: data.url ?? '',
        since: data.since ?? '',
        technology: data.technology ?? '',
        companyName: data.companyName ?? '',
        words: data.words ?? '',
        text: data.text ?? '',
        scrapingBeeType: data.scrapingBeeType ?? 'scrapingbee-text',
      });
    }
  }, [data, isChangingDirectly]);

  const onSubmit = async () => {
    let newFormData: any = { ...formData };

    const types = ['url', 'technology', 'since', 'companyName', 'words'];

    types.forEach(t => {
      if (data[t + 'Data']?.length) {
        newFormData[t] = data[t + 'Data']
          ?.map((item: any) => item.text)
          .join(' \n');
      }
    });

    try {
      const processText = (inputText: string) => {
        // Regular expression to match URLs

        // replcale any /n with space or any extra spaces with single space

        const text = inputText.replace(/\n/g, ' ').replace(/\s+/g, ' ').trim();

        var urlRegex = /(https?:\/\/[^\s]+)/g;

        // Check if the input text contains a URL
        if (urlRegex?.test?.(text)) {
          // If a URL is found, remove extra spaces
          var processedText = inputText?.replace(/\s+/g, ' ').trim();
          return processedText;
        } else {
          // If no URL is found, return the original text
          return inputText;
        }
      };

      const url = processText(newFormData?.url);
      setIsLoading(true);
      let response: any = null;
      if (formData.system === 'builtWithApi') {
        if (formData.type === 'free') {
          const res = await BuildWithApiService.free({
            url: url,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'domain') {
          const res = await BuildWithApiService.domain({
            url: url,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'keywords') {
          const res = await BuildWithApiService.keywords({
            url: url,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'relationship') {
          const res = await BuildWithApiService.relationship({
            url: url,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'technology') {
          const res = await BuildWithApiService.technology({
            technology: newFormData.technology,
            since: newFormData.since,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'trends') {
          const res = await BuildWithApiService.trends({
            technology: newFormData.technology,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'companyToUrl') {
          const res = await BuildWithApiService.companyToUrl({
            companyName: newFormData.companyName,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }

        if (formData.type === 'trust') {
          const res = await BuildWithApiService.trust({
            url: url,
            words: newFormData.url,
            live: true,
            responseFormat: 'json',
            flowId,
          });
          const stringifiedJson = JSON.stringify(res.data);
          response = {
            message: stringifiedJson,
            usedTokens: res.data.usedTokens,
          };
        }
      }

      if (formData.system === 'scrapingBee') {
        const res = await ScraperService.scraper(formData.scrapingBeeType, {
          url: url,
          flowId,
        });

        const isScrapingBeeLinks = formData.scrapingBeeType === 'scrapingbee-links';
        const input = isScrapingBeeLinks ? res.data.all_links ?? "No results found" : res.data.text;
        const processedMessage = isScrapingBeeLinks
          ? modifyTheStringifiedData(input)
          : input.replace(/(<([^>]+)>)/gi, ' ').replace(/\s+/g, ' ').trim();

        response = {
          message: processedMessage,
          usedTokens: res.data.usedTokens,
        };
      }

      if (formData.system === 'oxylab') {
        const res = await ScraperService.scraper('oxylab', {
          url: url,
          flowId,
        });

        response = {
          message: modifyTheStringifiedData(JSON.stringify(res.data)),
          usedTokens: res.data.usedTokens,
        };
      }

      if (formData.system === 'brightData') {
        const response = await ScraperService.scraper('bright-data', {
          url: url,
          flowId,
        });

        setSocketNodeResponse(id, null);
        return;
      }

      setFormData(prevState => ({
        ...prevState,
        text: response.message,
      }));

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          text: response.message ?? '',
          usedTokens: data?.flowTrigger?.id ? response.usedTokens : undefined,
          nodeUsedTokens: response.usedTokens,
        },
        setSchema,
      });

      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 1000);
    } catch (err) {
      let error = err as any;
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );

      if (error?.message?.includes('be parsed: Document')) {
        return setNotification({
          type: 'error',
          message: 'The site cannot be scraped. Please try another site.',
        });
      }

      setNotification({
        type: 'error',
        message: `Unable to connect with ${
          formData.system === 'builtWithApi'
            ? 'BuiltWithApi'
            : formData.system === 'scrapingBee'
            ? 'ScrapingBee'
            : 'OxyLab'
        } at this moment`,
      });

      setIsLoading(false);
    } finally {
      if (formData.system !== 'brightData') {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      setIsLoading(false);
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          text: data.text,
        },
        setSchema,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 1500);
      setSocketNodeResponse(null, null);
    }
  }, [socketNodeResponse?.nodeId, socketNodeResponse?.finished, data.text]);

  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));

    setWhoIsChanging({
      name: evt.target.name,
      value: evt.target.value,
    });
    setIsChangingDirectly(true);
  }, []);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );
  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  // console.log('data@WebCrawling:', data);

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        edges={edges}
        type={type}
        btnText="Run Prompt"
        onSubmit={onSubmit}
        isLoading={isLoading}
        handleRun={handleRun}
        data={data}
        id={id}
      >
        <Box display={'flex'}>
          <div>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
              ref={inputRef}
            >
              {(formData.system === 'oxylab' ||
                formData.system === 'scrapingBee') && (
                <Box>
                  <TagInput
                    onChange={onChange}
                    name={'url'}
                    labelName={'URL'}
                    isAdvanced
                    dataConnected={data?.urlData}
                    handleId={'webCrawlUrl'}
                    isCollapsed
                    isActive={isActiveEdge(edges, id, 'webCrawlUrl', 'target')}
                    findColor={findColor}
                    advancedCollapsed
                    nodeId={id}
                    removeIcons
                    value={formData.url}
                    nodeLabel={data?.label}
                    placeholder={'https://fluxprompt.ai'}
                  />
                </Box>
              )}
              {formData.system === 'scrapingBee' && (
                <Box>
                  {scrapingBeeTypes.map(({ value, label }) => (
                    <Chip
                      key={value}
                      sx={{
                        borderRadius: '8px',
                        fontSize: '12px',
                        color:
                          formData.scrapingBeeType === value
                            ? '#101828'
                            : user?.theme === 'dark'
                            ? '#F2F4F7'
                            : '#101828',
                        p: '4px 4px',
                        height: 'unset',
                        background:
                          formData.scrapingBeeType === value
                            ? '#FECDD2'
                            : user?.theme === 'dark'
                            ? '#101828'
                            : '#F2F4F7',
                        '&:hover': {
                          background: '#FECDD2',
                          color: '#101828',
                        },
                      }}
                      onClick={() => {
                        onChange({
                          target: {
                            value: value,
                            name: 'scrapingBeeType',
                          },
                        });
                      }}
                      label={label}
                    />
                  ))}
                </Box>
              )}

              {formData.system === 'builtWithApi' && (
                <>
                  <Box>
                    {(formData.type === 'free' ||
                      formData.type === 'domain' ||
                      formData.type === 'relationship' ||
                      formData.type === 'keywords') && (
                      <Box>
                        <TagInput
                          onChange={onChange}
                          name={'url'}
                          labelName={'URL'}
                          isAdvanced
                          nodeId={id}
                          isActive={isActiveEdge(
                            edges,
                            id,
                            'webCrawlUrl',
                            'target',
                          )}
                          findColor={findColor}
                          handleId={'webCrawlUrl'}
                          dataConnected={data?.urlData}
                          isCollapsed
                          advancedCollapsed
                          removeIcons
                          value={formData.url}
                          nodeLabel={data?.label}
                        />
                      </Box>
                    )}
                    {formData.type === 'technology' && (
                      <Box>
                        {technologyInputFields.map((input, i) => (
                          <Box key={i}>
                            <TagInput
                              onChange={onChange}
                              handleId={input.handleId}
                              isAdvanced
                              labelName={input.label}
                              isCollapsed
                              isActive={isActiveEdge(
                                edges,
                                id,
                                input.handleId,
                                'target',
                              )}
                              findColor={findColor}
                              dataConnected={data?.[input.name + 'Data'] ?? []}
                              removeIcons
                              advancedCollapsed
                              nodeId={id}
                              name={input.name}
                              value={formData[input.name]}
                              nodeLabel={data?.label}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                    {formData.type === 'trends' && (
                      <Box>
                        {trendsInputFields.map((input, i) => (
                          <Box key={i}>
                            <TagInput
                              handleId="webCrawlTechnology"
                              labelName={input.label}
                              onChange={onChange}
                              id={input.name}
                              name={input.name}
                              isAdvanced
                              removeIcons
                              isCollapsed
                              isActive={isActiveEdge(
                                edges,
                                id,
                                input.handleId,
                                'target',
                              )}
                              findColor={findColor}
                              nodeId={id}
                              dataConnected={data?.[input.name + 'Data'] ?? []}
                              advancedCollapsed
                              value={formData[input.name]}
                              nodeLabel={data?.label}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                    {formData.type === 'companyToUrl' && (
                      <Box>
                        {companyInputFields.map((input, i) => (
                          <Box key={i}>
                            <TagInput
                              onChange={onChange}
                              id={input.name}
                              handleId="webCrawlCompanyName"
                              labelName={input.label}
                              isAdvanced
                              isCollapsed
                              isActive={isActiveEdge(
                                edges,
                                id,
                                'webCrawlCompanyName',
                                'target',
                              )}
                              findColor={findColor}
                              dataConnected={data?.[input.name + 'Data'] ?? []}
                              advancedCollapsed
                              removeIcons
                              nodeId={id}
                              name={input.name}
                              value={formData[input.name]}
                              nodeLabel={data?.label}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                    {formData.type === 'trust' && (
                      <Box>
                        {trustInputFields.map((input, i) => (
                          <Box key={i}>
                            <TagInput
                              onChange={onChange}
                              handleId={input.handleId}
                              isAdvanced
                              labelName={input.label}
                              isCollapsed
                              nodeId={id}
                              removeIcons
                              isActive={isActiveEdge(
                                edges,
                                id,
                                input.handleId,
                                'target',
                              )}
                              findColor={findColor}
                              dataConnected={data?.[input.name + 'Data'] ?? []}
                              advancedCollapsed
                              name={input.name}
                              value={formData[input.name]}
                              nodeLabel={data?.label}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '360px',
                      flexWrap: 'wrap',
                      gap: '8px',
                      mb: '24px',
                      mt: '16px',
                    }}
                  >
                    {builtWithApiTypes.map(value => (
                      <Chip
                        key={value}
                        sx={{
                          borderRadius: '8px',
                          fontSize: '12px',
                          color: '#101828',
                          p: '4px 4px',

                          height: 'unset',
                          background:
                            formData.type === value ? '#FECDD2' : '#F2F4F7',
                          '&:hover': {
                            background: '#FECDD2',
                          },
                        }}
                        onClick={() => {
                          onChange({
                            target: {
                              value: value,
                              name: 'type',
                            },
                          });
                        }}
                        label={value}
                      />
                    ))}
                  </Box>
                </>
              )}
              <Divider
                sx={{
                  mt: '80px',
                  mb: '10px',
                  width: '360px',
                }}
              />
              {data?.placement !== 'Output' && (
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                    borderRadius={'4px'}
                    sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                    onClick={() => setSettingsModalOpen(true)}
                  >
                    <Faders size={18} color="#667085" />
                    <Typography fontSize={'12px'} color="#667085" variant="h6">
                      {formData.system}
                    </Typography>

                    <CaretDown size={'16px'} color="#667085" />
                  </Box>
                </Box>
              )}
              <SettingsModal
                onHide={() => setSettingsModalOpen(false)}
                show={settingsModalOpen}
                formData={formData}
                onChange={onChange}
              />
            </Box>
          </div>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              // previewResponses={previewResponses}
              maxOutputHeight={maxOutputHeight}
              previewIndex={data.previewIndex}
              value={data.text}
              activeHandle={isActiveEdge(edges, id, 'webCrawlSource', 'source')}
              placement={data?.placement}
              labelName={'Output'}
              handleId={'webCrawlSource'}
              // onPreview={onPreview}
              findColor={findColor}
              nodeLabel={data?.label}
              type={type}
              // onChangePlacement={onChangePlacement}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
}

export default memo(WebCrawling);
