import { useEffect, useState } from 'react';
import { AuthService } from '../../service/AuthService';
import { useAuthStore } from '../../store/storeAuth';
import { LocalStorageService } from '../../service/LocalStorageService';
import { useNotificationStore } from '../../store/storeNotifications';
import { UserConfigService } from '../../service/UserConfigService';
import useFlowsStore from '../../store/storeFlows';
import { useStoreTabs } from '../../store/storeTabs';
import Tap from '@tapfiliate/tapfiliate-js';
import { UserService } from '../../service/UserService';
import { useStoreGoogleData } from '../../store/storeGoogleData';
import { useStoreHubSpotData } from '../../store/storeHubSpotData';
import { HubSpotService } from '../../service/HubSpotService';

type Props = {
  setIsLoading: (value: boolean) => void;
};
const Loader = ({ setIsLoading }: Props) => {
  const { setNotification } = useNotificationStore(state => state);
  const { setFlows } = useFlowsStore(state => state);
  const {
    setAuthUser,
    setConfigs,
    accessToken: authToken,
  } = useAuthStore(state => state);
  const { setTabs } = useStoreTabs(state => state);
  const { setGoogleRefreshToken, setGoogleAccessToken } = useStoreGoogleData();
  const { setIsHubSpotConnected } = useStoreHubSpotData();

  const accessToken = LocalStorageService.getItem('accessToken');
  const localGoogleAccessToken = LocalStorageService.getItem(
    'googleSheetsAccessToken',
  );
  const localGoogleRefreshToken =
    LocalStorageService.getItem('googleRefreshToken');

  const [initialStart, setInitialStart] = useState(true);

  useEffect(() => {
    if (authToken) {
      setInitialStart(true);
    }
  }, [authToken]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);
        LocalStorageService.removeItem('googleRefreshToken');
        LocalStorageService.removeItem('googleSheetsAccessToken');
        const [userResponse, configResponse, flowsResponse] = await Promise.all(
          [
            AuthService.getMe(),
            UserConfigService.getConfigs(),
            UserService.getUserFlows(),
          ],
        );

        setAuthUser(userResponse.data);
        setConfigs(configResponse.data);
        setTabs(userResponse.data?.tabs ?? []);
        if (userResponse.data.googleRefreshToken) {
          const newTokens = await AuthService.googleRefreshToken({
            refresh_token: userResponse.data.googleRefreshToken,
          });

          setGoogleAccessToken(newTokens.data.access_token);
          setGoogleRefreshToken(newTokens.data.refresh_token);
        }

        setFlows(flowsResponse.data);
        Tap.trial(userResponse.data.id);

        if (userResponse.data.hubspotRefreshToken) {
          const tryRefreshHubSpotToken = async () => {
            try {
              await HubSpotService.refreshHubSpotToken();
              setIsHubSpotConnected(true);
            } catch {
              setIsHubSpotConnected(false);
            }
          };

          await tryRefreshHubSpotToken();
        }
      } catch (error) {
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
      }
    };

    if ((accessToken || authToken) && initialStart) {
      fetchUser();
      setInitialStart(false);
    }
  }, [
    accessToken,
    authToken,
    initialStart,
    localGoogleAccessToken,
    localGoogleRefreshToken,
  ]);

  return <></>;
};

export default Loader;
