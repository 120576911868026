import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class FileService extends ApiService {
  static pathName = '/file';

  static createFormFIle = (body: {
    name: string;
    src: string;
    formsId?: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}`, body);
  };

  static createFormFileToUpload = (body: {
    name: string;
    src: string;
    formsId?: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/forms`, body);
  };
}
