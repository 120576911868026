import React from 'react';
import './UserPlan.css';
import IntroductionPlanImage from '../../assets/images/IntroductionPlan.png';
import SubscriptionPlanImage from '../../assets/images/SubscriptionPlan.png';
import { Button } from '@mui/material';
import { Screen, SelectedPlan } from './Welcome';

interface Props {
  setScreen: (screen: Screen) => void;
  setSelectedPlan: (plan: SelectedPlan) => void;
}

const UserPlan: React.FC<Props> = ({ setScreen, setSelectedPlan }) => {
  return (
    <div>
      <p className={'question-text'}>How do you want to get started?</p>

      <div className={'plans-container'}>
        <div className={'introduction-plan-box-container'}>
          <h3 className={'user-plan-text'}>Introduction Plan</h3>
          <p className={'user-plan-description'}>
            Dip your toes into AI automation. We'll gift you 5,000 FluxTokens
            (limited time only) to explore our platform and experience the power
            of AI workflows.
          </p>

          <img
            src={IntroductionPlanImage}
            alt="Introduction Plan"
            className={'plan-image'}
          />

          <Button
            variant="outlined"
            onClick={() => {
              setSelectedPlan(SelectedPlan.Introduction);
              setScreen(Screen.SelectedPlan);
            }}
          >
            Start Introduction Plan
          </Button>
        </div>

        <div className={'subscription-plan-box-container'}>
          <h3 className={'subscription-plan-text'}>Subscription</h3>
          <p className={'subscription-description'}>
            Hit the ground running with AI. Sign up and we'll award you 10,000
            FluxTokens (limited time only) to fuel your productivity from day
            one.
          </p>

          <img
            src={SubscriptionPlanImage}
            alt="Subscription Plan"
            className={'plan-image'}
          />

          <Button
            variant="outlined"
            style={{ borderColor: 'white', color: 'white' }}
            onClick={() => {
              setSelectedPlan(SelectedPlan.Subscription);
              setScreen(Screen.SelectedPlan);
            }}
          >
            Unleash Ai Power
          </Button>
        </div>
      </div>

      <div className={'go-back-div-container'}>
        <Button onClick={() => setScreen(Screen.Questions)}>Go Back</Button>
      </div>
    </div>
  );
};

export default UserPlan;
