import { memo, useEffect, useRef, useState } from 'react';
import { NodeProps, Position, useEdges, useReactFlow } from 'reactflow';

import { VectorService } from '../../service/VectorService';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { useAuthStore } from '../../store/storeAuth';
import VectorMemoryUploadModal from '../LargeMemory/UploadModal/VectorMemoryUploadModal';
import { AuthService } from '../../service/AuthService';
import { getModelMaxTokens } from '../../util/modelMaxTokens';
import LinesReferenceModal, {
  LinesReference,
} from '../LargeMemory/LinesReferenceModal';
import { Box, Button, Divider, Typography } from '@mui/material';
import { CaretDown, Faders, Pen, PlusCircle } from '@phosphor-icons/react';
import TagInput from '../Test/TagInput';
import useFlowsStore from '../../store/storeFlows';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import Node from '../UI/Node/Node';
import InputHandle from '../UI/InputHandle/InputHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import PodSelect from '../LargeMemory/PodSelect/PodSelect';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import SettingsModal from '../LargeMemory/SettingsModal/SettingsModal';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useSocketStore } from '../../store/storeSocket';
import { arrayDataToText } from '../../util/dataToText';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { CompanySpacesService } from '../../service/CompanySpacesService';
const indexName = 'humanloop-demo';

const LargeMemory = ({ id, data, isConnectable, type }: NodeProps) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const { setAuthUser, user } = useAuthStore(state => state);
  const { socket } = useSocketStore(state => state);

  const [values, setValues] = useState<{
    query: string;
    searchResult: string;
    model: string;
    systemAi: string;
    topK: number;
    linesReferences: LinesReference[] | null;
    searchType: string;
    returnRaw: boolean;
  }>({
    query: '',
    searchResult: '',
    model: 'gpt-4',
    systemAi: 'OpenAI',
    topK: 10,
    linesReferences: null,
    searchType: 'similarity',
    returnRaw: false,
  });

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const { setNodes: setNodeState } = useReactFlow();

  const { setSaving, setSchema, flowId, spaceId } = useFlowsStore(
    state => state,
  );
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [nodeLoading, setNodeLoading] = useState(false);
  const edges = useEdges();
  const [collapsed, setCollapsed] = useState(true);
  const [namespaces, setNamespaces] = useState(user?.namespaces);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [selectVector, setSelectVector] = useState<any>(user?.namespaces?.[0]);
  const [loaders, setLoaders] = useState({
    search: false,
    upload: false,
  });
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [openVectorUploadModal, setOpenVectorUploadModal] = useState({
    open: false,
    type: '',
  });
  const [openLinesReferenceModal, setLinesReferenceModal] = useState(false);

  const onUploadDocument = async ({
    vectorName,
    files,
    type,
    file,
  }: {
    vectorName: string;
    files?: File[] | string[];
    type: string;
    file?: File | string;
  }) => {
    setNodeLoading(true);

    try {
      if (type === 'upload-from-computer') {
        if (Array.isArray(files) && files.length > 0) {
          const formData = new FormData();

          files.forEach(file => {
            formData.append('file', file as string | Blob);
          });

          await VectorService.addFile(
            {
              indexName,
              nameSpace: vectorName,
              spaceId: spaceId,
            },
            formData,
          );
        }
      }

      if (type === 'select-from-documents') {
        await VectorService.addFile({
          indexName,
          nameSpace: vectorName,
          spaceId,

          url: file as string,
        });
      }

      if (type === 'select-from-fluxprompt-object') {
        const formData = new FormData();
        formData.append('isFromPrevNode', 'true');
        formData.append('prevNodeTextGenerated', file as string);

        await VectorService.addFile(
          {
            indexName,
            nameSpace: vectorName,
            spaceId,
          },
          formData,
        );
      }
    } catch (error: any) {
      setNodeLoading(false);

      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
    } finally {
    }
  };

  const [hasToExecute, setHasToExecute] = useState(true);

  const searchFile = async () => {
    setLoaders({
      ...loaders,
      search: true,
    });

    setNodeLoading(true);

    try {
      if (!selectVector) {
        throw new Error('Please select a vector');
      }

      let prompt = values.query;

      if (data?.queryData?.length > 0) {
        prompt = arrayDataToText(data?.queryData);
      }

      const result = await VectorService.search(
        {
          prompt,
          model: values.model,
          topK: +values.topK,
          maxTokenModel: getModelMaxTokens(values.model, true),
          isNERSearch: values.searchType === 'similarity-ner',
        },
        {
          indexName,
          nameSpace: selectVector.name,
        },
      );

      const resultText = values?.returnRaw
        ? result?.data?.linesWhereQuestionWasFound
            ?.map((line: any) => `${line.lines} \n ${line.pageContent}`)
            ?.join('\n ')
        : result.data.text;

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          text: {
            result: resultText,
            linesWhereQuestionWasFound: result.data.linesWhereQuestionWasFound,
            text: result?.data?.text,
          },
        },
        setSchema,
      });

      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 1500);
      // setSocketNodeResponse(id, null);
    } catch (error: any) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
      setNodeLoading(false);
    } finally {
      setNodeLoading(false);
    }
  };

  const getSpace = async (spaceId: string, savedNamespace: any) => {
    try {
      const res = await CompanySpacesService.getSpaceById(spaceId);
      setNamespaces(res.data?.namespaces ?? []);

      const defaultNameSpace = res.data?.namespaces?.find(
        (nameSpace: { name: string }) => nameSpace?.name === savedNamespace,
      );
      setSelectVector(defaultNameSpace || res.data?.namespaces?.[0]);
      if (!defaultNameSpace) {
        setValues({ ...values, query: '' });
        setIsChangingDirectly(true);
        setWhoIsChanging({
          name: 'query',
          value: '',
        });

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],

          flowTriggerData: checkExecuteTrigger(data, id),
          flowId,
          objectData: {
            text: {
              result: null,
              linesWhereQuestionWasFound: null,
              text: null,
            },
          },
          setSchema,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (spaceId) {
      getSpace(spaceId, data?.nameSpace?.name);
    }
  }, [spaceId, data?.nameSpace]);

  useEffect(() => {
    if (user?.id && socket?.connected) {
      socket.on('response-vector', async (resData: any) => {
        if (spaceId) {
          getSpace(spaceId, resData?.nameSpace);

          setNotification({
            type: 'success',
            message: 'Vector created successfully',
          });

          setNodeLoading(false);
          return;
        }

        const userData = await AuthService.getMe();

        setAuthUser(userData.data);
        const newNamespaces = userData.data.namespaces;
        setNamespaces(newNamespaces);
        setNotification({
          type: 'success',
          message: 'Vector created successfully',
        });

        setNodeLoading(false);
      });
    }

    return () => {
      if (socket?.connected) {
        socket?.off('response-vector');
      }
    };
  }, [socket, user?.id, spaceId, namespaces]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          addFileFromData().then(() => {
            searchFile();
          });
          setHasToExecute(false);
        }, 1500);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, values, selectVector, data?.canceled]);

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setValues({
          ...values,
          searchResult: data.text ? data.text.result : '',
          query: data?.query ?? '',
          linesReferences: data?.text?.linesWhereQuestionWasFound ?? [],
          model: data?.model ?? 'gpt-4',
          topK: data?.topK ?? 10,
          systemAi: data?.systemAi ?? 'OpenAI',
          searchType: data?.searchType ?? 'similarity',
          returnRaw: data?.returnRaw ?? false,
        });

        setSelectVector(
          namespaces?.find(
            (nameSpace: { name: string }) =>
              nameSpace.name === data?.nameSpace?.name,
          ),
        );
      }
    },
    [data, isChangingDirectly, namespaces],
    300,
  );

  const addFileFromData = async () => {
    setNodeLoading(true);
    try {
      if (
        edges?.find(
          edge =>
            edge?.target === id && edge?.targetHandle === 'textInputDelete',
        )
      ) {
        onDeleteUploadedDocument(data?.textInputDeleteData?.[0]?.text);
        setNotification({
          type: 'success',
          message: 'File deleted successfully',
        });
      }

      if (
        edges?.find(
          edge => edge?.target === id && edge?.targetHandle === 'textInput',
        )
      ) {
        const formData = new FormData();
        formData.append('isFromPrevNode', 'true');
        formData.append(
          'prevNodeTextGenerated',
          data?.textInputData?.[0]?.text,
        );

        await VectorService.addFile(
          {
            indexName,
            nameSpace: selectVector.name,
            spaceId,
          },
          formData,
        );

        if (spaceId) {
          getSpace(spaceId, selectVector);
        } else {
          const res = await AuthService.getMe();

          setAuthUser(res.data);
        }

        setNotification({
          type: 'success',
          message: 'File uploaded successfully',
        });
      }
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    } finally {
      setNodeLoading(false);
    }
  };

  const onChange = (e: any) => {
    const name = e.target.name;

    setValues({
      ...values,
      [name]: e.target.value,
    });

    setIsChangingDirectly(true);
    setWhoIsChanging({
      name: name,
      value: e.target.value,
    });
  };

  const onDeleteUploadedDocument = async (fileName: string) => {
    const result = window.confirm('Are you sure you want to delete this file?');

    if (!result) return;
    try {
      await VectorService.deleteFile({
        nameSpace: selectVector.name,
        fileName,
        spaceId,
      });

      if (spaceId) {
        getSpace(spaceId, selectVector);
      } else {
        setTimeout(async () => {
          const res2 = await AuthService.getMe();

          setAuthUser(res2.data);

          setNamespaces(res2.data.namespaces);

          setSelectVector(
            res2?.data?.namespaces?.find(
              (nameSpace: { name: string }) =>
                nameSpace.name === selectVector.name,
            ),
          );
        }, 1000);
      }

      setNotification({
        type: 'success',
        message: 'File deleted successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    }
  };

  const onDeleteNameSpace = async (nameSpace: string) => {
    const result = window.confirm(
      'Are you sure you want to delete this vector?',
    );

    if (!result) return;
    try {
      const res = await VectorService.deleteNameSpace({
        nameSpace,
        indexName,
        spaceId,
      });

      if (spaceId) {
        getSpace(spaceId, null);
      } else {
        const res2 = await AuthService.getMe();

        setAuthUser(res2.data);

        setOpenVectorUploadModal({
          open: false,
          type: '',
        });

        setSelectVector(null);
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          nameSpace: null,
        },
        setSchema,
      });

      setNotification({
        type: 'success',
        message: 'File deleted successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    }
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        let newObject = {};
        if (whoIsChanging?.name === 'returnRaw') {
          //linesReferences

          const linesReferences =
            values.linesReferences
              ?.map((line: any) => `${line.lines} \n ${line.pageContent}`)
              ?.join('\n ') ?? '';

          if (whoIsChanging) {
            newObject = {
              text: {
                linesWhereQuestionWasFound: values.linesReferences,
                result: linesReferences,
              },
            };
          }
        }

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
            ...newObject,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const onChangeMemoryPod = (e: any) => {
    const selectedVector = namespaces?.find(
      nameSpace => nameSpace?.name === e?.target?.value,
    );
    setSelectVector(selectedVector);

    setIsChangingDirectly(true);

    setWhoIsChanging({
      name: 'nameSpace',
      value: selectedVector,
    });
  };

  const onChangeOnExecute = (e: any) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        clearOnExecution: e.target.checked,
      },
      setSchema,
    });
  };
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  return (
    <Box className={nodeLoading ? 'node' : ''}>
      <Node
        type={type}
        id={id}
        data={data}
        btnText="Search in memory pod"
        handleRun={handleRun}
        isLoading={nodeLoading}
        edges={edges}
        onSubmit={searchFile}
      >
        <Box display={'flex'}>
          <Box p={'16px'} borderRight={'1px solid #EAECF0'}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1,
                position: 'relative',
                '& svg': { cursor: 'pointer' },
                mb: '8px',
              }}
            >
              <InputHandle
                activeColor={findColor?.color}
                handleId={'textInput'}
                // isActive={
                //   edges?.find(
                //     (edge: any) =>
                //       edge?.source === id && edge?.sourceHandle === 'textInput'
                //   )?.source
                //     ? true
                //     : false
                // }

                left={-26}
                top={'50%'}
                position={Position.Left}
                type="target"
              />

              <InputHandle
                activeColor={'#f44336'}
                handleId={'textInputDelete'}
                backgroundColorDefault={'#f44336'}
                // isActive={
                //   edges?.find(
                //     (edge: any) =>
                //       edge?.source === id && edge?.sourceHandle === 'textInput'
                //   )?.source
                //     ? true
                //     : false
                // }
                isActive={true}
                left={-26}
                top={'190%'}
                position={Position.Left}
                type="target"
              />

              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <CaretDown
                  onClick={() => setCollapsed(!collapsed)}
                  size={16}
                  color={!collapsed ? '#3650DE' : '#667085'}
                />
                <Typography
                  fontSize={'16px'}
                  color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                  fontWeight={600}
                >
                  {'Memory Pod'}
                </Typography>
              </Box>
              <Box>
                <IconButtonTooltip
                  onClick={() =>
                    setOpenVectorUploadModal({
                      open: true,
                      type: 'add',
                    })
                  }
                  title={'Create new Memory Pod'}
                >
                  <PlusCircle fontSize={15} color="grey" cursor={'pointer'} />
                </IconButtonTooltip>

                <IconButtonTooltip
                  onClick={() =>
                    setOpenVectorUploadModal({
                      open: true,
                      type: 'edit',
                    })
                  }
                  title={'Edit active Memory Pod'}
                >
                  <Pen fontSize={15} color="grey" cursor={'pointer'} />
                </IconButtonTooltip>
              </Box>
            </Box>
            {collapsed && (
              <Box
                sx={{
                  marginX: '4px',
                  marginY: '12px',
                }}
              >
                <PodSelect
                  onChangeMemoryPod={onChangeMemoryPod}
                  namespaces={namespaces}
                  selectVector={selectVector}
                />
              </Box>
            )}
            <TagInput
              value={values.query}
              name="query"
              handleId="textQuery"
              dataConnected={data?.queryData}
              isCollapsed={true}
              findColor={findColor}
              isActive={isActiveEdge(edges, id, 'textQuery', 'target')}
              nodeId={id}
              labelName="Prompt"
              placeholder="Type something to search in vector database"
              onChange={(e: any) => onChange(e)}
              nodeLabel={data?.label}
            />

            <Divider
              sx={{
                mt: '24px',
                mb: '10px',
              }}
            />
            {data?.placement !== 'Output' && (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box
                  bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                  borderRadius={'4px'}
                  sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                  onClick={() => setSettingsModalOpen(true)}
                >
                  <Faders size={18} color="#667085" />
                  <Typography fontSize={'12px'} color="#667085" variant="h6">
                    Open AI: {values.model}
                  </Typography>

                  <CaretDown size={'16px'} color="#667085" />
                </Box>
              </Box>
            )}
            <SettingsModal
              onHide={() => setSettingsModalOpen(false)}
              show={settingsModalOpen}
              formData={values}
              onChange={onChange}
            />
          </Box>
          <Box p={'16px 24px 16px 16px'} height={'100%'}>
            <OutputTextarea
              // previewResponses={previewResponses}
              maxOutputHeight={500}
              previewIndex={data.previewIndex}
              value={data?.text?.result}
              activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              placement={data?.placement}
              labelName={'Output'}
              // onPreview={onPreview}
              onChangeOnExecute={onChangeOnExecute}
              hasClearOnExecute={true}
              clearOnExecution={data.clearOnExecution || false}
              findColor={findColor}
              nodeLabel={data?.label}
              // onChangePlacement={onChangePlacement}
              style={{
                flex: 1,
              }}
              textareaHeight={'100%'}
            />

            <Box>
              {(values?.linesReferences?.length ?? 0) > 0 && (
                <Button
                  onClick={() => setLinesReferenceModal(true)}
                  variant="text"
                  sx={{ mt: '16px' }}
                >
                  Show references {values?.linesReferences?.length}
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <VectorMemoryUploadModal
          openVectorUploadModal={openVectorUploadModal}
          setOpenVectorUploadModal={setOpenVectorUploadModal}
          onUploadDocument={onUploadDocument}
          onDeleteUploadedDocument={onDeleteUploadedDocument}
          selectVector={selectVector}
          onDeleteNameSpace={onDeleteNameSpace}
        />
        <LinesReferenceModal
          openLinesReferenceModal={openLinesReferenceModal}
          setLinesReferenceModal={setLinesReferenceModal}
          linesReferences={values.linesReferences}
        />
      </Node>
    </Box>
  );
};

export default memo(LargeMemory);
