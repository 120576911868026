import { Box, Button, Collapse, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as InactiveStatus } from '../../assets/icons/InactiveStatus.svg';

import { Notebook, Warning } from '@phosphor-icons/react';
import { Outlet, useNavigate } from 'react-router-dom';
import FluxpromptLogoWhite from '../../assets/icons/fluxprompt-logo-white.svg';
import FluxpromptLogo from '../../assets/icons/fluxprompt-logo.svg';
import { ReactComponent as RobotWarningIcon } from '../../assets/icons/robot2.svg';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { AuthService } from '../../service/AuthService';
import { CompanyService } from '../../service/CompanyService';
import { DocumentService } from '../../service/DocumentService';
import { FlowService } from '../../service/FlowService';
import { FolderService } from '../../service/FolderService';
import { LocalStorageService } from '../../service/LocalStorageService';
import { UserService } from '../../service/UserService';
import { useAuthStore } from '../../store/storeAuth';
import useFlowsStore from '../../store/storeFlows';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { useStoreTabs } from '../../store/storeTabs';
import { getNextPlan } from '../../util/planUtil';
import ChatBot from '../ChatBot/ChatBot';
import FlowFormModal from '../CoreNodeDIsplay/FlowFormModal';
import ModalComponent from '../Modals';
import TemplateSystemModal from '../TemplateSystemModal/TemplateSystemModal';
import TransferUserModal from '../TransferDataModal/TransferDataModal';
import LoadingSpinner from '../UI/LoadingSpinner';
import PaywallModal from '../UI/PaywallModal';
import FluxObjects from './LayoutComponents/FluxObjects/FluxObjects';
import Menu from './LayoutComponents/Menu';
import PrivateFlows from './LayoutComponents/PrivateFlows/PrivateFlows';
import Spaces from './LayoutComponents/Spaces/Spaces';
import Uploads from './LayoutComponents/Uokiads/Uploads';
import Vector from './LayoutComponents/Vector/Vector';
import Navbar from './Navbar';
import PreviewModal from '../UI/PreviewModal/PreviewModal';

const Layout = () => {
  const {
    user: authUser,
    setAuthUser,
    folders,
    setAccessToken,
    setDocuments,
    setFolders,
    setTechnologies,
    testPreviewModal,
    setTestPreviewModal,
    sidebarOpen,
    setSidebarOpen,
  } = useAuthStore(state => state);

  const [checked, setChecked] = useState(
    authUser?.theme === 'dark' ? true : false,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [collapsePrivate, setCollapsePrivate] = useState(true);
  const { removeNotification, setNotification } = useNotificationStore(
    state => state,
  );

  const { addTab, updateTab, tabs } = useStoreTabs((state: any) => state);
  const { addFlow, updateFlow } = useFlowsStore();

  const [openAddFlowModal, setOpenAddFlowModal] = useState<boolean>(false);
  const [inactiveUser, setInactiveUser] = useState(false);

  const [isFlowModalOpen, setIsFlowModalOpen] = useState({
    isOpen: false,
    flowId: '',
  });

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openPaywallFlows, setOpenPaywallFlows] = useState(false);

  const handleCloseFlows = () => {
    setIsFlowModalOpen({ isOpen: false, flowId: '' });
    setOpenPaywallFlows(false);
  };

  const [openPaywallChatBots, setOpenPaywallChatBots] = useState(false);

  const handleCloseChatBots = () => {
    setIsFlowModalOpen({ isOpen: false, flowId: '' });
    setOpenPaywallChatBots(false);
  };

  const [isTemplatesModalOpen, setTemplatesModalOpen] = useState(false);

  const [createOrEditFLow, setCreateOrEditFLow] = useState<{
    isSharing: boolean;
    isEditing: boolean;
    type?: string;
    flow: {
      id: string;
      name: string;
      allFlow?: any;
    };
    folderId: string;
  }>({
    isSharing: false,
    flow: {
      id: '',
      name: '',
    },
    type: '',
    folderId: '',
    isEditing: false,
  });

  const navigate = useNavigate();
  const onLogout = () => {
    setAccessToken(null);
    setAuthUser(null);
    LocalStorageService.removeItem('accessToken');
    navigate('/login');
  };

  const getSidebarToggleTopPosition = () => {
    if (!sidebarOpen) {
      if (tabs.length > 1) {
        return '43px';
      }
      return '8px';
    } else {
      return '85px';
    }
  };

  const onUpdateTheme = async (checked: boolean) => {
    try {
      await UserService.updateUser({
        theme: checked ? 'dark' : 'light',
      });
      const res = await AuthService.getMe();
      setChecked(checked);
      setAuthUser(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getFoldersAndDocuments = async () => {
      setIsLoading(true);
      try {
        const [folderData, documentData, technologiesData] = await Promise.all([
          FolderService.getFolders(),
          DocumentService.getDocuments(),
          authUser?.selectedCompanyId
            ? CompanyService.getCompanyTechnologies(authUser?.selectedCompanyId)
            : UserService.getTechnologies(),
        ]);
        setFolders(folderData.data);
        setDocuments(documentData.data);
        setTechnologies(technologiesData.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (authUser?.id) {
      getFoldersAndDocuments();
    }
  }, [authUser?.selectedCompanyId, authUser?.id]);

  const callGetFolders = async (folderId: string, isCreating = false) => {
    try {
      const res = await FolderService.getOne(folderId);

      let modifyFolders = folders.map((folder, index) => {
        if (folder.id === folderId && !isCreating) {
          return res.data;
        }

        return folder;
      });

      if (isCreating) {
        modifyFolders = [...folders, res.data];
      }

      setFolders(modifyFolders);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong , sharing failed',
      });
    }
  };

  const onCreateFlowOrUpdate = async (
    name: string,
    isEditing = false,
    id = '',
    folderId = '',
    onCopy = false,
    type = '',
    spaceId = '',
  ) => {
    try {
      if (isEditing) {
        if (onCopy) {
          const createResponse = await FlowService.duplicateFlow({
            flowId: id,
            folderId,
            spaceId,
          });

          addFlow({
            id: createResponse.data?.id,
            name: createResponse.data?.name,
            index: createResponse.data?.index,
          });
        } else {
          const res = await FlowService.updateFlow(id, { name });
          updateTab({
            flowId: res.data?.id,
            flowName: res.data?.name,
          });
          updateFlow(res.data);
        }
      } else {
        const createResponse = await FlowService.createFlow({
          name,
          folderId: folderId,
          type,
        });

        addFlow({
          id: createResponse.data?.id,
          name: createResponse.data?.name,
          index: createResponse.data?.index,
        });

        if (type === 'chat') {
          setAuthUser({
            ...authUser!,
            chatsId: [...authUser?.chatsId!, createResponse.data?.id!],
          });
        }

        navigate(
          `/flow/${createResponse.data.id}?folderId=${folderId}&type=flow`,
        );
      }

      callGetFolders(folderId);
      setOpenAddFlowModal(false);
    } catch (error) {
      console.log(error);
      let anyError = error as any;
      if (
        typeof anyError.message === 'string' &&
        anyError.message.includes('flows')
      ) {
        setOpenPaywallFlows(true);
      } else {
        setOpenPaywallChatBots(true);
      }
    }
  };

  const fluxBotsLimit = useMemo(() => {
    switch (authUser?.plan) {
      case 'Starter':
        return '3';
      case 'Advanced':
        return '15';
      default:
        return '0';
    }
  }, [authUser?.plan]);

  const flowsLimit = useMemo(() => {
    switch (authUser?.plan) {
      case 'Starter':
        return '5';
      case 'Advanced':
        return '25';
      default:
        return '0';
    }
  }, [authUser?.plan]);

  const nextPlan = useMemo(
    () => getNextPlan(authUser?.plan || null),
    [authUser?.plan],
  );

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Close sidebar by default on mobile
    if (isMobile) {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const getCompanyUser = async () => {
      try {
        const res = await CompanyService.getCompanyUser(
          authUser?.selectedCompanyId!,
        );
        setCurrentUser(res.data);
      } catch (error) {
        console.log('Error fetching user details:', error);
      }
    };

    if (authUser?.selectedCompanyId) {
      getCompanyUser();
    }
  }, [authUser?.selectedCompanyId]);

  useEffect(() => {
    if (currentUser?.status === 'inactive') {
      setInactiveUser(true);
    }
  }, []);

  const switchToPersonalAccount = async () => {
    try {
      const res = await UserService.updateUser({
        selectedCompanyId: '',
        // lastCompanyLogin: null,
      });
      setAuthUser(res.data);
      setInactiveUser(false);
      navigate('/', { replace: true });
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };
  const createPersonalAccount = () => {
    setAccessToken(null);
    setAuthUser(null);
    LocalStorageService.removeItem('accessToken');
    navigate('/register');
  };

  return (
    <div
      className="layout"
      style={{
        backgroundColor: authUser?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            width:
              sidebarOpen && isMobile ? '100%' : sidebarOpen ? '290px' : '0px',
            borderRight: `1px solid ${
              authUser?.theme === 'dark' ? '#344054' : '#D0D5DD'
            }`,
            position: 'relative',
            display: 'flex',
            maxHeight: '100vh',
          }}
          className={'layout_sidebar'}
        >
          <Box
            sx={{
              width: '100%',
              marginTop: '25px',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Box
              sx={{
                px: '18px',
                mb: '30px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '8px',
              }}
              onClick={() => navigate('/')}
            >
              {authUser?.theme === 'dark' ? (
                <img
                  src={FluxpromptLogoWhite}
                  style={{ width: '100%' }}
                  alt="fluxPrompt"
                />
              ) : (
                <img
                  src={FluxpromptLogo}
                  style={{ width: '100%' }}
                  alt="fluxPrompt"
                />
              )}
              {sidebarOpen ? (
                <Typography
                  sx={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                    fontFeatureSettings: "'clig' 0, 'liga' 0",
                    fontFamily: 'Montserrat',
                    fontSize: '8px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                  }}
                >
                  BETA VERSION 1.2
                </Typography>
              ) : null}
            </Box>
            <Box
              style={{
                position: 'absolute',
                top: getSidebarToggleTopPosition(),
                left: !sidebarOpen ? '1rem' : '13.8rem',
                zIndex: '999',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'end',
              }}
              className={`sidebar-toggle ${
                !sidebarOpen && 'sidebar-toggle--closed'
              }`}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <Box onClick={() => setSidebarOpen(!sidebarOpen)}>
                <Notebook
                  size={24}
                  weight={sidebarOpen ? 'regular' : 'fill'}
                  // weight={'regular'}
                  style={{
                    cursor: 'pointer',
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                  }}
                />
              </Box>
            </Box>

            <Box
              className={`layout_sidebar_menu`}
              style={{
                display: `${sidebarOpen ? 'flex' : 'none'}`,
                flexDirection: 'column',
                rowGap: '18px',
                height: '100%',
                // paddingBottom: '2rem',
              }}
            >
              <Menu
                darkTheme={authUser?.theme === 'dark'}
                setSettingsModalOpen={setIsModalOpen}
                setTemplatesModalOpen={setTemplatesModalOpen}
                sidebarOpen={sidebarOpen}
                authUser={authUser!}
                setIsLoading={setIsLoading}
                checked={checked}
                onLogout={onLogout}
                onUpdateTheme={onUpdateTheme}
                setAuthUser={setAuthUser}
              />

              <Box
                sx={{
                  flex: 1,
                  paddingLeft: '4px',
                  paddingRight: '8px',
                  paddingBottom: '30px',
                  marginBottom: '30px',
                  overflowY: 'scroll',
                  height: 'calc(100vh - 270px)',
                }}
              >
                <PrivateFlows
                  active={'/'}
                  addTab={addTab}
                  callGetFolders={callGetFolders}
                  createOrEditFLow={createOrEditFLow}
                  navigate={navigate}
                  collapsePrivate={collapsePrivate}
                  setCollapsePrivate={setCollapsePrivate}
                  onCreateFlowOrUpdate={onCreateFlowOrUpdate}
                  openAddFlowModal={openAddFlowModal}
                  setCreateOrEditFLow={setCreateOrEditFLow}
                  setIsFlowModalOpen={setIsFlowModalOpen}
                  setNotification={setNotification}
                  setOpenAddFlowModal={setOpenAddFlowModal}
                  setSidebarOpen={setSidebarOpen}
                  sidebarOpen={sidebarOpen}
                  updateTab={updateTab}
                  handleCloseSideBarMobile={() => {
                    if (window.innerWidth <= 768) {
                      setSidebarOpen(false);
                    }
                  }}
                />

                <Collapse in={collapsePrivate}>
                  <Uploads
                    removeNotification={removeNotification}
                    setNotification={setNotification}
                    sidebarOpen={sidebarOpen}
                    handleCloseSideBarMobile={() => {
                      if (window.innerWidth <= 768) {
                        setSidebarOpen(false);
                      }
                    }}
                  />

                  <FluxObjects
                    authUser={authUser!}
                    setAuthUser={setAuthUser}
                    sidebarOpen={sidebarOpen}
                    handleCloseSideBarMobile={() => {
                      if (window.innerWidth <= 768) {
                        setSidebarOpen(false);
                      }
                    }}
                  />

                  <Vector
                    authUser={authUser!}
                    setAuthUser={setAuthUser}
                    setNotification={setNotification}
                    sidebarOpen={sidebarOpen}
                    handleCloseSideBarMobile={() => {
                      if (window.innerWidth <= 768) {
                        setSidebarOpen(false);
                      }
                    }}
                  />
                </Collapse>
                <Spaces
                  setSidebarOpen={setSidebarOpen}
                  sidebarOpen={sidebarOpen}
                  onOpenModal={() => {
                    setIsModalOpen(true);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>

        {isModalOpen && (
          <ModalComponent
            onClose={() => {
              setIsModalOpen(false);
              let url = new URL(window.location.href);
              window.history.pushState({}, '', url.pathname);
            }}
            open={isModalOpen}
          />
        )}
        {isTemplatesModalOpen && (
          <TemplateSystemModal onClose={() => setTemplatesModalOpen(false)} />
        )}

        <div
          style={{
            height: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Navbar
            authUser={authUser}
            setAuthuser={setAuthUser}
            setCreateOrEditFLow={setCreateOrEditFLow}
            setOpenAddFlowModal={setOpenAddFlowModal}
            onCreateFlow={onCreateFlowOrUpdate}
            setFolders={setFolders}
          />

          {isLoading ? (
            <LoadingSpinner height="100%" />
          ) : (
            <>
              <Outlet context={[sidebarOpen]} />
              <ChatBot
                sideBarOpenWidth={sidebarOpen ? 290 : 0}
                authUser={authUser!}
                onCreateChat={() => {
                  setCreateOrEditFLow({
                    isSharing: false,
                    isEditing: false,
                    type: 'chat',
                    flow: {
                      name: '',
                      id: '',
                    },
                    folderId: folders.filter(
                      folder => folder.name === 'Chatbot',
                    )[0].id,
                  });
                  setOpenAddFlowModal(true);
                }}
              />
            </>
          )}
        </div>
      </div>
      <FlowFormModal
        isFlowModalOpen={isFlowModalOpen}
        setIsFlowModalOpen={setIsFlowModalOpen}
      />

      {/* Flows Paywall */}
      <PaywallModal
        onHide={handleCloseFlows}
        fullWidth={true}
        show={openPaywallFlows}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
              }}
              onClick={() => {
                handleCloseFlows();
                setIsModalOpen(true);
                navigate('?pricing=true');
              }}
            >
              See All Plans
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#3650DE',
                color: '#FFFFFF',
                outline: '1px solid #3650DE',
              }}
              onClick={() => {
                handleCloseFlows();
                setIsModalOpen(true);
                navigate(`?pricing=true&plan=${nextPlan}`);
              }}
            >
              Upgrade Now
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Warning size={45} color="#FDA000" />
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              {flowsLimit === '0'
                ? 'Please purchase a plan to create flows.'
                : 'Flow Limit Reached!'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              {flowsLimit === '0'
                ? ''
                : `You've reached the ${flowsLimit} flow limit allowed on our ${authUser?.plan} plan.`}
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              To create more flows and unlock additional features, upgrade to
              one of our premium plans.
            </Typography>
          </Box>
        </Box>
      </PaywallModal>

      {/* Chatbots Paywall */}
      <PaywallModal
        onHide={handleCloseChatBots}
        fullWidth={true}
        show={openPaywallChatBots}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
              }}
              onClick={() => {
                handleCloseChatBots();
                setIsModalOpen(true);
                navigate('?pricing=true');
              }}
            >
              See All Plans
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#3650DE',
                color: '#FFFFFF',
                outline: '1px solid #3650DE',
              }}
              onClick={() => {
                handleCloseChatBots();
                setIsModalOpen(true);
                navigate(`?pricing=true&plan=${nextPlan}`);
              }}
            >
              Upgrade Now
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <RobotWarningIcon />
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              {fluxBotsLimit === '0'
                ? 'Please purchase a plan to create FluxBots.'
                : 'FluxBots Limit Reached!'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              {fluxBotsLimit === '0'
                ? ''
                : `You've reached the ${fluxBotsLimit} FluxBots limit allowed on our ${authUser?.plan} plan.`}
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              To create more flows and unlock additional features, upgrade to
              one of our premium plans.
            </Typography>
          </Box>
        </Box>
      </PaywallModal>

      {/* Inactive Users */}
      <PaywallModal
        maxWidth="md"
        onHide={() => {}}
        show={inactiveUser}
        showCloseButton={false}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '32px',
          }}
        >
          <InactiveStatus />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <Typography fontWeight={600} fontSize={24}>
              Oops!
            </Typography>
            <Typography fontWeight={400}>
              You no longer have access to the Organization.
            </Typography>
            <Typography fontWeight={400}>
              Contact your admin for more information.
            </Typography>
          </Box>
          <Button
            variant="outlined"
            onClick={() =>
              authUser?.registrationMethod === 'invite'
                ? createPersonalAccount()
                : switchToPersonalAccount()
            }
            sx={{ width: '100%' }}
          >
            {authUser?.registrationMethod === 'invite'
              ? 'Create a personal account'
              : 'Switch to personal account'}
          </Button>
        </Box>
      </PaywallModal>
      {/* {/* {onPreviewModal && ( */}
      {testPreviewModal?.open && (
        <PreviewModal
          data={{
            ...testPreviewModal,
            // processedSegments,
          }}
          name={'Output'}
          onHide={() => {
            setTestPreviewModal(null);
          }}
          show={true}
          type={testPreviewModal?.type}
          // processedSegments={processedSegments}
        />
      )}
      {authUser?.transferData && <TransferUserModal onClose={() => {}} />}
    </div>
  );
};

export default Layout;
