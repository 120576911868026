import { Edge, Node } from 'reactflow';
import { validState } from '../validateNodeState';
import {
  flowTriggerDataHandle,
  getOutputByType,
  givingDataToNode,
} from './utilis';
import { allNodesTargetBasedOnType } from '../dataStoredToType';
import { processorArrayFc } from '../arrayFunctionts';

const updateNodeData = ({
  node,
  id,
  objectData,
}: {
  node: Node[];
  id: string;
  objectData: {
    [key: string]: string;
  };
}) => {
  return node.map(nd => {
    if (nd.id === id) {
      return {
        ...nd,
        data: {
          ...nd.data,
          ...objectData,
          ...validState(nd?.data?.isEmptyState || false),
        },
      };
    }

    if (objectData?.run === 'Run from here' && nd.id !== id) {
      return {
        ...nd,
        data: {
          ...nd.data,
          run: null,
        },
      };
    }

    return nd;
  });
};

const changeLabel = ({
  node,
  id,
  objectData,
  edges,
  objectCallerData,
}: {
  node: Node<any>[];
  id: string;
  objectData: {
    [key: string]: string;
  };
  edges: Edge[];
  objectCallerData: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}) => {
  const findAllEdges = edges?.filter(edge => edge.source === id);

  const combinedData: {
    [target: string]: { [targetHandle: string]: any };
  } = {};

  if (findAllEdges?.length) {
    findAllEdges.forEach((edge: any) => {
      const target = edge.target;
      const targetHandle = edge.targetHandle;

      if (!combinedData[target]) {
        combinedData[target] = {};
      }

      if (!combinedData?.[target][targetHandle]) {
        combinedData[target][targetHandle] = {};
      }

      getOutputByType({
        edge,
        nds: node,
        combinedData,
        objectCallerData,
        combinedTexts: {},
      });
    });
  }

  return node.map(nd => {
    const combinedTextsKeys = Object.keys(combinedData);

    const ndTypeAsKeyOfAllNodesTargetBasedOnType =
      allNodesTargetBasedOnType[
        nd.type as keyof typeof allNodesTargetBasedOnType
      ];

    if (nd.id === id) {
      return {
        ...nd,
        data: {
          ...nd.data,
          label: objectData.label,
        },
      };
    }

    if (
      combinedTextsKeys?.includes(nd?.id) &&
      ndTypeAsKeyOfAllNodesTargetBasedOnType
    ) {
      const targetKeys = Object?.keys(combinedData[nd.id]);

      const getValuesFrom = Object?.keys(
        ndTypeAsKeyOfAllNodesTargetBasedOnType,
      );

      const getAlsoValues = Object?.values(
        ndTypeAsKeyOfAllNodesTargetBasedOnType,
      );

      const keyMapping: any = getValuesFrom?.reduce((acc: any, key, index) => {
        acc[key] = getAlsoValues?.[index];
        return acc;
      }, {});

      const newObject: {
        [key: string]: any;
      } = {};

      targetKeys.forEach((key: string) => {
        const mappedKey = keyMapping[key];

        if (mappedKey) {
          newObject[mappedKey + 'Data'] = combinedData[nd.id][key];
        }
      });

      const newObjectKeys = Object.keys(newObject);

      let newData: {
        [key: string]: string;
      } = {};

      newObjectKeys.forEach((key: string) => {
        if (key) {
          const findData = nd?.data?.[key];

          if (!newData[key]) {
            newData[key] = findData;
          }

          if (findData?.length) {
            const mappedData = findData.map(
              (item: { id: string; text: string }) => {
                if (newObject[key]?.id === item?.id) {
                  return {
                    ...item,
                    label: objectData.label,
                  };
                }
                return item;
              },
            );

            newData[key] = mappedData;
          }
        }
      });

      return {
        ...nd,
        data: {
          ...nd.data,
          ...newData,
        },
      };
    }

    return nd;
  });
};

const updateEdgeData = ({
  updateEdge,
  node,
  objectCallerData,
}: {
  updateEdge: {
    oldEdge: Edge;
    newConnection: Edge;
  };
  node: Node[];
  objectCallerData: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}) => {
  if (updateEdge?.oldEdge?.source && updateEdge?.newConnection?.source) {
    const { oldEdge, newConnection } = updateEdge;

    const oldEdgeData = givingDataToNode({
      edge: oldEdge,
      nds: node,
      type: 'delete',
      objectCallerData,
    });

    const newConnectionData = givingDataToNode({
      edge: newConnection,
      nds: node,
      type: 'add',
      objectCallerData,
    });

    return node?.map((nd: any) => {
      if (nd?.id === newConnection.target && nd?.id !== oldEdge.target) {
        if (
          newConnection.targetHandle ===
            nodePassOnlyTextRealHandleId[
              nd?.type as keyof typeof nodePassOnlyTextRealHandleId
            ] ||
          (nd?.type === 'mathFunction' &&
            newConnection.targetHandle !== 'input' &&
            newConnection.targetHandle !== 'formula') ||
          nd?.type === 'fluxBox' ||
          nd?.type === 'nodesBox' ||
          nd?.type === 'fileSave' ||
          (nd?.type === 'scripting' &&
            newConnection.targetHandle !== 'input' &&
            newConnection.targetHandle !== 'script')
        ) {
          let data = {};

          if (nd.type === 'mathFunction' || nd.type === 'scripting') {
            const variables = nd.data?.variables || [];

            const newVariables = variables.map((item: any) => {
              if (item.id === newConnection.targetHandle) {
                return {
                  ...item,
                  value: newConnectionData?.data?.text,
                };
              }
              return item;
            });

            data = {
              variables: newVariables,
            };
          }

          if (nd.type === 'fileSave') {
            const files = nd.data?.files || [];

            const newFiles = files.map((item: any) => {
              const isName = newConnection.targetHandle?.includes('-name');
              const isFile = newConnection.targetHandle?.includes('-file');

              if (
                item.id === newConnection.targetHandle?.replace('-file', '') ||
                item.id === newConnection.targetHandle?.replace('-name', '')
              ) {
                return {
                  ...item,
                  value: isFile ? newConnectionData?.data?.text : item.value,
                  fileName: isName
                    ? newConnectionData?.data?.text
                    : item.fileName,
                };
              }
              return item;
            });

            data = {
              files: newFiles,
            };
          }

          if (nd.type === 'arrayNode') {
            const formData = {
              options: 'Paragraph',
              processorArray: [],
              customOperator: '',
              startingPoint: 1,
              endingPoint: 5,
              completed: 1,
              executingArray: 1,
              executing: 'default',
              auto: false,
              breakWords: '',
              preInput: '',
              postInput: '',
              processorArrayItemLength: 1,
              ...nd?.data,
              content: newConnectionData?.data?.text,
            };

            const processorArray = processorArrayFc(formData);

            data = {
              processorArray,
              content: newConnectionData?.data?.text,
            };
          }

          if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
            data = {
              [String(newConnection.targetHandle)]:
                newConnectionData?.data?.text,
            };
          }

          return {
            ...nd,
            data: {
              ...nd.data,
              [nodePassOnlyText[nd.type as keyof typeof nodePassOnlyText]]:
                newConnectionData.data.text,

              ...data,
            },
          };
        } else {
          if (!newConnectionData.checkIfAlreadyConnected) {
            const newData = [
              ...newConnectionData.handleData,
              newConnectionData.data,
            ];

            return {
              ...nd,
              data: {
                ...nd.data,
                [newConnectionData.getType + 'Data']: newData,
              },
            };
          }
        }
      }

      if (nd.id === oldEdge.target && nd.id !== newConnection.target) {
        if (
          oldEdge.targetHandle ===
            nodePassOnlyTextRealHandleId[
              nd?.type as keyof typeof nodePassOnlyTextRealHandleId
            ] ||
          (nd?.type === 'mathFunction' &&
            oldEdge.targetHandle !== 'input' &&
            oldEdge.targetHandle !== 'formula') ||
          nd?.type === 'fluxBox' ||
          nd?.type === 'nodesBox' ||
          nd?.type === 'fileSave' ||
          (nd?.type === 'scripting' &&
            oldEdge.targetHandle !== 'input' &&
            oldEdge.targetHandle !== 'script')
        ) {
          let data = {};

          if (nd.type === 'mathFunction' || nd.type === 'scripting') {
            const variables = nd.data?.variables || [];

            const newVariables = variables.map((item: any) => {
              if (item.id === oldEdge.targetHandle) {
                return {
                  ...item,
                  value: '',
                };
              }
              return item;
            });

            data = {
              variables: newVariables,
            };
          }

          if (nd.type === 'fileSave') {
            const files = nd.data?.files || [];

            const newFiles = files.map((item: any) => {
              const isName = oldEdge.targetHandle?.includes('-name');
              const isFile = oldEdge.targetHandle?.includes('-file');

              if (
                item.id === oldEdge.targetHandle?.replace('-file', '') ||
                item.id === oldEdge.targetHandle?.replace('-name', '')
              ) {
                return {
                  ...item,
                  value: isFile ? '' : item.value,
                  fileName: isName ? '' : item.fileName,
                };
              }
              return item;
            });

            data = {
              files: newFiles,
            };
          }

          if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
            data = {
              [String(oldEdge.targetHandle)]: '',
            };
          }

          if (nd.type === 'arrayNode') {
            data = {
              processorArray: [],
              content: '',
            };
          }

          return {
            ...nd,
            data: {
              ...nd.data,
              [nodePassOnlyText[nd.type as keyof typeof nodePassOnlyText]]: '',
              ...data,
            },
          };
        } else {
          return {
            ...nd,
            data: {
              ...nd.data,
              [oldEdgeData.getType + 'Data']: oldEdgeData.handleData,
            },
          };
        }
      }

      if (nd?.id === oldEdge.target && nd?.id === newConnection.target) {
        let object: any = {};

        if (
          oldEdge.targetHandle ===
            nodePassOnlyTextRealHandleId[
              nd?.type as keyof typeof nodePassOnlyTextRealHandleId
            ] ||
          (nd?.type === 'mathFunction' &&
            oldEdge.targetHandle !== 'input' &&
            oldEdge.targetHandle !== 'formula') ||
          (nd?.type === 'scripting' &&
            oldEdge.targetHandle !== 'input' &&
            oldEdge.targetHandle !== 'script') ||
          nd?.type === 'fluxBox' ||
          nd?.type === 'nodesBox' ||
          nd?.type === 'fileSave'
        ) {
          if (nd.type === 'mathFunction' || nd.type === 'scripting') {
            const variables = nd.data?.variables || [];

            const newVariables = variables.map((item: any) => {
              if (item.id === oldEdge.targetHandle) {
                return {
                  ...item,
                  value: '',
                };
              }
              return item;
            });

            object = {
              variables: newVariables,
            };
          }

          if (nd.type === 'fileSave') {
            const files = nd.data?.files || [];

            const newFiles = files.map((item: any) => {
              const isName = oldEdge.targetHandle?.includes('-name');
              const isFile = oldEdge.targetHandle?.includes('-file');

              if (
                item.id === oldEdge.targetHandle?.replace('-file', '') ||
                item.id === oldEdge.targetHandle?.replace('-name', '')
              ) {
                return {
                  ...item,
                  value: isFile ? '' : item.value,
                  fileName: isName ? '' : item.fileName,
                };
              }

              return item;
            });

            object = {
              files: newFiles,
            };
          }

          if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
            object = {
              [String(oldEdge.targetHandle)]: '',
            };
          }

          if (nd.type === 'arrayNode') {
            object = {
              processorArray: [],
              content: '',
              ...object,
            };
          }

          object = {
            [nodePassOnlyText[nd.type as keyof typeof nodePassOnlyText]]: '',
            ...object,
          };
        }

        if (
          newConnection.targetHandle ===
            nodePassOnlyTextRealHandleId[
              nd?.type as keyof typeof nodePassOnlyTextRealHandleId
            ] ||
          (nd?.type === 'mathFunction' &&
            newConnection.targetHandle !== 'input' &&
            newConnection.targetHandle !== 'formula') ||
          (nd?.type === 'scripting' &&
            newConnection.targetHandle !== 'input' &&
            newConnection.targetHandle !== 'script') ||
          nd?.type === 'fluxBox' ||
          nd?.type === 'nodesBox' ||
          nd?.type === 'fileSave'
        ) {
          if (nd.type === 'mathFunction' || nd.type === 'scripting') {
            const variables = object?.variables
              ? object?.variables
              : nd.data?.variables || [];
            const newVariables = variables.map((item: any) => {
              if (item.id === newConnection.targetHandle) {
                return {
                  ...item,
                  value: newConnectionData?.data?.text,
                };
              }
              return item;
            });

            object = {
              ...object,
              variables: newVariables,
            };
          }

          if (nd.type === 'fileSave') {
            const files = object?.files ? object?.files : nd.data?.files || [];
            const newfiles = files.map((item: any) => {
              const isName = newConnection.targetHandle?.includes('-name');
              const isFile = newConnection.targetHandle?.includes('-file');

              if (
                item.id === newConnection.targetHandle?.replace('-file', '') ||
                item.id === newConnection.targetHandle?.replace('-name', '')
              ) {
                return {
                  ...item,
                  value: isFile ? newConnectionData?.data?.text : item.value,
                  fileName: isName
                    ? newConnectionData?.data?.text
                    : item.fileName,
                };
              }
              return item;
            });

            object = {
              ...object,
              files: newfiles,
            };
          }

          if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
            object = {
              ...object,
              [String(newConnection.targetHandle)]:
                newConnectionData?.data?.text,
            };
          }

          if (nd.type === 'arrayNode') {
            const formData = {
              options: 'Paragraph',
              processorArray: [],
              customOperator: '',
              startingPoint: 1,
              endingPoint: 5,
              completed: 1,
              executingArray: 1,
              executing: 'default',
              auto: false,
              breakWords: '',
              preInput: '',
              postInput: '',
              processorArrayItemLength: 1,
              ...nd?.data,
              content: newConnectionData?.data?.text,
            };

            const processorArray = processorArrayFc(formData);

            object = {
              ...object,
              processorArray,
              content: newConnectionData?.data?.text,
            };
          }

          object = {
            ...object,
          };
        }

        const newData = [
          ...newConnectionData.handleData,
          newConnectionData.data,
        ];

        if (newConnectionData.checkIfAlreadyConnected) {
          const newTargetHandleData = newConnectionData.handleData.map(item => {
            if (item.id === oldEdge.source) {
              return {
                ...item,
                text: newConnectionData?.data?.text,
                label: newConnectionData?.data?.label,
              };
            }
            return item;
          });

          object = {
            ...object,
            [newConnectionData.getType + 'Data']: newTargetHandleData,
          };
        }

        if (nd.type === 'mathFunction' || nd.type === 'scripting') {
          object = {
            ...object,
            variables: object?.variables || nd?.data?.variables,
          };
        }

        if (nd.type === 'fileSave') {
          object = {
            ...object,
            files: object?.files || nd?.data?.files,
          };
        }

        return {
          ...nd,
          data: {
            ...nd.data,
            [oldEdgeData.getType + 'Data']: oldEdgeData.handleData,
            [newConnectionData.getType + 'Data']: newData,
            ...object,
          },
        };
      }

      return nd;
    });
  }

  return node;
};

const connect = ({
  connectData,
  node,
  objectCallerData,
}: {
  connectData: Edge;
  node: Node[];
  objectCallerData: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}) => {
  const { data: connectedText, findTarget } = givingDataToNode({
    edge: connectData,
    nds: node,
    type: 'add',
    objectCallerData,
  });

  if (
    connectData.targetHandle ===
      nodePassOnlyTextRealHandleId[
        findTarget?.type as keyof typeof nodePassOnlyTextRealHandleId
      ] ||
    (findTarget?.type === 'mathFunction' &&
      connectData.targetHandle !== 'input' &&
      connectData.targetHandle !== 'formula') ||
    (findTarget?.type === 'scripting' &&
      connectData.targetHandle !== 'input' &&
      connectData.targetHandle !== 'script') ||
    findTarget?.type === 'fluxBox' ||
    findTarget?.type === 'nodesBox' ||
    findTarget?.type === 'fileSave'
  ) {
    return node.map(nd => {
      if (nd.id === connectData?.target) {
        let data = {};

        if (nd.type === 'mathFunction' || nd.type === 'scripting') {
          const variables: {
            id: string;
            value: string;
          }[] = nd.data?.variables || [];

          const newVariables = variables.map(item => {
            if (item.id === connectData.targetHandle) {
              return {
                ...item,
                value: connectedText.text,
              };
            }
            return item;
          });

          data = {
            variables: newVariables,
          };
        }

        if (nd.type === 'fileSave') {
          const files: {
            id: string;
            value: string;
            fileName: string;
          }[] = nd.data?.files || [];

          const newfiles = files.map(item => {
            const isName = connectData.targetHandle?.includes('-name');
            const isFile = connectData.targetHandle?.includes('-file');

            if (
              item.id === connectData.targetHandle?.replace('-file', '') ||
              item.id === connectData.targetHandle?.replace('-name', '')
            ) {
              return {
                ...item,
                value: isFile ? connectedText.text : item.value,
                fileName: isName ? connectedText.text : item.fileName,
              };
            }
            return item;
          });

          data = {
            files: newfiles,
          };
        }

        if (nd.type === 'arrayNode') {
          const formData = {
            options: 'Paragraph',
            processorArray: [],
            customOperator: '',
            startingPoint: 1,
            endingPoint: 5,
            completed: 1,
            executingArray: 1,
            executing: 'default',
            auto: false,
            breakWords: '',
            preInput: '',
            postInput: '',
            processorArrayItemLength: 1,
            ...nd?.data,
            content: connectedText.text,
          };

          const processorArray = processorArrayFc(formData);

          data = {
            processorArray,
            content: connectedText.text,
          };
        }

        if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
          data = {
            [String(connectData?.targetHandle)]: connectedText.text,
          };
        }

        return {
          ...nd,
          data: {
            ...nd.data,

            [nodePassOnlyText[nd.type as keyof typeof nodePassOnlyText]]:
              connectedText.text,

            ...data,
          },
        };
      }
      return nd;
    });
  } else {
    const {
      checkIfAlreadyConnected,
      handleData: targetHandleData,
      getType,
      data,
    } = givingDataToNode({
      edge: connectData,
      nds: node,
      type: 'add',
      objectCallerData,
    });

    if (!checkIfAlreadyConnected) {
      const newTargetHandleData = [...targetHandleData, data];

      return node?.map(nd => {
        if (nd.id === connectData?.target) {
          return {
            ...nd,
            data: {
              ...nd.data,
              [getType + 'Data']: newTargetHandleData,
            },
          };
        }
        return nd;
      });
    }
  }
  return node;
};

const deleteEdgesData = ({
  deleteEdges,
  node,
  objectCallerData,
}: {
  deleteEdges: Edge[];
  node: Node[];
  objectCallerData?:
    | {
        id: string;
        text: {
          id: string;
          text: string;
        }[];
      }[]
    | undefined;
}) => {
  let newNodes = [...node];

  if (deleteEdges?.length) {
    deleteEdges.forEach((edgeToDelete: Edge) => {
      const {
        checkIfAlreadyConnected,
        handleData: targetHandleData,
        getType,
        findTarget,
      } = givingDataToNode({
        edge: edgeToDelete,
        nds: node,
        type: 'delete',
        objectCallerData,
      });

      if (
        edgeToDelete.targetHandle ===
          nodePassOnlyTextRealHandleId[
            findTarget?.type as keyof typeof nodePassOnlyTextRealHandleId
          ] ||
        (findTarget?.type === 'mathFunction' &&
          edgeToDelete.targetHandle !== 'input' &&
          edgeToDelete.targetHandle !== 'formula') ||
        findTarget?.type === 'fluxBox' ||
        findTarget?.type === 'nodesBox' ||
        findTarget?.type === 'fileSave' ||
        (findTarget?.type === 'scripting' &&
          edgeToDelete.targetHandle !== 'input' &&
          edgeToDelete.targetHandle !== 'script')
      ) {
        newNodes = node.map(nd => {
          if (nd.id === edgeToDelete?.target) {
            let data = {};

            let targetHandle = edgeToDelete.targetHandle;
            if (nd.type === 'mathFunction' || nd.type === 'scripting') {
              const variables: {
                id: string;
                value: string;
              }[] = nd.data?.variables || [];

              const newVariables = variables.map(item => {
                if (item.id === edgeToDelete.targetHandle) {
                  return {
                    ...item,
                    value: '',
                  };
                }
                return item;
              });

              data = {
                variables: newVariables,
              };
            }

            if (nd.type === 'fileSave') {
              const files: {
                id: string;
                fileName: string;
                value: string;
              }[] = nd.data?.files || [];

              const newfiles = files.map(item => {
                const isFile = edgeToDelete.targetHandle?.includes('-file');
                const isName = edgeToDelete.targetHandle?.includes('-name');

                if (
                  item.id === edgeToDelete.targetHandle?.replace('-file', '') ||
                  item.id === edgeToDelete.targetHandle?.replace('-name', '')
                ) {
                  return {
                    ...item,
                    value: isFile ? '' : item.value,
                    fileName: isName ? '' : item.fileName,
                  };
                }
                return item;
              });

              data = {
                files: newfiles,
              };
            }

            if (nd.type === 'arrayNode') {
              data = {
                processorArray: [],
                content: '',
              };
            }

            if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
              data = {
                [String(targetHandle)]: '',
              };
            }

            return {
              ...nd,
              data: {
                ...nd.data,

                [nodePassOnlyText[
                  findTarget?.type as keyof typeof nodePassOnlyText
                ]]: '',
                ...data,
              },
            };
          }
          return nd;
        });
      } else {
        newNodes = newNodes.map(nd => {
          if (nd.id === edgeToDelete.target) {
            return {
              ...nd,
              data: {
                ...nd.data,
                [getType + 'Data']: targetHandleData,
              },
            };
          }
          return nd;
        });
      }
    });
  }

  return newNodes;
};

const defaultTest = ({
  flowTriggerData,
  node,
  id,
  objectData,
  edges,
  changeType,
  objectCallerData,
}: {
  flowTriggerData: {
    flowGlobalId: string;
    flowId: string;
    id: string;
    completed: number;
    length: number;
    paused: boolean;
    allIds: string[];
    canceled: boolean;
    runFrom: string;
    hasArrayExecute: boolean;
    conditionTrue: boolean;
    arrayExecute: {
      id: string;
      completed: number;
      goToFirstId: boolean;
      loopingToId: string;
      firstId: string;
    };
  };
  node: Node[];
  id: string;
  objectData: {
    [key: string]: string;
  };
  edges: Edge[];
  changeType: string;
  objectCallerData: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}) => {
  let updatedNodes = modifyData(
    node,
    id,
    objectData,
    edges,
    changeType,
    objectCallerData,
  );

  const newNodes = flowTriggerDataHandle({
    nds: updatedNodes,
    flowTriggerData,
    edges,
    id,
  });

  let update = modifyData(
    newNodes,
    flowTriggerData?.arrayExecute?.id || '',
    {},
    edges,
    changeType,
    objectCallerData,
  );

  edges
    ?.map(edg => edg.source)
    ?.forEach((id: string) => {
      const findNode = update.find(nd => nd.id === id);

      if (findNode) {
        update = modifyData(
          update,
          id,
          findNode.data,
          edges,
          changeType,
          objectCallerData,
        );
      }
    });

  return update;
};

const handleChanges = ({
  node,
  changeType,
  id,
  objectData,
  edges,
  updateEdge,
  connectData,
  deleteEdges,
  flowTriggerData,
  objectCallerData,
}: {
  node: Node[];
  changeType: string;
  id: string;
  objectData: {
    [key: string]: string;
  };
  edges: Edge[];
  updateEdge: {
    oldEdge: Edge;
    newConnection: Edge;
  };
  connectData: Edge;
  deleteEdges: Edge[];
  flowTriggerData: {
    flowGlobalId: string;
    flowId: string;
    id: string;
    completed: number;
    length: number;
    paused: boolean;
    allIds: string[];
    runFrom: string;
    canceled: boolean;
    hasArrayExecute: boolean;
    conditionTrue: boolean;
    arrayExecute: {
      id: string;
      completed: number;
      goToFirstId: boolean;
      loopingToId: string;
      firstId: string;
    };
  };
  objectCallerData: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}): { nds: Node[]; node: Node[] } => {
  let newNds = node;

  if (changeType === 'default') {
    newNds = updateNodeData({ node, id, objectData });
  } else if (changeType === 'changeLabel') {
    newNds = changeLabel({
      node,
      id,
      objectData,
      edges,
      objectCallerData,
    });
  } else if (changeType === 'update-edge') {
    newNds = updateEdgeData({ updateEdge, node, objectCallerData });
  } else if (changeType === 'connect') {
    newNds = connect({ connectData, node, objectCallerData });
  } else if (changeType === 'delete-edges') {
    newNds = deleteEdgesData({ deleteEdges, node, objectCallerData });
  } else {
    newNds = defaultTest({
      flowTriggerData,
      node,
      id,
      objectData,
      edges,
      changeType,
      objectCallerData,
    });
  }

  return { nds: newNds, node };
};

export const modifyData = (
  node: Node[],
  id: string,
  objectData: {
    [key: string]: string;
  },
  edges: Edge[],
  changeType?: string,
  objectCallerData?: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[],
) => {
  const combinedTexts: {
    [target: string]: { [targetHandle: string]: string };
  } = {};

  const combinedData: {
    [target: string]: { [targetHandle: string]: any };
  } = {};

  let nds = node.map(nd => {
    if (nd.id === id) {
      return {
        ...nd,
        data: {
          ...nd.data,
          ...objectData,
        },
      };
    }

    return nd;
  });

  edges
    ?.filter(
      edg => edg?.target === id || edg?.source === id || edg?.source === id,
    )
    .map(edge => {
      const target = edge.target;
      const targetHandle = edge.targetHandle!;

      if (!combinedData[target]) {
        combinedData[target] = {};
      }

      if (!combinedData?.[target][targetHandle]) {
        combinedData[target][targetHandle] = {};
      }

      getOutputByType({
        edge,
        nds,
        combinedData,
        combinedTexts,
        objectCallerData,
      });
    });

  return (nds = nds?.map(nd => {
    const combinedTextsKeys = Object.keys(combinedData);

    const ndTypeAsKeyOfAllNodesTargetBasedOnType =
      allNodesTargetBasedOnType[
        nd.type as keyof typeof allNodesTargetBasedOnType
      ];

    const ndType: any = nd.type;

    const check: any =
      ndType !== 'mathFunction' ||
      ndType !== 'nodesBox' ||
      ndType !== 'fluxBox' ||
      ndType !== 'fluxObject' ||
      ndType !== 'secApiNode' ||
      ndType !== 'fluxBox' ||
      ndType !== 'fileSave' ||
      ndType !== 'scripting';

    if (nd.type === 'fluxBox') {
      if (combinedTextsKeys?.includes(nd?.id)) {
        const keys = Object.keys(combinedData?.[nd.id]);

        const newObject: {
          [key: string]: any;
        } = {};

        keys.forEach((key: string) => {
          if (combinedData?.[nd.id]?.[key]) {
            newObject[key] = combinedData?.[nd.id]?.[key]?.text;
          }
        });

        return {
          ...nd,
          data: {
            ...nd.data,
            ...newObject,
          },
        };
      }
    }
    if (nd.type === 'mathFunction') {
      if (combinedTextsKeys?.includes(nd?.id)) {
        const newObject: {
          [key: string]: any;
        } = {};

        const targetKeys = Object.keys(combinedData?.[nd.id]);

        targetKeys.forEach((key: string) => {
          if (key === 'formula') {
            const findFormulaData: {
              id: string;
              text: string;
            }[] = nd.data['formulaData'] || [];

            if (findFormulaData?.length) {
              const mappedData = findFormulaData.map((item, index: number) => {
                if (combinedData?.[nd.id]?.[key]?.id === item?.id) {
                  return {
                    ...item,
                    text: combinedData?.[nd.id]?.[key]?.text,
                  };
                }
                return item;
              });

              newObject[key + 'Data'] = mappedData;
            }
          }
        });

        return {
          ...nd,
          data: {
            ...nd.data,
            ...newObject,
            variables: nd.data?.variables?.length
              ? nd.data.variables?.map((item: any, index: number) => {
                  const targetKeys = Object.keys(combinedData?.[nd.id]);

                  if (targetKeys?.includes(item?.id)) {
                    return {
                      ...item,
                      value: combinedData?.[nd.id]?.[item.id]?.text,
                    };
                  }

                  return item;
                })
              : [],
          },
        };
      }
    }

    if (nd.type === 'scripting') {
      if (combinedTextsKeys?.includes(nd?.id)) {
        const newObject: {
          [key: string]: any;
        } = {};

        const targetKeys = Object.keys(combinedData?.[nd.id]);

        targetKeys.forEach((key: string) => {
          if (key === 'script') {
            const findFormulaData: {
              id: string;
              text: string;
            }[] = nd.data['scriptData'] || [];

            if (findFormulaData?.length) {
              const mappedData = findFormulaData.map((item, index: number) => {
                if (combinedData?.[nd.id]?.[key]?.id === item?.id) {
                  return {
                    ...item,
                    text: combinedData?.[nd.id]?.[key]?.text,
                  };
                }
                return item;
              });

              newObject[key + 'Data'] = mappedData;
            }
          }
        });

        return {
          ...nd,
          data: {
            ...nd.data,
            ...newObject,
            variables: nd.data?.variables?.length
              ? nd.data.variables?.map((item: any, index: number) => {
                  const targetKeys = Object.keys(combinedData?.[nd.id]);

                  if (targetKeys?.includes(item?.id)) {
                    return {
                      ...item,
                      value: combinedData?.[nd.id]?.[item.id]?.text,
                    };
                  }

                  return item;
                })
              : [],
          },
        };
      }
    }

    if (nd.type === 'fileSave') {
      if (combinedTextsKeys?.includes(nd?.id)) {
        const newObject: {
          [key: string]: any;
        } = {};

        let files = nd.data?.files || [];

        const newFilesMap = files.map((file: any) => {
          const targetKeys = Object.keys(combinedData?.[nd.id]);

          const newMappedKeys = targetKeys.map((key: string) => {
            if (key.includes('-name') || key?.includes('-file')) {
              return file.id;
            }
          });

          if (newMappedKeys.includes(file.id)) {
            const name =
              combinedData?.[nd.id]?.[file.id + '-name']?.text ||
              file?.fileName;
            const fileName =
              combinedData?.[nd.id]?.[file.id + '-file']?.text || file?.value;

            return {
              ...file,
              value: name,
              fileName: fileName,
            };
          }

          return file;
        });

        return {
          ...nd,
          data: {
            ...nd.data,
            ...newObject,
            files: nd.data?.files?.length ? newFilesMap : [],
          },
        };
      }
    }

    if (nd.type === 'fluxObject') {
      if (combinedTextsKeys?.includes(nd?.id)) {
        if (changeType === 'clear-entries' || nd?.data?.chat) {
          return nd;
        }

        if (changeType === 'execute') {
          const userSessionsChat = nd?.data?.userSessionsChat || [];
          const selectedChat = nd?.data?.selectedChat || 'default';
          let entries = nd.data.entries || [];

          const entryContent = combinedData?.[nd.id]?.['inputAdd']?.text;
          const entryExists = entries.some(
            (entry: any) => entry.content === entryContent,
          );
          const isEmptyEntry = entryContent?.includes('\n')
            ? entryContent?.replace(/\n/g, '')?.trim() === ''
            : false;

          if (!entryExists && entryContent && !isEmptyEntry) {
            entries.push({
              content: entryContent,
              timestamp: new Date().toISOString(),
              role: 'assistant',
            });
          }

          if (!entryExists && selectedChat === 'default') {
            return {
              ...nd,
              data: {
                ...nd.data,
                entries,
              },
            };
          }

          const findSession = userSessionsChat?.find(
            (session: any) => session?.id === selectedChat,
          );

          if (findSession?.id) {
            const sessionEntries = findSession.entries || [];
            const sessionEntryExists = sessionEntries.some(
              (entry: any) => entry.content === entryContent,
            );

            if (!sessionEntryExists && entryContent && !isEmptyEntry) {
              const newSessionEntries = [
                ...sessionEntries,
                {
                  content: entryContent,
                  timestamp: new Date().toISOString(),
                  role: 'assistant',
                },
              ];

              const newUserSessionsChat = userSessionsChat.map((session: any) =>
                session.id === selectedChat
                  ? { ...session, entries: newSessionEntries }
                  : session,
              );

              return {
                ...nd,
                data: {
                  ...nd.data,
                  userSessionsChat: newUserSessionsChat,
                },
              };
            }
          }
        }

        return nd;
      }
    }

    if (nd.type === 'fluxBox' || nd.type === 'nodesBox') {
      if (combinedTextsKeys?.includes?.(nd?.id)) {
        const targetKeys = Object.keys(combinedData[nd.id]);

        const newObject: {
          [key: string]: any;
        } = {};

        targetKeys.forEach((key: string) => {
          if (key) {
            newObject[key] = combinedData[nd.id][key].text;
          }
        });

        return {
          ...nd,
          data: {
            ...nd.data,
            ...newObject,
          },
        };
      }
    }

    if (check) {
      if (
        combinedTextsKeys?.includes(nd?.id) &&
        ndTypeAsKeyOfAllNodesTargetBasedOnType
      ) {
        const targetKeys = Object?.keys(combinedData[nd.id]);

        const getValuesFrom = Object.keys(
          ndTypeAsKeyOfAllNodesTargetBasedOnType,
        );

        const getAlsoValues = Object?.values(
          ndTypeAsKeyOfAllNodesTargetBasedOnType,
        );

        const keyMapping: any = getValuesFrom?.reduce(
          (acc: any, key, index) => {
            acc[key] = getAlsoValues?.[index];
            return acc;
          },
          {},
        );

        const newObject: {
          [key: string]: any;
        } = {};

        targetKeys.forEach((key: string) => {
          const mappedKey = keyMapping[key];

          if (mappedKey) {
            newObject[mappedKey + 'Data'] = combinedData[nd.id][key];
          }
        });

        // Check if any specific values in dynamic are missing in combinedTexts
        const commandTextNodeValues = Object.values(
          ndTypeAsKeyOfAllNodesTargetBasedOnType,
        );

        const settingsData = {
          modelSettings: {},
        };

        if (targetKeys.includes('modelSettings')) {
          settingsData.modelSettings = combinedData[nd.id]['modelSettings'];
        }

        if (targetKeys.includes('modelVoiceSettings')) {
          settingsData.modelSettings =
            combinedData[nd.id]['modelVoiceSettings'];
        }

        // commandTextNodeValues.forEach((value: any) => {
        //   if (!newObject[value + 'disabled']) {
        //     newObject[value] = nd.data[value]?.includes('undefined')
        //       ? ''
        //       : nd.data[value];
        //     newObject[value + 'disabled'] = false;
        //   }
        // });

        if (nd?.type === 'arrayNode') {
          const formData = {
            options: 'Paragraph',
            processorArray: [],
            customOperator: '',
            startingPoint: 1,
            endingPoint: 5,
            completed: 1,
            executingArray: 1,
            executing: 'default',
            auto: false,
            breakWords: '',
            preInput: '',
            postInput: '',
            processorArrayItemLength: 1,
            ...nd?.data,
            content: newObject?.['contentData']?.text,
          };

          const processorArray = processorArrayFc(formData);

          newObject['processorArray'] = processorArray;
        }

        if (nd.type === 'secApiNode') {
          if (newObject['queryData']) {
            newObject['url'] = newObject['queryData'];
          }
        }

        const newObjectKeys = Object.keys(newObject);

        let newData: any = {};

        newObjectKeys.forEach((key: string) => {
          if (key) {
            const findData = nd?.data?.[key];

            if (!newData[key]) {
              newData[key] = findData;
            }

            if (
              key.includes(
                nodePassOnlyText[ndType as keyof typeof nodePassOnlyText],
              )
            ) {
              newData[key.replace('Data', '')] = newObject[key]?.text;
              return;
            }

            if (key === 'processorArray') {
              newData[key] = newObject[key];
              return;
            }

            if (findData?.length) {
              const mappedData = findData.map(
                (
                  item: {
                    id: string;
                    text: string;
                  },
                  index: number,
                ) => {
                  if (newObject[key]?.id === item?.id) {
                    return {
                      ...item,
                      text: newObject[key]?.text,
                    };
                  }
                  return item;
                },
              );

              newData[key] = mappedData;
            }
          }
        });

        if (
          onlyInputPreview?.[ndType as keyof typeof onlyInputPreview] &&
          combinedData?.[nd.id]?.inputPreview?.text
        ) {
          newData['inputPreview'] = combinedData[nd.id]?.inputPreview?.text;
        }

        return {
          ...nd,
          data: {
            ...nd.data,
            ...newData,
            ...settingsData?.modelSettings,
          },
        };
      }
    }
    return nd;
  }));
};

export const onlyInputPreview = {
  imageReaderNode: 'inputPreview',
  audioReaderNode: 'inputPreview',
  documentSummarizer: 'inputPreview',
  imageGenerator: 'inputPreview',
};

export const nodePassOnlyText = {
  imageReaderNode: 'inputPreview',
  audioReaderNode: 'inputPreview',
  documentSummarizer: 'inputPreview',
  arrayNode: 'content',
  imageGenerator: 'inputPreview',
  mathFunction: 'varId',
  fluxBox: 'varId',
  fileSave: 'varId',
};

export const nodePassOnlyTextRealHandleId = {
  imageReaderNode: 'inputPreview',
  audioReaderNode: 'inputPreview',
  documentSummarizer: 'inputPreview',
  arrayNode: 'content',
  imageGenerator: 'inputPreview',
  mathFunction: 'varId',
  fluxBox: 'varId',
  fileSave: 'varId',
};

export {
  updateNodeData,
  changeLabel,
  updateEdgeData,
  deleteEdgesData,
  connect,
  handleChanges,
};
