import { Box, FormLabel, MenuItem, Select, Switch } from '@mui/material';
import { FormData } from './constants';
import KitRanger from '../UI/KitRanger';

type Props = {
  formData: FormData;
  onChange: (e: any) => void;
};

const Fal = ({ formData, onChange }: Props) => {
  const falModels = ['pro', 'dev', 'schnell'];
  const falImageSizeTypes = [
    'square_hd',
    'square',
    'portrait_4_3',
    'portrait_16_9',
    'landscape_16_9',
    'landscape_4_3',
  ];
  return (
    <>
      <Box>
        <Box>
          <FormLabel htmlFor="falModel">Fal models</FormLabel>
          <Select
            name="falModel"
            className="nodrag"
            fullWidth
            value={formData.falModel}
            onChange={onChange}
          >
            <MenuItem disabled value="noValue">
              {'Choose an Engine'}
            </MenuItem>
            {falModels?.map((model, index) => {
              return (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box>
          <FormLabel htmlFor="imageSize">Image size</FormLabel>
          <Select
            name="imageSize"
            className="nodrag"
            fullWidth
            value={formData.imageSize}
            onChange={onChange}
          >
            <MenuItem disabled value="noValue">
              {'Choose an Image Size'}
            </MenuItem>
            {falImageSizeTypes?.map((model, index) => {
              return (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <KitRanger
          value={formData.inference_steps}
          onChange={onChange}
          name="inference_steps"
          label="Number of inference steps"
          min={0}
          max={50}
          step={1}
        />
        <KitRanger
          value={formData.fal_seed}
          onChange={onChange}
          name="fal_seed"
          label="Seed"
          min={0}
          max={50}
          step={1}
        />
        <KitRanger
          value={formData.guidance_scalefloat}
          onChange={onChange}
          name="guidance_scalefloat"
          label="Classifier Free Guidance"
          min={0}
          max={50}
          step={1}
        />
        <Box style={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
          <FormLabel htmlFor="sync_mode">Sync Mode </FormLabel>
          <Switch
            name="sync_mode"
            id="sync_mode"
            onChange={onChange}
            value={formData.sync_mode}
          />
        </Box>

        <KitRanger
          value={formData.safety_tolerance}
          onChange={onChange}
          name="safety_tolerance"
          label="Safety Tolerance"
          min={1}
          max={6}
          step={1}
        />
      </Box>
    </>
  );
};

export default Fal;
