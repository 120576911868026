import { Box, IconButton, Popover, Typography } from '@mui/material';
import { CloudArrowUp, CloudCheck, CloudSlash } from '@phosphor-icons/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useFlowsStore from '../../store/storeFlows';

const SavingStatus = () => {
  const saving = useFlowsStore(state => state.saving);
  const [status, setStatus] = useState({
    isOnline: true,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const statusInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (saving.finished && saving.status === 'saved') {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    const handleOnline = () => setStatus(prev => ({ ...prev, isOnline: true }));
    const handleOffline = () =>
      setStatus(prev => ({ ...prev, isOnline: false }));

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    setStatus(prev => ({ ...prev, ...saving }));

    if (!saving.finished && saving.status === 'error') {
      alert('Error saving flow. Please refresh the page.');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [saving]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-37px',
        right: '50px',
        zIndex: '99',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <Typography color="#9e9e9e" fontSize={12}>
        {!saving.finished && saving.status === 'loading'
          ? 'Your flow is being saved...'
          : !saving?.finished && saving.status === 'error'
          ? 'Error saving flow. Please refresh the page.'
          : !status.isOnline && "You're offline. Flow isn't saving."}
      </Typography>
      <IconButton title="Auto" onMouseOver={statusInfo}>
        {!saving.finished && saving.status === 'loading' ? (
          <CloudArrowUp size={20} />
        ) : status.isOnline && saving.status === 'saved' ? (
          <CloudCheck size={20} color="#3650DE" />
        ) : (
          <CloudSlash size={20} />
        )}
      </IconButton>
      <Popover
        open={open}
        id={id}
        onClose={handleClose}
        onMouseLeave={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          borderRadius: '12px',
        }}
      >
        <div
          style={{
            padding: '10px',
            border: '1px solid #bdbdbd',
            borderRadius: '4px',
          }}
        >
          <Typography color="#9e9e9e" fontSize={12}>
            Last saved on:{' '}
            {saving.lastSaved && saving.status === 'saved'
              ? moment(saving.lastSaved).format('LT') +
                ' ' +
                moment(saving.lastSaved).format('L')
              : 'Your flow is being saved...'}
          </Typography>
        </div>
      </Popover>
    </Box>
  );
};

export default SavingStatus;
