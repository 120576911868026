import { memo, useState, useEffect, useRef } from 'react';
import { useEdges } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { NodeProps } from '../../util/Types/NodeProps';
import TagInput from '../Test/TagInput';

import useFlowsStore from '../../store/storeFlows';
import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import axios, { AxiosResponse } from 'axios';
import prettyBytes from 'pretty-bytes';
import { isActiveEdge } from '../../util/findActiveHandle';
import Label from '../UI/Label/Label';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { PlusCircle, TrashSimple } from '@phosphor-icons/react';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { checkExecuteTrigger } from '../../util/checkExecute';

export const sourceTypes = {
  host: 'host',
  port: 'port',
  database: 'database',
  username: 'username',
  password: 'password',
  connectionString: 'connectionString',
  collection: 'collection',
  filter: 'filter',
  query: 'query',
};

function SqlQuerier({ id, data, isConnectable, type, selected }: NodeProps) {
  const edges: any = useEdges();
  const [isLoading, setIsLoading] = useState(false);
  const [previewResponses, setPreviewResponses] = useState([]);

  const [formData, setFormData] = useState<{
    host: string;
    port: string;
    database: string;
    username: string;
    password: string;
    dbms: string;
    query: string;
    connectionString: string;
    collection: string;
    filter: string;
    response: AxiosResponse | null | any;
    text: string;
  }>({
    host: '',
    port: '',
    database: '',
    username: '',
    password: '',
    dbms: '',
    query: '',
    connectionString: '',
    collection: '',
    filter: '',
    response: null,
    text: '',
  });

  const setNotification = useNotificationStore(state => state.setNotification);

  const [hasError, setHasError] = useState(false);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [hasToExecute, setHasToExecute] = useState(true);

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const { setNodeState, setSchema, setSaving, flowId } = useFlowsStore(
    state => state,
  );

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setSaving,
          setNodeState,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const onSave = (
    object: { [key: string]: any },
    changeType: string = 'default',
  ) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowTriggerData: checkExecuteTrigger(data, id),
      changeType,
      flowId,
      objectData: {
        ...object,
      },
      setSchema,
    });
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (request: any) => {
        request.customData = request.customData || {};
        request.customData.startTime = new Date().getTime();
        return request;
      },
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => {
        response = updateEndTime(response);
        return response;
      },
      error => {
        if (error.response) {
          error.response = updateEndTime(error.response);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const updateEndTime = (response: AxiosResponse | any) => {
    const endTime = new Date().getTime();
    response.config.customData = response.config.customData || {};
    response.config.customData.time =
      endTime - response.config.customData.startTime;
    response.config.customData.size = prettyBytes(
      JSON.stringify(response.data).length,
      {
        bits: true,
      },
    );

    return response;
  };

  const handleSendRequest = async () => {
    const {
      host,
      port,
      database,
      username,
      password,
      dbms,
      query,
      connectionString,
      collection,
      filter,
    } = formData;

    if (!dbms) {
      setNotification({
        message: 'Please pick a DBMS',
        type: 'error',
      });
      return;
    } else if (
      (dbms === 'MySQL' || 'PostgreSQL') &&
      (!host || !database || !username || !port || !password || !dbms || !query)
    ) {
      setNotification({
        message: 'All fields are required',
        type: 'error',
      });
      return;
    } else if (
      dbms === 'MongoDB' &&
      (!connectionString || !database || !collection || !filter)
    ) {
      setNotification({
        message: 'All fields are required',
        type: 'error',
      });
      return;
    }

    var body = {};
    var endpoint = '';

    if (dbms === 'MySQL' || 'PostgreSQL') {
      body = {
        host,
        port: parseInt(port),
        username,
        password,
        database,
        query,
      };
      if (dbms === 'MySQL') {
        endpoint = '/database/mysql';
      } else if (dbms === 'PostgreSQL') {
        endpoint = '/database/postgres';
      }
    } else if (dbms === 'MongoDB') {
      body = { connectionString, database, collection, filter };
      endpoint = '/database/mongo';
    }

    setIsLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        },
      );

      const endTimeData = updateEndTime(res);

      onSave(
        {
          response: endTimeData,
          text: JSON.stringify(endTimeData.data, null, 2),
        },
        'execute',
      );

      setFormData({
        ...formData,
        response: endTimeData,
        text: JSON.stringify(endTimeData.data, null, 2),
      });
      setHasError(false);
    } catch (error: any) {
      setFormData({
        ...formData,
        response: error?.response,
        text: JSON.stringify(error?.response, null, 2),
      });
      onSave(
        {
          text: JSON.stringify(error?.response, null, 2),
        },
        'execute',
      );
      setHasError(true);
    } finally {
      setHasToExecute(true);
      setIsLoading(false);
    }
  };

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          handleSendRequest();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useEffect(() => {
    let defaultValues = {
      host: data?.host || '',
      port: data?.port || '',
      database: data?.database || '',
      username: data?.username || '',
      password: data?.password || '',
      dbms: data?.dbms || '',
      query: data?.query || '',
      connectionString: data?.connectionString || '',
      collection: data?.collection || '',
      filter: data?.filter || '',
      response: data.response || null,
      text: data.text || '',
    };

    setFormData(defaultValues);
  }, [data]);

  const onChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setIsChangingDirectly(true);

    setWhoIsChanging({ name: e.target.name, value: e.target.value });
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const renderDatabaseInputs = () => {
    switch (formData.dbms) {
      case 'MySQL':
      case 'PostgreSQL':
        return (
          <>
            <TagInput
              name="host"
              placeholder="https://example.com"
              labelName={'Host'}
              handleId={'host'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'host', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.hostData || []}
              placement={data?.placement}
              value={formData.host}
            />

            <TagInput
              name="port"
              placeholder={formData.dbms === 'MySQL' ? '3306' : '5432'}
              labelName={'Port'}
              handleId={'port'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'port', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.portData || []}
              placement={data?.placement}
              value={formData.port}
            />

            <TagInput
              name="database"
              placeholder="database-name"
              labelName={'Database Name'}
              handleId={'database'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'database', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.databaseData || []}
              placement={data?.placement}
              value={formData.database}
            />

            <TagInput
              name="username"
              placeholder="database-user"
              labelName={'Username'}
              handleId={'username'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'username', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.usernameData || []}
              placement={data?.placement}
              value={formData.username}
            />

            <TagInput
              name="password"
              placeholder="password"
              labelName={'Password'}
              handleId={'password'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'password', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.passwordData || []}
              placement={data?.placement}
              value={formData.password}
            />

            <TagInput
              name="query"
              placeholder="SELECT * FROM users"
              labelName={'Query'}
              handleId={'query'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'query', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              height={'100px'}
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.queryData || []}
              placement={data?.placement}
              value={formData.query}
            />
          </>
        );
      case 'MongoDB':
        return (
          <>
            <TagInput
              name="connectionString"
              placeholder="Put your connection string here"
              labelName={'Connection String'}
              handleId={'connectionString'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'connectionString', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.connectionStringData || []}
              placement={data?.placement}
              value={formData.connectionString}
            />

            <TagInput
              name="database"
              placeholder="database-name"
              labelName={'Database Name'}
              handleId={'database'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'database', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.databaseData || []}
              placement={data?.placement}
              value={formData.database}
            />

            <TagInput
              name="collection"
              placeholder="your_collection"
              labelName={'Collection'}
              handleId={'collection'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'collection', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.collectionData || []}
              placement={data?.placement}
              value={formData.collection}
            />

            <TagInput
              name="filter"
              placeholder='{"field": "value"}'
              labelName={'Filter'}
              handleId={'filter'}
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'filter', 'target')}
              isAdvanced={true}
              findColor={findColor}
              removeIcons
              height={'100px'}
              className="nodrag"
              advancedCollapsed={true}
              onChange={onChange}
              dataConnected={data?.filterData || []}
              placement={data?.placement}
              value={formData.filter}
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          id={id}
          edges={edges}
          type={type}
          handleRun={handleRun}
          data={data}
          isLoading={isLoading}
          btnText="Send Query"
          onSubmit={handleSendRequest}
          showTokensUsed={false}
        >
          <Box display={'flex'}>
            <Box p={'16px'} borderRight={'1px solid #EAECF0'}>
              <Box>
                <Label isAdvanced labelName="Select DBMS " />
                <Select
                  value={formData.dbms}
                  name="dbms"
                  onChange={onChange}
                  sx={{
                    bgcolor: '#EAECF0',
                    borderRadius: '8px',
                    border: 'none',
                    height: '30px',
                    textTransform: 'capitalize',
                    mb: '16px',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  className="nodrag"
                >
                  <MenuItem value="MySQL">MySQL</MenuItem>
                  <MenuItem value="PostgreSQL">PostgreSQL</MenuItem>
                  <MenuItem value="MongoDB">MongoDB</MenuItem>
                </Select>
                {renderDatabaseInputs()}

                {/*  */}
              </Box>
              <Box mt={'16px'} mb={'16px'}>
                {hasError && (
                  <FormHelperText
                    // error
                    sx={{
                      color: 'red',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    An error occurred while executing the query.
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box p={'16px 24px 16px 16px'}>
              <OutputTextarea
                // previewResponses={previewResponses}
                previewIndex={data.previewIndex}
                value={data.text}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                placement={data?.placement}
                labelName={'Output'}
                nodeLabel={data?.label}
                // onPreview={onPreview}
                // findColor={findColor}
                // onChangePlacement={onChangePlacement}
              />
              {/* {formData.response && (
                <Box className='mt-5' data-response-section>
                  <Typography variant='h6'>Response status</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                    className='d-flex my-2'
                  >
                  </Box>
                </Box>
              )} */}
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
}

export default memo(SqlQuerier);
