import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
import { EntityProperties } from '../components/HubSpotDataNode/PullHubSpotDataNode';
// import { User } from '../Utilis/Types/User';

type HubSpotParams = { limit: number; properties: string[], query?: string, sortBy?: string, sortOrder?: string, dealstatus?: string };

export class HubSpotService extends ApiService {
  static pathName = '/hubspot';

  static getHubSpotInstallUrl = (): Promise<{ installUrl: string }> => {
    return this.get(`${this.pathName}`);
  };

  static getHubSpotAccessToken = (
    code: string
  ): Promise<{
    tokens: {
      access_token: string;
      refresh_token: string;
      expires_in: number;
    }
  }> => {
    return this.get(`${this.pathName}/oauth-callback?code=${encodeURIComponent(code)}`);
  };

  static getHubSpotUserInfo = (): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/access-tokens`);
  };

  static refreshHubSpotToken = (): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/refresh-token`);
  };

  static getHubSpotEntityProperties = (entity: string): Promise<{ results: Array<EntityProperties> }> => {
    return this.get(`${this.pathName}/properties?entity=${entity}`);
  };

  static getHubSpotDeals = (
    params: HubSpotParams
  ): Promise<IGetOneResponse<any>> => {
    Object.keys(params).forEach(key => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });

    const { properties, ...rest } = params;
    const queryParams = new URLSearchParams(rest as any);
    properties.forEach(property => queryParams.append('properties[]', property));

    return this.get(`${this.pathName}/deals?${queryParams.toString()}`);
  };

  static getHubSpotDealById = (dealId: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/deals/${dealId}`);
  };

  static getHubSpotContacts = (
    params: HubSpotParams
  ): Promise<IGetOneResponse<any>> => {
    Object.keys(params).forEach(key => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });

    const { properties, ...rest } = params;
    const queryParams = new URLSearchParams(rest as any);
    properties.forEach(property => queryParams.append('properties[]', property));

    return this.get(`${this.pathName}/contacts?${queryParams}`);
  };

  static getHubSpotContactById = (contactId: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/contacts/${contactId}`);
  };

  static getHubSpotCompanies = (
    params: HubSpotParams
  ): Promise<IGetOneResponse<any>> => {
    Object.keys(params).forEach(key => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });

    const { properties, ...rest } = params;
    const queryParams = new URLSearchParams(rest as any);
    properties.forEach(property => queryParams.append('properties[]', property));
    
    return this.get(`${this.pathName}/companies?${queryParams}`);
  };

  static getHubSpotCompanyById = (companyId: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/companies/${companyId}`);
  };
}

