import React from 'react';
import './SelectedPlan.css';
import Pricing from '../../components/settings/Pricing/Pricing';
import { Button } from '@mui/material';
import { Screen, SelectedPlan } from './Welcome';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';

interface Props {
  goBack: () => void;
  setScreen: (screen: Screen) => void;
  setSelectedPlan: (plan: SelectedPlan) => void;
}

const Subscription: React.FC<Props> = ({
  goBack,
  setScreen,
  setSelectedPlan,
}) => {
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const setNotification = useNotificationStore(state => state.setNotification);

  const chooseIntroPlanHandler = () => {
    setScreen(Screen.SelectedPlan);
    setSelectedPlan(SelectedPlan.Introduction);
  };

  const welcomePageCallback = async () => {
    try {
      setScreen(Screen.Congrats);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  return (
    <div className={'subscription-plan-container'}>
      <p className={'description'}>
        How do you want to join the Ai revolution?
      </p>

      <Pricing welcomePageCallback={welcomePageCallback} />

      <div className={'buttons-container'}>
        <Button onClick={goBack}>Go Back</Button>
        <div className={'actions-container'}>
          <Button variant='outlined' onClick={chooseIntroPlanHandler}>
            Choose intro plan instead
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
