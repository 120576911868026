import {
    Box, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton, SxProps,
    Theme
} from '@mui/material';
import { X } from '@phosphor-icons/react';

type Props = {
    onHide: () => void;
    show: boolean;
    children: React.ReactNode;
    title?: string;
    footer?: React.ReactNode;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    sx?: SxProps<Theme> | undefined;
    showCloseButton?: boolean;
  };

const PaywallModal = ({
    onHide,
    show,
    children,
    title,
    footer,
    sx,
    showCloseButton = true,
  }: Props) => {

    return (
      <Dialog
        onClose={onHide}
        open={show}
        sx={{
          ...sx,
          borderRadius: "8px",
          border: "1px solid #7388F7",
          color: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {title && (
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {title}
            </DialogTitle>
          )}
          {showCloseButton && (
            <IconButton
              aria-label="close"
              size="medium"
              onClick={onHide}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <X size={24} color="#343330" />
            </IconButton>
          )}
          <DialogContent>{children}</DialogContent>
          {footer && (
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "24px",
              }}
            >
              {footer}
            </DialogActions>
          )}
        </Box>
      </Dialog>
    );
}

export default PaywallModal;