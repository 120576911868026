import React, { useEffect } from 'react';
import './PremiumModalUpgrade.css';
import KitModal from '../UI/KitModal';
import ProTemplateIcon from '../../assets/icons/pro-template.png';
import { Button } from '@mui/material';
import { ProductService } from '../../service/ProductService';
import { Product } from '../../util/Types/Product';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';

interface Props {
  onClose: () => void;
  showPricingHandler: () => void;

  setProfessionalPlan: React.Dispatch<
    React.SetStateAction<Product | undefined>
  >;
  setShowProfessionalPlan: React.Dispatch<React.SetStateAction<boolean>>;
}

const professionalDefaultTitleAndDescription = {
  index: 2,
  name: 'Professional',
  title:
    'Empowering small teams to operationalize Ai capabilities with cross-internet calls and collaboration tools.',
  price: null,
  activePlan: false,
  discounts: ['$0.0015 (50% discount)'],
  tokensText: '10,000 free monthly test Ai Tokens',
  descriptions: [
    'Full Advanced Features, 100+ 3rd Party Integrations, and dedicated Team Professional Services.',
    'Collaborate with up to 3 users and enjoy access to 3 dedicated spaces for seamless teamwork.',
  ],
};

const PremiumModalUpgrade: React.FC<Props> = ({
  onClose,
  showPricingHandler,
  setProfessionalPlan,
  setShowProfessionalPlan,
}) => {
  const setNotifications = useNotificationStore(state => state.setNotification);

  useEffect(() => {
    const getProfessionalPlan = async () => {
      try {
        const { data: products } = await ProductService.getProducts();
        const professionalPlan = products.find(
          product => product.name === 'Professional'
        );
        setProfessionalPlan({
          ...professionalDefaultTitleAndDescription,
          ...professionalPlan,
        } as Product);
      } catch (error) {
        setNotifications({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    getProfessionalPlan();
  }, []);

  return (
    <KitModal show={true} onHide={onClose}>
      <div className="premium-modal-upgrade-container">
        <img src={ProTemplateIcon} alt="Pro" />

        <h3 className="pro-template-text">Pro Template</h3>
        <p className="template-pro-text-description">
          Upgrade your account to access our exclusive collection of pro
          templates.
        </p>

        <div className="premium-actions-container">
          <Button
            type="button"
            variant="outlined"
            onClick={showPricingHandler}
            sx={{ flex: 1 }}
          >
            See All Plans
          </Button>

          {/* <Button
            type="button"
            variant="contained"
            onClick={() => setShowProfessionalPlan(true)}
          >
            Upgrade Now
          </Button> */}
        </div>
      </div>
    </KitModal>
  );
};

export default PremiumModalUpgrade;
