import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from 'react-hook-form';
import KitInput from '../../UI/KitInput';
import { additionalOptionsArray, anthropicOptions, systemDisplayNameMap, systemValueMap } from '../../AiConfigs/constants';

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[]
}
const AnthropicConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems,
}: Props) => {
  return (
    <Box>
      {/* <KitInput
        label='Name'
        name='name'
        type='text'
        errorsStatus={errors}
        register={register}
      /> */}
      <Box
        mt={'16px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" title="Ai System">
            Ai System
          </InputLabel>
          <Select
            name="AiSystem"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            onChange={e => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem
                  key={index}
                  value={systemValue}
                  disabled={disabled}
                >
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Model</InputLabel>
          <Select
            value={watch().anthropicModel}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Model"
            {...register('anthropicModel')}
            sx={{
              width: '100%',
            }}
          >
            {systems.length > 0
              ? systems
                  .filter((item: any) => item.system === 'anthropic')[0]
                  .models?.map((opt: any) => (
                    <MenuItem
                      key={opt.id}
                      value={opt.model}
                      disabled={opt.disabledByCompany}
                    >
                      {opt.model}
                    </MenuItem>
                  ))
              : anthropicOptions.map(opt => (
                  <MenuItem key={opt} value={opt}>
                    {opt}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginY: '16px',
        }}
      />

      <KitInput
        label="Max Tokens"
        name="maxTokens"
        type="number"
        errorsStatus={errors}
        register={register}
        // sxStyle={{
        //   mt: '16px',
        // }}
      />
    </Box>
  );
};

export default AnthropicConfigForm;
