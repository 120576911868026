import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { NodeResizer, useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { ReplicateService } from '../../service/ReplicateService';
import { Box, Divider, Typography } from '@mui/material';
import TagInput from '../Test/TagInput';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { checkExecuteTrigger } from '../../util/checkExecute';

import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import SettingsModal from '../AiMusicGenerator/SettingsModal/SettingsModal';
import { CaretDown, Faders } from '@phosphor-icons/react';
import OutputAudio from '../UI/OutputAudio/OutputAudio';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { AiMusicGeneratorFormValues } from '../AiMusicGenerator/constants';
import { executeErrorHandle } from '../../util/executeErrorHandle';

function AiMusicGenerator({ data, id, type }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();
  const {
    schema,
    socketNodeResponse,
    flowId,
    setSocketNodeResponse,
    setSchema,
    setSaving,
  } = useFlowsStore(state => state);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const edges = useEdges();
  const [previewResponses, setPreviewResponses] = useState([]);
  const setNotification = useNotificationStore(state => state.setNotification);
  const { user } = useAuthStore(state => state);

  const [formData, setFormData] = useState<AiMusicGeneratorFormValues>({
    model_version: '',
    prompt: '',
    duration: 0,
    normalization_strategy: '',
    top_k: 0,
    top_p: 0,
    temperature: 0,
    classifier_free_guidance: 0,
    outputFormat: 'string',
    seed: 0,
    audioUrl: '',
  });

  const debounceTimeoutRef = useRef<any>(null);

  const [hasToExecute, setHasToExecute] = useState(false);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          model_version: data.model_version ?? 'large',
          prompt: data.prompt ?? '',
          duration: data.duration ?? 30,
          normalization_strategy: data.normalization_strategy ?? 'peak',
          top_k: data.top_k ?? 250,
          top_p: data.top_p ?? 0,
          temperature: data.temperature ?? 1,
          classifier_free_guidance: data.classifier_free_guidance ?? 3,
          outputFormat: data.outputFormat ?? 'mp3',
          seed: data.seed ?? -1,
          audioUrl: data.text ?? '',
        });
        if (data?.previewResponses?.length > 0) {
          setPreviewResponses(data.previewResponses);
        }
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      let prompt = formData.prompt;

      if (data?.promptData?.length > 0) {
        prompt = data.promptData.map((item: any) => item.text).join(' \n');
      }

      const res = await ReplicateService.generateMusic({
        model_version: formData.model_version,
        prompt: replacePlaceholders(prompt, schema),
        duration: +formData.duration,
        normalization_strategy: formData.normalization_strategy,
        top_k: +formData.top_k,
        top_p: +formData.top_p,
        temperature: +formData.temperature,
        classifier_free_guidance: +formData.classifier_free_guidance,
        outputFormat: formData.outputFormat,
        seed: +formData.seed,
        flowId,
      });

      setSocketNodeResponse(id, null);
      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );

      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        setSaving,
        id,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
        flowId,
        objectData: {
          text: data.text,
          previewResponses: data?.previewResponses,
          previewIndex: data?.previewIndex,
        },
        setSchema,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);

      setIsLoading(false);
      setSocketNodeResponse(null, null);
    }
  }, [
    socketNodeResponse?.nodeId,
    socketNodeResponse?.finished,
    data.text,
    data?.previewResponses,
    data?.previewIndex,
    data?.flowTrigger,
  ]);

  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));

    setWhoIsChanging({
      name: evt.target.name,
      value: evt.target.value,
    });
    setIsChangingDirectly(true);
  }, []);

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            setSaving,
            changeType: 'changingPreviewIndex',
            flowId,
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };
  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        type={type}
        btnText="Run Prompt"
        id={id}
        isLoading={isLoading}
        data={data}
        edges={edges}
        onSubmit={onSubmit}
        handleRun={handleRun}
        onChangePlacement={onChangePlacement}
      >
        <NodeResizer isVisible={false} />

        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
          >
            <TagInput
              name="prompt"
              handleId={'music-gen-input'}
              labelName="Prompt"
              isCollapsed={true}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'music-gen-input', 'target')}
              findColor={findColor}
              dataConnected={data?.promptData || []}
              placement={data?.placement}
              onChangePlacement={onChangePlacement}
              value={formData.prompt}
              onChange={onChange}
              nodeLabel={data?.label}
              placeholder="Enter the type, tone, and style of music that you want."
            />
            {data?.placement !== 'Output' && (
              <>
                <Divider
                  sx={{
                    // mt: !collapsed ? '20px' : '200px',
                    mt: '64px',
                    mb: '10px',
                  }}
                />
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                    borderRadius={'4px'}
                    sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                    onClick={() => setSettingsModalOpen(true)}
                  >
                    <Faders size={18} color="#667085" />
                    <Typography fontSize={'12px'} color="#667085" variant="h6">
                      {'Settings'}
                    </Typography>

                    <CaretDown size={'16px'} color="#667085" />
                  </Box>
                </Box>
              </>
            )}
            <SettingsModal
              onHide={() => setSettingsModalOpen(false)}
              show={settingsModalOpen}
              formData={formData}
              onChange={onChange}
            />
          </Box>
          <Box p={'16px 24px 16px 16px'}>
            <OutputAudio
              previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data?.text}
              activeHandle={isActiveEdge(
                edges,
                id,
                'music-gen-output',
                'source',
              )}
              placement={data?.placement}
              labelName={'Output'}
              handleId={'music-gen-output'}
              onPreview={onPreview}
              findColor={findColor}
              onChangePlacement={onChangePlacement}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
}

export default memo(AiMusicGenerator);
