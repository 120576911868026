import {
  AiTextGeneratorFormValues,
  geminiFileds,
} from '../AiConfigs/constants';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { PrettoSlider } from '../../assets/styles/styles';
import { useAuthStore } from '../../store/storeAuth';
import { useState } from 'react';

type Props = {
  watch: AiTextGeneratorFormValues;
  onChange: (event: any) => void;
  imageSystem?: boolean;
  systems?: any[];
};

const modelInputBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '4px',
  mt: '8px',
};

const Gemini = ({ systems, onChange, watch, imageSystem = false }: Props) => {
  const { user } = useAuthStore(state => state);
  const [isAutoSetting, setIsAutoSetting] = useState(watch.autoToken);
  return (
    <>
      {!imageSystem ? (
        <Box>
          <FormLabel
            sx={{
              mb: '4px',
              color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
            }}
            htmlFor="geminiModel"
          >
            Type
          </FormLabel>
          <Select
            className="nodrag"
            value={watch.geminiModel}
            size="small"
            onChange={onChange}
            name={'geminiModel'}
            sx={{
              '& .MuiList-root': {
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
              bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              border: `2px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`,
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .Mui-selected': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                  },
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
              },
            }}
          >
            {systems
              ?.filter((item: any) => item.system === 'gemini')[0]
              .models?.map((opt: any) => (
                <MenuItem
                  key={opt.id}
                  value={opt.model}
                  disabled={opt.disabledByCompany}
                >
                  <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                    <Typography
                      variant="body2"
                      color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                    >
                      {opt.model}
                    </Typography>
                    {opt.disabledByCompany && (
                      <Typography fontSize={'12px'} color="red">
                        (disabled by admin)
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              ))}
          </Select>
        </Box>
      ) : (
        <Select
          className="nodrag"
          value={watch.geminiModel}
          size="small"
          onChange={onChange}
          name={'geminiModel'}
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            border: `2px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        >
          {systems
            ?.filter((item: any) => item.system === 'gemini')[0]
            .models?.map((opt: any) => (
              <MenuItem
                key={opt.id}
                value={opt.model}
                disabled={opt.disabledByCompany}
              >
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <Typography
                    variant="body2"
                    color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                  >
                    {opt.model}
                  </Typography>
                  {opt.disabledByCompany && (
                    <Typography fontSize={'12px'} color="red">
                      (disabled by admin)
                    </Typography>
                  )}
                </Box>
              </MenuItem>
            ))}
        </Select>
      )}
      {geminiFileds.map(opt => {
        const optName = opt.name;
        if (opt.name === 'maxOutputTokens') {
          return (
            <Box
              sx={{
                ...modelInputBoxStyle,
                alignItems: 'center',
                mt: '20px',
              }}
              key={optName}
            >
              <FormControlLabel
                control={
                  <Switch
                    onChange={e => {
                      onChange({
                        target: {
                          name: 'autoToken',
                          value: !watch.autoToken,
                        },
                      });

                      setIsAutoSetting(!watch.autoToken);
                    }}
                    name="autoToken"
                    checked={watch.autoToken}
                    sx={{
                      margin: 0,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  />
                }
                label="Auto setting"
              />
              <FormControl>
                <TextField
                  id="demo-simple-select"
                  type={opt.type}
                  onChange={onChange}
                  value={isAutoSetting ? 0 : watch.aiTokens}
                  disabled={isAutoSetting}
                  className="nodrag"
                  label={'Output Token Max'}
                  name={opt.name}
                  InputProps={{
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  sx={{
                    width: '290px',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </FormControl>
            </Box>
          );
        }
        return (
          <Box key={opt.name} style={modelInputBoxStyle}>
            <FormLabel
              htmlFor="tokens"
              sx={{
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
            >
              {opt.label}
            </FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              {opt.name !== 'maxOutputTokens' && (
                <PrettoSlider
                  step={Number(opt.step)}
                  min={Number(opt.min)}
                  name={opt.name}
                  value={Number(
                    watch[optName as keyof AiTextGeneratorFormValues],
                  )}
                  max={Number(opt.max)}
                  style={{ marginLeft: '8px', width: '350px' }}
                  onChange={e => {
                    onChange(e);
                  }}
                  className="nodrag"
                />
              )}

              <TextField
                type={'number'}
                value={watch[optName as keyof AiTextGeneratorFormValues]}
                name={opt.name}
                onChange={e => {
                  onChange(e);
                }}
                InputProps={{
                  inputProps: {
                    min: opt.min,
                    max: opt.max,
                    step: opt.step,
                  },
                  style: {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                }}
                style={{ marginLeft: '8px', width: '80px' }}
                className="nodrag"
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default Gemini;
