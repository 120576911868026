import React, { useEffect, useState } from 'react';
import { ScheduleService } from '../../service/ScheduleService';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import KitModal from '../UI/KitModal';
import useFlowsStore from '../../store/storeFlows';
import { generateCustomID } from '../../util/generateCustomId';
import { useSocketStore } from '../../store/storeSocket';
import { useNotificationStore } from '../../store/storeNotifications';
import { useAuthStore } from '../../store/storeAuth';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const intervalTypes = ['hourly', 'daily', 'weekly', 'monthly'];
const intervalWeekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const AutoTriggerTimerModal = ({
  isAutoTriggerModalOpen,
  setIsAutoTriggerModalOpen,
  formData,
  setFormData,
  data,
  intervalData,
  setIntervalData,
  id,
  saveNode,
}: any) => {
  const { flowId } = useFlowsStore(state => state);
  const setNotification = useNotificationStore(state => state.setNotification);
  const { user } = useAuthStore(state => state);

  const { setProcessingFlows, setDeleteProcessingFlow } = useSocketStore(
    state => state,
  );
  const [schedule, setSchedule] = useState<any>({});

  useEffect(() => {
    const getSchedule = async () => {
      try {
        const res: any = await ScheduleService.getSchedules();
        const flowSchedule = res?.data?.find(
          (schedule: { flowId: string }) => schedule.flowId === flowId,
        );
        if (flowSchedule?.flowId === flowId && formData.isAutotrigger) {
          setProcessingFlows(flowId);
        }
        setSchedule(flowSchedule);
      } catch (error) {}
    };

    if (formData.isAutotrigger && flowId && isAutoTriggerModalOpen) {
      getSchedule();
    }
  }, [formData.isAutotrigger, isAutoTriggerModalOpen, flowId]);

  const onChange = (e: { target: { value: string; name: string } }) => {
    if (e.target.name === 'monthly') {
      setIntervalData(
        (prev: { hourly: any; daily: any; monthly: any; weekly: any }) => ({
          ...prev,
          [e.target.name]: e.target.value,
          codedTime: `${
            e.target.name === 'hourly' ? e.target.value : prev.hourly
          } ${e.target.name === 'daily' ? e.target.value : prev.daily} ${
            e.target.name === 'monthly' ? e.target.value : prev.monthly
          } * ${e.target.name === 'weekly' ? e.target.value : prev.weekly}`,
        }),
      );
    } else if (e.target.name === 'weekly') {
      setIntervalData(
        (prev: { hourly: any; daily: any; monthly: any; weekly: any }) => ({
          ...prev,
          [e.target.name]: e.target.value,
          codedTime: `${
            e.target.name === 'hourly' ? e.target.value : prev.hourly
          } ${e.target.name === 'daily' ? e.target.value : prev.daily} * * ${
            e.target.name === 'weekly' ? e.target.value : prev.weekly
          }`,
        }),
      );
    } else if (e.target.name === 'daily') {
      setIntervalData(
        (prev: { hourly: any; daily: any; monthly: any; weekly: any }) => ({
          ...prev,
          [e.target.name]: e.target.value,
          codedTime: `${
            e.target.name === 'hourly' ? e.target.value : prev.hourly
          } ${e.target.name === 'daily' ? e.target.value : prev.daily} * * *`,
        }),
      );
    } else if (e.target.name === 'hourly') {
      setIntervalData(
        (prev: { hourly: any; daily: any; monthly: any; weekly: any }) => ({
          ...prev,
          [e.target.name]: e.target.value,
          codedTime: `${
            e.target.name === 'hourly' ? e.target.value : prev.hourly
          } * * * *`,
        }),
      );
    } else {
      setIntervalData((prev: { hourly: any; daily: any }) => ({
        ...prev,
        [e.target.name]: e.target.value,
        weekly: '*',
        monthly: '*',
        codedTime: `${prev?.hourly ? prev?.hourly : '*'} ${
          prev?.daily ? prev?.daily : '*'
        } * * *`,
      }));
    }
  };

  const getTriggerMessage = (type: string) => {
    if (type === 'hourly') {
      return `Runs ${intervalData?.hourly} minutes past the hour `;
    } else if (type === 'daily') {
      return `Runs every day at ${intervalData?.daily} : ${intervalData?.hourly}`;
    } else if (type === 'weekly') {
      return `Runs every ${intervalData?.weekly} at ${intervalData?.daily} : ${intervalData?.hourly}`;
    } else if (type === 'monthly') {
      return `Runs at the ${intervalData?.monthly} day of the month, at ${intervalData?.daily} : ${intervalData?.hourly}`;
    } else {
      return '';
    }
  };

  const codedTime = (interval: string) => {
    const weekDays: { [key: string]: number | string } = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7,
      ['*']: '*',
    };

    const intervalArray = interval.split(' ');

    const intervalData = {
      minute: intervalArray?.[0],
      hour: intervalArray?.[1],
      day: intervalArray?.[2],
      month: intervalArray?.[3],
      weekDay: intervalArray?.[4],
    };

    return `${intervalData.minute} ${intervalData.hour} ${intervalData.day} ${
      intervalData.month
    } ${weekDays[intervalData.weekDay]}`;
  };

  const onSave = async () => {
    try {
      if (schedule?.id) {
        await ScheduleService.updateSchedule({
          name: schedule.name,
          id: schedule.id,
          interval: codedTime(intervalData.codedTime),
        });
      } else {
        let objectData = {};

        if (intervalData.varInput) {
          objectData = {
            variableInputs: {
              inputId: intervalData.varInput,
              inputText: intervalData.inputText,
            },
          };
        }

        await ScheduleService.addSchedule({
          name: 'Flow Global Node' + generateCustomID(),
          interval: codedTime(intervalData.codedTime),
          flowId: flowId,
          webhook: false,
          ...objectData,
        });
        setProcessingFlows(flowId);
      }

      saveNode({
        triggerMessage: getTriggerMessage(intervalData.type),
        intervalData: intervalData,
        isAutotrigger: true,
      });

      setFormData((prev: any) => ({
        ...prev,
        triggerMessage: getTriggerMessage(intervalData.type),
        intervalData: intervalData,
        isAutotrigger: true,
      }));
      setIsAutoTriggerModalOpen(false);
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error?.message,
      });
    }
  };

  const onDelete = async () => {
    try {
      setDeleteProcessingFlow(flowId);
      await ScheduleService.deleteSchedule(schedule?.id);
      setIsAutoTriggerModalOpen(false);
      setFormData((prev: any) => ({
        ...prev,
        isAutotrigger: false,
      }));
      saveNode({
        triggerMessage: '',
        intervalData: {},
        isAutotrigger: false,
      });
    } catch (error) {
      setIsAutoTriggerModalOpen(false);
      setFormData((prev: any) => ({
        ...prev,
        isAutotrigger: false,
      }));
    }
  };

  const generateDays = () => {
    const daysInMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
    ).getDate();

    const daysArray = Array.from(
      { length: daysInMonth },
      (_, index) => index + 1,
    );

    return daysArray;
  };

  const hourlyInput = () => {
    const handleChange = (e: any) => {
      const selectedMinutes = e.target.value;

      onChange({
        target: {
          value: `${selectedMinutes}`, // Format as HH:mm with 00 for hours
          name: 'hourly',
        },
      });
    };

    return (
      <FormControl sx={{ width: '200px' }}>
        <InputLabel>Select Minute</InputLabel>
        <Select
          value={intervalData?.hourly || ''}
          onChange={handleChange}
          label="Select Minutes"
          sx={{
            backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#98A2B3' : 'black',
          }}
        >
          {Array.from({ length: 60 }, (_, i) => (
            <MenuItem key={i} value={i < 10 ? `0${i}` : i}>
              {i < 10 ? `0${i}` : i} minutes
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const dailyInput = () => {
    const handleChange = (time: any) => {
      const hours = time?.hour(); // Get the hour from the selected time
      const minutes = time?.minute();

      onChange({
        target: {
          value: hours.toString(),
          name: 'daily',
        },
      });
      onChange({
        target: {
          value: minutes.toString(),
          name: 'hourly',
        },
      });
    };

    // Format the default value using dayjs
    const defaultHour = parseInt(intervalData?.daily || '0', 10);
    const defaultMinute = parseInt(intervalData?.hourly || '0', 10);
    const defaultValue = dayjs().hour(defaultHour).minute(defaultMinute);

    return (
      <>
        <TimePicker
          label="Select Time"
          value={defaultValue}
          onChange={handleChange}
          slotProps={{ textField: { fullWidth: false } }}
          ampm={false}
        />
      </>
    );
  };

  const monthlyInput = () => {
    const handleDateChange = (date: dayjs.Dayjs | null) => {
      if (date) {
        const dayOfMonth = date.date(); // Extract only the day
        onChange({
          target: {
            value: dayOfMonth.toString(),
            name: 'monthly',
          },
        });
      }
    };

    // Format the default value as a dayjs object compatible with DatePicker
    const defaultDayValue = intervalData?.monthly
      ? dayjs()
          .date(parseInt(intervalData.monthly, 10)) // Set the selected day
          .startOf('day')
      : null;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <label
          style={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}
        >
          Every month at: &nbsp;
        </label> */}
        <DatePicker
          views={['day']}
          value={defaultDayValue}
          onChange={handleDateChange}
          format="DD"
          slotProps={{
            textField: {
              style: {
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                height: '40px',
                borderRadius: '5px',
              },
            },
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <KitModal
        show={isAutoTriggerModalOpen}
        onHide={() => {
          setIsAutoTriggerModalOpen(false);
          setFormData((prev: any) => ({
            ...prev,
            isAutotrigger: schedule?.flowId === flowId,
          }));
        }}
        title="Set time"
        footer={
          <>
            <Button onClick={onSave} variant="contained" sx={{ mr: '15px' }}>
              {schedule?.id ? 'Update' : 'Save'}
            </Button>
            {schedule?.id && <Button onClick={onDelete}>Delete</Button>}
          </>
        }
      >
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FormControl sx={{ width: '200px' }}>
              <InputLabel>Select Type</InputLabel>
              <Select
                value={intervalData?.type || ''}
                onChange={onChange}
                label="Select Minutes"
                name="type"
                sx={{
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#98A2B3' : 'black',
                }}
              >
                {intervalTypes.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {intervalData?.type === 'hourly' && hourlyInput()}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {intervalData?.type === 'daily' && <>{dailyInput()}</>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {intervalData?.type === 'weekly' && (
                <>
                  <label
                    style={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    Every&nbsp;
                  </label>
                  <FormControl sx={{ width: '200px' }}>
                    <Select
                      value={intervalData?.weekly || ''}
                      onChange={onChange}
                      name="weekly"
                      sx={{
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#98A2B3' : 'black',
                      }}
                    >
                      <MenuItem value="*">Choose</MenuItem>
                      {intervalWeekdays.map(type => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <label
                    style={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    &nbsp;at:&nbsp;
                  </label>
                  {dailyInput()}
                </>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {intervalData?.type === 'monthly' && (
                <>
                  {monthlyInput()}
                  <label
                    style={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    &nbsp;at:&nbsp;
                  </label>
                  {dailyInput()}
                </>
              )}
            </div>
          </div>
        </>
      </KitModal>
    </div>
  );
};

export default AutoTriggerTimerModal;
