import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FlowStatsTable from './FlowStatsTable';
import { Link as LinkIcon, Export, ArrowLeft } from '@phosphor-icons/react';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { CompanyService } from '../../service/CompanyService';
import { getAPIErrorMessage } from '../../helpers/helpers';
import LoadingSpinner from '../UI/LoadingSpinner';

interface Props {
  flowId: string;
  setSelectedFlowId: (flowId: undefined | string) => void;
}

const statsTime = {
  'This Month': 'This Month',
  '30 Days': '30 Days',
  '90 Days': '90 Days',
  Lifetime: 'Lifetime',
};

const queryTime: { [key: string]: string } = {
  'This Month': 'this_month',
  '30 Days': '30_days',
  '90 Days': '90_days',
};

const FlowStats: React.FC<Props> = ({ flowId, setSelectedFlowId }) => {
  const authUser = useAuthStore(state => state.user);

  const setNotification = useNotificationStore(state => state.setNotification);
  const [selectedStatsTime, setSelectedStatsTime] = useState(
    statsTime['Lifetime'],
  );

  const [loading, setLoading] = useState(true);
  const [flowStats, setFlowStats] = useState<any>();

  const getFlowStats = useCallback(async () => {
    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      const query =
        selectedStatsTime === statsTime.Lifetime
          ? ''
          : `&date=${queryTime[selectedStatsTime]}`;

      const { data } = await CompanyService.getFlowStats(
        authUser.selectedCompanyId,
        flowId,
        query,
      );

      setFlowStats(data);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }

    setLoading(false);
  }, [authUser, flowId, selectedStatsTime]);

  useEffect(() => {
    getFlowStats();
  }, [getFlowStats]);

  if (loading) return <LoadingSpinner />;

  const triggerOverview = flowStats?.triggerOverview || {};
  const { flowsReport } = flowStats;

  return (
    <Box>
      <Box
        sx={{
          borderBottom: '1px solid rgba(234, 236, 240, 1)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: '10x',
          height: '64px',
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            marginLeft: '10px',
            display: 'inline-block',
          }}
          onClick={() => setSelectedFlowId(undefined)}
        >
          <ArrowLeft color="rgba(52, 51, 48, 1)" size={18} />
        </Box>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
            pb: '10px',
          }}
        >
          Flow Details
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(234, 236, 240, 1)',
          paddingX: '24px',
          paddingY: '15px',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '28.8px',
            letterspacing: '0.15px',
          }}
        >
          Trigger Overview
        </Typography>

        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {Object.values(statsTime).map(statsTime => (
            <Box
              onClick={() => setSelectedStatsTime(statsTime)}
              sx={{
                borderRadius: '8px',
                padding: '3px 8px',
                background:
                  selectedStatsTime === statsTime
                    ? 'rgba(54, 80, 222, 1)'
                    : 'rgba(234, 236, 240, 1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <Typography
                sx={{
                  color:
                    selectedStatsTime === statsTime
                      ? 'white'
                      : 'rgba(16, 24, 40, 1)',
                  fontSize: '12px',
                  lineHeight: '19.92px',
                  letterSpacing: '0.4px',
                }}
              >
                {statsTime}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          width: '96%',
          margin: '20px 0',
          paddingX: '24px',
          paddingY: '15px',
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',

            flex: '1',
            minWidth: '150px',
            padding: '12px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
          >
            {triggerOverview.apiTriggers}
          </Typography>
          <Typography
            sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
          >
            API Triggers
          </Typography>
        </Box>

        <Box
          sx={{
            background:
              'linear-gradient(53.38deg, #FD746C 16.71%, #FE846A 48.74%, #FF9068 80.78%)',

            flex: '1',
            minWidth: '150px',
            padding: '12px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
          >
            {triggerOverview.emailTriggers}
          </Typography>
          <Typography
            sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
          >
            Email Triggers
          </Typography>
        </Box>

        <Box
          sx={{
            background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',

            flex: '1',
            minWidth: '150px',
            padding: '12px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
          >
            {triggerOverview.fluxPromptTriggers}
          </Typography>
          <Typography
            sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
          >
            Manual Triggers
          </Typography>
        </Box>

        <Box
          sx={{
            background:
              'linear-gradient(58.74deg, #683BB8 7.2%, #A969FD 92.35%)',

            flex: '1',
            minWidth: '150px',
            padding: '12px',
            borderRadius: '8px',
          }}
        >
          <Typography
            sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
          >
            {triggerOverview.autoTriggers}
          </Typography>
          <Typography
            sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
          >
            Auto Triggers
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: '1px solid rgba(234, 236, 240, 1)',
          margin: '10px 0',
        }}
      ></Box>

      <FlowStatsTable flowsReport={flowsReport} flowId={flowId} />
    </Box>
  );
};

export default FlowStats;
