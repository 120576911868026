export interface FormData {
  audioUrl: string;
  text?: string;
  voiceSystem: string;
  voice: string;
  title: string;
  speed: number;
  quality: string;
  outputFormat: string;
  sampleRate: number;
  voiceId: string;
  stability: number;
  similarity_boost: number;
  style: number;
  use_speaker_boost: boolean;
  modelId: string;
  evenLabsModel: string;
}

export const defaultAudioFormData: FormData = {
  audioUrl: '',
  text: '',
  voiceSystem: 'elevenlabs.io',
  voice: 'Adrian',
  title: '',
  speed: 1,
  quality: 'medium',
  outputFormat: 'mp3',
  sampleRate: 24000,
  voiceId: 'pqHfZKP75CvOlQylNhV4',
  stability: 0.5,
  similarity_boost: 0.75,
  style: 0.2,
  use_speaker_boost: true,
  modelId: 'eleven_multilingual_v2',
  evenLabsModel: 'even-labs-clone',
};
