import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class FlowService extends ApiService {
  static pathName = '/flow';
  static edgePathName = '/edge';

  static getFlows = (signal?: AbortSignal): Promise<IGetAllResponse<any>> => {
    // return this.get(`${this.pathName}?page=1&pageSize=10000`, signal);
    return this.get(`${this.pathName}`);
  };

  static getFlow = (
    id: string,
    signal?: AbortSignal,
  ): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/${id}`);
  };

  static getPublicFlow = (
    id: string,
    signal?: AbortSignal,
  ): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/public/${id}`);
  };

  static createFlow = (body: any): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}`, body);
  };

  static duplicateFlow = (body: any): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/duplicate`, body);
  };

  static shareFlow = (id: string, body: any): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/share/${id}`, body);
  };

  static nodesAutomation = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/nodes-automation/${id}`, body);
  };

  static copyTemplate = (
    flowId: string,
    folderId: string,
    name: string,
  ): Promise<IGetOneResponse<any>> => {
    return this.get(
      `${this.pathName}/use-template?flowId=${flowId}&folderId=${folderId}&name=${name}`,
    );
  };

  static updateFlow = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/${id}`, body);
  };

  static clearChatFlow = (
    id: string,
    nodeId: string,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(
      `${this.pathName}/clear-chat?flowId=${id}&nodeId=${nodeId}`,
      {},
    );
  };

  static deleteFlow = (id: string, type?: string) => {
    // return this.delete(`${this.pathName}/${id}`);
    let url = `${this.pathName}/${id}`;
    if (type === 'chat') {
      url += `?type=${type}`;
    }
    return this.delete(url);
  };

  static addNode = (
    flowId: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/add-node/${flowId}`, body);
  };

  static updateEdge = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/update-edge/${id}`, body);
  };

  static onConnectEdge = (
    flowId: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/on-connect-edge/${flowId}`, body);
  };

  static updateNode = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/update-node/${id}`, body);
  };

  static updateNodeData = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/update-node-data/${id}`, body);
  };

  static deleteEdge = (body: { flowId: string; edgeId: string }) => {
    return this.delete(
      `${this.pathName}/edge?flowId=${body.flowId}&edgeId=${body.edgeId}`,
    );
  };

  static clearSessions = (flowId: string): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/clear-session/${flowId}`);
  };

  static deleteNode = (body: { flowId: string; nodeId: string }) => {
    return this.delete(`${this.pathName}/node`, body);
  };

  static searchFlow = (flowName: string): Promise<IGetAllResponse<any>> => {
    return this.get(`${this.pathName}?flowName=${flowName}`);
  };

  static lastSeen = (page: number = 1): Promise<IGetAllResponse<any>> => {
    return this.get(`${this.pathName}?lastSeen=true&page=${page}&pageSize=25`);
  };

  static getSharedFlows = (page: number = 1): Promise<IGetAllResponse<any>> => {
    return this.get(`${this.pathName}/shared-flows?page=${page}&pageSize=25`);
  };

  static getFluxBots = (): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/flux-bots`);
  };
}
