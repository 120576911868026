import { groupTextBlocks } from './paragraphBreak';

const options = ['Paragraph', 'Sentence', 'Custom Separator'];

function splitText(text: string, separator = '\n') {
  // Replace multiple spaces with a single space
  if (!text) return;
  // Split the text into paragraphs or custom separators
  return text?.split(separator);
}
export const textToArray = (
  text: string,
  option: string,
  customOperator = '\n'
) => {
  if (option === options[0]) {
    return splitText(text, '\n')?.filter(
      paragraph =>
        paragraph !== '' &&
        paragraph !== ' ' &&
        paragraph !== `\n\n` &&
        paragraph !== `  `
    );
  }

  if (option === options[1]) {
    return splitText(text, '. ')
      ?.filter(
        paragraph =>
          paragraph !== '' &&
          paragraph !== ' ' &&
          paragraph !== `\n\n` &&
          paragraph !== `  `
      )
      ?.map(sentence => sentence + '.');
  }

  if (option === options[2]) {
    return splitText(text, customOperator)?.filter(
      paragraph =>
        paragraph !== '' &&
        paragraph !== ' ' &&
        paragraph !== `\n\n` &&
        paragraph !== `  `
    );
  }
};

export const processorArrayFc = (formData: any) => {
  if (formData.content) {
    let processorArray: string[] | undefined;
    if (formData.options === 'Custom Separator') {
      processorArray = textToArray(
        formData?.content,
        'Custom Separator',
        formData.customOperator ? formData.customOperator : '\n'
      );
    }

    if (formData.options === 'Paragraph') {
      processorArray = groupTextBlocks(
        formData?.content || '',
        Number(formData?.breakWords) || 200
      );
    }

    if (formData.options === 'Sentence') {
      processorArray = textToArray(formData?.content, 'Sentence');
    }

    const mergedArrayWithCustomText: any = processorArray?.reduce(
      (result: any, item: any, index: any) => {
        if (index % (formData?.processorArrayItemLength! || 1) === 0) {
          result.push(item);
        } else {
          result[result.length - 1] += '\n\n' + item;
        }
        return result;
      },
      []
    );

    const editedProcessorArray: any = mergedArrayWithCustomText?.map(
      (item: string) => {
        return formData.preInput + ' ' + item + ' ' + formData.postInput;
      }
    );

    return editedProcessorArray;
  }
  return [];
};
