import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class UserConfigService extends ApiService {
  static pathName = '/user-config';

  static getConfigs = (signal?: AbortSignal): Promise<IGetAllResponse<any>> => {
    return this.get(`${this.pathName}`);
  };

  static createConfig = (body: any): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}`, body);
  };

  static updateConfig = (
    id: string,
    body: any
  ): Promise<IGetOneResponse<any>> => {
    return this.patch(`${this.pathName}/${id}`, body);
  };

  static deleteConfig = (id: string) => {
    return this.delete(`${this.pathName}/${id}`);
  };
}
