import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  systemDisplayNameMap,
  systemValueMap,
} from '../../AiConfigs/constants';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from 'react-hook-form';
import voices from '../../flux_classes/voice';

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[];
}
const PlayHtConfigForm = ({
  register,
  setSystemType,
  systemType,
  watch,
  systems,
}: Props) => {
  return (
    <Box>
      <Box
        mt={'16px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            name="aiSystem"
            onChange={e => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem key={index} value={systemValue} disabled={disabled}>
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Model</InputLabel>
          <Select
            value={watch()?.voiceModel}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Model"
            {...register('voiceModel')}
            sx={{
              width: '100%',
            }}
          >
            {systems
              .filter((item: any) => item.system === 'playHt')[0]
              .models?.map((opt: any) => (
                <MenuItem
                  key={opt.id}
                  value={opt.model}
                  disabled={opt.disabledByCompany}
                >
                  {opt.model}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <FormControl
        sx={{
          width: '100%',
          mt: '16px',
        }}
      >
        <InputLabel id="demo-simple-select-label">Voice</InputLabel>
        <Select
          value={watch()?.voice}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Voice"
          {...register('voice')}
          sx={{
            width: '100%',
          }}
        >
          {voices
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(voice => (
              <MenuItem key={voice.id} value={voice.name}>
                {voice.name} ({voice.language})
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Divider
        sx={{
          marginY: '16px',
        }}
      />

      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <InputLabel id="demo-simple-select-label">Quality</InputLabel>
        <Select
          value={watch()?.quality}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Quality"
          {...register('quality')}
          sx={{
            width: '100%',
          }}
        >
          <MenuItem value="draft">Draft</MenuItem>
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="high">High</MenuItem>
          <MenuItem value="premium">Premium</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width: '100%',
          mt: '16px',
        }}
      >
        <InputLabel id="demo-simple-select-label">Output Format</InputLabel>
        <Select
          value={watch()?.outputFormat}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Output Format"
          {...register('outputFormat')}
          sx={{
            width: '100%',
          }}
        >
          <MenuItem value="mp3">MP3</MenuItem>
          <MenuItem value="wav">WAV</MenuItem>
          <MenuItem value="ogg">OGG</MenuItem>
          <MenuItem value="flac">FLAC</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Speed"
        {...register('speed')}
        sx={{
          mt: '16px',
          width: '100%',
        }}
        type="number"
      />
      <TextField
        label="Sample Rate"
        {...register('sampleRate')}
        sx={{
          mt: '16px',
          width: '100%',
        }}
        type="number"
      />
    </Box>
  );
};

export default PlayHtConfigForm;
