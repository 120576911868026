import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PaymentPlanBox from './PaymentPlanBox';
import { Product } from '../../../util/Types/Product';
import { useAuthStore } from '../../../store/storeAuth';
import { styled } from '@mui/system';

interface Props {
  products: Product[];
  setSelectedPlan: React.Dispatch<React.SetStateAction<Product | undefined>>;
  activeSubscriptionId: string | undefined;
  setIsSelectedTimePlanYearly: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectedTimePlanYearly: boolean;
  selectedPlan: Product | undefined;
}

interface User {
  // Existing properties

  plan?: string; // Add the "plan" field as optional
  trialTokens?: number; // Add "trialTokens" field
}

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    //color: "#3650DE", // Initial thumb color
    // "&:hover": {
    //   backgroundColor: "rgba(74, 144, 226, 0.1)", // Thumb hover color
    // },
    '&.Mui-checked': {
      color: '#3650DE', // Thumb color when checked
      '& + .MuiSwitch-track': {
        backgroundColor: '#3650DE', // Track color when checked
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#E0E0E0', // Track color when unchecked
  },
}));

const PaymentPlans = ({
  products,
  setSelectedPlan,
  activeSubscriptionId,
  isSelectedTimePlanYearly,
  setIsSelectedTimePlanYearly,
  selectedPlan,
}: Props) => {
  const authUser = useAuthStore(state => state.user);

  const handleChange = () => {
    setIsSelectedTimePlanYearly(!isSelectedTimePlanYearly);
  };

  const getPlanMessage = () => {
    if (activeSubscriptionId) {
      return null;
    }

    //const isOnIntroPlan = authUser?.plan === "Intro"; // Use optional chaining to avoid errors
    const hasFreeTokens = (authUser?.freeTokens || 0) > 0;

    //if (isOnIntroPlan) {
    if (hasFreeTokens) {
      return 'You are on our introductory plan. Subscribe to a pricing plan to get premium access.';
    } else {
      return 'You have used all your Intro FluxTokens. Subscribe to a pricing plan to get premium access.';
    }
    // }////

    //return null;
  };
  let productsWithoutEnterprise = products.filter(
    (productsWithoutEnterprise, index) => index !== products.length - 1,
  );
  let productsOnlyEnterprise = products.slice(-1);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 99,
            backgroundColor: 'white',
            borderBottom: '1px solid #D0D5DD',
            display: 'flex',
            height: authUser?.isUserCompany ? '67px' : '64px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: '#000',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '32px',
              margin: '24px',
            }}
          >
            Pricing Plans
          </Typography>
        </Box>

        <Box
          sx={{
            padding: '16px 24px 16px 24px',
          }}
        >
          {getPlanMessage() && (
            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '32px',
              }}
            >
              {getPlanMessage()}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: getPlanMessage() ? '16px' : '0',
              marginBottom: '20px',
            }}
          >
            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
              }}
              color={isSelectedTimePlanYearly ? '#888' : '#000'}
            >
              Monthly
            </Typography>

            <StyledSwitch
              checked={isSelectedTimePlanYearly}
              onChange={handleChange}
            />

            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
              }}
              color={isSelectedTimePlanYearly ? '#000' : '#888'}
            >
              Annual
            </Typography>
          </Box>

          <Grid
            container
            rowSpacing={{ xs: 8, sm: 6, md: 6 }}
            columnSpacing={{ xs: 3, sm: 2, md: 3 }}
            sx={{
              marginBottom: '35px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {products.map(subscription => (
              <Grid
                item
                key={subscription.id}
                xs={subscription.name === 'Enterprise' ? 12 : 12}
                sm={subscription.name === 'Enterprise' ? 12 : 6}
                md={subscription.name === 'Enterprise' ? 12 : 4}
                lg={subscription.name === 'Enterprise' ? 12 : 3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  order: subscription.name === 'Enterprise' ? 1 : 0,
                  marginTop: subscription.name === 'Enterprise' ? '32px' : '0',
                  width: '100%',
                }}
              >
                <PaymentPlanBox
                  subscription={subscription}
                  setSelectedPlan={setSelectedPlan}
                  activeSubscriptionId={activeSubscriptionId}
                  isChecked={isSelectedTimePlanYearly}
                  authUser={authUser!}
                />
              </Grid>
            ))}
          </Grid>

         
        </Box>

        {selectedPlan && (
          <Box>
            <Typography
              fontSize={'20px'}
              lineHeight={'32px'}
              fontWeight={500}
              mt={'20px'}
            >
              Your Plan: {selectedPlan.name}
            </Typography>

            <Typography fontSize={'16px'} color={'#3650DE'} mt={'10px'}>
              Price: per month $
              {isSelectedTimePlanYearly
                ? selectedPlan.year_price
                : selectedPlan?.price}{' '}
              {isSelectedTimePlanYearly ? '/annual' : '/monthly'}
            </Typography>

            <Typography fontSize={'16px'} color={'#888'} mt={'10px'}>
              Tokens: {selectedPlan.tokens}
            </Typography>

            {/* Include additional information as needed */}
          </Box>
        )}
      </Box>
    </>
  );
};

export default PaymentPlans;
