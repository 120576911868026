import { Box, Typography } from '@mui/material';
import {
  ArrowsIn,
  ArrowsOut,
  Article,
  ArticleNyTimes,
  BoxArrowDown,
  BoxArrowUp,
  Image,
  ListBullets,
  PaperPlaneTilt,
  TextAlignLeft,
  TextT,
} from '@phosphor-icons/react';
import { DragEvent } from 'react';
const topComponents = [
  {
    title: 'Title ',
    type: 'title',
    icon: <TextT size={16} className={'filled'} color={'#3650DE'} />,
  },
  {
    title: 'Short text',
    type: 'shortText',
    icon: <TextAlignLeft size={16} className={'filled'} color={'#3650DE'} />,
  },
  {
    title: 'Image ',
    type: 'image',
    icon: <Image size={16} className={'filled'} color={'#3650DE'} />,
  },
  {
    title: 'List',
    type: 'listNode',
    icon: <ListBullets size={16} className={'filled'} color={'#3650DE'} />,
  },
  {
    title: 'Input',
    type: 'inputDisplay',
    icon: <ArrowsIn size={16} className={'filled'} color={'#3650DE'} />,
  },
  {
    title: 'Output',
    type: 'outputDisplay',
    icon: <ArrowsOut size={16} className={'filled'} color={'#3650DE'} />,
  },
  {
    title: 'Submit',
    type: 'submit',
    icon: <PaperPlaneTilt size={16} className={'filled'} color={'#3650DE'} />,
  },
];

const FormSidebar = () => {
  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: any) => {
    event.dataTransfer.setData(
      'application/reactflow',
      JSON.stringify(nodeType),
    );
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '130px',
        zIndex: 999,
        left: '20px',
      }}
    >
      <Box
        sx={{
          width: '91px',
          bgcolor: '#fff',
          p: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          border: `1px solid var(--Neutral-300, #D0D5DD)`,
          borderRadius: '8px',
        }}
      >
        {topComponents.map((component, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '10px',
              padding: '4px 12px',
              borderRadius: '4px',
              cursor: 'pointer',
              textAlign: 'center',
              transition: 'background 0.3s',
              '&:hover': {
                background: '#E7F0FF',
                color: '#3650DE',
              },
            }}
            draggable={true}
            onDragStart={event => {
              onDragStart(event, {
                type: component.type,
                label: component.title,
              });
            }}
          >
            {component.icon}
            <Typography
              variant="body1"
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                letterSpacing: '0.1px',
                lineHeight: '157%',
                textWrap: 'nowrap',
              }}
            >
              {component.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FormSidebar;
