import { memo, useEffect, useRef, useState } from 'react';
import { Position, useEdges, useReactFlow } from 'reactflow';
import '../../assets/styles/animatedBorder.scss';

import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { CaretDown, Link } from '@phosphor-icons/react';
import { useNotificationStore } from '../../store/storeNotifications';

import { getAPIErrorMessage } from '../../helpers/helpers';
import { axiosInstance } from '../../service/ApiService';
import { useAuthStore } from '../../store/storeAuth';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { isActiveEdge } from '../../util/findActiveHandle';
import { NodeProps } from '../../util/Types/NodeProps';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import {
  commandTextNodeDefaultValues,
  modelTokenLimits,
} from '../AiConfigs/constants';
import { aiGeneratorInputs } from '../AiTextGenerator/constants';
import { commandTextNodeResponses } from '../AiTextGenerator/responses';
import SettingsModal from '../AiTextGenerator/SettingsModal/SettingsModal';
import GenerateFileLinkModal from '../GenerateFileLinkModal/GenerateFileLinkModal';
import TagInput from '../Test/TagInput';
import InputHandle from '../UI/InputHandle/InputHandle';
import Node from '../UI/Node/Node';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { useObserveElementWidth } from '../../util/useObserveElement';
import AiIcon from '../../assets/icons/AiIcon';

export const sourceTypes = {
  promptIn: 'prompt',
  contentIn: 'content',
  instructionIn: 'instruction',
  personaIn: 'persona',
};

// WordCount component
export function WordCount({
  text,
  props,
}: {
  text: string | null | undefined;
  props?: SxProps<Theme>;
}) {
  if (!text || typeof text !== 'string') return null;

  const trimmedText = text.trim();
  const wordCount = trimmedText.split(/\s+/).filter(Boolean).length;

  return (
    <Typography
      color={'#98A2B3'}
      fontSize={'12px'}
      lineHeight={'0'}
      sx={{ ...props }}
    >
      {wordCount} {wordCount === 1 ? 'Word' : 'Words'}
    </Typography>
  );
}

function AiTextGenerator({ id, data, type, selected }: NodeProps) {
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);

  const previewResponses = data?.previewResponses || [];

  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
    isChangingDirectly: false,
  });
  const { setNodes: setNodeState, getNodes } = useReactFlow();

  const [formData, setFormData] = useState({
    ...commandTextNodeDefaultValues,
    ...data,
  });

  const [showGenerateFileLinkModal, setShowGenerateFileLinkModal] =
    useState(false);

  const edges = useEdges();
  const { setSchema, flowId, schema, setSaving } = useFlowsStore(
    state => state,
  );
  const setNotifications = useNotificationStore(state => state.setNotification);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [hasToExecute, data, formData]);

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const text = ['prompt', 'persona', 'instruction', 'content'];
      let newFormData: any = { ...formData };

      text.forEach(t => {
        if (data[t + 'Data']?.length) {
          newFormData[t] = data[t + 'Data']
            ?.map((item: { text: string }) => item.text)
            .join(' \n');
        }
      });

      const response = await commandTextNodeResponses(
        newFormData,
        schema,
        flowId,
      );

      let responses = [...(data.previewRewsponses || [])];
      let updatedFileLinks = [...(data?.fileLinks || [])];

      if (responses.length === 10) {
        responses.shift();
      }

      if (updatedFileLinks.length === 10 && data.saveOutput) {
        updatedFileLinks.shift();
      }

      let fileLink;
      if (data.saveOutput) {
        const { data: res } = await axiosInstance.post('/upload/text', {
          text: response?.data?.message || '',
          fileType: data.fileType,
        });
        fileLink = res.data;
      }

      setFormData({
        ...data,
        ...newFormData,
        text: response?.data?.message,
        previewResponses: [...responses, response?.data?.message],
        previewIndex: responses.length,
        fileLinks: [...updatedFileLinks, fileLink],
        linkIndex: updatedFileLinks.length,
        fileLink,
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
        objectData: {
          text: response?.data?.message,
          previewResponses: [...responses, response?.data?.message],
          previewIndex: responses.length,
          fileLinks: [...updatedFileLinks, fileLink],
          linkIndex: updatedFileLinks.length,
          fileLink,
          usedTokens: data?.flowTrigger?.id
            ? response?.data?.usedTokens
            : undefined,
          nodeUsedTokens: response?.data?.usedTokens,
        },
        changeType: 'execute',
        setSchema,
        setSaving,
      });

      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotifications,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setIsLoading(false);

      setNotifications({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkWordCount = () => {
    const text = ['prompt', 'persona', 'instruction', 'content'];

    let newFormData: any = { ...formData, ...data };

    text.forEach(t => {
      if (newFormData[t + 'Data']?.length) {
        newFormData[t] = newFormData[t + 'Data']
          ?.map((item: { text: string }) => item.text)
          .join(' \n');
      }
    });

    return newFormData;
  };

  const setValue = (name: string, value: string, data?: any) => {
    setFormData({
      ...formData,
      [name]: value,
      ...data,
    });
  };

  const onChange = (e: { target: { name: string; value: string } }) => {
    if (e.target.name === 'anthropicModel') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        anthropicToken: e.target.value !== 'claude-35-sonnet' ? 4096 : 8192,
      });

      setWhoIsChanging({
        name: e.target.name,
        value: e.target.value,
        isChangingDirectly: true,
      });

      return;
    }

    if (e.target.name === 'aiModel') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        aiTokens:
          modelTokenLimits.find(item => item.model === e.target.value)
            ?.tokens || 4096,
      });

      return;
    }

    setValue(e.target.name, e.target.value);

    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
      isChangingDirectly: true,
    });
  };

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
      isChangingDirectly: true,
    });
  };

  useDebounceEffect(
    () => {
      if (whoIsChanging.isChangingDirectly) {
        setWhoIsChanging({
          value: '',
          name: '',
          isChangingDirectly: false,
        });

        let object = {};

        if (whoIsChanging.name === 'aiModel') {
          object = {
            aiTokens:
              modelTokenLimits.find(item => item.model === whoIsChanging.value)
                ?.tokens || 4096,
          };
        }
        if (whoIsChanging.name === 'anthropicModel') {
          object = {
            anthropicToken:
              whoIsChanging.value !== 'claude-35-sonnet' ? 4096 : 8192,
          };
        }

        if (whoIsChanging.name === 'type') {
          object = {
            modelName:
              whoIsChanging.value === 'text'
                ? 'models/text-bison-001'
                : 'models/chat-bison-001',
          };
        }

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
            ...object,
          },
          setSchema,
        });
      }
    },
    [whoIsChanging],
    300,
  );

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            setSaving,
            changeType: 'changingPreviewIndex',
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  const onChangeOnExecute = (e: any) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        clearOnExecution: e.target.checked,
      },
      setSchema,
    });
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const changeModelBasedOnSystem = (system: string) => {
    switch (system) {
      case 'OpenAI':
        return formData.aiModel;
      case 'Gemini':
        return formData.geminiModel;
      case 'openSources':
        return formData.openSourceModelName;
      case 'perplexityAi':
        return formData.perplexityType;
      case 'anthropicAi':
        return formData.anthropicModel;
      case 'Groq':
        return formData.groqModel;
      default:
        break;
    }
  };

  const onFileLinkSave = (saveOutput: boolean, fileType: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowTriggerData: checkExecuteTrigger(data, id),
      flowId,
      objectCallerData: user?.objects,
      objectData: {
        saveOutput,
        fileType,
      },
      setSchema,
      setSaving,
    });
    setShowGenerateFileLinkModal(false);
  };

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <>
      {showGenerateFileLinkModal && (
        <GenerateFileLinkModal
          onClose={() => setShowGenerateFileLinkModal(false)}
          onSave={onFileLinkSave}
          savedOutput={data.saveOutput}
          savedFileType={data.fileType}
        />
      )}

      <Box className={isLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          btnText="Run Prompt"
          onSubmit={onSubmit}
          isLoading={isLoading}
          data={data}
          id={id}
          selected={selected}
          onChangePlacement={onChangePlacement}
          handleRun={handleRun}
        >
          {!data?.collapsed && (
            <Box display={'flex'} sx={{ alignItems: 'stretch' }}>
              <div key={'div'}>
                <Box
                  p={'16px'}
                  ref={inputRef}
                  borderRight={`1px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`}
                  sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                >
                  {aiGeneratorInputs.map(input => {
                    let height = 'auto';
                    if (input.name === 'prompt') {
                      height = '120px';
                    }
                    return (
                      <Box sx={{ flex: 1 }} key={input.handleId}>
                        <TagInput
                          onChange={onChange}
                          name={input.name}
                          key={input.name}
                          nodeId={id}
                          labelName={input.labelName}
                          dataConnected={data?.[input.name + 'Data'] || []}
                          placeholder={input.placeholder}
                          value={
                            formData?.[input.name as keyof typeof formData] ||
                            ''
                          }
                          placement={data?.placement}
                          nodeLabel={data?.label}
                          handleId={input.handleId}
                          isActive={isActiveEdge(
                            edges,
                            id,
                            input.handleId,
                            'target',
                          )}
                          findColor={findColor}
                          isCollapsed={input.isCollapsed}
                          onChangePlacement={onChangePlacement}
                          height={height}
                        />
                      </Box>
                    );
                  })}

                  {data?.placement !== 'Output' && (
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      position={'relative'}
                      sx={{ marginTop: 'auto' }}
                    >
                      {getNodes()
                        .map(node => node.type)
                        .includes('dynamicSettingsNode') && (
                        <InputHandle
                          activeColor={findColor?.color}
                          handleId={'modelSettings'}
                          left={'-26px'}
                          top={'50%'}
                          isActive={isActiveEdge(
                            edges,
                            id,
                            'modelSettings',
                            'target',
                          )}
                          position={Position.Left}
                          type="target"
                        />
                      )}

                      <Box
                        bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                        borderRadius={'4px'}
                        sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                        onClick={() => setSettingsModalOpen(true)}
                      >
                        <AiIcon />
                        <Typography
                          fontSize={'12px'}
                          color="#667085"
                          variant="h6"
                        >
                          {changeModelBasedOnSystem(formData.AiSystem)}
                        </Typography>

                        <CaretDown size={'16px'} color="#667085" />
                      </Box>
                      <WordCount
                        text={
                          checkWordCount()?.prompt +
                          checkWordCount()?.content +
                          checkWordCount()?.instruction +
                          checkWordCount()?.persona
                        }
                      />
                    </Box>
                  )}
                  <SettingsModal
                    onHide={() => setSettingsModalOpen(false)}
                    show={settingsModalOpen}
                    formData={formData}
                    onChange={onChange}
                  />
                </Box>
              </div>
              <Box
                p={'16px 24px 16px 16px'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ flex: 1, marginBottom: '10px' }}>
                  <OutputTextarea
                    previewResponses={
                      data?.saveOutput ? [] : data?.previewResponses || []
                    }
                    previewIndex={data.previewIndex}
                    maxOutputHeight={maxOutputHeight}
                    value={data.text}
                    activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                    placement={data?.placement}
                    labelName={'Output'}
                    handleId={'output'}
                    nodeLabel={data?.label}
                    onPreview={onPreview}
                    onChangeOnExecute={onChangeOnExecute}
                    clearOnExecution={data.clearOnExecution || false}
                    findColor={findColor}
                    hasClearOnExecute={true}
                    onChangePlacement={onChangePlacement}
                    style={{
                      flex: 1,
                    }}
                    textareaHeight={'100%'}
                  />
                </Box>

                {data.saveOutput && (
                  <OutputTextarea
                    maxOutputHeight={maxOutputHeight}
                    value={data?.fileLink?.src || 'No Link generated'}
                    previewResponses={data?.previewResponses || []}
                    previewIndex={data.previewIndex || 0}
                    activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                    placement={data?.placement}
                    labelName={'Link'}
                    height="200px"
                    handleId={'fileLink'}
                    nodeLabel={data?.label}
                    type={type}
                    onPreview={onPreview}
                    clearOnExecution={data.clearOnExecution || false}
                    findColor={findColor}
                    hasClearOnExecute={false}
                    style={{
                      maxHeight: '78px',
                    }}
                    textareaHeight={'100%'}
                    textareaPadding={'2px'}
                  />
                )}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <IconButton
                    onClick={() => setShowGenerateFileLinkModal(true)}
                  >
                    <Link color="rgba(102, 112, 133, 1)" size={18} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
        </Node>
      </Box>
    </>
  );
}

export default memo(AiTextGenerator);
