import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from "react-hook-form";
import { imageAiModels, openSourceModelsImage } from "../../AiConfigs/constants";

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const ImageOpenSourceConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
}: Props) => {
  return (
    <Box mt={"16px"} sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
        <Select
          name="AiSystem"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Ai System"
          value={systemType}
          onChange={(e) => {
            setSystemType(e.target.value);
          }}
          sx={{
            width: "100%",
          }}
        >
          {imageAiModels.map((opt) => (
            <MenuItem
              key={opt.value}
              value={opt.value}
              // disabled={opt.disabled}
            >
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        sx={{
          width: "100%",
        }}
      >
        <InputLabel id="demo-simple-select-label">Model</InputLabel>
        <Select
          value={watch().openSourceModel}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Ai System"
          {...register("openSourceModel")}
          sx={{
            width: "100%",
          }}
        >
          {openSourceModelsImage.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ImageOpenSourceConfigForm;
