import React from 'react';
import KitModal from '../UI/KitModal';
import { Box, Button, Typography } from '@mui/material';
import { SealCheck } from '@phosphor-icons/react';

interface Props {
  onClose: () => void;
  showPricingHandler: () => void;
}

const UpgradeModal: React.FC<Props> = ({ onClose, showPricingHandler }) => {
  return (
    <KitModal show={true} onHide={onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '40px 60px',
          paddingBottom: '20px',
        }}
      >
        <SealCheck color="rgba(54, 80, 222, 1)" weight="fill" size={80} />

        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontWeight: 500,
            lineHeight: '28px',
            margin: '10px 0',
          }}
        >
          Upgrade to add Space
        </Typography>
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            lineHeight: '28px',
            width: '70%',
            textAlign: 'center',
            margin: '10px 0',
          }}
        >
          Your current plan does not allow you to create Space. Upgrade to
          create a space and get advanced features.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            width: '100%',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={showPricingHandler}
            sx={{ flex: 1 }}
          >
            View Pricing Plans
          </Button>
        </Box>
      </Box>
    </KitModal>
  );
};

export default UpgradeModal;
