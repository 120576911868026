import React, { useState } from 'react';
import { Position } from 'reactflow';
import { Box, TextField, Typography, Select, MenuItem } from '@mui/material';
import Label from '../UI/Label/Label';
import TagInput from '../Test/TagInput';
import InputHandle from '../UI/InputHandle/InputHandle';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { CaretDown, PlusCircle, Trash } from '@phosphor-icons/react';
import { isActiveEdge } from '../../util/findActiveHandle';
import useFlowsStore from '../../store/storeFlows';
import { useAuthStore } from '../../store/storeAuth';

type Props = {
  variables: {
    name: string;
    value: string;
    id: string;
  }[];
  addVariable: () => void;
  handleValueChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => void;
  script: string;
  language: string;
  handleScriptChange: (e: any) => void;
  data: any;
  edges: any;
  findColor: any;
  handleLanguageChange: (value: string) => void;
};

const ScriptEditor = ({
  variables,
  addVariable,
  handleValueChange,
  script,
  language,
  handleLanguageChange,
  handleScriptChange,
  data,
  findColor,
  edges,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const { setEdges, onEdgeDelete } = useFlowsStore(state => state);
  const onDeleteEdge = (sourceId: string) => {
    const test = edges?.filter(
      (edge: any) =>
        edge?.target === data?.myId && edge?.targetHandle === sourceId,
    );

    setEdges(eds => eds?.filter((edge: any) => edge?.id !== test[0]?.id));
    onEdgeDelete(test);
  };

  const { user } = useAuthStore(state => state);

  return (
    <Box>
      <Label isAdvanced labelName="Select Language " />
      <Select
        value={language}
        onChange={event => handleLanguageChange(event.target.value)}
        name="language"
        // onChange={onChange}
        sx={{
          bgcolor: '#EAECF0',
          borderRadius: '8px',
          border: 'none',
          height: '30px',
          textTransform: 'capitalize',
          mb: '16px',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        className="nodrag"
      >
        <MenuItem value="JavaScript">JavaScript</MenuItem>
        <MenuItem value="Python">Python</MenuItem>
      </Select>
      <TagInput
        placeholder="Write your code here"
        handleTab={true}
        isCollapsed={true}
        value={script}
        dataConnected={data?.scriptData}
        name="script"
        nodeId={data?.myId}
        height="120px"
        labelName="Code"
        disabled={isActiveEdge(edges, data?.myId, 'script', 'target')}
        handleId="script"
        onChange={handleScriptChange}
        findColor={findColor}
        nodeLabel={data?.label}
        // info='Shows the examples of all the functions that can be used in the script.'
        // infoOnClick={() =>
        //   window.open('https://formulajs.info/functions', '_blank')
        // }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          position: 'relative',
          '& svg': { cursor: 'pointer' },
          mb: !collapsed ? 0 : '8px',
        }}
      >
        <CaretDown
          onClick={() => setCollapsed(!collapsed)}
          size={16}
          color={!collapsed ? '#3650DE' : '#667085'}
        />
        <Typography
          fontSize={'16px'}
          color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
          fontWeight={600}
        >
          {'Variables'}
        </Typography>
        {variables?.length < 5 && (
          <IconButtonTooltip title="Add " onClick={addVariable}>
            <PlusCircle size={16} color="#98A2B3" />
          </IconButtonTooltip>
        )}
      </Box>
      {(variables || [])?.map((variable, index) => (
        <Box
          style={{
            marginBottom: collapsed ? 20 : 0,
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            position: 'relative',
          }}
          key={variable.id}
        >
          <InputHandle
            key={`${variable.id}`}
            position={Position.Left}
            type="target"
            left={-26}
            isActive={isActiveEdge(edges, data?.myId, variable.id, 'target')}
            activeColor={findColor?.color}
            top={!collapsed ? '-8px' : '50%'}
            handleId={variable.id}
          />
          {collapsed && (
            <>
              <TextField
                type="text"
                placeholder="Variable Name"
                name="name"
                value={variable.name}
                onChange={(e: React.ChangeEvent<any>) =>
                  handleValueChange(e, variable.id)
                }
                InputProps={{
                  style: {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                }}
                sx={{
                  maxWidth: '150px',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
              />

              <TextField
                type="text"
                name="value"
                placeholder="Enter number or words"
                value={variable.value}
                onChange={(e: React.ChangeEvent<any>) =>
                  handleValueChange(e, variable.id)
                }
                InputProps={{
                  style: {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                }}
                sx={{
                  maxWidth: '190px',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
              />

              <IconButtonTooltip
                title="delete"
                onClick={() => {
                  handleScriptChange({
                    target: {
                      name: 'variables',
                      value: variables?.filter(v => v?.id !== variable.id),
                    },
                  });

                  onDeleteEdge(variable.id);
                }}
              >
                <Trash size={16} color="#98A2B3" />
              </IconButtonTooltip>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ScriptEditor;
