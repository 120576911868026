import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from "react-hook-form";
import { ImageService } from "../../../service/ImageService";
import { useNotificationStore } from "../../../store/storeNotifications";
import {
  clipGuidancePresent,
  imageAiModels,
  stabilityFields,
  viewsArray
} from "../../AiConfigs/constants";

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[];
}

const StabilityAiConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems,
}: Props) => {
  const setNotifications = useNotificationStore(
    (state) => state.setNotification
  );

  const [imageModels, setImageModels] = useState<
    {
      description: string;
      id: string;
      name: string;
      type: string;
    }[]
  >([]);

  useEffect(() => {
    const getImageLL = async () => {
      try {
        const response = await ImageService.getImage();

        setImageModels(response.data.message);
      } catch (error: any) {
        setNotifications({
          type: "error",
          message: error.message || "Something went wrong",
        });
      }
    };

    getImageLL();
  }, []);

  return (
    <Box>
      <Box
        mt={'16px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
          <Select
            name="AiSystem"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            onChange={e => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            {systems.map((opt, index) => (
              <MenuItem
                key={index}
                value={opt.system}
                // disabled={opt.disabled}
              >
                {opt.system.charAt(0).toUpperCase() + opt.system.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Engine</InputLabel>
          <Select
            value={watch().engineId}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            {...register('engineId')}
            sx={{
              width: '100%',
            }}
          >
            {systems
              .filter((item: any) => item.system === 'stabilityAi')[0]
              .models?.map((opt: any) => (
                <MenuItem
                  key={opt.id}
                  value={opt.model}
                  disabled={opt.disabledByCompany}
                >
                  {opt.model}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        mt={'16px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">
            Clip Guidance Present
          </InputLabel>
          <Select
            value={watch().clip_guidance_preset}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            {...register('clip_guidance_preset')}
            sx={{
              width: '100%',
            }}
          >
            {clipGuidancePresent.map((opt, index) => (
              <MenuItem key={index} value={opt}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Sampler</InputLabel>
          <Select
            value={watch().sampler}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            {...register('sampler')}
            sx={{
              width: '100%',
            }}
          >
            {viewsArray.map((opt, index) => (
              <MenuItem key={index} value={opt}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {stabilityFields.map(field => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography fontSize={'14px'} fontWeight={500}>
              {field.label}
            </Typography>
            <Slider
              {...register(field.name)}
              step={Number(field.step)}
              min={Number(field.min)}
              max={Number(field.max)}
              value={Number(watch()?.[field.name])}
              onChange={(event, value) => setValue(field.name, value)}
            />
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              color={'rgba(0, 0, 0, 0.60)'}
            >
              {watch()?.[field.name]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default StabilityAiConfigForm;
