import {
  Box,
  Button,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { Gear, LockKey, LockKeyOpen } from '@phosphor-icons/react';
import React, { useState } from 'react';
import PublishModal from '../PublishModal/PublishModal';
import AccessModal from '../PublishModal/AccessModal';
import ShareFormModal from '../PublishModal/ShareFormModal';
import IconButtonTooltip from '../../../components/UI/IconButtonTooltip/IconButtonTooltip';
import SettingsModal from '../PublishModal/SettingsModal';
import useFlowsStore from '../../../store/storeFlows';
import { useLocation } from 'react-router-dom';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

type FormHeaderToolsProps = {
  flow: any;
  form: any;
  onSave: () => void;
  setFormPreviewOpen: (val: boolean) => void;
  setOpenPublishModal: (val: boolean) => void;
  setOpenAccessModal: (val: boolean) => void;
  setOpenShareModal: (val: boolean) => void;
  setUndoRedo: (val: any) => void;
  setForm: (val: any) => void;
  hasChanges: boolean;
  nodes: any;
  openPublishModal: boolean;
  openAccessModal: boolean;
  openShareModal: boolean;
  openSettingsModal: boolean;
  setOpenSettingsModal: (val: boolean) => void;
  onAddFlowOrRemove: (activeFlows: string[]) => void;
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}
const FormHeaderTools = ({
  flow,
  form,
  onSave,
  setFormPreviewOpen,
  setOpenPublishModal,
  setOpenAccessModal,
  setOpenShareModal,
  setUndoRedo,
  setForm,
  hasChanges,
  nodes,
  openPublishModal,
  openAccessModal,
  openShareModal,
  openSettingsModal,
  setOpenSettingsModal,
  onAddFlowOrRemove,
}: FormHeaderToolsProps) => {
  const [activeFlows, setactiveFlows] = useState<string[]>(
    form?.activeFlows || [],
  );
  const { flows } = useFlowsStore(state => state);
  const theme = useTheme();
  const location = useLocation();

  const filteredFlows = location.search?.includes('?spaceId=')
    ? flows?.filter(
        flow => flow?.spaceId === location.search?.split('?spaceId=')[1],
      )
    : flows?.filter(flow => flow.spaceName === null);

  const handleChange = (event: any) => {
    if (event?.target?.value?.length > 3) {
      return;
    }

    onAddFlowOrRemove(event.target.value);

    setactiveFlows(event.target.value);
  };

  return (
    <Box
      sx={{
        border: '1px solid #EAECF0',
        bgcolor: 'white',
        p: '12px 20px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          w: '100%',
        }}
      >
        <Select
          multiple
          // label="Active Flows"
          name="activeFlows"
          input={<OutlinedInput id="select-multiple-chip" />}
          onChange={handleChange}
          value={activeFlows}
          size="small"
          renderValue={selected => (
            <Box sx={{ display: 'flex', gap: 0.5 }}>
              {selected.map(value => {
                const name = filteredFlows?.find(
                  flow => flow.id === value,
                )?.name;

                return (
                  <Chip
                    key={value}
                    sx={{
                      maxWidth: '120px',
                      width: '100%',
                      fontSize: '12px',
                      overflow: 'hidden',
                      height: '24px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    // onDelete={() => handleDelete(value)}
                    label={name}
                  />
                );
              })}
            </Box>
          )}
          sx={{
            width: '100%',
            maxWidth: '500px',
          }}
          // MenuProps={MenuProps}
        >
          {filteredFlows?.map(flow => (
            <MenuItem
              style={getStyles(flow?.id, activeFlows, theme)}
              key={flow.id}
              value={flow.id}
              title={flow?.name}
            >
              {flow?.name ? flow?.name : 'Untitled Form'}
            </MenuItem>
          ))}
        </Select>

        <Box
          sx={{
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
            bgcolor: '#E7F0FF',
            p: '4px 8px',
            borderRadius: '4px',
            border: '1px solid #7388F7',
            cursor: 'pointer',
          }}
          color={'#3650DE'}
          onClick={() => {
            setOpenAccessModal(true);
            onSave();
          }}
        >
          {form?.publishType === 'internal' ? (
            <LockKey size={14} />
          ) : (
            <LockKeyOpen size={14} />
          )}
          <Typography
            sx={{
              lineHeight: '160%',
              textTransform: 'capitalize',
            }}
          >
            {form?.publishType}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
            bgcolor: form?.status === 'draft' ? '#FFF8E0' : '#E7F4E8',
            p: '4px 8px',
            borderRadius: '4px',

            color: form?.status === 'draft' ? '#FDA000' : '#378D3C',
            border: `1px solid  ${
              form?.status === 'draft' ? '#FED54D' : '#80C683'
            }`,
          }}
        >
          <Typography
            sx={{
              lineHeight: '160%',
              textTransform: 'capitalize',
            }}
          >
            {form?.status === 'draft' ? 'Unpublished' : 'Published'}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <IconButtonTooltip
          onClick={() => setOpenSettingsModal(true)}
          title="Settings"
          sx={{
            pr: '16px',
            borderRight: '1px solid #475467',
          }}
        >
          <Gear size={20} />
        </IconButtonTooltip>

        <Button
          onClick={() => {
            setFormPreviewOpen(true);
            onSave();
          }}
          variant="text"
        >
          Preview
        </Button>

        <PublishModal
          show={openPublishModal}
          nodes={nodes}
          onHide={() => setOpenPublishModal(false)}
          form={form}
          setUndoRedo={setUndoRedo}
          setForm={setForm}
          hasChanges={hasChanges}
        />

        <AccessModal
          show={openAccessModal}
          onHide={() => setOpenAccessModal(false)}
          form={form}
          setForm={setForm}
        />
        <SettingsModal
          show={openSettingsModal}
          onHide={() => setOpenSettingsModal(false)}
          form={form}
          setForm={setForm}
        />

        <ShareFormModal
          show={openShareModal}
          onHide={() => setOpenShareModal(false)}
          form={form}
        />

        <Button
          onClick={() => {
            setOpenShareModal(true);
            onSave();
          }}
          variant="outlined"
        >
          Share
        </Button>
        <Button
          onClick={() => {
            setOpenPublishModal(true);
            onSave();
          }}
          variant="contained"
        >
          Publish {hasChanges ? 'Changes' : ''}
        </Button>
      </Box>
    </Box>
  );
};

export default FormHeaderTools;
