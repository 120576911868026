import { Box, useMediaQuery, useTheme } from '@mui/material';
import ModalSidebar from './ModalSidebar';
import { useCallback, useEffect, useState } from 'react';
import { CompanySettings, ProfileSettings } from './components';
import { useAuthStore } from '../../store/storeAuth';
import KitModal from '../UI/KitModal';
import AiConfig from '../settings/AiConfig/AiConfig';
import Company from '../settings/Company';
import Billing from '../settings/Pricing/Billing';
import Pricing from '../settings/Pricing/Pricing';
import TokenUsage from '../TokenUsage/TokenUsage';
import { useLocation } from 'react-router-dom';
import Integrations from '../Integrations/Integrations';
import Flows from '../Flows/Flows';
import AiTechnologies from '../AiTechonologies/AiTechnologies';
import Spaces from '../Spaces/Spaces';
import { CompanyService } from '../../service/CompanyService';
import { getAPIErrorMessage } from '../../helpers/helpers';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';

type ISettingsModal = {
  open: boolean;
  onClose: () => void;
  activeItem?: string;
};

export default function SettingsModal({
  open,
  onClose,
  activeItem,
}: ISettingsModal) {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(
    activeItem || 'profileSettings',
  );
  const { setNotification } = useNotificationStore((state: any) => state);

  const [pricingDefaultState, setPricingDefaultState] = useState(false);
  const { user: authUser, setAuthUser } = useAuthStore((state: any) => state);
  const [company, setCompany] = useState<any>();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        if (authUser?.selectedCompanyId) {
          const res = await CompanyService.getOneCompany(
            authUser?.selectedCompanyId,
          );
          // console.log('resGetOneIndex:', res.data);
          setCompany(res.data);
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };
    fetchCompanies();
  }, [authUser?.selectedCompanyId]);

  const componentsMapping: { [key: string]: React.ReactNode } = {
    profileSettings: (
      <ProfileSettings
        authUser={authUser!}
        setSelectedMenuItem={setSelectedMenuItem}
        companyData={company}
      />
    ),
    organizationSettings: (
      <CompanySettings
        authUser={authUser!}
        setSelectedMenuItem={setSelectedMenuItem}
        companyData={company}
      />
    ),
    billing: (
      <Billing authUser={authUser!} setSelectedMenuItem={setSelectedMenuItem} />
    ),
    aiConfigurations: (
      <AiConfig
        authUser={authUser!}
        // setSelectedMenuItem={setSelectedMenuItem}
      />
    ),
    companyUsers: (
      <Company
        authUser={authUser}
        setSelectedMenuItem={setSelectedMenuItem}
        companyData={company}
        setAuthUser={setAuthUser}
      />
    ),
    pricing: (
      <Pricing
        setPricingDefaultState={setPricingDefaultState}
        pricingDefaultState={pricingDefaultState}
      />
    ),
    tokenUsage: <TokenUsage authUser={authUser!} />,
    aiTechnologies: (
      <AiTechnologies
        authUser={authUser!}
        setSelectedMenuItem={setSelectedMenuItem}
      />
    ),
    integration: (
      <Integrations
        authUser={authUser!}
        setSelectedMenuItem={setSelectedMenuItem}
      />
    ),
    flows: <Flows />,
    spaces: <Spaces />,
  };

  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('pricing=true')) {
      setSelectedMenuItem('pricing');
    }

    if (location.search.includes('createCompany=true')) {
      setSelectedMenuItem('companyUsers');
    }

    if (location.search.includes('space=true')) {
      setSelectedMenuItem('spaces');
    }
  }, [activeItem, location]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <KitModal
      show={open}
      onHide={onClose}
      maxWidth="xl"
      fullWidth
      showCloseButton={true}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '100%',
        },
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialogActions-root': {
          padding: '0px',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          overflowX: isMobile ? 'hidden' : 'auto',
          overflowY: isMobile ? 'auto' : 'hidden',
          // columnGap: '5px',
        }}
      >
        <ModalSidebar
          key={selectedMenuItem}
          current={selectedMenuItem}
          setCurrentNav={setSelectedMenuItem}
          setPricingDefaultState={setPricingDefaultState}
        />

        <Box
          sx={{
            // paddingX: "18px",
            // paddingY: "20px",
            // padding: '24px 16px',
            width: '100%',
            height: 'calc(100vh - 90px)',
            overflowX: 'auto',
          }}
        >
          {componentsMapping[selectedMenuItem]}
        </Box>
      </Box>
    </KitModal>
  );
}
