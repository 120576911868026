import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class BuildWithApiService extends ApiService {
  static pathName = '/build-with-api';

  static free = (body: {
    url: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/free`, body);
  };

  static domain = (body: {
    url: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/domain`, body);
  };

  static technology = (body: {
    technology: string;
    since: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/technology`, body);
  };

  static relationship = (body: {
    url: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/relationship`, body);
  };

  static keywords = (body: {
    url: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/keywords`, body);
  };

  static trends = (body: {
    technology: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/trends`, body);
  };

  static companyToUrl = (body: {
    companyName: string;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/company-to-url`, body);
  };
  static trust = (body: {
    url: string;
    words: string;
    live: boolean;
    responseFormat: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/trust`, body);
  };
}
