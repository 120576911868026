import { IconButton } from '@mui/material';
import { PaintBrush, Question } from '@phosphor-icons/react';
import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Connection,
  ControlButton,
  Controls,
  NodeMouseHandler,
  OnConnect,
  OnEdgesDelete,
  useReactFlow,
  useViewport,
} from 'reactflow';
import ColorModal from '../CoreNodeDIsplay/ColorModal';
import styled from 'styled-components';
import { edgeTypes, nodeTypes } from '../../util/nodeTypes';
import { useAuthStore } from '../../store/storeAuth';
import ContextMenu, { ContextMenuProps } from '../ContextMenu';

interface ExtendNode extends Node {
  id: string;
  position?: {
    x: number;
    y: number;
  };
  selected: boolean;
  data?: any;
  // parentNode: ParentNode | null;
}

const StyledBackground = styled(Background)<{
  background: string;
}>`
  background: ${props => props.background};
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const proOptions = { hideAttribution: true };

const ReactFlowBody = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  setReactFlowInstance,
  onConnect,
  onEdgeDelete,
  onEdgeUpdate,
  onEdgeUpdateStart,
  onEdgeUpdateEnd,
  onDragOver,
  onDrop,
  updateNodeEdge,
  setUpdateNodeEdge,
  onNodeDragStop,
  user,
  isValidConnection,
  onAddNode,
  onNodeSelected,
  handlePasteNode,
  onMouseMove,
}: any) => {
  const [type, setType] = useState<string>('paste');

  const [colors, setColors] = useState(user?.backgroundColor ?? []);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const { setAuthUser } = useAuthStore(state => state);
  const theme = user.theme;
  const { setNodes } = useReactFlow();
  const { zoom } = useViewport(); // Access zoom from useViewport

  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  // console.log(x, y);
  // Update mouse position relative to the viewport
  const onMouseMoveDetect = useCallback(
    (event: MouseEvent) => {
      const clientX = event.clientX;
      const clientY = event.clientY;

      // Calculate mouse position relative to the viewport
      const viewportX = clientX / zoom;
      const viewportY = clientY / zoom;

      setMousePosition({ x: viewportX, y: viewportY });
    },
    [zoom],
  );

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMoveDetect);

    return () => {
      window.removeEventListener('mousemove', onMouseMoveDetect);
    };
  }, [onMouseMoveDetect]);

  const gradientStyle = {
    background:
      colors?.length <= 1
        ? `${colors[0]}`
        : `linear-gradient(-45deg, ${colors.join(', ')})`,
  };

  const [menu, setMenu] = useState<Partial<ContextMenuProps>>({});

  const handleCopyNode = useCallback(
    (node: any) => {
      const selectedNode = nodes.find((node: ExtendNode) => node.selected);
      if (selectedNode) {
        // console.log('selectedNode:', selectedNode);

        if (selectedNode.id && selectedNode.id.startsWith(excludedIdPrefix)) {
          // console.log(
          //   'Selected node ID starts with the excluded prefix. Skipping copy operation.',
          // );
          return;
        }

        const { text, previewResponses, ...rest } = selectedNode;

        const nodeToCopy = {
          ...rest,
          data: {
            ...rest.data,
            text: null,
            previewResponses: null,
            promptData: null,
            previewIndex: 0,
          },
        };

        setType('copy');
        onNodeSelected(nodeToCopy);
      } else {
        console.log('No node selected.');
      }
    },
    [nodes],
  );

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMoveDetect);

    return () => {
      window.removeEventListener('mousemove', onMouseMoveDetect);
    };
  }, [onMouseMoveDetect]);

  const excludedIdPrefix = 'flow-global-starter';

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isInput =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA' ||
        (activeElement as HTMLElement)?.contentEditable === 'true';

      if (!isInput) {
        if (event.key === 'c' && event.metaKey) {
          handleCopyNode(event);
        } else if (event.key === 'v' && event.metaKey) {
          handlePasteNode(event);
        }
      }
    },
    [handleCopyNode, handlePasteNode],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onNodeClick: NodeMouseHandler = useCallback(
    (_, clicked) => {
      window.setTimeout(() => {
        setNodes(prev =>
          prev.map(node =>
            node.id === clicked.id
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    selectedBy:
                      node?.selected === false
                        ? null
                        : node?.selected && user?.id === node?.data?.selectedBy
                        ? user.id
                        : !node?.data?.selectedBy
                        ? user?.id
                        : node?.data?.selectedBy,
                  },
                }
              : node,
          ),
        );
      }, 500);
    },
    [setNodes, nodes],
  );

  // CLICK NODE CLICK
  const onNodeContextMenu = useCallback(
    (event: React.MouseEvent, node: any) => {
      const target = event.target as HTMLElement;

      const element = target.closest('[role="presentation"]');

      const isModal = target.getAttribute('role') === 'presentation';
      const modalContent = target.closest('.MuiDialogContent-root');

      const isInputOrTextarea =
        target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
      if (isModal || isInputOrTextarea || element || modalContent) {
        setMenu({});
        setType('');
        onNodeSelected(null);
        return; // Allow the default context menu
      }
      event.preventDefault(); // Prevent default browser menu

      setType('copy');
      onNodeSelected(node);
      setMenu({
        id: node.id,
        top: event.clientY - 4,
        left: event.clientX - 4,
        type: 'copy',
      });
    },
    [onNodeSelected],
  );

  const onPaneClick = useCallback(() => {
    setMenu({});
  }, []);

  return (
    <ReactFlow
      style={{
        background: theme === 'dark' ? '#475467' : '#E7F0FF',
      }}
      className={theme === 'dark' ? 'dark-theme' : 'light-theme'}
      nodes={nodes}
      edges={edges}
      onNodesChange={changes => {
        if (updateNodeEdge.initial) {
          onNodesChange(changes);

          return setUpdateNodeEdge({
            ...updateNodeEdge,
            initial: false,
          });
        }
        if (changes[0].type !== 'remove') {
          onNodesChange(changes);

          if (changes[0].type === 'position' && changes[0].dragging === true) {
            setUpdateNodeEdge({
              type: changes?.[0]?.type,
              id: changes?.[0]?.id,
              data: changes?.[0]?.position!,
              initial: false,
            });
          }
        }

        if (changes[0].type === 'remove') {
        }
      }}
      onNodeClick={onNodeClick}
      onEdgeClick={e => e.stopPropagation()}
      onEdgesChange={e => {
        onEdgesChange(e);
      }}
      snapToGrid
      onEdgesDelete={onEdgeDelete as OnEdgesDelete}
      onEdgeUpdate={onEdgeUpdate}
      onEdgeUpdateStart={onEdgeUpdateStart}
      onEdgeUpdateEnd={onEdgeUpdateEnd}
      onConnect={onConnect as OnConnect}
      nodeTypes={nodeTypes as any}
      onPointerMove={onMouseMove}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onInit={setReactFlowInstance}
      isValidConnection={(params: Connection) =>
        isValidConnection(params) as boolean
      }
      onNodeDragStop={(event, node) => onNodeDragStop(node)}
      proOptions={proOptions}
      fitView
      edgeTypes={edgeTypes as any}
      minZoom={0.03}
      onPaneClick={onPaneClick}
      onNodeContextMenu={onNodeContextMenu}
      // onPaneContextMenu={onPaneContextMenu}
      // onNodeContextMenu={onNodeContextMenu}
      // onBlur={() => {
      //   setMenu({});
      // }}
    >
      <Controls
        position="bottom-right"
        style={{
          display: 'flex',
          background: '#fff',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '5px',
          padding: '8px',
          gap: '24px',
          // size: '40px',
        }}
      >
        <ControlButton
          onClick={() => {
            setColorModalOpen(true);
          }}
        >
          <PaintBrush style={{}} size={20} />
        </ControlButton>
        <IconButton
          id="my_custom_link"
          onClick={() => {
            // setColorModalOpen(true);
          }}
        >
          <Question weight="fill" color="#000000" size={20} />
        </IconButton>
      </Controls>
      <ColorModal
        colorModalOpen={colorModalOpen}
        setColorModalOpen={setColorModalOpen}
        colors={colors}
        setColors={setColors}
        setAuthUser={setAuthUser}
      />

      {/* <MiniMapStyled /> */}
      <StyledBackground
        gap={12}
        size={1}
        background={gradientStyle.background}
      />

      {menu && (
        <ContextMenu
          id={menu.id as string}
          top={menu.top || 0}
          left={menu.left || 0}
          setMenu={setMenu}
          onCopy={handleCopyNode}
          onAddNode={onAddNode}
          onPaste={() => console.log('Pasting node reactflowbody')}
          onDelete={() => console.log('Deleting node')}
          type={type}
          setType={setType}
        />
      )}
    </ReactFlow>
  );
};

export default ReactFlowBody;
