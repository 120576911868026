import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AppBar,
  Typography,
  IconButton,
  Box,
  Tabs,
  Tab,
  Button,
  Popover,
  Tooltip,
} from '@mui/material';
import {
  BoundingBox,
  CaretDown,
  PencilSimpleLine,
  X,
  Link as LinkIcon,
  Copy,
  Trash,
} from '@phosphor-icons/react';
import { User } from '../../util/Types/User';
import { useStoreTabs } from '../../store/storeTabs';
import useFlowsStore from '../../store/storeFlows';
import { FlowService } from '../../service/FlowService';
import { useNotificationStore } from '../../store/storeNotifications';
import './Navbar.scss';
import { UserService } from '../../service/UserService';
import { useAuthStore } from '../../store/storeAuth';

type Props = {
  authUser: User | null;

  setCreateOrEditFLow: (value: {
    isSharing: boolean;
    isEditing: boolean;
    type?: string;
    flow: {
      id: string;
      name: string;
      allFlow?: any;
    };
    folderId: string;
  }) => void;

  setOpenAddFlowModal: (value: boolean) => void;

  onCreateFlow: (
    name: string,
    isEditing?: boolean,
    id?: string,
    folderId?: string,
    isCopy?: boolean,
    type?: string,
    spaceId?: string,
  ) => void;

  setAuthuser: (values: User) => void;
  setFolders: (values: any) => void;
};

type TabProps = {
  flowName: string;
  flowId: string;
};

const Navbar = ({
  authUser,
  setCreateOrEditFLow,
  setOpenAddFlowModal,
  onCreateFlow,
  setAuthuser,
  setFolders,
}: Props) => {
  const { flows } = useFlowsStore(state => state);

  const { folders } = useAuthStore(state => state);
  const setNotification = useNotificationStore(state => state.setNotification);
  const navigate = useNavigate();
  const location = useLocation();
  const { flowId } = useParams();

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverType, setPopoverType] = useState('');
  const [hoveredTab, setHoveredTab] = useState<number | null>(null);

  const tabs = useStoreTabs((state: any) => state.tabs);
  const removeTab = useStoreTabs((state: any) => state.removeTab);

  const activeFlow = flows.find(flow => flow.id === flowId);
  const reversedTabs = tabs.slice().reverse();

  const [folderId, setFolderId] = useState('');
  const [typeFlow, setTypeFlow] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('folderId');
    const typeFlowParam = params.get('type');
    if (id) {
      setFolderId(id);
    }
    if (typeFlowParam) {
      setTypeFlow(typeFlowParam);
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // if (hasUnsavedChanges) return;
    setValue(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverType(type);
  };

  const openUpdateFlowModal = (
    flow: { id: string; name: string },
    folderId: any,
    isSharing: boolean,
    isEditing: boolean,
  ) => {
    setCreateOrEditFLow({
      isSharing: isSharing,
      isEditing: isEditing,
      flow,
      folderId: folderId,
    });
    setOpenAddFlowModal(true);
    handleClose();
  };

  const onDelete = async () => {
    try {
      const result = window.confirm(
        'Are you sure you want to delete the flow?',
      );

      if (!result) return;

      await FlowService.deleteFlow(activeFlow?.id ?? '');
      setAuthuser({
        ...authUser!,
      });

      const modifyFolders = folders.map(folder => {
        return {
          ...folder,
          flows: folder.flows.filter(flow => flow.id !== flowId),
        };
      });

      setFolders(modifyFolders);

      const currentFlowId = location.pathname.split('/')[2];
      const newTabs: TabProps[] = reversedTabs.filter(
        (tab: TabProps) => tab.flowId !== flowId,
      );
      removeTab(flowId);

      if (newTabs.length === 0) {
        navigate('/');
      } else {
        if (currentFlowId === flowId) navigate(`/flow/${newTabs[0]?.flowId}`);
      }
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong , deleting failed',
      });
    }
  };

  const closeTab = async (e: any, tabId: string) => {
    e.stopPropagation();

    const currentFlowId = location.pathname.split('/')[2];
    const newTabs: TabProps[] = reversedTabs.filter(
      (tab: TabProps) => tab.flowId !== tabId,
    );

    removeTab(tabId);

    await UserService.updateUser({
      tabs: newTabs,
    });

    if (newTabs.length === 0) {
      navigate('/');
    } else {
      if (currentFlowId === tabId) navigate(`/flow/${newTabs[0]?.flowId}`);
    }
  };

  const hasTabs = tabs.length > 1;

  if (
    !new RegExp(
      /^\/flow|spaceFlow\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
    ).test(location.pathname)
  ) {
    return null;
  }

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: authUser?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
        boxShadow:
          authUser?.theme === 'dark'
            ? '0px 0px 3px 0px rgba(255, 255, 255, 0.5)'
            : '0px 0px 3px 0px rgba(0, 0, 0, 0.25)',
      }}
      className="navbar"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {hasTabs && (
          <Box
            sx={{
              width: '100%',
              background: authUser?.theme === 'dark' ? '#101828' : '#F2F4F7',
              display: 'flex',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              sx={{
                minHeight: '20px',
              }}
            >
              {reversedTabs?.map((tab: TabProps, index: number) => (
                <Tooltip title={tab.flowName} key={index}>
                  <Tab
                    onMouseEnter={() => setHoveredTab(index)}
                    onMouseLeave={() => setHoveredTab(null)}
                    style={{
                      borderRight: `0.1px solid ${
                        authUser?.theme === 'dark' ? '#344054' : '#D0D5DD'
                      }`,
                      padding: '8px 16px',
                      minHeight: '28px',
                      borderTopRightRadius: '4px',
                      borderTopLeftRadius:
                        tab.flowId !== flowId ? '4px' : '0px',
                      backgroundColor:
                        tab.flowId !== flowId
                          ? authUser?.theme === 'dark'
                            ? '#101828'
                            : '#F2F4F7'
                          : authUser?.theme === 'dark'
                          ? '#344054'
                          : '#EAECF0',
                    }}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          position: 'relative',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          fontSize={'12px'}
                          sx={{
                            width: '80px',
                            overflow: 'hidden',
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: 400,
                            textTransform: 'capitalize',
                            color:
                              tab.flowId !== flowId
                                ? authUser?.theme === 'dark'
                                  ? '#EAECF0'
                                  : '#667085'
                                : '#546BEA',
                          }}
                        >
                          {tab?.flowName}
                        </Typography>

                        <Box
                          sx={{
                            borderRadius: 28,
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                          className={`${value !== index && 'top-menu-icons'}`}
                          onClick={(e: any) => closeTab(e, tab.flowId)}
                        >
                          {(hoveredTab === index || tab.flowId === flowId) && (
                            <X
                              size={16}
                              style={{
                                cursor: 'pointer',
                                zIndex: '20',
                                color:
                                  tab.flowId !== flowId
                                    ? authUser?.theme === 'dark'
                                      ? '#F2F4F7'
                                      : '#667085'
                                    : '#546BEA',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    }
                    icon={
                      <BoundingBox
                        size={16}
                        style={{
                          color:
                            tab.flowId !== flowId
                              ? authUser?.theme === 'dark'
                                ? '#F2F4F7'
                                : '#667085'
                              : '#546BEA',
                        }}
                      />
                    }
                    iconPosition="start"
                    onClick={() => navigate(`/flow/${tab?.flowId}`)}
                  />
                </Tooltip>
              ))}
            </Tabs>
            {tabs.length >= 6 && (
              <Box
                sx={{
                  maxWidth: '40px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={e => {
                    handleClick(e, 'tabs');
                  }}
                >
                  <CaretDown color="#667085" size={20} />
                </IconButton>
              </Box>
            )}
          </Box>
        )}

        <Box
          sx={{
            width: '100%',
            height: '2.875rem',
            background: authUser?.theme === 'dark' ? '#344054' : '#EAECF0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={e => {
              handleClick(e, 'flowEditing');
            }}
          >
            <Typography
              title={activeFlow?.name}
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                color: authUser?.theme === 'dark' ? '#D0D5DD' : '#3650DE',
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <span
                style={{
                  maxWidth: '25rem',
                  paddingRight: '8px',
                  flex: '1',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {activeFlow?.name}
              </span>
            </Typography>

            <CaretDown
              size={16}
              color={authUser?.theme === 'dark' ? '#D0D5DD' : '#3650DE'}
            />
          </Button>
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          handleClose();
          setTimeout(() => {
            setPopoverType('');
          }, 200);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverType === 'flowEditing' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: '8px',
              background: authUser?.theme === 'dark' ? '#101828' : '#FFFF',
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                openUpdateFlowModal(
                  { id: activeFlow?.id ?? '', name: activeFlow?.name ?? '' },
                  '',
                  false,
                  true,
                );
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <PencilSimpleLine
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Rename
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                openUpdateFlowModal(
                  { id: activeFlow?.id ?? '', name: activeFlow?.name ?? '' },
                  '',
                  true,
                  false,
                );
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <LinkIcon
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Share Flow
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                onCreateFlow(
                  activeFlow?.name ?? '',
                  true,
                  activeFlow?.id,
                  folderId,
                  true,
                  typeFlow,
                  activeFlow?.spaceId,
                );
                handleClose();
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Copy
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Duplicate
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                color: '#b91c1c',
                px: '8px',
                gap: '8px',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? '#FFADB7' : '#fecdd3',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                onDelete();
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Trash size={20} color="#b91c1c" />
                <Typography>Delete Flow</Typography>
              </Box>
            </Button>
          </Box>
        )}
        {popoverType === 'tabs' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <Typography fontSize={'8px'} px={'8px'}>
              All tabs
            </Typography>
            {tabs?.map((tab: any, i: any) => (
              <Typography
                fontSize={'12px'}
                lineHeight={'20px'}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  width: '100%',
                  px: '8px',
                  backgroundColor: flowId === tab?.flowId ? '#667085' : '',
                }}
                key={i}
                onClick={() => {
                  navigate(`/flow/${tab?.flowId}`);
                }}
              >
                <BoundingBox />
                {tab.flowName}
              </Typography>
            ))}
          </Box>
        )}
      </Popover>
    </AppBar>
  );
};

export default Navbar;
