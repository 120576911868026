import { Node } from 'reactflow';
import { FlowService } from '../../service/FlowService';
import { nodeSchema } from '../myFunctionsCoreDisplay';
import { handleChanges } from './changeTypes';

export const nodeDataAutoSaveDynamic = ({
  setEdges,
  setNodeState,
  id,
  flowTriggerData,
  flowId,
  objectData,
  setSchema,
  newEdges,
  changeType = 'default',
  connectData,
  deleteEdges,
  updateEdge,
  objectCallerData,
  setSaving,
}: any) => {
  const edges = [...newEdges];

  setNodeState((node: Node[]) => {
    const nds = handleChanges({
      id,
      objectData,
      changeType,
      connectData,
      deleteEdges,
      updateEdge,
      objectCallerData,
      edges,
      flowTriggerData,
      node,
    }).nds;

    nodeSchema(nds, setSchema);

    return nds;
  });

  const onSaveNodeData = async () => {
    const saving = {
      finished: false,
      lastSaved: new Date().toISOString(),
      status: 'loading',
      flowId: flowId,
    };

    setSaving(saving);

    try {
      const response = await FlowService.updateNodeData(flowId, {
        id,
        data: { ...objectData, isEmptyState: false },
        changeType,
      });

      const saving = {
        finished: true,
        lastSaved: new Date().toISOString(),
        status: 'saved',
        flowId: flowId,
      };

      setSaving(saving);
    } catch (error) {
      const saving = {
        finished: false,
        lastSaved: new Date().toISOString(),
        status: 'error',
        flowId: flowId,
      };

      setSaving(saving);
    }
  };
  if (flowId) {
    onSaveNodeData();
  }
};

export const nodePassOnlyText = {
  imageReaderNode: 'inputPreview',
  audioReaderNode: 'inputPreview',
  documentSummarizer: 'inputPreview',
  arrayNode: 'content',
  imageGenerator: 'inputPreview',
  mathFunction: 'varId',
  fluxBox: 'varId',
  fileSave: 'varId',
};

export const nodePassOnlyTextRealHandleId = {
  imageReaderNode: 'inputPreview',
  audioReaderNode: 'inputPreview',
  documentSummarizer: 'inputPreview',
  arrayNode: 'content',
  imageGenerator: 'inputPreview',
  mathFunction: 'varId',
  fluxBox: 'varId',
  fileSave: 'varId',
};
