import React, { useEffect, useState } from 'react';
import { Socket as SocketType, io } from 'socket.io-client';
import { useAuthStore } from '../../store/storeAuth';
import useFlowsStore from '../../store/storeFlows';
import { modifyTheStringifiedData } from '../../util/modifyTheStringifiedData';
import { useNotificationStore } from '../../store/storeNotifications';
import { AuthService } from '../../service/AuthService';
import { useSocketStore } from '../../store/storeSocket';

interface SocketEvent {
  event: string;
  modifyData?: boolean;
}

const Socket: React.FC = () => {
  const authUser = useAuthStore(state => state.user);
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const { setNodeState, socketNodeResponse, setSocketNodeResponse } =
    useFlowsStore(state => state);

  const setSocket = useSocketStore(state => state.setSocket);

  const [socketOn, setSocketOn] = useState<{
    [key: string]: { data: any; started: boolean };
  }>({
    serpTrends: { data: '', started: false },
    census: { data: '', started: false },
    gpt4VisionPreview: { data: '', started: false },
    musicGen: { data: '', started: false },
    voice: { data: '', started: false },
    transcription: { data: '', started: false },
    vectorSearch: { data: '', started: false },
    secApi: { data: '', started: false },
  });
  const setNotification = useNotificationStore(state => state.setNotification);

  useEffect(() => {
    let socket: SocketType;

    const socketEvents: SocketEvent[] = [
      { event: 'response-summarize' },
      { event: 'response-api-v2' },
      { event: 'response-serp-trends', modifyData: true },
      { event: 'response-serp-trends-now', modifyData: true },
      { event: 'response-music-gen' },
      { event: 'response-census-data', modifyData: true },
      { event: 'response-gpt4-vision-preview' },
      { event: 'response-voice' },
      { event: 'response-eleven-labs-text-to-speech-stream' },
      { event: 'response-eleven-labs-text-to-speech' },
      { event: 'response-transcription' },
      { event: 'response-vector-search' },
      { event: 'sec-query', modifyData: true },
      { event: 'sec-full-text-search', modifyData: true },
      { event: 'sec-extractor', modifyData: true },
      { event: 'sec-trading', modifyData: true },
      { event: 'sec-form-d', modifyData: true },
      { event: 'sec-firm-search', modifyData: true },
      { event: 'sec-adv-direct-owners', modifyData: true },
      { event: 'sec-indirect-owners', modifyData: true },
      { event: 'sec-financial-industry-affiliations', modifyData: true },
      { event: 'sec-private-funds', modifyData: true },
      { event: 'sec-individual-registered-adviser', modifyData: true },
      { event: 'sec-brochure-api', modifyData: true },
      { event: 'sec-cik', modifyData: true },
      { event: 'sec-ticker', modifyData: true },
      { event: 'sec-cusip', modifyData: true },
      { event: 'response-bright-data', modifyData: true },
    ];
    if (authUser?.id) {
      socket = io(process.env.REACT_APP_API_URL as string);
      socket.on('connect', () => {
        socket.emit('new-user', { id: authUser?.id });
        setSocket(socket);
      });

      socket.on(
        'response-summarize',
        (resData: { data: { message: string } }) => {
          const text = resData.data.message;
          console.log(text);

          setSocketOn({
            ...socketOn,
            serpTrends: {
              data: text,
              started: true,
            },
          });
        },
      );

      socket.on('response-serp-trends', (data: any) => {
        setSocketOn({
          ...socketOn,
          serpTrends: {
            data: modifyTheStringifiedData(data.data),
            started: true,
          },
        });
      });

      socket.on('response-serp-trends-now', (data: any) => {
        setSocketOn({
          ...socketOn,
          serpTrends: {
            data: modifyTheStringifiedData(data.data),
            started: true,
          },
        });
      });
      socket.on('response-music-gen', data => {
        setSocketOn({
          ...socketOn,
          musicGen: {
            data: data.data && !data.error ? data.data : data.error,
            started: true,
          },
        });
      });

      socket.on('response-census-data', (data: any) => {
        setSocketOn({
          ...socketOn,
          census: {
            data: JSON.stringify(data),
            started: true,
          },
        });
      });

      socket.on('response-gpt4-vision-preview', (data: any) => {
        setSocketOn({
          ...socketOn,
          gpt4VisionPreview: {
            data: data.data,
            started: true,
          },
        });
      });
      socket.on('response-voice', data => {
        setSocketOn({
          ...socketOn,
          voice: {
            data: data.data,
            started: true,
          },
        });
      });

      socket.on('response-eleven-labs-text-to-speech-stream', data => {
        setSocketOn({
          ...socketOn,
          voice: {
            data: data.data?.src,
            started: true,
          },
        });
      });

      socket.on('response-eleven-labs-text-to-speech', data => {
        setSocketOn({
          ...socketOn,
          voice: {
            data: data.data,
            started: true,
          },
        });
      });

      socket.on('response-transcription', data => {
        setSocketOn({
          ...socketOn,
          transcription: {
            data: data.data,
            started: true,
          },
        });
      });

      socket.on('response-vector-search', (resData: any) => {
        setSocketOn({
          ...socketOn,
          vectorSearch: {
            data: resData.data?.message,
            started: true,
          },
        });
      });

      socket.on('sec-query', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-full-text-search', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-extractor', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-trading', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-form-d', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-firm-search', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-adv-direct-owners', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-indirect-owners', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-financial-industry-affiliations', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-private-funds', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-individual-registered-adviser', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-brochure-api', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-cik', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-ticker', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-cusip', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });
      socket.on('sec-company-name', (data: any) => {
        setSocketOn({
          ...socketOn,
          secApi: {
            data: {
              message: modifyTheStringifiedData(data.data),
              usedTokens: data.data.usedTokens,
            },
            started: true,
          },
        });
      });

      socket.on('response-bright-data', (data: any) => {
        if (data.data.error) {
          setNotification({
            type: 'error',
            message:
              data.data.error ||
              `Unable to connect with BrightData at this moment`,
          });

          return;
        }

        setSocketOn({
          ...socketOn,
          census: {
            data: data.data,
            started: true,
          },
        });
      });
    }

    return () => {
      socketEvents.forEach(({ event, modifyData }) => {
        socket?.off(event);
      });
    };
  }, [authUser?.id]);

  useEffect(() => {
    const handleSocketNodeResponse = () => {
      const updateNodeState = (eventName: string) => {
        if (
          socketOn[eventName].started &&
          socketOn[eventName].data &&
          socketNodeResponse?.nodeId &&
          socketNodeResponse?.finished === null
        ) {
          setNodeState((nds: any) =>
            nds?.map((nd: any) => {
              if (nd.id === socketNodeResponse.nodeId) {
                let responses: any[] = [];
                if (nd.data?.previewResponses?.length) {
                  responses = [...nd.data.previewResponses];
                }
                if (responses.length === 10) {
                  responses.shift();
                }

                const data = {
                  [eventName?.includes('voice') ||
                  eventName?.includes('even-labs')
                    ? 'data'
                    : 'text']: socketOn[eventName].data,
                };

                return {
                  ...nd,
                  data: {
                    ...nd.data,
                    ...data,
                    previewResponses: [...responses, socketOn[eventName].data],
                    previewIndex: responses.length,
                  },
                };
              }
              return nd;
            }),
          );
          setSocketOn(prevSocketOn => ({
            ...prevSocketOn,
            [eventName]: { data: '', started: false },
          }));
          setTimeout(() => {
            setSocketNodeResponse(socketNodeResponse.nodeId, true);
          }, 1000);
        }
      };

      const eventsToUpdate = [
        'serpTrends',
        'census',
        'gpt4VisionPreview',
        'musicGen',
        'voice',
        'transcription',
        'vectorSearch',
        'secApi',
      ];
      eventsToUpdate.forEach(eventName => updateNodeState(eventName));
    };
    if (typeof setNodeState === 'function' && socketNodeResponse) {
      handleSocketNodeResponse();
    }
  }, [socketOn, socketNodeResponse]);

  return <></>;
};

export default Socket;
