import {
  Box,
  Button,
  Collapse,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useAuthStore } from '../../../../store/storeAuth';
import {
  CaretDown,
  ChatCircleText,
  Link,
  PencilSimpleLine,
  Plus,
  UsersThree,
  Warning,
  Link as LinkIcon,
  ArrowSquareOut,
  Copy,
  Trash,
  ChatText,
  DotsThreeOutline,
  CaretRight,
  BoundingBox,
} from '@phosphor-icons/react';

import CreateFolderModal from '../PrivateFlows/FolderModal/FolderModal';
import { FolderService } from '../../../../service/FolderService';
import { updateIndexForNewFlowOrFolder } from '../../../../util/folderIndexCreater';
import {
  NotificationType,
  useNotificationStore,
} from '../../../../store/storeNotifications';
import { getAPIErrorMessage } from '../../../../helpers/helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SidebarFolders from '../../SidebarFolders';
import CreateFlowModal from '../../../Flow/CreateFlowModal';
import { FlowService } from '../../../../service/FlowService';
import useFlowsStore from '../../../../store/storeFlows';
import { useStoreTabs } from '../../../../store/storeTabs';
import { useNavigate, useParams } from 'react-router-dom';
import CopySpaceIDModal from './CopySpaceIDModal';
import CopySpaceEmailModal from './CopySpaceEmailModal';
import AddUsersModal from '../../../Spaces/AddUsersModal';
import UsersAddedModal from '../../../Spaces/UsersAddedModal';
import Uploads from '../Uokiads/Uploads';
import FluxObjects from '../FluxObjects/FluxObjects';
import Vector from '../Vector/Vector';
import { ReactComponent as DotsThreeOutlineGrayIcon } from '../../../../assets/icons/DotsThreeOutlineGrayIcon.svg';
import { ReactComponent as DotsThreeOutlineWhiteIcon } from '../../../../assets/icons/DotsThreeOutlineWhiteIcon.svg';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';
interface Props {
  space: any;
  getSpaces: () => Promise<void>;
  modifySpaceObject: (space: any) => void;
  modifySpaceVector: (space: any) => void;
  onOpenModal: () => void;
}

const Space: React.FC<Props> = ({
  space,
  getSpaces,
  modifySpaceObject,
  modifySpaceVector,
  onOpenModal,
}) => {
  const { removeNotification, setNotification } = useNotificationStore(
    state => state,
  );
  const navigate = useNavigate();
  const [chatCollapsed, setChatCollapsed] = useState(false);
  const params = useParams();

  const { user: authUser, setAuthUser } = useAuthStore(state => state);
  const { addFlow, updateFlow } = useFlowsStore();
  const { addTab, updateTab } = useStoreTabs((state: any) => state);
  const spaceId = space.id;
  const [folders, setFolders] = useState(space.folder);
  const [showUsersAddedModal, setShowUsersAddedModal] = useState(false);
  const [collapseFlows, setCollapseFlows] = useState(false);

  const [onOpenCreateFolder, setOnOpenCreateFolder] = useState(false);
  const [folderUpdate, setFolderUpdate] = useState({
    isEditing: false,
    isSubfolder: false,
    isSubfolderEditing: false,
    folder: {
      id: '',
      name: '',
    },
  });
  const [dragStarted, setDragStarted] = useState<any>({
    started: false,
    randomValue: '',
  });
  const [clickedFluxBotstId, setClickedFluxBotstId] = useState<string | null>(
    null,
  );

  const onDragStart = (e: any) => {
    setDragStarted({
      started: true,
      randomValue: Math.random().toString(),
    });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setClickedFluxBotstId(id === clickedFluxBotstId ? null : id);
  };
  const [isSpaceHovered, setIsSpaceHovered] = useState(false);
  const [openSpaceFolders, setOpenSpaceFolders] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showCopySpaceIDModal, setShowCopySpaceIDModal] = useState(false);
  const [showCopySpaceEmailModal, setShowCopySpaceEmailModal] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  //
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [active, setActive] = useState('/');
  const [createOrEditFLow, setCreateOrEditFLow] = useState<{
    isSharing: boolean;
    isEditing: boolean;
    type?: string;
    flow: {
      id: string;
      name: string;
      allFlow?: any;
    };
    folderId: string;
  }>({
    isSharing: false,
    flow: {
      id: '',
      name: '',
    },
    type: '',
    folderId: '',
    isEditing: false,
  });
  const [openAddFlowModal, setOpenAddFlowModal] = useState<boolean>(false);
  const [isFlowModalOpen, setIsFlowModalOpen] = useState({
    isOpen: false,
    flowId: '',
  });
  const { tabs } = useStoreTabs((state: any) => state);

  const onCreateFolderOrUpdate = async (
    name: string,
    isEditing = false,
    id = '',
    isSubfolder = false,
    isSubfolderEditing = false,
  ) => {
    try {
      if (isEditing || isSubfolderEditing) {
        await FolderService.updateFolder(id, { name });

        const res = await FolderService.getOne(id);

        setFolders((folders: any) => {
          let folderIndex = folders.findIndex(
            (folder: any) => folder.id === id,
          );

          const updatedFolders = [...folders];
          updatedFolders[folderIndex] = res.data;

          return updatedFolders;
        });
      } else if (isSubfolder) {
        const responseFolder = await FolderService.createFolder({ name });

        await FolderService.createSubFolder(id, responseFolder.data.id);
      } else {
        const index = updateIndexForNewFlowOrFolder(folders);

        const { data: folder } = await FolderService.createFolderOnSpace({
          name,
          index,
          spaceId,
        });

        setFolders((folders: any) => {
          return [...folders, folder];
        });
      }

      setOnOpenCreateFolder(false);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const onShareFlow = async (
    id: string,
    email: string,
    name: string,
    companyId?: string,
  ) => {
    try {
      let body: any = { email, name };
      if (companyId) {
        body = { email, companyId, name };
      }
      await FlowService.shareFlow(id, body);
      setNotification({
        type: 'success',
        message: 'Flow shared successfully',
      });
      setOpenAddFlowModal(false);
    } catch (error) {
      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const onCreateFlowOrUpdate = async (
    name: string,
    isEditing = false,
    id = '',
    folderId = '',
    onCopy = false,
    type = '',
  ) => {
    try {
      if (isEditing) {
        if (onCopy) {
          const flowResponse = await FlowService.getFlow(id);
          const createResponse = await FlowService.createFlow({
            name: name + '-copy',
            folderId: folderId,
            type: type,
          });
          await FlowService.updateFlow(createResponse.data?.id, {
            nodes: flowResponse.data.nodes,
            edges: flowResponse.data.edges,
          });
          addFlow({
            id: createResponse.data?.id,
            name: createResponse.data?.name,
            index: createResponse.data?.index,
          });
        } else {
          const res = await FlowService.updateFlow(id, { name });

          updateTab({
            flowId: res.data?.id,
            flowName: res.data?.name,
          });
          updateFlow(res.data);
        }
      } else {
        // const index = updateIndexForNewFlowOrFolder(folders, folderId);
        const createResponse = await FlowService.createFlow({
          name,
          folderId: folderId,
          type,
        });
        // addTab({
        //   flowName: createResponse.data?.name,
        //   flowId: createResponse.data?.id,
        //   folderId: folderId,
        // });
        addFlow({
          id: createResponse.data?.id,
          name: createResponse.data?.name,
          index: createResponse.data?.index,
        });
        if (type === 'chat') {
          setAuthUser({
            ...authUser!,
            chatsId: [...authUser?.chatsId!, createResponse.data?.id!],
          });
        }
        navigate(
          `/spaceFlow/${createResponse.data.id}?folderId=${folderId}&type=flow`,
        );
      }

      const res = await FolderService.getOne(folderId);

      setFolders((folders: any) => {
        let folderIndex = folders.findIndex(
          (folder: any) => folder.id === folderId,
        );

        const updatedFolders = [...folders];
        updatedFolders[folderIndex] = res.data;

        return updatedFolders;
      });

      setOpenAddFlowModal(false);
    } catch (error) {}
  };

  const onDeleteFolderSuccess = (folderId: string) => {
    setFolders(folders.filter((folder: any) => folder.id !== folderId));
    // getSpaces();
  };
  const onDeleteFlowSuccess = async (
    folderId: string,
    flowId: string,
    type?: string,
  ) => {
    const folderIndex = folders.findIndex(
      (folder: any) => folder.id === folderId,
    );

    const updatedFlows = folders[folderIndex].flows.filter(
      (flow: any) => flow.id !== flowId,
    );

    await FlowService.deleteFlow(flowId, type);
    setAuthUser({
      ...authUser!,
    });
    if (params.flowId === flowId) {
      navigate('/');
    }
    const updatedFolders = [...folders];
    updatedFolders[folderIndex] = {
      ...updatedFolders[folderIndex],
      flows: updatedFlows,
    };
    setFolders(updatedFolders);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setClickedFluxBotstId(null);
  };

  const onOpenFolderSuccess = (folderId: string, folder: any) => {
    const updatedFolders = [...folders];
    const folderIndex = folders.findIndex(
      (folder: any) => folder.id === folderId,
    );
    updatedFolders[folderIndex] = folder;
    setFolders(updatedFolders);
  };

  const onOpenFolderChat = async (id: string) => {
    if (!id) {
      return setChatCollapsed(true);
    }
    try {
      const res = await FolderService.getOne(id);

      onOpenFolderSuccess(id, res.data);
    } catch (error) {
      setChatCollapsed(false);
      console.log(error);
    } finally {
      setChatCollapsed(true);
    }
  };

  const handleCloseSideBarMobile = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };
  const handleDragAndDrop = async (results: any) => {
    const { source, destination, type } = results;

    setDragStarted({
      started: false,
      randomValue: '',
    });

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    const newFolders = [
      ...(folders?.filter((folder: any) => folder.name !== 'Chatbot') || []),
    ];

    const chatbotFolder = folders?.find(
      (folder: any) => folder.name === 'Chatbot',
    );

    if (source.droppableId === 'ROOT' && destination.droppableId === 'ROOT') {
      const spliceSource = newFolders.splice(source.index - 1, 1);

      if (spliceSource.length > 0) {
        newFolders.splice(destination.index - 1, 0, ...spliceSource);
      }

      const newFoldersWithIndex = newFolders.map((folder, index) => {
        return {
          ...folder,
          index: index + 1,
        };
      });

      setFolders([...(newFoldersWithIndex ?? []), chatbotFolder as any]);

      try {
        await FolderService.updateOrder({
          type: 'ROOT',
          newOrder: newFoldersWithIndex.map(({ id, index }) => ({ id, index })),
        });
      } catch (error) {
        console.log(error);
      }
      return;
    }

    // Handle dragging within the same folder
    if (source.droppableId === destination.droppableId) {
      const findFolder = newFolders.find(
        folder => folder.id === source.droppableId,
      );

      if (!findFolder) return;

      const newFlows = [...(findFolder?.flows || [])];

      const spliceSource = newFlows.splice(source.index - 1, 1);

      if (spliceSource.length > 0) {
        newFlows.splice(destination.index - 1, 0, ...spliceSource);
      }

      const newFlowsWithIndex = newFlows.map((flow, index) => {
        return {
          ...flow,
          index: index + 1,
        };
      });

      const newFoldersWithUpdatedFlows = newFolders.map(folder => {
        if (folder.id === source.droppableId) {
          return {
            ...folder,
            flows: newFlowsWithIndex,
          };
        }
        return folder;
      });

      setFolders([...newFoldersWithUpdatedFlows, chatbotFolder as any]);

      try {
        await FolderService.updateOrder({
          type: 'flowToFlow',
          newOrder: newFlowsWithIndex.map(({ id, index }) => ({
            id,
            index,
          })),
          folderId: source.droppableId,
        });
      } catch (error) {
        console.log(error);
      }
      return;
    }

    // Handle dragging between different folders
    if (source.droppableId !== destination.droppableId) {
      const findSourceFolder = newFolders.find(
        folder => folder.id === source.droppableId,
      );
      const findDestinationFolder = newFolders.find(
        folder => folder.id === destination.droppableId,
      );

      if (!findSourceFolder || !findDestinationFolder) return;

      const newSourceFlows = [...(findSourceFolder?.flows || [])];

      const newDestinationFlows = [...(findDestinationFolder?.flows || [])];

      const spliceSource = newSourceFlows.splice(source.index - 1, 1);

      if (spliceSource.length > 0) {
        if (
          findDestinationFolder?.flows?.length ===
          findDestinationFolder.flowsLength
        ) {
          newDestinationFlows.push(...spliceSource);
        } else {
          newDestinationFlows.splice(destination.index - 1, 0, ...spliceSource);
        }
      }

      const newSourceFlowsWithIndex = newSourceFlows.map((flow, index) => {
        return {
          ...flow,
          index: index + 1,
        };
      });

      const newDestinationFlowsWithIndex = newDestinationFlows.map(
        (flow, index) => {
          return {
            ...flow,
            index: index + 1,
          };
        },
      );

      const newFoldersWithUpdatedFlows = newFolders.map(folder => {
        if (folder.id === source.droppableId) {
          return {
            ...folder,
            flows: newSourceFlowsWithIndex,
          };
        }

        if (folder.id === destination.droppableId) {
          return {
            ...folder,
            flows: newDestinationFlowsWithIndex,
            flowsLength: newDestinationFlows.length,
          };
        }

        return folder;
      });

      setFolders([...newFoldersWithUpdatedFlows, chatbotFolder as any]);

      try {
        await FolderService.updateOrder({
          type: 'flowToFolder',
          sourceFolderId: source.droppableId,
          targetFolderId: destination.droppableId,
          newSourceOrder: newSourceFlowsWithIndex.map(({ id, index }) => ({
            id,
            index,
          })),
          newTargetOrder: newDestinationFlowsWithIndex.map(({ id, index }) => ({
            id,
            index,
          })),
        });
      } catch (error) {
        console.log(error);
      }
    }

    setDragStarted({
      started: false,
      randomValue: '',
    });
  };

  return (
    <>
      {showCopySpaceIDModal && (
        <CopySpaceIDModal
          spaceName={space.name}
          spaceId={space.email}
          onClose={() => setShowCopySpaceIDModal(false)}
        />
      )}

      {showCopySpaceEmailModal && (
        <CopySpaceEmailModal
          spaceName={space.name}
          spaceEmail={space.email}
          onClose={() => setShowCopySpaceEmailModal(false)}
        />
      )}

      {showAddUsersModal && (
        <AddUsersModal
          spaceId={space.id}
          onClose={() => setShowAddUsersModal(false)}
          setShowUsersAddedModal={setShowUsersAddedModal}
          getCompanySpace={getSpaces}
        />
      )}

      {showUsersAddedModal && (
        <UsersAddedModal onClose={() => setShowUsersAddedModal(false)} />
      )}

      <Box
        sx={{
          borderBottom: '1px solid rgba(234, 236, 240, 1)',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 16px',
            width: '92%',
            background: isSpaceHovered ? '#EAECF0' : undefined,
            borderBottom: isSpaceHovered
              ? '1px solid rgba(234, 236, 240, 1)'
              : '1px solid transparent',
          }}
          onMouseOver={() => setIsSpaceHovered(true)}
          onMouseLeave={() => setIsSpaceHovered(false)}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {space.disabled ? (
              <div
                title={`You have disabled  this space. Go to “Settings”, click on spaces to enable it`}
              >
                <Warning size={16} color="rgba(255, 193, 7, 1)" />
              </div>
            ) : null}
            <Typography
              style={{
                paddingLeft: '10px',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                color:
                  authUser?.theme === 'dark'
                    ? '#D0D5DD'
                    : 'rgba(71, 84, 103, 1)',
              }}
              className="menu"
            >
              {space.name}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {isSpaceHovered && (
              <>
                <>
                  <IconButtonTooltip
                    title="Space Options"
                    onClick={e => setAnchorEl(e.currentTarget)}
                  >
                    <DotsThreeOutline size={16} color="#667085" weight="fill" />
                  </IconButtonTooltip>

                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    <Box
                      sx={{
                        padding: '10px',
                        background: 'white',
                        boxShadow: '0 0 3x rgba(0,0,0,.3)',
                        border: '1px solid rgba(208, 213, 221, 1)',
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '150px',
                      }}
                    >
                      {space?.userSpace?.[0]?.role === 'admin' && (
                        <>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              gap: '5px',
                              cursor: 'pointer',
                              padding: '10px 2px',
                              '&:hover': {
                                background: 'rgba(231, 240, 255, 1)',
                              },
                            }}
                            onClick={() => {
                              setAnchorEl(null);
                              onOpenModal();
                              navigate(
                                window.location.pathname +
                                  '?space=true&spaceId=' +
                                  space?.id,
                              );
                            }}
                          >
                            <PencilSimpleLine color="rgba(102, 112, 133, 1)" />
                            <Typography
                              sx={{
                                width: '100%',
                                color: 'rgba(71, 84, 103, 1)',
                              }}
                            >
                              Edit space
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              gap: '5px',
                              cursor: 'pointer',
                              padding: '10px 2px',
                              '&:hover': {
                                background: 'rgba(231, 240, 255, 1)',
                              },
                            }}
                            onClick={() => {
                              setShowAddUsersModal(true);
                              setAnchorEl(null);
                            }}
                          >
                            <UsersThree color="rgba(102, 112, 133, 1)" />
                            <Typography
                              sx={{
                                width: '100%',
                                color: 'rgba(71, 84, 103, 1)',
                              }}
                            >
                              Add Users
                            </Typography>
                          </Box>
                        </>
                      )}
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          gap: '5px',
                          cursor: 'pointer',
                          padding: '10px 2px',
                          '&:hover': {
                            background: 'rgba(231, 240, 255, 1)',
                          },
                        }}
                        onClick={() => {
                          setShowCopySpaceIDModal(true);
                          setAnchorEl(null);
                        }}
                      >
                        <Link color="rgba(102, 112, 133, 1)" />
                        <Typography
                          sx={{
                            width: '100%',
                            color: 'rgba(71, 84, 103, 1)',
                          }}
                        >
                          Copy Space ID
                        </Typography>
                      </Box>
                    </Box>
                  </Popover>
                </>
              </>
            )}

            <IconButtonTooltip
              title="Collapse"
              onClick={() => setOpenSpaceFolders(!openSpaceFolders)}
            >
              {openSpaceFolders ? (
                <CaretRight
                  size={16}
                  color={authUser?.theme === 'dark' ? 'white' : '#667085'}
                />
              ) : (
                <CaretDown
                  size={16}
                  color={
                    openSpaceFolders
                      ? '#546BEA'
                      : authUser?.theme === 'dark'
                      ? 'white'
                      : '#667085'
                  }
                />
              )}
            </IconButtonTooltip>
          </Box>
        </Box>
        <Collapse in={openSpaceFolders} timeout={800}>
          <Box
            className="menu menu--on-hover"
            onClick={() => {
              setCollapseFlows(!collapseFlows);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
              paddingLeft: '15px',
              // width: '14.8rem',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                padding: '4px',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '8px',
                '&:hover': {
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                  borderRadius: '4px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <BoundingBox
                  style={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                    fontSize: '18px',
                  }}
                />
                {sidebarOpen && (
                  <>
                    <Typography
                      className="active-menu"
                      sx={{
                        color:
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                      }}
                    >
                      Flows
                    </Typography>
                  </>
                )}
              </Box>

              <Box sx={{ alignSelf: 'center', display: 'flex', gap: '8px' }}>
                <IconButtonTooltip
                  title="Add Folder"
                  onClick={event => {
                    setOnOpenCreateFolder(true);
                  }}
                >
                  <Plus
                    size={16}
                    className="menu-icons active-menu"
                    style={{
                      color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                      cursor: 'pointer',
                    }}
                  />
                </IconButtonTooltip>

                {sidebarOpen && (
                  <IconButtonTooltip title="Collapse">
                    {!collapseFlows ? (
                      <CaretDown
                        className="active-menu"
                        size={16}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                    ) : (
                      <CaretRight
                        className="active-menu"
                        size={16}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                    )}
                  </IconButtonTooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Collapse in={collapseFlows}>
            <Box
              style={{
                marginLeft: sidebarOpen ? '20px' : 10,
              }}
            >
              <Box
                style={{
                  marginLeft: sidebarOpen ? '15px' : 0,
                }}
              >
                <DragDropContext
                  onDragEnd={handleDragAndDrop}
                  onDragStart={onDragStart}
                  onDragUpdate={e => {
                    setDragStarted({
                      randomValue: e.destination?.droppableId,
                      started: e.source?.droppableId,
                    });
                  }}
                >
                  <Droppable isDropDisabled droppableId="ROOT" type="group">
                    {provided => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <>
                          {folders
                            ?.filter(
                              (folder: any) => folder?.name !== 'Chatbot',
                            )
                            ?.map((folder: any, index: number) => {
                              return (
                                <Draggable
                                  draggableId={folder.id}
                                  index={index}
                                  key={folder.id}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={`item-container`}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                    >
                                      <SidebarFolders
                                        theme={authUser?.theme}
                                        key={folder.id}
                                        active={active}
                                        folder={folder}
                                        space={space}
                                        dragStarted={dragStarted}
                                        setFolderUpdate={setFolderUpdate}
                                        isSpace={true}
                                        setCreateOrEditFLow={
                                          setCreateOrEditFLow
                                        }
                                        setOpenAddFlowModal={
                                          setOpenAddFlowModal
                                        }
                                        setOnOpenCreateFolder={
                                          setOnOpenCreateFolder
                                        }
                                        sidebarOpen={sidebarOpen}
                                        setSidebarOpen={setSidebarOpen}
                                        onCreateFlow={onCreateFlowOrUpdate}
                                        onClick={handleCloseSideBarMobile}
                                        onDeleteFolderSuccess={
                                          onDeleteFolderSuccess
                                        }
                                        setFolders={setFolders}
                                        onDeleteFlowSuccess={
                                          onDeleteFlowSuccess
                                        }
                                        onOpenFolderSuccess={
                                          onOpenFolderSuccess
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                        </>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
              <CreateFolderModal
                theme={authUser?.theme}
                show={onOpenCreateFolder}
                onCreateFolderOrUpdate={onCreateFolderOrUpdate}
                data={folderUpdate}
                onHide={() => {
                  setOnOpenCreateFolder(false);
                  setFolderUpdate(null as any);
                }}
              />

              <CreateFlowModal
                theme={authUser?.theme}
                show={openAddFlowModal}
                setIsFlowModalOpen={setIsFlowModalOpen}
                onCreateFlow={onCreateFlowOrUpdate}
                data={createOrEditFLow}
                onShareFlow={onShareFlow}
                onHide={() => {
                  setOpenAddFlowModal(false);
                  setCreateOrEditFLow({
                    isSharing: false,
                    isEditing: false,
                    type: '',
                    flow: {
                      name: '',
                      id: '',
                    },
                    folderId: '',
                  });
                }}
              />
            </Box>
          </Collapse>
          <Box
            className="menu menu--on-hover"
            onClick={() => {
              if (chatCollapsed) {
                setChatCollapsed(!chatCollapsed);
              } else {
                onOpenFolderChat(
                  folders.filter(
                    (folder: any) => folder.name === 'Chatbot',
                  )?.[0]?.id,
                );
              }
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
              paddingLeft: '15px',
              // width: '14.8rem',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                padding: '4px',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '8px',
                '&:hover': {
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                  borderRadius: '4px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <ChatText
                  style={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                    fontSize: '18px',
                  }}
                />
                {sidebarOpen && (
                  <>
                    <Typography
                      className="active-menu"
                      sx={{
                        color:
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                      }}
                    >
                      FluxBots
                    </Typography>
                  </>
                )}
              </Box>

              <Box sx={{ alignSelf: 'center', display: 'flex', gap: '8px' }}>
                <IconButtonTooltip
                  title="Add fluxbot"
                  onClick={event => {
                    event.stopPropagation();
                    setCreateOrEditFLow({
                      isSharing: false,
                      isEditing: false,
                      type: 'chat',
                      flow: {
                        name: '',
                        id: '',
                      },
                      folderId: folders.filter(
                        (folder: any) => folder.name === 'Chatbot',
                      )[0]?.id,
                    });
                    setOpenAddFlowModal(true);
                  }}
                >
                  <Plus
                    size={16}
                    className="menu-icons active-menu"
                    style={{
                      color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                      cursor: 'pointer',
                    }}
                  />
                </IconButtonTooltip>

                {sidebarOpen && (
                  <IconButtonTooltip title="Collapse">
                    {!chatCollapsed ? (
                      <CaretDown
                        className="active-menu"
                        size={16}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                    ) : (
                      <CaretRight
                        className="active-menu"
                        size={16}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                    )}
                  </IconButtonTooltip>
                )}
              </Box>
            </Box>
          </Box>

          <Collapse in={chatCollapsed} timeout={500}>
            <Box pl={'33px'}>
              {folders
                ?.filter((folder: any) => folder.name === 'Chatbot')
                ?.map((folder: any, index: number) => (
                  <Box
                    key={index}
                    gap={'8px'}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    {folder?.flows?.map((flow: any, idx: any) => (
                      <Box
                        key={`${flow.id}-${idx}`}
                        display="flex"
                        justifyContent="space-between"
                        alignItems={'center'}
                        className="menu menu--on-hover"
                        sx={{
                          height: '32px',
                          paddingLeft: '12px',
                          '&:hover': {
                            backgroundColor:
                              authUser?.theme === 'dark'
                                ? '#101828'
                                : '#E7F0FF',
                            borderRadius: '4px',
                          },
                        }}
                      >
                        <ChatCircleText
                          color={
                            authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                          }
                          size={25}
                          weight="regular"
                          className="active-menu"
                        />
                        <Typography
                          className="active-menu"
                          style={{
                            color:
                              authUser?.theme === 'dark'
                                ? '#D0D5DD'
                                : '#475467',
                            fontSize: '16px',
                            margin: '0',
                            cursor: 'pointer',
                            width: '13rem',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            paddingLeft: '8px',
                          }}
                          onClick={() => {
                            handleCloseSideBarMobile();
                            navigate(
                              `/spaceFlow/${flow?.id}?folderId=${folder.id}&type=chat`,
                            );
                          }}
                        >
                          {flow?.name}
                        </Typography>
                        <IconButton
                          size="medium"
                          className="top-menu-icons"
                          key={`${flow.id}-icon`}
                          onClick={e => {
                            handleClick(e, flow.id);
                          }}
                          style={{
                            color:
                              authUser?.theme === 'dark'
                                ? '#D0D5DD'
                                : '#667085',
                          }}
                        >
                          {authUser?.theme === 'dark' ? (
                            <DotsThreeOutlineWhiteIcon />
                          ) : (
                            <DotsThreeOutlineGrayIcon />
                          )}
                        </IconButton>
                        <Popover
                          key={`${flow.id}-popover`}
                          open={clickedFluxBotstId === flow.id}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              p: '8px',
                              background:
                                authUser?.theme === 'dark'
                                  ? '#101828'
                                  : '#FFFF',
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                width: '100%',
                                background: 'unset',
                                justifyContent: 'flex-start',
                                textTransform: 'unset',
                                fontSize: '16px',
                                boxShadow: 'unset',
                                px: '8px',
                                gap: '8px',
                                color: '#EAECF0',
                                ':hover': {
                                  background:
                                    authUser?.theme === 'dark'
                                      ? ' #1D2939'
                                      : '#EAECF0',
                                  boxShadow: 'unset',
                                },
                              }}
                              onClick={() => {
                                handleClose();
                                setOpenAddFlowModal(true);
                                setCreateOrEditFLow({
                                  isSharing: false,
                                  isEditing: true,
                                  type: 'flow',
                                  flow,
                                  folderId: folder.id,
                                });
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                <PencilSimpleLine
                                  size={20}
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#667085'
                                  }
                                />
                                <Typography
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#475467'
                                  }
                                >
                                  Rename
                                </Typography>
                              </Box>
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                width: '100%',
                                background: 'unset',
                                justifyContent: 'flex-start',
                                textTransform: 'unset',
                                fontSize: '16px',
                                boxShadow: 'unset',
                                px: '8px',
                                gap: '8px',
                                color: '#EAECF0',
                                ':hover': {
                                  background:
                                    authUser?.theme === 'dark'
                                      ? ' #1D2939'
                                      : '#EAECF0',
                                  boxShadow: 'unset',
                                },
                              }}
                              onClick={() => {
                                // handleClose();
                                // openUpdateFlowModal(flow, folder.id, true, false);
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                <LinkIcon
                                  size={20}
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#667085'
                                  }
                                />
                                <Typography
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#475467'
                                  }
                                >
                                  Share
                                </Typography>
                              </Box>
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                width: '100%',
                                background: 'unset',
                                justifyContent: 'flex-start',
                                textTransform: 'unset',
                                fontSize: '16px',
                                boxShadow: 'unset',
                                px: '8px',
                                gap: '8px',
                                color: '#EAECF0',
                                ':hover': {
                                  background:
                                    authUser?.theme === 'dark'
                                      ? ' #1D2939'
                                      : '#EAECF0',
                                  boxShadow: 'unset',
                                },
                              }}
                              disabled={tabs.length >= 10}
                              onClick={() => {
                                handleClose();
                                addTab({
                                  flowName: flow.name,
                                  flowId: flow.id,
                                  folderId: folder.id,
                                });

                                navigate(`/spaceFlow/${flow.id}`);
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                <ArrowSquareOut
                                  size={20}
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#667085'
                                  }
                                />
                                <Typography
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#475467'
                                  }
                                >
                                  Open in new tab
                                </Typography>
                              </Box>
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                width: '100%',
                                background: 'unset',
                                justifyContent: 'flex-start',
                                textTransform: 'unset',
                                fontSize: '16px',
                                boxShadow: 'unset',
                                px: '8px',
                                gap: '8px',
                                color: '#EAECF0',
                                ':hover': {
                                  background:
                                    authUser?.theme === 'dark'
                                      ? ' #1D2939'
                                      : '#EAECF0',
                                  boxShadow: 'unset',
                                },
                              }}
                              onClick={() => {
                                handleClose();
                                onCreateFlowOrUpdate(
                                  flow.name,
                                  true,
                                  flow.id,
                                  folder.id,
                                  true,
                                  'chat',
                                );
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Copy
                                  size={20}
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#667085'
                                  }
                                />
                                <Typography
                                  color={
                                    authUser?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : '#475467'
                                  }
                                >
                                  Duplicate
                                </Typography>
                              </Box>
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                width: '100%',
                                background: 'unset',
                                justifyContent: 'flex-start',
                                textTransform: 'unset',
                                fontSize: '16px',
                                boxShadow: 'unset',
                                color: '#b91c1c',
                                px: '8px',
                                gap: '8px',
                                ':hover': {
                                  background:
                                    authUser?.theme === 'dark'
                                      ? '#FFADB7'
                                      : '#fecdd3',
                                  boxShadow: 'unset',
                                },
                              }}
                              onClick={() => {
                                handleClose();
                                onDeleteFlowSuccess(folder.id, flow.id, 'chat');
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                <Trash size={20} color="#b91c1c" />
                                <Typography>Delete</Typography>
                              </Box>
                            </Button>
                          </Box>
                        </Popover>
                      </Box>
                    ))}
                  </Box>
                ))}
            </Box>
          </Collapse>

          <Uploads
            removeNotification={removeNotification}
            setNotification={setNotification}
            sidebarOpen={sidebarOpen}
            spaceId={space.id}
            handleCloseSideBarMobile={() => {
              if (window.innerWidth <= 768) {
                setSidebarOpen(false);
              }
            }}
          />

          <FluxObjects
            authUser={authUser!}
            space={space}
            setAuthUser={setAuthUser}
            sidebarOpen={sidebarOpen}
            modifySpaceObject={modifySpaceObject}
            spaceId={space.id}
            handleCloseSideBarMobile={() => {
              if (window.innerWidth <= 768) {
                setSidebarOpen(false);
              }
            }}
          />

          <Vector
            authUser={authUser!}
            setAuthUser={setAuthUser}
            setNotification={setNotification}
            spaceId={space.id}
            sidebarOpen={sidebarOpen}
            space={space}
            modifySpaceVector={modifySpaceVector}
            handleCloseSideBarMobile={() => {
              if (window.innerWidth <= 768) {
                setSidebarOpen(false);
              }
            }}
          />
        </Collapse>
      </Box>
    </>
  );
};

export default Space;
