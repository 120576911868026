import React, { useEffect, useState } from 'react';
import './Welcome.css';
import Header from './Header';
import Questions from './Questions';
import UserPlan from './UserPlan';
import { UserService } from '../../service/UserService';
import Introduction from './Introduction';
import Subscription from './Subscription';
import Congrats from './Congrats';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { useAuthStore } from '../../store/storeAuth';
import { Navigate } from 'react-router-dom';

export enum Screen {
  Questions = 'Questions',
  UserPlan = 'UserPlan',
  SelectedPlan = 'SelectedPlan',
  Congrats = 'Congrats',
}

export enum SelectedPlan {
  Introduction = 'Introduction',
  Subscription = 'Subscription',
}

const Welcome: React.FC = () => {
  const authUser = useAuthStore(state => state.user);

  const setNotification = useNotificationStore(state => state.setNotification);
  const [screen, setScreen] = useState<Screen>(Screen.Questions);
  const [selectedPlan, setSelectedPlan] = useState<undefined | SelectedPlan>();

  const [questionsData, setQuestionsData] = useState<{
    company: string;
    role: string;
    teamSize: number;
    industry: string;
    phoneNumber: string;
  }>({
    company: '',
    role: '',
    teamSize: 0,
    industry: '',
    phoneNumber: '',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getOnboarding = async () => {
      try {
        const { data } = await UserService.getOnboarding();
        const {
          page,
          company,
          role,
          teamSize,
          industry,
          phoneNumber = '',
        } = data;

        setQuestionsData({
          company,
          role,
          teamSize,
          industry,
          phoneNumber,
        });

        if (page === 2) {
          setScreen(Screen.UserPlan);
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }

      setLoading(false);
    };

    getOnboarding();
  }, []);

  const renderSelectedPlan = () => {
    if (selectedPlan === SelectedPlan.Introduction)
      return (
        <Introduction
          goBack={() => {
            setScreen(Screen.UserPlan);
            setSelectedPlan(undefined);
          }}
          setSelectedPlan={setSelectedPlan}
          setScreen={setScreen}
        />
      );
    else if (selectedPlan === SelectedPlan.Subscription)
      return (
        <Subscription
          goBack={() => {
            setScreen(Screen.UserPlan);
            setSelectedPlan(undefined);
          }}
          setSelectedPlan={setSelectedPlan}
          setScreen={setScreen}
        />
      );

    return null;
  };

  if (authUser?.hasFinishedOnboarding) return <Navigate to={'/'} />;

  if (loading) return null;

  return (
    <div className={'welcome-page'}>
      {screen !== Screen.Congrats && (
        <Header
          screen={screen}
          setScreen={setScreen}
          isSelectedPlanIntroScreen={
            selectedPlan === SelectedPlan.Introduction &&
            screen === Screen.SelectedPlan
          }
          isPricingScreen={
            selectedPlan === SelectedPlan.Subscription &&
            screen === Screen.SelectedPlan
            
          }
        />
      )}

      {screen === Screen.Questions && (
        <div className={'questions-container'}>
          <Questions
            setScreen={setScreen}
            questionsData={questionsData}
            setQuestionsData={setQuestionsData}
          />
        </div>
      )}

      {screen === Screen.UserPlan && (
        <div className={'user-plan-container'}>
          <UserPlan setScreen={setScreen} setSelectedPlan={setSelectedPlan} />
        </div>
      )}

      {screen === Screen.SelectedPlan && renderSelectedPlan()}

      {screen === Screen.Congrats && <Congrats selectedPlan={selectedPlan!} />}
    </div>
  );
};

export default Welcome;
