import { useState, memo, useEffect } from 'react';
import { useEdges, useNodes, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import { useAuthStore } from '../../store/storeAuth';
import { useParams } from 'react-router-dom';
import { NodeProps } from '../../util/Types/NodeProps';
import AutoTriggerTimerModal from '../CoreNodeDIsplay/AutoTriggerTimerModal';
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Table as TableUI,
  Typography,
  TextField,
} from '@mui/material';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import {
  CalendarBlank,
  Gear,
  GlobeHemisphereEast,
  Info,
  Sticker,
  Table,
} from '@phosphor-icons/react';
import OutputHandle from '../UI/Handle/OutputHandle';
import Label from '../UI/Label/Label';
import { isActiveEdge } from '../../util/findActiveHandle';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import SettingsModal from '../FlowGlobalNode/SettingsModal/SettingsModal';
import { findConnectedNodes } from '../../util/executeNode/findConnectedNodes';
import { FlowService } from '../../service/FlowService';
import KitModal from '../UI/KitModal';
import { ScheduleService } from '../../service/ScheduleService';
import dayjs from 'dayjs';

function FlowGlobalNode({ id, data }: NodeProps) {
  const { folders } = useAuthStore(state => state);
  const { flowId } = useParams();
  const activeFolder =
    folders?.find(folder => {
      const activeFolderByFlowId = folder?.flows?.find(
        flow => flow?.id === flowId,
      );
      return activeFolderByFlowId;
    }) ?? null;
  const edges = useEdges();
  const nodes: any = useNodes();

  const reactFlow = useReactFlow();
  const { user } = useAuthStore(state => state);
  const { allIds } = findConnectedNodes(reactFlow.getNodes(), edges, id);

  const activeFlow = activeFolder?.flows?.find(flow => flow?.id === flowId);
  const setNotification = useNotificationStore(state => state.setNotification);
  const {
    setNodeState,
    setSaving,
    setSchema,
    setIsPublic,
    isPublic,
    spaceId,
    setIsWebhook,
    isWebhook,
  } = useFlowsStore(state => state);
  const [isAutoTriggerModalOpen, setIsAutoTriggerModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    notesExpanded: false,
    notes: '',
    loading: false,
    isAutotrigger: false,
    triggerMessage: '',
    generateSessionId: false,
    selectedNode: data?.selectedNode || 'none',
    showEmailCalling: data?.showEmailCalling || false,
    showApiCalling: data?.showApiCalling || false,
  });
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [searchDate, setSearchDate] = useState('');

  const [intervalData, setIntervalData] = useState({
    type: 'hourly',
    hourly: '*',
    daily: '*',
    weekly: '*',
    monthly: '*',
    codedTime: '',
    inputText: '',
    varInput: '',
  });

  async function executeFlow() {
    nodeDataAutoSaveDynamic({
      setNodeState,
      id,
      flowTriggerData: {
        flowGlobalId: id,
        flowId: flowId,
      },
      setSaving,
      paused: false,
      changeType: 'execute',
      objectCallerData: [
        ...(user?.spaceObjects || []),
        ...(user?.objects || []),
      ],

      setSchema,
      newEdges: edges,
    });

    setFormData({
      ...formData,
      loading: true,
    });
  }

  const onChange = (e: any) => {
    const { name, value } = e.target;
    let object = {};

    if (name === 'selectedNode') {
      object = { selectedNode: value };
    } else if (name === 'showEmailCalling') {
      object = { showEmailCalling: !data?.showEmailCalling };
    } else if (name === 'showApiCalling') {
      object = { showApiCalling: !data?.showApiCalling };
    } else {
      object = { generateSessionId: !data?.generateSessionId };
    }

    nodeDataAutoSaveDynamic({
      setNodeState,
      id,
      flowId,
      objectData: {
        ...object,
      },
      setSaving,
      setSchema,
      newEdges: edges,
    });

    setFormData({
      ...formData,
      ...object,
    });
  };

  const saveNode = (data: any) => {
    nodeDataAutoSaveDynamic({
      setNodeState,
      id,
      flowId,
      objectData: {
        ...data,
      },
      setSaving,
      setSchema,
      newEdges: edges,
    });
  };

  const onCancelExecute = () => {
    setNodeState(nds => {
      return nds.map((nd: any) => {
        return {
          ...nd,
          data: {
            ...nd.data,
            flowTrigger: null,
            canceled: true,
          },
        };
      });
    });

    for (let index = 0; index < nodes?.length; index++) {
      const element = nodes[index];

      nodeDataAutoSaveDynamic({
        setNodeState,
        id: element.id,
        flowId,
        objectData: {
          usedTokens: undefined,
          hasExecutionError: false,
        },
        setSaving,
        setSchema,
        newEdges: edges,
      });
    }
    setFormData({
      ...formData,
      loading: false,
    });
  };
  const onPauseOrUnpause = (pause: boolean) => {
    setNodeState(nds => {
      return nds.map((nd: any) => {
        return {
          ...nd,
          data: {
            ...nd.data,
            paused: pause,
          },
        };
      });
    });
  };

  const toggleNotes = () => {
    setFormData({ ...formData, notesExpanded: !formData.notesExpanded });
  };

  const handleChange = async (e: any) => {
    if (e.target.name === 'isAutotrigger') {
      if (!formData.isAutotrigger) {
        setIsAutoTriggerModalOpen(true);
      } else {
        setIsAutoTriggerModalOpen(false);
        setFormData(prev => ({
          ...prev,
          triggerMessage: '',
        }));

        const res = await ScheduleService.getOneSchedule(flowId!);

        if (res?.data?.id) {
          await ScheduleService.deleteSchedule(res?.data?.id);
          setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.checked,
            triggerMessage: '',
            intervalData: null,
            isAutotrigger: false,
          }));
          saveNode({
            ...data,
            isAutotrigger: false,
            triggerMessage: '',
            intervalData: null,
          });
        }
        return;
      }

      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));

      setNodeState(nds =>
        nds.map(nd => {
          if (nd.id === id) {
            return {
              ...nd,
              data: {
                ...nd.data,
                [e.target.name]: e.target.checked,
              },
            };
          }
          return nd;
        }),
      );
    } else {
      setFormData(prev => ({ ...prev, [e.target.name]: e.target.checked }));
    }
  };

  const onUpdateChat = async (data: any, fluxId: string) => {
    try {
      await FlowService.updateNodeData(flowId!, {
        id: fluxId,
        data: {
          ...data,
        },
        changeType: 'default',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeIsPublicOrWebhook = async (data: any) => {
    try {
      await FlowService.updateFlow(flowId!, {
        public: data.isPublic,
        webhook: data.webhook,
      });

      if ('isPublic' in data) {
        setIsPublic(data.isPublic);
      }
      if ('webhook' in data) {
        setIsWebhook(data.webhook);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data?.hasFinished && user) {
      const totalTokens = nodes.reduce((sum: any, item: any) => {
        if (typeof item?.data?.usedTokens === 'number') {
          return sum + item?.data?.usedTokens;
        }
        return sum;
      }, 0);

      const hasError = nodes.some((item: any) => item?.data?.hasExecutionError);

      setNotification({
        type: 'success',
        message: 'Flow Global Finished',
      });

      saveNode({
        logs: [
          {
            date: new Date(),
            status: hasError ? 'Error while running' : 'Succesfully ran',
            spentTokens: Math.round((totalTokens ?? 0) * 1000) / 1000,
            cost: (totalTokens * user.fluxTokenPrice) * user.tokensPurchaseRate,
          },
          ...(Array.isArray(data?.logs) ? data.logs : []),
        ],
        flowUsedTokens: undefined,
      });

      for (let index = 0; index < nodes?.length; index++) {
        const element = nodes[index];

        nodeDataAutoSaveDynamic({
          setNodeState,
          id: element.id,
          flowId,
          objectData: {
            usedTokens: undefined,
            hasExecutionError: false,
          },
          setSaving,
          setSchema,
          newEdges: edges,
        });
      }

      setFormData({
        ...formData,
        loading: false,
      });
      setNodeState(nds => {
        const findFluxObject = nds?.find(
          (nd: any) => nd.type === 'fluxObject' && nd?.data?.chat === true,
        );

        const apiInput: any = nds?.find(
          (nd: any) => nd.type === 'varInputNode',
        );

        const apiOutput: any = nds?.find(
          (nd: any) => nd.type === 'outputObjectNode',
        );

        const text = apiOutput?.data?.textData
          ?.map((item: { text: string }) => item.text)
          ?.join(' ');

        return nds.map((nd: any) => {
          if (nd.type === 'outputObjectNode') {
            return {
              ...nd,
              data: {
                ...nd.data,
                canSendMail: true,
              },
            };
          }

          if (nd.type === 'fluxObject' && findFluxObject?.id === nd.id) {
            let entries = nd?.data?.entries ? nd?.data?.entries : [];

            const selectedChat = nd?.data?.selectedChat || 'default';

            if (selectedChat) {
              const findUserSession = nd?.data?.userSessionsChat?.find(
                (session: any) => session.id === selectedChat,
              );

              if (findUserSession) {
                const userSessionsChat = [
                  ...nd?.data?.userSessionsChat?.map((session: any) => {
                    if (session.id === selectedChat) {
                      return {
                        ...session,
                        entries: [
                          ...session.entries,
                          {
                            role: 'user',
                            content: apiInput?.data?.text,
                            timestamp: new Date().toLocaleString('en-US', {
                              month: 'numeric',
                              day: 'numeric',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: true,
                            })
                          },
                          {
                            role: 'assistant',
                            content: text,

                            timestamp: new Date().toLocaleString('en-US', {
                              month: 'numeric',
                              day: 'numeric',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: true,
                            })
                          },
                        ],
                      };
                    }
                    return session;
                  }),
                ];
                onUpdateChat(
                  {
                    userSessionsChat,
                    selectedChat,
                  },
                  nd.id,
                );
                return {
                  ...nd,
                  data: {
                    ...nd.data,
                    selectedChat: selectedChat,
                    userSessionsChat,
                  },
                };
              } else {
                const newEntries = [
                  ...entries,
                  ...[
                    {
                      role: 'user',
                      content: apiInput?.data?.text,
                      timestamp: new Date().toLocaleString('en-US', {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                      })
                    },
                    {
                      role: 'assistant',
                      content: text,
                      timestamp: new Date().toLocaleString('en-US', {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                      })
                    },
                  ],
                ];

                onUpdateChat(
                  {
                    entries: newEntries,
                  },
                  nd.id,
                );

                return {
                  ...nd,
                  data: {
                    ...nd.data,

                    entries: newEntries,
                  },
                };
              }
            }
          }

          if (nd.id === id) {
            return {
              ...nd,
              data: {
                ...nd.data,
                hasFinished: false,
                loading: false,
              },
            };
          }

          return {
            ...nd,
            data: {
              ...nd.data,
              flowTrigger: null,
            },
          };
        });
      });
    } else {
      setFormData({
        ...formData,
        notesExpanded: data.notesExpanded,
        notes: data.notes,
        triggerMessage: data.triggerMessage,
        isAutotrigger: data.isAutotrigger || false,
        generateSessionId: data?.generateSessionId || false,
        selectedNode: data?.selectedNode || 'none',
      });
    }
  }, [data, user]);

  useEffect(() => {
    if (data?.loading) {
      setFormData({
        ...formData,
        loading: true,
      });
    }
  }, [data?.loading, formData]);

  useEffect(() => {
    setIntervalData(data?.intervalData ?? intervalData);
  }, [data?.intervalData]);

  return (
    <Box
      className={formData.loading ? 'block' : 'block-unactive'}
      sx={{
        bgcolor: user?.theme === 'dark' ? '#1D2939' : 'background.paper',
        p: '16px',
        borderRadius: '8px',
        width: '350px',
      }}
    >
      <Box
        display={{
          display: 'flex',
          justifyContent: 'end',
          gap: '8px',
        }}
      >
        <IconButtonTooltip title={'Open Notes'} onClick={toggleNotes}>
          <Sticker color="#667085" size={20} />
        </IconButtonTooltip>
        <IconButtonTooltip
          onClick={() => {
            setSettingsModalOpen(true);
          }}
          title={'Settings'}
        >
          <Gear color="#667085" size={20} />
        </IconButtonTooltip>
      </Box>
      <Box>
        <GlobeHemisphereEast weight="fill" size={32} color="#667085" />

        <Label
          isAdvanced
          labelName={data.label}
          sx={{
            mt: '11px',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        />

        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            mt: '8px',
          }}
        >
          <Typography
            sx={{
              // color: '#98A2B3',
              color: user?.theme === 'dark' ? '#98A2B3' : '#667085',
              fontSize: '12px',
              fontWeight: 700,
            }}
          >
            {allIds.length}{' '}
            <span
              style={{
                color: user?.theme === 'dark' ? '#98A2B3' : '#667085',
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              Flow Steps
            </span>
          </Typography>
          <Typography
            sx={{
              // color: '#98A2B3',
              color: user?.theme === 'dark' ? '#98A2B3' : '#667085',
              fontSize: '12px',
              fontWeight: 700,
            }}
          >
            {reactFlow?.getNodes()?.length - 1}{' '}
            <span
              style={{
                color: user?.theme === 'dark' ? '#98A2B3' : '#667085',
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              Nodes
            </span>
          </Typography>
        </Box>

        <Box mt={'20px'}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.isAutotrigger}
                onChange={handleChange}
                name="isAutotrigger"
                // size="small"
              />
            }
            label="Set Auto Trigger"
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#142BFF',
            }}
            componentsProps={{
              typography: {
                fontSize: '14px',
                alignSelf: 'center',
                marginTop: '2px',
              },
            }}
          />
          {formData.isAutotrigger && (
            <>
              <Typography
                style={{
                  color: '#475467',
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  fontSize: '12px',
                  marginBottom: '10px',
                }}
                onClick={() => setIsAutoTriggerModalOpen(true)}
              >
                <CalendarBlank />
                {formData.triggerMessage}

                {formData.loading && (
                  <Chip
                    label="Active"
                    sx={{ bgcolor: '#4CAF50', color: 'white' }}
                    size="small"
                  />
                )}
              </Typography>
            </>
          )}
        </Box>

        <Box>
          <Button
            variant="contained"
            onClick={executeFlow}
            fullWidth
            disabled={formData.loading}
          >
            Run Flow
          </Button>{' '}
          {formData.loading && (
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  onPauseOrUnpause(data?.paused ? false : true);
                }}
                sx={{
                  mt: '16px',
                }}
              >
                {data?.paused ? 'Unpause' : 'Pause'}
              </Button>
            </Box>
          )}
          {formData.loading && (
            <Button
              variant="contained"
              onClick={onCancelExecute}
              fullWidth
              sx={{
                mt: '16px',
              }}
            >
              Cancel Flow
            </Button>
          )}
        </Box>
        <Box
          sx={{
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: '1px solid #EAECF0',
            pt: '10px',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontSize: '12px',
              color: '#475467',
            }}
            onClick={() => setShowLogsModal(true)}
          >
            <Table size={16} />
            Flow Logs
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#4CAF50',
            }}
          >
            {dayjs(data?.logs?.[0]?.date).format('MMM D h:mm A')} -{' '}
            {data?.logs?.[0]?.status}
          </Typography>
        </Box>

        {formData.notesExpanded && (
          <Box mt={'16px'}>
            <textarea
              id="notes"
              value={formData.notes}
              onChange={handleChange}
              name="notes"
              style={{
                height: 100,
                width: 'calc(100% - 16px)',
                padding: 8,
                fontFamily: 'inherit',
                borderRadius: '4px',
                outline: 'none',
                border: `1px solid ${
                  user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                }`,
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#fff',
                color: user?.theme === 'dark' ? '#98A2B3' : 'black',
              }}
            />
          </Box>
        )}
      </Box>

      <OutputHandle
        colorActive={{
          background: 'blue',
          color: 'white',
        }}
        handleId="a"
        isActive={isActiveEdge(edges, id, 'a', 'source')}
      />
      <AutoTriggerTimerModal
        isAutoTriggerModalOpen={isAutoTriggerModalOpen}
        setIsAutoTriggerModalOpen={setIsAutoTriggerModalOpen}
        formData={formData}
        setFormData={setFormData}
        data={data}
        saveNode={saveNode}
        intervalData={intervalData}
        setIntervalData={setIntervalData}
        activeFlowId={activeFlow?.id}
        id={id}
      />

      <SettingsModal
        formData={formData}
        onChange={onChange}
        isPublic={isPublic}
        isWebhook={isWebhook}
        onChangeIsPublicOrWebhook={onChangeIsPublicOrWebhook}
        onHide={() => {
          setSettingsModalOpen(false);
        }}
        spaceId={spaceId}
        flowId={flowId}
        show={settingsModalOpen}
      />

      <KitModal
        show={showLogsModal}
        onHide={() => setShowLogsModal(false)}
        fullWidth
        title={
          <Typography
            sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          >
            <GlobeHemisphereEast weight="fill" size={32} color="#667085" />
            <Label
              isAdvanced
              labelName={data.label + ' Logs'}
              sx={{
                mt: '11px',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            />
          </Typography>
        }
      >
        <TextField
          variant="outlined"
          label="Search Date"
          value={searchDate}
          onChange={e => setSearchDate(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'none', mt: '24px' }}
        >
          <TableUI>
            <TableHead sx={{ bgcolor: '#EAECF0', py: '16px' }}>
              <TableRow
                sx={{
                  py: '6px',
                }}
              >
                <TableCell>Date and Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tokens</TableCell>
                <TableCell>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.logs?.map((row: any, index: any) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        color: '#007AFF',
                      }}
                    >
                      {dayjs(row.date).format('h:mm A')}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        mt: '4px',
                        fontSize: '14px',
                        color: '#475467',
                      }}
                    >
                      <CalendarBlank size={16} />
                      {dayjs(row.date).format('MM/DD/YYYY')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {row.status === 'Error while running' ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">{row.status}</Typography>
                        <Tooltip title="More info">
                          <IconButton size="small" style={{ marginLeft: 4 }}>
                            <Info fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <Typography variant="body2">{row.status}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography>{row.spentTokens}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.cost === "-" ? "-" : `$${row.cost.toFixed(6)}`}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableUI>
        </TableContainer>
      </KitModal>
    </Box>
  );
}

export default memo(FlowGlobalNode);
