import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class FalService extends ApiService {
  static pathName = '/fal';

  static pro = (body: {
    flowId: string;
    fileUrl: string;
    prompt: string;
    imageSize: string;
    numInferenceSteps: number;
    seed: number;
    guidanceScale: number;
    syncMode: boolean | string;
    numImages: number;
    safetyTolerance: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/pro`, body);
  };

  static schnell = (body: {
    flowId: string;
    fileUrl: string;
    prompt: string;
    imageSize: string;
    numInferenceSteps: number;
    seed: number;
    guidanceScale: number;
    syncMode: boolean | string;
    numImages: number;
    safetyTolerance: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/schnell`, body);
  };

  static dev = (body: {
    flowId: string;
    fileUrl: string;
    prompt: string;
    imageSize: string;
    numInferenceSteps: number;
    seed: number;
    guidanceScale: number;
    syncMode: boolean | string;
    numImages: number;
    safetyTolerance: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/dev`, body);
  };
}
