import { DependencyList, useEffect, useRef } from 'react';

type DebounceEffectTearDown = (() => void) | void;

type DebounceEffectHandler = () => void;

// Use throughout your app instead of plain `useDispatch` and `useSelector`

/**
 * It is a delayed version of @function useEffect
 * @param handler Function that handles @param dependencies changes
 * @param dependencies The changes of these parameters are observed
 * @param duration The duration of time, before changes are considered
 */
export const useDebounceEffect = function (
  handler: DebounceEffectHandler,
  dependencies: DependencyList,
  duration: number,
  teardownFunction?: DebounceEffectTearDown
) {
  let timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    clearTimeout(timer.current as NodeJS.Timeout);
    timer.current = setTimeout(() => handler(), duration);

    return teardownFunction;
  }, dependencies);
};
