import { Box, Button, TextField, Typography } from '@mui/material';
import { PaintBrush } from '@phosphor-icons/react';
import React, { useState } from 'react';

type Props = {
  data: any;
  formData: {
    name: string;
    value: string;
    style: any;
    placeholder: string;
    titleEmpty: string;
  };
  editingType: string;
  onChanges: (e: any) => void;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  setEditingType: (val: string) => void;
  handleClick: (type: string) => void;
  editType: string;
};

const FormBuildInput = ({
  data,
  formData,
  onChanges,
  isEditing,
  setIsEditing,
  setEditingType,
  handleClick,
  editingType,
  editType,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hover, setHover] = useState(false);
  return (
    <>
      {(!isEditing || editingType !== editType) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            bgcolor: data?.bgColor ? data?.bgColor : '#ffffff',
            p: '2px 14px',
            ':hover': {
              bgcolor: data?.bgColor ? data?.bgColor : '#f9f9f9',
            },
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <Typography
            sx={{
              ...(formData?.style as any),
            }}
            fontWeight={500}
            onClick={() => {
              setIsEditing(true);
              setEditingType(editType);
              setIsFocused(true);
            }}
          >
            {formData.value ? formData.value : formData.titleEmpty}
          </Typography>
          <Button
            onClick={() => {
              handleClick(editType);
            }}
            sx={{
              color: '#475467',
              fontSize: '12px',
              textTransform: 'none',
              visibility: hover ? 'visible' : 'hidden',
              bgcolor: '#fff',
              '&:hover': {
                bgcolor: '#f9f9f9',
              },
            }}
          >
            <PaintBrush size={24} color="#475467" />
          </Button>
        </Box>
      )}
      {isEditing && editingType === editType && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: data?.bgColor ? data?.bgColor : '#ffffff',
            p: '14px',
          }}
        >
          <TextField
            name={formData.name}
            value={formData.value}
            onChange={onChanges}
            placeholder={formData.placeholder}
            fullWidth
            autoFocus={isFocused}
            size="medium"
            onBlur={e => {
              setIsEditing(false);
              setEditingType('');
              setIsFocused(false);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }
              if (e.key === 'Enter') {
                setIsEditing(false);
                setEditingType('');
                setIsFocused(false);
              }
            }}
            // onCopy={e => e.stopPropagation()}
            // onCut={e => e.stopPropagation()}
            // onPaste={e => e.stopPropagation()}
            className="nodrag"
            variant="standard"
            multiline
          />
        </Box>
      )}
    </>
  );
};

export default FormBuildInput;
