import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import { PaymentInfoDetails } from '../util/Types/PaymentInfoDetails';
import { Onboarding } from '../util/Types/Onboarding';

export class UserService extends ApiService {
  static pathName = '/user';

  static updateUser = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.patch(`${this.pathName}/update`, body);
  };

  static generateApiKey = (): Promise<
    IGetOneResponse<{
      apiKey: string;
    }>
  > => {
    return ApiService.post(`${this.pathName}/generate-api-key`);
  };

  static switchUserCompany = (body: {
    company?: { id: string; name: string };
    personal?: boolean;
  }): Promise<
    IGetOneResponse<{
      company?: {
        id: string;
        name: string;
      };
      personal?: boolean;
    }>
  > => {
    return ApiService.post(`${this.pathName}/switch-company`, body);
  };

  static getOnboarding = (): Promise<IGetOneResponse<Onboarding>> => {
    return ApiService.get(`${this.pathName}/onboarding`);
  };

  static getUserFlows = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/flows`);
  };

  static getUserSpaceDocuments = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/documents`);
  };

  static updateOnboarding = (
    body: Partial<Onboarding>,
  ): Promise<IGetOneResponse<{}>> => {
    return ApiService.patch(`${this.pathName}/onboarding`, body);
  };

  static createStripeCustomer = (): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/create-stripe-customer`);
  };

  static attachPaymentMethod = (
    id: string,
    body: Partial<PaymentInfoDetails>,
  ): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    return ApiService.post(
      `${this.pathName}/attach-payment-method/${id}`,
      body,
    );
  };

  static getSpaces = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/spaces`);
  };

  static getCompanies = (email: string): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/companies?email=${email}`);
  };

  static setDefaultPaymentMethod = (
    id: string,
  ): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    return ApiService.post(`${this.pathName}/set-default-payment-method/${id}`);
  };

  static removePaymentMethod = (id: string) => {
    return ApiService.delete(`${this.pathName}/delete-payment-method/${id}`);
  };

  static getPaymentMethods = (): Promise<
    IGetAllResponse<PaymentInfoDetails>
  > => {
    return ApiService.get(`${this.pathName}/get-payments-methods`);
  };

  static getTechnologies = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`/technologies`);
  };

  static getUsersByEmail = (email: string): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}/users-by-email?email=${email}`);
  };
}
