import React, { useCallback, useEffect, useRef, useState } from 'react';
import Node from '../UI/Node/Node';
import { NodeProps, useEdges } from 'reactflow';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import {
  getExtension,
  getGoogleDriveFolders,
  uploadFileToGoogleDrive,
} from './helpers';
import { LocalStorageService } from '../../service/LocalStorageService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import SearchableSelect from '../SearchableSelect';
import { useAuthStore } from '../../store/storeAuth';
import { axiosInstance } from '../../service/ApiService';
import { isGoogleSheetsAccessTokenValid } from '../GoogleSheetsIntegration/helpers';
import { AuthService } from '../../service/AuthService';
import { Info, Trash } from '@phosphor-icons/react';
import ConnectToGoogleSheets from '../GoogleSheetsIntegration/ConnectToGoogleSheets';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import useFlowsStore from '../../store/storeFlows';
import TagInput from '../Test/TagInput';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';

const firstId = `${Math.random().toString(36).substr(2, 9)}+file`;

const FileSave: React.FC<NodeProps> = ({ id, data, type }) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const { user, googleAccessToken, setGoogleLogin } = useAuthStore(
    state => state,
  );
  const edges = useEdges();
  const { setNodeState, setSaving, setSchema, flowId } = useFlowsStore(
    state => state,
  );
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [selectedGoogleDriveFolder, setSelectedGoogleDriveFolder] =
    useState<null | {
      label: string;
      value: string;
    }>(null);
  const [googleDriveFolders, setGoogleDriveFolders] = useState<
    { label: string; value: string }[]
  >([]);
  const [checkingConnection, setCheckingConnection] = useState(true);
  const [googleSheetsAccessToken, setGoogleSheetsAccessToken] = useState<
    undefined | string
  >();
  const [files, setFiles] = useState<
    { id: string; value: string; fileName: string }[]
  >([
    {
      id: firstId,
      value: '',
      fileName: `Node ${data?.label} + File 1`,
    },
  ]);

  const [uploading, setUploading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const [selectedDriveType, setSelectedDriveType] = useState<
    'me' | 'shared' | 'all'
  >('me');

  const getGoogleDriveFoldersHandler = useCallback(async () => {
    const token = LocalStorageService.getItem('googleSheetsAccessToken');
    try {
      const result = await getGoogleDriveFolders(token!, selectedDriveType);
      setGoogleDriveFolders(result);
    } catch (error) {}
  }, [selectedDriveType]);

  useEffect(() => {
    getGoogleDriveFoldersHandler();
  }, []);

  useEffect(() => {
    if (googleAccessToken) {
      setGoogleSheetsAccessToken(googleAccessToken);
      setIsConnected(true);
    } else {
      setGoogleSheetsAccessToken(undefined);
      setIsConnected(false);
    }
  }, [googleAccessToken]);

  useEffect(() => {
    const validateExistingGoogleSheetsAccessToken = async () => {
      const googleSheetsAccessToken = LocalStorageService.getItem(
        'googleSheetsAccessToken',
      );

      const refreshToken = LocalStorageService.getItem('googleRefreshToken');

      if (
        googleSheetsAccessToken &&
        (await isGoogleSheetsAccessTokenValid(googleSheetsAccessToken))
      ) {
        setGoogleSheetsAccessToken(googleSheetsAccessToken);
        setIsConnected(true);
      } else if (refreshToken) {
        await refreshAuthToken(refreshToken);
      }

      setCheckingConnection(false);
    };

    validateExistingGoogleSheetsAccessToken();
  }, []);
  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          uploadToDrive();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, files]);

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        if (data.files) {
          setFiles(data.files);
          // if (data.fileNames) {
          //   setFileNames(data.fileNames);
          // }
        } else {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            setSaving,
            flowId,
            objectData: {
              files,
              // fileNames,
            },
            setSchema,
          });
        }
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const refreshAuthToken = async (refreshToken: string) => {
    try {
      const newTokens = await AuthService.googleRefreshToken({
        refresh_token: refreshToken,
      });
      LocalStorageService.setItem(
        'googleSheetsAccessToken',
        newTokens.data.access_token,
      );
      LocalStorageService.setItem(
        'googleRefreshToken',
        newTokens.data.refresh_token,
      );

      setGoogleSheetsAccessToken(newTokens.data.access_token);
      setIsConnected(true);

      const googleDriveFolders = await getGoogleDriveFolders(
        newTokens.data.access_token,
        selectedDriveType,
      );
      setGoogleDriveFolders(googleDriveFolders);
    } catch (refreshError) {}
  };

  const onChange = (e: any, id: string) => {
    const updatedFiles = files.map(file => {
      if (file.id === id) {
        return { ...file, value: e.target.value };
      }
      return file;
    });

    setFiles(updatedFiles);

    setWhoIsChanging({
      name: 'files',
      value: updatedFiles,
    });

    setIsChangingDirectly(true);
  };

  const onChangeFileName = (e: any, id: string) => {
    const updatedFileNames = files.map(fileName => {
      if (fileName.id === id) {
        return { ...fileName, fileName: e.target.value };
      }
      return fileName;
    });

    setFiles(updatedFileNames);

    setWhoIsChanging({
      name: 'files',
      value: updatedFileNames,
    });
    setIsChangingDirectly(true);
  };

  const ensureHttpsUrl = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  const addMoreFiles = () => {
    const id = `${Math.random().toString(36).substr(2, 9)}+file`;
    const newFile = {
      id,
      value: '',
      fileName: `Node ${data?.label} + File ${files.length + 1}`,
    };

    setFiles([...files, newFile]);
    // setFileNames([...fileNames, newFileName]);

    setWhoIsChanging({
      name: 'files',
      value: [...files, newFile],
    });

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
            // fileNames:
            //   whoIsChanging.name === 'fileNames'
            //     ? whoIsChanging.value
            //     : fileNames,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const removeFileHandler = (id: string) => {
    const updatedFiles = files.filter(file => file.id !== id);

    setFiles(updatedFiles);

    setWhoIsChanging({
      name: 'files',
      value: updatedFiles,
    });

    setIsChangingDirectly(true);

    // setFileNames(updatedFileNames);
  };

  const uploadToDrive = async () => {
    if (!selectedGoogleDriveFolder) {
      throw new Error('Please, select a google drive folder');
    }

    const token = LocalStorageService.getItem('googleSheetsAccessToken');
    if (!token) {
      throw new Error('You are not authenticated');
    }
    const folderId = selectedGoogleDriveFolder.value;

    if (files.find(file => file.value === '')) {
      throw new Error('URL missing');
    }
    try {
      setUploading(true);
      await Promise.all(
        files.map(async file => {
          // const fileName = fileNames.find(
          //   fileName => fileName.id === fileNameId,
          // );
          const response = await axiosInstance.get(
            `/downloader?url=${ensureHttpsUrl(file.value)}`,
            {
              responseType: 'blob',
            },
          );

          const contentType = response.headers['content-type'];
          const s3FileBlob: Blob = response.data;

          const metadata = {
            name: file.fileName,
            mimeType: contentType,
            parents: [folderId],
          };

          const form = new FormData();
          form.append(
            'metadata',
            new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
          );
          form.append('file', s3FileBlob);

          const uploadResponse = await uploadFileToGoogleDrive(token, form);

          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowTriggerData: checkExecuteTrigger(data, id),
            flowId,
            objectCallerData: [
              ...(user?.spaceObjects || []),
              ...(user?.objects || []),
            ],

            changeType: 'execute',
            setSchema,
            setSaving,
          });
        }),
      );

      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setUploading(false);

      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setUploading(false);
    }
  };
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };
  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  return (
    <Node
      onSubmit={uploadToDrive}
      btnText="Save File"
      data={data}
      type={type}
      id={id}
      isLoading={checkingConnection || uploading}
      edges={edges}
      handleRun={handleRun}
      showTokensUsed={false}
    >
      <Box display={'flex'}>
        <Box
          p={'16px'}
          borderRight={`1px solid ${
            user?.theme === 'dark' ? '#475467' : '#EAECF0'
          }`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <FormControl variant="standard">
            {files.map((file, i) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  key={file.id}
                >
                  <Box>
                    <TagInput
                      name="value"
                      placeholder="https://example.com"
                      labelName={'File Name'}
                      handleId={file.id + '-name'}
                      isCollapsed={true}
                      nodeId={id}
                      isActive={isActiveEdge(
                        edges,
                        id,
                        file.id + '-name',
                        'target',
                      )}
                      isAdvanced={true}
                      findColor={findColor}
                      removeIcons
                      advancedCollapsed={true}
                      onChange={(e: any) => onChangeFileName(e, file.id)}
                      dataConnected={data?.urlData || []}
                      placement={data?.placement}
                      value={file.fileName}
                      nodeLabel={data?.label}
                    />

                    <TagInput
                      name="value"
                      placeholder="https://example.com"
                      labelName={'File URL'}
                      handleId={file.id + '-file'}
                      isCollapsed={true}
                      nodeId={id}
                      isActive={isActiveEdge(
                        edges,
                        id,
                        file.id + '-file',
                        'target',
                      )}
                      isAdvanced={true}
                      findColor={findColor}
                      removeIcons
                      advancedCollapsed={true}
                      onChange={(e: any) => onChange(e, file.id)}
                      dataConnected={data?.urlData || []}
                      placement={data?.placement}
                      value={file.value}
                      nodeLabel={data?.label}
                    />
                  </Box>

                  {i > 0 && (
                    <IconButton
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => removeFileHandler(file.id)}
                    >
                      <Trash />
                    </IconButton>
                  )}
                </Box>
              );
            })}
          </FormControl>

          <Button
            disabled={
              !!files.find(file => file.value === '') || files.length === 10
            }
            onClick={addMoreFiles}
            variant="contained"
          >
            Add Files
          </Button>
        </Box>
        {!isConnected ? (
          <Box
            sx={{
              p: '24px 20px',
              mb: '24px',
              borderRadius: '4px',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              display: 'flex',
              width: '386px',

              height: '146px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Info color="#98A2B3" size={32} />
            <Typography
              sx={{
                fontSize: '12px',
                color: '#98A2B3',
                textAlign: 'center',
              }}
            >
              <ConnectToGoogleSheets /> to log into Google so you can access
              your google drive folders and upload files on them.
            </Typography>
          </Box>
        ) : (
          <Box
            p={'16px 24px 16px 16px'}
            sx={{ minWidth: '400px', display: 'flex', flexDirection: 'column' }}
          >
            <Button
              variant="outlined"
              onClick={getGoogleDriveFoldersHandler}
              sx={{ marginBottom: '15px' }}
            >
              Refresh Folders
            </Button>

            <FormControl sx={{ marginBottom: '10px' }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Drive Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedDriveType}
                onChange={e =>
                  setSelectedDriveType(
                    e.target.value as 'me' | 'shared' | 'all',
                  )
                }
              >
                <FormControlLabel
                  value="me"
                  control={<Radio />}
                  label="My Drives"
                />
                <FormControlLabel
                  value="shared"
                  control={<Radio />}
                  label="Shared Drives"
                />
                <FormControlLabel value="all" control={<Radio />} label="All" />
              </RadioGroup>
            </FormControl>

            <SearchableSelect
              options={googleDriveFolders}
              value={selectedGoogleDriveFolder}
              onChange={setSelectedGoogleDriveFolder}
              fullWidth
              label="Select Google Drive Folder"
            />
          </Box>
        )}
      </Box>
    </Node>
  );
};

export default FileSave;
