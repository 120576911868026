import React from 'react';

const AiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M10.0845 3.4027L9.34795 3.96041C8.84913 3.33048 8.08365 2.98244 7.26657 3.0181C5.92395 3.07661 4.89429 4.22037 4.89429 5.53814V8.32455L8.15761 10.1897L7.84682 10.7138L4.20868 8.63327C4.05577 8.5458 3.96191 8.3855 3.96191 8.21209V5.56466C3.96191 3.65565 5.55597 2.03739 7.50184 2.10352C8.52093 2.13796 9.45673 2.6082 10.0845 3.4027Z"
        fill="#667085"
      />
      <path
        d="M4.53679 4.18359L4.66111 5.08781C3.85553 5.19661 3.16526 5.67264 2.78827 6.38425C2.16855 7.55361 2.66395 8.99968 3.82787 9.65887L6.28873 11.0519L9.56759 9.21332L9.87528 9.73933L6.2188 11.7885C6.06527 11.8745 5.87662 11.8742 5.7234 11.7876L3.3853 10.464C1.69894 9.50924 1.06679 7.34637 2.098 5.72719C2.63816 4.87875 3.52143 4.31952 4.53679 4.18359Z"
        fill="#667085"
      />
      <path
        d="M2.45638 9.28262L3.31727 9.62914C3.01052 10.3676 3.08573 11.1919 3.52581 11.8679C4.24872 12.9787 5.77378 13.2814 6.93738 12.6225L9.39824 11.2291L9.41378 7.52539L10.0323 7.52722L10.0139 11.657C10.0133 11.8304 9.91851 11.9904 9.76529 12.0773L7.42719 13.4008C5.74114 14.3553 3.51494 13.8107 2.60027 12.1254C2.12134 11.2425 2.06882 10.2131 2.45638 9.28262Z"
        fill="#667085"
      />
      <path
        d="M5.91406 13.6002L6.65064 13.0425C7.14946 13.6724 7.91495 14.0205 8.73202 13.9848C10.0746 13.9263 11.1043 12.7825 11.1043 11.4648V8.67837L7.84098 6.81325L8.15177 6.28906L11.7899 8.36965C11.9428 8.45711 12.0367 8.61741 12.0367 8.79082V11.4383C12.0367 13.3473 10.4423 14.9655 8.49675 14.8997C7.47766 14.865 6.54186 14.3947 5.91406 13.6002Z"
        fill="#667085"
      />
      <path
        d="M11.4615 12.8195L11.3372 11.9153C12.1428 11.8065 12.8331 11.3305 13.2101 10.6189C13.8298 9.44954 13.3344 8.00346 12.1705 7.34427L9.70959 5.95122L6.43073 7.78982L6.12305 7.26381L9.77952 5.21462C9.93305 5.12868 10.1217 5.12898 10.2749 5.21553L12.613 6.5391C14.2991 7.4936 14.9312 9.65647 13.9 11.276C13.3602 12.1244 12.4769 12.6836 11.4615 12.8195Z"
        fill="#667085"
      />
      <path
        d="M13.5397 7.72105L12.6789 7.37454C12.9856 6.63611 12.9104 5.81174 12.4703 5.13579C11.7474 4.02494 10.2223 3.72232 9.05874 4.38121L6.59788 5.77456L6.58234 9.47828L5.96387 9.47646L5.9822 5.34668C5.98283 5.17327 6.07762 5.01327 6.23084 4.92642L8.56893 3.60285C10.255 2.64835 12.4812 3.19295 13.3959 4.87827C13.8748 5.76115 13.9273 6.79062 13.5397 7.72105Z"
        fill="#667085"
      />
    </svg>
  );
};

export default AiIcon;
