import { Avatar, Box, Button, Popover, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { User } from '../../util/Types/User';
import { CompanyService } from '../../service/CompanyService';
import {
  Buildings,
  SignOut,
  ToggleLeft,
  ToggleRight,
} from '@phosphor-icons/react';
import { UserService } from '../../service/UserService';
import { AuthService } from '../../service/AuthService';
import { useAuthStore } from '../../store/storeAuth';
import { useNavigate } from 'react-router-dom';

interface Props {
  user: User;
  checked: boolean;
  onLogout: () => void;
  onUpdateTheme: (checked: boolean) => void;
  setAuthUser: (user: User) => void;
  setIsLoading: (loading: boolean) => void;
}
const createRandomBackgroundForDarkAndLight = (theme: string) => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  return theme === 'dark' ? `#${randomColor}` : '#3650DE';
};

const CompanyBox = ({
  user,
  checked,
  onLogout,
  onUpdateTheme,
  setIsLoading,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [myCompany, setMyCompany] = useState<any>(null);
  const navigate = useNavigate();
  const { setAuthUser } = useAuthStore(state => state);

  const userInitials: string = `${
    (selectedCompany?.id ? selectedCompany?.name : user?.name)?.split(
      ' ',
    )?.[0]?.[0] || ''
  } ${
    (selectedCompany?.id ? selectedCompany?.name : user?.name)?.split(
      ' ',
    )?.[1]?.[0] || ''
  }`;

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLButtonElement | null> | any;
  }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getCompany = async () => {
      try {
        const res = await CompanyService.getUserCompanies();

        const filterCompanies = res?.data?.filter(
          (company: any) => !company?.isCompanyOwner,
        );
        setCompanies(filterCompanies);

        if (user?.selectedCompanyId) {
          setMyCompany(
            res?.data?.find((company: any) => company?.isCompanyOwner),
          );

          setSelectedCompany(
            res?.data?.find(
              (company: any) => company.id === user?.selectedCompanyId,
            ),
          );
        } else {
          setSelectedCompany(null);
          setMyCompany(
            res?.data?.find(
              (company: any) => company.isCompanyOwner && company?.id,
            ),
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (user?.id) {
      getCompany();
    }
  }, [user?.id, user?.selectedCompanyId]);

  useEffect(() => {
    if (!user?.selectedCompanyId && myCompany?.id) {
      handleSwitchToPersonalView();
    }
  }, [myCompany?.id, user?.selectedCompanyId]);

  const handleSwitchToPersonalView = async () => {
    setIsLoading(true);
    try {
      // Check if we need to switch to myCompany
      if (myCompany?.id && myCompany?.id !== selectedCompany?.id) {
        await UserService.switchUserCompany({ company: myCompany });

        const res = await AuthService.getMe();
        setAuthUser({ ...res.data });
        handleClose();
        navigate('/');
      }
      // Check if we need to switch to personal
      else if (
        !myCompany?.id &&
        (user?.selectedCompanyId || selectedCompany?.id)
      ) {
        await UserService.switchUserCompany({ personal: true });

        const res = await AuthService.getMe();
        setAuthUser({ ...res.data });
        handleClose();
        navigate('/');
      }
    } catch (error) {
      console.error('Failed to switch to user environment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSwitchToCompanyView = async (company: any) => {
    setIsLoading(true);
    try {
      await UserService.switchUserCompany({
        company: {
          id: company.id,
          name: company.name,
        },
      });
      const res = await AuthService.getMe();
      setAuthUser({ ...res.data });
      handleClose();
      navigate('/');
    } catch (error) {
      console.error('Failed to switch to company environment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Avatar
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '4px',
            backgroundColor: '#3650DE',
            fontSize: '12px',
          }}
          color="primary"
          src={
            selectedCompany?.id !== myCompany?.id && selectedCompany?.id
              ? selectedCompany?.logo
              : myCompany?.id
              ? myCompany?.logo
              : user?.avatar
          }
        >
          {myCompany?.id ? <Buildings size={14} /> : userInitials}
        </Avatar>
        <Typography
          variant="h6"
          style={{
            color: user?.theme === 'dark' ? 'white' : '#667085',
            fontSize: '16px',
            marginLeft: '8px',
            marginTop: '4px',
            width: '10.5rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {selectedCompany?.id ? selectedCompany?.name : user?.name}
        </Typography>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: user?.theme === 'dark' ? '#101828' : '#FFFFFF',
          },
        }}
      >
        <>
          {user?.registrationMethod !== 'invite' && (
            <Box
              // key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                p: '15px',
                cursor: 'pointer',
                background:
                  myCompany?.id === selectedCompany?.id
                    ? user?.theme === 'dark'
                      ? '#1D2939'
                      : '#EAECF0'
                    : 'unset',
                '&:hover': {
                  background: user?.theme === 'dark' ? '#1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
                borderBottom:
                  user?.theme === 'dark'
                    ? '1px solid #344054'
                    : '1px solid #D0D5DD',
              }}
              onClick={handleSwitchToPersonalView}
            >
              <Avatar
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#3650DE',
                  fontSize: '12px',
                }}
                color="primary"
                src={!myCompany?.id ? user?.avatar : myCompany?.logo}
              >
                {myCompany?.id && user?.selectedCompanyId ? (
                  <Buildings size={24} />
                ) : (
                  userInitials
                )}
              </Avatar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#1D2939',
                    fontSize: '16px',
                    width: '12.5rem',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {myCompany?.id ? myCompany?.name : user?.name}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    color: '#475467',
                    fontSize: '14px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  {myCompany?.role || user?.email}
                </Typography>
              </Box>
            </Box>
          )}
          {companies?.map((company, i) => {
            const companyUserFind = company?.companyUsers?.find(
              (companyUser: any) =>
                companyUser?.user?.id === user?.id &&
                companyUser?.role === 'admin',
            );

            const isCompanyOwner = company?.isCompanyOwner;

            const companyName = company?.name;

            const companysInitials: string = `${
              (company?.name || companyUserFind.user?.name)?.split(
                ' ',
              )?.[0]?.[0] || ''
            } ${
              (company?.name || companyUserFind.user?.name)?.split(
                ' ',
              )?.[1]?.[0] || ''
            }`;
            return (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                  p: '15px',
                  cursor: 'pointer',
                  background:
                    selectedCompany?.id === company?.id
                      ? user?.theme === 'dark'
                        ? '#1D2939'
                        : '#EAECF0'
                      : 'unset',
                  '&:hover': {
                    background: user?.theme === 'dark' ? '#1D2939' : '#EAECF0',
                    boxShadow: 'unset',
                  },
                  borderBottom:
                    user?.theme === 'dark'
                      ? '1px solid #344054'
                      : '1px solid #D0D5DD',
                }}
                onClick={() => handleSwitchToCompanyView(company)}
              >
                <Avatar
                  style={{
                    borderRadius: '4px',
                    backgroundColor: createRandomBackgroundForDarkAndLight(
                      user?.theme,
                    ),
                    fontSize: '12px',
                  }}
                  color="primary"
                  src={company?.logo}
                >
                  <Buildings size={24} />
                </Avatar>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#1D2939',
                      fontSize: '16px',
                      width: '12.5rem',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {isCompanyOwner && !companyName ? user.name : company.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: '#475467',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  >
                    {company.role === 'employee' ? 'Org User' : company.role}
                  </Typography>
                </Box>
              </Box>
              // here
            );
          })}

          <Box
            sx={{
              width: '100%',
              padding: '8px',
              background: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            }}
          >
            <Button
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                color: user?.theme === 'dark' ? '#F2F4F7' : '#1D2939',
                ':hover': {
                  background: user?.theme === 'dark' ? '#1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => onUpdateTheme(!checked)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                  padding: '0px',
                  justifyContent: 'flex-start',
                }}
              >
                {checked ? (
                  <ToggleRight weight="fill" size={25} color="#667085" />
                ) : (
                  <ToggleLeft weight="fill" size={25} color="#667085" />
                )}
                <Typography
                  color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Switch to {checked ? 'Light' : 'Dark'} Mode
                </Typography>
              </Box>
            </Button>
            <Button
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                color: user?.theme === 'dark' ? '#F2F4F7' : '#475467',
                ':hover': {
                  background: user?.theme === 'dark' ? '#1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={onLogout}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                  padding: '0px',
                  justifyContent: 'flex-start',
                }}
              >
                <SignOut size={25} color="#667085" style={{}} />
                <Typography
                  color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Log Out
                </Typography>
              </Box>
            </Button>
          </Box>
        </>
      </Popover>
    </>
  );
};

export default CompanyBox;
