import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/storeAuth';
import { VoiceService } from '../../service/VoiceService';
import { AuthService } from '../../service/AuthService';
import LoadingSpinner from '../UI/LoadingSpinner';
import { CompanyService } from '../../service/CompanyService';
import KitModal from '../UI/KitModal';
import { Box } from '@mui/material';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useFlowsStore from '../../store/storeFlows';

type Props = {
  show: boolean;
  onHide: () => void;
  isEditingConfig?: {
    id: string;
    edit: boolean;
  };
  setIsEditingConfig?: (editing: { id: string; edit: boolean }) => void;
};

const VoiceCloneModal = ({ show, onHide }: Props) => {
  const { flowId } = useFlowsStore();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [voiceName, setVoiceName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<any>();
  const { setAuthUser } = useAuthStore(state => state);
  const [allowCompanyUsers, setAllowCompanyUsers] = useState(false);
  const [type, setType] = useState('play.ht');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    setSelectedFile(file!);
  };

  useEffect(() => {
    const getCompany = async () => {
      try {
        const res = await CompanyService.getCompanies();
        setCompany(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCompany();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile!);

    try {
      if (type === 'play.ht') {
        await VoiceService.generateClonedVoice({
          voiceName: voiceName,
          companyId: allowCompanyUsers ? company[0].id : undefined,
          file: formData,
          flowId,
        });
        const res = await AuthService.getMe();
        setAuthUser(res.data);
        setVoiceName('');
        setSelectedFile(null);
      }

      if (type === 'evenLabs') {
        // const response = await EvenLabsService.clone(formData);
        setSelectedFile(null);
      }
    } catch (error) {
      console.error('Error uploading file', error);
      setIsLoading(false);
      // Handle fetch error
    } finally {
      setIsLoading(false);
      setAllowCompanyUsers(false);
      onHide();
    }
  };

  return (
    <KitModal
      onHide={() => {
        onHide();
      }}
      show={show}
      footer={
        <>
          <Button
            // disabled={!selectedFile || voiceName === '' ? true : false}
            onClick={handleSubmit}
          >
            {isLoading ? <LoadingSpinner /> : 'Save'}
          </Button>
        </>
      }
      title="Voice Clone"
    >
      <>
        <Box>
          <Select
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}
            name="type"
          >
            <MenuItem value="play.ht">play.ht</MenuItem>
            <MenuItem value="evenLabs">Eleven Labs</MenuItem>
          </Select>
          <br />
          <br />

          {type === 'play.ht' && (
            <TextField
              type="text"
              name="voiceName"
              placeholder="Add the voice name"
              style={{ marginBottom: '10px' }}
              onChange={e => setVoiceName(e.target.value)}
            />
          )}
          <br />

          <TextField
            type="file"
            style={{ marginBottom: '10px' }}
            name="file"
            onChange={handleFileChange}
            inputProps={{
              accept: 'audio/wav, audio/mp3',
            }}
          />

          {type === 'play.ht' && (
            <>
              {company?.length > 0 ? (
                <FormControl
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <FormLabel>
                    <strong>Allow company users to access this voice</strong>
                  </FormLabel>
                  <Checkbox
                    title="Allow company users to access this voice"
                    onChange={() => setAllowCompanyUsers(!allowCompanyUsers)}
                    checked={allowCompanyUsers}
                    inputProps={{
                      'aria-label': 'Allow company users to access this voice',
                    }}
                  />
                </FormControl>
              ) : (
                <Checkbox
                  disabled
                  title="You need to create a company first"
                  id="disabled-custom-switch"
                  checked={allowCompanyUsers}
                  inputProps={{
                    'aria-label': 'You need to create a company first',
                  }}
                />
              )}
            </>
          )}
        </Box>
      </>
    </KitModal>
  );
};

export default VoiceCloneModal;
