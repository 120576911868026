import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class MailService extends ApiService {
  static pathName = 'mail';

  static send = (body: {
    emails: string[];
    subject?: string;
    text: string;
    flowId: string;
    urls?: string[];
    callbackUrl?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/send`, {
      ...body,
      callBackUrl: body.callbackUrl,
    });
  };
}
