import styled from 'styled-components';

export const CustomTargetHandle = styled.div<{
  bgColor?: string;
}>`
  .custom-target-handle {
    border: none;
    outline: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 5px;
    border-radius: 50%;
    transition: 0.3s ease;
  }

  .custom-target-handle:before {
    content: '';
    background: ${props =>
      props?.bgColor === 'red'
        ? 'linear-gradient(45deg, white, #ff7373)'
        : 'linear-gradient(45deg, white, blue)'};
    position: absolute;
    top: -3px;
    left: -2.5px;
    background-size: 200%;
    z-index: -1;
    filter: blur(3px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 5s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  .custom-target-handle:hover:before {
    opacity: 1;
  }

  .custom-target-handle:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-40deg) translateX(-1px) translateY(-1.5px);
    background: ${props =>
      props.bgColor === 'red'
        ? 'conic-gradient(#ff0044 0deg 260deg, transparent 0deg 260deg)'
        : 'conic-gradient(#80d4ff 0deg 260deg, transparent 0deg 260deg)'};
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;
