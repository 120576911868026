import styled from 'styled-components';

interface CustomHandleProps {
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  isLoading?: boolean;
  isConnected?: boolean;
}

export const CustomHandle = styled.div<CustomHandleProps>`
  .flow-global-handle {
    left: ${props => props?.left};
    top: ${props => props?.top};
    bottom: ${props => props?.bottom};
    right: ${props => props?.right};
    border-radius: 5px !important;
    position: absolute;
    background: black !important;
    width: 18px !important;
    height: 18px !important;
    animation: ${props =>
      props?.isLoading && 'gradientAnimation 4s linear infinite'};
    border: 5px solid ${props => (props?.isConnected ? 'green' : '#cfcdcb')};
  }

  @keyframes gradientAnimation {
    100% {
      transform: translate(-9px, 0px) rotate(-360deg);
    }
  }
`;
