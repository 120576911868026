export const getModelMaxTokens = (aiModel: string, isAutoSetting: boolean) => {
  if (aiModel === 'gpt-4' && isAutoSetting) {
    return 8192;
  } else if (aiModel === 'gpt-4-0613' && isAutoSetting) {
    return 8192;
  } else if (aiModel === 'gpt-3.5-turbo-16k' && isAutoSetting) {
    return 16000;
  } else if (aiModel === 'gpt-3.5-turbo' && isAutoSetting) {
    return 4000;
  } else if (aiModel === 'gpt-3.5-turbo-1106' && isAutoSetting) {
    return 120000;
  } else if (aiModel === 'gpt-4-1106-preview' && isAutoSetting) {
    return 120000;
  } else {
    return 1500;
  }
};
