import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { Position, useEdges, useReactFlow } from 'reactflow';
import { VoiceService } from '../../service/VoiceService';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import { useAuthStore } from '../../store/storeAuth';

import { VoiceInfo } from '../Voice/EvenLabs';
import { EvenLabsService } from '../../service/EvanLabsServce';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import {
  Box,
  Divider,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import TagInput from '../Test/TagInput';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import {
  CaretDown,
  Faders,
  Gear,
  PlusCircle,
  UserCircle,
} from '@phosphor-icons/react';
import Node from '../UI/Node/Node';
import { FormData, defaultAudioFormData } from '../AiAudioGenerator/constants';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputAudio from '../UI/OutputAudio/OutputAudio';
import voices from '../flux_classes/voice';
import SettingsModal from '../AiAudioGenerator/SettingsModal/SettingsModal';
import { isActiveEdge } from '../../util/findActiveHandle';
import InputHandle from '../UI/InputHandle/InputHandle';
import CloneModal from '../AiAudioGenerator/CloneModal';
import { Pro } from '../../views/sidebarFlowConstants';
import { executeErrorHandle } from '../../util/executeErrorHandle';

function AiAudioGenerator({ data, type, id }: NodeProps) {
  const {
    socketNodeResponse,
    setSchema,
    flowId,
    setSocketNodeResponse,
    setSaving,
  } = useFlowsStore(state => state);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const { setNodes: setNodeState } = useReactFlow();
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const { user } = useAuthStore(state => state);
  const [previewResponses, setPreviewResponses] = useState([]);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [evenLabsVoices, setEvenLabsVoices] = useState<VoiceInfo[]>([]);
  const [userCustomVoices, setUserCustomVoices] = useState<VoiceInfo[]>([]);

  const [evenLabsSettings, setEvenLabsSettings] = useState<{
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
  }>({
    stability: 0.5,
    similarity_boost: 0.75,
    style: 0,
    use_speaker_boost: true,
  });

  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [cloneModal, setCloneModal] = useState({
    system: '',
    open: false,
  });
  const [formData, setFormData] = useState<FormData>({
    ...defaultAudioFormData,
    // voiceSystem: 'elevenlabs.io',
  });

  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);

  const schema = useFlowsStore(state => state.schema);
  const [isLoading, setIsLoading] = useState(false);
  const [initialStart, setInitialStart] = useState(true);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          ...defaultAudioFormData,
          ...data,
        });

        if (data?.previewResponses?.length > 0) {
          setPreviewResponses(data.previewResponses);
        }
        setEvenLabsSettings({
          stability: data?.stability ?? 0.5,
          similarity_boost: data?.similarity_boost ?? 0.75,
          style: data?.style ?? 0,
          use_speaker_boost: data?.use_speaker_boost ?? true,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const generateVoiceAPI = async () => {
    setIsLoading(true);

    try {
      let response: any;

      let text = formData.text;

      if (data?.textData?.length) {
        text = data?.textData?.map((item: any) => item.text)?.join(' \n');
      }

      if (formData.voiceSystem === 'elevenlabs.io') {
        const res = await EvenLabsService.voiceGenerate(formData.voiceId, {
          text: replacePlaceholders(text!, schema),
          modelId: 'eleven_multilingual_v2',
          stability: +evenLabsSettings.stability,
          similarityBoost: +evenLabsSettings.similarity_boost,
          style: +evenLabsSettings.style,
          use_speaker_boost: evenLabsSettings.use_speaker_boost,
          flowId,
        });

        response = {
          data: {
            message: res.data.src,
          },
        };
      } else {
        response = await VoiceService.generateVoice({
          text: replacePlaceholders(text!, schema),
          voice: formData.voice.toLowerCase(),
          outputFormat: formData.outputFormat,
          speed: +formData.speed,
          sampleRate: +formData.sampleRate,
          quality: formData.quality,
          flowId,
        });
      }

      setSocketNodeResponse(id, null);

      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );

      setNotification({
        message: 'Something went wrong generating the voice',
        type: 'error',
      });
    } finally {
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      setIsLoading(false);
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setSaving,
        setNodeState,
        id,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
        flowId,
        objectData: {
          audioUrl: data?.data?.src ?? data?.data?.message,
          previewResponses: data?.previewResponses,
          previewIndex: data?.previewIndex,
          usedTokens: data?.flowTrigger?.id
            ? data?.data?.usedTokens
            : undefined,
          nodeUsedTokens: data?.data?.usedTokens,
        },
        setSchema,
      });

      setHasToExecute(true);

      setSocketNodeResponse(null, null);
    }
  }, [
    socketNodeResponse?.nodeId,
    socketNodeResponse?.finished,
    data.audioUrl,
    data?.previewResponses,
    data?.previewIndex,
    data?.flowTrigger,
    edges,
  ]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          generateVoiceAPI();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  const onChange = useCallback(
    (evt: { target: { name: any; value: any; checked?: boolean } }) => {
      if (evt.target.value !== undefined) {
        setWhoIsChanging({
          name: evt.target.name,
          value:
            evt.target?.checked !== undefined
              ? evt.target?.checked
              : evt.target.value,
        });
        setFormData(prevState => ({
          ...prevState,
          [evt.target.name]:
            evt.target?.checked !== undefined
              ? evt.target?.checked
              : evt.target.value,
        }));

        setIsChangingDirectly(true);
      }
    },
    [],
  );

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          setFormData(prevState => ({
            ...prevState,
            audioUrl: previewResponses[nextIndex],
            previewIndex: nextIndex,
          }));

          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            setSaving,
            id,
            changeType: 'changingPreviewIndex',
            flowId,
            objectData: {
              audioUrl: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  useEffect(() => {
    const evenLabs = async () => {
      try {
        const response = await EvenLabsService.voices();
        setEvenLabsVoices(response?.data?.voices);
        const customVoices = await EvenLabsService.userVoices();

        setUserCustomVoices(customVoices?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setInitialStart(false);
      }
    };

    if (initialStart) {
      evenLabs();
    }

    return () => {
      setInitialStart(false);
    };
  }, [initialStart]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setSaving,
          setNodeState,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const onChangeOnExecute = (e: any) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        clearOnExecution: e.target.checked,
      },
      setSchema,
    });
  };

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        type={type}
        btnText="Run Prompt"
        id={id}
        isLoading={isLoading}
        data={data}
        edges={edges}
        handleRun={handleRun}
        onSubmit={generateVoiceAPI}
        onChangePlacement={onChangePlacement}
      >
        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
          >
            {data.placement !== 'Output' && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    mb={'8px'}
                    fontSize={'16px'}
                    color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                    fontWeight={600}
                  >
                    {'Voice Selection'}
                  </Typography>

                  <Gear
                    onClick={() => setSettingsModalOpen(true)}
                    size={18}
                    color="#98A2B3"
                  />
                </Box>

                {formData.voiceSystem === 'play.ht' && (
                  <Select
                    sx={{
                      bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      mb: '24px',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '300px',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                    value={formData.voice}
                    name="voice"
                    onChange={onChange}
                    size="small"
                    className="nodrag"
                  >
                    <ListSubheader
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      }}
                    >
                      Custom voices
                    </ListSubheader>
                    <MenuItem
                      onClick={() =>
                        setCloneModal({
                          system: formData.voiceSystem,
                          open: true,
                        })
                      }
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      <PlusCircle size={18} color="#475467" />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        }}
                        fontWeight={700}
                        variant="body1"
                      >
                        Clone Voice
                      </Typography>
                      <Pro />
                    </MenuItem>
                    {userCustomVoices
                      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((voice, index: number) => (
                        <MenuItem
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                          key={index}
                          value={voice.id}
                        >
                          <UserCircle size={18} color="#475467" />
                          <Typography
                            sx={{
                              textTransform: 'capitalize',
                              fontSize: '12px',
                              fontWeight: 700,
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                            }}
                            variant="body1"
                          >
                            {voice.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    <ListSubheader
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      }}
                    >
                      Voices
                    </ListSubheader>
                    {voices
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(voice => (
                        <MenuItem
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                          key={voice.id}
                          value={voice.name}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                            }}
                            fontWeight={700}
                            variant="body1"
                          >
                            {voice.name}
                          </Typography>{' '}
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                            }}
                            variant="body1"
                          >
                            ({voice.language})
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                )}

                {formData.voiceSystem === 'elevenlabs.io' && (
                  <Select
                    className="nodrag"
                    value={formData.voiceId}
                    name="voiceId"
                    onChange={onChange}
                    sx={{
                      bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      mb: '24px',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '300px',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                  >
                    <ListSubheader
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      }}
                    >
                      Custom voices
                    </ListSubheader>
                    <MenuItem
                      onClick={() =>
                        setCloneModal({
                          system: formData.voiceSystem,
                          open: true,
                        })
                      }
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <PlusCircle size={18} color="#475467" />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        }}
                        fontWeight={700}
                        variant="body1"
                      >
                        Clone Voice
                      </Typography>
                      <Pro />
                    </MenuItem>
                    <ListSubheader
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      }}
                    >
                      Voices
                    </ListSubheader>
                    {evenLabsVoices
                      ?.sort((a, b) =>
                        (a.name || '').localeCompare(b.name || ''),
                      )
                      .map((voice, index) => (
                        <MenuItem
                          key={(voice?.voice_id || voice?.id) + index}
                          value={voice?.voice_id || voice?.id}
                        >
                          <Typography
                            sx={{
                              textTransform: 'capitalize',
                              fontSize: '12px',
                              fontWeight: 700,
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                            }}
                            variant="body1"
                          >
                            {voice?.src} {voice?.name}{' '}
                            {voice?.labels && (
                              <>
                                {voice?.labels?.accent} /{' '}
                                {voice?.labels?.gender}
                              </>
                            )}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </>
            )}
            <TagInput
              name="text"
              value={formData.text}
              onChange={onChange}
              handleId={'text-input'}
              labelName={'Text'}
              dataConnected={data?.textData || []}
              placement={data?.placement}
              nodeId={id}
              onChangePlacement={onChangePlacement}
              isCollapsed={true}
              disabled={data?.disabled}
              isActive={isActiveEdge(edges, id, 'text-input', 'target')}
              findColor={findColor}
              nodeLabel={data?.label}
              placeholder="Enter the text that will be turned into an audio format."
            />

            <Divider
              sx={{
                // mt: !collapsed ? '20px' : '200px',
                mt: '64px',
                mb: '10px',
              }}
            />
            {data?.placement !== 'Output' && (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                position={'relative'}
              >
                <InputHandle
                  activeColor={findColor?.color}
                  handleId={'modelSettings'}
                  isActive={true}
                  left={'-26px'}
                  top={'50%'}
                  position={Position.Left}
                  type="target"
                />
                <Box
                  bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                  borderRadius={'4px'}
                  sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                  onClick={() => setSettingsModalOpen(true)}
                >
                  <Faders size={18} color="#667085" />
                  <Typography fontSize={'12px'} color="#667085" variant="h6">
                    {formData.voiceSystem}
                  </Typography>

                  <CaretDown size={'16px'} color="#667085" />
                </Box>
              </Box>
            )}
            <SettingsModal
              onHide={() => setSettingsModalOpen(false)}
              show={settingsModalOpen}
              formData={formData}
              onChange={onChange}
            />
          </Box>
          <Box p={'16px 24px 16px 16px'}>
            <OutputAudio
              previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data?.audioUrl}
              activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              placement={data?.placement}
              labelName={'Output'}
              onPreview={onPreview}
              findColor={findColor}
              onChangePlacement={onChangePlacement}
              onChangeOnExecute={onChangeOnExecute}
              hasClearOnExecute={true}
              clearOnExecution={data.clearOnExecution || false}
            />
          </Box>
        </Box>
      </Node>
      <CloneModal
        onHide={() => setCloneModal({ system: '', open: false })}
        show={cloneModal.open}
        system={formData.voiceSystem}
      />
    </Box>
  );
}

export default memo(AiAudioGenerator);
