import { Box } from '@mui/material';
import React, { useState } from 'react';
import { NodeResizer, useReactFlow } from 'reactflow';
import { useAuthStore } from '../../../../store/storeAuth';
import { ArrowsOutCardinal, Trash, X, XCircle } from '@phosphor-icons/react';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';

interface FormNodeProps {
  children: React.ReactNode;
  id: string;
  type: string;
  nodeSelected?: boolean;
  data?: any;
  borderRadius?: string;
}

const FormNode = ({
  children,
  id,
  data,
  nodeSelected,
  borderRadius = '8px',
}: FormNodeProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [draggable, setDraggable] = useState(true);
  const [selected, setSelected] = useState(false);
  const user = useAuthStore(state => state.user);

  const reactFlow = useReactFlow();

  const onDeleteNode = () => {
    reactFlow?.setNodes(nds => nds.filter(n => n.id !== id));
  };

  return (
    <Box
      sx={{
        borderRadius,
        height: '100%',
        bgcolor: data?.bgColor ? data?.bgColor : '#fff',
        border: nodeSelected ? '2px dashed #3650DE' : 'none',
      }}
      className={draggable ? '' : 'nodrag'}
    >
      <Box
        padding={'6px'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: '8px',
          bgcolor: user?.theme === 'dark' ? '#101828' : '#f2f4f7',
        }}
        onClick={() => setSelected(!selected)}
      ></Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: '8px',
          p: '0 12px',
        }}
      >
        {/* <IconButtonTooltip
            onClick={() => setIsVisible(!isVisible)}
            title="Resize Node Form (this will not resize the content in the preview)"
          >
            <Resize
              size={18}
              color={
                isVisible
                  ? '#3650DE'
                  : user?.theme === 'dark'
                  ? '#fff'
                  : '#475467'
              }
            />
          </IconButtonTooltip> */}

        <IconButtonTooltip
          onClick={() => setDraggable(!draggable)}
          title="Drag Form"
        >
          <ArrowsOutCardinal
            size={16}
            color={
              draggable
                ? '#3650DE'
                : user?.theme === 'dark'
                ? '#fff'
                : '#475467'
            }
          />
        </IconButtonTooltip>

        <IconButtonTooltip onClick={onDeleteNode} title="Delete Form">
          <XCircle color="#475467" size={20} />
        </IconButtonTooltip>
      </Box>
      <NodeResizer
        onResize={(event, positions) => {}}
        isVisible={isVisible}
        lineStyle={{}}
        color="red"
        maxWidth={1000}
        minWidth={200}
      />
      <Box
        onClick={() => {
          setSelected(false);
        }}
        p={'16px 24px'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FormNode;
