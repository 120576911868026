import React, { useCallback, useEffect, useState } from 'react';
import NoFlows from './NoFlows';
import { Box } from '@mui/material';
import FlowsTable from './FlowsTable';
import FlowStats from './FlowStats';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { CompanyService } from '../../service/CompanyService';
import { getAPIErrorMessage } from '../../helpers/helpers';
import LoadingSpinner from '../UI/LoadingSpinner';

const Flows = () => {
  const authUser = useAuthStore(state => state.user);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [loading, setLoading] = useState(true);
  const [flows, setFlows] = useState<any[]>([]);
  const [selectedFlowId, setSelectedFlowId] = useState<undefined | string>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getFlows = useCallback(async () => {
    if (!authUser) return;

    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });
    try {
      const { data } = await CompanyService.getFlows(
        authUser.selectedCompanyId,
      );

      setFlows(data);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }

    setLoading(false);
  }, [authUser]);

  useEffect(() => {
    getFlows();
  }, [getFlows]);

  const renderFlows = () => {
    if (flows.length === 0) {
      return <NoFlows />;
    }

    if (selectedFlowId) {
      return (
        <FlowStats
          flowId={selectedFlowId}
          setSelectedFlowId={setSelectedFlowId}
        />
      );
    }

    if (flows.length > 0)
      return (
        <FlowsTable
          flows={flows}
          setSelectedFlowId={setSelectedFlowId}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          getFlows={getFlows}
        />
      );
  };

  return <Box>{loading ? <LoadingSpinner /> : renderFlows()}</Box>;
};

export default Flows;
