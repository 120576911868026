import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import KitModal from '../../UI/KitModal';
import { useAuthStore } from '../../../store/storeAuth';
import { CheckCircle } from '@phosphor-icons/react';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import { UploadService } from '../../../service/UploadService';
import { useNotificationStore } from '../../../store/storeNotifications';
import Tesseract from 'tesseract.js';
import { franc } from 'franc';
import { iso6393 } from 'iso-639-3';
import { pdfjs } from 'react-pdf';
const VectorMemoryUploadModal = ({
  openVectorUploadModal,
  setOpenVectorUploadModal,
  onUploadDocument,
  selectVector,
  onDeleteUploadedDocument,
  onDeleteNameSpace,
}: any) => {
  const [tabs, setTabs] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    selectedDocument: '',
    selectedFluxPromptObject: '',
    fluxPromptObjectText: '',
  });
  const [finish, setFinish] = useState(false);
  const isEditing = openVectorUploadModal.type === 'edit';
  pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

  const setNotification = useNotificationStore(state => state.setNotification);
  const { user: authUser, documents } = useAuthStore(state => state);
  const [notifyModal, setNotifyModal] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const onChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const onHideModal = () => {
    setOpenVectorUploadModal({
      open: false,
      type: '',
    });
    setFormData({
      name: '',
      selectedDocument: '',
      selectedFluxPromptObject: '',
      fluxPromptObjectText: '',
    });

    setFinish(false);

    setTabs(0);
  };

  const handleUpload = () => {
    // console.log('Uploading files:', uploadedFiles);
    // console.log('formData:', formData);
    if (tabs === 0) {
      if (
        !uploadedFiles ||
        uploadedFiles.length === 0 ||
        (!formData.name && !isEditing)
      ) {
        alert(
          `Please select ${
            !uploadedFiles || uploadedFiles.length === 0 ? 'a file' : 'a name'
          } for your Memory Pod.`,
        );

        setFinish(false);
        setNotifyModal(false);
        return;
      }
      const files = uploadedFiles;

      onUploadDocument({
        vectorName: isEditing ? selectVector?.name : formData.name,
        files: files,
        type: 'upload-from-computer',
        formData: formData,
      });

      setUploadedFiles(null);
    }

    if (tabs === 1) {
      if (!formData.selectedDocument || (!formData.name && !isEditing)) {
        alert(
          `Please select a ${
            !formData.selectedDocument ? 'document' : 'name'
          } for your Memory Pod.`,
        );

        setFinish(false);
        setNotifyModal(false);
        return;
      }

      onUploadDocument({
        vectorName: isEditing ? selectVector?.name : formData.name,
        file: formData.selectedDocument,
        type: 'select-from-documents',
        formData: formData,
      });
    }

    if (tabs === 2) {
      if (
        !formData.selectedFluxPromptObject ||
        !formData.fluxPromptObjectText ||
        (!formData.name && !isEditing)
      ) {
        alert(
          `Please select a ${
            !formData.selectedFluxPromptObject ? 'Flux Prompt Object' : 'Object'
          } for your Memory Pod.`,
        );

        setFinish(false);
        setNotifyModal(false);
        return;
      }

      onUploadDocument({
        vectorName: isEditing ? selectVector?.name : formData.name,
        file: formData.fluxPromptObjectText, // Pass as an array to maintain consistency
        type: 'select-from-fluxprompt-object',
        formData: formData,
      });
    }

    setFinish(true);
  };

  const [language, setLanguage] = useState<string>(
    localStorage.getItem('language') ?? 'eng',
  );

  const identifyImagePages = async (pdf: any): Promise<number[]> => {
    const imagePages: number[] = [];

    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const operatorList = await page.getOperatorList();
      const textContent = await page.getTextContent();

      const hasImages = operatorList.fnArray.some(
        (fn: any) =>
          fn === pdfjs.OPS.paintImageXObject ||
          fn === pdfjs.OPS.paintInlineImageXObject ||
          fn === pdfjs.OPS.paintImageMaskXObject,
      );

      if (hasImages) {
        imagePages.push(pageNumber);
      }
    }

    return imagePages;
  };

  const detectLanguage = (text: string) => {
    const detectedLang = franc(text);
    const languageName =
      iso6393.find(lang => lang.iso6393 === detectedLang)?.name || 'Unknown';
    setLanguage(languageName);
  };

  const recognizeTextFromImage = async (image: string, language: string) => {
    const worker = await Tesseract.createWorker(language, 1, {
      corePath: '/tesseract-core.wasm.js',
      workerPath: '/worker.min.js',
      logger: function (m) {},
    });

    const {
      data: { text },
    } = await worker.recognize(image);
    await worker.terminate();
    return text;
  };

  const onVerifyIfItsPdfFile = async (file: File) => {
    try {
      if (!file) return;
      // console.log("Picked File: ", file);
      setNotification({
        type: 'info',
        message: 'Extracting the PDF',
      });

      // console.log("Uploaded File Response: ", res);
      const originalFileName =
        file.name.split('.').slice(0, -1).join('.') || 'file';
      const uniqueSuffix = Date.now();
      let extractedText = '';

      if (file.type === 'application/pdf') {
        const arrayBuffer = await file.arrayBuffer();
        const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
        const pdf = await loadingTask.promise;

        const imagePages = await identifyImagePages(pdf);
        const totalPages = pdf.numPages;
        // console.log("Total Number of Pages: ", totalPages);

        const textPromises = Array.from(
          { length: totalPages },
          async (_, i) => {
            const pageNumber = i + 1;
            // console.log(`Processing page ${pageNumber}`);
            if (imagePages.includes(pageNumber)) {
              // console.log(`Page ${pageNumber} has images, converting to image and extracting text`);
              const page = await pdf.getPage(pageNumber);
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement('canvas');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const context = canvas.getContext('2d');
              if (context) {
                // console.log("Extracting text from image");
                await page.render({ canvasContext: context, viewport }).promise;
                const image = canvas.toDataURL('image/png');
                const text = await recognizeTextFromImage(image, 'eng');
                // const { data: { text } } = await worker.recognize(image);
                // await worker.terminate();
                detectLanguage(text);
                return `Page ${pageNumber}:\n${text}`;
              }
            } else {
              // console.log(`Page ${pageNumber} has no images, extracting text directly`);
              const page = await pdf.getPage(pageNumber);
              const textContent = await page.getTextContent();
              const text = textContent.items
                .map((item: any) => item.str)
                .join(' ');
              return `Page ${pageNumber}:\n${text}`;
            }
          },
        );

        const texts = await Promise.all(textPromises);
        extractedText = texts.join('\n');

        // console.log('Extracted Text:', extractedText);

        const blob = new Blob([extractedText], { type: 'text/plain' });
        const txtFile = new File(
          [blob],
          `extracted-pdf-${originalFileName}-${uniqueSuffix}.txt`,
          { type: 'text/plain' },
        );
        setNotification({
          type: 'success',
          message: 'File extracted successfully',
        });
        return txtFile;
        // console.log("Text Upload Service Response: ", txtUploadRes);
      } else {
        return file;
      }
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
    }
  };

  const onChangeUploadFiles = async (e: any) => {
    const files = Array.from(e?.target?.files) as File[];

    let newFiles: (File | undefined)[] = [];
    await Promise.all(
      files.map(async file => {
        const verifiedFile = await onVerifyIfItsPdfFile(file);

        return newFiles.push(verifiedFile);
      }),
    );

    if ((uploadedFiles || [])?.length) {
      setUploadedFiles([...uploadedFiles, ...newFiles]);
    } else {
      setUploadedFiles(newFiles);
    }
  };

  return (
    <>
      <KitModal
        show={notifyModal}
        maxWidth={'md'}
        onHide={() => {
          setNotifyModal(false);
          setFinish(false);
        }}
        showCloseButton={false}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '668px',
            borderRadius: '16px',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {finish && <CheckCircle size={25} weight="fill" color="#4CAF50" />}
            <Typography fontSize={'20px'} variant="h6">
              {finish
                ? 'Documents are being uploaded!'
                : 'Proceed to add document'}
            </Typography>
          </Box>

          <Typography mt={'5px'} variant="body2">
            {finish
              ? 'Depending on size of documents, please allow up to an hour for documents to upload to the Large Memory Pod.'
              : ` Once document is uploaded to a Memory Pod, they cannot be edited nor
            removed. Would you like to proceed to add document?`}
          </Typography>

          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            justifyContent={'end'}
            mt={'60px'}
          >
            {!finish && (
              <Button onClick={onHideModal} variant="outlined">
                Cancel
              </Button>
            )}
            <Button
              sx={{
                boxShadow: 'none',
              }}
              // disabled={formData.name === '' || !uploadedFile}
              onClick={() => {
                if (!finish) {
                  handleUpload();
                } else {
                  setNotifyModal(false);
                  onHideModal();
                }
              }}
              variant="contained"
            >
              {finish ? 'Dismiss' : 'Add Doc'}
            </Button>
          </Box>
        </Box>
      </KitModal>

      <KitModal
        show={openVectorUploadModal.open}
        maxWidth={'md'}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '668px',
            borderRadius: '16px',
          },
        }}
        onHide={onHideModal}
        // title={`Memory Pod : ${selectVector?.name}`}
      >
        <>
          <Typography
            fontSize={'20px'}
            variant="h6"
            color={authUser?.theme === 'dark' ? '#D0D5DD' : 'black'}
          >
            {isEditing
              ? `Edit ${selectVector?.name}`
              : 'Create a Large Memory Pod'}
          </Typography>
          <Typography
            mt={'5px'}
            variant="body2"
            color={authUser?.theme === 'dark' ? '#D0D5DD' : 'black'}
          >
            {isEditing
              ? 'Documents already uploaded.'
              : `Create a memory pod and upload documents into your pod. Then, use the
          Large Memory Node to easily query the documents and receive answers to
          your questions.`}
          </Typography>

          {isEditing && (
            <Box mt={'24px'}>
              {selectVector?.files?.map((file: any, i: any) => (
                <Chip
                  sx={{
                    borderRadius: '4px',
                    color: '#3f51b5',
                    borderColor: '#3f51b5',
                  }}
                  deleteIcon={
                    <Trash color="#d32f2f" weight="duotone" size={14} />
                  }
                  onDelete={() => onDeleteUploadedDocument(file)}
                  key={i}
                  label={file}
                  variant="outlined"
                />
              ))}
            </Box>
          )}

          {!isEditing && (
            <TextField
              label="Name your Memory Pod"
              fullWidth
              name="name"
              value={formData.name}
              onChange={onChange}
              sx={{
                mt: '24px',
                '& .MuiInputLabel-root': {
                  color: '#9e9e9e',
                },
              }}
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#9e9e9e',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#9e9e9e',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#9e9e9e',
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: '#9e9e9e',
                },
              }}
            />
          )}

          <Typography
            mt={'24px'}
            variant="h6"
            color={authUser?.theme === 'dark' ? '#D0D5DD' : 'black'}
          >
            {isEditing
              ? 'Add new documents into memory pod'
              : 'Upload documents into memory pod'}
          </Typography>
          <Typography
            mt={'5px'}
            variant="body2"
            color={authUser?.theme === 'dark' ? '#D0D5DD' : 'black'}
          >
            Choose the documents you want to put into your memory pod. You can
            select FluxPrompt objects, documents uploaded to FluxPrompt, or
            documents from your computer.
          </Typography>
          <Tabs
            value={tabs}
            onChange={(event, newValue) => {
              setTabs(newValue);
            }}
            sx={{
              mt: '24px',
              '& .MuiTabs-indicator': {
                backgroundColor:
                  authUser?.theme === 'dark' ? '#D0D5DD' : 'black',
              },
              '& .MuiTab-root': {
                color: authUser?.theme === 'dark' ? '#D0D5DD' : 'black',
              },
              '& .Mui-selected': {
                color: 'primary.main',
              },
            }}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                textTransform: 'none',
              }}
              label="Upload from computer"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                textTransform: 'none',
              }}
              label="FluxPrompt Uploads"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                textTransform: 'none',
              }}
              label="FluxPrompt Objects"
              {...a11yProps(2)}
            />
          </Tabs>

          {tabs === 0 && (
            <Box>
              <TextField
                fullWidth
                type="file"
                inputProps={{ multiple: true }}
                id={Math.random().toString()}
                onChange={onChangeUploadFiles}
                sx={{
                  mt: '34px',
                  mb: '24px',
                  '& .MuiInputLabel-root': {
                    color: '#9e9e9e',
                  },
                  '& label': {
                    backgroundColor: '#3f51b5',
                    color: '#fff',
                    padding: '10px 15px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  },
                  '& label:hover': {
                    backgroundColor: '#303f9f',
                  },
                }}
                variant="outlined"
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {uploadedFiles && uploadedFiles?.length > 0 && (
                  <Typography variant="body2" mt={1}>
                    {uploadedFiles.length} file(s) selected
                  </Typography>
                )}

                {uploadedFiles && uploadedFiles?.length > 1 && (
                  <Button
                    onClick={() => {
                      setUploadedFiles([]);
                    }}
                  >
                    Clear Files
                  </Button>
                )}
              </Box>
              {uploadedFiles &&
                uploadedFiles?.length > 0 &&
                (uploadedFiles || [])?.map((file: any, index: number) => (
                  <Chip
                    sx={{
                      borderRadius: '4px',
                      color: '#3f51b5',
                      borderColor: '#3f51b5',
                    }}
                    deleteIcon={
                      <Trash color="#d32f2f" weight="duotone" size={14} />
                    }
                    onDelete={() => {
                      setUploadedFiles(
                        uploadedFiles?.filter(
                          (item: any, idx: number) => idx !== index,
                        ),
                      );
                    }}
                    key={index}
                    label={file?.name}
                    variant="outlined"
                  />
                ))}
            </Box>
          )}

          {tabs === 1 && (
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Document
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.selectedDocument}
                  label="Select Document"
                  size="medium"
                  onChange={(e: any) => {
                    setFormData({
                      ...formData,
                      selectedDocument: e.target.value,
                    });
                  }}
                >
                  {documents.map((document: any, i: any) => (
                    <MenuItem value={document?.src} key={i}>
                      {document.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {tabs === 2 && (
            <Box>
              <FormControl
                sx={{
                  mt: '24px',
                }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">
                  Flux Prompt Objects
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  size="medium"
                  value={formData.selectedFluxPromptObject}
                  label="Flux Prompt Objects"
                  onChange={(e: any) => {
                    setFormData({
                      ...formData,
                      selectedFluxPromptObject: e.target.value,
                    });
                  }}

                  // onChange={handleChange}
                >
                  {authUser?.objects.map((document: any, i: any) => (
                    <MenuItem value={document?.id} key={i}>
                      {document.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formData.selectedFluxPromptObject && (
                <FormControl
                  sx={{
                    mt: '24px',
                  }}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Object from Selected Flux Prompt Object
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    size="medium"
                    id="demo-simple-select"
                    label=" Select Object from Selected Flux Prompt Object"
                    value={formData.fluxPromptObjectText}
                    onChange={(e: any) => {
                      setFormData({
                        ...formData,
                        fluxPromptObjectText: e.target.value,
                      });
                    }}
                  >
                    {authUser?.objects
                      ?.find(
                        item => item.id === formData.selectedFluxPromptObject,
                      )
                      ?.text?.map((document: any, i: any) => (
                        <MenuItem
                          value={document?.text}
                          title={document.text}
                          key={i}
                        >
                          Object {i + 1}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          )}
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'10px'}
            justifyContent={isEditing ? 'space-between' : 'end'}
            mt={'60px'}
          >
            {selectVector?.name && isEditing && (
              <Button
                color="error"
                onClick={() => onDeleteNameSpace(selectVector?.name)}
                variant="outlined"
              >
                Delete Memory Pod
              </Button>
            )}
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Button onClick={onHideModal} variant="outlined">
                Cancel
              </Button>
              <Button
                sx={{
                  boxShadow: 'none',
                }}
                // disabled={formData.name === '' || !uploadedFile}
                onClick={() => {
                  setFinish(true);
                  setNotifyModal(true);
                  handleUpload();
                }}
                variant="contained"
              >
                {isEditing ? 'Edit' : 'Create'} memory Pod
              </Button>
            </Box>
          </Box>
        </>
      </KitModal>
    </>
  );
};

export default VectorMemoryUploadModal;
