import React, { useState, useEffect } from 'react';
import './RemainingTokens.css';
import { Box, Button, IconButton } from '@mui/material';
import InfoIcon from '../../assets/icons/Info.png';
import SelectPlanModal from './SelectPlanModal';
import Pricing from '../settings/Pricing/Pricing';
import KitModal from '../UI/KitModal';
import { useAuthStore } from '../../store/storeAuth';
import { UserPlan } from '../../util/Types/User';
import TrialTokens from '../../assets/icons/TrialTokens.svg';
import { useSocketStore } from '../../store/storeSocket';
import { axiosInstance } from '../../service/ApiService';

const RemainingTokens: React.FC = () => {
  const {
    user: authUser,
    setAuthUser,
    sidebarOpen,
  } = useAuthStore(state => state);
  const socket = useSocketStore(state => state.socket);

  const spentTokens = authUser?.spentTokens || 0;
  const totalTokens = authUser?.totalTokens || 0;
  const freeTokens = authUser?.freeTokens || 0;
  const isUserCompany = authUser?.isUserCompany || false;
  const [showSelectPlanModal, setShowSelectPlanModal] = useState(false);
  const [showPricing, setShowPricing] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState('Introduction');

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error?.response?.data?.message?.[0] === 'You do not have enough tokens'
      ) {
        setShowSelectPlanModal(true);
      }

      return Promise.reject(error);
    },
  );

  const [showTokens, setShowTokens] = useState(true);

  useEffect(() => {
    if (authUser?.plan && Object.values(UserPlan).includes(authUser.plan)) {
      setSelectedPlan(authUser.plan);
    }
  }, [authUser?.plan]);

  useEffect(() => {
    if (authUser?.showTokens !== undefined) {
      setShowTokens(authUser?.showTokens!);
    }
  }, [authUser?.showTokens]);

  useEffect(() => {
    if (authUser?.id && socket?.connected) {
      socket.on('trial-tokens', async (resData: any) => {
        setAuthUser({
          ...authUser,
          freeTokens: resData?.data?.freeTokens || 0,
          trialTokens: resData?.data?.trialTokens || 0,
          spentTokens: resData?.data?.spentTokens || 0,
        });
      });
    }

    return () => {
      if (socket?.connected) {
        socket?.off('trial-tokens');
      }
    };
  }, [socket, authUser]);

  return (
    <>
      <SelectPlanModal
        plan={selectedPlan}
        show={showSelectPlanModal}
        onClose={() => setShowSelectPlanModal(false)}
        openPricingModal={() => setShowPricing(true)}
      />

      {showPricing && (
        <KitModal
          show={true}
          onHide={() => {
            setShowPricing(false);
            setShowSelectPlanModal(false);
          }}
          maxWidth="xl"
          fullWidth
          sx={{
            '& .MuiPaper-root': {
              height: '100vh',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Pricing />
          </Box>
        </KitModal>
      )}

      <div
        className="remaining-tokens-container"
        style={{
          marginLeft: '45px',
          ...(!sidebarOpen ? { left: '50px' } : undefined),
        }}
      >
        {showTokens && !isUserCompany && selectedPlan === UserPlan.Intro ? (
          spentTokens < totalTokens ? (
            <>
              <div className="remaining-tokens-plan-container">
                <p
                  style={{
                    margin: 0,
                    color: 'white',
                  }}
                >
                  {selectedPlan} Plan
                </p>
              </div>
              <p className="tokens-text">
                {spentTokens}/{totalTokens} FluxTokens remaining
              </p>
            </>
          ) : (
            <div className="select-plan-container">
              <Button
                size="small"
                sx={{
                  height: '30px',
                }}
                onClick={() => setShowPricing(true)}
                variant="contained"
              >
                Select Plan
              </Button>
              <IconButton onClick={() => setShowSelectPlanModal(true)}>
                <img src={InfoIcon} alt="Info" className="info-icon" />
              </IconButton>
            </div>
          )
        ) : freeTokens > 0 && showTokens && freeTokens > spentTokens ? (
          <div className="trial-tokens-container">
            <img
              style={{
                width: '16px',
                height: '16px',
                backgroundColor: 'white',
                borderRadius: '4px',
                padding: '2px',
              }}
              src={TrialTokens}
              alt="trial tokens"
            />
            <p className="tokens-text">
              {spentTokens}/{freeTokens} Free FluxTokens
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default RemainingTokens;
