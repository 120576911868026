import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import FormNode from '../Components/FormNode/FormNode';
import { NodeProps, useReactFlow } from 'reactflow';
import { Box, Button, FormLabel, Typography } from '@mui/material';
import { UploadSimple } from '@phosphor-icons/react';
import { useDebounceEffect } from '../../../util/useDebounceEffect';
import { useParams } from 'react-router-dom';
import { UploadService } from '../../../service/UploadService';
import { FileService } from '../../../service/FileService';
import PopoverEdit from '../TitleNode/Components/PopoverEdit';
import FormBuildInput from '../FormBuildInput/FormBuildInput';
import LoadingSpinner from '../../UI/LoadingSpinner';

const ImageComponent = ({ id, type, data }: NodeProps) => {
  const { formId } = useParams<{
    formId: string;
  }>();

  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    url: '',
    borderRadius: '4px',
    titleStyle: {
      fontSize: '20px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
    subtitleStyle: {
      fontSize: '14px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      textDecoration: 'none',
      selStyle: [],
    },
  });
  const reactFlow = useReactFlow();

  const [editingType, setEditingType] = useState('titleStyle');
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [changes, setChanges] = useState<any>({
    directly: false,
    value: '',
    name: '',
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (data && !changes?.directly) {
      setFormData({
        title: data.title || '',
        subtitle: data.subtitle || '',
        url: data.url || '',
        borderRadius: data.borderRadius || '4px',
        titleStyle: {
          ...formData?.titleStyle,
          ...data.titleStyle,
        },
        subtitleStyle: {
          ...formData?.subtitleStyle,
          ...data.subtitleStyle,
        },
      });
    }
  }, [data, changes]);

  const onChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setChanges({ directly: true, value, name });
  };

  const handleClick = (type: string) => {
    setOpenEditModal(true);
    setEditingType(type);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };
  useDebounceEffect(
    () => {
      if (changes.directly) {
        const nodes = reactFlow.getNodes();

        const mappedNodes = nodes.map(node => {
          if (node.id === id) {
            if (changes?.name === 'widthData') {
              return {
                ...node,
                width: changes.value?.width,
                style: {
                  width: changes.value?.width,
                },
                data: {
                  ...node.data,
                  [changes.name]: changes.value,
                },
              };
            }

            return {
              ...node,
              data: {
                ...node.data,
                [changes.name]: changes.value,
              },
            };
          }
          return node;
        });

        reactFlow.setNodes(mappedNodes);

        setChanges({ directly: false, value: '', name: '' });
      }
    },
    [changes],
    500,
  );

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    setUploadingLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file!);

      const res = await UploadService.uploadFile(formData);

      const fileService = await FileService.createFormFileToUpload({
        name: res?.data.name,
        src: res.data.src,
        formsId: formId!,
      });

      setChanges({ directly: true, value: res.data.src, name: 'url' });
    } catch (error) {
      console.error(error);
    } finally {
      setUploadingLoading(false);
    }
  };

  return (
    <FormNode id={id} type={type} borderRadius={formData.borderRadius}>
      <FormBuildInput
        data={data}
        formData={{
          name: 'title',
          value: formData.title,
          placeholder: 'Add title here...',
          style: formData.titleStyle,
          titleEmpty: 'Title (Optional)',
        }}
        editingType={editingType}
        editType={'titleStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      <FormBuildInput
        data={data}
        formData={{
          name: 'subtitle',
          value: formData.subtitle,
          placeholder: 'Add subtitle here...',
          style: formData.subtitleStyle,
          titleEmpty: 'Subtitle (Optional)',
        }}
        editingType={editingType}
        editType={'subtitleStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      {uploadingLoading && (
        <Box>
          <LoadingSpinner height="200px" />
        </Box>
      )}

      {!formData?.url && !uploadingLoading && (
        <FormLabel
          htmlFor={id}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            p: '24px',
            border: '1px solid #EAECF0',
            flexDirection: 'column',
          }}
        >
          <UploadSimple
            size={'32px'}
            // color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
          />

          <Typography
            sx={{
              fontSize: '14px',
              color: '#98A2B3',
              fontWeight: 600,
            }}
          >
            {
              'Click here to upload an Image. To test this, enter preview mode or publish your form.'
            }
          </Typography>
        </FormLabel>
      )}

      {formData?.url && !uploadingLoading && (
        <img
          style={{
            maxWidth: data?.widthData?.maxWidth || '100%',
            width: '100%',
          }}
          src={formData?.url}
          alt="test"
        />
      )}

      {formData?.url && (
        <Box display={'flex'} justifyContent={'end'}>
          <Button
            onClick={() => {
              onChanges({
                target: {
                  name: 'url',
                  value: '',
                },
              } as any);
            }}
          >
            Delete
          </Button>

          <Button
            onClick={() => {
              document.getElementById(id)?.click();
            }}
            variant="outlined"
          >
            Replace Image
          </Button>
        </Box>
      )}

      {openEditModal && (
        <PopoverEdit
          open={openEditModal}
          handleClose={handleClose}
          onChange={onChanges}
          selectedStyle={formData?.[editingType as keyof typeof formData]}
          editingType={editingType}
          modalStyle={{
            bgColor: data?.bgColor ? data?.bgColor : '#ffffff',
            widthData: data?.widthData,
            borderRadius: formData.borderRadius || '4px',
          }}
        />
      )}

      <input
        id={id}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </FormNode>
  );
};

export default memo(ImageComponent);
