import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import {
  ArrowLeft,
  CaretRight,
  CheckCircle,
  Circle,
  CreditCard,
  Dot,
  Plus,
  Tag,
} from '@phosphor-icons/react';
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';
import { UserService } from '../../../service/UserService';
import { useAuthStore } from '../../../store/storeAuth';
import { User } from '../../../util/Types/User';
import { Product } from '../../../util/Types/Product';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../../UI/LoadingSpinner';
import { getAPIErrorMessage } from '../../../helpers/helpers';
import { render } from '@testing-library/react';
import { ReactComponent as MasterCardIcon } from '../../../assets/icons/Mastercard.svg';
import { ReactComponent as VisaCardIcon } from '../../../assets/icons/Visa.svg';
import Arrow from '../../../assets/icons/Arrow';

interface Props {
  selectedPlan: any;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Product | undefined>>;
  onSubscribe: (couponId?: string) => void;
  welcomePageCallback?: () => Promise<void>;
  isSelectedTimePlanYearly: boolean;
  authUser?: User | null;
  welcomePage?: boolean;
}

const visa = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="32"
    viewBox="0 0 46 32"
    fill="none"
  >
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" fill="white" />
    <rect x="0.5" y="0.5" width="45" height="31" rx="5.5" stroke="#F2F4F7" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3321 21.1441H11.5858L9.52638 13.0563C9.42863 12.6843 9.22108 12.3554 8.91579 12.2003C8.15389 11.8108 7.31432 11.5007 6.39844 11.3444V11.033H10.8225C11.4331 11.033 11.8911 11.5007 11.9674 12.044L13.0359 17.878L15.7809 11.033H18.4509L14.3321 21.1441ZM19.9774 21.1441H17.3837L19.5195 11.033H22.1131L19.9774 21.1441ZM25.4687 13.8341C25.545 13.2895 26.003 12.9781 26.5372 12.9781C27.3768 12.8999 28.2914 13.0563 29.0546 13.4445L29.5125 11.2676C28.7493 10.9562 27.9097 10.7998 27.1478 10.7998C24.6305 10.7998 22.7987 12.2003 22.7987 14.1441C22.7987 15.6228 24.0962 16.3993 25.0121 16.867C26.003 17.3334 26.3846 17.6448 26.3083 18.1112C26.3083 18.8108 25.545 19.1222 24.7831 19.1222C23.8672 19.1222 22.9514 18.889 22.1131 18.4994L21.6552 20.6777C22.5711 21.066 23.5619 21.2223 24.4778 21.2223C27.3005 21.2992 29.0546 19.9 29.0546 17.7998C29.0546 15.1551 25.4687 15.0001 25.4687 13.8341ZM38.1318 21.1441L36.0724 11.033H33.8603C33.4024 11.033 32.9444 11.3444 32.7918 11.8108L28.9783 21.1441H31.6483L32.1812 19.6668H35.4618L35.7671 21.1441H38.1318ZM34.2419 13.7559L35.0038 17.5666H32.8681L34.2419 13.7559Z"
      fill="#172B85"
    />
  </svg>
);

const mastercard = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="19"
    viewBox="0 0 30 19"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9053 16.4392C13.3266 17.7699 11.2787 18.5732 9.04092 18.5732C4.04776 18.5732 0 14.5736 0 9.63988C0 4.70613 4.04776 0.706543 9.04092 0.706543C11.2787 0.706543 13.3266 1.50987 14.9053 2.8406C16.484 1.50987 18.5319 0.706543 20.7697 0.706543C25.7628 0.706543 29.8106 4.70613 29.8106 9.63988C29.8106 14.5736 25.7628 18.5732 20.7697 18.5732C18.5319 18.5732 16.484 17.7699 14.9053 16.4392Z"
      fill="#ED0006"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9062 16.4392C16.8502 14.8006 18.0828 12.3625 18.0828 9.63988C18.0828 6.91727 16.8502 4.47913 14.9062 2.8406C16.485 1.50987 18.5329 0.706543 20.7706 0.706543C25.7638 0.706543 29.8115 4.70613 29.8115 9.63988C29.8115 14.5736 25.7638 18.5732 20.7706 18.5732C18.5329 18.5732 16.485 17.7699 14.9062 16.4392Z"
      fill="#F9A000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.907 16.4393C16.8509 14.8008 18.0835 12.3627 18.0835 9.64007C18.0835 6.91748 16.8509 4.47936 14.907 2.84082C12.9631 4.47936 11.7305 6.91748 11.7305 9.64007C11.7305 12.3627 12.9631 14.8008 14.907 16.4393Z"
      fill="#FF5E00"
    />
  </svg>
);

const PaymentForm = ({
  selectedPlan,
  setSelectedPlan,
  onSubscribe,
  isSelectedTimePlanYearly,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const setNotification = useNotificationStore(state => state.setNotification);
  const { user: authUser, setAuthUser } = useAuthStore(state => state);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | null
  >(authUser?.defaultPaymentMethod || null);

  const [newCard, setNewCard] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    couponId: '',
    readAndAgree: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    couponId: '',
    readAndAgree: false,
  });

  const getMyPaymentMethods = async () => {
    try {
      const response = await UserService.getPaymentMethods();

      setPaymentMethods(response.data);

      if (response.data.length <= 0) {
        setNewCard(true);
      }
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!formData.readAndAgree) {
      setErrors({
        ...errors,
        readAndAgree: 'Please agree to the terms and conditions',
      });
      setIsLoading(false);
      return;
    }

    if (!stripe || !elements) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
      setIsLoading(false);

      return;
    }

    const card = elements.getElement(CardNumberElement);
    const ccv = elements.getElement(CardCvcElement);
    const expiry = elements.getElement(CardExpiryElement);

    if (!card || !ccv || !expiry) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });

      setIsLoading(false);
      return;
    }

    const result = await stripe.createToken(card);

    if (result.error) {
      setNotification({
        type: 'error',
        message: result.error.message || 'Something went wrong',
      });

      setIsLoading(false);

      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: {
        token: result.token.id,
      },
      billing_details: {
        name: formData.name,
      },
    });

    if (error) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
      setIsLoading(false);

      return;
    }

    try {
      await UserService.attachPaymentMethod(paymentMethod?.id!, {
        brand: paymentMethod?.card?.brand!,
        exp_month: paymentMethod?.card?.exp_month!,
        exp_year: paymentMethod?.card?.exp_year!,
        last4: paymentMethod?.card?.last4!,
        billing_details: {
          name: paymentMethod?.billing_details?.name!,
        },
      });

      await UserService.setDefaultPaymentMethod(paymentMethod?.id!);
      setIsLoading(true);

      onSubscribe(formData.couponId);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });

      setIsLoading(false);
      return;
    }

    setAuthUser({
      ...(authUser as User),
      defaultPaymentMethod: paymentMethod?.id!,
    });

    setFormData({
      name: '',
      couponId: '',
      readAndAgree: false,
    });

    // setNewMethod(true);
    // onHide();

    setIsLoading(false);
  };

  const onChange = (id: string) => {
    setSelectedPaymentMethodId(id);
  };

  const changeDefaultCard = async (id: string) => {
    try {
      await UserService.setDefaultPaymentMethod(id);

      setAuthUser({
        ...(authUser as User),
        defaultPaymentMethod: id,
      });

      setNotification({
        type: 'success',
        message: 'Default payment method updated',
      });
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const isWelcomePage = window.location.pathname === '/welcome' ? true : false;

  useEffect(() => {
    getMyPaymentMethods();
  }, []);

  const handleDirectPayment = async () => {
    try {
      setIsLoading(true);
      onSubscribe();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPrice = () => {
    if (!selectedPlan?.price) return null;
    if (!isSelectedTimePlanYearly) {
      return (
        <Box
          sx={{
            display: 'inline-flex',
          }}
        >
          <Typography
            fontSize={'20px'}
            fontWeight={500}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
            color={'#3650DE'}
          >
            ${selectedPlan?.price}
            <span
              style={{
                color: '#475467',
                fontSize: '20px',
                fontWeight: 400,
              }}
            >
              / monthly
            </span>
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={{}}>
          <Typography
            fontSize={'24px'}
            fontWeight={500}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
            color={'#3650DE'}
          >
            ${selectedPlan?.year_price}
            <span
              style={{
                color: '#475467',
                fontSize: '12px',
              }}
            >
              {' '}
              / yearly
            </span>
          </Typography>
        </Box>
      );
    }
  };

  const renderLeftTokens = () => {
    if (!selectedPlan?.price) return null;
    if (!isSelectedTimePlanYearly) {
      return (
        <Box
          sx={{
            display: 'inline-flex',
          }}
        >
          <Typography
            fontSize={'14px'}
            color={'#3650DE'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontWeight: 500,
              lineHeight: '166%',
            }}
          >
            {selectedPlan?.tokens} &nbsp;
          </Typography>
          <Typography
            fontSize={'14px'}
            color={'#667085'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontWeight: 500,
              lineHeight: '166%',
            }}
          >
            free monthly Ai Tokens
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: 'inline-flex',
            gap: '8px',
          }}
        >
          <Typography
            fontSize={'14px'}
            color={'#3650DE'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontWeight: 500,
              lineHeight: '166%',
            }}
          >
            {selectedPlan?.tokens}
          </Typography>
          <Typography
            fontSize={'14px'}
            color={'#667085'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              fontWeight: 500,
              lineHeight: '166%',
            }}
          >
            free yearly Ai Tokens
          </Typography>
        </Box>
      );
    }
  };

  const renderSubscribeButtonPrice = () => {
    if (!selectedPlan?.price) return null;

    if (!isSelectedTimePlanYearly) {
      return `$${selectedPlan.price}`;
    } else {
      return `$${selectedPlan.year_price}`;
    }
  };

  return (
    <Box className="payment-form">
      {!isWelcomePage && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 99,
            backgroundColor: 'white',
            borderBottom: '1px solid #D0D5DD',
            display: 'flex',
            height: authUser?.isUserCompany ? '67px' : '64px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: '#000',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '32px',
              margin: '24px',
              gap: '8px',
            }}
          >
            <IconButton onClick={() => setSelectedPlan(undefined)}>
              <ArrowLeft size={20} color="#343330" />
            </IconButton>
            Pricing Plans
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px 24px 16px 24px',
          gap: '24px',
          alignItems: isWelcomePage ? 'center' : 'initial',
        }}
      >
        <Box
          sx={{
            p: '16px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            border: `0.5px solid var(--Blue, #F2F4F7)`,
            width: '95%',
            gap: '16px',
            boxShadow: '0px 4px 13px 2px rgba(0, 0, 0, 0.10)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: '8px',
                alignItems: 'center',
                width: '33px',
                height: '33px',
                justifyContent: 'center',
                borderRadius: '4px',
                background: '#E7F0FF',
              }}
            >
              <Tag size={28} weight="fill" color="#3650DE" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                fontSize={'20px'}
                fontWeight={500}
                lineHeight={'133.4%'}
                color={'#101828'}
              >
                {selectedPlan?.name} Plan
              </Typography>
              <Typography
                sx={{
                  color: '#475467',
                  fontSize: '14px',
                  lineHeight: '166%',
                  fontWeight: 400,
                }}
              >
                {selectedPlan?.title}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '12px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {renderPrice()}
            </Box>
            <Dot size={40} color="#344054" />

            {selectedPlan?.discounts?.length &&
              selectedPlan?.discounts?.map((discount: any) => (
                <Box
                  sx={{
                    display: 'inline-flex',
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    color={'#3650DE'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      fontWeight: 500,
                      lineHeight: '166%',
                    }}
                  >
                    {discount}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    color={'#667085'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      fontWeight: 500,
                      lineHeight: '166%',
                    }}
                  >
                    &nbsp;/ Ai Token
                  </Typography>
                </Box>
              ))}

            <Dot size={40} color="#344054" />

            <Box
              sx={{
                display: 'flex',
              }}
            >
              {renderLeftTokens()}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              color: '#475467',
              fontWeight: 500,
            }}
          >
            Choose your payment method
          </Typography>
          {newCard && !isWelcomePage && paymentMethods?.length > 0 && (
            <Box
              sx={{
                p: '16px',
                display: 'flex',
                flexDirection: 'row',
                width: '35%',
                gap: '8px',
                fontSize: '14px',
                color: '#475467',
                alignItems: 'center',
                fontWeight: 500,
                cursor: 'pointer',
              }}
              onClick={() => setNewCard(false)}
            >
              <ArrowLeft size={18} color="#667085" weight="bold" />
              Go to my saved cards
            </Box>
          )}
        </Box>

        {paymentMethods?.length > 0 && !newCard && !isWelcomePage && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              width: '100%',
              overflowY: paymentMethods.length > 2 ? 'auto' : 'hidden',
            }}
          >
            {paymentMethods
              // .sort((a, b) =>
              //   a.paymentMethodId === authUser?.defaultPaymentMethod ? -1 : 0,
              // )
              .map(method => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    border: '1px solid #EAECF0',
                    justifyContent: 'flex-start',
                    borderRadius: '8px',
                    maxWidth: '471px',
                    padding: '16px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '503px',
                      gap: '12px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '46px',
                        height: '32px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '8px',

                        border: '1px solid #EAECF0',
                      }}
                    >
                      {(() => {
                        switch (method.brand) {
                          case 'visa':
                            return <VisaCardIcon />;
                          case 'mastercard':
                            return <MasterCardIcon />;
                          default:
                            return <CreditCard size={32} />;
                        }
                      })()}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#1D2939',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                          }}
                        >
                          {method?.brand?.charAt(0).toUpperCase() +
                            method?.brand?.slice(1)}{' '}
                          ending in {method?.last4}
                        </Typography>

                        {method.paymentMethodId ===
                          authUser?.defaultPaymentMethod && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '17px',
                              padding: '2px 4px',
                              backgroundColor: '#E7F0FF',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#3650DE',
                                fontFamily: 'Roboto',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                              }}
                            >
                              Primary
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          color: '#475467',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          pb: '8px',
                        }}
                      >
                        Expiry {method.exp_month}/{method.exp_year}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '20px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        {method.paymentMethodId !==
                          authUser?.defaultPaymentMethod && (
                          <Typography
                            sx={{
                              color: '#475467',
                              fontFamily: 'Roboto',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              changeDefaultCard(method.paymentMethodId)
                            }
                          >
                            Set as default
                          </Typography>
                        )}

                        {/* <Typography
                        sx={{
                          color: '#3650DE',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          cursor: 'pointer',
                        }}
                      >
                        Edit
                      </Typography> */}
                      </Box>
                    </Box>
                  </Box>

                  <label
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <input
                      type="radio"
                      style={{
                        position: 'absolute',
                        opacity: 0,
                        height: '20px',
                        width: '20px',
                        cursor: 'pointer',
                      }}
                      checked={
                        selectedPaymentMethodId === method.paymentMethodId
                      }
                      onChange={() => onChange(method.paymentMethodId)}
                    />
                    {selectedPaymentMethodId === method.paymentMethodId ? (
                      <CheckCircle size={24} weight="fill" color="#3650DE" />
                    ) : (
                      <Circle size={24} weight="regular" color="#3650DE" />
                    )}
                  </label>
                </Box>
              ))}
          </Box>
        )}
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            width: '150px',
          }}
          onClick={() => setNewCard(true)}
        >
          <Plus size={18} color="#667085" weight="bold" />
          <Typography
            sx={{
              color: '#667085',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
          >
            Add new card
          </Typography>
        </Button>
        {(newCard || isWelcomePage) && (
          <Box
            sx={{
              maxWidth: '500px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                marginTop: '16px',
              }}
            >
              <Box sx={{ mb: '20px' }}>
                <InputLabel
                  sx={{
                    mb: '6px',
                  }}
                >
                  {'Apply Coupon Code'}
                </InputLabel>
                <TextField
                  type="text"
                  name="couponId"
                  size="medium"
                  label="Code"
                  value={formData.couponId}
                  fullWidth
                  onChange={e =>
                    setFormData({ ...formData, couponId: e.target.value })
                  }
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <InputLabel>{'Card number'}</InputLabel>
                <CardNumberElement
                  options={{
                    classes: {
                      base: 'base-class-element',
                    },
                    showIcon: true,
                  }}
                />
              </Box>

              <Box sx={{ mt: '20px' }}>
                <TextField
                  type="text"
                  name="name"
                  size="medium"
                  label="Name on Card"
                  value={formData.name}
                  fullWidth
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  marginTop: '20px',
                  flexWrap: 'wrap',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <Box sx={{ width: { xs: '100%', sm: 'calc(50% - 10px)' } }}>
                  <InputLabel>{'Expiration Date'}</InputLabel>
                  <CardExpiryElement
                    className="base-class-element"
                    options={{
                      classes: {
                        base: 'base-class',
                      },
                      style: {
                        base: {
                          fontSize: '16px',
                          backgroundColor: 'white',
                          padding: '10px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: { xs: '100%', sm: 'calc(50% - 10px)' } }}>
                  <InputLabel>{'CVC'}</InputLabel>
                  <CardCvcElement
                    className="base-class-element"
                    options={{
                      classes: {
                        base: 'base-class',
                      },
                      style: {
                        base: {
                          fontSize: '16px',
                          backgroundColor: 'white',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Typography mt={'20px'} fontSize={'14px'} color={'#101828'}>
              Your personal data will be used to process your order, support
              your experience, and for other purposes described in our Privacy
              Policy.
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.readAndAgree}
                  onChange={e =>
                    setFormData({ ...formData, readAndAgree: e.target.checked })
                  }
                  name="readAndAgree"
                />
              } // Pass the field object to Checkbox
              label={
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: '#101828',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '2.2px',
                  }}
                >
                  I have read and agree to the{' '}
                  <span
                    style={{
                      color: '#3650DE',
                      cursor: 'pointer',
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      window.open(
                        window.location.origin + '/legal/privacy-policy',
                        '_blank',
                      );
                    }}
                  >
                    Privacy Policy
                  </span>{' '}
                  and
                  <span
                    style={{
                      color: '#3650DE',
                      cursor: 'pointer',
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      window.open(
                        window.location.origin + '/legal/terms-of-service',
                        '_blank',
                      );
                    }}
                  >
                    {' '}
                    Terms of Service
                  </span>
                </Typography>
              }
            />
          </Box>
        )}

        <Button
          variant="contained"
          sx={{
            width: '503px',
          }}
          disabled={isLoading}
          onClick={() => {
            if (newCard || isWelcomePage) {
              handleSubmit();
            } else {
              handleDirectPayment();
            }
          }}
          // disabled={paymentMethods.length <= 0}
        >
          <Typography>
            Subscribe and pay {renderSubscribeButtonPrice()}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentForm;
