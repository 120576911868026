import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { FormData } from './constants';

type Props = {
  formData: FormData;
  onChange: (
    event: Event | React.InputHTMLAttributes<HTMLInputElement>,
  ) => void;
};

const PlayHt = ({ formData, onChange }: Props) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '24px',
          gap: '8px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel htmlFor="quality-select">Quality</InputLabel>
          <Select
            id="quality-select"
            value={formData.quality}
            name="quality"
            label="Quality"
            className="nodrag"
            onChange={onChange}
            sx={{ width: '100%' }}
          >
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="high">High</MenuItem>
            <MenuItem value="premium">Premium</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel htmlFor="quality-select">Output Format</InputLabel>
          <Select
            className="nodrag"
            value={formData.outputFormat}
            name="outputFormat"
            label="Output Format"
            onChange={onChange}
            sx={{ width: '100%' }}
          >
            <MenuItem value="mp3">MP3</MenuItem>
            <MenuItem value="wav">WAV</MenuItem>
            <MenuItem value="ogg">OGG</MenuItem>
            <MenuItem value="flac">FLAC</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: '24px',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <TextField
          id="speed-input"
          type="text"
          placeholder="Speed"
          label="Speed"
          sx={{
            width: '100%',
          }}
          value={formData.speed}
          name="speed"
          onChange={onChange}
        />
        <TextField
          id="sample-rate-input"
          type="text"
          label="Sample Rate"
          sx={{
            width: '100%',
          }}
          placeholder="Sample Rate"
          value={formData.sampleRate}
          name="sampleRate"
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export default PlayHt;
