import axios from 'axios';

export const getGoogleDriveFolders = async (
  token: string,
  driveType: 'me' | 'shared' | 'all',
): Promise<{ label: string; value: string }[]> => {
  try {
    if (driveType === 'all') {
      const response = await axios.get(
        'https://www.googleapis.com/drive/v3/files',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            q: "mimeType='application/vnd.google-apps.folder' and trashed=false",
            fields: 'files(id, name)',
          },
        },
      );

      return response.data.files.map((folder: any) => ({
        label: folder.name,
        value: folder.id,
      }));
    } else if (driveType === 'me') {
      const response = await axios.get(
        'https://www.googleapis.com/drive/v3/files',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            q: "mimeType='application/vnd.google-apps.folder' and 'me' in owners and trashed=false",
            fields: 'files(id, name)',
          },
        },
      );

      return response.data.files.map((folder: any) => ({
        label: folder.name,
        value: folder.id,
      }));
    } else {
      const sharedDriveResponse = await axios.get(
        'https://www.googleapis.com/drive/v3/drives',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            fields: 'drives(id, name)',
          },
        },
      );

      const sharedDriveFolderPromises = sharedDriveResponse.data.drives.map(
        async (drive: any) => {
          const foldersResponse = await axios.get(
            'https://www.googleapis.com/drive/v3/files',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                q: `mimeType='application/vnd.google-apps.folder' and '${drive.id}' in parents and trashed=false`,
                fields: 'files(id, name)',
                includeItemsFromAllDrives: true,
                supportsAllDrives: true,
              },
            },
          );

          return foldersResponse.data.files.map((folder: any) => ({
            label: folder.name,
            value: folder.id,
          }));
        },
      );

      const sharedDriveFolders = await Promise.all(sharedDriveFolderPromises);

      return sharedDriveFolders.flat();
    }
  } catch (error) {
    throw error;
  }
};

export const uploadFileToGoogleDrive = async (
  token: string,
  formData: FormData,
) => {
  try {
    const uploadResponse = await axios.post(
      'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/related',
        },
        params: {
          supportsAllDrives: true,
        },
      },
    );

    return uploadResponse.data;
  } catch (error) {
    throw error;
  }
};

export const getExtension = (contentType: string) => {
  const contentTypeToExtension: any = {
    'image/png': '.png',
    'image/jpeg': '.jpg',
    'image/gif': '.gif',
    'image/bmp': '.bmp',
    'image/webp': '.webp',
    'image/tiff': '.tiff',
    'image/svg+xml': '.svg',
    'text/html': '.html',
    'text/plain': '.txt',
    'application/pdf': '.pdf',
    'application/json': '.json',
    'application/xml': '.xml',
    'audio/mpeg': '.mp3',
    'audio/wav': '.wav',
    'video/mp4': '.mp4',
    'video/x-msvideo': '.avi',
  };

  return contentTypeToExtension[contentType] || '';
};
