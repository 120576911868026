import React, { useState } from 'react';
import KitModal from '../../components/UI/KitModal';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { FlowService } from '../../service/FlowService';
import { FolderService } from '../../service/FolderService';

interface Props {
  onHide: () => void;
}
const CreateFlow: React.FC<Props> = ({ onHide }) => {
  const { folders, setFolders } = useAuthStore(state => state);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [creatingFlow, setCreatingFlow] = useState(false);
  const [flowName, setFlowName] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState<
    undefined | string
  >();
  const folderChangeHandler = (e: SelectChangeEvent) => {
    setSelectedFolderId(e.target.value);
  };

  const saveHandler = async () => {
    setCreatingFlow(true);
    try {
      await FlowService.createFlow({
        name: flowName,
        folderId: selectedFolderId!,
      });

      const folderResponse = await FolderService.getFolders();
      setFolders(folderResponse.data);
      setNotification({
        type: NotificationType.Success,
        message: 'Flow created successfully',
      });
      onHide();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
    setCreatingFlow(false);
  };

  return (
    <KitModal
      onHide={onHide}
      fullWidth={true}
      show={true}
      title={'Create New Flow'}
      footer={
        <Button
          variant={`contained`}
          onClick={saveHandler}
          disabled={creatingFlow}
        >
          Save
        </Button>
      }
    >
      <div style={{ padding: 10 }}>
        <TextField
          type='text'
          value={flowName}
          onChange={e => setFlowName(e.target.value)}
          placeholder='Name your flow'
          fullWidth
          style={{ marginBottom: 20, marginTop: 10 }}
        />

        <FormControl fullWidth>
          <InputLabel htmlFor='folder-select'>Choose a folder</InputLabel>
          <Select
            id='folder-select'
            className='nodrag'
            name='folder'
            onChange={folderChangeHandler}
            value={selectedFolderId}
            fullWidth
            label='Choose a folder'
          >
            {folders.map(folder => {
              return (
                <MenuItem key={folder.id} value={folder.id}>
                  {folder.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </KitModal>
  );
};

export default CreateFlow;
