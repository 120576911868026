import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import NoSpaces from './NoSpaces';
import SpacesTable from './SpacesTable';
import SpaceDetails from './SpaceDetails';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanySpace } from '../../util/Types/CompanySpace';
import LoadingSpinner from '../UI/LoadingSpinner';
import { useAuthStore } from '../../store/storeAuth';
import { useLocation } from 'react-router-dom';

const Spaces = () => {
  const authUser = useAuthStore(state => state.user);
  const setNotification = useNotificationStore(state => state.setNotification);
  const [companySpacesLoading, setCompanySpacesLoading] = useState(true);
  const [companySpaces, setCompanySpaces] = useState<CompanySpace[]>([]);
  const [selectedSpaceId, setSelectedSpaceId] = useState<undefined | string>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const location = useLocation();

  const getCompanySpaces = useCallback(async () => {
    if (!authUser) return;

    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      const { data: companySpaces } =
        await CompanySpacesService.getCompanySpaces(authUser.selectedCompanyId);

      setCompanySpaces(companySpaces);

      const urlParams = new URLSearchParams(location.search);
      const spaceId = urlParams?.get('spaceId');

      if (spaceId) {
        setSelectedSpaceId(spaceId);
      }
    } catch (error) {
      console.log(error);
      // setNotification({
      //   type: NotificationType.Error,
      //   message: getAPIErrorMessage(error as string),
      // });
    }

    setCompanySpacesLoading(false);
  }, [authUser, page, rowsPerPage, location.search]);

  useEffect(() => {
    getCompanySpaces();
  }, [getCompanySpaces, selectedSpaceId]);

  const renderCompanySpaces = () => {
    if (companySpaces.length === 0)
      return <NoSpaces getCompanySpaces={getCompanySpaces} />;

    if (selectedSpaceId) {
      return (
        <SpaceDetails
          spaceId={selectedSpaceId}
          setSelectedSpaceId={setSelectedSpaceId}
        />
      );
    }

    if (companySpaces.length > 0) {
      return (
        <SpacesTable
          companySpaces={companySpaces}
          setSelectedSpaceId={setSelectedSpaceId}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          getCompanySpaces={getCompanySpaces}
        />
      );
    }
  };

  if (!authUser?.selectedCompanyId) return <LoadingSpinner />;

  return (
    <Box>
      {companySpacesLoading ? <LoadingSpinner /> : renderCompanySpaces()}
    </Box>
  );
};

export default Spaces;
