import { memo, useState, useEffect, useMemo } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import '../../assets/styles/animatedBorder.scss';
import { processorArrayFc } from '../../util/arrayFunctionts';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import {
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  Collapse,
} from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import Label from '../UI/Label/Label';
import OutputContent from '../UI/OutputContent/OutputContent';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { CaretDown, Info } from '@phosphor-icons/react';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { findConnectedNodes } from '../../util/executeNode/findConnectedNodes';
import { useObserveElementWidth } from '../../util/useObserveElement';
import TagInput from '../Test/TagInput';

interface FormDataProps {
  options: string;
  content: string;
  processorArray: string[] | undefined;
  customOperator: string;
  startingPoint: number;
  endingPoint: number;
  completed?: number;
  executingArray?: number;
  whoIsChanging?: string;
  executing?: string;
  auto: boolean;
  breakWords?: string;
  preInput?: string;
  postInput?: string;
  processorArrayItemLength?: number;
}
/////Collapse Button/////

function SequenceNode({ id, data, isConnectable, type }: NodeProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });

  const [collapsed, setCollapsed] = useState(false);

  const [formData, setFormData] = useState<FormDataProps>({
    options: 'Paragraph',
    content: data.content,
    processorArray: [],
    customOperator: '',
    startingPoint: 1,
    endingPoint: 5,
    completed: 1,
    executingArray: 1,
    executing: 'default',
    auto: true,
    breakWords: '',
    preInput: '',
    postInput: '',
    processorArrayItemLength: 1,
  });

  const { setNodes: setNodeState } = useReactFlow();

  const { flowId, setSaving, setSchema } = useFlowsStore(state => state);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const edges = useEdges();
  const [hasToExecute, setHasToExecute] = useState(true);

  const reactFlow = useReactFlow();

  const flowNodes = useMemo(() => {
    const findFlowGlobal = edges?.find(edg => edg?.source?.includes('global'));

    const { allIds } = findConnectedNodes(
      reactFlow.getNodes(),
      edges,
      findFlowGlobal?.source!,
    );

    return allIds;
  }, [edges]);

  const processorArrayLength: number | undefined =
    formData?.processorArray?.length;

  useDebounceEffect(
    () => {
      const object = {
        processorArray: data?.processorArray || [],
        options: data?.options || 'Paragraph',
        customOperator: data?.customOperator || '',
        startingPoint: data?.startingPoint || 1,
        endingPoint: data?.endingPoint || 5,
        executing: data?.executing || 'default',
        auto:
          data?.auto === undefined || data?.auto === null ? true : data?.auto,
        breakWords: data?.breakWords || '',
        preInput: data.preInput || '',
        postInput: data.postInput || '',
        processorArrayItemLength:
          data?.processorArrayItemLength === undefined
            ? 1
            : data?.processorArrayItemLength,
      };

      if (!isChangingDirectly) {
        setFormData({
          content: data?.content || '',
          ...object,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onChange = (e: any) => {
    const { name, value, checked } = e.target;
    let updatedFormData = {};

    if (name === 'auto') {
      updatedFormData = {
        ...updatedFormData,
        [name]: checked,
        endingPoint: formData?.processorArray?.length
          ? processorArrayLength!
          : 5,
      };
    } else {
      updatedFormData = {
        ...updatedFormData,
        [name]: value,
      };
    }

    if (name === 'startingPoint') {
      updatedFormData = {
        ...updatedFormData,
        completed: formData.startingPoint,
      };
    }

    updatedFormData = {
      ...updatedFormData,
    };

    setFormData({
      ...formData,
      ...updatedFormData,
    });

    setWhoIsChanging({
      name: e.target.name,
      value: updatedFormData,
    });

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        const processorArray = processorArrayFc({
          ...formData,
          ...whoIsChanging?.value,
        });

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: {
            ...whoIsChanging?.value,
            processorArray,
          },
          changeType: 'change',
          setSchema,
        });

        setFormData({
          ...formData,
          processorArray,
        });

        setIsChangingDirectly(false);
      }
    },
    [isChangingDirectly, whoIsChanging, formData],
    500,
  );

  useEffect(() => {
    if (data.flowTrigger) {
      if (hasToExecute) {
        setHasToExecute(false);
        onStartExecute();
      }
    }
  }, [data.flowTrigger, hasToExecute, formData]);

  const onStartExecute = () => {
    const { startingPoint, endingPoint } = formData;

    setIsLoading(true);

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      changeType: 'execute',
      objectCallerData: [
        ...(user?.spaceObjects || []),
        ...(user?.objects || []),
      ],

      flowTriggerData: {
        ...data?.flowTrigger,
        arrayExecute: {
          id: id,
          startingPoint: startingPoint - 1,
          completed: startingPoint - 1,
          endingPoint: formData.auto
            ? (data?.processorArray?.length || 1) - 1
            : endingPoint - 1,
          firstId: flowNodes?.[flowNodes?.findIndex(node => node === id) + 1],
          loopingToId:
            formData?.executing && formData?.executing === 'default'
              ? flowNodes?.[flowNodes?.findIndex(node => node === id) + 1]
              : formData?.executing,
        },
      },
      flowId,
      objectData: {
        completed: startingPoint - 1,
      },
      setSchema,
    });
    setIsLoading(false);

    setTimeout(() => {
      setHasToExecute(true);
    }, 1500);
  };

  const options = ['Paragraph', 'Sentence', 'Custom Separator'];
  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          id={id}
          data={data}
          type={type}
          btnText="Run Function"
          onSubmit={onStartExecute}
          isLoading={isLoading}
          edges={edges}
          showTokensUsed={false}
        >
          <Box display={'flex'}>
            <div>
              <Box
                p={'16px'}
                borderRight={`1px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`}
                ref={inputRef}
              >
                <Label
                  isAdvanced={true}
                  labelName="Content Input"
                  handleId="content"
                  disabled={
                    edges?.find(
                      edge =>
                        edge?.target === id && edge.targetHandle === 'content',
                    )
                      ? true
                      : false
                  }
                  findColor={findColor}
                />

                <Box>
                  <Typography
                    color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                  >
                    Separate content by every:
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      marginTop: '8px',
                    }}
                  >
                    {formData.options === 'Custom Separator' ? (
                      <TextField
                        type="text"
                        name="customOperator"
                        placeholder="Enter Custom Separator"
                        className="nodrag"
                        value={formData.customOperator}
                        onChange={onChange}
                        InputProps={{
                          style: {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                        }}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          bgcolor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          border: `2px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                      />
                    ) : formData?.options === 'Paragraph' ? (
                      <TextField
                        type="number"
                        name="breakWords"
                        placeholder="Number"
                        className="nodrag"
                        value={formData.breakWords}
                        onChange={onChange}
                        InputProps={{
                          style: {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                        }}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          bgcolor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          border: `2px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                      />
                    ) : (
                      <TextField
                        type="number"
                        className="nodrag"
                        placeholder="Number of items in the array"
                        name="processorArrayItemLength"
                        value={formData?.processorArrayItemLength || ''}
                        onChange={onChange}
                        inputProps={{ max: 99999 }}
                        InputProps={{
                          style: {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                        }}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          bgcolor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          border: `2px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                      />
                    )}
                    <Select
                      name="options"
                      value={formData.options}
                      onChange={onChange}
                      className="nodrag"
                      size="small"
                      sx={{
                        '& .MuiList-root': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        },
                        '& .MuiSvgIcon-root': {
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        },
                        borderRadius: '8px',
                        border: 'none',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .Mui-selected': {
                              backgroundColor:
                                user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                            },
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          },
                        },
                      }}
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          value={option}
                          key={index}
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>

                <Box mt={'16px'}>
                  <Label isAdvanced={true} labelName="Processing Details" />

                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                      >
                        Select where to end the content loop
                      </Typography>
                      <IconButtonTooltip title="The content will each pass through sequential nodes, one at a time. Selected where you would like to stop this loop.">
                        <Info
                          size={16}
                          color={user?.theme === 'dark' ? '#F9FAFB' : '#1D2939'}
                        />
                      </IconButtonTooltip>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      <Select
                        name="executing"
                        value={formData.executing}
                        onChange={onChange}
                        id="executing-type"
                        className="nodrag"
                        size="small"
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: 'none',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                        }}
                      >
                        <MenuItem
                          value="default"
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          Loop through first connected Node
                        </MenuItem>
                        {flowNodes
                          ?.slice(flowNodes?.findIndex(node => node === id) + 1)
                          ?.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              sx={{
                                color:
                                  user?.theme === 'dark' ? '#D0D5DD' : 'black',
                              }}
                            >
                              {reactFlow.getNode(option)?.data?.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mt: '24px',
                      position: 'relative',
                      '& svg': { cursor: 'pointer' },
                      mb: !collapsed ? 0 : '8px',
                    }}
                  >
                    <CaretDown
                      onClick={() => setCollapsed(!collapsed)}
                      size={16}
                      color={!collapsed ? '#3650DE' : '#667085'}
                    />
                    <Typography
                      fontSize={'16px'}
                      color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                      fontWeight={600}
                    >
                      {'Advanced'}
                    </Typography>
                  </Box>
                  <Collapse in={collapsed}>
                    <Box mt={'16px'}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                        >
                          Select content to work with
                        </Typography>
                        <IconButtonTooltip title={''}>
                          <Info
                            size={16}
                            color={
                              user?.theme === 'dark' ? '#F9FAFB' : '#1D2939'
                            }
                          />
                        </IconButtonTooltip>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          marginTop: '8px',
                        }}
                      >
                        <Select
                          name="auto"
                          value={formData.auto ? 'default' : 'false'}
                          onChange={e => {
                            onChange({
                              target: {
                                name: 'auto',
                                checked:
                                  e.target.value === 'default' ? true : false,
                              },
                            });
                          }}
                          className="nodrag"
                          size="small"
                          sx={{
                            '& .MuiList-root': {
                              backgroundColor:
                                user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                            },
                            '& .MuiSvgIcon-root': {
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            },
                            borderRadius: '8px',
                            border: 'none',
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                            bgcolor:
                              user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                '& .Mui-selected': {
                                  backgroundColor:
                                    user?.theme === 'dark'
                                      ? '#667085'
                                      : '#D0D5DD',
                                },
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#1D2939'
                                    : '#F9FAFB',
                              },
                            },
                          }}
                        >
                          <MenuItem
                            value="default"
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            Run all content through loop
                          </MenuItem>
                          <MenuItem
                            value="false"
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            Run selected content through loop
                          </MenuItem>
                        </Select>
                      </Box>

                      {!formData.auto && (
                        <Box mt={'16px'}>
                          <TextField
                            type="number"
                            name="startingPoint"
                            label="Starting Point"
                            value={formData.startingPoint}
                            className="nodrag"
                            disabled={formData.auto}
                            onChange={onChange}
                            InputProps={{
                              style: {
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#101828',
                              },
                            }}
                            sx={{
                              // color: formData.auto ? 'red' : 'black',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                              bgcolor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                              border: `2px solid ${
                                user?.theme === 'dark' ? '#475467' : '#EAECF0'
                              }`,
                            }}
                          />

                          <TextField
                            type="number"
                            name="endingPoint"
                            disabled={formData.auto}
                            className="nodrag"
                            label="Ending Point"
                            value={formData.endingPoint}
                            onChange={onChange}
                            InputProps={{
                              style: {
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#101828',
                              },
                            }}
                            sx={{
                              // color: formData.auto ? 'red' : 'black',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                              bgcolor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                              border: `2px solid ${
                                user?.theme === 'dark' ? '#475467' : '#EAECF0'
                              }`,
                            }}
                          />
                        </Box>
                      )}
                    </Box>

                    <Box
                      style={{
                        marginTop: '25px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <Typography
                        color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                      >
                        Add text to content
                      </Typography>

                      <TagInput
                        labelName={'Start content'}
                        handleId={'preInputText'}
                        name="preInput"
                        dataConnected={data?.preInputTextData || []}
                        value={formData.preInput}
                        onChange={onChange}
                        isActive={isActiveEdge(
                          edges,
                          id,
                          'preInputText',
                          'target',
                        )}
                        isAdvanced={true}
                        findColor={findColor}
                        advancedCollapsed={true}
                        isCollapsed={true}
                        nodeId={id}
                        removeIcons={true}
                        nodeLabel={data?.label}
                        placeholder={'Add text to start of content (optional)'}
                      />

                      <TagInput
                        labelName={'End content'}
                        handleId={'postInputText'}
                        name="postInput"
                        dataConnected={data?.postInputTextData || []}
                        value={formData.postInput}
                        onChange={onChange}
                        isActive={isActiveEdge(
                          edges,
                          id,
                          'postInputText',
                          'target',
                        )}
                        isAdvanced={true}
                        findColor={findColor}
                        advancedCollapsed={true}
                        isCollapsed={true}
                        nodeId={id}
                        removeIcons={true}
                        nodeLabel={data?.label}
                        placeholder={'Add text to end of content (optional)'}
                      />
                    </Box>
                  </Collapse>
                </Box>

                {/* {formData.options === 'Custom Separator' && (
                <>
                  <TextField
                    type='text'
                    name='customOperator'
                    placeholder='Enter Custom Separator'
                    value={formData.customOperator}
                    onChange={onChange}
                    label='Custom Separator'
                  />
                </>
              )}
            */}
              </Box>
            </div>
            <Box p={'16px 24px 16px 16px'}>
              <OutputContent
                edges={edges}
                maxOutputHeight={maxOutputHeight}
                id={id}
                value={formData.processorArray}
                labelName={'Content'}
                isCollapsed={collapsed}
                getNode={reactFlow.getNode}
                findColor={findColor}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              />
              {/* <OutputTextarea
                previewResponses={previewResponses}
                previewIndex={data.previewIndex}
                value={data.text}
                activeHandle={
                  edges?.find(
                    (edge: any) =>
                      edge?.source === id && edge?.sourceHandle === 'output'
                  )?.source
                    ? true
                    : false
                }
                placement={data?.placement}
                labelName={'Output'}
                onPreview={onPreview}
                findColor={findColor}
                onChangePlacement={onChangePlacement}
              /> */}
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
}

export default memo(SequenceNode);
