import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Export } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { CompanySpace } from '../../util/Types/CompanySpace';
import CreateSpaceModal from '../Layout/LayoutComponents/Spaces/CreateSpaceModal';

interface Props {
  companySpaces: CompanySpace[];
  setSelectedSpaceId: React.Dispatch<React.SetStateAction<string | undefined>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  getCompanySpaces: () => Promise<void>;
}

const SpacesTable: React.FC<Props> = ({
  companySpaces,
  setSelectedSpaceId,
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  getCompanySpaces,
}) => {
  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false);

  const start = page * rowsPerPage;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToCsv = (data: CompanySpace[], fileName: string) => {
    const csvRows = [];

    const headers = [
      'Space name',
      'Users',
      'Flows',
      'FluxTokens',
      'Images',
      'Audio',
      'Text',
    ];
    csvRows.push(headers.join(','));

    data.forEach(row => {
      const values = [
        row.name,
        row.users,
        row.numberOfFlows,
        row.fluxTokens?.toFixed(3),
        row.imageTokens?.toFixed(3),
        row.audioTokens?.toFixed(3),
        row.textTokens?.toFixed(3),
      ];
      csvRows.push(values.join(','));
    });

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      {showCreateSpaceModal && (
        <CreateSpaceModal
          setSelectedSpaceId={setSelectedSpaceId}
          onClose={() => setShowCreateSpaceModal(false)}
          getSpaces={getCompanySpaces}
        />
      )}

      <Box>
        <Box
          sx={{
            display: 'flex',
            height: '64px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            borderBottom: '1px solid #D0D5DD',
            flexShrink: 0,
            mb: '10px',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: '#000',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '32px',
              margin: '24px',
              pb: '10px',
            }}
          >
            Spaces
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: '24px',
            paddingY: '15px',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(71, 84, 103, 1)',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '28.8px',
              letterspacing: '0.15px',
            }}
          >
            Spaces Table View
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Button
              variant="text"
              sx={{
                color: 'rgba(54, 80, 222, 1)',
                background: 'transparent',
                border: '1px solid rgba(54, 80, 222, 1)',
                height: '35px',
              }}
              endIcon={<Export color="rgba(54, 80, 222, 1)" />}
              onClick={() => exportToCsv(companySpaces, 'spaces.csv')}
            >
              Export Table
            </Button>

            <Button
              variant="contained"
              sx={{
                color: 'white',
                background: 'rgba(54, 80, 222, 1)',
                height: '35px',
              }}
              onClick={() => setShowCreateSpaceModal(true)}
            >
              Add Space
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            width: '96%',
            marginTop: '20px',
            paddingX: '24px',
          }}
        >
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table size={'medium'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Space name</TableCell>
                    <TableCell align="center">Users</TableCell>
                    <TableCell align="center">Flows</TableCell>
                    <TableCell align="center">FluxTokens</TableCell>
                    <TableCell align="center">Images</TableCell>
                    <TableCell align="center">Audio</TableCell>
                    <TableCell align="center">Text</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companySpaces
                    .slice(start, start + rowsPerPage)
                    .map(companySpace => {
                      return (
                        <TableRow
                          hover
                          onClick={() => {
                            setSelectedSpaceId(companySpace.id);
                          }}
                          tabIndex={-1}
                          key={companySpace.id}
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          <TableCell
                            sx={{
                              // color: 'rgba(71, 84, 103, 1)',
                              // fontSize: '14px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              fontWeight: 400,
                              color: '#2C4DFF',
                            }}
                          >
                            <Typography>{companySpace.name}</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: 'rgba(71, 84, 103, 1)',
                              fontSize: '14px',
                            }}
                          >
                            {companySpace.users}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: 'rgba(71, 84, 103, 1)',
                              fontSize: '14px',
                            }}
                          >
                            {companySpace.numberOfFlows}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'rgba(71, 84, 103, 1)',
                              fontSize: '12px',
                            }}
                            align="center"
                          >
                            {companySpace.fluxTokens?.toFixed(3)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'rgba(71, 84, 103, 1)',
                              fontSize: '12px',
                            }}
                            align="center"
                          >
                            {companySpace.imageTokens?.toFixed(3)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'rgba(71, 84, 103, 1)',
                              fontSize: '12px',
                            }}
                            align="center"
                          >
                            {companySpace.audioTokens?.toFixed(3)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'rgba(71, 84, 103, 1)',
                              fontSize: '12px',
                            }}
                            align="center"
                          >
                            {companySpace.textTokens?.toFixed(3)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={companySpaces.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      slotProps={{
                        select: {
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      sx={{
                        '& .MuiInputBase-root': {
                          margin: '0 10px',
                          marginRight: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default SpacesTable;
