import { memo, useEffect, useRef, useState } from 'react';
import { NodeProps, Position, useEdges, useReactFlow } from 'reactflow';

import '../../assets/styles/animatedBorder.scss';
import { useAuthStore } from '../../store/storeAuth';
import { Box, Typography } from '@mui/material';
import useFlowsStore from '../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import Node from '../UI/Node/Node';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { isActiveEdge } from '../../util/findActiveHandle';
import SelectDocument from '../StoredDataCaller/PodSelect/SelectDocument';
import { DocumentService } from '../../service/DocumentService';
import { useNotificationStore } from '../../store/storeNotifications';
import { UploadService } from '../../service/UploadService';
import { UserService } from '../../service/UserService';
import { useObserveElementWidth } from '../../util/useObserveElement';
import InputHandle from '../UI/InputHandle/InputHandle';

const StoredDataCaller = ({ id, data, isConnectable, type }: NodeProps) => {
  // const { documents, setDocuments } = useAuthStore(state => state);
  const setNotification = useNotificationStore(state => state.setNotification);
  const [selectedDocument, setSelectedDocument] = useState({
    name: '',
    id: data?.selectedDocumentIdData?.[0]?.text || '',
  });

  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const { setNodes: setNodeState } = useReactFlow();

  const { setSchema, setSaving, flowId, spaceId } = useFlowsStore(
    state => state,
  );

  const [isLoading, setIsLoading] = useState(false);
  const edges = useEdges();

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    if (!isChangingDirectly) {
      if (
        data?.selectedDocumentIdData?.length &&
        data?.selectedDocument?.id !== data?.selectedDocumentIdData?.[0]?.text
      ) {
        onChange({
          target: {
            value: data?.selectedDocumentIdData?.[0]?.text,
          },
        });
      } else {
        setSelectedDocument(data?.selectedDocument || {});
      }
    }
  }, [data?.selectedDocument, data?.selectedDocumentIdData]);

  const onChange = async (e: any) => {
    const value = e.target.value;

    const findDocument = documents.find((doc: any) => doc.id === value);

    try {
      const response = await DocumentService?.extractTextFromFile({
        id: findDocument?.id,
      });
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        objectData: {
          selectedDocument: findDocument,
          text: response?.data?.message ?? response?.data?.src ?? '',
        },
        setSchema,
      });

      setSelectedDocument(findDocument);
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message,
      });
    }

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const { user } = useAuthStore(state => state);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getDocuments();
  }, [spaceId]);

  const getDocuments = async () => {
    try {
      if (spaceId) {
        const res = await DocumentService.getSpaceDocuments(spaceId);
        setDocuments(res?.data);
        const existingDocument = res.data.find(
          doc => doc.id === data?.selectedDocument?.id,
        );
        if (!existingDocument) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            setSaving,
            flowId,
            changeType: 'execute',
            objectCallerData: [
              ...(user?.spaceObjects || []),
              ...(user?.objects || []),
            ],

            objectData: {
              selectedDocument: null,
              text: '',
            },
            setSchema,
          });
        }

        return;
      }

      // if (spaceId) {
      const res = await UserService.getUserSpaceDocuments();
      setDocuments(res?.data);
      //   return;
      // }

      // const res = await DocumentService.getDocuments();
      // setDocuments(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onUploadDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e?.target?.files?.[0];
      const formData = new FormData();

      formData.append('file', file as any);

      const res = await UploadService.uploadFile(formData);

      const response = await DocumentService.createDocument({
        name: res.data.name,
        src: res.data.src,
        spaceId: spaceId!,
      });

      getDocuments();
      setNotification({
        type: 'success',
        message: 'File uploaded successfully',
      });
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
    } finally {
      e.target.value = '';
      e.target.files = null;
    }
  };

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        type={type}
        id={id}
        data={data}
        isLoading={isLoading}
        edges={edges}
        showTokensUsed={false}
      >
        <Box display={'flex'}>
          <div>
            <Box p={'16px 24px 16px 16px'} ref={inputRef}>
              <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                <Typography
                  fontSize={'16px'}
                  color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                  fontWeight={600}
                >
                  {'Select the Stored Data'}
                </Typography>
              </Box>
              <SelectDocument
                onChangeDocument={onChange}
                documents={documents}
                spaceId={spaceId}
                selectedDocument={selectedDocument || {}}
                isLoading={isLoading}
              />
              <input
                type="file"
                id="content-upload-file"
                hidden
                ref={uploadFileRef}
                onChange={e => onUploadDocument(e)}
              />
              <InputHandle
                activeColor={findColor?.color}
                handleId={'selectedDocumentId'}
                isActive={isActiveEdge(
                  edges,
                  id,
                  'selectedDocumentId',
                  'target',
                )}
                left={-10}
                top={'30%'}
                position={Position.Left}
                type="target"
              />
              <Box sx={{ height: data?.text ? '280px' : '25%' }}>
                <OutputTextarea
                  // previewResponses={previewResponses}
                  maxOutputHeight={maxOutputHeight}
                  previewIndex={data.previewIndex}
                  value={data?.text}
                  activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                  placement={data?.placement}
                  labelName={selectedDocument?.name || 'Output'}
                  // onPreview={onPreview}
                  topPosition={!data?.text ? '-200px' : '10px'}
                  findColor={findColor}
                  nodeLabel={data?.label}
                  // onChangePlacement={onChangePlacement}
                  isHidden={!data?.text}
                />
              </Box>
            </Box>
          </div>
        </Box>
      </Node>
    </Box>
  );
};

export default memo(StoredDataCaller);
