import { useMemo } from 'react';
import {
  additionalOptionsArray,
  optionsArray,
} from '../../AiConfigs/constants';
import KitModal from '../../UI/KitModal';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData: values, onChange }: Props) => {
  const additionalOptionsArrayFiltered = useMemo(() => {
    return additionalOptionsArray.map(option => ({
      ...option,
      disabled: option.value !== 'OpenAI',
    }));
  }, []);

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      showCloseButton={false}
      onHide={onHide}
      show={show}
    >
      <Typography variant='h6'> Settings</Typography>

      <FormControlLabel
        control={
          <Switch
            onChange={() => {
              onChange({
                target: {
                  name: 'returnRaw',
                  value: !values.returnRaw,
                },
              });
            }}
            checked={values?.returnRaw}
          />
        }
        label={'Return Raw'}
      />
      <FormControl
        sx={{
          mt: '24px',
          mb: '24px',
        }}
        fullWidth
      >
        <InputLabel htmlFor={'searchType'}>Search Type</InputLabel>
        <Select
          fullWidth
          value={values.searchType}
          name='searchType'
          className='nodrag'
          label='Search Type'
          onChange={(e: any) => onChange(e)}
        >
          <MenuItem value='similarity'>Similarity Search</MenuItem>
          <MenuItem value='similarity-ner'>Similarity Search (NER)</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        sx={{
          mb: '24px',
        }}
      >
        <InputLabel htmlFor='systemAi'>System </InputLabel>

        <Select
          name='systemAi'
          className='nodrag'
          label='System '
          fullWidth
          value={values.systemAi}
          onChange={e => onChange(e)}
        >
          {additionalOptionsArrayFiltered.map(opt => (
            <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor='model'>Ai Model</InputLabel>
        <Select
          className='nodrag'
          value={values.model}
          name='model'
          label='Ai Model'
          onChange={e => onChange(e)}
        >
          {optionsArray.map(opt => (
            <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        type='number'
        onChange={e => onChange(e)}
        value={values.topK}
        label='Top K'
        name='topK'
        sx={{
          mt: '24px',
        }}
        fullWidth
      />
    </KitModal>
  );
};

export default SettingsModal;
