import axios from 'axios';

interface Spreadsheet {
  id: string;
  name: string;
}

interface Drive {
  id: string;
  name: string;
}

const querySpreadsheets = async (
  accessToken: string,
  query: string,
  driveId?: string,
): Promise<Spreadsheet[]> => {
  const headers = { Authorization: `Bearer ${accessToken}` };
  const params: Record<string, any> = {
    q: query,
    fields: 'files(id, name)',
    includeItemsFromAllDrives: true,
    supportsAllDrives: true,
  };

  if (driveId) {
    params.driveId = driveId;
    params.corpora = 'drive';
  }

  try {
    const response = await axios.get(
      'https://www.googleapis.com/drive/v3/files',
      { params, headers },
    );
    return response.data.files;
  } catch (error) {
    throw error;
  }
};

const listSharedDrives = async (accessToken: string): Promise<Drive[]> => {
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await axios.get(
      'https://www.googleapis.com/drive/v3/drives',
      {
        params: {
          fields: 'drives(id, name)',
        },
        headers,
      },
    );
    return response.data.drives;
  } catch (error) {
    throw error;
  }
};

export const getSpreadsheets = async (
  accessToken: string,
): Promise<Spreadsheet[]> => {
  try {
    const userDriveSpreadsheets = await querySpreadsheets(
      accessToken,
      "mimeType='application/vnd.google-apps.spreadsheet'",
    );

    const sharedDrives = await listSharedDrives(accessToken);

    const sharedDriveSpreadsheetsPromises = sharedDrives.map(drive =>
      querySpreadsheets(
        accessToken,
        "mimeType='application/vnd.google-apps.spreadsheet'",
        drive.id,
      ),
    );

    const sharedDriveSpreadsheetsArrays = await Promise.all(
      sharedDriveSpreadsheetsPromises,
    );

    const sharedDriveSpreadsheets = sharedDriveSpreadsheetsArrays.flat();

    const allSpreadsheets = [
      ...userDriveSpreadsheets,
      ...sharedDriveSpreadsheets,
    ];

    return allSpreadsheets;
  } catch (error) {
    throw error;
  }
};

export const isGoogleSheetsAccessTokenValid = async (
  googleSheetsAccessToken: string,
): Promise<boolean> => {
  try {
    const { data } = await axios.get(
      `https://oauth2.googleapis.com/tokeninfo?access_token=${googleSheetsAccessToken}`,
    );

    if (data) {
      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};

export const getConnectedEmail = async (googleSheetsAccessToken: string) => {
  try {
    const { data } = await axios.get(
      `https://oauth2.googleapis.com/tokeninfo?access_token=${googleSheetsAccessToken}`,
    );

    return data.email;
  } catch (error) {
    throw error;
  }
};
