import KitModal from '../../UI/KitModal';
import { Box, MenuItem, Select, Typography } from '@mui/material';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant='h6'>Settings</Typography>
        <Box>
          <Select
            name='system'
            label='System'
            className='nodrag'
            value={formData.system}
            onChange={onChange}
          >
            <MenuItem value='builtWithApi'>BuiltWith Api</MenuItem>
            <MenuItem value='oxylab'>Oxy Lab</MenuItem>
            <MenuItem value='scrapingBee'>ScrapingBee</MenuItem>
            {/* <MenuItem value='brightData'>Bright Data</MenuItem> */}
          </Select>
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
