export const googleTrendsCategories = {
'0': 'All categories',
  '642': 'ADD & ADHD',
  '625': 'AIDS & HIV',
  '1289': 'Academic Conferences & Publications',
  '427': 'Accident & Personal Injury Law',
  '278': 'Accounting & Auditing',
  '1341': 'Accounting & Financial Software',
  '894': 'Acting & Theater',
  '1097': 'Action & Adventure Films',
  '1311': 'Action & Platform Games',
  '1239': 'Acupuncture & Chinese Medicine',
  '820': 'Acura',
  '974': 'Adoption',
  '925': 'Adventure Games',
  '707': 'Adventure Travel',
  '25': 'Advertising & Marketing',
  '356': 'Aerospace & Defense',
  '326': 'Affiliate Programs',
  '1208': 'African Music',
  '547': 'African-Americans',
  '579': 'Africans & Diaspora',
  '623': 'Aging & Geriatrics',
  '748': 'Agricultural Equipment',
  '46': 'Agriculture & Forestry',
  '1389': 'Agritourism',
  '670': 'Agrochemicals',
  '1247': 'Air Force',
  '203': 'Air Travel',
  '1245': 'Airport Parking & Transportation',
  '277': 'Alcoholic Beverages',
  '626': 'Allergies',
  '499': 'Alternative & Natural Medicine',
  '1015': 'Alumni & Reunions',
  '624': "Alzheimer's Disease",
  '258': 'American Football',
  '788': 'Anatomy',
  '400': 'Ancestry & Genealogy',
  '882': 'Animal Products & Services',
  '883': 'Animal Welfare',
  '1104': 'Animated Films',
  '317': 'Anime & Manga',
  '64': 'Antiques & Collectibles',
  '315': 'Antivirus & Malware',
  '639': 'Anxiety & Stress',
  '378': 'Apartments & Residential Rentals',
  '68': 'Apparel',
  '1228': 'Apparel Services',
  '747': 'Aquaculture',
  '1034': 'Arab & Middle Eastern Music',
  '556': 'Arabs & Middle Easterners',
  '919': 'Arcade & Coin-Op Games',
  '477': 'Architecture',
  '1248': 'Army',
  '1361': 'Art & Craft Supplies',
  '628': 'Arthritis',
  '3': 'Arts & Entertainment',
  '1195': 'Arts Education',
  '912': 'Asian Cuisine',
  '1257': 'Asians & Diaspora',
  '649': 'Assisted Living & Long Term Care',
  '1352': 'Assistive Technology',
  '627': 'Asthma',
  '448': 'Astrology & Divination',
  '435': 'Astronomy',
  '983': 'Athletic Apparel',
  '1254': 'Atmospheric Science',
  '292': 'Auctions',
  '821': 'Audi',
  '1089': 'Audio & Music Software',
  '361': 'Audio Equipment',
  '1092': 'Audio Files Formats & Codecs',
  '1217': 'Auto Exterior',
  '468': 'Auto Financing',
  '467': 'Auto Insurance',
  '1218': 'Auto Interior',
  '1190': 'Automotive Industry',
  '47': 'Autos & Vehicles',
  '662': 'Aviation',
  '822': 'BMW',
  '1374': 'Babies & Toddlers',
  '1231': 'Baby & Pet Names',
  '115': 'Baby Care & Hygiene',
  '907': 'Baked Goods',
  '37': 'Banking',
  '423': 'Bankruptcy',
  '259': 'Baseball',
  '264': 'Basketball',
  '1365': 'Bathroom',
  '1074': 'Beaches & Islands',
  '44': 'Beauty & Fitness',
  '1219': 'Beauty Pageants',
  '948': 'Bed & Bath',
  '1369': 'Bedding & Bed Linens',
  '1366': 'Bedroom',
  '1367': 'Beds & Headboards',
  '404': 'Beer',
  '1059': 'Bentley',
  '1191': 'Bicycles & Accessories',
  '939': 'Billiards',
  '1384': 'Binoculars, Telescopes & Optical Devices',
  '690': 'Biographies & Quotations',
  '440': 'Biological Sciences',
  '884': 'Birds',
  '198': 'Birth Control',
  '1270': 'Birthdays & Name Days',
  '504': 'Blogging Resources & Services',
  '1394': 'Blu-Ray Players & Recorders',
  '1040': 'Blues',
  '1170': 'Bluetooth Accessories',
  '920': 'Board Games',
  '459': 'Boating',
  '1140': 'Boats & Watercraft',
  '239': 'Body Art',
  '241': 'Bodybuilding',
  '360': 'Bollywood & South Asian Film',
  '355': 'Book Retailers',
  '22': 'Books & Literature',
  '1016': 'Bowling',
  '515': 'Boxing',
  '1287': 'Brazilian Music',
  '112': 'Broadcast & Network News',
  '1243': 'Broadway & Musical Theater',
  '862': 'Buddhism',
  '1060': 'Buick',
  '650': 'Building Materials & Supplies',
  '708': 'Bus & Rail',
  '1272': 'Business & Corporate Law',
  '12': 'Business & Industrial',
  '377': 'Business & Personal Listings',
  '498': 'Business & Productivity Software',
  '1375': 'Business Cards & Stationary',
  '799': 'Business Education',
  '1138': 'Business Finance',
  '1200': 'Business Formation',
  '784': 'Business News',
  '1159': 'Business Operations',
  '336': 'Business Plans & Presentations',
  '721': 'Business Process',
  '329': 'Business Services',
  '731': 'C & C++',
  '1300': 'CAD & CAM',
  '217': 'CD & Audio Shopping',
  '1321': 'CD & DVD Drives & Burners',
  '1322': 'CD & DVD Storage Media',
  '501': 'Cable & Satellite Providers',
  '823': 'Cadillac',
  '691': 'Calculators & Reference Tools',
  '1358': 'Calendar & Scheduling Software',
  '389': 'Calling Cards',
  '308': 'Camcorders',
  '573': 'Camera & Photo Equipment',
  '1383': 'Camera Lenses',
  '307': 'Cameras',
  '306': 'Cameras & Camcorders',
  '398': 'Campaigns & Elections',
  '1213': 'Campers & RVs',
  '429': 'Cancer',
  '906': 'Candy & Sweets',
  '230': 'Car Audio',
  '1188': 'Car Electronics',
  '205': 'Car Rental & Taxi Services',
  '1189': 'Car Video',
  '39': 'Card Games',
  '100': 'Cards & Greetings',
  '959': 'Career Resources & Planning',
  '1215': 'Cargo Trucks & Trailers',
  '1246': 'Carnival & Mardi Gras',
  '1339': 'Carpooling & Ridesharing',
  '319': 'Cartoons',
  '984': 'Casual Apparel',
  '926': 'Casual Games',
  '885': 'Cats',
  '184': 'Celebrities & Entertainment News',
  '57': 'Charity & Philanthropy',
  '534': 'Cheerleading',
  '288': 'Chemicals Industry',
  '505': 'Chemistry',
  '921': 'Chess & Abstract Strategy Games',
  '826': 'Chevrolet',
  '403': 'Child Care',
  '985': "Children's Clothing",
  '679': "Children's Interests",
  '1183': "Children's Literature",
  '741': 'Chips & Processors',
  '643': 'Cholesterol Issues',
  '585': 'Christian & Gospel Music',
  '1274': 'Christian Holidays',
  '864': 'Christianity',
  '1078': 'Christmas',
  '833': 'Chrysler',
  '834': 'Citroën',
  '1014': 'City & Local Guides',
  '651': 'Civil Engineering',
  '1102': 'Classic Films',
  '1037': 'Classic Rock & Oldies',
  '1013': 'Classic Vehicles',
  '586': 'Classical Music',
  '61': 'Classifieds',
  '671': 'Cleaning Agents',
  '949': 'Cleaning Supplies & Services',
  '1238': 'Cleansing & Detoxification',
  '1255': 'Climate Change & Global Warming',
  '1223': 'Clip Art & Animated GIFs',
  '1363': 'Clocks',
  '124': 'Clothing Accessories',
  '188': 'Clubs & Nightlife',
  '189': 'Clubs & Organizations',
  '672': 'Coatings & Adhesives',
  '916': 'Coffee & Tea',
  '629': 'Cold & Flu',
  '923': 'Collectible Card Games',
  '813': 'College Financing',
  '1073': 'College Sports',
  '372': 'Colleges & Universities',
  '514': 'Combat Sports',
  '1095': 'Comedy Films',
  '318': 'Comics',
  '316': 'Comics & Animation',
  '1178': 'Commercial & Investment Real Estate',
  '1160': 'Commercial Lending',
  '1214': 'Commercial Vehicles',
  '904': 'Commodities & Futures Trading',
  '1302': 'Communications & Media Studies',
  '385': 'Communications Equipment',
  '1240': 'Company Earnings',
  '1179': 'Company News',
  '723': 'Compensation & Benefits',
  '41': 'Computer & Video Games',
  '717': 'Computer Components',
  '496': 'Computer Drives & Storage',
  '1229': 'Computer Education',
  '30': 'Computer Hardware',
  '226': 'Computer Memory',
  '487': 'Computer Monitors & Displays',
  '312': 'Computer Peripherals',
  '1227': 'Computer Science',
  '314': 'Computer Security',
  '728': 'Computer Servers',
  '5': 'Computers & Electronics',
  '891': 'Concerts & Music Festivals',
  '967': 'Constitutional Law & Civil Rights',
  '48': 'Construction & Maintenance',
  '950': 'Construction & Power Tools',
  '652': 'Construction Consulting & Contracting',
  '1162': 'Consulting',
  '97': 'Consumer Advocacy & Protection',
  '78': 'Consumer Electronics',
  '69': 'Consumer Resources',
  '808': 'Content Management',
  '1276': 'Contests, Awards & Prizes',
  '122': 'Cooking & Recipes',
  '120': 'Cookware & Diningware',
  '1331': 'Copiers',
  '1181': 'Corporate & Financial Crime',
  '334': 'Corporate Events',
  '331': 'Corporate Training',
  '1220': 'Cosmetic Procedures',
  '238': 'Cosmetic Surgery',
  '147': 'Cosmetology & Beauty Professionals',
  '988': 'Costumes',
  '511': 'Counseling Services',
  '587': 'Country Music',
  '365': 'Coupons & Discount Offers',
  '663': 'Couriers & Messengers',
  '1075': 'Courts & Judiciary',
  '284': 'Crafts',
  '279': 'Credit & Lending',
  '811': 'Credit Cards',
  '296': 'Cricket',
  '704': 'Crime & Justice',
  '424': 'Criminal Law',
  '749': 'Crops & Seed',
  '206': 'Cruises & Charters',
  '297': 'Culinary Training',
  '1103': 'Cult & Indie Films',
  '814': 'Currencies & Foreign Exchange',
  '806': 'Custom & Performance Vehicles',
  '341': 'Customer Relationship Management (CRM)',
  '450': 'Customer Services',
  '1373': 'Cutlery & Cutting Accessories',
  '458': 'Cycling',
  '1025': 'DJ Resources & Equipment',
  '1145': 'DVD & Video Rentals',
  '210': 'DVD & Video Shopping',
  '1395': 'DVD Players & Recorders',
  '1393': 'DVRs & Set-Top Boxes',
  '581': 'Dance',
  '588': 'Dance & Electronic Music',
  '1323': 'Data Backup & Recovery',
  '488': 'Data Formats & Protocols',
  '343': 'Data Management',
  '900': 'Data Sheets & Electronics Reference',
  '55': 'Dating & Personals',
  '812': 'Debt Management',
  '669': 'Defense Industry',
  '510': 'Demographics',
  '640': 'Depression',
  '653': 'Design',
  '309': 'Desktop Computers',
  '1088': 'Desktop Publishing',
  '802': 'Developer Jobs',
  '730': 'Development Tools',
  '225': 'Device Drivers',
  '630': 'Diabetes',
  '692': 'Dictionaries & Encyclopedias',
  '917': 'Dining Guides',
  '527': 'Directories & Listings',
  '677': 'Disabled & Special Needs',
  '1205': 'Discrimination & Identity Relations',
  '367': 'Distance Learning',
  '1298': 'Distributed & Parallel Computing',
  '664': 'Distribution & Logistics',
  '1305': 'Diving & Underwater Activities',
  '1261': 'Divorce & Separation',
  '634': "Doctors' Offices",
  '332': 'Document & Printing Services',
  '1072': 'Documentary Films',
  '836': 'Dodge',
  '886': 'Dogs',
  '472': 'Domestic Services',
  '827': 'Doors & Windows',
  '1206': 'Drag & Street Racing',
  '1094': 'Drama Films',
  '1397': 'Drawing & Coloring',
  '1173': 'Dress-Up & Fashion Games',
  '927': 'Driving & Racing Games',
  '1351': 'Drug & Alcohol Testing',
  '1350': 'Drug & Alcohol Treatment',
  '1314': 'Drug Laws & Policy',
  '646': 'Drugs & Medications',
  '1327': 'Drums & Percussion',
  '968': 'Drunk Driving Law',
  '673': 'Dyes & Pigments',
  '1324': 'E-Book Readers',
  '608': 'E-Books',
  '340': 'E-Commerce Services',
  '1211': 'Ear Nose & Throat',
  '1012': 'Early Childhood Education',
  '1168': 'Earth Sciences',
  '1033': 'East Asian Music',
  '549': 'East Asians & Diaspora',
  '1123': 'Easter',
  '682': 'Eastern Europeans',
  '571': 'Eating Disorders',
  '442': 'Ecology & Environment',
  '520': 'Economics',
  '1164': 'Economy News',
  '1005': 'Ecotourism',
  '538': 'Edgy & Bizarre',
  '74': 'Education',
  '374': 'Educational Resources',
  '804': 'Educational Software',
  '1380': 'Electric & Plug-In Vehicles',
  '658': 'Electricity',
  '743': 'Electromechanical Devices',
  '1192': 'Electronic Accessories',
  '742': 'Electronic Components',
  '434': 'Electronics & Electrical',
  '394': 'Email & Messaging',
  '962': 'Embassies & Consulates',
  '168': 'Emergency Services',
  '1328': 'Endocrine Conditions',
  '233': 'Energy & Utilities',
  '1216': 'Engine & Transmission',
  '231': 'Engineering & Technology',
  '342': 'Enterprise Resource Planning (ERP)',
  '77': 'Enterprise Technology',
  '612': 'Entertainment Industry',
  '1143': 'Entertainment Media',
  '1144': 'Entertainment Media Rentals',
  '82': 'Environmental Issues',
  '568': 'Equestrian',
  '202': 'Erectile Dysfunction',
  '56': 'Ethnic & Identity Groups',
  '1304': 'Etiquette',
  '956': 'Event Planning',
  '569': 'Events & Listings',
  '963': 'Executive Branch',
  '607': 'Exotic Pets',
  '973': 'Expatriate Communities',
  '1022': 'Experimental & Industrial Music',
  '554': 'Extreme Sports',
  '1224': 'Eyeglasses & Contacts',
  '989': 'Eyewear',
  '143': 'Face & Body Care',
  '661': 'Factory Automation',
  '1132': 'Family',
  '1131': 'Family & Relationships',
  '1291': 'Family Films',
  '522': 'Family Law',
  '1290': 'Family-Oriented Games & Activities',
  '540': 'Fan Fiction',
  '998': 'Fantasy Sports',
  '185': 'Fashion & Style',
  '98': 'Fashion Designers & Collections',
  '1155': 'Fashion Modeling',
  '918': 'Fast Food',
  '1332': 'Fax Machines',
  '1061': 'Ferrari',
  '838': 'Fiat',
  '1230': 'Fiber & Textile Arts',
  '928': 'Fighting Games',
  '321': 'File Sharing & Hosting',
  '1108': 'Film & TV Awards',
  '1116': 'Film & TV Industry',
  '1117': 'Film & TV Production',
  '1086': 'Film Festivals',
  '7': 'Finance',
  '1163': 'Financial Markets',
  '903': 'Financial Planning',
  '726': 'Fire & Security Services',
  '1165': 'Fiscal Policy News',
  '887': 'Fish & Aquaria',
  '462': 'Fishing',
  '94': 'Fitness',
  '1318': 'Flash Drives & Memory Cards',
  '447': 'Flash-Based Entertainment',
  '832': 'Flooring',
  '981': 'Flora & Fauna',
  '323': 'Flowers',
  '1152': 'Fluid Handling',
  '1023': 'Folk & Traditional Music',
  '805': 'Fonts',
  '71': 'Food & Drink',
  '621': 'Food Production',
  '957': 'Food Service',
  '697': 'Footwear',
  '840': 'Ford',
  '1264': 'Foreign Language Resources',
  '1266': 'Foreign Language Study',
  '750': 'Forestry',
  '990': 'Formal Wear',
  '693': 'Forms Guides & Templates',
  '191': 'Forum & Chat Providers',
  '901': 'Freeware & Shareware',
  '289': 'Freight & Trucking',
  '1134': 'Friendship',
  '908': 'Fruits & Vegetables',
  '1268': 'Fuel Economy & Gas Prices',
  '539': 'Fun & Trivia',
  '1174': 'Fun Tests & Silly Surveys',
  '638': 'GERD & Digestive Disorders',
  '896': 'GM-Daewoo',
  '842': 'GMC',
  '794': 'GPS & Navigation',
  '362': 'Gadgets & Portable Electronics',
  '381': 'Game Cheats & Hints',
  '899': 'Game Systems & Consoles',
  '8': 'Games',
  '1343': 'Gaming Media & Reference',
  '1312': 'Gangs & Organized Crime',
  '269': 'Gardening & Landscaping',
  '113': 'Gay-Lesbian-Bisexual-Transgender',
  '350': 'Gems & Jewelry',
  '980': 'General Reference',
  '835': 'Generators',
  '941': 'Genetic Disorders',
  '982': 'Genetics',
  '1084': 'Geographic Reference',
  '443': 'Geology',
  '99': 'Gifts',
  '70': 'Gifts & Special Event Items',
  '261': 'Golf',
  '507': 'Gossip & Tabloid News',
  '503': 'Goth Subculture',
  '76': 'Government',
  '1387': 'Government Agencies',
  '1385': 'Government Contracting & Procurement',
  '1282': 'Grants & Financial Assistance',
  '654': 'Graphic Design',
  '486': 'Graphics & Animation Software',
  '121': 'Grocery & Food Retailers',
  '1325': 'Guitars',
  '519': 'Gymnastics',
  '1354': 'HDTVs',
  '828': 'HVAC & Climate Control',
  '146': 'Hair Care',
  '235': 'Hair Loss',
  '1079': 'Halloween & October 31st',
  '986': 'Handbags & Purses',
  '1017': 'Handball',
  '1046': 'Handheld Game Consoles',
  '1320': 'Hard Drives',
  '1035': 'Hard Rock & Progressive',
  '739': 'Hardware Modding & Tuning',
  '631': 'Headaches & Migraines',
  '1396': 'Headphones',
  '991': 'Headwear',
  '45': 'Health',
  '419': 'Health Conditions',
  '254': 'Health Education & Medical Training',
  '252': 'Health Foundations & Medical Research',
  '249': 'Health Insurance',
  '1253': 'Health News',
  '1256': 'Health Policy',
  '559': 'Heart & Hypertension',
  '837': 'Heavy Machinery',
  '542': 'Hiking & Camping',
  '866': 'Hinduism',
  '1006': 'Historical Sites & Buildings',
  '433': 'History',
  '65': 'Hobbies & Leisure',
  '260': 'Hockey',
  '678': 'Holidays & Seasonal Events',
  '11': 'Home & Garden',
  '271': 'Home Appliances',
  '466': 'Home Financing',
  '270': 'Home Furnishings',
  '158': 'Home Improvement',
  '465': 'Home Insurance',
  '727': 'Home Office',
  '1348': 'Home Storage & Shelving',
  '1157': 'Home Theater Systems',
  '137': 'Homemaking & Interior Decor',
  '791': 'Homeschooling',
  '843': 'Honda',
  '615': 'Horror Films',
  '888': 'Horses',
  '751': 'Horticulture',
  '955': 'Hospitality Industry',
  '250': 'Hospitals & Treatment Centers',
  '179': 'Hotels & Accommodations',
  '1232': 'House Painting & Finishing',
  '1166': 'Housing & Development',
  '694': 'How-To, DIY & Expert Content',
  '157': 'Human Resources',
  '1280': 'Human Rights & Liberties',
  '474': 'Humanities',
  '1062': 'Hummer',
  '182': 'Humor',
  '461': 'Hunting & Shooting',
  '810': 'Hybrid & Alternative Vehicles',
  '244': 'Hygiene & Toiletries',
  '845': 'Hyundai',
  '104': 'ISPs',
  '1149': 'Ice Skating',
  '1313': 'Immigration Policy & Border Issues',
  '354': 'Import & Export',
  '1038': 'Indie & Alternative Music',
  '681': 'Indigenous Peoples',
  '1000': 'Individual Sports',
  '655': 'Industrial & Product Design',
  '287': 'Industrial Materials & Equipment',
  '632': 'Infectious Diseases',
  '647': 'Infertility',
  '1377': 'Infiniti',
  '817': 'Injury',
  '1333': 'Ink & Toner',
  '493': 'Input Devices',
  '1278': 'Insects & Entomology',
  '38': 'Insurance',
  '426': 'Intellectual Property',
  '1221': 'Intelligence & Counterterrorism',
  '656': 'Interior Design',
  '521': 'International Relations',
  '13': 'Internet & Telecom',
  '304': 'Internet Clients & Browsers',
  '807': 'Internet Software',
  '107': 'Investing',
  '1139': 'Investment Banking',
  '868': 'Islam',
  '1275': 'Islamic Holidays',
  '1378': 'Isuzu',
  '1063': 'Jaguar',
  '732': 'Java',
  '42': 'Jazz',
  '589': 'Jazz & Blues',
  '846': 'Jeep',
  '550': 'Jewish Culture',
  '1124': 'Jewish Holidays',
  '960': 'Job Listings',
  '60': 'Jobs',
  '958': 'Jobs & Education',
  '1204': 'Journalism & News Industry',
  '869': 'Judaism',
  '848': 'Kia',
  '154': 'Kids & Teens',
  '951': 'Kitchen & Dining',
  '800': 'Knowledge Management',
  '1356': 'LCD TVs',
  '701': 'Labor & Employment Law',
  '1120': 'Lakes & Rivers',
  '1064': 'Lamborghini',
  '272': 'Lamps & Lighting',
  '1065': 'Land Rover',
  '108': 'Language Resources',
  '310': 'Laptops & Notebooks',
  '913': 'Latin American Cuisine',
  '591': 'Latin American Music',
  '1285': 'Latin Pop',
  '548': 'Latinos & Latin-Americans',
  '1364': 'Laundry',
  '19': 'Law & Government',
  '535': 'Law Enforcement',
  '641': 'Learning & Developmental Disabilities',
  '410': 'Left-Wing Politics',
  '75': 'Legal',
  '792': 'Legal Education',
  '1137': 'Legal Forms',
  '969': 'Legal Services',
  '964': 'Legislative Branch',
  '849': 'Lexus',
  '375': 'Libraries & Museums',
  '850': 'Lincoln',
  '736': 'Linux & Unix',
  '406': 'Liquor',
  '1184': 'Literary Classics',
  '895': 'Live Comedy',
  '1273': 'Live Sporting Events',
  '752': 'Livestock',
  '1386': 'Lobbying',
  '572': 'Local News',
  '364': 'Lottery & Sweepstakes',
  '1309': 'Loyalty Cards & Programs',
  '1003': 'Luggage & Travel Accessories',
  '696': 'Luxury Goods',
  '552': 'MLM & Business Opportunities',
  '227': 'MP3 & Portable Media Players',
  '735': 'Mac OS',
  '1299': 'Machine Learning & Artificial Intelligence',
  '412': 'Magazines',
  '1150': 'Mail & Package Delivery',
  '1293': 'Major Kitchen Appliances',
  '234': 'Make-Up & Cosmetics',
  '338': 'Management',
  '49': 'Manufacturing',
  '268': 'Maps',
  '1250': 'Marines',
  '665': 'Maritime Transport',
  '83': 'Marketing Services',
  '1133': 'Marriage',
  '516': 'Martial Arts',
  '1101': 'Martial Arts Films',
  '1066': 'Maserati',
  '73': 'Mass Merchants & Department Stores',
  '557': 'Massage Therapy',
  '935': 'Massive Multiplayer',
  '436': 'Mathematics',
  '546': 'Matrimonial Services',
  '1368': 'Mattresses',
  '851': 'Mazda',
  '909': 'Meat & Seafood',
  '1203': 'Media Critics & Watchdogs',
  '1090': 'Media Players',
  '251': 'Medical Devices & Equipment',
  '256': 'Medical Facilities & Services',
  '253': 'Medical Literature & Resources',
  '945': 'Medical Photos & Illustration',
  '635': 'Medical Procedures',
  '943': 'Medical Tests & Exams',
  '914': 'Mediterranean Cuisine',
  '1319': 'Memory Card Readers',
  '992': "Men's Clothing",
  '636': "Men's Health",
  '437': 'Mental Health',
  '852': 'Mercedes-Benz',
  '280': 'Merchant Services & Payment Systems',
  '853': 'Mercury',
  '1241': 'Mergers & Acquisitions',
  '1036': 'Metal (Music)',
  '606': 'Metals & Mining',
  '1381': 'Microblogging',
  '1317': 'Microcars & City Cars',
  '366': 'Military',
  '1288': 'Military History',
  '1067': 'Mini',
  '922': 'Miniatures & Wargaming',
  '854': 'Mitsubishi',
  '382': 'Mobile & Wireless',
  '1171': 'Mobile & Wireless Accessories',
  '1109': 'Mobile Apps & Add-Ons',
  '1382': 'Mobile OS',
  '390': 'Mobile Phones',
  '1353': 'Mobility Equipment & Accessories',
  '180': 'Motor Sports',
  '273': 'Motorcycles',
  '1119': 'Mountain & Ski Resorts',
  '1085': 'Movie Listings & Theater Showtimes',
  '213': 'Movie Memorabilia',
  '1106': 'Movie Reference',
  '1107': 'Movie Reviews & Previews',
  '34': 'Movies',
  '291': 'Moving & Relocation',
  '965': 'Multilateral Organizations',
  '497': 'Multimedia Software',
  '35': 'Music & Audio',
  '929': 'Music & Dance Games',
  '218': 'Music Art & Memorabilia',
  '1113': 'Music Awards',
  '1028': 'Music Composition & Theory',
  '1087': 'Music Education & Instruction',
  '1024': 'Music Equipment & Technology',
  '1026': 'Music Recording Technology',
  '1027': 'Music Reference',
  '220': 'Music Streams & Downloads',
  '1105': 'Musical Films',
  '216': 'Musical Instruments',
  '609': 'Myth & Folklore',
  '829': 'Nails Screws & Fasteners',
  '171': 'Native Americans',
  '1249': 'Navy',
  '347': 'Network Monitoring & Management',
  '344': 'Network Security',
  '729': 'Network Storage',
  '311': 'Networking',
  '346': 'Networking Equipment',
  '942': 'Neurological Disorders',
  '1226': 'Neuroscience',
  '1271': 'New Year',
  '16': 'News',
  '408': 'Newspapers',
  '1043': 'Nintendo',
  '855': 'Nissan',
  '560': 'Non-Alcoholic Beverages',
  '915': 'North American Cuisine',
  '954': 'Nuclear Energy',
  '1372': 'Nursery & Playroom',
  '418': 'Nursing',
  '456': 'Nutrition',
  '558': 'OBGYN',
  '818': 'Obesity',
  '449': 'Occult & Paranormal',
  '644': 'Occupational Health & Safety',
  '148': 'Off-Road Vehicles',
  '33': 'Offbeat',
  '337': 'Office & Facilities Management',
  '333': 'Office Furniture',
  '28': 'Office Services',
  '95': 'Office Supplies',
  '659': 'Oil & Gas',
  '513': 'Olympics',
  '299': 'Online Communities',
  '105': 'Online Games',
  '43': 'Online Goodies',
  '1222': 'Online Image Galleries',
  '582': 'Online Journals & Personal Sites',
  '613': 'Online Media',
  '211': 'Online Video',
  '313': 'Open Source',
  '1185': 'Opera',
  '303': 'Operating Systems',
  '1201': 'Opinion & Commentary',
  '744': 'Optoelectronics & Fiber',
  '245': 'Oral & Dental Care',
  '688': 'Outdoors',
  '993': 'Outerwear',
  '718': 'Outsourcing',
  '228': 'PDAs & Handhelds',
  '290': 'Packaging',
  '1258': 'Pagan & Esoteric Traditions',
  '819': 'Pain Management',
  '786': 'Paintball',
  '1167': 'Painting',
  '1169': 'Paleontology',
  '1262': 'Parasites & Parasitic Diseases',
  '58': 'Parenting',
  '1306': 'Parking',
  '324': 'Party & Holiday Supplies',
  '936': 'Party Games',
  '724': 'Payroll Services',
  '645': 'Pediatrics',
  '14': 'People & Society',
  '1234': 'People Search',
  '23': 'Performing Arts',
  '242': 'Perfumes & Fragrances',
  '1147': 'Personal Aircraft',
  '102': 'Personals',
  '471': 'Pest Control',
  '379': 'Pet Food & Supplies',
  '563': 'Pets',
  '66': 'Pets & Animals',
  '856': 'Peugeot',
  '255': 'Pharmaceuticals & Biotech',
  '248': 'Pharmacy',
  '1093': 'Philosophy',
  '384': 'Phone Service Providers',
  '978': 'Photo & Image Sharing',
  '576': 'Photo & Video Services',
  '275': 'Photo & Video Sharing',
  '577': 'Photo & Video Software',
  '320': 'Photo Rating Sites',
  '439': 'Photographic & Digital Arts',
  '719': 'Physical Asset Management',
  '500': 'Physical Therapy',
  '444': 'Physics',
  '1326': 'Pianos & Keyboards',
  '1296': 'Places of Worship',
  '1355': 'Plasma TVs',
  '674': 'Plastics & Polymers',
  '1153': 'Plumbing',
  '830': 'Plumbing Fixtures & Equipment',
  '809': 'Podcasting',
  '565': 'Poetry',
  '946': 'Poisons & Overdoses',
  '924': 'Poker & Casino Games',
  '1180': 'Political Humor',
  '1202': 'Political Polls & Surveys',
  '396': 'Politics',
  '857': 'Pontiac',
  '1021': 'Pop Music',
  '858': 'Porsche',
  '1127': 'Poverty & Hunger',
  '745': 'Power Supplies',
  '401': 'Pregnancy & Maternity',
  '1346': 'Presentation Software',
  '352': 'Price Comparisons',
  '371': 'Primary & Secondary Schooling (K-12)',
  '494': 'Printers',
  '1330': 'Printers, Copiers & Fax',
  '1176': 'Printing & Publishing',
  '1284': 'Prisons & Corrections',
  '1281': 'Privacy Issues',
  '970': 'Product Liability',
  '353': 'Product Reviews & Price Comparisons',
  '1199': 'Professional & Trade Associations',
  '31': 'Programming',
  '1360': 'Project Management',
  '1359': 'Project Management Software',
  '1357': 'Projection TVs',
  '1334': 'Projectors & Screens',
  '687': 'Property Development',
  '463': 'Property Inspections & Appraisals',
  '425': 'Property Management',
  '902': 'Proxying & Filtering',
  '543': 'Psychology',
  '1161': 'Public Finance',
  '947': 'Public Health',
  '1316': 'Public Policy',
  '1136': 'Public Records',
  '327': 'Public Relations',
  '166': 'Public Safety',
  '1303': 'Public Speaking',
  '1347': 'Public Storage',
  '1041': 'Punk (Music)',
  '937': 'Puzzles & Brainteasers',
  '720': 'Quality Control & Tracking',
  '889': 'Rabbits & Rodents',
  '262': 'Racquet Sports',
  '215': 'Radio',
  '787': 'Radio Control & Modeling',
  '1182': 'Radio Equipment',
  '666': 'Rail Transport',
  '1030': 'Rap & Hip-Hop',
  '29': 'Real Estate',
  '96': 'Real Estate Agencies',
  '1080': 'Real Estate Listings',
  '1114': 'Record Labels',
  '1115': 'Recording Industry',
  '999': 'Recreational Aviation',
  '330': 'Recruitment & Staffing',
  '1307': 'Recycling',
  '533': 'Reference',
  '1031': 'Reggae & Caribbean Music',
  '1242': 'Reggaeton',
  '1007': 'Regional Parks & Gardens',
  '59': 'Religion & Belief',
  '1020': 'Religious Music',
  '859': 'Renault-Samsung',
  '657': 'Renewable & Alternative Energy',
  '195': 'Reproductive Health',
  '976': 'Reproductive Rights',
  '890': 'Reptiles & Amphibians',
  '824': 'Respiratory Conditions',
  '816': 'Restaurant Supply',
  '276': 'Restaurants',
  '961': 'Resumes & Portfolios',
  '844': 'Retail Equipment & Technology',
  '841': 'Retail Trade',
  '619': 'Retirement & Pension',
  '409': 'Right-Wing Politics',
  '532': 'Ringtones & Mobile Goodies',
  '620': 'Risk Management',
  '1141': 'Robotics',
  '590': 'Rock Music',
  '622': 'Roleplaying Games',
  '1068': 'Rolls-Royce',
  '1135': 'Romance',
  '1310': 'Romance Films',
  '1175': 'Roofing',
  '702': 'Royalty',
  '517': 'Rugby',
  '1362': 'Rugs & Carpets',
  '541': 'Running & Walking',
  '1057': 'SUVs',
  '897': 'Saab',
  '1286': 'Salsa & Tropical Music',
  '1301': 'Same-Sex Marriage',
  '1091': 'Samples & Sound Libraries',
  '860': 'Saturn',
  '1259': 'Scandals & Investigations',
  '495': 'Scanners',
  '174': 'Science',
  '676': 'Science Fiction & Fantasy',
  '616': 'Science Fiction & Fantasy Films',
  '445': 'Scientific Equipment',
  '446': 'Scientific Institutions',
  '1251': 'Scientology',
  '1069': 'Scion',
  '1212': 'Scooters & Mopeds',
  '733': 'Scripting Languages',
  '84': 'Search Engine Optimization & Marketing',
  '485': 'Search Engines',
  '705': 'Security Products & Services',
  '870': 'Self-Help & Motivational',
  '298': 'Seniors & Retirement',
  '383': 'Service Providers',
  '536': 'Sex Education & Counseling',
  '1236': 'Sexual Enhancement',
  '421': 'Sexually Transmitted Diseases',
  '892': 'Sheet Music',
  '930': 'Shooter Games',
  '18': 'Shopping',
  '531': 'Shopping Portals & Search Engines',
  '1390': 'Sightseeing Tours',
  '1076': 'Signage',
  '1098': 'Silent Films',
  '931': 'Simulation Games',
  '1126': 'Skate Sports',
  '975': 'Skeptics & Non-Believers',
  '1148': 'Skiing & Snowboarding',
  '93': 'Skin & Nail Care',
  '420': 'Skin Conditions',
  '578': 'Skins Themes & Wallpapers',
  '633': 'Sleep Disorders',
  '994': 'Sleepwear',
  '551': 'Small Business',
  '1292': 'Small Kitchen Appliances',
  '1071': 'Smart Phones',
  '1237': 'Smoking & Smoking Cessation',
  '294': 'Soccer',
  '54': 'Social Issues & Advocacy',
  '847': 'Social Network Apps & Add-Ons',
  '529': 'Social Networks',
  '509': 'Social Sciences',
  '508': 'Social Services',
  '1370': 'Sofas & Chairs',
  '32': 'Software',
  '224': 'Software Utilities',
  '617': 'Song Lyrics & Tabs',
  '1044': 'Sony PlayStation',
  '1039': 'Soul & R&B',
  '740': 'Sound & Video Cards',
  '893': 'Soundtracks',
  '910': 'Soups & Stews',
  '1032': 'South Asian Music',
  '528': 'South Asians & Diaspora',
  '580': 'Southeast Asians & Pacific Islanders',
  '668': 'Space Technology',
  '145': 'Spas & Beauty Services',
  '1158': 'Speakers',
  '457': 'Special & Restricted Diets',
  '1118': 'Special Education',
  '977': 'Special Occasions',
  '1004': 'Specialty Travel',
  '101': 'Spirituality',
  '1244': 'Spoofs & Satire',
  '263': 'Sporting Goods',
  '20': 'Sports',
  '1082': 'Sports Coaching & Training',
  '932': 'Sports Games',
  '1083': 'Sports Memorabilia',
  '1077': 'Sports News',
  '1344': 'Spreadsheet Software',
  '373': 'Standardized & Admissions Tests',
  '966': 'State & Local Government',
  '1252': 'Statistics',
  '91': 'Stereo Systems & Components',
  '1235': 'Steroids & Performance-Enhancing Drugs',
  '574': 'Stock Photography',
  '722': 'Strategic Planning',
  '933': 'Strategy Games',
  '1308': 'Study Abroad',
  '1207': 'Stunts & Dangerous Feats',
  '861': 'Subaru',
  '502': 'Subcultures & Niche Interests',
  '257': 'Substance Abuse',
  '1100': 'Superhero Films',
  '801': 'Supply Chain Management',
  '689': 'Surf & Swim',
  '944': 'Surgery',
  '1070': 'Suzuki',
  '1210': 'Swap Meets & Outdoor Markets',
  '952': 'Swimming Pools & Spas',
  '995': 'Swimwear',
  '428': 'T-Shirts',
  '36': 'TV & Video',
  '229': 'TV & Video Equipment',
  '1047': 'TV Comedies',
  '1055': 'TV Commercials',
  '1111': 'TV Crime & Legal Shows',
  '1193': 'TV Dramas',
  '1110': 'TV Family-Oriented Shows',
  '1050': 'TV Game Shows',
  '1187': 'TV Guides & Reference',
  '1194': 'TV Medical Shows',
  '359': 'TV Networks & Stations',
  '1049': 'TV Reality Shows',
  '1112': 'TV Sci-Fi & Fantasy Shows',
  '358': 'TV Shows & Programs',
  '357': 'TV Soap Operas',
  '1048': 'TV Talk Shows',
  '938': 'Table Games',
  '940': 'Table Tennis',
  '1277': 'Tablet PCs',
  '1186': 'Talk Radio',
  '1283': 'Tax Preparation & Planning',
  '700': 'Teaching & Classroom Resources',
  '1001': 'Team Sports',
  '1233': 'Technical Reference',
  '567': 'Technical Support',
  '785': 'Technology News',
  '680': 'Teen Interests',
  '392': 'Teleconferencing',
  '328': 'Telemarketing',
  '305': 'Televisions',
  '1376': 'Tennis',
  '746': 'Test & Measurement',
  '1379': 'Text & Instant Messaging',
  '566': 'Textiles & Nonwovens',
  '1125': 'Thanksgiving',
  '1008': 'Theme Parks',
  '1340': 'Theology & Religious Study',
  '1096': 'Thriller, Crime & Mystery Films',
  '1329': 'Thyroid Conditions',
  '614': 'Ticket Sales',
  '695': 'Time & Calendars',
  '1081': 'Timeshares & Vacation Properties',
  '123': 'Tobacco Products',
  '1392': 'Tourist Boards & Visitor Centers',
  '208': 'Tourist Destinations',
  '863': 'Toyota',
  '432': 'Toys',
  '518': 'Track & Field',
  '335': 'Trade Shows & Conventions',
  '685': 'Traffic & Public Transit',
  '1388': 'Training & Certification',
  '1265': 'Translation Tools & Resources',
  '50': 'Transportation & Logistics',
  '67': 'Travel',
  '1010': 'Travel Agencies & Services',
  '1011': 'Travel Guides & Travelogues',
  '1260': 'Troubled Relationships',
  '1056': 'Trucks',
  '610': 'Trucks & SUVs',
  '530': 'Undergarments',
  '996': 'Uniforms & Workwear',
  '1121': 'Unions & Labor Movement',
  '144': 'Unwanted Body & Facial Hair Removal',
  '592': 'Urban & Hip-Hop',
  '686': 'Urban & Regional Planning',
  '667': 'Urban Transport',
  '1279': 'VPN & Remote Access',
  '1019': 'Vacation Offers',
  '1263': 'Vaccines & Immunizations',
  '1122': "Valentine's Day",
  '839': 'Valves Hoses & Fittings',
  '1058': 'Vans & Minivans',
  '898': 'Vauxhall-Opel',
  '825': 'Vegetarian Cuisine',
  '815': 'Vehicle Brands',
  '1294': 'Vehicle Codes & Driving Laws',
  '1269': 'Vehicle Fuels & Lubricants',
  '170': 'Vehicle Licensing & Registration',
  '138': 'Vehicle Maintenance',
  '89': 'Vehicle Parts & Accessories',
  '473': 'Vehicle Shopping',
  '803': 'Vehicle Shows',
  '1267': 'Vehicle Specs, Reviews & Comparisons',
  '438': 'Vehicle Wheels & Tires',
  '905': 'Venture Capital',
  '793': 'Veterans',
  '380': 'Veterinarians',
  '1315': 'Video File Formats & Codecs',
  '1342': 'Video Game Emulation',
  '1146': 'Video Game Retailers',
  '492': 'Video Players & Recorders',
  '979': 'Video Sharing',
  '1391': 'Vineyards & Wine Tourism',
  '972': 'Virtual Worlds',
  '555': 'Visa & Immigration',
  '246': 'Vision Care',
  '24': 'Visual Art & Design',
  '237': 'Vitamins & Supplements',
  '618': 'Vocals & Show Tunes',
  '369': 'Vocational & Continuing Education',
  '386': 'Voice & Video Chat',
  '865': 'Volkswagen',
  '699': 'Volleyball',
  '867': 'Volvo',
  '451': 'Warranties & Service Contracts',
  '660': 'Waste Management',
  '987': 'Watches',
  '441': 'Water & Marine Sciences',
  '1002': 'Water Activities',
  '1371': 'Water Filters & Purifiers',
  '118': 'Water Sports',
  '1349': 'Water Supply & Treatment',
  '63': 'Weather',
  '1142': 'Web Apps & Online Tools',
  '422': 'Web Design & Development',
  '53': 'Web Hosting & Domain Registration',
  '301': 'Web Portals',
  '302': 'Web Services',
  '675': 'Web Stats & Analytics',
  '575': 'Webcams & Virtual Tours',
  '293': 'Weddings',
  '236': 'Weight Loss',
  '706': 'Welfare & Unemployment',
  '683': 'Western Europeans',
  '1099': 'Western Films',
  '1225': 'Wholesalers & Liquidators',
  '119': 'Wildlife',
  '734': 'Windows & .NET',
  '737': 'Windows OS',
  '405': 'Wine',
  '265': 'Winter Sports',
  '997': "Women's Clothing",
  '648': "Women's Health",
  '831': 'Wood & Plastics',
  '1345': 'Word Processing Software',
  '703': 'Work & Labor Issues',
  '911': 'World Cuisines',
  '593': 'World Music',
  '1209': 'World News',
  '1198': 'World Sports Competitions',
  '512': 'Wrestling',
  '1177': 'Writers Resources',
  '725': 'Writing & Editing Services',
  '1045': 'Xbox',
  '953': 'Yard & Patio',
  '611': 'Yoga & Pilates',
  '402': 'Youth Camps',
  '1009': 'Zoos-Aquariums-Preserves',
};



export const googleTrendsGeoLocations = {
  '': 'Worldwide',
  AF: 'Afghanistan',
  'AF-BDS': 'Badakhshan',
  'AF-BDG': 'Badghis',
  'AF-BGL': 'Baghlan',
  'AF-BAL': 'Balkh',
  'AF-BAM': 'Bamiyan',
  'AF-DAY': 'Daykundi',
  'AF-FRA': 'Farah',
  'AF-FYB': 'Faryab',
  'AF-GHA': 'Ghazni',
  'AF-GHO': 'Ghor',
  'AF-HEL': 'Helmand',
  'AF-HER': 'Herat',
  'AF-JOW': 'Jowzjan',
  'AF-KAB': 'Kabul',
  'AF-KAN': 'Kandahar',
  'AF-KAP': 'Kapisa',
  'AF-KHO': 'Khost',
  'AF-KNR': 'Kunar',
  'AF-KDZ': 'Kunduz',
  'AF-LAG': 'Laghman',
  'AF-LOG': 'Logar',
  'AF-NAN': 'Nangarhar',
  'AF-NIM': 'Nimruz',
  'AF-NUR': 'Nuristan',
  'AF-URU': 'Oruzgan',
  'AF-PIA': 'Paktia',
  'AF-PKA': 'Paktika',
  'AF-PAN': 'Panjshir',
  'AF-PAR': 'Parvan',
  'AF-SAM': 'Samangan',
  'AF-SAR': 'Sare Pol',
  'AF-TAK': 'Takhar',
  'AF-WAR': 'Wardak',
  'AF-ZAB': 'Zabul',
  AX: 'Åland Islands',
  AL: 'Albania',
  'AL-01': 'Berat',
  'AL-09': 'Dibër County',
  'AL-02': 'Durrës',
  'AL-03': 'Elbasan',
  'AL-04': 'Fier',
  'AL-05': 'Gjirokastra',
  'AL-06': 'Korca',
  'AL-07': 'Kukës County',
  'AL-08': 'Lezhë County',
  'AL-10': 'Shkodër County',
  'AL-11': 'Tirana',
  'AL-12': 'Vlora',
  DZ: 'Algeria',
  'DZ-01': 'Adrar Province',
  'DZ-44': 'Aïn Defla Province',
  'DZ-46': 'Aïn Témouchent Province',
  'DZ-16': 'Algiers Province',
  'DZ-23': 'Annaba Province',
  'DZ-05': 'Batna Province',
  'DZ-08': 'Béchar',
  'DZ-06': 'Béjaïa',
  'DZ-07': 'Biskra',
  'DZ-09': 'Blida Province',
  'DZ-34': 'Bordj Bou Arreridj',
  'DZ-10': 'Bouïra Province',
  'DZ-35': 'Boumerdès Province',
  'DZ-02': 'Chlef Province',
  'DZ-25': 'Constantine',
  'DZ-17': 'Djelfa Province',
  'DZ-32': 'El Bayadh Province',
  'DZ-39': 'El Oued Province',
  'DZ-36': 'El Taref Province',
  'DZ-47': 'Ghardaia Province',
  'DZ-24': 'Guelma Province',
  'DZ-33': 'Illizi Province',
  'DZ-18': 'Jijel Province',
  'DZ-40': 'Khenchela Province',
  'DZ-03': 'Laghouat Province',
  'DZ-28': "M'Sila Province",
  'DZ-29': 'Mascara Province',
  'DZ-26': 'Médéa Province',
  'DZ-43': 'Mila Province',
  'DZ-27': 'Mostaganem Province',
  'DZ-45': 'Naâma Province',
  'DZ-31': 'Oran Province',
  'DZ-30': 'Ouargla Province',
  'DZ-04': 'Oum El Bouaghi Province',
  'DZ-11': 'Province of Tamanrasset',
  'DZ-48': 'Relizane Province',
  'DZ-20': 'Saïda Province',
  'DZ-19': 'Setif',
  'DZ-22': 'Sidi-Bel-Abbes',
  'DZ-21': 'Skikda Province',
  'DZ-41': 'Souk Ahras Province',
  'DZ-12': 'Tebessa',
  'DZ-14': 'Tiaret Province',
  'DZ-37': 'Tindouf Province',
  'DZ-42': 'Tipaza Province',
  'DZ-38': 'Tissemsilt',
  'DZ-15': 'Tizi Ouzou Province',
  'DZ-13': 'Tlemcen Province',
  AS: 'American Samoa',
  AD: 'Andorra',
  'AD-07': 'Andorra la Vella',
  'AD-02': 'Canillo',
  'AD-03': 'Encamp',
  'AD-08': 'Escaldes-Engordany',
  'AD-04': 'La Massana',
  'AD-05': 'Ordino',
  'AD-06': 'Sant Julià de Lòria',
  AO: 'Angola',
  'AO-BGO': 'Bengo Province',
  'AO-BGU': 'Benguela Province',
  'AO-BIE': 'Bié Province',
  'AO-CAB': 'Cabinda Province',
  'AO-CCU': 'Cuando Cubango Province',
  'AO-CNO': 'Cuanza Norte Province',
  'AO-CUS': 'Cuanza Sul',
  'AO-CNN': 'Cunene',
  'AO-HUA': 'Huambo Province',
  'AO-HUI': 'Huila Province',
  'AO-LUA': 'Luanda Province',
  'AO-LNO': 'Lunda Norte',
  'AO-LSU': 'Lunda Sul Province',
  'AO-MAL': 'Malanje Province',
  'AO-MOX': 'Moxico Province',
  'AO-NAM': 'Namibe Province',
  'AO-UIG': 'Uíge Province',
  'AO-ZAI': 'Zaire Province',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua & Barbuda',
  'AG-10': 'Barbuda',
  'AG-11': 'Redonda',
  'VC-04': 'Saint George',
  'GD-04': 'Saint John',
  'AG-05': 'Saint Mary',
  'AG-06': 'Saint Paul',
  'BB-09': 'Saint Peter',
  'BB-10': 'Saint Philip',
  AR: 'Argentina',
  'AR-C': 'Autonomous City of Buenos Aires',
  'AR-B': 'Buenos Aires Province',
  'AR-K': 'Catamarca Province',
  'AR-H': 'Chaco Province',
  'AR-U': 'Chubut Province',
  'CO-COR': 'Cordoba',
  'AR-W': 'Corrientes Province',
  'AR-E': 'Entre Rios',
  'AR-P': 'Formosa Province',
  'AR-Y': 'Jujuy',
  'AR-L': 'La Pampa Province',
  'AR-F': 'La Rioja Province',
  'AR-M': 'Mendoza Province',
  'AR-N': 'Misiones Province',
  'AR-Q': 'Neuquen',
  'AR-R': 'Río Negro',
  'AR-A': 'Salta Province',
  'DO-22': 'San Juan Province',
  'AR-D': 'San Luis Province',
  'AR-Z': 'Santa Cruz Province',
  'AR-S': 'Santa Fe Province',
  'AR-G': 'Santiago del Estero Province',
  'AR-V': 'Tierra del Fuego Province',
  'AR-T': 'Tucumán',
  AM: 'Armenia',
  'AM-AG': 'Aragatsotn Province',
  'AM-AR': 'Ararat Province',
  'AM-AV': 'Armavir Province',
  'AM-GR': 'Gegharkunik Province',
  'AM-KT': 'Kotayk Province',
  'AM-LO': 'Lori Province',
  'AM-SH': 'Shirak Province',
  'AM-SU': 'Syunik Province',
  'AM-TV': 'Tavush Province',
  'AM-VD': 'Vayots Dzor Province',
  'AM-ER': 'Yerevan',
  AW: 'Aruba',
  AU: 'Australia',
  'AU-ACT': 'Australian Capital Territory',
  'AU-NSW': 'New South Wales',
  'AU-NT': 'Northern Territory',
  'AU-QLD': 'Queensland',
  'AU-SA': 'South Australia',
  'AU-TAS': 'Tasmania',
  'AU-VIC': 'Victoria',
  'AU-WA': 'Western Australia',
  AT: 'Austria',
  'AT-1': 'Burgenland',
  'AT-2': 'Carinthia',
  'AT-3': 'Lower Austria',
  'AT-5': 'Salzburg',
  'AT-6': 'Styria',
  'AT-7': 'Tyrol',
  'AT-4': 'Upper Austria',
  'AT-9': 'Vienna',
  'AT-8': 'Vorarlberg',
  AZ: 'Azerbaijan',
  'AZ-NX': 'Nakhchivan Autonomous Republic',
  BS: 'Bahamas',
  'BS-AK': 'Acklins',
  'BS-BY': 'Berry Islands',
  'BS-BI': 'Bimini',
  'BS-BP': 'Black Point',
  'BS-CI': 'Cat Island',
  'BS-CO': 'Central Abaco',
  'BS-CS': 'Central Andros',
  'BS-CE': 'Central Eleuthera',
  'BS-CK': 'Crooked Island and Long Cay',
  'BS-EG': 'East Grand Bahama',
  'BS-EX': 'Exuma',
  'BS-FP': 'Freeport',
  'BS-GC': 'Grand Cay',
  'BS-HI': 'Harbour Island',
  'BS-HT': 'Hope Town',
  'BS-IN': 'Inagua',
  'BS-LI': 'Long Island',
  'BS-MC': 'Mangrove Cay',
  'BS-MG': 'Mayaguana',
  'BS-MI': "Moore's Island",
  'BS-NO': 'North Abaco',
  'BS-NS': 'North Andros',
  'BS-NE': 'North Eleuthera',
  'BS-RI': 'Ragged Island',
  'BS-RC': 'Rum Cay',
  'SV-SS': 'San Salvador',
  'BS-SO': 'South Abaco',
  'BS-SA': 'South Andros',
  'BS-SE': 'South Eleuthera',
  'BS-SW': 'Spanish Wells',
  'BS-WG': 'West Grand Bahama',
  BH: 'Bahrain',
  'BH-13': 'Capital Governorate',
  'BH-16': 'Central Governorate',
  'BH-15': 'Muharraq Governorate',
  'BH-17': 'Northern Governorate',
  'BH-14': 'Southern Governorate',
  BD: 'Bangladesh',
  'BD-A': 'Barisal Division',
  'BD-B': 'Chittagong Division',
  'BD-C': 'Dhaka Division',
  'BD-D': 'Khulna Division',
  'BD-E': 'Rajshahi Division',
  'BD-F': 'Rangpur Division',
  'BD-G': 'Sylhet Division',
  BB: 'Barbados',
  'BB-01': 'Christ Church',
  'VC-02': 'Saint Andrew',
  'BB-04': 'Saint James',
  'BB-06': 'Saint Joseph',
  'BB-07': 'Saint Lucy',
  'BB-08': 'Saint Michael',
  'JM-03': 'Saint Thomas Parish',
  BY: 'Belarus',
  'BY-BR': 'Brest Region',
  'BY-HO': 'Gomel Region',
  'BY-HR': 'Hrodna Region',
  'BY-MI': 'Minsk Region',
  'BY-MA': 'Mogilev Region',
  'BY-VI': 'Vitebsk Region',
  BE: 'Belgium',
  'BE-BRU': 'Brussels',
  'BE-VLG': 'Flanders',
  'BE-WAL': 'Walloon Region',
  'BZ-BZ': 'Belize',
  'BZ-CY': 'Cayo',
  'BZ-CZL': 'Corozal',
  'BZ-OW': 'Orange Walk',
  'BZ-SC': 'Stann Creek',
  'BZ-TOL': 'Toledo',
  BJ: 'Benin',
  'BJ-AL': 'Alibori',
  'BJ-AK': 'Atakora',
  'BJ-AQ': 'Atlantique',
  'BJ-BO': 'Borgou',
  'BJ-CO': 'Collines',
  'BJ-DO': 'Donga',
  'BJ-KO': 'Kouffo',
  'CM-LT': 'Littoral',
  'BJ-MO': 'Mono',
  'BJ-OU': 'Oueme',
  'NG-PL': 'Plateau',
  'BJ-ZO': 'Zou',
  BM: 'Bermuda',
  BT: 'Bhutan',
  'BT-33': 'Bumthang',
  'BT-12': 'Chhukha',
  'BT-22': 'Dagana',
  'BT-GA': 'Gasa',
  'BT-13': 'Haa',
  'BT-44': 'Lhuntse',
  'BT-42': 'Mongar',
  'BT-11': 'Paro',
  'BT-43': 'Pemagatshel',
  'BT-23': 'Punakha',
  'BT-45': 'Samdrup Jongkhar',
  'BT-14': 'Samtse',
  'BT-31': 'Sarpang',
  'BT-15': 'Thimphu',
  'BT-41': 'Trashigang',
  'BT-TY': 'Trashiyangtse',
  'BT-32': 'Trongsa',
  'BT-21': 'Tsirang',
  'BT-24': 'Wangdue Phodrang',
  'BT-34': 'Zhemgang',
  BO: 'Bolivia',
  'BO-B': 'Beni Department',
  'BO-H': 'Chuquisaca Department',
  'BO-C': 'Cochabamba Department',
  'BO-L': 'La Paz Department',
  'BO-O': 'Oruro Department',
  'BO-N': 'Pando Department',
  'BO-P': 'Potosi Department',
  'CV-CR': 'Santa Cruz',
  'BO-T': 'Tarija Department',
  BA: 'Bosnia & Herzegovina',
  'BA-BRC': 'Brčko Distrikt',
  'BA-BIH': 'Federation of Bosnia and Herzegovina',
  'BA-SRP': 'Republika Srpska',
  BW: 'Botswana',
  'ZM-02': 'Central',
  'BW-GH': 'Ghanzi',
  'BW-KG': 'Kgalagadi',
  'BW-KL': 'Kgatleng',
  'BW-KW': 'Kweneng',
  'ZA-NW': 'North West',
  'BW-NE': 'North-East',
  'BW-SE': 'South-East',
  'ZM-07': 'Southern',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  'BR-DF': 'Federal District',
  'BR-AC': 'State of Acre',
  'BR-AL': 'State of Alagoas',
  'BR-AP': 'State of Amapá',
  'BR-AM': 'State of Amazonas',
  'BR-BA': 'State of Bahia',
  'BR-CE': 'State of Ceará',
  'BR-ES': 'State of Espírito Santo',
  'BR-GO': 'State of Goiás',
  'BR-MA': 'State of Maranhão',
  'BR-MT': 'State of Mato Grosso',
  'BR-MS': 'State of Mato Grosso do Sul',
  'BR-MG': 'State of Minas Gerais',
  'BR-PA': 'State of Pará',
  'BR-PB': 'State of Paraíba',
  'BR-PR': 'State of Paraná',
  'BR-PE': 'State of Pernambuco',
  'BR-PI': 'State of Piauí',
  'BR-RJ': 'State of Rio de Janeiro',
  'BR-RN': 'State of Rio Grande do Norte',
  'BR-RS': 'State of Rio Grande do Sul',
  'BR-RO': 'State of Rondônia',
  'BR-RR': 'State of Roraima',
  'BR-SC': 'State of Santa Catarina',
  'BR-SP': 'State of São Paulo',
  'BR-SE': 'State of Sergipe',
  'BR-TO': 'State of Tocantins',
  IO: 'British Indian Ocean Territory',
  VG: 'British Virgin Islands',
  BN: 'Brunei',
  'BN-BE': 'Belait',
  'BN-BM': 'Brunei-Muara',
  'BN-TE': 'Temburong',
  'BN-TU': 'Tutong',
  BG: 'Bulgaria',
  'BG-01': 'Blagoevgrad Province',
  'BG-02': 'Burgas',
  'BG-08': 'Dobrich Province',
  'BG-07': 'Gabrovo',
  'BG-26': 'Haskovo Province',
  'BG-28': 'Jambol',
  'BG-09': 'Kardzhali Province',
  'BG-10': 'Kyustendil Province',
  'BG-11': 'Lovec',
  'BG-12': 'Montana Province',
  'BG-13': 'Pazardzik',
  'BG-14': 'Pernik',
  'BG-15': 'Pleven Province',
  'BG-16': 'Plovdiv Province',
  'BG-17': 'Razgrad',
  'BG-18': 'Ruse',
  'BG-27': 'Shumen Province',
  'BG-19': 'Silistra',
  'BG-20': 'Sliven Province',
  'BG-21': 'Smoljan',
  'BG-22': 'Sofia City Province',
  'BG-23': 'Sofia Province',
  'BG-24': 'Stara Zagora',
  'BG-25': 'Targovishte Province',
  'BG-03': 'Varna',
  'BG-04': 'Veliko Tarnovo Province',
  'BG-05': 'Vidin',
  'BG-06': 'Vraca',
  BF: 'Burkina Faso',
  'BF-01': 'Boucle du Mouhoun',
  'BF-02': 'Cascades',
  'HT-CE': 'Centre',
  'BF-04': 'Centre Est',
  'BF-05': 'Centre-Nord',
  'BF-06': 'Centre-Ouest',
  'BF-07': 'Centre-Sud',
  'BF-08': 'Est',
  'BF-09': 'Hauts-Bassins',
  'HT-ND': 'Nord',
  'BF-11': 'Plateau-Central Region',
  'BF-12': 'Sahel',
  'BF-13': 'Sud-Ouest',
  BI: 'Burundi',
  'BI-BB': 'Bubanza',
  'BI-BM': 'Bujumbura Mairie',
  'BI-BL': 'Bujumbura Rural',
  'BI-BR': 'Bururi',
  'BI-CA': 'Cankuzo',
  'BI-CI': 'Cibitoke',
  'BI-GI': 'Gitega',
  'BI-KR': 'Karuzi',
  'BI-KY': 'Kayanza',
  'BI-KI': 'Kirundo',
  'BI-MA': 'Makamba',
  'BI-MU': 'Muramvya',
  'BI-MY': 'Muyinga',
  'BI-MW': 'Mwaro',
  'BI-NG': 'Ngozi',
  'BI-RT': 'Rutana',
  'BI-RY': 'Ruyigi',
  KH: 'Cambodia',
  'KH-1': 'Banteay Meanchey',
  'KH-2': 'Battambang',
  'KH-3': 'Kampong Cham',
  'KH-4': 'Kampong Chhnang',
  'KH-5': 'Kampong Speu​',
  'KH-6': 'Kampong Thom',
  'KH-7': 'Kampot',
  'KH-8': 'Kandal',
  'KH-23': 'Kep',
  'KH-9': 'Koh Kong',
  'KH-10': 'Kratie',
  'KH-11': 'Mondulkiri',
  'KH-22': 'Oddar Meancheay',
  'KH-24': 'Pailin',
  'KH-12': 'Phnom Penh',
  'KH-18': 'Preah Sihanouk',
  'KH-13': 'Preah Vihear',
  'KH-14': 'Prey Veng',
  'KH-15': 'Pursat',
  'KH-16': 'Ratanakiri',
  'KH-17': 'Siem Reap',
  'KH-19': 'Stung Treng',
  'KH-20': 'Svay Rieng',
  'KH-21': 'Takeo',
  'KH-25': 'Tbong Khmum',
  CM: 'Cameroon',
  'CM-AD': 'Adamawa Region',
  'CM-ES': 'East',
  'CM-EN': 'Extreme North',
  'LB-AS': 'North',
  'CM-NW': 'Northwest',
  'LB-JA': 'South',
  'CM-SW': 'Southwest',
  'CM-OU': 'West',
  CA: 'Canada',
  'CA-AB': 'Alberta',
  'CA-BC': 'British Columbia',
  'CA-MB': 'Manitoba',
  'CA-NB': 'New Brunswick',
  'CA-NL': 'Newfoundland and Labrador',
  'CA-NT': 'Northwest Territories',
  'CA-NS': 'Nova Scotia',
  'CA-NU': 'Nunavut',
  'CA-ON': 'Ontario',
  'CA-PE': 'Prince Edward Island',
  'CA-QC': 'Québec',
  'CA-SK': 'Saskatchewan',
  'CA-YT': 'Yukon Territory',
  CV: 'Cape Verde',
  'CV-BV': 'Boa Vista',
  'CV-BR': 'Brava',
  'CV-MA': 'Maio',
  'CV-MO': 'Mosteiros',
  'CV-PA': 'Paul',
  'CV-PN': 'Porto Novo',
  'CV-PR': 'Praia',
  'CV-RB': 'Ribeira Brava',
  'CV-RG': 'Ribeira Grande',
  'CV-RS': 'Ribeira Grande de Santiago',
  'CV-SL': 'Sal',
  'CV-CA': 'Santa Catarina',
  'CV-CF': 'Santa Catarina do Fogo',
  'CV-SD': 'Sao Domingos',
  'CV-SF': 'Sao Filipe',
  'CV-SO': 'Sao Lourenco dos Orgaos',
  'CV-SM': 'Sao Miguel',
  'CV-SS': 'Sao Salvador do Mundo',
  'CV-SV': 'Sao Vicente',
  'CV-TA': 'Tarrafal',
  'CV-TS': 'Tarrafal de Sao Nicolau',
  BQ: 'Caribbean Netherlands',
  'BQ-BO': 'Bonaire',
  'BQ-SA': 'Saba',
  'BQ-SE': 'Sint Eustatius',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  'CF-BB': 'Bamingui-Bangoran',
  'CF-BGF': 'Bangui',
  'CF-BK': 'Basse-Kotto',
  'CF-HM': 'Haut-Mbomou',
  'CF-HK': 'Haute-Kotto',
  'CF-KG': 'Kemo',
  'CF-LB': 'Lobaye',
  'CF-HS': 'Mambere-Kadei',
  'CF-MB': 'Mbomou',
  'CF-KB': 'Nana-Grebizi',
  'CF-NM': 'Nana-Mambéré',
  'CF-MP': "Ombella-M'Poko",
  'CF-UK': 'Ouaka',
  'CF-AC': 'Ouham',
  'CF-OP': 'Ouham-Pendé',
  'CF-SE': 'Sangha-Mbare',
  'CF-VK': 'Vakaga',
  TD: 'Chad',
  'TD-BG': 'Bahr El Gazel',
  'TD-BA': 'Batha',
  'TD-BO': 'Borkou',
  'TD-CB': 'Chari-Baguirmi',
  'TD-EN': 'Ennedi',
  'TD-GR': 'Guera',
  'TD-HL': 'Hadjer-Lamis',
  'TD-KA': 'Kanem',
  'TD-LC': 'Lac',
  'TD-LO': 'Logone Occidental',
  'TD-LR': 'Logone Oriental',
  'TD-MA': 'Mandoul',
  'TD-ME': 'Mayo-Kebbi Est',
  'TD-MO': 'Mayo-Kebbi Ouest',
  'TD-MC': 'Moyen-Chari',
  'TD-ND': "N'Djamena",
  'TD-OD': 'Ouaddai',
  'TD-SI': 'Sila',
  'TD-TA': 'Tandjile',
  'TD-TI': 'Tibesti',
  'TD-WF': 'Wadi Fira',
  'TD-SA': 'سلمات',
  CL: 'Chile',
  'CL-AN': 'Antofagasta Region',
  'CL-AR': 'Araucania',
  'CL-AP': 'Arica y Parinacota Region',
  'CL-AT': 'Atacama Region',
  'CL-BI': 'Bío Bío Region',
  'CL-CO': 'Coquimbo Region',
  'CL-LL': 'Los Lagos Region',
  'CL-LR': 'Los Ríos Region',
  'CL-MA': 'Magallanes y la Antártica Chilena Region',
  'CL-ML': 'Maule Region',
  'CL-LI': "O'Higgins Region",
  'CL-RM': 'Santiago Metropolitan Region',
  'CL-TA': 'Tarapacá Region',
  'CL-VS': 'Valparaiso Region',
  'CL-AI': 'XI Región',
  CN: 'China',
  'CN-34': 'Anhui',
  'CN-11': 'Beijing',
  'CN-50': 'Chongqing',
  'CN-35': 'Fujian',
  'CN-62': 'Gansu',
  'CN-44': 'Guangdong',
  'CN-45': 'Guangxi',
  'CN-52': 'Guizhou',
  'CN-46': 'Hainan',
  'CN-13': 'Hebei',
  'CN-23': 'Heilongjiang',
  'CN-41': 'Henan',
  'CN-42': 'Hubei',
  'CN-43': 'Hunan',
  'CN-15': 'Inner Mongolia',
  'CN-32': 'Jiangsu',
  'CN-36': 'Jiangxi',
  'CN-22': 'Jilin',
  'CN-21': 'Liaoning',
  'CN-64': 'Ningxia',
  'CN-63': 'Qinghai',
  'CN-61': 'Shaanxi',
  'CN-37': 'Shandong',
  'CN-31': 'Shanghai',
  'CN-14': 'Shanxi',
  'CN-51': 'Sichuan',
  'CN-12': 'Tianjin',
  'CN-54': 'Tibet',
  'CN-65': 'Xinjiang',
  'CN-53': 'Yunnan',
  'CN-33': 'Zhejiang',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  'CO-AMA': 'Amazonas Department',
  'CO-ANT': 'Antioquia',
  'CO-ARA': 'Arauca',
  'CO-ATL': 'Atlantico',
  'CO-DC': 'Bogota',
  'CO-BOL': 'Bolivar',
  'CO-BOY': 'Boyaca',
  'CO-CAL': 'Caldas',
  'CO-CAQ': 'Caquetá',
  'CO-CAS': 'Casanare',
  'CO-CAU': 'Cauca Department',
  'CO-CES': 'Cesar',
  'CO-CHO': 'Choco',
  'CO-CUN': 'Cundinamarca',
  'CO-GUA': 'Guainia',
  'CO-GUV': 'Guaviare',
  'CO-HUI': 'Huila',
  'CO-LAG': 'La Guajira',
  'CO-MAG': 'Magdalena',
  'CO-MET': 'Meta',
  'CO-NAR': 'Narino',
  'CO-NSA': 'North Santander',
  'CO-PUT': 'Putumayo',
  'CO-QUI': 'Quindio',
  'CO-RIS': 'Risaralda',
  'CO-SAP': 'San Andrés and Providencia',
  'CO-SAN': 'Santander Department',
  'VE-R': 'Sucre',
  'CO-TOL': 'Tolima',
  'CO-VAC': 'Valle del Cauca',
  'CO-VAU': 'Vaupes',
  'CO-VID': 'Vichada',
  KM: 'Comoros',
  'KM-M': 'Mwali',
  'KM-G': 'Ngazidja',
  'KM-A': 'Nzwani',
  CG: 'Congo - Brazzaville',
  'CG-11': 'Bouenza',
  'CG-BZV': 'Brazzaville',
  'CG-8': 'Cuvette',
  'CG-15': 'Cuvette-Ouest',
  'CG-5': 'Kouilou',
  'CG-2': 'Lekoumou',
  'CG-7': 'Likouala',
  'CG-9': 'Niari',
  'TG-P': 'Plateaux',
  'CG-16': 'Pointe Noire',
  'CG-12': 'Pool',
  'CG-13': 'Sangha',
  CD: 'Congo - Kinshasa',
  'CD-BN': 'Bandundu',
  'CD-BC': 'Bas Congo',
  'CD-EQ': 'Equator',
  'CD-KW': 'Kasai Occidental',
  'CD-KE': 'Kasai Oriental',
  'CD-KA': 'Katanga',
  'CD-KN': 'Kinshasa',
  'CD-MA': 'Maniema',
  'CD-NK': 'North-Kivu',
  'CD-OR': 'Orientale',
  'CD-SK': 'South-Kivu',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  'CR-A': 'Alajuela',
  'CR-G': 'Guanacaste Province',
  'CR-H': 'Heredia Province',
  'CR-L': 'Limón Province',
  'CR-C': 'Provincia de Cartago',
  'CR-P': 'Puntarenas Province',
  'CR-SJ': 'San José Province',
  CI: 'Côte d’Ivoire',
  'CI-16': 'Agnebi',
  'CI-17': 'Bafing',
  'CI-09': 'Bas-Sassandra',
  'CI-10': 'Denguele',
  'CI-06': 'Dix-Huit Montagnes',
  'CI-18': 'Fromager',
  'CI-02': 'Haut-Sassandra',
  'CI-07': 'Lacs',
  'CI-01': 'Lagunes',
  'CI-12': 'Marahoue',
  'CI-19': 'Moyen-Cavally',
  'CI-05': 'Moyen-Comoe',
  'CI-11': 'Nzi-Comoe',
  'TG-S': 'Savanes',
  'CI-15': 'Sud-Bandama',
  'CI-13': 'Sud-Comoe',
  'CI-04': 'Vallee du Bandama',
  'CI-14': 'Worodougou',
  'CI-08': 'Zanzan',
  HR: 'Croatia',
  'HR-07': 'Bjelovar-Bilogora County',
  'HR-12': 'Brod-Posavina County',
  'HR-21': 'City of Zagreb',
  'HR-19': 'Dubrovnik-Neretva County',
  'HR-18': 'Istria County',
  'HR-04': 'Karlovac County',
  'HR-06': 'Koprivnica-Križevci County',
  'HR-02': 'Krapina-Zagorje County',
  'HR-09': 'Lika-Senj County',
  'HR-20': 'Međimurje County',
  'HR-14': 'Osijek-Baranja County',
  'HR-11': 'Požega-Slavonia County',
  'HR-08': 'Primorje-Gorski Kotar County',
  'HR-15': 'Šibenik-Knin County',
  'HR-03': 'Sisak-Moslavina County',
  'HR-17': 'Split-Dalmatia County',
  'HR-05': 'Varaždin County',
  'HR-10': 'Virovitica-Podravina County',
  'HR-16': 'Vukovar-Srijem County',
  'HR-13': 'Zadar County',
  'HR-01': 'Zagreb County',
  CU: 'Cuba',
  'CU-15': 'Artemisa Province',
  'CU-09': 'Camaguey',
  'CU-08': 'Ciego de Avila',
  'CU-06': 'Cienfuegos',
  'CU-12': 'Granma',
  'CU-14': 'Guantanamo',
  'CU-03': 'Havana',
  'CU-11': 'Holguin',
  'CU-99': 'Isla de la Juventud',
  'CU-10': 'Las Tunas',
  'CU-04': 'Matanzas',
  'CU-16': 'Mayabeque',
  'CU-01': 'Pinar del Rio',
  'CU-07': 'Sancti Spiritus',
  'CU-13': 'Santiago de Cuba',
  'CU-05': 'Villa Clara',
  CW: 'Curaçao',
  CY: 'Cyprus',
  'CY-04': 'Famagusta',
  'CY-06': 'Girne',
  'CY-03': 'Larnaca',
  'CY-02': 'Limassol',
  'CY-01': 'Nicosia District',
  'CY-05': 'Paphos',
  CZ: 'Czechia',
  'CZ-ST': 'Central Bohemian Region',
  'CZ-KR': 'Hradec Králové Region',
  'CZ-KA': 'Karlovy Vary Region',
  'CZ-LI': 'Liberec Region',
  'CZ-MO': 'Moravian-Silesian Region',
  'CZ-OL': 'Olomouc Region',
  'CZ-PA': 'Pardubice Region',
  'CZ-PL': 'Plzeň Region',
  'CZ-PR': 'Prague',
  'CZ-JC': 'South Bohemian Region',
  'CZ-JM': 'South Moravian Region',
  'CZ-US': 'Ústí nad Labem Region',
  'CZ-VY': 'Vysocina Region',
  'CZ-ZL': 'Zlin Region',
  DK: 'Denmark',
  'DK-84': 'Capital Region of Denmark',
  'DK-82': 'Central Denmark Region',
  'DK-81': 'North Denmark Region',
  'DK-83': 'Region Syddanmark',
  'DK-85': 'Region Zealand',
  'DJ-DJ': 'Djibouti',
  'DJ-AS': 'Ali Sabieh',
  'DJ-AR': 'Arta',
  'DJ-DI': 'Dikhil',
  'DJ-OB': 'Obock',
  'DJ-TA': 'Tadjourah',
  DM: 'Dominica',
  'JM-02': 'Saint Andrew Parish',
  'DM-03': 'Saint David Parish',
  'DM-04': 'Saint George Parish',
  'DM-05': 'Saint John Parish',
  'DM-06': 'Saint Joseph Parish',
  'DM-07': 'Saint Luke Parish',
  'DM-08': 'Saint Mark Parish',
  'DM-09': 'Saint Patrick Parish',
  'DM-10': 'Saint Paul Parish',
  'DM-11': 'Saint Peter Parish',
  DO: 'Dominican Republic',
  'DO-02': 'Azua Province',
  'DO-03': 'Baoruco Province',
  'DO-04': 'Barahona Province',
  'DO-05': 'Dajabón Province',
  'DO-01': 'Distrito Nacional',
  'DO-06': 'Duarte Province',
  'DO-08': 'El Seibo Province',
  'DO-07': 'Elías Piña Province',
  'DO-09': 'Espaillat Province',
  'DO-30': 'Hato Mayor Province',
  'DO-19': 'Hermanas Mirabal Province',
  'DO-10': 'Independencia Province',
  'DO-11': 'La Altagracia Province',
  'DO-12': 'La Romana Province',
  'DO-13': 'La Vega Province',
  'DO-14': 'María Trinidad Sánchez Province',
  'DO-28': 'Monseñor Nouel Province',
  'DO-15': 'Monte Cristi Province',
  'DO-29': 'Monte Plata Province',
  'DO-16': 'Pedernales Province',
  'DO-17': 'Peravia Province',
  'DO-18': 'Puerto Plata Province',
  'DO-20': 'Samaná Province',
  'DO-21': 'San Cristóbal Province',
  'DO-31': 'San José de Ocoa Province',
  'DO-23': 'San Pedro de Macorís Province',
  'DO-24': 'Sánchez Ramírez Province',
  'DO-25': 'Santiago Province',
  'DO-26': 'Santiago Rodríguez Province',
  'DO-32': 'Santo Domingo Province',
  'DO-27': 'Valverde Province',
  EC: 'Ecuador',
  'EC-A': 'Azuay',
  'EC-B': 'Bolívar Province',
  'EC-F': 'Cañar Province',
  'EC-C': 'Carchi Province',
  'EC-H': 'Chimborazo Province',
  'EC-X': 'Cotopaxi Province',
  'EC-O': 'El Oro',
  'EC-E': 'Esmeraldas Province',
  'EC-W': 'Galápagos Islands',
  'EC-G': 'Guayas',
  'EC-I': 'Imbabura Province',
  'EC-L': 'Loja',
  'EC-R': 'Los Ríos Province',
  'EC-M': 'Manabí Province',
  'EC-S': 'Morona-Santiago Province',
  'EC-D': 'Orellana Province',
  'EC-Y': 'Pastaza Province',
  'EC-P': 'Pichincha',
  'EC-N': 'Provincia de Napo',
  'EC-SE': 'Santa Elena Province',
  'EC-SD': 'Santo Domingo de los Tsáchilas Province',
  'EC-U': 'Sucumbíos Province',
  'EC-T': 'Tungurahua',
  'EC-Z': 'Zamora-Chinchipe Province',
  EG: 'Egypt',
  'EG-KB': 'Al Qalyubia Governorate',
  'EG-ALX': 'Alexandria Governorate',
  'EG-SHR': 'Ash Sharqia Governorate',
  'EG-AST': 'Assiut Governorate',
  'EG-ASN': 'Aswan Governorate',
  'EG-BNS': 'Beni Suef Governorate',
  'EG-C': 'Cairo Governorate',
  'EG-DK': 'Dakahlia Governorate',
  'EG-DT': 'Damietta Governorate',
  'EG-BH': 'El Beheira Governorate',
  'EG-FYM': 'Faiyum Governorate',
  'EG-GH': 'Gharbia Governorate',
  'EG-GZ': 'Giza Governorate',
  'EG-IS': 'Ismailia Governorate',
  'EG-KFS': 'Kafr El Sheikh Governorate',
  'EG-LX': 'Luxor Governorate',
  'EG-MT': 'Matrouh Governorate',
  'EG-MN': 'Menia Governorate',
  'EG-MNF': 'Menofia Governorate',
  'EG-WAD': 'New Valley Governorate',
  'EG-SIN': 'North Sinai Governorate',
  'EG-PTS': 'Port Said Governorate',
  'EG-KN': 'Qena Governorate',
  'EG-BA': 'Red Sea Governorate',
  'EG-SHG': 'Sohag Governorate',
  'EG-JS': 'South Sinai Governorate',
  'EG-SUZ': 'Suez Governorate',
  SV: 'El Salvador',
  'SV-AH': 'Ahuachapan',
  'SV-CA': 'Cabanas',
  'SV-CH': 'Chalatenango',
  'SV-CU': 'Cuscatlan',
  'PE-LAL': 'La Libertad',
  'HN-LP': 'La Paz',
  'SV-UN': 'La Union',
  'SV-MO': 'Morazan',
  'SV-SM': 'San Miguel',
  'SV-SV': 'San Vicente',
  'SV-SA': 'Santa Ana',
  'SV-SO': 'Sonsonate',
  'SV-US': 'Usulutan',
  GQ: 'Equatorial Guinea',
  'GQ-AN': 'Annobón',
  'GQ-BN': 'Bioko Norte',
  'GQ-BS': 'Bioko Sur',
  'GQ-CS': 'Centro Sur',
  'GQ-KN': 'Kie-Ntem',
  'GQ-LI': 'Litoral',
  'GQ-WN': 'Wele-Nzas',
  ER: 'Eritrea',
  'ER-AN': 'Anseba',
  'ER-GB': 'Gash-Barka',
  'ER-SK': 'Northern Red Sea',
  'ER-DK': 'Southern Red Sea',
  EE: 'Estonia',
  'EE-37': 'Harju County',
  'EE-39': 'Hiiu County',
  'EE-44': 'Ida-Viru County',
  'EE-51': 'Järva County',
  'EE-49': 'Jõgeva County',
  'EE-57': 'Lääne County',
  'EE-59': 'Lääne-Viru County',
  'EE-67': 'Pärnu County',
  'EE-65': 'Põlva County',
  'EE-70': 'Rapla County',
  'EE-74': 'Saare County',
  'EE-78': 'Tartu County',
  'EE-82': 'Valga County',
  'EE-84': 'Viljandi County',
  'EE-86': 'Võru County',
  SZ: 'Eswatini',
  'SZ-HH': 'Hhohho',
  'SZ-LU': 'Lubombo',
  'SZ-MA': 'Manzini',
  'SZ-SH': 'Shiselweni',
  ET: 'Ethiopia',
  'ET-AA': 'Addis Ababa',
  'ET-AF': 'Afar',
  'ET-AM': 'Amhara',
  'ET-BE': 'Benishangul-Gumuz',
  'ET-DD': 'Dire Dawa',
  'ET-GA': 'Gambella',
  'ET-HA': 'Harari',
  'ET-OR': 'Oromia',
  'ET-SO': 'Somali',
  'ET-SN': "Southern Nations, Nationalities, and People's Region",
  'ET-TI': 'Tigray',
  FK: 'Falkland Islands (Islas Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  'FJ-C': 'Central Division',
  'FJ-E': 'Eastern Division',
  'FJ-N': 'Northern Division',
  'FJ-R': 'Rotuma',
  'FJ-W': 'Western Division',
  FI: 'Finland',
  'FI-08': 'Central Finland',
  'FI-07': 'Central Ostrobothnia',
  'FI-05': 'Kainuu',
  'FI-09': 'Kymenlaakso',
  'FI-10': 'Lapland',
  'FI-13': 'North Karelia',
  'FI-14': 'Northern Ostrobothnia',
  'FI-15': 'Northern Savonia',
  'FI-12': 'Ostrobothnia',
  'FI-16': 'Päijänne Tavastia',
  'FI-11': 'Pirkanmaa',
  'FI-17': 'Satakunta',
  'FI-02': 'South Karelia',
  'FI-03': 'Southern Ostrobothnia',
  'FI-04': 'Southern Savonia',
  'FI-19': 'Southwest Finland',
  'FI-06': 'Tavastia Proper',
  'FI-18': 'Uusimaa',
  FR: 'France',
  'FR-A': 'Alsace',
  'FR-B': 'Aquitaine',
  'FR-C': 'Auvergne',
  'FR-E': 'Brittany',
  'FR-D': 'Burgundy',
  'FR-F': 'Centre-Val de Loire',
  'FR-G': 'Champagne-Ardenne',
  'FR-H': 'Corsica',
  'FR-I': 'Franche-Comté',
  'FR-J': 'Île-de-France',
  'FR-K': 'Languedoc-Roussillon',
  'FR-L': 'Limousin',
  'FR-M': 'Lorraine',
  'FR-P': 'Lower Normandy',
  'FR-N': 'Midi-Pyrénées',
  'FR-O': 'Nord-Pas-de-Calais',
  'FR-R': 'Pays de la Loire',
  'FR-S': 'Picardy',
  'FR-T': 'Poitou-Charentes',
  'FR-U': "Provence-Alpes-Côte d'Azur",
  'FR-V': 'Rhone-Alpes',
  'FR-Q': 'Upper Normandy',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  'GA-1': 'Estuaire',
  'GA-2': 'Haut-Ogooue',
  'GA-3': 'Moyen-Ogooue',
  'GA-4': 'Ngounie',
  'GA-5': 'Nyanga',
  'GA-6': 'Ogooue-Ivindo',
  'GA-7': 'Ogooue-Lolo',
  'GA-8': 'Ogooue-Maritime',
  'GA-9': 'Woleu-Ntem',
  GM: 'Gambia',
  'GM-B': 'Banjul',
  'GM-M': 'Central River',
  'GM-L': 'Lower River',
  'GM-N': 'North Bank Division',
  'GM-U': 'Upper River',
  'GM-W': 'West Coast Region',
  'US-GA': 'Georgia',
  'GE-AB': 'Abkhazia',
  'GE-AJ': 'Adjara',
  'GE-GU': 'Guria',
  'GE-IM': 'Imereti',
  'GE-KA': 'Kakheti',
  'GE-KK': 'Kvemo Kartli',
  'GE-MM': 'Mtskheta-Mtianeti',
  'GE-RL': 'Racha-Lechkhumi and Lower Svaneti',
  'GE-SZ': 'Samegrelo-Upper Svaneti',
  'GE-SJ': 'Samtskhe-Javakheti',
  'GE-SK': 'Shida Kartli',
  'GE-TB': 'Tbilisi',
  DE: 'Germany',
  'DE-BW': 'Baden-Württemberg',
  'DE-BY': 'Bavaria',
  'DE-BE': 'Berlin',
  'DE-BB': 'Brandenburg',
  'DE-HB': 'Bremen',
  'DE-HH': 'Hamburg',
  'DE-HE': 'Hesse',
  'DE-NI': 'Lower Saxony',
  'DE-MV': 'Mecklenburg-Vorpommern',
  'DE-NW': 'North Rhine-Westphalia',
  'DE-RP': 'Rhineland-Palatinate',
  'DE-SL': 'Saarland',
  'DE-SN': 'Saxony',
  'DE-ST': 'Saxony-Anhalt',
  'DE-SH': 'Schleswig-Holstein',
  'DE-TH': 'Thuringia',
  GH: 'Ghana',
  'GH-AH': 'Ashanti Region',
  'GH-BA': 'Brong-Ahafo Region',
  'UG-E': 'Eastern Region',
  'GH-AA': 'Greater Accra Region',
  'UG-N': 'Northern Region',
  'GH-UE': 'Upper East Region',
  'GH-UW': 'Upper West Region',
  'GH-TV': 'Volta Region',
  'UG-W': 'Western Region',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  'GL-KU': 'Kujalleq',
  'GL-QA': 'Qaasuitsup',
  'GL-QE': 'Qeqqata',
  'GL-SM': 'Sermersooq',
  GD: 'Grenada',
  'GD-10': 'Carriacou and Petite Martinique',
  'VC-03': 'Saint David',
  'GD-05': 'Saint Mark',
  'VC-05': 'Saint Patrick',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  'GT-AV': 'Alta Verapaz',
  'GT-BV': 'Baja Verapaz Department',
  'GT-CM': 'Chimaltenango Department',
  'GT-CQ': 'Chiquimula Department',
  'GT-PR': 'El Progreso Department',
  'GT-ES': 'Escuintla',
  'GT-GU': 'Guatemala Department',
  'GT-HU': 'Huehuetenango Department',
  'GT-IZ': 'Izabal Department',
  'GT-JA': 'Jalapa Department',
  'GT-JU': 'Jutiapa Department',
  'GT-PE': 'Petén Department',
  'GT-QZ': 'Quetzaltenango Department',
  'GT-QC': 'Quiché Department',
  'GT-RE': 'Retalhuleu Department',
  'GT-SA': 'Sacatepequez',
  'GT-SM': 'San Marcos Department',
  'GT-SR': 'Santa Rosa Department',
  'GT-SO': 'Sololá Department',
  'GT-SU': 'Suchitepequez',
  'GT-TO': 'Totonicapán Department',
  'GT-ZA': 'Zacapa Department',
  GG: 'Guernsey',
  GN: 'Guinea',
  'GN-B': 'Boké',
  'GN-C': 'Conakry',
  'GN-F': 'Faranah',
  'GN-K': 'Kankan',
  'GN-D': 'Kindia',
  'GN-L': 'Labé',
  'GN-M': 'Mamou',
  'GN-N': 'Nzérékoré',
  GW: 'Guinea-Bissau',
  'GW-BA': 'Bafata',
  'GW-BM': 'Biombo',
  'GW-BS': 'Bissau',
  'GW-BL': 'Bolama',
  'GW-CA': 'Cacheu',
  'GW-GA': 'Gabu',
  'GW-OI': 'Oio',
  'GW-QU': 'Quinara',
  'GW-TO': 'Tombali Region',
  GY: 'Guyana',
  'GY-BA': 'Barima-Waini',
  'GY-CU': 'Cuyuni-Mazaruni',
  'GY-DE': 'Demerara-Mahaica',
  'GY-EB': 'East Berbice-Corentyne',
  'GY-ES': 'Essequibo Islands-West Demerara',
  'GY-MA': 'Mahaica-Berbice',
  'GY-PM': 'Pomeroon-Supenaam',
  'GY-PT': 'Potaro-Siparuni',
  'GY-UD': 'Upper Demerara-Berbice',
  'GY-UT': 'Upper Takutu-Upper Essequibo',
  HT: 'Haiti',
  'HT-AR': 'Artibonite',
  'HT-GA': "Grand'Anse",
  'HT-NI': 'Nippes',
  'HT-NE': 'Nord-Est',
  'HT-NO': 'Nord-Ouest',
  'HT-OU': 'Ouest',
  'HT-SD': 'Sud',
  'HT-SE': 'Sud-Est',
  HM: 'Heard & McDonald Islands',
  HN: 'Honduras',
  'HN-AT': 'Atlantida',
  'HN-IB': 'Bay Islands',
  'HN-CH': 'Choluteca',
  'HN-CL': 'Colon',
  'HN-CM': 'Comayagua',
  'HN-CP': 'Copan',
  'HN-CR': 'Cortés',
  'HN-EP': 'El Paraiso',
  'HN-FM': 'Francisco Morazan',
  'HN-GD': 'Gracias a Dios',
  'HN-IN': 'Intibuca',
  'HN-LE': 'Lempira',
  'HN-OC': 'Ocotepeque',
  'HN-OL': 'Olancho',
  'HN-SB': 'Santa Barbara',
  'HN-VA': 'Valle',
  'HN-YO': 'Yoro',
  HK: 'Hong Kong',
  HU: 'Hungary',
  'HU-BK': 'Bács-Kiskun',
  'HU-BA': 'Baranya',
  'HU-BE': 'Békés County',
  'HU-BZ': 'Borsod-Abaúj-Zemplén',
  'HU-BU': 'Budapest',
  'HU-CS': 'Csongrád',
  'HU-FE': 'Fejér',
  'HU-GS': 'Gyor-Moson-Sopron',
  'HU-HB': 'Hajdú-Bihar',
  'HU-HE': 'Heves County',
  'HU-JN': 'Jász-Nagykun-Szolnok',
  'HU-KE': 'Komárom-Esztergom',
  'HU-NO': 'Nógrád County',
  'HU-PE': 'Pest County',
  'HU-SO': 'Somogy County',
  'HU-SZ': 'Szabolcs-Szatmár-Bereg',
  'HU-TO': 'Tolna County',
  'HU-VA': 'Vas County',
  'HU-VE': 'Veszprém',
  'HU-ZA': 'Zala County',
  IS: 'Iceland',
  'IS-1': 'Capital Region',
  'IS-6': 'Northeast',
  'IS-5': 'Northwestern Region',
  'IS-2': 'Southern Peninsula Region',
  'IS-4': 'Westfjords Region',
  IN: 'India',
  'IN-AN': 'Andaman and Nicobar Islands',
  'IN-AP': 'Andhra Pradesh',
  'IN-AR': 'Arunachal Pradesh',
  'IN-AS': 'Assam',
  'IN-BR': 'Bihar',
  'IN-CH': 'Chandigarh',
  'IN-CT': 'Chhattisgarh',
  'IN-DN': 'Dadra and Nagar Haveli',
  'IN-DD': 'Daman and Diu',
  'IN-DL': 'Delhi',
  'IN-GA': 'Goa',
  'IN-GJ': 'Gujarat',
  'IN-HR': 'Haryana',
  'IN-HP': 'Himachal Pradesh',
  'IN-JK': 'Jammu and Kashmir',
  'IN-JH': 'Jharkhand',
  'IN-KA': 'Karnataka',
  'IN-KL': 'Kerala',
  'IN-LD': 'Lakshadweep',
  'IN-MP': 'Madhya Pradesh',
  'IN-MH': 'Maharashtra',
  'IN-MN': 'Manipur',
  'IN-ML': 'Meghalaya',
  'IN-MZ': 'Mizoram',
  'IN-NL': 'Nagaland',
  'IN-OR': 'Odisha',
  'IN-PY': 'Puducherry',
  'PK-PB': 'Punjab',
  'IN-RJ': 'Rajasthan',
  'IN-SK': 'Sikkim',
  'IN-TN': 'Tamil Nadu',
  'IN-TG': 'Telangana',
  'IN-TR': 'Tripura',
  'IN-UP': 'Uttar Pradesh',
  'IN-UT': 'Uttarakhand',
  'IN-WB': 'West Bengal',
  ID: 'Indonesia',
  'ID-AC': 'Aceh',
  'ID-BA': 'Bali',
  'ID-BB': 'Bangka Belitung Islands',
  'ID-BT': 'Banten',
  'ID-BE': 'Bengkulu',
  'ID-JT': 'Central Java',
  'ID-KT': 'Central Kalimantan',
  'ID-ST': 'Central Sulawesi',
  'ID-JI': 'East Java',
  'ID-KI': 'East Kalimantan',
  'ID-NT': 'East Nusa Tenggara',
  'ID-GO': 'Gorontalo',
  'ID-JA': 'Jambi',
  'ID-LA': 'Lampung',
  'ID-MA': 'Maluku',
  'ID-KU': 'North Kalimantan',
  'ID-MU': 'North Maluku',
  'ID-SA': 'North Sulawesi',
  'ID-SU': 'North Sumatra',
  'ID-PA': 'Papua',
  'ID-RI': 'Riau',
  'ID-KR': 'Riau Islands',
  'ID-SG': 'South East Sulawesi',
  'ID-KS': 'South Kalimantan',
  'ID-SN': 'South Sulawesi',
  'ID-SS': 'South Sumatra',
  'ID-JK': 'Special Capital Region of Jakarta',
  'ID-YO': 'Special Region of Yogyakarta',
  'ID-JB': 'West Java',
  'ID-KB': 'West Kalimantan',
  'ID-NB': 'West Nusa Tenggara',
  'ID-PB': 'West Papua',
  'ID-SR': 'West Sulawesi',
  'ID-SB': 'West Sumatra',
  IR: 'Iran',
  'IR-32': 'Alborz',
  'IR-03': 'Ardabil',
  'IR-06': 'Bushehr',
  'IR-08': 'Chaharmahal and Bakhtiari',
  'IR-01': 'East Azerbaijan',
  'IR-14': 'Fars',
  'IR-19': 'Gilan',
  'IR-27': 'Golestan',
  'IR-24': 'Hamadan',
  'IR-23': 'Hormozgan',
  'IR-05': 'Ilam',
  'IR-04': 'Isfahan',
  'IR-15': 'Kerman',
  'IR-17': 'Kermanshah',
  'IR-30': 'Khorasan Razavi',
  'IR-10': 'Khuzestan',
  'IR-18': 'Kohgiluyeh Va Boyer Ahmad',
  'IR-16': 'Kurdistan',
  'IR-20': 'Lorestan',
  'IR-22': 'Markazi',
  'IR-21': 'Mazandaran',
  'IR-31': 'North Khorasan',
  'IR-28': 'Qazvin',
  'IR-26': 'Qom',
  'IR-12': 'Semnan',
  'IR-13': 'Sistan and Baluchestan',
  'IR-29': 'South Khorasan',
  'IR-07': 'Tehran',
  'IR-02': 'West Azerbaijan',
  'IR-25': 'Yazd',
  'IR-11': 'Zanjan',
  IQ: 'Iraq',
  'IQ-AN': 'Al Anbar',
  'IQ-MU': 'Al Muthanna',
  'IQ-QA': 'Al-Qadisiyyah',
  'IQ-NA': 'An-Najaf',
  'IQ-BB': 'Babil',
  'IQ-BG': 'Baghdad',
  'IQ-BA': 'Basrah',
  'IQ-DQ': 'Dhi Qar',
  'IQ-DI': 'Diyala',
  'IQ-DA': 'Duhok',
  'IQ-AR': 'Erbil',
  'IQ-KA': 'Karbala',
  'IQ-TS': 'Kirkuk',
  'IQ-MA': 'Maysan',
  'IQ-NI': 'Ninawa',
  'IQ-SD': 'Salah Al-Din',
  'IQ-SU': 'Sulaymaniyah',
  'IQ-WA': 'Wasit',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  'IL-M': 'Center District',
  'IL-HA': 'Haifa District',
  'IL-JM': 'Jerusalem District',
  'IL-Z': 'North District',
  'IL-D': 'South District',
  'IL-TA': 'Tel Aviv District',
  IT: 'Italy',
  'IT-65': 'Abruzzo',
  'IT-23': 'Aosta',
  'IT-75': 'Apulia',
  'IT-77': 'Basilicata',
  'IT-78': 'Calabria',
  'IT-72': 'Campania',
  'IT-45': 'Emilia-Romagna',
  'IT-36': 'Friuli-Venezia Giulia',
  'IT-62': 'Lazio',
  'IT-42': 'Liguria',
  'IT-25': 'Lombardy',
  'IT-57': 'Marche',
  'IT-67': 'Molise',
  'IT-21': 'Piedmont',
  'IT-88': 'Sardinia',
  'IT-82': 'Sicily',
  'IT-32': 'Trentino-Alto Adige/South Tyrol',
  'IT-52': 'Tuscany',
  'IT-55': 'Umbria',
  'IT-34': 'Veneto',
  JM: 'Jamaica',
  'JM-13': 'Clarendon Parish',
  'JM-09': 'Hanover Parish',
  'JM-01': 'Kingston Parish',
  'JM-12': 'Manchester Parish',
  'JM-04': 'Portland Parish',
  'JM-06': 'Saint Ann Parish',
  'JM-14': 'Saint Catherine Parish',
  'JM-11': 'Saint Elizabeth Parish',
  'JM-08': 'Saint James Parish',
  'JM-05': 'Saint Mary Parish',
  'JM-07': 'Trelawny Parish',
  'JM-10': 'Westmoreland Parish',
  JP: 'Japan',
  'JP-23': 'Aichi Prefecture',
  'JP-05': 'Akita Prefecture',
  'JP-02': 'Aomori Prefecture',
  'JP-12': 'Chiba Prefecture',
  'JP-38': 'Ehime Prefecture',
  'JP-18': 'Fukui Prefecture',
  'JP-40': 'Fukuoka Prefecture',
  'JP-07': 'Fukushima Prefecture',
  'JP-21': 'Gifu Prefecture',
  'JP-10': 'Gunma Prefecture',
  'JP-34': 'Hiroshima Prefecture',
  'JP-01': 'Hokkaido Prefecture',
  'JP-28': 'Hyogo Prefecture',
  'JP-08': 'Ibaraki Prefecture',
  'JP-17': 'Ishikawa Prefecture',
  'JP-03': 'Iwate Prefecture',
  'JP-37': 'Kagawa Prefecture',
  'JP-46': 'Kagoshima Prefecture',
  'JP-14': 'Kanagawa Prefecture',
  'JP-39': 'Kochi Prefecture',
  'JP-43': 'Kumamoto Prefecture',
  'JP-26': 'Kyoto Prefecture',
  'JP-24': 'Mie Prefecture',
  'JP-04': 'Miyagi Prefecture',
  'JP-45': 'Miyazaki Prefecture',
  'JP-20': 'Nagano Prefecture',
  'JP-42': 'Nagasaki Prefecture',
  'JP-29': 'Nara Prefecture',
  'JP-15': 'Niigata Prefecture',
  'JP-44': 'Oita Prefecture',
  'JP-33': 'Okayama Prefecture',
  'JP-47': 'Okinawa Prefecture',
  'JP-27': 'Osaka Prefecture',
  'JP-41': 'Saga Prefecture',
  'JP-11': 'Saitama Prefecture',
  'JP-25': 'Shiga Prefecture',
  'JP-32': 'Shimane Prefecture',
  'JP-22': 'Shizuoka Prefecture',
  'JP-09': 'Tochigi Prefecture',
  'JP-36': 'Tokushima Prefecture',
  'JP-13': 'Tokyo',
  'JP-31': 'Tottori Prefecture',
  'JP-16': 'Toyama Prefecture',
  'JP-30': 'Wakayama Prefecture',
  'JP-06': 'Yamagata Prefecture',
  'JP-35': 'Yamaguchi Prefecture',
  'JP-19': 'Yamanashi Prefecture',
  JE: 'Jersey',
  JO: 'Jordan',
  'JO-AJ': 'Ajloun Governorate',
  'JO-AM': 'Amman Governorate',
  'JO-AQ': 'Aqaba Governorate',
  'JO-BA': 'Balqa Governorate',
  'JO-IR': 'Irbid Governorate',
  'JO-JA': 'Jerash Governorate',
  'JO-KA': 'Karak Governorate',
  'JO-MN': "Ma'an Governorate",
  'JO-MD': 'Madaba Governorate',
  'JO-MA': 'Mafraq Governorate',
  'JO-AT': 'Tafilah Governorate',
  'JO-AZ': 'Zarqa Governorate',
  KZ: 'Kazakhstan',
  'KZ-AKM': 'Akmola Region',
  'KZ-AKT': 'Aktobe Region',
  'KZ-ALM': 'Almaty Region',
  'KZ-ATY': 'Atyrau Province',
  'KZ-VOS': 'East Kazakhstan Region',
  'KZ-ZHA': 'Jambyl Province',
  'KZ-KAR': 'Karagandy Region',
  'KZ-KUS': 'Kostanay Region',
  'KZ-KZY': 'Kyzylorda Province',
  'KZ-MAN': 'Mangystau Region',
  'KZ-SEV': 'North Kazakhstan Province',
  'KZ-PAV': 'Pavlodar Province',
  'KZ-YUZ': 'South Kazakhstan Region',
  'KZ-ZAP': 'West Kazakhstan Province',
  KE: 'Kenya',
  KI: 'Kiribati',
  'KI-G': 'Gilbert Islands',
  'KI-L': 'Line Islands',
  'KI-P': 'Phoenix Islands',
  XK: 'Kosovo',
  KW: 'Kuwait',
  'KW-AH': 'Al Ahmadi Governorate',
  'KW-KU': 'Al Asimah Governate',
  'KW-FA': 'Al Farwaniyah Governorate',
  'KW-JA': 'Al Jahra Governorate',
  'KW-HA': 'Hawalli Governorate',
  'KW-MU': 'Mubarak Al-Kabeer Governorate',
  KG: 'Kyrgyzstan',
  'KG-B': 'Batken Province',
  'KG-C': 'Chuy Province',
  'KG-J': 'Dzhalal-Abadskaya',
  'KG-N': 'Naryn Province',
  'KG-O': 'Osh Province',
  'KG-T': 'Talas',
  'KG-Y': 'Ysyk Kol Province',
  LA: 'Laos',
  'LA-AT': 'Attapeu',
  'LA-BK': 'Bokeo',
  'LA-BL': 'Bolikhamsai',
  'LA-CH': 'Champasak',
  'LA-HO': 'Houaphan',
  'LA-KH': 'Khammouane',
  'LA-LP': 'Louangphabang',
  'LA-LM': 'Luang Namtha',
  'LA-OU': 'Oudomxay',
  'LA-PH': 'Phongsaly',
  'LA-SL': 'Salavan',
  'LA-SV': 'Savannakhet',
  'LA-XA': 'Sayabouly',
  'LA-VI': 'Vientiane',
  'LA-VT': 'Vientiane Prefecture',
  'LA-XE': 'Xekong',
  'LA-XI': 'Xiangkhouang',
  LV: 'Latvia',
  'LV-011': 'Ādaži Municipality',
  'LV-001': 'Aglona Municipality',
  'LV-002': 'Aizkraukle Municipality',
  'LV-003': 'Aizpute Municipality',
  'LV-004': 'Aknīste Municipality',
  'LV-005': 'Aloja Municipality',
  'LV-006': 'Alsunga Municipality',
  'LV-007': 'Alūksne Municipality',
  'LV-008': 'Amata Municipality',
  'LV-009': 'Ape Municipality',
  'LV-010': 'Auce Municipality',
  'LV-012': 'Babīte Municipality',
  'LV-013': 'Baldone Municipality',
  'LV-014': 'Baltinava Municipality',
  'LV-015': 'Balvi Municipality',
  'LV-016': 'Bauska Municipality',
  'LV-017': 'Beverīna Municipality',
  'LV-018': 'Brocēni Municipality',
  'LV-019': 'Burtnieki Municipality',
  'LV-020': 'Carnikava Municipality',
  'LV-022': 'Cēsis Municipality',
  'LV-021': 'Cesvaine Municipality',
  'LV-023': 'Cibla Municipality',
  'LV-024': 'Dagdas novads',
  'LV-DGV': 'Daugavpils',
  'LV-025': 'Daugavpils Municipality',
  'LV-026': 'Dobele Municipality',
  'LV-027': 'Dundaga Municipality',
  'LV-028': 'Durbe Municipality',
  'LV-029': 'Engure Municipality',
  'LV-030': 'Ērgļi Municipality',
  'LV-031': 'Garkalne Municipality',
  'LV-032': 'Grobiņa Municipality',
  'LV-033': 'Gulbene Municipality',
  'LV-034': 'Iecava Municipality',
  'LV-035': 'Ikšķile Municipality',
  'LV-036': 'Ilūkste Municipality',
  'LV-037': 'Inčukalna novads',
  'LV-038': 'Jaunjelgava Municipality',
  'LV-039': 'Jaunpiebalga Municipality',
  'LV-040': 'Jaunpils Municipality',
  'LV-JKB': 'Jēkabpils',
  'LV-042': 'Jēkabpils Municipality',
  'LV-JEL': 'Jelgava',
  'LV-041': 'Jelgava Municipality',
  'LV-JUR': 'Jūrmala',
  'LV-043': 'Kandava Municipality',
  'LV-044': 'Kārsava Municipality',
  'LV-051': 'Ķegums Municipality',
  'LV-052': 'Ķekava Municipality',
  'LV-045': 'Kocēni Municipality',
  'LV-046': 'Koknese Municipality',
  'LV-047': 'Krāslava Municipality',
  'LV-048': 'Krimulda Municipality',
  'LV-049': 'Krustpils Municipality',
  'LV-050': 'Kuldīga Municipality',
  'LV-053': 'Lielvārde Municipality',
  'LV-LPX': 'Liepājas pilsēta',
  'LV-055': 'Līgatne Municipality',
  'LV-054': 'Limbaži Municipality',
  'LV-056': 'Līvāni Municipality',
  'LV-057': 'Lubāna Municipality',
  'LV-058': 'Ludza Municipality',
  'LV-059': 'Madona Municipality',
  'LV-061': 'Mālpils Municipality',
  'LV-062': 'Mārupe Municipality',
  'LV-060': 'Mazsalaca Municipality',
  'LV-063': 'Mērsrags Municipality',
  'LV-064': 'Naukšēni Municipality',
  'LV-065': 'Nereta Municipality',
  'LV-066': 'Nīca Municipality',
  'LV-067': 'Ogre Municipality',
  'LV-068': 'Olaines novads',
  'LV-069': 'Ozolnieki Municipality',
  'LV-070': 'Pārgauja Municipality',
  'LV-071': 'Pāvilosta Municipality',
  'LV-072': 'Pļaviņas Municipality',
  'LV-073': 'Preiļi Municipality',
  'LV-074': 'Priekule Municipality',
  'LV-075': 'Priekuļi Municipality',
  'LV-076': 'Rauna Municipality',
  'LV-REZ': 'Rēzekne Municipality',
  'LV-078': 'Riebiņi Municipality',
  'LV-RIX': 'Riga',
  'LV-079': 'Roja Municipality',
  'LV-080': 'Ropaži Municipality',
  'LV-081': 'Rucava Municipality',
  'LV-082': 'Rugāji Municipality',
  'LV-084': 'Rūjiena Municipality',
  'LV-083': 'Rundāle Municipality',
  'LV-085': 'Sala Municipality',
  'LV-086': 'Salacgrīva Municipality',
  'LV-087': 'Salaspils Municipality',
  'LV-088': 'Saldus Municipality',
  'LV-089': 'Saulkrasti Municipality',
  'LV-090': 'Sēja Municipality',
  'LV-091': 'Sigulda Municipality',
  'LV-092': 'Skrīveri Municipality',
  'LV-093': 'Skrunda Municipality',
  'LV-094': 'Smiltene Municipality',
  'LV-095': 'Stopiņi Municipality',
  'LV-096': 'Strenči Municipality',
  'LV-097': 'Talsi Municipality',
  'LV-098': 'Tērvete Municipality',
  'LV-099': 'Tukums Municipality',
  'LV-100': 'Vaiņode Municipality',
  'LV-101': 'Valka Municipality',
  'LV-VMR': 'Valmiera',
  'LV-102': 'Varakļāni Municipality',
  'LV-103': 'Vārkava Municipality',
  'LV-104': 'Vecpiebalga Municipality',
  'LV-105': 'Vecumnieki Municipality',
  'LV-VEN': 'Ventspils',
  'LV-106': 'Ventspils Municipality',
  'LV-107': 'Viesīte Municipality',
  'LV-108': 'Viļaka Municipality',
  'LV-109': 'Viļāni Municipality',
  'LV-110': 'Zilupe Municipality',
  LB: 'Lebanon',
  'LB-BA': 'Beirut',
  'LB-BI': 'Beqaa',
  'LB-JL': 'Mount Lebanon',
  'LB-NA': 'Nabatiyeh',
  LS: 'Lesotho',
  'LS-D': 'Berea',
  'LS-B': 'Butha-Buthe',
  'LS-C': 'Leribe',
  'LS-E': 'Mafeteng',
  'LS-A': 'Maseru',
  'LS-F': "Mohale's Hoek",
  'LS-J': 'Mokhotlong',
  'LS-H': "Qacha's Nek",
  'LS-G': 'Quthing',
  'LS-K': 'Thaba-Tseka',
  LR: 'Liberia',
  'LR-BM': 'Bomi',
  'LR-BG': 'Bong',
  'LR-GP': 'Gbarpolu',
  'LR-GB': 'Grand Bassa',
  'LR-CM': 'Grand Cape Mount',
  'LR-GG': 'Grand Gedeh',
  'LR-GK': 'Grand Kru',
  'LR-LO': 'Lofa',
  'LR-MG': 'Margibi',
  'US-MD': 'Maryland',
  'LR-MO': 'Montserrado',
  'LR-NI': 'Nimba',
  'LR-RI': 'River Cess',
  'LR-RG': 'River Gee',
  'LR-SI': 'Sinoe',
  LY: 'Libya',
  'LY-BU': 'Al Butnan',
  'LY-JA': 'Al Jabal al Akhdar',
  'LY-JG': 'Al Jabal al Gharbi',
  'LY-JI': 'Al Jafara',
  'LY-JU': 'Al Jufrah',
  'LY-KF': 'Al Kufrah',
  'LY-MJ': 'Al Marj',
  'LY-MB': 'Al Murqub',
  'LY-WA': 'Al Wahat',
  'LY-NQ': 'An Nuqat al Khams',
  'LY-ZA': 'Az Zawiyah',
  'LY-BA': 'Banghazi',
  'LY-DR': 'Darnah',
  'LY-GT': 'Ghat',
  'LY-MI': 'Misratah',
  'LY-MQ': 'Murzuq',
  'LY-NL': 'Nalut',
  'LY-SB': 'Sabha',
  'LY-SR': 'Surt',
  'LY-TB': 'Tripoli',
  'LY-WD': 'Wadi Al Hayaa',
  'LY-WS': 'Wadi Ash-Shati',
  LI: 'Liechtenstein',
  'LI-01': 'Balzers',
  'LI-02': 'Eschen',
  'LI-03': 'Gamprin',
  'LI-04': 'Mauren',
  'LI-05': 'Planken',
  'LI-06': 'Ruggell',
  'LI-07': 'Schaan',
  'LI-08': 'Schellenberg',
  'LI-09': 'Triesen',
  'LI-10': 'Triesenberg',
  'LI-11': 'Vaduz',
  LT: 'Lithuania',
  'LT-AL': 'Alytus County',
  'LT-KU': 'Kaunas County',
  'LT-KL': 'Klaipėda County',
  'LT-MR': 'Marijampolė County',
  'LT-PN': 'Panevėžys County',
  'LT-SA': 'Šiauliai County',
  'LT-TA': 'Tauragė County',
  'LT-TE': 'Telšiai County',
  'LT-UT': 'Utena County',
  'LT-VL': 'Vilnius County',
  LU: 'Luxembourg',
  'LU-D': 'Diekirch',
  'LU-G': 'Grevenmacher',
  'LU-L': 'Luxembourg District',
  MO: 'Macao',
  MG: 'Madagascar',
  'MG-T': 'Antananarivo',
  'MG-D': 'Antsiranana',
  'MG-F': 'Fianarantsoa',
  'MG-M': 'Mahajanga',
  'MG-A': 'Toamasina',
  'MG-U': 'Toliara',
  MW: 'Malawi',
  'UG-C': 'Central Region',
  'MW-S': 'Southern Region',
  MY: 'Malaysia',
  'MY-14': 'Federal Territory of Kuala Lumpur',
  'MY-01': 'Johor',
  'MY-02': 'Kedah',
  'MY-03': 'Kelantan',
  'MY-15': 'Labuan Federal Territory',
  'MY-04': 'Malacca',
  'MY-05': 'Negeri Sembilan',
  'MY-06': 'Pahang',
  'MY-07': 'Penang',
  'MY-08': 'Perak',
  'MY-09': 'Perlis',
  'MY-16': 'Putrajaya',
  'MY-12': 'Sabah',
  'MY-13': 'Sarawak',
  'MY-10': 'Selangor',
  'MY-11': 'Terengganu',
  MV: 'Maldives',
  'LK-2': 'Central Province',
  'MV-MLE': 'Male',
  'LK-7': 'North Central Province',
  'MV-NO': 'North Province',
  'MV-SC': 'South Central Province',
  'MV-SU': 'South Province',
  'MV-UN': 'Upper North Province',
  'MV-US': 'Upper South Province',
  ML: 'Mali',
  'ML-BKO': 'Bamako',
  'ML-7': 'Gao',
  'ML-1': 'Kayes',
  'ML-8': 'Kidal',
  'ML-2': 'Koulikoro',
  'ML-5': 'Mopti',
  'ML-4': 'Segou',
  'ML-3': 'Sikasso',
  'ML-6': 'Tombouctou',
  MT: 'Malta',
  MH: 'Marshall Islands',
  'MH-ALL': 'Ailinglaplap Atoll',
  'MH-ALK': 'Ailuk Atoll',
  'MH-ARN': 'Arno Atoll',
  'MH-AUR': 'Aur Atoll',
  'MH-EBO': 'Ebon',
  'MH-ENI': 'Enewetak Atoll',
  'MH-JAB': 'Jabat Island',
  'MH-JAL': 'Jaluit Atoll',
  'MH-KIL': 'Kili Island',
  'MH-KWA': 'Kwajalein Atoll',
  'MH-LAE': 'Lae Atoll',
  'MH-LIB': 'Lib Island',
  'MH-LIK': 'Likiep Atoll',
  'MH-MAJ': 'Majuro Atoll',
  'MH-MAL': 'Maloelap Atoll',
  'MH-MEJ': 'Mejit Island',
  'MH-MIL': 'Mili Atoll',
  'MH-NMK': 'Namorik Atoll',
  'MH-NMU': 'Namu Atoll',
  'MH-RON': 'Rongelap Atoll',
  'MH-UJA': 'Ujae Atoll',
  'MH-UTI': 'Utirik Atoll',
  'MH-WTH': 'Wotho Atoll',
  'MH-WTJ': 'Wotje Atoll',
  MQ: 'Martinique',
  MR: 'Mauritania',
  'MR-07': 'Adrar',
  'MR-03': 'Assaba',
  'MR-05': 'Brakna',
  'MR-08': 'Dakhlet Nouadhibou',
  'MR-04': 'Gorgol',
  'MR-10': 'Guidimaka',
  'MR-01': 'Hodh Ech Chargui',
  'MR-02': 'Hodh El Gharbi',
  'MR-12': 'Inchiri',
  'MR-NKC': 'Nouakchott',
  'MR-09': 'Tagant',
  'MR-11': 'Tiris Zemmour',
  'MR-06': 'Trarza',
  MU: 'Mauritius',
  'MU-AG': 'Agalega Islands',
  'MU-BL': 'Black River',
  'MU-CC': 'Cargados Carajos',
  'MU-FL': 'Flacq',
  'MU-GP': 'Grand Port',
  'MU-MO': 'Moka',
  'MU-PA': 'Pamplemousses',
  'MU-PW': 'Plaines Wilhems',
  'MU-PL': 'Port Louis',
  'MU-RR': 'Rivière du Rempart',
  'MU-RO': 'Rodrigues',
  'MU-SA': 'Savanne',
  YT: 'Mayotte',
  MX: 'Mexico',
  'MX-AGU': 'Aguascalientes',
  'MX-BCN': 'Baja California',
  'MX-BCS': 'Baja California Sur',
  'MX-CAM': 'Campeche',
  'MX-CHP': 'Chiapas',
  'MX-CHH': 'Chihuahua',
  'MX-COA': 'Coahuila',
  'MX-COL': 'Colima',
  'MX-DUR': 'Durango',
  'MX-GUA': 'Guanajuato',
  'MX-GRO': 'Guerrero',
  'MX-HID': 'Hidalgo',
  'MX-JAL': 'Jalisco',
  'MX-DIF': 'Mexico City',
  'MX-MIC': 'Michoacán',
  'MX-MOR': 'Morelos',
  'MX-NAY': 'Nayarit',
  'MX-NLE': 'Nuevo Leon',
  'MX-OAX': 'Oaxaca',
  'MX-PUE': 'Puebla',
  'MX-QUE': 'Querétaro',
  'MX-ROO': 'Quintana Roo',
  'MX-SLP': 'San Luis Potosi',
  'MX-SIN': 'Sinaloa',
  'MX-SON': 'Sonora',
  'MX-MEX': 'State of Mexico',
  'MX-TAB': 'Tabasco',
  'MX-TAM': 'Tamaulipas',
  'MX-TLA': 'Tlaxcala',
  'MX-VER': 'Veracruz',
  'MX-YUC': 'Yucatan',
  'MX-ZAC': 'Zacatecas',
  FM: 'Micronesia',
  'FM-TRK': 'Chuuk',
  'FM-KSA': 'Kosrae',
  'FM-PNI': 'Pohnpei',
  'FM-YAP': 'Yap',
  MD: 'Moldova',
  'MD-AN': 'Anenii Noi',
  'MD-BA': 'Balti',
  'MD-BS': 'Basarabeasca District',
  'MD-BD': 'Bender',
  'MD-BR': 'Briceni',
  'MD-CA': 'Cahul',
  'MD-CL': 'Calarasi',
  'MD-CT': 'Cantemir',
  'MD-CS': 'Causeni',
  'MD-CU': 'Chisinau',
  'MD-CM': 'Cimișlia',
  'MD-CR': 'Criuleni',
  'MD-DO': 'Donduseni',
  'MD-DR': 'Drochia',
  'MD-DU': 'Dubasari',
  'MD-ED': 'Edineţ District',
  'MD-FA': 'Falesti',
  'MD-FL': 'Floresti',
  'MD-GA': 'Gagauzia',
  'MD-GL': 'Glodeni',
  'MD-HI': 'Hincesti',
  'MD-IA': 'Ialoveni',
  'MD-LE': 'Leova',
  'MD-NI': 'Nisporeni',
  'MD-OC': 'Ocnita',
  'MD-OR': 'Orhei',
  'MD-RE': 'Rezina',
  'MD-RI': 'Riscani',
  'MD-SI': 'Singerei',
  'MD-SD': 'Soldanesti',
  'MD-SO': 'Soroca',
  'MD-SV': 'Stefan Voda',
  'MD-ST': 'Straseni',
  'MD-TA': 'Taraclia',
  'MD-TE': 'Telenești District',
  'MD-SN': 'Transnistria',
  'MD-UN': 'Ungheni',
  MC: 'Monaco',
  MN: 'Mongolia',
  'MN-073': 'Arkhangai',
  'MN-071': 'Bayan-Ulgii',
  'MN-069': 'Bayankhongor',
  'MN-067': 'Bulgan',
  'MN-037': 'Darkhan-Uul',
  'MN-061': 'Dornod',
  'MN-063': 'Dornogovi',
  'MN-059': 'Dundgovi',
  'MN-065': 'Govi-Altai',
  'MN-064': 'Govisumber',
  'MN-039': 'Khentii',
  'MN-043': 'Khovd',
  'MN-041': 'Khuvsgul',
  'MN-035': 'Orkhon',
  'MN-049': 'Selenge',
  'MN-051': 'Sukhbaatar',
  'MN-047': 'Tov',
  'MN-1': 'Ulaanbaatar',
  'MN-053': 'Umnugovi',
  'MN-046': 'Uvs',
  'MN-055': 'Uvurkhangai',
  'MN-057': 'Zavkhan',
  ME: 'Montenegro',
  'ME-01': 'Andrijevica',
  'ME-02': 'Bar',
  'ME-03': 'Berane',
  'ME-04': 'Bijelo Polje',
  'ME-05': 'Budva',
  'ME-06': 'Cetinje',
  'ME-07': 'Danilovgrad',
  'ME-08': 'Herceg Novi',
  'ME-09': 'Kolasin',
  'ME-10': 'Kotor',
  'ME-11': 'Mojkovac',
  'ME-12': 'Niksic',
  'ME-13': 'Plav',
  'ME-14': 'Pljevlja',
  'ME-15': 'Pluzine',
  'ME-16': 'Podgorica',
  'ME-17': 'Rozaje',
  'ME-18': 'Savnik',
  'ME-19': 'Tivat',
  'ME-20': 'Ulcinj',
  'ME-21': 'Zabljak',
  MS: 'Montserrat',
  MA: 'Morocco',
  'MA-09': 'Chaouia-Ouardigha',
  'MA-10': 'Doukkala-Abda',
  'MA-05': 'Fes-Boulemane',
  'MA-02': 'Gharb-Chrarda-Beni Hssen',
  'MA-08': 'Grand Casablanca',
  'MA-14': 'Guelmim-Es Semara',
  'MA-15': 'Laayoune-Boujdour-Sakia El Hamra',
  'MA-11': 'Marrakesh-Tensift-El Haouz',
  'MA-06': 'Meknes-Tafilalet',
  'MA-04': 'Oriental',
  'MA-07': 'Rabat-Sale-Zemmour-Zaer',
  'MA-13': 'Souss-Massa-Draa',
  'MA-12': 'Tadla-Azilal',
  'MA-01': 'Tangier-Tetouan',
  'MA-03': 'Taza-Al Hoceima-Taounate',
  MZ: 'Mozambique',
  'MZ-P': 'Cabo Delgado Province',
  'MZ-G': 'Gaza Province',
  'MZ-I': 'Inhambane Province',
  'MZ-B': 'Manica Province',
  'MZ-MPM': 'Maputo City',
  'MZ-L': 'Maputo Province',
  'MZ-N': 'Nampula Province',
  'MZ-A': 'Niassa Province',
  'MZ-S': 'Sofala Province',
  'MZ-T': 'Tete Province',
  'MZ-Q': 'Zambezia Province',
  MM: 'Myanmar (Burma)',
  'MM-07': 'Ayeyarwady',
  'MM-02': 'Bago Region',
  'MM-14': 'Chin State',
  'MM-11': 'Kachin',
  'MM-12': 'Kayah',
  'MM-13': 'Kayin State',
  'MM-03': 'Magway Region',
  'MM-04': 'Mandalay Region',
  'MM-15': 'Mon State',
  'MM-18': 'Naypyidaw Union Territory',
  'MM-16': 'Rakhine',
  'MM-01': 'Sagaing Region',
  'MM-17': 'Shan',
  'MM-05': 'Tanintharyi Region',
  'MM-06': 'Yangon Region',
  NA: 'Namibia',
  'NA-ER': 'Erongo',
  'NA-HA': 'Hardap Region',
  'NA-KA': 'Karas Region',
  'NA-OK': 'Kavango Region',
  'NA-KH': 'Khomas Region',
  'NA-KU': 'Kunene Region',
  'NA-OW': 'Ohangwena',
  'NA-OH': 'Omaheke Region',
  'NA-OS': 'Omusati Region',
  'NA-ON': 'Oshana Region',
  'NA-OT': 'Oshikoto Region',
  'NA-OD': 'Otjozondjupa Region',
  'NA-CA': 'Zambezi Region',
  NR: 'Nauru',
  'NR-01': 'Aiwo',
  'NR-02': 'Anabar',
  'NR-03': 'Anetan',
  'NR-04': 'Anibare',
  'NR-05': 'Baiti',
  'NR-06': 'Boe',
  'NR-07': 'Buada',
  'NR-08': 'Denigomodu',
  'NR-09': 'Ewa',
  'NR-10': 'Ijuw',
  'NR-11': 'Meneng',
  'NR-12': 'Nibok',
  'NR-13': 'Uaboe',
  'NR-14': 'Yaren',
  NP: 'Nepal',
  'NP-5': 'Far-Western Region',
  'NP-2': 'Mid-Western Region',
  NL: 'Netherlands',
  'NL-DR': 'Drenthe',
  'NL-FL': 'Flevoland',
  'NL-FR': 'Friesland',
  'NL-GE': 'Gelderland',
  'NL-GR': 'Groningen',
  'NL-LI': 'Limburg',
  'NL-NB': 'North Brabant',
  'NL-NH': 'North Holland',
  'NL-OV': 'Overijssel',
  'NL-ZH': 'South Holland',
  'NL-UT': 'Utrecht',
  'NL-ZE': 'Zeeland',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  'NZ-AUK': 'Auckland',
  'NZ-BOP': 'Bay Of Plenty',
  'NZ-CAN': 'Canterbury',
  'NZ-CIT': 'Chatham Islands',
  'NZ-GIS': 'Gisborne',
  'NZ-HKB': "Hawke's Bay",
  'NZ-MWT': 'Manawatu-Wanganui',
  'NZ-MBH': 'Marlborough',
  'NZ-NSN': 'Nelson',
  'NZ-NTL': 'Northland',
  'NZ-OTA': 'Otago',
  'NZ-STL': 'Southland',
  'NZ-TKI': 'Taranaki',
  'NZ-TAS': 'Tasman',
  'NZ-WKO': 'Waikato',
  'NZ-WGN': 'Wellington',
  'NZ-WTC': 'West Coast',
  NI: 'Nicaragua',
  'NI-AN': 'Autonomous Region of the Northern Atlantic',
  'NI-AS': 'Autonomous Region of the Southern Atlantic',
  'NI-BO': 'Boaco',
  'NI-CA': 'Carazo',
  'NI-CI': 'Chinandega',
  'NI-CO': 'Chontales',
  'NI-ES': 'Esteli',
  'NI-GR': 'Granada',
  'NI-JI': 'Jinotega',
  'NI-LE': 'Leon',
  'NI-MD': 'Madriz',
  'NI-MN': 'Managua',
  'NI-MS': 'Masaya',
  'NI-MT': 'Matagalpa',
  'NI-NS': 'Nueva Segovia',
  'NI-SJ': 'Rio San Juan',
  'NI-RI': 'Rivas',
  'NG-NI': 'Niger',
  'NE-1': 'Agadez',
  'NE-2': 'Diffa',
  'NE-3': 'Dosso',
  'NE-4': 'Maradi',
  'NE-8': 'Niamey',
  'NE-5': 'Tahoua',
  'NE-6': 'Tillaberi',
  'NE-7': 'Zinder',
  NG: 'Nigeria',
  'NG-AB': 'Abia',
  'NG-AD': 'Adamawa',
  'NG-AK': 'Akwa Ibom',
  'NG-AN': 'Anambra',
  'NG-BA': 'Bauchi',
  'NG-BY': 'Bayelsa',
  'NG-BE': 'Benue',
  'NG-BO': 'Borno',
  'NG-CR': 'Cross River',
  'NG-DE': 'Delta',
  'NG-EB': 'Ebonyi',
  'NG-ED': 'Edo',
  'NG-EK': 'Ekiti',
  'NG-EN': 'Enugu',
  'NG-FC': 'Federal Capital Territory',
  'NG-GO': 'Gombe',
  'NG-IM': 'Imo',
  'NG-JI': 'Jigawa',
  'NG-KD': 'Kaduna',
  'NG-KN': 'Kano',
  'NG-KT': 'Katsina',
  'NG-KE': 'Kebbi',
  'NG-KO': 'Kogi',
  'NG-KW': 'Kwara',
  'NG-LA': 'Lagos',
  'NG-NA': 'Nasarawa',
  'NG-OG': 'Ogun State',
  'NG-ON': 'Ondo',
  'NG-OS': 'Osun',
  'NG-OY': 'Oyo',
  'NG-RI': 'Rivers',
  'NG-SO': 'Sokoto',
  'NG-TA': 'Taraba',
  'NG-YO': 'Yobe',
  'NG-ZA': 'Zamfara',
  NU: 'Niue',
  'NU-TOI': 'Toi',
  NF: 'Norfolk Island',
  KP: 'North Korea',
  'KP-04': 'Chagang',
  'KP-07': 'Kangwon',
  'KP-09': 'North Hamgyong',
  'KP-06': 'North Hwanghae',
  'KP-03': 'North Pyongan',
  'KP-01': 'Pyongyang',
  'KP-13': 'Rason',
  'KP-10': 'Ryanggang',
  'KP-08': 'South Hamgyong',
  'KP-05': 'South Hwanghae',
  'KP-02': 'South Pyongan',
  MK: 'North Macedonia',
  'MK-07': 'Bosilovo',
  'MK-08': 'Brvenitsa',
  'MK-78': 'Centar Zhupa',
  'MK-80': 'Chashka',
  'MK-23': 'Delchevo',
  'MK-26': 'Dojran',
  'MK-19': 'Gostivar',
  'MK-51': 'Makedonska Kamenitsa',
  'MK-53': 'Mogila',
  'MK-02': 'Municipality of Arachinovo',
  'MK-03': 'Municipality of Berovo',
  'MK-04': 'Municipality of Bitola',
  'MK-05': 'Municipality of Bogdanci',
  'MK-06': 'Municipality of Bogovinje',
  'MK-81': 'Municipality of Cheshinovo-Obleshevo',
  'MK-82': 'Municipality of Chucher Sandevo',
  'MK-21': 'Municipality of Debar',
  'MK-22': 'Municipality of Debarca',
  'MK-25': 'Municipality of Demir Hisar',
  'MK-24': 'Municipality of Demir Kapija',
  'MK-27': 'Municipality of Dolneni',
  'MK-18': 'Municipality of Gevgelija',
  'MK-20': 'Municipality of Gradsko',
  'MK-34': 'Municipality of Ilinden',
  'MK-35': 'Municipality of Jegunovce',
  'MK-37': 'Municipality of Karbinci',
  'MK-36': 'Municipality of Kavadarci',
  'MK-40': 'Municipality of Kichevo',
  'MK-42': 'Municipality of Kochani',
  'MK-41': 'Municipality of Konche',
  'MK-43': 'Municipality of Kratovo',
  'MK-44': 'Municipality of Kriva Palanka',
  'MK-45': 'Municipality of Krivogashtani',
  'MK-46': 'Municipality of Krushevo',
  'MK-47': 'Municipality of Kumanovo',
  'MK-48': 'Municipality of Lipkovo',
  'MK-49': 'Municipality of Lozovo',
  'MK-52': 'Municipality of Makedonski Brod',
  'MK-50': 'Municipality of Mavrovo and Rostusha',
  'MK-54': 'Municipality of Negotino',
  'MK-55': 'Municipality of Novatsi',
  'MK-56': 'Municipality of Novo Selo',
  'MK-60': 'Municipality of Pehchevo',
  'MK-59': 'Municipality of Petrovets',
  'MK-61': 'Municipality of Plasnitsa',
  'MK-62': 'Municipality of Prilep',
  'MK-65': 'Municipality of Rankovtse',
  'MK-66': 'Municipality of Resen',
  'MK-67': 'Municipality of Rosoman',
  'MK-83': 'Municipality of Shtip',
  'MK-70': 'Municipality of Sopishte',
  'MK-72': 'Municipality of Struga',
  'MK-11': 'Municipality of Vasilevo',
  'MK-13': 'Municipality of Veles',
  'MK-12': 'Municipality of Vevchani',
  'MK-14': 'Municipality of Vinica',
  'MK-31': 'Municipality of Zajas',
  'MK-30': 'Municipality of Zhelino',
  'MK-33': 'Municipality of Zrnovci',
  'MK-58': 'Ohrid',
  'MK-63': 'Probishtip',
  'MK-64': 'Radovish',
  'MK-85': 'Skopje',
  'MK-71': 'Staro Nagorichane',
  'MK-73': 'Strumitsa',
  'MK-74': 'Studenichani',
  'MK-69': 'Sveti Nikole',
  'MK-75': 'Tearce',
  'MK-76': 'Tetovo',
  'MK-10': 'Valandovo',
  'MK-16': 'Vrapchishte',
  'MK-32': 'Zelenikovo',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  'NO-02': 'Akershus',
  'NO-09': 'Aust-Agder',
  'NO-06': 'Buskerud',
  'NO-20': 'Finnmark',
  'NO-04': 'Hedmark',
  'NO-12': 'Hordaland',
  'NO-15': 'Møre og Romsdal',
  'NO-17': 'Nord-Trondelag',
  'NO-18': 'Nordland',
  'NO-05': 'Oppland',
  'NO-03': 'Oslo',
  'NO-01': 'Ostfold',
  'NO-11': 'Rogaland',
  'NO-14': 'Sogn og Fjordane',
  'NO-16': 'Sor-Trondelag',
  'NO-08': 'Telemark',
  'NO-19': 'Troms',
  'NO-10': 'Vest-Agder',
  'NO-07': 'Vestfold',
  OM: 'Oman',
  'OM-DA': 'Ad Dakhiliyah ‍Governorate',
  'OM-ZA': 'Ad Dhahirah Governorate',
  'OM-BS': 'Al Batinah North Governorate',
  'OM-BJ': 'Al Batinah South Governorate',
  'OM-BU': 'Al Buraymi Governorate',
  'OM-WU': 'Al Wusta Governorate',
  'OM-SS': 'Ash Sharqiyah North Governorate',
  'OM-SJ': 'Ash Sharqiyah South Governorate',
  'OM-ZU': 'Dhofar Governorate',
  'OM-MU': 'Musandam Governorate',
  'OM-MA': 'Muscat Governorate',
  PK: 'Pakistan',
  'PK-JK': 'Azad Jammu and Kashmir',
  'PK-BA': 'Balochistan',
  'PK-TA': 'Federally Administered Tribal Areas',
  'PK-GB': 'Gilgit-Baltistan',
  'PK-IS': 'Islamabad Capital Territory',
  'PK-KP': 'Khyber Pakhtunkhwa',
  'PK-SD': 'Sindh',
  PW: 'Palau',
  'PW-002': 'Aimeliik',
  'PW-004': 'Airai',
  'PW-010': 'Angaur',
  'PW-050': 'Hatohobei',
  'PW-100': 'Kayangel',
  'PW-150': 'Koror',
  'PW-212': 'Melekeok',
  'PW-214': 'Ngaraard',
  'PW-218': 'Ngarchelong',
  'PW-222': 'Ngardmau',
  'PW-227': 'Ngaremlengui',
  'PW-224': 'Ngatpang',
  'PW-226': 'Ngchesar',
  'PW-228': 'Ngiwal',
  'PW-350': 'Peleliu',
  'PW-370': 'Sonsorol',
  PS: 'Palestine',
  'PA-8': 'Panama',
  'PA-1': 'Bocas del Toro Province',
  'PA-4': 'Chiriquí Province',
  'PA-2': 'Coclé Province',
  'PA-3': 'Colón Province',
  'PA-5': 'Darién Province',
  'PA-EM': 'Emberá-Wounaan Comarca',
  'PA-KY': 'Guna Yala',
  'PA-6': 'Herrera Province',
  'PA-7': 'Los Santos Province',
  'PA-NB': 'Ngäbe-Buglé Comarca',
  'PA-9': 'Veraguas Province',
  PG: 'Papua New Guinea',
  'PG-NSB': 'Autonomous Region of Bougainville',
  'PG-CPK': 'Chimbu Province',
  'PG-EBR': 'East New Britain Province',
  'PG-ESW': 'East Sepik Province',
  'PG-EHG': 'Eastern Highlands Province',
  'PG-EPW': 'Enga Province',
  'PG-GPK': 'Gulf Province',
  'PG-JWK': 'Jiwaka Province',
  'PG-MPM': 'Madang Province',
  'PG-MRL': 'Manus Province',
  'PG-MBA': 'Milne Bay Province',
  'PG-MPL': 'Morobe Province',
  'PG-NCD': 'National Capital District',
  'PG-NIK': 'New Ireland Province',
  'LK-4': 'Northern Province',
  'PG-SHM': 'Southern Highlands Province',
  'PG-WBK': 'West New Britain Province',
  'PG-SAN': 'West Sepik Province',
  'PG-WHM': 'Western Highlands Province',
  'LK-1': 'Western Province',
  PY: 'Paraguay',
  'PY-16': 'Alto Paraguay',
  'PY-10': 'Alto Parana',
  'PY-13': 'Amambay Department',
  'PY-ASU': 'Asunción',
  'PY-19': 'Boqueron',
  'PY-5': 'Caaguazu',
  'PY-6': 'Caazapa',
  'PY-14': 'Canindeyu',
  'PY-1': 'Concepcion',
  'PY-3': 'Cordillera',
  'PY-4': 'Guaira',
  'PY-7': 'Itapua',
  'PY-8': 'Misiones',
  'PY-12': 'Neembucu',
  'PY-9': 'Paraguari',
  'PY-15': 'Presidente Hayes Department',
  'PY-2': 'San Pedro',
  PE: 'Peru',
  'VE-Z': 'Amazonas',
  'PE-ANC': 'Ancash',
  'PE-APU': 'Apurimac',
  'PE-ARE': 'Arequipa',
  'PE-AYA': 'Ayacucho',
  'PE-CAJ': 'Cajamarca',
  'PE-CAL': 'Callao',
  'PE-CUS': 'Cusco',
  'PE-HUV': 'Huancavelica',
  'PE-HUC': 'Huanuco',
  'PE-ICA': 'Ica',
  'PE-JUN': 'Junin',
  'PE-LAM': 'Lambayeque',
  'PE-LIM': 'Lima Region',
  'PE-LOR': 'Loreto',
  'PE-MDD': 'Madre de Dios',
  'PE-MOQ': 'Moquegua',
  'PE-PAS': 'Pasco',
  'PE-PIU': 'Piura',
  'PE-PUN': 'Puno',
  'PE-SAM': 'San Martin',
  'PE-TAC': 'Tacna',
  'PE-TUM': 'Tumbes',
  'PE-UCA': 'Ucayali',
  PH: 'Philippines',
  'PH-14': 'Autonomous Region in Muslim Mindanao',
  'PH-05': 'Bicol',
  'PH-02': 'Cagayan Valley',
  'PH-40': 'Calabarzon',
  'PH-13': 'Caraga',
  'PH-03': 'Central Luzon',
  'PH-07': 'Central Visayas',
  'PH-15': 'Cordillera Administrative Region',
  'PH-11': 'Davao Region',
  'PH-08': 'Eastern Visayas',
  'PH-01': 'Ilocos Region',
  'PH-00': 'Metro Manila',
  'PH-41': 'MIMAROPA',
  'PH-10': 'Northern Mindanao',
  'PH-12': 'Region XII',
  'PH-06': 'Western Visayas',
  'PH-09': 'Zamboanga Peninsula',
  PN: 'Pitcairn Islands',
  PL: 'Poland',
  'PL-WP': 'Greater Poland Voivodeship',
  'PL-KP': 'Kuyavian-Pomeranian Voivodeship',
  'PL-MA': 'Lesser Poland Voivodeship',
  'PL-LD': 'Łódź Voivodeship',
  'PL-DS': 'Lower Silesian Voivodeship',
  'PL-LU': 'Lublin Voivodeship',
  'PL-LB': 'Lubusz Voivodeship',
  'PL-MZ': 'Masovian Voivodeship',
  'PL-OP': 'Opole Voivodeship',
  'PL-PK': 'Podkarpackie Voivodeship',
  'PL-PD': 'Podlaskie Voivodeship',
  'PL-PM': 'Pomeranian Voivodeship',
  'PL-SL': 'Silesian Voivodeship',
  'PL-SK': 'Swietokrzyskie',
  'PL-WN': 'Warmian-Masurian Voivodeship',
  'PL-ZP': 'West Pomeranian Voivodeship',
  PT: 'Portugal',
  'PT-01': 'Aveiro District',
  'PT-20': 'Azores',
  'PT-02': 'Beja District',
  'PT-03': 'Braga',
  'PT-04': 'Bragança District',
  'PT-05': 'Castelo Branco District',
  'PT-06': 'Coimbra District',
  'PT-07': 'Évora District',
  'PT-08': 'Faro District',
  'PT-09': 'Guarda District',
  'PT-10': 'Leiria District',
  'PT-11': 'Lisbon',
  'PT-30': 'Madeira',
  'PT-12': 'Portalegre District',
  'PT-13': 'Porto District',
  'PT-14': 'Santarém District',
  'PT-15': 'Setubal',
  'PT-16': 'Viana do Castelo District',
  'PT-17': 'Vila Real District',
  'PT-18': 'Viseu District',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  'QA-ZA': 'Al Daayen',
  'QA-KH': 'Al Khor',
  'QA-RA': 'Al Rayyan Municipality',
  'QA-WA': 'Al Wakrah Municipality',
  'QA-DA': 'Doha',
  'QA-MS': 'Madinat ash Shamal',
  'QA-US': 'Umm Salal Municipality',
  RE: 'Réunion',
  RO: 'Romania',
  'RO-AB': 'Alba County',
  'RO-AR': 'Arad County',
  'RO-AG': 'Argeș County',
  'RO-BC': 'Bacău County',
  'RO-BH': 'Bihor County',
  'RO-BN': 'Bistrița-Năsăud County',
  'RO-BT': 'Botoșani County',
  'RO-BR': 'Brăila County',
  'RO-BV': 'Brașov County',
  'RO-B': 'Bucharest',
  'RO-BZ': 'Buzău County',
  'RO-CL': 'Călărași County',
  'RO-CS': 'Caraș-Severin County',
  'RO-CJ': 'Cluj County',
  'RO-CT': 'Constanța County',
  'RO-CV': 'Covasna County',
  'RO-DB': 'Dâmbovița County',
  'RO-DJ': 'Dolj County',
  'RO-GL': 'Galați',
  'RO-GR': 'Giurgiu County',
  'RO-GJ': 'Gorj County',
  'RO-HR': 'Harghita County',
  'RO-HD': 'Hunedoara County',
  'RO-IL': 'Ialomița County',
  'RO-IS': 'Iași County',
  'RO-IF': 'Ilfov County',
  'RO-MM': 'Maramureș County',
  'RO-MH': 'Mehedinți County',
  'RO-MS': 'Mureș County',
  'RO-NT': 'Neamț County',
  'RO-OT': 'Olt County',
  'RO-PH': 'Prahova',
  'RO-SJ': 'Sălaj',
  'RO-SM': 'Satu Mare County',
  'RO-SB': 'Sibiu',
  'RO-SV': 'Suceava County',
  'RO-TR': 'Teleorman County',
  'RO-TM': 'Timiș County',
  'RO-TL': 'Tulcea County',
  'RO-VL': 'Vâlcea County',
  'RO-VS': 'Vaslui County',
  'RO-VN': 'Vrancea County',
  RU: 'Russia',
  'RU-AD': 'Adygea',
  'RU-ALT': 'Altai Krai',
  'RU-AL': 'Altai Republic',
  'RU-AMU': 'Amur Oblast',
  'RU-ARK': 'Arkhangelsk Oblast',
  'RU-AST': 'Astrakhan Oblast',
  'RU-BEL': 'Belgorod Oblast',
  'RU-BRY': 'Bryansk Oblast',
  'RU-BU': 'Buryatia',
  'RU-CE': 'Chechnya',
  'RU-CHE': 'Chelyabinsk Oblast',
  'RU-CHU': 'Chukotka Autonomous Okrug',
  'RU-CU': 'Chuvashia Republic',
  'RU-DA': 'Dagestan Republic',
  'RU-IN': 'Ingushetia',
  'RU-IRK': 'Irkutsk Oblast',
  'RU-IVA': 'Ivanovo Oblast',
  'RU-YEV': 'Jewish Autonomous Oblast',
  'RU-KB': 'Kabardino-Balkaria',
  'RU-KGD': 'Kaliningrad Oblast',
  'RU-KL': 'Kalmykia',
  'RU-KLU': 'Kaluga Oblast',
  'RU-KAM': 'Kamchatka Krai',
  'RU-KC': 'Karachay-Cherkessia',
  'RU-KEM': 'Kemerovo Oblast',
  'RU-KHA': 'Khabarovsk Krai',
  'RU-KHM': 'Khanty-Mansi Autonomous Okrug',
  'RU-KIR': 'Kirov Oblast',
  'RU-KO': 'Komi Republic',
  'RU-KOS': 'Kostroma Oblast',
  'RU-KDA': 'Krasnodar Krai',
  'RU-KYA': 'Krasnoyarsk Krai',
  'RU-KGN': 'Kurgan Oblast',
  'RU-KRS': 'Kursk Oblast',
  'RU-LEN': 'Leningrad Oblast',
  'RU-LIP': 'Lipetsk Oblast',
  'RU-MAG': 'Magadan Oblast',
  'RU-ME': 'Mari El Republic',
  'RU-MO': 'Mordovia',
  'RU-MOW': 'Moscow',
  'RU-MOS': 'Moscow Oblast',
  'RU-MUR': 'Murmansk Oblast',
  'RU-NEN': 'Nenets Autonomous Okrug',
  'RU-NIZ': 'Nizhny Novgorod Oblast',
  'RU-SE': 'North Ossetia–Alania',
  'RU-NGR': 'Novgorod Oblast',
  'RU-NVS': 'Novosibirsk Oblast',
  'RU-OMS': 'Omsk Oblast',
  'RU-ORE': 'Orenburg Oblast',
  'RU-ORL': 'Oryol Oblast',
  'RU-PNZ': 'Penza Oblast',
  'RU-PER': 'Perm Krai',
  'RU-PRI': 'Primorsky Krai',
  'RU-PSK': 'Pskov Oblast',
  'RU-BA': 'Republic of Bashkortostan',
  'RU-KR': 'Republic of Karelia',
  'RU-KK': 'Republic of Khakassia',
  'RU-ROS': 'Rostov Oblast',
  'RU-RYA': 'Ryazan Oblast',
  'RU-SPE': 'Saint Petersburg',
  'RU-SA': 'Sakha Republic',
  'RU-SAK': 'Sakhalin Oblast',
  'RU-SAM': 'Samara Oblast',
  'RU-SAR': 'Saratov Oblast',
  'RU-SMO': 'Smolensk Oblast',
  'RU-STA': 'Stavropol Krai',
  'RU-SVE': 'Sverdlovsk Oblast',
  'RU-TAM': 'Tambov Oblast',
  'RU-TA': 'Tatarstan',
  'RU-TOM': 'Tomsk Oblast',
  'RU-TUL': 'Tula Oblast',
  'RU-TY': 'Tuva',
  'RU-TVE': 'Tver Oblast',
  'RU-TYU': 'Tyumen Oblast',
  'RU-UD': 'Udmurt Republic',
  'RU-ULY': 'Ulyanovsk Oblast',
  'RU-VLA': 'Vladimir Oblast',
  'RU-VGG': 'Volgograd Oblast',
  'RU-VLG': 'Vologda Oblast',
  'RU-VOR': 'Voronezh Oblast',
  'RU-YAN': 'Yamalo-Nenets Autonomous Okrug',
  'RU-YAR': 'Yaroslavl Oblast',
  'RU-ZAB': 'Zabaykalsky Krai',
  RW: 'Rwanda',
  'LK-5': 'Eastern Province',
  'RW-01': 'Kigali',
  'LK-3': 'Southern Province',
  WS: 'Samoa',
  'WS-AA': "A'ana",
  'WS-AL': 'Aiga-i-le-Tai',
  'WS-AT': 'Atua',
  'WS-FA': "Fa'asaleleaga",
  'WS-GE': "Gaga'emauga",
  'WS-GI': "Gaga'ifomauga",
  'WS-PA': 'Palauli',
  'WS-SA': "Satupa'itea",
  'WS-TU': 'Tuamasaga',
  'WS-VF': "Va'a-o-Fonoti",
  'WS-VS': 'Vaisigano',
  'SM-07': 'San Marino',
  'SM-01': 'Acquaviva',
  'SM-06': 'Borgo Maggiore',
  'SM-02': 'Chiesanuova',
  'SM-03': 'Domagnano',
  'SM-04': 'Faetano',
  'SM-05': 'Fiorentino',
  'SM-08': 'Montegiardino',
  'SM-09': 'Serravalle',
  ST: 'São Tomé & Príncipe',
  'ST-P': 'Principe',
  'ST-S': 'Sao Tome',
  SA: 'Saudi Arabia',
  'SA-11': 'Al Bahah Province',
  'SA-12': 'Al Jowf',
  'SA-03': 'Al Madinah Province',
  'SA-05': 'Al Qassim',
  'SA-14': 'Aseer Province',
  'SA-06': 'Hail Province',
  'SA-09': 'Jazan',
  'SA-02': 'Makkah Province',
  'SA-10': 'Najran',
  'SA-08': 'Northern Borders Province',
  'SA-01': 'Riyadh Province',
  'SA-07': 'Tabuk Province',
  SN: 'Senegal',
  'SN-DK': 'Dakar',
  'SN-DB': 'Diourbel Region',
  'SN-FK': 'Fatick Region',
  'SN-KA': 'Kaffrine Region',
  'SN-KL': 'Kaolack Region',
  'SN-KE': 'Kédougou Region',
  'SN-KD': 'Kolda Region',
  'SN-LG': 'Louga Region',
  'SN-MT': 'Matam Region',
  'SN-SL': 'Saint-Louis Region',
  'SN-SE': 'Sédhiou Region',
  'SN-TC': 'Tambacounda Region',
  'SN-TH': 'Thiès Region',
  'SN-ZG': 'Ziguinchor',
  RS: 'Serbia',
  'RS-VO': 'Vojvodina',
  SC: 'Seychelles',
  'SC-01': 'Anse Aux Pins',
  'SC-02': 'Anse Boileau',
  'SC-03': 'Anse Etoile',
  'SC-05': 'Anse Royale',
  'SC-04': 'Au Cap',
  'SC-06': 'Baie Lazare',
  'SC-07': 'Baie Sainte Anne',
  'SC-08': 'Beau Vallon',
  'SC-09': 'Bel Air',
  'SC-10': 'Bel Ombre',
  'SC-11': 'Cascade',
  'SC-12': 'Glacis',
  'SC-13': 'Grand Anse Mahe',
  'SC-14': 'Grand Anse Praslin',
  'SC-15': 'La Digue',
  'SC-16': 'La Riviere Anglaise',
  'SC-24': 'Les Mamelles',
  'SC-17': 'Mont Buxton',
  'SC-18': 'Mont Fleuri',
  'SC-19': 'Plaisance',
  'SC-20': 'Pointe La Rue',
  'SC-21': 'Port Glaud',
  'SC-25': 'Roche Caiman',
  'SC-22': 'Saint Louis',
  'SC-23': 'Takamaka',
  SL: 'Sierra Leone',
  'SL-W': 'Western Area',
  SG: 'Singapore',
  SX: 'Sint Maarten',
  SK: 'Slovakia',
  'SK-BC': 'Banská Bystrica Region',
  'SK-BL': 'Bratislava Region',
  'SK-KI': 'Košice Region',
  'SK-NI': 'Nitra Region',
  'SK-PV': 'Prešov Region',
  'SK-TC': 'Trenčín Region',
  'SK-TA': 'Trnava Region',
  'SK-ZI': 'Žilina Region',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  'SB-CT': 'Capital Territory',
  'SB-CH': 'Choiseul Province',
  'SB-GU': 'Guadalcanal Province',
  'SB-IS': 'Isabel Province',
  'SB-MK': 'Makira-Ulawa',
  'SB-ML': 'Malaita Province',
  'SB-RB': 'Rennell and Bellona Province',
  'SB-TE': 'Temotu Province',
  SO: 'Somalia',
  'SO-AW': 'Awdal',
  'SO-BK': 'Bakool',
  'SO-BN': 'Banaadir',
  'SO-BR': 'Bari',
  'SO-BY': 'Bay',
  'SO-GA': 'Galguduud',
  'SO-GE': 'Gedo',
  'SO-HI': 'Hiran',
  'SO-JD': 'Jubbada Dhexe',
  'SO-JH': 'Jubbada Hoose',
  'SO-MU': 'Mudug',
  'SO-NU': 'Nugaal',
  'SO-SA': 'Sanaag',
  'SO-SD': 'Shabeellaha Dhexe',
  'SO-SH': 'Shabeellaha Hoose',
  'SO-SO': 'Sool',
  'SO-TO': 'Togdheer',
  'SO-WO': 'Woqooyi Galbeed',
  ZA: 'South Africa',
  'ZA-EC': 'Eastern Cape',
  'ZA-FS': 'Free State',
  'ZA-GT': 'Gauteng',
  'ZA-NL': 'KwaZulu-Natal',
  'ZA-LP': 'Limpopo',
  'ZA-MP': 'Mpumalanga',
  'ZA-NC': 'Northern Cape',
  'ZA-WC': 'Western Cape',
  GS: 'South Georgia & South Sandwich Islands',
  KR: 'South Korea',
  'KR-26': 'Busan',
  'KR-43': 'Chungcheongbuk-do',
  'KR-44': 'Chungcheongnam-do',
  'KR-27': 'Daegu',
  'KR-30': 'Daejeon',
  'KR-42': 'Gangwon-do',
  'KR-29': 'Gwangju',
  'KR-41': 'Gyeonggi-do',
  'KR-47': 'Gyeongsangbuk-do',
  'KR-48': 'Gyeongsangnam-do',
  'KR-28': 'Incheon',
  'KR-49': 'Jeju-do',
  'KR-45': 'Jeollabuk-do',
  'KR-46': 'Jeollanam-do',
  'KR-11': 'Seoul',
  'KR-31': 'Ulsan',
  SS: 'South Sudan',
  'SS-UY': 'Al Wahdah',
  'SS-LK': 'Al-Buhayrat',
  'SS-EC': 'Central Equatoria',
  'SS-EE': 'Eastern Equatoria',
  'SS-JG': 'Jonglei',
  'SS-BN': 'Northern Bahr el Ghazal',
  'SS-NU': 'Upper Nile',
  'SS-WR': 'Warrap',
  'SS-BW': 'Western Bahr el Ghazal',
  'SS-EW': 'Western Equatoria',
  ES: 'Spain',
  'ES-AN': 'Andalusia',
  'ES-AR': 'Aragon',
  'ES-AS': 'Asturias',
  'ES-IB': 'Balearic Islands',
  'ES-PV': 'Basque Country',
  'ES-CN': 'Canary Islands',
  'ES-CB': 'Cantabria',
  'ES-CL': 'Castile and León',
  'ES-CM': 'Castile-La Mancha',
  'ES-CT': 'Catalonia',
  'ES-CE': 'Ceuta',
  'ES-MD': 'Community of Madrid',
  'ES-EX': 'Extremadura',
  'ES-GA': 'Galicia',
  'ES-RI': 'La Rioja',
  'ES-ML': 'Melilla',
  'ES-NC': 'Navarre',
  'ES-MC': 'Region of Murcia',
  'ES-VC': 'Valencian Community',
  LK: 'Sri Lanka',
  'LK-6': 'North Western Province',
  'LK-9': 'Sabaragamuwa Province',
  'LK-8': 'Uva Province',
  BL: 'St. Barthélemy',
  SH: 'St. Helena',
  'SH-AC': 'Ascension',
  'SH-HL': 'Saint Helena',
  'SH-TA': 'Tristan da Cunha',
  KN: 'St. Kitts & Nevis',
  'KN-01': 'Christ Church Nichola Town Parish',
  'KN-02': 'Saint Anne Sandy Point Parish',
  'KN-03': 'Saint George Basseterre Parish',
  'KN-04': 'Saint George Gingerland Parish',
  'KN-05': 'Saint James Windward Parish',
  'KN-06': 'Saint John Capisterre',
  'KN-07': 'Saint John Figtree Parish',
  'KN-08': 'Saint Mary Cayon Parish',
  'KN-09': 'Saint Paul Capisterre Parish',
  'KN-10': 'Saint Paul Charlestown Parish',
  'KN-11': 'Saint Peter Basseterre Parish',
  'KN-12': 'Saint Thomas Lowland Parish',
  'KN-13': 'Saint Thomas Middle Island Parish',
  'KN-15': 'Trinity Palmetto Point Parish',
  LC: 'St. Lucia',
  'LC-01': 'Anse La Raye',
  'LC-02': 'Castries',
  'LC-03': 'Choiseul',
  'LC-04': 'Dauphin',
  'LC-05': 'Dennery',
  'LC-06': 'Gros Islet',
  'LC-07': 'Laborie',
  'LC-08': 'Micoud',
  'LC-09': 'Praslin',
  'LC-10': 'Soufriere',
  'LC-11': 'Vieux Fort',
  MF: 'St. Martin',
  PM: 'St. Pierre & Miquelon',
  VC: 'St. Vincent & Grenadines',
  'VC-01': 'Charlotte',
  'VC-06': 'Grenadines',
  SD: 'Sudan',
  'SD-GZ': 'Al Jazirah',
  'SD-GD': 'Al Qadarif',
  'SD-NB': 'Blue Nile',
  'SD-DW': 'Gharb Darfur',
  'SD-DS': 'Janub Darfur',
  'SD-KA': 'Kassala',
  'SD-KH': 'Khartoum',
  'SD-KN': 'North Kurdufan',
  'ZM-05': 'Northern',
  'SD-RS': 'Red Sea',
  'SD-NR': 'River Nile',
  'SD-SI': 'Sennar',
  'SD-DN': 'Shamal Darfur',
  'SD-KS': 'South Kordofan',
  'SD-NW': 'White Nile',
  SR: 'Suriname',
  'SR-BR': 'Brokopondo',
  'SR-CM': 'Commewijne',
  'SR-CR': 'Coronie',
  'SR-MA': 'Marowijne',
  'SR-NI': 'Nickerie District',
  'SR-PR': 'Para',
  'SR-PM': 'Paramaribo District',
  'SR-SA': 'Saramacca',
  'SR-SI': 'Sipaliwini',
  'SR-WA': 'Wanica',
  SJ: 'Svalbard & Jan Mayen',
  SE: 'Sweden',
  'SE-K': 'Blekinge County',
  'SE-W': 'Dalarna County',
  'SE-X': 'Gavleborg County',
  'SE-I': 'Gotland County',
  'SE-N': 'Halland County',
  'SE-Z': 'Jamtland County',
  'SE-F': 'Jonkoping County',
  'SE-H': 'Kalmar County',
  'SE-G': 'Kronoberg County',
  'SE-BD': 'Norrbotten County',
  'SE-T': 'Örebro County',
  'SE-E': 'Östergötland County',
  'SE-M': 'Skåne County',
  'SE-D': 'Södermanland County',
  'SE-AB': 'Stockholm County',
  'SE-C': 'Uppsala County',
  'SE-S': 'Varmland County',
  'SE-AC': 'Västerbotten County',
  'SE-Y': 'Västernorrland County',
  'SE-U': 'Västmanland County',
  'SE-O': 'Västra Götaland County',
  CH: 'Switzerland',
  'CH-AG': 'Aargau',
  'CH-AI': 'Appenzell Innerrhoden',
  'CH-AR': 'Appenzell Outer Rhodes',
  'CH-BL': 'Basel-Landschaft',
  'CH-BS': 'Basel-Stadt',
  'CH-BE': 'Canton of Bern',
  'CH-FR': 'Canton of Fribourg',
  'CH-GL': 'Canton of Glarus',
  'CH-JU': 'Canton of Jura',
  'CH-NE': 'Canton of Neuchâtel',
  'CH-OW': 'Canton of Obwalden',
  'CH-SH': 'Canton of Schaffhausen',
  'CH-SZ': 'Canton of Schwyz',
  'CH-SO': 'Canton of Solothurn',
  'CH-UR': 'Canton of Uri',
  'CH-ZG': 'Canton of Zug',
  'CH-GE': 'Geneva',
  'CH-GR': 'Grisons',
  'CH-LU': 'Lucerne',
  'CH-NW': 'Nidwalden',
  'CH-SG': 'St. Gallen',
  'CH-TG': 'Thurgau',
  'CH-TI': 'Ticino',
  'CH-VS': 'Valais',
  'CH-VD': 'Vaud',
  'CH-ZH': 'Zurich',
  SY: 'Syria',
  'SY-HA': 'Al Hasakah',
  'SY-HL': 'Aleppo Governorate',
  'SY-RA': 'Ar-Raqqah Governorate',
  'SY-SU': 'As-Suwayda Governorate',
  'SY-DI': 'Damascus Governorate',
  'SY-DR': 'Daraa Governorate',
  'SY-DY': 'Deir Ez-Zor Governorate',
  'SY-HM': 'Hama Governorate',
  'SY-HI': 'Homs Governorate',
  'SY-ID': 'Idlib Governorate',
  'SY-LA': 'Latakia Governorate',
  'SY-QU': 'Quneitra Governorate',
  'SY-RD': 'Rif-Dimashq Governorate',
  'SY-TA': 'Tartus Governorate',
  TW: 'Taiwan',
  'TW-KHH': 'Kaohsiung City',
  'TW-TPQ': 'New Taipei City',
  'TW-TXG': 'Taichung City',
  'TW-TNN': 'Tainan City',
  'TW-TPE': 'Taipei City',
  'TW-TAO': 'Taoyuan City',
  TJ: 'Tajikistan',
  'TJ-RR': 'Districts of Republican Subordination',
  'TJ-GB': 'Gorno-Badakhshan Autonomous Province',
  'TJ-KT': 'Khatlon',
  'TJ-SU': 'Sughd Province',
  TZ: 'Tanzania',
  'TZ-01': 'Arusha',
  'TZ-03': 'Dodoma',
  'TZ-04': 'Iringa',
  'TZ-05': 'Kagera',
  'TZ-08': 'Kigoma',
  'TZ-09': 'Kilimanjaro',
  'TZ-12': 'Lindi',
  'TZ-26': 'Manyara',
  'TZ-13': 'Mara',
  'TZ-14': 'Mbeya',
  'TZ-16': 'Morogoro',
  'TZ-17': 'Mtwara',
  'TZ-18': 'Mwanza',
  'TZ-06': 'Pemba North',
  'TZ-10': 'Pemba South',
  'TZ-19': 'Pwani',
  'TZ-20': 'Rukwa',
  'TZ-21': 'Ruvuma',
  'TZ-22': 'Shinyanga',
  'TZ-23': 'Singida',
  'TZ-24': 'Tabora',
  'TZ-25': 'Tanga',
  'TZ-11': 'Zanzibar Central/South',
  'TZ-07': 'Zanzibar North',
  'TZ-15': 'Zanzibar Urban/West',
  TH: 'Thailand',
  'TH-37': 'Amnat Charoen',
  'TH-15': 'Ang Thong',
  'TH-10': 'Bangkok',
  'TH-38': 'Bueng Kan',
  'TH-31': 'Buri Ram',
  'TH-24': 'Chachoengsao',
  'TH-18': 'Chai Nat',
  'TH-36': 'Chaiyaphum',
  'TH-22': 'Chanthaburi',
  'TH-50': 'Chiang Mai',
  'TH-57': 'Chiang Rai',
  'TH-20': 'Chon Buri',
  'TH-86': 'Chumphon',
  'TH-46': 'Kalasin',
  'TH-62': 'Kamphaeng Phet',
  'TH-71': 'Kanchanaburi',
  'TH-40': 'Khon Kaen',
  'TH-81': 'Krabi',
  'TH-52': 'Lampang',
  'TH-51': 'Lamphun',
  'TH-42': 'Loei',
  'TH-16': 'Lopburi',
  'TH-58': 'Mae Hong Son',
  'TH-44': 'Maha Sarakham',
  'TH-49': 'Mukdahan',
  'TH-26': 'Nakhon Nayok',
  'TH-73': 'Nakhon Pathom',
  'TH-48': 'Nakhon Phanom',
  'TH-30': 'Nakhon Ratchasima',
  'TH-60': 'Nakhon Sawan',
  'TH-80': 'Nakhon Si Thammarat',
  'TH-55': 'Nan',
  'TH-96': 'Narathiwat',
  'TH-39': 'Nong Bua Lam Phu',
  'TH-43': 'Nong Khai',
  'TH-12': 'Nonthaburi',
  'TH-13': 'Pathum Thani',
  'TH-94': 'Pattani',
  'TH-82': 'Phang-nga',
  'TH-93': 'Phatthalung',
  'TH-56': 'Phayao',
  'TH-67': 'Phetchabun',
  'TH-76': 'Phetchaburi',
  'TH-66': 'Phichit',
  'TH-65': 'Phitsanulok',
  'TH-14': 'Phra Nakhon Si Ayutthaya',
  'TH-54': 'Phrae',
  'TH-83': 'Phuket',
  'TH-25': 'Prachin Buri',
  'TH-77': 'Prachuap Khiri Khan',
  'TH-85': 'Ranong',
  'TH-70': 'Ratchaburi',
  'TH-21': 'Rayong',
  'TH-45': 'Roi Et',
  'TH-27': 'Sa Kaeo',
  'TH-47': 'Sakon Nakhon',
  'TH-11': 'Samut Prakan',
  'TH-74': 'Samut Sakhon',
  'TH-75': 'Samut Songkhram',
  'TH-19': 'Saraburi',
  'TH-91': 'Satun',
  'TH-33': 'Si Sa Ket',
  'TH-17': 'Sing Buri',
  'TH-90': 'Songkhla',
  'TH-64': 'Sukhothai',
  'TH-72': 'Suphan Buri',
  'TH-84': 'Surat Thani',
  'TH-32': 'Surin',
  'TH-63': 'Tak',
  'TH-92': 'Trang',
  'TH-23': 'Trat',
  'TH-34': 'Ubon Ratchathani',
  'TH-41': 'Udon Thani',
  'TH-61': 'Uthai Thani',
  'TH-53': 'Uttaradit',
  'TH-95': 'Yala',
  'TH-35': 'Yasothon',
  TL: 'Timor-Leste',
  'TL-AL': 'Aileu',
  'TL-AN': 'Ainaro',
  'TL-BA': 'Baucau',
  'TL-BO': 'Bobonaro',
  'TL-CO': 'Cova-Lima',
  'TL-DI': 'Dili',
  'TL-ER': 'Ermera',
  'TL-LA': 'Lautem',
  'TL-LI': 'Liquica',
  'TL-MT': 'Manatuto',
  'TL-MF': 'Manufahi',
  'TL-OE': 'Oecussi-Ambeno',
  'TL-VI': 'Viqueque',
  TG: 'Togo',
  'TG-C': 'Centrale',
  'TG-K': 'Kara',
  'TG-M': 'Maritime',
  TK: 'Tokelau',
  TO: 'Tonga',
  'TO-01': "'Eua",
  'TO-02': "Ha'apai",
  'TO-03': 'Niuas',
  'TO-04': 'Tongatapu',
  'TO-05': "Vava'u",
  TT: 'Trinidad & Tobago',
  'TT-ARI': 'Arima',
  'TT-CHA': 'Chaguanas',
  'TT-CTT': 'Couva-Tabaquite-Talparo',
  'TT-DMN': 'Diego Martin',
  'TT-ETO': 'Eastern Tobago',
  'TT-PED': 'Penal-Debe',
  'TT-PTF': 'Point Fortin',
  'TT-POS': 'Port of Spain',
  'TT-PRT': 'Princes Town',
  'TT-RCM': 'Rio Claro-Mayaro',
  'TT-SFO': 'San Fernando',
  'TT-SJL': 'San Juan-Laventille',
  'TT-SGE': 'Sangre Grande',
  'TT-SIP': 'Siparia',
  'TT-TUP': 'Tunapuna-Piarco',
  'TT-WTO': 'Western Tobago',
  TN: 'Tunisia',
  'TN-12': 'Ariana',
  'TN-31': 'Beja',
  'TN-13': 'Ben Arous',
  'TN-23': 'Bizerte',
  'TN-81': 'Gabes',
  'TN-71': 'Gafsa',
  'TN-32': 'Jendouba',
  'TN-41': 'Kairouan',
  'TN-42': 'Kasserine',
  'TN-73': 'Kebili',
  'TN-33': 'Kef',
  'TN-14': 'La Manouba',
  'TN-53': 'Mahdia',
  'TN-82': 'Medenine',
  'TN-52': 'Monastir',
  'TN-21': 'Nabeul',
  'TN-61': 'Sfax',
  'TN-43': 'Sidi Bou Zid',
  'TN-34': 'Siliana',
  'TN-51': 'Sousse',
  'TN-83': 'Tataouine',
  'TN-72': 'Tozeur',
  'TN-11': 'Tunis',
  'TN-22': 'Zaghouan',
  TR: 'Turkey',
  'TR-01': 'Adana',
  'TR-02': 'Adıyaman Province',
  'TR-03': 'Afyonkarahisar Province',
  'TR-04': 'Ağrı',
  'TR-68': 'Aksaray',
  'TR-05': 'Amasya Province',
  'TR-06': 'Ankara',
  'TR-07': 'Antalya',
  'TR-75': 'Ardahan Province',
  'TR-08': 'Artvin',
  'TR-09': 'Aydın Province',
  'TR-10': 'Balıkesir Province',
  'TR-74': 'Bartın Province',
  'TR-72': 'Batman',
  'TR-69': 'Bayburt',
  'TR-11': 'Bilecik',
  'TR-12': 'Bingöl Province',
  'TR-13': 'Bitlis',
  'TR-14': 'Bolu',
  'TR-15': 'Burdur Province',
  'TR-16': 'Bursa',
  'TR-17': 'Çanakkale Province',
  'TR-18': 'Çankırı',
  'TR-19': 'Çorum Province',
  'TR-20': 'Denizli',
  'TR-21': 'Diyarbakır',
  'TR-81': 'Düzce Province',
  'TR-22': 'Edirne',
  'TR-23': 'Elazığ',
  'TR-24': 'Erzincan',
  'TR-25': 'Erzurum',
  'TR-26': 'Eskişehir Province',
  'TR-27': 'Gaziantep',
  'TR-28': 'Giresun',
  'TR-29': 'Gümüşhane',
  'TR-30': 'Hakkâri',
  'TR-31': 'Hatay',
  'TR-76': 'Iğdır',
  'TR-32': 'Isparta Province',
  'TR-34': 'İstanbul',
  'TR-35': 'İzmir',
  'TR-46': 'Kahramanmaraş Province',
  'TR-78': 'Karabük',
  'TR-70': 'Karaman',
  'TR-36': 'Kars',
  'TR-37': 'Kastamonu',
  'TR-38': 'Kayseri Province',
  'TR-79': 'Kilis',
  'TR-71': 'Kırıkkale',
  'TR-39': 'Kırklareli',
  'TR-40': 'Kırşehir',
  'TR-41': 'Kocaeli',
  'TR-42': 'Konya',
  'TR-43': 'Kütahya',
  'TR-44': 'Malatya',
  'TR-45': 'Manisa',
  'TR-47': 'Mardin',
  'TR-33': 'Mersin Province',
  'TR-48': 'Muğla Province',
  'TR-49': 'Muş',
  'TR-50': 'Nevşehir',
  'TR-51': 'Niğde',
  'TR-52': 'Ordu',
  'TR-80': 'Osmaniye',
  'TR-53': 'Rize',
  'TR-54': 'Sakarya',
  'TR-55': 'Samsun',
  'TR-63': 'Şanlıurfa Province',
  'TR-56': 'Siirt Province',
  'TR-57': 'Sinop Province',
  'TR-58': 'Sivas',
  'TR-73': 'Şırnak',
  'TR-59': 'Tekirdağ',
  'TR-60': 'Tokat',
  'TR-61': 'Trabzon',
  'TR-62': 'Tunceli',
  'TR-64': 'Uşak',
  'TR-65': 'Van',
  'TR-77': 'Yalova Province',
  'TR-66': 'Yozgat',
  'TR-67': 'Zonguldak',
  TM: 'Turkmenistan',
  'TM-A': 'Ahal',
  'TM-S': 'Ashgabat',
  'TM-B': 'Balkan',
  'TM-D': 'Dashoguz Province',
  'TM-L': 'Lebap',
  'TM-M': 'Mary',
  TC: 'Turks & Caicos Islands',
  TV: 'Tuvalu',
  'TV-FUN': 'Funafuti',
  'TV-NMG': 'Nanumanga',
  'TV-NMA': 'Nanumea',
  'TV-NIT': 'Niutao',
  'TV-NIU': 'Nui',
  'TV-NKF': 'Nukufetau',
  'TV-NKL': 'Nukulaelae',
  'TV-VAI': 'Vaitupu',
  UM: 'U.S. Outlying Islands',
  VI: 'U.S. Virgin Islands',
  UG: 'Uganda',
  UA: 'Ukraine',
  'UA-71': "Cherkas'ka oblast",
  'UA-74': "Chernihivs'ka oblast",
  'UA-77': "Chernivets'ka oblast",
  'UA-43': 'Crimea',
  'UA-12': 'Dnipropetrovsk Oblast',
  'UA-14': 'Donetsk Oblast',
  'UA-26': "Ivano-Frankivs'ka oblast",
  'UA-63': 'Kharkiv Oblast',
  'UA-65': "Khersons'ka oblast",
  'UA-68': "Khmel'nyts'ka oblast",
  'UA-35': "Kirovohrads'ka oblast",
  'UA-30': 'Kyiv city',
  'UA-32': "Kyivs'ka oblast",
  'UA-09': "Luhans'ka oblast",
  'UA-46': 'Lviv Oblast',
  'UA-48': "Mykolaivs'ka oblast",
  'UA-51': 'Odessa Oblast',
  'UA-53': "Poltavs'ka oblast",
  'UA-56': "Rivnens'ka oblast",
  'UA-40': "Sevastopol' city",
  'UA-59': "Sums'ka oblast",
  'UA-61': "Ternopil's'ka oblast",
  'UA-05': "Vinnyts'ka oblast",
  'UA-07': "Volyns'ka oblast",
  'UA-21': "Zakarpats'ka oblast",
  'UA-23': "Zaporiz'ka oblast",
  'UA-18': "Zhytomyrs'ka oblast",
  AE: 'United Arab Emirates',
  'AE-AZ': 'Abu Dhabi',
  'AE-AJ': 'Ajman',
  'AE-DU': 'Dubai',
  'AE-FU': 'Fujairah',
  'AE-RK': 'Ras al Khaimah',
  'AE-SH': 'Sharjah',
  'AE-UQ': 'Umm Al Quwain',
  GB: 'United Kingdom',
  'GB-ENG': 'England',
  'GB-NIR': 'Northern Ireland',
  'GB-SCT': 'Scotland',
  'GB-WLS': 'Wales',
  US: 'United States',
  'US-AL': 'Alabama',
  'US-AL-630': 'Birmingham AL',
  'US-GA-522': 'Columbus GA',
  'US-GA-606': 'Dothan AL',
  'US-AL-691': 'Huntsville-Decatur (Florence) AL',
  'US-MS-711': 'Meridian MS',
  'US-FL-686': 'Mobile AL-Pensacola (Ft. Walton Beach) FL',
  'US-AL-698': 'Montgomery (Selma) AL',
  'US-AK': 'Alaska',
  'US-AK-743': 'Anchorage AK',
  'US-AK-745': 'Fairbanks AK',
  'US-AK-747': 'Juneau AK',
  'US-AZ': 'Arizona',
  'US-AZ-753': 'Phoenix AZ',
  'US-AZ-789': 'Tucson (Sierra Vista) AZ',
  'US-CA-771': 'Yuma AZ-El Centro CA',
  'US-AR': 'Arkansas',
  'US-OK-670': 'Ft. Smith-Fayetteville-Springdale-Rogers AR',
  'US-AR-734': 'Jonesboro AR',
  'US-AR-693': 'Little Rock-Pine Bluff AR',
  'US-TN-640': 'Memphis TN',
  'US-LA-628': 'Monroe LA-El Dorado AR',
  'US-TX-612': 'Shreveport LA',
  'US-MO-619': 'Springfield MO',
  'US-CA': 'California',
  'US-CA-800': 'Bakersfield CA',
  'US-CA-868': 'Chico-Redding CA',
  'US-CA-802': 'Eureka CA',
  'US-CA-866': 'Fresno-Visalia CA',
  'US-CA-803': 'Los Angeles CA',
  'US-OR-813': 'Medford-Klamath Falls OR',
  'US-CA-828': 'Monterey-Salinas CA',
  'US-CA-804': 'Palm Springs CA',
  'US-NV-811': 'Reno NV',
  'US-CA-862': 'Sacramento-Stockton-Modesto CA',
  'US-CA-825': 'San Diego CA',
  'US-CA-807': 'San Francisco-Oakland-San Jose CA',
  'US-CA-855': 'Santa Barbara-Santa Maria-San Luis Obispo CA',
  'US-CO': 'Colorado',
  'US-CO-752': 'Colorado Springs-Pueblo CO',
  'US-WY-751': 'Denver CO',
  'US-CO-773': 'Grand Junction-Montrose CO',
  'US-CT': 'Connecticut',
  'US-CT-533': 'Hartford & New Haven CT',
  'US-DE': 'Delaware',
  'US-PA-504': 'Philadelphia PA',
  'US-MD-576': 'Salisbury MD',
  'US-DC': 'District of Columbia',
  'US-FL': 'Florida',
  'US-FL-571': 'Ft. Myers-Naples FL',
  'US-FL-592': 'Gainesville FL',
  'US-GA-561': 'Jacksonville FL',
  'US-FL-528': 'Miami-Ft. Lauderdale FL',
  'US-FL-534': 'Orlando-Daytona Beach-Melbourne FL',
  'US-FL-656': 'Panama City FL',
  'US-GA-530': 'Tallahassee FL-Thomasville GA',
  'US-FL-539': 'Tampa-St. Petersburg (Sarasota) FL',
  'US-FL-548': 'West Palm Beach-Ft. Pierce FL',
  'US-GA-525': 'Albany GA',
  'US-GA-524': 'Atlanta GA',
  'US-SC-520': 'Augusta GA',
  'US-TN-575': 'Chattanooga TN',
  'US-GA-503': 'Macon GA',
  'US-SC-507': 'Savannah GA',
  'US-HI': 'Hawaii',
  'US-HI-744': 'Honolulu HI',
  'US-ID': 'Idaho',
  'US-OR-757': 'Boise ID',
  'US-WY-758': 'Idaho Falls-Pocatello ID',
  'US-WA-881': 'Spokane WA',
  'US-ID-760': 'Twin Falls ID',
  'US-IL': 'Illinois',
  'US-IL-648': 'Champaign & Springfield-Decatur IL',
  'US-IN-602': 'Chicago IL',
  'US-IA-682': 'Davenport IA-Rock Island-Moline IL',
  'US-KY-649': 'Evansville IN',
  'US-MO-632': 'Paducah KY-Cape Girardeau MO-Harrisburg-Mount Vernon IL',
  'US-IL-675': 'Peoria-Bloomington IL',
  'US-MO-717': 'Quincy IL-Hannibal MO-Keokuk IA',
  'US-IL-610': 'Rockford IL',
  'US-MO-609': 'St. Louis MO',
  'US-IN-581': 'Terre Haute IN',
  'US-IN': 'Indiana',
  'US-OH-515': 'Cincinnati OH',
  'US-OH-509': 'Ft. Wayne IN',
  'US-IN-527': 'Indianapolis IN',
  'US-IN-582': 'Lafayette IN',
  'US-KY-529': 'Louisville KY',
  'US-MI-588': 'South Bend-Elkhart IN',
  'US-IA': 'Iowa',
  'US-IA-637': 'Cedar Rapids-Waterloo-Iowa City & Dubuque IA',
  'US-IA-679': 'Des Moines-Ames IA',
  'US-NE-652': 'Omaha NE',
  'US-MO-631': 'Ottumwa IA-Kirksville MO',
  'US-MN-611': 'Rochester MN-Mason City IA-Austin MN',
  'US-NE-624': 'Sioux City IA',
  'US-KS': 'Kansas',
  'US-MO-603': 'Joplin MO-Pittsburg KS',
  'US-MO-616': 'Kansas City MO',
  'US-MO-638': 'St. Joseph MO',
  'US-KS-605': 'Topeka KS',
  'US-KS-678': 'Wichita-Hutchinson KS',
  'US-KY': 'Kentucky',
  'US-KY-736': 'Bowling Green KY',
  'US-WV-564': 'Charleston-Huntington WV',
  'US-TN-557': 'Knoxville TN',
  'US-KY-541': 'Lexington KY',
  'US-TN-659': 'Nashville TN',
  'US-VA-531': 'Tri-Cities TN-VA',
  'US-LA': 'Louisiana',
  'US-LA-644': 'Alexandria LA',
  'US-MS-716': 'Baton Rouge LA',
  'US-LA-642': 'Lafayette LA',
  'US-LA-643': 'Lake Charles LA',
  'US-LA-622': 'New Orleans LA',
  'US-ME': 'Maine',
  'US-ME-537': 'Bangor ME',
  'US-NH-500': 'Portland-Auburn ME',
  'US-ME-552': 'Presque Isle ME',
  'US-MD-512': 'Baltimore MD',
  'US-WV-511': 'Washington DC (Hagerstown MD)',
  'US-MA': 'Massachusetts',
  'US-NH-506': 'Boston MA-Manchester NH',
  'US-MA-521': 'Providence RI-New Bedford MA',
  'US-MA-543': 'Springfield-Holyoke MA',
  'US-MI': 'Michigan',
  'US-MI-583': 'Alpena MI',
  'US-MI-505': 'Detroit MI',
  'US-MI-513': 'Flint-Saginaw-Bay City MI',
  'US-MI-563': 'Grand Rapids-Kalamazoo-Battle Creek MI',
  'US-MI-551': 'Lansing MI',
  'US-MI-553': 'Marquette MI',
  'US-OH-547': 'Toledo OH',
  'US-MI-540': 'Traverse City-Cadillac MI',
  'US-MN': 'Minnesota',
  'US-WI-676': 'Duluth MN-Superior WI',
  'US-ND-724': 'Fargo-Valley City ND',
  'US-WI-702': 'La Crosse-Eau Claire WI',
  'US-MN-737': 'Mankato MN',
  'US-WI-613': 'Minneapolis-St. Paul MN',
  'US-MS': 'Mississippi',
  'US-MS-746': 'Biloxi-Gulfport MS',
  'US-MS-673': 'Columbus-Tupelo-West Point MS',
  'US-MS-647': 'Greenwood-Greenville MS',
  'US-MS-710': 'Hattiesburg-Laurel MS',
  'US-MS-718': 'Jackson MS',
  'US-MO': 'Missouri',
  'US-MO-604': 'Columbia-Jefferson City MO',
  'US-MT': 'Montana',
  'US-WY-756': 'Billings, MT',
  'US-MT-754': 'Butte-Bozeman MT',
  'US-MT-798': 'Glendive MT',
  'US-MT-755': 'Great Falls MT',
  'US-MT-766': 'Helena MT',
  'US-ND-687': 'Minot-Bismarck-Dickinson(Williston) ND',
  'US-MT-762': 'Missoula MT',
  'US-NE': 'Nebraska',
  'US-WY-759': 'Cheyenne WY-Scottsbluff NE',
  'US-NE-722': 'Lincoln & Hastings-Kearney NE',
  'US-NE-740': 'North Platte NE',
  'US-SD-725': 'Sioux Falls(Mitchell) SD',
  'US-NV': 'Nevada',
  'US-NV-839': 'Las Vegas NV',
  'US-WY-770': 'Salt Lake City UT',
  'US-NH': 'New Hampshire',
  'US-VT-523': 'Burlington VT-Plattsburgh NY',
  'US-NJ': 'New Jersey',
  'US-NY-501': 'New York NY',
  'US-NM': 'New Mexico',
  'US-NM-790': 'Albuquerque-Santa Fe NM',
  'US-TX-634': 'Amarillo TX',
  'US-TX-765': 'El Paso TX',
  'US-NY': 'New York',
  'US-NY-532': 'Albany-Schenectady-Troy NY',
  'US-NY-502': 'Binghamton NY',
  'US-PA-514': 'Buffalo NY',
  'US-PA-565': 'Elmira NY',
  'US-NY-538': 'Rochester NY',
  'US-NY-555': 'Syracuse NY',
  'US-NY-526': 'Utica NY',
  'US-NY-549': 'Watertown NY',
  'US-NC': 'North Carolina',
  'US-SC-517': 'Charlotte NC',
  'US-SC-570': 'Florence-Myrtle Beach SC',
  'US-NC-518': 'Greensboro-High Point-Winston Salem NC',
  'US-NC-545': 'Greenville-New Bern-Washington NC',
  'US-SC-567': 'Greenville-Spartanburg SC-Asheville NC-Anderson SC',
  'US-VA-544': 'Norfolk-Portsmouth-Newport News VA',
  'US-NC-560': 'Raleigh-Durham (Fayetteville) NC',
  'US-NC-550': 'Wilmington NC',
  'US-ND': 'North Dakota',
  'US-OH': 'Ohio',
  'US-OH-510': 'Cleveland-Akron (Canton) OH',
  'US-OH-535': 'Columbus OH',
  'US-OH-542': 'Dayton OH',
  'US-OH-558': 'Lima OH',
  'US-WV-597': 'Parkersburg WV',
  'US-WV-554': 'Wheeling WV-Steubenville OH',
  'US-PA-536': 'Youngstown OH',
  'US-OH-596': 'Zanesville OH',
  'US-OK': 'Oklahoma',
  'US-OK-650': 'Oklahoma City OK',
  'US-TX-657': 'Sherman TX-Ada OK',
  'US-OK-671': 'Tulsa OK',
  'US-TX-627': 'Wichita Falls TX & Lawton OK',
  'US-OR': 'Oregon',
  'US-OR-821': 'Bend OR',
  'US-OR-801': 'Eugene OR',
  'US-WA-820': 'Portland OR',
  'US-WA-810': 'Yakima-Pasco-Richland-Kennewick WA',
  'US-PA': 'Pennsylvania',
  'US-PA-516': 'Erie PA',
  'US-PA-566': 'Harrisburg-Lancaster-Lebanon-York PA',
  'US-PA-574': 'Johnstown-Altoona PA',
  'US-PA-508': 'Pittsburgh PA',
  'US-PA-577': 'Wilkes Barre-Scranton PA',
  'US-RI': 'Rhode Island',
  'US-SC': 'South Carolina',
  'US-SC-519': 'Charleston SC',
  'US-SC-546': 'Columbia SC',
  'US-SD': 'South Dakota',
  'US-WY-764': 'Rapid City SD',
  'US-TN': 'Tennessee',
  'US-TN-639': 'Jackson TN',
  'US-TX': 'Texas',
  'US-TX-662': 'Abilene-Sweetwater TX',
  'US-TX-635': 'Austin TX',
  'US-TX-692': 'Beaumont-Port Arthur TX',
  'US-TX-600': 'Corpus Christi TX',
  'US-TX-623': 'Dallas-Ft. Worth TX',
  'US-TX-636': 'Harlingen-Weslaco-Brownsville-McAllen TX',
  'US-TX-618': 'Houston TX',
  'US-TX-749': 'Laredo TX',
  'US-TX-651': 'Lubbock TX',
  'US-TX-633': 'Odessa-Midland TX',
  'US-TX-661': 'San Angelo TX',
  'US-TX-641': 'San Antonio TX',
  'US-TX-709': 'Tyler-Longview(Lufkin & Nacogdoches) TX',
  'US-TX-626': 'Victoria TX',
  'US-TX-625': 'Waco-Temple-Bryan TX',
  'US-UT': 'Utah',
  'US-VT': 'Vermont',
  'US-VA': 'Virginia',
  'US-WV-559': 'Bluefield-Beckley-Oak Hill WV',
  'US-VA-584': 'Charlottesville VA',
  'US-WV-569': 'Harrisonburg VA',
  'US-VA-556': 'Richmond-Petersburg VA',
  'US-VA-573': 'Roanoke-Lynchburg VA',
  'US-WA': 'Washington',
  'US-WA-819': 'Seattle-Tacoma WA',
  'US-WV': 'West Virginia',
  'US-WV-598': 'Clarksburg-Weston WV',
  'US-WI': 'Wisconsin',
  'US-WI-658': 'Green Bay-Appleton WI',
  'US-WI-669': 'Madison WI',
  'US-WI-617': 'Milwaukee WI',
  'US-WI-705': 'Wausau-Rhinelander WI',
  'US-WY': 'Wyoming',
  'US-WY-767': 'Casper-Riverton WY',
  UY: 'Uruguay',
  'UY-AR': 'Artigas Department',
  'UY-CA': 'Canelones Department',
  'UY-CL': 'Cerro Largo Department',
  'UY-CO': 'Colonia',
  'UY-DU': 'Durazno Department',
  'UY-FS': 'Flores Department',
  'UY-FD': 'Florida Department',
  'UY-LA': 'Lavalleja Department',
  'UY-MA': 'Maldonado Department',
  'UY-MO': 'Montevideo Department',
  'UY-PA': 'Paysandú Department',
  'UY-RN': 'Río Negro Department',
  'UY-RV': 'Rivera Department',
  'UY-RO': 'Rocha Department',
  'UY-SA': 'Salto',
  'UY-SJ': 'San José Department',
  'UY-SO': 'Soriano Department',
  'UY-TA': 'Tacuarembó Department',
  'UY-TT': 'Treinta y Tres Department',
  UZ: 'Uzbekistan',
  'UZ-AN': 'Andijan Province',
  'UZ-BU': 'Bukhara Province',
  'UZ-FA': 'Ferghana Province',
  'UZ-JI': 'Jizzakh Province',
  'UZ-QA': 'Kashkadarya Province',
  'UZ-XO': 'Khorezm Province',
  'UZ-NG': 'Namangan Province',
  'UZ-NW': 'Navoiy Province',
  'UZ-QR': 'Republic of Karakalpakstan',
  'UZ-SA': 'Samarkand Province',
  'UZ-SI': 'Sirdarya Province',
  'UZ-SU': 'Surkhandarya Province',
  'UZ-TO': 'Tashkent Province',
  VU: 'Vanuatu',
  'VU-MAP': 'Malampa Province',
  'VU-PAM': 'Penama Province',
  'VU-SAM': 'Sanma Province',
  'VU-SEE': 'Shefa Province',
  'VU-TAE': 'Tafea Province',
  'VU-TOB': 'Torba Province',
  VA: 'Vatican City',
  VE: 'Venezuela',
  'VE-B': 'Anzoategui',
  'VE-C': 'Apure',
  'VE-D': 'Aragua',
  'VE-E': 'Barinas',
  'VE-F': 'Bolívar',
  'VE-A': 'Capital District',
  'VE-G': 'Carabobo',
  'VE-H': 'Cojedes',
  'VE-Y': 'Delta Amacuro',
  'VE-I': 'Falcón',
  'VE-W': 'Federal Dependencies',
  'VE-J': 'Guárico',
  'VE-K': 'Lara',
  'VE-L': 'Mérida',
  'VE-M': 'Miranda',
  'VE-N': 'Monagas',
  'VE-O': 'Nueva Esparta',
  'VE-P': 'Portuguesa',
  'VE-S': 'Táchira',
  'VE-T': 'Trujillo',
  'VE-X': 'Vargas',
  'VE-U': 'Yaracuy',
  'VE-V': 'Zulia',
  VN: 'Vietnam',
  'VN-44': 'An Giang Province',
  'VN-43': 'Ba Ria - Vung Tau',
  'VN-54': 'Bac Giang',
  'VN-53': 'Bắc Kạn Province',
  'VN-55': 'Bac Lieu',
  'VN-56': 'Bac Ninh Province',
  'VN-50': 'Ben Tre',
  'VN-31': 'Binh Dinh Province',
  'VN-57': 'Binh Duong',
  'VN-58': 'Binh Phuoc',
  'VN-40': 'Binh Thuan',
  'VN-59': 'Ca Mau',
  'VN-CT': 'Can Tho',
  'VN-04': 'Cao Bang',
  'VN-DN': 'Da Nang',
  'VN-33': 'Đắk Lắk Province',
  'VN-72': 'Dak Nong',
  'VN-71': 'Dien Bien',
  'VN-39': 'Dong Nai',
  'VN-45': 'Đồng Tháp Province',
  'VN-30': 'Gia Lai Province',
  'VN-03': 'Ha Giang',
  'VN-63': 'Hà Nam Province',
  'VN-23': 'Ha Tinh Province',
  'VN-61': 'Hai Duong',
  'VN-HP': 'Haiphong',
  'VN-HN': 'Hanoi',
  'VN-73': 'Hau Giang',
  'VN-SG': 'Ho Chi Minh',
  'VN-14': 'Hoa Binh',
  'VN-66': 'Hung Yen Province',
  'VN-34': 'Khanh Hoa Province',
  'VN-47': 'Kien Giang',
  'VN-28': 'Kon Tum Province',
  'VN-01': 'Lai Chau',
  'VN-35': 'Lâm Đồng',
  'VN-09': 'Lang Son Province',
  'VN-02': 'Lao Cai',
  'VN-41': 'Long An Province',
  'VN-67': 'Nam Dinh',
  'VN-22': 'Nghe An',
  'VN-18': 'Ninh Bình Province',
  'VN-36': 'Ninh Thuan Province',
  'VN-68': 'Phu Tho Province',
  'VN-32': 'Phú Yên Province',
  'VN-24': 'Quang Binh Province',
  'VN-27': 'Quang Nam Province',
  'VN-29': 'Quang Ngai',
  'VN-13': 'Quảng Ninh Province',
  'VN-25': 'Quảng Trị Province',
  'VN-52': 'Soc Trang',
  'VN-05': 'Son La',
  'VN-37': 'Tây Ninh Province',
  'VN-20': 'Thai Binh',
  'VN-69': 'Thai Nguyen',
  'VN-21': 'Thanh Hoa',
  'VN-26': 'Thua Thien Hue',
  'VN-46': 'Tien Giang',
  'VN-51': 'Tra Vinh',
  'VN-07': 'Tuyên Quang Province',
  'VN-49': 'Vinh Long',
  'VN-70': 'Vinh Phuc Province',
  'VN-06': 'Yen Bai Province',
  WF: 'Wallis & Futuna',
  'WF-ALO': 'Alo',
  EH: 'Western Sahara',
  YE: 'Yemen',
  'YE-AM': "'Amran",
  'YE-AB': 'Abyan',
  'YE-DA': "Ad Dali'",
  'YE-AD': 'Aden',
  'YE-BA': "Al Bayda'",
  'YE-HU': 'Al Hudaydah',
  'YE-JA': 'Al Jawf',
  'YE-MR': 'Al Mahrah',
  'YE-MW': 'Al Mahwit',
  'YE-SA': 'Amanat Al Asimah',
  'YE-DH': 'Dhamar',
  'YE-HD': 'Hadramaut',
  'YE-HJ': 'Hajjah',
  'YE-IB': 'Ibb',
  'YE-LA': 'Lahij',
  'YE-MA': "Ma'rib",
  'YE-RA': 'Raymah',
  'YE-SD': "Sa'dah",
  'YE-SN': "Sana'a",
  'YE-SH': 'Shabwah',
  'YE-TA': "Ta'izz",
  ZM: 'Zambia',
  'ZM-08': 'Copperbelt',
  'ZM-03': 'Eastern',
  'ZM-04': 'Luapula',
  'ZM-09': 'Lusaka',
  'ZM-06': 'North-Western',
  'ZM-01': 'Western',
  ZW: 'Zimbabwe',
  'ZW-BU': 'Bulawayo',
  'ZW-HA': 'Harare',
  'ZW-MA': 'Manicaland',
  'ZW-MC': 'Mashonaland Central',
  'ZW-ME': 'Mashonaland East',
  'ZW-MW': 'Mashonaland West',
  'ZW-MV': 'Masvingo',
  'ZW-MN': 'Matabeleland North',
  'ZW-MS': 'Matabeleland South',
  'ZW-MI': 'Midlands',
};

export const countries = [
  {
    name: 'Afghanistan',
    flag: '🇦🇫',
    code: 'AF',
    langCode: 'fa',
  },
  {
    name: 'Åland Islands',
    flag: '🇦🇽',
    code: 'AX',
    langCode: 'sv',
  },
  {
    name: 'Albania',
    flag: '🇦🇱',
    code: 'AL',
    langCode: 'sq',
  },
  {
    name: 'Algeria',
    flag: '🇩🇿',
    code: 'DZ',
    langCode: 'ar',
  },
  {
    name: 'American Samoa',
    flag: '🇦🇸',
    code: 'AS',
    langCode: 'en',
  },
  {
    name: 'Andorra',
    flag: '🇦🇩',
    code: 'AD',
    langCode: 'ca',
  },
  {
    name: 'Angola',
    flag: '🇦🇴',
    code: 'AO',
    langCode: 'pt',
  },
  {
    name: 'Anguilla',
    flag: '🇦🇮',
    code: 'AI',
    langCode: 'en',
  },
  {
    name: 'Antarctica',
    flag: '🇦🇶',
    code: 'AQ',
    langCode: 'en',
  },
  {
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
    code: 'AG',
    langCode: 'en',
  },
  {
    name: 'Argentina',
    flag: '🇦🇷',
    code: 'AR',
    langCode: 'es',
  },
  {
    name: 'Armenia',
    flag: '🇦🇲',
    code: 'AM',
    langCode: 'hy',
  },
  {
    name: 'Aruba',
    flag: '🇦🇼',
    code: 'AW',
    langCode: 'nl',
  },
  {
    name: 'Australia',
    flag: '🇦🇺',
    code: 'AU',
    langCode: 'en',
  },
  {
    name: 'Austria',
    flag: '🇦🇹',
    code: 'AT',
    langCode: 'de',
  },
  {
    name: 'Azerbaijan',
    flag: '🇦🇿',
    code: 'AZ',
    langCode: 'az',
  },
  {
    name: 'Bahamas',
    flag: '🇧🇸',
    code: 'BS',
    langCode: 'en',
  },
  {
    name: 'Bahrain',
    flag: '🇧🇭',
    code: 'BH',
    langCode: 'ar',
  },
  {
    name: 'Bangladesh',
    flag: '🇧🇩',
    code: 'BD',
    langCode: 'bn',
  },
  {
    name: 'Barbados',
    flag: '🇧🇧',
    code: 'BB',
    langCode: 'en',
  },
  {
    name: 'Belarus',
    flag: '🇧🇾',
    code: 'BY',
    langCode: 'be',
  },
  {
    name: 'Belgium',
    flag: '🇧🇪',
    code: 'BE',
    langCode: 'nl',
  },
  {
    name: 'Belize',
    flag: '🇧🇿',
    code: 'BZ',
    langCode: 'en',
  },
  {
    name: 'Benin',
    flag: '🇧🇯',
    code: 'BJ',
    langCode: 'fr',
  },
  {
    name: 'Bermuda',
    flag: '🇧🇲',
    code: 'BM',
    langCode: 'en',
  },
  {
    name: 'Bhutan',
    flag: '🇧🇹',
    code: 'BT',
    langCode: 'dz',
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    code: 'BO',
    langCode: 'es',
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    code: 'BA',
    langCode: 'bs',
  },
  {
    name: 'Botswana',
    flag: '🇧🇼',
    code: 'BW',
    langCode: 'en',
  },
  {
    name: 'Bouvet Island',
    flag: '🇧🇻',
    code: 'BV',
    langCode: 'no',
  },
  {
    name: 'Brazil',
    flag: '🇧🇷',
    code: 'BR',
    langCode: 'pt',
  },
  {
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    code: 'IO',
    langCode: 'en',
  },
  {
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    code: 'BN',
    langCode: 'ms',
  },
  {
    name: 'Bulgaria',
    flag: '🇧🇬',
    code: 'BG',
    langCode: 'bg',
  },
  {
    name: 'Burkina Faso',
    flag: '🇧🇫',
    code: 'BF',
    langCode: 'fr',
  },
  {
    name: 'Burundi',
    flag: '🇧🇮',
    code: 'BI',
    langCode: 'fr',
  },
  {
    name: 'Cambodia',
    flag: '🇰🇭',
    code: 'KH',
    langCode: 'km',
  },
  {
    name: 'Cameroon',
    flag: '🇨🇲',
    code: 'CM',
    langCode: 'fr',
  },
  {
    name: 'Canada',
    flag: '🇨🇦',
    code: 'CA',
    langCode: 'en',
  },
  {
    name: 'Cape Verde',
    flag: '🇨🇻',
    code: 'CV',
    langCode: 'pt',
  },
  {
    name: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'KY',
    langCode: 'en',
  },
  {
    name: 'Central African Republic',
    flag: '🇨🇫',
    code: 'CF',
    langCode: 'fr',
  },
  {
    name: 'Chad',
    flag: '🇹🇩',
    code: 'TD',
    langCode: 'fr',
  },
  {
    name: 'Chile',
    flag: '🇨🇱',
    code: 'CL',
    langCode: 'es',
  },
  {
    name: 'China',
    flag: '🇨🇳',
    code: 'CN',
    langCode: 'zh',
  },
  {
    name: 'Christmas Island',
    flag: '🇨🇽',
    code: 'CX',
    langCode: 'en',
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    code: 'CC',
    langCode: 'en',
  },
  {
    name: 'Colombia',
    flag: '🇨🇴',
    code: 'CO',
    langCode: 'es',
  },
  {
    name: 'Comoros',
    flag: '🇰🇲',
    code: 'KM',
    langCode: 'ar',
  },
  {
    name: 'Congo',
    flag: '🇨🇬',
    code: 'CG',
    langCode: 'fr',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    code: 'CD',
    langCode: 'fr',
  },
  {
    name: 'Cook Islands',
    flag: '🇨🇰',
    code: 'CK',
    langCode: 'en',
  },
  {
    name: 'Costa Rica',
    flag: '🇨🇷',
    code: 'CR',
    langCode: 'es',
  },
  {
    name: "Cote d'Ivoire",
    flag: '🇨🇮',
    code: 'CI',
    langCode: 'fr',
  },
  {
    name: 'Croatia',
    flag: '🇭🇷',
    code: 'HR',
    langCode: 'hr',
  },
  {
    name: 'Cuba',
    flag: '🇨🇺',
    code: 'CU',
    langCode: 'es',
  },
  {
    name: 'Cyprus',
    flag: '🇨🇾',
    code: 'CY',
    langCode: 'el',
  },
  {
    name: 'Czech Republic',
    flag: '🇨🇿',
    code: 'CZ',
    langCode: 'cs',
  },
  {
    name: 'Denmark',
    flag: '🇩🇰',
    code: 'DK',
    langCode: 'da',
  },
  {
    name: 'Djibouti',
    flag: '🇩🇯',
    code: 'DJ',
    langCode: 'fr',
  },
  {
    name: 'Dominica',
    flag: '🇩🇲',
    code: 'DM',
    langCode: 'en',
  },
  {
    name: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'DO',
    langCode: 'es',
  },
  {
    name: 'Ecuador',
    flag: '🇪🇨',
    code: 'EC',
    langCode: 'es',
  },
  {
    name: 'Egypt',
    flag: '🇪🇬',
    code: 'EG',
    langCode: 'ar',
  },
  {
    name: 'El Salvador',
    flag: '🇸🇻',
    code: 'SV',
    langCode: 'es',
  },
  {
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    code: 'GQ',
    langCode: 'es',
  },
  {
    name: 'Eritrea',
    flag: '🇪🇷',
    code: 'ER',
    langCode: 'ti',
  },
  {
    name: 'Estonia',
    flag: '🇪🇪',
    code: 'EE',
    langCode: 'et',
  },
  {
    name: 'Ethiopia',
    flag: '🇪🇹',
    code: 'ET',
    langCode: 'am',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    code: 'FK',
    langCode: 'en',
  },
  {
    name: 'Faroe Islands',
    flag: '🇫🇴',
    code: 'FO',
    langCode: 'fo',
  },
  {
    name: 'Fiji',
    flag: '🇫🇯',
    code: 'FJ',
    langCode: 'en',
  },
  {
    name: 'Finland',
    flag: '🇫🇮',
    code: 'FI',
    langCode: 'fi',
  },
  {
    name: 'France',
    flag: '🇫🇷',
    code: 'FR',
    langCode: 'fr',
  },
  {
    name: 'French Guiana',
    flag: '🇬🇫',
    code: 'GF',
    langCode: 'fr',
  },
  {
    name: 'French Polynesia',
    flag: '🇵🇫',
    code: 'PF',
    langCode: 'fr',
  },
  {
    name: 'French Southern Territories',
    flag: '🇹🇫',
    code: 'TF',
    langCode: 'fr',
  },
  {
    name: 'Gabon',
    flag: '🇬🇦',
    code: 'GA',
    langCode: 'fr',
  },
  {
    name: 'Gambia',
    flag: '🇬🇲',
    code: 'GM',
    langCode: 'en',
  },
  {
    name: 'Georgia',
    flag: '🇬🇪',
    code: 'GE',
    langCode: 'ka',
  },
  {
    name: 'Germany',
    flag: '🇩🇪',
    code: 'DE',
    langCode: 'de',
  },
  {
    name: 'Ghana',
    flag: '🇬🇭',
    code: 'GH',
    langCode: 'en',
  },
  {
    name: 'Gibraltar',
    flag: '🇬🇮',
    code: 'GI',
    langCode: 'en',
  },
  {
    name: 'Greece',
    flag: '🇬🇷',
    code: 'GR',
    langCode: 'el',
  },
  {
    name: 'Greenland',
    flag: '🇬🇱',
    code: 'GL',
    langCode: 'kl',
  },
  {
    name: 'Grenada',
    flag: '🇬🇩',
    code: 'GD',
    langCode: 'en',
  },
  {
    name: 'Guadeloupe',
    flag: '🇬🇵',
    code: 'GP',
    langCode: 'fr',
  },
  {
    name: 'Guam',
    flag: '🇬🇺',
    code: 'GU',
    langCode: 'en',
  },
  {
    name: 'Guatemala',
    flag: '🇬🇹',
    code: 'GT',
    langCode: 'es',
  },
  {
    name: 'Guernsey',
    flag: '🇬🇬',
    code: 'GG',
    langCode: 'en',
  },
  {
    name: 'Guinea',
    flag: '🇬🇳',
    code: 'GN',
    langCode: 'fr',
  },
  {
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
    code: 'GW',
    langCode: 'pt',
  },
  {
    name: 'Guyana',
    flag: '🇬🇾',
    code: 'GY',
    langCode: 'en',
  },
  {
    name: 'Haiti',
    flag: '🇭🇹',
    code: 'HT',
    langCode: 'fr',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    code: 'HM',
    langCode: 'en',
  },
  {
    name: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    code: 'VA',
    langCode: 'it',
  },
  {
    name: 'Honduras',
    flag: '🇭🇳',
    code: 'HN',
    langCode: 'es',
  },
  {
    name: 'Hong Kong',
    flag: '🇭🇰',
    code: 'HK',
    langCode: 'zh',
  },
  {
    name: 'Hungary',
    flag: '🇭🇺',
    code: 'HU',
    langCode: 'hu',
  },
  {
    name: 'Iceland',
    flag: '🇮🇸',
    code: 'IS',
    langCode: 'is',
  },
  {
    name: 'India',
    flag: '🇮🇳',
    code: 'IN',
    langCode: 'hi',
  },
  {
    name: 'Indonesia',
    flag: '🇮🇩',
    code: 'ID',
    langCode: 'id',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    code: 'IR',
    langCode: 'fa',
  },
  {
    name: 'Iraq',
    flag: '🇮🇶',
    code: 'IQ',
    langCode: 'ar',
  },
  {
    name: 'Ireland',
    flag: '🇮🇪',
    code: 'IE',
    langCode: 'ga',
  },
  {
    name: 'Isle of Man',
    flag: '🇮🇲',
    code: 'IM',
    langCode: 'en',
  },
  {
    name: 'Israel',
    flag: '🇮🇱',
    code: 'IL',
    langCode: 'he',
  },
  {
    name: 'Italy',
    flag: '🇮🇹',
    code: 'IT',
    langCode: 'it',
  },
  {
    name: 'Jamaica',
    flag: '🇯🇲',
    code: 'JM',
    langCode: 'en',
  },
  {
    name: 'Japan',
    flag: '🇯🇵',
    code: 'JP',
    langCode: 'ja',
  },
  {
    name: 'Jersey',
    flag: '🇯🇪',
    code: 'JE',
    langCode: 'en',
  },
  {
    name: 'Jordan',
    flag: '🇯🇴',
    code: 'JO',
    langCode: 'ar',
  },
  {
    name: 'Kazakhstan',
    flag: '🇰🇿',
    code: 'KZ',
    langCode: 'kk',
  },
  {
    name: 'Kenya',
    flag: '🇰🇪',
    code: 'KE',
    langCode: 'sw',
  },
  {
    name: 'Kiribati',
    flag: '🇰🇮',
    code: 'KI',
    langCode: 'en',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: '🇰🇵',
    code: 'KP',
    langCode: 'ko',
  },
  {
    name: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    code: 'KR',
    langCode: 'ko',
  },
  {
    name: 'Kosovo',
    flag: '🇽🇰',
    code: 'XK',
    langCode: 'sq',
  },
  {
    name: 'Kuwait',
    flag: '🇰🇼',
    code: 'KW',
    langCode: 'ar',
  },
  {
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    code: 'KG',
    langCode: 'ky',
  },
  {
    name: 'Laos',
    flag: '🇱🇦',
    code: 'LA',
    langCode: 'lo',
  },
  {
    name: 'Latvia',
    flag: '🇱🇻',
    code: 'LV',
    langCode: 'lv',
  },
  {
    name: 'Lebanon',
    flag: '🇱🇧',
    code: 'LB',
    langCode: 'ar',
  },
  {
    name: 'Lesotho',
    flag: '🇱🇸',
    code: 'LS',
    langCode: 'en',
  },
  {
    name: 'Liberia',
    flag: '🇱🇷',
    code: 'LR',
    langCode: 'en',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    code: 'LY',
    langCode: 'ar',
  },
  {
    name: 'Liechtenstein',
    flag: '🇱🇮',
    code: 'LI',
    langCode: 'de',
  },
  {
    name: 'Lithuania',
    flag: '🇱🇹',
    code: 'LT',
    langCode: 'lt',
  },
  {
    name: 'Luxembourg',
    flag: '🇱🇺',
    code: 'LU',
    langCode: 'lb',
  },
  {
    name: 'Macao',
    flag: '🇲🇴',
    code: 'MO',
    langCode: 'zh',
  },
  {
    name: 'Macedonia',
    flag: '🇲🇰',
    code: 'MK',
    langCode: 'mk',
  },
  {
    name: 'Madagascar',
    flag: '🇲🇬',
    code: 'MG',
    langCode: 'fr',
  },
  {
    name: 'Malawi',
    flag: '🇲🇼',
    code: 'MW',
    langCode: 'en',
  },
  {
    name: 'Malaysia',
    flag: '🇲🇾',
    code: 'MY',
    langCode: 'ms',
  },
  {
    name: 'Maldives',
    flag: '🇲🇻',
    code: 'MV',
    langCode: 'dv',
  },
  {
    name: 'Mali',
    flag: '🇲🇱',
    code: 'ML',
    langCode: 'fr',
  },

  {
    name: 'Malta',
    flag: '🇲🇹',
    code: 'MT',
    langCode: 'mt',
  },
  {
    name: 'Marshall Islands',
    flag: '🇲🇭',
    code: 'MH',
    langCode: 'en',
  },
  {
    name: 'Martinique',
    flag: '🇲🇶',
    code: 'MQ',
    langCode: 'fr',
  },
  {
    name: 'Mauritania',
    flag: '🇲🇷',
    code: 'MR',
    langCode: 'ar',
  },
  {
    name: 'Mauritius',
    flag: '🇲🇺',
    code: 'MU',
    langCode: 'en',
  },
  {
    name: 'Mayotte',
    flag: '🇾🇹',
    code: 'YT',
    langCode: 'fr',
  },
  {
    name: 'Mexico',
    flag: '🇲🇽',
    code: 'MX',
    langCode: 'es',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    code: 'FM',
    langCode: 'en',
  },
  {
    name: 'Moldova',
    flag: '🇲🇩',
    code: 'MD',
    langCode: 'ro',
  },
  {
    name: 'Monaco',
    flag: '🇲🇨',
    code: 'MC',
    langCode: 'fr',
  },
  {
    name: 'Mongolia',
    flag: '🇲🇳',
    code: 'MN',
    langCode: 'mn',
  },
  {
    name: 'Montenegro',
    flag: '🇲🇪',
    code: 'ME',
    langCode: 'sr',
  },
  {
    name: 'Montserrat',
    flag: '🇲🇸',
    code: 'MS',
    langCode: 'en',
  },
  {
    name: 'Morocco',
    flag: '🇲🇦',
    code: 'MA',
    langCode: 'ar',
  },
  {
    name: 'Mozambique',
    flag: '🇲🇿',
    code: 'MZ',
    langCode: 'pt',
  },
  {
    name: 'Myanmar',
    flag: '🇲🇲',
    code: 'MM',
    langCode: 'my',
  },
  {
    name: 'Namibia',
    flag: '🇳🇦',
    code: 'NA',
    langCode: 'en',
  },
  {
    name: 'Nauru',
    flag: '🇳🇷',
    code: 'NR',
    langCode: 'na',
  },
  {
    name: 'Nepal',
    flag: '🇳🇵',
    code: 'NP',
    langCode: 'ne',
  },
  {
    name: 'Netherlands',
    flag: '🇳🇱',
    code: 'NL',
    langCode: 'nl',
  },
  {
    name: 'Netherlands Antilles',
    flag: '',
    code: 'AN',
    langCode: 'nl',
  },
  {
    name: 'New Caledonia',
    flag: '🇳🇨',
    code: 'NC',
    langCode: 'fr',
  },
  {
    name: 'New Zealand',
    flag: '🇳🇿',
    code: 'NZ',
    langCode: 'en',
  },
  {
    name: 'Nicaragua',
    flag: '🇳🇮',
    code: 'NI',
    langCode: 'es',
  },
  {
    name: 'Niger',
    flag: '🇳🇪',
    code: 'NE',
    langCode: 'fr',
  },
  {
    name: 'Nigeria',
    flag: '🇳🇬',
    code: 'NG',
    langCode: 'en',
  },
  {
    name: 'Niue',
    flag: '🇳🇺',
    code: 'NU',
    langCode: 'en',
  },
  {
    name: 'Norfolk Island',
    flag: '🇳🇫',
    code: 'NF',
    langCode: 'en',
  },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    langCode: 'en',
  },
  {
    name: 'Norway',
    flag: '🇳🇴',
    code: 'NO',
    langCode: 'no',
  },
  {
    name: 'Oman',
    flag: '🇴🇲',
    code: 'OM',
    langCode: 'ar',
  },
  {
    name: 'Pakistan',
    flag: '🇵🇰',
    code: 'PK',
    langCode: 'ur',
  },
  {
    name: 'Palau',
    flag: '🇵🇼',
    code: 'PW',
    langCode: 'en',
  },
  {
    name: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    code: 'PS',
    langCode: 'ar',
  },
  {
    name: 'Panama',
    flag: '🇵🇦',
    code: 'PA',
    langCode: 'es',
  },
  {
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'PG',
    langCode: 'en',
  },
  {
    name: 'Paraguay',
    flag: '🇵🇾',
    code: 'PY',
    langCode: 'es',
  },
  {
    name: 'Peru',
    flag: '🇵🇪',
    code: 'PE',
    langCode: 'es',
  },
  {
    name: 'Philippines',
    flag: '🇵🇭',
    code: 'PH',
    langCode: 'en',
  },
  {
    name: 'Pitcairn',
    flag: '🇵🇳',
    code: 'PN',
    langCode: 'en',
  },
  {
    name: 'Poland',
    flag: '🇵🇱',
    code: 'PL',
    langCode: 'pl',
  },
  {
    name: 'Portugal',
    flag: '🇵🇹',
    code: 'PT',
    langCode: 'pt',
  },
  {
    name: 'Puerto Rico',
    flag: '🇵🇷',
    code: 'PR',
    langCode: 'es',
  },
  {
    name: 'Qatar',
    flag: '🇶🇦',
    code: 'QA',
    langCode: 'ar',
  },
  {
    name: 'Romania',
    flag: '🇷🇴',
    code: 'RO',
    langCode: 'ro',
  },
  {
    name: 'Russia',
    flag: '🇷🇺',
    code: 'RU',
    langCode: 'ru',
  },
  {
    name: 'Rwanda',
    flag: '🇷🇼',
    code: 'RW',
    langCode: 'rw',
  },
  {
    name: 'Reunion',
    flag: '🇷🇪',
    code: 'RE',
    langCode: 'fr',
  },
  {
    name: 'Saint Barthelemy',
    flag: '🇧🇱',
    code: 'BL',
    langCode: 'fr',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    code: 'SH',
    langCode: 'en',
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    code: 'KN',
    langCode: 'en',
  },
  {
    name: 'Saint Lucia',
    flag: '🇱🇨',
    code: 'LC',
    langCode: 'en',
  },
  {
    name: 'Saint Martin',
    flag: '🇲🇫',
    code: 'MF',
    langCode: 'fr',
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    code: 'PM',
    langCode: 'fr',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    code: 'VC',
    langCode: 'en',
  },
  {
    name: 'Samoa',
    flag: '🇼🇸',
    code: 'WS',
    langCode: 'sm',
  },
  {
    name: 'San Marino',
    flag: '🇸🇲',
    code: 'SM',
    langCode: 'it',
  },
  {
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    code: 'ST',
    langCode: 'pt',
  },
  {
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    code: 'SA',
    langCode: 'ar',
  },
  {
    name: 'Senegal',
    flag: '🇸🇳',
    code: 'SN',
    langCode: 'fr',
  },
  {
    name: 'Serbia',
    flag: '🇷🇸',
    code: 'RS',
    langCode: 'sr',
  },
  {
    name: 'Seychelles',
    flag: '🇸🇨',
    code: 'SC',
    langCode: 'fr',
  },
  {
    name: 'Sierra Leone',
    flag: '🇸🇱',
    code: 'SL',
    langCode: 'en',
  },
  {
    name: 'Singapore',
    flag: '🇸🇬',
    code: 'SG',
    langCode: 'en',
  },
  {
    name: 'Slovakia',
    flag: '🇸🇰',
    code: 'SK',
    langCode: 'sk',
  },
  {
    name: 'Slovenia',
    flag: '🇸🇮',
    code: 'SI',
    langCode: 'sl',
  },
  {
    name: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'SB',
    langCode: 'en',
  },
  {
    name: 'Somalia',
    flag: '🇸🇴',
    code: 'SO',
    langCode: 'so',
  },
  {
    name: 'South Africa',
    flag: '🇿🇦',
    code: 'ZA',
    langCode: 'en',
  },
  {
    name: 'South Sudan',
    flag: '🇸🇸',
    code: 'SS',
    langCode: 'en',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    code: 'GS',
    langCode: 'en',
  },
  {
    name: 'Spain',
    flag: '🇪🇸',
    code: 'ES',
    langCode: 'es',
  },
  {
    name: 'Sri Lanka',
    flag: '🇱🇰',
    code: 'LK',
    langCode: 'si',
  },
  {
    name: 'Sudan',
    flag: '🇸🇩',
    code: 'SD',
    langCode: 'ar',
  },
  {
    name: 'Suriname',
    flag: '🇸🇷',
    code: 'SR',
    langCode: 'nl',
  },
  {
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    code: 'SJ',
    langCode: 'no',
  },
  {
    name: 'Swaziland',
    flag: '🇸🇿',
    code: 'SZ',
    langCode: 'en',
  },
  {
    name: 'Sweden',
    flag: '🇸🇪',
    code: 'SE',
    langCode: 'sv',
  },
  {
    name: 'Switzerland',
    flag: '🇨🇭',
    code: 'CH',
    langCode: 'de',
  },
  {
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    code: 'SY',
    langCode: 'ar',
  },
  {
    name: 'Taiwan',
    flag: '🇹🇼',
    code: 'TW',
    langCode: 'zh',
  },
  {
    name: 'Tajikistan',
    flag: '🇹🇯',
    code: 'TJ',
    langCode: 'tg',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    code: 'TZ',
    langCode: 'sw',
  },
  {
    name: 'Thailand',
    flag: '🇹🇭',
    code: 'TH',
    langCode: 'th',
  },
  {
    name: 'Timor-Leste',
    flag: '🇹🇱',
    code: 'TL',
    langCode: 'pt',
  },
  {
    name: 'Togo',
    flag: '🇹🇬',
    code: 'TG',
    langCode: 'fr',
  },
  {
    name: 'Tokelau',
    flag: '🇹🇰',
    code: 'TK',
    langCode: 'en',
  },
  {
    name: 'Tonga',
    flag: '🇹🇴',
    code: 'TO',
    langCode: 'en',
  },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    langCode: 'en',
  },
  {
    name: 'Tunisia',
    flag: '🇹🇳',
    code: 'TN',
    langCode: 'ar',
  },
  {
    name: 'Turkey',
    flag: '🇹🇷',
    code: 'TR',
    langCode: 'tr',
  },
  {
    name: 'Turkmenistan',
    flag: '🇹🇲',
    code: 'TM',
    langCode: 'tk',
  },
  {
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    code: 'TC',
    langCode: 'en',
  },
  {
    name: 'Tuvalu',
    flag: '🇹🇻',
    code: 'TV',
    langCode: 'en',
  },
  {
    name: 'Uganda',
    flag: '🇺🇬',
    code: 'UG',
    langCode: 'sw',
  },
  {
    name: 'Ukraine',
    flag: '🇺🇦',
    code: 'UA',
    langCode: 'uk',
  },
  {
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'AE',
    langCode: 'ar',
  },
  {
    name: 'United Kingdom',
    flag: '🇬🇧',
    code: 'GB',
    langCode: 'en',
  },
  {
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    langCode: 'en',
  },
  {
    name: 'Uruguay',
    flag: '🇺🇾',
    code: 'UY',
    langCode: 'es',
  },
  {
    name: 'Uzbekistan',
    flag: '🇺🇿',
    code: 'UZ',
    langCode: 'uz',
  },
  {
    name: 'Vanuatu',
    flag: '🇻🇺',
    code: 'VU',
    langCode: 'bi',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    langCode: 'es',
  },
  {
    name: 'Vietnam',
    flag: '🇻🇳',
    code: 'VN',
    langCode: 'vi',
  },
  {
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    langCode: 'en',
  },
  {
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    langCode: 'en',
  },
  {
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    code: 'WF',
    langCode: 'fr',
  },
  {
    name: 'Yemen',
    flag: '🇾🇪',
    code: 'YE',
    langCode: 'ar',
  },
  {
    name: 'Zambia',
    flag: '🇿🇲',
    code: 'ZM',
    langCode: 'en',
  },
  {
    name: 'Zimbabwe',
    flag: '🇿🇼',
    code: 'ZW',
    langCode: 'en',
  },
];
