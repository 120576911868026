import { Box, Typography } from '@mui/material';
import { Gear, File } from '@phosphor-icons/react';
import React from 'react';

import CompanyBox from '../CompanyBox';
import { User } from '../../../util/Types/User';

export const menuPadding = {
  paddingLeft: '16px',
  paddingRight: '16px',
};

type Props = {
  darkTheme: boolean;
  sidebarOpen: boolean;
  setSettingsModalOpen: (open: boolean) => void;
  setTemplatesModalOpen: (open: boolean) => void;
  authUser: User;
  onLogout: () => void;
  checked: boolean;
  onUpdateTheme: (checked: boolean) => void;
  setAuthUser: (user: User) => void;
  setIsLoading: (loading: boolean) => void;
};
export const menu: {
  name: string;
  path: string;
  Icon: (...others: any[]) => JSX.Element;
}[] = [
  // {
  //   name: 'Search',
  //   path: '/',
  //   Icon: ({ ...props }) => <MagnifyingGlass {...props} />,
  // },
  {
    name: 'Settings',
    path: '/settings',
    Icon: ({ ...props }) => <Gear {...props} weight="fill" />,
  },
  {
    name: 'Templates',
    path: '/',
    Icon: ({ ...props }) => <File {...props} weight="fill" />,
  },
];

const Menu = ({
  darkTheme,
  sidebarOpen,
  setSettingsModalOpen,
  authUser,
  onLogout,
  checked,
  onUpdateTheme,
  setTemplatesModalOpen,
  setAuthUser,
  setIsLoading,
}: Props) => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          ...menuPadding,
        }}
      >
        <CompanyBox
          user={authUser}
          onLogout={onLogout}
          checked={checked}
          onUpdateTheme={onUpdateTheme}
          setAuthUser={setAuthUser}
          setIsLoading={setIsLoading}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
          borderBottom: `1px solid ${darkTheme ? '#344054' : '#D0D5DD'}`,
          paddingBottom: '10px',
          cursor: 'pointer',
          ...menuPadding,
        }}
      >
        {menu.map(({ name, Icon, path }, index) => (
          <Box
            key={path + index}
            style={{
              color: darkTheme ? 'white' : 'black',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              display: 'flex',
              justifyContent: 'start',
              textDecoration: 'none',
              alignItems: 'center',
              columnGap: '12px',
            }}
            onClick={() => {
              name === 'Templates'
                ? setTemplatesModalOpen(true)
                : setSettingsModalOpen(true);
            }}
          >
            <Icon
              fontSize={sidebarOpen ? '15px' : '20px'}
              color={darkTheme ? 'white' : '#667085'}
              size={20}
            />
            {sidebarOpen && (
              <Typography style={{ color: darkTheme ? '#D0D5DD' : '#667085' }}>
                {name}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Menu;
