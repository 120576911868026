import { create } from 'zustand';
import { User } from '../util/Types/User';

type SocketTypes = {
  processingNode: string;

  processingFlows: string[];

  socketLoading: boolean;

  summarizeData: {
    nodeId: string;
    data: string;
  }[];

  largeMemoryData: {
    nodeId: string;
    data: string;
  }[];
  socket: any;
  setSocket: (socket: any) => void;
  setProcessingNode: (nodeId: string) => void;
  setProcessingFlows: (flowId: string) => void;
  setDeleteProcessingFlow: (flowId: string) => void;
  setSummarizeData: (summarizeData: { nodeId: string; data: string }) => void;
  setLargeMemoryData: (summarizeData: { nodeId: string; data: string }) => void;
  setSocketLoading: (isLoading: boolean) => void;
  activeNodeSockets: string[];
  setActiveNodeSockets: (activeNodeSockets: string[]) => void;
};

export const useSocketStore = create<SocketTypes>(set => ({
  summarizeData: [],
  processingNode: '',
  processingFlows: [],
  socketLoading: false,
  largeMemoryData: [],
  socket: null,
  activeNodeSockets: [],

  setSocket: socket => {
    set(() => ({
      socket: socket,
    }));
  },

  setActiveNodeSockets(activeNodeSockets) {
    set(() => ({
      activeNodeSockets,
    }));
  },
  setSummarizeData: newData => {
    set(state => {
      const existingIndex = state.summarizeData.findIndex(
        (item: { nodeId: string }) => item.nodeId === newData.nodeId,
      );

      if (existingIndex !== -1) {
        const updatedData = [...state.summarizeData];
        updatedData[existingIndex] = newData;

        return { summarizeData: updatedData };
      } else {
        return { summarizeData: [...state.summarizeData, newData] };
      }
    });
  },

  setLargeMemoryData: newData => {
    set(state => {
      const existingIndex = state.largeMemoryData.findIndex(
        (item: { nodeId: string }) => item.nodeId === newData.nodeId,
      );

      if (existingIndex !== -1) {
        const updatedData = [...state.largeMemoryData];
        updatedData[existingIndex] = newData;

        return { largeMemoryData: updatedData };
      } else {
        return { largeMemoryData: [...state.largeMemoryData, newData] };
      }
    });
  },

  setProcessingNode: nodeId => {
    set(() => ({ processingNode: nodeId }));
  },

  setProcessingFlows: flowId => {
    set(state => {
      const uniqueFlows = new Set(state.processingFlows);

      uniqueFlows.add(flowId);

      const newProcessingFlows = Array.from(uniqueFlows);

      return { processingFlows: newProcessingFlows };
    });
  },

  setDeleteProcessingFlow: flowId => {
    set(state => {
      const updatedFlowIds = state.processingFlows.filter(id => id !== flowId);
      return { processingFlows: updatedFlowIds };
    });
  },

  setSocketLoading: isLoading => {
    set(() => ({ socketLoading: isLoading }));
  },
}));
