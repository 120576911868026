export const formatPrompt = data => {
  const promptArray = [];
  if (data.persona) {
    promptArray.push({
      role: 'system',
      content: data.persona,
    });
  }
  if (data.prompt) {
    promptArray.push({
      role: 'user',
      content: data.prompt,
    });
  }
  if (data.content || data.context) {
    let contentString = 'For the prompt mentioned above, ';
    if (data.content) {
      contentString += 'here is the reference content: ' + data.content + '\n';
    }
    if (data.context) {
      contentString += 'here is the context: ' + data.context;
    }
    promptArray.push({
      role: 'user',
      content: contentString,
    });
  }
  if (data.instruction || data.examples || data.format) {
    const contentArray = [];
    if (data.instruction) {
      contentArray.push(
        `Instructions in crafting your response: ${data.instruction}`
      );
    }
    if (data.examples) {
      contentArray.push(`Examples: ${data.examples}`);
    }
    if (data.format) {
      contentArray.push(
        `Format you need to use in your response: ${data.format}`
      );
    }
    const contentString = contentArray.join('\n');
    promptArray.push({
      role: 'user',
      content: contentString,
    });
  }
  return promptArray;
};
