import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from 'react-hook-form';
import {
  geminiFileds,
  systemDisplayNameMap,
  systemValueMap,
} from '../../AiConfigs/constants';

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[];
}

const GeminiConfigForm = ({
  register,
  setSystemType,
  setValue,
  systemType,
  watch,
  systems,
}: Props) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
          <Select
            name="AiSystem"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            onChange={e => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem key={index} value={systemValue} disabled={disabled}>
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Ai Model</InputLabel>
          <Select
            value={watch().geminiModel}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai Model"
            {...register('geminiModel')}
            sx={{
              width: '100%',
            }}
          >
            {systems
              .filter((item: any) => item.system === 'gemini')[0]
              .models?.map((opt: any) => (
                <MenuItem
                  key={opt.id}
                  value={opt.model}
                  disabled={opt.disabledByCompany}
                >
                  {opt.model}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          mt: '32px',
        }}
      >
        {geminiFileds.map(field => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography fontSize={'14px'} fontWeight={500}>
              {field.label}
            </Typography>
            <Slider
              {...register(field.name)}
              step={Number(field.step)}
              min={Number(field.min)}
              max={Number(field.max)}
              value={Number(watch()?.[field.name])}
              onChange={(event, value) => setValue(field.name, value)}
            />
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              color={'rgba(0, 0, 0, 0.60)'}
            >
              {watch()?.[field.name]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GeminiConfigForm;
