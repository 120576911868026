import { ApiService } from './ApiService';
import {
  IGetAllResponse,
  IGetOneResponse,
  Message,
} from '../util/Types/Generic';
import { Product } from '../util/Types/Product';
import { Invoice } from '../components/settings/Pricing/DataGridExportableTable';
export class ProductService extends ApiService {
  static pathName = '/product';

  static getMyProducts = (): Promise<IGetAllResponse<Product>> => {
    return ApiService.get(`${this.pathName}/my-products`);
  };

  static getProductInvoices = (): Promise<IGetAllResponse<Invoice>> => {
    return ApiService.get(`${this.pathName}/invoices`);
  };

  static subscribeProduct = (body: Partial<Product>): Promise<Message> => {
    return ApiService.post(`${this.pathName}/create-subscription`, {
      product: { id: body.id },
      description: body.description,
      yearly: body.yearly,
      ...(!!body.couponId ? { couponId: body.couponId } : {}),
    });
  };

  static applyCoupon = (body: {
    productId: string;
    couponId: string;
  }): Promise<IGetOneResponse<{ message: string }>> => {
    return ApiService.post(`${this.pathName}/apply-coupon`, {
      productId: body.productId,
      couponId: body.couponId,
    });
  };

  static changeUserPlan = (body: any): Promise<Message> => {
    return ApiService.post(`${this.pathName}/change-user-plan`, {
      user: { id: body.user.id },
      product: { id: body.product.id },
      description: body.description,
    });
  };

  static subscribeProductOneTime = (
    body: Partial<Product>,
  ): Promise<Message> => {
    return ApiService.post(`${this.pathName}/create-payment-intent`, {
      product: { id: body.id },
      description: body.description,
    });
  };

  static retryPayment = (body: { id: string }): Promise<Message> => {
    return ApiService.post(`${this.pathName}/retry-payment`, {
      id: body.id,
    });
  };

  // static retryPayment = (body: Partial<Invoice>): Promise<Message> => {
  //   return ApiService.post(`${this.pathName}/retry-payment`, {
  //     id: body.id,
  //   });
  // };

  static getProducts = (): Promise<IGetAllResponse<Product>> => {
    return ApiService.get(`${this.pathName}`);
  };

  static buyFlexCredits = (
    amount: number,
  ): Promise<
    IGetAllResponse<{
      message: string;
    }>
  > => {
    return ApiService.post(`${this.pathName}/buy-flux-credits`, {
      amount,
    });
  };

  static startTrial = (): Promise<null> => {
    return ApiService.post(`${this.pathName}/start-trial`);
  };
}
