import React, { useState } from 'react';
import './Congrats.css';
import { SelectedPlan } from './Welcome';
import { Button, CircularProgress } from '@mui/material';
import ReactConfetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import CongratsImage from '../../assets/images/Congrats.png';
import { useAuthStore } from '../../store/storeAuth';
import { AuthService } from '../../service/AuthService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { FolderService } from '../../service/FolderService';
import { FlowService } from '../../service/FlowService';
import useFlowsStore from '../../store/storeFlows';

interface Props {
  selectedPlan: SelectedPlan;
}

const Congrats: React.FC<Props> = ({ selectedPlan }) => {
  const { addFlow } = useFlowsStore();
  const navigate = useNavigate();
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const setNotification = useNotificationStore(state => state.setNotification);
  const { setFolders } = useAuthStore(state => state);

  const [loading, setLoading] = useState(false);

  let message;
  if (selectedPlan === SelectedPlan.Subscription)
    message = `Congratulations on successfully subscribing to FluxPrompt! 🎉 You're now part of a vibrant community of AI enthusiasts and innovators who are transforming the way they work.`;
  else
    message = `Congratulations on successfully joining FluxPrompt! 🎉 You're now part of a vibrant community of AI enthusiasts and innovators who are transforming the way they work.`;

  const submitHandler = async () => {
    try {
      setLoading(true);
      const res = await AuthService.getMe();
      setAuthUser(res.data);

      if (window.location.origin.includes('staging')) {
        setLoading(false);

        return navigate('/');
      }

      const { data } = await FolderService.createFolder({ name: 'Onboarding' });
      const folderId = data.id;

      const flowId = window.location.origin.includes('app')
        ? 'e87fe184-edbf-4a40-8e22-3f95346b0e7e'
        : '2afea1d7-b685-47fd-9151-7f3ad655f688';
      const flowName = 'First Flow: Daily News Scraper';
      // const flowIdTest = '2afea1d7-b685-47fd-9151-7f3ad655f688';

      const createdFlow = await FlowService.copyTemplate(
        flowId,
        folderId,
        flowName,
      );

      const folderResponse = await FolderService.getFolders();
      setFolders(folderResponse.data);
      setLoading(false);

      addFlow({
        id: createdFlow.data.id,
        name: createdFlow.data.name,
        index: 0,
      });

      navigate(`/flow/${createdFlow.data.id}?folderId=${folderId}&showVideo=1`);
    } catch (error) {
      setLoading(false);
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  return (
    <div className={'congrats-page'}>
      <img src={CongratsImage} alt="Congrats" className={'congrats-image'} />
      <ReactConfetti
        style={{
          width: '100vw',
          height: '100vh',
        }}
      />
      <h1 className={'congrats-text'}>Congrats!</h1>
      <p className={'congrats-description'}>{message}</p>
      <Button
        variant="contained"
        style={{ color: 'rgba(54, 80, 222, 1)', background: 'white' }}
        onClick={submitHandler}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
      >
        Let’s show you around!
      </Button>
    </div>
  );
};

export default Congrats;
