import React, { useState, useEffect } from 'react';
import { Box, TextField, Autocomplete, Button, Typography } from '@mui/material';
import { UserService } from '../../service/UserService';
import { ProductService } from '../../service/ProductService';

interface User {
  id: string;
  name: string;
  email: string;
  currentPlan: string;
}

const UserPlanManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [newPlan, setNewPlan] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [plans, setPlans] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);


  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const response = await UserService.getUsersByEmail(value);
    setUsers(response.data || []);
    setSearchTerm(value);


    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(async () => {
      if (value) {
        const filteredUsers = users.filter(user => 
          user.email.toLowerCase().includes(value.toLowerCase())
        );
        setUsers(filteredUsers);
      }
    }, 300);

    setDebounceTimeout(timeout);
  };

  const handleUserSelect = async (user: User | null) => {
    const plans =await ProductService.getProducts();

    setPlans(plans.data);
    setSelectedUser(user);
    if (user) {
      setSearchTerm(user.email);
    }
  };

  const handlePlanChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNewPlan(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedUser && newPlan) {
      try {
        await ProductService.changeUserPlan({
            user: { id: selectedUser.id },
            product: { id: newPlan },
            description: 'User Plan Change',
          });
          setSuccessMessage('User Plan Updated!')
      } catch (error: any) {
        if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage(error?.message || 'Something went wrong');
          }
      }
     
    }
  };

  return (
    <Box sx={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom>User Plan Management</Typography>
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.email}
        value={selectedUser}
        onChange={(event, newValue) => handleUserSelect(newValue)}
        renderOption={(props, option) => (
          <li {...props}>
            {option.email} - Current Plan: {option.plan}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search by email"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              marginBottom: '20px',
              width: '600px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#007BFF',
                },
                '&:hover fieldset': {
                  borderColor: '#28a745',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#28a745',
                },
              },
            }}
          />
        )}
      />
      {selectedUser && (
        <form onSubmit={handleSubmit}>
          <Box sx={{ marginBottom: '20px' }}>
            <Typography variant="body1">
              Selected User: {selectedUser.name} ({selectedUser.email})
            </Typography>
            <Typography variant="body1">
              Current Plan: {selectedUser.plan}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <label htmlFor="plan-select">New Plan: </label>
            <select id="plan-select" value={newPlan} onChange={handlePlanChange}>
              <option value="">--Select a plan--</option>
              {plans.map(plan => (
                <option key={plan.id} value={plan.id}>{plan.name}</option>
              ))}
            </select>
          </Box>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={!newPlan}
            sx={{
              backgroundColor: '#28a745',
              '&:hover': {
                backgroundColor: '#218838',
              },
            }}
          >
            Update Plan
          </Button>
        </form>
      )}
      {errorMessage && (
  <Typography color="error" sx={{ marginTop: '20px' }}>
    Error: {errorMessage}
  </Typography>
)}
      {successMessage && (
  <Typography color="#28a745" sx={{ marginTop: '20px' }}>
   Success: {successMessage}
  </Typography>
)}
    </Box>
  );
};

export default UserPlanManagement;