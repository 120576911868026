import {
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TextAlignLeft } from '@phosphor-icons/react';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  entities: any[];
  setSelectedEntity: any;
  enabledTechnologies: any;
  disabledTechnologies: any;
  entityType: string;
  setDisplayedTechnologies: any;
  setShowTechnologiesModal: any;
  setViewState: Dispatch<SetStateAction<"OrganizationsAndWorkspaces" | "OrganizationTechnologies" | "WorkspaceTechnologies">>
}

const PermissionsOverview = ({
  entities,
  setSelectedEntity,
  enabledTechnologies,
  disabledTechnologies,
  entityType,
  setDisplayedTechnologies,
  setShowTechnologiesModal,
  setViewState                                                             
}: Props) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: entityType === 'company' ? 'Organization Name' : 'Space Name',
      sortable: true,
      flex: 1,
      renderCell: params => (
        <Typography
          sx={{ cursor: 'pointer' }}
          onClick={() => setSelectedEntity(params.value)}
          padding={'16px'}
          color={'#3650DE'}
        >
          {params.value.name}
        </Typography>
      ),
    },
    {
      field: 'enabledTechnologies',
      headerName: 'Enabled AI Technologies',
      headerClassName: 'header-padding',
      sortable: false,
      flex: 3,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            padding: '10px',
          }}
        >
          <Typography
            whiteSpace={'normal'}
            fontSize={'12px'}
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              width: '90%',
            }}
          >
            {params.value}
          </Typography>
          {params.value === 'No AI Technologies enabled' ? (
            <Box sx={{ width: '32px', height: '32px' }} />
          ) : (
            <IconButton
              onClick={() => {
                setDisplayedTechnologies(Object.values(enabledTechnologies)[0]);
                setShowTechnologiesModal(true);
              }}
            >
              <TextAlignLeft size={16} />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: 'disabledTechnologies',
      headerName: 'Disabled AI Technologies',
      headerClassName: 'header-padding',
      sortable: false,
      flex: 3,
      renderCell: params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            padding: '10px',
          }}
        >
          <Typography
            whiteSpace={'normal'}
            fontSize={'12px'}
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {params.value}
          </Typography>
          {params.value === 'No AI Technologies disabled' ? (
            <Box sx={{ width: '32px', height: '32px' }} />
          ) : (
            <IconButton
              onClick={() => {
                setDisplayedTechnologies(
                  Object.values(disabledTechnologies)[0],
                );
                setShowTechnologiesModal(true);
              }}
            >
              <TextAlignLeft size={16} />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: 'customize',
      headerName: '',
      headerClassName: 'header-padding',
      sortable: false,
      flex: 1,
      renderCell: params => (
        <Button
          onClick={() => {
            setSelectedEntity(params.value);
            setViewState(entityType === 'company' ? 'OrganizationTechnologies' : 'WorkspaceTechnologies');
          }}
        >
          Customize
        </Button>
      ),
    },
  ];

  const displayTechnologies = (technologies: any[]) => {
    return technologies.map(({ system, models }, index) => {
      const modelNames = Array.isArray(models)
        ? models?.map((model: any) => model.model).join(', ')
        : '';
      return index === 0
        ? `${system.charAt(0).toUpperCase() + system.slice(1)} (${modelNames})`
        : system.charAt(0).toUpperCase() + system.slice(1);
    });
  };

  const rows = entities.map((entity: any, index: number) => {
    const entityEnabledTechnologies = enabledTechnologies[entity.id] || {};
    const entityDisabledTechnologies = disabledTechnologies[entity.id] || {};

    return {
      id: entity.id || index + 1,
      name: entity,
      enabledTechnologies:
        entityEnabledTechnologies.length > 0
          ? `${entityEnabledTechnologies.length} ${
              entityEnabledTechnologies.length === 1
                ? 'Technology'
                : 'Technologies'
            } enabled: ` +
            displayTechnologies(entityEnabledTechnologies).slice(0, 2) +
            '...'
          : 'No AI Technologies enabled',
      disabledTechnologies:
        Object.values(entityDisabledTechnologies).length > 0
          ? `${Object.values(entityDisabledTechnologies).length} ${
              Object.values(entityDisabledTechnologies).length === 1
                ? 'Technology'
                : 'Technologies'
            } disabled: ` +
            displayTechnologies(entityDisabledTechnologies)
              .slice(0, 3)
              .join(', ')
          : 'No AI Technologies disabled',
      customize: entity,
    };
  });

  return <DataGrid hideFooter rows={rows} columns={columns} />;
};

export default PermissionsOverview;
