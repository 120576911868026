import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import KitInput from '../../UI/KitInput';
import {
  additionalOptionsArray,
  openSourcesModels,
  systemDisplayNameMap,
  systemValueMap,
} from '../../AiConfigs/constants';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from 'react-hook-form';

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[]
}

const OpenSourcesConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems,
}: Props) => {
  return (
    <Box>
      {/* <KitInput
        label='Name'
        name='name'
        type='text'
        errorsStatus={errors}
        register={register}
      /> */}
      <Box
        mt={"16px"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "32px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" title="Ai System">
            Ai System
          </InputLabel>
          <Select
            name="AiSystem"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            onChange={(e) => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: "100%",
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem
                  key={index}
                  value={systemValue}
                  disabled={disabled}
                >
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Ai Model</InputLabel>
          <Select
            value={watch().openSourceModelName}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai Model"
            {...register("openSourceModelName")}
            sx={{
              width: "100%",
            }}
          >
            {openSourcesModels.map((opt) => (
              <MenuItem
                key={opt.value}
                value={opt.value}
                disabled={opt.disabled}
              >
                {opt.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginY: "16px",
        }}
      />

      {(watch().openSourceModelName === "falcon-40b-instruct" ||
        watch().openSourceModelName === "mpt-7b-storywriter") && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <KitInput
            label="Max Length"
            name="maxLength"
            type="number"
            errorsStatus={errors}
            register={register}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              Ai Temperature
            </Typography>
            <Slider
              {...register("aiTemperature")}
              step={Number(0.1)}
              min={Number(0)}
              max={Number(2)}
              value={Number(watch()?.aiTemperature)}
              onChange={(event, value) => setValue("aiTemperature", value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.aiTemperature}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              Top P
            </Typography>
            <Slider
              {...register("topP")}
              step={Number(0.1)}
              min={Number(0)}
              max={Number(2)}
              value={Number(watch()?.topP)}
              onChange={(event, value) => setValue("topP", value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.topP}
            </Typography>
          </Box>
          <KitInput
            label="Seed"
            name="seed"
            type="number"
            errorsStatus={errors}
            register={register}
          />
          <KitInput
            label="Repetition Penalty"
            name="repetitionPenalty"
            type="number"
            errorsStatus={errors}
            register={register}
          />
          <KitInput
            label="Length Penalty"
            name="lengthPenalty"
            type="number"
            errorsStatus={errors}
            register={register}
          />

          <KitInput
            label="No Repeat Ngram Size"
            name="noRepeatNgramSize"
            type="number"
            errorsStatus={errors}
            register={register}
          />
        </Box>
      )}
      {watch().openSourceModelName === "mpt-30b-chat" && (
        <>
          <KitInput
            label="Max New Tokens"
            name="maxNewTokens"
            type="number"
            errorsStatus={errors}
            register={register}
            sxStyle={{
              mt: "16px",
            }}
          />
        </>
      )}
      {watch().openSourceModelName === "llama-2-70b-chat" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <KitInput
            label="Max New Tokens"
            name="maxNewTokens"
            type="number"
            errorsStatus={errors}
            register={register}
          />
          <KitInput
            label="Min New Tokens"
            name="minNewTokens"
            type="number"
            errorsStatus={errors}
            register={register}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              Ai Temperature
            </Typography>
            <Slider
              {...register("aiTemperature")}
              step={Number(0.1)}
              min={Number(0)}
              max={Number(2)}
              value={Number(watch()?.aiTemperature)}
              onChange={(event, value) => setValue("aiTemperature", value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.aiTemperature}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              Top K
            </Typography>
            <Slider
              {...register("topK")}
              step={Number(0.1)}
              min={Number(0)}
              max={Number(2)}
              value={Number(watch()?.topK)}
              onChange={(event, value) => setValue("topK", value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.topK}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              Top P
            </Typography>
            <Slider
              {...register("topP")}
              step={Number(0.1)}
              min={Number(0)}
              max={Number(2)}
              value={Number(watch()?.topP)}
              onChange={(event, value) => setValue("topP", value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.topP}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={500}>
              Seed
            </Typography>
            <Slider
              {...register("seed")}
              step={Number(0.1)}
              min={Number(0)}
              max={Number(2)}
              value={Number(watch()?.seed)}
              onChange={(event, value) => setValue("seed", value)}
            />
            <Typography
              fontSize={"14px"}
              fontWeight={400}
              color={"rgba(0, 0, 0, 0.60)"}
            >
              {watch()?.seed}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OpenSourcesConfigForm;
