import { useState } from 'react';
import KitModal from '../../../components/UI/KitModal';
import { Box, Button, Radio, Typography } from '@mui/material';
import { LockKey, LockKeyOpen } from '@phosphor-icons/react';
import { useNotificationStore } from '../../../store/storeNotifications';
import { FormsService } from '../../../service/FormService';

type Props = {
  show: boolean;
  onHide: () => void;
  form: any;
  setForm: (form: any) => void;
};

const AccessModal = ({ show, onHide, form, setForm }: Props) => {
  const [selected, setSelected] = useState(form?.publishType || 'internal');
  const [finishType, setFinishType] = useState('');
  const setNotification = useNotificationStore(state => state.setNotification);

  const onPublish = async (type: string) => {
    try {
      const response = await FormsService.update(form?.id!, {
        publishType: selected,
      });

      setForm({
        ...form,
        publishType: selected,
      });

      setFinishType(selected);
    } catch (error) {
      setFinishType('error');
    } finally {
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(
      window.location.origin + '/published/form/' + form?.id,
    );

    setNotification({
      message: 'Link Copied',
      type: 'success',
      duration: 3000,
    });
  };

  const publishType = [
    {
      name: 'Internal',
      type: 'internal',
      description:
        'Only available to FluxPrompt users who can complete and submit the form. ',
    },

    {
      name: 'External',
      type: 'external',
      description:
        'Available to anyone with whom you share the link. Also accessible to FluxPrompt users.',
    },
  ];

  const finishTypeTexts = [
    {
      type: 'internal',
      title: 'Your form is now Internal only!',
      description: `Only FluxPrompt users can complete and 
      submit the form.`,
      icon: <LockKey size={64} color="#67BB6A" />,
    },
    {
      type: 'external',
      title: 'Your form is now External!',
      description:
        'Available to anyone with whom you share the link. Also accessible to FluxPrompt users.',
      icon: <LockKeyOpen size={64} color="#67BB6A" />,
    },
  ];

  return (
    <KitModal
      sx={{
        '& .MuiPaper-root': {
          maxWidth: finishType !== '' ? '540px' : '640px',
          background: '#fff',
        },

        '& .MuiDialogContent-root': {
          background: '#fff',

          p: '24px',
        },
      }}
      fullWidth
      show={show}
      onHide={
        finishType === ''
          ? onHide
          : () => {
              onHide();
              setFinishType('');
            }
      }
    >
      <>
        {finishType !== '' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
            mt={'12px'}
          >
            <Box mt={'24px'}>
              {finishTypeTexts.find(f => f.type === finishType)?.icon}
            </Box>
            <Box
              sx={{
                maxWidth: '470px',
              }}
            >
              <Typography color={'#475467'} fontSize={'16px'} variant="h6">
                {finishTypeTexts.find(f => f.type === finishType)?.title}
              </Typography>
              <Typography color={'#475467'} fontSize={'16px'} mt={'8px'}>
                {finishTypeTexts.find(f => f.type === finishType)?.description}
              </Typography>
            </Box>
            <Box
              mt={'24px'}
              sx={{
                display: 'flex',
                gap: '24px',
                width: '100%',
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  setFinishType('');
                }}
              >
                Go back
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onHide();
                  setFinishType('');
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        ) : (
          <Box mt={'12px'} sx={{}}>
            <Typography variant="h6">Publish Form</Typography>

            <Box mt={'24px'}>
              {publishType.map(type => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                    borderRadius: '8px',
                    p: '8px 24px',
                    cursor: 'pointer',
                    mb: '24px',
                    border: `1px solid  ${
                      selected === type.type ? '#3650DE' : '#EAECF0'
                    }`,
                    background: selected === type.type ? '#E7F0FF' : '#F9FAFB',
                  }}
                  key={type.type}
                  onClick={() => setSelected(type.type)}
                >
                  <Box>
                    {selected === type.type ? (
                      <LockKeyOpen color="#3650DE" size={16} />
                    ) : (
                      <LockKey color="#667085" size={16} />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: '#101828',
                          lineHeight: '20px',
                        }}
                        fontWeight={500}
                      >
                        {type.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#475467',
                        }}
                      >
                        {type.description}
                      </Typography>
                    </Box>
                    <Radio
                      size="small"
                      onChange={() => setSelected(type.type)}
                      value={type.type}
                      checked={selected === type?.type}
                    />
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              mt={'24px'}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '16px',
              }}
            >
              <Button
                disabled={form?.publishType === selected}
                variant="contained"
                color="primary"
                onClick={() => onPublish('published')}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}
      </>
    </KitModal>
  );
};

export default AccessModal;
