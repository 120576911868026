import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { Product } from '../../../util/Types/Product';
import { User } from '../../../util/Types/User';

interface Props {
  subscription: Product;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Product | undefined>>;
  activeSubscriptionId: string | undefined;
  isChecked: boolean;
  authUser: User;
}

const PaymentPlanBox = ({
  subscription,
  setSelectedPlan,
  activeSubscriptionId,
  isChecked,
  authUser,
}: Props) => {
  return (
    <Box
      sx={{
        width: subscription.name === 'Enterprise' ? '100%' : '85%',
        height: subscription.name === 'Enterprise' ? '85%' : '100%',
        border: `1px solid ${
          subscription.id === activeSubscriptionId ? '#546BEA' : '#D0D5DD'
        }`,
        borderRadius: '12px',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent:
          subscription.name === 'Enterprise' ? 'start' : 'space-between',
        boxShadow:
          subscription.id === activeSubscriptionId
            ? '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)'
            : 'none',
      }}
    >
      <Box>
        <Typography
          sx={{
            color: '#475467',
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            marginBottom: '4px',
          }}
        >
          {subscription?.name}
        </Typography>
        <Typography
          sx={{
            color: '#667085',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            display: 'flex',
            alignItems: 'start',
            height: subscription.name === 'Enterprise' ? '' : '80px',
          }}
        >
          {subscription?.title}
        </Typography>
        {(subscription?.price || subscription?.price === 0) &&
          subscription?.name !== 'Enterprise' && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                color: '#3650DE',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '28px',
                marginTop: '12px',
              }}
            >
              ${isChecked ? subscription?.year_price : subscription?.price}
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: '#667085',
                }}
              >
                {isChecked ? '/annual' : '/monthly'}
              </Typography>
            </Typography>
          )}

        {subscription?.discounts?.length && (
          <>
            {subscription?.discounts?.map(discount => (
              <Typography
                key={discount}
                sx={{
                  color: '#3650DE',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '8px',
                  lineHeight: '13.28px',
                }}
              >
                {discount}
                <Typography
                  sx={{
                    color: '#667085',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '8px',
                    lineHeight: '16px',
                  }}
                >
                  /Ai Token
                </Typography>
              </Typography>
            ))}
          </>
        )}

        {subscription?.tokensText && (
          <Typography
            sx={{
              color: '#3650DE',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '8px',
              lineHeight: '13.28px',
              marginTop: '4px',
            }}
          >
            {subscription?.tokensText}
          </Typography>
        )}

        {subscription?.name !== 'Enterprise' && (
          <Divider
            sx={{
              marginTop: '4px',
              marginBottom: '16px',
            }}
          />
        )}
        <List
          sx={{
            mt: '24px',
            pt: subscription.name === 'Enterprise' ? '16px' : '',
            display: subscription.name === 'Enterprise' ? 'grid' : '',
            gridTemplateColumns:
              subscription.name === 'Enterprise' ? '1fr 1fr' : '',
            gap: subscription.name === 'Enterprise' ? '16px' : '',
            width: subscription.name === 'Enterprise' ? '70%' : '100%',
            alignItems: subscription.name === 'Enterprise' ? 'center' : '',
          }}
        >
          {subscription?.descriptions?.map((description, i) => (
            <ListItem disablePadding>
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  mr: '6px',
                  mt: '2px',
                  alignSelf: 'flex-start',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                    fill="#F2F4F7"
                  />
                  <path
                    d="M4.37988 8.51732L6.44849 10.5859L11.6202 5.41421"
                    stroke="#3650DE"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{}}
                primaryTypographyProps={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  lineHeight: '140%',
                  color: '#475467',
                  fontWeight: 400,
                }}
                primary={description}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {subscription.id === activeSubscriptionId && (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            borderRadius: '2px',
            padding: '8px 12px',
            mt: '12px',
            border: '1px solid #3650DE',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#3650DE',
              fontSize: '16px',
              fontWeight: 500,
              fontStyle: 'normal',
            }}
          >
            Current Plan
          </Typography>
        </Box>
      )}
      {subscription.id === activeSubscriptionId ? (
        <></>
      ) : (
        <Box
          sx={{
            display: subscription.name === 'Enterprise' ? 'flex' : 'block',
            justifyContent:
              subscription.name === 'Enterprise' ? 'flex-end' : '',
          }}
        >
          <Button
            variant="contained"
            sx={{
              mt: '12px',
              backgroundColor: '#3650DE',
              borderRadius: '2px',
              width: subscription.name === 'Enterprise' ? '186px' : '100%',
              border: '1px solid #3650DE',
              boxShadow: 'none',
              fontSize: '16px',
              fontWeight: 500,
              fontStyle: 'normal',
              padding: '8px 0',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#3650DE',
                boxShadow: 'none',
              },
            }}
            onClick={() => {
              if (subscription?.name === 'Enterprise') {
                window.open(
                  'mailto:sales@enhanced.ai?subject=Enterprise%20Plan',
                );
              } else {
                setSelectedPlan(subscription);
              }
            }}
          >
            {subscription?.name === 'Enterprise'
              ? 'Contact Sales'
              : authUser?.plan
              ? 'Change Plan'
              : 'Subscribe'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PaymentPlanBox;
