import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import dayjs from 'dayjs';
import './TokenUsage.scss';
import {
  Box,
  FormControl,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ImagesUsage from './ImagesUsage';
import TextUsage from './TextUsage';
import AudioUsage from './AudioUsage';
import ApiCallsUsage from './ApiCallsUsage';
import OverallUsage from './OverallUsage';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import styled from 'styled-components';
import { CaretDown } from '@phosphor-icons/react';
import { User } from '../../util/Types/User';
import { CompanyService } from '../../service/CompanyService';
import { getAPIErrorMessage } from '../../helpers/helpers';

export enum TokenUsageOption {
  OverallUsage = 'Overall Usage',
  Images = 'Images',
  Text = 'Text',
  Audio = 'Audio',
  APICalls = 'API Calls',
  Stacked = 'Stacked',
}

export enum FilterBy {
  Week = 'Week',
  Month = 'Month',
}

const BlueSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgba(54, 80, 222, 1)',
  },
}));

const filteredStaticRanges = {
  Week: [
    {
      label: 'This Week',
      isSelected: (range: any) =>
        dayjs().startOf('week').isSame(range.startDate, 'day') &&
        dayjs().endOf('week').isSame(range.endDate, 'day'),
      range: () => ({
        startDate: dayjs().startOf('week').toDate(),
        endDate: dayjs().endOf('week').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'Last Week',
      isSelected: (range: any) =>
        dayjs()
          .subtract(1, 'week')
          .startOf('week')
          .isSame(range.startDate, 'day') &&
        dayjs().subtract(1, 'week').endOf('week').isSame(range.endDate, 'day'),
      range: () => ({
        startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
        endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
        key: 'selection',
      }),
    },
  ],
  Month: [
    {
      label: 'This month',
      isSelected: (range: any) => {
        const thisMonthStart = dayjs().startOf('month').toDate();
        const thisMonthEnd = dayjs().endOf('month').toDate();
        return (
          dayjs(range.startDate).isSame(thisMonthStart, 'day') &&
          dayjs(range.endDate).isSame(thisMonthEnd, 'day')
        );
      },
      range: () => ({
        startDate: dayjs().startOf('month').toDate(),
        endDate: dayjs().endOf('month').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'Last month',
      isSelected: (range: any) => {
        const lastMonthStart = dayjs()
          .subtract(1, 'month')
          .startOf('month')
          .toDate();
        const lastMonthEnd = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .toDate();
        return (
          dayjs(range.startDate).isSame(lastMonthStart, 'day') &&
          dayjs(range.endDate).isSame(lastMonthEnd, 'day')
        );
      },
      range: () => ({
        startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'Last 3 Months',
      isSelected: (range: any) => {
        const last3MonthsStart = dayjs()
          .subtract(3, 'month')
          .startOf('month')
          .toDate();
        const last3MonthsEnd = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .toDate();
        return (
          dayjs(range.startDate).isSame(last3MonthsStart, 'day') &&
          dayjs(range.endDate).isSame(last3MonthsEnd, 'day')
        );
      },
      range: () => ({
        startDate: dayjs().subtract(3, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'Last 6 Months',
      isSelected: (range: any) => {
        const last6MonthsStart = dayjs()
          .subtract(6, 'month')
          .startOf('month')
          .toDate();
        const last6MonthsEnd = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .toDate();
        return (
          dayjs(range.startDate).isSame(last6MonthsStart, 'day') &&
          dayjs(range.endDate).isSame(last6MonthsEnd, 'day')
        );
      },
      range: () => ({
        startDate: dayjs().subtract(6, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'Last Year',
      isSelected: (range: any) => {
        const lastYearStart = dayjs()
          .subtract(1, 'year')
          .startOf('year')
          .toDate();
        const lastYearEnd = dayjs().subtract(1, 'year').endOf('year').toDate();
        return (
          dayjs(range.startDate).isSame(lastYearStart, 'day') &&
          dayjs(range.endDate).isSame(lastYearEnd, 'day')
        );
      },
      range: () => ({
        startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
        endDate: dayjs().subtract(1, 'year').endOf('year').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'Last 12 months',
      isSelected: (range: any) =>
        dayjs()
          .subtract(12, 'month')
          .startOf('month')
          .isSame(range.startDate, 'day') &&
        dayjs().endOf('month').isSame(range.endDate, 'day'),
      range: () => ({
        startDate: dayjs().subtract(12, 'month').startOf('month').toDate(),
        endDate: dayjs().endOf('month').toDate(),
        key: 'selection',
      }),
    },
    {
      label: 'This year',
      isSelected: (range: any) =>
        dayjs().startOf('year').isSame(range.startDate, 'day') &&
        dayjs().endOf('month').isSame(range.endDate, 'day'),
      range: () => ({
        startDate: dayjs().startOf('year').toDate(),
        endDate: dayjs().endOf('month').toDate(),
        key: 'selection',
      }),
    },
  ],
};

type Props = {
  authUser: User;
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
};

export enum ViewType {
  Company = 'Company View',
  Personal = 'Personal View',
}

const TokenUsage = ({ authUser }: Props) => {
  const calendarRef = useRef<null | HTMLDivElement>(null);
  const calendarPreviewRef = useRef<null | HTMLDivElement>(null);
  const switchRef = useRef<null | HTMLButtonElement>(null);

  const viewOptionsRef = useRef<null | HTMLDivElement>(null);

  const setNotification = useNotificationStore(state => state.setNotification);

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<Range>({
    startDate: dayjs().startOf('week').toDate(),
    endDate: dayjs().endOf('week').toDate(),
    key: 'selection',
  });
  const [selectedTokenUsageOption, setSelectedTokenUsageOption] = useState<
    undefined | TokenUsageOption
  >(TokenUsageOption.OverallUsage);
  const [selectedFilterBy, setSelectedFilterBy] = useState<FilterBy>(
    FilterBy.Week,
  );
  const [differentYearsSelected, setDifferentYearsSelected] = useState<
    number[]
  >([]);
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(
    ViewType.Personal,
  );
  const [showViewOptions, setShowViewOptions] = useState(false);
  const [userRole, setUserRole] = useState();

  const generateWeeksQueryString = useCallback(
    (startDate: Date, endDate: Date): string => {
      const start = dayjs(startDate);
      const end = dayjs(endDate);

      const startWeekOfMonth = Math.floor((start.date() - 1) / 7) + 1;
      const endWeekOfMonth = Math.floor((end.date() - 1) / 7) + 1;

      let weeks: number[] = [];
      if (startWeekOfMonth === endWeekOfMonth) {
        weeks.push(startWeekOfMonth);
      } else {
        for (let i = startWeekOfMonth; i < endWeekOfMonth; i++) {
          weeks.push(i);
        }
      }

      const isFirstDaySunday = start.startOf('month').day() === 0;
      if (isFirstDaySunday && startWeekOfMonth !== endWeekOfMonth) {
        weeks.push(endWeekOfMonth);
      }

      return `weeks=${weeks.join(',')}`;
    },
    [],
  );

  const generateQueryString = useCallback(
    (startDate: Date, endDate: Date): string => {
      const start = dayjs(startDate);
      const end = dayjs(endDate);

      let queryString =
        selectedViewType === ViewType.Company
          ? `companyId=${authUser.selectedCompanyId}&year=${start.year()}`
          : `year=${start.year()}`;

      if (selectedFilterBy === FilterBy.Week) {
        if (start.month() === end.month()) {
          queryString += `&months=${end.month() + 1}`;
          // if (
          //   !(
          //     start.isSame(start.startOf('month').toDate()) &&
          //     end.isSame(end.endOf('month').toDate())
          //   )
          // )
          //   queryString += `&${generateWeeksQueryString(startDate, endDate)}`;
        } else {
          const months = Array.from(
            { length: end.month() - start.month() + 1 },
            (_, i) => start.month() + i + 1,
          );
          queryString += `&months=${months.join(',')}`;
        }
      } else {
        if (start.month() !== end.month()) {
          const months = Array.from(
            { length: end.month() - start.month() + 1 },
            (_, i) => start.month() + i + 1,
          );
          queryString += `&months=${months.join(',')}`;
        } else {
          if (
            start.isSame(start.startOf('month').toDate()) &&
            end.isSame(end.endOf('month').toDate())
          ) {
            queryString += `&months=${end.month() + 1}`;
          } else {
            queryString += `&${generateWeeksQueryString(startDate, endDate)}`;
          }
        }
      }

      return queryString;
    },
    [authUser, generateWeeksQueryString, selectedFilterBy, selectedViewType],
  );

  useEffect(() => {
    if (!authUser) return;

    const getUserCompany = async () => {
      const companyId = authUser.selectedCompanyId;
      try {
        if (companyId) {
          const res = await CompanyService.getUserOfCompany(companyId);
          const userData = Array.isArray(res.data) ? res.data[0] : res.data;

          if (userData && userData.role) {
            setUserRole(userData.role);
          }
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    getUserCompany();
  }, [authUser]);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (
        !calendarRef.current ||
        !calendarPreviewRef.current ||
        !switchRef.current
      )
        return;

      if (calendarPreviewRef.current.contains(e.target)) return;
      if (switchRef.current.contains(e.target) === true) return;

      if (calendarRef.current.contains(e.target) === false) {
        setShowCalendar(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [calendarRef.current, calendarPreviewRef.current, switchRef.current]);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!viewOptionsRef.current) return;

      if (!viewOptionsRef.current.contains(e.target)) {
        setShowViewOptions(false);
      }
    };
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [viewOptionsRef.current]);

  const toggleCalendarHandler = () =>
    setShowCalendar(prevShowCalendar => !prevShowCalendar);

  const onChangeHandler: (rangesByKey: RangeKeyDict) => void = (
    rangesByKey: RangeKeyDict,
  ) => {
    const { startDate, endDate } = rangesByKey.selection;

    if (
      dayjs(endDate).endOf('week').year() -
        dayjs(startDate).startOf('week').year() >=
      2
    ) {
      return setNotification({
        type: NotificationType.Error,
        message: 'You can not select more than 2 years of data',
      });
    }

    if (
      dayjs(startDate).startOf('week').year() !==
      dayjs(endDate).endOf('week').year()
    ) {
      setDifferentYearsSelected([
        dayjs(startDate).year(),
        dayjs(endDate).year(),
      ]);
    } else {
      setDifferentYearsSelected([]);
    }

    const differenceInDays = dayjs(endDate).diff(dayjs(startDate), 'day');

    if (selectedFilterBy === FilterBy.Week) {
      if (dayjs(startDate).isSame(endDate, 'day')) {
        setSelectedDateRange({
          startDate: dayjs(startDate).startOf('week').toDate(),
          endDate: dayjs(startDate).endOf('week').toDate(),
          key: 'selection',
        });
      } else {
        if (differenceInDays < 6) return;
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        if (
          start.isSame(start.startOf('month'), 'day') &&
          end.isSame(end.endOf('month'), 'day')
        ) {
          setSelectedDateRange({
            startDate: start.startOf('month').toDate(),
            endDate: end.endOf('month').toDate(),
            key: 'selection',
          });
        } else
          setSelectedDateRange({
            startDate: dayjs(startDate).startOf('week').toDate(),
            endDate: dayjs(endDate).endOf('week').toDate(),
            key: 'selection',
          });
      }
    } else {
      if (dayjs(startDate).isSame(endDate, 'day')) {
        setSelectedDateRange({
          startDate: dayjs(startDate).startOf('month').toDate(),
          endDate: dayjs(startDate).endOf('month').toDate(),
          key: 'selection',
        });
      }

      const start = dayjs(startDate);
      const end = dayjs(endDate);

      if (start.month() !== end.month()) {
        setSelectedDateRange({
          startDate: start.startOf('month').toDate(),
          endDate: end.endOf('month').toDate(),
          key: 'selection',
        });
      }

      if (
        start.isSame(start.startOf('month'), 'day') &&
        end.isSame(end.endOf('month'), 'day')
      ) {
        setSelectedDateRange({
          startDate: start.startOf('month').toDate(),
          endDate: end.endOf('month').toDate(),
          key: 'selection',
        });
      }
    }
  };

  const filterByChangeHandler = (value: FilterBy) => {
    setSelectedFilterBy(value as FilterBy);
    if (value === FilterBy.Week) {
      setSelectedDateRange({
        startDate: dayjs().startOf('week').toDate(),
        endDate: dayjs().endOf('week').toDate(),
        key: 'selection',
      });
    } else {
      setSelectedDateRange({
        startDate: dayjs().startOf('month').toDate(),
        endDate: dayjs().endOf('month').toDate(),
        key: 'selection',
      });
    }
  };

  const selectedRangeDisplay = `${dayjs(selectedDateRange.startDate).format(
    'MMM D, YYYY',
  )} - ${dayjs(selectedDateRange.endDate).format('MMM D, YYYY')}`;

  const queryString = generateQueryString(
    selectedDateRange.startDate!,
    selectedDateRange.endDate!,
  );

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: 'white',
          borderBottom: '1px solid #D0D5DD',
          display: 'flex',
          height: authUser.isUserCompany ? '67px' : '64px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
          }}
        >
          Token Usage
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '24px 24px 16px 24px',
          gap: '32px',
        }}
      >
        <header className={'token-usage-header'}>
          {Object.values(TokenUsageOption).map(option => {
            return (
              <Box
                onClick={() => setSelectedTokenUsageOption(option)}
                sx={{
                  borderRadius: '4px',
                  padding: '3px 8px',
                  background:
                    selectedTokenUsageOption === option
                      ? 'rgba(54, 80, 222, 1)'
                      : 'rgba(234, 236, 240, 1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{
                    color:
                      selectedTokenUsageOption === option
                        ? 'white'
                        : 'rgba(102, 112, 133, 1)',
                    fontSize: '12px',
                    lineHeight: '19.92px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {option}
                </Typography>
              </Box>
            );
          })}

          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            {authUser.selectedCompanyId && userRole === 'admin' && (
              <Box
                sx={{
                  background: '#eaecf0',
                  width: '175px',
                  padding: '3px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  justifyContent: 'center',
                  // marginLeft: 'auto',

                  position: 'relative',
                }}
                onClick={() => setShowViewOptions(prev => !prev)}
                ref={viewOptionsRef}
              >
                <Typography
                  sx={{
                    color: 'rgba(102, 112, 133, 1)',
                    fontSize: '12px',
                    padding: 0,
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                    marginRight: '15%',
                  }}
                >
                  {selectedViewType}
                </Typography>

                <CaretDown
                  weight="fill"
                  color="rgba(102, 112, 133, 1)"
                  size={12}
                  style={{ marginRight: '-15%' }}
                />

                {showViewOptions && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '30px',
                      left: '0',
                      background: 'white',
                      boxShadow: '0 3px 4px rgba(0,0,0,.4)',
                      padding: '10px 15px',
                      width: '145px',
                      zIndex: '99',
                    }}
                  >
                    {Object.values(ViewType).map(viewType => (
                      <Box
                        sx={{
                          padding: '5px 0',
                        }}
                        onClick={() => setSelectedViewType(viewType)}
                      >
                        <Typography
                          sx={{
                            color: 'rgba(102, 112, 133, 1)',
                            fontSize: '12px',
                            textAlign: 'center',
                            lineHeight: '20px',
                            letterSpacing: '0.4px',
                            '&:hover': {
                              color: '#3650DE',
                            },
                          }}
                        >
                          {viewType}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            <div className="filter-by">
              {showCalendar && (
                <FormControl>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ margin: '0 10px' }}
                  >
                    <Typography
                      sx={{
                        color: 'rgba(71, 84, 103, 1)',
                      }}
                    >
                      Weekly
                    </Typography>
                    <BlueSwitch
                      ref={switchRef}
                      value={'Monthly'}
                      checked={selectedFilterBy === FilterBy.Month}
                      onChange={() =>
                        filterByChangeHandler(
                          selectedFilterBy === FilterBy.Month
                            ? FilterBy.Week
                            : FilterBy.Month,
                        )
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      sx={{
                        color: 'rgba(71, 84, 103, 1)',
                      }}
                    >
                      Monthly
                    </Typography>
                  </Stack>
                </FormControl>
              )}
              <div
                ref={calendarPreviewRef}
                className="calendar-preview"
                style={{
                  background: 'rgba(234, 236, 240, 1)',
                  padding: '6px 10px',
                }}
                onClick={toggleCalendarHandler}
              >
                <Typography
                  className="calendar-preview-text"
                  sx={{
                    color: 'rgba(102, 112, 133, 1)',
                    fontSize: '12px',
                    padding: 0,
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {selectedRangeDisplay}
                </Typography>

                <CaretDown
                  weight="fill"
                  color="rgba(102, 112, 133, 1)"
                  size={12}
                />
              </div>
            </div>
          </Box>
        </header>

        <div ref={calendarRef} className={'date-range-picker-container'}>
          {showCalendar && (
            <DateRangePicker
              className="date-range-picker"
              ranges={[selectedDateRange]}
              onChange={onChangeHandler}
              minDate={new Date('01/01/2020')}
              maxDate={new Date('12/31/2030')}
              scroll={{ enabled: true }}
              moveRangeOnFirstSelection={false}
              rangeColors={['#3650DE']}
              editableDateInputs={false}
              showPreview={true}
              staticRanges={filteredStaticRanges[selectedFilterBy]}
              inputRanges={[]}
              weekStartsOn={0}
              dragSelectionEnabled={false}
              // retainEndDateOnFirstSelection
            />
          )}
        </div>

        {selectedTokenUsageOption === TokenUsageOption.Images && (
          <ImagesUsage
            selectedDateRange={selectedDateRange}
            queryString={queryString}
            differentYearsSelected={differentYearsSelected}
            filteredBy={selectedFilterBy}
            viewType={selectedViewType}
          />
        )}

        {selectedTokenUsageOption === TokenUsageOption.Text && (
          <TextUsage
            selectedDateRange={selectedDateRange}
            queryString={queryString}
            differentYearsSelected={differentYearsSelected}
            filteredBy={selectedFilterBy}
            viewType={selectedViewType}
          />
        )}

        {selectedTokenUsageOption === TokenUsageOption.Audio && (
          <AudioUsage
            selectedDateRange={selectedDateRange}
            queryString={queryString}
            differentYearsSelected={differentYearsSelected}
            filteredBy={selectedFilterBy}
            viewType={selectedViewType}
          />
        )}

        {selectedTokenUsageOption === TokenUsageOption.APICalls && (
          <ApiCallsUsage
            selectedDateRange={selectedDateRange}
            queryString={queryString}
            differentYearsSelected={differentYearsSelected}
            filteredBy={selectedFilterBy}
            viewType={selectedViewType}
          />
        )}

        {(selectedTokenUsageOption === TokenUsageOption.OverallUsage ||
          selectedTokenUsageOption === TokenUsageOption.Stacked) && (
          <OverallUsage
            selectedDateRange={selectedDateRange}
            queryString={queryString}
            differentYearsSelected={differentYearsSelected}
            filteredBy={selectedFilterBy}
            stacked={selectedTokenUsageOption === TokenUsageOption.Stacked}
            viewType={selectedViewType}
          />
        )}
      </Box>
    </>
  );
};

export default TokenUsage;
