import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormsService } from '../../service/FormService';
import { useAuthStore } from '../../store/storeAuth';
import { FluxService } from '../../service/FluxService';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import FluxpromptLogo from '../../assets/icons/fluxprompt-logo.svg';
import { useNotificationStore } from '../../store/storeNotifications';
import { io } from 'socket.io-client';
import NodesPreview from '../FormBuilder/PreviewModal/NodesPreview';

const PublishedForm = () => {
  const [isLoadin, setIsLoading] = useState(false);
  const [form, setForm] = useState<any>({});
  const [nodes, setNodes] = useState<any[]>([]);
  const params = useParams<{ formId: string }>();
  const [inputs, setInputs] = useState<any>([]);
  const [outputs, setOutputs] = useState<any>([]);
  const [flows, setFlows] = useState<any[]>([]);
  const [formLoading, setFormLoading] = useState(false);
  const user = useAuthStore(state => state.user);
  const [errorType, setErrorType] = useState('');
  const [socketClient, setSocketClient] = useState<any>(null);
  const setNotification = useNotificationStore(state => state.setNotification);
  const navigate = useNavigate();

  useEffect(() => {
    const getFlow = async () => {
      setIsLoading(true);
      try {
        const response = await FormsService.getFormPublished(params.formId!);

        const nodes = response.data.publishedNodes;

        const mapNodes = nodes.map((node: any) => {
          if (node.type === 'inputDisplay') {
            const getVarInputNodes = response?.data?.flow?.nodes
              ?.filter((nd: any) => nd.type === 'varInputNode')
              ?.map((nd: any) => {
                return {
                  id: nd.id,
                  label: nd.data.label,
                };
              });
            return {
              ...node,
              data: {
                ...node.data,
                varInputNodes: getVarInputNodes,
              },
            };
          }

          if (node.type === 'outputDisplay') {
            const getVarInputNodes = response?.data?.flow?.nodes
              ?.filter((nd: any) => nd.type === 'outputObjectNode')
              ?.map((nd: any) => {
                return {
                  id: nd?.id,
                  label: nd?.data?.label,
                };
              });

            return {
              ...node,
              data: {
                ...node.data,
                varOutputNodes: getVarInputNodes,
              },
            };
          }
          return {
            ...node,
          };
        });

        const flowInputs = response?.data?.flows
          ?.map((flow: any) => {
            const getFlowInputs = flow?.nodes
              .filter((node: any) => node.type === 'varInputNode')
              ?.map((node: any) => ({
                inputId: node?.id,
                inputText: '',
              }));
            return getFlowInputs;
          })
          .flat();

        const flowOutputs = response?.data?.flows
          ?.map((flow: any) => {
            const getFlowOutputs = flow?.nodes
              .filter((node: any) => node.type === 'outputObjectNode')
              ?.map((node: any) => ({
                outputId: node?.id,
                text: '',
              }));
            return getFlowOutputs;
          })
          .flat();

        setInputs(flowInputs);
        setOutputs(flowOutputs);
        setNodes(mapNodes?.filter((node: any) => node.type !== 'linesNode'));
        setFlows(response?.data?.flows);
        setForm(response?.data);

        if (response?.data?.formStyle?.backgroundImage) {
          document.body.style.backgroundImage = `url(${response?.data?.formStyle?.backgroundImage})`;
          document.body.style.backgroundSize = 'cover';
          document.body.style.backgroundRepeat = 'no-repeat';
          document.body.style.backgroundPosition = 'center';
        }

        // Add background color with !important
        if (response?.data?.formStyle?.backgroundColor) {
          document.body.style.setProperty(
            'background-color',
            response?.data?.formStyle?.backgroundColor,
            'important',
          );
        }
      } catch (error: any) {
        if (error?.message === 'You need to be logged in to access this form') {
          setErrorType('login');
        }

        if (error?.message === 'Form not found') {
          setErrorType('notFound');
        }
        if (
          error?.message ===
          'Sorry, this form is not published or its not available'
        ) {
          setErrorType('notPublished');
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (params.formId) {
      getFlow();
    }
  }, [params.formId]);

  const calculatePositions = (nodes: any) => {
    const sortedNodes = nodes.sort(
      (a: any, b: any) => a.position.y - b.position.y,
    );
    const minY = Math.min(...sortedNodes.map((node: any) => node.position.y));

    const adjustedNodes = sortedNodes.map((node: any) => {
      return {
        ...node,
        position: {
          x: node.position.x < 0 ? 0 : node.position.x,
          y: node.position.y - minY < 0 ? 0 : node.position.y - minY,
        },
      };
    });

    const groupedNodes: any[] = [];
    const range = 70;
    adjustedNodes.forEach((node: any) => {
      const group = groupedNodes.find((g: any) =>
        g.some((n: any) => Math.abs(n.position.y - node.position.y) <= range),
      );
      if (group) {
        group.push(node);
      } else {
        groupedNodes.push([node]);
      }
    });

    let currentY = 0;
    const marginY = 20;
    const marginX = 10;

    const finalNodes: any[] = [];
    groupedNodes.forEach(group => {
      let currentX = 0;
      group.forEach((node: any) => {
        finalNodes.push({
          ...node,
          position: {
            x: currentX,
            y: currentY,
          },
        });
        currentX += (node?.width || 100) + marginX;
      });
      const maxHeight = Math.max(
        ...group.map((node: any) => node?.height || 100),
      );
      currentY += maxHeight + marginY;
    });

    // sort groupedNodes by position X
    const sorted = groupedNodes.map(newGroup =>
      newGroup.sort((a: any, b: any) => a.position.x - b.position.x),
    );

    return { finalNodes, groupedNodes: sorted, adjustedNodes: sorted?.flat() };
  };

  const onSubmit = async (nodeData: any) => {
    setFormLoading(true);
    try {
      const { submitType, flows: flowIds } = nodeData;

      let flowsIds =
        !submitType || submitType === 'default'
          ? flows?.map((flow: any) => flow.id)
          : flowIds;

      for (let i = 0; i < flowsIds.length; i++) {
        for (let j = i; j < flowsIds.length; j++) {
          if (i === j) {
            await FluxService.formApi(
              flowsIds[i],
              form?.id,
              socketClient?.id!,
              {
                variableInputs: inputs,
              },
            );
          }
        }
      }

      // set new output

      // setOutputs(response?.data?.message);
    } catch (error: any) {
      setNotification({
        message: error?.message,
        type: 'error',
        duration: 3000,
      });
      setFormLoading(false);
    }
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL as string);

    socket.on('connect', () => {
      setSocketClient(socket);
    });
    socket.on('form-test', (data: any) => {
      setFormLoading(false);
      if (data?.data) {
        setOutputs((prev: any) => {
          return prev.map((item: any) => {
            const findItem = data?.data?.find(
              (data: any) => data.outputId === item?.outputId,
            );
            if (findItem) {
              return {
                ...item,
                text: findItem.text,
              };
            }
            return item;
          });
        });
      }
    });

    return () => {
      socket?.off('connect');
      socket?.off('form-test');
    };
  }, [setOutputs]);

  const { adjustedNodes } = calculatePositions(nodes);

  if (isLoadin) {
    return <LoadingSpinner height="100vh" />;
  }

  return (
    <Box
      sx={{
        maxWidth: '1048px',
        margin: '0 auto',
        p: '20px 16px',
      }}
    >
      {errorType && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              bgcolor: '#fff',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              gap: '30px',
              p: '20px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              height: '400px',
            }}
          >
            <Box>
              <img
                style={{
                  maxWidth: '350px',
                  width: '100%',
                  height: '100px',
                }}
                src={FluxpromptLogo}
                alt="Fluxprompt Logo"
              />
            </Box>

            {errorType === 'login' && (
              <>
                <Typography mb={'24px'} variant="h1">
                  You need to be logged in to access this form
                </Typography>

                <Button
                  onClick={() => {
                    navigate('/login');
                  }}
                  variant="contained"
                  fullWidth
                >
                  Login
                </Button>
              </>
            )}

            {(errorType === 'notPublished' || errorType === 'notFound') && (
              <>
                <Typography variant="h1">
                  Sorry, this form is not published or its not available
                </Typography>

                <Button
                  onClick={() => {
                    navigate('/login');
                  }}
                  variant="contained"
                  fullWidth
                >
                  {user?.id ? 'Go To Dashboard' : 'Login '}
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      {/* {adjustedNodes?.map((nd: any, index) => {
    return <Nodes key={index} nd={nd} />;
  })} */}

      {formLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(255,255,255,0.5)',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 250px)', // 4 fixed-width columns
          gap: form?.formStyle?.horizontalSettings?.enabled
            ? form?.formStyle?.horizontalSettings?.gap
              ? form?.formStyle?.horizontalSettings?.gap + 'px'
              : '16px'
            : '0',
          position: 'relative', // Allows absolute positioning if needed

          '@media (max-width: 768px)': {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {adjustedNodes.map(
          (
            node: { width: number; height: number; data: any },
            index: React.Key | null | undefined,
          ) => {
            const width =
              node.data?.widthData.w === '100%'
                ? 1000
                : node.data.widthData?.width;
            // Calculate gridColumn span based on width
            const columnSpan = Math.ceil(width / 250);

            return (
              <Box
                key={index}
                sx={{
                  gridColumn: `span ${columnSpan}`, // Use calculated column span
                  // gridRow: `span ${Math.ceil(node.height / 100)}`, // Adjust grid rows based on height
                  // Positioning
                  position: 'relative', // Optional, for children positioning

                  '@media (max-width: 768px)': {
                    gridColumn: 'unset',
                    gridRow: 'unset',
                  },
                }}
              >
                <NodesPreview
                  nd={node}
                  groupLength={1}
                  inputs={inputs}
                  outputs={outputs}
                  gapEnabled={form?.formStyle?.horizontalSettings?.enabled}
                  setInputs={setInputs}
                  setOutputs={setOutputs}
                  onSubmit={onSubmit}
                />
              </Box>
            );
          },
        )}
      </Box>
    </Box>
  );
};

export default PublishedForm;
