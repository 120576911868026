import React, { useEffect } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import Label from '../../UI/Label/Label';
import TagInput from '../../Test/TagInput';
import { useStoreGoogleData } from '../../../store/storeGoogleData';

interface Props {
  inputId: string;
  data: any;
}
//1ZJv9hIhG4-AkzT8BOB6tOh6mOFqcPBnr8n6rrFvQqQU
//1BxiMVs0XRA5nFMdKvBdBZjgmUUqptlbs74OgvE2upms

const PullPublicGoogleSheetsData: React.FC<Props> = ({ inputId, data }) => {
  const { workSheets } = useStoreGoogleData();

  useEffect(() => {
    if (!inputId) return;

    data?.updatePublicWorkSheets(inputId);
  }, [inputId]);

  return (
    <Box>
      <Label
        isAdvanced
        labelStyle={{
          fontSize: '14px',
          color: '#101828',
          fontWeight: 400,
          marginTop: '0px',
        }}
        labelName="Sheets"
      />
      <Select
        onChange={e => {
          data?.updateSelectedWorkSheet(parseInt(e.target.value as string));
        }}
        value={data?.selectedWorkSheet?.sheetId || undefined}
        className="nodrag"
        style={{ width: 200 }}
        name="sheets"
      >
        <MenuItem value={'none'} disabled>
          None
        </MenuItem>
        {workSheets.map(({ sheetId, title }) => (
          <MenuItem key={sheetId} value={sheetId}>
            {title}
          </MenuItem>
        ))}
      </Select>

      <TagInput
        placeholder="Use A1 Notation. Example: A1:Z40"
        onChange={(e: any) =>
          data?.setPublicRangeFilter(e.target.value as string)
        }
        name="rangeFilter"
        value={data?.publicRangeFilter}
        isAdvanced
        handleId={'rangeFilter'}
        showCopy
        dataConnected={data?.rangeFilterData || []}
        removeIcons
        labelName={'Select Area (Optional)'}
        isCollapsed
        labelStyle={{
          fontSize: '14px',
          color: '#101828',
          fontWeight: 400,
        }}
        advancedCollapsed
      />
    </Box>
  );
};

export default PullPublicGoogleSheetsData;
