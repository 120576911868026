import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import RecentlyOpened from './RecentlyOpened';
import SharedFlows from './SharedFlows';

enum FlowOption {
  RecentlyOpened = 'Recently Opened',
  SharedFiles = 'Shared Files',
}

const Flows: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<FlowOption>(
    FlowOption.RecentlyOpened
  );

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          margin: '20px 0',
        }}
      >
        <Box>
          <Box
            sx={{
              background:
                selectedOption === FlowOption.RecentlyOpened
                  ? 'rgba(54, 80, 222, 1)'
                  : 'rgba(242, 244, 247, 1)',
              display: 'inline-block',
              padding: '8px 10px',
              borderRadius: '8px',
              cursor: 'pointer',
              marginRight: '10px',
            }}
            onClick={() => {
              setSelectedOption(FlowOption.RecentlyOpened);
            }}
          >
            <Typography
              sx={{
                color:
                  selectedOption === FlowOption.RecentlyOpened
                    ? 'white'
                    : 'rgba(16, 24, 40, 1)',
                fontWeight:
                  selectedOption === FlowOption.RecentlyOpened ? 700 : 400,
                fontSize: '12px',
                lineHeight: '20px',
                letterSpacing: '0.4px',
              }}
            >
              {FlowOption.RecentlyOpened}
            </Typography>
          </Box>
          <Box
            sx={{
              background:
                selectedOption === FlowOption.SharedFiles
                  ? 'rgba(54, 80, 222, 1)'
                  : 'rgba(242, 244, 247, 1)',
              display: 'inline-block',
              padding: '8px 10px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedOption(FlowOption.SharedFiles);
            }}
          >
            <Typography
              sx={{
                color:
                  selectedOption === FlowOption.SharedFiles
                    ? 'white'
                    : 'rgba(16, 24, 40, 1)',
                fontWeight:
                  selectedOption === FlowOption.SharedFiles ? 700 : 400,
                fontSize: '12px',
                lineHeight: '20px',
                letterSpacing: '0.4px',
              }}
            >
              {FlowOption.SharedFiles}
            </Typography>
          </Box>
        </Box>
      </Box>
      {selectedOption === FlowOption.RecentlyOpened && <RecentlyOpened />}
      {selectedOption === FlowOption.SharedFiles && <SharedFlows />}
    </div>
  );
};

export default Flows;
