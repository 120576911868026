import React, { useEffect, useState } from 'react';
import { useStoreHubSpotData } from '../../store/storeHubSpotData';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { HubSpotService } from '../../service/HubSpotService';

const ConnectToHubSpot: React.FC = () => {
  const [installUrl, setInstallUrl] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);
  const { isHubSpotConnected, setIsHubSpotConnected } = useStoreHubSpotData();

  const loginErrorHandler = (err: any) => {
    setNotification({
      type: NotificationType.Error,
      message: getAPIErrorMessage(err as any),
    });
  };

  const exchangeCodeForToken = async (code: string) => {
    try {
      await HubSpotService.getHubSpotAccessToken(code);
      setIsHubSpotConnected(true);
    } catch (err: any) {
      loginErrorHandler(err);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      const handleCode = (event: any) => {
        if (event.data.type === 'integration_completed') {
          exchangeCodeForToken(event.data.code);
          setIsPopupOpen(false);

          window.removeEventListener('message', handleCode);
        }
      };

      window.addEventListener('message', handleCode);
    }
  }, [isPopupOpen]);

  useEffect(() => {
    const getInstallUrl = async () => {
      const response = await HubSpotService.getHubSpotInstallUrl();
      setInstallUrl(response.installUrl);
    };

    if (!installUrl) {
      getInstallUrl();
    }
  }, [installUrl]);

  const loginHandler = () => {
    window.open(installUrl, 'hubSpotpopup', 'popup');
    setIsPopupOpen(true);
  };

  return (
    <span
      style={{
        fontSize: '14px',
        color: '#3650DE',
        fontWeight: 700,
        cursor: 'pointer',
      }}
      onClick={() => loginHandler()}
    >
      Click here
    </span>
  );
};

export default ConnectToHubSpot;
