import React, { ReactElement, useMemo, useState } from 'react';
import KitModal from '../UI/KitModal';
import Navigation from './Navigation';
import './TemplateSystemModal.css';
import { templateCategories, templates } from './templateSystemData';
import TemplatesList from './TemplatesList';
import SeeAllList from './SeeAllList';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import ContentCreationIcon from '../../assets/icons/Content Creation.png';
import MarketingIcon from '../../assets/icons/Marketing.png';
import ResearchIcon from '../../assets/icons/Research & Development.png';
import DesignIcon from '../../assets/icons/Design.png';
import DataAnalyticsIcon from '../../assets/icons/Data Analytics.png';
import ProjectManagementIcon from '../../assets/icons/Project Management.png';
import SalesIcon from '../../assets/icons/Sales.png';
import CustomServiceIcon from '../../assets/icons/Customer Service.png';
import FinanceIcon from '../../assets/icons/Finance.png';
import HumanResourcesIcon from '../../assets/icons/Human Resources.png';
import ProductManagement from '../../assets/icons/Product Management.png';
import SearchIcon from '../../assets/icons/Search.png';

interface Props {
  onClose: () => void;
}

export const Icons: { [key: string]: ReactElement } = {
  'Content Creation': <img src={ContentCreationIcon} alt='Content Creations' />,
  Marketing: <img src={MarketingIcon} alt='Marketings' />,
  'Research & Development': (
    <img src={ResearchIcon} alt='Research & Development' />
  ),
  Design: <img src={DesignIcon} alt='Design' />,
  'Data Analytics': <img src={DataAnalyticsIcon} alt='Data Analytics' />,
  'Project Management': (
    <img src={ProjectManagementIcon} alt='ProjectManagement' />
  ),
  Sales: <img src={SalesIcon} alt='Sales' />,
  'Customer Service': <img src={CustomServiceIcon} alt='Customer Service' />,
  Finance: <img src={FinanceIcon} alt='Finance' />,
  'Human Resources': <img src={HumanResourcesIcon} alt='Human Resources' />,
  'Product Management': (
    <img src={ProductManagement} alt='Product Management' />
  ),
};

const TemplateSystemModal: React.FC<Props> = ({ onClose }) => {
  const [selectedCategory, setSelectedCategory] = useState<undefined | string>(
    undefined
  );
  const [searchValue, setSearchValue] = useState('');
  const [showAll, setShowAll] = useState(true);
  const [showRecommended, setShowRecommended] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const templatesPerSelectedCategory = useMemo(() => {
    if (!selectedCategory) return [];

    return templates.filter(({ templateCategory }) =>
      templateCategory.includes(selectedCategory)
    );
  }, [selectedCategory]);

  return (
    <KitModal
      show={true}
      onHide={onClose}
      maxWidth='xl'
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '75%',
        },
        '& .MuiDialogContent-root': {
          padding: 0,
        },
      }}
    >
      <div className='template-system-container'>
        <Navigation
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setShowAll={setShowAll}
          showAll={showAll}
          showRecommended={showRecommended}
          setShowRecommended={setShowRecommended}
        />

        <div style={{ marginTop: 40, marginLeft: 20, width: '90%' }}>
          <FormControl sx={{ m: 1 }} variant='standard'>
            <TextField
              variant="outlined"
              placeholder="Search All"
              onChange={onChange}
              value={searchValue}
              sx={{
                width: 500,
                padding: '4px, 24px, 4px, 24px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  padding: '4px, 24px, 4px, 24px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          {showAll && (
            <SeeAllList
              templateCategories={templateCategories}
              searchValue={searchValue}
              closeTemplateSystemModal={onClose}
              seeAllHandler={category => {
                setShowAll(false);
                setSelectedCategory(category);
                setShowRecommended(false);
              }}
              showRecommended={false}
            />
          )}

          {showRecommended && (
            <SeeAllList
              templateCategories={templateCategories}
              searchValue={searchValue}
              closeTemplateSystemModal={onClose}
              seeAllHandler={category => {
                setShowAll(false);
                setSelectedCategory(category);
                setShowRecommended(false);
              }}
              showRecommended={true}
            />
          )}

          {selectedCategory && (
            <TemplatesList
              templates={templatesPerSelectedCategory}
              searchValue={searchValue}
              closeTemplateSystemModal={onClose}
              selectedCategory={selectedCategory}
            />
          )}
        </div>
      </div>
    </KitModal>
  );
};

export default TemplateSystemModal;
