import { create } from 'zustand';

type Props = {
  changeLabel: {
    myId: string;
    label: string;
  };

  deleteNode: {
    id: string | null;
  };

  setDeleteNode: (response: string | null) => void;

  setChangeLabel: (response: { myId: string; label: string }) => void;
};

export const useNodesStore = create<Props>(set => ({
  changeLabel: {
    myId: '',
    label: '',
  },

  deleteNode: {
    id: '',
  },

  setDeleteNode: (response: string | null) => {
    set(() => ({
      deleteNode: {
        id: response,
      },
    }));
  },

  setChangeLabel: (response: { myId: string; label: string }) => {
    set(() => ({
      changeLabel: {
        ...response,
      },
    }));
  },
}));
