import { Box } from '@mui/material';
import IconButtonTooltip from '../../../components/UI/IconButtonTooltip/IconButtonTooltip';
import { ArrowArcLeft, ArrowArcRight } from '@phosphor-icons/react';

type HeaderProps = {
  flow: any;
  form: any;
  formattedTime: string;
  formattedDate: string;
  onSave: () => void;
  onPreview: (type: string) => void;
};

const Header = ({
  flow,
  form,
  formattedTime,
  formattedDate,
  onSave,
  onPreview,
}: HeaderProps) => {
  return (
    <Box
      sx={{
        background: '#EAECF0',
        p: '12px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <IconButtonTooltip onClick={() => onPreview('prev')} title="undo">
          <ArrowArcLeft size={20} />
        </IconButtonTooltip>
        <IconButtonTooltip onClick={() => onPreview('next')} title="Redo">
          <ArrowArcRight size={20} />
        </IconButtonTooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
        }}
      >
        {/* <Typography
          sx={{
            color: '#98A2B3',
            fontSize: '14px',

            fontWeight: '500',
          }}
        >
          {flow?.name} /
        </Typography> */}
        <Box
          sx={{
            color: '#3650DE',
            fontSize: '14px',
            fontWeight: '500',
            textTransform: 'capitalize',
          }}
        >
          {form?.name}
        </Box>
      </Box>
      <Box></Box>
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Typography
          sx={{
            color: '#98A2B3',
            fontSize: '14px',

            fontWeight: '500',
          }}
        >
          Last saved on {formattedTime} {formattedDate}
        </Typography>

        <Button
          variant="text"
          sx={{
            color: '#3650DE',
            fontSize: '14px',
            fontWeight: '500',
          }}
          onClick={onSave}
        >
          Save
        </Button>
      </Box> */}
    </Box>
  );
};

export default Header;
