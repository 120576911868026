import React, { useState } from 'react';

import KitModal from '../../../../UI/KitModal';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { MagnifyingGlass } from '@phosphor-icons/react';

type Props = {
  onHide: () => void;
  show: boolean;
  onOpenPreviewFile: any;
};

const FilesModal = ({ onHide, show, onOpenPreviewFile }: Props) => {
  const [searchFile, setSearchFile] = useState('');

  const handleSearchFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFile(e.target.value);
  };

  const checkIfIsImage = (src: string) => {
    return (
      src.includes('.png') || src.includes('.jpg') || src.includes('.jpeg')
    );
  };

  const checkIfHasText = (src: string) => {
    return src.includes('.txt') || src.includes('.csv') || src.includes('.pdf');
  };

    const formatTextWithLineBreaks = (text: string) => {
      return text.replace(/\n/g, '<br />');
    };

  return (
    <KitModal
      onHide={() => {
        onHide();
      }}
      title='Files'
      fullWidth
      show={show}
    >
      <>
        {!onOpenPreviewFile.isOpen && (
          <Box className='input-group mb-3'>
            <input
              type='text'
              className='form-control'
              placeholder='Search file'
              aria-label='Search file'
              aria-describedby='basic-addon2'
              value={searchFile}
              onChange={handleSearchFile}
            />
            <span className='input-group-text' id='basic-addon2'>
              <MagnifyingGlass />
            </span>
          </Box>
        )}

        <Box>
          {checkIfHasText(onOpenPreviewFile.src) &&
            onOpenPreviewFile.generatedText && (
              <Card className='card mt-2'>
                <CardContent className='card-body'>
                  <Typography sx={{ fontSize: 16 }} className='card-title'>
                    File Name : {onOpenPreviewFile.name} 
                  </Typography>
                  <Typography sx={{ fontSize: 16 }} className='card-title'>
                    Language : {onOpenPreviewFile.language} 
                  </Typography>
                  <br />
                  <Typography className='card-text'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatTextWithLineBreaks(onOpenPreviewFile.generatedText),
                    }}
                  />
                    {/* {onOpenPreviewFile.generatedText} */}
                  </Typography>
                </CardContent>
              </Card>
            )}
          {checkIfIsImage(onOpenPreviewFile.src) && (
            <Card className='card mt-2'>
              <CardContent className='card-body'>
                <h5 className='card-title'>File Image</h5>
                <img
                  src={onOpenPreviewFile.src}
                  alt={onOpenPreviewFile.name}
                  className='img-fluid'
                />
              </CardContent>
            </Card>
          )}
        </Box>
      </>
    </KitModal>
  );
};

export default FilesModal;
