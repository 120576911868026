import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
import { UploadImage } from '../util/Types/UploadImage';

export class UploadService extends ApiService {
  static pathName = '/upload';

  static uploadFile(file: any, flowId?: string): Promise<IGetOneResponse<UploadImage>> {
    return this.post(`${this.pathName}/single`, file);
  }
}
