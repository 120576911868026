import { create } from 'zustand';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
}

type NotificationState = {
  notification: {
    type: string;
    message: string;
    duration?: number;
    key?: string | number;
  };
  setNotification: (notification: {
    type: string;
    message: string;
    duration?: number;
  }) => void;
  removeNotification: () => void;
};

export const useNotificationStore = create<NotificationState>(set => ({
  notification: {
    type: '',
    message: '',
    duration: 3000,
  },
  setNotification: (notification: {
    type: string;
    message: string;
    duration?: number;
  }) => {
    set(() => ({
      notification: {
        type: notification.type,
        message: notification.message,
        duration: notification.duration,
      },
    }));
  },
  removeNotification: () => {
    // console.log('Removing notification');
    set(() => ({
      notification: {
        type: '',
        message: '',
        duration: 0,
      },
    }));
  },
}));
