import React, { useEffect, useRef, useState } from 'react';
import KitModal from '../UI/KitModal';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import html2canvas from 'html2canvas';
import classes from './ExpandImageModal.module.css';
import { Box, Button, Slider, Typography } from '@mui/material';
import { ArrowUUpLeft, Trash } from '@phosphor-icons/react';

interface Props {
  imageUrl: string;
  onHideModal: () => void;
  onSave: (
    url: string,
    dimensions: { top: number; left: number; bottom: number; right: number },
  ) => void;
  initialDimensions?: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
}

const initialWidth = 500;
const initialHeight = 300;
const canvasWidth = 1278;
const fullCanvasHeight = 520;

const ExpandImageModal = (props: Props) => {
  const { onHideModal, onSave, imageUrl, initialDimensions } = props;

  const [history, setHistory] = useState<any>([
    {
      topOffset: 0,
      leftOffset: 0,
      rightOffset: 0,
      bottomOffset: 0,
    },
  ]);
  const [currentStep, setCurrentStep] = useState(0);
  const resizableRef = useRef<any>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [topOffset, setTopOffset] = useState(0);
  const [leftOffset, setLeftOffset] = useState(0);
  const [rightOffset, setRightOffset] = useState(0);
  const [bottomOffset, setBottomOffset] = useState(0);

  const borderWidth = initialWidth + leftOffset + rightOffset;
  const borderHeight = initialHeight + topOffset + bottomOffset;

  const [expandAllValue, setExpandAllValue] = useState(0);

  useEffect(() => {
    if (initialDimensions) {
      setTopOffset(initialDimensions.top);
      setRightOffset(initialDimensions.right);
      setLeftOffset(initialDimensions.left);
      setBottomOffset(initialDimensions.bottom);
      setHistory([
        {
          topOffset: initialDimensions.top,
          leftOffset: initialDimensions.left,
          rightOffset: initialDimensions.right,
          bottomOffset: initialDimensions.bottom,
        },
      ]);
    }
  }, [initialDimensions]);

  const maxHorizontalOffset = 700;
  const maxVerticalOffset = 300;

  const aspectRatio = initialWidth / initialHeight;

  let scaledWidth = initialWidth - 30;
  let scaledHeight = initialHeight - 30;
  const widthToScale = canvasWidth - initialWidth;
  const heightToScale = fullCanvasHeight - initialHeight;

  if (
    leftOffset + rightOffset > widthToScale ||
    bottomOffset + topOffset > heightToScale
  ) {
    const horizontalScaleFactor = Math.min(
      1,
      maxHorizontalOffset / (leftOffset + rightOffset),
    );
    scaledWidth = scaledWidth * horizontalScaleFactor;
    scaledHeight = scaledWidth / aspectRatio;
  }

  if (topOffset > maxVerticalOffset || bottomOffset > maxVerticalOffset) {
    const verticalScaleFactor = Math.min(
      1,
      maxVerticalOffset / (topOffset + bottomOffset),
    );
    scaledHeight = scaledHeight * verticalScaleFactor;
    scaledWidth = scaledHeight * aspectRatio;
  }

  const handleResize = (event: any, { size, handle }: any) => {
    const newWidth = size.width;
    const newHeight = size.height;

    let newLeftOffset = leftOffset;
    let newRightOffset = rightOffset;

    if (
      handle.includes('w') ||
      handle.includes('sw') ||
      handle.includes('nw')
    ) {
      //  left
      newLeftOffset = newWidth - initialWidth - rightOffset;
    } else if (
      handle.includes('e') ||
      handle.includes('se') ||
      handle.includes('ne')
    ) {
      // right
      newRightOffset = newWidth - initialWidth - leftOffset;
    }

    let newTopOffset = topOffset;
    let newBottomOffset = bottomOffset;

    if (
      handle.includes('n') ||
      handle.includes('nw') ||
      handle.includes('ne')
    ) {
      //  up
      newTopOffset = newHeight - initialHeight - bottomOffset;
    } else if (
      handle.includes('s') ||
      handle.includes('se') ||
      handle.includes('sw')
    ) {
      //  down
      newBottomOffset = newHeight - initialHeight - topOffset;
    }

    setLeftOffset(newLeftOffset);
    setRightOffset(newRightOffset);
    setTopOffset(newTopOffset);
    setBottomOffset(newBottomOffset);

    handleStepChanges({
      topOffset: newTopOffset,
      rightOffset: newRightOffset,
      leftOffset: newLeftOffset,
      bottomOffset: newBottomOffset,
    });
  };

  const handleStepChanges = ({
    topOffset,
    leftOffset,
    rightOffset,
    bottomOffset,
  }: {
    topOffset: number;
    leftOffset: number;
    rightOffset: number;
    bottomOffset: number;
  }) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const newState = {
        topOffset,
        leftOffset,
        rightOffset,
        bottomOffset,
      };

      if (currentStep === history.length - 1) {
        setHistory([...history, newState]);
        setCurrentStep(currentStep => currentStep + 1);
      } else {
        const newHistory = history.slice(0, currentStep + 1);
        setHistory([...newHistory, newState]);
        setCurrentStep(newHistory.length);
      }
    }, 300);
  };

  const handleUndo = () => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      const prevState = history[prevStep];

      setTopOffset(prevState.topOffset);
      setLeftOffset(prevState.leftOffset);
      setRightOffset(prevState.rightOffset);
      setBottomOffset(prevState.bottomOffset);

      setCurrentStep(prevStep);
    } else {
      setTopOffset(0);
      setLeftOffset(0);
      setRightOffset(0);
      setBottomOffset(0);
    }
  };

  const handleRedo = () => {
    if (currentStep < history.length - 1) {
      const nextStep = currentStep + 1;
      const nextState = history[nextStep];

      setTopOffset(nextState.topOffset);
      setLeftOffset(nextState.leftOffset);
      setRightOffset(nextState.rightOffset);
      setBottomOffset(nextState.bottomOffset);

      setCurrentStep(nextStep);
    }
  };

  function handleReset() {
    const initialOffsets = {
      topOffset: 0,
      leftOffset: 0,
      rightOffset: 0,
      bottomOffset: 0,
    };

    setHistory([initialOffsets]);
    setCurrentStep(0);

    setTopOffset(0);
    setLeftOffset(0);
    setRightOffset(0);
    setBottomOffset(0);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }

  const borderX = initialWidth - scaledWidth - 30;
  const borderY = initialHeight - scaledHeight - 30;

  const getDimensionStyles = () => {
    const middleBottomWidth =
      Math.min(1278, borderWidth - borderX) / 2 - scaledWidth / 2;
    const middleBottomHeight =
      Math.min(520, borderHeight - borderY) / 2 - scaledHeight / 2;

    const horizontalProportion = (leftOffset - rightOffset) / scaledWidth;
    const verticalProportion = (topOffset - bottomOffset) / scaledHeight;
    let top: number | string = Math.min(
      topOffset + 10,
      Math.max(0, scaledHeight),
    );
    let left: number | string = Math.min(
      leftOffset + 10,
      Math.max(0, scaledWidth),
    );
    let bottom: number | string = 'auto';
    let right: number | string = 'auto';

    const canvasWidth = 710 - scaledWidth;
    const canvasHeight = 220;
    let offsetX = 10;

    if (verticalProportion > 0.5) {
      bottom = 0 + offsetX;
      top = 'auto';
    }

    if (horizontalProportion > 0.5) {
      right = 0 + offsetX;
      left = 'auto';
    }

    if (
      leftOffset > canvasWidth &&
      leftOffset - rightOffset < canvasWidth &&
      leftOffset >= rightOffset
    ) {
      const dif = canvasWidth - leftOffset + rightOffset;

      if (dif >= 1200) {
        left = 0 + offsetX;
        right = 'auto';
      } else {
        right = dif < 0 ? 0 + offsetX : dif;
        left = 'auto';
      }
    } else {
      if (leftOffset - rightOffset >= canvasWidth) {
        right = 0 + offsetX;
        left = 'auto';
      } else if (rightOffset - leftOffset >= canvasWidth) {
        left = 0 + offsetX;
        right = 'auto';
      }
    }

    if (
      topOffset > canvasHeight &&
      topOffset - bottomOffset < canvasHeight &&
      topOffset >= bottomOffset
    ) {
      const dif = canvasHeight - topOffset + bottomOffset;

      if (dif >= 520) {
        top = 0 + offsetX;
        bottom = 'auto';
      } else {
        bottom = dif < 0 ? 0 + offsetX : dif;
        top = 'auto';
      }
    } else if (
      bottomOffset > canvasHeight &&
      bottomOffset - topOffset < canvasHeight &&
      bottomOffset > topOffset &&
      bottomOffset - topOffset > 0
    ) {
      const dif = canvasHeight - bottomOffset + topOffset;

      if (dif >= 520) {
        bottom = 0 + offsetX;
        top = 'auto';
      } else {
        top = dif < 0 ? 0 + offsetX : dif;
        bottom = 'auto';
      }
    } else {
      if (topOffset - bottomOffset >= canvasHeight) {
        bottom = 0 + offsetX;
        top = 'auto';
      } else if (bottomOffset - topOffset >= canvasHeight) {
        top = 0 + offsetX;
        bottom = 'auto';
      }
    }

    if (
      rightOffset > canvasWidth &&
      rightOffset - leftOffset < canvasWidth &&
      rightOffset > leftOffset &&
      rightOffset - leftOffset > 0
    ) {
      const dif = canvasWidth - rightOffset + leftOffset;

      if (dif >= 1200) {
        right = 0 + offsetX;
        left = 'auto';
      } else {
        left = dif < 0 ? 0 + offsetX : dif;
        right = 'auto';
      }
    }

    if (leftOffset > rightOffset) {
      right = (rightOffset / leftOffset) * middleBottomWidth + offsetX;
    } else {
      left = (leftOffset / rightOffset) * middleBottomWidth + offsetX;
    }

    if (leftOffset === 0 && 0 === rightOffset) {
      right = 'auto';
      left = offsetX;
    }

    if (topOffset > bottomOffset) {
      bottom = (bottomOffset / topOffset) * middleBottomHeight + offsetX;
    } else {
      top = (topOffset / bottomOffset) * middleBottomHeight + offsetX;
    }

    if (topOffset === 0 && 0 === bottomOffset) {
      top = 'auto';
      bottom = offsetX;
    }

    return {
      top,
      left,
      bottom,
      right,
    };
  };

  return (
    <KitModal
      maxWidth={'md'}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '2000px',
          maxHeight: '1000px',
          width: '86%',
          height: '99%',
          borderRadius: '16px',
        },
      }}
      fullWidth
      onHide={onHideModal}
      show
    >
      <div>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 500,
            marginTop: '0px',
            padding: '0px 0',
          }}
        >
          Expand
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '32px',
            letterSpacing: '0.15',
          }}
        >
          Drag the borders or sliders to indicate how you want Ai to expand your
          image.
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            gap: '20px',
            margin: '0',
            borderBottom: '1px solid rgba(242, 244, 247, 1)',
            textAlign: 'right',
          }}
        >
          <Button
            onClick={handleUndo}
            disabled={currentStep <= 0}
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <Typography sx={{ fontWeight: 500, color: 'rgba(71, 84, 103, 1)' }}>
              Undo
            </Typography>
            <ArrowUUpLeft color="rgba(71, 84, 103, 1)" />
          </Button>
          <Button
            onClick={handleRedo}
            disabled={currentStep >= history.length - 1}
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <Typography sx={{ fontWeight: 500, color: 'rgba(71, 84, 103, 1)' }}>
              Redo
            </Typography>
            <ArrowUUpLeft color="rgba(71, 84, 103, 1)" />
          </Button>

          <Button
            onClick={handleReset}
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <Typography sx={{ fontWeight: 500, color: 'rgba(71, 84, 103, 1)' }}>
              Reset
            </Typography>
            <Trash color="rgba(71, 84, 103, 1)" />
          </Button>
        </Box>

        <Box sx={{ display: 'flex', gap: '50px' }}>
          <Box>
            <Typography
              sx={{
                color: 'rgba(16, 24, 40, 1)',
                fontWeight: 500,
                fontSize: '14px',
                marginBottom: '10px',
              }}
            >
              Expand all
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Box sx={{ display: 'flex', gap: '5px' }}>
                <Slider
                  size="small"
                  defaultValue={expandAllValue / 20}
                  aria-label="Small"
                  onChange={(e: any) => {
                    const value = e.target.value * 20;
                    setExpandAllValue(e.target.value * 20);
                    setLeftOffset(value);
                    setRightOffset(value);
                    setTopOffset(value);
                    setBottomOffset(value);
                    handleStepChanges({
                      topOffset: value,
                      leftOffset: value,
                      rightOffset: value,
                      bottomOffset: value,
                    });
                  }}
                  value={expandAllValue / 20}
                  valueLabelDisplay="auto"
                  color="primary"
                  sx={{ width: '150px' }}
                />

                <Typography
                  sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontWeight: 500,
                    fontSize: '14px',
                    marginTop: '5px',
                  }}
                >
                  {expandAllValue.toFixed(2)} px
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                color: 'rgba(16, 24, 40, 1)',
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              Expand by orientation
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '10px 0',
                marginBottom: '20px',
                gap: '5px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(208, 213, 221, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    T
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Slider
                      size="small"
                      defaultValue={topOffset / 20}
                      aria-label="Small"
                      onChange={(e: any) => {
                        setTopOffset(e.target.value * 20);
                        handleStepChanges({
                          topOffset: e.target.value * 20,
                          leftOffset,
                          bottomOffset,
                          rightOffset,
                        });
                      }}
                      value={topOffset / 20}
                      valueLabelDisplay="auto"
                      color="primary"
                      sx={{ width: '130px' }}
                    />

                    <Typography
                      sx={{
                        color: 'rgba(16, 24, 40, 1)',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginTop: '5px',
                      }}
                    >
                      {topOffset.toFixed(2)} px
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(208, 213, 221, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    B
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <Slider
                      size="small"
                      defaultValue={bottomOffset / 20}
                      value={bottomOffset / 20}
                      aria-label="Small"
                      onChange={(e: any) => {
                        setBottomOffset(e.target.value * 20);
                        handleStepChanges({
                          bottomOffset: e.target.value * 20,
                          topOffset,
                          leftOffset,
                          rightOffset,
                        });
                      }}
                      valueLabelDisplay="auto"
                      color="primary"
                      sx={{ width: '130px' }}
                    />

                    <Typography
                      sx={{
                        color: 'rgba(16, 24, 40, 1)',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginTop: '5px',
                      }}
                    >
                      {bottomOffset.toFixed(2)} px
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(208, 213, 221, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    R
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <Slider
                      size="small"
                      defaultValue={rightOffset / 20}
                      aria-label="Small"
                      value={rightOffset / 20}
                      onChange={(e: any) => {
                        setRightOffset(e.target.value * 20);
                        handleStepChanges({
                          rightOffset: e.target.value * 20,
                          topOffset,
                          leftOffset,
                          bottomOffset,
                        });
                      }}
                      valueLabelDisplay="auto"
                      color="primary"
                      sx={{ width: '130px' }}
                    />

                    <Typography
                      sx={{
                        color: 'rgba(16, 24, 40, 1)',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginTop: '5px',
                      }}
                    >
                      {rightOffset.toFixed(2)} px
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px',
                    marginLeft: 'auto',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(208, 213, 221, 1)',

                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    L
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <Slider
                      size="small"
                      defaultValue={leftOffset / 20}
                      value={leftOffset / 20}
                      aria-label="Small"
                      onChange={(e: any) => {
                        setLeftOffset(e.target.value * 20);
                        handleStepChanges({
                          leftOffset: e.target.value * 20,
                          topOffset,
                          rightOffset,
                          bottomOffset,
                        });
                      }}
                      valueLabelDisplay="auto"
                      color="primary"
                      sx={{ width: '130px' }}
                    />

                    <Typography
                      sx={{
                        color: 'rgba(16, 24, 40, 1)',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginTop: '5px',
                      }}
                    >
                      {leftOffset.toFixed(2)} px
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <div className={classes['pageBackground']} ref={resizableRef}>
          <ResizableBox
            width={borderWidth - borderX}
            height={borderHeight - borderY}
            minConstraints={[initialWidth, initialHeight]}
            maxConstraints={[2000, 2000]}
            onResize={handleResize}
            resizeHandles={['n', 'e', 's', 'w', 'ne', 'nw', 'se', 'sw']}
            style={{
              border: '5px solid rgba(84, 107, 234, 1)',
              position: 'relative',
              boxSizing: 'border-box',
              backgroundColor: 'transparent',
              maxHeight: '520px',
              maxWidth: '1278px',
              zIndex: 99,
            }}
          >
            <img
              src={`${process.env.REACT_APP_API_URL}/proxy?url=${imageUrl}`}
              alt="Expandable"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                // objectFit: 'contain',

                width: scaledWidth,
                height: scaledHeight,

                position: 'absolute',
                zIndex: 0,
                ...getDimensionStyles(),
              }}
            />
          </ResizableBox>
        </div>
        <Box
          sx={{
            textAlign: 'right',
            marginTop: '1px',
            marginBottom: '0',
            marginRight: '35px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onSave(imageUrl, {
                top: topOffset,
                left: leftOffset,
                right: rightOffset,
                bottom: bottomOffset,
              });
            }}
          >
            Continue
          </Button>
        </Box>
      </div>
    </KitModal>
  );
};

export default ExpandImageModal;
