import { Box, Button, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { UserConfigService } from '../../service/UserConfigService';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import KitModal from '../UI/KitModal';
import AnthropicConfigForm from '../settings/AiConfig/AnthropicConfigForm';
import GeminiConfigForm from '../settings/AiConfig/GeminiConfigForm';
import GroqConfigForm from '../settings/AiConfig/GroqConfigForm';
import OpenAiConfigForm from '../settings/AiConfig/OpenAiConfigForm';
import OpenSourcesConfigForm from '../settings/AiConfig/OpenSourcesConfigForm';
import PerplexityConfigForm from '../settings/AiConfig/PerplexityConfigForm';
import {
  commandTextNodeDefaultValues,
  geminiAi,
  groqAi,
  openAi,
} from './constants';
import { ValueTypes } from './types';

type Props = {
  show: boolean;
  onHide: () => void;
  isEditingConfig?: {
    id: string;
    edit: boolean;
  };
  setIsEditingConfig?: (editing: { id: string; edit: boolean }) => void;
  saveChanges: () => void;
  textSystems: string[];
};

export type TextModalHandle = {
  submitForm: () => void;
};

const TextModal = forwardRef<TextModalHandle, Props>(
  (
    {
      show,
      onHide,
      isEditingConfig,
      setIsEditingConfig,
      saveChanges,
      textSystems,
    }: Props,
    ref,
  ) => {
    const { setNotification } = useNotificationStore();
    const [systemType, setSystemType] = useState('OpenAI');
    const { setConfigs, configs } = useAuthStore(state => state);

    const textConfig = configs?.find(config => config.type === 'text');

    const {
      register,
      handleSubmit,
      control,
      watch,
      setValue,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: {
        // name: isEditingConfig?.id ? textConfig.name : "",
        ...openAi,
        ...textConfig?.aiConfig,
        ...groqAi,
        ...geminiAi,
        modelName: 'models/text-bison-001',
        perplexityType: commandTextNodeDefaultValues.perplexityType,
        anthropicModel: commandTextNodeDefaultValues.anthropicModel,
        maxTokens: commandTextNodeDefaultValues.max_tokens,
      },
    });

    useEffect(() => {
      if (isEditingConfig?.edit) {
        const config = configs.find(
          config => config.id === isEditingConfig?.id,
        );
        if (config) {
          setSystemType(config.modelType);
          reset({
            // name: config?.name,
            ...openAi,
            ...textConfig?.aiConfig,
            ...groqAi,
            ...geminiAi,
            modelName: 'models/text-bison-001',
            perplexityType: commandTextNodeDefaultValues.perplexityType,
            anthropicModel: commandTextNodeDefaultValues.anthropicModel,
            maxTokens: commandTextNodeDefaultValues.max_tokens,
          });
        }
      }
    }, [isEditingConfig]);

    const getAiConfigsToBeSaved = (systemType: string, values: ValueTypes) => {
      if (systemType === 'OpenAI') {
        return {
          aiModel: values.aiModel,
          aiTokens: values.aiTokens,
          aiFp: values.aiFp,
          aiPp: values.aiPp,
          autoToken: values.autoToken,
          aiTemperature: values.aiTemperature,
        };
      } else if (systemType === 'Gemini') {
        return {
          geminiModel: values.geminiModel,
          maxOutputTokens: values.maxOutputTokens,
          aiTemperature: values.temperature,
          topP: values.topP,
          topK: values.topK,
        };
      } else if (systemType === 'openSources') {
        if (
          values.openSourceModelName === 'falcon-40b-instruct' ||
          values.openSourceModelName === 'mpt-7b-storywriter'
        ) {
          return {
            openSourceModelName: values.openSourceModelName,
            maxLength: values.maxLength,
            aiTemperature: values.aiTemperature,
            topP: values.topP,
            seed: values.seed,
            repetitionPenalty: values.repetitionPenalty,
            lengthPenalty: values.lengthPenalty,
            noRepeatNgramSize: values.noRepeatNgramSize,
          };
        } else if (values.openSourceModelName === 'mpt-30b-chat') {
          return {
            openSourceModelName: values.openSourceModelName,
            maxNewTokens: values.maxNewTokens,
          };
        } else if (values.openSourceModelName === 'llama-2-70b-chat') {
          return {
            openSourceModelName: values.openSourceModelName,
            maxNewTokens: values.maxNewTokens,
            minNewTokens: values.minNewTokens,
            temperature: values.temperature,
            topP: values.topP,
            topK: values.topK,
            seed: values.seed,
          };
        } else {
          return {
            openSourceModelName: values.openSourceModelName,
          };
        }
      } else if (systemType === 'perplexityAi') {
        return {
          perplexityType: values.perplexityType,
        };
      } else if (systemType === 'anthropicAi') {
        return {
          anthropicModel: values.anthropicModel,
          maxTokens: values.maxTokens,
        };
      } else if (systemType === 'Groq') {
        return {
          maxTokens: values.maxTokens,
          name: values.name,
          seed: values.seed,
          aiTemperature: values.aiTemperature,
          topP: values.topP,
        };
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onFinish),
    }));

    const revertToDefault = () => {
      setSystemType('OpenAI');
      reset({
        ...openAi,
      });
    };

    const onFinish = async (values: ValueTypes) => {
      try {
        if (isEditingConfig?.edit && isEditingConfig?.id) {
          const response = await UserConfigService.updateConfig(
            isEditingConfig.id,
            {
              aiConfig: getAiConfigsToBeSaved(systemType, values),
              name: 'text',
              type: 'text',
              modelType: systemType,
            },
          );
          const newConfigs = configs.map(config => {
            if (config.id === isEditingConfig?.id) {
              return {
                ...config,
                name: 'text',
                aiConfig: response.data.aiConfig,
                type: 'text',
                modelType: systemType,
              };
            }
            return config;
          });
          setConfigs(newConfigs);
          setIsEditingConfig!({
            id: '',
            edit: false,
          });
          return;
        }

        const response = await UserConfigService.createConfig({
          aiConfig: getAiConfigsToBeSaved(systemType, values),
          type: 'text',
          modelType: systemType,
          name: 'text',
        });

        setConfigs([...configs, response.data]);
        setIsEditingConfig!({
          id: '',
          edit: false,
        });
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    const onClose = () => {
      setIsEditingConfig!({
        id: '',
        edit: false,
      });
      onHide();
    };

    const confirmChanges = () => {
      onHide();
      saveChanges();
    };

    return (
      <KitModal
        onHide={() => {
          onClose();
        }}
        show={show}
      >
        <Typography
          fontSize={'20px'}
          fontWeight={500}
          lineHeight={'32px'}
          color={'#000'}
        >
          Text Configurations
        </Typography>
        <form
          action="submit"
          onSubmit={handleSubmit(onFinish)}
          style={{
            paddingTop: '10px',
            minWidth: '500px',
            overflow: 'hidden',
          }}
        >
          {systemType === 'OpenAI' && (
            <OpenAiConfigForm
              systems={textSystems}
              errors={errors}
              register={register}
              systemType={systemType}
              setSystemType={setSystemType}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          )}

          {systemType === 'openSources' && (
            <OpenSourcesConfigForm
              systems={textSystems}
              errors={errors}
              register={register}
              systemType={systemType}
              setSystemType={setSystemType}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          )}

          {systemType === 'perplexityAi' && (
            <PerplexityConfigForm
              systems={textSystems}
              errors={errors}
              register={register}
              systemType={systemType}
              setSystemType={setSystemType}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          )}
          {systemType === 'anthropicAi' && (
            <AnthropicConfigForm
              systems={textSystems}
              errors={errors}
              register={register}
              systemType={systemType}
              setSystemType={setSystemType}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          )}

          {systemType === 'Groq' && (
            <GroqConfigForm
              systems={textSystems}
              errors={errors}
              register={register}
              systemType={systemType}
              setSystemType={setSystemType}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          )}

          {systemType === 'Gemini' && (
            <GeminiConfigForm
              systems={textSystems}
              errors={errors}
              register={register}
              systemType={systemType}
              setSystemType={setSystemType}
              watch={watch}
              control={control}
              setValue={setValue}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '32px',
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: '100%', mt: '16px' }}
              onClick={revertToDefault}
            >
              Revert to default
            </Button>
            <Button
              variant="contained"
              sx={{ width: '100%', mt: '16px' }}
              onClick={confirmChanges}
            >
              Continue
            </Button>
          </Box>
        </form>
      </KitModal>
    );
  },
);

export default TextModal;
