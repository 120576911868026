import { create } from 'zustand';
import { Edge, Node } from 'reactflow';
import { Flow } from '../util/Types/Flow';

export type NodeData = {
  variables: never[];
  collapsed: boolean;
  label: string;
  type: string;
  textInput?: string;
};

export type RFState = {
  nodes: Node<NodeData>[];
  edges: Edge[];
  flows: Flow[];
  schema: {
    [key: string]: {
      type: string;
      required: boolean;
    };
  };
  socketNodeResponse: {
    finished: boolean | null;
    nodeId: string | null;
  } | null;

  flowId: string;
  saving: {
    lastSaved: string;
    status: string;
    finished: boolean;
    flowId: string;
  };
  spaceId: string;
  isPublic: boolean;
  isWebhook: boolean;
  setSocketNodeResponse: (
    nodeId: string | null,
    finished: boolean | null,
  ) => void;

  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;

  setNodeState: React.Dispatch<
    React.SetStateAction<Node<NodeData, string | undefined>[]>
  >;
  setInitialNodesAndEdges: (nodes: Node<NodeData>[], edges: Edge[]) => void;
  setFlows: (flows: Flow[]) => void;
  addFlow: (flow: Flow) => void;
  setStateNodes: (
    setNodes: React.Dispatch<
      React.SetStateAction<Node<NodeData, string | undefined>[]>
    >,
  ) => void;

  setStateHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;

  setEdgeState: (
    setEdges: React.Dispatch<React.SetStateAction<Edge[]>>,
  ) => void;
  setSchema: (schema: {
    [key: string]: {
      type: string;
      required: boolean;
    };
  }) => void;
  setFlowId: (flowId: string) => void;
  setSpaceId: (spaceId: string) => void;
  executeFlow: (func: any) => void;
  setIsPublic: (isPublic: boolean) => void;
  setIsWebhook: (isWebhook: boolean) => void;
  setExecuteFlow: (func: any) => void;
  onEdgeDelete: (edges: Edge[], edgeId?: string) => void;
  setOnEdgeDelete: (onEdgeDelete: () => void) => void;
  setSaving: (saving: {
    lastSaved?: string;
    status: string;
    finished?: boolean;
    flowId?: string;
  }) => void;

  updateFlow: (flow: Flow) => void;

  colorsIndex: any[];

  setColorsIndex: (colorsIndex: any) => void;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useFlowsStore = create<RFState>((set, get) => ({
  nodes: [],
  edges: [],
  flows: [],
  schema: {},
  socketNodeResponse: {
    nodeId: null,
    finished: null,
  },
  flowId: '',
  saving: {
    lastSaved: '',
    status: '',
    finished: true,
    flowId: '',
  },
  isPublic: false,
  isWebhook: false,
  colorsIndex: [],
  spaceId: '',
  setSaving: saving => {
    set({
      saving: {
        lastSaved: saving.lastSaved || '',
        status: saving.status,
        finished: saving.finished || false,
        flowId: saving.flowId || '',
      },
    });
  },
  setIsPublic(isPublic) {
    set({
      isPublic,
    });
  },

  setIsWebhook(isWebhook) {
    set({
      isWebhook,
    });
  },

  setColorsIndex: colorsIndex => {
    set({
      colorsIndex: colorsIndex,
    });
  },
  setSpaceId: (spaceId: string) => {
    set({
      spaceId,
    });
  },

  onEdgeDelete: () => {},
  executeFlow: () => {},
  setExecuteFlow: func => {
    set({
      executeFlow: func,
    });
  },

  setStateHasUnsavedChanges: () => {},
  setNodeState: () => {},
  setEdges: () => {},
  setInitialNodesAndEdges: (nodes: Node<NodeData>[], edges: Edge[]) => {
    set({
      nodes,
      edges,
    });
  },

  addFlow: (newFlow: Flow) => {
    set(state => ({
      flows: [...state.flows, newFlow],
    }));
  },

  updateFlow: (updatedFlow: Flow) => {
    set(state => ({
      flows: state.flows.map(flow =>
        flow.id === updatedFlow.id ? updatedFlow : flow,
      ),
    }));
  },

  setFlowId(flowId: string) {
    set({ flowId });
  },

  setEdgeState(setEdges) {
    set({
      setEdges,
    });
  },

  setOnEdgeDelete(onEdgeDelete) {
    set({
      onEdgeDelete,
    });
  },

  setStateNodes: setNodeState =>
    set({
      setNodeState,
    }),

  setFlows: (flows: Flow[]) => {
    set({
      flows,
    });
  },

  setSocketNodeResponse: (nodeId: string | null, finished: any) => {
    set({
      socketNodeResponse: {
        nodeId,
        finished,
      },
    });
  },

  setSchema: (schema: any) => {
    set({
      schema,
    });
  },
}));

export default useFlowsStore;
