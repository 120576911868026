import { nodeDataAutoSaveDynamic } from './autosave/nodedata_autosave';
import { checkExecuteTrigger } from './checkExecute';

export const executeErrorHandle = (
  executeCounter: number,
  setExecuteCounter: React.Dispatch<React.SetStateAction<number>>,
  setHasToExecute: React.Dispatch<React.SetStateAction<boolean>>,
  data: any,
  edges: any[],
  setNodeState: Function,
  setSaving: Function,
  id: string,
  flowId: string,
  setSchema: Function,
  setNotification: Function,
  error: any,
  objectCallerData: {
    id: string;
    text: {
      text: string;
      id: string;
    }[];
    name: string;
  }[],
) => {
  if (data?.flowTrigger?.id) {
    if (executeCounter < 2) {
      setExecuteCounter(prev => prev + 1);
      setHasToExecute(true);
    } else {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          hasExecutionError: true,
        },
        setSchema,
        objectCallerData,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
    }
  }

  if (error.statusCode === 404) {
    setNotification({
      type: 'error',
      message: 'System called took too long to respond. Try again.',
    });
  }
};
