import React, { ReactElement, useState } from 'react';
import { ITemplate } from './templateSystemData';
import Template from './Template';
import './SeeAllList.css';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right.svg';

interface Props {
  templateCategory: string;
  closeTemplateSystemModal: () => void;
  searchValue: string;
  showRecommended: boolean;
  filteredTemplates: ITemplate[];
  templatesPerPage: number;
  icon: ReactElement;
  seeAllHandler: (category: string) => void;
}

const CarouselTemplateList: React.FC<Props> = ({
  closeTemplateSystemModal,
  filteredTemplates,
  templatesPerPage,
  icon,
  templateCategory,
  seeAllHandler,
}) => {
  const [start, setStart] = useState(0);

  const currentPage = Math.ceil(start / templatesPerPage) + 1;
  const totalPages = Math.floor(filteredTemplates.length / templatesPerPage);

  const goNextHandler = () => {
    if (filteredTemplates.length > templatesPerPage + start + 1) {
      setStart(prev => prev + 2);
    }
  };

  const goPreviousHandler = () => {
    if (currentPage > 1) {
      setStart(prev => prev - 2);
    }
  };
  return (
    <div>
      <div className="template-category-container">
        <div className="template-icon-category-container">
          {icon}
          <h2 className="template-category-text">{templateCategory}</h2>
        </div>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Box
              sx={{
                background:
                  currentPage === 1
                    ? 'rgba(208, 213, 221, 1)'
                    : 'rgba(54, 80, 222, 1)',
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={goPreviousHandler}
            >
              {<LeftIcon />}
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '25.6px',
                letterSpacing: '0.15px',
                textAlign: 'center',
                color: 'rgba(102, 112, 133, 1)',
                width: '44px',
              }}
            >
              {currentPage}/{totalPages}
            </Typography>
            <Box
              sx={{
                background:
                  currentPage === totalPages
                    ? 'rgba(208, 213, 221, 1)'
                    : 'rgba(54, 80, 222, 1)',
                width: '20px',
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
              onClick={goNextHandler}
            >
              {<RightIcon />}
            </Box>
          </Box>

          <Button
            variant="text"
            onClick={() => seeAllHandler(templateCategory)}
          >
            See All
          </Button>
        </Box>
      </div>
      <div className="carousel-templates-list">
        {filteredTemplates
          .slice(start, start + templatesPerPage)
          .map((template, i) => (
            <Template
              key={template.title + i}
              template={template}
              closeTemplateSystemModal={closeTemplateSystemModal}
            />
          ))}
      </div>
    </div>
  );
};

export default CarouselTemplateList;
