import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { fluxBlogs, fluxTutorialsVideos } from './fluxTutorialsData';
import { ReactComponent as FluxTutorialIcon } from '../../assets/icons/FluxTutorials.svg';
import ReactPlayer from 'react-player';
import { ReactComponent as FluxBlogsIcon } from '../../assets/icons/FluxBlogs.svg';

enum FluxTutorialOption {
  Videos = 'Videos',
  Blogs = 'Blogs',
}

const FluxTutorials = () => {
  const [selectedOption, setSelectedOption] = useState<FluxTutorialOption>(
    FluxTutorialOption.Videos,
  );

  const renderVideos = () => {
    return fluxTutorialsVideos.map(({ videoUrl, title, playlistUrl }, i) => (
      <Box
        sx={{
          background: 'white',
          border: '1px solid rgba(234, 236, 240, 1)',
          borderRadius: '8px',
          padding: '10px',
          width: '400px',
          height: '250px',
        }}
        key={videoUrl + i}
      >
        <ReactPlayer
          url={videoUrl}
          controls
          playing={false}
          width={400}
          height={200}
        />
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontWeight: 500,
            padding: '5px 0',
            marginTop: '5px',
            fontSize: '14px',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, .7)',
            fontSize: '14px',
            fontWeight: 400,
          }}
        >
          Learn more:{' '}
          <a href={playlistUrl} target="_blank">
            view full playlist
          </a>
        </Typography>
      </Box>
    ));
  };

  const renderBlogs = () => {
    return fluxBlogs.map(
      ({ title, link, previewUrl, description, author }, i) => (
        <Box
          key={title + i}
          sx={{
            background: 'white',
            border: '1px solid rgba(234, 236, 240, 1)',
            borderRadius: '8px',
            width: '400px',
            height: '260px',
            transition: 'all .3s ease',

            '&:hover': {
              boxShadow: `2px 4px 35px 0px rgba(0, 0, 0, 0.25)`,
              transform: `translateY(-10px)`,
            },
          }}
        >
          <a target="_blank" style={{ textDecoration: 'none' }} href={link}>
            <Box
              sx={{
                background: '#f9fafb',
                width: '80%',
                height: '60px',
                textAlign: 'center',
                margin: '10px auto',
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={previewUrl}
                alt="Preview"
                style={{ height: 100, width: '400px', objectFit: 'contain' }}
              />
            </Box>
            <Box sx={{ padding: '10px' }}>
              <Typography
                sx={{
                  color: 'rgba(33, 150, 243, 1)',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Author: {author}
              </Typography>

              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontWeight: 500,
                  fontSize: '20px',
                  margin: '15px 0',
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  overflowY: 'auto',
                  height: '100px',
                  fontSize: '14px',
                  letterSpacing: '0.15px',
                  lineHeight: '18px',
                }}
              >
                {description}{' '}
                {i !== fluxBlogs.length - 1 && (
                  <a
                    target="_blank"
                    style={{
                      textDecoration: 'none',
                      color: 'rgb(54, 80, 222)',
                    }}
                    href={link}
                  >
                    Read More
                  </a>
                )}
              </Typography>
            </Box>
          </a>
        </Box>
      ),
    );
  };

  return (
    <Box
      sx={{
        background: 'rgba(249, 250, 251, 1)',
        border: '1px solid rgba(234, 236, 240, 1)',
        padding: '20px',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgba(234, 236, 240, 1)',
          marginBottom: '1px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {selectedOption === FluxTutorialOption.Videos ? (
            <FluxTutorialIcon />
          ) : (
            <FluxBlogsIcon />
          )}

          <Typography
            sx={{
              fontWeight: 500,
              color: 'rgba(71, 84, 103, 1)',
              margin: 0,
            }}
          >
            {selectedOption === FluxTutorialOption.Videos
              ? 'Flux Tutorials'
              : 'Flux Blogs'}
          </Typography>
        </Box>
        <Box
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(71, 84, 103, 1)',
              fontSize: 14,
              cursor: 'pointer',
              fontWeight:
                selectedOption === FluxTutorialOption.Videos ? '600' : '400',
            }}
            onClick={() => setSelectedOption(FluxTutorialOption.Videos)}
          >
            Videos
          </Typography>
          <Typography
            sx={{
              color: 'rgba(71, 84, 103, 1)',
              fontSize: 14,
              cursor: 'pointer',
              fontWeight:
                selectedOption === FluxTutorialOption.Blogs ? '600' : '400',
            }}
            onClick={() => setSelectedOption(FluxTutorialOption.Blogs)}
          >
            Blogs
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          overflowX: 'auto',
          overflowY: 'hidden',
          width: '100%',
          padding: '10px 0',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
        }}
      >
        {selectedOption === FluxTutorialOption.Videos
          ? renderVideos()
          : renderBlogs()}
      </Box>
    </Box>
  );
};

export default FluxTutorials;
