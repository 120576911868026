import React, { MouseEvent, useEffect, useState } from 'react';
import useFlowsStore from '../../../store/storeFlows';
import { Box, TextField, Typography } from '@mui/material';
import { FlowService } from '../../../service/FlowService';
import { Pen, TextT, XCircle } from '@phosphor-icons/react';
import { useEdges, useReactFlow } from 'reactflow';
import { nodeDataAutoSaveDynamic } from '../../../util/autosave/nodedata_autosave';
import { useAuthStore } from '../../../store/storeAuth';

type Props = {
  data: {
    label: string;
    myId: string;
  };
  icon?: any;
};
const ChangeLabel = ({ data, icon }: Props) => {
  const [isInput, setIsInput] = useState(false);
  const [changeLabelValue, SetChangeLabelValue] = useState(data.label);
  const reactFlow = useReactFlow();
  const { user } = useAuthStore(state => state);
  const edges = useEdges();

  const { setNodeState, flowId, setSaving, setSchema } = useFlowsStore(
    state => state,
  );

  const ref = React.useRef<HTMLInputElement>(null);
  const onSave = () => {
    setIsInput(false);

    const nodes = reactFlow.getNodes();

    const hasNodeWithSameLabel = nodes?.some(
      nd => nd.data.label === changeLabelValue,
    );

    // If any node has the same label, show an alert
    if (hasNodeWithSameLabel) {
      alert('Another node already has this label');
      SetChangeLabelValue(data.label);
    } else {
      nodeDataAutoSaveDynamic({
        setNodeState: setNodeState,
        newEdges: edges,
        setSaving,
        setSchema: setSchema,
        id: data.myId,
        flowId,
        changeType: 'changeLabel',
        objectData: {
          label: changeLabelValue,
        },
      });
    }
  };

  const handleClickLabel = (event: MouseEvent<HTMLElement>) => {
    if (event.detail === 2) {
      setIsInput(true);
    }
  };

  useEffect(() => {
    if (isInput) {
      ref.current?.focus();
    }
  }, [isInput]);

  return (
    <Box className="d-flex">
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {icon ? (
          <Box
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#344054' : '#D0D5DD'
            }`}
            paddingRight={'8px'}
          >
            {icon}
          </Box>
        ) : (
          <TextT size={32} color={'#98A2B3'} />
        )}
        {isInput ? (
          <TextField
            type="text"
            ref={ref}
            name="label"
            size="small"
            inputProps={{ style: { fontSize: '14px' } }}
            onChange={e => {
              SetChangeLabelValue(e.target.value);
            }}
            onBlur={onSave} // Save when the input loses focus
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onSave();
              }
              if (e.key === 'Escape') {
                setIsInput(false);
                SetChangeLabelValue(data?.label);
              }
            }}
            value={changeLabelValue}
            InputProps={{
              style: {
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              },
            }}
            sx={{
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              border: `2px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`,
            }}
          />
        ) : (
          <Typography
            fontSize={'14px'}
            onClick={handleClickLabel}
            sx={{ cursor: 'text' }}
            color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
          >
            {data?.label ? data?.label : 'Untitled'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ChangeLabel;
