import { Box, SxProps, Theme, Typography } from '@mui/material';
import { CaretDown, TextAlignLeft } from '@phosphor-icons/react';
import React from 'react';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';
import InputHandle from '../InputHandle/InputHandle';
import { useAuthStore } from '../../../store/storeAuth';

type LabelProps = {
  labelName?: string;
  findColor?: { color: string };
  onCollapse?: () => void;
  collapsed?: boolean;
  isAdvanced?: boolean;
  setOnPreviewModal?: any;
  handleId?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  labelStyle?: SxProps<Theme>;
};

const Label = ({
  labelName,
  isAdvanced = false,
  onCollapse,
  collapsed,
  setOnPreviewModal,
  findColor,
  handleId,
  disabled,
  sx,
  labelStyle,
}: LabelProps) => {
  const { user } = useAuthStore(state => state);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        position: 'relative',
        '& svg': { cursor: 'pointer' },
        mb: '8px',
        ...sx,
      }}
    >
      {handleId && (
        <InputHandle
          activeColor={findColor?.color}
          handleId={handleId}
          isActive={disabled}
          type='target'
          left={-26}
          top={'10px'}
        />
      )}
      {!isAdvanced && (
        <CaretDown
          onClick={onCollapse}
          size={16}
          color={!collapsed ? '#3650DE' : '#667085'}
        />
      )}
      <Typography
        sx={{
          ...labelStyle,
        }}
        fontSize={'16px'}
        color= {user?.theme === 'dark' ? '#98A2B3' : '#101828'}
        fontWeight={600}
      >
        {labelName}
      </Typography>
      {setOnPreviewModal && (
        <IconButtonTooltip
          onClick={() => setOnPreviewModal(true)}
          title='Preview'
        >
          <TextAlignLeft size={16} color='#98A2B3' />{' '}
        </IconButtonTooltip>
      )}
    </Box>
  );
};

export default Label;
