import KitModal from '../../UI/KitModal';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { AiMusicGeneratorFormValues, formFields } from '../constants';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: AiMusicGeneratorFormValues;
  onChange: (e: any) => void;
};

const CustomFormControl = ({
  field,
  value,
  onChange,
}: {
  field:
    | {
        label: string;
        inputType: string;
        id: string;
        name: string;
        options: string[];
        type?: undefined;
      }
    | {
        label: string;
        inputType: string;
        id: string;
        name: string;
        type: string;
        options?: undefined;
      };
  value: string | number;
  onChange: (e: any) => void;
}) => {
  const { label, inputType, name, type, options } = field;

  return (
    <FormControl>
      {inputType === 'Select' && (
        <InputLabel htmlFor={name}>{label}</InputLabel>
      )}
      {inputType === 'Select' ? (
        <Select
          label={label}
          className='nodrag'
          size='medium'
          value={value}
          onChange={onChange}
          name={name}
        >
          {options?.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          label={label}
          value={value}
          onChange={onChange}
          name={name}
          size='medium'
          type={type}
        />
      )}
    </FormControl>
  );
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '680px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      showCloseButton={false}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant='h6'> Settings</Typography>

        {formFields.map((field, index) => (
          <Box key={index} sx={{ mb: '24px', mt: '24px' }}>
            <CustomFormControl
              onChange={onChange}
              value={formData[field.name as keyof AiMusicGeneratorFormValues]}
              field={field}
            />
          </Box>
        ))}
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
