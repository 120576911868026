import React, { memo, useEffect, useState } from 'react';
import FormNode from '../Components/FormNode/FormNode';
import { NodeProps, useReactFlow } from 'reactflow';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { TextAlignLeft, Warning } from '@phosphor-icons/react';
import { useDebounceEffect } from '../../../util/useDebounceEffect';
import PopoverEdit from '../TitleNode/Components/PopoverEdit';
import FormBuildInput from '../FormBuildInput/FormBuildInput';

const OutputNodeDisplay = ({ id, type, data }: NodeProps) => {
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    url: '',
    selectedFlow: '',
    borderRadius: '4px',
    titleStyle: {
      fontSize: '20px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
    subtitleStyle: {
      fontSize: '14px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      textDecoration: 'none',
      selStyle: [],
    },
  });
  const reactFlow = useReactFlow();

  const [changes, setChanges] = useState<any>({
    directly: false,
    value: '',
    name: '',
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingType, setEditingType] = useState('titleStyle');

  useEffect(() => {
    if (data && !changes?.directly) {
      const selectedFlow =
        data?.selectedFlow && data?.varOutputNodes?.length > 0
          ? data?.selectedFlow
          : !data?.selectedFlow && data?.varOutputNodes?.length > 0
          ? 'none'
          : 'none';

      setFormData({
        title: data.title || '',
        subtitle: data.subtitle || '',
        borderRadius: data.borderRadius || '4px',
        url: data.url || '',
        selectedFlow: selectedFlow,
        titleStyle: {
          ...formData?.titleStyle,
          ...data.titleStyle,
        },
        subtitleStyle: {
          ...formData?.subtitleStyle,
          ...data.subtitleStyle,
        },
      });
    }
  }, [data, changes]);
  const onChanges = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setChanges({ directly: true, value, name });
  };

  const handleClick = (type: string) => {
    setOpenEditModal(true);
    setEditingType(type);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };
  useDebounceEffect(
    () => {
      if (changes.directly) {
        const nodes = reactFlow.getNodes();

        const mappedNodes = nodes.map(node => {
          if (node.id === id) {
            if (changes?.name === 'widthData') {
              return {
                ...node,
                width: changes.value?.width,
                style: {
                  width: changes.value?.width,
                },
                data: {
                  ...node.data,
                  [changes.name]: changes.value,
                },
              };
            }

            return {
              ...node,
              data: {
                ...node.data,
                [changes.name]: changes.value,
              },
            };
          }
          return node;
        });

        reactFlow.setNodes(mappedNodes);

        setChanges({ directly: false, value: '', name: '' });
      }
    },
    [changes],
    500,
  );

  return (
    <FormNode id={id} type={type} borderRadius={formData.borderRadius}>
      <FormBuildInput
        data={data}
        formData={{
          name: 'title',
          value: formData.title,
          placeholder: 'Add title here...',
          style: formData.titleStyle,
          titleEmpty: 'Title',
        }}
        editingType={editingType}
        editType={'titleStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      <FormBuildInput
        data={data}
        formData={{
          name: 'subtitle',
          value: formData.subtitle,
          placeholder: 'Add subtitle here...',
          style: formData.subtitleStyle,
          titleEmpty: 'Subtitle (Optional)',
        }}
        editingType={editingType}
        editType={'subtitleStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      {openEditModal && (
        <PopoverEdit
          open={openEditModal}
          handleClose={handleClose}
          onChange={onChanges}
          selectedStyle={formData?.[editingType as keyof typeof formData]}
          editingType={editingType}
          modalStyle={{
            bgColor: data?.bgColor ? data?.bgColor : '#ffffff',
            widthData: data?.widthData,
            borderRadius: formData.borderRadius || '4px',
          }}
        />
      )}
      <Box
        sx={{
          border: `1px dashed var(--Neutral-300, #D0D5DD)`,
          borderRadius: '8px',
          padding: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '16px',
          mb: '24px',
        }}
      >
        <Box textAlign={'center'}>
          <TextAlignLeft size={24} color="#667085" />
          <Typography fontSize={'12px'} color={'#475467'}>
            Your output will be displayed in text format here once you submit
            your form. To test this, enter preview mode or publish your form.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Select
          name="selectedFlow"
          value={formData.selectedFlow || 'none'}
          onChange={onChanges}
          className="nodrag"
          sx={{
            border: '1px solid #3650DE',
            color: '#3650DE',
            maxWidth: '148px',
            width: '100%',
            fontSize: '12px',
          }}
        >
          <MenuItem value={'none'} disabled>
            Choose Input Node
          </MenuItem>
          {data?.varOutputNodes?.map((node: any, index: number) => (
            <MenuItem
              key={index}
              value={node?.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#475467',
                  fontWeight: 400,
                }}
              >
                ({node?.flowName})
              </Typography>{' '}
              {node.label}
            </MenuItem>
          ))}
        </Select>

        {(data?.varOutputNodes?.length === 0 ||
          formData?.selectedFlow === 'none') && (
          <Box display={'flex'} alignItems={'center'} gap="8px">
            <Warning size={16} color="#F57F17" />
            <Typography
              variant="body2"
              sx={{
                color: '#475467',

                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              No input{' '}
              {formData?.selectedFlow === 'none' &&
              data?.varInputNodes?.length !== 0
                ? 'selected'
                : 'connected'}
            </Typography>
          </Box>
        )}
      </Box>
    </FormNode>
  );
};

export default memo(OutputNodeDisplay);
