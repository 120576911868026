import React, { memo, useEffect, useState } from 'react';
import { NodeProps } from '../../../util/Types/NodeProps';
import { useReactFlow } from 'reactflow';
import { useDebounceEffect } from '../../../util/useDebounceEffect';
import FormNode from '../Components/FormNode/FormNode';
import FormBuildInput from '../FormBuildInput/FormBuildInput';
import {
  Box,
  Checkbox,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PopoverEdit from '../TitleNode/Components/PopoverEdit';
import {
  CaretCircleDown,
  CaretDown,
  CheckCircle,
  RadioButton,
  Warning,
  X,
} from '@phosphor-icons/react';

const List = ({ id, type, data, selected }: NodeProps) => {
  const [formData, setFormData] = useState({
    title: '',
    type: 'dropdown',
    borderRadius: '4px',
    titleStyle: {
      fontSize: '20px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
    question: '',
    selectedFlow: '',

    options: [
      {
        id: Math.random().toString(36).substr(2, 9),
        value: '',
      },
      {
        id: Math.random().toString(36).substr(2, 9),
        value: '',
        type: 'add',
      },
    ],

    questionStyle: {
      fontSize: '14px',
      color: '#475467',
      textAlign: 'left',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 400,
      textDecoration: 'none',
      selStyle: [],
    },
  });
  const reactFlow = useReactFlow();

  const [isEditing, setIsEditing] = useState(false);
  const [editingType, setEditingType] = useState('');

  const [changes, setChanges] = useState<any>({
    directly: false,
    value: '',
    name: '',
  });

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleClick = (type: string) => {
    setOpenEditModal(true);
    setEditingType(type);
  };

  const nodes = reactFlow.getNodes()?.map(node => {
    if (
      (node?.type === 'inputDisplay' || node?.type === 'listNode') &&
      node?.id !== id
    ) {
      return {
        id: node?.id,
        label: node?.data?.label + node?.data?.flowName,
        selectedFlow: node?.data?.selectedFlow,
      };
    }
  });

  const handleClose = () => {
    setOpenEditModal(false);
  };
  useEffect(() => {
    if (data && !changes?.directly) {
      const selectedFlow =
        data?.selectedFlow && data?.varInputNodes?.length > 0
          ? data?.selectedFlow
          : !data?.selectedFlow && data?.varInputNodes?.length > 0
          ? 'none'
          : 'none';
      setFormData({
        title: data.title || '',
        type: data.type || 'dropdown',
        borderRadius: data.borderRadius || '4px',
        question: data.question || '',
        options: data.options || formData.options,
        questionStyle: {
          ...formData?.questionStyle,
          ...data?.questionStyle,
        },
        selectedFlow: selectedFlow,
        titleStyle: {
          ...formData?.titleStyle,
          ...data?.titleStyle,
        },
      });
    }
  }, [data, changes]);

  const onChanges = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setChanges({ directly: true, value, name });
  };

  useDebounceEffect(
    () => {
      if (changes.directly) {
        const nodes = reactFlow.getNodes();

        const mappedNodes = nodes.map(node => {
          if (node.id === id) {
            if (changes?.name === 'widthData') {
              return {
                ...node,
                width: changes.value?.width,
                style: {
                  width: changes.value?.width,
                },
                data: {
                  ...node.data,
                  [changes.name]: changes.value,
                },
              };
            }

            return {
              ...node,
              data: {
                ...node.data,
                [changes.name]: changes.value,
              },
            };
          }
          return node;
        });

        reactFlow.setNodes(mappedNodes);

        setChanges({ directly: false, value: '', name: '' });
      }
    },
    [changes],
    500,
  );

  const onAddOption = (optId: string) => {
    // remove add type from last option

    const newOptions = [...formData.options];
    newOptions.pop();
    newOptions.push({
      id: Math.random().toString(36).substr(2, 9),
      value: '',
    });
    newOptions.push({
      id: Math.random().toString(36).substr(2, 9),
      value: '',
      type: 'add',
    });
    setFormData({ ...formData, options: newOptions });

    onChanges({
      target: {
        name: 'options',
        value: newOptions,
      },
    });
  };

  const onChangeOption = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: string,
  ) => {
    const newOptions = formData.options.map(option => {
      if (option.id === index) {
        return { ...option, value: e.target.value };
      }
      return option;
    });

    setFormData({ ...formData, options: newOptions });

    setChanges({ directly: true, value: newOptions, name: 'options' });
  };

  const onDeleteOption = (id: string) => {
    const newOptions = formData.options.filter(option => option.id !== id);
    setFormData({ ...formData, options: newOptions });

    onChanges({
      target: {
        name: 'options',
        value: newOptions,
      },
    });
  };

  return (
    <FormNode id={id} type={type!} borderRadius={formData.borderRadius}>
      <Box>
        <FormBuildInput
          data={data}
          formData={{
            name: 'title',
            value: formData.title,
            placeholder: 'Add Name here...',
            style: formData.titleStyle,
            titleEmpty: 'Name',
          }}
          editingType={editingType}
          editType={'titleStyle'}
          handleClick={handleClick}
          isEditing={isEditing}
          onChanges={onChanges}
          setEditingType={setEditingType}
          setIsEditing={setIsEditing}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            mb: '8px',
          }}
        >
          <Box
            sx={{
              maxWidth: data?.widthData?.width > 749 ? '60%' : '100%',
              width: '100%',
            }}
          >
            <FormBuildInput
              data={data}
              formData={{
                name: 'question',
                value: formData.question,
                placeholder: 'Add question here...',
                style: formData.questionStyle,
                titleEmpty: 'Add question here',
              }}
              editingType={editingType}
              editType={'questionStyle'}
              handleClick={handleClick}
              isEditing={isEditing}
              onChanges={onChanges}
              setEditingType={setEditingType}
              setIsEditing={setIsEditing}
            />
          </Box>
          <Select
            onChange={onChanges}
            className="nodrag"
            value={formData.type}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            name="type"
            fullWidth
            IconComponent={() => (
              <Box mt={'2px'} mr="8px">
                <CaretDown size={20} />
              </Box>
            )}
            sx={{
              color: '#475467',
              bgcolor: '#fff',
              maxWidth: '200px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <MenuItem
              value="dropdown"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  fontSize: '12px',
                }}
              >
                <CaretCircleDown size={20} />
                <Typography lineHeight={0} mt={'2px'} fontSize={'12px'}>
                  Dropdown
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem sx={{}} value="radio">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <RadioButton size={20} />
                <Typography lineHeight={0} mt={'2px'} fontSize={'12px'}>
                  Single Choice
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value="multipleChoice">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <CheckCircle size={20} />
                <Typography lineHeight={0} mt={'2px'} fontSize={'12px'}>
                  Multiple Choice
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </Box>

        <Box>
          {formData.options.map((option, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  opacity: option.type === 'add' ? 0.5 : 1,
                  mb: '8px',
                }}
              >
                <TextField
                  placeholder={
                    option?.type === 'add'
                      ? 'Add Option ' + (index + 1)
                      : '' + ' Option ' + (index + 1)
                  }
                  size="medium"
                  variant="standard"
                  className="nodrag"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formData?.type === 'dropdown' && (
                          <Typography>{index + 1}.</Typography>
                        )}

                        {formData?.type === 'multipleChoice' && (
                          <Checkbox disabled size="small" />
                        )}

                        {formData?.type === 'radio' && (
                          <Radio disabled size={'small'} />
                        )}
                      </InputAdornment>
                    ),

                    endAdornment:
                      option.type === 'add' ? null : (
                        <InputAdornment
                          onClick={() => onDeleteOption(option.id)}
                          position="end"
                          sx={{
                            cursor: 'pointer',
                          }}
                        >
                          <X size={24} />
                        </InputAdornment>
                      ),
                  }}
                  onClick={() => {
                    if (option.type === 'add') {
                      onAddOption(option.id);
                    }
                  }}
                  value={option.value}
                  onChange={e => onChangeOption(e, option.id)}
                />
              </Box>
            );
          })}
        </Box>

        {openEditModal && (
          <PopoverEdit
            open={openEditModal}
            handleClose={handleClose}
            onChange={onChanges}
            selectedStyle={formData.titleStyle}
            editingType={editingType}
            modalStyle={{
              bgColor: data?.bgColor ? data?.bgColor : '#ffffff',
              widthData: data?.widthData,
              borderRadius: formData.borderRadius || '4px',
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Select
          name="selectedFlow"
          value={formData.selectedFlow || 'none'}
          onChange={onChanges}
          className="nodrag"
          sx={{
            border: '1px solid #3650DE',
            color: '#3650DE',
            maxWidth: '148px',
            width: '100%',
            fontSize: '12px',
          }}
        >
          <MenuItem value={'none'} disabled>
            Choose Input Node
          </MenuItem>
          {data?.varInputNodes?.map((node: any, index: number) => (
            <MenuItem
              key={index}
              disabled={nodes?.some(
                (n: any) => n?.selectedFlow === node?.id && n?.id !== id,
              )}
              value={node?.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#475467',
                  fontWeight: 400,
                }}
              >
                ({node?.flowName})
              </Typography>{' '}
              {node.label}
            </MenuItem>
          ))}
        </Select>

        {(data?.varInputNodes?.length === 0 ||
          formData?.selectedFlow === 'none') && (
          <Box display={'flex'} alignItems={'center'} gap="8px">
            <Warning size={16} color="#F57F17" />
            <Typography
              variant="body2"
              sx={{
                color: '#475467',

                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              No input
              {formData?.selectedFlow === 'none' &&
              data?.varInputNodes?.length !== 0
                ? 'selected'
                : 'connected'}
            </Typography>
          </Box>
        )}
      </Box>
    </FormNode>
  );
};

export default memo(List);
