import { Box, Button, TextField, Typography } from '@mui/material';
import { BoundingBox, Pen, Scroll } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import KitModal from '../UI/KitModal';
import LoadingSpinner from '../UI/LoadingSpinner';

type Props = {
  onHide: () => void;
  show: boolean;
  flow: any;
  isCreateFormLoading: boolean;
  onCreateForm: (name: string) => void;
};

const CreateFormModal = ({
  onHide,
  show,
  flow,
  isCreateFormLoading,
  onCreateForm,
}: Props) => {
  const [name, setName] = useState('');

  return (
    <KitModal
      onHide={onHide}
      show={show}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '520px',
          borderRadius: '8px',
        },
        '& .MuiDialogContent-root': {
          padding: '32px',
        },
        '& .MuiDialogActions-root': {
          padding: '32px',
        },
      }}
      showCloseButton={false}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        gap='1rem'
      >
        <Box display='flex' gap='16px' alignItems='center'>
          <Box
            p={'2px'}
            bgcolor={'#E7F0FF'}
            display={'flex'}
            alignContent={'center'}
            justifyContent={'center'}
          >
            <Scroll color='#3650DE' size={32} />
          </Box>
          <Typography fontSize={'32px'} color={'#101828'}>
            Create your form
          </Typography>
        </Box>
        <Typography
          mb={'32px'}
          sx={{
            color: '#475467',
            fontSize: '16px',
            fontWeight: 400,
          }}
        >
          You're crafting a form based on the{' '}
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {'"'}
            {flow?.name || 'Untitled'}
            {'" '}
          </span>
          existing flow.
        </Typography>
        <TextField
          id='outlined-basic'
          label='Enter Form Name'
          variant='outlined'
          placeholder="Form's name"
          type='text'
          name='name'
          value={name}
          onChange={e => setName(e.target.value)}
          fullWidth
        />

        <Button
          disabled={!name || isCreateFormLoading}
          fullWidth
          onClick={() => {
            setName('');
            onCreateForm(name);
          }}
          variant={`contained`}
          size='small'
        >
          {isCreateFormLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <></>
          )}
          Continue
        </Button>
      </Box>
    </KitModal>
  );
};

export default CreateFormModal;
