import React from 'react';
import './Navigation.css';
import { templateCategories } from './templateSystemData';
import { ReactComponent as TemplatesIcon } from '../../assets/icons/Templates.svg';
import { Box, Typography } from '@mui/material';
import { ReactComponent as RolesIcon } from '../../assets/icons/Roles.svg';

interface Props {
  selectedCategory: undefined | string;
  setSelectedCategory: (category: undefined | string) => void;
  setShowAll: (show: boolean) => void;
  showAll: boolean;
  showRecommended: boolean;
  setShowRecommended: (show: boolean) => void;
}

const Navigation: React.FC<Props> = ({
  selectedCategory,
  setSelectedCategory,
  setShowAll,
  showAll,
  showRecommended,
  setShowRecommended,
}) => {
  const categoryChangeHandler = (category: string) => {
    setSelectedCategory(category);
    setShowAll(false);
    setShowRecommended(false);
  };

  const showAllHandler = () => {
    setShowAll(true);
    setShowRecommended(false);
    setSelectedCategory(undefined);
  };

  const showRecommendedHandler = () => {
    setShowAll(false);
    setShowRecommended(true);
    setSelectedCategory(undefined);
  };

  return (
    <div className="navigation">
      <div className="templates-icon-text-container">
        <TemplatesIcon />
        <p className="templates">Templates</p>
      </div>
      <p
        onClick={showAllHandler}
        className={`all ${showAll ? 'all-selected' : undefined}`}
      >
        All
      </p>
      <p
        onClick={showRecommendedHandler}
        className={`recommended ${
          showRecommended ? 'recommended-selected' : undefined
        }`}
      >
        Recommended
      </p>

      <Box
        sx={{
          paddingLeft: '30px',
          display: 'flex',
          alignItems: 'center',
          margin: '20px 0',
          marginBottom: '0px',
          gap: '4px',
        }}
      >
        <RolesIcon />
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '20px',
            letterSpacing: '0.4px',
            color: 'rgba(71, 84, 103, 1)',
          }}
        >
          Roles
        </Typography>
      </Box>

      <div className="categories-container">
        {templateCategories.map(category => {
          const className =
            selectedCategory === category
              ? 'category selected-category'
              : 'category';
          return (
            <p
              key={category}
              className={className}
              onClick={() => categoryChangeHandler(category)}
            >
              {category}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;
