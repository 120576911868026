export const aiImageReaderBlipInputs = [
  {
    name: 'prompt',
    labelName: 'Prompt',
    placeholder: 'What should I tell you about this image?',
    handleId: 'imageGenPrompt',
    isCollapsed: true,
  },
  {
    name: 'question',
    labelName: 'Question',
    placeholder: '', // Add placeholder if needed
    handleId: 'imageGenQuestion',
    isCollapsed: true,
  },
  {
    name: 'caption',
    labelName: 'Caption',
    placeholder: 'What can I help you with today?',
    handleId: 'imageGenCaption',
    isCollapsed: false,
  },

  {
    name: 'content',
    labelName: 'Content',
    placeholder: '', // Add placeholder if needed
    handleId: 'imageGenContent',
    isCollapsed: false,
  },
];
