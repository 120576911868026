import {
  Avatar,
  Box,
  Button,
  Grid,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FluxTokenLimitModal from './modals/FluxTokenLimitModal';
import DisableUserModal from './modals/DisableUserModal';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { User } from '../../util/Types/User';
import { CompanyService } from '../../service/CompanyService';
import { spec } from 'node:test/reporters';
import { formatActualDate } from '../../util/dateUtil';
import { useNotificationStore } from '../../store/storeNotifications';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const boxStyles = {
  display: 'flex',
  padding: '12px',
  width: '80%',
  height: '60px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  borderRadius: '8px',
  color: '#fff',
};

type Props = {
  user: User;
  companyId: string;
  navigate: (view: string) => void;
  onUserDisabled: () => void;
};
type SpecificDetails = {
  userData: Array<{
    label: string;
    nodeRuns: number;
    tokenUsed: number;
  }>;
  flowData: Array<{
    creator: string;
    name: string;
    nodeRuns: number;
    spaceName: string;
    tokenUsed: number;
    updatedAt: string;
  }>;
};

const UserProfile = ({ companyId, user, navigate, onUserDisabled }: Props) => {
  const [openFluxTokeLimitModal, setOpenFluxTokeLimitModal] = useState(false);

  const [openDisableModal, setOpenDisableModal] = useState(false);

  const handleCloseDisableModal = () => {
    setOpenDisableModal(false);
  };

  const [company, setCompany] = useState<any>({});
  const [limit, setLimit] = useState<number>(0);
  const setNotification = useNotificationStore(state => state.setNotification);
  const [isLoading, setIsLoading] = useState(false);
  const [specificDetails, setSpecificDetails] =
    useState<SpecificDetails | null>(null);

  useEffect(() => {
    const getUserDetailsInsideCompany = async () => {
      try {
        if (companyId && user?.id) {
          const res = await CompanyService.getUserDetailsInsideCompany({
            userId: user.id,
            companyId,
          });
          setSpecificDetails(res.data);
        }
      } catch (error) {
        console.log('Error fetching user details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getUserDetailsInsideCompany();
  }, [companyId, user]);

  const columns: GridColDef[] = [
    {
      field: 'flowName',
      headerName: 'Flow Name',
      flex: 1,
    },
    {
      field: 'workspaceName',
      headerName: 'Workspace Name',
      flex: 1,
    },
    {
      field: 'lastUsed',
      headerName: 'Last Used',
      // type: 'dateTime', !
      flex: 1,
    },
    {
      field: 'flowCreator',
      headerName: 'Flow Creator',
      flex: 1,
    },
    {
      field: 'nodeRuns',
      headerName: 'Node Runs',
      flex: 1,
    },
    {
      field: 'tokensUsed',
      headerName: 'Tokens Used',
      flex: 1,
    },
  ];

  const rows =
    specificDetails?.flowData?.map((flow: any, i: number) => ({
      id: flow.id || i + 1,
      flowName: flow.name,
      workspaceName: flow.status,
      lastUsed: formatActualDate(flow.updatedAt),
      flowCreator: flow.creator,
      nodeRuns: flow.nodeRuns,
      tokensUsed: flow.tokenUsed,
    })) || [];

  const rowHeight = 52;
  const headerHeight = 56;
  const maxHeight = 400;

  const gridHeight = Math.min(
    headerHeight + rows.length * rowHeight,
    maxHeight,
  );

  const getUserDataValue = (label: string) => {
    const data = specificDetails?.userData?.find(item => item.label === label);
    return data ? data.tokenUsed : 0;
  };

  const totalValue =
    getUserDataValue('Text') +
    getUserDataValue('Image') +
    getUserDataValue('Audio') +
    getUserDataValue('api_calls');

  const getNodeRunsByLabel = (label: string) => {
    return (
      specificDetails?.userData?.reduce((total, item) => {
        return item.label === label ? total + (item.nodeRuns || 0) : total;
      }, 0) || 0
    );
  };

  const totalNodeRunsText = getNodeRunsByLabel('Text');
  const totalNodeRunsAudio = getNodeRunsByLabel('Audio');
  const totalNodeRunsApiCalls = getNodeRunsByLabel('api_calls');
  const totalNodeRunsImages = getNodeRunsByLabel('Images');
  const totalNodeRuns =
    totalNodeRunsText +
    totalNodeRunsAudio +
    totalNodeRunsImages +
    totalNodeRunsApiCalls;

  const dataBox = [
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'AI FluxTokens',
      value: totalValue,
    },
    {
      background:
        'linear-gradient(53deg, #FD746C 16.71%, #FE846A 48.74%, #FF9068 80.78%)',
      label: 'API Calls',
      value: getUserDataValue('api_calls'),
    },
    {
      background: 'linear-gradient(90deg, #142BFF 0%, #396AFC 100%)',
      label: 'Text FluxTokens',
      value: getUserDataValue('Text'),
    },
    {
      background: 'linear-gradient(59deg, #683BB8 7.2%, #A969FD 92.35%)',
      label: 'Image FluxTokens',
      value: getUserDataValue('Image'),
    },
    {
      background: 'linear-gradient(63deg, #73E49A 14.58%, #3FC36D 82.62%)',
      label: 'Audio FluxTokens',
      value: getUserDataValue('Audio'),
    },
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Flows Created',
      value: rows.length,
    },
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Node Runs',
      value: totalNodeRuns,
    },
  ];

  const handleDisable = async () => {
    try {
      const res = await CompanyService.updateCompanyEnableDisable({
        companyId,
        userId: user.id,
        status: 'inactive',
      });
      setCompany(res.data);
      setNotification({
        type: 'success',
        message: 'User disabled successfully',
      });
      onUserDisabled();
      navigate('UserOverview');
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDisableModal(false);
    }
  };

  const handleEnable = async () => {
    try {
      const res = await CompanyService.updateCompanyEnableDisable({
        companyId,
        userId: user.id,
        status: 'active',
      });
      setCompany(res.data);
      setNotification({
        type: 'success',
        message: 'User enabled successfully',
      });
      onUserDisabled();
      navigate('UserOverview');
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDisableModal(false);
    }
  };

  return (
    <Box
      sx={{
        padding: '24px 24px 16px 24px',
        gap: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '16px',
        }}
      >
        {/* User Top Details  */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Avatar
            {...stringAvatar('Bruno Skendaj')}
            sx={{
              backgroundColor: '#2C4DFF',
              p: '18px',
              borderRadius: '4px',
            }}
          />

          <Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Typography
                sx={{
                  color: '#475467',
                  fontFamily: 'Roboto',
                  fontSize: '22px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '133.4%',
                }}
              >
                {user.name}
              </Typography>

              {user.status === 'inactive' && (
                <>
                  <Typography>•</Typography>
                  <Typography
                    sx={{
                      color: '#FF9103',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '133.4%',
                    }}
                    //                     color: var(--Warning-800, #FF9103);
                    // text-align: center;
                    // font-feature-settings: 'clig' off, 'liga' off;

                    // /* Body1 */
                    // font-family: Roboto;
                    // font-size: 16px;
                    // font-style: normal;
                    // font-weight: 400;
                    // line-height: 150%; /* 24px */
                    // letter-spacing: 0.15px;
                  >
                    This user has been disabled
                  </Typography>
                </>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Typography
                sx={{
                  color: '#475467',
                  // textAlign: "center",
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '133.4%',
                  letterSpacing: '0.1px',
                }}
              >
                {user.email}
              </Typography>
              {/* <Typography>•</Typography>
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  color: '#2C4DFF',
                  fontWeight: 500,
                  lineHeight: '133.4%',
                  letterSpacing: '0.1px',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenFluxTokeLimitModal(true)}
              >
                Set Flux Token Limit
              </Typography> */}
            </Box>
          </Box>
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
          }}
        >
          {/* <Button
          variant='outlined'
          sx={{
            gap: '8px',
            color: '#FF0000',
            borderColor: '#FF0000',
            "&:hover": {
                borderColor: '#FF0000',
                backgroundColor: '#FFEEF0',
                },
            borderRadius: '2px',
            
          }}
          // onClick={() => console.log('clicked')}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
            }}
          >
           Delete User
          </Typography>
        </Button> */}
          {user.status === 'inactive' || user.status === 'active' ? (
            <Button
              variant="outlined"
              sx={{
                borderRadius: '2px',
                gap: '8px',
              }}
              onClick={() => setOpenDisableModal(true)}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                {(() => {
                  switch (user.status) {
                    case 'inactive':
                      return 'Enable User';
                    case 'active':
                      return 'Disable User';
                    default:
                      return 'Unknown Status';
                  }
                })()}
              </Typography>
            </Button>
          ) : null}
        </Box>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: '15px',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: '#475467',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '29px',
            }}
          >
            User Stats Overview
          </Typography>
          <Box>
            <Button
              sx={{
                borderRadius: '12px',
                padding: '10px 20px',
                fontSize: '16px',
                textTransform: 'none' as const,
                marginRight: '10px',
                backgroundColor: '#EAECF0',
              }}
            >
              All Time
            </Button>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {dataBox.map((item, index) => (
            <Grid item xs={12} sm={3} md={1.7} key={index}>
              <Box sx={{ ...boxStyles, backgroundImage: item.background }}>
                <Typography variant="h5">{item.value}</Typography>
                <Typography variant="body2">{item.label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#475467',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '29px',
            mb: '15px',
          }}
        >
          User Activity
        </Typography>
      </Box>

      <Box style={{ height: gridHeight, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          hideFooter={rows?.length < 15 ? true : false}
          pageSizeOptions={[5, 10]}
          sx={{
            backgroundColor: 'white',
            // paddingX: '8px',
            '& .MuiDataGrid-filler': {
              width: '0%',
            },
            '& .MuiDataGrid-root': {
              width: '100%',
            },
            '& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root':
              {
                alignItems: 'baseline',
              },
          }}
        />
      </Box>

      <DisableUserModal
        onHide={handleCloseDisableModal}
        fullWidth={true}
        show={openDisableModal}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
              }}
              onClick={() => {
                setOpenDisableModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#3650DE',
                color: '#FFFFFF',
                outline: '1px solid #3650DE',
              }}
              onClick={() => {
                {
                  user.status === 'inactive' ? handleEnable() : handleDisable();
                }
              }}
            >
              {user.status === 'inactive' ? 'Enable' : 'Disable'}
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              {user.status === 'inactive' ? 'Enable User' : 'Disable User'}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              Are you sure you want to
              {user.status === 'inactive' ? 'enable' : 'disable'}
              disable this user. After doing so this user will not be able to
              use the platform inside the company space.
            </Typography>
          </Box>
        </Box>
      </DisableUserModal>
      {/* <FluxTokenLimitModal
        theme={user?.theme}
        show={openFluxTokeLimitModal}
        setIsFluxModalOpen={setOpenFluxTokeLimitModal}
        onHide={() => setOpenFluxTokeLimitModal(false)}
        data={{
          limit: limit,
          companyId: companyId,
        }}
      /> */}
    </Box>
  );
};

export default UserProfile;
