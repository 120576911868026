import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import { UploadImage } from '../util/Types/UploadImage';

export class DocumentService extends ApiService {
  static pathName = '/document';

  static getDocuments = (): Promise<IGetAllResponse<any>> => {
    return ApiService.get(`${this.pathName}?page=1&pageSize=1000`);
  };

  static getSpaceDocuments = (
    spaceId: string,
  ): Promise<IGetAllResponse<any>> => {
    return ApiService.get(
      `${this.pathName}?spaceId=${spaceId}&page=1&pageSize=1000`,
    );
  };

  static createDocument = (
    body: UploadImage & { spaceId: string },
  ): Promise<IGetOneResponse<UploadImage>> => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static extractTextFromFile = (body: {
    id?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/extractText`, body);
  };

  static getDocumentById = (id: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/${id}`);
  };

  static deleteOne = (id: string, spaceId?: string) => {
    const url = spaceId 
      ? `${this.pathName}/${id}?spaceId=${spaceId}`
      : `${this.pathName}/${id}`;
      return ApiService.delete(url);
  };
}
