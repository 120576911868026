const getIdForTableGen = (tableData: any[]) => {
  const getAllIndexesThatAreSelected: number[] = [];

  if (tableData?.length) {
    tableData?.[0]?.columns?.forEach(
      (col: { selected: any }, index: number) => {
        if (col.selected) {
          getAllIndexesThatAreSelected.push(index);
        }
      }
    );
  }

  let idsForSelectedColumns: {
    index: string[];
  }[] = [];
  let processedIds: string[] = [];
  if (getAllIndexesThatAreSelected?.length) {
    // Iterate through the rows and collect the ids of selected columns by index

    getAllIndexesThatAreSelected?.forEach(index => {
      idsForSelectedColumns.push({
        index: tableData.map(row => row?.columns?.[index]?.id),
      });
    });

    // now we have an array of objects with the ids of the selected columns flatt it to an array of strings

    processedIds = idsForSelectedColumns.map(obj => obj.index).flat();
  }

  return {
    processedIds,
    getAllIndexesThatAreSelected,
    idsForSelectedColumns,
  };
};

export { getIdForTableGen };
