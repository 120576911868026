import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class EvenLabsService extends ApiService {
  static pathName = '/even-labs';

  static voices = (): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/voices`);
  };

  static userVoices = (): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/user-voices`);
  };

  static models = (): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/models`);
  };

  static voiceSettings = (id: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/voice-settings/${id}`);
  };

  static clone = (
    body: {
      name: string;
      type: string;
      flowId?: string;
    },
    file: FormData,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/clone${body.name ? `?name=${body.name}` : ''}${
        body.type ? `&type=${body.type}` : ''
      }${body.flowId ? `&flowId=${body.flowId}` : ''}`,
      file,
    );
  };

  static updateSettings = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.patch(`${this.pathName}/voice-settings/${id}`, body);
  };

  static voiceGenerate = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/text-to-speech/${id}`, body);
  };
}
