import React, { useState } from 'react';
import AuthCard from '../../components/auth/AuthCard';
import { useForm } from 'react-hook-form';
import KitInput from '../../components/UI/KitInput';
import { AuthService } from '../../service/AuthService';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotificationStore } from '../../store/storeNotifications';
import { Box, Button } from '@mui/material';
type IFormInput = {
  password: string;
  confirmPassword: string;
};
const ResetPassword = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IFormInput>();

  const { setNotification } = useNotificationStore(state => state);

  const [status, setStatus] = useState({
    reset: false,
    error: false,
  });

  const onSubmit = async (values: IFormInput) => {
    try {
      const response = await AuthService.changePassword(userId!, {
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      setStatus({ reset: true, error: false });
      setNotification({
        type: 'success',
        message: 'Password changed succesfully',
      });
    } catch (error) {
      setStatus({ reset: false, error: true });
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
    }
  };

  return (
    <Box
      className=' login'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AuthCard>
        <Box textAlign={'center'} className='text-center '>
          {status.reset && !status.error ? (
            <>
              <h4 className='login_form_info--text'>
                Password Reset Successfully
              </h4>
              <Button
                className='btn-primary register_btn-verify '
                onClick={() => navigate('/')}
              >
                Return to login
              </Button>
            </>
          ) : (
            <>
              <h4 className='login_form_info--text'>Reset Password</h4>
              <form>
                <KitInput
                  name={'password'}
                  type='password'
                  register={register}
                  label='Password'
                  errorsStatus={errors}
                  validation={{
                    required: {
                      value: true,
                      message: 'password is required',
                    },
                    minLength: {
                      value: 8,
                      message: 'password must be at least 8 characters',
                    },

                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                      message:
                        'password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                    },
                  }}
                />{' '}
                <KitInput
                  name={'confirmPassword'}
                  type='password'
                  label='Confirm Password'
                  register={register}
                  sxStyle={{
                    mb: '20px',
                    mt: '20px',
                  }}
                  errorsStatus={errors}
                  validation={{
                    required: {
                      value: true,
                      message: 'Confirm Password is required',
                    },

                    validate: value =>
                      value === watch('password') ||
                      'The passwords do not match',
                  }}
                />
                <Button
                  onClick={handleSubmit(onSubmit)}
                  className='w-100 mt-3 '
                  fullWidth
                  variant='contained'
                  type='submit'
                >
                  Set Password
                </Button>
              </form>
            </>
          )}

          {status.error && !status.reset && (
            <p
              className='text-danger'
              style={{
                color: '#5D6E82',
                margin: '20px  0 0 0',
              }}
            >
              Something went wrong, please try again
            </p>
          )}
        </Box>
      </AuthCard>
    </Box>
  );
};

export default ResetPassword;
