import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Copy, PencilSlash } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { useAuthStore } from '../../../store/storeAuth';
import { useNotificationStore } from '../../../store/storeNotifications';
import { WordCount } from '../../nodes/AiTextGenerator';
import KitModal from '../KitModal';
import { replaceNtoBr } from '../../../util/markdown';
import LoadingSpinner from '../LoadingSpinner';
// eslint-disable-next-line
import Worker from 'worker-loader!../../../util/worker/textSegmentationWorker';
import OutputMarkDown from '../../OutputMarkdown/OutputMarkDown';
import Codepen from '@uiw/react-codepen';

export interface Segment {
  type: 'code' | 'text' | 'table' | 'chart' | 'img' | 'audio' | 'link';
  content: string;
  isHtml?: boolean;
  title?: string;
}

export const TextComponent = ({
  item,
  onChange,
  user,
  onCopy,
  onHide,
  contentType = 'all',
}: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(item.text);
  const [segments, setSegments] = useState<Segment[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const worker = new Worker();
    worker.onmessage = (e: MessageEvent<Segment[]>) => {
      setSegments(e.data);
      setLoading(false);
    };
    worker.postMessage({ text: value, batchSize: 10000 });

    return () => {
      worker.terminate();
    };
  }, [value]);

  const getSegmentBasedOnContentType = () => {
    if (contentType === 'code') {
      const codedSegments = segments.filter(segment => segment.type === 'code');
      return codedSegments;
    }
    if (contentType === 'table') {
      const tableSegments = segments.filter(
        segment => segment.type === 'table',
      );
      return tableSegments;
    }
    return segments;
  };

  const iframeRef = useRef<any>(null);

  useEffect(() => {
    const htmlContent = `${
      segments?.find(item => item.title === 'html')?.content ?? ''
    } ${segments?.find(item => item.title === 'javascript')?.content ?? ''}`;

    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      iframe.contentDocument.open();
      iframe.contentDocument.write(htmlContent);
      iframe.contentDocument.close();
    }
  }, [segments]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <Box
      p={'8px'}
      sx={{
        borderRadius: '8px',
        border: isEditing ? '1px dashed #3650DE' : '1px solid #EAECF0',

        '&:hover': {
          background:
            user?.theme === 'dark'
              ? item.edit
                ? '#333'
                : '#222'
              : item.edit
              ? '#FFF'
              : '#F9FAFB',
          cursor: item.edit ? 'text' : 'default',
        },
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {item.label && (
          <Typography
            color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
            fontSize={'16px'}
            fontWeight={500}
            lineHeight={'157%'}
          >
            {item.label}
          </Typography>
        )}
        <Box>
          <IconButton onClick={() => onCopy(item.text)}>
            <Copy color="#98A2B3" size={16} />
          </IconButton>

          {!item.edit && (
            <Tooltip
              title={
                item.label === 'Output'
                  ? 'Output text is not editable.'
                  : 'Connected text not editable'
              }
              arrow
              placement="top"
            >
              <IconButton>
                <PencilSlash color="#98A2B3" size={16} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {segments?.some(
        segment => segment.title === 'html' || segment.title === 'javascript',
      ) && (
        <Codepen
          html={segments?.find(item => item.title === 'html')?.content}
          js={segments?.find(item => item.title === 'javascript')?.content}
        >
          Codepen
        </Codepen>
      )}

      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: '300px', border: '1px solid #ccc' }}
        title="Custom Iframe"
      />

      <Box
        onClick={() => {
          if (item.edit) {
            setIsEditing(true);
          }
        }}
      >
        {!isEditing ? (
          <Box
            color={user?.theme === 'dark' ? '#F9FAFB' : '#475467'}
            height={'100%'}
            fontSize={'16px'}
            fontWeight={400}
            lineHeight={'143%'}
            sx={{
              overflowWrap: 'break-word',
              overflow: 'hidden auto',
            }}
          >
            <>
              {getSegmentBasedOnContentType()?.map(
                (segment: any, index: number) => (
                  <OutputMarkDown
                    onCopy={onCopy}
                    segment={segment}
                    user={user}
                    key={index}
                    index={index}
                    value={value}
                    segments={getSegmentBasedOnContentType()}
                  />
                ),
              )}
            </>
          </Box>
        ) : (
          <textarea
            value={replaceNtoBr(value)}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setIsEditing(false);
                setValue(item.text);
              }
            }}
            onChange={e => setValue(e.target.value)}
            style={{
              border: 'none',
              outline: 'none',
              resize: 'vertical',
              fontFamily: 'inherit',
              maxWidth: '100%',
              width: '100%',
              fontSize: '16px',
              minHeight: '400px',
              lineHeight: '143%',
              height: '400px',
              fontWeight: 400,
            }}
          />
        )}

        <Box display={'flex'} justifyContent={'flex-end'} mt={'8px'}>
          {isEditing && (
            <Box>
              <IconButton
                onClick={() => {
                  setIsEditing(false);
                  onChange &&
                    onChange({
                      target: {
                        name: item.name,
                        value: value,
                      },
                    });
                  onHide();
                }}
              >
                <Typography
                  color={'#3650DE'}
                  fontSize={'16px'}
                  fontWeight={500}
                  lineHeight={'150%'}
                >
                  Save
                </Typography>
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const SmallMemoryTextComponent = ({ item, onCopy, user }: any) => {
  const [processedSegments, setProcessedSegments] = useState<Segment[]>([]);

  const [isLoading, setLoading] = useState(true);
  const value = item.content;

  useEffect(() => {
    const worker = new Worker();
    worker.onmessage = (e: MessageEvent<Segment[]>) => {
      setProcessedSegments(e.data);
      setLoading(false);
    };
    worker.postMessage({ text: value, batchSize: 10000 });

    return () => {
      worker.terminate();
    };
  }, [value]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      p={'12px'}
      sx={{
        borderRadius: '8px',
        border: '1px solid #EAECF0',
        '&:hover': {
          background:
            user?.theme === 'dark'
              ? item.edit
                ? '#333'
                : '#222'
              : item.edit
              ? '#FFF'
              : '#F9FAFB',
          cursor: item.edit ? 'text' : 'default',
        },
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
          <Typography
            color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
            fontSize={'16px'}
            fontWeight={500}
            lineHeight={'157%'}
          >
            Time Stamp:
          </Typography>
          <Typography
            color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
            fontSize={'16px'}
            lineHeight={'157%'}
          >
            {item.timestamp}
          </Typography>
        </Box>

        <Box>
          <Tooltip title={'This text is not editable.'} arrow placement="top">
            <IconButton>
              <PencilSlash color="#98A2B3" size={16} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <Typography
          color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
          fontSize={'16px'}
          fontWeight={500}
          lineHeight={'157%'}
        >
          Role:
        </Typography>
        <Typography
          color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
          fontSize={'16px'}
          lineHeight={'157%'}
        >
          {item.role.charAt(0).toUpperCase() + item.role.slice(1)}
        </Typography>
      </Box>
      <>
        {processedSegments?.map((segment, index) => (
          <OutputMarkDown
            onCopy={onCopy}
            segment={segment}
            user={user}
            key={index}
            index={index}
            value={value}
            segments={processedSegments}
          />
        ))}
      </>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => onCopy(item.content)}>
          <Copy size={19} color="#000" />
        </IconButton>
      </Box>
    </Box>
  );
};

type PreviewModalProps = {
  onHide: () => void;
  show: boolean;
  data: any;
  onChange?: (e: any) => void;
  name?: string;
  isSmallMemory?: boolean;
  nodeLabel?: string;
  type?: string;
  processedSegments?: any[];
};

const PreviewModal = ({
  onHide,
  show,
  data,
  onChange,
  name,
  isSmallMemory,
  nodeLabel,
  type,
  processedSegments,
}: PreviewModalProps) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text);

    setNotification({
      type: 'success',
      message: 'Text copied to clipboard',
    });
  };

  const { user } = useAuthStore(state => state);

  return (
    <KitModal
      maxWidth={'md'}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'hidden',
          maxHeight: '700px',
          width:
            data?.value?.length > 600 ||
            (data?.data?.length > 2 &&
              data?.data?.map((item: any) => item?.text?.length > 400))
              ? '1000px'
              : '500px',
          borderRadius: '16px',
          pb: '16px',
          backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
          borderTop: 'none',
          borderBottom: 'none',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
        p: '0px',
      }}
      fullWidth
      showCloseButton={true}
      show={show}
      onHide={onHide}
      title={
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
              fontSize="16px"
              fontWeight={600}
              lineHeight="150%"
              sx={{
                maxWidth: '350px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              title={isSmallMemory ? data[0]?.name : data?.labelName}
            >
              {isSmallMemory ? data[0]?.name : data?.labelName}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={'6px'} mb={'10px'}>
            <Typography
              color={'#475467'}
              fontSize={'14px'}
              fontWeight={400}
              letterSpacing={'0.17px'}
              mt={0}
            >
              {data?.nodeLabel}
            </Typography>
            <Typography
              color={'#475467'}
              fontSize={'14px'}
              fontWeight={400}
              letterSpacing={'0.17px'}
            >
              {data?.value && data?.nodeLabel && '•'}
            </Typography>
            <WordCount
              text={data?.value}
              props={{
                color: '#475467',
                fontSize: '14px',
              }}
            />
          </Box>
        </>
      }
      headerStyles={{
        pb: '0px',
      }}
    >
      <Box maxHeight={550}>
        {isSmallMemory ? (
          data[0]?.entries ? (
            <Box
              display={{
                display: 'flex',
                flexDirection: 'column',
                gap: '14px',
              }}
            >
              {data[0]?.entries.map((item: any, index: number) => {
                return (
                  <SmallMemoryTextComponent
                    key={index}
                    item={item}
                    onCopy={onCopy}
                  />
                );
              })}
            </Box>
          ) : (
            <Box
              display={{
                display: 'flex',
                flexDirection: 'column',
                gap: '14px',
              }}
            >
              {data.map((item: any, index: number) => {
                return (
                  <SmallMemoryTextComponent
                    key={index}
                    item={item}
                    onCopy={onCopy}
                  />
                );
              })}
            </Box>
          )
        ) : data?.data?.length > 0 ? (
          <Box
            display={{
              display: 'flex',
              flexDirection: 'column',
              gap: '14px',
            }}
          >
            {data?.data?.map((item: any, index: number) => {
              return (
                <TextComponent
                  key={index}
                  item={{ ...item, name }}
                  onChange={onChange}
                  onCopy={onCopy}
                  user={user}
                  onHide={onHide}
                  nodeLabel={nodeLabel}
                  processedSegments={processedSegments}
                  type={type}
                />
              );
            })}
          </Box>
        ) : (
          <TextComponent
            item={{
              text: data?.value,
              label: data?.labelName,
              edit: data?.readOnly ? false : true,
              name: name,
            }}
            onChange={onChange}
            onCopy={onCopy}
            user={user}
            onHide={onHide}
            processedSegments={processedSegments}
            type={type}
          />
        )}
      </Box>
    </KitModal>
  );
};

export default PreviewModal;
