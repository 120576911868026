import { Edge, Node } from 'reactflow';

export const getNodesConnectedToflow = (edges: any[]) => {
  const findAllCommanderNodes = edges.filter(edge =>
    edge.source?.includes('global')
  );

  if (findAllCommanderNodes.length === 0) return;

  const findConnectedNodes = (
    edges: Edge[],
    nodeId: string,

    combinedIds: string[] = [],
    visited = new Set()
  ) => {
    let connectedNodes: string[] = [];

    edges.forEach(edge => {
      const { source, target, sourceHandle, targetHandle } = edge;

      if (source === nodeId && sourceHandle === 'outputFlow') {
        if (!visited.has(target)) {
          visited.add(target);
          combinedIds.push(target);

          const recursivelyConnectedNodes = findConnectedNodes(
            edges,
            target,
            combinedIds,
            visited
          );

          connectedNodes.push(target, ...recursivelyConnectedNodes);
        }
      }
    });

    return connectedNodes;
  };
  const combinedIds: {
    [target: string]: string[];
  } = {};
  findAllCommanderNodes.forEach(edge => {
    if (!combinedIds[edge.target]) {
      combinedIds[edge.target] = [];
    }

    findConnectedNodes(edges, edge.target, combinedIds[edge.target]);
  });

  const keys = Object.keys(combinedIds);

  const allIds = keys
    .map(key => {
      return [key, ...combinedIds[key]];
    })
    ?.flat();

  return allIds;
};

export const getIds = (edges: any[], sourceId: string) => {
  const findAllCommanderNodes = edges.filter(edge => edge?.source === sourceId);

  if (findAllCommanderNodes.length === 0) return;

  const findConnectedNodes = (
    edges: Edge[],
    nodeId: string,

    combinedIds: string[] = [],
    visited = new Set()
  ) => {
    let connectedNodes: string[] = [];

    edges.forEach(edge => {
      const { source, target } = edge;

      if (source === nodeId) {
        if (!visited.has(target)) {
          visited.add(target);
          combinedIds.push(target);

          const recursivelyConnectedNodes = findConnectedNodes(
            edges,
            target,
            combinedIds,
            visited
          );

          connectedNodes.push(target, ...recursivelyConnectedNodes);
        }
      }
    });

    return connectedNodes;
  };
  const combinedIds: {
    [target: string]: string[];
  } = {};
  findAllCommanderNodes.forEach(edge => {
    if (!combinedIds[edge.target]) {
      combinedIds[edge.target] = [];
    }

    findConnectedNodes(edges, edge.target, combinedIds[edge.target]);
  });

  const keys = Object.keys(combinedIds);

  const allIds = keys
    .map(key => {
      return [key, ...combinedIds[key]];
    })
    ?.flat();

  return allIds;
};
