import { Box, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { clipGuidancePresent, viewsArray } from '../AiConfigs/constants';
import KitRanger from '../UI/KitRanger';
import { FormData } from './constants';

type Props = {
  formData: FormData;
  onChange: (e: any) => void;

  imageModels:
    | {
        description: string;
        id: string;
        name: string;
        type: string;
      }[]
    | undefined;
};

const StabilityAi = ({ formData, onChange, imageModels }: Props) => {
  return (
    <>
      <Box>
        {formData.type === 'Text to Image' && (
          <Box>
            <FormLabel htmlFor="engineId">Engine</FormLabel>
            <Select
              name="engineId"
              className="nodrag"
              fullWidth
              value={imageModels?.length ? formData.engineId : 'noValue'}
              onChange={onChange}
            >
              <MenuItem disabled value="noValue">
                {'Choose an Engine'}
              </MenuItem>
              {imageModels?.map((imageModel, index) => {
                if (formData.type === 'Upscale Image' && index === 0) {
                  return (
                    <MenuItem key={index} value={imageModel?.id}>
                      {imageModel?.description}
                    </MenuItem>
                  );
                }

                if (formData.type !== 'Upscale Image' && index !== 0) {
                  return (
                    <MenuItem key={index} value={imageModel?.id}>
                      {imageModel?.description}
                    </MenuItem>
                  );
                }

                return null;
              })}
            </Select>
          </Box>
        )}
        {/* <Box>
          <FormLabel htmlFor="engineId">Engine</FormLabel>
          <Select
            name="engineId"
            className="nodrag"
            fullWidth
            defaultValue={formData.engineId}
            value={imageModels?.length ? formData.engineId : 'noValue'}
            onChange={onChange}
          >
            <MenuItem disabled value="noValue">
              {'Choose an Engine'}
            </MenuItem>
            {imageModels?.map((imageModel, index) => {
              if (formData.type === 'Upscale Image' && index === 0) {
                return (
                  <MenuItem key={index} value={imageModel?.id}>
                    {imageModel?.description}
                  </MenuItem>
                );
              }

              if (formData.type !== 'Upscale Image' && index !== 0) {
                return (
                  <MenuItem key={index} value={imageModel?.id}>
                    {imageModel?.description}
                  </MenuItem>
                );
              }

              return null;
            })}
          </Select>
        </Box> */}

        <Box>
          <InputLabel htmlFor="type">Clip Guidance Present </InputLabel>

          <Select
            name="clip_guidance_preset"
            className="nodrag"
            value={formData.clip_guidance_preset || ''}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value={''}></MenuItem>
            {clipGuidancePresent?.map((imageModel, index) => (
              <MenuItem key={index} value={imageModel}>
                {imageModel}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel htmlFor="sampler">Sampler </InputLabel>
          <Select
            fullWidth
            name="sampler"
            className="nodrag"
            value={formData.sampler || ''}
            onChange={onChange}
          >
            {viewsArray?.map((imageModel, index) => (
              <MenuItem key={index} value={imageModel}>
                {imageModel}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <KitRanger
          value={formData.width}
          onChange={onChange}
          name="width"
          label="Width"
          min={512}
          max={1024}
          step={64}
        />
        <KitRanger
          value={formData.height}
          onChange={onChange}
          name="height"
          label="Height"
          min={512}
          max={1920}
          step={64}
        />

        <KitRanger
          value={formData.cfg_scale}
          onChange={onChange}
          name="cfg_scale"
          min={1}
          label="Cfg Scale"
          max={35}
          step={1}
        />
        <KitRanger
          value={formData.steps}
          onChange={onChange}
          name="steps"
          label="Steps"
          min={10}
          max={150}
          step={1}
        />
        <KitRanger
          value={formData.image_strength}
          onChange={onChange}
          name="image_strength"
          min={0}
          max={1}
          label="Image Strength"
          step={0.01}
        />
        <KitRanger
          value={formData.promptWeight}
          onChange={onChange}
          name="promptWeight"
          label="Prompt Weight"
          min={0}
          max={1}
          step={0.01}
        />
      </Box>
    </>
  );
};

export default StabilityAi;
