import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import { styled } from '@mui/system';
import ReactPlayer from 'react-player';
import PopupCloseIcon from '../../assets/icons/popup-close.png';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  videoUrl: string;
  onClose: () => void;
}

const PopupWithVideo: React.FC<Props> = ({ videoUrl, onClose }) => {
  const playerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePlay = () => {
    const playerElementWrapper = (playerRef.current as any)?.wrapper;

    if (!playerElementWrapper) return;

    if (isMobile && playerElementWrapper.requestFullscreen) {
      playerElementWrapper.requestFullscreen();
    } else if (isMobile && playerElementWrapper.mozRequestFullScreen) {
      playerElementWrapper.mozRequestFullScreen();
    } else if (isMobile && playerElementWrapper.webkitRequestFullscreen) {
      playerElementWrapper.webkitRequestFullscreen();
    } else if (isMobile && playerElementWrapper.msRequestFullscreen) {
      playerElementWrapper.msRequestFullscreen();
    }
  };

  const popupContainerStyle: { [key: string]: number | string } = {
    background: 'linear-gradient(to right, #142BFF 0%, #396AFC 100%)',
    padding: 35,
    borderRadius: 5,
    border: 'none',
    position: 'relative',
  };
  if (isMobile) {
    popupContainerStyle.width = '90vw';
  }

  return (
    <div>
      <StyledModal open={open} onClose={handleClose}>
        <div style={popupContainerStyle}>
          <div
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              cursor: 'pointer',
            }}
          >
            <img src={PopupCloseIcon} alt="Close" style={{ width: 20 }} />
          </div>

          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            controls={true}
            playing
            onPlay={handlePlay}
            width={isMobile ? '100%' : undefined}
          />
        </div>
      </StyledModal>
    </div>
  );
};

export default PopupWithVideo;
