import { FalService } from '../../service/FalService';
import { IdeogramService } from '../../service/IdeogramService';
import {
  ImageService,
  StabilityAiImageService,
} from '../../service/ImageService';
import { OpenAiService } from '../../service/OpenAiService';
import { ReplicateService } from '../../service/ReplicateService';
import { mergeText } from '../../util/mergeText';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { FormData as Test } from './constants';

export const imageGeneratorResponses = async (
  formData: Test,
  schema: any,
  multipleUploadFiles: FileList | null | undefined,
  promptObjects: {
    name: string;
    placeholder: string;
  }[],
  flowId: string,
) => {
  let response: any = null;
  const text = mergeText(formData);
  if (formData.aiModels === 'openAi') {
    if (formData.openAiModels === 'createImage') {
      const res = await OpenAiService.createImage({
        prompt: replacePlaceholders(text, schema),
        size: formData.size,
        quality: formData.quality,
        flowId,
      });

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }

    if (formData.openAiModels === 'editImage') {
      const formDataMultiple = new FormData();

      formDataMultiple.append('flowId', flowId);

      if (multipleUploadFiles) {
        for (let i = 0; i < multipleUploadFiles.length; i++) {
          formDataMultiple.append('file', multipleUploadFiles[i]);
        }
      }

      const res = await OpenAiService.editImage(
        {
          prompt: replacePlaceholders(text, schema),
          numbersOfVariations: formData.numbersOfVariations,
          size: formData.size,
          flowId,
        },
        formDataMultiple,
      );

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }

    if (formData.openAiModels === 'imageVariation') {
      const formDataMultiple = new FormData();
      formDataMultiple.append('flowId', flowId);

      if (multipleUploadFiles) {
        for (let i = 0; i < multipleUploadFiles.length; i++) {
          formDataMultiple.append('file', multipleUploadFiles[i]);
        }
      }

      const res = await OpenAiService.imageVariation(
        {
          prompt: replacePlaceholders(formData.prompt, schema),
          numbersOfVariations: formData.numbersOfVariations,
          size: formData.size,
          flowId,
        },
        formDataMultiple,
      );

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }
  }

  if (formData.aiModels === 'openSources') {
    const text = mergeText(formData);

    const res = await ReplicateService.kandinsky({
      prompt: replacePlaceholders(text, schema),
      negativePrompt:
        replacePlaceholders(formData.negativePrompt, schema) || null,
      steps: Number(formData.steps),
      width: Number(formData.width),
      height: Number(formData.height),
      flowId,
    });

    response = {
      message: res.data.message,
      usedTokens: res.data.usedTokens,
    };
  }

  if (formData.aiModels === 'stabilityAi') {
    if (formData.type === 'Text to Image') {
      if (
        [
          'sd3',
          'core',
          'stability-ultra',
          'sd3.5-large',
          'sd3.5-large-turbo',
        ].includes(formData.engineId)
      ) {
        const aspectRatio = formData.selectedImageToImageAspectRatio || '1:1';
        if (
          ['sd3', 'sd3.5-large', 'sd3.5-large-turbo'].includes(
            formData.engineId,
          )
        ) {
          const query = {
            flowId,
            fileUrl: formData.inputPreview || '',
            prompt: replacePlaceholders(text, schema),
            aspect_ratio: aspectRatio,
            mode: 'text-to-image',
            negative_prompt: formData.negativePrompt || 'None',
            model: formData.engineId,
            output_format: formData.output_format || 'png',
          };

          let body = null;
          if (formData.inputPreview?.includes('blob') && formData.uploadImage) {
            body = new FormData();
            body.append('file', formData.uploadImage as any);
            body.append('flowId', flowId);
          }

          const res = await StabilityAiImageService.stabilitySd3(body, query);
          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };
        } else if (formData.engineId === 'core') {
          const query = {
            flowId,
            fileUrl: formData.inputPreview || '',
            prompt: replacePlaceholders(text, schema),
            aspect_ratio: aspectRatio,
            negative_prompt: formData.negativePrompt || 'None',
            style_present:
              formData.style_present === 'None' ? null : formData.style_present,
            output_format: formData.output_format || 'png',
          };

          const res = await StabilityAiImageService.stabilityCore(query);

          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };
        } else if (formData.engineId === 'stability-ultra') {
          const body = {
            prompt: replacePlaceholders(text, schema),
            flowId,
          };

          const res = await StabilityAiImageService.stabilityUltra(body);
          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };
        }
      } else {
        let prompt = [];

        const text = mergeText(formData);

        const checkIfAnyOfThePromptIsFilled = promptObjects.some(
          obj => formData[obj?.name as keyof Test],
        );

        if (formData.negativePrompt && checkIfAnyOfThePromptIsFilled && text) {
          prompt.push({
            text: replacePlaceholders(text, schema),
            weight: Number(formData.promptWeight),
          });

          prompt.push({
            text: replacePlaceholders(formData.negativePrompt, schema),
            weight: -1,
          });
        } else if (checkIfAnyOfThePromptIsFilled) {
          prompt.push({
            text: replacePlaceholders(text, schema),
            weight: Number(formData.promptWeight),
          });
        }

        const res = await ImageService.textToImage({
          engineId: formData.engineId,
          cfg_scale: Number(formData.cfg_scale),
          clip_guidance_preset: formData.clip_guidance_preset,
          height: Number(formData.height),
          width: Number(formData.width),
          name: `${Math.random()}`,
          prompt: prompt,
          style_present:
            formData.style_present === 'None' ? null : formData.style_present,

          steps: Number(formData.steps),
          flowId,
        });

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      }
    }

    if (formData.type === 'Upscale Image') {
      let image = null;

      let object = {
        engineId: formData.engineId,
        width: Number(formData.width),
        height: Number(formData.height),
        src: '',
        flowId,
      };

      if (formData.inputPreview?.includes('blob') && formData.uploadImage) {
        image = new FormData();
        image.append('file', formData.uploadImage as any);
        image.append('flowId', flowId);
      } else if (formData?.inputPreview && !formData.uploadImage) {
        object.src = formData.inputPreview;
      }

      if (Number(formData.width) === 0 && Number(formData.height) === 0) {
        throw new Error('Width and Height cannot be 0');
      }

      const res = await ImageService.upscaleImage(object, image);

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }

    if (formData.type === 'AI Edit') {
      if (formData.aiEditSelectedType === 'using mask') {
        if (!formData.inputPreview || !formData.maskInputPreview) return;

        const res = await ImageService.inpaint(
          formData.inputPreview,
          formData.maskInputPreview,
          formData.prompt,
        );

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      } else if (formData.aiEditSelectedType === 'using search prompt') {
        if (!formData.inputPreview) return;

        const res = await ImageService.searchReplace({
          fileUrl: formData.inputPreview,
          prompt: formData.prompt,
          searchPrompt: formData?.searchPrompt || formData?.Data?.[0]?.text,
        });

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      } else if (formData.aiEditSelectedType === 'recolor using search') {
        if (!formData.inputPreview) return;
        const selectPrompt = formData.searchPrompt;
        const res = await ImageService.recolorUsingSearch({
          fileUrl: formData.inputPreview,
          prompt: formData.prompt,
          selectPrompt: selectPrompt || '',
        });

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      }
    }

    if (formData.type === 'AI erase') {
      if (formData.aiEraseSelectedType === 'remove background') {
        let imageUrl = formData.inputPreview;

        if (imageUrl) {
          const res = await ImageService.removeBackgroundUrl(imageUrl);

          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };
        } else {
          let imageData = new FormData();
          imageData.append('file', formData.uploadImage as any);
          const res = await ImageService.removeBackground(imageData);

          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };
        }
      } else if (formData.aiEraseSelectedType === 'remove objects') {
        if (!formData.inputPreview || !formData.maskInputPreview) return;

        const res = await ImageService.erase({
          image: formData.inputPreview,
          mask: formData.maskInputPreview,
        });

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      }
    }

    if (formData.type === 'Enhance resolution') {
      if (!formData.inputPreview) return;

      const res = await ImageService.enhanceResolution({
        src: formData.inputPreview,
        prompt: formData.prompt,
      });

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }

    if (formData.type === 'Expand Image') {
      if (!formData.dimensions || !formData.inputPreview) return;

      const left = formData.dimensions.left.toString();
      const right = formData.dimensions.right.toString();
      const top = formData.dimensions.top.toString();
      const bottom = formData.dimensions.bottom.toString();

      const res = await ImageService.outpaint({
        fileUrl: formData.inputPreview,
        left,
        up: top,
        right,
        down: bottom,
      });

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }

    if (formData.type === 'Image to image') {
      const influenceStrength = formData.influenceStrength
        ? formData.influenceStrength / 100
        : 0;

      const aspectRatio = formData.selectedImageToImageAspectRatio || '1:1';
      const negativePrompt = formData.negativePrompt;

      if (!formData.inputPreview) return;

      if (formData.imageToImageSelectedType === 'sketch') {
        const res = await ImageService.imageToImageSketch({
          fileUrl: formData.inputPreview,
          prompt: formData.prompt,
          controlStrength: influenceStrength,
          negativePrompt,
          aspectRatio,
        });
        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      } else if (formData.imageToImageSelectedType === 'style') {
        const res = await ImageService.imageToImageStyle({
          fileUrl: formData.inputPreview,
          prompt: formData.prompt,
          controlStrength: influenceStrength,
          negativePrompt,
          aspectRatio,
        });
        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      } else if (formData.imageToImageSelectedType === 'structure') {
        const res = await ImageService.imageToImageStructure({
          fileUrl: formData.inputPreview,
          prompt: formData.prompt,
          controlStrength: influenceStrength,
          negativePrompt,
          aspectRatio,
        });
        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };
      }
    }
  }

  if (formData.aiModels === 'ideogram') {
    if (formData.type === 'Image to image') {
      const res = await IdeogramService.remix({
        flowId,
        fileUrl: formData.inputPreview as string,
        prompt: formData.prompt,
        aspectRatio: formData.aspect_ratio_ideogram,
        model: formData.ideogramModel,
        magicPromptOption: formData.magicPrompt,
        styleType: formData.style_type,
        negativePrompt: formData.negativePrompt,
        resolution: formData.resolution,
        imageWeight: formData.image_weight,
      });
      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    } else if (formData.type === 'Expand Image') {
      const res = await IdeogramService.upscaleImage({
        flowId,
        fileUrl: formData.inputPreview as string,
        prompt: formData.prompt,
        aspectRatio: formData.aspect_ratio_ideogram,
        model: formData.ideogramModel,
        magicPromptOption: formData.magicPrompt,
        styleType: formData.style_type,
        negativePrompt: formData.negativePrompt,
        resolution: formData.resolution,
        imageWeight: formData.image_weight,
      });

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    } else {
      const query = {
        flowId,
        fileUrl: formData.inputPreview as string,
        prompt: replacePlaceholders(text, schema),
        aspectRatio: formData.aspect_ratio_ideogram,
        model: formData.ideogramModel,
        magicPromptOption: formData.magicPrompt,
        styleType: formData.style_type,
        negativePrompt: formData.negativePrompt
          ? formData.negativePrompt
          : null,
        resolution: formData.resolution,
        imageWeight: formData.image_weight,
      };

      const res = await IdeogramService.createImage(query);

      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }
  }
  if (formData.aiModels === 'fal') {
    const sync_mode = formData.sync_mode === 'false' ? false : true;
    if (formData.falModel === 'pro') {
      const res = await FalService.pro({
        flowId,
        fileUrl: formData.inputPreview as string,
        prompt: formData.prompt,
        guidanceScale: formData.guidance_scalefloat,
        imageSize: formData.imageSize,
        numImages: formData.num_images,
        numInferenceSteps: formData.inference_steps,
        safetyTolerance: String(formData.safety_tolerance),
        seed: formData.fal_seed,
        syncMode: sync_mode,
      });
      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }
    if (formData.falModel === 'schnell') {
      const res = await FalService.schnell({
        flowId,
        fileUrl: formData.inputPreview as string,
        prompt: formData.prompt,
        guidanceScale: formData.guidance_scalefloat,
        imageSize: formData.imageSize,
        numImages: formData.num_images,
        numInferenceSteps: formData.inference_steps,
        safetyTolerance: String(formData.safety_tolerance),
        seed: formData.fal_seed,
        syncMode: sync_mode,
      });
      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }
    if (formData.falModel === 'dev') {
      const res = await FalService.dev({
        flowId,
        fileUrl: formData.inputPreview as string,
        prompt: formData.prompt,
        guidanceScale: formData.guidance_scalefloat,
        imageSize: formData.imageSize,
        numImages: formData.num_images,
        numInferenceSteps: formData.inference_steps,
        safetyTolerance: String(formData.safety_tolerance),
        seed: formData.fal_seed,
        syncMode: sync_mode,
      });
      response = {
        message: res.data.message,
        usedTokens: res.data.usedTokens,
      };
    }
  }

  return response;
};
