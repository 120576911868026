import React from 'react';
import { Handle, Position, useEdges, useReactFlow } from 'reactflow';
import { coreEdgeStyle } from './edges/coreEdgeStyleDef';
import { Box } from '@mui/material';

interface Props {
  isConnectable: boolean;
  nodeId: string;
  sourceName: string;
  style?: React.CSSProperties;
  position: Position;
  setActiveSourceName?: (sourceName: string) => void;
  className?: string;
}

const SourceHandle = ({
  isConnectable,
  nodeId,
  sourceName,
  style,
  position,
  setActiveSourceName,
  className,
}: Props) => {
  const edges = useEdges();
  const { setEdges } = useReactFlow();
  const handleEdgeColors = () => {
    const colors = [
      'green',
      'blue',
      'red',
      'purple',
      'orange',
      'yellow',
      '#90EE90',
      '#ADD8E6',
      '#FFC0CB',
      '#E6E6FA',
      '#FFD700',
      '#FFFFE0',
    ];
    let colorIndex = 0;
    const coloredEdges = edges.map((edge, i) => {
      if (edge.source === nodeId && edge.sourceHandle === sourceName) {
        const updatedEdge = {
          ...edge,
          style: {
            ...edge.style,
            stroke: colors[colorIndex],
            strokeWidth: 10,
          },
          type: 'coloredEdge',
          markerEnd: coreEdgeStyle.markerEndCustom,

          data: {
            coloredEdge: true,
            coloredFromSource: true,
            coloredFromTarget: false,
          },
        };

        colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
        return updatedEdge;
      }
      return {
        ...edge,
        style: {
          ...edge.style,
          stroke: '#6e75ff',
          strokeWidth: 1,
        },
        type: edge.type === 'custom' ? 'custom' : '',
        markerEnd: coreEdgeStyle.markerEnd,
        data: {
          coloredEdge: false,
        },
      };
    });

    setEdges(coloredEdges);
  };
  return (
    <Box>
      <Handle
        type='source'
        position={position}
        id={sourceName}
        isConnectable={isConnectable}
        className={className}
        style={style}
        onClick={e => {
          e.stopPropagation();
          setActiveSourceName?.(sourceName);
          handleEdgeColors();
        }}
      />
    </Box>
  );
};

export default SourceHandle;
