import { Box, MenuItem, Select } from '@mui/material';
import { googleTrendsCategories } from '../../util/countries';
import { WebTrendsFrom } from '../nodes/WebTrends';
import Label from '../UI/Label/Label';
import { useAuthStore } from '../../store/storeAuth';

const dataTypes = {
  TIMESERIES: 'Interest over time',
  GEO_MAP: 'Compared breakdown by region',
  GEO_MAP_0: 'Interest by region',
  RELATED_TOPICS: 'Related topics',
  RELATED_QUERIES: 'Related queries',
};

const groupTypes = {
  images: 'Image Search',
  news: 'News Search',
  froogle: 'Google Shopping',
  youtube: 'Youtube Search',
};

export const dateTypes = [
  'now 1-H',
  'now 4-H',
  'now 1-d',
  'now 7-d',
  'today 1-m',
  'today 3-m',
  'today 12-m',
  'today 5-y',
  'all',
];

const regionTypes = ['COUNTRY', 'REGION', 'DMA', 'CITY'];

type Props = {
  formData: WebTrendsFrom;
  handleInputChange: (e: any) => void;
};

const sortedCategories = Object.entries(googleTrendsCategories)
.filter(([key]) => key !== '0')
.sort(([, a], [, b]) => a.localeCompare(b));

const WebTrendsForm = ({ formData, handleInputChange }: Props) => {
  const { user } = useAuthStore(state => state);

  return (
    <Box>
      <Label isAdvanced labelName='Data Type' />
      <Select
        name='data_type'
        className='nodrag'
        value={formData.data_type}
        onChange={handleInputChange}
        size='small'
        sx={{
          '& .MuiList-root': {
            backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          mb: "10px",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        {Object.entries(dataTypes).map(([code, name]) => (
          <MenuItem key={code} value={code} sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}>
            {name}
          </MenuItem>
        ))}
      </Select>

      <Label labelName='Category' isAdvanced />
      <Select
        name='cat'
        className='nodrag'
        value={formData.cat}
        onChange={handleInputChange}
        size='small'
        sx={{
          '& .MuiList-root': {
            backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          mb: "10px",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        <MenuItem key='0' value='0' sx={{
          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
        }}>
          {googleTrendsCategories['0']}
        </MenuItem>
        {sortedCategories.map(([code, name]) => (
          <MenuItem key={code} value={code} sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <Label labelName='Property' isAdvanced />
      <Select
        name='group'
        className='nodrag'
        value={formData.group || ''}
        onChange={handleInputChange}
        size='small'
        sx={{
          '& .MuiList-root': {
            backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          mb: "10px",
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        <MenuItem value={'Web Search'} sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>Web Search</MenuItem>
        {Object.entries(groupTypes).map(([code, name]) => (
          <MenuItem key={code} value={code} sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default WebTrendsForm;
