import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
  alpha,
} from '@mui/material';
import UploadIcon from '../../../assets/icons/download.svg';
import { useEffect, useRef, useState } from 'react';
import { CompanyService } from '../../../service/CompanyService';
import { useNotificationStore } from '../../../store/storeNotifications';
import { UploadService } from '../../../service/UploadService';
import {
  Buildings,
  PencilSimpleLine,
  UploadSimple,
} from '@phosphor-icons/react';
import ChangeOrganizationNameModal from '../ChangeOrganizationNameModal';
import { User } from '../../../util/Types/User';
import { useAuthStore } from '../../../store/storeAuth';

const boxStyles = {
  display: 'flex',
  padding: '12px',
  width: '80%',
  height: '60px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  borderRadius: '8px',
  color: '#fff',
  marginRight: '30px',
};

interface DataBoxItem {
  background: string;
  label: string;
  value: number;
}
interface Stats {
  flowCreated: number;
  nodeRuns: number;
  spaceCreated: number;
  totalTokens: number;
}

type Props = {
  authUser: User;
  companyData: any;
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
};

const CompanySettings = ({
  authUser,
  setSelectedMenuItem,
  companyData,
}: Props) => {
  const [company, setCompany] = useState<any>(companyData);
  const avatarUploadRef = useRef<any>(null);
  const [
    isChangeOrganizationNameModalOpen,
    setIsChangeOrganizationNameModalOpen,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthStore();

  const activeUser = company?.companyUsers?.find(
    (item: any) => item.user?.id === user?.id,
  );

  const setNotification = useNotificationStore(state => state.setNotification);

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      const file = e.target.files![0];
      const formDataLogo = new FormData();
      formDataLogo.append('file', file);

      const logo = await UploadService.uploadFile(formDataLogo);
      const res = await CompanyService.updateCompany(
        {
          name: company?.name,
          voice: company?.voice,
          logo: logo.data.src,
          domain: company?.domain,
        },
        companyData?.id,
      );

      setCompany(res?.data);

      setNotification({
        type: 'success',
        message: 'Logo updated successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const [stats, setStats] = useState<Stats>();
  useEffect(() => {
    const getCompanyStats = async () => {
      try {
        if (companyData.id) {
          const res = await CompanyService.getCompanyStats(companyData.id);
          setStats(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCompanyStats();
  }, [companyData.id]);

  const dataBox: DataBoxItem[] = [
    {
      background:
        'linear-gradient(53deg, #FD746C 16.71%, #FE846A 48.74%, #FF9068 80.78%)',
      label: 'AI FluxTokens',
      value: stats?.totalTokens || 0,
    },
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Spaces Created',
      value: stats?.spaceCreated || 0,
    },
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Flows Created',
      value: stats?.flowCreated || 0,
    },
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Node Runs',
      value: stats?.nodeRuns || 0,
    },
  ];

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: 'white',
          borderBottom: '1px solid #D0D5DD',
          display: 'flex',
          height: authUser.isUserCompany ? '67px' : '64px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            marginLeft: '24px',
          }}
        >
          Organization Settings
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '24px 24px 16px 24px',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                position: 'relative',
                borderRadius: '4px',
                width: '75px',
                height: '75px',
                background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="warning" />
            </Box>
          ) : company?.logo ? (
            <Box
              sx={{
                borderRadius: '4px',
                width: '75px',
                height: '75px',
                backgroundImage: `${
                  Boolean(company?.logo)
                    ? `url(${company?.logo})`
                    : `url(${UploadIcon})`
                }`,
                backgroundSize: `${Boolean(company?.logo) ? 'cover' : '40%'}`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Box
                sx={{
                  borderRadius: '4px',
                  backgroundColor: alpha('#808080', 0.8),
                  cursor: 'pointer',
                  px: '2px',
                  py: '1px',
                  margin: '4px',
                }}
                onClick={() => avatarUploadRef.current?.click()}
              >
                <UploadSimple size={20} color="#ffffff" />
              </Box>
              <input
                type="file"
                ref={avatarUploadRef}
                hidden
                onChange={handleLogoUpload}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '4px',
                  width: '75px',
                  height: '75px',
                  background:
                    'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Buildings size={40} color="#ffffff" />
                <Box
                  sx={{
                    position: 'absolute',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    background:
                      'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
                    bottom: 0,
                    right: 1,
                    px: '1px',
                    py: '1px',
                  }}
                  onClick={() => avatarUploadRef.current?.click()}
                >
                  <UploadSimple size={20} color="#ffffff" />
                </Box>
                <input
                  type="file"
                  ref={avatarUploadRef}
                  hidden
                  onChange={handleLogoUpload}
                />
              </Box>
            </>
          )}

          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: '22px', width: '200px' }}
            />
          ) : (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{
                    color: '#475467',
                    fontFamily: 'Roboto',
                    fontSize: '22px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '133.4%',
                  }}
                >
                  {companyData?.name}
                </Typography>

                {activeUser.role === 'admin' && (
                  <Button
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                    onClick={() => setIsChangeOrganizationNameModalOpen(true)}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 450,
                        lineHeight: '24px',
                        color: '#3650DE',
                      }}
                    >
                      Edit
                    </Typography>
                    <PencilSimpleLine size={20} />
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#475467',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '133.4%',
                    letterSpacing: '0.1px',
                  }}
                >
                  {authUser?.plan}&nbsp;•&nbsp;
                </Typography>
                <Typography
                  sx={{
                    color: '#3650DE',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '133.4%',
                    letterSpacing: '0.1px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedMenuItem?.('companyUsers')}
                >
                  {company?.companyUsers?.length === 0
                    ? '1'
                    : company?.companyUsers?.length}
                  {company?.companyUsers?.length > 1
                    ? ' Organization Users'
                    : ' Organization User'}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ marginTop: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '20px',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '29px',
              }}
            >
              Organization Stats Overview
            </Typography>

            <Box>
              <Button
                sx={{
                  borderRadius: '12px',
                  padding: '10px 20px',
                  fontSize: '16px',
                  textTransform: 'none' as const,
                  marginRight: '30px',
                  backgroundColor: '#EAECF0',
                }}
              >
                All time
              </Button>
            </Box>
          </Box>

          <Grid container>
            {dataBox.map((item, index) => (
              <Grid item xs={12} sm={3} md={3} key={index}>
                <Box sx={{ ...boxStyles, backgroundImage: item.background }}>
                  <Typography variant="h5">{item.value}</Typography>
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <ChangeOrganizationNameModal
          companyData={companyData}
          isChangeOrganizationNameModalOpen={isChangeOrganizationNameModalOpen}
          setIsChangeOrganizationNameModalOpen={
            setIsChangeOrganizationNameModalOpen
          }
        />
      </Box>
    </>
  );
};

export default CompanySettings;
