import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { XCircle } from '@phosphor-icons/react';
import { BoundingBox } from '@phosphor-icons/react/dist/ssr';
import React from 'react';
import { useAuthStore } from '../../store/storeAuth';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    // padding: '24px',
    border: 0,
    p: 0,
  },
  '& .MuiDialogActions-root': {
    // padding: '24px',
    border: 0,
    p: 0,
  },
  '& .MuiDialogActions-root .MuiDialogContent-root MuiDialogContent-dividers css-1t4vnk2-MuiDialogContent-root':
    {
      // padding: '24px',
      border: 0,
      p: 0,
    },
}));

type Props = {
  onHide: () => void;
  show: boolean;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  footer?: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  sx?: SxProps<Theme> | undefined;
  showCloseButton?: boolean;
  headerStyles?: SxProps<Theme>;
};

const KitModal = ({
  onHide,
  show,
  children,
  title,
  footer,
  fullWidth = false,
  maxWidth = 'md',
  sx,
  showCloseButton = true,
  headerStyles,
}: Props) => {
  const { user } = useAuthStore(state => state);
  return (
    <BootstrapDialog
      onClose={onHide}
      aria-labelledby="customized-dialog-title"
      open={show}
      maxWidth={maxWidth}
      sx={{
        ...sx,
      }}
      fullWidth={fullWidth}
      scroll="paper"
    >
      <Box
        sx={{
          bgcolor: user?.theme === 'dark' ? '#1D2939' : '#FFF',
        }}
      >
        {title && (
          <DialogTitle
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              top: 3,
              paddingLeft: 3,
              ...headerStyles,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'justify-start',
                gap: '8px',
              }}
            >
              {/* <BoundingBox color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'} size={20} /> */}
              <Typography
                color={user?.theme === 'dark' ? '#D0D5DD' : '#667085'}
              >
                {title}
              </Typography>
            </Box>
          </DialogTitle>
        )}
        {showCloseButton && (
          <IconButton
            aria-label="close"
            size="medium"
            onClick={onHide}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
              zIndex: 100,
            }}
          >
            <XCircle color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'} />
          </IconButton>
        )}
        <DialogContent dividers>{children}</DialogContent>
        {footer && <DialogActions>{footer}</DialogActions>}
      </Box>
    </BootstrapDialog>
  );
};

export default KitModal;
