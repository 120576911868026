import { Node } from 'reactflow';
import { sortNodes } from './sortNodes';
import { nodeDataAutoSaveDynamic } from './autosave/nodedata_autosave';
import { arrayDataToText } from './dataToText';
import { FlowService } from '../service/FlowService';
import { getAPIErrorMessage } from '../helpers/helpers';

export const storeDataForSelectedNodeFc = (
  storeDataForSelectedNode: {
    data: { isDeletingCondition: { index: any } };
    myId: any;
  },
  setNodes: (arg0: (nds: any) => any) => void,
  setEdges: (arg0: (eds: any) => any) => void,
  setNotification: (notification: { type: string; message: string }) => void, // Pass setNotification as a parameter
) => {
  if (storeDataForSelectedNode.data?.isDeletingCondition?.index) {
    setEdges((eds: any[]) => {
      return eds.filter(
        (edge: { targetHandle: string }) =>
          edge?.targetHandle !==
          `conditionHandle-${storeDataForSelectedNode.data?.isDeletingCondition?.index}`,
      );
    });
  }

  const removeIsDeletingCondition = () => {
    if (storeDataForSelectedNode.data?.isDeletingCondition?.index) {
      return {
        ...storeDataForSelectedNode.data,
        isDeletingCondition: {
          index: null,
          isDeleting: false,
        },
      };
    } else {
      return storeDataForSelectedNode.data;
    }
  };

  setNodes((nds: any[]) =>
    nds.map((node: { data: { myId: any } }) => {
      if (node.data.myId === storeDataForSelectedNode.myId) {
        return {
          ...node,
          data: {
            ...node.data,
            ...removeIsDeletingCondition(),
          },
        };
      }
      return node;
    }),
  );
};

export const getNodeId = (nodeType: string) => {
  const newId = `${nodeType}_${Date.now()}_${Math.random().toFixed(4)}`;
  return newId;
};

export const onAddNodeFc = (
  type: {
    type: string;
    label: any;
    myType: any;
    title: any;
    position: { x: number; y: number };
    extent: any;
    parentNode: any;
    data?: any;
  },
  setNodes: (arg0: { (nds: any): any; (nds: any): any }) => void,
  setViewport: (arg0: { x: any; y: any; zoom: any }) => void,
  setEdges: (arg0: (eds: any) => any) => void,
  position: { x: number; y: number; zoom: any },
  configs: any[],
  flowId: string,
  setNotification: (notification: { type: string; message: string }) => void,
) => {
  const myId = getNodeId(type.type);

  const textConfig = configs.find(config => config.type === 'text');
  const voiceConfig = configs.find(config => config.type === 'voice');
  const imageConfig = configs.find(config => config.type === 'image');

  const aiTextConfigs =
    type.type === 'commandTextNode' && textConfig?.id
      ? { AiSystem: textConfig?.modelType, ...textConfig?.aiConfig }
      : {};

  const aiVoiceConfigs =
    type.type === 'voiceBoxNode' && voiceConfig?.id
      ? { voiceSystem: voiceConfig?.modelType, ...voiceConfig?.aiConfig }
      : {};

  const aiImageConfigs =
    type.type === 'imageGenerator' && imageConfig?.id
      ? { aiModels: imageConfig?.modelType, ...imageConfig?.aiConfig }
      : {};

  const aiDynamicSettingsConfigs =
    type.type === 'dynamicSettingsNode' && textConfig?.id && voiceConfig?.id
      ? {
          AiSystem: textConfig?.modelType,
          ...textConfig?.aiConfig,
          voiceSystem: voiceConfig?.modelType,
          ...voiceConfig?.aiConfig,
        }
      : {};

  const aiConfigs =
    type.type === 'commandTextNode'
      ? aiTextConfigs
      : type.type === 'voiceBoxNode'
      ? aiVoiceConfigs
      : type.type === 'imageGenerator'
      ? aiImageConfigs
      : type.type === 'dynamicSettingsNode'
      ? aiDynamicSettingsConfigs
      : {};

  const newNode = {
    id: myId,
    type: type.type,
    position: type.position,
    extent: type?.extent,
    parentNode: type?.parentNode,
  };

  setViewport({
    x: position.x + 100,
    y: position.y + 100,
    zoom: position.zoom,
  });

  const nameChecking = (nds: any[], type: string, nodeType: string) => {
    const filterNodes = nds?.filter(node => node.type === nodeType);

    if (filterNodes.length > 0) {
      const existingLabels = filterNodes.map(node => node.data.label);
      const uniqueLabel = generateUniqueLabel(type, existingLabels);
      return uniqueLabel;
    }

    return type;
  };

  const generateUniqueLabel = (label: string, existingLabels: string[]) => {
    let count = 1;
    let uniqueLabel = label;

    while (existingLabels.includes(uniqueLabel)) {
      uniqueLabel = `${label} ${count}`;
      count++;
    }

    return uniqueLabel;
  };

  setNodes((nds: any[]) => {
    const label = nameChecking(nds, type.label, type.type);

    const newNodeData = {
      isEmptyState: true,
      myType: type.myType,
      title: type.title,
      collapsed: type.type === 'nodesGroup' ? false : undefined,
      ...aiConfigs,
      ...(type?.data || {}),
      myId: myId,
      label: type.label,
    };

    const updatedNodes = nds
      .concat({
        ...newNode,
        data: newNodeData,
      })
      .sort(sortNodes);

    return updatedNodes;
  });

  const addNode = async () => {
    try {
      await FlowService.addNode(flowId, {
        id: myId,
        type: type.type,
        data: {
          myType: type?.myType,
          title: type?.title,
          collapsed: type?.type === 'nodesGroup' ? false : undefined,
          ...aiConfigs,
          ...(type?.data || {}),
          myId: myId,
          label: type.label,
        },
        position: type.position,
        extent: type?.extent,
        parentNode: type?.parentNode,
      });
    } catch (error) {
      // setNotification({
      //   type: 'error',
      //   message: 'Failed to add node ' + getAPIErrorMessage(error as any),
      // });
      console.log(error);
    }
  };

  addNode();
};

export const onConnectFc = (
  setDeleteNode: (arg0: any) => void,
  params: {
    target: string | string[];
    source: string | string[];
    sourceHandle: string;
    targetHandle: string;
  },
  setSchema: (arg0: any) => void,
  setEdges: (arg0: (eds: any) => any) => void,
  edges: any,
  addEdge: (arg0: any, arg1: any) => any,
  coreEdgeStyle: {
    style: any;
    animated: any;
    markerEndCustom: any;
    markerEnd: any;
  },
  setNodes: (arg0: (nds: any) => any) => void,
  nodes: any[],
  flowId: string,
  objectData: any,
  setSaving: (arg0: {
    lastSaved: string;
    status: string;
    finished: boolean;
    flowId: string;
  }) => void,
  setNotification: (notification: { type: string; message: string }) => void,
) => {
  setDeleteNode(null);

  const getCustomEdges = () => {
    if (
      params.sourceHandle === 'outputFlow' ||
      params.sourceHandle === 'a' ||
      params.sourceHandle === 'outputHandle-0' ||
      params.sourceHandle === 'outputHandle-1' ||
      params?.sourceHandle?.includes('conditionHandle') ||
      params.sourceHandle?.includes('outputHandle')
    ) {
      return 'custom';
    } else {
      return '';
    }
  };

  setEdges(eds =>
    addEdge(
      {
        ...params,
        type: getCustomEdges(),
        style: coreEdgeStyle.style,
        animated: coreEdgeStyle.animated,
        markerEnd:
          getCustomEdges() === 'custom'
            ? coreEdgeStyle.markerEndCustom
            : coreEdgeStyle.markerEnd,
      },
      eds,
    ),
  );

  const connectNode = async () => {
    try {
      await FlowService.onConnectEdge(flowId, {
        params,
      });
    } catch (error) {
      console.log(error);
    }
  };

  connectNode();

  const findSourceNode = nodes?.find(node => node.id === params.source);

  nodeDataAutoSaveDynamic({
    setNodeState: setNodes,
    id: findSourceNode?.id,
    setSchema,
    setSaving,
    newEdges: [...edges, params],
    changeType: 'connect',
    objectCallerData: objectData,
    connectData: {
      ...params,
    },
  });
};

export const isNodeOverlapping = (
  node1: { position: any; width: any; height: any },
  node2: { position: any; width: any; height: any },
) => {
  const rect1 = node1?.position;
  const rect2 = node2?.position;

  return (
    rect1?.x < rect2?.x + node2?.width &&
    rect1?.x + node1?.width > rect2?.x &&
    rect1?.y < rect2?.y + node2?.height &&
    rect1?.y + node1?.height > rect2?.y
  );
};

export const checkIfIsFinished = (
  hasFinished: boolean,
  setIsChangingDirectly: (finished: boolean) => void,
) => {
  if (!hasFinished) {
    setIsChangingDirectly(true);
    return false;
  } else if (hasFinished) {
    setIsChangingDirectly(false);

    return true;
  }
};

export const basedOnType = {
  commandTextNode: [
    'prompt',
    'persona',
    'instruction',
    'content',
    'text',
    'modelSettings',
  ],
  dynamicSettingsNode: ['outputModelTextSettings'],
  outputController: ['text'],
  textBox: ['text'],
  richTextBox: ['text'],
  imageGenerator: ['subject', 'environment', 'tone', 'view'],
  voiceBoxNode: ['audioUrl', 'text'],
  imageReaderNode: ['text', 'content', 'question', 'prompt', 'caption'],
  animationNode: ['prompt', 'negativePrompt'],
  commandContentNode: ['text', 'search'],
  documentSummarizer: ['text'],
  webSearch: ['query', 'text'],
  webLocations: ['query', 'text'],
  webMedia: ['query', 'text'],
  webTrends: ['query', 'text'],
  webCrawler: ['query', 'text'],
  webNews: ['query', 'text'],
  webAmazon: ['url'],
  arrayNode: ['content'],
  apiCaller: ['url', 'jsonRequestBody', 'jsonAuth', 'username', 'password'],
  sqlQuerier: [
    'host',
    'port',
    'database',
    'username',
    'password',
    'connectionString',
    'collection',
    'filter',
    'query',
  ],
  audioReaderNode: ['YouTubeUrl'],
  commandMusicNode: ['prompt'],
  webCrawling: ['url', 'technology', 'text'],
  mathFunction: ['formula', 'result'],
  varInputNode: ['text', 'fileUrl'],
  varOutputNode: ['text', 'callbackUrl', 'urls', 'subject'],
};

export const nodeSchema = (nodes: Node[], setSchema: (schema: any) => void) => {
  const mapNodes =
    nodes
      ?.filter(node => node.type !== 'globalStarter')
      .map(node => {
        return {
          ...node?.data,
          type: node?.type,
        };
      }) || [];

  const convertedObject: {
    [x: string]: {
      [x: string]: string;
    };
  } = {};

  mapNodes?.forEach(node => {
    const labelKey = node?.label?.replace?.(/\s/g, '')?.toLowerCase();
    if (!convertedObject[labelKey]) {
      convertedObject[labelKey] = {};
    }

    const keys = basedOnType[node.type as keyof typeof basedOnType];

    if (keys) {
      keys.forEach(key => {
        let check = node[key as keyof typeof node];

        const checkIfNodeData: [] = node[(key + 'Data') as keyof typeof node];

        if (checkIfNodeData?.length > 0) {
          check = arrayDataToText(checkIfNodeData);
        }

        if (!check) {
          convertedObject[labelKey][key] = '';
        }

        if (check) {
          convertedObject[labelKey][key] = check;
        }
      });
    }
  });

  setSchema(convertedObject);
};

export const replacePlaceholders = (
  originalString: string,
  replacementSchema: any,
) => {
  return originalString?.replace?.(/{{(.*?)}}/g, (match, p1) => {
    const keys = p1?.split('.');
    let value = replacementSchema;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return match; // If the key is not found, return the original placeholder
      }
    }
    return value;
  });
};
