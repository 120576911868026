import { Box, FormLabel, Slider } from '@mui/material';
import React from 'react';
import { PrettoSlider } from '../../assets/styles/styles';

interface KitRangerProps {
  value: number;
  onChange: (event: any) => void;
  name: string;
  min: number;
  max: number;
  step: number;
  label?: string;
}

const KitRanger: React.FC<KitRangerProps> = ({
  value,
  onChange,
  name,
  min,
  max,
  step,
  label,
}) => {
  return (
    <Box>
      {label && <FormLabel htmlFor='tokens'>{label}</FormLabel>}
      <Box display={'flex'} alignItems={'center'} gap={'8px'}>
        <FormLabel htmlFor='tokens'>{value}</FormLabel>
        <PrettoSlider
          value={Number(value)}
          onChange={onChange}
          name={name}
          min={min}
          max={max}
          valueLabelDisplay='auto'
          step={step}
          className='nodrag'
          sx={{
            border: 'none',
            maxWidth: '95%',
          }}
        />{' '}
      </Box>
    </Box>
  );
};

export default KitRanger;
