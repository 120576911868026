import React from 'react';
import './SelectPlanModal.css';
import KitModal from '../UI/KitModal';
import SelectPlanImage from '../../assets/images/SelectPlan.png';
import { Button } from '@mui/material';

interface Props {
  onClose: () => void;
  plan: string;
  openPricingModal: () => void;
  show: boolean;
}

const SelectPlanModal: React.FC<Props> = ({
  plan,
  onClose,
  openPricingModal,
  show,
}) => {
  return (
    <KitModal show={show} onHide={onClose}>
      <div className={'select-plan-modal-container'}>
        <img src={SelectPlanImage} alt='Select Plan' />

        <h3 className={'select-plan-text'}>Your {plan} period has ended.</h3>
        <p className={'select-plan-description'}>
          Thank you for trying out FluxPrompt!
        </p>
        <p className={'select-plan-description'}>
          Please select a plan to unlock full access.
        </p>

        <div className={'select-plan-actions-container'}>
          <Button type='button' variant='outlined' onClick={onClose}>
            Cancel
          </Button>

          <Button type='button' variant='contained' onClick={openPricingModal}>
            Select Plan
          </Button>
        </div>
      </div>
    </KitModal>
  );
};

export default SelectPlanModal;
