/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PushGoogleSheetsData from '../GoogleSheetsIntegration/PushGoogleSheetsData';
import { Box, Button, MenuItem, Select, Tooltip } from '@mui/material';
import PushGoogleDocsData from '../GoogleDocsIntegration/PushGoogleDocsData';
import Node from '../UI/Node/Node';
import NoConnection from '../PullDataNode/NoConnection/NoConnection';
import TagInput from '../Test/TagInput';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { Position, useEdges } from 'reactflow';
import useFlowsStore from '../../store/storeFlows';
import Label from '../UI/Label/Label';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { arrayDataToText } from '../../util/dataToText';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { Info } from '@phosphor-icons/react';
import { useAuthStore } from '../../store/storeAuth';
import { LocalStorageService } from '../../service/LocalStorageService';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { FlowService } from '../../service/FlowService';
import {
  GoogleSpreadsheet,
  GoogleSpreadsheetWorksheet,
} from 'google-spreadsheet';
import { useStoreGoogleData } from '../../store/storeGoogleData';
import { AuthService } from '../../service/AuthService';
import {
  getSpreadsheets,
  isGoogleSheetsAccessTokenValid,
} from '../GoogleSheetsIntegration/helpers';
import {
  appendDataToEndOfDoc,
  createNewDoc,
  getDocContent,
  getDocs,
  prependDataToBeginningOfDoc,
  sendDataAsNewPageAfterSpecifiedPage,
  sendDataToBeginningOfDocAsNewPage,
  sendDataToEndOfDocAsNewPage,
} from '../GoogleDocsIntegration/helpers';
import InputHandle from '../UI/InputHandle/InputHandle';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { getGoogleDriveFolders } from '../FileSave/helpers';
import SearchableSelect from '../SearchableSelect';
import { mt } from 'date-fns/locale';

enum GoogleService {
  GoogleSheets = 'Google Sheets',
  GoogleDocs = 'Google Docs',
  RawFiles = 'Raw Files',
}

interface SpreadSheet {
  id: string;
  name: string;
}
export type Doc = {
  id: string;
  name: string;
};

export enum PushDataOption {
  BEGINNING_OF_DOC = 'Beginning of document',
  END_OF_DOC = 'End of document',
  BEGINNING_OF_DOC_AS_A_NEW_PAGE = 'Start of document as a new page',
  END_OF_DOC_AS_A_NEW_PAGE = 'End of document as a new page',
  NEW_PAGE_AFTER_SPECIFIED_PAGE = 'After a specific page',
}

const PushData: React.FC<{ id: string; type: string; data: any }> = ({
  id,
  data,
  type,
}) => {
  const [selectedWorkSheet, setSelectedWorkSheet] = useState<
    undefined | GoogleSpreadsheetWorksheet
  >(undefined);
  const [selectedSpreadSheet, setSelectedSpreadSheet] = useState<
    undefined | SpreadSheet
  >(data?.spreadsheet ? { id: data?.spreadsheet, name: '' } : undefined);
  const [selectedDoc, setSelectedDoc] = useState<undefined | Doc>(
    data?.docs ? { id: data?.docs, name: '' } : undefined,
  );
  const [selectedPublicGoogleService, setSelectedPublicGoogleService] =
    useState(GoogleService.GoogleSheets);
  const [workSheetsLoaded, setWorkSheetsLoaded] = useState(false);
  const [publicRangeFilter, setPublicRangeFilter] = useState('');
  const [spreadSheets, setSpreadSheets] = useState<any[]>([]);
  const [workSheets, setWorkSheets] = useState<any[]>([]);
  const [folderOptions, setFolderOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [docs, setDocs] = useState<any[]>([]);

  const setNotification = useNotificationStore(state => state.setNotification);
  const [formData, setFormData] = useState({
    text: data.text || '',
    textContent: data.textContent || '',
    selectedGoogleService:
      data.selectedGoogleService || GoogleService.GoogleSheets,
    spreadsheet: data.spreadsheet || 'none',
    sheets: data.sheets !== undefined ? data.sheets : 'new',
    name: data.name || '',
    selectedPushDataOption:
      data.selectedPushDataOption || PushDataOption.END_OF_DOC,
    specifiedPage: data.specifiedPage || '',
    docs: data.docs || '',
    newDocumentName: data.newDocumentName || '',
    fileUrl: data.fileUrl || '',
  });

  const { setNodeState, setSaving, setSchema, flowId } = useFlowsStore(
    state => state,
  );

  const edges = useEdges();

  const authUser = useAuthStore(state => state);

  const [isDisabled, setIsDisabled] = useState(true);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasToExecute, setHasToExecute] = useState(true);

  const setValue = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const debounceTimeoutRef = useRef<any>(null);

  const {
    isRefreshing,
    setIsRefreshing,
    googleAccessToken,
    googleRefreshToken,
    setGoogleAccessToken,
    setGoogleRefreshToken,
    setIsSheetConnected,
    isSheetConnected,
  } = useStoreGoogleData();
  ///////// Sheet Context Actions //////////////////////////////////////////////////////////////////////////

  const disconnectHandlerGoogle = async () => {
    if (!googleAccessToken) {
      setNotification({
        type: NotificationType.Error,
        message: 'Session expired. Please login again.',
      });
      return;
    }

    await refreshAuthToken(googleRefreshToken as string);
  };

  const loadWorkSheets = useCallback(
    async (spreadSheetId: string) => {
      setWorkSheetsLoaded(true);
      try {
        const doc = new GoogleSpreadsheet(spreadSheetId, {
          token: googleAccessToken!,
        });

        await doc.loadInfo();

        const workSheets: GoogleSpreadsheetWorksheet[] = doc.sheetsByIndex;
        setWorkSheets(workSheets);
        setWorkSheetsLoaded(true);

        return workSheets;
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          setNotification({
            type: NotificationType.Error,
            message: getAPIErrorMessage(error),
          });
        }
      } finally {
        setWorkSheetsLoaded(false);
      }
    },
    [googleAccessToken, workSheets],
  );

  const refreshAuthToken = async (refreshToken: string) => {
    try {
      const newTokens = await AuthService.googleRefreshToken({
        refresh_token: refreshToken,
      });

      setGoogleAccessToken(newTokens.data.access_token);
      setGoogleRefreshToken(newTokens.data.refresh_token);
      setIsSheetConnected(true);

      const docs = await getSpreadsheets(newTokens.data.access_token);
      setSpreadSheets(docs);
    } catch (refreshError) {}
  };

  const getSpreadSheetsHandler = useCallback(async () => {
    if (!googleAccessToken) {
      return disconnectHandlerGoogle();
    }

    try {
      const spreadSheets = await getSpreadsheets(googleAccessToken);
      setSpreadSheets(spreadSheets);

      if (data?.spreadsheet && spreadSheets?.length) {
        initialSpreadSheetSelection({
          spreadsheet: data?.spreadsheet,
          sheets: data?.sheets,
          spreadSheets,
        });
      }
    } catch (error) {
      let anyError = error as any;

      if (anyError.response.status === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
  }, [googleAccessToken, data?.spreadsheet, data?.sheets]);

  const initialSpreadSheetSelection = async ({
    spreadsheet,
    sheets,
    spreadSheets,
  }: any) => {
    const selectedSpreadSheet = spreadSheets.find(
      ({ id }: any) => id === spreadsheet,
    );
    try {
      if (selectedSpreadSheet !== undefined && selectedSpreadSheet !== null) {
        setSelectedSpreadSheet(selectedSpreadSheet);
        const workSheets = await loadWorkSheets(spreadsheet);

        if (sheets !== undefined && sheets !== null) {
          await updateSelectedWorkSheet(sheets, workSheets || []);
        } else {
          setSelectedWorkSheet(undefined);
        }
      } else {
        setSelectedSpreadSheet(undefined);
        setSelectedWorkSheet(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshSpreadSheets = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await getSpreadSheetsHandler();
      if (selectedSpreadSheet) {
        await loadWorkSheets(selectedSpreadSheet.id);
      }
    } catch (error) {
      let anyError = error as any;
      if (anyError.response.status === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
    setIsRefreshing(false);
  }, [selectedSpreadSheet]);

  const updateSelectedSpreadSheet = useCallback(
    (selectedSpreadSheetId: string) => {
      const selectedSpreadSheet = spreadSheets.find(
        ({ id }) => id === selectedSpreadSheetId,
      );

      if (selectedSpreadSheet) {
        setSelectedSpreadSheet(selectedSpreadSheet);
        loadWorkSheets(selectedSpreadSheetId);

        setSelectedWorkSheet(undefined);
      } else {
        setSelectedSpreadSheet(undefined);
        setSelectedWorkSheet(undefined);
      }
    },
    [spreadSheets],
  );

  const updateSelectedWorkSheet = useCallback(
    async (
      selectedWorkSheetId: number,
      newWorkSheets?: GoogleSpreadsheetWorksheet[],
    ) => {
      if (selectedWorkSheetId === -1) {
        setSelectedWorkSheet(undefined);
        setWorkSheets([]);

        return;
      }

      setWorkSheetsLoaded(true);
      try {
        const initalWorksheets = newWorkSheets?.length
          ? newWorkSheets
          : workSheets;

        const selectedWorkSheet = initalWorksheets.find(
          ({ sheetId }) => sheetId === selectedWorkSheetId,
        );

        if (selectedWorkSheet) {
          setSelectedWorkSheet(selectedWorkSheet);

          if (formData.selectedGoogleService === GoogleService.GoogleSheets) {
            const currentWorkSheetContent = await getWorkSheetData(
              '',
              selectedWorkSheet,
            );

            if (data?.text !== currentWorkSheetContent) {
              setWhoIsChanging({
                name: 'text',
                value: currentWorkSheetContent,
              });
              setIsChangingDirectly(true);
            }
          }
          // await selectedWorkSheet.loadCells();
        } else {
          setSelectedWorkSheet(undefined);
        }
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          setNotification({
            type: NotificationType.Error,
            message: getAPIErrorMessage(error),
          });
        }
      } finally {
        setWorkSheetsLoaded(false);
      }
    },

    [workSheets, formData.selectedGoogleService, data?.text],
  );

  const getWorkSheetData = useCallback(
    async (rangeFilter: string, initalSheet?: any): Promise<string> => {
      if (!selectedWorkSheet && !initalSheet)
        return 'You have not selected a worksheet';

      let selectedWorkSheetNew = initalSheet || selectedWorkSheet;

      let startRow = 0;
      let endRow = selectedWorkSheetNew?.rowCount! - 1;
      let startCol = 0;
      let endCol = selectedWorkSheetNew!.columnCount - 1;

      if (rangeFilter !== '') {
        let match;

        match = rangeFilter.match(/^([A-Z]+)(\d+):([A-Z]+)(\d+)$/);
        if (match) {
          const [, startColStr, startRowStr, endColStr, endRowStr] = match;
          startCol = startColStr.charCodeAt(0) - 65;
          startRow = parseInt(startRowStr, 10) - 1;
          endCol = endColStr.charCodeAt(0) - 65;
          endRow = parseInt(endRowStr, 10) - 1;
        } else {
          match = rangeFilter.match(/^([A-Z]+):([A-Z]+)$/);
          if (match) {
            const [, startColStr, endColStr] = match;
            startCol = startColStr.charCodeAt(0) - 65;
            endCol = endColStr.charCodeAt(0) - 65;
          } else {
            match = rangeFilter.match(/^(\d+):(\d+)$/);
            if (match) {
              const [, startRowStr, endRowStr] = match;
              startRow = parseInt(startRowStr, 10) - 1;
              endRow = parseInt(endRowStr, 10) - 1;
            } else {
              return 'Invalid range format. Please provide the range in the format B1:E5, A:A, or 1:1';
            }
          }
        }
      }

      await selectedWorkSheetNew.loadCells();

      let textData: string = '';

      for (let row = startRow; row <= endRow; row++) {
        let rowData: string[] = [];

        let numberOfColumnsWithValuePerRow = 0;
        for (let col = startCol; col <= endCol; col++) {
          const cell = selectedWorkSheetNew.getCell(row, col);

          const cellValue = cell.value === null ? '' : cell.value;

          if (cellValue !== '') {
            numberOfColumnsWithValuePerRow++;
          }
          rowData.push(cellValue as string);
        }

        if (numberOfColumnsWithValuePerRow !== 0) {
          textData += rowData.join(',') + '\n';
        }
      }

      return textData;
    },
    [selectedWorkSheet],
  );

  const hasWorkSheetValuesInFirstRow = useCallback(
    async (sheet: GoogleSpreadsheetWorksheet) => {
      await sheet.loadCells();

      const cells = await sheet.getCellsInRange('A1:Z1');

      if (!cells) return false;

      for (let cell of cells) {
        if (cell || cell.value) return true;
      }

      return false;
    },
    [],
  );

  const pushData = useCallback(
    async (sheet: GoogleSpreadsheetWorksheet, text: string) => {
      // Custom function to parse CSV text
      const parseCSV = (input: string) => {
        const rows: string[][] = [];
        let currentRow: string[] = [];
        let currentValue = '';
        let insideQuotes = false;

        for (let i = 0; i < input.length; i++) {
          const char = input[i];
          const nextChar = input[i + 1];

          if (char === '"' && insideQuotes && nextChar === '"') {
            // Handle escaped quotes by adding a single quote
            currentValue += '"';
            i++; // Skip the next quote
          } else if (char === '"') {
            // Toggle the insideQuotes flag when encountering a quote
            insideQuotes = !insideQuotes;
          } else if (char === ',' && !insideQuotes) {
            // If not inside quotes, a comma indicates a new value
            currentRow.push(currentValue.trim());
            currentValue = '';
          } else if (char === '\n' && !insideQuotes) {
            // If not inside quotes, a new line indicates a new row
            currentRow.push(currentValue.trim());
            rows.push(currentRow);
            currentRow = [];
            currentValue = '';
          } else {
            // Regular character, add it to the current value
            currentValue += char;
          }
        }

        // Push the last value and row if there's any data left
        if (currentValue || currentRow.length) {
          currentRow.push(currentValue.trim());
          rows.push(currentRow);
        }

        return rows;
      };

      try {
        // Use the custom parser to split and parse the CSV text
        const textArray = parseCSV(text);

        const hasValuesInFirstRow = await hasWorkSheetValuesInFirstRow(sheet);

        if (hasValuesInFirstRow) {
          await sheet.addRows(textArray);
          setWorkSheets([...workSheets, sheet]);
        } else {
          await sheet.setHeaderRow([...textArray[0]]);
          await sheet.addRows([...textArray.slice(1)]);
          setWorkSheets([...workSheets, sheet]);
        }
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          throw error;
        }
      }
    },
    [hasWorkSheetValuesInFirstRow],
  );

  const pushDataToSelectedWorkSheet = useCallback(
    async (text: string) => {
      if (!selectedSpreadSheet?.id || !selectedWorkSheet)
        return setNotification({
          type: NotificationType.Error,
          message: 'You need to select spreadsheet and worksheet',
        });

      try {
        const doc = new GoogleSpreadsheet(selectedSpreadSheet!.id, {
          token: googleAccessToken!,
        });
        await doc.loadInfo();

        await pushData(selectedWorkSheet, text);
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          throw error;
        }
      }
    },
    [selectedSpreadSheet, selectedWorkSheet, googleAccessToken, pushData],
  );

  const pushDataHandler = useCallback(
    (text: string) => {
      return pushDataToSelectedWorkSheet(text);
    },
    [pushDataToSelectedWorkSheet],
  );

  const pushDataToNewSheetHandler = useCallback(
    async (sheetName: string, text: string) => {
      try {
        const doc = new GoogleSpreadsheet(selectedSpreadSheet!.id, {
          token: googleAccessToken!,
        });
        await doc.loadInfo();

        const newSheet = await doc.addSheet({ title: sheetName });

        await pushData(newSheet, text);
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          throw error;
        }
      }
    },
    [selectedSpreadSheet, googleAccessToken, pushData, data?.sheetNameData],
  );

  const updatePublicWorkSheets = async (publicSheetId: string) => {
    try {
      setWorkSheets([]);
      const doc = new GoogleSpreadsheet(publicSheetId, {
        apiKey: 'AIzaSyA5ruxonya48UJ6XuhmNcC7x82gmvESeLU',
      });

      await doc.loadInfo();

      const workSheets: GoogleSpreadsheetWorksheet[] = doc.sheetsByIndex;

      setWorkSheets(workSheets);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const loadSpreadSheetsInfo = useCallback(async () => {
    try {
      await Promise.all([getSpreadSheetsHandler()]);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error),
      });
    }
  }, [googleAccessToken]);

  useEffect(() => {
    if (googleAccessToken) {
      setGoogleAccessToken(googleAccessToken);
      setIsSheetConnected(true);
    } else {
      setGoogleAccessToken(null);
      setIsSheetConnected(false);
    }
  }, [googleAccessToken]);

  useEffect(() => {
    const validateExistingGoogleSheetsAccessToken = async () => {
      const googleSheetsAccessToken = LocalStorageService.getItem(
        'googleSheetsAccessToken',
      );

      const refreshToken = LocalStorageService.getItem('googleRefreshToken');

      if (
        googleSheetsAccessToken &&
        (await isGoogleSheetsAccessTokenValid(googleSheetsAccessToken))
      ) {
        setGoogleAccessToken(googleSheetsAccessToken);
        setIsSheetConnected(true);
      } else if (refreshToken) {
        await refreshAuthToken(refreshToken);
      }
    };

    validateExistingGoogleSheetsAccessToken();
  }, []);

  ////////////////// Doc Context Actions /////////////////////////////////////////////////////
  const getDocsHandler = useCallback(async () => {
    if (!googleAccessToken) {
      return disconnectHandlerGoogle();
    }

    try {
      const docs = await getDocs(googleAccessToken, '');
      setDocs(docs);
    } catch (error) {
      let anyError = error as any;
      if (anyError.response.status === 401) {
        if (!googleRefreshToken) {
          setNotification({
            type: NotificationType.Error,
            message: 'Session expired. Please login again.',
          });
          return;
        }

        await refreshAuthToken(googleRefreshToken);
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
  }, [googleAccessToken, selectedDoc]);

  const refreshDocsHandler = async () => {
    setIsRefreshing(true);
    try {
      await getDocsHandler();
    } catch (error) {
      let anyError = error as any;
      if (anyError.response.status === 401) {
        disconnectHandlerGoogle();
        if (!googleRefreshToken) {
          setNotification({
            type: NotificationType.Error,
            message: 'Session expired. Please login again.',
          });
          return;
        }

        await refreshAuthToken(googleRefreshToken);
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error),
      });
    }
    setIsRefreshing(false);
  };

  useEffect(() => {
    if (isSheetConnected && googleAccessToken) {
      loadSpreadSheetsInfo();
      getDocsHandler();
    }
  }, [isSheetConnected, googleAccessToken]);

  const getFullContent = async (newDocId?: string) => {
    if (!googleAccessToken || (!selectedDoc && !newDocId))
      throw new Error('You have not selected a doc!');

    const docId = newDocId || selectedDoc?.id;

    try {
      const fullContent = await getDocContent(googleAccessToken, docId!);

      return fullContent;
    } catch (error) {
      let anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
      throw error;
    }
  };

  const updateSelectedDoc = useCallback(
    async (selectedDocId: string) => {
      const selectedDoc = docs.find(({ id }) => id === selectedDocId);

      if (selectedDoc) {
        setSelectedDoc(selectedDoc);

        let text = '';

        if (formData.selectedGoogleService === GoogleService.GoogleDocs) {
          text = await getFullContent(selectedDocId);
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            setSaving,
            flowTriggerData: checkExecuteTrigger(data, id),
            changeType: 'execute',
            objectData: {
              text: text,
            },
            setSchema,
          });
        }
      } else {
        setSelectedDoc(undefined);
      }
    },
    [docs, formData],
  );

  const pushDocDataHandler = async (
    pushDataOption: PushDataOption,
    content: string,
    page?: number,
  ) => {
    if (!googleAccessToken) throw new Error('You are not connected to Google!');
    if (!selectedDoc) throw new Error('You have not selected a document!');

    try {
      if (pushDataOption === PushDataOption.BEGINNING_OF_DOC) {
        await prependDataToBeginningOfDoc(
          googleAccessToken,
          selectedDoc.id,
          content + '\n',
        );
      } else if (pushDataOption === PushDataOption.END_OF_DOC) {
        await appendDataToEndOfDoc(
          googleAccessToken,
          selectedDoc.id,
          '\n' + content,
        );
      } else if (pushDataOption === PushDataOption.END_OF_DOC_AS_A_NEW_PAGE) {
        await sendDataToEndOfDocAsNewPage(
          googleAccessToken,
          selectedDoc.id,
          content,
        );
      } else if (
        pushDataOption === PushDataOption.BEGINNING_OF_DOC_AS_A_NEW_PAGE
      ) {
        await sendDataToBeginningOfDocAsNewPage(
          googleAccessToken,
          selectedDoc.id,
          content,
        );
      } else if (
        pushDataOption === PushDataOption.NEW_PAGE_AFTER_SPECIFIED_PAGE
      ) {
        if (!page || isNaN(page) || +page < 0)
          throw new Error('Page must be specified!');

        await sendDataAsNewPageAfterSpecifiedPage(
          googleAccessToken,
          selectedDoc.id,
          content,
          page,
        );
      }
    } catch (error) {
      let anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      }

      throw error;
    }
  };

  const createAndPushDataToNewDoc = async (
    title: string,
    content: string,
    pushDataOption: PushDataOption,
    page?: number,
  ) => {
    if (!googleAccessToken) throw new Error('You are not connected to Google!');

    try {
      const newDoc = await createNewDoc(
        googleAccessToken,
        title,
        data.selectedFolderDoc,
      );

      if (pushDataOption === PushDataOption.BEGINNING_OF_DOC) {
        await prependDataToBeginningOfDoc(
          googleAccessToken,
          newDoc.documentId,
          content + '\n',
        );
      } else if (pushDataOption === PushDataOption.END_OF_DOC) {
        await appendDataToEndOfDoc(
          googleAccessToken,
          newDoc.documentId,
          '\n' + content,
        );
      } else if (pushDataOption === PushDataOption.END_OF_DOC_AS_A_NEW_PAGE) {
        await sendDataToEndOfDocAsNewPage(
          googleAccessToken,
          newDoc.documentId,
          content,
        );
      } else if (
        pushDataOption === PushDataOption.BEGINNING_OF_DOC_AS_A_NEW_PAGE
      ) {
        await sendDataToBeginningOfDocAsNewPage(
          googleAccessToken,
          newDoc.documentId,
          content,
        );
      } else if (
        pushDataOption === PushDataOption.NEW_PAGE_AFTER_SPECIFIED_PAGE
      ) {
        if (!page || isNaN(page) || +page < 0)
          throw new Error('Page must be specified!');

        await sendDataAsNewPageAfterSpecifiedPage(
          googleAccessToken,
          newDoc.documentId,
          content,
          page,
        );
      }

      await getDocsHandler();

      setSelectedDoc({ id: newDoc.documentId, name: title });
    } catch (error) {
      let anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      }

      throw error;
    }
  };
  const updateSelectedPublicDoc = async (publicDocId: string) => {
    setSelectedDoc({ id: publicDocId, name: '' });
  };

  useEffect(() => {
    const updateDisabledAuth = async () => {
      if (!authUser?.user) return;

      try {
        const { data } = await FlowService.getFlow(flowId);
        const { creatorId, spaceId } = data;

        if (!spaceId) {
          return setIsDisabled(false);
        }

        if (authUser.user.id !== creatorId) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    updateDisabledAuth();
  }, [authUser, flowId]);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit().then(() => {
            loadWorkSheets(selectedSpreadSheet?.id as string);
          });
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [hasToExecute, formData, data]);

  useEffect(() => {
    const fetchFolders = async () => {
      const folders = await getGoogleDriveFolders(
        googleAccessToken as string,
        'all',
      );
      setFolderOptions(folders);
    };

    fetchFolders();
  }, [googleAccessToken]);

  const maxNumber = Math.max(
    ...workSheets?.map(item => {
      const match = item?.title?.match(/\((\d+)\)/);
      return match ? parseInt(match[1], 10) : 0;
    }),
  );

  const isExistingName = workSheets.find(item => item.title === formData.name);

  const isExistingArrToTextName = workSheets?.find(
    item => item.title === arrayDataToText(data?.sheetNameData),
  );

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      if (!selectedSpreadSheet || !selectedWorkSheet || !selectedDoc) {
        updateSelectedDoc(formData.docs);
        updateSelectedSpreadSheet(formData.spreadsheet);
        updateSelectedWorkSheet(formData.sheets as any);
      }
      let text = formData.textContent;

      if (data?.textContentData?.length > 0) {
        text = arrayDataToText(data.textContentData);
      }

      if (formData.selectedGoogleService === GoogleService.GoogleSheets) {
        if (formData.sheets === 'new') {
          let name = formData.name;

          if (!!isExistingName) {
            name = formData.name + `(${maxNumber + 1})`;
          }

          if (data?.sheetNameData?.length > 0) {
            if (!!isExistingArrToTextName) {
              name = arrayDataToText(data.sheetNameData) + `(${maxNumber + 1})`;
            } else {
              name = arrayDataToText(data.sheetNameData);
            }
          }

          await pushDataToNewSheetHandler(name, text);
        } else {
          await pushDataHandler(text);
        }
      }
      if (formData.selectedGoogleService === GoogleService.GoogleDocs) {
        if (!data.docs) {
          const googleDocName = formData.newDocumentName;
          if (!googleDocName)
            return setNotification({
              type: NotificationType.Error,
              message: 'Document Name is required or select a document',
            });
          await createAndPushDataToNewDoc(
            googleDocName,
            text,
            PushDataOption.BEGINNING_OF_DOC,
            parseInt(formData.specifiedPage),
          );
        } else {
          await pushDocDataHandler(
            formData.selectedPushDataOption,
            text,
            parseInt(formData.specifiedPage),
          );
        }
      }
      if (formData.selectedGoogleService === GoogleService.RawFiles) {
        if (!data.fileUrlData || !data.fileUrl) {
          const fileName = 'Raw File' + Date.now();
          if (!fileName)
            return setNotification({
              type: NotificationType.Error,
              message: 'Document Name is required or select a document',
            });
          await createAndPushDataToNewDoc(
            fileName,
            data.fileUrlData,
            PushDataOption.BEGINNING_OF_DOC,
          );
        }
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowId,
        setSaving,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        objectData: {},
        setSchema,
      });
    } catch (error) {
      let anyError = error as any;
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error),
      });

      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
    }
  };

  const onChange = (e: { target: { name: string; value: any } }) => {
    setValue(e.target.name, e.target.value);
    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
    });
    setIsChangingDirectly(true);
  };

  const onChangeSelect = (e: { target: { name: string; value: any } }) => {
    setValue(e.target.name, e.target.value);
    let object = {};

    if (e.target.name === 'spreadsheet') {
      object = {
        spreadsheet: e.target.value,
        sheets: 'new',
      };
    } else if (e.target.name === 'sheets') {
      object = {
        spreadsheet: selectedSpreadSheet?.id,
        sheets: e.target.value,
      };
    } else if (e.target.name === 'docs') {
      object = {
        selectedDoc: e.target.value,
      };
    } else if (e.target.name === 'folders') {
      object = {
        selectedFolderDoc: e.target.value,
      };
    }

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        [e.target.name]: e.target.value,
        ...object,
      },
      setSchema,
    });
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const refreshBasedOnService = () => {
    if (formData.selectedGoogleService === GoogleService.GoogleSheets) {
      refreshSpreadSheets();
    }
    if (formData.selectedGoogleService === GoogleService.GoogleDocs) {
      refreshDocsHandler();
    }
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  return (
    <Node
      edges={edges}
      btnText="Push Data"
      onSubmit={onSubmit}
      data={data}
      type={type}
      id={id}
      isLoading={isLoading}
      showTokensUsed={false}
    >
      {formData.sheets === 'new' &&
        formData.selectedGoogleService !== GoogleService.RawFiles && (
          <InputHandle
            position={Position.Left}
            type="target"
            left={-10}
            isActive={isActiveEdge(edges, data?.myId, 'sheetName', 'target')}
            activeColor={findColor?.color}
            top={'92.5%'}
            handleId={'sheetName'}
          />
        )}
      <Box sx={{ cursor: isDisabled ? 'not-allowed' : 'auto' }}>
        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
            sx={{
              backgroundColor: isDisabled
                ? 'rgba(250, 250, 250, 0.85)'
                : 'white',
              pointerEvents: isDisabled ? 'none' : 'auto',
              cursor: isDisabled ? 'not-allowed' : 'default',
              boxShadow: isDisabled ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
              transition: 'all 0.3s ease',
            }}
          >
            <NoConnection
              data={{
                ...data,
                setSelectedPublicGoogleService,
                selectedPublicGoogleService,
                updateSelectedWorkSheet,
                updatePublicWorkSheets,
                selectedWorkSheet,
                publicRangeFilter,
                setPublicRangeFilter,
                updateSelectedPublicDoc,
              }}
              connection={isSheetConnected}
              nodeType="pushData"
            />
            {isSheetConnected && (
              <>
                {formData.selectedGoogleService === GoogleService.RawFiles ? (
                  <TagInput
                    key={id}
                    placeholder={'Url'}
                    onChange={onChange}
                    value={formData.fileUrl}
                    dataConnected={data?.fileUrlData || []}
                    isAdvanced={true}
                    labelName="File Input"
                    findColor={findColor}
                    isCollapsed={false}
                    handleId={'fileUrl'}
                    nodeId={id}
                    isActive={isActiveEdge(edges, id, 'fileUrl', 'target')}
                    advancedCollapsed={true}
                    name="fileUrl"
                    fullWidth
                    InputProps={{
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    labelStyle={{
                      marginTop: '16px',
                    }}
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                    handleSettings={{ top: '50.5px' }}
                  />
                ) : (
                  <TagInput
                    labelName="Data"
                    handleId={'textContent'}
                    name="textContent"
                    dataConnected={data?.textContentData || []}
                    value={formData?.textContent}
                    onChange={onChange}
                    isActive={isActiveEdge(edges, id, 'textContent', 'target')}
                    isAdvanced={true}
                    findColor={findColor}
                    advancedCollapsed={true}
                    isCollapsed={true}
                    height={'320px'}
                    maxHeight={320}
                    maxGrowHeight={320}
                    nodeId={id}
                    removeIcons={true}
                    nodeLabel={data?.label}
                    placeholder={'Connect data into Google Docs or Sheets'}
                  />
                )}
              </>
            )}
            {isSheetConnected && (
              <Box display={'flex'} gap={4}>
                <Label labelName="Select where to push data to" isAdvanced />
                {isDisabled && (
                  <Button sx={{ marginBottom: '8px' }}>
                    No Editing Permission
                  </Button>
                )}
              </Box>
            )}
            <Label
              labelName="Data Type"
              isAdvanced
              labelStyle={{
                fontSize: '14px',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                fontWeight: 400,
              }}
            />
            <Select
              className="nodrag"
              name="selectedGoogleService"
              value={formData.selectedGoogleService}
              onChange={onChangeSelect}
              sx={{
                '& .MuiList-root': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                },
                '& .MuiSvgIcon-root': {
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
                borderRadius: '8px',
                border: 'none',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                    },
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                },
              }}
            >
              {Object.values(GoogleService).map(googleService => (
                <MenuItem
                  key={googleService}
                  value={googleService}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  {googleService}
                </MenuItem>
              ))}
            </Select>{' '}
            {!data.selectedDoc && (
              <Box mt={'8px'}>
                <Label
                  labelName="Folder"
                  isAdvanced
                  labelStyle={{
                    fontSize: '14px',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    fontWeight: 400,
                  }}
                />
                <SearchableSelect
                  options={folderOptions}
                  label="Select Document Folder"
                  onChange={value => {
                    if (value?.value === data?.selectedFolderDoc) return;
                    const selectedValue = value ? value.value : '';

                    onChangeSelect({
                      target: { name: 'folders', value: selectedValue },
                    });
                  }}
                  value={
                    folderOptions.find(
                      option => option?.value === data?.selectedFolderDoc,
                    ) || null
                  }
                  className="nodrag"
                  style={{ width: 100, marginBottom: '20px' }}
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                />
              </Box>
            )}
            {formData.selectedGoogleService === GoogleService.GoogleSheets ? (
              <PushGoogleSheetsData
                formData={formData}
                onChangeSelect={onChangeSelect}
                onChange={onChange}
                spreadSheets={spreadSheets}
                workSheets={workSheets}
                id={id}
                data={{
                  ...data,
                  updateSelectedSpreadSheet,
                  updateSelectedWorkSheet,
                  workSheetsLoaded,
                  selectedSpreadSheet,
                  selectedWorkSheet,
                }}
                type={type}
              />
            ) : formData.selectedGoogleService === GoogleService.GoogleDocs ? (
              <PushGoogleDocsData
                formData={formData}
                onChangeSelect={onChangeSelect}
                onChange={onChange}
                id={id}
                data={{
                  ...data,
                  updateSelectedDoc,
                  selectedDoc,
                  docsData: docs || [],
                }}
                type={type}
              />
            ) : (
              <></>
            )}
          </Box>

          <Box
            p={'16px 24px 16px 16px'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box p={'16px 24px 16px 0'} sx={{ height: '100%' }}>
              <OutputTextarea
                previewIndex={data.previewIndex}
                activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                value={data?.text}
                onChangeSelect
                labelName={'Current Content'}
                placement={data?.placement}
                nodeLabel={data?.label}
                clearOnExecution={data.clearOnExecution || false}
                hasClearOnExecute={true}
                findColor={findColor}
                style={{ marginRight: '-24px', paddingRight: '24px' }}
              />
            </Box>
            {isSheetConnected && !isRefreshing ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '20px',
                }}
              >
                <Tooltip
                  title="Files are up to date"
                  arrow
                  placement="bottom"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#80C683',
                        color: 'white',
                      },
                    },
                    arrow: {
                      sx: {
                        color: '#80C683',
                      },
                    },
                  }}
                >
                  <Button onClick={refreshBasedOnService}>
                    Refresh Google
                  </Button>
                </Tooltip>
                <IconButtonTooltip
                  title={`
                  The data to pull can be from Google Sheets or Google Docs. New Documents or Spreadsheets can be added by refreshing the Google connection. 
                  `}
                >
                  <Info size={16} />
                </IconButtonTooltip>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginTop: '20px',
                  height: '29px',
                }}
              ></Box>
            )}
          </Box>
        </Box>
      </Box>
    </Node>
  );
};

export default PushData;
