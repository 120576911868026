import React, { useEffect, useState } from 'react';
import KitModal from '../../../components/UI/KitModal';
import { Box, Button, CircularProgress } from '@mui/material';
import { FluxService } from '../../../service/FluxService';
import NodesPreview from './NodesPreview';
import { io } from 'socket.io-client';
import { useNotificationStore } from '../../../store/storeNotifications';

type Props = {
  show: boolean;
  onHide: () => void;
  nodes: any;
  form: any;
  flow: any;
  flows: any[];
};

const PreviewModal = ({ show, onHide, nodes, flow, form, flows }: Props) => {
  const [inputs, setInputs] = useState<any>([]);
  const [outputs, setOutputs] = useState<any>([]);
  const [formLoading, setFormLoading] = useState(false);
  const [socketClient, setSocketClient] = useState<any>(null);
  const setNotification = useNotificationStore(state => state.setNotification);

  useEffect(() => {
    const flowInputs = flows
      ?.map((flow: any) => {
        const getFlowInputs = flow?.nodes
          .filter((node: any) => node.type === 'varInputNode')
          ?.map((node: any) => ({
            inputId: node?.id,
            inputText: '',
          }));
        return getFlowInputs;
      })
      .flat();

    const flowOutputs = flows
      ?.map((flow: any) => {
        const getFlowOutputs = flow?.nodes
          .filter((node: any) => node.type === 'outputObjectNode')
          ?.map((node: any) => ({
            outputId: node?.id,
            text: '',
          }));
        return getFlowOutputs;
      })
      .flat();

    setInputs(flowInputs);
    setOutputs(flowOutputs);
  }, [flows]);

  const calculatePositions = (nodes: any) => {
    const sortedNodes = nodes.sort(
      (a: any, b: any) => a.position.y - b.position.y,
    );
    const minY = Math.min(...sortedNodes.map((node: any) => node.position.y));

    const adjustedNodes = sortedNodes.map((node: any) => {
      return {
        ...node,
      };
    });

    const groupedNodes: any[] = [];
    const range = 70;
    adjustedNodes.forEach((node: any) => {
      const group = groupedNodes.find((g: any) =>
        g.some((n: any) => Math.abs(n.position.y - node.position.y) <= range),
      );
      if (group) {
        group.push(node);
      } else {
        groupedNodes.push([node]);
      }
    });

    let currentY = 0;
    const marginY = 20;
    const marginX = 10;

    const finalNodes: any[] = [];
    groupedNodes.forEach(group => {
      let currentX = 0;
      group.forEach((node: any) => {
        finalNodes.push({
          ...node,
          position: {
            x: currentX,
            y: currentY,
          },
        });
        currentX += (node?.width || 100) + marginX;
      });
      const maxHeight = Math.max(
        ...group.map((node: any) => node?.height || 100),
      );
      currentY += maxHeight + marginY;
    });

    // sort groupedNodes by position X
    const sorted = groupedNodes.map(newGroup =>
      newGroup.sort((a: any, b: any) => a.position.x - b.position.x),
    );

    // Step 5: Sort flexGroups by X position within their rows

    return { finalNodes, groupedNodes: sorted, adjustedNodes: sorted?.flat() };
  };

  const onSubmit = async (nodeData: any) => {
    setFormLoading(true);
    try {
      const { submitType, flows: flowIds } = nodeData;

      let flowsIds =
        !submitType || submitType === 'default'
          ? flows?.map((flow: any) => flow.id)
          : flowIds;

      for (let i = 0; i < flowsIds.length; i++) {
        for (let j = i; j < flowsIds.length; j++) {
          if (i === j) {
            const response = await FluxService.formApi(
              flowsIds[i],
              form?.id,
              socketClient?.id!,
              {
                variableInputs: inputs,
              },
            );
          }
        }
      }

      // set new output

      // setOutputs(response?.data?.message);
    } catch (error: any) {
      setNotification({
        message: error?.message,
        type: 'error',
        duration: 3000,
      });
      setFormLoading(false);
    }
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL as string);

    socket.on('connect', () => {
      setSocketClient(socket);
    });
    socket.on('form-test', (data: any) => {
      setFormLoading(false);
      if (data?.data) {
        setOutputs((prev: any) => {
          return prev.map((item: any) => {
            const findItem = data?.data?.find(
              (data: any) => data.outputId === item?.outputId,
            );
            if (findItem) {
              return {
                ...item,
                text: findItem.text,
              };
            }
            return item;
          });
        });
      }
    });

    return () => {
      socket?.off('connect');
      socket?.off('form-test');
    };
  }, [setOutputs]);

  const ref = React.useRef(null);
  const { adjustedNodes } = calculatePositions(nodes);

  return (
    <KitModal
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '1500px',
          overflow: formLoading ? 'hidden' : '',
        },

        '& .MuiDialogContent-root': {
          background: `url(${form?.formStyle?.backgroundImage || ''})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundColor: form?.formStyle?.backgroundColor || '#F2F4F7',

          // scroll hidden
          overflow: formLoading ? 'hidden' : '',
        },
      }}
      fullWidth
      show={show}
      onHide={onHide}
    >
      <Box
        sx={{
          maxWidth: '1048px',
          margin: '0 auto',
        }}
        ref={ref}
      >
        {formLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: 'rgba(255,255,255,0.5)',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {/* {adjustedNodes?.map((nd: any, index) => {
          return <Nodes key={index} nd={nd} />;
        })} */}

        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Main axis is vertical
            gap: form?.formStyle?.verticalSettings?.enabled
              ? form?.formStyle?.verticalSettings?.gap
                ? form?.formStyle?.verticalSettings?.gap + 'px'
                : '16px'
              : '0',
            flexGrow: 1, // Ensures elements grow to fill space
          }}
        >
          {groupedNodes?.map((group: any, index) => {
            if (group.length === 1) {
              return (
                <NodesPreview
                  key={index}
                  inputs={inputs}
                  groupLength={group.length}
                  gapEnabled={form?.formStyle?.horizontalSettings?.enabled}
                  outputs={outputs}
                  setInputs={setInputs}
                  setOutputs={setOutputs}
                  onSubmit={onSubmit}
                  nd={group[0]}
                />
              );
            }

            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: form?.formStyle?.horizontalSettings?.enabled
                    ? form?.formStyle?.horizontalSettings?.gap
                      ? form?.formStyle?.horizontalSettings?.gap + 'px'
                      : '16px'
                    : '0',
                  flexWrap: 'wrap', // Allows wrapping into the next line
                  flexGrow: 1, // Ensures elements grow proportionally
                  width: '100%', // Forces children to span full width
                  justifyContent: 'space-between', // Ensures spacing between items
                  '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
              >
                {group.map((nd: any, index: any) => {
                  return (
                    <NodesPreview
                      key={index}
                      nd={nd}
                      groupLength={group.length}
                      inputs={inputs}
                      outputs={outputs}
                      gapEnabled={form?.formStyle?.horizontalSettings?.enabled}
                      setInputs={setInputs}
                      setOutputs={setOutputs}
                      onSubmit={onSubmit}
                    />
                  );
                })}
              </Box>
            );
          })}
        </Box> */}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 250px)', // 4 fixed-width columns
            gap: form?.formStyle?.verticalSettings?.enabled
              ? form?.formStyle?.verticalSettings?.gap
                ? form?.formStyle?.verticalSettings?.gap + 'px'
                : '16px'
              : '0',
            position: 'relative', // Allows absolute positioning if needed

            '@media (max-width: 768px)': {
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          {adjustedNodes.map(
            (
              node: { width: number; height: number },
              index: React.Key | null | undefined,
            ) => {
              const width = node.width === 1050 ? 1000 : node.width;
              // Calculate gridColumn span based on width
              const columnSpan = Math.ceil(width / 250);

              return (
                <Box
                  key={index}
                  sx={{
                    gridColumn: `span ${columnSpan}`, // Use calculated column span
                    gridRow: `span ${Math.ceil(node.height / 100)}`, // Adjust grid rows based on height
                    // Positioning
                    position: 'relative', // Optional, for children positioning

                    '@media (max-width: 768px)': {
                      gridColumn: 'unset',
                      gridRow: 'unset',
                    },
                  }}
                >
                  <NodesPreview
                    nd={node}
                    groupLength={1}
                    inputs={inputs}
                    outputs={outputs}
                    gapEnabled={form?.formStyle?.horizontalSettings?.enabled}
                    setInputs={setInputs}
                    setOutputs={setOutputs}
                    onSubmit={onSubmit}
                  />
                </Box>
              );
            },
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default PreviewModal;
