import { create } from 'zustand';
import { User } from '../util/Types/User';
import { Folder } from '../util/Types/Folder';

type AuthState = {
  user: User | null;
  accessToken: string | null;
  folders: Folder[];
  fluxBots: Folder[];
  setFluxBots: (fluxBots: Folder[]) => void;
  configs: any[];
  documents: any[];
  setAccessToken: (accessToken: string | null) => void;
  setAuthUser: (user: User | null) => void;
  setFolders: (folders: { id: string; name: string }[]) => void;
  setConfigs: (configs: any) => void;
  setDocuments: (documents: any) => void;
  setTechnologies: (technologies: any) => void;
  setCompanySpaceTechnologies: (companySpaceTechnologies: any) => void;
  technologies: any[];
  companySpaceTechnologies: any[];
  testPreviewModal: any;
  setTestPreviewModal: (testPreviewModal: any) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
};

export const useAuthStore = create<AuthState>(set => ({
  user: null,
  accessToken: null,
  folders: [],
  configs: [],
  fluxBots: [],
  documents: [],
  technologies: [],
  companySpaceTechnologies: [],
  testPreviewModal: {},
  sidebarOpen: true,

  setAccessToken: (accessToken: string | null) => {
    set(() => ({ accessToken }));
  },

  setTestPreviewModal(testPreviewModal) {
    set(() => ({ testPreviewModal }));
  },

  setFolders: (folders: any) => {
    set(() => ({ folders }));
  },
  setFluxBots: (fluxBots: any) => {
    set(() => ({ fluxBots }));
  },
  setConfigs: (configs: any) => {
    set(() => ({ configs }));
  },
  setAuthUser: user => {
    set(() => ({ user }));
  },

  setDocuments: documents => {
    set(() => ({ documents }));
  },

  setTechnologies: (technologies: any) => {
    set(() => ({ technologies }));
  },

  setCompanySpaceTechnologies: (companySpaceTechnologies: any) => {
    set(() => ({ companySpaceTechnologies }));
  },
  setSidebarOpen: (sidebarOpen: boolean) => {
    set(() => ({ sidebarOpen }));
  },
}));
