import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import TagInput from '../Test/TagInput';
import { useAuthStore } from '../../store/storeAuth';
import SearchableSelect from '../SearchableSelect';
import { useStoreGoogleData } from '../../store/storeGoogleData';

const PullGoogleSheetsData: React.FC<{
  formData: any;
  onChange: (e: any) => void;
  onChangeSelect: (e: any) => void;
  nodeLabel: string;
  data: any;
  spreadSheets: any[];
  workSheets: any[];
}> = ({
  formData,
  onChange,
  onChangeSelect,
  nodeLabel,
  data,
  spreadSheets,
  workSheets,
}) => {
  useEffect(() => {
    if (formData?.spreadsheet !== 'none') {
      data?.updateSelectedSpreadSheet(formData.spreadsheet);
    } else {
      data?.updateSelectedWorkSheet(-1);
    }

    if (formData?.sheets !== 'none' && data?.workSheetsLoaded) {
      data?.updateSelectedWorkSheet(formData.sheets);
    }
  }, [formData?.spreadsheet, formData?.sheets]);

  const { user } = useAuthStore(state => state);
  const spreadSheetOptions = spreadSheets.map((sheet: any) => ({
    label: sheet.name,
    value: sheet.id,
  }));

  const workSheetOptions = workSheets.map((sheet: any) => ({
    label: sheet.title,
    value: sheet.sheetId,
  }));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {/* <Box>
          <Button onClick={refreshSpreadSheets}>Refresh Spreadsheets</Button>
        </Box> */}

        <SearchableSelect
          options={spreadSheetOptions}
          label="Select a spreadsheet"
          onChange={value => {
            const selectedValue = value ? value.value : '';
            onChangeSelect({
              target: { name: 'spreadsheet', value: selectedValue },
            });
            data?.updateSelectedSpreadSheet(selectedValue);
          }}
          value={
            spreadSheetOptions.find(
              option => option.value === data?.selectedSpreadSheet?.id,
            ) || null
          }
          className="nodrag"
          style={{ width: 100 }}
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            // bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            // color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        />

        {/* <Label
          isAdvanced
          labelStyle={{
            fontSize: '14px',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            fontWeight: 400,
          }}
          labelName="Sheets"
        /> */}
        <SearchableSelect
          options={workSheetOptions}
          label="Select a sheet"
          onChange={value => {
            const selectedValue = value ? value.value : '';
            onChangeSelect({
              target: { name: 'sheets', value: selectedValue },
            });
            data?.updateSelectedWorkSheet(Number(selectedValue));
          }}
          value={
            workSheetOptions.find(
              option => option.value === data?.selectedWorkSheet?.sheetId,
            ) || null
          }
          style={{ width: 200 }}
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        />

        <TagInput
          placeholder="Use A1 Notation. Example: A1:Z40"
          onChange={onChange}
          name="rangeFilter"
          value={formData.rangeFilter}
          isAdvanced
          showCopy
          dataConnected={data?.rangeFilterData || []}
          removeIcons
          labelName={'Select Area (Optional)'}
          isCollapsed
          handleId="rangeFilter"
          nodeLabel={nodeLabel}
          labelStyle={{
            fontSize: '14px',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            fontWeight: 400,
          }}
          advancedCollapsed
        />

        {/* <Button onClick={getWorkSheetDataHandler}>Get Data</Button>

        {showWorkSheetData && <TextareaAutosize value={workSheetData} />} */}
      </Box>
    </>
  );
};

export default PullGoogleSheetsData;
