import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
import { UploadImage } from '../util/Types/UploadImage';

export class ApiCallerService extends ApiService {
  static pathName = '/api_call';

  static request(body: any): Promise<IGetOneResponse<any>> {
    return this.post(`${this.pathName}`, body);
  }
}
