import { memo, useCallback, useState } from 'react';
import { useNotificationStore } from '../../store/storeNotifications';

import { useEdges, useReactFlow } from 'reactflow';

import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { Box, TextField, Typography } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';

import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';
import { FlowService } from '../../service/FlowService';

const FlowCreator = ({ id, data, type }: NodeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setNodes: setNodeState, setEdges: setEdges } = useReactFlow();
  const reactflow = useReactFlow();
  const { flowId, setSaving, setSchema } = useFlowsStore(state => state);
  const { setNotification } = useNotificationStore(state => state);
  const edges = useEdges();

  const [btnLoading, setBtnLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const [formData, setFormData] = useState({
    nodes: '',
    edges: '',
  });

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setFormData({
          ...data,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onClick = async () => {
    setBtnLoading(true);
    let nodeJson = [];
    let edgeJson = [];

    if (formData.nodes) {
      try {
        nodeJson = JSON.parse(formData.nodes);
      } catch (error) {
        setNotification({
          message: 'Invalid JSON Request Body',
          type: 'error',
        });
        return;
      }
    }

    if (formData.edges) {
      try {
        edgeJson = JSON.parse(formData.edges);
      } catch (error) {
        setNotification({
          message: 'Invalid JSON Auth',
          type: 'error',
        });
        return;
      }
    }
    try {
      const nodes = reactflow.getNodes();
      const filteredNodes = nodes.filter(
        node =>
          !nodeJson.some((n: any) => n.id === node.id) &&
          node?.type !== 'globalStarter',
      );
      const filteredEdges = edges.filter(
        edge =>
          !edgeJson.some((e: any) => e.id === edge.id) &&
          !edge?.source?.includes('globalStarter'),
      );

      // Send the merged and filtered data to the service
      await FlowService.nodesAutomation(flowId!, {
        nodes: [...filteredNodes, ...nodeJson],
        edges: [...filteredEdges, ...edgeJson],
      });

      // Update state
      setNodeState([...filteredNodes, ...nodeJson]);
      setEdges([...filteredEdges, ...edgeJson]);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const onChange = useCallback(
    (event: { target: { name: string; value: any } }) => {
      setWhoIsChanging({
        value: event.target.value,
        name: event.target.name,
      });

      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });

      setIsChangingDirectly(true);
    },
    [id],
  );

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  return (
    <>
      <Box className={isLoading || btnLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          isGlobal={false}
          onSubmit={onClick}
          btnText="Automate Nodes"
          isLoading={isLoading || btnLoading}
          data={data}
          id={id}
          showTokensUsed={false}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '24px',
              alignItems: 'center',
              p: '24px',
              width: '1000px',
            }}
          >
            <Box sx={{ width: '50%' }}>
              <Typography variant="h3">Nodes JSON</Typography>
              <TextField
                name="nodes"
                placeholder="Enter nodes JSON"
                onChange={onChange}
                value={formData.nodes}
                multiline
                rows={10}
                fullWidth
              />
            </Box>
            <Box sx={{ width: '50%' }}>
              <Typography variant="h3">Edges JSON</Typography>
              <TextField
                name="edges"
                placeholder="Enter edges JSON"
                onChange={onChange}
                value={formData.edges}
                multiline
                rows={10}
                fullWidth
              />
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
};

export default memo(FlowCreator);
