import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';

interface Option {
  label: string;
  value: string;
}

interface SearchableSelectProps
  extends Omit<
    AutocompleteProps<Option, false, false, false>,
    'renderInput' | 'options' | 'getOptionLabel' | 'onChange' | 'value'
  > {
  options: Option[];
  label?: string;
  onChange: (value: Option | null) => void;
  value: Option | null;
  className?: string;
  style?: React.CSSProperties;
  sx?: object;
  MenuProps?: object;
  isDark?: boolean;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  label,
  onChange,
  value,
  className,
  style,
  sx,
  MenuProps,
  isDark,
  ...props
}) => {
  const { fullWidth } = props;
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputLabelProps={{
            style: {
              //   color: isDark ? '#D0D5DD' : '#101828',
              //   width: '20%',
            },
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              //   color: isDark ? '#D0D5DD' : '#101828',
              width: fullWidth ? '100%' : '60%',

              backgroundColor: isDark ? '#101828' : '#F9FAFB',
            },
            endAdornment: params.InputProps.endAdornment,
          }}
          sx={
            {
              //   '& .MuiOutlinedInput-root': {
              //     '& fieldset': {
              //       borderColor: isDark ? '#667085' : '#D0D5DD',
              //     },
              //     '&:hover fieldset': {
              //       borderColor: isDark ? '#D0D5DD' : '#101828',
              //     },
              //     '&.Mui-focused fieldset': {
              //       borderColor: isDark ? '#D0D5DD' : '#101828',
              //     },
              //   },
              //   '& .MuiInputBase-root': {
              //     color: isDark ? '#D0D5DD' : '#101828',
              //   },
            }
          }
        />
      )}
      onChange={(event, newValue) => onChange(newValue)}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      //   sx={{
      //     '& .MuiList-root': {
      //       backgroundColor: isDark ? '#101828' : '#F9FAFB',
      //     },
      //     '& .MuiSvgIcon-root': {
      //       color: isDark ? '#D0D5DD' : '#101828',
      //     },
      //     borderRadius: '8px',
      //     border: 'none',
      //     boxShadow: 'none',
      //     '.MuiOutlinedInput-notchedOutline': { border: 0 },
      //     bgcolor: isDark ? '#101828' : '#F9FAFB',
      //     color: isDark ? '#D0D5DD' : '#101828',
      //   }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      {...props}
    />
  );
};

export default SearchableSelect;
