import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';

// import { User } from '../Utilis/Types/User';
export class GroqService extends ApiService {
  static pathName = '/groq';

  static search = (body: {
    messages: {
      role: string;
      content: string;
      name: string;
    }[];
    model: string;
    seed: number;
    temperature: number;
    max_tokens: number;
    top_p: number;
    stop: string | null;
    flowId?: string;
  }): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    return this.post(`${this.pathName}`, body);
  };

  static whisperLargeV3 = (query: {
    fileUrl: string;
    flowId?: string;
  }): Promise<
    IGetOneResponse<{
      usedTokens: any;
      message: string;
    }>
  > => {
    return this.post(
      `${this.pathName}/whisper-large-v3?flowId=${query.flowId}&fileUrl=${query.fileUrl}`,
    );
  };
}
