import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';

// import { User } from '../Utilis/Types/User';
export class GeminiService extends ApiService {
  static pathName = '/gemini';

  static gemini = (body: {
    model: string;
    prompt: string;
    temperature: number;
    topP: number;
    topK: number;
    maxOutputTokens: number;
    flowId?: string;
  }): Promise<
    IGetOneResponse<{
      message: string;
    }>
  > => {
    const { prompt, ...bodyWithoutPrompt } = body;

    const queryParams = new URLSearchParams(
      bodyWithoutPrompt as any,
    ).toString();
    return this.post(`${this.pathName}?${queryParams}`, { prompt: prompt });
  };

  static geminiProVision = (
    query: {
      prompt: string;
      temperature: number;
      topP: number;
      topK: number;
      maxOutputTokens: number;
      fileUrl?: string;
      flowId?: string;
    },
    body: FormData,
  ): Promise<
    IGetOneResponse<{
      usedTokens: any;
      message: string;
    }>
  > => {
    const fileUrls = query.fileUrl ? [query.fileUrl] : [];

    const encodedUrls = fileUrls.map(url => encodeURIComponent(url!)).join(',');

    return this.post(
      `${this.pathName}/gemini-pro-vision?prompt=${query.prompt}${
        `&temperature=${query.temperature}` || ''
      }${`&topP=${query.topP}` || ''}${`&topK=${query.topK}` || ''}${
        `&maxOutputTokens=${query.maxOutputTokens}` || ''
      }${encodedUrls ? `&fileUrl=${encodedUrls}` : ''}${
        query.flowId ? `&flowId=${query.flowId}` : ''
      }`,
      body,
    );
  };

  static multiModalNew = (
    query: {
      prompt: string;
      temperature: number;
      topP: number;
      topK: number;
      maxOutputTokens: number;
    },
    body: FormData,
  ): Promise<
    IGetOneResponse<{
      usedTokens: any;
      message: string;
    }>
  > => {
    return this.post(`${this.pathName}/multi-modal-new`, body, query);
  };

  static speechToText = ({
    fileUrl,
  }: any): Promise<
    IGetOneResponse<{
      usedTokens: any;
      message: string;
    }>
  > => {
    return this.post(
      `${this.pathName}/speech-to-text${fileUrl ? `?fileUrl=${fileUrl}` : ''}`,
    );
  };
}
