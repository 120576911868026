export const types = {
  varInputForm: 'varInputForm',
  textBox: 'textBox',
  richTextBox: 'richTextBox',
  textCompressorNode: 'textCompressorNode',
  conditionNode: 'conditionNode',
  voiceBoxNode: 'voiceBoxNode',
  fluxObject: 'fluxObject',
  varInputNode: 'varInputNode',
  outputObjectNode: 'outputObjectNode',
  commandTextNode: 'commandTextNode',
  outputController: 'outputController',
  dynamicSettingsNode: 'dynamicSettingsNode',
};

export const allNodesTargetBasedOnType = {
  animationNode: {
    animationPromptInput: 'prompt',
    animationNegativePromptInput: 'negativePrompt',
    startImageInput: 'startImageInput',
    endImageInput: 'endImageInput',
  },

  commandTextNode: {
    promptIn: 'prompt',
    contentIn: 'content',
    instructionIn: 'instruction',
    personaIn: 'persona',
  },
  outputController: {
    input: 'text',
  },
  outputObjectNode: {
    input: 'text',
    callbackUrlInput: 'callbackUrlInput',
    emails: 'emails',
    subject: 'subject',
    urls: 'urls',
  },

  varInputNode: {
    text: 'text',
  },
  textBox: {
    inputText: 'text',
  },
  richTextBox: {
    input: 'text',
  },
  voiceBoxNode: {
    ['text-input']: 'text',
  },
  commandContentNode: {
    textInput: 'textInput',
    textQuery: 'query',
    textInputDelete: 'textInputDelete',
  },
  apiCaller: {
    url: 'url',
    jsonRequestBody: 'jsonRequestBody',
    jsonAuth: 'jsonAuth',
    username: 'username',
    password: 'password',
  },
  sqlQuerier: {
    host: 'host',
    port: 'port',
    database: 'database',
    username: 'username',
    password: 'password',
    connectionString: 'connectionString',
    collection: 'collection',
    filter: 'filter',
    query: 'query',
  },

  pushData: {
    textContent: 'textContent',
    sheetName: 'sheetName',
    fileUrl: 'fileUrl',
  },
  conditionNode: {
    text: 'text',
  },
  imageGenerator: {
    prompt: 'prompt',
    style: 'style',
    view: 'view',
    subject: 'subject',
    environment: 'environment',
    tone: 'tone',
    negativePrompt: 'negativePrompt',
    inputPreview: 'inputPreview',
    searchPrompt: 'searchPrompt',
  },
  arrayNode: {
    content: 'content',
    preInputText: 'preInputText',
    postInputText: 'postInputText',
  },
  webSearch: {
    query: 'query',
  },
  webMedia: {
    query: 'query',
  },
  webLocations: {
    query: 'query',
  },
  webNews: {
    query: 'query',
  },
  mathFunction: {
    formula: 'formula',
  },
  scripting: {
    script: 'script',
  },
  audioReaderNode: {
    youtubeUrl: 'youtubeUrl',
    inputPreview: 'inputPreview',
  },
  commandMusicNode: {
    'music-gen-input': 'prompt',
  },

  imageReaderNode: {
    imageGenCaption: 'caption',
    imageGenQuestion: 'question',
    imageGenContent: 'content',
    imgGenPrompt: 'prompt',
    src: 'src',
  },
  webCrawling: {
    webCrawlUrl: 'url',
    webCrawlTechnology: 'technology',
    webCrawlSince: 'since',
    webCrawlCompanyName: 'companyName',
    webCrawlWords: 'words',
  },
  webTrends: {
    query: 'query',
  },
  secApiNode: {
    secNodeTarget: 'query',
    section: 'section',
    type: 'type',
  },
  webAmazon: {
    queryUrl: 'url',
  },

  pullData: {
    rangeFilter: 'rangeFilter',
    paragraphStart: 'paragraphStart',
    paragraphEnd: 'paragraphEnd',
  },

  hubSpotCRM: {
    entityId: 'entityId',
  },

  storedDataCaller: {
    selectedDocumentId: 'selectedDocumentId',
  },
};

export const allValuesByType = {
  animationNode: [
    'prompt',
    'negativePrompt',
    'text',
    'startImageInput',
    'endImageInput',
  ],
  commandTextNode: ['prompt', 'content', 'instruction', 'persona', 'text'],
  outputController: ['text'],
  outputObjectNode: ['text', 'emails', 'subject'],
  varInputNode: ['text'],
  textBox: ['text'],
  richTextBox: ['text'],
  voiceBoxNode: ['text', 'audioUrl'],
  commandContentNode: ['textInput', 'query'],
  apiCaller: ['url', 'jsonRequestBody', 'jsonAuth', 'username', 'password'],
  sqlQuerier: [
    'host',
    'port',
    'database',
    'username',
    'password',
    'connectionString',
    'collection',
    'filter',
    'query',
  ],
  imageGenerator: [
    'prompt',
    'style',
    'view',
    'subject',
    'environment',
    'tone',
    'negativePrompt',
    'inputPreview',
    'text',
  ],
  arrayNode: ['content'],
  webSearch: ['query', 'text'],
  audioReaderNode: ['youtubeUrl', 'text'],
  commandMusicNode: ['prompt', 'text'],
  imageReaderNode: ['caption', 'question', 'content', 'prompt', 'src', 'text'],
  webCrawling: ['url', 'technology', 'since', 'companyName', 'words'],
  webTrends: ['query', 'text'],
  secApiNode: ['query', 'section', 'type', 'text'],
  webAmazon: ['url', 'text'],
};
