import React, { useEffect, useState } from 'react';
import KitModal from '../UI/KitModal';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { FlowService } from '../../service/FlowService';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { FolderService } from '../../service/FolderService';
import { useNavigate } from 'react-router-dom';
import useFlowsStore from '../../store/storeFlows';
import SearchableSelect from '../SearchableSelect';
import { UserService } from '../../service/UserService';

interface Props {
  onHide: () => void;
  flowId: string;
  closeTemplateSystemModal: () => void;
  templateTitle: string;
}
const CreateTemplateFlowModal: React.FC<Props> = ({
  onHide,
  flowId,
  closeTemplateSystemModal,
  templateTitle,
}) => {
  const { addFlow } = useFlowsStore();
  const navigate = useNavigate();
  const { folders, setFolders } = useAuthStore(state => state);
  const setNotification = useNotificationStore(state => state.setNotification);
  const authUser = useAuthStore(state => state.user);

  const [flowName, setFlowName] = useState(templateTitle);
  const [selectedFolderId, setSelectedFolderId] = useState<
    undefined | string
  >();
  const [creatingTemplateFlow, setCreatingTemplateFlow] = useState(false);
  const [spaceFolders, setSpaceFolders] = useState<any[]>([]);
  const [spaces, setSpaces] = useState<any[]>([]);
  const [selectedSpaceId, setSelectedSpaceId] = useState<undefined | string>();

  useEffect(() => {
    const getSpaces = async () => {
      if (!authUser) return;
      try {
        const { data } = await UserService.getSpaces();
        setSpaces(data);
      } catch (error) {
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      }
    };

    getSpaces();
  }, []);

  useEffect(() => {
    if (selectedSpaceId) {
      const selectedSpace = spaces.find(({ id }) => id === selectedSpaceId);
      if (!selectedSpace) return;
      const spaceFolders = selectedSpace.folder;

      setSpaceFolders(spaceFolders);
    }
  }, [spaces, selectedSpaceId]);

  const folderChangeHandler = (e: SelectChangeEvent) => {
    setSelectedFolderId(e.target.value);
  };

  const saveTemplateHandler = async () => {
    if (!flowId || !selectedFolderId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Flow Id and FolderId must be specified',
      });

    try {
      setCreatingTemplateFlow(true);

      const createdFlow = await FlowService.copyTemplate(
        flowId,
        selectedFolderId,
        flowName,
      );
      addFlow({
        id: createdFlow.data.id,
        name: createdFlow.data.name,
        index: createdFlow.data.index,
      });

      const folderResponse = await FolderService.getFolders();
      setFolders(folderResponse.data);

      setNotification({
        type: NotificationType.Success,
        message: 'Flow created successfully',
      });

      onHide();
      closeTemplateSystemModal();
      navigate(`/flow/${createdFlow.data.id}`);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }

    setCreatingTemplateFlow(false);
  };

  const spacesOptions = [{ name: 'My Personal Space', id: 0 }, ...spaces].map(
    (space: any) => ({
      label: space.name,
      value: space.id,
    }),
  );

  const foldersToShow = selectedSpaceId ? spaceFolders : folders;

  const foldersToShowOptions = foldersToShow.map((folder: any) => ({
    label: folder.name,
    value: folder.id,
  }));

  return (
    <KitModal
      onHide={onHide}
      fullWidth={true}
      show={true}
      title={'Create flow from template'}
      footer={
        <Button
          variant={`contained`}
          onClick={saveTemplateHandler}
          disabled={creatingTemplateFlow}
        >
          Save
        </Button>
      }
    >
      <div style={{ padding: 10 }}>
        <TextField
          type="text"
          name="flowName"
          value={flowName}
          onChange={e => setFlowName(e.target.value)}
          placeholder="Name your flow"
          fullWidth
          style={{ marginBottom: 20, marginTop: 10 }}
        />

        <FormControl fullWidth>
          {spaces.length > 0 && (
            <SearchableSelect
              options={spacesOptions}
              label="Select Space"
              onChange={value => {
                const selectedValue = value ? value.value : '';

                setSelectedSpaceId(selectedValue);
              }}
              value={
                spacesOptions.find(
                  option => option?.value === selectedSpaceId,
                ) || spacesOptions[0]
              }
              className="nodrag"
              style={{ width: 100, marginBottom: '20px' }}
              sx={{
                '& .MuiList-root': {
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#101828' : '#F9FAFB',
                },
                '& .MuiSvgIcon-root': {
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
                borderRadius: '8px',
                border: 'none',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor:
                        authUser?.theme === 'dark' ? '#667085' : '#D0D5DD',
                    },
                    backgroundColor:
                      authUser?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                },
              }}
            />
          )}
          <Box sx={{ margin: '5px 0' }}></Box>

          <SearchableSelect
            options={foldersToShowOptions}
            label="Select Folder"
            onChange={value => {
              const selectedValue = value ? value.value : '';

              setSelectedFolderId(selectedValue);
            }}
            value={
              foldersToShowOptions.find(
                option => option?.value === selectedFolderId,
              ) || null
            }
            className="nodrag"
            style={{ width: 100, marginBottom: '20px' }}
            sx={{
              '& .MuiList-root': {
                backgroundColor:
                  authUser?.theme === 'dark' ? '#101828' : '#F9FAFB',
              },
              '& .MuiSvgIcon-root': {
                color: authUser?.theme === 'dark' ? '#D0D5DD' : '#101828',
              },
              borderRadius: '8px',
              border: 'none',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .Mui-selected': {
                    backgroundColor:
                      authUser?.theme === 'dark' ? '#667085' : '#D0D5DD',
                  },
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
              },
            }}
          />
        </FormControl>
      </div>
    </KitModal>
  );
};

export default CreateTemplateFlowModal;
