import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  ExportButton,
  Invoice,
} from '../../settings/Pricing/DataGridExportableTable';
import { Export } from '@phosphor-icons/react';

interface ResponseDataGridTableProps {
  tableData: {
    columns: GridColDef[];
    rows: any[];
  };
}

export const ResponseDataGridTable: React.FC<ResponseDataGridTableProps> = ({
  tableData,
}) => {
  const { columns, rows } = tableData;

  const convertToCSV = (rows: Invoice[]) => {
    const header = Object.keys(rows[0]) as (keyof Invoice)[];
    const csvRows = [
      header.join(','),
      ...rows.map(row =>
        header.map(fieldName => JSON.stringify(row[fieldName] || '')).join(','),
      ),
    ];
    return csvRows.join('\n');
  };

  const downloadCSV = (rows: Invoice[]) => {
    const csvData = convertToCSV(rows);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const ExportButton = ({ rows }: { rows: Invoice[] }) => {
    const handleExport = () => {
      downloadCSV(rows);
    };

    return (
      <Button
        onClick={handleExport}
        startIcon={<Export size={18} weight="regular" />}
        sx={{
          border: '1px solid #3650DE',
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: 500,
          fontStyle: 'normal',
          width: '20%',
          padding: '8px 10px',
          marginBottom: '16px',
          backgroundColor: '#fff',
          color: '#3650DE',
        }}
      >
        Export CSV
      </Button>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ExportButton rows={rows} />
      </Box>
      <DataGrid
        columns={columns}
        rows={rows}
        hideFooterSelectedRowCount
        hideFooter
        hideFooterPagination
      />
    </Box>
  );
};
