import React from 'react';
import voices from '../flux_classes/voice';
import { Box, FormLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';

type Props = {
  formData: any;
  onChange: any;
  user: any;
};

const PlayHt = ({ formData, onChange, user }: Props) => {
  const { user : authUser } = useAuthStore(state => state);

  return (
    <div>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <FormLabel htmlFor='voice-select' sx={{
          color : authUser?.theme === 'dark' ? '#D0D5DD' : 'black'
        }}
        >Voice:</FormLabel>
        <Select
          native
          value={formData.voice}
          name='voice'
          className='nodrag'
          onChange={onChange}
          sx={{
            '& .MuiList-root': {
              backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
              border: 'none',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        >
          <option aria-label='None' value='' />
          <optgroup label='Custom voices'>
            {user?.voice
              ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
              ?.map((voice: any, index: number) => (
                <option key={index} value={voice.id}>
                  {voice.name}
                </option>
              ))}
          </optgroup>
          <optgroup label='Voices'>
            {voices
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(voice => (
                <option key={voice.id} value={voice.name}>
                  {voice.name} ({voice.language})
                </option>
              ))}
          </optgroup>
        </Select>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <FormLabel htmlFor='quality-select' sx={{
          color : authUser?.theme === 'dark' ? '#D0D5DD' : 'black'
        }}>Quality:</FormLabel>
        <Select
          id='quality-select'
          value={formData.quality}
          name='quality'
          className='nodrag'
          onChange={onChange}
          sx={{
            '& .MuiList-root': {
              backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
              border: 'none',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        >
          <MenuItem value='draft'   sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>Draft</MenuItem>
          <MenuItem value='low'   sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>Low</MenuItem>
          <MenuItem value='medium'   sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>Medium</MenuItem>
          <MenuItem value='high'   sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>High</MenuItem>
          <MenuItem value='premium'   sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>Premium</MenuItem>
        </Select>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <FormLabel htmlFor='format-select' sx={{
          color : authUser?.theme === 'dark' ? '#D0D5DD' : 'black'
        }}>Output Format:</FormLabel>
        <Select
          className='nodrag'
          value={formData.outputFormat}
          name='outputFormat'
          onChange={onChange}
          sx={{
            '& .MuiList-root': {
              backgroundColor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
              border: 'none',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor:  user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        >
          <MenuItem value='mp3' sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>MP3</MenuItem>
          <MenuItem value='wav' sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>WAV</MenuItem>
          <MenuItem value='ogg' sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>OGG</MenuItem>
          <MenuItem value='flac' sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}>FLAC</MenuItem>
        </Select>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <FormLabel htmlFor='speed-input' sx={{
          color : authUser?.theme === 'dark' ? '#D0D5DD' : 'black'
        }}>Speed:</FormLabel>
        <TextField
          id='speed-input'
          type='text'
          placeholder='Speed'
          value={formData.speed}
          name='speed'
          onChange={onChange}
          InputProps={{
            style: {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
          }}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            bgcolor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            border: `2px solid ${user?.theme === 'dark' ? '#475467' : '#EAECF0'}`,
          }}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <FormLabel htmlFor='sample-rate-input' sx={{
          color : authUser?.theme === 'dark' ? '#D0D5DD' : 'black'
        }}>Sample Rate:</FormLabel>
        <TextField
          id='sample-rate-input'
          type='text'
          placeholder='Sample Rate'
          value={formData.sampleRate}
          name='sampleRate'
          onChange={onChange}
          InputProps={{
            style: {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
          }}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            bgcolor:  user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            border: `2px solid ${user?.theme === 'dark' ? '#475467' : '#EAECF0'}`,
          }}
        />
      </Box>
    </div>
  );
};

export default PlayHt;
