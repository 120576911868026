import React, { useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '../../assets/icons/Search.png';
import { Export } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { Link as LinkIcon } from '@phosphor-icons/react';

interface Props {
  flowsReport: any[];
  flowId: string;
}
const FlowStatsTable: React.FC<Props> = ({ flowsReport, flowId }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchValue, setSearchValue] = useState('');

  const start = page * rowsPerPage;

  const filteredFlowsReport = useMemo(() => {
    return flowsReport.filter(flowReport =>
      flowReport.user?.name?.toLowerCase().includes(searchValue),
    );
  }, [searchValue, flowsReport]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const exportToCsv = (data: any[], fileName: string) => {
    const csvRows = [];

    const headers = [
      'User of flow',
      'Flow Runs',
      'Tokens Used',
      'API Triggers',
      'Email Triggers',
      'FluxPrompt Triggers',
      'Auto Triggers',
    ];

    csvRows.push(headers.join(','));

    data.forEach(row => {
      const values = [
        row.user.name,
        row.nodeRuns,
        row.tokenUsed?.toFixed(3),
        row.apiTriggers,
        row.emailTriggers,
        row.fluxPromptTriggers,
        row.autoTriggers,
      ];
      csvRows.push(values.join(','));
    });

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box sx={{ width: '96%', paddingX: '24px', paddingY: '15px' }}>
      <Box
        sx={{
          margin: 0,
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Typography
              sx={{
                color: 'rgba(71, 84, 103, 1)',
                fontSize: '18px',
                fontWeight: 500,
                lineHeight: '28.8px',
                letterspacing: '0.15px',
              }}
            >
              Flow Table View
            </Typography>

            <Link to={`/flow/${flowId}`} style={{ textDecoration: 'none' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <LinkIcon color="rgba(0, 111, 238, 1)" />
                <Typography
                  sx={{
                    color: 'rgba(54, 80, 222, 1)',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    letterSpacing: '0.17px',
                  }}
                >
                  Go to flow
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <FormControl sx={{ m: 1 }} variant="standard">
            <TextField
              variant="outlined"
              placeholder="Type the user’s name in here."
              onChange={handleInputChange}
              value={searchValue}
              sx={{
                width: '300px',
                padding: '4px, 12px, 4px, 12px',
                background: 'transparent',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  padding: '4px, 24px, 4px, 12px',
                  height: '34px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Box
            sx={{
              border: '1px solid rgba(54, 80, 222, 1)',
              padding: '4px 10px',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              width: '120px',
              cursor: 'pointer',
            }}
            onClick={() => exportToCsv(filteredFlowsReport, 'flow_stats.csv')}
          >
            <Typography
              sx={{
                color: 'rgba(54, 80, 222, 1)',
                fontSize: '16px',
                fontWeight: 500,
                letterSpacing: '0.15px',
                lineHeight: '24px',
              }}
            >
              Export Table
            </Typography>
            <Export color="rgba(54, 80, 222, 1)" />
          </Box>
        </Box>
      </Box>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table size={'medium'}>
            <TableHead>
              <TableRow>
                <TableCell>User of flow</TableCell>
                <TableCell align="center">Flow Runs</TableCell>
                <TableCell align="center">Tokens Used</TableCell>
                <TableCell align="center">API Triggers</TableCell>
                <TableCell align="center">Email Triggers</TableCell>
                <TableCell align="center">FluxPrompt Triggers</TableCell>
                <TableCell align="center">Auto Triggers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFlowsReport
                .slice(start, start + rowsPerPage)
                .map(flowReport => {
                  return (
                    <TableRow
                      hover
                      onClick={() => {}}
                      tabIndex={-1}
                      key={flowReport.id}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: 400,
                          color: '#2C4DFF',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Typography>{flowReport.user.name}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '14px' }}
                      >
                        {flowReport.nodeRuns}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flowReport.tokenUsed?.toFixed(3)}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flowReport.apiTriggers}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flowReport.emailTriggers}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flowReport.fluxPromptTriggers}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flowReport.autoTriggers}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={filteredFlowsReport.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      margin: 'none',
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    '& .MuiInputBase-root': {
                      margin: '0 10px',
                      marginRight: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default FlowStatsTable;
