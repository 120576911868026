import { Box, Typography } from '@mui/material';
import React from 'react';
import KitModal from '../UI/KitModal';
export type LinesReference = {
  lines: string;
  pageContent: string;
};

const LinesReferenceModal = ({
  openLinesReferenceModal,
  setLinesReferenceModal,
  linesReferences,
}: any) => {
  return (
    <KitModal
      show={openLinesReferenceModal}
      fullWidth
      onHide={() => setLinesReferenceModal(false)}
    >
      <Box>
        {linesReferences?.map((item: LinesReference, i: number) => {
          return (
            <Box key={i}>
              {' '}
              <Typography variant='h3'>{item.lines}</Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: item.pageContent }}
              />
            </Box>
          );
        })}
      </Box>
    </KitModal>
  );
};

export default LinesReferenceModal;
