import KitModal from '../../UI/KitModal';
import { Box, Typography } from '@mui/material';
import WebTrendsSettings from '../WebTrendsSettings';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant='h6'>Settings</Typography>
        <WebTrendsSettings onChange={onChange} formData={formData} />
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
