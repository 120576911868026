import { memo, useCallback, useState } from 'react';
import { Position, useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box, Collapse, Divider, Tooltip, Typography } from '@mui/material';
import { Copy, Info } from '@phosphor-icons/react';
import Node from '../UI/Node/Node';
import InputHandle from '../UI/InputHandle/InputHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import TagInput from '../Test/TagInput';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Label from '../UI/Label/Label';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';

function ApiInput({ data, id, type }: NodeProps) {
  const setNotification = useNotificationStore(state => state.setNotification);
  const edges = useEdges();
  const { setNodes: setNodeState } = useReactFlow();
  const { setSchema, setSaving, flowId } = useFlowsStore(state => state);

  const [collapsed, setCollapsed] = useState(false);
  const [fileUrlCollapsed, setFileUrlCollapsed] = useState(false);

  const [textBoxValue, setTextBoxValue] = useState(data?.text);
  const [fileUrlValue, setFileUrlValue] = useState(data?.fileUrl);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  const onChange = useCallback(
    (evt: {
      target: {
        value: {
          target: { value: string };
        };
        name: string;
      };
    }) => {
      setIsChangingDirectly(true);
      if (evt.target.name === 'text') {
        setTextBoxValue(evt.target.value);
      }
      if (evt.target.name === 'fileUrl') {
        setFileUrlValue(evt.target.value);
      }
    },
    [],
  );

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          changeType: 'changeText',
          objectData: { text: textBoxValue, fileUrl: fileUrlValue },
          setSchema,
        });
      }
    },
    [isChangingDirectly, textBoxValue, fileUrlValue],
    500,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const getHandlePosition = (collapsed: boolean, fileUrlCollapsed: boolean) => {
    if (collapsed && fileUrlCollapsed) {
      return {
        collapsed: '80px',
        fileUrlCollapsed: '190px',
      };
    }
    if (collapsed && !fileUrlCollapsed) {
      return {
        collapsed: '80px',
        fileUrlCollapsed: '135px',
      };
    }
    if (!collapsed && fileUrlCollapsed) {
      return {
        collapsed: '25px',
        fileUrlCollapsed: '105px',
      };
    }
    if (!collapsed && !fileUrlCollapsed) {
      return {
        collapsed: '25px',
        fileUrlCollapsed: '55px',
      };
    }
  };

  return (
    <Node
      id={id}
      data={data}
      isLoading={false}
      type={type}
      showTokensUsed={false}
    >
      <Box p="16px" position={'relative'}>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Label
            labelName="Data"
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
          />{' '}
          <Tooltip title="Use this node to pass in Data from external sources, such as email, api, webhook, chats, forms, or from other flows.">
            <Info size={16} color="#667085" />
          </Tooltip>
        </Box>

        <Collapse in={collapsed}>
          <TagInput
            name={'text'}
            value={textBoxValue}
            labelName={'Test Data'}
            nodeId={id}
            isCollapsed={true}
            onChange={onChange}
            nodeLabel={data?.label}
            labelStyle={{ fontWeight: 400, fontSize: '14px' }}
            isAdvanced={true}
            advancedCollapsed
          />
        </Collapse>

        <Box
          sx={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Label
            labelName="File URL"
            collapsed={fileUrlCollapsed}
            onCollapse={() => setFileUrlCollapsed(!fileUrlCollapsed)}
          />{' '}
          <Tooltip title="When passing in a file, image, audio, pdf, etc into a flow, it is automatically converted into a URL to be used inside of the workflow. This field is where you pass it through to your flow.">
            <Info size={16} color="#667085" />
          </Tooltip>
        </Box>
        <Collapse in={fileUrlCollapsed}>
          <TagInput
            name={'fileUrl'}
            value={fileUrlValue}
            labelName={'Test File URL'}
            nodeId={id}
            isCollapsed={false}
            onChange={onChange}
            nodeLabel={data?.label}
            labelStyle={{ fontWeight: 400, fontSize: '14px' }}
            isAdvanced={true}
            advancedCollapsed
          />
        </Collapse>

        <InputHandle
          activeColor={findColor?.color}
          handleId={'outputFileUrl'}
          isActive={isActiveEdge(edges, id, 'outputFileUrl', 'source')}
          right={-12}
          top={getHandlePosition(collapsed, fileUrlCollapsed)?.fileUrlCollapsed}
          position={Position.Right}
          type="source"
        />

        <InputHandle
          activeColor={findColor?.color}
          handleId={'output'}
          isActive={isActiveEdge(edges, id, 'output', 'source')}
          right={-12}
          top={getHandlePosition(collapsed, fileUrlCollapsed)?.collapsed}
          position={Position.Right}
          type="source"
        />

        <Divider
          sx={{
            mt: '16px',
          }}
        />
        <Box
          onClick={() => {
            navigator.clipboard.writeText(data.myId);
            setNotification({
              message: 'Copied to clipboard',
              type: 'success',
            });
          }}
          sx={{
            borderRadius: '4px',
            padding: '6px 8px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            width: 'fit-content',
            mt: '24px',
            '&:hover': {
              background: user?.theme === 'dark' ? '#101828' : '#E8E8E8',
            },
          }}
          className="d-flex align-items-center gap-2"
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: user?.theme === 'dark' ? '#6E7A8A' : '#D0D5DD',
              lineHeight: '16px',
              fontWeight: 400,
            }}
          >
            ID: {id}
          </Typography>
          <Copy
            color={user?.theme === 'dark' ? '#6E7A8A' : '#D0D5DD'}
            cursor={'pointer'}
            className="nodrag"
          />
        </Box>
      </Box>
    </Node>
  );
}

export default memo(ApiInput);
