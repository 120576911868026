import { Box, Button, TextField } from '@mui/material';
import { Pen } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import KitModal from '../UI/KitModal';
import { useAuthStore } from '../../store/storeAuth';
import SearchableSelect from '../SearchableSelect';
import { UserService } from '../../service/UserService';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import TagManager from 'react-gtm-module';

// import { GTMDataLayerEvent } from 'react-gtm-module'; // Adjust the import path as needed

// Local declaration for ReactFlow within CreateFlowModal
interface ReactFlow {
  currentFlowName?: string;
  getCurrentFlowName?: () => string;
}

// Extend the Window interface locally
declare global {
  interface Window {
    ReactFlow?: ReactFlow;
    // dataLayer?: any[]
  }
}

type Props = {
  onHide: () => void;
  onCreateFlow: (
    name: string,
    isEditing?: boolean,
    id?: string,
    folderId?: string,
    isCopy?: boolean,
    type?: string,
  ) => void;
  show: boolean;
  data: {
    isSharing: boolean;
    isEditing: boolean;
    type?: string;
    flow: {
      id: string;
      name: string;
    };
    folderId: string;
  };
  onShareFlow: (
    id: string,
    email: string,
    name: string,
    selectedCompanyId?: string,
  ) => void;
  theme: string | undefined;
  setIsFlowModalOpen: any;
};

const CreateFlowModal = ({
  onHide,
  onCreateFlow,
  show,
  data,
  onShareFlow,
}: Props) => {
  const { user } = useAuthStore(state => state);

  const [name, setName] = useState(data?.flow?.name || '');
  const [email, setEmail] = useState('');
  const [initial, setInitial] = useState(true);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    undefined | string
  >();

  useDebounceEffect(
    () => {
      const getCompanies = async () => {
        const res = await UserService.getCompanies(email);

        if (res.data.length === 1) {
          setSelectedCompanyId(res.data[0].id);
        }

        setCompanies(res.data);
      };

      if (initial && !email) {
        getCompanies();

        setInitial(false);
      } else if (email) {
        getCompanies();
      }
    },
    [email, initial],
    400,
  );

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onHide();
    setName('');

    // Send custom event to GTM when a flow is created or edited
    TagManager.dataLayer({
      dataLayer: {
        event: 'flow_creation',
        flowName: name,
      } as any,
    });

    if (data?.isEditing) {
      onCreateFlow(name, true, data?.flow?.id, data?.folderId);
    } else {
      onCreateFlow(name, false, '', data?.folderId, false, data?.type);
    }
  };

  const companyOptions = companies.map((company: any) => ({
    label: company.name,
    value: company.id,
  }));

  return (
    <KitModal
      onHide={onHide}
      show={show}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '650px',
          width: '650px',
        },
      }}
      title={
        <Box>
          {data?.isEditing
            ? 'Rename Flow'
            : data?.type === 'chat'
            ? 'Create Chat Template'
            : data.isSharing
            ? 'Share Flow'
            : 'Create Flow'}
        </Box>
      }
    >
      <form onSubmit={handleFormSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="1rem"
        >
          <TextField
            id="outlined-basic"
            label="Enter flow name"
            variant="outlined"
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />

          {data?.flow?.id && data.isSharing && (
            <Box sx={{ width: '100%' }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                name="email"
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Enter email or space ID"
              />

              <Box sx={{ height: '10px' }}></Box>
              {companies.length > 1 && (
                <SearchableSelect
                  fullWidth
                  options={companyOptions}
                  label="Select Environment"
                  onChange={value => {
                    const selectedValue = value ? value.value : '';

                    setSelectedCompanyId(selectedValue);
                  }}
                  value={
                    companyOptions.find(
                      option => option?.value === selectedCompanyId,
                    ) || null
                  }
                  className="nodrag"
                  style={{ width: '100%', marginBottom: '20px' }}
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                />
              )}

              <br />

              <Box
                display={'flex'}
                mt={'15px'}
                alignItems={'center'}
                gap={'10px'}
                className="d-flex justify-content-center gap-3 mt-3 align-items-center"
              >
                <Button
                  variant={`contained`}
                  size="small"
                  disabled={!email || !name || !data.isSharing}
                  onClick={() => {
                    onShareFlow(data?.flow?.id, email, name, selectedCompanyId);
                  }}
                >
                  Share Flow
                </Button>
              </Box>
            </Box>
          )}

          {!data?.isSharing && (
            <Box alignSelf="flex-end">
              <Button
                disabled={!name}
                // onClick={() => {
                //   window.dataLayer = window.dataLayer || [];
                //   window.dataLayer.push({
                //     event: 'flow_creation',
                //     flowName: name,
                //   });
                //   console.log('Data layer push successful');

                //   onHide();
                //   setName('');
                //   if (data?.isEditing) {
                //     onCreateFlow(name, true, data?.flow?.id, data?.folderId);
                //   } else {
                //     onCreateFlow(
                //       name,
                //       false,
                //       '',
                //       data?.folderId,
                //       false,
                //       data?.type,
                //     );
                //   }
                // }}
                onClick={handleFormSubmit}
                variant={`contained`}
                size="small"
              >
                {data?.isEditing ? <Pen /> : 'Create'}
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </KitModal>
  );
};

export default CreateFlowModal;
