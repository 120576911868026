import { Box, Button, Typography } from '@mui/material';
import ReactConfetti from 'react-confetti';
import { ReactComponent as FluxPrompt } from '../../assets/icons/FluxPrompt.svg';
import KitInput from '../../components/UI/KitInput';
import BrandsLogin from '../../components/auth/BrandsLogin';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../store/storeAuth';
import { base64Decode } from '../../helpers/decodeToken';
import { AuthService } from '../../service/AuthService';
import { useForm } from 'react-hook-form';
import { LocalStorageService } from '../../service/LocalStorageService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { UserService } from '../../service/UserService';

interface FormDataTypes {
  name: string;
  newPassword: string;
  confirmPassword: string;
}

const SpecialRegister = () => {
  const authUser = useAuthStore(state => state.user);
  const setNotification = useNotificationStore(state => state.setNotification);
  const navigate = useNavigate();
  const [token, setToken] = useState<string>('');
  const decodedToken = token ? base64Decode(token) : null;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormDataTypes>();
  const { setAccessToken } = useAuthStore(state => state);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      setToken(token);
    }
  }, []);

  const signupUser = async (values: FormDataTypes) => {
    try {
      const { data } = await AuthService.register(
        {
          name: values.name,
          email: decodedToken.email,
          password: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
        token,
      );

      LocalStorageService.setItem('accessToken', data.accessToken);
      setAccessToken(data.accessToken);

      await UserService.updateUser({
        companyUserHasFinishedOnboarding: false,
      });
      navigate('/onboarding');
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <ReactConfetti
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          // height: '670px',
          width: '500px',
          padding: '32px',
          gap: '48px',
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid var(--Neutral-300, #D0D5DD)',
          boxShadow: '10px 10px 40px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <FluxPrompt />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box>
            <Typography
              fontSize={'32px'}
              fontWeight={500}
              color={'var(--Text-Secondary, #475467)'}
              lineHeight={'116.7%'}
            >
              Welcome to your FluxPrompt
            </Typography>
            <Typography
              fontSize={'32px'}
              fontWeight={500}
              color={'var(--Primary-500, #3650DE)'}
              lineHeight={'116.7%'}
            >
              {decodedToken?.plan || 'Starter'} Account
            </Typography>
          </Box>
          <Typography
            fontSize={'16px'}
            fontWeight={400}
            color={'var(--Text-Secondary, #475467)'}
            lineHeight={'175%'}
          >
            To continue, please set up a password for your account associated
            with this email address:{' '}
            <a href={`mailto:${decodedToken?.email}`}>
              {decodedToken && decodedToken.email}
            </a>
          </Typography>
        </Box>
        <form action="submit" onSubmit={handleSubmit(signupUser)}>
          <KitInput
            register={register}
            name="name"
            fullWidth={true}
            label="Name"
            sxStyle={{
              marginBottom: '20px',
            }}
            validation={{
              required: {
                value: true,
                message: 'Name is required',
              },
            }}
            errorsStatus={errors}
          />

          <KitInput
            label="New Password"
            name="newPassword"
            type="password"
            errorsStatus={errors}
            register={register}
            sxStyle={{ marginBottom: '20px' }}
            validation={{
              required: {
                value: true,
                message: 'password is required',
              },
              minLength: {
                value: 8,
                message: 'password must be at least 8 characters',
              },

              // pattern: {
              //   value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
              //   message:
              //     'password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
              // },
            }}
          />
          <KitInput
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            errorsStatus={errors}
            register={register}
            sxStyle={{ marginBottom: '20px' }}
            validation={{
              required: {
                value: true,
                message: 'Confirm Password is required',
              },

              validate: value =>
                value === watch('newPassword') || 'The passwords do not match',
            }}
          />
          <Button type="submit" variant="contained" sx={{ width: '100%' }}>
            Continue
          </Button>
        </form>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <BrandsLogin type={'register'} text="or" />
        </Box>
      </Box>
    </div>
  );
};

export default SpecialRegister;
