import { IGetOneResponse } from '../util/Types/Generic';
import { ApiService } from './ApiService';

export class ScheduleService extends ApiService {
  static pathName = '/schedule';

  static getSchedules = () => {
    return ApiService.get(`${this.pathName}`);
  };

  static getOneSchedule = (id: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/${id}`);
  };

  static addSchedule = (body: {
    name: string;
    interval: string;
    flowId: string;
    webhook: boolean;
    variableInputs?: { inputId: string; inputText: string }[];
  }) => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static updateSchedule = (body: {
    name: string;
    interval: string;
    id: string;
  }) => {
    return ApiService.patch(`${this.pathName}/update/${body.id}`, {
      name: body.name,
      interval: body.interval,
    });
  };

  static deleteSchedule = (id: string) => {
    return ApiService.delete(`${this.pathName}/delete/${id}`);
  };
}
