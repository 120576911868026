import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import { TokenUsage } from '../util/Types/TokenUsage';
import { ApiService } from './ApiService';

export class ReportService extends ApiService {
  static pathName = '/report';

  static getOverallUsage = (
    userId: string,
    query: string,
  ): Promise<
    IGetOneResponse<{
      [key: string]: TokenUsage[];
    }>
  > => {
    return ApiService.get(`${this.pathName}/${userId}?${query}`);
  };

  static getImagesUsage = (
    userId: string,
    query: string,
  ): Promise<IGetAllResponse<TokenUsage>> => {
    return ApiService.get(`${this.pathName}/images/${userId}?${query}`);
  };

  static getTextUsage = (
    userId: string,
    query: string,
  ): Promise<IGetAllResponse<TokenUsage>> => {
    return ApiService.get(`${this.pathName}/text/${userId}?${query}`);
  };

  static getAudioUsage = (
    userId: string,
    query: string,
  ): Promise<IGetAllResponse<TokenUsage>> => {
    return ApiService.get(`${this.pathName}/audio/${userId}?${query}`);
  };

  static getApiCallsUsage = (
    userId: string,
    query: string,
  ): Promise<IGetAllResponse<TokenUsage>> => {
    return ApiService.get(`${this.pathName}/api-calls/${userId}?${query}`);
  };

  static getCustomerUsage = async (userId: string, query?: string) => {
    return await ApiService.get(`${this.pathName}/user-report/${userId}?${query}`);
  };
}
