import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';

import { useDebounceEffect } from '../../util/useDebounceEffect';

import { NodeProps } from '../../util/Types/NodeProps';
import { options, viewsArrayOptions } from '../AiConfigs/constants';
import { updateOptions } from '../../util/imageGenUtilis';
import useFlowsStore from '../../store/storeFlows';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  Divider,
  ListSubheader,
} from '@mui/material';
import { imageGeneratorResponses } from '../ImageGenerator/responses';
import {
  FormData as ImageGeneratorFormData,
  aiImageGeneratorInputs,
  imageTypes,
  imgDefaultValues,
  versions,
} from '../ImageGenerator/constants';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import { CaretDown, Faders } from '@phosphor-icons/react';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputImage from '../UI/OutputImage/OutputImage';
import TagInput from '../Test/TagInput';
import SettingsModal from '../ImageGenerator/SettingsModal/SettingsModal';
import { isActiveEdge } from '../../util/findActiveHandle';
import UploadBox from '../UI/UploadBox/UploadBox';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import MultiSelectTone from '../UI/MultiSelect/MultiSelectTone';
import { base64ToBlob, getAPIErrorMessage } from '../../helpers/helpers';
import { UploadService } from '../../service/UploadService';
import { FileService } from '../../service/FileService';
import MaskImageEditModal from '../MaskImageEditModal/MaskImageEditModal';
import ExpandImageModal from '../ExpandImageModal/ExpandImageModal';
import SearchableSelect from '../SearchableSelect';

const aspectRatioOptions = [
  { label: '1:1', value: '1:1' },
  { label: '2:3', value: '2:3' },
  { label: '3:2', value: '3:2' },
  { label: '4:5', value: '4:5' },
  { label: '5:4', value: '5:4' },
  { label: '9:16', value: '9:16' },
  { label: '9:21', value: '9:21' },
  { label: '16:9', value: '16:9' },
  { label: '21:9', value: '21:9' },
];

const types = [
  {
    name: 'Create Image',
    value: 'createImage',
  },
  {
    name: 'Edit Image',
    value: 'editImage',
  },
  {
    name: 'Image Variation',
    value: 'imageVariation',
  },
];

const stabilityAiTypeCategories = [
  {
    title: 'Create Image',
    options: [{ label: 'Text to Image' }, { label: 'Image to image' }],
  },
  {
    title: 'Edit Image',
    options: [
      { label: 'AI Edit' },
      { label: 'AI erase' },
      { label: 'Enhance resolution' },
      { label: 'Expand Image' },
    ],
  },
];

const ideogramAiTypeCategories = [
  {
    title: 'Create Image',
    options: [{ label: 'Text to Image' }, { label: 'Image to image' }],
  },
  {
    title: 'Edit Image',
    options: [{ label: 'Expand Image' }],
  },
];

function ImageGenerator({ id, data, type }: NodeProps) {
  const setNotifications = useNotificationStore(state => state.setNotification);
  const [isLoading, setIsLoading] = useState(false);
  const [multipleUploadFiles] = useState<FileList | null>();
  const edges = useEdges();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [previewResponses, setPreviewResponses] = useState([]);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const { user } = useAuthStore(state => state);

  const [collapsed, setCollapsed] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();
  const [showExpandImageModal, setShowExpandImageModal] = useState(false);

  const { schema, setSaving, flowId, setSchema } = useFlowsStore(
    state => state,
  );

  const [formData, setFormData] = useState<ImageGeneratorFormData>({
    ...imgDefaultValues,
    inputPreview: '',
    uploadImage: null,
  });
  const [showMaskImageEditModal, setShowMaskImageEditModal] = useState(false);

  const [hasToExecute, setHasToExecute] = useState(true);

  useDebounceEffect(
    () => {
      if (!isChangingDirectly) {
        setFormData({
          ...imgDefaultValues,
          ...data,
          engineId:
            data?.engineId === 'stability-ai-text-to-image'
              ? formData.engineId
              : data.engineId,

          src: data.text,
        });
        if (data?.previewResponses?.length > 0) {
          setPreviewResponses(data.previewResponses);
        }
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const promptTypes = [
    'subject',
    'environment',
    'tone',
    'view',
    'style',
    'prompt',
  ];
  const promptObjects = promptTypes.map(type => ({
    name: type,
    placeholder: type.charAt(0).toUpperCase() + type.slice(1),
  }));
  const onSubmit = async (e?: { preventDefault: () => void } | null) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      let newFormData: any = { ...formData };

      imageTypes.forEach(type => {
        if (newFormData[type + 'Data'] && newFormData[type + 'Data']?.length) {
          newFormData[type] = newFormData[type + 'Data']
            ?.map((data: { text: string }) => data.text)
            ?.join(' \n');
        }
      });

      const response: any = await imageGeneratorResponses(
        newFormData,
        schema,
        multipleUploadFiles,
        promptObjects,
        flowId,
      );

      let responses = [...previewResponses];

      if (responses.length === 10) {
        responses.shift();
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowId,
        objectData: {
          text: response.message,
          previewIndex: responses?.length,
          previewResponses: [...responses, response.message],
          usedTokens: data?.flowTrigger?.id ? response.usedTokens : undefined,
          nodeUsedTokens: response.usedTokens,
        },
        setSchema,
      });

      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 1000);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotifications,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setIsLoading(true);
      setNotifications({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (e: { target: { name: any; value: any } }) => {
    if (e.target.name === 'sizeGen') {
      const size = e.target.value.split('x');

      setFormData({
        ...formData,
        sizeGenerate: e.target.value,
        height: +size[1],
        width: +size[0],
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          sizeGenerate: e.target.value,
          height: +size[1],
          width: +size[0],
        },
        setSchema,
      });

      return;
    }

    if (e.target.name === 'engineId') {
      const findIndex = versions.findIndex(
        version => version.version === e.target.value,
      );

      const size = versions?.[findIndex]?.dimensions[0]?.split('x');

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        height: +size?.[1],
        width: +size?.[0],
        sizeGenerate: versions?.[findIndex]?.dimensions?.[0],
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          engineId: e.target.value,
          sizeGenerate: versions?.[findIndex]?.dimensions?.[0],
          height: size?.[1],
          width: size?.[0],
        },
        setSchema,
      });

      return;
    }

    if (e.target.name === 'type') {
      const itsUpscale = e.target.value === 'Upscale Image';

      const engineId = itsUpscale
        ? 'esrgan-v1-x2plus'
        : formData.engineId === 'esrgan-v1-x2plus'
        ? 'stable-diffusion-xl-1024-v1-0'
        : formData.engineId;

      const findIndex = versions.findIndex(
        version => version.version === engineId,
      );

      const size = versions?.[findIndex]?.dimensions?.[0]?.split('x');
      let objectData: any = {
        engineId: engineId,
        type: e.target.value,
        sizeGenerate: itsUpscale
          ? formData.sizeGenerate
          : versions?.[findIndex]?.dimensions?.[0],
        height: itsUpscale ? formData.height : size?.[1],
        width: itsUpscale ? formData.width : size?.[0],
        maskInputPreview: undefined,
        maskInputPreviewToShow: undefined,
        dimensions: undefined,
        expandedImagePreview: undefined,
      };

      if (e.target.value === 'AI Edit') {
        objectData['aiEditSelectedType'] = 'using mask';
      } else if (e.target.value === 'AI erase') {
        objectData['aiEraseSelectedType'] = 'remove objects';
      } else if (e.target.value === 'Enhance resolution') {
        objectData['selectedEnhancedResolution'] = '4k';
      } else if (e.target.value === 'Image to image') {
        objectData['imageToImageSelectedType'] = 'style';
        objectData['selectedImageToImageAspectRatio'] = '1:1';
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: objectData,
        setSchema,
      });
      setFormData({
        ...formData,
        ...objectData,
      });

      return;
    }

    if (e.target.name === 'influenceStrength') {
      setFormData({
        ...formData,
        influenceStrength: e.target.value,
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          influenceStrength: e.target.value,
        },
        setSchema,
      });

      return;
    }

    setIsChangingDirectly(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      whoIsChanging: e.target.name,
    });
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly)
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [formData.whoIsChanging]:
              formData[formData.whoIsChanging as keyof ImageGeneratorFormData],
          },
          setSchema,
        });
      setIsChangingDirectly(false);
    },
    [
      isChangingDirectly,
      formData[formData.whoIsChanging as keyof ImageGeneratorFormData],
    ],
    500,
  );

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  const onChangeTone = useCallback(
    (value: any[]) => {
      const test = updateOptions(
        formData.toneDefaultValues,
        value,
        [
          ...options.color,
          ...options.lighting,
          data?.customToneValues?.options,
        ],
        formData.tone,
      );

      const checkIfFirstElementIsAnComma = test[0] === '';

      if (checkIfFirstElementIsAnComma) {
        test.shift();
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          toneDefaultValues: value,
          tone: test.join(','),
        },
        setSchema,
      });
    },
    [formData],
  );

  const onAddCustomOptions = useCallback(
    (value: any[], name: string) => {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          [name]: value,
        },
        setSchema,
      });
    },
    [formData],
  );

  const onChangeView = useCallback(
    (value: any[]) => {
      // we have options as a constant we have to check if a new text is added to the default values

      const test = updateOptions(
        formData.viewDefaultValues,
        value,
        [
          ...options.color,
          ...options.lighting,
          data?.customViewValues?.options,
        ],
        formData.view,
      );

      const checkIfFirstElementIsAnComma = test[0] === '';

      if (checkIfFirstElementIsAnComma) {
        test.shift();
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          viewDefaultValues: value,
          view: test.join(','),
        },
        setSchema,
      });
    },
    [formData],
  );

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            setSaving,
            changeType: 'changingPreviewIndex',
            flowId,
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  const onChangePlacement = (name: string, value: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      changeType: 'changingPlacement',
      flowId,
      objectData: {
        [name]: value,
      },
      setSchema,
    });
  };

  const filteredTypes =
    formData.model === 'dall-e-3'
      ? types.filter(type => type.value === 'createImage')
      : formData.model === 'dall-e-2'
      ? types.filter(type => type.value !== 'createImage')
      : types;

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const changeModelBasedOnSystem = (system: string) => {
    switch (system) {
      case 'stabilityAi':
        return formData.type !== 'Text to Image' ? 'sd3' : formData.engineId;
      case 'openAi':
        return formData.model;
      case 'openSources':
        return formData.openSourceModel;
      case 'ideogram':
        return formData.ideogramModel;
      case 'fal':
        return formData.falModel;

      default:
        break;
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        console.log('No file selected');
        return;
      }
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);
      await FileService.createFormFIle({
        name: response?.data.name,
        src: response.data.src,
        flowId: flowId,
      });
      if (response.data && response.data.src) {
        setFormData(prevState => {
          const newState = {
            ...prevState,
            uploadImage: file,
            inputPreview: response.data.src,
            maskInputPreview: undefined,
            maskInputPreviewToShow: undefined,
            dimensions: undefined,
            expandedImagePreview: undefined,
            src: response.data.src,
          };
          return newState;
        });

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            uploadImage: file,
            inputPreview: response.data.src,
            maskInputPreview: undefined,
            maskInputPreviewToShow: undefined,
            src: response.data.src,
            dimensions: undefined,
            expandedImagePreview: undefined,
          },
          setSchema,
          newEdges: edges,
        });
      } else {
        console.error('Invalid response from server:', response);
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setNotifications({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const switchImage = (imageUrl: string) => {
    if (imageUrl) {
      setFormData({
        ...formData,
        inputPreview: imageUrl,
        maskInputPreview: undefined,
        maskInputPreviewToShow: undefined,
        image_strength:
          formData?.type === 'Image to image'
            ? 0.5
            : formData.type === 'Expand Image'
            ? 0.9
            : 0,

        dimensions: undefined,
        expandedImagePreview: undefined,
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          inputPreview: imageUrl,
          maskInputPreview: undefined,
          maskInputPreviewToShow: undefined,
          dimensions: undefined,
          image_strength:
            formData?.type === 'Image to image'
              ? 0.5
              : formData.type === 'Expand Image'
              ? 0.9
              : 0,

          expandedImagePreview: undefined,
        },
        setSchema,
      });
    }
  };

  const onChangeOnExecute = (e: any) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        clearOnExecution: e.target.checked,
      },
      setSchema,
    });
  };

  const onAiEditTypeChange = (type: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        aiEditSelectedType: type,
      },
      setSchema,
    });
  };

  const onAiEraseTypeChange = (type: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        aiEraseSelectedType: type,
      },
      setSchema,
    });
  };

  const deleteImage = () => {
    setFormData({
      ...formData,
      uploadImage: null,
      inputPreview: '',
      maskInputPreview: undefined,
      maskInputPreviewToShow: undefined,
      dimensions: undefined,
      expandedImagePreview: undefined,
    });

    if (formData.inputPreview) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId,
        objectData: {
          inputPreview: '',
          maskInputPreview: undefined,
          maskInputPreviewToShow: undefined,
          dimensions: undefined,
          expandedImagePreview: undefined,
        },
        setSchema,
      });
    }
  };

  const updatePreview = async (modifiedUrl: string, url: string) => {
    const modifiedBlob = base64ToBlob(modifiedUrl, 'image/png');
    const blob = base64ToBlob(url, 'image/png');

    const randomUrl = Math.random().toFixed(10).toString();
    const modifiedFormData = new FormData();
    modifiedFormData.append('file', modifiedBlob, 'modified-mask' + randomUrl);

    const originalFormData = new FormData();
    originalFormData.append('file', blob, 'original-mask' + randomUrl);

    const [{ data: modifiedData }, { data: originalData }] = await Promise.all([
      UploadService.uploadFile(modifiedFormData),
      UploadService.uploadFile(originalFormData),
    ]);

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        maskInputPreview: modifiedData.src,
        maskInputPreviewToShow: originalData.src,
      },
      setSchema,
    });
  };

  const getUploadBoxValue = () => {
    if (!formData.inputPreview) return undefined;
    if (
      formData.type === 'AI Edit' &&
      formData.aiEditSelectedType === 'using mask' &&
      formData.maskInputPreviewToShow
    )
      return formData.maskInputPreviewToShow;

    if (
      formData.type === 'AI erase' &&
      formData.aiEraseSelectedType === 'remove objects' &&
      formData.maskInputPreviewToShow
    )
      return formData.maskInputPreviewToShow;

    return formData.inputPreview;
  };

  const saveExpandedImage = async (
    imageUrl: string,
    dimensions: {
      top: number;
      left: number;
      bottom: number;
      right: number;
    },
  ) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        expandedImagePreview: imageUrl,
        dimensions,
      },
      setSchema,
    });
    setShowExpandImageModal(false);
  };

  const onEnhanceResolutionChange = (resolution: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        selectedEnhancedResolution: resolution,
      },
      setSchema,
    });
  };

  const aspectRationChangeHandler = (aspectRatio: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        selectedImageToImageAspectRatio: aspectRatio,
      },
      setSchema,
    });
  };

  const onImageToImageTypeChange = (value: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        imageToImageSelectedType: value,
      },
      setSchema,
    });
  };

  const checkAddMask = () => {
    if (
      formData.type === 'AI erase' &&
      formData.aiEraseSelectedType === 'remove objects'
    )
      return true;

    if (
      formData.type === 'AI Edit' &&
      formData.aiEditSelectedType === 'using mask'
    )
      return true;

    return false;
  };

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <>
      {showMaskImageEditModal && formData.inputPreview && (
        <MaskImageEditModal
          imageUrl={formData.inputPreview}
          onHideModal={() => setShowMaskImageEditModal(false)}
          onSave={(modifiedUrl: string, url: string) => {
            updatePreview(modifiedUrl, url);

            setShowMaskImageEditModal(false);
          }}
          type={formData.type === 'AI Edit' ? 'using mask' : 'remove objects'}
        />
      )}

      {showExpandImageModal && formData.inputPreview && (
        <ExpandImageModal
          imageUrl={formData.inputPreview}
          onHideModal={() => setShowExpandImageModal(false)}
          onSave={(
            imageUrl: string,
            dimensions: {
              top: number;
              left: number;
              bottom: number;
              right: number;
            },
          ) => saveExpandedImage(imageUrl, dimensions)}
          initialDimensions={formData.dimensions}
        />
      )}

      <Box className={isLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          btnText="Run Prompt"
          onSubmit={onSubmit}
          isLoading={isLoading}
          data={data}
          handleRun={handleRun}
          id={id}
          onChangePlacement={onChangePlacement}
        >
          {!data?.collapsed && (
            <Box display={'flex'}>
              <Box
                p={'16px'}
                borderRight={`1px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'8px'}
                  mb={'8px'}
                >
                  {formData.aiModels === 'stabilityAi' && (
                    <>
                      <Select
                        value={formData?.type ?? 'Text To Image'}
                        name="type"
                        onChange={onChange}
                        size="small"
                        className="nodrag"
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : 'white',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: '1px solid rgba(234, 236, 240, 1)',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor: user?.theme === 'dark' ? '#101828' : 'white',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          // paddingRight: '60px',
                          width: '200px',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : 'white',
                            },
                          },
                        }}
                      >
                        {stabilityAiTypeCategories.map((el: any, i: number) => {
                          const {
                            title,
                            options,
                          }: {
                            title: string;
                            options: any;
                          } = el;
                          return [
                            <ListSubheader
                              sx={{
                                fontWeight: 600,
                                color: 'rgba(16, 24, 40, 1)',
                              }}
                              key={i + title}
                            >
                              {title}
                            </ListSubheader>,

                            options.map((option: any, index: number) => [
                              <MenuItem
                                key={index + option.label}
                                value={option.label}
                                sx={{
                                  color:
                                    user?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : 'rgba(71, 84, 103, 1)',
                                  background: 'transparent',
                                  margin: '5px 0',
                                }}
                              >
                                {option.label}
                              </MenuItem>,
                              index === options.length - 1 && i === 0 && (
                                <Divider />
                              ),
                            ]),
                          ];
                        })}
                      </Select>

                      {formData.type === 'Text to Image' &&
                        ![
                          'sd3',
                          'core',
                          'stability-ultra',
                          'sd3.5-large',
                          'sd3.5-large-turbo',
                        ].includes(formData.engineId) && (
                          <Select
                            value={formData.sizeGenerate}
                            onChange={onChange}
                            name="sizeGen"
                            size="small"
                            className="nodrag"
                            sx={{
                              '& .MuiList-root': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#101828'
                                    : '#F9FAFB',
                              },
                              '& .MuiSvgIcon-root': {
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#101828',
                              },
                              borderRadius: '8px',
                              border: 'none',
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              bgcolor:
                                user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  '& .Mui-selected': {
                                    backgroundColor:
                                      user?.theme === 'dark'
                                        ? '#667085'
                                        : '#D0D5DD',
                                  },
                                  backgroundColor:
                                    user?.theme === 'dark'
                                      ? '#1D2939'
                                      : '#F9FAFB',
                                },
                              },
                            }}
                          >
                            {versions
                              .find(
                                version =>
                                  version.version === formData?.engineId,
                              )
                              ?.dimensions?.map((size, index) => (
                                <MenuItem
                                  key={index}
                                  value={`${size}`}
                                  sx={{
                                    color:
                                      user?.theme === 'dark'
                                        ? '#D0D5DD'
                                        : 'black',
                                  }}
                                >
                                  {size}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                    </>
                  )}

                  {formData.aiModels === 'ideogram' && (
                    <>
                      <Select
                        value={formData.type || 'Text To Image'}
                        name="type"
                        onChange={onChange}
                        size="small"
                        className="nodrag"
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : 'white',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: '1px solid rgba(234, 236, 240, 1)',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor: user?.theme === 'dark' ? '#101828' : 'white',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          // paddingRight: '60px',
                          width: '200px',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : 'white',
                            },
                          },
                        }}
                      >
                        {ideogramAiTypeCategories.map((el: any, i: number) => {
                          const {
                            title,
                            options,
                          }: {
                            title: string;
                            options: any;
                          } = el;
                          return [
                            <ListSubheader
                              sx={{
                                fontWeight: 600,
                                color: 'rgba(16, 24, 40, 1)',
                              }}
                              key={i + title}
                            >
                              {title}
                            </ListSubheader>,

                            options.map((option: any, index: number) => [
                              <MenuItem
                                key={index + option.label}
                                value={option.label}
                                sx={{
                                  color:
                                    user?.theme === 'dark'
                                      ? '#D0D5DD'
                                      : 'rgba(71, 84, 103, 1)',
                                  background: 'transparent',
                                  margin: '5px 0',
                                }}
                              >
                                {option.label}
                              </MenuItem>,
                              index === options.length - 1 && i === 0 && (
                                <Divider />
                              ),
                            ]),
                          ];
                        })}

                        {/* {stabilityAiType.map((type, index) => (
                          <MenuItem
                            key={index}
                            value={type}
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            {type}
                          </MenuItem>
                        ))} */}
                      </Select>

                      {formData.type === 'Text to Image' &&
                        !['sd3', 'core', 'stability-ultra'].includes(
                          formData.engineId,
                        ) && (
                          <Select
                            value={formData.sizeGenerate}
                            onChange={onChange}
                            name="sizeGen"
                            size="small"
                            className="nodrag"
                            sx={{
                              '& .MuiList-root': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#101828'
                                    : '#F9FAFB',
                              },
                              '& .MuiSvgIcon-root': {
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#101828',
                              },
                              borderRadius: '8px',
                              border: 'none',
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              bgcolor:
                                user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  '& .Mui-selected': {
                                    backgroundColor:
                                      user?.theme === 'dark'
                                        ? '#667085'
                                        : '#D0D5DD',
                                  },
                                  backgroundColor:
                                    user?.theme === 'dark'
                                      ? '#1D2939'
                                      : '#F9FAFB',
                                },
                              },
                            }}
                          >
                            {versions
                              .find(
                                version =>
                                  version.version === formData?.engineId,
                              )
                              ?.dimensions?.map((size, index) => (
                                <MenuItem
                                  key={index}
                                  value={`${size}`}
                                  sx={{
                                    color:
                                      user?.theme === 'dark'
                                        ? '#D0D5DD'
                                        : 'black',
                                  }}
                                >
                                  {size}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                    </>
                  )}

                  {formData.aiModels === 'openAi' && (
                    <>
                      <Select
                        value={formData.openAiModels}
                        onChange={onChange}
                        className="nodrag"
                        name="openAiModels"
                        sx={{
                          borderRadius: '8px',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          height: 'unset',
                          bgcolor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          border: `1px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                          style: {
                            maxHeight: '700px',
                          },
                        }}
                      >
                        {filteredTypes.map(type => (
                          <MenuItem
                            key={type.value}
                            value={type.value}
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        onChange={onChange}
                        name="size"
                        value={formData.size}
                        size="small"
                        sx={{
                          borderRadius: '8px',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          border: `1px solid ${
                            user?.theme === 'dark' ? '#475467' : '#EAECF0'
                          }`,
                        }}
                        className="nodrag"
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                          style: {
                            maxHeight: '700px',
                          },
                        }}
                      >
                        <MenuItem
                          value={'1024x1024'}
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          1024x1024
                        </MenuItem>
                        <MenuItem
                          value={'1024x1792'}
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          1024x1792
                        </MenuItem>
                        <MenuItem
                          value={'1792x1024'}
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          1792x1024
                        </MenuItem>
                      </Select>
                    </>
                  )}
                </Box>

                {formData.type === 'Image to image' &&
                  formData.aiModels === 'stabilityAi' && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Box
                        onClick={() => onImageToImageTypeChange('style')}
                        sx={{
                          background:
                            formData.imageToImageSelectedType === 'style'
                              ? 'rgba(149, 117, 206, 1)'
                              : 'rgba(242, 244, 247, 1)',
                          color:
                            formData.imageToImageSelectedType !== 'style'
                              ? 'rgba(71, 84, 103, 1)'
                              : 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography>style</Typography>
                      </Box>
                      <Box
                        onClick={() => onImageToImageTypeChange('structure')}
                        sx={{
                          background:
                            formData.imageToImageSelectedType === 'structure'
                              ? 'rgba(149, 117, 206, 1)'
                              : 'rgba(242, 244, 247, 1)',
                          color:
                            formData.imageToImageSelectedType !== 'structure'
                              ? 'rgba(71, 84, 103, 1)'
                              : 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography>structure</Typography>
                      </Box>{' '}
                      <Box
                        onClick={() => onImageToImageTypeChange('sketch')}
                        sx={{
                          background:
                            formData.imageToImageSelectedType === 'sketch'
                              ? 'rgba(149, 117, 206, 1)'
                              : 'rgba(242, 244, 247, 1)',
                          color:
                            formData.imageToImageSelectedType !== 'sketch'
                              ? 'rgba(71, 84, 103, 1)'
                              : 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '10px',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography>sketch</Typography>
                      </Box>
                    </Box>
                  )}

                {formData.type === 'AI Edit' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <Box
                      onClick={() => onAiEditTypeChange('using mask')}
                      sx={{
                        background:
                          formData.aiEditSelectedType === 'using mask'
                            ? 'rgba(149, 117, 206, 1)'
                            : 'rgba(242, 244, 247, 1)',
                        color:
                          formData.aiEditSelectedType !== 'using mask'
                            ? 'rgba(71, 84, 103, 1)'
                            : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography>using mask</Typography>
                    </Box>
                    <Box
                      onClick={() => onAiEditTypeChange('using search prompt')}
                      sx={{
                        background:
                          formData.aiEditSelectedType === 'using search prompt'
                            ? 'rgba(149, 117, 206, 1)'
                            : 'rgba(242, 244, 247, 1)',
                        color:
                          formData.aiEditSelectedType !== 'using search prompt'
                            ? 'rgba(71, 84, 103, 1)'
                            : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography>using search prompt</Typography>
                    </Box>{' '}
                    <Box
                      onClick={() => onAiEditTypeChange('recolor using search')}
                      sx={{
                        background:
                          formData.aiEditSelectedType === 'recolor using search'
                            ? 'rgba(149, 117, 206, 1)'
                            : 'rgba(242, 244, 247, 1)',
                        color:
                          formData.aiEditSelectedType !== 'recolor using search'
                            ? 'rgba(71, 84, 103, 1)'
                            : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography>recolor using search</Typography>
                    </Box>
                  </Box>
                )}

                {formData.type === 'AI erase' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <Box
                      onClick={() => onAiEraseTypeChange('remove objects')}
                      sx={{
                        background:
                          formData.aiEraseSelectedType === 'remove objects'
                            ? 'rgba(149, 117, 206, 1)'
                            : 'rgba(242, 244, 247, 1)',
                        color:
                          formData.aiEraseSelectedType !== 'remove objects'
                            ? 'rgba(71, 84, 103, 1)'
                            : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography>remove objects</Typography>
                    </Box>
                    <Box
                      onClick={() => onAiEraseTypeChange('remove background')}
                      sx={{
                        background:
                          formData.aiEraseSelectedType === 'remove background'
                            ? 'rgba(149, 117, 206, 1)'
                            : 'rgba(242, 244, 247, 1)',
                        color:
                          formData.aiEraseSelectedType !== 'remove background'
                            ? 'rgba(71, 84, 103, 1)'
                            : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        borderRadius: '8px',
                      }}
                    >
                      <Typography>remove background</Typography>
                    </Box>{' '}
                  </Box>
                )}

                {((formData.openAiModels === 'editImage' &&
                  formData.aiModels === 'openAi') ||
                  (formData.type === 'AI Edit' &&
                    formData.aiModels === 'stabilityAi') ||
                  (formData.type === 'Image to image' &&
                    formData.aiModels === 'stabilityAi') ||
                  (formData.type === 'Enhance resolution' &&
                    formData.aiModels === 'stabilityAi') ||
                  (formData.type === 'AI erase' &&
                    formData.aiModels === 'stabilityAi') ||
                  (formData.type === 'Expand Image' &&
                    formData.aiModels === 'stabilityAi') ||
                  (formData.type === 'Image to image' &&
                    formData.aiModels === 'stabilityAi') ||
                  (formData.type === 'Image to image' &&
                    formData.aiModels === 'ideogram') ||
                  (formData.type === 'Expand Image' &&
                    formData.aiModels === 'ideogram')) && (
                  <Box
                    sx={{
                      borderTop: '1px solid rgba(234, 236, 240, 1)',
                      paddingTop: '10px',
                    }}
                  >
                    <UploadBox
                      labelName="Image Input"
                      value={getUploadBoxValue()}
                      onChange={onChange}
                      name="inputPreview"
                      isActive={isActiveEdge(
                        edges,
                        id,
                        'inputPreview',
                        'target',
                      )}
                      id={id}
                      onChangeUploadImage={handleUpload}
                      findColor={findColor}
                      uploadedFile={formData.uploadImage}
                      handleId="inputPreview"
                      deleteImage={deleteImage}
                      addMask={checkAddMask()}
                      buttonText={
                        formData.type === 'AI Edit' ? 'Add Mask' : 'Edit'
                      }
                      onAddMask={() => setShowMaskImageEditModal(true)}
                      expandSettings={formData.type === 'Expand Image'}
                      onExpandSettings={() => setShowExpandImageModal(true)}
                      expandedImagePreview={formData.expandedImagePreview}
                      dimensions={formData.dimensions}
                      enhanceResolution={formData.type === 'Enhance resolution'}
                      selectedEnhancedResolution={
                        formData.selectedEnhancedResolution
                      }
                      onEnhanceResolutionChange={(e: any) =>
                        onEnhanceResolutionChange(e.target.value)
                      }
                      imageNodeType={formData.type}
                    />
                  </Box>
                )}

                {formData.type === 'AI erase' ||
                (formData.aiModels !== 'ideogram' &&
                  formData.type === 'Expand Image') ||
                formData.type === 'Enhance resolution'
                  ? null
                  : aiImageGeneratorInputs
                      .slice(1, 2)
                      .map(input => (
                        <TagInput
                          onChange={onChange}
                          name={input.name}
                          key={input.name}
                          nodeId={id}
                          labelName={
                            formData.type === 'AI Edit' &&
                            formData.aiModels === 'stabilityAi' &&
                            formData.aiEditSelectedType ===
                              'recolor using search'
                              ? 'Recolor Prompt'
                              : input.labelName
                          }
                          dataConnected={data?.[input.name + 'Data'] || []}
                          placeholder={input.placeholder}
                          value={
                            formData?.[input.name as keyof typeof formData] ||
                            ''
                          }
                          placement={data?.placement}
                          nodeLabel={data?.label}
                          handleId={input.handleId}
                          disabled={isActiveEdge(
                            edges,
                            id,
                            input.name,
                            'target',
                          )}
                          findColor={findColor}
                          isCollapsed={input.isCollapsed}
                          onChangePlacement={onChangePlacement}
                        />
                      ))}

                {formData.type === 'AI Edit' &&
                  formData.aiModels === 'stabilityAi' &&
                  formData.aiEditSelectedType === 'using search prompt' && (
                    <TagInput
                      onChange={onChange}
                      name={'searchPrompt'}
                      nodeId={id}
                      labelName={'Search Prompt'}
                      dataConnected={data?.searchPromptData || []}
                      placeholder={'Find the clouds in the image'}
                      value={
                        formData?.['searchPrompt' as keyof typeof formData] ||
                        ''
                      }
                      placement={data?.placement}
                      nodeLabel={data?.label}
                      handleId={'searchPrompt'}
                      isActive={isActiveEdge(
                        edges,
                        id,
                        'searchPrompt',
                        'target',
                      )}
                      findColor={findColor}
                      isCollapsed={true}
                      onChangePlacement={onChangePlacement}
                    />
                  )}

                {(formData.type === 'Image to image' ||
                  (formData.type === 'Text to Image' &&
                    ['sd3', 'core', 'stability-ultra'].includes(
                      formData.engineId,
                    ))) && (
                  <Box>
                    <Box sx={{ margin: '10px 0', marginBottom: '20px' }}>
                      <SearchableSelect
                        options={aspectRatioOptions}
                        label="Select Aspect Ratio"
                        onChange={value => {
                          const selectedValue = value ? value.value : '';
                          aspectRationChangeHandler(selectedValue);
                        }}
                        value={
                          aspectRatioOptions.find(
                            option =>
                              option?.value ===
                              formData.selectedImageToImageAspectRatio,
                          ) || null
                        }
                        className="nodrag"
                        style={{ width: 100, margin: '10px 0' }}
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: 'none',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                )}

                {formData.type === 'Image to image' && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      margin: '10px 0',
                      marginBottom: '20px',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        color: 'rgba(16, 24, 40, 1)',
                        fontWeight: 500,
                        fontSize: '14px',
                        marginTop: '2px',
                      }}
                    >
                      Influence Strength: {formData.influenceStrength}%
                    </Typography>
                    <input
                      type="range"
                      onChange={(e: any) => {
                        onChange({
                          target: {
                            name: 'influenceStrength',
                            value: e.target.value,
                          },
                        });
                      }}
                      style={{ width: '150px' }}
                      value={formData.influenceStrength}
                      className="nodrag"
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative',
                    '& svg': { cursor: 'pointer' },
                    mb: !collapsed ? 0 : '8px',
                  }}
                >
                  <CaretDown
                    onClick={() => setCollapsed(!collapsed)}
                    size={16}
                    color={!collapsed ? '#3650DE' : '#667085'}
                  />
                  <Typography
                    fontSize={'16px'}
                    color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                    fontWeight={600}
                  >
                    {'Advanced'}
                  </Typography>
                </Box>

                {aiImageGeneratorInputs.slice(2, 7).map(input => {
                  if (input?.name === 'tone') {
                    return (
                      <Box mb={collapsed ? '8px' : 0} key={input.name}>
                        <TagInput
                          onChange={onChange}
                          name={input.name}
                          key={input.name}
                          isAdvanced={true}
                          advancedCollapsed={collapsed}
                          nodeId={id}
                          disabled={isActiveEdge(
                            edges,
                            id,
                            input.name,
                            'target',
                          )}
                          labelName={input.labelName}
                          dataConnected={data?.[input.name + 'Data'] || []}
                          placeholder={input.placeholder}
                          value={
                            formData?.[input.name as keyof typeof formData] ||
                            ''
                          }
                          placement={data?.placement}
                          nodeLabel={data?.label}
                          handleId={input.handleId}
                          findColor={findColor}
                          isCollapsed={collapsed}
                          onChangePlacement={onChangePlacement}
                        />
                        {collapsed && (
                          <>
                            <MultiSelectTone
                              defaultValues={formData.toneDefaultValues}
                              defaultOptions={[
                                {
                                  label: 'Colors',
                                  options: options.color,
                                },

                                {
                                  label: 'Lighting',
                                  options: options.lighting,
                                },
                              ]}
                              customOptions={data.customToneValues}
                              onAddCustomOptions={(value: any[]) =>
                                onAddCustomOptions(value, 'customToneValues')
                              }
                              onChange={onChangeTone}
                            />
                          </>
                        )}
                      </Box>
                    );
                  }

                  if (input?.name === 'view') {
                    return (
                      <Box mb={collapsed ? '8px' : 0} key={input.name}>
                        <TagInput
                          onChange={onChange}
                          name={input.name}
                          key={input.name}
                          isAdvanced={true}
                          advancedCollapsed={collapsed}
                          nodeId={id}
                          disabled={isActiveEdge(
                            edges,
                            id,
                            input.name,
                            'target',
                          )}
                          labelName={input.labelName}
                          dataConnected={data?.[input.name + 'Data'] || []}
                          placeholder={input.placeholder}
                          value={
                            formData?.[input.name as keyof typeof formData] ||
                            ''
                          }
                          placement={data?.placement}
                          nodeLabel={data?.label}
                          handleId={input.handleId}
                          findColor={findColor}
                          isCollapsed={collapsed}
                          onChangePlacement={onChangePlacement}
                        />
                        {collapsed && (
                          <>
                            <MultiSelectTone
                              defaultValues={formData.viewDefaultValues}
                              defaultOptions={[
                                {
                                  label: 'Views',
                                  options: viewsArrayOptions.views,
                                },
                              ]}
                              customOptions={data.customViewValues}
                              onAddCustomOptions={(value: any) =>
                                onAddCustomOptions(value, 'customViewValues')
                              }
                              onChange={onChangeView}
                            />
                          </>
                        )}
                      </Box>
                    );
                  }

                  return (
                    <TagInput
                      onChange={onChange}
                      name={input.name}
                      key={input.name}
                      isAdvanced={true}
                      advancedCollapsed={collapsed}
                      nodeId={id}
                      disabled={isActiveEdge(edges, id, input.name, 'target')}
                      labelName={input.labelName}
                      dataConnected={data?.[input.name + 'Data'] || []}
                      placeholder={input.placeholder}
                      value={
                        formData?.[input.name as keyof typeof formData] || ''
                      }
                      placement={data?.placement}
                      nodeLabel={data?.label}
                      handleId={input.handleId}
                      findColor={findColor}
                      isCollapsed={collapsed}
                      onChangePlacement={onChangePlacement}
                    />
                  );
                })}

                {(formData.type === 'Image to image' ||
                  formData.aiModels === 'ideogram') && (
                  <TagInput
                    onChange={onChange}
                    name={'negativePrompt'}
                    key={'negativePrompt'}
                    isAdvanced={true}
                    advancedCollapsed={collapsed}
                    nodeId={id}
                    disabled={isActiveEdge(
                      edges,
                      id,
                      'negativePrompt',
                      'target',
                    )}
                    labelName={'Negative Prompt'}
                    dataConnected={data?.['negativePromptData'] || []}
                    placeholder={'Connect or type'}
                    value={
                      formData?.['negativePrompt' as keyof typeof formData] ||
                      ''
                    }
                    placement={data?.placement}
                    nodeLabel={data?.label}
                    handleId={'negativePrompt'}
                    findColor={findColor}
                    isCollapsed={collapsed}
                    onChangePlacement={onChangePlacement}
                  />
                )}

                <Divider
                  sx={{
                    mt: !collapsed ? '20px' : '200px',
                    mb: '10px',
                  }}
                />
                {data?.placement !== 'Output' && (
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                      borderRadius={'4px'}
                      sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                      onClick={() => setSettingsModalOpen(true)}
                    >
                      <Faders size={18} color="#667085" />
                      <Typography
                        fontSize={'12px'}
                        color="#667085"
                        variant="h6"
                      >
                        {changeModelBasedOnSystem(formData.aiModels)}
                      </Typography>

                      <CaretDown size={'16px'} color="#667085" />
                    </Box>
                  </Box>
                )}
                <SettingsModal
                  onHide={() => setSettingsModalOpen(false)}
                  show={settingsModalOpen}
                  formData={formData}
                  onChange={onChange}
                />
              </Box>
              <Box p={'16px 24px 16px 16px'}>
                <OutputImage
                  previewResponses={previewResponses}
                  previewIndex={data.previewIndex}
                  value={data.text}
                  activeHandle={
                    edges?.find(
                      (edge: any) =>
                        edge?.source === id &&
                        edge?.sourceHandle === 'outputPreview',
                    )?.source
                      ? true
                      : false
                  }
                  switchImage={switchImage}
                  placement={data?.placement}
                  labelName={'Output'}
                  handleId={'outputPreview'}
                  onPreview={onPreview}
                  findColor={findColor}
                  onChangePlacement={onChangePlacement}
                  onChangeOnExecute={onChangeOnExecute}
                  hasClearOnExecute={true}
                  clearOnExecution={data.clearOnExecution || false}
                />
              </Box>
            </Box>
          )}
        </Node>
      </Box>
    </>
  );
}

export default memo(ImageGenerator);
