import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ReactConfetti from 'react-confetti';
import { ReactComponent as FluxPrompt } from '../../assets/icons/FluxPrompt.svg';
import KitInput from '../../components/UI/KitInput';
import { useForm } from 'react-hook-form';
import { CheckCircle } from '@phosphor-icons/react/dist/ssr';
import { axiosInstance } from '../../service/ApiService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';

interface FormDataTypes {
  name: string;
  email: string;
}

interface Props {
  decodedToken: any;
  setShowSendInvite: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendInvite: React.FC<Props> = ({ decodedToken, setShowSendInvite }) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormDataTypes>();

  const [invitationSent, setInvitationSent] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState('');

  const sendInviteHandler = async (values: FormDataTypes) => {
    try {
      await axiosInstance.post('/auth/admin-invite', {
        name: values.name,
        email: values.email,
        plan: decodedToken?.plan || 'starter',
      });
      setInvitationSent(true);
      setInvitationEmail(values.email);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const renderInvitationSent = () => {
    return (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          // height: '670px',
          width: '40%',
          padding: '32px',
          gap: '28px',
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid var(--Neutral-300, #D0D5DD)',
          boxShadow: '10px 10px 40px 0px rgba(0, 0, 0, 0.10)',
          textAlign: 'center',
        }}
      >
        <FluxPrompt />
        <CheckCircle color="rgba(103, 187, 106, 1)" size={120} weight="fill" />
        <Typography
          color={'var(--Text-Secondary, #475467)'}
          sx={{ fontSize: '20px', lineHeight: '32px' }}
        >
          An invitation has been successfully sent to{' '}
          <span style={{ color: 'rgba(54, 80, 222, 1)' }}>
            {invitationEmail}
          </span>
          . Once the admin has created an account they can invite team members.
        </Typography>

        <Button
          variant="contained"
          onClick={() => setShowSendInvite(false)}
          fullWidth
        >
          Continue to Homepage
        </Button>
      </Box>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <ReactConfetti
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: -1,
        }}
      />
      {invitationSent ? (
        renderInvitationSent()
      ) : (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexDirection: 'column',
            // height: '670px',
            width: '500px',
            padding: '32px',
            gap: '48px',
            backgroundColor: 'white',
            borderRadius: '8px',
            border: '1px solid var(--Neutral-300, #D0D5DD)',
            boxShadow: '10px 10px 40px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <FluxPrompt />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box>
              <Typography
                fontSize={'32px'}
                fontWeight={500}
                color={'var(--Text-Secondary, #475467)'}
                lineHeight={'116.7%'}
              >
                Welcome to your FluxPrompt
              </Typography>
              <Typography
                fontSize={'32px'}
                fontWeight={500}
                color={'var(--Primary-500, #3650DE)'}
                lineHeight={'116.7%'}
              >
                {decodedToken?.plan || 'Starter'} Account
              </Typography>
            </Box>
            <Typography
              fontSize={'16px'}
              fontWeight={400}
              color={'var(--Text-Secondary, #475467)'}
              lineHeight={'175%'}
            >
              Fill out the name and email for the admin of the account.
            </Typography>
          </Box>
          <form action="submit" onSubmit={handleSubmit(sendInviteHandler)}>
            <KitInput
              register={register}
              name="name"
              fullWidth={true}
              label="Name"
              placeholder="Enter here"
              sxStyle={{
                marginBottom: '20px',
              }}
              validation={{
                required: {
                  value: true,
                  message: 'Name is required',
                },
              }}
              errorsStatus={errors}
            />

            <KitInput
              label="Email"
              name="email"
              type="email"
              placeholder="Enter here"
              errorsStatus={errors}
              register={register}
              sxStyle={{ marginBottom: '20px' }}
              validation={{
                required: {
                  value: true,
                  message: 'Email is required',
                },

                // pattern: {
                //   value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                //   message:
                //     'password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                // },
              }}
            />

            <Button type="submit" variant="contained" sx={{ width: '100%' }}>
              Continue
            </Button>
          </form>
        </Box>
      )}
    </div>
  );
};

export default SendInvite;
