import React, { memo, useState } from 'react';
import FormNode from '../Components/FormNode/FormNode';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { NodeProps, useReactFlow } from 'reactflow';
import { useDebounceEffect } from '../../../util/useDebounceEffect';

const SubmitButtonNode = ({ id, type, data }: NodeProps) => {
  const [formData, setFormData] = useState<{
    btnName: string;
    submitType: string;
    flows: any[];
  }>({
    btnName: '',
    submitType: data?.submitType || 'default',
    flows: data?.flows || [],
  });
  const [changes, setChanges] = useState<any>({
    directly: false,
    data: {},
  });
  //   Submit 'all flows'
  // Submit 'flow 1'
  // Submit 'flow 1' & 'flow 2' (etc.)
  const reactFlow = useReactFlow();

  const submitTypesBasedOnFlows = (flows: any[]) => {
    const submitTypes: any[] = [];

    // Always add "Submit to all flows" option
    submitTypes.push({
      name: 'Submit to all flows',
      value: 'default',
      flows: [], // Include all flow indices
    });

    // Add options for each flow and their combinations
    for (let i = 0; i < flows.length; i++) {
      for (let j = i; j < flows.length; j++) {
        // If i === j, it's a single flow, otherwise it's a range of flows
        const flowRange = flows.slice(i, j + 1);
        const flowNames = flowRange.map((_, idx) => `${_.name}`).join(' & ');

        submitTypes.push({
          name: `Submit ${flowNames}`,
          value: `submit_flow_${i + 1}_${j + 1}`, // Unique value for each combination
          flows: flowRange.map((_, index) => _.id), // Flow indices in the range
        });
      }
    }

    return submitTypes;
  };

  const onChangeFlow = (e: any, flows: any[]) => {
    const { value } = e.target;

    setFormData({
      ...formData,
      submitType: value,
      flows: flows,
    });

    setChanges({
      directly: true,
      data: {
        submitType: value,
        flows: flows,
      },
    });
  };

  useDebounceEffect(
    () => {
      if (changes.directly) {
        const nodes = reactFlow.getNodes();

        const mappedNodes = nodes.map(node => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                ...changes.data,
              },
            };
          }
          return node;
        });

        reactFlow.setNodes(mappedNodes);

        setChanges({ directly: false, data: {} });
      }
    },
    [changes],
    500,
  );

  const submitFlows = submitTypesBasedOnFlows(data?.flowsButtons || []);

  return (
    <FormNode id={id} type={type}>
      <Select
        name="submitType"
        // value={formData.selectedFlow || 'none'}
        // onChange={onChanges}
        value={formData.submitType}
        onChange={e =>
          onChangeFlow(
            e,
            submitFlows?.find(type => type.value === e.target.value)?.flows,
          )
        }
        className="nodrag"
        sx={{
          border: '1px solid #3650DE',
          color: '#3650DE',
          maxWidth: '148px',
          width: '100%',
          fontSize: '12px',
        }}
      >
        <MenuItem value={'none'} disabled>
          Choose Submit Flow Type
        </MenuItem>
        {submitFlows?.map((flow: any, index: number) => (
          <MenuItem
            key={index}
            // disabled={flows?.some(
            //   (n: any) => n?.selectedFlow === flow?.id && n?.id !== id,
            // )}
            value={flow?.value}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {flow?.name}
          </MenuItem>
        ))}
      </Select>

      <Button variant="contained">Submit</Button>
    </FormNode>
  );
};

export default memo(SubmitButtonNode);
