import React, { useEffect, useState } from 'react';
import './BrandsLogin.scss';
import { AuthService } from '../../service/AuthService';
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';

import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageService } from '../../service/LocalStorageService';
import { useAuthStore } from '../../store/storeAuth';
import { useNotificationStore } from '../../store/storeNotifications';
import { Box, Button, Typography } from '@mui/material';
import { FacebookLogo } from '@phosphor-icons/react';
import { AppleLogo, GoogleLogo } from '@phosphor-icons/react/dist/ssr';
type Props = {
  text?: string;
  type?: string;
  loginCallback?: () => Promise<void>;
};
const BrandsLogin = ({
  text = 'Or login with',
  type = 'login',
  loginCallback,
}: Props) => {
  const navigate = useNavigate();
  const setAccessToken = useAuthStore(state => state.setAccessToken);
  const { setNotification } = useNotificationStore(state => state);
  const [isError, setIsError] = useState('');
  const [token, setToken] = useState('');

  const login = useGoogleLogin({
    onSuccess: tokenResponse =>
      googleLoginAndRegister(tokenResponse.access_token, type),
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      setToken(token);
    }
  }, []);

  const handleAppleLogin = async (data: {
    authorization: { id_token: string };
    user: { name: { firstName: string } };
  }) => {
    try {
      const { id_token } = data?.authorization;
      const name = data?.user?.name?.firstName;

      if (id_token) {
        if (type === 'register') {
          const response = await AuthService.registerWithApple(
            {
              accessToken: id_token,
              name,
            },
            token,
          );
          LocalStorageService.setItem('accessToken', response.data.accessToken);
          setAccessToken(response.data.accessToken);

          navigate('/');
        } else if (type === 'login') {
          const response = await AuthService.loginWithApple({
            accessToken: id_token,
          });
          LocalStorageService.setItem('accessToken', response.data.accessToken);
          setAccessToken(response.data.accessToken);

          if (loginCallback) await loginCallback();
          navigate('/');
        }
      }
    } catch (err) {
      let error = err as any;
      if (error.message === 'Account not found!') {
        setIsError('Account not found! Please go to register page.');
      }
      if (error.message === 'Email already exists!') {
        setIsError('Email already exists! please go to login page.');
      } else if (
        error.message === 'This account is not registered with Apple!'
      ) {
        setIsError('This account is not registered with Apple!');
      }
    }
  };

  const googleLoginAndRegister = async (accessToken: string, type: string) => {
    try {
      if (type === 'register') {
        const response = await AuthService.registerWithGoogle(
          accessToken,
          token,
        );
        LocalStorageService.setItem('accessToken', response.data.accessToken);
        setAccessToken(response.data.accessToken);

        navigate('/');
      } else if (type === 'login') {
        const response = await AuthService.loginWithGoogle(accessToken);
        LocalStorageService.setItem('accessToken', response.data.accessToken);
        setAccessToken(response.data.accessToken);

        if (loginCallback) await loginCallback?.();
        navigate('/');
      }
    } catch (err) {
      let error = err as any;
      if (error.message === 'Account not found!') {
        setNotification({
          type: 'error',
          message: 'Account not found! Please go to register page.',
        });
      }

      if (error.message === 'User already exists!') {
        setIsError('User already exists! Please go to login page.');
      } else if (
        error.message === 'This account is not registered with Google!'
      ) {
        setIsError('This account is not registered with Google!');
      }
    }
  };

  //apple login causing error on localhost

  return (
    <Box className="brands-login">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
        className=" brands-login_other "
      >
        <Box className="brands-login_other--left-divider" />
        <Typography className="subtext text-secondary ">{text}</Typography>
        <Box className="brands-login_other--right-divider" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Button
          className=""
          onClick={() => login()}
          style={{
            border: '1px solid #3C5A99',
            color: '#3C5A99',
            gap: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <GoogleLogo size={15} />
          <span className="">Google</span>
        </Button>

        <AppleLogin
          render={renderProps => (
            <Button
              sx={{
                border: '1px solid #3C5A99',
                color: '#3C5A99',
                gap: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
              onClick={renderProps.onClick}
            >
              <AppleLogo weight="fill" size={15} />
              <span className="">Apple</span>
            </Button>
          )}
          usePopup={window.location.hostname !== 'localhost'}
          scope={'email name'}
          clientId={process.env.REACT_APP_APPLE_CLIENT_ID || ''}
          redirectURI={`${window.location.origin}/${type}`}
          callback={handleAppleLogin}
        />
      </Box>{' '}
      <br />
      {isError && (
        <span className="text-center mt-5 text-danger text-center">
          {isError}
        </span>
      )}
    </Box>
  );
};

export default BrandsLogin;
