import { Box, Typography } from '@mui/material';
import React from 'react';

const Image = ({ data }: any) => {
  return (
    <Box
      sx={{
        padding: '16px',
        height: 'fit-content',
        borderRadius: data?.fullData?.borderRadius || '0px',
        ...data?.style,

        bgcolor: data?.fullData?.bgColor || '#fff',
      }}
    >
      {data?.label && (
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#475467',
            ...data?.fullData?.titleStyle,
          }}
          variant="h1"
          mb={'8px'}
        >
          {data?.label ? data.label : 'Title Component'}
        </Typography>
      )}
      {data?.subtitle && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#98A2B3',
            ...data?.fullData?.subtitleStyle,
          }}
          mb={'8px'}
          variant="h1"
        >
          {data?.subtitle ? data.subtitle : 'Title Component'}
        </Typography>
      )}

      <Box>
        {data?.url && (
          <img
            style={{
              maxWidth: data?.style?.maxWidth,
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundPosition: 'center',
              height: '50%',
              backgroundSize: 'cover',
            }}
            src={data?.url ? data.url : ''}
            alt="test"
          />
        )}
      </Box>
    </Box>
  );
};
export default Image;
