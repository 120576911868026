import React, { useEffect, useState } from 'react';
import './Login.scss';
import { useNavigate, useLocation } from 'react-router';
import AuthCard from '../../components/auth/AuthCard';
import BrandsLogin from '../../components/auth/BrandsLogin';
import KitInput from '../../components/UI/KitInput';
import { useForm } from 'react-hook-form';
import { AuthService } from '../../service/AuthService';
import { useAuthStore } from '../../store/storeAuth';
import { LocalStorageService } from '../../service/LocalStorageService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { base64Decode } from '../../helpers/decodeToken';
import { CompanyService } from '../../service/CompanyService';
type IFormInput = {
  email: string;
  password: string;
};
const Login = () => {
  const navigate = useNavigate();
  const onNavigatePath = (path: string) => {
    navigate(`/${path}`);
  };
  const { setNotification } = useNotificationStore(state => state);

  const [tokenData, setTokenData] = useState<{
    token: string;
    decoded: {
      email: string;
    };
  }>({
    token: '',
    decoded: {
      email: '',
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  const { setAuthUser, setAccessToken } = useAuthStore(state => state);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      setTokenData({
        token: token!,
        decoded: base64Decode(token!),
      });
      setValue('email', base64Decode(token!).email);
    }
  }, []);

  const location = useLocation();

  const onSubmitLogin = async (values: IFormInput) => {
    try {
      const response = await AuthService.login(values.email, values.password);

      setAuthUser(response.data);
      LocalStorageService.setItem('accessToken', response.data.accessToken);
      setAccessToken(response.data.accessToken);

      const urlParams = new URLSearchParams(location.search).get('token');

      if (urlParams) {
        setTimeout(() => {
          navigate('/company/accept-invite?token=' + urlParams);
        }, 1000);
      } else {
        navigate('/');
      }
    } catch (error: any) {
      if (error.message === 'Password is incorrect!') {
        setError('password', {
          type: 'validate',
          message: error.message,
        });
        setNotification({
          type: 'error',
          message: error.message,
        });
      } else if (error.message === 'Email does not exist!') {
        setError('email', {
          type: 'validate',
          message: error.message,
        });

        setNotification({
          type: 'error',
          message: error.message,
        });
      } else {
        setNotification({
          type: 'error',
          message: error.message || 'Something went wrong',
        });
      }
    }
  };

  return (
    <Box
      className=" login"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AuthCard>
        <>
          <form className="login_form">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className="login_form_info"
            >
              <h4 className="login_form_info--text">Login</h4>
              <Typography className="login_form_info--subtext">
                New user?{' '}
                <Typography
                  role="button"
                  sx={{
                    color: '#3C5A99',
                    cursor: 'pointer',
                  }}
                  onClick={() => onNavigatePath('register')}
                >
                  Create Account
                </Typography>
              </Typography>
            </Box>
            <Box sx={{}}>
              <KitInput
                register={register}
                name="email"
                label="Email"
                sxStyle={{
                  marginBottom: '20px',
                }}
                type="email"
                validation={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                errorsStatus={errors}
              />
              <KitInput
                name={'password'}
                type="password"
                register={register}
                label="Password"
                navigateText="Forgot Password?"
                onNavigate={() => onNavigatePath('forgot-password')}
                errorsStatus={errors}
                displayEnd
                sxStyle={{
                  width: '100%',
                }}
                validation={{
                  required: {
                    value: true,
                    message: 'Password is required',
                  },
                }}
              />{' '}
            </Box>
            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
              <Checkbox name="rememberMe" />
              <Typography className="subtext">Remember me</Typography>
            </Box>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmitLogin)}
              type="submit"
              sx={{
                width: '100%',
                mt: '20px',
                backgroundColor: '#3C5A99',
                color: '#fff',
                mb: '20px',
                '&:hover': {
                  backgroundColor: '#3C5A99',
                },
              }}
            >
              Login
            </Button>
          </form>
          <BrandsLogin />
        </>
      </AuthCard>
    </Box>
  );
};

export default Login;
