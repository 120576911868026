import React, { useState } from 'react';
import VectorMemoryUploadModal from '../../../LargeMemory/UploadModal/VectorMemoryUploadModal';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CaretDown,
  CaretUp,
  HardDrives,
  NotePencil,
  Plus,
  Trash,
} from '@phosphor-icons/react';
import { User } from '../../../../util/Types/User';
import { VectorService } from '../../../../service/VectorService';
import { AuthService } from '../../../../service/AuthService';
import { ReactComponent as DotsThreeOutlineGrayIcon } from '../../../../assets/icons/DotsThreeOutlineGrayIcon.svg';
import { ReactComponent as DotsThreeOutlineWhiteIcon } from '../../../../assets/icons/DotsThreeOutlineWhiteIcon.svg';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';

const indexName = 'humanloop-demo';

type Props = {
  authUser: User;
  sidebarOpen: boolean;
  setAuthUser: (user: User) => void;
  setNotification: (notification: any) => void;
  handleCloseSideBarMobile: () => void;
  spaceId?: string;
  space?: any;
  modifySpaceVector?: (space: any) => void;
};
const Vector = ({
  authUser,
  sidebarOpen,
  setAuthUser,
  setNotification,
  handleCloseSideBarMobile,
  spaceId,
  space,
  modifySpaceVector,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [vectorCollapsed, setVectorCollapsed] = useState(false);
  const [selectVector, setSelectVector] = useState<any>(
    space?.id ? space?.namespaces?.[0] : authUser?.namespaces?.[0],
  );

  const [openVectorUploadModal, setOpenVectorUploadModal] = useState({
    open: false,
    type: '',
  });

  const onUploadDocument = async ({
    vectorName,
    files,
    type,
    formData,
    file
  }: {
    vectorName: string;
    files: File[] | string[];
    type: string;
    file?:File | string;
    formData: {
      name: string;
      selectedDocument: string;
      selectedFluxPromptObject: string;
      fluxPromptObjectText: string;
    };
  }) => {
    try {
      if (type === 'upload-from-computer') {
        if (Array.isArray(files) && files.length > 0) {
          const formData = new FormData();

          files.forEach(file => {
            formData.append('file', file as string | Blob);
          });

          await VectorService.addFile(
            {
              indexName,
              nameSpace: vectorName,
              spaceId: spaceId,
            },
            formData,
          );
        }
      }

      if (type === 'select-from-documents') {
        await VectorService.addFile({
          indexName,
          nameSpace: vectorName,
          spaceId,

          url: file as string,
        });
      }

      if (type === 'select-from-fluxprompt-object') {
        const formData = new FormData();
        formData.append('isFromPrevNode', 'true');
        formData.append('prevNodeTextGenerated', file as string);

        await VectorService.addFile(
          {
            indexName,
            nameSpace: vectorName,
            spaceId,
          },
          formData,
        );
      }
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
    } finally {
    }
  };

  const onDeleteUploadedDocument = async (fileName: string) => {
    handleClose();
    const result = window.confirm('Are you sure you want to delete this file?');

    if (!result) return;

    try {
      const res = await VectorService.deleteFile({
        nameSpace: selectVector.name,
        fileName,
        spaceId,
      });

      if (spaceId) {
        modifySpaceVector?.(space);
        return;
      }

      const res2 = await AuthService.getMe();

      setAuthUser(res2.data);

      setSelectVector(
        res2?.data?.namespaces?.find(
          (nameSpace: { name: string }) => nameSpace.name === selectVector.name,
        ),
      );

      setNotification({
        type: 'success',
        message: 'File deleted successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    }
  };

  const onDeleteNameSpace = async (nameSpace: string) => {
    handleClose();
    const result = window.confirm(
      'Are you sure you want to delete this vector?',
    );

    if (!result) return;
    try {
      const res = await VectorService.deleteNameSpace({
        nameSpace,
        indexName,
        spaceId,
      });

      if (spaceId) {
        modifySpaceVector?.(space);
        return;
      }

      const res2 = await AuthService.getMe();

      setAuthUser(res2.data);

      setOpenVectorUploadModal({
        open: false,
        type: '',
      });

      setSelectVector(null);

      setNotification({
        type: 'success',
        message: 'File deleted successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    }
  };

  const vectors = space?.id
    ? space?.namespaces || []
    : authUser?.namespaces || [];

  return (
    <>
      <Box
        className={`layouts_sidebar_menu--btn align-items-center `}
        onClick={() => setVectorCollapsed(!vectorCollapsed)}
        sx={{
          pt: '4px',
          display: 'flex',
          justifyContent: !sidebarOpen ? 'center' : 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          // color: authUser?.theme === 'dark' ? 'white' : 'black',
          // wifth: '100%',
          paddingLeft: '10px',
          // width: '15.1rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <Box
          className="menu menu--on-hover"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            paddingRight: '7px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            '&:hover': {
              backgroundColor:
                authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
              borderRadius: '4px',
            },
          }}
        >
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            <HardDrives
              className="active-menu"
              color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
              size={20}
              weight="regular"
              style={{ alignSelf: 'center' }}
            />
            {sidebarOpen && (
              <>
                <Typography
                  className="text-capitalize active-menu"
                  sx={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    '&:hover': {
                      color: '#3650DE',
                    },
                  }}
                >
                  Vector Memory
                </Typography>
              </>
            )}
          </Box>

          <Box sx={{ alignSelf: 'center', display: 'flex', gap: '8px' }}>
            <IconButtonTooltip
              title="Create new Vector"
              onClick={event => {
                event.stopPropagation();
                setOpenVectorUploadModal({
                  type: 'add',
                  open: true,
                });
              }}
            >
              <Plus
                size={16}
                className="menu-icons active-menu"
                style={{
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                  cursor: 'pointer',
                }}
              />
            </IconButtonTooltip>

            {sidebarOpen && (
              <IconButtonTooltip title="Collapse">
                {!vectorCollapsed ? (
                  <CaretDown
                    className="active-menu"
                    size={16}
                    color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  />
                ) : (
                  <CaretUp
                    className="active-menu"
                    size={16}
                    color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  />
                )}
              </IconButtonTooltip>
            )}
          </Box>
        </Box>
      </Box>
      <Collapse
        in={vectorCollapsed}
        timeout={500}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box
          style={{
            paddingLeft: '27px',
          }}
          gap={'8px'}
        >
          {vectors?.map((object: any, key: number) => (
            <Box
              className="menu menu--on-hover"
              sx={{
                paddingLeft: '20px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': {
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                  borderRadius: '4px',
                },
              }}
              key={key}
            >
              <Tooltip title={object?.name}>
                <Typography
                  variant="body1"
                  style={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                    fontSize: '16px',
                    margin: '0',
                    width: '13rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => {
                    handleCloseSideBarMobile();
                    setSelectVector(object);
                    setOpenVectorUploadModal({
                      open: true,
                      type: 'edit',
                    });
                  }}
                >
                  {object?.name}
                </Typography>
              </Tooltip>

              <IconButton size="medium" onClick={handleClick}>
                {authUser?.theme === 'dark' ? (
                  <DotsThreeOutlineWhiteIcon />
                ) : (
                  <DotsThreeOutlineGrayIcon />
                )}
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: '8px',
                    background:
                      authUser?.theme === 'dark' ? '#101828' : '#FFFF',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      px: '8px',
                      gap: '8px',
                      color: '#EAECF0',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() => {
                      setSelectVector(object);
                      setOpenVectorUploadModal({
                        open: true,
                        type: 'edit',
                      });
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <NotePencil
                        size={20}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                      <Typography
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'
                        }
                      >
                        Edit
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      color: '#b91c1c',
                      px: '8px',
                      gap: '8px',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? '#FFADB7' : '#fecdd3',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() => onDeleteNameSpace(selectVector?.name)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Trash size={20} color="#b91c1c" />
                      <Typography>Delete</Typography>
                    </Box>
                  </Button>
                </Box>
              </Popover>
            </Box>
          ))}
        </Box>
      </Collapse>
      <VectorMemoryUploadModal
        openVectorUploadModal={openVectorUploadModal}
        setOpenVectorUploadModal={setOpenVectorUploadModal}
        selectVector={selectVector}
        onUploadDocument={onUploadDocument}
        onDeleteUploadedDocument={onDeleteUploadedDocument}
        onDeleteNameSpace={onDeleteNameSpace}
      />
    </>
  );
};

export default Vector;
