import React, { useEffect, useRef, useState } from 'react';
import './ChatBot.scss';
import { useAuthStore } from '../../store/storeAuth';
import { FluxService } from '../../service/FluxService';
import { FlowService } from '../../service/FlowService';
import LoadingSpinner from '../UI/LoadingSpinner';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import {
  ArrowUp,
  CaretDown,
  Chats,
  CheckCircle,
  Copy,
  FilePdf,
  Gear,
  Link,
  NotePencil,
  Notebook,
  Pause,
  Play,
  Plus,
  TextAlignJustify,
  Trash,
  Upload,
  X,
  XCircle,
} from '@phosphor-icons/react';
import useAutosizeTextArea from './useAutoSizeTextArea';
import LightIcon from '../../assets/icons/LightIcon';
import { useStoreTabs } from '../../store/storeTabs';
import { useNotificationStore } from '../../store/storeNotifications';
import { User } from '../../util/Types/User';
import { processTextSegments } from '../../util/markdown';
import { HistoryList } from './HistoryList';
import ChatBotSessionModal from './ChatBotSessionModal';
import {
  differenceInDays,
  format,
  isToday,
  isYesterday,
  parseISO,
  setYear,
} from 'date-fns';
import OutputMarkDown from '../OutputMarkdown/OutputMarkDown';
import { UserService } from '../../service/UserService';
import PublishChatModal from './PublishChatModal/PublishChatModal';
import { UploadService } from '../../service/UploadService';
import { useDropzone } from 'react-dropzone';
import { useSocketStore } from '../../store/storeSocket';
import { useNavigate } from 'react-router-dom';

const chatExamples = [
  `What constraints should we consider in the development of a new website?`,
  `List the logistical challenges in organizing a large-scale corporate event?`,
  `Name effective strategies to engage sponsors in a charity event for a social cause?`,
  `What factors should I consider when creating a diversity and inclusion program?`,
];

type Props = {
  onCreateChat: () => void;
  authUser: User;
  sideBarOpenWidth: number;
};

const ChatBot = ({ onCreateChat, authUser, sideBarOpenWidth }: Props) => {
  const [chat, setChat] = useState<any[]>([]);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [publishChatModal, setPublishChatModal] = useState(false);
  const [session, setSession] = useState<string>();
  const [sessionName, setSessionName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<string>('default');
  const [autoPlay, setAutoPlay] = useState(false);

  const { socket } = useSocketStore(state => state);

  const [activeTab, setActiveTab] = useState<{
    id: string;
    name?: string;
    space?: string;
  }>({
    id: '',
    name: '',
    space: '',
  });
  const [expansionLevel, setExpansionLevel] = useState<
    'collapsed' | 'middle' | 'full' | 'closed'
  >('closed');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [chatTyping, setChatTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [text, setText] = useState('');
  const [flow, setFlow] = useState<any>();
  const [chatSettings, setChatSettings] = useState({
    settings: false,
    flow: false,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [filePreview, setFilePreview] = useState<any>(null);

  const lastItemRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const setNotification = useNotificationStore(state => state.setNotification);
  const tabs = useStoreTabs((state: any) => state.tabs);
  const addTab = useStoreTabs(state => state.addTab);
  const { user, fluxBots, setFluxBots, setAuthUser } = useAuthStore(
    state => state,
  );
  const navigate = useNavigate();
  const selectedChatFind = chat?.find((chat: any) => chat?.id === session);
  const currentUrl = window.location.href;
  const baseUrl = window.location.origin;
  const cleanCurrentUrl = currentUrl.endsWith('/')
    ? currentUrl.slice(0, -1)
    : currentUrl;
  const cleanBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  const calculateWidth = () => {
    return windowWidth - sideBarOpenWidth;
  };

  const calculateHeight = () => {
    return windowHeight - 46;
  };

  const toggleExpansionLevel = () => {
    setExpansionLevel(prev => {
      if (prev === 'closed') return 'collapsed';
      if (prev === 'collapsed') return 'middle';
      if (prev === 'middle') return 'full';

      setIsHistoryOpen(false);
      return 'closed';
    });
  };

  const getChatWindowStyles = () => {
    switch (expansionLevel) {
      case 'closed':
        return {
          display: 'none',
          transition: 'width 0.2s ease',
        };
      case 'collapsed':
        return {
          maxWidth: '420px',
          height: `${calculateHeight()}px`,
          bottom: '0',
          right: '0',
          transition: 'width 0.2s ease',
        };
      case 'middle':
        return {
          width: '664px',
          height: `${calculateHeight()}px`,
          bottom: '0',
          right: '0',
          transition: 'width 0.2s ease',
        };
      case 'full':
        return {
          width: `${calculateWidth()}px`,
          height: `${calculateHeight()}px`,
          bottom: '0',
          right: '0',
          transition: 'width 0.2s ease',
        };
      default:
        return {
          display: 'none',
          transition: 'width 0.2s ease',
        };
    }
  };

  const toggleHistory = () => {
    setIsHistoryOpen(prevState => {
      if (expansionLevel === 'collapsed') {
        setExpansionLevel('middle');
      }
      return !prevState;
    });
  };

  const onCreateNewUserSession = async (name: string) => {
    if (!name) {
      return;
    }
    const sessionChat = {
      id: Math.random().toString(36).substring(7),
      name,
      entries: [],
    };

    const newChat = [
      ...chat.filter(chat => chat?.id !== 'default'),
      sessionChat,
    ];

    setChat([...chat, sessionChat]);
    setSelectedSessionId(sessionChat.id);

    const id = flow?.nodes?.find(
      (item: any) => item.type === 'fluxObject' && item?.data?.chat,
    )?.id!;

    await FlowService.updateNodeData(flow.id, {
      id,
      data: { userSessionsChat: newChat },
      changeType: 'default',
    });
    setShowModal(false);
  };

  const clearConversation = async () => {
    if (!activeTab.id || !flow) {
      setNotification({
        type: 'error',
        message: 'Active tab or flow not set',
      });
      return;
    }

    try {
      await FlowService.clearChatFlow(
        activeTab.id!,
        flow?.nodes?.find((item: any) => item.type === 'fluxObject')?.id!,
      );

      setChat(prevChat => {
        return prevChat.map(chatSession => {
          if (chatSession.id === session) {
            return { ...chatSession, entries: [] };
          }
          return chatSession;
        });
      });

      setNotification({
        type: 'success',
        message: 'Conversation cleared successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error clearing chat flow',
      });
    }
  };

  const getFluxBots = async () => {
    try {
      const res = await FlowService.getFluxBots();

      setFluxBots(res.data);

      if (
        user?.chatFlowId &&
        fluxBots?.find(item => item.id === user?.chatFlowId)?.name
      ) {
        setActiveTab({
          id: user?.chatFlowId,
          name: fluxBots?.find(item => item.id === user?.chatFlowId)?.name,
          space: fluxBots?.find(item => item.id === user?.chatFlowId)?.space,
        });
        return;
      }

      if (res.data && Array.isArray(res.data) && res.data.length > 0) {
        setActiveTab({
          id: res?.data?.[0]?.id,
          name: res?.data?.[0]?.name,
          space: res?.data?.[0]?.space,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabClick = (index: string) => {
    const selectedTab = fluxBots?.find(tab => tab.id === index);
    if (selectedTab) {
      setActiveTab({
        id: selectedTab.id,
        name: selectedTab.name,
        space: selectedTab.space,
      });
    } else {
      console.error('Tab not found for index:', index);
      setNotification({
        type: 'error',
        message: 'Selected tab not found',
      });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSettings(true);
    setChatSettings({
      ...chatSettings,
      settings: !chatSettings.settings,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSettings(false);
  };

  const handleSendMessage = async () => {
    if (!activeTab || !flow) {
      setNotification({
        type: 'error',
        message: 'Active tab or flow not set',
      });
      return;
    }
    setChatTyping(false);
    textAreaRef?.current?.blur();
    const checkTextIsNotEmpty = text.trim().length > 0;

    if (!checkTextIsNotEmpty) {
      return;
    }

    let newChat = selectedChatFind?.entries;
    newChat.push({
      role: 'user',
      content: text + `${filePreview?.src ? `\n${filePreview.src}` : ''}`,
      timestamp: new Date().toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      }),
    });

    const filterChats = chat.filter(chatSession => chatSession.id !== session);
    setChat([
      ...filterChats,
      {
        ...selectedChatFind,
        entries: newChat,
      },
    ]);

    setChatTyping(true);
    setText('');
    if (textAreaRef.current?.style.height) {
      textAreaRef.current!.style.height = '40px';
    }
    try {
      const findGlobalNode = flow?.nodes?.find(
        (item: any) => item.type === 'globalStarter',
      )?.data?.selectedNode;

      let findVarInputNode = flow?.nodes?.find(
        (item: any) => item.type === 'varInputNode',
      );

      if (findGlobalNode) {
        findVarInputNode = flow?.nodes?.find(
          (item: any) =>
            item.type === 'varInputNode' && item?.id === findGlobalNode,
        );
      }

      let sessionId = {};
      if (session !== 'default') {
        sessionId = {
          sessionId: session,
        };
      }
      await FluxService.chatApi(activeTab.id!, socket?.id!, {
        ...sessionId,
        variableInputs: [
          {
            inputId: findVarInputNode?.id,
            inputText: text,
            inputFileUrl: filePreview?.src,
          },
        ],
      });

      setChat([...filterChats, { ...selectedChatFind, entries: newChat }]);
      setText('');
    } catch (error) {
      setText('');
    } finally {
    }
  };

  useEffect(() => {
    const handleChatbotResponse = async (resData: any) => {
      const newChat = selectedChatFind?.entries;
      const timestamp = resData?.data?.[0]?.timestamp;

        if (newChat && newChat.map((item: any) => item.timestamp).includes(timestamp)) {
        return;
      }

      const { text: content, fileUrl } = resData.data[0];
      newChat.push({
        role: 'assistant',
        content,
        fileUrl,
        timestamp,
      });

      const filterChats = chat.filter(chatSession => chatSession.id !== session);

      setChat([
        ...filterChats,
        {
          ...selectedChatFind,
          entries: newChat,
        },
      ]);

      setChatTyping(false);
      setFilePreview(null);
      setText('');
      setTimeout(() => {
        lastItemRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 300);

      textAreaRef?.current?.focus();
    };

    if (socket?.id) {
      socket?.on('chatbot-req', handleChatbotResponse);
    }

    return () => {
      socket?.off('chatbot-req', handleChatbotResponse);
    };
  }, [socket?.id, session, chat, selectedChatFind]);

  const onChange = (e: any) => {
    // setChatTyping(true);
    setText(e.target.value);
  };

  const onCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const getDateLabel = (timestamp: string) => {
    try {
      let date = parseISO(timestamp);
      const now = new Date();

      date = setYear(date, now.getFullYear());

      if (isToday(date)) {
        return 'Today';
      } else if (isYesterday(date)) {
        return 'Yesterday';
      } else {
        const diffDays = differenceInDays(now, date);
        if (diffDays > 0 && diffDays < 7) {
          return `${diffDays} days ago`;
        } else {
          return format(date, 'MMMM d');
        }
      }
    } catch (error) {
      console.error('Invalid date:', timestamp);
      return 'Recent';
    }
  };

  const sortAndGroupChatSessions = (chatSessions: any[]) => {
    const sorted = chatSessions.sort((a, b) => {
      const aTime =
        a.entries.length > 0
          ? new Date(a.entries[a.entries.length - 1].timestamp).getTime()
          : new Date().getTime();
      const bTime =
        b.entries.length > 0
          ? new Date(b.entries[b.entries.length - 1].timestamp).getTime()
          : new Date().getTime();
      return bTime - aTime;
    });

    const grouped: { [key: string]: any[] } = {};
    sorted.forEach(session => {
      const dateLabel =
        session.entries.length > 0
          ? getDateLabel(session.entries[session.entries.length - 1].timestamp)
          : 'Today';
      if (!grouped[dateLabel]) {
        grouped[dateLabel] = [];
      }
      grouped[dateLabel].push({
        ...session,
        lastTimestamp:
          session.entries.length > 0
            ? session.entries[session.entries.length - 1].timestamp
            : null,
      });
    });

    return grouped;
  };

  const onClickSelectFluxBot = async (item: any) => {
    try {
      handleTabClick(item.id);
      setActiveTab(item);
      setChatSettings({
        ...chatSettings,
        flow: !chatSettings.flow,
      });

      setAuthUser({
        ...authUser!,
        chatFlowId: item.id,
      });
      await UserService.updateUser({
        chatFlowId: item.id,
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Selected tab not found',
      });
    }
  };

  const handleFileUpload = async (file: any) => {
    setIsFileUploading(true);
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await UploadService.uploadFile(formData);

      if (file.type.startsWith('image/')) {
        setFilePreview({ type: 'image', src: res.data.src });
      } else if (file.type === 'application/pdf') {
        // Preview for PDFs
        setFilePreview({ type: 'pdf', name: file.name, src: res.data.src });
      } else if (file.type.startsWith('audio/')) {
        setFilePreview({ type: 'audio', name: file.name, src: res.data.src });
      }
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
      setIsFileUploading(false);
    } finally {
      setIsFileUploading(false);
    }
  };

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      handleFileUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf'],
      'audio/*': ['.mp3', '.wav', '.ogg', '.flac', '.aac', '.m4a'],
    },
  });

  useAutosizeTextArea(textAreaRef.current, text);

  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        lastItemRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 300);
    };

    scrollToBottom();
  }, [chat]);

  useEffect(() => {
    if (expansionLevel !== 'closed' && !activeTab?.id && user?.id) {
      getFluxBots();
    }
  }, [
    expansionLevel,
    user?.chatFlowId,
    activeTab,
    user?.selectedCompanyId,
    user?.id,
  ]);

  useEffect(() => {
    const getFlow = async () => {
      setIsLoading(true);
      try {
        const response = await FlowService.getFlow(activeTab.id!);
        if (response.data.nodes) {
          const getChatFromNodes = response.data?.nodes?.find(
            (item: any) => item.type === 'fluxObject',
          )?.data;
          setChat([
            {
              id: 'default',
              name: 'Primary',
              entries: getChatFromNodes.entries,
            },
            ...(getChatFromNodes?.userSessionsChat || []),
          ]);
          setSession('default');

          setFlow(response?.data);
        }
      } catch (error) {
        setNotification({
          type: 'error',
          message: 'Error fetching chat flow',
        });
      } finally {
        setTimeout(() => {
          lastItemRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }, 300);
        textAreaRef?.current?.focus();
        setChatTyping(false);
        setIsLoading(false);
      }
    };

    if (activeTab?.id && expansionLevel !== 'closed') {
      getFlow();
    }
  }, [activeTab.id, expansionLevel]);

  useEffect(() => {
    if (expansionLevel === 'closed') {
      setTimeout(() => {
        setActiveTab({ id: '', name: '', space: '' });
      }, 1000);
    }
  }, [expansionLevel]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <Box
      {...getRootProps()}
      onClick={() => {
        if (chatSettings.settings || chatSettings.flow) {
          setChatSettings({
            settings: false,
            flow: false,
          });
        }
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Box
          sx={{
            width: '420px',
            height: '100%',
            position: 'absolute',
            right: 0,
            bottom: 0,
            bgcolor: 'white',
            ...getChatWindowStyles(),
          }}
        >
          <p style={{ textAlign: 'center' }}>Drop the files here...</p>
        </Box>
      ) : (
        <>
          {' '}
          {cleanCurrentUrl !== cleanBaseUrl && (
            <Box
              style={{
                position: 'absolute',
                top: tabs.length <= 1 ? '8px' : '45px',
                right: '20px',
              }}
            >
              <IconButton onClick={toggleExpansionLevel}>
                <Chats size={20} color="#3650DE" />
              </IconButton>
            </Box>
          )}
          {expansionLevel !== 'closed' && (
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#fff',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'row',
                zIndex: 101,
                borderLeft:
                  expansionLevel === 'full' ? '1px solid #E5E7EB' : '',
                ...getChatWindowStyles(),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {/* History Section */}
                {isHistoryOpen && (
                  <Box
                    sx={{
                      position: 'relative',
                      backgroundColor: 'transparent',
                      // backgroundColor: '#F9FAFB',
                      borderRight: '1px solid #E5E7EB',
                      padding: '16px 8px',
                      transition: 'transform 0.6s ease',
                      transform: isHistoryOpen
                        ? 'translateX(0)'
                        : 'translateX(-100%)',
                      zIndex: 101,
                      width: '200px',
                    }}
                  >
                    {/* Content of the history section */}
                    <>
                      {/* Add New Chat and Clear Conversation */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection={'column'}
                        alignItems="center"
                        mb={2}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            width: '100%',
                            background: 'unset',
                            justifyContent: 'flex-start',
                            textTransform: 'unset',
                            fontSize: '16px',
                            boxShadow: 'unset',
                            px: '8px',
                            gap: '8px',
                            color: '#EAECF0',
                            ':hover': {
                              background:
                                user?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                              boxShadow: 'unset',
                            },
                          }}
                          onClick={() => setShowModal(true)}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              color={
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828'
                              }
                              fontWeight={700}
                            >
                              Add New Chat
                            </Typography>
                            <Plus
                              size={20}
                              color={
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828'
                              }
                              weight="bold"
                            />
                          </Box>
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            width: '100%',
                            background: 'unset',
                            justifyContent: 'flex-start',
                            textTransform: 'unset',
                            fontSize: '16px',
                            boxShadow: 'unset',
                            px: '8px',
                            gap: '8px',
                            color: '#EAECF0',
                            ':hover': {
                              background:
                                user?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                              boxShadow: 'unset',
                            },
                          }}
                          onClick={() => {
                            clearConversation();
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <Typography
                              color={
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828'
                              }
                              fontWeight={700}
                            >
                              Clear Conversation
                            </Typography>
                            <Trash
                              size={20}
                              color={
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828'
                              }
                              weight="bold"
                            />
                          </Box>
                        </Button>
                      </Box>
                      <Divider />
                      {/* History List */}
                      <HistoryList
                        groupedSections={sortAndGroupChatSessions(chat)}
                        onSelectSession={sessionId => {
                          setChatTyping(false);
                          setSelectedSessionId(sessionId);
                          setSession(sessionId);
                        }}
                        selectedSessionId={selectedSessionId}
                      />
                    </>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#ffff',
                  width: '100%',
                  borderTop: '5px solid #B589FF',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                    width: '96%',
                    borderBottom:
                      user?.theme === 'dark' ? '' : '1px solid #F2F4F7',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={toggleHistory}>
                      <TextAlignJustify
                        size={24}
                        style={{
                          cursor: 'pointer',
                          color:
                            authUser?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                      />
                    </IconButton>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                        backgroundColor: '#F2F4F7',
                        borderRadius: '8px',
                        padding: '8px',
                      }}
                      onClick={() =>
                        setChatSettings({
                          ...chatSettings,
                          flow: !chatSettings.flow,
                        })
                      }
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '150px',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        }}
                        title={`${activeTab?.name ?? ''} (${
                          activeTab?.space ?? ''
                        })`}
                        color={'#FFF'}
                        variant="subtitle1"
                      >
                        {`${activeTab?.name ?? ''} (${activeTab?.space ?? ''})`}
                      </Typography>

                      {chatSettings.flow && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '100%',
                            left: '0',
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            borderRadius: '4px',
                            zIndex: 300,
                            maxHeight: 'calc(100vh - 200px)',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            width: '300px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              padding: '8px',
                              width: '100%',
                              '& span': {
                                fontSize: '12px',
                                fontWeight: 500,
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#475467',
                              },
                              '& button': {
                                fontSize: '12px',
                                fontWeight: 500,
                                color:
                                  user?.theme === 'dark'
                                    ? '#D0D5DD'
                                    : '#475467',
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                '&:hover': {
                                  color:
                                    user?.theme === 'dark' ? '#fff' : '#475467',
                                },
                              },
                            }}
                          >
                            {fluxBots?.map((item, index) => (
                              <MenuItem
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '10px',
                                  backgroundColor:
                                    activeTab?.id === item.id ? '#B589FF' : '',
                                }}
                                onClick={() => {
                                  onClickSelectFluxBot(item);
                                }}
                                key={index}
                              >
                                {item && (
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      // whiteSpace: 'nowrap',
                                      // overflow: 'hidden',
                                      // textOverflow: 'ellipsis',
                                      width: '150px',
                                      color:
                                        activeTab?.id === item.id
                                          ? '#fff'
                                          : user?.theme === 'dark'
                                          ? '#D0D5DD'
                                          : '#475467',
                                      '&:hover': {
                                        color:
                                          activeTab?.id === item.id
                                            ? '#B589FF'
                                            : '#475467',
                                      },
                                    }}
                                    title={`${item.name} (${item.space})`}
                                  >
                                    {`${item.name} (${item.space})`}
                                  </Typography>
                                )}
                              </MenuItem>
                            ))}
                          </Box>
                        </Box>
                      )}

                      <CaretDown
                        cursor={'pointer'}
                        color="#101828"
                        size={16}
                        weight="regular"
                      />
                    </Box>

                    {flow?.public && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '6px',
                          backgroundColor: '#F9FAFB',
                          borderRadius: '8px',
                          ml: '10px',
                          padding: '6px',
                        }}
                      >
                        <Typography
                          lineHeight={'20px'}
                          color={'#4CAF50'}
                          fontSize={'12px'}
                        >
                          Published
                        </Typography>
                        <CheckCircle color="#4CAF50" size={16} />
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={() => setAutoPlay(!autoPlay)}
                      title="Auto play audios toggle"
                    >
                      {autoPlay ? (
                        <Pause
                          size={24}
                          style={{
                            cursor: 'pointer',
                            color:
                              authUser?.theme === 'dark'
                                ? '#D0D5DD'
                                : '#101828',
                          }}
                        />
                      ) : (
                        <Play
                          size={24}
                          style={{
                            cursor: 'pointer',
                            color:
                              authUser?.theme === 'dark'
                                ? '#D0D5DD'
                                : '#101828',
                          }}
                        />
                      )}
                    </IconButton>
                    <IconButton onClick={toggleExpansionLevel}>
                      <Notebook
                        size={24}
                        style={{
                          cursor: 'pointer',
                          color:
                            authUser?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={e => {
                        handleClick(e);
                      }}
                    >
                      <Gear
                        cursor={'pointer'}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#101828'
                        }
                        size={24}
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setIsHistoryOpen(false);
                        setExpansionLevel('closed');
                      }}
                    >
                      <XCircle
                        size={24}
                        style={{
                          cursor: 'pointer',
                          color:
                            authUser?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                      />
                    </IconButton>
                    {chatSettings.settings && (
                      <Popover
                        open={openSettings}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: '8px',
                            background:
                              user?.theme === 'dark' ? '#101828' : '#FFFF',
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  user?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              setPublishChatModal(true);
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Play
                                size={20}
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#475467'
                                }
                              >
                                Publish externally
                              </Typography>
                            </Box>
                          </Button>

                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  user?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              setNotification({
                                message: 'Link copied to clipboard',
                                type: 'success',
                                duration: 3000,
                              });

                              navigator.clipboard.writeText(
                                `${window.location.origin}/chatbot/${activeTab?.id}`,
                              );
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Link
                                size={20}
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#475467'
                                }
                              >
                                Copy link
                              </Typography>
                            </Box>
                          </Button>

                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              ':hover': {
                                background:
                                  user?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              setChatSettings({
                                ...chatSettings,
                                flow: !chatSettings.flow,
                              });
                              clearConversation();
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Trash
                                size={20}
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#475467'
                                }
                              />
                              <Typography
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#475467'
                                }
                              >
                                Clear Conversation
                              </Typography>
                            </Box>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  user?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              setChatSettings({
                                ...chatSettings,
                                flow: !chatSettings.flow,
                              });

                              if (tabs.length <= 9) {
                                addTab({
                                  flowName: flow?.name,
                                  flowId: flow?.id,
                                  folderId: fluxBots[0].id!,
                                });
                              } else {
                                navigate(
                                  `/flow/${flow?.id}?folderId=${fluxBots[0]
                                    .id!}&type=flow`,
                                );
                              }
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <NotePencil
                                size={20}
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#475467'
                                }
                              >
                                Edit Ai FluxBot
                              </Typography>
                            </Box>
                          </Button>

                          <Button
                            variant="contained"
                            sx={{
                              width: '100%',
                              background: 'unset',
                              justifyContent: 'flex-start',
                              textTransform: 'unset',
                              fontSize: '16px',
                              boxShadow: 'unset',
                              px: '8px',
                              gap: '8px',
                              color: '#EAECF0',
                              ':hover': {
                                background:
                                  user?.theme === 'dark'
                                    ? ' #1D2939'
                                    : '#EAECF0',
                                boxShadow: 'unset',
                              },
                            }}
                            onClick={() => {
                              setChatSettings({
                                ...chatSettings,
                                flow: !chatSettings.flow,
                              });
                              onCreateChat();
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Plus
                                size={20}
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#667085'
                                }
                              />
                              <Typography
                                color={
                                  user?.theme === 'dark' ? '#D0D5DD' : '#475467'
                                }
                              >
                                Create New Ai FluxBot
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Popover>
                    )}
                  </Box>
                </Box>
                {/* Chat container */}

                <Box
                  sx={{
                    maxWidth: '1200px',
                    width: '100%',
                    position: 'relative',
                    overflowY: 'auto',
                  }}
                  className="chat-container"
                >
                  <Box
                    ref={lastItemRef}
                    sx={{
                      padding: '0 20px',
                      '@media (max-width: 1600px)': {
                        maxWidth: '1000px',
                      },
                      '@media (max-width: 1200px)': {
                        maxWidth: '800px',
                      },
                      '@media (max-width: 900px)': {
                        maxWidth: '600px',
                      },
                    }}
                  >
                    {selectedChatFind?.entries?.length === 0 && (
                      <Box
                        sx={{
                          textAlign: 'center',
                          mt: '28px',
                        }}
                      >
                        <Typography
                          sx={{
                            mt: '8px',
                            fontSize: '32px',
                            fontWeight: 700,
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                          }}
                          variant="h4"
                        >
                          FluxBot 💬
                        </Typography>

                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          mb="10px"
                          mt="60px"
                          sx={{
                            '& svg': {},
                          }}
                        >
                          {user?.theme === 'dark' ? (
                            <LightIcon strokeColor={'#D0D5DD'} />
                          ) : (
                            <LightIcon strokeColor={'#475467'} />
                          )}
                        </Box>

                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontWeight: 700,
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                          }}
                          variant="body1"
                        >
                          Examples
                        </Typography>
                        <Box
                          sx={{
                            padding: user?.theme === 'dark' ? '0' : '24px',
                          }}
                        >
                          {chatExamples.map((item, index) => {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  mt: '16px',
                                  mb: '16px',
                                  p: '8px',
                                  cursor: 'pointer',
                                  backgroundColor:
                                    user?.theme === 'dark'
                                      ? 'rgba(255, 255, 255, 0.08)'
                                      : '',
                                  '&:hover': {
                                    backgroundColor:
                                      user?.theme === 'dark'
                                        ? '#2A3B4B'
                                        : '#F2F4F7',
                                  },
                                }}
                                onClick={() => setText(item)}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: 500,
                                    fontFamily: 'Raleway',
                                    fontStyle: 'normal',

                                    color:
                                      user?.theme === 'dark'
                                        ? '#D0D5DD'
                                        : '#475467',
                                  }}
                                  variant="body1"
                                >
                                  “{item}”
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    )}
                    {isLoading ? (
                      <>
                        <LoadingSpinner variant="light" />
                      </>
                    ) : (
                      <>
                        {selectedChatFind?.entries.map(
                          (item: any, index: number) => {
                            const itemsSegments = processTextSegments(
                              item?.content,
                            );

                            return (
                              <>
                                <Box
                                  key={index}
                                  sx={{
                                    display: 'flex',
                                    // backgroundColor: 'red',
                                    flexDirection: 'column',
                                    mt: '10px',
                                    marginBottom:
                                      chat.length - 1 === index && !chatTyping
                                        ? '45px'
                                        : '0',

                                    alignItems:
                                      item.role === 'assistant'
                                        ? 'flex-start'
                                        : 'flex-end',

                                    padding:
                                      expansionLevel === 'full'
                                        ? '10px 50px'
                                        : expansionLevel === 'middle'
                                        ? '10px'
                                        : expansionLevel === 'collapsed'
                                        ? '8px'
                                        : '5px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',

                                      alignItems: 'flex-start',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: '8px',
                                        alignItems: 'flex-start',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      {item.role === 'assistant' && (
                                        <Box
                                          sx={{
                                            bgcolor: '#B589FF',
                                            display: 'flex',
                                            width: '24px',
                                            height: '24px',
                                            padding: '8px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '4px',
                                            gap: '13.5px',
                                          }}
                                        >
                                          <Chats size={20} color="#fff" />
                                        </Box>
                                      )}
                                      <Box
                                        className={`${
                                          item.role === 'assistant'
                                            ? 'bg-white assistant-after'
                                            : 'bg-primary user-after'
                                        }`}
                                        sx={{
                                          padding: '10px 15px',
                                          borderRadius:
                                            item.role === 'assistant'
                                              ? '0px 8px 8px 8px'
                                              : '8px 0px 8px 8px',
                                          backgroundColor:
                                            user?.theme === 'dark'
                                              ? item.role === 'assistant'
                                                ? '#101828'
                                                : 'transparent'
                                              : item.role === 'assistant'
                                              ? '#F2F4F7'
                                              : 'transparent',
                                          color:
                                            user?.theme === 'dark'
                                              ? item.role === 'assistant'
                                                ? '#F2F4F7'
                                                : '#F2F4F7'
                                              : item.role === 'assistant'
                                              ? '#475467'
                                              : '#475467',
                                          // marginBottom: '5px',
                                          fontSize: '16px',
                                          maxWidth: '80%',
                                        }}
                                      >
                                        {item.role === 'user' && (
                                          <>
                                            {item?.fileUrl && (
                                              <>
                                                {item?.fileUrl?.includes(
                                                  '.pdf',
                                                ) ? (
                                                  <a
                                                    href={item?.fileUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    {item?.fileUrl}
                                                  </a>
                                                ) : (
                                                  <img
                                                    alt="img"
                                                    src={item?.fileUrl}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}

                                        {itemsSegments?.length !== 0 &&
                                          itemsSegments?.map(
                                            (segment: any, index: number) => (
                                              <OutputMarkDown
                                                onCopy={onCopy}
                                                segment={segment}
                                                user={user!}
                                                key={index}
                                                index={index}
                                                value={item?.content}
                                                segments={itemsSegments}
                                                autoPlay={autoPlay}
                                              />
                                            ),
                                          )}
                                      </Box>
                                    </Box>
                                    {item.role === 'assistant' && (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          paddingLeft: '48px',
                                        }}
                                      >
                                        <IconButton
                                          onClick={() => onCopy(item?.content)}
                                          sx={{
                                            marginBottom: '15px',
                                          }}
                                        >
                                          <Copy
                                            size={20}
                                            color={
                                              user?.theme === 'dark'
                                                ? '#D0D5DD'
                                                : '#475467'
                                            }
                                          />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </>
                            );
                          },
                        )}
                      </>
                    )}

                    {chatTyping && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: '45px',
                        }}
                      >
                        <Box
                          className={`${'bg-white assistant-after'}`}
                          style={{
                            padding: '10px',
                            borderRadius: '10px',

                            color: 'assistant',
                            marginBottom: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      position: 'sticky',
                      maxWidth: '1000px',
                      marginTop: 'auto',
                      zIndex: 999,
                      bottom: 10,
                      left: 0,
                      right: 0,
                      padding: '8px 10px',
                      margin: '0 10px',
                      backgroundColor:
                        text.trim() !== ''
                          ? '#D0D5DD'
                          : user?.theme === 'dark'
                          ? '#101828'
                          : '#fff',
                      gap: '8px',
                      borderRadius: '10px',
                      border:
                        user?.theme === 'dark'
                          ? '1px solid #1D2939'
                          : '1px solid #D0D5DD',
                    }}
                  >
                    {filePreview && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: '8px',
                          backgroundColor: '#F3F4F6',
                          borderRadius: '8px',
                          marginBottom: '8px',
                        }}
                      >
                        {isFileUploading ? (
                          <LoadingSpinner />
                        ) : (
                          <>
                            {filePreview.type === 'image' ? (
                              <img
                                src={filePreview.src}
                                alt="Preview"
                                style={{
                                  maxWidth: '100px',
                                  maxHeight: '100px',
                                  borderRadius: '4px',
                                }}
                              />
                            ) : filePreview.type === 'audio' ? (
                              <audio
                                src={filePreview.src}
                                controls
                                style={{}}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                }}
                              >
                                <FilePdf size={24} color="#FF3278" />
                                <span>{filePreview.name}</span>
                              </Box>
                            )}
                          </>
                        )}

                        <Box
                          sx={{
                            cursor: 'pointer',
                            marginLeft: '8px',
                          }}
                          onClick={() => setFilePreview(null)}
                        >
                          <X size={16} />
                        </Box>
                      </Box>
                    )}
                    <Box
                      sx={{
                        padding: '0px 5px',
                        borderRadius: '8px',
                        maxWidth: '1000px',
                        width: '85%',
                        margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor:
                          text.trim() !== ''
                            ? '#D0D5DD'
                            : user?.theme === 'dark'
                            ? '#101828'
                            : '#fff',
                      }}
                    >
                      <textarea
                        className={`${
                          user?.theme === 'dark'
                            ? 'chat-textarea-dark'
                            : 'chat-textarea'
                        }`}
                        onChange={onChange}
                        placeholder="Message FluxBot"
                        ref={textAreaRef}
                        onKeyDown={e => {
                          if (e.key === 'Enter' && e.shiftKey) {
                            return;
                          }
                          if (e.key === 'Enter') {
                            if (!chatTyping) {
                              handleSendMessage();
                            }
                          }
                        }}
                        style={{
                          backgroundColor:
                            text.trim() !== ''
                              ? '#D0D5DD'
                              : user?.theme === 'dark'
                              ? '#101828'
                              : '#fff',
                          fontSize: '16px',
                          transition: 'width 0.2s ease',
                          width: '100%',
                        }}
                        rows={1}
                        value={text}
                      />
                      <Box
                        sx={{
                          p: '8px',
                          borderRadius: '4px',
                          backgroundColor:
                            text.trim() !== ''
                              ? '#344054'
                              : user?.theme === 'dark'
                              ? '#101828'
                              : '#D0D5DD',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: '8px',
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*,application/pdf,audio/*"
                          style={{ display: 'none' }}
                          id="upload-file"
                          onChange={(e: any) =>
                            handleFileUpload(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-file"
                          style={{ cursor: 'pointer' }}
                        >
                          <Upload color="#fff" size={20} />
                        </label>
                      </Box>
                      <Box
                        sx={{
                          p: '8px',
                          borderRadius: '4px',
                          backgroundColor:
                            text.trim() !== ''
                              ? '#344054'
                              : user?.theme === 'dark'
                              ? '#101828'
                              : '#D0D5DD',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={handleSendMessage}
                      >
                        <ArrowUp color="#fff" size={20} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {publishChatModal && (
            <PublishChatModal
              show={publishChatModal}
              onHide={() => setPublishChatModal(false)}
              setFlow={setFlow}
              chatData={flow}
            />
          )}
        </>
      )}

      <ChatBotSessionModal
        sessionName={sessionName}
        setSessionName={setSessionName}
        show={showModal}
        onCreateNewUserSession={onCreateNewUserSession}
        onHide={() => setShowModal(false)}
      />
    </Box>
  );
};

export default ChatBot;
