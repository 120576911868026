import React from 'react';
import SpacesModal from '../../../Spaces/SpacesModal';
import { Box, Button, TextField, Typography } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../../../store/storeNotifications';
import KitModal from '../../../UI/KitModal';
import { RocketLaunch } from '@phosphor-icons/react';

interface Props {
  spaceName: string;
  spaceId: string;
  onClose: () => void;
}

const CopySpaceIDModal: React.FC<Props> = ({ spaceName, spaceId, onClose }) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const copySpaceId = async () => {
    try {
      navigator.clipboard.writeText(spaceId);
      setNotification({
        type: NotificationType.Success,
        message: 'Space ID copied successfully',
      });
      onClose();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: 'Something went wrong',
      });
    }
  };
  return (
    <KitModal
      onHide={onClose}
      show={true}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '459px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '24px',
        },
        '& .MuiDialogActions-root': {
          padding: '24px',
        },
      }}
      footer={
        <>
          <Button fullWidth variant="contained" onClick={copySpaceId}>
            Copy Space ID
          </Button>
        </>
      }
    >
      <Box mt={'16px'}>
        <Box>
          <Box
            sx={{
              p: '4px',
              bgcolor: '#E7F0FF',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <RocketLaunch color="#3650DE" size={24} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              mt: '8px',
              color: '#475467',
              fontSize: '20px',
              fontWeight: 500,
            }}
          >
            Copy {spaceName} space ID
          </Typography>
        </Box>{' '}
        <Typography
          sx={{
            color: '#475467',
            fontSize: '16px',
            letterSpacing: '0.15px',
            mt: '8px',
          }}
        >
          To share flows into this Org Space, copy and paste this ID into the
          sharing pop up window of the flow.
        </Typography>
        <TextField
          label="Space ID"
          variant="outlined"
          size="medium"
          fullWidth
          disabled
          sx={{ marginTop: '20px' }}
          value={spaceId}
        />
      </Box>
    </KitModal>
  );
};

export default CopySpaceIDModal;
