import React, { useState, useEffect } from 'react';
import SidebarFlow from './SidebarFlow';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Popover,
  Typography,
} from '@mui/material';
import {
  CaretDown,
  Folder as FolderIcon,
  Plus,
  Trash,
  PencilSimpleLine,
  CaretRight,
  FolderSimpleUser,
} from '@phosphor-icons/react';
import { Folder } from '../../util/Types/Folder';
import { FolderService } from '../../service/FolderService';
import { useAuthStore } from '../../store/storeAuth';
import '../../assets/styles/iconhover.css';
import { ReactComponent as DotsThreeOutlineBlueIcon } from '../../assets/icons/DotsThreeOutlineBlueIcon.svg';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { useParams, useSearchParams } from 'react-router-dom';

type Props = {
  folder: Folder;
  active: string;
  setCreateOrEditFLow: (value: {
    isSharing: boolean;
    isEditing: boolean;
    type?: string;
    flow: {
      id: string;
      name: string;
      allFlow?: any;
    };
    folderId: string;
  }) => void;
  setOpenAddFlowModal: (value: boolean) => void;
  setFolderUpdate: (value: {
    isSubfolder: boolean;
    isSubfolderEditing: boolean;
    isEditing: boolean;
    folder: {
      id: string;
      name: string;
    };
  }) => void;

  setOnOpenCreateFolder: (value: boolean) => void;
  onClick?: () => void;
  sidebarOpen?: boolean;
  setSidebarOpen?: (value: boolean) => void;
  theme: string | undefined;
  onCreateFlow: (
    name: string,
    isEditing?: boolean,
    id?: string,
    folderId?: string,
    isCopy?: boolean,
    type?: string,
  ) => void;
  dragStarted: {
    started: string;
    randomValue: string;
  };
  onDeleteFolderSuccess?: (folderId: string) => void;
  onDeleteFlowSuccess?: (folderId: string, flowId: string) => void;
  onOpenFolderSuccess?: (folderId: string, folder: any) => void;
  isSpace?: boolean;
  setFolders?: (folders: any) => void;
  space?: any;
};

const SidebarFolders = ({
  folder: folderProp,
  setCreateOrEditFLow,
  setOpenAddFlowModal,
  setFolderUpdate,
  setOnOpenCreateFolder,
  onClick,
  sidebarOpen,
  setSidebarOpen,
  theme,
  onCreateFlow,
  onDeleteFolderSuccess,
  onDeleteFlowSuccess,
  onOpenFolderSuccess,
  isSpace = false,
  space,
}: Props) => {
  const [folder, setFolder] = useState(folderProp);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setFolders, folders } = useAuthStore(state => state);
  const [isHovered, setIsHovered] = useState(false);

  const { flowId } = useParams();
  const [searchParams, _] = useSearchParams();
  const folderId = searchParams.get('folderId');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setFolder(folderProp);
  }, [folderProp]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openCreateFlowModal = (folderId: string, type: string) => {
    setOpenAddFlowModal(true);

    setCreateOrEditFLow({
      isSharing: false,
      isEditing: false,
      type,
      flow: {
        id: '',
        name: '',
      },
      folderId: folderId,
    });
  };

  let timeoutId: string | number | NodeJS.Timeout | undefined;

  function handleMouseEnter() {
    timeoutId = setTimeout(() => {
      setSidebarOpen?.(true);
      setOpen(true);
    }, 500);
  }

  function handleMouseLeave() {
    clearTimeout(timeoutId);
  }

  const openUpdateFolderModal = () => {
    setOnOpenCreateFolder(true);
    setFolderUpdate({
      isSubfolder: false,
      isSubfolderEditing: false,
      isEditing: true,
      folder,
    });
    handleClose();
  };

  const openUpdateFlowModal = (
    flow: { id: string; name: string },
    folderId: any,
    isSharing: boolean,
    isEditing: boolean,
  ) => {
    setCreateOrEditFLow({
      isSharing: isSharing,
      isEditing: isEditing,
      flow,
      folderId: folderId,
    });
    setOpenAddFlowModal(true);
    handleClose();
  };

  const onDeleteFolder = async () => {
    handleClose();
    try {
      const result = window.confirm(
        'Are you sure you want to delete folder? If you delete folder all flows inside will be deleted too.',
      );

      if (!result) return;

      await FolderService.deleteFolder(folder?.id);

      setFolders(folders?.filter(item => item.id !== folder?.id));

      if (onDeleteFolderSuccess) {
        onDeleteFolderSuccess(folder?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onOpenFolder = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await FolderService.getOne(id);
      setFolder(res.data);
      const modifyFolders = folders.map(folder => {
        if (folder.id === id) {
          return res.data;
        }
        return folder;
      });

      setFolders(modifyFolders);
      setOpen(!open);

      if (typeof onOpenFolderSuccess === 'function') {
        onOpenFolderSuccess(id, res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   const openIfActiveFlow = async () => {
  //     setIsLoading(true);
  //     try {
  //       const res = await FolderService.getOne(folderId as string);
  //       if (folderId === folder.id) {
  //         setFolder(res.data);

  //         const modifyFolders = folders.map(folder => {
  //           if (folder.id === folderId) {
  //             return res.data;
  //           }
  //           return folder;
  //         });

  //         setFolders(modifyFolders);
  //         setOpen(!open);
  //       }
  //     } catch (error) {
  //       setIsLoading(false);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   if (flowId) {
  //     openIfActiveFlow();
  //   }
  // }, []);

  const type = folder.name === 'Chatbot' ? 'chat' : '';

  return (
    <Droppable
      droppableId={folder.id}
      isDropDisabled={folder.name === 'Chatbot'}
    >
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          onMouseEnter={() => {
            if (!sidebarOpen) {
              handleMouseEnter();
            }
          }}
          onMouseLeave={() => {
            if (!sidebarOpen) {
              handleMouseLeave();
            }
          }}
        >
          <Box
            className={`menu ${isHovered ? 'menu--on-hover' : ''}`}
            sx={{
              display: 'flex',
              justifyContent: sidebarOpen ? 'space-between' : 'center',
              alignItems: 'center',
              padding: '0px 4px 0px 4px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              '&:hover .truncate-text': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '24px',
                padding: '4px',
                background: snapshot.draggingOverWith
                  ? theme === 'dark'
                    ? '#101928'
                    : '#e6f1fe'
                  : '',
                '&:hover': {
                  backgroundColor: theme === 'dark' ? '#101828' : '#E7F0FF',
                  borderRadius: '4px',
                },
              }}
            >
              <Box
                onClick={() => onOpenFolder(folder?.id)}
                sx={{
                  display: 'flex',
                  columnGap: '8px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignSelf: 'center',
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : folder.name === 'Shared' ? (
                    <FolderSimpleUser
                      size={20}
                      color={
                        snapshot.draggingOverWith
                          ? '#546BEA'
                          : theme === 'dark'
                          ? 'white'
                          : '#667085'
                      }
                      weight="regular"
                      style={{ alignSelf: 'center' }}
                      className="active-menu"
                    />
                  ) : (
                    <FolderIcon
                      size={20}
                      color={
                        snapshot.draggingOverWith
                          ? '#546BEA'
                          : theme === 'dark'
                          ? 'white'
                          : '#667085'
                      }
                      weight="regular"
                      style={{ alignSelf: 'center' }}
                      className="active-menu"
                    />
                  )}
                </Box>
                {sidebarOpen && (
                  <Box
                    className="text-capitalize"
                    sx={{
                      color: theme === 'dark' ? '#D0D5DD' : '#667085',
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: 400,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className="active-menu truncate-text"
                      sx={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: snapshot.draggingOverWith
                          ? '#546BEA'
                          : theme === 'dark'
                          ? '#D0D5DD'
                          : '#475467',
                        alignItems: 'center',
                        width: isHovered ? '6rem' : '8rem',
                        transition: 'width 0.1s',
                      }}
                    >
                      {folder.name}
                    </Typography>
                  </Box>
                )}
              </Box>
              {sidebarOpen && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ alignSelf: 'center' }}>
                    {folder.name !== 'Chatbot' && folder.name !== 'Shared' && (
                      <>
                        <>
                          <IconButtonTooltip title="" onClick={handleClick}>
                            <DotsThreeOutlineBlueIcon className="menu-icons active-menu" />
                          </IconButtonTooltip>
                          <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: '8px',
                                background:
                                  theme === 'dark' ? '#101828' : '#FFFF',
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  width: '100%',
                                  background: 'unset',
                                  justifyContent: 'flex-start',
                                  textTransform: 'unset',
                                  fontSize: '16px',
                                  boxShadow: 'unset',
                                  gap: '16px',
                                  px: '8px',
                                  color: '#EAECF0',
                                  ':hover': {
                                    background:
                                      theme === 'dark' ? ' #1D2939' : '#EAECF0',
                                    boxShadow: 'unset',
                                  },
                                }}
                                onClick={openUpdateFolderModal}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '10px',
                                  }}
                                >
                                  <PencilSimpleLine
                                    size={20}
                                    color={
                                      theme === 'dark' ? '#D0D5DD' : '#667085'
                                    }
                                  />
                                  <Typography
                                    color={
                                      theme === 'dark' ? '#D0D5DD' : '#475467'
                                    }
                                  >
                                    Update Folder
                                  </Typography>
                                </Box>
                              </Button>
                              <Button
                                variant="contained"
                                sx={{
                                  width: '100%',
                                  background: 'unset',
                                  justifyContent: 'flex-start',
                                  gap: '16px',
                                  textTransform: 'unset',
                                  fontSize: '16px',
                                  boxShadow: 'unset',
                                  color: '#b91c1c',
                                  px: '8px',
                                  ':hover': {
                                    background: '#fecdd3',
                                    boxShadow: 'unset',
                                  },
                                }}
                                onClick={onDeleteFolder}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '10px',
                                  }}
                                >
                                  <Trash size={20} color="#b91c1c" />
                                  <Typography>Delete</Typography>
                                </Box>
                              </Button>
                            </Box>
                          </Popover>
                        </>
                        <IconButtonTooltip
                          title={'Create Flow'}
                          onClick={() => {
                            openCreateFlowModal(folder.id, type);
                          }}
                        >
                          <Plus
                            size={16}
                            color={theme === 'dark' ? '#D0D5DD' : '#667085'}
                            className="menu-icons active-menu"
                          />
                        </IconButtonTooltip>
                      </>
                    )}
                  </Box>
                  <IconButtonTooltip
                    title="Collapse"
                    onClick={() => {
                      if (!open) {
                        onOpenFolder(folder?.id);
                      } else {
                        setOpen(!open);
                      }
                    }}
                  >
                    {!open ? (
                      <CaretDown
                        size={16}
                        className="active-menu"
                        color={
                          snapshot.draggingOverWith
                            ? '#546BEA'
                            : theme === 'dark'
                            ? 'white'
                            : '#667085'
                        }
                      />
                    ) : (
                      <CaretRight
                        size={16}
                        className="active-menu"
                        color={
                          snapshot.draggingOverWith
                            ? '#546BEA'
                            : theme === 'dark'
                            ? 'white'
                            : '#667085'
                        }
                      />
                    )}
                  </IconButtonTooltip>
                </Box>
              )}
            </Box>
          </Box>
          {sidebarOpen && (
            <Collapse in={open} timeout={800}>
              <div style={{}}>
                {/* {folder?.flows?.filter((_, index) => dragStarted.started ? index < 5 : true).map((flow, index) => ( */}
                {folder?.flows?.map((flow, index) => (
                  <Draggable
                    draggableId={flow.id}
                    index={flow?.index}
                    key={flow.id}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="item-container"
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <SidebarFlow
                          folderId={folder?.id}
                          key={flow.id}
                          flow={flow}
                          theme={theme}
                          onClick={onClick}
                          spaceId={space?.id}
                          isSpace={isSpace}
                          openUpdateFlowModal={openUpdateFlowModal}
                          setFolder={setFolder}
                          folder={folder}
                          onCreateFlow={async () => {
                            onCreateFlow(
                              flow?.name,
                              true,
                              flow?.id,
                              folder?.id,
                              true,
                              'flow',
                            );
                            const res = await FolderService.getOne(folder?.id);

                            setFolder(res.data);
                          }}
                          onDeleteFlowSuccess={onDeleteFlowSuccess}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            </Collapse>
          )}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default SidebarFolders;
