import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';

export class LumaLabsService extends ApiService {
  static pathName = '/luma_labs';

  static createGeneration = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/create-generation`, body);
  };

  static getGeneration = (id: any): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/get-generation/${id}`);
  };
}
