import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SpecificSpaceTable from './SpecificSpaceTable';
import DisableSpaceModal from './DisableSpaceModal';
import AddUsersModal from './AddUsersModal';
import { CompanySpace } from '../../util/Types/CompanySpace';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { ArrowLeft, Copy, PencilSimpleLine } from '@phosphor-icons/react';
import LoadingSpinner from '../UI/LoadingSpinner';
import SetFluxTokenLimitModal from './SetFluxTokenLimitModal';
import UsersAddedModal from './UsersAddedModal';
import { useAuthStore } from '../../store/storeAuth';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import EditSpaceModal from './EditSpaceModal';

interface Props {
  spaceId: string;
  setSelectedSpaceId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const statsTime = {
  'This Month': 'This Month',
  '30 Days': '30 Days',
  '90 Days': '90 Days',
  Lifetime: 'Lifetime',
};

const queryTime: { [key: string]: string } = {
  'This Month': 'this_month',
  '30 Days': '30_days',
  '90 Days': '90_days',
};

const SpaceDetails: React.FC<Props> = ({ spaceId, setSelectedSpaceId }) => {
  const authUser = useAuthStore(state => state.user);

  const setNotification = useNotificationStore(state => state.setNotification);
  const [selectedStatsTime, setSelectedStatsTime] = useState(
    statsTime['Lifetime'],
  );
  const [showDisableSpaceModal, setShowDisableSpaceModal] = useState(false);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [spaceDataLoading, setSpaceDataLoading] = useState(true);
  const [companySpaceData, setCompanySpaceData] = useState<
    undefined | CompanySpace
  >();
  const [showSetFluxTokenLimitModal, setShowSetFluxTokenLimitModal] =
    useState(false);
  const [showUsersAddessModal, setShowUsersAddedModal] = useState(false);
  const [openModalName, setOpenModalName] = useState(false);

  const copySpaceId = async () => {
    try {
      navigator.clipboard.writeText(spaceId);
      setNotification({
        type: NotificationType.Success,
        message: 'Space ID copied successfully',
      });
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: 'Something went wrong',
      });
    }
  };

  const getCompanySpace = useCallback(async () => {
    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      const query =
        selectedStatsTime === statsTime.Lifetime
          ? ''
          : `&date=${queryTime[selectedStatsTime]}`;
      const { data } = await CompanySpacesService.getCompanySpace(
        authUser.selectedCompanyId,
        spaceId,
        query,
      );
      setCompanySpaceData(data);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }

    setSpaceDataLoading(false);
  }, [authUser, spaceId, selectedStatsTime]);

  const onSaveSpaceName = async (name: string) => {
    try {
      await CompanySpacesService.update(spaceId, {
        name,
      });

      getCompanySpace();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }
  };

  useEffect(() => {
    getCompanySpace();
  }, [getCompanySpace]);

  const onOpenOrClose = (open: boolean) => {
    setOpenModalName(open);
  };

  if (spaceDataLoading) return <LoadingSpinner />;

  return (
    <>
      {showDisableSpaceModal && (
        <DisableSpaceModal
          onClose={() => setShowDisableSpaceModal(false)}
          spaceId={spaceId}
        />
      )}

      {showAddUsersModal && (
        <AddUsersModal
          onClose={() => setShowAddUsersModal(false)}
          spaceId={spaceId}
          getCompanySpace={getCompanySpace}
          setShowUsersAddedModal={setShowUsersAddedModal}
        />
      )}

      {showUsersAddessModal && (
        <UsersAddedModal onClose={() => setShowUsersAddedModal(false)} />
      )}

      {showSetFluxTokenLimitModal && (
        <SetFluxTokenLimitModal
          onClose={() => setShowSetFluxTokenLimitModal(false)}
          spaceId={spaceId}
          getCompanySpace={getCompanySpace}
        />
      )}

      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10x',
            width: '100%',
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid rgba(234, 236, 240, 1)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '10x',
              height: '64px',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                marginLeft: '10px',
                display: 'inline-block',
              }}
              onClick={() => setSelectedSpaceId(undefined)}
            >
              <ArrowLeft color="rgba(52, 51, 48, 1)" size={18} />
            </Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '32px',
                margin: '24px',
                pb: '10px',
              }}
            >
              Space Details
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 0',
            borderBottom: '1px solid rgba(234, 236, 240, 1)',
            paddingX: '24px',
            paddingY: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography
                sx={{
                  color: 'rgba(71, 84, 103, 1)',
                  fontSize: '24px',
                  lineHeight: '32px',
                }}
              >
                {companySpaceData?.name}
              </Typography>

              <IconButtonTooltip
                onClick={() => onOpenOrClose(true)}
                title="Edit space name"
              >
                <PencilSimpleLine size={16} color={'#667085'} />
              </IconButtonTooltip>
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '0.1px',
                color: 'rgba(71, 84, 103, 1)',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              {companySpaceData?.email}
              <Tooltip
                arrow
                placement="top"
                title="Copy space ID"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <IconButton onClick={copySpaceId}>
                  <Copy size={16} />
                </IconButton>
              </Tooltip>
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '22px',
                  letterSpacing: '0.1px',
                  color: 'rgba(71, 84, 103, 1)',
                }}
              >
                {companySpaceData?.users.length} users
              </Typography>
              {companySpaceData?.limit ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Typography sx={{ color: 'rgba(54, 80, 222, 1)' }}>
                    {companySpaceData.limit} FluxToken
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(54, 80, 222, 1)',
                      fontWeight: 500,
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowSetFluxTokenLimitModal(true)}
                  >
                    Edit
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={{
                    color: 'rgba(54, 80, 222, 1)',
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowSetFluxTokenLimitModal(true)}
                >
                  Set FluxToken Limit
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <Button
              variant="text"
              onClick={() => setShowDisableSpaceModal(true)}
            >
              Disable Space
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowAddUsersModal(true)}
            >
              Add Users
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingX: '24px',
            paddingY: '15px',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(71, 84, 103, 1)',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '28.8px',
              letterspacing: '0.15px',
            }}
          >
            {companySpaceData?.name} Stats Overview
          </Typography>

          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {Object.values(statsTime).map(statsTime => (
              <Box
                key={statsTime}
                onClick={() => setSelectedStatsTime(statsTime)}
                sx={{
                  borderRadius: '8px',
                  padding: '3px 8px',
                  background:
                    selectedStatsTime === statsTime
                      ? 'rgba(54, 80, 222, 1)'
                      : 'rgba(234, 236, 240, 1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{
                    color:
                      selectedStatsTime === statsTime
                        ? 'white'
                        : 'rgba(16, 24, 40, 1)',
                    fontSize: '12px',
                    lineHeight: '19.92px',
                    letterSpacing: '0.4px',
                  }}
                >
                  {statsTime}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            width: '96%',
            paddingBottom: '20px',
            borderBottom: '1px solid rgba(234, 236, 240, 1)',
            paddingX: '24px',
            paddingY: '15px',
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
              flex: '1',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
            >
              {companySpaceData?.usedTokens?.toFixed(3) || 0}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
            >
              Ai FluxTokens
            </Typography>
          </Box>

          <Box
            sx={{
              background:
                'linear-gradient(53.38deg, #FD746C 16.71%, #FE846A 48.74%, #FF9068 80.78%)',
              flex: '1',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
            >
              {companySpaceData?.textTokens?.toFixed(3) || 0}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
            >
              Test FluxTokens{' '}
            </Typography>
          </Box>

          <Box
            sx={{
              background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
              flex: '1',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
            >
              {companySpaceData?.imageTokens?.toFixed(3) || 0}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
            >
              Image FluxTokens
            </Typography>
          </Box>

          <Box
            sx={{
              background:
                ' linear-gradient(63.43deg, #73E49A 14.58%, #3FC36D 82.62%)',
              flex: '1',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
            >
              {companySpaceData?.audioTokens?.toFixed(3) || 0}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
            >
              Audio FluxTokens
            </Typography>
          </Box>

          <Box
            sx={{
              background:
                'linear-gradient(58.74deg, #683BB8 7.2%, #A969FD 92.35%)',
              flex: '1',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
            >
              {companySpaceData?.numberOfFlows || 0}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
            >
              Flows Created
            </Typography>
          </Box>

          <Box
            sx={{
              background:
                'linear-gradient(58.74deg, #683BB8 7.2%, #A969FD 92.35%)',
              flex: '1',
              padding: '12px',
              borderRadius: '8px',
            }}
          >
            <Typography
              sx={{ fontSize: '24px', lineHeight: '32px', color: 'white' }}
            >
              {companySpaceData?.nodeRuns || 0}
            </Typography>
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white' }}
            >
              Node Runs
            </Typography>
          </Box>
        </Box>

        {openModalName && (
          <EditSpaceModal
            spaceName={companySpaceData?.name!}
            onClose={() => onOpenOrClose(false)}
            onSave={onSaveSpaceName}
          />
        )}

        {companySpaceData?.users && (
          <SpecificSpaceTable
            spaceId={spaceId}
            users={companySpaceData.users}
            getCompanySpace={getCompanySpace}
          />
        )}
      </Box>
    </>
  );
};

export default SpaceDetails;
