export interface FormData {
  prompt: string;
  negativePrompt: string;
  baseModel: string;
  seed: number;
  text: string;
  aspect_ratio: string;
  loop: string;
  start_image: string;
  end_image: string;
  cfg_scale: number;
  motion_bucket_id: number;
  duration: number;
  type: string;
}

export const baseModels = ['luma-labs', 'stability', 'runway-ml'];

export const defaultFormData: FormData = {
  prompt: '',
  negativePrompt: '',
  baseModel: 'luma-labs',
  seed: 0,
  text: '',
  aspect_ratio: '16:9',
  loop: 'false',
  start_image: '',
  end_image: '',
  cfg_scale: 1.8,
  motion_bucket_id: 127,
  duration: 5,
  type: 'Text to Video',
};
