import {
  DragEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './Sidebar.scss';

import { useAuthStore } from '../store/storeAuth';
import {
  Box,
  Button,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Info, Wrench } from '@phosphor-icons/react';
import { types } from './sidebarFlowConstants';
import KitModal from '../components/UI/KitModal';
import { colors } from '../components/UI/Node/nodeConstants';
import { useStoreApi } from 'reactflow';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const ApiConfigModal = ({
  show,
  onHide,
  config,
  flowId,
  apiKey,
}: {
  show: boolean;
  onHide: () => void;
  config: string;
  flowId: string;
  apiKey: string;
}) => {
  const checkText = useMemo(() => {
    const text =
      config === 'No var input nodes found'
        ? config
        : `{
        ${config}
      }`;

    try {
      return JSON.stringify(JSON.parse(text), null, 2);
    } catch (e) {
      return config;
    }
  }, [config]);

  const generateLinkForFlowId = useMemo(() => {
    if (window.location.origin.includes('localhost')) {
      return `http://localhost:3001/flux/api-v2?flowId=${flowId}`;
    }

    if (window.location.origin.includes('staging')) {
      return `https://api-staging.fluxprompt.ai/flux/api-v2?flowId=${flowId}`;
    }

    if (window.location.origin.includes('app')) {
      return `https://api.fluxprompt.ai/flux/api-v2?flowId=${flowId}`;
    }

    if (window.location.origin.includes('dev')) {
      return `https://api-dev.fluxprompt.ai/flux/api-v2?flowId=${flowId}`;
    }
  }, [flowId]);

  const { user } = useAuthStore(state => state);

  return (
    <KitModal maxWidth={'md'} fullWidth show={show} onHide={onHide}>
      <Box>
        <Typography
          variant={'h5'}
          color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
        >
          API Config
        </Typography>

        <br />

        <Typography
          variant={'body1'}
          color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
        >
          You can use the following link to access the API for the flow.
        </Typography>
        <br />
        <TextField
          fullWidth
          value={generateLinkForFlowId}
          InputProps={{
            readOnly: true,
            style: {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
          }}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            border: `2px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
        />

        <br />

        <Typography
          variant={'body1'}
          color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
        >
          Use the following API Key to authenticate the API request.
        </Typography>
        <br />

        <TextField
          fullWidth
          value={'api-key' + ' : ' + apiKey}
          InputProps={{
            readOnly: true,
            style: {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
          }}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            border: `2px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
        />

        <Button
          onClick={() => {
            navigator.clipboard.writeText(generateLinkForFlowId!);
          }}
        >
          Copy Link
        </Button>

        <br />
        <br />

        <Typography
          variant={'body1'}
          color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
        >
          This is the API configuration for the flow. You can use this to
          generate the API for the flow.
        </Typography>

        <br />
      </Box>
      <Box>
        <TextField
          multiline
          rows={20}
          fullWidth
          value={checkText}
          InputProps={{
            readOnly: true,
            style: {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
          }}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            border: `2px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
        />
      </Box>
      <br />
      <Button
        onClick={() => {
          navigator.clipboard.writeText(checkText);
        }}
      >
        Copy API Config Body
      </Button>
    </KitModal>
  );
};

type Props = {
  onSave?: () => void;
  onRestore?: () => void;
  onGenerateApi: string;
  onClickAddNode: (
    data:
      | {
          type: string;
          label: string;
        }
      | any,
  ) => void;
  flowId: string;
};

const nameOfSectionBaseInColor = [
  {
    name: 'Text',
    color: colors.text.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>
        {props.open ? colors.text.iconFilled : colors.text.icon}
      </SvgIcon>
    ),
    open: false,
    bgColor: 'purple',
  },
  {
    name: 'Visual',
    color: colors.image.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>
        {props.open ? colors.image.iconFilled : colors.image.icon}
      </SvgIcon>
    ),
    open: false,
    bgColor: 'blue',
  },

  {
    name: 'Audio',
    color: colors.audio.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>
        {props.open ? colors.audio.iconFilled : colors.audio.icon}
      </SvgIcon>
    ),
    open: false,
    bgColor: 'green',
  },
  {
    name: 'Data',
    color: colors.data.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>
        {props.open ? colors.data.iconFilled : colors.data.icon}
      </SvgIcon>
    ),
    open: false,
    bgColor: 'orange',
  },
  {
    name: 'Web',
    color: colors.web.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>{props.open ? colors.web.iconFilled : colors.web.icon}</SvgIcon>
    ),
    open: false,
    bgColor: 'silver',
  },

  {
    name: 'Functions',
    color: colors.functions.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>
        {props.open ? colors.functions.iconFilled : colors.functions.icon}
      </SvgIcon>
    ),
    open: false,
    bgColor: 'red',
  },

  {
    name: 'External ',
    color: colors.external.color,
    Icon: (props: { open: boolean }) => (
      <SvgIcon>
        {props.open ? colors.external.iconFilled : colors.external.icon}
      </SvgIcon>
    ),
    open: false,
    bgColor: 'violet',
  },
];

const Sidebar = ({ onGenerateApi, onClickAddNode, flowId }: Props) => {
  const [generateApiConfig, setGenerateApiConfig] = useState(false);
  const store = useStoreApi();
  const [isHoveringInOpened, setIsHoveringInOpened] = useState(false);

  const { user } = useAuthStore(state => state);
  const [sidebar, setSidebar] = useState(nameOfSectionBaseInColor);

  const trackNodeCreation = (method: 'click' | 'drag', nodeType: string) => {
    window.dataLayer = window.dataLayer || [];
    const event = {
      event: 'node_creation',
      method: method || 'unknown',
      nodeType: nodeType || 'unknown',
      userId: user?.id || 'unknown',
      userName: user?.name || 'unknown',
    };
    // console.log('Data to be pushed:', event);
    window.dataLayer.push(event);
  };

  const onDragStart = (event: DragEvent<HTMLDivElement>, nodeType: any) => {
    event.dataTransfer.setData(
      'application/reactflow',
      JSON.stringify(nodeType),
    );
    event.dataTransfer.effectAllowed = 'move';
  };

  const ref = useRef<
    HTMLDivElement | HTMLParagraphElement | HTMLHeadingElement | null
  >(null);

  const replacedText =
    onGenerateApi === 'No var input nodes found'
      ? onGenerateApi
      : '"variableInputs":' +
        ' \n' +
        onGenerateApi
          .replace(/,/g, ',\n')
          .replace(/\{/g, '{\n')
          .replace(/\[/g, '[\n')
          .replace(/\}/g, '\n}')
          .replace(/\]/g, '\n]');

  const positionsBasedOnRefAndIndex = useCallback(
    (index: number) => {
      if (ref.current) {
        if (index === 0) {
          return -1;
        }

        const lastIndex = sidebar.length - 1;

        if (index === lastIndex) {
          return index * 75 - 160;
        }
        if (index === 3) {
          return index * 75 - 15;
        }

        if (index === 4) {
          return index * 75 - 20;
        }

        if (index === 5) {
          return index * 75 - 30;
        }

        return index * 75;
      }
    },
    [ref.current],
  );
  const open = sidebar?.findIndex((item, index) => item.open);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!isHoveringInOpened) {
      timeoutId = setTimeout(() => {
        setSidebar(prev => prev.map(item => ({ ...item, open: false })));
      }, 3000);
    }

    return () => clearTimeout(timeoutId);
  }, [open, isHoveringInOpened]);

  return (
    <>
      <div
        className={` right-sidebar `}
        style={{
          backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
          border: user?.theme === 'dark' ? '#344054' : '#D0D5DD',
          borderRadius:
            open === 0
              ? '8px 0 8px 8px'
              : open === sidebar.length - 1
              ? '8px 8px 0px 8px'
              : '8px',
        }}
      >
        <aside>
          <Box ref={ref} display={'flex'} flexDirection={'column'} gap={'16px'}>
            {sidebar.map(({ name, color, Icon, open, bgColor }, index) => (
              <Fragment key={index}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'5px'}
                  alignContent={'center'}
                  alignItems={'center'}
                  onClick={() => {
                    // trackNodeCreation('click', name);
                    setSidebar(prev => {
                      const newSidebar = prev.map((item, idx) => {
                        if (idx === index) {
                          return {
                            ...item,
                            open: !item.open, // Toggling the open state
                          };
                        } else {
                          return {
                            ...item,
                            open: false, // Closing other sections
                          };
                        }
                      });
                      return newSidebar;
                    });
                  }}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    padding: '4px 0',
                    // backgroundColor: bgColor,
                    backgroundColor: open
                      ? user?.theme === 'dark'
                        ? '#1D2939'
                        : '#F9FAFB'
                      : user?.theme === 'dark'
                      ? '#1D2939'
                      : '#F9FAFB',

                    '&:hover': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F2F4F7',
                    },
                  }}
                >
                  <Icon open={open} />

                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: open
                        ? color
                        : user?.theme === 'dark'
                        ? '#D0D5DD'
                        : '#667085',
                      fontSize: '14px',
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              </Fragment>
            ))}
          </Box>
        </aside>
        <Box
          sx={{
            position: 'absolute',
            left: '77px',
            height: 'fit-content',
            top:
              open === sidebar.length - 1
                ? ''
                : positionsBasedOnRefAndIndex(open),
            bottom:
              open === sidebar.length - 1
                ? 0
                : positionsBasedOnRefAndIndex(open),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {types.map(
              ({ label, type, btn, bgColor, tooltip, icon }, index) => {
                const open = sidebar?.filter((item, index) => item.open);

                const findOpenWithBgColor = open?.find(
                  item => item.bgColor === bgColor,
                );

                if (
                  findOpenWithBgColor?.bgColor === bgColor &&
                  findOpenWithBgColor.open
                ) {
                  if (label === 'Api Config') {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          border:
                            user?.theme === 'dark'
                              ? '1px solid #1D2939'
                              : '1px solid #F9FAFB',
                          borderRadius: '0 4px 4px 0',
                          bgcolor: user?.theme === 'dark' ? '#101828' : '#fff',
                          padding: '8px',
                          cursor: 'grab',
                          gap: '4px',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#667085',
                          '&:hover': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F2F4F7',
                          },
                        }}
                        onMouseEnter={() => {
                          setIsHoveringInOpened(true);
                        }}
                        onMouseLeave={() => {
                          setIsHoveringInOpened(false);
                        }}
                        key={index}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                          }}
                        >
                          <Wrench
                            color={
                              user?.theme === 'dark' ? '#D0D5DD' : '#667085'
                            }
                          />
                          <Typography
                            onClick={() => setGenerateApiConfig(true)}
                            style={{
                              whiteSpace: 'nowrap',
                              fontSize: '16px',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#667085',
                            }}
                          >
                            {btn}
                          </Typography>
                        </Box>
                        {tooltip && (
                          <Tooltip
                            key={index}
                            title={tooltip}
                            placement="right"
                          >
                            <Info
                              size={20}
                              color={
                                user?.theme === 'dark' ? '#D0D5DD' : '#667085'
                              }
                            />
                          </Tooltip>
                        )}
                      </Box>
                    );
                  }

                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border:
                          user?.theme === 'dark'
                            ? '1px solid #1D2939'
                            : '1px solid #F9FAFB',
                        borderRadius: '0 4px 4px 0',
                        bgcolor: user?.theme === 'dark' ? '#101828' : '#fff',
                        padding: '8px',
                        cursor: 'grab',
                        gap: '4px',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#667085',
                        '&:hover': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F2F4F7',
                        },
                      }}
                      key={index}
                      onClick={() => {
                        if (type) {
                          trackNodeCreation('click', type);
                        }
                        onClickAddNode({
                          type,
                          label,
                          store: store.getState(),
                        });
                      }}
                      onMouseEnter={() => {
                        setIsHoveringInOpened(true);
                      }}
                      onMouseLeave={() => {
                        setIsHoveringInOpened(false);
                      }}
                      onDragStart={event => {
                        if (type) {
                          trackNodeCreation('drag', type);
                        }
                        onDragStart(event, { type, label });
                      }}
                      draggable={true}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        {/* Custom Icon */}
                        {icon ? icon : <Wrench />}
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            fontSize: '16px',
                          }}
                        >
                          {btn}
                        </Typography>
                      </Box>
                      {tooltip && (
                        <Tooltip key={index} title={tooltip} placement="right">
                          <Info size={20} />
                        </Tooltip>
                      )}
                    </Box>
                  );
                }
              },
            )}
          </Box>
        </Box>
      </div>
      <ApiConfigModal
        show={generateApiConfig}
        onHide={() => setGenerateApiConfig(false)}
        config={replacedText}
        flowId={flowId}
        apiKey={
          user?.apiKey! || 'Please add your API Key found on the settings page'
        }
      />
    </>
  );
};

export default Sidebar;
