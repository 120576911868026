import React, { useEffect, useState } from 'react';
import KitModal from '../../UI/KitModal';
import { Box, Typography } from '@mui/material';
import IconButtonTooltip from '../../UI/IconButtonTooltip/IconButtonTooltip';
import {
  CaretLeft,
  CaretRight,
  Copy,
  FileArrowDown,
} from '@phosphor-icons/react';

import { onDownloadFile } from '../../../util/fileDownloader';

type Props = {
  show: boolean;
  onHide: () => void;
  responses: string[];
  active: string;
};

const PreviewModal = ({ show, onHide, active, responses }: Props) => {
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    if (show) {
      const findIndex = responses.findIndex(response => response === active);
      setActiveImage(findIndex !== -1 ? findIndex : 0);
    }
  }, [show]);

  const onCopy = () => {
    navigator.clipboard.writeText(responses[activeImage]);
  };

  const onPreview = (index: number) => {
    if (index < 0 || index >= responses.length) return;

    setActiveImage(index);
  };

  const onNext = () => {
    if (activeImage + 1 >= responses.length) return;
    setActiveImage(activeImage + 1);
  };

  const maxHeight = window?.innerHeight - 400;

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '1341px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '24px',
        },
        '& .MuiDialogActions-root': {
          padding: '24px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Box
          sx={{
            mt: '24px',
            mb: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: '#344054',
              p: '5px 6px',
              backgroundColor: '#F2F4F7',
              borderRadius: '4px',
              width: 'fit-content',
            }}
          >
            {activeImage + 1}-{responses.length}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            <IconButtonTooltip
              onClick={() => onDownloadFile(responses[activeImage], `image`)}
              title='Download Image'
              sx={{
                backgroundColor: '#F2F4F7',
                p: '6px',
              }}
            >
              <FileArrowDown color='#344054' size={18} />
            </IconButtonTooltip>

            <IconButtonTooltip
              sx={{
                backgroundColor: '#F2F4F7',
                p: '6px',
              }}
              onClick={onCopy}
              title='Copy image url'
            >
              <Copy color='#344054' size={18} />
            </IconButtonTooltip>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              boxShadow:
                '0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 5px 6px -3px rgba(0, 0, 0, 0.20)',
              borderRadius: '8px',
              maxWidth: 'fit-content',
              maxHeight: maxHeight + 'px',
            }}
            src={responses[activeImage] || ''}
            alt='image'
          />
        </Box>

        <Box mt='32px'>
          {responses.length > 1 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '8px',
                mt: '16px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F2F4F7',
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  cursor: 'pointer',
                  p: '6px',
                }}
                onClick={() => onPreview(activeImage - 1)}
              >
                <CaretLeft color='#344054' size={18} />
              </Box>
              {responses?.map((res, index) => (
                <Box key={res}>
                  <img
                    onClick={() => setActiveImage(index)}
                    style={{
                      width: activeImage === index ? '93px' : '97px',
                      height: activeImage === index ? '93px' : '97px',
                      border:
                        activeImage === index
                          ? '4px solid #F9FAFB'
                          : '1px solid #F9FAFB',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      objectFit: 'cover',
                      boxShadow:
                        activeImage !== index
                          ? '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
                          : '0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 5px 6px -3px rgba(0, 0, 0, 0.20)',
                    }}
                    src={res}
                    alt={'res' + res}
                  />
                </Box>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F2F4F7',
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  cursor: 'pointer',
                  p: '6px',
                }}
                onClick={() => onNext()}
              >
                <CaretRight color='#344054' size={18} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default PreviewModal;
