import KitModal from '../../../UI/KitModal';
import {
  Box,
  Button,
  Divider,
  FormLabel,
  IconButton,
  TextField,
} from '@mui/material';
import { Trash } from '@phosphor-icons/react';

const ObjectModal = ({
  show,
  onHide,
  objectUpdate,
  setObjectUpdate,
  onObjectUpdate,
  isRenameClicked,
  isLoading,
}: any) => {
  const onObjectChange = (e: { target: { name: any; value: any } }) => {
    if (e.target.name === 'objectName') {
      setObjectUpdate((prev: any) => ({ ...prev, name: e.target.value }));
    } else {
      const modifiedObjectTexts = objectUpdate.text.map((text: any) => {
        if (e.target.name === text.id) {
          return {
            ...text,
            text: e.target.value,
          };
        }
        return text;
      });
      setObjectUpdate((prev: any) => ({ ...prev, text: modifiedObjectTexts }));
    }
  };

  const addNewElement = () => {
    const newElement = {
      id: Math.random().toString(36).substr(2, 9),
      text: '',
    };
    setObjectUpdate((prev: any) => ({
      ...prev,
      text: [...prev.text, newElement],
    }));
  };

  const onRemoveElement = (id: string) => {
    const modifiedObjectTexts = objectUpdate.text.filter(
      (text: any) => text.id !== id
    );
    setObjectUpdate((prev: any) => ({ ...prev, text: modifiedObjectTexts }));
  };

  return (
    <KitModal
      onHide={() => {
        onHide();
        setObjectUpdate({
          id: '',
          text: [],
        });
      }}
      fullWidth
      title={objectUpdate?.isCreating ? 'Create Object' : 'Update Object'}
      show={show}
      footer={
        <Button
          disabled={isLoading}
          variant='contained'
          onClick={onObjectUpdate}
        >
          {objectUpdate?.isCreating ? 'Create' : 'Update'}
        </Button>
      }
    >
      <div className='input-group mb-3'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
            alignContent: 'center',
            mb: 2,
          }}
        >
          <FormLabel>Object Name</FormLabel>
          <TextField
            defaultValue={objectUpdate.name}
            name='objectName'
            placeholder="Enter object's name"
            onChange={onObjectChange}
          />
        </Box>

        {isRenameClicked === false && (
          <>
            <Divider sx={{ mb: '20px' }} />
            {objectUpdate?.text?.map((item: any) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    alignContent: 'center',
                    mb: 2,
                  }}
                  key={item?.id}
                  className='input-group-prepend'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      gap: '20px',
                    }}
                  >
                    <textarea
                      className='form-control'
                      defaultValue={item?.text}
                      name={item.id}
                      onChange={onObjectChange}
                      style={{
                        maxWidth: '100%',
                        width: '100%',
                        height: '40px',
                      }}
                    />

                    <IconButton
                      color={'error'}
                      onClick={() => onRemoveElement(item?.id)}
                    >
                      <Trash size={20} />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          </>
        )}
      </div>

      {isRenameClicked === false && (
        <Button variant='contained' onClick={addNewElement}>
          Add new element
        </Button>
      )}
    </KitModal>
  );
};

export default ObjectModal;
