import React, { useEffect, useState } from 'react';
import { EvenLabsService } from '../../service/EvanLabsServce';
import { User } from '../../util/Types/User';
import { Select } from '@mui/material';
type Props = {
  formData: any;
  onChange: (event: any) => void;
  user: User;
  voices: VoiceInfo[];
  settings: {
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
  };
  setEvenLabsSettings: (settings: any) => void;
};

export interface VoiceInfo {
  id: string;
  voice_id: string;
  name: string;
  samples: null | any[]; // You can replace 'any[]' with a more specific type if needed
  category: string;
  fine_tuning: {
    language: null | string;
    is_allowed_to_fine_tune: boolean;
    fine_tuning_requested: boolean;
    finetuning_state: string;
    verification_attempts: null | any[]; // You can replace 'any[]' with a more specific type if needed
    verification_failures: any[];
    verification_attempts_count: number;
    slice_ids: null | any[]; // You can replace 'any[]' with a more specific type if needed
    manual_verification: null | any; // You can replace 'any' with a more specific type if needed
    manual_verification_requested: boolean;
  };
  labels: {
    accent: string;
    description: null | string;
    age: string;
    gender: string;
    use_case: string;
  };
  description: null | string;
  preview_url: string;
  available_for_tiers: any[]; // You can replace 'any[]' with a more specific type if needed
  settings: null | any; // You can replace 'any' with a more specific type if needed
  sharing: null | any; // You can replace 'any' with a more specific type if needed
  high_quality_base_model_ids: any[]; // You can replace 'any[]' with a more specific type if needed
  src?: string;
}
const EvenLabs = ({
  onChange,
  formData,
  user,
  voices,
  settings,
  setEvenLabsSettings,
}: Props) => {
  const makePercentage = (value: number) => {
    // if value is 0.5, return 50 from 0.0 to 1.0
    return Math.round(value * 100);
  };

  return (
    <div>
      <Select
        className='nodrag'
        value={formData.voiceId}
        name='voiceId'
        onChange={onChange}
        sx={{
          bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          borderRadius: '8px',
          border: 'none',
          boxShadow: 'none',
          mb: '24px',
          width: '100%',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '300px',
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              '& .Mui-selected': {
                backgroundColor:
                  user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor:
                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        {voices
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(voice => (
            <option key={voice.voice_id} value={voice.voice_id}>
              {voice.name}{' '}
              <>
                {voice.labels.accent} / {voice.labels.gender}
              </>
            </option>
          ))}
      </Select>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <label htmlFor='stability'>Stability</label>
        <input
          type='range'
          className='nodrag'
          value={formData.stability}
          min={0}
          max={1}
          step={0.1}
          name='stability'
          onChange={onChange}
        />
        {makePercentage(formData.stability)} %
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <label htmlFor='similarity_boost'>Similarity Boost</label>
        <input
          type='range'
          className='nodrag'
          value={formData.similarity_boost}
          min={0}
          max={1}
          step={0.01}
          name='similarity_boost'
          onChange={onChange}
        />
        {makePercentage(formData.similarity_boost)} %
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <label htmlFor='style'>Style</label>
        <input
          type='range'
          className='nodrag'
          value={formData.style}
          min={0}
          max={1}
          step={0.01}
          name='style'
          onChange={onChange}
        />
        {makePercentage(formData.style)} %
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <label htmlFor='use_speaker_boost'>Use Speaker Boost</label>
        <input
          type='checkbox'
          className='nodrag'
          checked={formData.use_speaker_boost}
          name='use_speaker_boost'
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default EvenLabs;
