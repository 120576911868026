import React, {
  ChangeEvent,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  conditionsCheck,
  singleConditionCheck,
} from '../../util/conditionsCheck';
import { NodeProps } from '../../util/Types/NodeProps';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import useFlowsStore from '../../store/storeFlows';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import Node from '../UI/Node/Node';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { Question, TrashSimple } from '@phosphor-icons/react';
import TagInput from '../Test/TagInput';
import Label from '../UI/Label/Label';
import ConditionHandle from '../UI/ConditionHandle/ConditionHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useEdges, useReactFlow } from 'reactflow';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';

const conditionTypes = {
  number: {
    operators: [
      { value: '>', label: 'Greater Than' },
      { value: '<', label: 'Less Than' },
      { value: '=', label: 'Equal' },
    ],
    inputType: 'number',
  },
  charCount: {
    operators: [
      { value: '>', label: 'Greater Than' },
      { value: '<', label: 'Less Than' },
      { value: '=', label: 'Equal' },
    ],
    inputType: 'number',
  },
  text: {
    operators: [
      { value: 'begins', label: 'Begins' },
      { value: 'contains', label: 'Contains' },
      { value: 'ends', label: 'Ends' },
    ],
    inputType: 'text',
  },
  sentiment: {
    operators: [{ value: 80 }],
    inputType: 'textarea',
  },
};

interface ConditionProps {
  type?: keyof ConditionProps | string | undefined;
  operator?: string;
  value?: number | string;
  logicOperator?: string;
  sentimentValue?: string;
  sentimentOperatorValue?: number | string;
  textType?: string;
}

const DecisionPaths = memo(({ data, id, type }: NodeProps) => {
  const [conditions, setConditions] = useState<ConditionProps[]>(
    data?.conditions || [],
  );
  const { setNodes: setNodeState } = useReactFlow();

  const { setSchema, setSaving, flowId } = useFlowsStore(state => state);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: null,
    name: '',
  });

  const edges = useEdges();

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statement, setStatement] = useState(true);
  const [text, setText] = useState(data.text);

  const addCondition = () => {
    const newConditions = [
      ...conditions,
      {
        type: 'number',
        operator: '>',
        value: '',
        logicOperator: conditions[1]?.logicOperator
          ? conditions[1]?.logicOperator
          : 'AND',
        sentimentValue: '',
        sentimentOperatorValue: '',
      },
    ];
    setConditions(newConditions);
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,

      flowId,
      objectData: {
        conditions: newConditions,
      },
      setSchema,
    });
  };

  useDebounceEffect(
    () => {
      if (data.conditions && !isChangingDirectly) {
        setConditions(data.conditions);

        const newData = data.conditions;

        let text = data.text;

        if (data.textData) {
          text = data.textData?.map((item: any) => item.text).join('\n');
        }

        setStatement(conditionsCheck(newData, text));
        if (data.statement !== conditionsCheck(newData, text)) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            setSaving,
            id,

            flowId,
            objectData: {
              statement: conditionsCheck(newData, text),
            },
            setSchema,
          });
        }
      } else if (!isChangingDirectly) {
        const newConditions = [
          {
            type: 'number',
            operator: '>',
            value: '',
            sentimentValue: '',
            sentimentOperatorValue: '',
          },
        ];
        setConditions(newConditions);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,

          flowId,
          objectData: {
            conditions: newConditions,
          },
          setSchema,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const deleteCondition = (index: number) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        conditions: newConditions,
      },
      setSchema,
    });
  };

  const [hasToExecute, setHasToExecute] = useState(true);

  const debounceTimeoutRef = useRef<any>(null);

  const onCheckCondition = () => {
    setIsLoading(true);

    // add value to conditions

    let text = data.text;

    if (data.textData) {
      text = data.textData
        ?.map((item: { text: string }) => item.text)
        .join('\n');
    }

    setStatement(conditionsCheck(conditions, text));

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      changeType: 'execute',
      objectCallerData: [
        ...(user?.spaceObjects || []),
        ...(user?.objects || []),
      ],

      flowTriggerData: checkExecuteTrigger(data, id),
      flowId,
      objectData: {
        statement: conditionsCheck(conditions, text),
      },
      setSchema,
    });

    setIsLoading(false);

    setTimeout(() => {
      setHasToExecute(true);
    }, 1000);
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onCheckCondition();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, conditions, data?.canceled]);

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | SelectChangeEvent<any>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const { name, value } = e.target;
    const newConditions = conditions.map((condition, i) => {
      if (name === 'logicOperator') {
        return {
          ...condition,
          [name]: value,
        };
      }

      if (i !== index) {
        return condition;
      }

      if (name === 'type') {
        const defaultOperator =
          conditionTypes[value as keyof typeof conditionTypes].operators[0]
            .value;

        return {
          ...condition,
          [name]: value,
          operator: defaultOperator,
        };
      }

      return {
        ...condition,
        [e.target.name]: e.target.value,
      };
    });

    setIsChangingDirectly(true);
    setConditions(newConditions as ConditionProps[]);

    setWhoIsChanging({
      value: newConditions,
      name: 'conditions',
    });
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);

    setWhoIsChanging({
      value: e.target.value,
      name: 'text',
    });
    setIsChangingDirectly(true);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  const handles = (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          p: '10px',
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          alignItems: 'center',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
      >
        <IconButtonTooltip
          title={
            'The condition is true if all conditions are true or false if any condition is false.'
          }
        >
          <Question color="#98A2B3" size={12} />
        </IconButtonTooltip>
        <Typography
          lineHeight={'0'}
          fontWeight={'bold'}
          color={'#475467'}
          fontSize={'12px'}
        >
          {'This condition is ' + (statement ? 'true' : 'false')}
        </Typography>
      </Box>
      <ConditionHandle
        handleId={'outputHandle-0'}
        label="True"
        // index={index}
        style={{
          width: 'unset',
          borderRadius: '15px',
          height: 'unset',
          padding: `5px 6px 5px 11px`,
          borderColor: findColor?.color,
          right: -60,
          top: 6,
        }}
        colorActive={findColor}
      />
      <ConditionHandle
        handleId={'outputHandle-1'}
        label="False"
        // index={index}
        style={{
          width: 'unset',
          borderRadius: '15px',
          height: 'unset',
          padding: `5px 6px 5px 6px`,
          borderColor: findColor?.color,
          right: -60,
          top: 30,
        }}
        colorActive={findColor}
      />
    </>
  );

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  const { user } = useAuthStore(state => state);

  const __renderConditions = useMemo(() => {
    return (
      <Box p={'16px'}>
        {conditions.map((condition, index) => {
          const conditionTypeAsIndex: keyof ConditionProps | any =
            condition?.type;

          const conditionType =
            conditionTypes[conditionTypeAsIndex as keyof typeof conditionTypes];

          let text = data.text;

          if (data.textData) {
            text = data.textData
              ?.map((item: { text: string }) => item.text)
              .join('\n');
          }

          const conditionCheck = singleConditionCheck(
            { ...condition },
            index,
            text,
          );

          return (
            <Box position={'relative'} key={index}>
              {condition.logicOperator === 'SWITCH' && (
                <ConditionHandle
                  handleId={'conditionHandle-T' + index}
                  label="T"
                  index={index}
                  colorActive={findColor}
                />
              )}
              {index === 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Label
                    isAdvanced={true}
                    labelName={`Condition ${index + 1}`}
                  />
                  <Typography
                    sx={{
                      color: conditionCheck ? '#80C683' : '#E47373',
                      fontSize: '12px',
                      fontWeight: 700,
                    }}
                  >
                    This condition is {conditionCheck ? 'true' : 'false'}
                  </Typography>
                </Box>
              )}
              {index > 0 && (
                <Box>
                  {index + 1 === conditions.length &&
                    condition.logicOperator === 'SWITCH' && (
                      <ConditionHandle
                        handleId={'conditionHandle-F' + index}
                        label="F"
                        top="84px"
                        index={index}
                        colorActive={findColor}
                      />
                    )}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: '24px',
                      gap: '8px',
                    }}
                  >
                    <Divider
                      sx={{
                        maxWidth:
                          condition.logicOperator !== 'SWITCH' ? '40%' : '18%',
                        width: '100%',
                      }}
                    />
                    <Select
                      className="nodrag"
                      name="logicOperator"
                      onChange={e => onChange(e, index)}
                      value={condition.logicOperator}
                      size="small"
                      sx={{
                        '& .MuiList-root': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        },
                        '& .MuiSvgIcon-root': {
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        },
                        borderRadius: '8px',
                        border: 'none',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .Mui-selected': {
                              backgroundColor:
                                user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                            },
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          },
                        },
                      }}
                    >
                      <MenuItem
                        value="AND"
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        AND
                      </MenuItem>
                      <MenuItem
                        value="OR"
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        OR
                      </MenuItem>
                      <MenuItem
                        value={'SWITCH'}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        {`If Condition ${index} is false, use Condition ${
                          index + 1
                        }`}
                      </MenuItem>
                    </Select>
                    <Divider
                      sx={{
                        maxWidth:
                          condition.logicOperator !== 'SWITCH' ? '40%' : '18%',
                        width: '100%',
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '8px',
                      mt: '8px',
                      mb: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <Label
                        isAdvanced={true}
                        labelName={`Condition ${index + 1}`}
                        sx={{
                          mb: 0,
                        }}
                      />
                      <IconButtonTooltip
                        title="Delete Condition"
                        onClick={() => deleteCondition(index)}
                      >
                        <TrashSimple size={16} color="#667085" />
                      </IconButtonTooltip>
                    </Box>

                    <Typography
                      sx={{
                        color: singleConditionCheck(condition, index, text)
                          ? '#80C683'
                          : '#E47373',
                        fontSize: '12px',
                        fontWeight: 700,
                      }}
                    >
                      This condition is {conditionCheck ? 'true' : 'false'}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  If
                </Typography>
                <Select
                  value={condition.type || 'number'}
                  onChange={e => onChange(e, index)}
                  name="type"
                  className="nodrag"
                  size="small"
                  sx={{
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  <MenuItem
                    value="number"
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    Number
                  </MenuItem>
                  <MenuItem
                    value="charCount"
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    Character Count
                  </MenuItem>
                  <MenuItem
                    value="sentiment"
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    Text Sentiment
                  </MenuItem>

                  <MenuItem
                    value="text"
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    Text
                  </MenuItem>
                </Select>
                {condition.type === 'sentiment' ? (
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                    }}
                    className="nodrag"
                    name="sentimentOperatorValue"
                    onChange={e => onChange(e, index)}
                    value={condition.sentimentOperatorValue}
                  />
                ) : (
                  <>
                    {condition.type !== 'text' && (
                      <Typography
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        Is
                      </Typography>
                    )}

                    <Select
                      onChange={e => onChange(e, index)}
                      name="operator"
                      className="nodrag"
                      value={condition.operator}
                      sx={{
                        '& .MuiList-root': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        },
                        '& .MuiSvgIcon-root': {
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        },
                        borderRadius: '8px',
                        border: 'none',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .Mui-selected': {
                              backgroundColor:
                                user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                            },
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          },
                        },
                      }}
                    >
                      {conditionType.operators.map((op: any) => (
                        <MenuItem
                          key={op.value}
                          value={op.value}
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          {op.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {(condition.operator === 'begins' ||
                  condition.operator === 'ends' ||
                  condition.operator === '=') && (
                  <Typography
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    with
                  </Typography>
                )}

                <Typography
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  this
                </Typography>

                {conditionType.inputType === 'number' ? (
                  <TextField
                    type="number"
                    className="nodrag"
                    name="value"
                    onChange={e => onChange(e, index)}
                    placeholder="Type number"
                    value={condition.value}
                    InputProps={{
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    sx={{
                      width: '137px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                  />
                ) : (
                  <TextField
                    name="sentimentValue"
                    placeholder="Type text"
                    className="nodrag"
                    onChange={e => onChange(e, index)}
                    value={condition.sentimentValue}
                    InputProps={{
                      style: {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                    }}
                    sx={{
                      width: '137px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      border: `2px solid ${
                        user?.theme === 'dark' ? '#475467' : '#EAECF0'
                      }`,
                    }}
                  />
                )}
              </Box>
            </Box>
          );
        })}

        <Button onClick={addCondition}>Add Condition</Button>
      </Box>
    );
  }, [
    conditions,
    data.text,
    data.textData,
    findColor,
    isChangingDirectly,
    user?.theme,
  ]);

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        customHandles={
          conditions?.length > 0 &&
          conditions[1]?.logicOperator === 'SWITCH' ? (
            <></>
          ) : (
            handles
          )
        }
        edges={edges}
        type={type}
        handleRun={handleRun}
        data={data}
        isLoading={isLoading}
        id={id}
        showTokensUsed={false}
      >
        {!data?.collapsed && (
          <Box display={'flex'}>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
            >
              <TagInput
                name={`text`}
                onChange={onChangeText}
                placeholder={
                  'Add decision paths to your workflows by connecting or typing information here and checking it against certain conditions.'
                }
                dataConnected={data?.['textData']}
                labelName={`Input`}
                width={'192px'}
                handleId={'text'}
                height={'150px'}
                findColor={findColor}
                disabled={isActiveEdge(edges, id, 'text', 'target')}
                isCollapsed={true}
                info={'Input'}
                nodeId={id}
                value={text}
                nodeLabel={data?.label}
              />
            </Box>

            {__renderConditions}
          </Box>
        )}
      </Node>
    </Box>
  );
});

export default memo(DecisionPaths);
