import { Box, FormLabel, Switch, TextField } from '@mui/material';
import React from 'react';

type Props = {
  formData: any;
  onChange: (e: any) => void;
};

const Blip2 = ({ formData, onChange }: Props) => {
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormLabel htmlFor='temperature'>Temperature</FormLabel>
        <TextField
          className='nodrag'
          name='temperature'
          id='temperature'
          type='number'
          value={formData.temperature}
          onChange={onChange}
          style={{
            resize: 'both',
            overflow: 'auto',
          }}
        />
      </Box>
      <Box style={{ display: 'flex', gap: '30px' }}>
        <FormLabel htmlFor='use_nucleus_sampling'>
          Use Nucleus Sampling
        </FormLabel>
        <Switch
          name='use_nucleus_sampling'
          id='use_nucleus_sampling'
          onChange={onChange}
          value={formData.use_nucleus_sampling as any}
        />
      </Box>
    </Box>
  );
};

export default Blip2;
