/* eslint-disable react-hooks/exhaustive-deps */
import KitModal from '../../UI/KitModal';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useAuthStore } from '../../../store/storeAuth';
import { useMemo, useState } from 'react';
import { useReactFlow } from 'reactflow';
import Label from '../../UI/Label/Label';
import { useNotificationStore } from '../../../store/storeNotifications';
import IconButtonTooltip from '../../UI/IconButtonTooltip/IconButtonTooltip';
import { Copy } from '@phosphor-icons/react';

import useFlowsStore from '../../../store/storeFlows';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: any;
  onChange: (e: any) => void;
  flowId?: string;
  isPublic?: boolean;
  isWebhook?: boolean;
  onChangeIsPublicOrWebhook?: (e: any) => void;
  spaceId?: string;
};

const SettingsModal = ({
  onHide,
  show,
  formData,
  onChange,
  flowId,
  isPublic,
  isWebhook,
  onChangeIsPublicOrWebhook,
}: Props) => {
  const { getNodes } = useReactFlow();
  const { flows } = useFlowsStore(state => state);
  const { user } = useAuthStore(state => state);

  const setNotification = useNotificationStore(state => state.setNotification);

  const config = useMemo(() => {
    let nodes = getNodes();
    const getAllVarInputNodes = () => {
      const varInputNodes = nodes?.filter(
        node => node?.type === 'varInputNode',
      );

      if (!varInputNodes.length) {
        return 'No var input nodes found';
      }

      const rawJson = JSON.stringify(
        varInputNodes.map((node, idx) => ({
          inputId: node?.id,
          inputText: `Example  ${idx + 1} Text For Input `,
        })),
      );

      return (
        '"variableInputs":' +
        ' \n' +
        rawJson
          .replace(/,/g, ',\n')
          .replace(/\{/g, '{\n')
          .replace(/\[/g, '[\n')
          .replace(/\}/g, '\n}')
          .replace(/\]/g, '\n]')
      );
    };
    return getAllVarInputNodes();
  }, [getNodes()]);

  const nodeOptionsApiInput = useMemo(() => {
    let nodes = getNodes();
    const getAllVarInputNodes = () => {
      const varInputNodes = nodes?.filter(
        node => node?.type === 'varInputNode',
      );

      if (!varInputNodes.length) {
        return [];
      }

      return varInputNodes.map(node => ({
        value: node?.id,
        label: node?.data?.label,
      }));
    };
    return getAllVarInputNodes();
  }, [getNodes()]);

  const activeFlow = flows.find(flow => flow.id === flowId);

  const apiKey = user?.apiKey;

  const checkText = useMemo(() => {
    const text =
      config === 'No var input nodes found'
        ? config
        : `{
        ${config}
      }`;

    try {
      return JSON.stringify(JSON.parse(text), null, 2);
    } catch (e) {
      return config;
    }
  }, [config]);

  const copyFlow = () => {
    navigator.clipboard.writeText(activeFlow?.id!);
    setNotification({
      message: 'Copied to clipboard',
      type: 'success',
    });
  };

  const copyFlowEmail = () => {
    navigator.clipboard.writeText(
      activeFlow?.id! + '@' + process.env.REACT_APP_FLOW_EMAIL,
    );
    setNotification({
      message: 'Copied to clipboard',
      type: 'success',
    });
  };

  const copyWebhookUrl = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_API_URL}/flux/api-v2-webhook?flowId=${flowId}`,
    );
    setNotification({
      message: 'Copied to clipboard',
      type: 'success',
    });
  };

  const generateLinkForFlowId = useMemo(() => {
    if (window.location.origin.includes('localhost')) {
      return `http://localhost:3001/flux/api-v2?flowId=${flowId}`;
    }

    if (window.location.origin.includes('staging')) {
      return `https://api-staging.fluxprompt.ai/flux/api-v2?flowId=${flowId}`;
    }

    if (window.location.origin.includes('app')) {
      return `https://api.fluxprompt.ai/flux/api-v2?flowId=${flowId}`;
    }

    if (window.location.origin.includes('dev')) {
      return `https://api-dev.fluxprompt.ai/flux/api-v2?flowId=${flowId}`;
    }
  }, [flowId]);

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '690px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      showCloseButton={false}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Label isAdvanced labelName="Details:" />
        <Divider
          sx={{
            mb: '8px',
            mt: '8px',
          }}
        />
        <Typography
          sx={{
            color: '#475467',
            fontSize: '16px',
            mb: '4px',
          }}
        >
          Flow Name:{' '}
          <span
            style={{
              color: '#98A2B3',
            }}
          >
            {activeFlow?.name}
          </span>
        </Typography>

        <Typography
          sx={{
            color: '#475467',
            fontSize: '16px',
            mb: '4px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          Flow ID:{' '}
          <span
            style={{
              color: '#98A2B3',
            }}
          >
            {activeFlow?.id}
          </span>
          <IconButtonTooltip title="Copy Flow Id" onClick={copyFlow}>
            <Copy size={14} color="#475467" />
          </IconButtonTooltip>
        </Typography>
      </Box>

      <Box
        sx={{
          mt: '26px',
        }}
      >
        <Label isAdvanced labelName="General Settings:" />
        <Divider
          sx={{
            mb: '8px',
            mt: '8px',
          }}
        />
        <FormControlLabel
          name="generateSessionId"
          onChange={onChange}
          checked={formData.generateSessionId}
          control={<Switch />}
          sx={{
            ml: 0,
            color: '#475467',
          }}
          labelPlacement="start"
          label="Allow passing in custom ID for Chat Sessions"
        />

        <Box>
          <FormLabel
            sx={{
              mr: '8px',
              color: '#475467',
            }}
            htmlFor="selectedNode"
          >
            Target input Node (for email and webhook calls)
          </FormLabel>
          <Select
            name="selectedNode"
            value={
              formData?.selectedNode || nodeOptionsApiInput[0]?.value || 'none'
            }
            onChange={onChange}
            sx={{
              mt: '2px',
            }}
          >
            <MenuItem value={'none'} disabled>
              Choose API Input Node
            </MenuItem>
            {nodeOptionsApiInput?.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option?.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box sx={{ mt: '26px' }}>
        <Label
          sx={{
            mb: '16px',
          }}
          labelName="Email Calling:"
          isAdvanced
        />
        <Divider
          sx={{
            mb: '8px',
            mt: '8px',
          }}
        />
        <Box>
          <FormControlLabel
            name="showEmailCalling"
            onChange={onChange}
            checked={formData.showEmailCalling}
            control={<Switch />}
            sx={{
              ml: 0,
              color: '#475467',
            }}
            labelPlacement="start"
            label="Turn On Email triggering"
          />
        </Box>
        {formData.showEmailCalling && (
          <>
            <Typography
              sx={{
                color: '#475467',
                fontSize: '16px',
                mb: '4px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              Flow Email:{' '}
              <span
                style={{
                  color: '#98A2B3',
                }}
              >
                {activeFlow?.id}@{process.env.REACT_APP_FLOW_EMAIL}
              </span>
              <IconButtonTooltip
                title="Copy Flow Email"
                onClick={copyFlowEmail}
              >
                <Copy size={14} />
              </IconButtonTooltip>
            </Typography>

            <Box>
              <FormControlLabel
                name="isPublic"
                onChange={(e: any) => {
                  onChangeIsPublicOrWebhook?.({
                    isPublic: !isPublic,
                  });
                }}
                checked={isPublic}
                control={<Switch />}
                sx={{
                  ml: 0,
                  color: '#475467',
                }}
                labelPlacement="start"
                label="Allow emails from any address (not just yours)"
              />
            </Box>
          </>
        )}
      </Box>

      <Box sx={{ mt: '26px' }}>
        <Label isAdvanced labelName="Webhook Calling:" />
        <Divider
          sx={{
            mb: '8px',
            mt: '8px',
          }}
        />

        <Box>
          <FormControlLabel
            name="isWebhook"
            onChange={(e: any) => {
              onChangeIsPublicOrWebhook?.({
                webhook: !isWebhook,
              });
            }}
            checked={isWebhook}
            control={<Switch />}
            sx={{
              ml: 0,
              color: '#475467',
            }}
            labelPlacement="start"
            label="Turn on webhook"
          />
        </Box>
        {isWebhook && (
          <>
            <Typography
              sx={{
                color: '#475467',
                fontSize: '16px',
                mb: '4px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Flow Webhook URL:{' '}
              <span
                style={{
                  color: '#98A2B3',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  maxWidth: '500px',
                }}
              >
                {process.env.REACT_APP_API_URL}/flux/api-v2-webhook?flowId=
                {activeFlow?.id}
              </span>
              <IconButtonTooltip
                title="Copy Webhook URL"
                onClick={copyWebhookUrl}
              >
                <Copy size={14} />
              </IconButtonTooltip>
            </Typography>
            <Typography
              sx={{
                mt: '8px',
                color: '#475467',
                fontSize: '12px',
              }}
              variant={'body1'}
            >
              Use this URL to call the flow from a Webhook. Any body of
              information you pass will be passing into the Data Input node
              selected under General Settings
            </Typography>
          </>
        )}
      </Box>

      <Box sx={{ mt: '26px' }}>
        <Label isAdvanced labelName="API Calling:" />
        <Divider
          sx={{
            mb: '8px',
            mt: '8px',
          }}
        />
        <Box>
          <FormControlLabel
            name="showApiCalling"
            onChange={onChange}
            checked={formData.showApiCalling}
            control={<Switch />}
            sx={{
              ml: 0,
              color: '#475467',
            }}
            labelPlacement="start"
            label="Turn on API Calling"
          />
        </Box>
        {formData.showApiCalling && (
          <>
            <>
              <Typography
                sx={{
                  color: '#475467',
                  fontSize: '16px',
                  mb: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <span
                  style={{
                    color: '#98A2B3',
                  }}
                >
                  {generateLinkForFlowId}
                </span>
                <IconButtonTooltip
                  title="Copy API URL"
                  onClick={() => {
                    navigator.clipboard.writeText(generateLinkForFlowId!);
                  }}
                >
                  <Copy size={14} />
                </IconButtonTooltip>
              </Typography>

              <Typography
                sx={{
                  mt: '8px',
                  color: '#475467',
                  fontSize: '12px',
                }}
                variant={'body1'}
              >
                You can use the following link to access the API for the flow.
              </Typography>

              <Box
                sx={{
                  mb: '24px',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(generateLinkForFlowId!);
                    setNotification({
                      message: 'Link Copied to clipboard',
                      type: 'success',
                    });
                  }}
                >
                  Copy Link
                </Button>
              </Box>
            </>
            <>
              <Typography
                sx={{
                  color: '#475467',
                  fontSize: '16px',
                  mb: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                API Auth:
                <span
                  style={{
                    color: '#98A2B3',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    maxWidth: '500px',
                  }}
                >
                  api-key: {apiKey}
                </span>
                <IconButtonTooltip
                  title="Copy Api Key"
                  onClick={() => {
                    navigator.clipboard.writeText(apiKey!);
                  }}
                >
                  <Copy size={14} />
                </IconButtonTooltip>
              </Typography>
              <Typography
                sx={{
                  mt: '8px',
                  color: '#475467',
                  fontSize: '12px',
                }}
                variant={'body1'}
              >
                Use the following API Key to authenticate the API request.
              </Typography>

              <Box
                sx={{
                  mb: '24px',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={() => {
                    if (apiKey === null)
                      return setNotification({
                        message: 'No API Key generated',
                        type: 'warning',
                      });
                    navigator.clipboard.writeText(apiKey!);
                    setNotification({
                      message: 'Api Key Copied to clipboard',
                      type: 'success',
                    });
                  }}
                >
                  Copy API Key
                </Button>
              </Box>
            </>
            <Box>
              <Typography
                sx={{
                  color: '#475467',
                  fontSize: '16px',
                  mb: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                JSON Body Call Example
              </Typography>
              <TextField
                multiline
                rows={5}
                fullWidth
                value={checkText}
                InputProps={{
                  readOnly: true,
                  style: {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                }}
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
              />
            </Box>
            <Typography
              sx={{
                mt: '8px',
                color: '#475467',
                fontSize: '12px',
              }}
              variant={'body1'}
            >
              Example JSON body to use when calling the flow.
            </Typography>

            <Box
              sx={{
                mb: '24px',
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(checkText);
                  setNotification({
                    message: 'Api Config Body Copied to clipboard',
                    type: 'success',
                  });
                }}
              >
                Copy JSON Body Example
              </Button>
            </Box>
          </>
        )}
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
