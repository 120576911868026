import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const OAuthCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    window.opener.postMessage({ type: 'integration_completed', code }, '*');
    window.close();
  }, [code]);

  return ("");
}

export default OAuthCallback;
