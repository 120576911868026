import React from 'react';
import { Handle, Position, useEdges, useReactFlow } from 'reactflow';
import { coreEdgeStyle } from './edges/coreEdgeStyleDef';
import { Box } from '@mui/material';

interface Props {
  isConnectable: boolean;
  nodeId: string;
  targetName: string;
  style?: React.CSSProperties;
  position: Position;
  setActiveTargetName?: (targetName: string) => void;
  className?: string;
}

const TargetHandle = ({
  isConnectable,
  nodeId,
  targetName,
  style,
  position,
  setActiveTargetName,
  className,
}: Props) => {
  const edges = useEdges();
  const { setEdges } = useReactFlow();
  const handleEdgeColors = () => {
    const colors = [
      'green',
      'blue',
      'red',
      'purple',
      'orange',
      'yellow',
      '#90EE90',
      '#ADD8E6',
      '#FFC0CB',
      '#E6E6FA',
      '#FFD700',
      '#FFFFE0',
    ];
    let colorIndex = 0;
    const coloredEdges = edges.map((edge, i) => {
      if (edge.target === nodeId && edge.targetHandle === targetName) {
        const updatedEdge = {
          ...edge,
          style: {
            ...edge.style,
            stroke: colors[colorIndex],
            strokeWidth: 10,
          },
          type: 'coloredEdge',
          markerEnd: coreEdgeStyle.markerEndCustom,

          data: {
            coloredEdge: true,
            coloredFromSource: false,
            coloredFromTarget: true,
          },
        };

        colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
        return updatedEdge;
      }
      return {
        ...edge,
        style: {
          ...edge.style,
          stroke: '#6e75ff',
          strokeWidth: 1,
        },
        type: edge.type === 'custom' ? 'custom' : '',
        markerEnd: coreEdgeStyle.markerEnd,
        data: {
          coloredEdge: false,
        },
      };
    });

    setEdges(coloredEdges);
  };
  return (
    <Box>
      <Handle
        type='target'
        position={position}
        id={targetName}
        isConnectable={isConnectable}
        style={style}
        className={className}
        onClick={e => {
          e.stopPropagation();
          setActiveTargetName?.(targetName);
          handleEdgeColors();
        }}
      />
    </Box>
  );
};

export default TargetHandle;
