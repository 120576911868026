import KitModal from '../../UI/KitModal';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FormDataTranscriber } from '../../nodes/AiAudioTranscriber';
import { useAuthStore } from '../../../store/storeAuth';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: FormDataTranscriber;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  const user = useAuthStore(state => state.user);

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '320px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant="h6">Settings</Typography>
        <Box
          sx={{
            mt: '24px',
          }}
        >
          <Select
            className="nodrag"
            value={formData.aiModel}
            size="small"
            onChange={onChange}
            name={'aiModel'}
            sx={{
              '& .MuiList-root': {
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
              bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              border: `2px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`,
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .Mui-selected': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                  },
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
              },
            }}
          >
            <MenuItem
              value={'openAi'}
              sx={{
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
            >
              {'OpenAI'}
            </MenuItem>
            <MenuItem
              value={'gemini'}
              sx={{
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
            >
              {'Gemini'}
            </MenuItem>
            <MenuItem
              value={'groq'}
              sx={{
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
            >
              {'Groq'}
            </MenuItem>
          </Select>

          {formData.type === 'ytTranscript' && (
            <FormControl fullWidth>
              <InputLabel htmlFor="ytType">YouTube Type</InputLabel>
              <Select
                className="nodrag"
                value={formData?.ytType ?? 'text'}
                size="medium"
                fullWidth
                label={'YouTube Type'}
                onChange={onChange}
                name={'ytType'}
              >
                <MenuItem value={'text'}>{'Transcript'}</MenuItem>
                <MenuItem value={'textMtd'}>
                  {'Transcript + Meta Data'}
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
