import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import ChangeLabel from '../ChangeLabel/ChangeLabel';
import DeleteNode from '../DeleteNode/DeleteNode';
import { Edge, useReactFlow } from 'reactflow';
import {
  ArrowDown,
  ArrowLineLeft,
  ArrowLineRight,
  ArrowsInLineHorizontal,
} from '@phosphor-icons/react';
import './Node.scss';
import InputHandle from '../Handle/InputHandle';
import OutputHandle from '../Handle/OutputHandle';
import { findConnectedNodes } from '../../../util/executeNode/findConnectedNodes';
import { nodeColorBasedOnType } from './nodeConstants';
import { useAuthStore } from '../../../store/storeAuth';

import TagManager from 'react-gtm-module';
import useFlowsStore from '../../../store/storeFlows';
import { nodeDataAutoSaveDynamic } from '../../../util/autosave/nodedata_autosave';
const options = ['Run from start', 'Run from here'];

type Props = {
  type?: string;
  children?: React.ReactNode;
  data?: any;
  id: string;
  onSubmit?: () => void;
  btnText?: string;
  isLoading: boolean;
  edges?: Edge[];
  onChangePlacement?: (name: string, value: string) => void;
  isGlobal?: boolean;
  customHandles?: React.ReactNode;
  selected?: boolean;
  handleRun?: (run: string) => void;
  size?: { width: number; height: number };
  showTokensUsed?: boolean;
};

const Node = ({
  type,
  children,
  data,
  id,
  onSubmit,
  btnText,
  isLoading,
  edges,
  onChangePlacement,
  isGlobal = false,
  customHandles,
  selected = false,
  size,
  showTokensUsed = true,
}: Props) => {
  const reactFlow = useReactFlow();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(
    data?.run ? options?.findIndex(option => option === data?.run) : 0,
  );
  const { setSchema, flowId, setSaving } = useFlowsStore(state => state);
  const { setNodes: setNodeState } = useReactFlow();
  const [placement, setPlacement] = useState(data?.placement || 'Both');
  const { user } = useAuthStore(state => state);
  useMemo(() => {
    setPlacement(data?.placement || 'Both');
  }, [data?.placement]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    const globalId = edges?.find(edge =>
      edge?.source?.includes('flow-global-starter'),
    )?.source;
    setSelectedIndex(index);

    if (index === 0) {
      nodeDataAutoSaveDynamic({
        setNodeState,
        id,
        flowTriggerData: {
          flowGlobalId: globalId,
          flowId: flowId,
        },
        setSaving,
        paused: false,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        setSchema,
        newEdges: edges,
      });
    }

    if (index === 1) {
      nodeDataAutoSaveDynamic({
        setNodeState,
        id,
        flowTriggerData: {
          flowGlobalId: globalId,
          flowId: flowId,
          runFrom: id,
        },
        setSaving,
        paused: false,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        setSchema,
        newEdges: edges,
      });
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // Function to handle data layer push
  const pushDataLayer = () => {
    // Simpler data layer push using TagManager from 'react-gtm-module'
    TagManager.dataLayer({
      dataLayer: {
        event: 'node_run',
        nodeType: type || 'unknown', // The type of the node run
        flowId: data?.flowId || 'unknown', // The flow ID where the node belongs
        triggerType: data?.triggerType || 'Run button', // How the node was triggered
      } as any,
    });
  };

  const handleNodeRun = () => {
    pushDataLayer(); // Trigger the data layer push when the node runs

    if (onSubmit) {
      onSubmit(); // Execute the provided onSubmit logic if any
    }
  };

  const { index, activeSource } = useMemo(() => {
    if (!edges) return { allIds: [], index: 0, activeSource: false };
    let nds = reactFlow?.getNodes();

    const globalId = edges?.find(edge =>
      edge?.source?.includes('flow-global-starter'),
    )?.source;

    const { allIds } = findConnectedNodes(nds, edges!, globalId!);

    const findIndex = allIds.findIndex((nodeId: string) => nodeId === id);

    const findSource = edges?.find(
      edge => edge.source === id && edge.sourceHandle === 'outputFlow',
    )?.source;

    return {
      allIds,
      index: findIndex + 1 || 0,
      activeSource: findSource ? true : false,
    };
  }, [edges, id]);

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const nodeIcon = nodeType.nodeIcon;

  const placements = [
    {
      name: 'Output',
      icon: (
        <ArrowLineRight
          color={user?.theme === 'dark' ? '#667085' : 'black'}
          size={24}
        />
      ),
    },
    {
      name: 'Prompt',
      icon: (
        <ArrowLineLeft
          color={user?.theme === 'dark' ? '#667085' : 'black'}
          size={24}
        />
      ),
    },
    {
      name: 'Both',

      icon: (
        <ArrowsInLineHorizontal
          color={user?.theme === 'dark' ? '#667085' : 'black'}
          size={24}
        />
      ),
    },
  ];

  const getNextPlacement = () => {
    const currentIndex = placements.findIndex(p => p.name === placement);
    const nextIndex = (currentIndex + 1) % placements.length;
    return placements[nextIndex];
  };

  const selectedBy =
    selected && data?.selectedBy && data?.selectedBy === user?.id
      ? true
      : !data?.selectedBy
      ? true
      : !selected
      ? true
      : false;

  return (
    <Box
      className={isLoading ? 'block' : 'hidden'}
      sx={{
        po: selectedBy ? '' : 'relative',
        pointerEvents: selectedBy ? '' : 'none',
        ...size,
      }}
    >
      {!selectedBy && (
        <Box className="nodrag">
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '110%',
              height: '110%',
              zIndex: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0,0,0,0.5)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color: 'white',
                fontSize: '14px',
              }}
            >
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  background: 'red',
                }}
              />
              <Box>Selected by another user</Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        className={isLoading ? 'block' : ''}
        sx={{
          borderRadius: `8px 8px 0px 0px`,
          // background: `var(--Api-call, linear-gradient(90deg, #1436FF 0%, #396AFC 100%))`,
          border: `1px solid ${findColor?.color}`,
          borderTop: 'none',
          bgcolor: '#fff',
          height: '100%',
        }}
      >
        <Box
          sx={{
            background: findColor?.background,
            width: '100%',
            height: '6px',
            borderRadius: `var(--1, 8px) var(--1, 8px) 0px 0px`,
          }}
        />{' '}
        <Box
          padding={'6px'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
            bgcolor: user?.theme === 'dark' ? '#101828' : '#f2f4f7',
          }}
        >
          {' '}
          <Typography
            color={'#98A2B3'}
            sx={{
              visibility: showTokensUsed ? 'visible' : 'hidden',
              fontSize: '12px',
            }}
          >
            Tokens used :{' '}
            {Math.round((data?.nodeUsedTokens ?? 0) * 1000) / 1000}
          </Typography>
          <DeleteNode nodeId={id} />
        </Box>
        <Box
          p={'8.5px 30px'}
          bgcolor={user?.theme === 'dark' ? '#1D2939' : '#fff'}
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
        >
          <ChangeLabel icon={nodeIcon} data={data} />

          {btnText && (
            <>
              <Box
                bgcolor={user?.theme === 'dark' ? '#1D2939' : '#fff'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <ButtonGroup
                  variant="contained"
                  ref={anchorRef}
                  size="small"
                  aria-label="Button group with a nested menu"
                >
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleNodeRun}
                    sx={{
                      background: findColor?.background,
                      borderRight: '1px solid #fff',
                      borderColor: findColor?.color + ' !important',
                    }}
                    disabled={isLoading}
                  >
                    {btnText}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleToggle}
                    sx={{
                      background: findColor?.background,
                      p: '0px !important',
                      minWidth: '25px',
                      maxWidth: '25px',
                      '&: hover': {
                        background: findColor?.background,
                      },
                    }}
                  >
                    <ArrowDown />
                  </Button>
                </ButtonGroup>

                <Popper
                  sx={{ zIndex: 1 }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper
                        sx={{
                          width: '159px',
                          pt: '0',
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={event =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Tooltip title="Switch Layout">
                  <Box
                    bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                    p={'4px'}
                    borderRadius={'4px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        background:
                          user?.theme === 'dark' ? '#0d131f' : '#F9FAFB',
                      },
                    }}
                    onClick={() => {
                      const nextPlacement = getNextPlacement();
                      setPlacement(nextPlacement.name);
                      onChangePlacement?.('placement', nextPlacement.name);
                    }}
                  >
                    {placements?.find(p => p.name === placement)?.icon}
                  </Box>
                </Tooltip>
              </Box>
            </>
          )}
          {customHandles && (
            <InputHandle colorActive={findColor} index={index} />
          )}
          {customHandles && <Box position={'relative'}>{customHandles}</Box>}
          {(btnText || isGlobal) && (
            <>
              <OutputHandle colorActive={findColor} isActive={activeSource} />
              <InputHandle colorActive={findColor} index={index} />
            </>
          )}
        </Box>
        <Box bgcolor={user?.theme === 'dark' ? '#1D2939' : '#fff'}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Node;
