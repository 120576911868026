export const aiGeneratorInputs = [
  {
    name: 'prompt',
    labelName: 'Prompt',
    placeholder: 'What can I help you with today?',
    handleId: 'promptIn',
    isCollapsed: true,
  },
  {
    name: 'content',
    labelName: 'Content and Context',
    placeholder: 'What does the AI need to know?',
    handleId: 'contentIn',
    isCollapsed: false,
  },
  {
    name: 'instruction',
    labelName: 'Instructions',
    placeholder: 'Input additional parameters',
    handleId: 'instructionIn',
    isCollapsed: false,
  },
  {
    name: 'persona',
    labelName: 'Persona',
    placeholder: 'What tone do you want the output to have?',
    handleId: 'personaIn',
    isCollapsed: false,
  },
];
