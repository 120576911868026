import React, { memo, useEffect, useState } from 'react';
import FormNode from '../Components/FormNode/FormNode';
import { Box } from '@mui/material';
import { NodeProps, useReactFlow } from 'reactflow';
import { useDebounceEffect } from '../../../util/useDebounceEffect';
import PopoverEdit from './Components/PopoverEdit';
import FormBuildInput from '../FormBuildInput/FormBuildInput';

const TitleNode = ({ id, type, data, selected }: NodeProps) => {
  const [formData, setFormData] = useState({
    title: '',
    borderRadius: '4px',

    titleStyle: {
      fontSize: '20px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
  });
  const reactFlow = useReactFlow();

  const [isEditing, setIsEditing] = useState(false);
  const [editingType, setEditingType] = useState('');

  const [changes, setChanges] = useState<any>({
    directly: false,
    value: '',
    name: '',
  });

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleClick = (type: string) => {
    setOpenEditModal(true);
    setEditingType(type);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };
  useEffect(() => {
    if (data && !changes?.directly) {
      setFormData({
        title: data.title || '',
        borderRadius: data.borderRadius || '4px',
        titleStyle: {
          ...formData?.titleStyle,
          ...data.titleStyle,
        },
      });
    }
  }, [data, changes]);

  const onChanges = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setChanges({ directly: true, value, name });
  };

  useDebounceEffect(
    () => {
      if (changes.directly) {
        const nodes = reactFlow.getNodes();

        const mappedNodes = nodes.map(node => {
          if (node.id === id) {
            if (changes?.name === 'widthData') {
              return {
                ...node,
                width: changes.value?.width,
                style: {
                  width: changes.value?.width,
                },
                data: {
                  ...node.data,
                  [changes.name]: changes.value,
                },
              };
            }

            return {
              ...node,
              data: {
                ...node.data,
                [changes.name]: changes.value,
              },
            };
          }
          return node;
        });

        reactFlow.setNodes(mappedNodes);

        setChanges({ directly: false, value: '', name: '' });
      }
    },
    [changes],
    500,
  );

  return (
    <FormNode id={id} type={type} borderRadius={formData.borderRadius}>
      <Box>
        <FormBuildInput
          data={data}
          formData={{
            name: 'title',
            value: formData.title,
            placeholder: 'Add title here...',
            style: formData.titleStyle,
            titleEmpty: 'Title',
          }}
          editingType={editingType}
          editType={'titleStyle'}
          handleClick={handleClick}
          isEditing={isEditing}
          onChanges={onChanges}
          setEditingType={setEditingType}
          setIsEditing={setIsEditing}
        />
        {openEditModal && (
          <PopoverEdit
            open={openEditModal}
            handleClose={handleClose}
            onChange={onChanges}
            selectedStyle={formData.titleStyle}
            editingType={editingType}
            modalStyle={{
              bgColor: data?.bgColor ? data?.bgColor : '#ffffff',
              widthData: data?.widthData,
              borderRadius: formData.borderRadius || '8px',
            }}
          />
        )}
      </Box>
    </FormNode>
  );
};

export default memo(TitleNode);
