import { Box, Button, IconButton, Typography } from '@mui/material';
import {
  Buildings,
  CaretLeft,
  CaretRight,
  FolderUser,
  Gear,
  MagnifyingGlass,
  UsersThree,
} from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { ReactComponent as ExploreSettings } from '../../assets/icons/ExploreSettings.svg';
import { ReactComponent as FluxPromptTitle } from '../../assets/icons/FluxPromptTitle.svg';
import { ReactComponent as SettingsTutorial } from '../../assets/icons/SettingsTutorial.svg';
import { ReactComponent as StarterPlan } from '../../assets/icons/StarterPlan.svg';
import { ReactComponent as CreateSpace } from '../../assets/icons/CreateSpace.svg';
import { ReactComponent as AddUsers } from '../../assets/icons/AddUsers.svg';
import { ReactComponent as SetUpOrganization } from '../../assets/icons/SetUpOrganization.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as Templates } from '../../assets/icons/TemplatesOnboarding.svg';
import { ReactComponent as Nodes } from '../../assets/icons/NodesOnboarding.svg';
import { ReactComponent as AutomateWorkflows } from '../../assets/icons/AutomateWorkflows.svg';

import Congrats from './Congrats';
import { useAuthStore } from '../../store/storeAuth';
import { Navigate } from 'react-router-dom';
import { AuthService } from '../../service/AuthService';

const Onboarding = () => {
  const authUser = useAuthStore(state => state.user);

  const [userRole, setUserRole] = useState<'employee' | 'admin'>('employee');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCongrats, setShowCongrats] = useState(false);
  const svgs =
    userRole !== 'admin'
      ? [Dashboard, Templates, Nodes, AutomateWorkflows]
      : [
          StarterPlan,
          SettingsTutorial,
          CreateSpace,
          AddUsers,
          SetUpOrganization,
          ExploreSettings,
        ];

  useEffect(() => {
    const getUserRole = async () => {
      try {
        const { data } = await AuthService.getMe();

        setUserRole(data.invitedAs);
      } catch (error) {}
    };

    getUserRole();
  }, []);

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? svgs.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === svgs.length - 1 ? 0 : prevIndex + 1,
    );
  };

  if (authUser?.companyUserHasFinishedOnboarding) return <Navigate to={'/'} />;

  return showCongrats ? (
    <Congrats />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        gap: '35px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '16px',
          width: '1126px',
          height: '113px',
        }}
      >
        {currentIndex === 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              {userRole !== 'admin' ? (
                <Typography
                  fontSize={'28px'}
                  fontWeight={500}
                  lineHeight={'116%'}
                >
                  Dashboard
                </Typography>
              ) : (
                <>
                  {' '}
                  <Typography
                    fontSize={'29px'}
                    fontWeight={500}
                    lineHeight={'150%'}
                    color="var(--Main-Blue, #3650DE)"
                  >
                    Welcome to
                  </Typography>
                  <FluxPromptTitle />
                </>
              )}
            </Box>
            <Typography
              fontWeight={400}
              fontSize={'20px'}
              color="var(--Text-Secondary, #475467)"
              textAlign={'center'}
              lineHeight={'175%'}
              height={'53.075px'}
            >
              {userRole !== 'admin'
                ? `You can view recent files or explore our extensive collection of tutorials and blogs. Navigate through these resources to get familiar with our platform.`
                : `Prepare to experience a whole new way of working, where AI becomes
              your trusted partner, empowering you to achieve more than ever
              before.`}
            </Typography>
          </>
        )}

        {currentIndex === 1 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'var(--Primary-50, #E7F0FF)',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Gear size={19} color="#3650DE" />
              </Box>
              <Typography
                fontSize={'28px'}
                fontWeight={500}
                lineHeight={'116%'}
              >
                {userRole !== 'admin' ? 'Templates' : `Access Settings`}
              </Typography>
            </Box>
            <Typography
              fontWeight={400}
              fontSize={20}
              color="var(--Text-Secondary, #475467)"
              textAlign={'center'}
              lineHeight={'175%'}
            >
              {userRole !== 'admin'
                ? `Explore our 100+ flow templates designed for various roles and use cases, including content creation, research and development, data analytics, finance, marketing, and many more.`
                : `On the side menu you can access the settings dashboard where you
              have full control over your company information, collaborative
              spaces, and employees.`}
            </Typography>
          </>
        )}

        {currentIndex === 2 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'var(--Primary-50, #E7F0FF)',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FolderUser size={19} color="#3650DE" />
              </Box>
              <Typography
                fontSize={'28px'}
                fontWeight={500}
                lineHeight={'116%'}
              >
                {userRole !== 'admin' ? 'Nodes' : `Create Spaces`}
              </Typography>
            </Box>
            <Typography
              fontWeight={400}
              fontSize={20}
              color="var(--Text-Secondary, #475467)"
              textAlign={'center'}
              lineHeight={'175%'}
            >
              {userRole !== 'admin'
                ? `Easily get work done with our versatile drag-and-drop tools. Generate text, images, and audio files, perform internet searches, integrate with popular services, and much more—all powered by AI.`
                : `Create Spaces to collaborate on flows as a team! Select “Spaces”
              on the Settings Dialog to create a Space and add users to it. You
              can also set token usage limits, track usage activity and more.`}
            </Typography>
          </>
        )}

        {currentIndex === 3 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'var(--Primary-50, #E7F0FF)',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <UsersThree size={19} color="#3650DE" />
              </Box>
              <Typography
                fontSize={'28px'}
                fontWeight={500}
                lineHeight={'116%'}
              >
                {userRole !== 'admin' ? 'Automate Workflows' : 'Add users'}
              </Typography>
            </Box>
            <Typography
              fontWeight={400}
              fontSize={20}
              color="var(--Text-Secondary, #475467)"
              textAlign={'center'}
              lineHeight={'175%'}
            >
              {userRole !== 'admin'
                ? `Connect tools to build robust AI-driven flows. Automate lead generation, personalize email campaigns, analyze customer behavior, schedule social media posts, track performance metrics, and more.`
                : `Invite users to join your organization by selecting “Organization
              Users” and the “Add User” button. You can also set limits on token
              usage, track their activity, view their flows, disable users, and
              more.`}
            </Typography>
          </>
        )}

        {currentIndex === 4 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'var(--Primary-50, #E7F0FF)',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Buildings size={19} color="#3650DE" />
              </Box>
              <Typography
                fontSize={'28px'}
                fontWeight={500}
                lineHeight={'116%'}
              >
                Set Up Organization
              </Typography>
            </Box>
            <Typography
              fontWeight={400}
              fontSize={20}
              color="var(--Text-Secondary, #475467)"
              textAlign={'center'}
              lineHeight="175%"
              height={'53.075px'}
            >
              Customize FluxPrompt, by adding your organization’s name and
              upload its logo.
            </Typography>
          </>
        )}

        {currentIndex === 5 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'var(--Primary-50, #E7F0FF)',
                  padding: '8px',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MagnifyingGlass size={19} color="#3650DE" />
              </Box>
              <Typography
                fontSize={'28px'}
                fontWeight={500}
                lineHeight={'116%'}
              >
                Explore Settings
              </Typography>
            </Box>
            <Typography
              fontWeight={400}
              fontSize={20}
              color="var(--Text-Secondary, #475467)"
              textAlign={'center'}
              lineHeight="175%"
            >
              Don’t forget to explore more admin features, such as enabling only
              the right Ai Technologies for your organization and checking out
              our third party app integrations.
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          //   width: '100%',
          //   maxWidth: 1300,
          height: '50%',
          overflow: 'hidden',
          //   position: 'relative',
          gap: '32px',
        }}
      >
        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
          <CaretLeft
            color={currentIndex === 0 ? '#98A2B3' : '#3650DE'}
            size={30}
          />
        </IconButton>
        {svgs.map((Svg, index) => (
          <Box
            key={index}
            sx={{
              display: index === currentIndex ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '20px',
              width: '70vw',
              height: 'auto',
            }}
          >
            <Svg />
          </Box>
        ))}

        <IconButton
          onClick={handleNext}
          disabled={currentIndex === svgs.length - 1}
        >
          <CaretRight
            color={currentIndex === svgs.length - 1 ? '#98A2B3' : '#3650DE'}
            size={30}
          />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {svgs.map((_, index) => (
          <Box
            key={index}
            sx={{
              height: 10,
              width: 10,
              borderRadius: '50%',
              backgroundColor:
                index === currentIndex ? 'primary.main' : 'grey.400',
              mx: 1,
              cursor: 'pointer',
            }}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Box>
      <Box
        sx={{
          width: '90%',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'end',
        }}
      >
        <Button onClick={() => setShowCongrats(true)}>Continue</Button>
      </Box>
    </Box>
  );
};

export default Onboarding;
