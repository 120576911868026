import ReactFlow, { MarkerType } from 'reactflow';
/* import styled from "styled-components";

const StyledCoreEdge = styled.div`
  stroke: ${(props) => props.theme.cntFlow};
`;

const StyledMarkerEnd = styled.div`
  color: ${(props) => props.theme.cntArrow};
`; */

export const coreEdgeStyle = {
  animated: true,
  strokeWidth: 5,
  style: { stroke: '#6e75ff' },
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 25,
    height: 25,
    color: 'red',
    strokeWidth: 1,
  },
  markerEndCustom: {
    type: MarkerType.ArrowClosed,
    width: 35,
    height: 35,
    color: 'red',
    strokeWidth: 1,
  },
};
