import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Position, useEdges, useReactFlow } from 'reactflow';
import { useAuthStore } from '../../store/storeAuth';
import { NodeProps } from '../../util/Types/NodeProps';
import { UserService } from '../../service/UserService';
import { AuthService } from '../../service/AuthService';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { Pen, Plus, TextAlignCenter, Trash } from '@phosphor-icons/react';
import { nodeStateChange } from '../../util/nodeStateChange';
import useFlowsStore from '../../store/storeFlows';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import TagInput from '../Test/TagInput';
import InputHandle from '../UI/InputHandle/InputHandle';
import Label from '../UI/Label/Label';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { isActiveEdge } from '../../util/findActiveHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { CompanySpacesService } from '../../service/CompanySpacesService';

const ObjectCaller = ({ id, data, type }: NodeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRefObject, setSelectedRefObject] = useState('');
  const { setSchema, spaceId, setSaving, flowId } = useFlowsStore(
    state => state,
  );

  const { setNodes: setNodeState } = useReactFlow();
  const [hasToExecute, setHasToExecute] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { user: authUser, setAuthUser } = useAuthStore(state => state);
  const [objects, setObjects] = useState(authUser?.objects);
  const refOutputData = data?.refOutput;
  const [advancedCollapsed, setAdvancedCollapsed] = useState(true);
  const edges = useEdges();
  useMemo(() => {
    if (data.selectedId) {
      setSelectedRefObject(data.selectedId);
    }
  }, [data.text, data?.refOutput, data?.selectedId]);

  const getSpace = async (spaceId: string, nameSpace: any) => {
    try {
      const res = await CompanySpacesService.getSpaceById(spaceId);
      setObjects(res.data?.objects ?? []);

      setAuthUser({ ...authUser!, spaceObjects: res?.data?.objects || [] });
      setSelectedRefObject(nameSpace);
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(() => {
    if (spaceId) {
      getSpace(spaceId, data?.selectedId);
    } else {
      if (authUser?.objects?.length! > 0) {
        setObjects(authUser?.objects);
      }
    }
  }, [authUser?.objects, spaceId, data?.selectedId]);
  const onChangeObject = (objId: string) => {
    setSelectedRefObject(objId);

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        selectedId: objId,
      },
      setSchema,
    });
  };

  const onChangeChecked = (value: any, name: any) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        [name]: value,
      },
      setSchema,
    });
  };

  const debounceTimeoutRef = useRef<any>(null);

  const onSubmit = async () => {
    try {
      const myObjects = objects?.map(object => {
        if (object.id === selectedRefObject) {
          const findItem = object?.text?.map(item => {
            const checked = data?.[item.id + 'checked'];
            const hasText = data?.[item.id + '-in'] ? true : false;
            const newTxt = data[item.id + '-in'];
            const text = checked
              ? hasText
                ? newTxt
                : item?.text
              : newTxt
              ? newTxt
                ? item.text + '\n\n' + newTxt
                : item?.text
              : item?.text;

            return {
              ...item,
              text: text,
            };
          });

          return {
            ...object,
            text: findItem,
          };
        }
        return object;
      });

      if (spaceId) {
        await CompanySpacesService.updateCompanySpace(
          authUser?.selectedCompanyId!,
          {
            spaceId: spaceId,
            objects: myObjects,
          },
        );

        getSpace(spaceId, selectedRefObject);
      } else {
        await UserService.updateUser({
          objects: myObjects,
        });
        setAuthUser({
          ...authUser!,
          objects: myObjects!,
        });
        setObjects(myObjects);
      }

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        objectCallerData: myObjects,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        setSchema,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 1500);
    } catch (error) {
      setHasToExecute(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddNewObject = async () => {
    try {
      const newObj = objects?.map(object => {
        if (object.id === selectedRefObject) {
          return {
            ...object,
            text: [
              ...object?.text,
              {
                id: Math.random().toString(),
                text: '',
              },
            ],
          };
        }
        return object;
      });

      if (spaceId) {
        await CompanySpacesService.updateCompanySpace(
          authUser?.selectedCompanyId!,
          {
            spaceId: spaceId,
            objects: newObj,
          },
        );

        getSpace(spaceId, selectedRefObject);
      } else {
        await UserService.updateUser({
          objects: newObj,
        });
        const userRes = await AuthService.getMe();

        setObjects(userRes?.data?.objects);
        setAuthUser(userRes?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteObject = async (objId: string) => {
    try {
      const newObj = objects?.map(object => {
        if (object.id === selectedRefObject) {
          return {
            ...object,
            text: object?.text?.filter(txt => txt.id !== objId),
          };
        }
        return object;
      });
      if (spaceId) {
        await CompanySpacesService.updateCompanySpace(
          authUser?.selectedCompanyId!,
          {
            spaceId: spaceId,
            objects: newObj,
          },
        );

        getSpace(spaceId, selectedRefObject);
      } else {
        await UserService.updateUser({
          objects: newObj,
        });

        const userRes = await AuthService.getMe();
        setAuthUser(userRes?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, data?.canceled]);

  const onChangeTextElement = (e: any, id: string) => {
    const mutatedObj = objects?.map(object => {
      if (object.id === selectedRefObject) {
        return {
          ...object,
          text: object?.text?.map(item => {
            if (item.id === id) {
              return {
                ...item,
                text: e.target.value,
              };
            }
            return item;
          }),
        };
      }
      return object;
    });

    setObjects(mutatedObj);

    setIsEditing(true);
  };

  const onSave = async () => {
    try {
      const mutatedObj = objects?.map(object => {
        if (object.id === selectedRefObject) {
          return {
            ...object,
            text: object?.text?.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  text: refOutputData,
                };
              }
              return item;
            }),
          };
        }
        return object;
      });

      if (spaceId) {
        await CompanySpacesService.updateCompanySpace(
          authUser?.selectedCompanyId!,
          {
            spaceId: spaceId,
            objects: mutatedObj,
          },
        );

        getSpace(spaceId, selectedRefObject);
      } else {
        await UserService.updateUser({
          objects: mutatedObj,
        });

        setAuthUser({ ...authUser!, objects: mutatedObj! });
        setObjects(mutatedObj);
      }
      setIsEditing(false);

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        objectCallerData: mutatedObj,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        setSchema,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Node
      edges={edges}
      isLoading={isLoading}
      id={id}
      type={type}
      data={data}
      handleRun={handleRun}
      isGlobal={true}
      showTokensUsed={false}
    >
      <Box sx={{ p: '16px' }}>
        <Label isAdvanced={true} labelName="Select an Object" />

        <Select
          name="flowId"
          value={selectedRefObject || 'none'}
          onChange={e => onChangeObject(e.target.value)}
          className="nodrag"
          size="small"
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        >
          <MenuItem
            value="none"
            disabled
            sx={{
              color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
            }}
          >
            None Selected
          </MenuItem>

          {objects?.map(object => (
            <MenuItem
              key={object.id}
              value={object.id}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
              }}
            >
              {object.name}
            </MenuItem>
          ))}
        </Select>
        <Box mt={'24px'} />

        {!selectedRefObject ? (
          <Box
            width={'386px'}
            height={'187px'}
            sx={{
              padding: '10px',
              borderRadius: '8px',
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <TextAlignCenter size={32} color="#98A2B3" />
              <Typography fontSize={'14px'} color="#98A2B3">
                Easily inject your objects into workflows.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            <Label
              labelName="Object Elements"
              onCollapse={() => setAdvancedCollapsed(!advancedCollapsed)}
              sx={{
                mb: advancedCollapsed ? '8px' : '0',
              }}
            />
            {objects
              ?.find(obj => obj?.id === selectedRefObject)
              ?.text?.map((txt, index) => (
                <Box key={txt?.id}>
                  <Box position={advancedCollapsed ? 'relative' : 'unset'}>
                    <TagInput
                      className="nodrag"
                      key={txt?.id}
                      value={txt.text}
                      maxLength={500}
                      labelName={`Element ${index + 1}`}
                      isAdvanced={true}
                      nodeId={id}
                      findColor={findColor}
                      advancedCollapsed={advancedCollapsed}
                      nodeLabel={data?.label}
                      handleId={`${txt?.id}-in`}
                      handleSettings={{
                        top: '50%',
                      }}
                      isActive={isActiveEdge(
                        edges,
                        id,
                        `${txt.id}-in`,
                        'target',
                      )}
                      isCollapsed={false}
                      style={{
                        resize: 'both',
                        overflow: 'auto',
                        width: '100%',
                      }}
                      customSettingsLeft={
                        <>
                          <IconButtonTooltip
                            onClick={() => onDeleteObject(txt.id)}
                            title="Delete Element"
                          >
                            <Trash size={14} />
                          </IconButtonTooltip>
                        </>
                      }
                      customSettingsRight={
                        <>
                          <Switch
                            name="checkingAddToRefOrOverwrite"
                            checked={data?.[txt.id + 'checked']}
                            size="small"
                            onChange={e => {
                              onChangeChecked(
                                e.target.checked,
                                txt.id + 'checked',
                              );
                            }}
                          />
                        </>
                      }
                      onChange={(e: any) => onChangeTextElement(e, txt?.id)}
                    />

                    <InputHandle
                      handleId={`${txt?.id}-out`}
                      type="source"
                      right={'-26px'}
                      top={'50%'}
                      activeColor={findColor.color}
                      position={Position.Right}
                      isActive={isActiveEdge(
                        edges,
                        id,
                        `${txt.id}-out`,
                        'source',
                      )}
                    />
                  </Box>
                </Box>
              ))}
            {selectedRefObject && isEditing && (
              <Box display={'flex'} justifyContent={'center'}>
                <Button
                  sx={{
                    mt: '20px',
                  }}
                  onClick={onSave}
                >
                  Save Changes <Pen size={20} />
                </Button>
              </Box>
            )}

            {selectedRefObject && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Button onClick={onAddNewObject}>
                  Add new element <Plus />
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Node>
  );
};

export default memo(ObjectCaller);
