export const openAi = {
  aiModel: 'gpt-4o',
  aiTokens: '4096',
  aiFp: '0.5',
  aiPp: '0.5',
  aiTemperature: '0.5',
};

export const openAiModels = [
  'gpt-4',
  'gpt-3.5-turbo',
  'gpt-4-1106-preview',
  'text-davinci-003',
  'text-curie-001',
  'text-babbage-001',
  'text-ada-001',
  'gpt-4-0613',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-0125',
  'gpt-4-0125-preview',
  'gpt-4-turbo-preview',
  'gpt-3.5-turbo-instruct',
  `gpt-4o`,
  'gpt-4-vision-preview',
  'transcript',
  'dall-e-3-1024ST',
  'dall-e-3-1792HD',
  'dall-e-3-1024HD',
  'dall-e-3-1792ST',
  'dall-e-2-1024',
  'dall-e-2-512',
  'dall-e-2-524',
  'dall-e-2-1024',
];

export const anthropicAiModels = [
  'claude-2.1',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307',
];

export const buildWithApiModels = ['web-scraper-build-with-api'];

export const censusData = ['us-census-data'];

export const clipDrop = ['clip-drop'];

export const evenLabsModels = [
  'even-labs-clone',
  'even-labs-text-to-speech',
  'even-labs-text-to-speech-stream',
];

export const geminiModels = [
  'gemini-pro-vision',
  'gemini-multi-modal',
  'gemini-speech-to-text',
  'gemini-1.0-pro',
  'gemini-1.5-pro',
  'gemini-flash',
];

export const googleSheetsModels = ['google-sheets'];

export const stabilityAiModels = [
  'sd3',
  'sd3-turbo',
  'stability-ai-text-to-image',
  'stability-ai-image-to-image',
  'stability-ai-image-upscale',
  'stability-ai-image-to-video',
  'stability-ai-image-core',
];

export const perplexityAiModels = [
  'llama-31-sonar-small-chat',
  'llama-31-sonar-large-chat',
  'llama-31-sonar-small-online',
  'llama-31-sonar-large-online',
  'llama-31-sonar-huge-128k-online',
  '70b-instruct',
  '8b-instruct',
];

export const replicateAiModels = [
  'falcon-40b-instruct',
  'mpt-7b-storywriter',
  'llama-2-70b-chat',
  'kandinsky',
  'code-llama-34b',
  'code-llama-34b-instruct',
  'musicGen',
  'blip2',
  'animate-diff',
  'llama-2-13b-chat',
  'mistral-7b',
  'llava-13b',
  'whisper-v3',
  'pixart-lcm-xl-2',
];

export const webScraperModels = [
  'web-scraper-scraper-bee',
  'web-scraper-oxy-labs',
];

export const playHtModels = ['play-ht-text-to-voice', 'play-ht-clone-voice'];

export const voicePlayHt = {
  voiceModel: 'play-ht-text-to-voice',
  voiceSystem: 'play.ht',
  voice: 'Evelyn',
  speed: 1,
  quality: 'medium',
  outputFormat: 'mp3',
  sampleRate: 24000,
};

export const voiceEvenLabs = {
  voiceModel: 'evenlabs-text-to-voice',
  voiceSystem: 'elevenlabs.io',
  voice: 'Evelyn',
  speed: 1,
  quality: 'medium',
  outputFormat: 'mp3',
  sampleRate: 24000,
};

export const imageAi = {
  engineId: 'stability-ai-text-to-image',
  cfg_scale: 8,
  height: 1024,
  width: 1024,
  steps: 50,
  clip_guidance_preset: 'NONE',
  type: 'Text to Image',
  promptWeight: 1.0,
  image_strength: 0.0,
  negativePrompt: '',
  style_present: 'None',
  sampler: 'AUTO',
  toneDefaultValues: [],
  viewDefaultValues: [],
  openSourceModel: 'kandinsky2',
  openAiModels: 'createImage',
  quality: 'hd',
  numbersOfVariations: 1,
  size: '1024x1024',
};

export const groqAi = {
  seed: 50,
  maxTokens: 4000,
  aiTemperature: 0.5,
  topP: 0.5,
  groqStop: '',
  groqModel: 'llama3-8b-8192',
};

export const geminiAi = {
  geminiModel: 'gemini-1.5-pro',
  system: 'gemini',
  maxOutputTokens: 256,
  temperature: 0.5,
  topP: 0.5,
  topK: 40,
};

export const anthropicOptions = [
  'claude-35-sonnet',
  'claude-3-sonnet-20240229',
  'claude-3-opus-20240229',
  'claude-3-haiku-20240307',
  'claude-2.1',
];

export const modelTokenLimits = [
  { model: 'gpt-4o', tokens: 16384 },
  { model: 'gpt-4o-mini', tokens: 16384 },
  { model: 'gpt-4-turbo-preview', tokens: 4096 },
  { model: 'gpt-4-1106-preview', tokens: 4096 },
  { model: 'gpt-4', tokens: 8192 },
  { model: 'gpt-4-0613', tokens: 8192 },
  { model: 'gpt-4-0125-preview', tokens: 4096 },
  { model: 'gpt-3.5-turbo', tokens: 4096 },
  { model: 'gpt-3.5-turbo-0125', tokens: 4096 },
  { model: 'gpt-3.5-turbo-1106', tokens: 4096 },
  { model: 'gpt-3.5-turbo-instruct', tokens: 4096 },
];

export type AiTextGeneratorFormValues = {
  aiFp: string;
  aiModel: string;
  aiPp: string;
  aiTemperature: string;
  aiTokens: string;
  maxAiTokens: string;
  maxOutputTokens: number;
  temperature: number;
  topP: number;
  topK: number;
  type: string;
  AiSystem: string;
  modelName: string;
  text: string;
  openSourceModelName: string;
  max_length: number;
  top_p: number;
  repetition_penalty: number;
  length_penalty: number;
  no_repeat_ngram_size: number;
  seed: number;
  max_new_tokens: number;
  system_prompt: string;
  min_new_tokens: number;
  prompt: string;
  content: string;
  instruction: string;
  persona: string;
  perplexityType: string;
  autoToken: boolean;
  anthropicModel: string;
  max_tokens: number;
  groqModel: string;
  groqTemperature: number;
  groqMaxTokens: number;
  groqTopP: number;
  groqStop: string;
  groqSeed: number;
  groqName: string;
  stop?: string;
  geminiTemp: number;
  geminiTopP: number;
  geminiTopK: number;
  geminiMaxOutputTokens: number;
  googleType: string;
  geminiModel: string;
  anthropicToken: number;
  anthropicTopK: number;
  anthropicTopP: number;
  anthropicTemperature: number;
  perplexityTokens: number;
  perplexityTemperature: number;
  perplexityTopP: number;
  perplexityTopK: number;
};

export const systemValueMap: { [key: string]: string } = {
  openAi: 'OpenAI',
  perplexity: 'perplexityAi',
  anthropic: 'anthropicAi',
  groq: 'Groq',
  gemini: 'Gemini',
  replicate: 'openSources',
  evenLabs: 'elevenlabs.io',
  playHt: 'play.ht',
};

export const systemDisplayNameMap: { [key: string]: string } = {
  openAi: 'OpenAI',
  perplexityAi: 'Perplexity',
  anthropicAi: 'Anthropic',
  groq: 'Groq',
  gemini: 'Gemini',
  openSources: 'Open Source',
  playHt: 'Play.ht',
};

export const commandTextNodeDefaultValues = {
  aiFp: '0.5',
  aiModel: 'gpt-4o',
  aiPp: '0.5',
  aiTemperature: '0.5',
  aiTokens: '4000',
  maxAiTokens: '',
  maxOutputTokens: 256,
  temperature: 0.2,
  topP: 0.8,
  topK: 40,
  type: 'text',
  AiSystem: 'OpenAI',
  modelName: 'models/text-bison-001',
  text: '',
  openSourceModelName: 'code-llama-34b-instruct',
  max_length: 500,
  top_p: 1,
  repetition_penalty: 1,
  length_penalty: 1,
  no_repeat_ngram_size: 0,
  seed: -1,
  max_new_tokens: 65,
  system_prompt: '',
  min_new_tokens: 0,
  prompt: '',
  content: '',
  instruction: '',
  persona: '',
  perplexityType: 'llama-31-sonar-large-online',
  autoToken: false,
  anthropicModel: anthropicOptions[0],
  max_tokens: 4096,
  groqModel: 'mixtral-8x7b-32768',
  groqTemperature: 0.5,
  groqMaxTokens: 4096,
  groqTopP: 1,
  groqStop: '',
  groqSeed: -1,
  groqName: '',
  geminiTemp: 1,
  geminiTopP: 0.9,
  geminiTopK: 10,
  geminiMaxOutputTokens: 8192,
  googleType: 'Gemini',
  geminiModel: 'gemini-1.5-pro',
  anthropicToken: 8192,
  anthropicTopK: 40,
  anthropicTopP: 1,
  anthropicTemperature: 0.2,
  perplexityTokens: 8192,
  perplexityTemperature: 0.2,
  perplexityTopP: 1,
  perplexityTopK: 40,
};

export const groqFields = [
  {
    name: 'seed',
    label: 'Seed',
    type: 'number',
    step: '1',
    min: '-1',
    max: '100',
  },

  {
    name: 'groqMaxTokens',
    label: 'Max Tokens',
    type: 'number',
    step: '1',
    min: '1',
    max: '4000',
  },
  {
    name: 'geminiTemp',
    label: 'Temperature',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '1',
  },
  {
    name: 'geminiTopP',
    label: 'Top P',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '1',
  },
];

export const groqModels = [
  'llama3-8b-8192',
  'llama3-70b-8192',
  'mixtral-8x7b-32768',
  'gemma-7b-it',
];

export const groqOptions = [
  { value: 'llama3-8b-8192', name: 'llama3-8b-8192', disabled: false },
  { value: 'llama3-70b-8192', name: 'llama3-70b-8192', disabled: false },
  { value: 'mixtral-8x7b-32768', name: 'mixtral-8x7b-32768', disabled: false },
  { value: 'gemma-7b-it', name: 'gemma-7b-it', disabled: false },
];

export const googleSystem = [
  { value: 'gemini', name: 'Gemini', disabled: false },
];

export const geminiType = [
  { value: 'text', name: 'Text', disabled: false },
  { value: 'pro', name: 'Text Pro', disabled: false },
];

export const geminiFileds = [
  {
    name: 'geminiMaxOutputTokens',
    label: 'Max Output Tokens',
    type: 'number',
    step: '1',
    min: '1',
    max: '8192',
  },
  {
    name: 'temperature',
    label: 'Temperature (Creativity of LLM response)',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '1',
  },
  {
    name: 'topP',
    label: 'Top-P',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '1',
  },
  {
    name: 'topK',
    label: 'Top-K',
    type: 'range',
    step: '1',
    min: '1',
    max: '40',
  },
];

export const stabilityFields = [
  {
    name: 'width',
    label: 'Width',
    type: 'number',
    step: '128',
    min: '512',
    max: '1024',
  },
  {
    name: 'height',
    label: 'Height',
    type: 'number',
    step: '128',
    min: '512',
    max: '1920',
  },
  {
    name: 'cfg_scale',
    label: 'Cfg Scale',
    type: 'number',
    step: '1',
    min: '1',
    max: '35',
  },
  {
    name: 'steps',
    label: 'Steps',
    type: 'number',
    step: '1',
    min: '10',
    max: '150',
  },
  {
    name: 'image_strength',
    label: 'Image Strength',
    type: 'number',
    step: '0.01',
    min: '0',
    max: '1',
  },
  {
    name: 'promptWeight',
    label: 'Prompt Weight',
    type: 'number',
    step: '0.01',
    min: '0',
    max: '1',
  },
];

export const imageAiModels = [
  {
    value: 'stabilityAi',
    name: 'Stability Ai',
  },
  {
    value: 'falAi',
    name: 'Fal Ai',
  },
  {
    value: 'openAi',
    name: 'Open AI',
  },
  {
    value: 'openSources',
    name: 'Open Sources',
  },
];

export const openSourceModelsImage = [
  {
    value: 'kandinsky2',
    name: 'Kandinsky 2',
  },
];

export const inputFields = [
  {
    name: 'aiTokens',
    label: 'Tokens',
    type: 'number',
    step: '1',
    min: '0',
    max: '8000',
  },
  {
    name: 'aiTemperature',
    label: 'Temperature',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '2.0',
  },
  {
    name: 'aiFp',
    label: 'F-Penalty',
    type: 'range',
    step: '0.1',
    min: '-2.0',
    max: '2.0',
  },
  {
    name: 'aiPp',
    label: 'P-Penalty',
    type: 'range',
    step: '0.1',
    min: '-2.0',
    max: '2.0',
  },
];

export const falcon400bInputs = [
  {
    name: 'max_length',
    label: 'Max Length',
    type: 'number',
    step: '1',
    min: '1',
    max: '700000',
  },
  {
    name: 'temperature',
    label: 'Temperature',
    type: 'range',
    step: '0.01',
    min: '0.01',
    max: '5',
  },
  {
    name: 'top_p',
    label: 'Top P',
    type: 'range',
    step: '0.01',
    min: '0.01',
    max: '1',
  },
  {
    name: 'repetition_penalty',
    label: 'Repetition Penalty',
    type: 'range',
    step: '0.01',
    min: '0.01',
    max: '5',
  },
  {
    name: 'length_penalty',
    label: 'Length Penalty',
    type: 'range',
    step: '0.01',
    min: '0.01',
    max: '5',
  },
  {
    name: 'no_repeat_ngram_size',
    label: 'No Repeat Ngram Size',
    type: 'number',
    step: '0.1',
    min: '0',
    max: '100',
  },
  {
    name: 'seed',
    label: 'Seed',
    type: 'range',
    step: '0.01',
    min: '-1',
    max: '100',
  },
];

export const llama7bInputs = [
  {
    name: 'max_new_tokens',
    label: 'Max New Tokens',
    type: 'number',
    step: '1',
    min: '1',
    max: '999999',
  },
  {
    name: 'min_new_tokens',
    label: 'Min New Tokens',
    type: 'number',
    step: '1',
    min: '-1',
    max: '999999',
  },
  {
    name: 'temperature',
    label: 'Temperature',
    type: 'range',
    step: '0.01',
    min: '0.01',
    max: '5',
  },
  {
    name: 'top_p',
    label: 'Top P',
    type: 'range',
    step: '0.01',
    min: '0.01',
    max: '1',
  },
  {
    name: 'topK',
    label: 'Top-K',
    type: 'range',
    step: '1',
    min: '1',
    max: '100',
  },

  {
    name: 'seed',
    label: 'Seed',
    type: 'range',
    step: '0.01',
    min: '-1',
    max: '100',
  },
];

export const openAiInputs = [
  {
    name: 'aiTemperature',
    label: 'Ai Temperature',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '2',
  },
  {
    name: 'aiFp',
    label: 'Ai Fp',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '2',
  },
  {
    name: 'aiPp',
    label: 'Ai Pp',
    type: 'range',
    step: '0.1',
    min: '0',
    max: '2',
  },
];

export const openSourcesModels = [
  {
    value: 'falcon-40b-instruct',
    name: 'falcon-40b-instruct',
    disabled: false,
  },
  { value: 'mpt-30b-chat', name: 'mpt-30b-chat', disabled: false },
  { value: 'mpt-7b-storywriter', name: 'mpt-7b-storywriter', disabled: false },
  { value: 'llama-2-70b-chat', name: 'llama-2-70b-chat', disabled: false },
  { value: 'code-llama-34b', name: 'code-llama-34b', disabled: false },
  {
    value: 'code-llama-34b-instruct',
    name: 'code-llama-34b-instruct',
    disabled: false,
  },
];

export const optionsArray = [
  {
    value: 'gpt-4o',
    name: 'gpt-4o',
    disabled: false,
    tags: ['New', '128,000 Tokens', '4,096 Tokens', 'Training up to Oct 2023'],
    description: `OPEN AI most advanced, multimodal flagship model that’s cheaper and faster than GPT-4 Turbo. Currently points to gpt-4o-2024-05-13.`,
  },
  {
    value: 'gpt-4o-mini',
    name: 'gpt-4o-mini',
    disabled: false,
    tags: ['New', '128,000 Tokens', '4,096 Tokens', 'Training up to Oct 2023'],
    description: `OPEN AI most advanced, multimodal flagship model that’s cheaper and faster than GPT-4 Turbo. Currently points to gpt-4o-2024-05-13.`,
  },
  {
    value: 'gpt-4-turbo-preview',
    name: 'gpt-4-turbo-preview',
    disabled: false,
    tags: ['New', '128,000 Tokens', '4,096 Tokens', 'Training up to Dec 2023'],
    description: `GPT-4 Turbo The latest GPT-4 model intended to reduce cases of “laziness” where the model doesn’t complete a task. Returns a maximum of 4,096 output tokens.`,
  },
  {
    value: 'gpt-4-1106-preview',
    name: 'gpt-4-1106-preview',
    disabled: false,
    description: `GPT-4 Turbo model featuring improved instruction following, JSON mode, reproducible outputs, parallel function calling, and more. Returns a maximum of 4,096 output tokens. This is a preview model.`,
    tags: ['128,000 Tokens', '4,096 Tokens', 'Training up to Apr 2023'],
  },
  {
    value: 'gpt-4',
    name: 'gpt-4',
    disabled: false,
    description: 'Currently points to gpt-4-0613',
    tags: ['8,192 Tokens', '4,096 Tokens', 'Training up to Sep 2021'],
  },
  {
    value: 'gpt-4-0613',
    name: 'gpt-4-0613',
    disabled: false,
    description: `Snapshot of gpt-4 from June 13th 2023 with improved function calling support.`,
    tags: ['8,192 Tokens', '4,096 Tokens', 'Training up to Sep 2021'],
  },
  {
    value: 'gpt-4-0125-preview',
    name: 'gpt-4-0125-preview',
    disabled: false,
    tags: ['New', '128,000 Tokens', '4,096 Tokens', 'Training up to Dec 2023'],
    description: `GPT-4 Turbo The latest GPT-4 model intended to reduce cases of “laziness” where the model doesn’t complete a task. Returns a maximum of 4,096 output tokens.`,
  },
  {
    value: 'gpt-3.5-turbo',
    name: 'gpt-3.5-turbo',
    disabled: false,
    description: `Currently points to gpt-3.5-turbo-0125.`,
    tags: ['16,385 Tokens', '4,096 Tokens', 'Training up to Sep 2021'],
  },

  {
    value: 'gpt-3.5-turbo-0125',
    name: 'gpt-3.5-turbo-0125',
    disabled: false,
    description: `Updated GPT 3.5 Turbo The latest GPT-3.5 Turbo model with higher accuracy at responding in requested formats and a fix for a bug which caused a text encoding issue for non-English language function calls. Returns a maximum of 4,096 output tokens.`,
    tags: ['New', '16,385 Tokens', '4,096 Tokens', 'Training up to Sep 2021'],
  },
  {
    value: 'gpt-3.5-turbo-1106',
    name: 'gpt-3.5-turbo-1106',
    disabled: false,
    description: `GPT-3.5 Turbo model with improved instruction following, JSON mode, reproducible outputs, parallel function calling, and more. Returns a maximum of 4,096 output tokens.`,
    tags: ['16,385 Tokens', '4,096 Tokens', 'Training up to Sep 2021'],
  },
  {
    value: 'gpt-3.5-turbo-instruct',
    name: 'gpt-3.5-turbo-instruct',
    disabled: false,
    description: `Similar capabilities as GPT-3 era models. Compatible with legacy Completions endpoint and not Chat Completions.`,
    tags: ['4,096 tokens', '2,096 Tokens', 'Training up to Sep 2021'],
  },
  {
    value: 'gpt-3.5-turbo-16k',
    name: 'gpt-3.5-turbo-16k',
    disabled: false,
    description: 'Currently points to gpt-3.5-turbo-16k-0613',
    tags: [
      'Legacy',
      '16,385 Tokens',
      '4,096 Tokens',
      'Training up to Sep 2021',
    ],
  },
];

export const additionalOptionsArray = [
  { value: 'anthropicAi', name: 'Anthropic AI', disabled: false, models: [] },
  { value: 'OpenAI', name: 'OpenAI', disabled: false, models: openAiModels },
  { value: 'Google', name: 'Google', disabled: false, models: [] },
  { value: 'Groq', name: 'Groq', disabled: false, models: [] },
  { value: 'openSources', name: 'Open Source', disabled: false, models: [] },
  { value: 'perplexityAi', name: 'Perplexity AI', disabled: false, models: [] },
];

export const aiTechnologies = [
  {
    value: 'anthropicAi',
    name: 'Anthropic AI',
    enabled: true,
    models: anthropicAiModels,
  },
  { value: 'OpenAI', name: 'OpenAI', enabled: true, models: openAiModels },
  { value: 'gemini', name: 'Gemini', enabled: true, models: geminiModels },
  {
    value: 'perplexityAi',
    name: 'Perplexity AI',
    enabled: true,
    models: perplexityAiModels,
  },
  {
    value: 'stabilityAi',
    name: 'Stability AI',
    enabled: true,
    models: stabilityAiModels,
  },
  {
    value: 'replicate',
    name: 'Open Source',
    enabled: true,
    models: replicateAiModels,
  },
  {
    value: 'evenlabs.io',
    name: 'Even Labs',
    enabled: true,
    models: evenLabsModels,
  },
  { value: 'play.ht', name: 'Play HT', enabled: true, models: playHtModels },
  {
    value: 'googleSheets',
    name: 'Google Sheets',
    enabled: true,
    models: googleSheetsModels,
  },
  {
    value: 'summarize',
    name: 'Summarize',
    enabled: true,
    models: ['summarize'],
  },
  {
    value: 'webScraper',
    name: 'Web Scraper',
    enabled: true,
    models: webScraperModels,
  },
  { value: 'secData', name: 'SecData', enabled: true, models: ['sec-data'] },
  { value: 'semRush', name: 'SemRush', enabled: true, models: ['sem-rush'] },
  { value: 'serpApi', name: 'SerpApi', enabled: true, models: ['serp-api'] },
  { value: 'clipDrop', name: 'ClipDrop', enabled: true, models: clipDrop },
  {
    value: 'censusData',
    name: 'Census Data',
    enabled: true,
    models: censusData,
  },
  {
    value: 'buildWithApi',
    name: 'Build With API',
    enabled: true,
    models: buildWithApiModels,
  },
];

export const perplexityOptions = [
  'llama-31-sonar-small-chat',
  'llama-31-sonar-large-chat',
  'llama-31-sonar-small-online',
  'llama-31-sonar-large-online',
  'llama-31-sonar-huge-128k-online',
  '70b-instruct',
  '8b-instruct',
];
export const sizes = [
  { width: 1024, height: 1024 },
  { width: 1152, height: 896 },
  { width: 1216, height: 832 },
  { width: 1344, height: 768 },
  { width: 1536, height: 640 },
  { width: 640, height: 1536 },
  { width: 768, height: 1344 },
  { width: 832, height: 1216 },
  { width: 896, height: 1152 },
];

export const options = {
  lighting: [
    { value: 'Sunlight', label: 'Sunlight' },
    { value: 'Rim light', label: 'Rim light' },
    { value: 'Volumetric lighting', label: 'Volumetric lighting' },
    { value: 'Backlight', label: 'Backlight' },
    { value: 'Dimly lit', label: 'Dimly lit' },
    { value: 'Well-lit', label: 'Well-lit' },
    { value: 'Hard light', label: 'Hard light' },
    { value: 'Direct light from...', label: 'Direct light from...' },
    { value: 'Subsurface scattering', label: 'Subsurface scattering' },
    { value: 'Bounce light', label: 'Bounce light' },
    { value: 'Ambient light', label: 'Ambient light' },
  ],
  color: [
    {
      value: 'Monochromatic color scheme',
      label: 'Monochromatic color scheme',
    },
    { value: 'Analogous color scheme', label: 'Analogous color scheme' },
    {
      value: 'Complementary color scheme',
      label: 'Complementary color scheme',
    },
    {
      value: 'Split Complementary color scheme',
      label: 'Split Complementary color scheme',
    },
    {
      value: 'Double Split Complementary color scheme',
      label: 'Double Split Complementary color scheme',
    },
    {
      value: 'Triadic (or triad) color scheme',
      label: 'Triadic (or triad) color scheme',
    },
    { value: 'Square color scheme', label: 'Square color scheme' },
    { value: 'Compound color scheme', label: 'Compound color scheme' },
    { value: 'Rectangle color scheme', label: 'Rectangle color scheme' },
    { value: 'Shades color scheme', label: 'Shades color scheme' },
    { value: '60-30-10 color ratio', label: '60-30-10 color ratio' },
    {
      value: 'Black and white color scheme',
      label: 'Black and white color scheme',
    },
  ],
};
export const viewsArrayOptions = {
  views: [
    { label: 'Bird’s-eye view', value: 'Bird’s-eye view' },
    { label: 'Worm’s-eye view', value: 'Worm’s-eye view' },
    { label: 'Profile view', value: 'Profile view' },
    { label: 'Landscape view', value: 'Landscape view' },
    { label: 'Aerial view', value: 'Aerial view' },
    { label: 'High-angle view', value: 'High-angle view' },
    { label: 'Low-angle view', value: 'Low-angle view' },
    { label: 'Frontal view', value: 'Frontal view' },
    { label: 'Rear view', value: 'Rear view' },
    { label: 'Oblique view', value: 'Oblique view' },
    { label: 'Point of view (POV)', value: 'Point of view (POV)' },
    { label: 'Overhead view', value: 'Overhead view' },
    { label: 'Close-up view', value: 'Close-up view' },
    { label: 'Panoramic view', value: 'Panoramic view' },
    { label: 'Wide-angle view', value: 'Wide-angle view' },
    { label: 'Telephoto view', value: 'Telephoto view' },
    { label: 'Fish-eye view', value: 'Fish-eye view' },
    { label: 'Candid view', value: 'Candid view' },
    { label: '360-degree view', value: '360-degree view' },
    { label: 'Split-view', value: 'Split-view' },
    { label: 'Three-quarter view', value: 'Three-quarter view' },
    { label: 'Interior view', value: 'Interior view' },
    { label: 'Exterior view', value: 'Exterior view' },
    { label: 'Top-down view', value: 'Top-down view' },
    { label: 'Cross-sectional view', value: 'Cross-sectional view' },
    { label: 'Isometric view', value: 'Isometric view' },
    { label: 'Parallel view', value: 'Parallel view' },
    { label: 'Diagonal view', value: 'Diagonal view' },
    { label: 'Circular view', value: 'Circular view' },
    { label: 'Reflection view', value: 'Reflection view' },
  ],
};

export const viewsArray = [
  'AUTO',
  'DDIM',
  'DDPM',
  'K_DPMPP_2M',
  'K_DPMPP_2S_ANCESTRAL',
  'K_DPM_2',
  'K_DPM_2_ANCESTRAL',
  'K_EULER',
  'K_EULER_ANCESTRAL',
  'K_HEUN',
  'K_LMS',
];

export const clipGuidancePresent = [
  'NONE',
  'FAST_BLUE',
  'FAST_GREEN',
  'SIMPLE',
  'SLOW',
  'SLOWER',
  'SLOWEST',
];

export const aspectRatioIdeogram = [
  { label: '10x16', value: 'ASPECT_10_16' },
  { label: '16x10', value: 'ASPECT_16_10' },
  { label: '9x16', value: 'ASPECT_9_16' },
  { label: '16x9', value: 'ASPECT_16_9' },
  { label: '3x2', value: 'ASPECT_3_2' },
  { label: '2x3', value: 'ASPECT_2_3' },
  { label: '4x3', value: 'ASPECT_4_3' },
  { label: '3x4', value: 'ASPECT_3_4' },
  { label: '1x1', value: 'ASPECT_1_1' },
  { label: '1x3', value: 'ASPECT_1_3' },
  { label: '3x1', value: 'ASPECT_3_1' },
];

export const imageOpenAiModels = [
  {
    name: 'Create Image',
    value: 'createImage',
  },
  {
    name: 'Edit Image',
    value: 'editImage',
  },
  {
    name: 'Image Variation',
    value: 'imageVariation',
  },
];

export const stylePresent = [
  'None',
  '3d-model',
  'analog-film',
  'anime',
  'cinematic',
  'comic-book',
  'digital-art',
  'enhance',
  'fantasy-art',
  'isometric',
  'line-art',
  'low-poly',
  'modeling-compound',
  'neon-punk',
  'origami',
  'photographic',
  'pixel-art',
  'tile-texture',
];
