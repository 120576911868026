import { Box, Typography } from '@mui/material';
import KitModal from '../UI/KitModal';

interface Props {
  systemDescription: string;
  system: string;
  onHide: () => void;
  show: boolean;
}

const DescriptionModal = ({
  systemDescription,
  system,
  onHide,
  show,
}: Props) => {
  return (
    <KitModal
      headerStyles={{
        pb: '0px',
      }}
      title={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            color={'#101828'}
            fontSize="14px"
            fontWeight={500}
            lineHeight="150%"
          >
            {system.charAt(0).toUpperCase() + system.slice(1)}
          </Typography>
        </Box>
      }
      fullWidth
      show={show}
      onHide={onHide}
    >
      <Typography color={'#475467'} fontSize={'14px'}>
        {systemDescription}
      </Typography>
    </KitModal>
  );
};

export default DescriptionModal;
