import React, {
  ComponentType,
  FC,
  FunctionComponent,
  MouseEvent,
  ReactNode,
  SVGAttributes,
  useState,
} from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { CreditCard, DotsThreeOutline, Trash } from '@phosphor-icons/react';
import DeleteCardModal from '../modals/DeleteCardModal';
import { PaymentInfoDetails } from '../../../util/Types/PaymentInfoDetails';
import { ReactComponent as MasterCardIcon } from '../../../assets/icons/Mastercard.svg';
import { ReactComponent as VisaCardIcon } from '../../../assets/icons/Visa.svg';

const PaymentCardBox: FC<PaymentInfoDetails> = ({
  brand,
  primary = false,
  last4,
  handleDeleteCard,
  id,
  paymentMethodId,
  exp_month,
  exp_year,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDeleteCardModal, setOpenDeleteCardModal] = useState(false);

  const handleCloseDeleteCardModal = () => {
    setOpenDeleteCardModal(false);
  };

  const renderCardIcon = () => {
    switch (brand) {
      case 'visa':
        return <VisaCardIcon />;
      case 'mastercard':
        return <MasterCardIcon />;

      default:
        return <CreditCard size={32} />;
    }
  };

  const onDeleteCard = () => {
    if (handleDeleteCard && paymentMethodId) {
      handleDeleteCard(paymentMethodId);
      handleCloseDeleteCardModal();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #EAECF0',
          justifyContent: 'flex-start',
          borderRadius: '8px',
          padding: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '278px',
            gap: '12px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '46px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              border: '1px solid #EAECF0',
            }}
          >
            {renderCardIcon()}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  color: '#1D2939',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                {brand?.charAt(0)?.toUpperCase() + brand?.slice(1)}
              </Typography>
              {primary && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '17px',
                    padding: '2px 4px',
                    backgroundColor: '#E7F0FF',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#3650DE',
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    }}
                  >
                    Primary
                  </Typography>
                </Box>
              )}
            </Box>

            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
              }}
            >
              Ending in {last4}
            </Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="more"
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'flex-start',
            padding: '0px',
            margin: '0px',
          }}
        >
          <DotsThreeOutline size={16} weight="fill" color="#343330" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                setOpenDeleteCardModal(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <Trash size={18} color="#101828" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: '#101828',
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}
              >
                Delete Card
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <DeleteCardModal
        onHide={handleCloseDeleteCardModal}
        fullWidth={true}
        show={openDeleteCardModal}
        footer={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '31px',
              width: '100%',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
                flex: 1,
                height: '36px',
              }}
              onClick={() => {
                setOpenDeleteCardModal(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#F05450',
                color: '#FFFFFF',
                outline: '1px solid #F05450',
                flex: 1,
                height: '36px',
                '&:hover': {
                  backgroundColor: '#F05450',
                },
              }}
              onClick={onDeleteCard}
            >
              Delete
            </Button>
          </Box>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              Delete Card
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              Are you sure you want to delete the card ending in {last4}?
            </Typography>
          </Box>
        </Box>
      </DeleteCardModal>
    </>
  );
};

export default PaymentCardBox;
