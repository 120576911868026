import { AnthropicService } from '../../service/AnthropicService';
import { GeminiService } from '../../service/GeminiService';
import { GroqService } from '../../service/GroqService';
import { OpenAiService } from '../../service/OpenAiService';
import { PerplexityService } from '../../service/PerplexityService';
import { ReplicateService } from '../../service/ReplicateService';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { AiTextGeneratorFormValues } from '../AiConfigs/constants';
import { formatPrompt } from '../flux_classes/PromptCrafter';

export const commandTextNodeResponses = async (
  e: AiTextGeneratorFormValues,
  schema: any,
  flowId: string,
) => {
  const prompt = formatPrompt({
    prompt: replacePlaceholders(e.prompt, schema),
    content: replacePlaceholders(e.content, schema),
    instruction: replacePlaceholders(e.instruction, schema),
    persona: replacePlaceholders(e.persona, schema),
  });
  let promptText = '';
  prompt.forEach((p, i) => {
    promptText = promptText + ' ' + p.content;
  });

  let response = null;

  if (e.AiSystem === 'Groq') {
    response = await GroqService.search({
      messages: [
        {
          role: prompt[0]?.role,
          content: promptText,
          name: e.groqName
            ? e.groqName
            : Math.random().toString(36).substring(7),
        },
      ],
      seed: +e.groqSeed,
      model: e.groqModel,
      temperature: +e.groqTemperature,
      max_tokens: +e.groqMaxTokens,
      top_p: +e.groqTopP,
      stop: e?.stop ? e?.stop : null,
      flowId,
    });
  }

  if (e.AiSystem === 'openSources') {
    //falcon-40b-instruct
    //mpt-30b-chat
    // mpt-7b-storywriter
    // llama-2-70b-chat
    const options = {
      prompt: promptText,
      max_length: +e.max_length,
      temperature: +e.temperature,
      top_p: +e.top_p,
      repetition_penalty: +e.repetition_penalty,
      length_penalty: +e.length_penalty,
      no_repeat_ngram_size: +e.no_repeat_ngram_size,
      seed: +e.seed,
      flowId,
    };
    if (e.openSourceModelName === 'falcon-40b-instruct') {
      response = await ReplicateService.falcon40bInstruct(options);
    }

    if (e.openSourceModelName === 'mpt-7b-storywriter') {
      response = await ReplicateService.mpt7bStorywriter(options);
    }

    if (e.openSourceModelName === 'mpt-30b-chat') {
      response = await ReplicateService.mpt30bChat({
        prompt: promptText,
        max_new_tokens: +e.max_new_tokens,
        flowId,
      });
    }

    if (e.openSourceModelName === 'llama-2-70b-chat') {
      const prompt = formatPrompt({
        prompt: e.prompt,
        content: e.content,
        instructions: e.instruction,
      });
      let promptText = '';
      prompt.forEach((p, i) => {
        promptText = promptText + ' ' + p.content;
      });
      response = await ReplicateService.llama70bChat({
        system_prompt: e.persona,
        prompt: promptText,
        max_new_tokens: +e.max_new_tokens,
        min_new_tokens: +e.min_new_tokens,
        temperature: +e.temperature,
        top_p: +e.top_p,
        top_k: +e.topK,
        seed: +e.seed,
        flowId,
      });
    }

    if (e.openSourceModelName === 'code-llama-34b') {
      response = await ReplicateService.codeLlama34b({
        prompt: promptText,
        flowId,
      });
    }

    if (e.openSourceModelName === 'code-llama-34b-instruct') {
      response = await ReplicateService.codeLlama34bInstruct({
        prompt: promptText,
        flowId,
      });
    }
  }

  if (e.AiSystem === 'OpenAI') {
    response = await OpenAiService.login({
      prompt: promptText,
      model: e.aiModel,
      fp: +e.aiFp,
      pp: +e.aiPp,
      tokens: +e.aiTokens,
      temperature: +e.aiTemperature,
      role: prompt[0]?.role,
      flowId,
    });
  }

  if (e.AiSystem === 'perplexityAi') {
    const prompt = formatPrompt({
      prompt: replacePlaceholders(e.prompt, schema),
      content: replacePlaceholders(e.content, schema),
      instruction: replacePlaceholders(e.instruction, schema),
    });

    let promptText = '';
    prompt.forEach((p, i) => {
      promptText = promptText + ' ' + p.content;
    });

    response = await PerplexityService.response(
      {
        prompt: promptText,
        system: replacePlaceholders(e.persona, schema) || null,
        flowId,
        top_p: +e.perplexityTopP,
        top_k: +e.perplexityTopK,
        temperature: +e.perplexityTemperature,
        max_tokens: +e.perplexityTokens,
      },
      e.perplexityType,
    );
  }

  if (e.AiSystem === 'anthropicAi') {
    let pathname = 'claude-v3';
    if (e.anthropicModel?.startsWith('claude-2')) {
      pathname = 'claude';
    } else if (e.anthropicModel?.includes('claude-35-sonnet')) {
      pathname = 'claude-35-sonnet';
    }

    response = await AnthropicService.response(
      {
        model:
          pathname === 'claude-35-sonnet'
            ? 'claude-3-5-sonnet-20240620'
            : e.anthropicModel,
        prompt: promptText,
        max_tokens: +e.anthropicToken,
        temperature: +e.anthropicTemperature,
        topP: +e.anthropicTopP,
        topK: +e.anthropicTopK,
        flowId,
      },
      pathname,
    );
  }
  if (e.AiSystem === 'Gemini') {
    if (e.geminiModel === 'gemini-1.0-pro') {
      response = await GeminiService.gemini({
        model: 'gemini-1.0-pro',
        prompt: promptText,
        temperature: +e.geminiTemp,
        topP: +e.geminiTopP,
        topK: +e.geminiTopK,
        maxOutputTokens: +e.geminiMaxOutputTokens,
        flowId,
      });
    }
    if (e.geminiModel === 'gemini-1.5-pro') {
      response = await GeminiService.gemini({
        model: 'gemini-1.5-pro',
        prompt: promptText,
        temperature: +e.geminiTemp,
        topP: +e.geminiTopP,
        topK: +e.geminiTopK,
        maxOutputTokens: +e.geminiMaxOutputTokens,
        flowId,
      });
    }
    if (e.geminiModel === 'gemini-flash') {
      response = await GeminiService.gemini({
        model: 'gemini-1.5-flash',
        prompt: promptText,
        temperature: +e.geminiTemp,
        topP: +e.geminiTopP,
        topK: +e.geminiTopK,
        maxOutputTokens: +e.geminiMaxOutputTokens,
        flowId,
      });
    }
  }

  return response;
};
