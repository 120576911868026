import { Suspense, useEffect, useRef, useState } from 'react';
import './App.scss';
import { Navigator } from './navigator/Navigator';
import { ReactFlowProvider } from 'reactflow';
import Loader from './views/Auth/Loader';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAuthStore } from './store/storeAuth';
import LoadingSpinner from './components/UI/LoadingSpinner';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Notification from './components/Notifications/Notification';
import { RouterProvider } from 'react-router-dom';
import Socket from './components/Socket/Socket';
import { Button, ThemeProvider } from '@mui/material';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { SnackbarProvider } from 'notistack';
import { theme } from './theme';
import useFlowsStore from './store/storeFlows';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || ''; // GA4 Measurement ID
const CONTAINER_ID = process.env.REACT_APP_GTM_CONTAINER_ID || ''; // GA4 Measurement ID

const tagManagerArgs = {
  gtmId: CONTAINER_ID,
};

TagManager.initialize(tagManagerArgs);

function App() {
  const authUser = useAuthStore(state => state.user);
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const [stripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || ''),
  );
  const notistackRef = useRef<SnackbarProvider>(null);
  const { setSaving } = useFlowsStore(state => state);
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: 'pageview', page: '/login', title: 'Login Page' });

    if (authUser?.theme === 'dark') {
      setAuthUser({
        ...authUser,
        theme: 'dark',
      });
    } else {
      setAuthUser({
        ...authUser!,
        theme: 'light',
      });
    }
    const saving = {
      status: 'saved',
    };

    setSaving(saving);
  }, [authUser?.theme, setAuthUser]);

  useEffect(() => {
    if (authUser?.id) {
      // Simulating user data
      const user = {
        name: authUser?.name || 'John Doe',
        id: authUser?.id || '123',
        email: authUser?.email || '',
        createdAt: Math.floor(Date.now() / 1000), // Unix timestamp
      };

      const windowGlobal = typeof window !== 'undefined' && (window as any);
      // Setting up intercomSettings
      windowGlobal.intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'mzbz15nv',
        name: user.name, // Full name
        user_id: user.id, // a UUID for your user
        email: user.email, // the email for your user
        created_at: user.createdAt, // Signup date as a Unix timestamp
        custom_launcher_selector: '#my_custom_link',
        hide_default_launcher: true,
        horizontal_padding: 85,
        vertical_padding: 75,
      };

      // Reattach Intercom if already loaded, or load it
      if (windowGlobal.Intercom) {
        windowGlobal.Intercom('reattach_activator');
        windowGlobal.Intercom('update', windowGlobal.intercomSettings);
      } else {
        // Load Intercom
        const loadIntercom = () => {
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/mzbz15nv';
          var x = document.getElementsByTagName('script')[0];
          x?.parentNode?.insertBefore(s, x);

          // change position of intercom widget
          windowGlobal.addEventListener('load', () => {
            windowGlobal.Intercom('update', {
              hide_default_launcher: true,
            });
          });
        };

        // Load Intercom when document is ready
        if (document.readyState === 'complete') {
          loadIntercom();
        } else if (windowGlobal.attachEvent) {
          windowGlobal.attachEvent('onload', loadIntercom);
        } else {
          windowGlobal.addEventListener('load', loadIntercom, false);
        }
      }
    }
  }, [authUser]);

  return (
    <Suspense fallback={<div>...loading</div>}>
      <CookieConsent
        buttonText="Accept Cookies"
        declineButtonText="Decline"
        cookieName="cookieConsent"
        ButtonComponent={Button}
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        enableDeclineButton={true}
        cookieSecurity={true}
        expires={150} // Expires after 150 days
        onAccept={() => {
          // Use js-cookie to set the cookie
          Cookies?.set('cookieConsent', 'true', { path: '/', expires: 150 });
        }}
        onDecline={() => {
          // Optional: Handle the decline action
          Cookies?.set('cookieConsent', 'false', { path: '/', expires: 150 });
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            ref={notistackRef}
            action={key => (
              <Button
                onClick={() => notistackRef?.current?.closeSnackbar(key)}
                style={{ color: '#fff', fontSize: '20px' }}
              >
                ✖
              </Button>
            )}
            maxSnack={4}
          >
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            >
              <Elements stripe={stripePromise}>
                <Socket />
                <Loader setIsLoading={setIsLoading} />
                <Notification />
                <ReactFlowProvider>
                  {isLoading ? (
                    <LoadingSpinner height="100vh" />
                  ) : (
                    <RouterProvider router={Navigator} />
                  )}
                </ReactFlowProvider>
              </Elements>
            </GoogleOAuthProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
