import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { User } from '../../util/Types/User';
import { useAuthStore } from '../../store/storeAuth';
import { Buildings, Warning } from '@phosphor-icons/react';
import KitModal from '../UI/KitModal';
import { CompanyService } from '../../service/CompanyService';
import { UserService } from '../../service/UserService';
import LoadingSpinner from '../UI/LoadingSpinner';

type Props = {
  onClose: () => void;
};

const TransferUserModal: React.FC<Props> = ({ onClose }) => {
  const { user: authUser, setAuthUser } = useAuthStore(state => state);

  const [userCompanies, setUserCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserCompanies = async () => {
      setLoading(true);
      try {
        const { data } = await CompanyService.getUserCompanies();

        setUserCompanies(data);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchUserCompanies();
  }, []);

  const handleCompanyChange = (
    event: SyntheticEvent<Element, Event>,
    value: any | null,
  ) => {
    setSelectedCompany(value?.id ?? null);
  };

  const onCloseModal = async () => {
    try {
      const updateUser = await UserService.updateUser({
        transferData: true,
      });

      setAuthUser({
        ...authUser!,
        transferData: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const transferUserToCompany = async () => {
    if (!selectedCompany) return;

    try {
      await CompanyService.transferData(selectedCompany);

      setAuthUser({
        ...authUser!,
        selectedCompanyId: selectedCompany,
        transferData: false,
      });

      onClose();
    } catch (error) {
      // Handle error
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <KitModal
      onHide={onClose}
      show={true}
      showCloseButton={false}
      footer={
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Button variant="outlined" sx={{ flex: 1 }} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ flex: 1 }}
            onClick={transferUserToCompany}
            disabled={!selectedCompany}
          >
            Transfer
          </Button>
        </Box>
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Warning size={32} weight="bold" color="orange" />
        <Typography variant="h6" sx={{ ml: 1 }}>
          Transfer User Data
        </Typography>
      </Box>

      <List sx={{ maxWidth: '600px' }}>
        <ListItem>
          Due to new changes to the platform, users can now transfer their
          personal data to another company.
        </ListItem>

        <ListItem>
          This action is irreversible, so please make sure you are certain
          before proceeding.
        </ListItem>

        <ListItem>
          This action will transfer all your data to the selected company.
          Flows, Folders, FluxBots, Documents, Flux Objects, Vector Memory...
          will be transferred to the selected company .
        </ListItem>
      </List>

      <Autocomplete
        options={userCompanies}
        getOptionLabel={option => option.name}
        onChange={handleCompanyChange}
        multiple={false}
        renderOption={(props, option) => (
          <li {...props} key={option.id} >
            <Avatar src={option.logo} alt={option.name} sx={{ marginRight: 1 }}>
              <Buildings size={22} />
            </Avatar>
            {option.name}
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Select Company"
            placeholder="Company Name"
          />
        )}
      />
    </KitModal>
  );
};

export default TransferUserModal;
