import { Fragment } from 'react';

import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import AuthLayout from '../components/Layout/AuthLayout';
import Layout from '../components/Layout/Layout';
import ConfirmAccount from '../views/Auth/ConfirmAccount';
import ForgotPassword from '../views/Auth/ForgotPassword';
import Login from '../views/Auth/Login';
import Register from '../views/Auth/Register';
import ResetPassword from '../views/Auth/ResetPassword';
import Tapfiliate from '../views/Auth/Tapfiliate/Tapfiliate';
import CoreNodeElement from '../views/CoreNodeDisplay';
import Dashboard from '../views/Dashboard/Dashboard';
import Legal from '../views/PrivacyAndTerms/Legal';
import PrivacyPolicy from '../views/PrivacyAndTerms/PrivacyPolicy';
import TermsAndConditions from '../views/PrivacyAndTerms/TermsAndConditions';
import Welcome from '../views/Welcome/Welcome';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import FormBuilder from '../views/FormBuilder/FormBuilder';
import Onboarding from '../views/SpecialRegister/Onboarding';
import SpecialRegister from '../views/SpecialRegister/SpecialRegister';
import CompanyAccept from '../views/Auth/CompanyAccept/CompanyAccept';
import AdminRegister from '../views/AdminRegister/AdminRegister';
import CompanyUserLogin from '../views/CompanyUserLogin/CompanyUserLogin';
import CoreNodeDisplayCollaboration from '../views/CoreNodeDisplayCollaboration';
import PublishedForm from '../views/PublishedForm/PublishedForm';
import AdminDashboard from '../views/AdminDashboard/AdminDashboard';
import PublicChatBot from '../components/ChatBot/PublicChatBot';
import CustomerUsagePage from '../views/AdminDashboard/CustomerPage';
import UserPlanManagementPage from '../views/AdminDashboard/UserPlanManagementPage';
import OAuthCallback from '../components/HubSpotIntegration/OAuthCallback';
export const Navigator = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route path="/" element={<PrivateRoutes />}>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/" element={<Layout />}>
          <Route path="/form/:formId" element={<FormBuilder />} />
          <Route path="/flow/:flowId" element={<CoreNodeElement />} />
          <Route
            path="/spaceFlow/:flowId"
            element={<CoreNodeDisplayCollaboration />}
          />
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/oauth-callback/hubspot" element={<OAuthCallback />} />
      </Route>
      <Route path="/admin/customer-usage" element={<CustomerUsagePage />} />
      <Route
        path="/admin/user-plan-management"
        element={<UserPlanManagementPage />}
      />

      <Route path="/" element={<PublicRoutes />}>
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/auth/register" element={<SpecialRegister />} />

        <Route path="/admin-register" element={<AdminRegister />} />
        <Route path="/company-user-login" element={<CompanyUserLogin />} />

        <Route path="/" element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:userId" element={<ResetPassword />} />
          <Route path="/confirm-account/:userId" element={<ConfirmAccount />} />
        </Route>
      </Route>

      <Route path="/company/accept-invite" element={<CompanyAccept />} />

      <Route path="/tapfiliate-invite" element={<Tapfiliate />} />
      <Route path="/published/form/:formId" element={<PublishedForm />} />
      <Route path="/chatbot/:chatId" element={<PublicChatBot />} />

      <Route path="/legal" element={<Legal />}>
        <Route
          path="/legal/terms-of-service"
          element={<TermsAndConditions />}
        />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
    </Fragment>,
  ),
);
