import React, { useState } from 'react';
import SpacesModal from './SpacesModal';
import { Button, TextField, Typography } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { useAuthStore } from '../../store/storeAuth';

interface Props {
  spaceId: string;
  onClose: () => void;
  getCompanySpace: () => void;
}

const SetFluxTokenLimitModal: React.FC<Props> = ({
  onClose,
  spaceId,
  getCompanySpace,
}) => {
  const authUser = useAuthStore(state => state.user);

  const setNotification = useNotificationStore(state => state.setNotification);

  const [tokenLimit, setTokenLimit] = useState('');

  const saveTokenLimit = async () => {
    if (!tokenLimit || isNaN(parseFloat(tokenLimit))) {
      return setNotification({
        type: NotificationType.Error,
        message: 'Token Limit must be a positive number',
      });
    }

    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      const companyId = authUser.selectedCompanyId;
      await CompanySpacesService.setTokenLimit(companyId, {
        limit: parseFloat(tokenLimit),
        spaceId,
      });

      getCompanySpace();
      setNotification({
        type: NotificationType.Success,
        message: 'Token Limit was set successfully',
      });

      onClose();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }
  };
  return (
    <SpacesModal
      modalTitle="FluxToken Limit"
      onClose={onClose}
      footer={
        <Button
          sx={{
            width: '100%',
          }}
          variant="contained"
          onClick={saveTokenLimit}
        >
          Save
        </Button>
      }
    >
      <Typography
        sx={{
          color: 'rgba(71, 84, 103, 1)',
          fontSize: '14px',
          lineHeight: '24.5px',
          marginBottom: '10px',
          marginTop: '-20px',
        }}
      >
        Limit the FluxTokens this user can generate in a month.
      </Typography>
      <TextField
        label="FluxToken Limit"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 20 }}
        onChange={e => setTokenLimit(e.target.value)}
        value={tokenLimit}
        placeholder="Enter Token Limit"
        type="number"
      />
    </SpacesModal>
  );
};

export default SetFluxTokenLimitModal;
