import { Box, Button, Typography } from '@mui/material';
import ReactConfetti from 'react-confetti';
import { ReactComponent as FluxPrompt } from '../../assets/icons/FluxPrompt.svg';
import KitInput from '../../components/UI/KitInput';
import BrandsLogin from '../../components/auth/BrandsLogin';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/storeAuth';
import { base64Decode } from '../../helpers/decodeToken';
import { AuthService } from '../../service/AuthService';
import { useForm } from 'react-hook-form';
import { LocalStorageService } from '../../service/LocalStorageService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanyService } from '../../service/CompanyService';

interface FormDataTypes {
  password: string;
}

const CompanyUserLogin = () => {
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const setNotification = useNotificationStore(state => state.setNotification);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const decodedToken = token ? base64Decode(token) : null;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataTypes>();
  const { setAccessToken } = useAuthStore(state => state);

  const acceptCompanyInvite = async (
    token: string,
    companyId: string,
    email: string,
  ) => {
    try {
      const res = await AuthService.getMe();
      const authUser = res.data;

      if (email !== authUser.email) {
        return setNotification({
          type: NotificationType.Error,
          message: 'Email is not correct',
        });
      }

      await CompanyService.acceptCompanyInvite(token);

      setAuthUser({
        ...authUser!,
        selectedCompanyId: companyId,
      });
    } catch (err) {
      let error = err as any;
      if (error.statusCode === 404) {
        setNotification({
          type: NotificationType.Success,
          message: 'You have accepted this invite.',
        });
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
  };

  const loginUser = async (values: FormDataTypes) => {
    if (!token)
      return setNotification({
        type: NotificationType.Error,
        message: 'You can not login without the correct link',
      });

    try {
      const { data } = await AuthService.login(
        decodedToken.email,
        values.password,
      );

      LocalStorageService.setItem('accessToken', data.accessToken);
      setAccessToken(data.accessToken);

      await acceptCompanyInvite(
        token,
        decodedToken.companyId,
        decodedToken.email?.toLowerCase(),
      );

      navigate('/', { replace: true, state: {} });
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <ReactConfetti
        style={{
          position: 'absolute',
          width: '100vw',
          height: '100vh',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          // height: '670px',
          width: '500px',
          padding: '32px',
          gap: '48px',
          backgroundColor: 'white',
          borderRadius: '8px',
          border: '1px solid var(--Neutral-300, #D0D5DD)',
          boxShadow: '10px 10px 40px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <FluxPrompt />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Box>
            <Typography
              fontSize={'32px'}
              fontWeight={500}
              color={'var(--Text-Secondary, #475467)'}
              lineHeight={'116.7%'}
            >
              Welcome to the {decodedToken?.companyName} FluxPrompt account
            </Typography>
          </Box>
          <Typography
            fontSize={'16px'}
            fontWeight={400}
            color={'var(--Text-Secondary, #475467)'}
            lineHeight={'175%'}
          >
            To continue, please enter you password for your account associated
            with this email address:{' '}
            <a href={`mailto:${decodedToken?.email}`}>
              {decodedToken && decodedToken.email}
            </a>
          </Typography>
        </Box>
        <form
          action="submit"
          onSubmit={handleSubmit(loginUser)}
          style={{ width: '100%' }}
        >
          <KitInput
            label="Password"
            name="password"
            type="password"
            fullWidth
            errorsStatus={errors}
            register={register}
            sxStyle={{ marginBottom: '20px' }}
            validation={{
              required: {
                value: true,
                message: 'Password is required',
              },
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },

              // pattern: {
              //   value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
              //   message:
              //     'password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
              // },
            }}
          />

          <Button type="submit" variant="contained" sx={{ width: '100%' }}>
            Login
          </Button>
        </form>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <BrandsLogin
            type={'login'}
            text="or"
            loginCallback={async () => {
              if (token) {
                return await acceptCompanyInvite(
                  token,
                  decodedToken.companyId,
                  decodedToken.email?.toLowerCase(),
                );
              }
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CompanyUserLogin;
