import { CompanySpace } from '../util/Types/CompanySpace';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import { User } from '../util/Types/User';
import { ApiService } from './ApiService';

export class CompanySpacesService extends ApiService {
  static pathName = '/company-spaces';

  static createCompanySpace: (body: {
    company: { id: string };
    name: string;
  }) => Promise<any> = (body: { company: { id: string }; name: string }) => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static updateCompanySpace = (
    companyId: string,
    body: any,
  ): Promise<IGetOneResponse<CompanySpace>> => {
    return ApiService.patch(`${this.pathName}/${companyId}`, body);
  };

  static getCompanySpaces: (
    companyId: string,
  ) => Promise<IGetAllResponse<CompanySpace>> = (companyId: string) => {
    return ApiService.get(`${this.pathName}?companyId=${companyId}`);
  };

  static getCompanySpace: (
    companyId: string,
    spaceId: string,
    query: string,
  ) => Promise<IGetOneResponse<CompanySpace>> = (
    companyId: string,
    spaceId: string,
    query: string,
  ) => {
    return ApiService.get(
      `${this.pathName}/space?spaceId=${spaceId}&companyId=${companyId}${query}`,
    );
  };

  static getSpaceById = (spaceId: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/${spaceId}`);
  };

  static setTokenLimit: (
    companyId: string,
    body: { limit: number; spaceId: string },
  ) => Promise<any> = (
    companyId: string,
    body: { limit: number; spaceId: string },
  ) => {
    return ApiService.patch(`${this.pathName}/${companyId}`, body);
  };

  static addUser = (body: {
    company: { id: string };
    user: { id: string };
    id: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/add-user`, body);
  };

  static removeUser = (body: {
    company: { id: string };
    user: { id: string };
    id: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/remove-user`, body);
  };

  static disableSpace = (spaceId: string): Promise<IGetOneResponse<any>> => {
    return ApiService.patch(`${this.pathName}/disable-space/${spaceId}`, {});
  };

  static update = (
    spaceId: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.patch(`${this.pathName}/${spaceId}`, body);
  };

  static getAllCompanyUsers = (
    spaceId: string,
  ): Promise<IGetAllResponse<User>> => {
    return ApiService.get(`${this.pathName}/users?spaceId=${spaceId}`);
  };

  static getEnabledTechnologies = (
    spaceId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/enabled-technologies?spaceId=${spaceId}`,
    );
  };

  static getDisabledTechnologies = (
    spaceId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/disabled-technologies?spaceId=${spaceId}`,
    );
  };

  static getSpaceTechnologies = (
    spaceId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/technologies?spaceId=${spaceId}`);
  };

  static enableSpaceTechnology = (body: {
    spaceId: string;
    modelName: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/enable-model`, body);
  };

  static disableSpaceTechnology = (body: {
    spaceId: string;
    modelName: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/disable-model`, body);
  };
}
