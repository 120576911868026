import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'reactflow/dist/style.css';
import { axiosInstance } from './service/ApiService';

import { LocalStorageService } from './service/LocalStorageService';
import Tap from '@tapfiliate/tapfiliate-js';

import TagManager from 'react-gtm-module';

import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_ENV !== 'local') {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    debug: true,
    dsn: 'https://3ce9aac8aaf8b620d830634aa2e615fc@o4508558351335424.ingest.us.sentry.io/4508558353301504',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      process.env.REACT_APP_API_URL as string,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

type GTMDataLayerApiErrorEvent = {
  event: string;
  errorCode: number;
  errorMessage: string;
  errorUrl: string;
};

Tap.init('47903-66403d');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      LocalStorageService.removeItem('accessToken');
    }

    // Define the error event with more details
    const errorEvent: GTMDataLayerApiErrorEvent = {
      event: 'apiError',
      errorCode: error.response.status,
      errorMessage: error.message,
      errorUrl: error.config.url,
    };

    // Push the detailed error event to the GTM data layer
    TagManager.dataLayer({
      dataLayer: [errorEvent],
    });

    return Promise.reject(error);
  },
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
