import React from 'react';
import Title from '../../../components/PublishedForm/Text/Text';
import List from '../../../components/PublishedForm/List/List';
import ShortText from '../../../components/PublishedForm/ShortText/ShortText';
import Image from '../../../components/PublishedForm/Image/Image';
import Output from '../../../components/PublishedForm/Output/Output';
import Input from '../../../components/PublishedForm/Input/Input';
import { Box, Button } from '@mui/material';
import { widthData } from '../../../components/FormNodes/TitleNode/Components/PopoverEdit';

const updateWidthBasedOnGapAndLength = (
  gapEnabled: any,
  length: number,
  widthDt: { w: string },
) => {
  const maxWidth = 1048; // Full width of the row
  const gap = 16; // Gap between items if enabled

  // Find the selected width data
  const selectedWidth = widthData.find(width => width.w === widthDt?.w);

  if (!selectedWidth) {
    throw new Error('Selected width not found');
  }

  // Calculate total gap based on the gapEnabled flag
  const totalGap = gapEnabled ? (length - 1) * gap : gap * length;

  // Ensure total gap doesn't exceed maxWidth
  const availableWidth = Math.max(maxWidth - totalGap, 0);

  // Calculate each item's width dynamically based on the selectedWidth data

  const calculatedWidth =
    availableWidth / length > selectedWidth.width
      ? selectedWidth.width
      : availableWidth / length;

  const ccwitdh =
    calculatedWidth === 516
      ? selectedWidth.width + 16 + (gapEnabled ? 16 : 32) - 32
      : calculatedWidth === 338.6666666666667
      ? selectedWidth.width + 16 + (gapEnabled ? 0 : 32) - 32
      : calculatedWidth === 500
      ? selectedWidth.width + 16 + (gapEnabled ? 0 : 32) - 32
      : Math.min(calculatedWidth, selectedWidth.width) - 32;

  return {
    ...selectedWidth,
    calculatedWidth: ccwitdh,
    gapIncluded: !gapEnabled, // Indicate if the gap was added for disabled scenarios
  };
};

const NodesPreview = ({
  nd,
  inputs,
  outputs,
  setInputs,
  setOutputs,
  onSubmit,
  groupLength,
  gapEnabled,
}: any) => {
  const { type, data, id } = nd;
  const { label } = data;

  if (type === 'title') {
    return (
      <Title
        key={id}
        data={{
          label: data?.title || '',
          fullData: data,
          style: {
            maxWidth: updateWidthBasedOnGapAndLength(
              gapEnabled,
              groupLength,
              data?.widthData,
            ).calculatedWidth,
            // position: 'absolute',
            width: '100%',

            backgroundColor: 'white',
            // left: position.x,
            // top: position.y,
          },
        }}
      />
    );
  }

  if (type === 'listNode') {
    return (
      <List
        key={id}
        input={inputs}
        setInputs={setInputs}
        data={{
          label: data?.title || '',
          fullData: data,
          question: data?.question || '',
          options: data?.options || [],
          type: data?.type || 'dropdown',
          width: '100%',

          style: {
            maxWidth: updateWidthBasedOnGapAndLength(
              gapEnabled,
              groupLength,
              data?.widthData,
            ).calculatedWidth,
            // position: 'absolute',
            backgroundColor: 'white',

            // left: position.x,
            // top: position.y,
          },
        }}
      />
    );
  }

  if (type === 'shortText') {
    return (
      <ShortText
        key={id}
        data={{
          label: data?.title,
          subtitle: data?.subtitle,
          text: data?.text,
          width: '100%',
          fullData: data,
          style: {
            maxWidth: updateWidthBasedOnGapAndLength(
              gapEnabled,
              groupLength,
              data?.widthData,
            ).calculatedWidth,
            // position: 'absolute',
            // backgroundColor: 'white',
            // left: position.x,
            // top: position.y,
          },
        }}
      />
    );
  }

  if (type === 'image') {
    return (
      <Image
        key={id}
        data={{
          label: data?.title,
          subtitle: data?.subtitle,
          fullData: data,
          width: '100%',
          url: data?.url || '',
          style: {
            maxWidth: updateWidthBasedOnGapAndLength(
              gapEnabled,
              groupLength,
              data?.widthData,
            ).calculatedWidth,
            // position: 'absolute',
            backgroundColor: 'white',
            // left: position.x,
            // top: position.y,
          },
        }}
      />
    );
  }

  if (type === 'outputDisplay') {
    return (
      <Output
        key={id}
        output={outputs?.find(
          (out: any) => out?.outputId === data?.selectedFlow,
        )}
        setOutputs={setOutputs}
        data={{
          label: data?.title,
          width: '100%',
          fullData: data,
          subtitle: data?.subtitle,
          style: {
            maxWidth: updateWidthBasedOnGapAndLength(
              gapEnabled,
              groupLength,
              data?.widthData,
            ).calculatedWidth,
            // position: 'absolute',

            // left: position.x,
            // top: position.y,
          },
        }}
      />
    );
  }

  if (type === 'inputDisplay') {
    return (
      <Input
        key={id}
        input={inputs}
        setInputs={setInputs}
        data={{
          label: data?.title,
          width: '100%',
          fullData: data,
          subtitle: data?.subtitle,
          style: {
            maxWidth: updateWidthBasedOnGapAndLength(
              gapEnabled,
              groupLength,
              data?.widthData,
            ).calculatedWidth,
            // position: 'absolute',
            backgroundColor: 'white',
            // left: position.x,
            // top: position.y,
          },
        }}
      />
    );
  }

  if (type === 'submit') {
    return (
      <Box>
        <Button onClick={() => onSubmit(nd.data)} variant="contained">
          Submit
        </Button>
      </Box>
    );
  }

  return (
    <Box
      key={id}
      sx={{
        // position: 'absolute',
        maxWidth: nd?.width ? nd?.width - 32 : 'auto',
        width: '100%',

        backgroundColor: 'white',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        mb: '16px',
        // left: position.x,
        // top: position.y,
      }}
    >
      {label}
    </Box>
  );
};
export default NodesPreview;
