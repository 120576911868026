export function stringToColor(index: number) {
  const colors = [
    '#FDA000',
    '#7828C8',
    '#F31260',
    '##3650DE',
    '#17C964',
    '#FE6E01',
    '#4527A1',
    '#B71C1B',
    '#2133BA',
    '#FECA26',
    '#7B4CFE',
    '#F05450',
    '#5169FF',
    '#2DCE89',
  ];

  return colors[index];
}
