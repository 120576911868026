import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingSpinner = ({ height = '100%', variant = 'dark' }) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
