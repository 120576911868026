import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
import { UploadImage } from '../util/Types/UploadImage';

export class ScraperService extends ApiService {
  static pathName = '/scraper';

  static scraper(
    type: string,
    body: {
      url?: any;
      query?: string;
      start_page?: number;
      pages?: number;
      domain?: string;
      flowId?: string;
    },
  ): Promise<IGetOneResponse<any>> {
    return this.post(`${this.pathName}/${type}`, body);
  }
}
