import { useCallback, useEffect, useState } from 'react';
import { UserService } from '../../../../service/UserService';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Popover,
  Typography,
} from '@mui/material';
import {
  CaretDown,
  DotsThreeOutline,
  PencilSimpleLine,
  Plus,
} from '@phosphor-icons/react';
import Space from './Space';
import { useAuthStore } from '../../../../store/storeAuth';
import CreateSpaceModal from './CreateSpaceModal';
import {
  NotificationType,
  useNotificationStore,
} from '../../../../store/storeNotifications';
import { getAPIErrorMessage } from '../../../../helpers/helpers';
import LoadingSpinner from '../../../UI/LoadingSpinner';
import { CompanySpacesService } from '../../../../service/CompanySpacesService';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';
import { useNavigate } from 'react-router-dom';
import { useSocketStore } from '../../../../store/storeSocket';
type Props = {
  onOpenModal: () => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
};

const Spaces = ({ onOpenModal, sidebarOpen, setSidebarOpen }: Props) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const navigate = useNavigate();

  const { user: authUser, setAuthUser } = useAuthStore(state => state);
  const [collapseSpace, setCollapseSpace] = useState(true);
  const [loading, setLoading] = useState(false);
  const [spacesLoading, setSpacesLoading] = useState(false);
  const [spaces, setSpaces] = useState<any[]>([]);
  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false);
  const [onHoverSpace, setOnHoverSpace] = useState(false);
  const socket = useSocketStore(state => state.socket);

  const getSpaces = useCallback(async () => {
    setSpacesLoading(true);
    try {
      const { data } = await UserService.getSpaces();
      setSpaces(data);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setSpacesLoading(false);
    }
  }, []);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (authUser?.selectedCompanyId) {
      getSpaces();
    } else {
      setSpaces([]);
    }
  }, [getSpaces, authUser?.selectedCompanyId]);

  const modifySpaceObject = async (space: any) => {
    try {
      setSpaces(
        spaces.map(s => {
          if (s.id === space.id) {
            return space;
          }
          return s;
        }),
      );

      await CompanySpacesService.updateCompanySpace(
        authUser?.selectedCompanyId!,
        {
          spaceId: space.id,
          objects: space.objects,
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (socket?.connected && authUser?.id) {
      socket.on('add-space', (resData: any) => {
        getSpaces();
      });
    }

    return () => {
      if (socket?.connected) {
        socket?.off('add-space');
      }
    };
  }, [socket, authUser?.id]);

  const modifySpaceVector = async (space: any) => {
    try {
      getSpaces();
    } catch (error) {
      console.log(error);
    }
  };

  if (spacesLoading) return <LoadingSpinner />;

  if (spaces.length === 0 && authUser?.companyRole === 'employee') {
    return <></>;
  }

  if (spaces.length === 0 && authUser?.companyRole === 'admin')
    // if (true)
    return (
      <>
        {showCreateSpaceModal && (
          <CreateSpaceModal
            onClose={() => setShowCreateSpaceModal(false)}
            getSpaces={getSpaces}
            onOpenModal={onOpenModal}
          />
        )}

        <Box
          style={{
            // display: 'flex',
            padding: '5px 5px',
            borderRadius: '4px',
            width: '100%',
            background: onHoverSpace ? '#F2F4F7' : 'transparent',
          }}
          onMouseEnter={() => setOnHoverSpace(true)}
          onMouseLeave={() => setOnHoverSpace(false)}
        >
          {onHoverSpace ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                position: 'relative',
              }}
            >
              <Typography
                style={{
                  paddingLeft: '10px',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                }}
                className="menu"
              >
                Spaces
              </Typography>
              <Box
                position={'absolute'}
                right={'10px'}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '8px',
                }}
              >
                <IconButtonTooltip
                  onClick={() => setShowCreateSpaceModal(true)}
                  title="Create a space"
                  componentProps={{
                    tooltip: {
                      sx: {
                        bgcolor:
                          authUser?.theme === 'dark' ? '#101828' : '#475467',
                      },
                    },
                    arrow: {
                      sx: {
                        color:
                          authUser?.theme === 'dark' ? '#101828' : '#475467',
                      },
                    },
                  }}
                >
                  <Plus
                    className="top-menu-icons"
                    size={16}
                    color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    weight="bold"
                    style={{ alignSelf: 'center', cursor: 'pointer' }}
                  />
                </IconButtonTooltip>

                <IconButtonTooltip
                  title="Spaces Options"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  <DotsThreeOutline size={16} color="#667085" weight="fill" />
                </IconButtonTooltip>

                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                >
                  <Box
                    sx={{
                      padding: '10px',
                      background: 'white',
                      boxShadow: '0 0 3x rgba(0,0,0,.3)',
                      border: '1px solid rgba(208, 213, 221, 1)',
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: '150px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        gap: '5px',
                        cursor: 'pointer',
                        padding: '10px 2px',
                        '&:hover': {
                          background: 'rgba(231, 240, 255, 1)',
                        },
                      }}
                      onClick={() => {
                        // setShowAddUsersModal(true);
                        // setAnchorEl(null);
                      }}
                    >
                      <PencilSimpleLine color="rgba(102, 112, 133, 1)" />
                      <Typography
                        sx={{
                          width: '100%',
                          color: 'rgba(71, 84, 103, 1)',
                        }}
                      >
                        Edit Spaces
                      </Typography>
                    </Box>
                  </Box>
                </Popover>

                <IconButtonTooltip
                  onClick={() => setCollapseSpace(!collapseSpace)}
                  title="Collapse"
                  componentProps={{
                    tooltip: {
                      sx: {
                        bgcolor:
                          authUser?.theme === 'dark' ? '#101828' : '#475467',
                      },
                    },
                    arrow: {
                      sx: {
                        color:
                          authUser?.theme === 'dark' ? '#101828' : '#475467',
                      },
                    },
                  }}
                >
                  <CaretDown
                    className="top-menu-icons"
                    size={16}
                    color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    weight="bold"
                    style={{ alignSelf: 'center', cursor: 'pointer' }}
                  />
                </IconButtonTooltip>
              </Box>
            </Box>
          ) : (
            <Divider
              sx={{
                width: '100%',
                color: authUser?.theme === 'dark' ? '#fff' : '#475467',
              }}
              textAlign="center"
            >
              <Typography
                style={{
                  paddingLeft: '10px',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                }}
                className="menu"
              >
                Spaces
              </Typography>
            </Divider>
          )}
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'rgba(71, 84, 103, 1)',
              fontSize: '12px',
              mt: '16px',
            }}
          >
            You haven’t created any spaces yet
          </Typography>
          <Button
            variant="outlined"
            sx={{
              textAlign: 'center',
              fontSize: '12px',
              mt: '18px',
              p: '4px 10px',
            }}
            onClick={() => setShowCreateSpaceModal(true)}
          >
            Add Space
          </Button>
        </Box>
      </>
    );

  return (
    <Box>
      {showCreateSpaceModal && (
        <CreateSpaceModal
          onClose={() => setShowCreateSpaceModal(false)}
          getSpaces={getSpaces}
          onOpenModal={onOpenModal}
        />
      )}

      <Box
        sx={{
          // display: 'flex',
          padding: '5px 5px',
          borderRadius: '4px',
          mt: '28px',
          width: '100%',
          background: onHoverSpace ? '#F2F4F7' : 'transparent',
        }}
        onMouseEnter={() => setOnHoverSpace(true)}
        onMouseLeave={() => setOnHoverSpace(false)}
      >
        {onHoverSpace ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              position: 'relative',
            }}
          >
            <Typography
              style={{
                paddingLeft: '10px',
                fontSize: '12px',
                fontWeight: 400,
                color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
              }}
              className="menu"
            >
              Spaces
            </Typography>
            <Box
              position={'absolute'}
              right={'10px'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {authUser?.companyRole === 'admin' && (
                <>
                  <IconButtonTooltip
                    onClick={() => setShowCreateSpaceModal(true)}
                    title="Create a space"
                    componentProps={{
                      tooltip: {
                        sx: {
                          bgcolor:
                            authUser?.theme === 'dark' ? '#101828' : '#475467',
                        },
                      },
                      arrow: {
                        sx: {
                          color:
                            authUser?.theme === 'dark' ? '#101828' : '#475467',
                        },
                      },
                    }}
                  >
                    <Plus
                      className="top-menu-icons"
                      size={16}
                      color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                      weight="bold"
                      style={{ alignSelf: 'center', cursor: 'pointer' }}
                    />
                  </IconButtonTooltip>
                  <IconButtonTooltip
                    title="Spaces Options"
                    onClick={e => setAnchorEl(e.currentTarget)}
                  >
                    <DotsThreeOutline size={16} color="#667085" weight="fill" />
                  </IconButtonTooltip>
                </>
              )}
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <Box
                  sx={{
                    padding: '10px',
                    background: 'white',
                    boxShadow: '0 0 3x rgba(0,0,0,.3)',
                    border: '1px solid rgba(208, 213, 221, 1)',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '150px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: '5px',
                      cursor: 'pointer',
                      padding: '10px 2px',
                      '&:hover': {
                        background: 'rgba(231, 240, 255, 1)',
                      },
                    }}
                    onClick={() => {
                      setAnchorEl(null);
                      onOpenModal();
                      navigate(window.location.pathname + '?space=true');
                    }}
                  >
                    <PencilSimpleLine color="rgba(102, 112, 133, 1)" />
                    <Typography
                      sx={{
                        width: '100%',
                        color: 'rgba(71, 84, 103, 1)',
                      }}
                    >
                      Edit Spaces
                    </Typography>
                  </Box>
                </Box>
              </Popover>

              <IconButtonTooltip
                onClick={() => setCollapseSpace(!collapseSpace)}
                title="Collapse"
                componentProps={{
                  tooltip: {
                    sx: {
                      bgcolor:
                        authUser?.theme === 'dark' ? '#101828' : '#475467',
                    },
                  },
                  arrow: {
                    sx: {
                      color: authUser?.theme === 'dark' ? '#101828' : '#475467',
                    },
                  },
                }}
              >
                <CaretDown
                  className="top-menu-icons"
                  size={16}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  weight="bold"
                  style={{ alignSelf: 'center', cursor: 'pointer' }}
                />
              </IconButtonTooltip>
            </Box>
          </Box>
        ) : (
          <Divider
            sx={{
              width: '100%',
              color: authUser?.theme === 'dark' ? '#fff' : '#475467',
            }}
            textAlign="center"
          >
            <Typography
              style={{
                paddingLeft: '10px',
                fontSize: '12px',
                fontWeight: 400,
                color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
              }}
              className="menu"
            >
              Spaces
            </Typography>
          </Divider>
        )}
      </Box>

      <Collapse in={collapseSpace}>
        {spaces.map(space => (
          <Box key={space.id} mb={'8px'}>
            <Space
              space={space}
              onOpenModal={onOpenModal}
              getSpaces={getSpaces}
              modifySpaceObject={modifySpaceObject}
              modifySpaceVector={modifySpaceVector}
            />
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default Spaces;
