import React, { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '../../assets/icons/Search.png';
import { DotsThreeVertical, Trash } from '@phosphor-icons/react';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { useAuthStore } from '../../store/storeAuth';

interface Props {
  spaceId: string;
  users: any[];
  getCompanySpace: () => Promise<void>;
}

const SpecificSpaceTable: React.FC<Props> = ({
  users,
  spaceId,
  getCompanySpace,
}) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const authUser = useAuthStore(state => state.user);

  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [removeOptionUserId, setRemoveOptionUserId] = useState<
    undefined | string
  >();

  const start = page * rowsPerPage;

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleRemoveUser = async () => {
    if (!removeOptionUserId) return;
    if (!authUser?.selectedCompanyId)
      return setNotification({
        type: NotificationType.Error,
        message: 'Switch to company account',
      });

    try {
      await CompanySpacesService.removeUser({
        company: { id: authUser.selectedCompanyId },
        user: { id: removeOptionUserId },
        id: spaceId,
      });

      await getCompanySpace();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }
  };

  return (
    <Box sx={{ margin: '20px 0' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px',
          paddingX: '24px',
          paddingY: '15px',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '28.8px',
            letterspacing: '0.15px',
          }}
        >
          Space Specific Table View
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <FormControl sx={{ m: 1 }} variant="standard">
            <TextField
              variant="outlined"
              placeholder="Search Specifics"
              onChange={handleInputChange}
              value={searchValue}
              sx={{
                width: '300px',
                padding: '4px, 12px, 4px, 12px',
                background: 'transparent',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  padding: '4px, 24px, 4px, 12px',
                  height: '34px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
      </Box>

      <Box sx={{ width: '96%', paddingX: '24px', paddingY: '15px' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table size={'medium'}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Spaces</TableCell>
                  <TableCell align="left">Flows</TableCell>
                  {/* <TableCell align="right">Flow Runs</TableCell> */}
                  <TableCell align="left">FluxTokens</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.slice(start, start + rowsPerPage).map(user => {
                  return (
                    <TableRow tabIndex={-1} key={user.id}>
                      <TableCell
                        sx={{
                          color: 'rgba(71, 84, 103, 1)',
                          fontSize: '14px',
                        }}
                        align="left"

                      >
                        {user.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'rgba(71, 84, 103, 1)',
                          fontSize: '12px',
                        }}
                        align="left"
                      >
                      {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                      </TableCell>

                      <TableCell
                        sx={{
                          color: 'rgba(71, 84, 103, 1)',
                          fontSize: '12px',
                        }}
                        align="left"
                      >
                        {user.spaces.join(', ')}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'rgba(71, 84, 103, 1)',
                          fontSize: '12px',
                        }}
                        align="left"
                      >
                        {user.flows}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'rgba(71, 84, 103, 1)',
                          fontSize: '12px',
                        }}
                        align="left"
                      >
                      {user.fluxTokens ? user.fluxTokens.toFixed(3) : '0'}                      
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'rgba(71, 84, 103, 1)',
                          fontSize: '12px',
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                        align="left"
                        onClick={() => {
                          setRemoveOptionUserId(
                            removeOptionUserId ? undefined : user.id,
                          );
                        }}
                      >
                        {removeOptionUserId === user.id && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '30px',
                              left: '-60px',
                              zIndex: 10000,
                              boxShadow: '2px 4px 35px 0px rgba(0, 0, 0, 0.25)',
                              background: 'white',
                              padding: '12px',
                              borderRadius: '4px',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                            onClick={handleRemoveUser}
                          >
                            <Trash color="rgba(16, 24, 40, 1)" />
                            <Typography
                              sx={{
                                color: 'rgba(16, 24, 40, 1)',
                                fontSize: '12px',
                              }}
                            >
                              Remove
                            </Typography>
                          </Box>
                        )}
                        <DotsThreeVertical
                          color={'rgba(29, 41, 57, 1)'}
                          height={'18px'}
                          size={32}
                          weight="bold"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={filteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      '& .MuiInputBase-root': {
                        margin: '0 10px',
                        marginRight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default SpecificSpaceTable;

