import { memo, useState, useEffect, useMemo, useRef } from 'react';
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useEdges,
} from 'reactflow';
import ChangeLabel from '../UI/ChangeLabel/ChangeLabel';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { useStore as useStoreReactflow } from 'reactflow';
import { CustomHandle } from '../CustomHandle';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import DeleteNode from '../UI/DeleteNode/DeleteNode';
import { useDetachNodes } from '../../util/useDetachNodes';
import { NodeProps } from '../../util/Types/NodeProps';

import { StyledNode } from '../../assets/styles/styles';
import SourceHandle from '../SourceHandle';
import { CustomSourceHandle } from '../CustomSourceHandle';
import Table from '../TableProcessor/Table';
import { CustomTargetHandle } from '../CustomTargetHandle';
import TargetHandle from '../TargetHandle';
import useFlowsStore from '../../store/storeFlows';
import { Box } from '@mui/material';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';

const outputHandleStyle = {
  position: 'absolute',
  top: '95%',
  borderRadius: '3px',
};

export const sourceTypes = {
  promptIn: 'prompt',
  contentIn: 'content',
  instructionIn: 'instruction',
  personaIn: 'persona',
};

export interface tableDataTypes {
  id: string;

  columns: {
    id: string;
    name: string;
    value: string;
    selected: boolean;
  }[];
}

function TableOutput({ id, data, isConnectable, selected }: NodeProps) {
  const defaultValues = {};
  const edges = useEdges();
  const [connectedHandle, setConnectedHandle] = useState<{
    mainSourceHandleConnected?: boolean;
    mainTargetHandleConnected?: boolean;
  }>({});

  const { setNodeState, setEdges, setSaving, flowId, setSchema } =
    useFlowsStore(state => state);

  const [isLoading, setIsLoading] = useState(false);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: null,
    name: '',
  });
  const [tableData, setTableData] = useState<tableDataTypes[]>([]);
  const [hasToExecute, setHasToExecute] = useState(true);

  const detachNodes = useDetachNodes();
  const hasParent = useStoreReactflow(
    store => !!store.nodeInternals.get(id)?.parentNode,
  );

  const onDetach = () => detachNodes([id]);

  const outputHandle = (
    <CustomSourceHandle>
      <SourceHandle
        isConnectable={isConnectable}
        nodeId={id}
        sourceName={'output'}
        position={Position.Right}
        className="custom-source-handle"
      />
    </CustomSourceHandle>
  );
  const inputHandle = (
    <Handle
      className="flow-global-handle"
      type="target"
      position={Position.Top}
      id="input"
      isConnectable={isConnectable}
    />
  );
  const outputFlowHandle = (
    <CustomSourceHandle>
      <SourceHandle
        isConnectable={isConnectable}
        nodeId={id}
        sourceName={'outputFlow'}
        position={Position.Bottom}
        className={'flow-global-handle'}
      />
    </CustomSourceHandle>
  );

  const onExecuteNode = useMemo(() => {
    const values = {
      ...defaultValues,
      ...data,
    };
    return values;
  }, [data]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit(onExecuteNode);
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, onExecuteNode, data?.canceled]);

  const onSubmit = async (e: FormData) => {
    setIsLoading(false);

    nodeDataAutoSaveDynamic({
      setEdges,
      setNodeState,
      id,
      setSaving,
      flowTriggerData: checkExecuteTrigger(data, id),
      setSchema,
    });

    setTimeout(() => {
      setHasToExecute(true);
    }, 1000);
  };

  useMemo(() => {
    // if (executeNodeData?.id) {
    //   const processedIds = executeNodeData?.arrayExecute?.processedIds;
    //   if (data?.newText) {
    //     const completed = executeNodeData?.arrayExecute?.completed || 0;
    //     const indexId = processedIds?.[completed - 1];
    //     const newUpdatedTableData = tableData.map((row, index) => {
    //       const findColumnId = row?.columns?.find(col => col.id === indexId);
    //       const findIndex = row?.columns?.findIndex(col => col.id === indexId);
    //       return {
    //         ...row,
    //         columns: row.columns.map((col, index) => {
    //           if (col?.id === findColumnId?.id && findIndex !== -1) {
    //             return {
    //               ...col,
    //               value: data?.newText,
    //             };
    //           }
    //           return col;
    //         }),
    //       };
    //     });
    //     nodeDataAutoSaveDynamic({
    //       setEdges,
    //       setNodeState,
    //       id,
    //       flowTriggerData: checkExecuteTrigger(data, id),
    //       changeType: 'execute',
    //       flowId,
    //       objectData: {
    //         tableData: newUpdatedTableData,
    //       },
    //       setSchema,
    //     });
    //   }
    // }
  }, [data.flowTrigger, data?.newText]);

  useEffect(() => {
    if (data.tableData && !isChangingDirectly) {
      setTableData(data.tableData);
    } else if (!data.tableData && !isChangingDirectly) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowId,
        setSaving,
        objectData: {
          tableData: [],
        },
        setSchema,
      });
    }
  }, [data]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
      }
    },
    [isChangingDirectly, whoIsChanging],
    1500,
  );

  const options = [
    { value: 'changeSelected', label: 'Change Selected Values' },
    { value: 'newRow', label: 'Add new Row' },
  ];

  const inputPersona = (
    <CustomTargetHandle>
      <TargetHandle
        isConnectable={isConnectable}
        nodeId={id}
        targetName="tableInput"
        style={{
          background: '#555',
        }}
        className="custom-target-handle"
        position={Position.Left}
      />
    </CustomTargetHandle>
  );

  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <StyledNode
          id={data.myId}
          bgColor="#FFE8A3"
          style={{
            border: '5px solid #FFCD29',
            outline: selected ? '1px solid red' : 'none',
          }}
        >
          <NodeToolbar>
            {hasParent && <button onClick={onDetach}>Detach</button>}
          </NodeToolbar>

          <NodeResizer isVisible={false} />
          <ChangeLabel data={data} />
          <DeleteNode nodeId={id} />
          {!data?.collapsed && (
            <>
              {/* <Form.Select name='test'>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select> */}
              <Table
                id={id}
                setIsChangingDirectly={setIsChangingDirectly}
                tableData={tableData}
                type={'output'}
                setTableData={setTableData}
                setWhoIsChanging={setWhoIsChanging}
              />
            </>
          )}
          {outputHandle}
          {inputPersona}
        </StyledNode>
      </Box>

      <CustomHandle
        isConnected={connectedHandle?.mainSourceHandleConnected}
        isLoading={isLoading}
        bottom={'-7.5px'}
        left={'90%'}
      >
        {outputFlowHandle}
      </CustomHandle>

      <CustomHandle
        left={'10%'}
        top={'-7.5px'}
        isConnected={connectedHandle?.mainTargetHandleConnected}
        isLoading={isLoading}
      >
        {inputHandle}
      </CustomHandle>
    </>
  );
}

export default memo(TableOutput);
