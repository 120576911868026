import './AiConfig.scss';

import { Box, Tooltip, Typography } from '@mui/material';
import { Image, Info, SpeakerSimpleLow, TextT } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { VoiceService } from '../../../service/VoiceService';
import { useAuthStore } from '../../../store/storeAuth';
import { User } from '../../../util/Types/User';
import ImageModal, { ImageModalHandle } from '../../AiConfigs/ImageModal';
import SuccessfulChangeModal from '../../AiConfigs/SuccessfulChangeModal';
import TextModal, { TextModalHandle } from '../../AiConfigs/TextModal';
import VoiceCloneModal from '../../AiConfigs/VoiceCloneModal';
import VoiceModal, { VoiceModalHandle } from '../../AiConfigs/VoiceModal';
import ConfigBox from './ConfigBox';

export function MP3Player({ audioUrl }: any) {
  const [audio, setaudio] = useState(audioUrl);
  useEffect(() => {
    setaudio(audioUrl);
  }, [audioUrl]);

  return (
    <Box>
      <audio controls>
        <source src={audio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </Box>
  );
}

interface TextConfigs {
  name: string;
  aiSystem: string;
  aiModel: string;
  aiTokens: string;
  aiTemperature: string;
  aiFp: string;
  aiPp: string;
}
interface AudioConfigs {
  name: string;
  voice: string;
  voiceModel: string;
  aiSystem: string;
  quality: string;
  outputFormat: string;
  speed: string;
  sampleRate: string;
}

interface ImageConfigs {
  name: string;
  aiSystem: string;
  engineId: string;
  clip_guidance_preset: string;
  sampler: string;
  width: string;
  height: string;
  cfgScale: string;
  steps: string;
  image_Strength: string;
  promptWeight: string;
  openSourceModel: string;
  openAiModels: string;
  numbersOfVariations: string;
  quality: string;
  size: string;
  negativePrompt: string;
}

interface Props {
  authUser: User;
}

const AiConfig = ({ authUser }: Props) => {
  const { configs, setConfigs, technologies } = useAuthStore(state => state);

  const [openedModal, setOpenedModal] = useState({
    text: false,
    audio: false,
    image: false,
    clone: false,
  });

  const [isEditingConfig, setIsEditingConfig] = useState({
    id: '',
    edit: false,
  });

  const [textConfigs, setTextConfigs] = useState<TextConfigs>({
    name: '',
    aiSystem: '',
    aiModel: '',
    aiTokens: '',
    aiTemperature: '',
    aiFp: '',
    aiPp: '',
  });

  const [audioConfigs, setAudioConfigs] = useState<AudioConfigs>({
    name: '',
    voice: '',
    voiceModel: '',
    aiSystem: '',
    quality: '',
    outputFormat: '',
    speed: '',
    sampleRate: '',
  });

  const [imageConfigs, setImageConfigs] = useState<ImageConfigs>({
    name: '',
    aiSystem: '',
    engineId: '',
    clip_guidance_preset: '',
    sampler: '',
    width: '',
    height: '',
    cfgScale: '',
    steps: '',
    image_Strength: '',
    promptWeight: '',
    openSourceModel: '',
    openAiModels: '',
    numbersOfVariations: '',
    quality: '',
    size: '',
    negativePrompt: '',
  });

  const textConfig = configs?.find(config => config.type === 'text');
  const audioConfig = configs?.find(config => config.type === 'voice');
  const imageConfig = configs?.find(config => config.type === 'image');
  const aiTextConfig = textConfig?.aiConfig;
  const aiAudioConfig = audioConfig?.aiConfig;
  const aiImageConfig = imageConfig?.aiConfig;

  const [confirmChanges, setConfirmChanges] = useState(false);
  const [configType, setConfigType] = useState('');
  const textModalRef = useRef<TextModalHandle>(null);
  const voiceModalRef = useRef<VoiceModalHandle>(null);
  const imageModalRef = useRef<ImageModalHandle>(null);

  const [voices, setVoices] = useState<any[]>([]);
  // const [technologies, setTechnologies] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // const fetchTechnologies = async () => {
  //   try {
  //     setIsLoading(true);
  //     if (authUser.selectedCompanyId) {
  //       const response = await CompanyService.getCompanyTechnologies(
  //         authUser.selectedCompanyId as string,
  //       );
  //       setTechnologies(response.data)
  //     } else {
  //       const response = await UserService.getTechnologies();
  //       setTechnologies(response.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchTechnologies();
  // }, [authUser]);

  const textSystems = ['anthropic', 'openAi', 'groq', 'gemini', 'perplexity'];

  const audioSystems = ['evenLabs', 'playHt'];

  const imageSystems = ['stabilityAi', 'openAi'];

  const filteredTextSystems = technologies
    ?.filter(system => textSystems.includes(system.system))
    .map(system => ({
      ...system,
      models: system?.models?.filter((model: any) => model.type === 'Text'),
    }));

  const filteredAudioSystems = technologies
    ?.filter(system => audioSystems.includes(system.system))
    .map(system => ({
      ...system,
      models: system?.models?.filter((model: any) => model.type === 'Audio'),
    }));

  const filteredImageSystems = technologies
    ?.filter(system => imageSystems.includes(system.system))
    .map(system => ({
      ...system,
      models: system?.models?.filter((model: any) => model.type === 'Image'),
    }));

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await VoiceService.getVoices();
        setVoices(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchVoices();
  }, []);

  useEffect(() => {
    setTextConfigs({
      name: textConfig?.name ?? '',
      aiSystem: textConfig?.modelType ?? '',
      ...aiTextConfig,
    });
    setAudioConfigs({
      name: audioConfig?.name ?? '',
      aiSystem: audioConfig?.modelType ?? '',
      ...aiAudioConfig,
    });
    setImageConfigs({
      name: imageConfig?.name ?? '',
      aiSystem: imageConfig?.modelType ?? '',
      ...aiImageConfig,
    });
  }, [configs]);

  const onCloseModal = (type: string) => {
    setOpenedModal({
      ...openedModal,
      [type]: false,
    });
  };

  const configsArr = [
    {
      name: 'Default Text Configuration',
      type: 'text',
      id: textConfig?.id,
      icon: (
        <TextT
          size={16}
          color={'#FFF'}
          style={{
            padding: '2px',
            backgroundColor: '#7388F7',
            borderRadius: '2px',
          }}
        />
      ),
      configTypes: [
        {
          name: 'System',
          value: 'aiSystem',
        },
        {
          name: 'AI Fp',
          value: 'aiFp',
        },
        {
          name: 'Model',
          value: 'aiModel',
        },
        {
          name: 'AI Pp',
          value: 'aiPp',
        },
        {
          name: 'Tokens',
          value: 'aiTokens',
        },
        {
          name: 'AI Temperature',
          value: 'aiTemperature',
        },
        {
          name: 'Model',
          value: 'modelName',
        },
        {
          name: 'OpenSources Model',
          value: 'openSourceModelName',
        },
        {
          name: 'Model',
          value: 'anthropicModel',
        },
        {
          name: 'Model',
          value: 'geminiModel',
        },
        {
          name: 'Max Output Tokens',
          value: 'maxOutputTokens',
        },
        {
          name: 'Top P',
          value: 'topP',
        },
        {
          name: 'Top K',
          value: 'topK',
        },
        {
          name: 'Max Length',
          value: 'maxLength',
        },
        {
          name: 'Seed',
          value: 'seed',
        },
        {
          name: 'Repetition Penalty',
          value: 'repetitionPenalty',
        },
        {
          name: 'Length Penalty',
          value: 'lengthPenalty',
        },
        {
          name: 'No Repeat Ngram Size',
          value: 'noRepeatNgramSize',
        },
        {
          name: 'Max New Tokens',
          value: 'maxNewTokens',
        },
        {
          name: 'Min New Tokens',
          value: 'minNewTokens',
        },
        {
          name: 'Max Tokens',
          value: 'maxTokens',
        },
        {
          name: 'Type',
          value: 'perplexityType',
        },
      ],
    },
    {
      name: 'Default Audio Configuration',
      type: 'audio',
      id: audioConfig?.id,
      icon: (
        <SpeakerSimpleLow
          size={16}
          color={'#FFF'}
          style={{
            padding: '2px',
            backgroundColor: '#80C683',
            borderRadius: '2px',
          }}
        />
      ),
      configTypes: [
        {
          name: 'System',
          value: 'aiSystem',
        },
        {
          name: 'Voice',
          value: 'voice',
        },
        {
          name: 'Model',
          value: 'voiceModel',
        },
        {
          name: 'Model',
          value: 'evenLabsModel',
        },
        {
          name: 'Quality',
          value: 'quality',
        },
        {
          name: 'Output Format',
          value: 'outputFormat',
        },
        {
          name: 'Speed',
          value: 'speed',
        },
        {
          name: 'Sample Rate',
          value: 'sampleRate',
        },
        {
          name: 'Voice',
          value: 'voiceId',
        },
        {
          name: 'Stability',
          value: 'stability',
        },
        {
          name: 'Similarity Boost',
          value: 'similarity_boost',
        },
        {
          name: 'Style',
          value: 'style',
        },
        {
          name: 'Use Speaker Boost',
          value: 'use_speaker_boost',
        },
      ],
    },
    {
      name: 'Default Image Configurations',
      type: 'image',
      id: imageConfig?.id,
      icon: (
        <Image
          size={16}
          color={'#FFF'}
          style={{
            padding: '2px',
            backgroundColor: '#7B4CFE',
            borderRadius: '2px',
          }}
        />
      ),
      configTypes: [
        {
          name: 'System',
          value: 'aiSystem',
        },
        {
          name: 'Engine',
          value: 'engineId',
        },
        {
          name: 'Clip Guidance',
          value: 'clip_guidance_preset',
        },
        {
          name: 'Sampler',
          value: 'sampler',
        },
        {
          name: 'Width',
          value: 'width',
        },
        {
          name: 'Height',
          value: 'height',
        },
        {
          name: 'CFG Scale',
          value: 'cfg_scale',
        },
        {
          name: 'Steps',
          value: 'steps',
        },
        {
          name: 'Image Strength',
          value: 'image_Strength',
        },
        {
          name: 'Prompt Weight',
          value: 'promptWeight',
        },
        {
          name: 'Open Source Model',
          value: 'openSourceModel',
        },
        {
          name: 'Open AI Model',
          value: 'openAiModels',
        },
        {
          name: 'Size',
          value: 'size',
        },
        {
          name: 'Quality',
          value: 'quality',
        },
        {
          name: 'Negative Prompt',
          value: 'negativePrompt',
        },
      ],
    },
  ];

  const getConfigState = (type: string | undefined) => {
    if (type === 'text') {
      return textConfigs;
    } else if (type === 'audio') {
      return audioConfigs;
    } else if (type === 'image') {
      return imageConfigs;
    }
  };

  const goBack = () => {
    setConfirmChanges(false);
    if (configType === 'text') {
      setOpenedModal({
        ...openedModal,
        text: true,
      });
    } else if (configType === 'audio') {
      setOpenedModal({
        ...openedModal,
        audio: true,
      });
    } else if (configType === 'image') {
      setOpenedModal({
        ...openedModal,
        image: true,
      });
    }
  };

  const handleYesContinue = () => {
    setConfirmChanges(false);
    if (textModalRef.current && configType === 'text') {
      textModalRef.current.submitForm();
    } else if (voiceModalRef.current && configType === 'audio') {
      voiceModalRef.current.submitForm();
    } else if (imageModalRef.current && configType === 'image') {
      imageModalRef.current.submitForm();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          height: authUser?.isUserCompany ? '67px' : '64px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          borderBottom: '1px solid #D0D5DD',
          flexShrink: 0,
          mb: '10px',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
            pb: '10px',
            gap: '8px',
          }}
        >
          Ai configurations
        </Typography>
      </Box>
      <Box
        sx={{
          paddingX: '24px',
          paddingY: '15px',
          gap: '24px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Typography color={'#475467'} fontSize={'16px'}>
            Set default system and model for generative Ai nodes.
          </Typography>
          <Tooltip
            title={'Changes will only impact new nodes you drag into the flow.'}
            placement="bottom"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#344054',
                  color: 'white',
                },
              },
              arrow: {
                sx: {
                  color: '#344054',
                },
              },
            }}
          >
            <Info size={20} />
          </Tooltip>
        </Box>
        {configsArr.map((config, i) => (
          <ConfigBox
            key={i}
            config={config}
            editConfigId={config?.id}
            configValues={getConfigState(config.type)}
            setOpenedModal={setOpenedModal}
            setIsEditingConfig={setIsEditingConfig}
            icon={config.icon}
            setConfigType={setConfigType}
            configType={config.type}
          />
        ))}
      </Box>
      <VoiceModal
        audioSystems={filteredAudioSystems}
        ref={voiceModalRef}
        isEditingConfig={isEditingConfig}
        setIsEditingConfig={setIsEditingConfig}
        show={openedModal.audio}
        onHide={() => onCloseModal('audio')}
        saveChanges={() => setConfirmChanges(!confirmChanges)}
      />

      <TextModal
        textSystems={filteredTextSystems}
        ref={textModalRef}
        isEditingConfig={isEditingConfig}
        setIsEditingConfig={setIsEditingConfig}
        show={openedModal.text}
        onHide={() => onCloseModal('text')}
        saveChanges={() => setConfirmChanges(!confirmChanges)}
      />
      <VoiceCloneModal
        show={openedModal.clone}
        onHide={() => onCloseModal('clone')}
      />

      <ImageModal
        imageSystems={filteredImageSystems}
        ref={imageModalRef}
        isEditingConfig={isEditingConfig}
        setIsEditingConfig={setIsEditingConfig}
        show={openedModal.image}
        onHide={() => onCloseModal('image')}
        saveChanges={() => setConfirmChanges(!confirmChanges)}
      />

      <SuccessfulChangeModal
        confirmChanges={confirmChanges}
        closeModal={() => setConfirmChanges(false)}
        goBack={goBack}
        onConfirm={handleYesContinue}
      />
    </Box>
  );
};

export default AiConfig;
