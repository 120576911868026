import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './AuthLayout.scss';
import { Box } from '@mui/material';
const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.from === 'register' && location.state?.token) {
      navigate(`/register?token=${location.state?.token}`, {
        replace: true,
        state: null,
      });
      return;
    }
  }, [location, navigate]);

  return (
    <Box
      className="auth-layout"
      sx={{
        display: 'flex',
      }}
    >
      <Box className="auth-layout_bg"></Box>
      <Box className="auth-layout_content">
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthLayout;
