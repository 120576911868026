import React from 'react';
import './SelectedPlan.css';
import IntroductionPlanDescription from '../../assets/images/IntroductionPlanDescription.png';
import { Button } from '@mui/material';
import { Screen, SelectedPlan } from './Welcome';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { ProductService } from '../../service/ProductService';
import IntroductionPlanMobile from '../../assets/images/IntroductionPlanMobile.png';
import IntroductionImageMobile from '../../assets/images/IntroductionImageMobile.png';

interface Props {
  goBack: () => void;
  setScreen: (screen: Screen) => void;
  setSelectedPlan: (plan: SelectedPlan) => void;
}

const Introduction: React.FC<Props> = ({
  goBack,
  setScreen,
  setSelectedPlan,
}) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const introPlanHandler = async () => {
    try {
      await ProductService.startTrial();
      setScreen(Screen.Congrats);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const skipIntroHandler = () => {
    setScreen(Screen.SelectedPlan);
    setSelectedPlan(SelectedPlan.Subscription);
  };

  return (
    <div className={'introduction-plan-container'}>
      <p className={'description'}>
        Prepare to level up your productivity as our newest community member!
      </p>

      <img
        src={IntroductionPlanDescription}
        alt="Introduction Plan"
        className={'introduction-image'}
      />

      <div className={'introduction-mobile-container'}>
        <img
          src={IntroductionPlanMobile}
          alt="Introduction Plan"
          className={'introduction-plan-mobile-image'}
        />
        <img
          src={IntroductionImageMobile}
          alt="5000 Free Tokens"
          className={'introduction-image-mobile'}
        />
      </div>

      <div className={'buttons-container'}>
        <div className={'go-back-container'}>
          <Button onClick={goBack}>Go Back</Button>
        </div>
        <div className={'actions-container'}>
          <Button variant="outlined" onClick={skipIntroHandler}>
            Skip intro plan and subscribe
          </Button>
          <Button variant="contained" onClick={introPlanHandler}>
            Continue with Intro Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
