import React, { useEffect, useState } from 'react';
import KitModal from '../UI/KitModal';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useAuthStore } from '../../store/storeAuth';
import Label from '../UI/Label/Label';

interface Props {
  onClose: () => void;
  onSave: (saveOutput: boolean, fileType: string) => void;
  savedOutput: boolean;
  savedFileType: FileType;
}

const BlueSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'rgba(54, 80, 222, 1)',
  },
}));

enum FileType {
  txt = 'txt',
  html = 'html',
  json = 'json',
  csv = 'csv',
  xml = 'xml',
  yaml = 'yaml',
  docx = 'docx',
}

const GenerateFileLinkModal: React.FC<Props> = ({
  onClose,
  onSave,
  savedOutput,
  savedFileType,
}) => {
  const { user } = useAuthStore(state => state);
  const [saveOutput, setSaveOutput] = useState(savedOutput || false);
  const [selectedFileType, setSelectedFileType] = useState(
    savedFileType || FileType.txt,
  );

  useEffect(() => {
    if (savedOutput !== undefined) setSaveOutput(savedOutput);
    if (savedFileType !== undefined) setSelectedFileType(savedFileType);
  }, [savedOutput, savedFileType]);

  const onSaveOutputChange = () => {
    setSaveOutput(prev => !prev);
  };

  const onFileTypeChange = (e: any) => {
    setSelectedFileType(e.target.value);
  };

  const saveFileLinkSettings = async () => {
    onSave(saveOutput, selectedFileType);
  };

  return (
    <KitModal
      onHide={() => {
        onClose();
      }}
      show={true}
    >
      <Box sx={{ padding: '10px 5px', minWidth: '400px' }}>
        <Typography sx={{ padding: '10px 0' }}>Generate File Link</Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
            marginBottom: '15px',
          }}
        >
          <BlueSwitch
            value={'Monthly'}
            checked={saveOutput}
            onChange={onSaveOutputChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography
            sx={{
              color: 'rgba(71, 84, 103, 1)',
              fontSize: '14px',
            }}
          >
            Save output and generate file link.
          </Typography>
        </Box>

        <Box sx={{ margin: '10px 0' }}>
          <Label
            labelName="Choose File Type"
            isAdvanced
            labelStyle={{
              fontSize: '14px',
              color:
                user?.theme === 'dark' ? '#D0D5DD' : 'rgba(71, 84, 103, 1)',
              fontWeight: 400,
            }}
          />
          <Select
            className="nodrag"
            name="selectedFileType"
            value={selectedFileType}
            onChange={onFileTypeChange}
            fullWidth
            sx={{
              width: '100%',
              '& .MuiList-root': {
                backgroundColor:
                  user?.theme === 'dark' ? 'rgba(71, 84, 103, 1)' : '#F9FAFB',
              },
              '& .MuiSvgIcon-root': {
                color:
                  user?.theme === 'dark' ? '#D0D5DD' : 'rgba(71, 84, 103, 1)',
              },

              borderRadius: '8px',
              border: '1px solid rgba(152, 162, 179, 1)',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              bgcolor:
                user?.theme === 'dark' ? 'rgba(71, 84, 103, 1)' : '#F9FAFB',
              color:
                user?.theme === 'dark' ? '#D0D5DD' : 'rgba(71, 84, 103, 1)',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .Mui-selected': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                  },
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
              },
            }}
          >
            {Object.values(FileType).map(fileType => (
              <MenuItem
                key={fileType}
                value={fileType}
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
              >
                .{fileType}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Button variant="contained" fullWidth onClick={saveFileLinkSettings}>
        Save
      </Button>
    </KitModal>
  );
};

export default GenerateFileLinkModal;
