import { Box, Switch, Typography } from '@mui/material';
import {
  CaretLeft,
  CaretRight,
  Copy,
  FileArrowDown,
  FileImage,
  FileVideo,
  ImageSquare,
  Info,
  TextAlignLeft,
} from '@phosphor-icons/react';
import InputHandle from '../InputHandle/InputHandle';
import { Position } from 'reactflow';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';
import { useState } from 'react';
import PreviewModal from '../../ImageGenerator/PreviewModal/PreviewModal';
import { onDownloadFile } from '../../../util/fileDownloader';
import { useAuthStore } from '../../../store/storeAuth';

interface Tag {
  tag: string;
  text: string;
}

const OutputImage = ({
  value,
  placeholder = '',
  labelName = '',
  previewResponses,
  onPreview,
  previewIndex,
  findColor,
  handleId = 'output',
  activeHandle = false,
  placement,
  onChangePlacement,
  switchImage,
  type = 'image',
  hasClearOnExecute = false,
  clearOnExecution,
  onChangeOnExecute,
}: any) => {
  const onCopy = () => {
    navigator.clipboard.writeText(value);
  };

  const [modal, setModal] = useState(false);
  const { user } = useAuthStore(state => state);

  return (
    <Box position={'relative'} mb={'16px'} height={'75%'}>
      <InputHandle
        activeColor={findColor?.color}
        handleId={handleId}
        isActive={activeHandle}
        position={Position.Right}
        type="source"
      />

      {placement === 'Prompt' && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,

            '& :hover': {
              '&.change-placement': {
                border: '1px solid ' + findColor?.color,
                '& svg': {
                  fill: findColor?.color,
                },
              },
            },
          }}
          onClick={() => onChangePlacement?.('placement', 'Output')}
        >
          <Box
            className="change-placement"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              alignSelf: 'center',
              border: `1px solid ${activeHandle ? findColor.color : '#98A2B3'}`,
              maxWidth: 'fit-content',
            }}
          >
            <TextAlignLeft
              size={16}
              color={activeHandle ? findColor.color : '#98A2B3'}
            />
          </Box>
          <Typography
            fontSize={'12px'}
            color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
          >
            {labelName?.split(' ')[0]}
          </Typography>
        </Box>
      )}

      {placement !== 'Prompt' && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              position: 'relative',
              '& svg': { cursor: 'pointer' },
              mb: '8px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                fontSize={'16px'}
                color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                fontWeight={600}
              >
                {labelName}
              </Typography>
              <TextAlignLeft size={16} color="#98A2B3" />
            </Box>
            {hasClearOnExecute && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Switch
                  size="small"
                  onChange={onChangeOnExecute}
                  checked={clearOnExecution}
                />
                <IconButtonTooltip title={'Clear  output upon run'}>
                  <Info color="#98A2B3" size={12} />
                </IconButtonTooltip>
              </Box>
            )}
          </Box>
          <>
            {type === 'image' && (
              <Box
                sx={{
                  borderRadius: '4px',
                  border: value ? '1px solid #98A2B3' : '',

                  backgroundImage: value
                    ? `url("${value.url ?? value}")`
                    : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  padding: '15px 8px',

                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',

                  height: placement === 'Output' ? '480px' : '75%',
                  width: placement === 'Output' ? '680px' : '360px',
                  maxHeight: '480px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (value) {
                    setModal(true);
                  }
                }}
              >
                {!value && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={'8px'}
                  >
                    <FileImage color="#98A2B3" size={32} />
                    <Typography
                      fontSize={'14px'}
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#98A2B3'}
                    >
                      Your images will be generated here
                    </Typography>
                  </Box>
                )}
              </Box>
            )}

            {type === 'video' && (
              <>
                {
                  <Box
                    sx={{
                      borderRadius: '4px',
                      border: value ? '1px solid #98A2B3' : '',

                      backgroundImage: value
                        ? ''
                        : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      padding: '15px 8px',

                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',

                      height: placement === 'Output' ? '480px' : '75%',
                      width: placement === 'Output' ? '680px' : '360px',
                      maxHeight: '480px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {!value && (
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={'8px'}
                      >
                        <FileVideo color="#98A2B3" size={32} />
                        <Typography fontSize={'14px'} color={'#98A2B3'}>
                          Your animations will be generated here
                        </Typography>
                      </Box>
                    )}

                    {value && (
                      <video
                        style={{
                          borderRadius: '4px',
                        }}
                        width="100%"
                        height="100%"
                        controls
                        key={value}
                      >
                        <source src={value} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </Box>
                }
              </>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              mt={'8px'}
            >
              <Box style={{}}>
                {previewResponses?.length !== 0 && (
                  <Box display={'flex'} alignItems={'center'} gap={''}>
                    <CaretLeft
                      fontSize={16}
                      cursor={'pointer'}
                      color="#667085"
                      onClick={() => onPreview('prev')}
                    />
                    <Typography
                      lineHeight={'166%'}
                      fontSize={'12px'}
                      color={'#667085'}
                      m={'0'}
                      p="0"
                      mb={'-2px'}
                    >
                      {previewIndex !== undefined
                        ? previewIndex + 1
                        : previewResponses?.length}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        -
                      </span>
                      {previewResponses?.length}
                    </Typography>
                    <CaretRight
                      fontSize={16}
                      color="#667085"
                      cursor={'pointer'}
                      onClick={() => onPreview('next')}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                {switchImage && (
                  <IconButtonTooltip
                    onClick={() => switchImage(value)}
                    title="Use image as input"
                  >
                    <ImageSquare color="#98A2B3" size={16} />
                  </IconButtonTooltip>
                )}
                <IconButtonTooltip
                  onClick={() => onDownloadFile(value, 'image')}
                  title="Download Image"
                >
                  <FileArrowDown color="#98A2B3" size={16} />
                </IconButtonTooltip>

                <IconButtonTooltip onClick={onCopy} title="Copy image url">
                  <Copy color="#98A2B3" size={16} />
                </IconButtonTooltip>
              </Box>
            </Box>
          </>
        </>
      )}

      <PreviewModal
        onHide={() => {
          setModal(false);
        }}
        responses={previewResponses}
        active={value}
        show={modal}
      />
    </Box>
  );
};

export default OutputImage;
