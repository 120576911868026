import React, { useEffect } from 'react';
import { useNotificationStore } from '../../store/storeNotifications';
import { useSnackbar } from 'notistack';

const Notification = () => {
  const { notification } = useNotificationStore((state: any) => state);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification?.message) {
      const duration = notification.duration !== undefined ? notification.duration : 3000;
// console.log("duration:", duration);

      enqueueSnackbar(notification.message, {
        variant: notification.type,
        autoHideDuration: duration,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }
  }, [notification, enqueueSnackbar]);

  return null;
};

export default Notification;
