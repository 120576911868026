import {
  Box,
  Button,
  Collapse,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { UploadService } from '../../../../service/UploadService';
import { DocumentService } from '../../../../service/DocumentService';
import { useAuthStore } from '../../../../store/storeAuth';
import {
  CaretDown,
  Download,
  Eye,
  Plus,
  Upload,
  Trash,
  CaretRight,
  SpinnerGap,
} from '@phosphor-icons/react';
import axios from 'axios';
import { pdfjs } from 'react-pdf';

import FilesModal from './FileModal/FilesModal';
import { ReactComponent as DotsThreeOutlineGrayIcon } from '../../../../assets/icons/DotsThreeOutlineGrayIcon.svg';
import { ReactComponent as DotsThreeOutlineWhiteIcon } from '../../../../assets/icons/DotsThreeOutlineWhiteIcon.svg';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';
import Tesseract from 'tesseract.js';
import { franc } from 'franc';
import { iso6393 } from 'iso-639-3';

type Props = {
  setNotification: (notification: any) => void;
  removeNotification: () => void;
  sidebarOpen: boolean;
  handleCloseSideBarMobile: () => void;
  spaceId?: string;
};

const Uploads = ({
  setNotification,
  removeNotification,
  sidebarOpen,
  handleCloseSideBarMobile,
  spaceId,
}: Props) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/build/pdf.worker.min.mjs',
  //   import.meta.url,
  // ).toString();
  pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

  const [onOpenPreviewFile, setOnOpenPreviewFile] = useState<{
    isOpen: boolean;
    src: string;
    name: string;
    id: string;
    generatedText?: string;
    language?: string;
  }>({
    isOpen: false,
    src: '',
    name: '',
    id: '',
    generatedText: '',
    language: '',
  });
  const {
    user: authUser,
    documents,
    setDocuments,
  } = useAuthStore(state => state);

  const [isMyFilesOpen, setIsMyFilesOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [clickedUploadId, setClickedUploadId] = useState<string | null>(null);
  const [spaceDocuments, setSpaceDocuments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setClickedUploadId(id === clickedUploadId ? null : id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedUploadId(null);
  };
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const handleClickAdd = (event: any) => {
    event.stopPropagation();
    uploadFileRef.current?.click();
  };

  useEffect(() => {
    if (spaceId && isMyFilesOpen) {
      getDocuments(spaceId);
    }
  }, [spaceId, isMyFilesOpen]);

  const getDocuments = async (spaceId?: string) => {
    try {
      if (spaceId && isMyFilesOpen) {
        const res = await DocumentService.getSpaceDocuments(spaceId);
        setSpaceDocuments(res?.data);
        return;
      }

      const res = await DocumentService.getDocuments();
      setDocuments(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [language, setLanguage] = useState<string>(
    localStorage.getItem('language') ?? 'eng',
  );

  const identifyImagePages = async (pdf: any): Promise<number[]> => {
    const imagePages: number[] = [];

    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber);
      const operatorList = await page.getOperatorList();
      const textContent = await page.getTextContent();

      const hasImages = operatorList.fnArray.some(
        (fn: any) =>
          fn === pdfjs.OPS.paintImageXObject ||
          fn === pdfjs.OPS.paintInlineImageXObject ||
          fn === pdfjs.OPS.paintImageMaskXObject,
      );

      if (hasImages) {
        imagePages.push(pageNumber);
      }
    }

    return imagePages;
  };

  const detectLanguage = (text: string) => {
    const detectedLang = franc(text);
    const languageName =
      iso6393.find(lang => lang.iso6393 === detectedLang)?.name || 'Unknown';
    setLanguage(languageName);
  };

  const recognizeTextFromImage = async (image: string, language: string) => {
    const worker = await Tesseract.createWorker(language, 1, {
      corePath: '/tesseract-core.wasm.js',
      workerPath: '/worker.min.js',
      logger: function (m) {
        console.log(m);
      },
    });

    const {
      data: { text },
    } = await worker.recognize(image);
    await worker.terminate();
    return text;
  };

  const onUploadDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      const file = e?.target?.files?.[0];
      if (!file) return;
      // console.log("Picked File: ", file);
      setNotification({
        type: 'info',
        message: 'Uploading the PDF',
      });

      const formData = new FormData();
      formData.append('file', file);

      const res = await UploadService.uploadFile(formData);
      // console.log("Uploaded File Response: ", res);
      const originalFileName =
        file.name.split('.').slice(0, -1).join('.') || 'file';
      const uniqueSuffix = Date.now();
      let extractedText = '';

      if (file.type === 'application/pdf') {
        const arrayBuffer = await file.arrayBuffer();
        const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
        const pdf = await loadingTask.promise;

        const imagePages = await identifyImagePages(pdf);
        const totalPages = pdf.numPages;
        // console.log("Total Number of Pages: ", totalPages);

        const textPromises = Array.from(
          { length: totalPages },
          async (_, i) => {
            const pageNumber = i + 1;
            // console.log(`Processing page ${pageNumber}`);
            if (imagePages.includes(pageNumber)) {
              // console.log(`Page ${pageNumber} has images, converting to image and extracting text`);
              const page = await pdf.getPage(pageNumber);
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement('canvas');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const context = canvas.getContext('2d');
              if (context) {
                // console.log("Extracting text from image");
                await page.render({ canvasContext: context, viewport }).promise;
                const image = canvas.toDataURL('image/png');
                const text = await recognizeTextFromImage(image, 'eng');
                // const { data: { text } } = await worker.recognize(image);
                // await worker.terminate();
                detectLanguage(text);
                return `Page ${pageNumber}:\n${text}`;
              }
            } else {
              // console.log(`Page ${pageNumber} has no images, extracting text directly`);
              const page = await pdf.getPage(pageNumber);
              const textContent = await page.getTextContent();
              const text = textContent.items
                .map((item: any) => item.str)
                .join(' ');
              return `Page ${pageNumber}:\n${text}`;
            }
          },
        );

        const texts = await Promise.all(textPromises);
        extractedText = texts.join('\n');

        // console.log('Extracted Text:', extractedText);

        const blob = new Blob([extractedText], { type: 'text/plain' });
        const txtFile = new File(
          [blob],
          `extracted-pdf-${originalFileName}-${uniqueSuffix}.txt`,
          { type: 'text/plain' },
        );

        const txtFormData = new FormData();
        txtFormData.append('file', txtFile);

        const txtUploadRes = await UploadService.uploadFile(txtFormData);
        // console.log("Text Upload Service Response: ", txtUploadRes);

        await DocumentService.createDocument({
          name: txtUploadRes.data.name,
          src: txtUploadRes.data.src,
          spaceId: spaceId!,
        });
      } else {
        await DocumentService.createDocument({
          name: `extracted-pdf-${originalFileName}-${uniqueSuffix}`,
          src: res.data.src,
          spaceId: spaceId!,
        });
      }

      getDocuments(spaceId);
      setIsLoading(false);
      setNotification({
        type: 'success',
        message: 'File uploaded and processed successfully',
      });
    } catch (error: any) {
      setIsLoading(false);
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
    } finally {
      e.target.value = '';
      e.target.files = null;
    }
  };

  const onDeleteDocument = async (id: string) => {
    try {
      const result = window.confirm('Are you sure you want to delete file?');

      if (!result) return;

      await DocumentService.deleteOne(id, spaceId);
      getDocuments(spaceId);

      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreviewFile = (src: string, name: string, id: string) => {
    handleClose();
    if (src.includes('.txt') || src.includes('csv')) {
      axios
        .get(`${src}`)
        .then(response => {
          const text = response.data;
          const formattedText = text.replace(
            /Page \d+: /g,
            '<br /><br />Page ',
          );
          setOnOpenPreviewFile({
            isOpen: true,
            src,
            name,
            id,
            generatedText: formattedText,
            language: language,
          });
        })
        .catch(error => console.error('Error fetching TXT file:', error));
      return;
    }

    setOnOpenPreviewFile({
      isOpen: true,
      src,
      name,
      id,
    });
  };

  const handleDownload = (src: string, name: string) => {
    handleClose();
    const link = document.createElement('a');

    axios
      .get(src, {
        responseType: 'blob',
      })
      .then(res => {
        link.href = URL.createObjectURL(res.data);
        link.download = name;
        link.click();
      })
      .finally(() => {
        link.remove();
      });
  };

  const docs = spaceId ? spaceDocuments || [] : documents || [];

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        role="button"
        sx={{ display: 'none' }}
      >
        <input
          type="file"
          id="content-upload-file"
          hidden
          ref={uploadFileRef}
          onChange={e => onUploadDocument(e)}
        />
      </Box>

      {/** Uploaded files list */}
      <Box
        className="menu menu--on-hover"
        sx={{
          display: 'flex',
          marginLeft: '14px',
          // width: '14.6rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          justifyContent: 'space-between',
          paddingRight: '4px',
          paddingBottom: '4px',
          '&:hover': {
            backgroundColor: authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
            borderRadius: '4px',
          },
        }}
      >
        {isLoading ? (
          <>
            <Box
              sx={{
                display: 'flex',
                columnGap: '8px',
                alignItems: 'center',
                paddingLeft: '4px',
              }}
            >
              <SpinnerGap
                className="active-menu rotating-icon"
                size={20}
                weight="regular"
                color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
              />

              {sidebarOpen && (
                <>
                  <Typography
                    className="text-capitalize active-menu"
                    sx={{
                      color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      fontStyle: 'italic',
                    }}
                  >
                    Uploading the pdf
                  </Typography>
                </>
              )}
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                columnGap: '8px',
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '24px',
                paddingRight: '4px',
                paddingLeft: '4px',
              }}
              onClick={() => setIsMyFilesOpen(!isMyFilesOpen)}
            >
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '8px',
                  alignItems: 'center',
                }}
              >
                <Upload
                  className="active-menu"
                  size={20}
                  weight="regular"
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />

                {sidebarOpen && (
                  <>
                    <Typography
                      className="text-capitalize active-menu"
                      sx={{
                        color:
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                      }}
                    >
                      Uploads
                    </Typography>
                  </>
                )}
              </Box>
              <Box sx={{ display: 'flex' }}>
                <IconButtonTooltip
                  title="Upload File"
                  onClick={event => handleClickAdd(event)}
                >
                  <Plus
                    size={16}
                    className="menu-icons active-menu"
                    style={{
                      color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                      cursor: 'pointer',
                      marginRight: '8px',
                    }}
                  />
                </IconButtonTooltip>

                {sidebarOpen && (
                  <IconButtonTooltip
                    title="Collapse"
                    onClick={() => setIsMyFilesOpen(!isMyFilesOpen)}
                  >
                    {!isMyFilesOpen ? (
                      <CaretDown
                        className="active-menu"
                        size={16}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                    ) : (
                      <CaretRight
                        className="active-menu"
                        size={16}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                    )}
                  </IconButtonTooltip>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Collapse
        timeout={500}
        in={isMyFilesOpen}
        sx={{
          overflowY: 'auto',
        }}
      >
        <Box
          gap={'8px'}
          sx={{
            paddingLeft: sidebarOpen ? '45px' : '',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {docs?.map(document => (
            <Box
              key={document?.id}
              className="menu menu--on-hover"
              sx={{
                maxWidth: '265px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': {
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                  borderRadius: '4px',
                },
              }}
            >
              {/* <Tooltip title={document.name} > */}
              <Typography
                style={{
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                  fontSize: '16px',
                  margin: '0',
                  marginBottom: '5px',
                  width: '12.5rem',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                title={document?.name}
                onClick={() => {
                  handleCloseSideBarMobile();
                  handlePreviewFile(
                    document?.src,
                    document?.name,
                    document?.id,
                  );
                }}
              >
                {document?.name}
              </Typography>

              {/* </Tooltip> */}

              <IconButton
                size="medium"
                key={document?.id}
                onClick={e => {
                  handleClick(e, document?.id);
                }}
              >
                {authUser?.theme === 'dark' ? (
                  <DotsThreeOutlineWhiteIcon />
                ) : (
                  <DotsThreeOutlineGrayIcon />
                )}
              </IconButton>
              <Popover
                open={clickedUploadId === document?.id}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: '8px',
                    background:
                      authUser?.theme === 'dark' ? '#101828' : '#FFFF',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      px: '8px',
                      gap: '8px',
                      color: '#EAECF0',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() =>
                      handlePreviewFile(
                        document?.src,
                        document?.name,
                        document?.id,
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Eye
                        size={20}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                      <Typography
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'
                        }
                      >
                        View
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      px: '8px',
                      gap: '8px',
                      color: '#EAECF0',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() =>
                      handleDownload(document?.src, document?.name)
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Download
                        size={20}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                      <Typography
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'
                        }
                      >
                        Download
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      color: '#b91c1c',
                      px: '8px',
                      gap: '8px',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? '#FFADB7' : '#fecdd3',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() => onDeleteDocument(document?.id)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Trash size={20} color="#b91c1c" />
                      <Typography>Delete Upload</Typography>
                    </Box>
                  </Button>
                </Box>
              </Popover>
            </Box>
          ))}
        </Box>
      </Collapse>
      <FilesModal
        onHide={() => {
          setOnOpenPreviewFile({
            isOpen: false,
            src: '',
            name: '',
            id: '',
            generatedText: '',
            language: '',
          });
        }}
        show={onOpenPreviewFile.isOpen}
        onOpenPreviewFile={onOpenPreviewFile}
      />
    </>
  );
};

export default Uploads;
