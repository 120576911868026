import React, { useEffect, useState } from 'react';
import { FlowService } from '../../service/FlowService';
import { Box, Typography } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import FlowsTable from './FlowsTable';

const RecentlyOpened: React.FC = () => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const [recentFlows, setRecentFlows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getRecentFlows = async () => {
      try {
        const { data } = await FlowService.lastSeen(page);

        setRecentFlows(data);
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
      setLoading(false);
    };

    getRecentFlows();
  }, []);

  if (loading) return <LoadingSpinner />;

  if (!loading && recentFlows.length === 0)
    return (
      <Box
        sx={{
          border: '1px dashed rgba(208, 213, 221, 1)',
          width: '450px',
          height: '225px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
          }}
        >
          You don’t have any recently opened files
        </Typography>
      </Box>
    );

  return <FlowsTable flows={recentFlows} />;
};

export default RecentlyOpened;
