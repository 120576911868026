import {
  Box,
  Button,
  FormLabel,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useRef, useState } from 'react';
import KitModal from '../../UI/KitModal';
import {
  CheckCircle,
  Copy,
  Images,
  PaintBrush,
  UploadSimple,
} from '@phosphor-icons/react';
import { UploadService } from '../../../service/UploadService';
import { FlowService } from '../../../service/FlowService';
import { useAuthStore } from '../../../store/storeAuth';
import { useNotificationStore } from '../../../store/storeNotifications';
import LoadingSpinner from '../../UI/LoadingSpinner';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

type Props = {
  onHide: () => void;
  show: boolean;
  chatData: any;
  setFlow: (arg0: any) => void;
};
const steps = [
  'Add Name and Description',
  'Add Actions',
  'Chat Personalization',
  'Add logo',
];

const PublishChatModal = ({ onHide, show, chatData, setFlow }: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const setNotification = useNotificationStore(state => state.setNotification);
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const user = useAuthStore(state => state.user);
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState({
    name: chatData.name || '',
    chatDescription: chatData.chatDescription || '',
    logo: chatData.logo || '',
    backgroundColor: chatData.backgroundColor || '#fff',
    chatActions: chatData.chatActions?.length
      ? chatData.chatActions
      : [
          {
            name: 'Action 1',
            description: '',
          },
          {
            name: 'Action 2',
            description: '',
          },
          {
            name: 'Action 3',
            description: '',
          },
        ],
    chatImg: chatData.chatImage || '',
  });

  const isStepOptional = (step: number) => {
    return false;
  };

  const ref = useRef<HTMLInputElement>(null);

  const handleCoverUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    try {
      const file = e.target.files![0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);

      setFormData(prev => ({ ...prev, logo: response.data.src }));
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Failed to upload image',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const onChange = (e: any) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onChangeChatAction = (e: any, index: number) => {
    const { value, name } = e.target;

    const newActions = formData.chatActions.map((action: any, idx: any) => {
      if (idx === index) {
        return {
          ...action,
          [name]: value,
        };
      }
      return action;
    });

    setFormData({
      ...formData,
      chatActions: newActions,
    });
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const newRef = useRef<HTMLInputElement>(null);

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === 4) {
      try {
        const response = await FlowService.updateFlow(chatData.id, {
          name: formData.name,
          chatActions: formData.chatActions,
          chatDescription: formData.chatDescription,
          chatImage: formData.chatImg,
          logo: formData.logo,
          backgroundColor: formData.backgroundColor,
          public: true,
        });

        setFlow({
          ...chatData,
          name: formData.name,
          chatActions: formData.chatActions,
          chatDescription: formData.chatDescription,
          chatImage: formData.chatImg,
          logo: formData.logo,
          backgroundColor: formData.backgroundColor,
          public: true,
        });
      } catch (error) {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        setNotification({
          type: 'error',
          message: 'Failed to update flow',
        });
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    setUploadingLoading(true);

    const formData = new FormData();
    formData.append('file', file!);

    try {
      const res = await UploadService.uploadFile(formData);

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        chatImg: res?.data?.src,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setUploadingLoading(false);
    }
  };

  const onChangeBackgroundColor = (e: any) => {
    setFormData({
      ...formData,
      backgroundColor: e.target.value,
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const [selectedCode, setSelectedCode] = useState<'script' | 'iframe'>(
    'script',
  );

  const iframeCode = `<iframe src='${window.location.origin}/chatbot/${chatData.id}' height='800px' width='600px' style="border: none;"></iframe>`;
  const scriptCode = `<script src='${window.location.origin}/embed-script.js'></script>
<flux-prompt-chat src='${window.location.origin}/chatbot/${chatData.id}' width="600px" height="800px"></flux-prompt-chat>`;

  return (
    <KitModal
      sx={{
        '.MuiPaper-root': {
          maxWidth: '1050px',
          width: '100%',
          height: activeStep === 4 ? 'fit-content' : '700px',
        },
        '& .MuiDialogContent-root': {
          padding: '24px',
        },
      }}
      footer={
        <Box
          display={'flex'}
          gap={2}
          p={'0 20px 20px 20px'}
          width={'100%'}
          justifyContent={'end'}
          sx={{
            position: 'absolute',
            bottom: '0',
          }}
        >
          {activeStep !== 5 && (
            <>
              {activeStep !== 0 && (
                <Button variant="outlined" sx={{}} onClick={handleBack}>
                  Go back to previous step
                </Button>
              )}
              <Button variant="contained" sx={{}} onClick={handleNext}>
                {activeStep === steps.length ? 'Publish' : 'Continue'}
              </Button>
            </>
          )}{' '}
        </Box>
      }
      show={show}
      onHide={onHide}
    >
      <Box>
        {activeStep !== 4 && (
          <Box
            sx={{
              p: '10px',
              bgcolor: '#E7F0FF',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <PaintBrush color="#3650DE" size={32} />
          </Box>
        )}
        {activeStep !== steps.length && activeStep !== 4 && (
          <>
            <Typography
              variant="h6"
              sx={{
                mt: '16px',
                color: '#101828',
                fontSize: '24px',
                fontWeight: 500,
              }}
            >
              Customize Fluxbot
            </Typography>
            <Typography
              sx={{
                mt: '16px',
                color: '#101828',
                fontSize: '20px',
                fontWeight: 400,
              }}
            >
              To publish your Fluxbot you need to customize it first.
            </Typography>
          </>
        )}

        <Box mt={'40px'}>
          {activeStep !== steps.length && activeStep !== 4 && (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step sx={{}} key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          {activeStep === 5 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
                marginTop: '40px',
              }}
            >
              <CheckCircle weight="fill" color={'#4CAF50'} size={100} />

              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
              >
                <Typography
                  sx={{
                    color: '#101828',
                    fontSize: '20px',
                    fontWeight: 500,
                  }}
                >
                  You have succesfully published your bot!
                </Typography>
                <Typography
                  sx={{
                    color: '#101828',
                    fontSize: '20px',
                    fontWeight: 400,
                  }}
                >
                  This is the generated link. Copy and share this link to share
                  your Fluxbot to anyone you want.
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/chatbot/${chatData.id}`,
                    );

                    setNotification({
                      type: 'success',
                      message: 'Link copied to clipboard',
                      duration: 3000,
                    });
                  }}
                >
                  <Typography
                    sx={{
                      color: '#475467',
                      fontSize: '20px',
                    }}
                  >
                    {window.location.origin}/chatbot/{chatData.id}
                  </Typography>
                  <Copy size={24} color={'#3650DE'} />
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={selectedCode}
                    onChange={() => {
                      setSelectedCode(prev =>
                        prev === 'script' ? 'iframe' : 'script',
                      );
                    }}
                  >
                    <Tab label="Script" value="script" />
                    <Tab label="Iframe" value="iframe" />
                  </Tabs>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    selectedCode === 'script'
                      ? navigator.clipboard.writeText(scriptCode)
                      : navigator.clipboard.writeText(iframeCode);
                    setNotification({
                      type: 'success',
                      message: 'Code copied to clipboard',
                      duration: 3000,
                    });
                  }}
                >
                  <SyntaxHighlighter
                    language="html"
                    style={stackoverflowLight}
                    customStyle={{
                      fontSize: '16px',
                      border: '1px solid #47546760',
                      padding: '12px',
                      flex: 1,
                      margin: '0',
                    }}
                    wrapLongLines
                  >
                    {selectedCode === 'script' ? scriptCode : iframeCode}
                  </SyntaxHighlighter>
                  <Copy size={24} color={'#3650DE'} />
                </Box>
              </Box>
            </Box>
          )}
          {activeStep === steps.length ? (
            <Box>
              <Typography
                sx={{
                  mt: '16px',
                  color: '#101828',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                You have succesfully customized your Fluxbot. This is how it
                will show up to the user.
              </Typography>
              <Typography
                sx={{
                  mt: '16px',
                  color: '#101828',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                Are you sure you want to publish it?.
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#F9FAFB',
                  p: '40px',
                  mt: '16px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: '16px',
                  }}
                >
                  <Box
                    component={'img'}
                    src={formData.logo}
                    alt=""
                    sx={{
                      borderRadius: '12px',
                      width: '80px ',
                      height: '80px ',
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    mt: '8px',
                    fontSize: '32px',
                    fontWeight: 700,
                    textAlign: 'center',
                    color: user?.theme === 'dark' ? '#fff' : '#000',
                    mb: '16px',
                  }}
                  variant="h4"
                >
                  {formData.name}
                </Typography>

                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 400,
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                    maxWidth: '700px',
                    letterSpacing: '0.15px',
                    margin: '0 auto',
                    lineHeight: '28.8px',
                    textAlign: 'center',
                  }}
                  variant="body1"
                >
                  {formData.chatDescription}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box mt={'40px'}>
              {activeStep === 0 && (
                <>
                  <TextField
                    defaultValue={chatData.name}
                    label="Add Name"
                    value={formData.name}
                    name="name"
                    onChange={onChange}
                    size="medium"
                    variant="outlined"
                  />

                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#475467',
                      mt: '10px',
                    }}
                  >
                    Add Description
                  </Typography>
                  <TextField
                    name="chatDescription"
                    defaultValue={chatData?.description}
                    value={formData.chatDescription}
                    fullWidth
                    multiline
                    onChange={onChange}
                  />
                </>
              )}

              {activeStep === 1 && (
                <Box>
                  {formData.chatActions.map((action: any, index: number) => (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '40px',
                        alignItems: 'center',
                        mb: '10px',
                        width: '100%',
                      }}
                    >
                      <TextField
                        label="Add Name"
                        value={action.name}
                        name={'name'}
                        onChange={e => onChangeChatAction(e, index)}
                        size="medium"
                        variant="outlined"
                      />

                      <Box
                        sx={{
                          maxWidth: '90%',
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: '#475467',
                          }}
                        >
                          Add Description
                        </Typography>
                        <TextField
                          name="description"
                          value={action.description}
                          fullWidth
                          sx={{
                            width: '100%',
                          }}
                          multiline
                          onChange={e => onChangeChatAction(e, index)}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

              {activeStep === 3 && (
                <Box
                  sx={{
                    mt: '16px',
                    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                    onClick={e => {
                      ref.current?.click();
                    }}
                  >
                    <input
                      onChange={handleCoverUpload}
                      ref={ref}
                      id={'unique'}
                      type="file"
                      hidden
                    />

                    {isUploading && <LoadingSpinner />}
                    {formData?.logo ? (
                      <>
                        <img
                          width={80}
                          height={80}
                          src={formData?.logo}
                          style={{
                            borderRadius: '12px',
                          }}
                          alt=""
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          p: '20px',
                          background: ` var(--Blue, linear-gradient(90deg, #142BFF 0%, #396AFC 100%))`,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 'fit-content',
                          borderRadius: '4px',
                          position: 'relative',
                        }}
                      >
                        <>
                          <Images size={40} color={'#fff'} />
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: '5px',
                              right: '5px',
                            }}
                          >
                            <UploadSimple color="#fff" size={13} />
                          </Box>
                        </>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {activeStep === 2 && (
                <div>
                  <TextField
                    type="color"
                    value={formData?.backgroundColor}
                    onChange={onChangeBackgroundColor}
                    defaultValue={formData?.backgroundColor}
                    label="Background Color"
                    fullWidth
                    style={{
                      maxWidth: '15%',
                    }}
                  />
                  <div
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    {uploadingLoading && (
                      <Box>
                        <LoadingSpinner height="200px" />
                      </Box>
                    )}

                    {!formData?.chatImg && (
                      <FormLabel
                        htmlFor={'image-upload-file'}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          p: '24px',
                          border: '1px solid #EAECF0',
                          flexDirection: 'column',
                        }}
                      >
                        <UploadSimple
                          size={'32px'}
                          // color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                        />

                        <Typography
                          sx={{
                            fontSize: '14px',
                            color: '#98A2B3',
                            fontWeight: 600,
                          }}
                        >
                          {'Click here to upload an Image.'}
                        </Typography>
                      </FormLabel>
                    )}

                    {formData?.chatImg && (
                      <img
                        style={{
                          maxWidth: '350px',

                          width: '100%',
                          height: '100%',
                        }}
                        src={formData?.chatImg}
                        alt="test"
                      />
                    )}

                    <input
                      id={'image-upload-file'}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />

                    {formData?.chatImg && (
                      <Box>
                        <Box display={'flex'} gap={'10px'}>
                          <Button
                            onClick={() => {
                              setFormData({
                                ...formData,
                                chatImg: '',
                              });
                            }}
                          >
                            Delete
                          </Button>

                          <Button
                            onClick={() => {
                              document
                                .getElementById('image-upload-file')
                                ?.click();
                            }}
                            variant="outlined"
                          >
                            Replace Image
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </div>
                </div>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default PublishChatModal;
