import { GridColDef } from '@mui/x-data-grid';
import { ApexOptions } from 'apexcharts';

export const replaceNtoBr = (text: string): string => {
  if (!text) return '';

  // Replace multiple newlines with a single newline
  // text = text.replace(/\n+/g, '\n');

  // Ensure double newline after the introductory paragraph
  text = text.replace(/(application:)\n(Step \d+:)/g, '$1\n\n$2');

  // Ensure double newline after each "Step X: <Title>"
  text = text.replace(/(Step \d+:.*)\n/g, '$1\n\n');

  // Ensure a single newline before headers (###)
  text = text.replace(/\n\s*\n\s*(?=###)/g, '\n\n');

  // Bold the headings (lines starting with "###" or "##" or "#")
  text = text.replace(/^(### )(.+)$/gm, '$1**$2**');

  // Bold the headings (lines starting with "***" or "**" or "*")
  text = text.replace(/^(### )(.+)$/gm, '$1**$2**');

  // Bold the lines starting with "Explanation:"
  text = text.replace(/^(Explanation:)/gm, '**$1**');

  // Format code blocks (lines surrounded by triple backticks)
  text = text.replace(
    /```([\s\S]*?)```/g,
    match => `\`\`\`${match.slice(3, -3).trim()}\`\`\``,
  );

  // Format inline code (text surrounded by single backticks)
  text = text.replace(/`([^`]+)`/g, '`$1`');

  // Replace list items (lines starting with "-" or "*" followed by space)
  text = text.replace(/^\s*[\-\*]\s+/gm, '* ');

  // Ensure proper spacing after headers or bold text
  text = text.replace(/(\*\*.*\*\*)\s*\n/g, '$1\n\n');

  // Trim any leading/trailing whitespace
  return text.trim();
};

export const splitTextIntoSegments = (
  text: string,
): Array<{
  type: 'code' | 'text' | 'table' | 'chart' | 'img' | 'audio' | 'link';
  content: string;
  isHtml?: boolean;
  title?: string;
}> => {
  if (!text) return [{ type: 'text', content: '' }];
  const segments: Array<{
    type: 'code' | 'text' | 'table' | 'chart' | 'img' | 'audio' | 'link';
    content: string;
    isHtml?: boolean;
    title?: string;
  }> = [];
  const codeRegex = /(```.*?```)/gs;
  const tableRegex = /\|(?:.*\|)+\n\|[-:| ]+\|\n(?:\|.*\|[\n]*)+/g;
  const htmlImgRegex = /<img\s.*?>/g;
  const htmlAudioRegex = /<audio\s.*?>.*?<\/audio>/gs;
  const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
  const chartRegex = /{[\s\S]*?}/g;
  let lastIndex = 0;
  let tableContent: string | null = null;
  let chartContent: string | null = null;
  const linkRegex = /(?:https?:\/\/|www\.)[^\s/$.?#].[^\s]*/gi;

  text.replace(codeRegex, (match, p1, offset) => {
    if (lastIndex < offset) {
      segments.push({
        type: 'text',
        content: text.slice(lastIndex, offset).trim(),
      });
    }
    const cleanedCode = match
      .slice(3, -3)
      .split('\n')
      .filter(line => line.trim() !== '')
      .join('\n');

    // console.log('cleanedCode', cleanedCode);
    const lines = cleanedCode.split('\n');
    // console.log('lines', lines);
    const firstLine = lines[0];
    // console.log('firstLine', firstLine);
    const remainingLines = lines.slice(1);
    // console.log('remainingLines', remainingLines);
    // Join the remaining lines back into a single string
    const modifiedCleanedCode = remainingLines.join('\n').trim();
    // console.log('modifiedCleanedCode', modifiedCleanedCode);
    const isHtml = htmlTagRegex.test(modifiedCleanedCode);

    segments.push({
      type: 'code',
      content: modifiedCleanedCode,
      isHtml,
      title: firstLine,
    });
    lastIndex = offset + match.length;
    return match;
  });

  // Detect and parse tables
  text.replace(tableRegex, (match, offset) => {
    if (lastIndex < offset) {
      segments.push({
        type: 'text',
        content: text.slice(lastIndex, offset).trim(),
      });
    }
    tableContent = match;
    lastIndex = offset + match.length;
    return match;
  });

  // Detect and parse IMG HTML
  text.replace(htmlImgRegex, (match, offset) => {
    if (lastIndex < offset) {
      segments.push({
        type: 'text',
        content: text.slice(lastIndex, offset).trim(),
      });
    }
    segments.push({ type: 'img', content: match });
    lastIndex = offset + match.length;
    return match;
  });

  // Detect and parse AUDIO HTML
  text.replace(htmlAudioRegex, (match, offset) => {
    if (lastIndex < offset) {
      segments.push({
        type: 'text',
        content: text.slice(lastIndex, offset).trim(),
      });
    }
    segments.push({ type: 'audio', content: match });
    lastIndex = offset + match.length;
    return match;
  });

  // Detect and parse links
  text.replace(linkRegex, (match, offset) => {
    if (lastIndex < offset) {
      segments.push({
        type: 'text',
        content: text.slice(lastIndex, offset).trim(),
      });
    }
    let cleanUrl = match.replace(/[\[\]\(\)`"“”‘’\)\.,;]+$/g, '');

    // console.log('match:', match);
    // console.log(
    //   'extractUrlFromMarkdown(cleanUrl):',
    //   extractUrlFromMarkdown(cleanUrl),
    // );
    // console.log('cleanUrl:', cleanUrl);

    segments.push({
      type: 'link',
      content: extractUrlFromMarkdown(cleanUrl) || cleanUrl,
    });
    lastIndex = offset + match.length;
    return match;
  });

  // if (chartContent) {
  //   segments.push({ type: 'chart', content: chartContent });
  // }

  if (tableContent) {
    segments.push({ type: 'table', content: tableContent });
  }

  if (lastIndex < text.length) {
    segments.push({ type: 'text', content: text.slice(lastIndex).trim() });
  }

  return segments;
};

export const processTextSegments = (
  text: string,
): Array<{
  type: 'code' | 'text' | 'table' | 'chart' | 'img' | 'audio' | 'link';
  content: string;
}> => {
  if (!text) return [{ type: 'text', content: '' }];

  const segments = splitTextIntoSegments(text);

  const tableSegment = segments.find(segment => segment.type === 'table');
  const chartSegment = segments.find(segment => segment.type === 'chart');

  // Handle only one table segment
  if (tableSegment) {
    // Remove the table segment from segments to avoid duplication
    return segments
      .map(segment => {
        if (segment.type === 'text') {
          return { ...segment, content: replaceNtoBr(segment.content) };
        }
        return segment;
      })
      .filter(segment => segment.type !== 'table')
      .concat([tableSegment]);
  }

  return segments.map(segment => {
    if (segment.type === 'text') {
      return { ...segment, content: replaceNtoBr(segment.content) };
    }
    return segment;
  });
};

// Function to parse table content and generate MUI DataGrid
export const generateDataGridFromTable = (tableContent: string) => {
  const rows: any[] = [];
  const columns: GridColDef[] = [];

  const lines = tableContent.split('\n').filter(line => line.trim() !== '');
  if (lines.length < 2) return { columns: [], rows: [] };

  // Parsing column headers
  const headers = lines[0]
    .split('|')
    .map(header => header.trim())
    .filter(header => header);
  headers.forEach(header => {
    columns.push({ field: header, headerName: header, width: 150 });
  });

  // Parsing rows
  for (let i = 2; i < lines.length; i++) {
    const cells = lines[i]
      .split('|')
      .map(cell => cell.trim())
      .filter(cell => cell);
    const row: any = { id: i - 1 };
    headers.forEach((header, index) => {
      row[header] = cells[index] || '';
    });
    rows.push(row);
  }

  return { columns, rows };
};

export const processChartData = (chartContent: string) => {
  try {
    // Attempt to parse the JSON content
    const parsedData = JSON.parse(chartContent);

    // Ensure all required fields have default values if not provided
    const series = parsedData.series || [{ name: 'Series 1', data: [] }];
    const options: ApexOptions = {
      chart: {
        type: parsedData.type || 'line',
        height: parsedData.height || 350,
      },
      title: {
        text: parsedData.title || 'Chart',
        align: 'left',
      },
      xaxis: {
        categories: parsedData.categories || [],
      },
      yaxis: {
        title: {
          text: parsedData.yAxisTitle || 'Y-axis',
        },
      },
      ...parsedData.options, // Additional options if provided
    };

    return { series, options };
  } catch (error) {
    console.error('Error processing chart data:', error);
    return {
      series: [{ name: 'Error', data: [] }],
      options: {
        chart: {
          type: 'line',
          height: 350,
        },
        title: {
          text: 'Error',
          align: 'left',
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Y-axis',
          },
        },
      } as ApexOptions,
    };
  }
};

export const extractImageUrl = (htmlString: string): string => {
  const checkIfIsUrl = (url: string) => {
    const urlRegex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return urlRegex.test(url);
  };

  if (checkIfIsUrl(htmlString) && !htmlString?.includes('src=')) {
    return htmlString;
  }

  const urlMatch = htmlString.match(/src="([^"]+)"/);
  return urlMatch ? urlMatch[1] : '';
};

interface ProcessContentResult {
  jsxContent?: string;
  htmlContent?: string[];
}

export function extractReturnContent(content: string): ProcessContentResult {
  // Regex to detect JSX return statement
  const jsxReturnPattern = /return \(([^]*?)\)/;

  // Regex to detect HTML content
  const htmlContentPattern = /<[^>]*>?[^<]*?<\/[^>]*>?/g;

  // Check for JSX
  const jsxMatch = content.match(jsxReturnPattern);
  if (jsxMatch) {
    return { jsxContent: jsxMatch[1].trim() };
  }

  // Check for HTML
  const htmlMatches = content.match(htmlContentPattern);
  if (htmlMatches) {
    return { htmlContent: htmlMatches.map(match => match.trim()) };
  }

  return {};
}

export function extractUrlFromMarkdown(markdownOrText: string): string | null {
  // Regular expression to match a markdown link and extract the URL inside the parentheses
  const markdownUrlMatch = markdownOrText.match(
    /\[([^\]]+)\]\((https?:\/\/[^\)\s]+)\)/,
  );
  if (markdownUrlMatch && markdownUrlMatch[1]) {
    return markdownUrlMatch[1]; // Return the URL from the markdown link
  }

  // Regular expression to match a standalone HTTPS URL
  const httpsUrlMatch = markdownOrText.match(/https?:\/\/[^\s]+/);

  if (httpsUrlMatch && httpsUrlMatch[0]) {
    return httpsUrlMatch[0]; // Return the standalone HTTPS URL
  }

  return null; // Return null if no match is found
}
