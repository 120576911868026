import { createTheme } from '@mui/material';

interface ColorShades {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
}

interface CustomColorPalette {
  blue: ColorShades;
  neutral: ColorShades;
}
declare module '@mui/material/styles' {
  interface Theme {
    customColorPalette: CustomColorPalette;
  }

  interface ThemeOptions {
    customColorPalette: CustomColorPalette;
  }
}

export const theme = createTheme({
  customColorPalette: {
    blue: {
      50: '#E7F0FF',
      100: '#C4CFFF',
      200: '#9AAAFF',
      300: '#7388F7',
      400: '#546BEA',
      500: '#3650DE',
      600: '#3248D4',
      700: '#2B3DC6',
      800: '#2133BA',
      900: '#141BA1',
      A100: '#869FFF',
      A200: '#5169FF',
      A400: '#3755FF',
      A700: '#3946FF',
    },
    neutral: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },
  },
  palette: {
    primary: {
      main: '#3650DE',
      // 50: '#EDE7F6',
      // 100: '#D2C5EB',
      // 200: '#B39DDA',
      // 300: '#9575CE',
      // 400: '#7D57C1',
      // 500: '#673AB7',
      // 600: '#5E37B3',
      // 700: '#522DA8',
      // 800: '#4527A1',
      // 900: '#2E1B92',
      // A100: '#B589FF',
      // A200: '#7B4CFE',
      // A400: '#661EFF',
      // A700: '#661EFF',
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        rail: {
          color: '#D0D5DD',
          opacity: 1,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '16px',
          color: '#98A2B3',
          fontWeight: 500,
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            // Controls checked color for the thumb
            color: '#fff',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            padding: '10px 22px',
            boxShadow: 'unset',
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            padding: '10px 22px',
          },
        },
      ],
    },

    MuiTextField: {
      defaultProps: {
        size: 'small',
        sx: {
          '.MuiInputLabel-root': {
            //label fontsize
            fontSize: '16px !important',
          },
        },
      },

      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },

    MuiSelect: {
      defaultProps: {
        size: 'small',
        sx: {
          bgcolor: '#EAECF0',
          borderRadius: '8px',
          border: 'none',
          height: '30px',
          textTransform: 'capitalize',
          mb: '16px',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    allVariants: {
      lineHeight: '17px',
      fontFamily: 'Roboto, sans-serif',
    },
    h1: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '29px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '29px',
    },
    h6: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.17px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
