import React, { useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { DotsNine, PencilLine, Trash } from '@phosphor-icons/react';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { useAuthStore } from '../../store/storeAuth';

interface Data {
  id: string;
  label: string;
  text: string;
}

type Props = {
  data: Data;
  index: number;
  onDragStart?: (e: any) => void;
  onDrop?: (e: any) => void;
  stoppedDragging?: boolean;
  setStoppedDragging?: (arg0: boolean) => void;
  onDeleteEdge?: (sourceId: string) => void;
  canDrag?: boolean;
  openEditContentModal: () => void;
};

const DraggableBox = ({
  data,
  onDragStart = () => {},
  onDrop = () => {},
  stoppedDragging,
  setStoppedDragging = () => {},
  onDeleteEdge = () => {},
  openEditContentModal,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);

  useMemo(() => {
    if (stoppedDragging && isDragging) {
      setIsDragging(false);
      setStoppedDragging(false);
    }
  }, [stoppedDragging]);
  const { user } = useAuthStore(state => state);

  const typeOfTextIsNotObject =
    typeof data?.text !== 'object' ? data?.text : '';

  return (
    <Box
      onDrop={e => {
        onDrop(e);
        setIsDragging(false);
      }}
      onDragStart={e => {
        onDragStart(e);
        setIsDragging(true);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2.5,
        height: '38px',
        borderRadius: '4px',
        border: `1px solid ${user?.theme === 'dark' ? '#475467' : '#EAECF0'}`,
        background: user?.theme === 'dark' ? '#101828' : '#FFF',
        opacity: isDragging ? 0.5 : 1,
        mb: '8px',
        px: '8px',
      }}
      width={'360px'}
      style={{
        cursor: 'grab',
        display: 'flex',
        alignItems: 'center',
        justifyContent: data.label !== 'Custom' ? 'center' : 'space-between',
      }}
    >
      <DotsNine color="#475467" size={16} weight="bold" />
      {data.label !== 'Custom' && (
        <Typography
          fontSize={'12px'}
          whiteSpace={'nowrap'}
          fontWeight={700}
          color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
          title={data?.label}
          width={'100px'}
          textOverflow={'ellipsis'}
          overflow={'hidden'}
        >
          {data?.label}
        </Typography>
      )}

      <Typography
        whiteSpace={'nowrap'}
        fontSize={'12px'}
        fontWeight={400}
        title={typeOfTextIsNotObject}
        color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
        width={data.label !== 'Custom' ? '150px' : '100% !important'}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
      >
        {typeOfTextIsNotObject}
      </Typography>
      {data.label === 'Custom' && (
        <IconButtonTooltip onClick={openEditContentModal} title="Edit content">
          <PencilLine color="#475467" size={16} />
        </IconButtonTooltip>
      )}

      <IconButtonTooltip
        onClick={() => {
          onDeleteEdge(data.id);
        }}
        title="Delete Edge"
      >
        <Trash color="#475467" size={16} />
      </IconButtonTooltip>
    </Box>
  );
};

export default DraggableBox;
