export const groupTextBlocks = (text: string, maxWordsPerParagraph: number) => {
  // Define the punctuation marks to split paragraphs on
  const punctuationMarks = ['.', '!', '?'];

  // Split the text into sentences based on punctuation marks
  const sentences = text.split(new RegExp(`([${punctuationMarks.join('')}])`));

  // Initialize variables
  let currentParagraph = '';
  const paragraphs = [];

  // Loop through the sentences and create paragraphs
  for (const sentence of sentences) {
    // Add the sentence to the current paragraph if it doesn't exceed the word limit
    if (
      currentParagraph.split(' ').length + sentence.split(' ').length <=
      maxWordsPerParagraph
    ) {
      currentParagraph += sentence;
    } else {
      // If adding the sentence would exceed the word limit, start a new paragraph
      paragraphs.push(currentParagraph.trim());
      currentParagraph = sentence;
    }
  }

  // Add the last paragraph (if any)
  if (currentParagraph) {
    paragraphs.push(currentParagraph.trim());
  }

  return paragraphs;
};
