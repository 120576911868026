import { useEffect, useMemo, useRef, useState } from 'react';
import useFlowsStore from '../../store/storeFlows';
import EvenLabs, { VoiceInfo } from '../Voice/EvenLabs';
import { useAuthStore } from '../../store/storeAuth';
import { useDetachNodes } from '../../util/useDetachNodes';
import { NodeResizer, NodeToolbar, useEdges, useStore } from 'reactflow';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import PlayHt from '../Voice/PlayHt';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { Chip, FormLabel, MenuItem, Select } from '@mui/material';
import { systemDisplayNameMap, systemValueMap } from '../AiConfigs/constants';
import { EvenLabsService } from '../../service/EvanLabsServce';

function DynamicVoiceSettingsNode({ data, isConnectable, id }: any) {
  const voiceSystemRef = useRef(null);
  const { setNodeState, setSchema, flowId, setSaving } = useFlowsStore(
    state => state,
  );
  const edges = useEdges();
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const setValue = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { user, technologies } = useAuthStore(state => state);
  const [evenLabsVoices, setEvenLabsVoices] = useState<VoiceInfo[]>([]);
  const [evenLabsSettings, setEvenLabsSettings] = useState<{
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
  }>({
    stability: 0.5,
    similarity_boost: 0.75,
    style: 0,
    use_speaker_boost: true,
  });

  const audioSystems = ['evenLabs', 'playHt'];

  const filteredAudioSystems = technologies
    ?.filter(system => audioSystems.includes(system.system))
    .map(system => ({
      ...system,
      models: system.models?.filter((model: any) => model.type === 'Audio'),
    }));

  useEffect(() => {
    const evenLabs = async () => {
      try {
        const response = await EvenLabsService.voices();
        setEvenLabsVoices(response?.data?.voices);
      } catch (error) {
        console.log(error);
      }
    };

    evenLabs();
  }, []);

  const [formData, setFormData] = useState<{
    audioUrl: string;
    text?: string;
    voiceSystem: string;
    voice: string;
    title: string;
    speed: number;
    quality: string;
    outputFormat: string;
    sampleRate: number;
    voiceId: string;
    stability: number;
    similarity_boost: number;
    style: number;
    use_speaker_boost: boolean;
    modelId: string;
  }>({
    audioUrl: '',
    text: '',
    voiceSystem: 'elevenlabs.io',
    voice: 'evelyn',
    title: '',
    speed: 1,
    quality: 'medium',
    outputFormat: 'mp3',
    sampleRate: 24000,
    voiceId: 'FGY2WhTYpPnrIDTdsKH5',
    stability: 0.5,
    similarity_boost: 0.75,
    style: 0.5,
    use_speaker_boost: true,
    modelId: 'eleven_multilingual_v2',
  });

  const detachNodes = useDetachNodes();
  const hasParent = useStore(
    store => !!store.nodeInternals.get(id)?.parentNode,
  );
  const onDetach = () => detachNodes([id]);

  useEffect(() => {
    if (data && !isChangingDirectly) {
      setFormData({
        voiceSystem: data.voiceSystem || 'elevenlabs.io',
        voice: data.voice || 'Adrian',
        title: data.title || '',
        speed: data.speed || 1,
        quality: data.quality || 'medium',
        outputFormat: data.outputFormat || 'mp3',
        sampleRate: data.sampleRate || 24000,
        audioUrl: data.audioUrl || '',
        voiceId: data.voiceId || 'FGY2WhTYpPnrIDTdsKH5',
        stability: data.stability || 0.5,
        similarity_boost: data.similarity_boost || 0.75,
        style: data.style || 0.5,
        use_speaker_boost: data.use_speaker_boost || true,
        modelId: data.modelId || '',
        text: data.text || '',
      });
    }
  }, [data, isChangingDirectly]);

  const onChange = (e: { target: { name: any; value: any } }) => {
    setValue(e.target.name, e.target.value);

    setWhoIsChanging({
      value: e.target.value,
      name: e.target.name,
    });

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          setSaving,
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const jasonData = useMemo(() => {
    if (data?.voiceSystem === 'elevenlabs.io') {
      return JSON.stringify({
        System: formData?.voiceSystem,
        Stability: evenLabsSettings?.stability,
        SimilarityBoost: evenLabsSettings?.similarity_boost,
        Style: evenLabsSettings?.style,
        UseSpeakerBoost: evenLabsSettings?.use_speaker_boost,
      });
    } else {
      return JSON.stringify({
        System: formData?.voiceSystem,
        Voice: formData?.voice,
        Quality: formData?.quality,
        OutputFormat: formData?.outputFormat,
        Speed: formData?.speed,
        SampleRate: formData?.sampleRate,
      });
    }
  }, [
    data?.voiceSystem,
    evenLabsSettings?.similarity_boost,
    evenLabsSettings?.stability,
    evenLabsSettings?.style,
    evenLabsSettings?.use_speaker_boost,
    formData?.outputFormat,
    formData?.quality,
    formData?.sampleRate,
    formData?.speed,
    formData?.voice,
    formData?.voiceSystem,
  ]);

  const { user: authUser } = useAuthStore(state => state);

  return (
    <div className={false ? 'node' : ''}>
      <NodeToolbar>
        {hasParent && <button onClick={onDetach}>Detach</button>}
      </NodeToolbar>
      <NodeResizer isVisible={false} />
      {!data?.collapsed && (
        <>
          <div>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <FormLabel
                sx={{
                  mb: '4px',
                }}
              >
                System
              </FormLabel>

              <Select
                className="nodrag"
                fullWidth
                value={formData.voiceSystem}
                defaultValue={'elevenlabs.io'}
                size="small"
                onChange={onChange}
                name="voiceSystem"
                sx={{
                  width: '100%',
                }}
              >
                {filteredAudioSystems.map((opt, index) => {
                  const disabled = opt?.models?.every(
                    (model: any) => model.disabledByCompany,
                  );
                  const systemValue = systemValueMap[opt.system] || opt.system;
                  const displayName =
                    systemDisplayNameMap[systemValue] || systemValue;
                  return (
                    <MenuItem
                      key={index}
                      value={systemValue}
                      disabled={disabled}
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {displayName}
                      {disabled && (
                        <Chip
                          label="Disabled by admin"
                          color="error"
                          size="small"
                          variant="filled"
                          sx={{
                            borderRadius: '8px',
                          }}
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {formData.voiceSystem === 'play.ht' && (
              <PlayHt onChange={onChange} formData={formData} user={user} />
            )}
            {formData.voiceSystem === 'elevenlabs.io' && (
              <EvenLabs
                onChange={onChange}
                formData={formData}
                user={user!}
                voices={evenLabsVoices}
                settings={evenLabsSettings}
                setEvenLabsSettings={setEvenLabsSettings}
              />
            )}
          </div>
          <textarea
            value={jasonData}
            disabled
            className="nodrag"
            readOnly
            style={{
              color: authUser?.theme === 'dark' ? '#D0D5DD' : '#101828',
              backgroundColor: authUser?.theme === 'dark' ? '#1D2939' : '#fff',
              border: `2px solid ${
                authUser?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`,
            }}
          ></textarea>
        </>
      )}
    </div>
  );
}

export default DynamicVoiceSettingsNode;
