import { useEffect, useState } from 'react';
import KitModal from '../../../../UI/KitModal';
import { Box, Button, TextField } from '@mui/material';

type Props = {
  onHide: () => void;
  onCreateFolderOrUpdate: (
    name: string,
    isEditing?: boolean,
    id?: string,
    isSubfolder?: boolean,
    isSubfolderEditing?: boolean,
  ) => void;
  show: boolean;
  data: {
    isSubfolder: boolean;
    isEditing: boolean;
    isSubfolderEditing?: boolean;
    folder: {
      id: string;
      name: string;
    };
  };
  theme: string | undefined;
};

const CreateFolderModal = ({
  onHide,
  onCreateFolderOrUpdate,
  show,
  data,
}: Props) => {
  const [name, setName] = useState(
    data?.isEditing || data?.isSubfolderEditing ? data?.folder?.name : '',
  );
  useEffect(() => {
    setName(
      data?.isEditing || data?.isSubfolderEditing ? data?.folder?.name : '',
    );
  }, [data?.folder?.name]);

  const title =
    data?.isEditing || data?.isSubfolderEditing
      ? 'Update the folder'
      : 'Create new folder';

  return (
    <KitModal
      onHide={onHide}
      title={title}
      show={show}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '650px',
          width: '650px',
        },
      }}
    >
      <Box>
        <TextField
          type="text"
          className="form-control"
          fullWidth
          name="name"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Enter folder name"
        />
        <Box
          display={'flex'}
          mt={'15px'}
          alignItems={'center'}
          gap={'10px'}
          sx={{ justifyContent: 'flex-end' }}
          className="d-flex justify-content-center  gap-3  mt-3 align-items-center"
        >
          <Button
            variant={`contained`}
            size="small"
            disabled={!name}
            onClick={() => {
              setName('');
              if (data?.isEditing) {
                onCreateFolderOrUpdate(name, true, data?.folder?.id);
              } else if (data?.isSubfolderEditing) {
                onCreateFolderOrUpdate(
                  name,
                  false,
                  data?.folder?.id,
                  false,
                  true,
                );
              } else if (data?.isSubfolder) {
                onCreateFolderOrUpdate(name, false, data?.folder?.id, true);
              } else {
                onCreateFolderOrUpdate(name);
              }
            }}
          >
            {data?.isEditing || data?.isSubfolderEditing ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    </KitModal>
  );
};

export default CreateFolderModal;
