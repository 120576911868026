import React, { useState } from 'react';
import KitModal from '../UI/KitModal';
import { Box, Button, TextField, Typography } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';

interface Props {
  onClose: () => void;
  spaceName: string;
  onSave: (name: string) => void;
}

const EditSpaceModal: React.FC<Props> = ({ onClose, spaceName, onSave }) => {
  const [name, setName] = useState(spaceName);

  const onChange = (e: any) => {
    setName(e.target.value);
  };
  return (
    <KitModal show={true} onHide={onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '24px',
          paddingBottom: '20px',
          minWidth: '30vw',
        }}
      >
        <TextField
          name="name"
          label="Space Name"
          value={name}
          onChange={onChange}
          fullWidth
          InputProps={{
            style: {
              color: '#475467',
            },
          }}
        />

        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: '16px',
          }}
          onClick={() => {
            onSave(name);
            onClose();
          }}
        >
          <CheckCircle size={20} color="white" />
          Save
        </Button>
      </Box>
    </KitModal>
  );
};

export default EditSpaceModal;
