import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import {
  CaretDown,
  Copy,
  Gear,
  PlusCircle,
  XCircle,
} from '@phosphor-icons/react';
import { TextAlignLeft } from '@phosphor-icons/react/dist/ssr';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEdges, useReactFlow } from 'reactflow';
import { useAuthStore } from '../../store/storeAuth';
import useFlowsStore from '../../store/storeFlows';
import { useNotificationStore } from '../../store/storeNotifications';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { isActiveEdge } from '../../util/findActiveHandle';
import { NodeProps } from '../../util/Types/NodeProps';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import SmallMemorySessionModal from '../SmallMemory/SmallMemorySessionModal';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import Label from '../UI/Label/Label';
import Node from '../UI/Node/Node';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import OutputTable from '../UI/OutputTable/OutputTable';
import PreviewModal from '../UI/PreviewModal/PreviewModal';
function SmallMemory({ data, selected, id, type }: NodeProps) {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const edges = useEdges();
  const [entriesAnchorEl, setEntriesAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [sessionName, setSessionName] = useState('');

  const openEntriesPopover = Boolean(entriesAnchorEl);
  const popOverId = openEntriesPopover ? 'simple-popover' : undefined;

  const openSettingsPopover = Boolean(settingsAnchorEl);
  const popOverSettingsId = openSettingsPopover ? 'simple-popover' : undefined;

  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const [formData, setFormData] = useState({
    selectedType: 'Load Last X Entries',
    selectedNumber: 50,
    selectedIndex: 5,
    chat: false,
    clearOnExecution: false,
  });

  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...formData,
      ...(data.selectedType && { selectedType: data.selectedType }),
      ...(data.selectedNumber && { selectedNumber: data.selectedNumber }),
      ...(data.selectedIndex && { selectedIndex: data.selectedIndex }),
      ...(data.chat && { chat: data.chat }),
      ...(data.clearOnExecution && { clearOnExecution: data.clearOnExecution }),
    },
  });

  const [showModal, setShowModal] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();
  const [previewModal, setPreviewModal] = useState(false);

  const { setSchema, setSaving, flowId } = useFlowsStore(state => state);

  const [selectedChat, setSelectedChat] = useState('default');
  const [isOutputTableModalOpen, setIsOutputTableModalOpen] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [userSessionChat, setUserSessionChat] = useState<
    {
      id: string;
      name: string;
      entries: {
        role: string;
        content: string;
        timestamp: string;
        timeline?: string;
      }[];
    }[]
  >([]);

  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      chat: data.chat || false,
      selectedType: data.selectedType || 'Load Last X Entries',
      selectedNumber: data.selectedNumber || 0,
      selectedIndex: data.selectedIndex || 5,
      clearOnExecution: data.clearOnExecution || false,
    });
  }, [data]);

  useEffect(() => {
    setUserSessionChat([
      {
        id: 'default',
        name: 'Primary',
        entries: data?.entries,
      },
      ...(data?.userSessionsChat || []),
    ]);

    setSelectedChat(data?.selectedChat || 'default');
  }, [data.entries, data.userSessionsChat, data?.selectedChat]);

  const outputsType = [
    { name: 'Pass First X Entries', value: 'Load First X Entries' },
    { name: 'Pass First X Words', value: 'Load First X Words' },
    { name: 'Pass Last X Entries', value: 'Load Last X Entries' },
    { name: 'Pass Last X Words', value: 'Load Last X Words' },
  ];

  const onChange = (e: { target: { name: any; value: any } }) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setIsChangingDirectly(true);
    setWhoIsChanging({
      value: e.target.value,
      name: e.target.name,
    });
  };

  const isWords = watch('selectedType')?.includes('Words');

  const clearEntries = () => {
    const checkIfChatDefault = selectedChat === 'default';

    let object;
    if (checkIfChatDefault) {
      object = {
        entries: undefined,
      };
    } else {
      object = {
        userSessionsChat: userSessionChat
          ?.filter?.(chat => chat.id !== 'default')
          .map(chat => {
            if (chat.id === selectedChat) {
              return {
                ...chat,
                entries: undefined,
              };
            }
            return chat;
          }),
      };
    }

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      changeType: 'clear-entries',
      objectData: {
        ...object,
      },
      setSchema,
    });
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          changeType: 'changing-directly',
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const onCreateNewUserSession = (name: string) => {
    if (!name) {
      return;
    }
    const sessionChat = {
      id: Math.random().toString(36).substring(7),
      name,
      entries: [],
    };

    const newChat = [
      ...userSessionChat.filter(chat => chat?.id !== 'default'),
      sessionChat,
    ];

    setSelectedChat(sessionChat.id);
    setUserSessionChat([...userSessionChat, sessionChat]);

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        userSessionsChat: newChat,
        selectedChat: sessionChat.id,
      },
      setSchema,
    });

    setShowModal(false);
  };

  function jsonToCsv(jsonData: any) {
    let csv = '';
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(',') + '\n';
    // Add the data
    jsonData.forEach(function (row: any) {
      let data = headers.map(header => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
      csv += data + '\n';
    });
    let blob = new Blob([csv], { type: 'text/csv' });

    return blob;
  }

  const onChangeOnExecute = (e: {
    target: { name: string; checked: boolean };
  }) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        clearOnExecution: e.target.checked,
      },
      setSchema,
    });
  };

  const onChangeChatMode = () => {
    const entries = userSessionChat?.find(
      chat => chat.id === selectedChat,
    )?.entries;

    const dataObj = {
      chat: !formData.chat,
      selectedType:
        !formData.chat === true ? 'Load All Entries' : 'Load Last X Entries',
      selectedIndex:
        !formData.chat === true ? entries?.length || 0 : formData.selectedIndex,
    };

    setFormData({
      ...formData,
      ...dataObj,
    });

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      changeType: 'execute',
      objectData: {
        ...dataObj,
      },
      setSchema,
    });
  };

  const entries = userSessionChat?.find(
    chat => chat.id === selectedChat,
  )?.entries;

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const onSubmit = (data: any) => {
    setFormData({
      ...data,
    });

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      changeType: 'execute',
      objectData: {
        ...data,
      },
      setSchema,
    });

    setEntriesAnchorEl(null);
  };

  return (
    <Node
      type={type}
      isLoading={false}
      id={id}
      data={data}
      showTokensUsed={false}
    >
      <Box p="16px">
        <Box position={'relative'}>
          <Box
            mb={'16px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box display={'flex'} alignItems={'center'} gap={'12px'}>
              {userSessionChat?.length > 1 ? (
                <Select
                  name="selectedChat"
                  value={selectedChat || 'default'}
                  onChange={onChange}
                  className="nodrag"
                  size="small"
                  sx={{
                    fontWeight: 600,
                    backgroundColor: '#FFF',
                    '& .MuiList-root': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                    },
                    '& .MuiSvgIcon-root': {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                    borderRadius: '8px',
                    border: 'none',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    bgcolor: user?.theme === 'dark' ? '#101828' : '#FFF',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .Mui-selected': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                        },
                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                      },
                    },
                  }}
                >
                  {/* {!userSessionChat?.length && (
                  <MenuItem
                    key="default"
                    value="default"
                    sx={{
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                  >
                    default
                  </MenuItem>
                )} */}
                  {userSessionChat.map((chat, i) => (
                    <MenuItem
                      key={chat.id}
                      value={chat.id}
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      {chat.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Label sx={{ mb: '0' }} labelName="Stored Content" isAdvanced />
              )}
              <IconButtonTooltip
                title={'Create new user session'}
                onClick={() => setShowModal(true)}
              >
                <PlusCircle
                  size={20}
                  color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                />
              </IconButtonTooltip>
              {entries && entries?.length > 0 && (
                <Box display={'flex'} alignItems={'center'}>
                  <IconButton onClick={() => setPreviewModal(true)}>
                    <TextAlignLeft size={16} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        entries.map(e => e.content).join('\n'),
                      );
                      setNotification({
                        message: 'Copied to clipboard',
                        type: 'success',
                      });
                    }}
                    title="Copy All Content"
                  >
                    <Copy size={16} />
                  </IconButton>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <IconButtonTooltip
                title={'Small Memory Settings'}
                onClick={event => setSettingsAnchorEl(event.currentTarget)}
              >
                <Gear
                  size={22}
                  color={openSettingsPopover ? '#3650DE' : '#98A2B3'}
                />
              </IconButtonTooltip>

              <Popover
                open={openSettingsPopover}
                anchorEl={settingsAnchorEl}
                id={popOverSettingsId}
                onClose={() => setSettingsAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box
                  sx={{
                    width: '350px',
                    border: '1px solid var(--Neutral-300, #D0D5DD)',
                    padding: '16px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '22px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight={'24px'}
                    >
                      Settings
                    </Typography>
                    <IconButton
                      sx={{ padding: '4px' }}
                      size="medium"
                      onClick={() => setSettingsAnchorEl(null)}
                    >
                      <XCircle color="#667085" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight={'24px'}
                    >
                      Clear Execution
                    </Typography>
                    <Typography fontSize={'12px'} color={'#475467'}>
                      Clear the stored entries on every flow execution
                    </Typography>
                    <Switch
                      onChange={onChangeOnExecute}
                      checked={formData.clearOnExecution}
                    />
                  </Box>
                  <Box>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight={'24px'}
                    >
                      Chat Mode
                    </Typography>
                    <Typography fontSize={'12px'} color={'#475467'}>
                      Enable or disable chat mode
                    </Typography>
                    <Switch
                      checked={formData.chat}
                      onChange={onChangeChatMode}
                    />
                  </Box>
                  <Box>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight={'24px'}
                    >
                      Store by User Session
                    </Typography>
                    <Typography fontSize={'12px'} color={'#475467'}>
                      Record entries by sessions with customer ID/User ID
                    </Typography>
                  </Box>
                  {sessionName && (
                    <Typography
                      sx={{
                        backgroundColor: 'var(--Neutral-100, #F2F4F7)',
                        padding: '6px 10px',
                        borderRadius: '8px',
                        width: 'fit-content',
                      }}
                    >
                      {sessionName}
                    </Typography>
                  )}
                  <Box>
                    <Button
                      onClick={() => {
                        setShowModal(true);
                        setSettingsAnchorEl(null);
                      }}
                    >
                      Create New Session
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      onCreateNewUserSession(sessionName);
                      setSessionName('');
                      setSettingsAnchorEl(null);
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Popover>
              {entries && entries.length > 0 && (
                <Button
                  onClick={event => setEntriesAnchorEl(event.currentTarget)}
                  sx={{
                    backgroundColor: 'var(--Neutral-100, #F2F4F7)',
                    padding: '6px 10px',
                    borderRadius: '8px',
                    display: 'flex',
                    gap: '4px',
                    justifyContent: 'center',
                    color: 'var(--Neutral-800, #101828)',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  <Typography>
                    {formData.selectedType
                      .replace(
                        'X',
                        isWords
                          ? String(formData.selectedNumber) || 'X'
                          : String(formData.selectedIndex) || 'X',
                      )
                      .replace('Load', 'Pass')}
                  </Typography>
                  <CaretDown weight="fill" />
                </Button>
              )}

              <Popover
                open={openEntriesPopover}
                anchorEl={entriesAnchorEl}
                id={popOverId}
                onClose={() => setEntriesAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box
                  sx={{
                    width: '350px',
                    border: '1px solid var(--Neutral-300, #D0D5DD)',
                    padding: '16px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '22px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight={'24px'}
                    >
                      Entries to Pass
                    </Typography>
                    <IconButton
                      sx={{ padding: '4px' }}
                      size="medium"
                      onClick={() => setEntriesAnchorEl(null)}
                    >
                      <XCircle color="#667085" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight={'24px'}
                    >
                      Which Entries to Pass
                    </Typography>
                    <Typography fontSize={'12px'} color={'#475467'}>
                      Decide which of your stored entries to pass to other
                      nodes.
                    </Typography>
                  </Box>
                  <Box>
                    <Select
                      name="selectedType"
                      value={watch('selectedType')}
                      onChange={e => setValue('selectedType', e.target.value)}
                      className="nodrag"
                      size="small"
                      sx={{
                        '& .MuiList-root': {
                          backgroundColor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        },
                        '& .MuiSvgIcon-root': {
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        },
                        borderRadius: '8px',
                        border: 'none',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .Mui-selected': {
                              backgroundColor:
                                user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                            },
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                          },
                        },
                      }}
                    >
                      {!watch('chat') &&
                        outputsType?.map((flow, i) => (
                          <MenuItem
                            key={flow.value}
                            value={flow.value}
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            {flow.name}
                          </MenuItem>
                        ))}
                      <MenuItem
                        key="Load All Entries"
                        value="Load All Entries"
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        Pass All Entries
                      </MenuItem>

                      {watch('chat') && (
                        <MenuItem
                          key="Load Last X Entries"
                          value="Load Last X Entries"
                          sx={{
                            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                          }}
                        >
                          Pass Last X Entries
                        </MenuItem>
                      )}
                    </Select>
                  </Box>
                  {watch('selectedType') !== 'Load All Entries' && (
                    <Box className="w-100">
                      {isWords ? (
                        <TextField
                          className="w-50 nodrag"
                          name="selectedNumber"
                          type="number"
                          label="Number of Words to pass"
                          value={watch('selectedNumber')}
                          onChange={e =>
                            setValue('selectedNumber', Number(e.target.value))
                          }
                          size="small"
                          inputProps={{
                            min: 1,
                          }}
                          InputProps={{
                            style: {
                              height: '50px',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            },
                          }}
                          sx={{
                            width: '100%',
                            height: '50px',
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            bgcolor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            border: `2px solid ${
                              user?.theme === 'dark' ? '#475467' : '#EAECF0'
                            }`,
                          }}
                        />
                      ) : (
                        <TextField
                          className="w-50 nodrag"
                          name="selectedIndex"
                          type="number"
                          label="Number of Entries to pass"
                          value={watch('selectedIndex')}
                          onChange={e =>
                            setValue('selectedIndex', Number(e.target.value))
                          }
                          inputProps={{
                            min: 1,
                          }}
                          InputProps={{
                            style: {
                              height: '50px',
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            },
                          }}
                          sx={{
                            width: '100%',
                            height: '50px',
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                            bgcolor:
                              user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            border: `2px solid ${
                              user?.theme === 'dark' ? '#475467' : '#EAECF0'
                            }`,
                          }}
                        />
                      )}
                    </Box>
                  )}
                  <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                    Save
                  </Button>
                </Box>
              </Popover>
            </Box>
          </Box>
          <OutputTable
            value={entries}
            entries={entries}
            showInputHandles={true}
            labelName={'Store Content'}
            findColor={findColor}
            activeHandle={isActiveEdge(edges, id, 'output', 'source')}
            inputActive={isActiveEdge(edges, id, 'inputAdd', 'target')}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {/* {userSessionChat?.find(chat => chat?.id === selectedChat)?.entries
              ?.length && (
              <IconButtonTooltip
                title="Download User SessionChat as CSV"
                onClick={() => {
                  const csvData = jsonToCsv(
                    userSessionChat?.find(chat => chat.id === selectedChat)
                      ?.entries,
                  );
                  const a = document.createElement('a');
                  const url = URL.createObjectURL(csvData);
                  a.href = url;
                  a.download = 'userSessionChat.csv';
                  a.click();
                  URL.revokeObjectURL(url);
                }}
              >
                <FileArrowDown size={20} />
              </IconButtonTooltip>
            )} */}

            {/* {selectedChat !== 'default' && (
              <IconButtonTooltip
                onClick={() => {
                  navigator.clipboard.writeText(id + '-' + selectedChat);
                  // setNotification({
                  //   message: 'Copied to clipboard',
                  //   type: 'success',
                  // });
                }}
                title="Copy Session id to clipboard"
              >
                <Copy />
              </IconButtonTooltip>
            )} */}
          </Box>
          <Box>
            <Button onClick={clearEntries}>Clear Entries</Button>
          </Box>
        </Box>
      </Box>

      {/* <SettingsModal
        onChange={onChange}
        onHide={() => setSettingsModalOpen(false)}
        show={settingsModalOpen}
        formData={formData}
        data={data}
        entries={entries}
        onChangeOnExecute={onChangeOnExecute}
        onChangeChatMode={onChangeChatMode}
      /> */}

      <SmallMemorySessionModal
        sessionName={sessionName}
        setSessionName={setSessionName}
        show={showModal}
        onCreateNewUserSession={onCreateNewUserSession}
        onHide={() => setShowModal(false)}
      />

      <PreviewModal
        data={userSessionChat.filter(chat => chat?.id === selectedChat)}
        isSmallMemory
        onHide={() => setPreviewModal(false)}
        show={previewModal}
      />
    </Node>
  );
}

export default memo(SmallMemory);
