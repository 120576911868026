import React from 'react';
import EmailSvg from '../../assets/icons/Email.svg';
import { Typography, Button } from '@mui/material';

type Props = {
  onNavigate: () => void;
  bodyText: string;
};

const CheckEmail = ({ onNavigate, bodyText }: Props) => {
  return (
    <div className='text-center '>
      <img
        src={EmailSvg}
        style={{
          width: '74px',
          height: '74px',
        }}
        alt='test'
      />

      <Typography variant='h3' className='login_form_info--text'>
        Please check your email!
      </Typography>
      <Typography
        style={{
          color: '#5D6E82',
        }}
      >
        {bodyText}
      </Typography>

      <Button
        onClick={onNavigate}
        variant='contained'
        style={{
          backgroundColor: '#2A7BE4',
          padding: '7px 13px',
          fontSize: '13px',
        }}
      >
        {'<'} Return to Login
      </Button>
    </div>
  );
};

export default CheckEmail;
