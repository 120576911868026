import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class FluxService extends ApiService {
  static pathName = '/flux';

  static FluxApi = (
    flowId: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/beta-v2?flowId=${flowId}${
        body?.sessionId ? `&sessionId=${body.sessionId}` : ''
      }`,
      body,
    );
  };

  static chatApi = (
    flowId: string,
    socketId: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/chatbot-api-socket?flowId=${flowId}${
        body?.sessionId ? `&sessionId=${body.sessionId}` : ''
      }${socketId ? `&socketId=${socketId}` : ''}`,
      body,
    );
  };

  static formApi = (
    flowId: string,
    formId: string,
    socketId: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/formapi-v1?flowId=${flowId}&formId=${formId}&socketId=${socketId}`,
      body,
    );
  };
}
