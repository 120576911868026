import {
  FormControl,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { FieldErrors, RegisterOptions } from 'react-hook-form';

type Props = {
  type?: string;
  register?: any;
  validation?: RegisterOptions;
  name: string;
  placeholder?: string;
  className?: string;
  errorsStatus?: FieldErrors;
  label?: string;
  navigateText?: string;
  disabled?: boolean;
  onNavigate?: () => void;
  sxStyle?: SxProps<Theme>;
  fullWidth?: boolean;
  displayEnd?: boolean;
  defaultValue?: string;
};
const KitInput = ({
  type = 'text',
  register,
  validation,
  name,
  placeholder,
  className,
  errorsStatus,
  defaultValue,
  label,
  navigateText,
  onNavigate,
  disabled,
  sxStyle,
  fullWidth,
  displayEnd = false,
}: Props) => {
  const error = errorsStatus?.[name]?.message ? true : false;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      error={error}
      required={error}
    >
      {onNavigate && (
        <Typography
          sx={{
            cursor: 'pointer',
          }}
          role="button"
          mb={'15px'}
          textAlign={'right'}
          onClick={onNavigate}
          className="subtext "
        >
          {navigateText}
        </Typography>
      )}
      <TextField
        size="small"
        label={label}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type={displayEnd && showPassword ? 'text' : type}
        ref={errorsStatus?.[name]?.ref}
        variant="outlined"
        InputProps={{
          endAdornment: displayEnd ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        disabled={disabled}
        error={error}
        fullWidth
        sx={{
          ...sxStyle,
        }}
        helperText={
          errorsStatus?.[name]?.message ? errorsStatus[name]?.message : ''
        }
        {...register(name, validation)}
      />
    </FormControl>
  );
};

export default KitInput;
