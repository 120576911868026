import { useMemo, useState } from 'react';
import { NodeProps, Position, useEdges, useReactFlow } from 'reactflow';
import useFlowsStore from '../../store/storeFlows';

import DynamicTextSettingsNode from '../DynamicSettings/DynamicTextSettingsNode';
import DynamicVoiceSettingsNode from '../DynamicSettings/DynamicVoiceSettingsNode';

import { useNotificationStore } from '../../store/storeNotifications';
import { Box, MenuItem, Select } from '@mui/material';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { Copy } from '@phosphor-icons/react';
import Node from '../UI/Node/Node';
import InputHandle from '../UI/InputHandle/InputHandle';
import { isActiveEdge } from '../../util/findActiveHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';

const modelInputBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '4px',
};
export interface FormData {
  aiFp: string;
  aiModel: string;
  aiPp: string;
  aiTemperature: string;
  aiTokens: string;
  whoIsChanging: keyof FormData | any;
  maxOutputTokens: number;
  temperature: number;
  topP: number;
  topK: number;
  AiSystem: string;
  type: string;
  modelName: string;
  text: string;
  openSourceModelName: string;
  max_length: number;
  top_p: number;
  repetition_penalty: number;
  length_penalty: number;
  no_repeat_ngram_size: number;
  seed: number;
  max_new_tokens: number;
  system_prompt: string;
  min_new_tokens: number;
  top_k: number;
}
function DynamicSettingNode({
  id,
  data,
  isConnectable,
  selected,
  type,
}: NodeProps) {
  const defaultValues = {
    selectedOption: 'select',
  };
  const [selectedOption, setSelectedOption] = useState('text');
  const { setNodes: setNodeState } = useReactFlow();

  const { setSaving, setSchema, flowId } = useFlowsStore(state => state);

  const setNotification = useNotificationStore(state => state.setNotification);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const edges = useEdges();

  const handleSelectChange = (e: {
    target: { name: string; value: string };
  }) => {
    setSelectedOption(e.target.value);

    setWhoIsChanging({
      value: e.target.value,
      name: e.target.name,
    });

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  useMemo(() => {
    if (!isChangingDirectly) {
      setSelectedOption(data?.selectedOption || 'text');
    }
  }, [data.selectedOption]);

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const outputAIModelSettings = (
    <InputHandle
      activeColor={findColor?.color}
      handleId={'outputModelTextSettings'}
      isActive={isActiveEdge(edges, id, 'outputModelTextSettings', 'source')}
      right={-8}
      top={'50%'}
      position={Position.Right}
      type="source"
    />
  );

  const { user } = useAuthStore(state => state);

  return (
    <>
      <Node
        isLoading={false}
        type={type}
        id={id}
        data={data}
        showTokensUsed={false}
      >
        <Box p={'16px'}>
          <Box
            onClick={() => {
              navigator.clipboard.writeText(id);
              setNotification({
                message: 'Copied to clipboard',
                type: 'success',
              });
            }}
            className="d-flex align-items-center gap-2"
          >
            <small
              style={{
                color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                textTransform: 'capitalize',
              }}
            >
              Copy ID&nbsp;
            </small>
            <Copy
              cursor={'pointer'}
              className="nodrag"
              color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
            />
          </Box>
          <Box style={modelInputBoxStyle}>
            <Select
              style={{ marginLeft: '8px' }}
              name="selectedOption"
              value={selectedOption}
              onChange={handleSelectChange}
              className="nodrag"
              sx={{
                '& .MuiList-root': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                },
                '& .MuiSvgIcon-root': {
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
                borderRadius: '8px',
                border: 'none',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                    },
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                },
              }}
            >
              <MenuItem
                value="text"
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
              >
                Dynamic Text Settings
              </MenuItem>
              <MenuItem
                value="voice"
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
              >
                Dynamic Voice Settings
              </MenuItem>
            </Select>
          </Box>
          {outputAIModelSettings}
          <Box id="componentContainer">
            {selectedOption === 'text' && (
              <DynamicTextSettingsNode
                id={id}
                data={data}
                isConnectable={isConnectable}
                selected={selected}
                isChangingDirectly={isChangingDirectly}
                onChange={handleSelectChange}
              />
            )}
            {selectedOption === 'voice' && (
              <DynamicVoiceSettingsNode
                id={id}
                data={data}
                isConnectable={isConnectable}
                selected={selected}
              />
            )}
          </Box>
        </Box>
      </Node>
    </>
  );
}
export default DynamicSettingNode;
