import { IGetOneResponse } from '../util/Types/Generic';
import { ApiService } from './ApiService';

interface SecDefaultTypes {
  query: string;
  from: number;
  size: number;
  flowId?: string;
}

interface SecFullTextSearchTypes {
  query: string;
  formTypes: string[];
  startDate: string;
  endDate: string;
  flowId?: string;
}

interface SecExtractorTypes {
  url: string;
  section: string;
  type: string;
  flowId?: string;
}

export class SecService extends ApiService {
  static pathName = '/sec';

  static query = (body: SecDefaultTypes): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/query`, body);
  };

  static fullTextSearch = (
    body: SecFullTextSearchTypes,
  ): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/full-text-search`, body);
  };

  static extractor = (
    body: SecExtractorTypes,
  ): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/extractor`, body);
  };

  static trading = (body: SecDefaultTypes): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/trading`, body);
  };

  static formD = (body: SecDefaultTypes): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/form-d`, body);
  };

  static advFirmSearch = (
    body: SecDefaultTypes,
  ): Promise<IGetOneResponse<any>> => {
    return this.post(`${this.pathName}/adv-firm-search`, body);
  };

  static advIndividualRegisteredAdviser = (
    body: SecDefaultTypes,
  ): Promise<IGetOneResponse<any>> => {
    return this.post(
      `${this.pathName}/adv-individual-registered-adviser`,
      body,
    );
  };
  static advDirectOwners = (crd: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/adv-direct-owners?crd=${crd}`);
  };

  static advIndirectOwners = (crd: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/adv-indirect-owners?crd=${crd}`);
  };

  static advFinancialIndustryAffiliations = (
    crd: string,
  ): Promise<IGetOneResponse<any>> => {
    return this.get(
      `${this.pathName}/adv-financial-industry-affiliations?crd=${crd}`,
    );
  };

  static advPrivateFunds = (crd: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/adv-private-funds?crd=${crd}`);
  };

  static advBrochureApi = (crd: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/adv-brochure-api?crd=${crd}`);
  };

  static cik = (cik: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/cik?cik=${cik}`);
  };

  static ticker = (ticker: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/ticker?ticker=${ticker}`);
  };

  static cusip = (cusip: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/cusip?cusip=${cusip}`);
  };

  static companyName = (companyName: string): Promise<IGetOneResponse<any>> => {
    return this.get(`${this.pathName}/company-name?name=${companyName}`);
  };
}
