import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Popover, Switch, Typography } from '@mui/material';
import {
  CaretLeft,
  CaretRight,
  Copy,
  Info,
  TextAlignCenter,
  TextAlignLeft,
} from '@phosphor-icons/react';
import InputHandle from '../InputHandle/InputHandle';
import { Position } from 'reactflow';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';
import { useAuthStore } from '../../../store/storeAuth';

export const parseOptions = {
  replace: (domNode: any) => {
    if (domNode.name === 'img') {
      const { src, alt } = domNode.attribs;
      return (
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            maxWidth: '100%',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        />
      );
    }
  },
};

const OutputTextarea = ({
  value,
  placeholder = '',
  labelName = '',
  previewResponses,
  onPreview,
  previewIndex,
  findColor,
  activeHandle = false,
  placement,
  handleId = 'output',
  isHidden = false,
  topPosition,
  style,
  nodeLabel,
  hasClearOnExecute = false,
  clearOnExecution,
  onChangeOnExecute,
  maxOutputHeight,
  type,
}: any) => {
  const onCopy = () => {
    navigator.clipboard.writeText(value);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user, setTestPreviewModal } = useAuthStore(state => state);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const textarea = textareaRef.current;
      if (textarea && textarea.scrollHeight > textarea.clientHeight) {
        event.stopPropagation();
        textarea.scrollTop += event.deltaY;
      }
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('wheel', handleWheel);
      }
    };
  }, [value]);

  return (
    <Box
      position={'relative'}
      mb={'16px'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...style,
      }}
    >
      <InputHandle
        activeColor={findColor?.color}
        handleId={handleId}
        isActive={activeHandle}
        position={Position.Right}
        type="source"
        top={topPosition}
      />

      {!isHidden && (
        <>
          {placement === 'Prompt' && (
            <Button
              sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,

                '& :hover': {
                  '&.change-placement': {
                    border: '1px solid ' + findColor?.color,
                    '& svg': {
                      fill: findColor?.color,
                    },
                  },
                },
              }}
              onClick={handleClick}
            >
              <Box
                className="change-placement"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  alignSelf: 'center',
                  border: `1px solid ${
                    activeHandle ? findColor.color : '#98A2B3'
                  }`,
                  maxWidth: 'fit-content',
                }}
              >
                <TextAlignLeft
                  size={20}
                  color={activeHandle ? findColor.color : '#98A2B3'}
                />
              </Box>
              <Typography
                fontSize={'12px'}
                color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
              >
                {labelName?.split(' ')[0]}
              </Typography>
            </Button>
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '13px',
                padding: '15px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                boxShadow:
                  '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
              }}
            >
              <Typography
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  fontFeatureSettings: "'clig' 0, 'liga' 0",
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '150%',
                  letterSpacing: '0.15px',
                }}
              >
                {labelName?.split(' ')?.[0]}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '340px',
                  height: value ? '90px' : '40px',
                  overflowY: value ? 'scroll' : 'hidden',
                  alignItems: value ? 'flex-start' : 'center',
                  gap: '10px',
                  flexShrink: '0',
                  borderRadius: '4px',
                  border: value ? '1px dashed #D0D5DD' : '1px solid #D0D5DD',
                  padding: '8px',
                }}
              >
                <Typography
                  sx={{
                    color: '#98A2B3',
                    fontFeatureSettings: "'clig' 0, 'liga' 0",
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '166%',
                    letterSpacing: '0.4px',
                  }}
                >
                  {value
                    ? value
                    : `Connect or type more ${labelName
                        ?.split(' ')?.[0]
                        .toLowerCase()} to enhance your prompt.`}
                </Typography>
              </Box>
            </Box>
          </Popover>

          {placement !== 'Prompt' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                  position: 'relative',
                  '& svg': { cursor: 'pointer' },
                  mb: '8px',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    fontSize={'16px'}
                    color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                    fontWeight={600}
                  >
                    {labelName}
                  </Typography>
                  {value && (
                    <>
                      <TextAlignLeft
                        onClick={() =>
                          setTestPreviewModal({
                            labelName,
                            value: value,
                            readOnly: true,
                            label: labelName,
                            nodeLabel: nodeLabel,
                            name: 'Output',
                            type: type,
                            open: true,
                          })
                        }
                        size={16}
                        color={user?.theme === 'dark' ? '#D0D5DD' : '#98A2B3'}
                      />
                      <IconButtonTooltip
                        onClick={onCopy}
                        placement={copy ? 'bottom' : 'top'}
                        title={copy ? 'Text Copied' : 'Copy text'}
                        componentProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: copy ? '#80C683' : undefined,
                              color: copy ? 'white' : undefined,
                            },
                          },
                          arrow: {
                            sx: {
                              color: copy ? '#80C683' : undefined,
                            },
                          },
                        }}
                      >
                        <Copy color="#98A2B3" size={12} />
                      </IconButtonTooltip>
                    </>
                  )}
                </Box>
                {hasClearOnExecute && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      size="small"
                      onChange={onChangeOnExecute}
                      checked={clearOnExecution}
                    />
                    <IconButtonTooltip title={'Clear  output upon run'}>
                      <Info color="#98A2B3" size={12} />
                    </IconButtonTooltip>
                  </Box>
                )}
              </Box>
              <>
                {!value ? (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={'8px'}
                    width={placement === 'Output' ? '680px' : '360px'}
                    height={placement === 'Output' ? '480px' : '100%'}
                    sx={{
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    }}
                  >
                    <TextAlignCenter color="#98A2B3" size={32} />
                    <Typography
                      fontSize={'14px'}
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#98A2B3'}
                    >
                      Your output will be generated here
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    color={user?.theme === 'dark' ? '#F9FAFB' : '#475467'}
                    width={placement === 'Output' ? '680px' : '360px'}
                    maxHeight={
                      maxOutputHeight === 0 ? 277 : maxOutputHeight - 64
                    }
                    height={'100%'}
                    fontSize={'16px'}
                    fontWeight={400}
                    lineHeight={'143%'}
                    sx={{
                      overflowWrap: 'break-word',
                      overflow: 'hidden auto',
                      border: '1px solid #D0D5DD',
                      borderRadius: '4px',
                    }}
                  >
                    <textarea
                      value={value}
                      placeholder={placeholder}
                      readOnly
                      className="nodrag"
                      name={labelName + Math.random() * 1000}
                      style={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexGrow: 1,
                        boxSizing: 'border-box',
                        outline: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        resize: 'none',
                        padding: '15px 8px',
                        height: placement === 'Output' ? '480px' : '100%',
                        width: placement === 'Output' ? '680px' : '360px',
                        border: `1px solid ${
                          user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                        }`,

                        backgroundColor:
                          user?.theme === 'dark' ? '#1D2939' : '#fff',
                        color: user?.theme === 'dark' ? '#98A2B3' : 'black',
                      }}
                    />
                  </Box>
                )}

                {previewResponses?.length !== 0 && previewResponses && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    mt={'8px'}
                  >
                    <Box>
                      <Box display={'flex'} alignItems={'center'} gap={''}>
                        <CaretLeft
                          fontSize={16}
                          cursor={'pointer'}
                          color="#667085"
                          onClick={() => onPreview('prev')}
                        />
                        <Typography
                          lineHeight={'166%'}
                          fontSize={'12px'}
                          color={'#667085'}
                          m={'0'}
                          p="0"
                          mb={'-2px'}
                        >
                          {previewIndex !== undefined
                            ? previewIndex + 1
                            : previewResponses?.length}{' '}
                          <span
                            style={{
                              fontSize: '12px',
                            }}
                          >
                            -
                          </span>
                          {previewResponses?.length}
                        </Typography>
                        <CaretRight
                          fontSize={16}
                          color="#667085"
                          cursor={'pointer'}
                          onClick={() => onPreview('next')}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default OutputTextarea;
