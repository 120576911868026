import { memo, useState, useEffect, useRef } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import { ReplicateService } from '../../service/ReplicateService';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { OpenAiService } from '../../service/OpenAiService';
import { Box, Divider, Typography } from '@mui/material';
import TagInput from '../Test/TagInput';
import { replacePlaceholders } from '../../util/myFunctionsCoreDisplay';
import { checkExecuteTrigger } from '../../util/checkExecute';

import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import ImageUploadBox from '../UI/UploadBox/UploadBox';
import { CaretDown, Faders } from '@phosphor-icons/react';
import SettingsModal from '../ImageReader/SettingsModal/SettingsModal';
import { aiImageReaderBlipInputs } from '../ImageReader/constants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { GeminiService } from '../../service/GeminiService';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { AnthropicService } from '../../service/AnthropicService';
import { UploadService } from '../../service/UploadService';
import { FileService } from '../../service/FileService';
import { IdeogramService } from '../../service/IdeogramService';

export const imageReaderTargetTypes = {
  imageGenCaption: 'caption',
  imageGenQuestion: 'question',
  imageGenContent: 'content',
  imgGenPrompt: 'prompt',
  src: 'src',
};

function ImageReader({ id, data, type }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();

  const {
    schema,
    setSocketNodeResponse,
    setSchema,
    socketNodeResponse,
    setSaving,
    flowId,
  } = useFlowsStore(state => state);

  const setNotifications = useNotificationStore(state => state.setNotification);
  const [previewResponses, setPreviewResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const edges = useEdges();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [whoIsChanging, setWhoIsChanging] = useState({ name: '', value: '' });
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);

  const [formData, setFormData] = useState<{
    caption: string;
    question: string;
    content: string;
    use_nucleus_sampling: boolean;
    temperature: number;
    text: string;
    model: string;
    prompt: string;
    aiFp: string;
    aiPp: string;
    aiTemperature: string;
    aiTokens: string;
    src: string;
    inputPreview?: string;
    uploadImage?: string | any;
    openAiModel: string;
    geminiTemp: number;
    geminiTopP: number;
    geminiTopK: number;
    geminiMaxOutputTokens: number;
    geminiModel: string;
    anthropicModel: string;
  }>({
    caption: '',
    question: '',
    content: '',
    use_nucleus_sampling: false,
    temperature: 0,
    text: '',
    model: 'gpt-4o',
    openAiModel: 'gpt-4o',
    prompt: '',
    aiFp: '0.5',
    aiPp: '0.5',
    aiTemperature: '0.5',
    aiTokens: '1500',
    src: '',
    inputPreview: '',
    uploadImage: '',
    geminiTemp: 1,
    geminiTopP: 0.9,
    geminiTopK: 10,
    geminiMaxOutputTokens: 2500,
    geminiModel: 'proVision',
    anthropicModel: 'vision',
  });

  const [hasToExecute, setHasToExecute] = useState(true);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          caption: data?.caption ?? '',
          question: data?.question ?? '',
          content: data?.content ?? '',
          use_nucleus_sampling:
            data?.use_nucleus_sampling ?? formData.use_nucleus_sampling,
          temperature: data?.temperature ?? 0,
          text: data?.text ?? '',
          model: data?.model ?? 'gpt-4o',
          prompt: data?.prompt ?? '',
          aiFp: data?.aiFp ?? '0.5',
          aiPp: data?.aiPp ?? '0.5',
          aiTemperature: data?.aiTemperature ?? '0.5',
          aiTokens: data?.aiTokens ?? '1500',
          src: data?.src ?? '',
          inputPreview: data?.inputPreview ?? formData.inputPreview,
          uploadImage: formData.uploadImage ?? '',
          openAiModel: data?.openAiModel ?? 'gpt-4o',
          geminiTemp: data?.geminiTemp ?? 1,
          geminiTopP: data?.geminiTopP ?? 0.9,
          geminiTopK: data?.geminiTopK ?? 10,
          geminiMaxOutputTokens: data?.geminiMaxOutputTokens ?? 2500,
          geminiModel: data?.geminiModel ?? 'proVision',
          anthropicModel: data?.anthropicModel ?? 'vision',
        });

        setPreviewResponses(data?.previewResponses ?? []);
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onSubmit = async () => {
    try {
      let response: { message: any; usedTokens?: any } | null = null;
      setIsLoading(true);
      const formDataImage = new FormData();

      let img: any = {};
      let prompt = formData.prompt;

      if (data?.promptData?.length) {
        prompt = data.promptData?.map((item: any) => item.text)?.join(' \n');
      }

      if (formData.uploadImage instanceof File) {
        formDataImage.append('file', formData.uploadImage);
      } else if (formData.inputPreview) {
        img.src = formData.inputPreview;
      }

      if (formData.model === 'blip2') {
        let types = ['caption', 'question', 'content'];

        let newFormData: any = {
          ...formData,
        };

        types.forEach(type => {
          if (data[type + 'Data']?.length) {
            newFormData[type] = data[type + 'Data']
              ?.map((item: any) => item.label)
              ?.join(' \n');
          }
        });

        const res = await ReplicateService.imageToTextConvert(
          {
            caption: replacePlaceholders(newFormData.caption, schema),
            question: replacePlaceholders(newFormData.question, schema),
            content: replacePlaceholders(newFormData.content, schema),
            use_nucleus_sampling: formData.use_nucleus_sampling,
            temperature: +formData.temperature,
            flowId,
            ...img,
          },
          formDataImage,
        );

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };

        let responses = [...previewResponses];

        if (responses.length === 10) {
          responses.shift();
        }

        setFormData(prevState => ({
          ...prevState,
          text: response?.message,
          previewResponses: [...responses, response?.message],
          previewIndex: responses.length,
        }));

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          flowTriggerData: checkExecuteTrigger(data, id),
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],

          flowId,
          setSaving,
          objectData: {
            text: response?.message,
            previewResponses: [...responses, response?.message],
            previewIndex: responses.length,
            usedTokens: data?.flowTrigger?.id ? response.usedTokens : undefined,
            nodeUsedTokens: response.usedTokens,
          },
          setSchema,
          newEdges: edges,
        });

        setExecuteCounter(0);

        setTimeout(() => {
          setHasToExecute(true);
        }, 1500);
        setIsLoading(false);
      }

      if (formData.model === 'gemini') {
        if (formData.geminiModel === 'proVision') {
          const res = await GeminiService.geminiProVision(
            {
              prompt: prompt,
              temperature: +formData.geminiTemp,
              topP: +formData.geminiTopP,
              topK: +formData.geminiTopK,
              maxOutputTokens: +formData.geminiMaxOutputTokens,
              fileUrl: formData.inputPreview,
              flowId,
            },
            formDataImage,
          );

          // console.log('Gemini response: ', response);

          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };

          let responses = [...previewResponses];

          if (responses.length === 10) {
            responses.shift();
          }

          setFormData(prevState => ({
            ...prevState,
            text: response?.message,
            previewResponses: [...responses, response?.message],
            previewIndex: responses.length,
          }));

          nodeDataAutoSaveDynamic({
            setNodeState,
            id,
            flowTriggerData: checkExecuteTrigger(data, id),
            changeType: 'execute',
            objectCallerData: [
              ...(user?.spaceObjects || []),
              ...(user?.objects || []),
            ],

            flowId,
            setSaving,
            objectData: {
              text: response?.message,
              previewResponses: [...responses, response?.message],
              previewIndex: responses.length,
              usedTokens: data?.flowTrigger?.id
                ? response.usedTokens
                : undefined,
              nodeUsedTokens: response.usedTokens,
            },
            setSchema,
            newEdges: edges,
          });

          setExecuteCounter(0);
          setTimeout(() => {
            setHasToExecute(true);
          }, 1500);
          setIsLoading(false);

          return;
        }

        if (formData.geminiModel === 'multiModal') {
          const res = await GeminiService.multiModalNew(
            {
              prompt: prompt,
              temperature: +formData.geminiTemp,
              topP: +formData.geminiTopP,
              topK: +formData.geminiTopK,
              maxOutputTokens: +formData.geminiMaxOutputTokens,
            },
            formDataImage,
          );

          response = {
            message: res.data.message,
            usedTokens: res.data.usedTokens,
          };

          let responses = [...previewResponses];

          if (responses.length === 10) {
            responses.shift();
          }

          setFormData(prevState => ({
            ...prevState,
            text: response?.message,
            previewResponses: [...responses, response?.message],
            previewIndex: responses.length,
          }));

          nodeDataAutoSaveDynamic({
            setNodeState,
            id,
            flowTriggerData: checkExecuteTrigger(data, id),
            changeType: 'execute',
            objectCallerData: [
              ...(user?.spaceObjects || []),
              ...(user?.objects || []),
            ],

            flowId,
            setSaving,
            objectData: {
              text: response?.message,
              previewResponses: [...responses, response?.message],
              previewIndex: responses.length,
              usedTokens: data?.flowTrigger?.id
                ? response.usedTokens
                : undefined,
              nodeUsedTokens: response.usedTokens,
            },
            setSchema,
            newEdges: edges,
          });

          setExecuteCounter(0);

          return;
        }
      }

      if (formData.model === 'anthropic') {
        const res = await AnthropicService.anthropicVision(
          {
            prompt: prompt,
            flowId,
            url: formData.inputPreview,
          },
          formDataImage,
        );

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };

        let responses = [...previewResponses];

        if (responses.length === 10) {
          responses.shift();
        }

        setFormData(prevState => ({
          ...prevState,
          text: response?.message,
          previewResponses: [...responses, response?.message],
          previewIndex: responses.length,
        }));

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          flowTriggerData: checkExecuteTrigger(data, id),
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],

          flowId,
          setSaving,
          objectData: {
            text: response?.message,
            previewResponses: [...responses, response?.message],
            previewIndex: responses.length,
            usedTokens: data?.flowTrigger?.id ? response.usedTokens : undefined,
            nodeUsedTokens: response.usedTokens,
          },
          setSchema,
          newEdges: edges,
        });

        setExecuteCounter(0);
        setTimeout(() => {
          setHasToExecute(true);
        }, 1500);
        setIsLoading(false);

        return;
      }

      if (formData.model === 'gpt-4o') {
        const res = await OpenAiService.gpt4Preview(
          {
            prompt,
            temperature: formData.aiTemperature,
            maxTokens: formData.aiTokens,
            presencePenalty: formData.aiPp,
            frequencyPenalty: formData.aiFp,
            model: 'gpt-4o',
            flowId,
            ...img,
          },
          formDataImage,
        );

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };

        setSocketNodeResponse(id, null);
        setExecuteCounter(0);
      }
      if (formData.model === 'ideogram') {
        const res = await IdeogramService.describeImage({
          flowId,
          fileUrl: formData?.inputPreview as string,
        });

        response = {
          message: res.data.message,
          usedTokens: res.data.usedTokens,
        };

        // setSocketNodeResponse(id, null);

        let responses = [...previewResponses];

        if (responses.length === 10) {
          responses.shift();
        }

        setFormData(prevState => ({
          ...prevState,
          text: response?.message,
          previewResponses: [...responses, response?.message],
          previewIndex: responses.length,
        }));

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          flowTriggerData: checkExecuteTrigger(data, id),
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],

          flowId,
          setSaving,
          objectData: {
            text: response?.message,
            previewResponses: [...responses, response?.message],
            previewIndex: responses.length,
            usedTokens: data?.flowTrigger?.id ? response.usedTokens : undefined,
            nodeUsedTokens: response.usedTokens,
          },
          setSchema,
          newEdges: edges,
        });

        setExecuteCounter(0);

        setTimeout(() => {
          setHasToExecute(true);
        }, 1500);
        setIsLoading(false);
      }
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotifications,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotifications({
        type: 'error',
        message: getAPIErrorMessage(error as any),
      });
      setIsLoading(false);
    } finally {
    }
  };

  // console.log('FormData: ', formData);
  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        setNodeState,
        id,
        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        objectData: {
          text: data.text?.message,
          previewResponses: data?.previewResponses,
          previewIndex: data?.previewIndex,
          usedTokens: data?.flowTrigger?.id
            ? data?.text?.usedTokens
            : undefined,
          nodeUsedTokens: data?.text?.usedTokens,
        },
        setSchema,
        newEdges: edges,
      });

      setTimeout(() => {
        setHasToExecute(true);
      }, 2500);
      setIsLoading(false);
      setSocketNodeResponse(null, null);
    }
  }, [
    socketNodeResponse?.nodeId,
    socketNodeResponse?.finished,
    data.text,
    data?.previewResponses,
    data?.previewIndex,
    data?.flowTrigger,
    edges,
  ]);

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            setNodeState,
            id,
            setSaving,
            changeType: 'changingPreviewIndex',
            flowId,
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
            newEdges: edges,
          });

          setFormData(prevState => ({
            ...prevState,
            text: previewResponses[nextIndex],
            previewIndex: nextIndex,
          }));
        }
      }
    }
  };

  const onChange = (e: any) => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]:
        e.target.name === 'use_nucleus_sampling'
          ? !formData.use_nucleus_sampling
          : e.target.value,
    }));

    setWhoIsChanging({
      value:
        e.target.name === 'use_nucleus_sampling'
          ? !formData.use_nucleus_sampling
          : e.target.value,
      name: e.target.name,
    });
    setIsChangingDirectly(true);
  };

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
          newEdges: edges,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        // console.log("No file selected");
        return;
      }
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      console.log('FormData on upload: ', formData);

      const response = await UploadService.uploadFile(formData);
      const fileService = await FileService.createFormFIle({
        name: response?.data.name,
        src: response.data.src,
        flowId: flowId,
      });
      if (response.data && response.data.src) {
        setFormData(prevState => {
          const newState = {
            ...prevState,
            uploadImage: file,
            inputPreview: response.data.src,
          };
          return newState;
        });

        nodeDataAutoSaveDynamic({
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            uploadImage: file,
            inputPreview: response.data.src,
          },
          setSchema,
          newEdges: edges,
        });
      } else {
        console.error('Invalid response from server:', response);
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setNotifications({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const deleteImage = () => {
    setFormData({
      ...formData,
      uploadImage: null,
      inputPreview: '',
    });

    if (formData.inputPreview) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowId,
        setSaving,
        objectData: {
          inputPreview: '',
        },
        setSchema,
      });
    }
  };

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        type={type}
        btnText="Read Image"
        isLoading={isLoading}
        handleRun={handleRun}
        id={id}
        data={data}
        onSubmit={onSubmit}
        edges={edges}
        onChangePlacement={onChangePlacement}
      >
        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
          >
            {formData.model !== 'ideogram' && (
              <>
                {formData.model === 'gpt-4o' ||
                formData.model === 'gemini' ||
                formData.model === 'anthropic' ? (
                  <TagInput
                    name="prompt"
                    labelName={'Prompt'}
                    value={formData.prompt}
                    dataConnected={data?.promptData}
                    onChange={onChange}
                    isCollapsed={true}
                    handleId={'imgGenPrompt'}
                    disabled={isActiveEdge(edges, id, 'imgGenPrompt', 'target')}
                    findColor={findColor}
                    nodeId={id}
                    placeholder={
                      aiImageReaderBlipInputs.find(
                        input => input.name === 'prompt',
                      )?.placeholder
                    }
                    onChangePlacement={onChangePlacement}
                    nodeLabel={data?.label}
                  />
                ) : (
                  <>
                    {aiImageReaderBlipInputs
                      .filter(input => input.name !== 'prompt')
                      .map((input, index) => (
                        <TagInput
                          name={input.name}
                          labelName={input.labelName}
                          key={index}
                          nodeId={id}
                          dataConnected={data?.[input.name + 'Data']}
                          value={
                            formData?.[input.name as keyof typeof formData]
                          }
                          onChange={onChange}
                          isActive={isActiveEdge(
                            edges,
                            id,
                            input.handleId,
                            'target',
                          )}
                          findColor={findColor}
                          isCollapsed={input.isCollapsed}
                          handleId={input.handleId}
                          onChangePlacement={handleUpload}
                          nodeLabel={data?.label}
                          placeholder={input.placeholder}
                        />
                      ))}
                  </>
                )}
              </>
            )}

            <ImageUploadBox
              labelName="Image Input"
              value={formData.inputPreview}
              onChange={onChange}
              name="inputPreview"
              id={id}
              disabled={isActiveEdge(edges, id, 'inputPreview', 'target')}
              onChangeUploadImage={handleUpload}
              findColor={findColor}
              uploadedFile={formData.uploadImage}
              handleId="inputPreview"
              deleteImage={deleteImage}
            />

            {data?.placement !== 'Output' && (
              <>
                <Divider
                  sx={{
                    mt: '24px',
                    mb: '10px',
                  }}
                />
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box
                    bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                    borderRadius={'4px'}
                    sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                    onClick={() => setSettingsModalOpen(true)}
                  >
                    <Faders size={18} color="#667085" />
                    <Typography fontSize={'12px'} color="#667085" variant="h6">
                      {formData.model}
                    </Typography>

                    <CaretDown size={'16px'} color="#667085" />
                  </Box>
                </Box>
              </>
            )}
            <SettingsModal
              onHide={() => setSettingsModalOpen(false)}
              show={settingsModalOpen}
              formData={formData}
              onChange={onChange}
            />
          </Box>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data.text}
              activeHandle={
                edges?.find(
                  (edge: any) =>
                    edge?.source === id && edge?.sourceHandle === 'output',
                )?.source
                  ? true
                  : false
              }
              placement={data?.placement}
              labelName={'Output'}
              onPreview={onPreview}
              findColor={findColor}
              onChangePlacement={onChangePlacement}
              nodeLabel={data?.label}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
}

export default memo(ImageReader);
