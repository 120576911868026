import {
  Box,
  Button,
  Collapse,
  IconButton,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import {
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Copy,
  PlusCircle,
  Question,
  TextAlignLeft,
} from '@phosphor-icons/react';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from 'react';
import { useReactFlow } from 'reactflow';
import useFlowsStore from '../../store/storeFlows';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import InputHandle from '../UI/InputHandle/InputHandle';
import PreviewModal from '../UI/PreviewModal/PreviewModal';
import DraggableBox from './DraggableBox';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { useAuthStore } from '../../store/storeAuth';
import KitModal from '../UI/KitModal';
import { WordCount } from '../nodes/AiTextGenerator';
import { generateCustomID } from '../../util/generateCustomId';

interface Tag {
  tag: string;
  text: string;
  handleTab?: boolean;
}

interface TagInputProps {
  onChange?: any;
  name: string;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  labelName?: string;
  isCollapsed?: boolean;
  handleId?: string;
  hideTextArea?: boolean;
  findColor?: any;
  dataConnected?: any;
  nodeLabel?: string;
  placement?: string;
  readOnly?: boolean;
  isAdvanced?: boolean;
  advancedCollapsed?: boolean;
  info?: string;
  infoOnClick?: () => void;
  nodeId?: string;
  width?: string;
  removeIcons?: boolean;
  removeCopyPreview?: boolean;
  labelStyle?: React.CSSProperties;
  handleSettings?: any;
  customSettingsRight?: any;
  customSettingsLeft?: any;
  height?: string;
  isActive?: boolean;
  showCopy?: boolean;
  handleTab?: boolean;
  maxGrowHeight?: number;
  maxHeight?: number;
  type?: string;
}

const TagInput = ({
  onChange,
  name,
  value,
  disabled,
  placeholder = '',
  labelName = '',
  isCollapsed = false,
  handleId = '',
  hideTextArea = false,
  findColor,
  dataConnected = [],
  nodeLabel,
  placement,
  readOnly = false,
  isAdvanced = false,
  advancedCollapsed = false,
  info = '',
  infoOnClick,
  nodeId,
  width = '360px',
  removeIcons = false,
  removeCopyPreview = true,
  labelStyle,
  handleSettings,
  customSettingsRight,
  customSettingsLeft,
  height,
  isActive = false,
  showCopy = false,
  handleTab = false,
  maxGrowHeight = 120,
  showHandle = true,
  maxHeight = 200,
}: TagInputProps | any) => {
  const [showHints, setShowHints] = useState<boolean>(false);
  const [suggestedTags, setSuggestedTags] = useState<Tag[]>([]);
  const { schema, onEdgeDelete } = useFlowsStore(state => state);
  const { getEdges } = useReactFlow();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [copy, setCopy] = useState(false);
  const [specificTags, setSpecificTags] = useState(0);
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [onPreviewModal, setOnPreviewModal] = useState(false);
  const [showAddContentModal, setShowAddContentModal] = useState({
    show: false,
    isEditing: false,
    contentBoxId: '',
  });
  const [currentIndex, setCurrentIndex] = useState<number>(
    dataConnected?.length || 0,
  );
  const [anchorElList, setAnchorElList] = useState<HTMLButtonElement | null>(
    null,
  );
  const [newBoxContentInput, setNewBoxContentInput] = useState('');
  const { user } = useAuthStore(state => state);

  const edges = getEdges();

  const extractSuggestions = useCallback(
    (schema: any, path: string[] = []): Tag[] => {
      let suggestions: any = [];

      for (const key in schema) {
        const newPath: any = [...path, key];
        suggestions.push(newPath.join('.'));

        if (typeof schema[key] === 'object') {
          suggestions = suggestions.concat(
            extractSuggestions(schema[key], newPath),
          );
        }
      }

      return suggestions;
    },
    [],
  );

  const getFieldName = () => {
    if (labelName === 'Content and Context') {
      return 'content';
    }
    if (labelName === 'Instructions') {
      return 'instruction';
    }
    return labelName.toLowerCase();
  };

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const textarea = textareaRef.current;
      if (textarea && textarea.scrollHeight > textarea.clientHeight) {
        event.stopPropagation();
        textarea.scrollTop += event.deltaY;
      }
    };

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value: any = e.target.value;
      onChange?.({ target: { name, value } });

      const cursorPosition = e.target.selectionStart;
      setSpecificTags(cursorPosition);

      const regex = /{{(.*?)}}/g;
      const matches = [...value.matchAll(regex)];

      if (matches.length === 0) {
        setShowHints(false);
        setAnchorElList(null);
        return;
      }

      for (const match of matches) {
        const matchStart = match.index;
        const matchEnd = match.index + match[0].length;

        if (cursorPosition >= matchStart && cursorPosition <= matchEnd) {
          const text = match[1].trim();
          const suggestions = extractSuggestions(schema);

          if (text.length > 0) {
            const textFilter = suggestions.filter((suggestion: any) =>
              suggestion.includes(text),
            );
            setSuggestedTags(textFilter);
          } else {
            setSuggestedTags(suggestions);
          }
          setAnchorElList(e?.currentTarget as any);
          setShowHints(true);
          return;
        }
      }
      setAnchorElList(null);

      setShowHints(false);
    },
    [extractSuggestions, schema],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (handleTab && e.key === 'Tab') {
        e.preventDefault();
        const start = e.currentTarget.selectionStart;
        const end = e.currentTarget.selectionEnd;
        const newValue =
          value.substring(0, start) + '\t' + value.substring(end);
        onChange({ target: { name, value: newValue } });

        requestAnimationFrame(() => {
          if (textareaRef.current) {
            textareaRef.current.selectionStart =
              textareaRef.current.selectionEnd = start + 1;
          }
        });
      }
    },
    [handleTab, value],
  );

  const handleTagClick = useCallback(
    (tag: string | Tag) => {
      const regex = /{{(.*?)}}/g;
      let match;
      let lastMatch;

      while ((match = regex.exec(value)) !== null) {
        if (match.index > specificTags) {
          break;
        }
        lastMatch = match;
      }

      if (lastMatch) {
        const newValue =
          value.slice(0, lastMatch.index) +
          `{{${tag}}}` +
          value.slice(lastMatch.index + lastMatch[0].length);
        onChange?.({ target: { name, value: newValue } });
        setShowHints(false);
      }
    },
    [specificTags, value],
  );

  const onCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (dataConnected?.length === 0) {
      setCurrentIndex(0);
    } else if (currentIndex >= dataConnected?.length) {
      setCurrentIndex(dataConnected?.length - 1);
    }
  }, [dataConnected?.length, currentIndex]);

  const handlePrevious = useCallback(() => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? dataConnected?.length - 1 : prevIndex - 1,
    );
  }, [dataConnected?.length]);

  const handleNext = useCallback(() => {
    setCurrentIndex(prevIndex =>
      prevIndex === dataConnected?.length - 1 ? 0 : prevIndex + 1,
    );
  }, [dataConnected?.length]);

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(value);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  }, [value]);

  const onDeleteEdge = useCallback(
    (sourceId: string) => {
      if (sourceId?.includes('custom_')) {
        const filteredData = dataConnected?.filter(
          (item: any) => item.id !== sourceId,
        );
        onChange({
          target: {
            name: getFieldName() + 'Data',
            value: filteredData,
          },
        });
        return;
      }
      const sourceIncludesNodesBox =
        sourceId?.includes('nodesBox') ||
        sourceId?.includes('scripting') ||
        (sourceId?.includes('commandTextNode') &&
          sourceId?.includes('fileLink')) ||
        (sourceId?.includes('outputController') &&
          sourceId?.includes('fileLink')) ||
        (sourceId?.includes('varInputNode') &&
          sourceId?.includes('outputFileUrl'))
          ? sourceId?.split('$')?.[0]
          : sourceId;
      const filteredEdges = edges?.filter(
        (edge: any) =>
          edge?.target === nodeId &&
          edge?.targetHandle === handleId &&
          edge?.source === sourceIncludesNodesBox,
      );
      onEdgeDelete(filteredEdges);
    },
    [getEdges, handleId, nodeId, onEdgeDelete, dataConnected],
  );

  useEffect(() => {
    autoGrow();
  }, [value]);

  const autoGrow = useCallback(() => {
    if (textareaRef.current) {
      const element = textareaRef.current;
      const maxHeight = maxGrowHeight || 120;
      if (!value) {
        element.style.height = '20px';
      }
      const newHeight = Math.min(element.scrollHeight - 10, maxHeight);
      element.style.height = `${newHeight}px`;
      element.style.overflowY = newHeight >= maxHeight ? 'auto' : 'hidden';
    }
  }, [maxGrowHeight, value]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openList = Boolean(anchorElList);
  const idList = openList ? 'simple-popover' : undefined;

  const [showAll, setShowAll] = useState<boolean>(() => {
    const storedShowAll = localStorage.getItem(`showAll-${name}`);
    return storedShowAll === 'true';
  });

  const handleShowMore = useCallback(() => {
    setShowAll(true);
    localStorage.setItem(`showAll-${name}`, 'true');
  }, [name]);

  const handleShowLess = useCallback(() => {
    setShowAll(false);
    localStorage.setItem(`showAll-${name}`, 'false');
  }, [name]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) {
        return;
      }

      const newItems = Array.from(dataConnected);
      const [removed] = newItems.splice(sourceIndex, 1);
      newItems.splice(destinationIndex, 0, removed);

      onChange?.({
        target: {
          name: name + 'Data',
          value: newItems,
        },
      });
    },
    [dataConnected, onChange, name],
  );

  const onAddNewContentBox = () => {
    onChange({
      target: {
        name: getFieldName() + 'Data',
        value: [
          ...dataConnected,
          {
            label: 'Custom',
            text: newBoxContentInput,
            id: 'custom_' + generateCustomID(),
          },
        ],
      },
    });
    setShowAddContentModal({ show: false, isEditing: false, contentBoxId: '' });
    setNewBoxContentInput('');
  };

  const onEditContentBox = () => {
    const editedData = dataConnected?.map((item: any) => {
      if (item.id === showAddContentModal.contentBoxId) {
        return {
          ...item,
          text: newBoxContentInput,
        };
      }
      return item;
    });
    onChange({
      target: {
        name: getFieldName() + 'Data',
        value: editedData,
      },
    });
    setShowAddContentModal({ show: false, isEditing: false, contentBoxId: '' });
    setNewBoxContentInput('');
  };

  return (
    <Box
      position={'relative'}
      mb={!advancedCollapsed || !isCollapsed ? '0' : '16px'}
      className="nodrag"
    >
      {handleId && showHandle && (
        <InputHandle
          activeColor={findColor?.color}
          handleId={handleId}
          isActive={disabled || isActive}
          type={handleSettings?.type ? handleSettings?.type : 'target'}
          left={handleSettings?.right ? 'unset' : -26}
          right={handleSettings?.right ? handleSettings?.right : 'unset'}
          position={handleSettings?.position}
          top={
            !isCollapsed && isAdvanced
              ? handleSettings?.top
                ? handleSettings?.top
                : '-8px'
              : '10px'
          }
        />
      )}
      {((isAdvanced === false && (isCollapsed || !advancedCollapsed)) ||
        (isAdvanced === true && advancedCollapsed)) && (
        <>
          {placement === 'Output' && (
            <Button
              sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                fill: isActive ? findColor?.color : '#98A2B3',
                '& :hover': {
                  '&.change-placement': {
                    border: '1px solid ' + findColor?.color,
                    '& svg': {
                      fill: findColor?.color,
                    },
                  },
                },
              }}
              onClick={handleClick}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  alignSelf: 'center',
                  border: `1px solid ${isActive ? '#1436FF' : '#98A2B3'}`,
                  maxWidth: 'fit-content',
                }}
                className="change-placement"
              >
                <TextAlignLeft
                  size={20}
                  color={'#98A2B3'}
                  fill={isActive ? findColor.color : '#98A2B3'}
                />
              </Box>
              <Typography
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  fontFeatureSettings: "'clig' 0, 'liga' 0",
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '166%',
                  letterSpacing: '0.4px',
                }}
              >
                {labelName?.split(' ')[0]}
              </Typography>
            </Button>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '2px',
                padding: '15px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                boxShadow:
                  '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
              }}
            >
              <Typography
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  fontFeatureSettings: "'clig' 0, 'liga' 0",
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '150%',
                  letterSpacing: '0.15px',
                }}
              >
                {labelName?.split(' ')[0]}
              </Typography>
              <Box>
                {dataConnected?.length > 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        onClick={handlePrevious}
                        sx={{ padding: '0px' }}
                      >
                        <CaretLeft size={18} weight="bold" />
                      </IconButton>
                      <Typography
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          fontSize: '12px',
                          fontWeight: '600',
                          marginX: '10px',
                          marginY: '5px',
                        }}
                      >
                        {`${currentIndex + 1} out of ${dataConnected?.length}`}
                      </Typography>
                      <IconButton onClick={handleNext} sx={{ padding: '0px' }}>
                        <CaretRight size={18} weight="bold" />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        width: '340px',
                        height: '150px',
                        overflowY: 'scroll',
                        alignItems: 'flex-start',
                        gap: '10px',
                        flexShrink: '0',
                        borderRadius: '4px',
                        border: '1px solid #D0D5DD',
                        padding: '8px',
                      }}
                    >
                      <p
                        style={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          fontFeatureSettings: "'clig' 0, 'liga' 0",
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '166%',
                          letterSpacing: '0.4px',
                        }}
                      >
                        {dataConnected?.[currentIndex] || ''}
                      </p>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '340px',
                      height: value ? '90px' : '40px',
                      overflowY: value ? 'scroll' : 'hidden',
                      alignItems: value ? 'flex-start' : 'center',
                      gap: '10px',
                      flexShrink: '0',
                      borderRadius: '4px',
                      border: '1px solid #D0D5DD',
                      padding: '8px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#98A2B3',
                        fontFeatureSettings: "'clig' 0, 'liga' 0",
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '166%',
                        letterSpacing: '0.4px',
                      }}
                    >
                      {value
                        ? value
                        : `Connect or type more ${labelName
                            ?.split(' ')[0]
                            .toLowerCase()} to enhance your prompt.`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Popover>

          {placement !== 'Output' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  '& svg': { cursor: 'pointer' },
                  justifyContent: 'space-between',
                  mb: '8px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative',
                  }}
                >
                  {!isAdvanced &&
                    (collapsed ? (
                      <CaretDown
                        onClick={onCollapse}
                        size={16}
                        color={'#667085'}
                      />
                    ) : (
                      <CaretRight
                        onClick={onCollapse}
                        size={16}
                        color={'#3650DE'}
                      />
                    ))}
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      fontWeight: 600,
                      ...labelStyle,
                    }}
                  >
                    {labelName}
                  </Typography>

                  {removeCopyPreview && (
                    <>
                      {value || dataConnected?.length > 0 ? (
                        <IconButtonTooltip
                          onClick={() => setOnPreviewModal(true)}
                          title="Preview"
                        >
                          <TextAlignLeft size={13} color={'#98A2B3'} />
                        </IconButtonTooltip>
                      ) : (
                        <></>
                      )}
                      {showCopy && (
                        <IconButtonTooltip onClick={onCopy} title="Copy text">
                          <Copy color="#98A2B3" size={12} />
                        </IconButtonTooltip>
                      )}
                      {value ? (
                        <IconButtonTooltip
                          onClick={onCopy}
                          placement={copy ? 'bottom' : 'top'}
                          title={copy ? 'Text Copied' : 'Copy text'}
                          componentProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: copy ? '#80C683' : undefined,
                                color: copy ? 'white' : undefined,
                              },
                            },
                            arrow: {
                              sx: {
                                color: copy ? '#80C683' : undefined,
                              },
                            },
                          }}
                        >
                          <Copy color="#98A2B3" size={13} />
                        </IconButtonTooltip>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {dataConnected?.length !== 0 && (
                    <IconButtonTooltip
                      onClick={() =>
                        setShowAddContentModal({
                          show: true,
                          isEditing: false,
                          contentBoxId: '',
                        })
                      }
                      title="Add content box"
                    >
                      <PlusCircle color="#98A2B3" size={12} />
                    </IconButtonTooltip>
                  )}

                  {customSettingsLeft && customSettingsLeft}
                </Box>
                {customSettingsRight && customSettingsRight}
              </Box>
              <>
                {dataConnected?.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      rowGap: '13px',
                      flexDirection: 'column',
                      width: '360px',
                      position: 'relative',
                    }}
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {dataConnected
                              .slice(0, showAll ? dataConnected?.length : 3)
                              .map((data: any, index: number) => {
                                return (
                                  <Draggable
                                    key={data.id}
                                    draggableId={data.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          left: '10px',
                                          top: '170px',
                                          opacity: snapshot.isDragging
                                            ? 0.5
                                            : 1,
                                        }}
                                      >
                                        <DraggableBox
                                          index={index}
                                          key={data.id}
                                          data={data}
                                          onDeleteEdge={onDeleteEdge}
                                          openEditContentModal={() => {
                                            setShowAddContentModal({
                                              show: true,
                                              isEditing: true,
                                              contentBoxId: data.id,
                                            });
                                            setNewBoxContentInput(
                                              typeof data?.text !== 'object'
                                                ? data?.text
                                                : '',
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    {dataConnected?.length > 3 && (
                      <Button
                        onClick={showAll ? handleShowLess : handleShowMore}
                        size="small"
                        variant="text"
                        sx={{
                          gap: '8px',
                        }}
                      >
                        {showAll ? (
                          <>
                            <CaretUp size={16} />
                            Show less
                          </>
                        ) : (
                          <>
                            <CaretDown size={16} />
                            Show more
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                ) : (
                  <>
                    <Collapse in={collapsed || isAdvanced}>
                      {!hideTextArea && (
                        <textarea
                          value={value}
                          placeholder={placeholder}
                          onChange={handleInputChange}
                          onKeyDown={handleTab ? handleKeyDown : undefined}
                          readOnly={readOnly}
                          disabled={disabled}
                          name={name}
                          onClick={(e: any) => {
                            const cursorPosition = e?.target?.selectionStart;
                            setSpecificTags(cursorPosition);

                            const regex = /{{}}/g; // Changed to only match empty double curly braces
                            const matches = [...value.matchAll(regex)];

                            if (matches.length === 0) {
                              setShowHints(false);
                              setAnchorElList(null);
                              return;
                            }

                            for (const match of matches) {
                              const matchStart = match.index;
                              const matchEnd = match.index + match[0].length;

                              if (
                                cursorPosition >= matchStart &&
                                cursorPosition <= matchEnd
                              ) {
                                const suggestions = extractSuggestions(schema);
                                setSuggestedTags(suggestions);
                                setAnchorElList(e?.currentTarget as any);
                                setShowHints(true);
                                return;
                              }
                            }

                            setAnchorElList(null);
                            setShowHints(false);
                          }}
                          className="nodrag"
                          ref={textareaRef}
                          style={{
                            outline: 'none',
                            fontFamily: 'Roboto',
                            fontSize: '16px',
                            fontWeight: 400,
                            resize: 'none',
                            borderRadius: '4px',
                            lineHeight: '20px',
                            padding: '5px',
                            height:
                              isAdvanced || !isCollapsed
                                ? height
                                  ? height
                                  : 'unset'
                                : height
                                ? height
                                : '20px',
                            width: placement === 'Prompt' ? '680px' : width,
                            border: `1px solid ${
                              user?.theme === 'dark' ? '#344054' : '#D0D5DD'
                            }`,
                            backgroundColor:
                              user?.theme === 'dark' ? '#1D2939' : '#fff',
                            color: user?.theme === 'dark' ? '#98A2B3' : 'black',
                          }}
                        />
                      )}
                      {removeIcons !== true && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                          mt={'8px'}
                        >
                          {info && (
                            <IconButtonTooltip
                              onClick={infoOnClick}
                              title={info}
                            >
                              <Question color="#98A2B3" size={12} />
                            </IconButtonTooltip>
                          )}
                        </Box>
                      )}
                    </Collapse>
                    {showHints && (
                      <Popover
                        open={openList}
                        anchorEl={anchorElList}
                        onClose={() => {
                          setShowHints(false);
                          setAnchorElList(null);
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Escape') {
                            setShowHints(false);
                            setAnchorElList(null);
                          }
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        sx={{
                          '& .MuiPaper-root': {
                            maxHeight: 250,
                            width: '300px',
                            background: 'white',
                            boxShadow: 24,
                            border: '1px solid #D0D5DD',
                            padding: '14px',
                            gap: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                          },
                        }}
                      >
                        {suggestedTags.map((tag: any) => (
                          <ListItem
                            key={tag}
                            sx={{
                              cursor: 'pointer',
                              color: '#475467',
                              ':hover': {
                                backgroundColor: '#F2F4F7',
                              },
                            }}
                            onClick={() => handleTagClick(tag)}
                          >
                            {tag}
                          </ListItem>
                        ))}
                      </Popover>
                    )}
                  </>
                )}
              </>

              <PreviewModal
                data={{
                  labelName,
                  data: dataConnected,
                  nodeLabel: nodeLabel,
                  value: value,
                  readOnly: readOnly,
                  label: labelName,
                }}
                onChange={onChange}
                name={name}
                onHide={() => setOnPreviewModal(false)}
                show={onPreviewModal}
              />
              <KitModal
                maxWidth={'md'}
                sx={{
                  '& .MuiPaper-root': {
                    overflow: 'hidden',
                    maxHeight: '700px',
                    width: '500px',
                    borderRadius: '16px',
                    pb: '16px',
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                  '& .MuiDialogContent-root': {
                    padding: '16px',
                    borderTop: 'none',
                    borderBottom: 'none',
                  },
                  '& .MuiDialogActions-root': {
                    padding: '16px',
                  },
                  p: '0px',
                }}
                fullWidth
                showCloseButton={true}
                show={showAddContentModal.show}
                onHide={() =>
                  setShowAddContentModal({
                    show: false,
                    isEditing: false,
                    contentBoxId: '',
                  })
                }
                title={
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
                        fontSize="16px"
                        fontWeight={600}
                        lineHeight="150%"
                        sx={{
                          maxWidth: '350px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        title={labelName}
                      >
                        {labelName}
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={'6px'}
                      mb={'10px'}
                    >
                      <Typography
                        color={'#475467'}
                        fontSize={'14px'}
                        fontWeight={400}
                        letterSpacing={'0.17px'}
                        mt={0}
                      >
                        {nodeLabel}
                      </Typography>
                      <Typography
                        color={'#475467'}
                        fontSize={'14px'}
                        fontWeight={400}
                        letterSpacing={'0.17px'}
                      >
                        {value && nodeLabel && '•'}
                      </Typography>
                      <WordCount
                        text={value}
                        props={{
                          color: '#475467',
                          fontSize: '14px',
                        }}
                      />
                    </Box>
                  </>
                }
                headerStyles={{
                  pb: '0px',
                }}
              >
                <textarea
                  value={newBoxContentInput}
                  onChange={e => setNewBoxContentInput(e.target.value)}
                  style={{
                    resize: 'vertical',
                    fontFamily: 'inherit',
                    maxWidth: '100%',
                    width: '100%',
                    fontSize: '16px',
                    minHeight: '400px',
                    lineHeight: '143%',
                    height: '200px',
                    fontWeight: 400,
                  }}
                />

                <Box display={'flex'} justifyContent={'flex-end'} mt={'8px'}>
                  <Box>
                    <IconButton
                      onClick={
                        showAddContentModal.isEditing
                          ? onEditContentBox
                          : onAddNewContentBox
                      }
                    >
                      <Typography
                        color={'#3650DE'}
                        fontSize={'16px'}
                        fontWeight={500}
                        lineHeight={'150%'}
                      >
                        Save
                      </Typography>
                    </IconButton>
                  </Box>
                </Box>
              </KitModal>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(TagInput);
