import { Box, IconButton, Typography } from "@mui/material";
import { PencilSimpleLine } from "@phosphor-icons/react/dist/ssr";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { VoiceInfo } from "../../Voice/EvenLabs";
import { EvenLabsService } from "../../../service/EvanLabsServce";

interface TextConfigValues {
  [key: string]: string | any;
  aiSystem: string;
  aiModel: string;
  aiTokens: string;
  aiTemperature: string;
  aiFp: string;
  aiPp: string;
}

interface AudioConfigValues {
  [key: string]: string | any;
  name: string;
  voice: string;
  aiSystem: string;
  quality: string;
  outputFormat: string;
  speed: string;
  sampleRate: string;
}

interface ImageConfigValues {
  [key: string]: string | any;
  name: string;
  aiSystem: string;
  engineId: string;
  clip_guidance_preset: string;
  sampler: string;
  width: string;
  height: string;
  cfgScale: string;
  steps: string;
  image_Strength: string;
  promptWeight: string;
  openSourceModel: string;
  openAiModels: string;
  numbersOfVariations: string;
  quality: string;
  size: string;
  negativePrompt: string;
}

type Props = {
  config:
    | {
        name: string;
        type: string;
        configTypes: {
          name: string;
          value: string;
        }[];
      }
    | undefined;
  configValues:
    | TextConfigValues
    | AudioConfigValues
    | ImageConfigValues
    | undefined;
  setOpenedModal: Dispatch<
    SetStateAction<{
      text: boolean;
      audio: boolean;
      image: boolean;
      clone: boolean;
    }>
  >;
  editConfigId: string;
  setIsEditingConfig: (editing: { id: string; edit: boolean }) => void;
  icon?: React.ReactNode;
  setConfigType: Dispatch<SetStateAction<string>>;
  configType: string;
};

const ConfigBox = ({
  config,
  configValues,
  setOpenedModal,
  editConfigId,
  setIsEditingConfig,
  icon,
  setConfigType,
  configType,
}: Props) => {
  const handleOpenTextModal = () => {
    if (editConfigId) {
      setIsEditingConfig({
        id: editConfigId,
        edit: true,
      });
      setOpenedModal((prev) => ({ ...prev, [config?.type as string]: true }));
    } else {
      setOpenedModal((prev) => ({ ...prev, [config?.type as string]: true }));
    }

    setConfigType(config?.type as string);
  };

  const makePercentage = (value: number) => {
    return Math.round(value * 100);
  };

  const hasValues = config?.configTypes.some(
    (configType) => configValues?.[configType?.value]
  );

  const [evenLabsVoices, setEvenLabsVoices] = useState<VoiceInfo[]>([]);
  const [initialStart, setInitialStart] = useState(true);

  useEffect(() => {
    const evenLabs = async () => {
      try {
        const response = await EvenLabsService.voices();
        setEvenLabsVoices(response?.data?.voices);
      } catch (error) {
        console.log(error);
      } finally {
        setInitialStart(false);
      }
    };
    if (initialStart) {
      evenLabs();
    }

    return () => {
      setInitialStart(false);
    };
  }, [initialStart]);

  const getVoiceNameById = (voiceId: string) => {
    const voice = evenLabsVoices.find((voice) => voice.voice_id === voiceId);
    return voice ? voice.name : voiceId;
  };

  return (
    <Box
      sx={{
        padding: "16px",
        border: "1px solid #EAECF0",
        borderRadius: "8px",
        width: "538px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={handleOpenTextModal}>
          <PencilSimpleLine size={18} color={"#667085"} />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {icon}
        <Typography
          fontSize={"20px"}
          fontWeight={500}
          lineHeight={"32px"}
          color={"#000"}
        >
          {config?.name}
        </Typography>
      </Box>
      <Box sx={{ marginY: "16px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#3650DE",
            fontWeight: 600,
            lineHeight: "20px",
          }}
        >
          Default Settings
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
        }}
      >
        {config?.configTypes
          .slice(0, hasValues || configType === 'image' ? config.configTypes.length : 6)
          .map((configType, i) => {
            const value = configValues?.[configType?.value];
            return (
              <Fragment key={i}>
                {value || !hasValues ? (
                  <Typography
                    sx={{
                      color: '#475467',
                      fontSize: '16px',
                    }}
                  >
                    {configType.name}:{' '}
                    {configType.value === 'voiceId' ? getVoiceNameById(value) : value
                      ? configType.value === 'stability' ||
                        configType.value === 'similarity_boost' ||
                        configType.value === 'style'
                        ? `${makePercentage(value.toString())}%`
                        : configType?.value === 'aiSystem'
                        ? `${
                            value.toString().charAt(0).toUpperCase() +
                            value.toString().slice(1)
                          }`
                        : `${value.toString()}`
                      : ''}
                  </Typography>
                ) : null}
              </Fragment>
            );
          })}
      </Box>
    </Box>
  );
};

export default ConfigBox;
