import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TextField, Autocomplete, Checkbox, FormControlLabel, Button } from '@mui/material';
import { ReportService } from '../../service/ReportService';
import { UserService } from '../../service/UserService';

const YEAR_INPUT = new Date().getFullYear();

const AdminDashboard: React.FC = () => {
  const [usageData, setUsageData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [showLabel, setShowLabel] = useState<boolean>(true);
  const [showModel, setShowModel] = useState<boolean>(true);
  const [showUsedTokens, setShowUsedTokens] = useState<boolean>(true);
  const [showWeek, setShowWeek] = useState<boolean>(true);
  const [showMonth, setShowMonth] = useState<boolean>(true);
  const [showYear, setShowYear] = useState<boolean>(true);
  const [showNodeRuns, setShowNodeRuns] = useState<boolean>(true);
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [groupBy, setGroupBy] = useState<'month' | 'week'>('month'); // Default to month
  const [monthInput, setMonthInput] = useState<number |string>(''); // State for month input
  const [weekInput, setWeekInput] = useState<number | string>(''); // State for week input
  const [initialData, setInitialData] = useState<any[]>([]);
  const [yearInput, setYearInput] = useState<number | string>(YEAR_INPUT); // State for year input

  
  
  
  useEffect(() => {
    const fetchData = async () => {
      if (selectedUser) {
        try {
          const response: any = await ReportService.getCustomerUsage(selectedUser.id);
          const data = response.data;

          setInitialData(data);
          setGroupedData(data);


        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [selectedUser, groupBy]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(async () => {
      if (value) {
        try {
          const response = await UserService.getUsersByEmail(value);
          setUsers(response.data || []);
        } catch (error) {
          setUsers([]);
        }
      } else {
        setUsers([]);
      }
    }, 1000);

    setDebounceTimeout(timeout);
  };
  

  const onWeekChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setWeekInput(value);
    const monthQuery = monthInput ? `&month=${monthInput}` : '';
    const yearQuery = yearInput ? `&year=${yearInput}` : '';

      const response: any = await ReportService.getCustomerUsage(selectedUser.id, `week=${value}${monthQuery}${yearQuery}`);
      const data = response.data;
      setInitialData(data);
      setGroupedData(data);
  };

  const onMonthChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setMonthInput(value);
    const weekQuery = weekInput ? `&week=${weekInput}` : '';
    const yearQuery = yearInput ? `&year=${yearInput}` : '';

      const response: any = await ReportService.getCustomerUsage(selectedUser.id, `month=${value}${weekQuery}${yearQuery}`);
      const data = response.data;
      setInitialData(data);
      setGroupedData(data);
  };

  const onYearChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || YEAR_INPUT;
    setYearInput(value);
    const monthQuery = monthInput ? `&month=${monthInput}` : '';
    const weekQuery = weekInput ? `&week=${weekInput}` : '';

      const response: any = await ReportService.getCustomerUsage(selectedUser.id, `year=${value}${monthQuery}${weekQuery}`);
      const data = response.data;
      setInitialData(data);
      setGroupedData(data);
  };


  const handleUserSelect = (user: any) => {
    setSelectedUser(user);
    setSearchTerm(user.email);
    setUsers([]);
  };


  const convertToCSV = (data: any[]) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(','));
    return [headers, ...rows].join('\n');
  };

  const downloadCSV = () => {
    const csv = convertToCSV(groupedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'customer_usage_data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const hasData = groupedData.length > 0;

  return (
    <Box
      sx={{
        backgroundColor: '#f0f0f0', // Light grey background
        color: '#333333', // Dark grey text
        padding: '20px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ color: '#333333' }}>
        Customer Usage Data
      </Typography>
      {hasData && (
    <Button 
      variant="contained" 
      onClick={downloadCSV}
      sx={{ 
        backgroundColor: '#28a745',
        '&:hover': {
          backgroundColor: '#218838',
        },
        position: 'absolute',  // Add this
        right: '20px',  // Add this
        top: '100px',  // Add this
      }}
    >
      Download CSV
    </Button>
  )}
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.email}
        value={selectedUser}
        onChange={(event, newValue) => {
          if (newValue) {
            handleUserSelect(newValue);
          }
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.email} - {option.plan}
          </li>
        )}
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search by email..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                ...params.InputProps,
                style: { color: '#333333' }, // Set text color to dark grey
              }}
              sx={{
                backgroundColor: '#ffffff', // White background for input
                marginBottom: '20px',
                width: '600px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#007BFF', // Soft blue border
                  },
                  '&:hover fieldset': {
                    borderColor: '#28a745', // Soft green on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#28a745', // Soft green when focused
                  },
                },
              }}
            />
            {selectedUser && (
              <Typography variant="body1" sx={{ marginLeft: '10px', color: '#333333' }}>
                Account Type: {selectedUser.plan}
              </Typography>
            )}
          </Box>
        )}
      />
      <Box sx={{ marginBottom: '20px' }}>
        <FormControlLabel
          control={<Checkbox checked={showLabel} onChange={() => setShowLabel(!showLabel)} sx={{ color: '#28a745' }} />}
          label="Label"
        />
        <FormControlLabel
          control={<Checkbox checked={showModel} onChange={() => setShowModel(!showModel)} sx={{ color: '#28a745' }} />}
          label="Model"
        />
        <FormControlLabel
          control={<Checkbox checked={showUsedTokens} onChange={() => setShowUsedTokens(!showUsedTokens)} sx={{ color: '#28a745' }} />}
          label="Used Tokens"
        />
        <FormControlLabel
          control={<Checkbox checked={showWeek} onChange={() => setShowWeek(!showWeek)} sx={{ color: '#28a745' }} />}
          label="Week"
        />
        <FormControlLabel
          control={<Checkbox checked={showMonth} onChange={() => setShowMonth(!showMonth)} sx={{ color: '#28a745' }} />}
          label="Month"
        />
        <FormControlLabel
          control={<Checkbox checked={showYear} onChange={() => setShowYear(!showYear)} sx={{ color: '#28a745' }} />}
          label="Year"
        />
        <FormControlLabel
          control={<Checkbox checked={showNodeRuns} onChange={() => setShowNodeRuns(!showNodeRuns)} sx={{ color: '#28a745' }} />}
          label="Node Runs"
        />
      </Box>
      <Box sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <TextField
          label="Month (1-12)"
          type="number"
          value={monthInput}
          onChange={onMonthChange}
          inputProps={{ min: 1, max: 12 }} // Limit input to 1-12
          sx={{ marginRight: '10px', width: '150px' }}
        />
        <TextField
          label="Week"
          type="number"
          value={weekInput}
          onChange={onWeekChange}
          inputProps={{ min: 1 }} // Limit input to positive numbers
          sx={{ marginRight: '10px' }} // Add margin for spacing
        />
        <TextField
          label="Year"
          type="number"
          value={yearInput}
          onChange={onYearChange}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          border: '1px solid #ccc',
          backgroundColor: '#ffffff', // White background for the table
        }}
      >
        <Table sx={{ backgroundColor: '#ffffff' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E7F0FF' }}> {/* Light green header */}
              {/* <TableCell sx={{ color: '#ffffff' }}>{groupBy === 'month' ? 'Month' : 'Week'}</TableCell> */}
              {showLabel && <TableCell sx={{ color: '#333333' }}>Label</TableCell>}
              {showModel && <TableCell sx={{ color: '#333333' }}>Model</TableCell>}
              {showUsedTokens && <TableCell sx={{ color: '#333333' }}>Used Tokens</TableCell>}
              {showWeek && <TableCell sx={{ color: '#333333' }}>Week</TableCell>}
              {showMonth && <TableCell sx={{ color: '#333333' }}>Month</TableCell>}
              {showYear && <TableCell sx={{ color: '#333333' }}>Year</TableCell>}
              {showNodeRuns && <TableCell sx={{ color: '#333333' }}>Node Runs</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedData.length > 0 ? (
              groupedData
                ?.map((row, index) => (
                  <React.Fragment key={index}>
                    {/* Render month or week header based on group type */}
                  
                      <TableRow key={index}>
                        {showLabel && <TableCell>{row.label}</TableCell>}
                        {showModel && <TableCell>{row.model}</TableCell>}
                        {showUsedTokens && <TableCell>{row.usedTokens}</TableCell>}
                        {showWeek && <TableCell>{row.week}</TableCell>}
                        {showMonth && <TableCell>{row.month}</TableCell>}
                        {showYear && <TableCell>{row.year}</TableCell>}
                        {showNodeRuns && <TableCell>{row.nodeRuns}</TableCell>}
                      </TableRow>
                  </React.Fragment>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminDashboard;