import React, { useState, ChangeEvent, MouseEvent, useRef } from 'react';
import {
  TextField,
  Box,
  IconButton,
  InputAdornment,
  CircularProgress,
  Popper,
  Paper,
  ListItem,
  List,
  ListItemText,
} from '@mui/material';
import SearchIcon from '../../assets/icons/Search.png';
import CloseIcon from '../../assets/icons/Close.png';
import { FlowService } from '../../service/FlowService';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import useOutsideClick from './useOutsideClick';
import { useNavigate } from 'react-router-dom';

const SearchMenu: React.FC = () => {
  const searchRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [results, setResults] = useState<{ id: string; name: string, isForm: boolean }[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedFlowId, setSelectedFlowId] = useState<undefined | string>();

  useDebounceEffect(
    () => {
      const searchFlows = async () => {
        try {
          setLoading(true);

          const { data } = await FlowService.searchFlow(searchTerm);
          setResults(
            data.map(({ name, id, isForm }) => ({ name, id, isForm })),
          );
          setLoading(false);
        } catch (error) {}
      };

      if (searchTerm) searchFlows();
    },
    [searchTerm],
    500,
  );

  useOutsideClick(
    searchRef,
    () => {
      if (anchorEl) {
        handleClose();
      }
    },
    resultsRef,
  );

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (result: {
    name: string;
    id: string;
    isForm: boolean;
  }) => {
    setSearchTerm(result.name);
    setSelectedFlowId(result.id);
    handleClose();

    if (result.isForm) {
      navigate(`/form/${result.id}`);
      return;
    }

    navigate(`/flow/${result.id}`);
  };

  const clearSearch = () => {
    setSearchTerm('');
    handleClose();
  };

  const handleSearchIconClick = () => {
    if (selectedFlowId) navigate(`/flow/${selectedFlowId}`);
  };

  return (
    <Box sx={{ width: '600px' }} ref={searchRef}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search All Flows"
        value={searchTerm}
        onChange={handleInputChange}
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box
                component="div"
                sx={{ cursor: 'pointer' }}
                onClick={handleSearchIconClick}
              >
                <img src={SearchIcon} alt="Search" />
              </Box>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearSearch}>
                <img src={CloseIcon} alt="Close" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
        style={{ borderRadius: '8px' }}
      />

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: anchorEl?.clientWidth }}
      >
        <Paper sx={{ width: '100%', overflowY: 'auto', maxHeight: '350px' }}>
          {loading ? (
            <ListItem>
              <CircularProgress size={24} />
            </ListItem>
          ) : (
            <List ref={resultsRef}>
              {results.length > 0 ? (
                results.map((result, index) => (
                  <ListItem
                    sx={{ cursor: 'pointer' }}
                    key={index}
                    onClick={() => handleMenuItemClick(result)}
                  >
                    <ListItemText primary={result.name} />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No results" />
                </ListItem>
              )}
            </List>
          )}
        </Paper>
      </Popper>
    </Box>
  );
};

export default SearchMenu;
