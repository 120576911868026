import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='terms-of-service'>
      <div className='box-top-panel'>
        <h4>Terms of Service</h4>
      </div>
      <div className='base-outlet-content base-outlet-content--plaintext'>
        <p className='m-t-0'>
          This FluxPrompt Terms of Service ("Agreement") is between you ("The Customer" or "you") and FluxPrompt ("we", "us", "FluxPrompt", "The Company"). This Agreement may be modified from time to time, in which case we will make reasonable efforts to notify you of such changes. Continued usage of our services constitutes agreement with such changes.
        </p>
        <p>
          Please also see the corresponding{' '}
          <a href='/legal/privacy-policy/'>Privacy Policy</a> for details on how
          FluxPrompt manages your personal data.
        </p>
        <h2>Definitions</h2>
        <dl>
          <dd>
            <strong>"Services"</strong> refers to the various services provided
            by FluxPrompt to the Customer, which include, but are not limited to, access to multiple Large Language Models (LLMs), Voice AI systems, and the creation of business workflows.
          </dd>
          <dd>
            <strong>"Fees"</strong> refers to money paid by The Customer to
            FluxPrompt for use of Services.
          </dd>
          <dd>
            <strong>"FluxPrompt"</strong> refers to the web application at
            https://app.fluxprompt.ai, the services and APIs that power the platform, and tools that communicate with FluxPrompt APIs from different user environments.
          </dd>
          <dd>
            <strong>"User"</strong> a user of Services, through the
            https://app.fluxprompt.ai web application.
          </dd>
        </dl>
        <h2>Data Stored by FluxPrompt</h2>
        <p>
          When a project is connected to FluxPrompt, it may send the following data:
        </p>
        <ul>
          <li>
            Analytics data - containing application bootstrap timings, and other
            data necessary to power the advanced reporting and billing
            calculations in FluxPrompt.
          </li>
          <li>
            Serialized static application data - containing a serialized, static
            representation of your application structure (but not the code
            itself).
          </li>
        </ul>
        <h2>Data Security</h2>
        <p>
          All data, including computation results, analytics data, user metadata, and workspace metadata, are transmitted over secure HTTPS and are encrypted at rest. Data is stored in a database accessible by select FluxPrompt engineers.
        </p>
        <p>
          FluxPrompt is committed to user privacy and focuses on collecting and storing the minimal amount of data needed to provide valuable services to our users.
        </p>
        <h2>Intellectual Property and Usage</h2>
        <p>
          The Customer agrees not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion of the Services (including your FluxPrompt ID), use of the Services, or access to the Services. The Customer agrees not to reverse engineer the platform or system.
        </p>
        <h2>Subscriptions</h2>
        <p>
          Subscriptions may be created, edited, or deleted from the Settings
          page of the App. The pricing for each subscription plan will be as displayed on the App or as otherwise communicated to you.
        </p>
        <h2>Termination</h2>
        <p>
          Service may be terminated by FluxPrompt upon failure by the Customer to pay the Fees or upon breach of any of these Terms and Conditions. In the event of such termination, you will not be entitled to any refunds or credits for any partial periods of service, or refunds for any unused periods of service.
        </p>
      </div>
    </div>
  );
  
};

export default TermsAndConditions;
