import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Table as TableIcon, TextAlignLeft } from '@phosphor-icons/react';
import InputHandle from '../InputHandle/InputHandle';
import { Position } from 'reactflow';
import { useAuthStore } from '../../../store/storeAuth';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';
import { useState } from 'react';
import PreviewModal from '../PreviewModal/PreviewModal';

interface Tag {
  tag: string;
  text: string;
}

const replaceNtoBr = (text: string) => {
  if (!text) return '';

  // Replace new lines with <br> tags
  let updatedText = text.replace(/\n/g, '<br>');

  // Replace markdown bold and italic syntax
  updatedText = updatedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  updatedText = updatedText.replace(/\*(.*?)\*/g, '<em>$1</em>');

  // Replace markdown headers (e.g., ### Header)
  updatedText = updatedText.replace(/^(#{1,6})\s(.*)$/gm, (match, p1, p2) => {
    const level = p1.length; // Determine header level based on number of hashes
    return `<h${level}>${p2}</h${level}>`;
  });

  // Remove remaining markdown symbols (like # for headers)
  updatedText = updatedText.replace(/#/g, '');

  // Replace markdown unordered lists (e.g., * item)
  updatedText = updatedText.replace(/^\s*\*\s+(.*)$/gm, '<ul><li>$1</li></ul>');
  // Merge consecutive <ul> tags
  updatedText = updatedText.replace(/<\/ul>\s*<ul>/g, '');

  // Replace markdown ordered lists (e.g., 1. item)
  updatedText = updatedText.replace(
    /^\s*\d+\.\s+(.*)$/gm,
    '<ol><li>$1</li></ol>',
  );
  // Merge consecutive <ol> tags
  updatedText = updatedText.replace(/<\/ol>\s*<ol>/g, '');

  // Replace audio links with <audio> tags
  updatedText = updatedText.replace(
    /http(s)?:\/\/[^\s]+\.mp3/g,
    '<audio src="$&" controls></audio>',
  );

  // Replace image links with <img> tags
  updatedText = updatedText.replace(
    /http(s)?:\/\/[^\s]+\.(jpg|png)/g,
    match => `<img src="${match}" alt="image" />`,
  );

  return updatedText;
};

const OutputTable = ({
  value,
  findColor,
  handleId = 'output',
  activeHandle = false,
  inputActive = false,
  inputHandleId = 'inputAdd',
  placement,
  entries,
  showInputHandles = false,
}: any) => {
  const checkValue = typeof value === 'string' ? [] : value;
  const { user } = useAuthStore(state => state);
  const [onOpen, setOnOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState<string[]>([]);

  return (
    <Box mb={'16px'} height={'100%'}>
      {showInputHandles === true && (
        <>
          <InputHandle
            activeColor={findColor?.color}
            handleId={handleId}
            isActive={activeHandle}
            position={Position.Right}
            right={-26}
            top={'50%'}
            type="source"
          />
          <InputHandle
            activeColor={findColor?.color}
            handleId={inputHandleId}
            isActive={inputActive}
            position={Position.Left}
            left={-26}
            top={'14px'}
            type="target"
          />
        </>
      )}

      {!value?.length && (
        <>
          <Box
            sx={{
              borderRadius: '4px',
              border: value?.length ? '1px solid #98A2B3' : '',
              backgroundImage: value?.length
                ? ''
                : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              padding: '15px 8px',

              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',

              height: placement === 'Output' ? '480px' : '260px',
              width: placement === 'Output' ? '680px' : '768px',
              maxHeight: '480px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {(!value || value.length === 0) && (
              <Box
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={'8px'}
              >
                <TableIcon color="#98A2B3" size={32} />
                <Typography fontSize={'14px'} color={'#98A2B3'}>
                  Record and store all outputs of a flow by connecting
                  generators to this node.
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}

      {value && value?.length !== 0 && (
        <TableContainer
          sx={{
            maxHeight: '480px',
            py: showInputHandles === true ? '0px' : '8px',
            overflowY: 'auto',
            backgroundColor: user?.theme === 'dark' ? '#101828' : '#fff',
          }}
        >
          <Table>
            <TableHead
              sx={{
                background: user?.theme === 'dark' ? '#101828' : '#fff',
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    color: user?.theme === 'dark' ? '#fff' : 'black',
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    color: user?.theme === 'dark' ? '#fff' : 'black',
                  }}
                >
                  Timestamp
                </TableCell>
                <TableCell
                  sx={{
                    color: user?.theme === 'dark' ? '#fff' : 'black',
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    color: user?.theme === 'dark' ? '#fff' : 'black',
                  }}
                >
                  Content
                  {/* {value ? (
                    <IconButtonTooltip
                      onClick={() => setOnOpen(true)}
                      title="Preview"
                    >
                      <TextAlignLeft size={13} color={'#98A2B3'} />
                    </IconButtonTooltip>
                  ) : null} */}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                maxHeight: '480px',
                overflowY: 'auto',
              }}
            >
              {value?.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{
                    // verticalAlign: 'top',
                    color: user?.theme === 'dark' ? '#fff' : 'black',
                  }}
                >
                  <TableCell
                    sx={{
                      color: user?.theme === 'dark' ? '#fff' : 'black',
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: user?.theme === 'dark' ? '#fff' : 'black',
                    }}
                  >
                    {row?.timestamp}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: user?.theme === 'dark' ? '#fff' : 'black',
                    }}
                  >
                    {row?.role?.charAt(0)?.toUpperCase()}
                    {row?.role?.slice(1)}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: user?.theme === 'dark' ? '#fff' : 'black',
                      maxWidth: '600px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          width: '90%',
                          marginLeft: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {replaceNtoBr(row?.content)}
                      </Typography>
                      <IconButtonTooltip
                        onClick={() => {
                          setOnOpen(true);
                          setSelectedContent([row]);
                        }}
                        title="Preview"
                      >
                        <TextAlignLeft size={16} color={'#98A2B3'} />
                      </IconButtonTooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* <KitModal
        maxWidth={'md'}
        sx={{
          '& .MuiPaper-root': {
            width: '800px',
            maxHeight: '700px',
            borderRadius: '16px',
            pb: showInputHandles === true ? '0px' : '16px',
            backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
          },
          '& .MuiDialogContent-root': {
            padding: '16px',
            borderTop: 'none',
            borderBottom: 'none',
          },
          '& .MuiDialogActions-root': {
            padding: '16px',
          },
          p: '0px',
        }}
        fullWidth
        showCloseButton={true}
        show={onOpen}
        onHide={() => setOnOpen(false)}
        title={
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color={user?.theme === 'dark' ? '#F9FAFB' : '#101828'}
                fontSize="16px"
                fontWeight={600}
                lineHeight="150%"
              >
                Output Table
              </Typography>
            </Box>
          </>
        }
        headerStyles={{
          pb: '0px',
        }}
      >
        <OutputTable
          value={entries}
          labelName={'Store Content'}
          findColor={findColor}
        />
      </KitModal> */}
      <PreviewModal
        show={onOpen}
        onHide={() => setOnOpen(false)}
        isSmallMemory
        data={selectedContent}
      />
    </Box>
  );
};

export default OutputTable;
