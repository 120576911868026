import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class OpenAiService extends ApiService {
  static pathName = '/open-ai';

  static login = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static createImage = (body: {
    prompt: string;
    size: string;
    quality: string;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/create-image`, body);
  };

  static editImage = (
    query: {
      prompt: string;
      size?: string;
      numbersOfVariations?: string;
      flowId?: string;
    },
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/edit-image?prompt=${query.prompt}${
        query.size ? `&size=${query.size}` : ''
      }${
        query.numbersOfVariations
          ? `&numbersOfVariations=${query.numbersOfVariations}`
          : ''
      }${query.flowId ? `&flowId=${query.flowId}` : ''}`,
      body,
    );
  };

  static imageVariation = (
    query: {
      prompt: string;
      size?: string;
      numbersOfVariations?: string;
      flowId?: string;
    },
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/image-variation?prompt=${query.prompt}${
        query.size ? `&size=${query.size}` : ''
      }${
        query.numbersOfVariations
          ? `&numbersOfVariations=${query.numbersOfVariations}`
          : ''
      }${query.flowId ? `&flowId=${query.flowId}` : ''}`,
      body,
    );
  };

  static gpt4Preview = (
    query: {
      prompt: string;
      temperature?: string;
      maxTokens?: string;
      frequencyPenalty?: string;
      presencePenalty?: string;
      src?: string;
      model?: string;
      flowId?: string;
    },
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    const urls = [query?.src];

    const encodedUrls = urls.map(url => encodeURIComponent(url!)).join(',');

    return ApiService.post(
      `${this.pathName}/gpt4-vision-preview?prompt=${query.prompt}${
        query.temperature ? `&temperature=${query.temperature}` : ''
      }${query.maxTokens ? `&maxTokens=${query.maxTokens}` : ''}${
        query.frequencyPenalty
          ? `&frequencyPenalty=${query.frequencyPenalty}`
          : ''
      }${
        query.presencePenalty ? `&presencePenalty=${query.presencePenalty}` : ''
      }${query.src ? `&urls=${encodedUrls}` : ''}&model=${query.model}${
        query.flowId ? `&flowId=${query.flowId}` : ''
      }`,

      body,
    );
  };

  static convertAudioToText = ({
    file,
    url,
  }: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/create-transcription${url ? `?url=${url}` : ''}`,
      file,
    );
  };
}
