import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { PalmService } from '../service/PalmService';
import { useNotificationStore } from '../store/storeNotifications';
import { Button, Table } from '@mui/material';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import Mermaid from './UI/Mermaid';
import { OpenAiService } from '../service/OpenAiService';
import KitModal from './UI/KitModal';
import useFlowsStore from '../store/storeFlows';

type DataVisualizationModalProps = {
  resData: string;
  showDataModal: boolean;
  setShowDataModal: (show: boolean) => void;
};

type ChartData = {
  datasets: {
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
};

type TableData = {
  labels: string[];
  data: {
    [key: string]: string | number;
  }[];
};

const DataVisualizationModal = ({
  resData,
  showDataModal,
  setShowDataModal,
}: DataVisualizationModalProps) => {
  const { flowId } = useFlowsStore();
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState<ChartData>({ datasets: [] });
  const [tableData, setTableData] = useState<TableData>({
    labels: [],
    data: [],
  });
  const [mermaidData, setMermaidData] = useState(``);
  const [isLoading, setIsLoading] = useState(false);
  const [remountKey, setRemountKey] = useState(0);

  const labels = data?.[0] ? Object.keys(data?.[0]) : [];
  const [showChart, setShowChart] = useState({
    config: false,
    open: false,
    label: labels[0],
    value: labels[0],
  });
  const [showTable, setShowTable] = useState(false);
  const [showMermaid, setShowMermaid] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);
  const [sanitizedData, setSanitizedData] = useState('');

  const handleChartData = async () => {
    setIsLoading(true);
    try {
      if (resData && data.length <= 0) {
        const palmRes = await PalmService.response(
          {
            prompt:
              'If here is any data modify for me all that in a javascript single array of objects and make sure after every last property of an object a comma is there so I can parse it in javascript also the objects property values of the array should not be array or object, and dont write anything else in the response, not even a description and the array should not contain unescaped control characters, also make sure every property name is double-quoted, also make sure if there are objects in the array they should have the same properties :' +
              resData,
            modelName: 'models/text-bison-001',
            maxOutputTokens: +'1000',
            temperature: +'0.2',
            topP: +'0.8',
            topK: +'40',
            flowId,
          },
          'Text',
        );

        const sanitizedDataString = palmRes?.data?.message?.replace(/'/g, '"');
        setSanitizedData(sanitizedDataString);
        const parsedData = JSON.parse(sanitizedDataString);

        setShowChart({
          config: true,
          open: false,
          label: labels[0],
          value: labels[0],
        });
        setShowTable(false);
        setData(parsedData);
      } else {
        setShowChart({
          config: true,
          open: false,
          label: showChart.label,
          value: showChart.value,
        });
        setShowTable(false);
      }
    } catch (error) {
      setNotification({
        message: 'Something went wrong loading the data, try again',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleTableData = async () => {
    try {
      setIsLoading(true);
      if (resData && data.length <= 0) {
        const palmRes = await PalmService.response(
          {
            prompt:
              'If here is any data modify for me all that in a javascript single array of objects and make sure after every last property of an object a comma is there so I can parse it in javascript also the objects property values of the array should not be array or object, and dont write anything else in the response, not even a description and the array should not contain unescaped control characters, also make sure every property name is double-quoted, also make sure if there are objects in the array they should have the same properties:' +
              resData,
            modelName: 'models/text-bison-001',
            maxOutputTokens: +'1000',
            temperature: +'0.2',
            topP: +'0.8',
            topK: +'40',
            flowId,
          },
          'Text',
        );

        const sanitizedDataString = palmRes?.data?.message?.replace(/'/g, '"');
        setSanitizedData(sanitizedDataString);

        const parsedData = JSON.parse(sanitizedDataString);

        setShowTable(true);
        setData(parsedData);
        setShowChart({
          config: false,
          open: false,
          label: showChart.label,
          value: showChart.value,
        });
        setShowMermaid(false);
      } else {
        setShowTable(true);
        setShowChart({
          config: false,
          open: false,
          label: labels[0],
          value: labels[0],
        });
        setShowMermaid(false);
      }
    } catch (error) {
      setNotification({
        message: 'Something went wrong loading the data, try again',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleCodeView = async () => {
    try {
      setIsLoading(true);
      if (resData) {
        const palmRes = await PalmService.response(
          {
            prompt:
              'If here is any code return me only the code part cleaned and sanitized, without any parentheses, backticks or text that shows what language it is :' +
              resData,
            modelName: 'models/text-bison-001',
            maxOutputTokens: +'1000',
            temperature: +'0.2',
            topP: +'0.8',
            topK: +'40',
            flowId,
          },
          'Text',
        );

        const sanitizedDataString = palmRes?.data?.message?.replace(/'/g, '"');
        setSanitizedData(sanitizedDataString);
      }
    } catch (error) {
      setNotification({
        message: 'Something went wrong loading the data, try again',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleMermaidTreeData = async () => {
    try {
      setIsLoading(true);
      setShowMermaid(true);
      if (resData) {
        await OpenAiService.login({
          prompt:
            `If here is any data modify for me that into a (version 10.5.0) mermaidjs tree graphic (base in this mermaid syntax "
          graph TD
            A[Lionel Messi]
          
          subgraph Barcelona
          A-->|Appearances: 44| Appearances1
          A-->|Goals: 31| Goals1
          A-->|Assists: 27| Assists1
          end" 
          ) and wrapped in backticks so i can use it directly in the mermaid component, and only return me the graph part of it cleaned and sanitized, without any parentheses, or without text that shows what library it is and without any description:` +
            resData,
          model: 'gpt-4',
          fp: +'0.5',
          pp: +'0.5',
          tokens: +'1000',
          temperature: +'0.5',
          // role: prompt[0]?.role,
          flowId,
        })
          .then(async res => {
            const palmRes = await OpenAiService.login({
              prompt:
                'Return me the mermaid syntax part with without syntax errors and correct spaces and everything so i dont have errors in mermaid syntax , without any parentheses, and without any description of this respnse that i got:' + //
                resData,
              model: 'gpt-4',
              fp: +'0.5',
              pp: +'0.5',
              tokens: +'850',
              temperature: +'0.5',
              flowId,
            });

            const sanitizedDataString = palmRes?.data?.message?.replace(
              /`/g,
              ``,
            );
            setMermaidData(sanitizedDataString);
            setSanitizedData(sanitizedDataString);
            setRemountKey(prevKey => prevKey + 1);
          })
          .catch(e => {
            setIsLoading(false);
          });
      }
    } catch (error) {
      setNotification({
        message: 'Something went wrong loading the data, try again',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleHtmlView = async () => {
    try {
      setIsLoading(true);
      if (resData) {
        const palmRes = await PalmService.response(
          {
            prompt:
              'If here is any data return me it in full html template code (with head and body tags) cleaned and sanitized (without any backticks or text that shows what language it is), without any backticks or text that shows what language it is :' +
              resData,
            modelName: 'models/text-bison-001',
            maxOutputTokens: +'1000',
            temperature: +'0.2',
            topP: +'0.8',
            topK: +'40',
            flowId,
          },
          'Text',
        );

        const sanitizedDataString = palmRes?.data?.message?.replace(/'/g, '"');
        setSanitizedData(sanitizedDataString);
      }
    } catch (error) {
      setNotification({
        message: 'Something went wrong loading the data, try again',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (data.length === 0) return;

    const tableDataModified = {
      labels: Object.keys(data[0]),
      data: data,
    };
    setTableData(tableDataModified);
    // Set up chart data
    const chartDataModified = {
      datasets: [
        {
          data: Array.isArray(data)
            ? data?.map(item => item[showChart?.value])
            : [],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };

    setChartData(chartDataModified);
  }, [data, showChart, showTable]);

  return (
    <div>
      <KitModal
        fullWidth
        show={showDataModal}
        onHide={() => {
          setShowDataModal(false);
          setShowChart({
            config: false,
            open: false,
            label: '',
            value: '',
          });
          setShowTable(false);
          setChartData({ datasets: [] });
          setData([]);
          setTableData({ labels: [], data: [] });
          setSanitizedData('');
        }}
        title="Data Visualization"
      >
        <>
          <div style={{ display: 'flex', gap: 20 }}>
            <Button onClick={handleChartData}>
              {isLoading ? 'Loading...' : 'Show data in chart'}
            </Button>
            <Button onClick={handleTableData}>
              {' '}
              {isLoading ? 'Loading...' : 'Show data in table'}
            </Button>
            <Button onClick={handleMermaidTreeData}>
              {' '}
              {isLoading ? 'Loading...' : 'Show data in tree graphic'}
            </Button>
            <Button onClick={handleCodeView}>
              {' '}
              {isLoading ? 'Loading...' : 'Show Response Code'}
            </Button>
          </div>
          <div
            style={{
              marginTop: '20px',
            }}
          >
            {showChart.config && (
              <div style={{ display: 'flex', gap: '50px' }}>
                <div>
                  <p style={{ margin: 0 }}>Show value label based on:</p>
                  <select
                    value={showChart.label}
                    onChange={e =>
                      setShowChart({
                        config: true,
                        open: false,
                        label: e.target.value,
                        value: showChart.value,
                      })
                    }
                  >
                    {labels.map(label => (
                      <option>{label}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <b>Warning: Make sure this is number</b>
                  <p style={{ margin: 0 }}>Show value based on :</p>

                  <select
                    value={showChart.value}
                    onChange={e =>
                      setShowChart({
                        config: true,
                        open: false,
                        label: showChart.label,
                        value: e.target.value,
                      })
                    }
                  >
                    {labels.map(label => (
                      <option key={label} value={label}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <Button
                  disabled={
                    !showChart.value ||
                    !showChart.label ||
                    data[0][showChart.value] !==
                      Number(data[0][showChart.value])
                  }
                  onClick={() =>
                    setShowChart({
                      config: false,
                      open: true,
                      label: showChart.label,
                      value: showChart.value,
                    })
                  }
                >
                  Show Chart
                </Button>
              </div>
            )}
          </div>
          <div>
            {showChart.open && (
              <ReactApexChart
                options={{
                  xaxis: {
                    categories: data.map(
                      item => item[showChart?.label] ?? item,
                    ),
                  },
                }}
                series={chartData?.datasets ? chartData?.datasets : []}
                type={'bar'}
                height="300"
              />
            )}
          </div>
          <div>
            {showTable && (
              <Table>
                <thead>
                  <tr>
                    {tableData?.labels?.map(item => (
                      <th>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.data?.map((item, i: number) => {
                    return (
                      <tr>
                        {tableData?.labels?.map((label, index) => {
                          return <td>{item?.[label]}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
          <div>
            {showMermaid && (
              <div>
                <Button onClick={handleHtmlView}>
                  {isLoading ? 'Loading...' : 'Show HTML'}
                </Button>
                <Mermaid key={remountKey} chart={mermaidData} />
              </div>
            )}
          </div>

          <div
            style={{
              backgroundColor: 'black',
              color: 'white',
              padding: '20px',
              borderRadius: '5px',
            }}
          >
            <AceEditor
              height="400px"
              width="100%"
              value={sanitizedData ?? undefined}
              mode="javascript"
              theme="monokai"
              fontSize="16px"
              highlightActiveLine={true}
              setOptions={{
                enableLiveAutocompletion: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
        </>
      </KitModal>
    </div>
  );
};

export default DataVisualizationModal;
