import React, { memo, useEffect, useState } from 'react';
import FormNode from '../Components/FormNode/FormNode';
import { NodeProps, useReactFlow } from 'reactflow';
import { useDebounceEffect } from '../../../util/useDebounceEffect';
import PopoverEdit from '../TitleNode/Components/PopoverEdit';
import FormBuildInput from '../FormBuildInput/FormBuildInput';

const ShortText = ({ id, type, data, selected }: NodeProps) => {
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    text: '',
    borderRadius: data.borderRadius || '4px',
    titleStyle: {
      fontSize: '20px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
    subtitleStyle: {
      fontSize: '14px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
    textStyle: {
      fontSize: '14px',
      color: '#475467',
      textAlign: 'center',
      fontStyle: 'normal',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      textDecoration: 'none',
      selStyle: [],
    },
  });
  const reactFlow = useReactFlow();
  const [editingType, setEditingType] = useState('titleStyle');

  const [changes, setChanges] = useState<any>({
    directly: false,
    value: '',
    name: '',
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (data && !changes?.directly) {
      setFormData({
        title: data.title || '',
        subtitle: data.subtitle || '',
        borderRadius: data.borderRadius || '4px',
        text: data.text || '',
        titleStyle: {
          ...formData?.titleStyle,
          ...data.titleStyle,
        },
        subtitleStyle: {
          ...formData?.subtitleStyle,
          ...data.subtitleStyle,
        },
        textStyle: {
          ...formData?.textStyle,
          ...data.textStyle,
        },
      });
    }
  }, [data, changes]);

  const onChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setChanges({ directly: true, value, name });
  };

  const handleClick = (type: string) => {
    setOpenEditModal(true);
    setEditingType(type);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };
  useDebounceEffect(
    () => {
      if (changes.directly) {
        const nodes = reactFlow.getNodes();

        const mappedNodes = nodes.map(node => {
          if (node.id === id) {
            if (changes?.name === 'widthData') {
              return {
                ...node,
                width: changes.value?.width,
                style: {
                  width: changes.value?.width,
                },
                data: {
                  ...node.data,
                  [changes.name]: changes.value,
                },
              };
            }

            return {
              ...node,
              data: {
                ...node.data,
                [changes.name]: changes.value,
              },
            };
          }
          return node;
        });

        reactFlow.setNodes(mappedNodes);

        setChanges({ directly: false, value: '', name: '' });
      }
    },
    [changes],
    500,
  );
  return (
    <FormNode borderRadius={formData.borderRadius} id={id} type={type}>
      <FormBuildInput
        data={data}
        formData={{
          name: 'title',
          value: formData.title,
          placeholder: 'Add title here...',
          style: formData.titleStyle,
          titleEmpty: 'Title',
        }}
        editingType={editingType}
        editType={'titleStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      <FormBuildInput
        data={data}
        formData={{
          name: 'subtitle',
          value: formData.subtitle,
          placeholder: 'Add subtitle here...',
          style: formData.subtitleStyle,
          titleEmpty: 'Subtitle (Optional)',
        }}
        editingType={editingType}
        editType={'subtitleStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      <FormBuildInput
        data={data}
        formData={{
          name: 'text',
          value: formData.text,
          placeholder: 'Add description here...',
          style: formData.textStyle,
          titleEmpty: 'Description(Optional)',
        }}
        editingType={editingType}
        editType={'textStyle'}
        handleClick={handleClick}
        isEditing={isEditing}
        onChanges={onChanges}
        setEditingType={setEditingType}
        setIsEditing={setIsEditing}
      />

      {openEditModal && (
        <PopoverEdit
          open={openEditModal}
          handleClose={handleClose}
          onChange={onChanges}
          selectedStyle={formData?.[editingType as keyof typeof formData]}
          editingType={editingType}
          modalStyle={{
            bgColor: data?.bgColor ? data?.bgColor : '#ffffff',
            widthData: data?.widthData,
            borderRadius: formData.borderRadius || '8px',
          }}
        />
      )}
    </FormNode>
  );
};

export default memo(ShortText);
