import { Typography } from '@mui/material';
import React from 'react';
import { Handle, Position } from 'reactflow';

type ConditionHandleProps = {
  index?: number;
  colorActive?: { background: string; color: string };
  label?: string;
  handleId: string;
  top?: string;
  style?: React.CSSProperties;
};

const ConditionHandle = ({
  index,
  label,
  colorActive,
  handleId,
  top,
  style,
}: ConditionHandleProps) => {
  return (
    <Handle
      type='source'
      position={Position.Right}
      id={handleId}
      style={{
        background: colorActive?.background,
        position: 'absolute',
        width: '20px',
        right: -28,
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: top,
        ...style,
      }}
    >
      <Typography
        sx={{
          color: '#fff',
          fontSize: '12px',
          fontWeight: style ? 400 : 700,
          lineHeight: '0px',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      >
        {label}
      </Typography>
    </Handle>
  );
};

export default ConditionHandle;
