import React from 'react';
import KitModal from '../UI/KitModal';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as YellowLinkIcon } from '../../assets/icons/YellowLink.svg';

interface Props {
  onHide: () => void;
  logoutHandler: () => void;
}
const LogoutModal: React.FC<Props> = ({ onHide, logoutHandler }) => {
  return (
    <KitModal fullWidth showCloseButton={true} onHide={onHide} show={true}>
      <Box
        sx={{
          padding: '30px 0',
          paddingBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <YellowLinkIcon />
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: '28px',
            letterSpacing: '0.15px',
            textAlign: 'center',
            color: 'rgba(71, 84, 103, 1)',
            width: '60%',
          }}
        >
          Are you sure you want to log out of this linked connection? All your
          nodes using this connection will not work.
        </Typography>

        <Box sx={{ display: 'flex', gap: '15px', marginTop: '20px' }}>
          <Button onClick={onHide} variant="outlined">
            Cancel
          </Button>
          <Button onClick={logoutHandler} variant="contained">
            Log out
          </Button>
        </Box>
      </Box>
    </KitModal>
  );
};

export default LogoutModal;
