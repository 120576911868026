import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import KitInput from "../../UI/KitInput";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from "react-hook-form";
import { EvenLabsService } from "../../../service/EvanLabsServce";
import { systemDisplayNameMap, systemValueMap } from "../../AiConfigs/constants";

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[];
}

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  marginLeft: "10px",
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ElevenlabsConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems,
}: Props) => {
  const [voices, setVoices] = useState<any[]>([]);

  useEffect(() => {
    const getVoices = async () => {
      try {
        const response = await EvenLabsService.voices();
        setVoices(response.data?.voices);
      } catch (error) {
        console.log(error);
      }
    };
    getVoices();
  }, []);

  const makePercentage = (value: number) => {
    // if value is 0.5, return 50 from 0.0 to 1.0
    return Math.round(value * 100);
  };
  return (
    <Box>
      {/* <KitInput
        label='Name'
        name='name'
        type='text'
        errorsStatus={errors}
        register={register}
      /> */}
      <Box
        mt={'16px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            name="aiSystem"
            onChange={e => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem key={index} value={systemValue} disabled={disabled}>
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Model</InputLabel>
          <Select
            value={watch()?.evenLabsModel}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Model"
            {...register('evenLabsModel')}
            sx={{
              width: '100%',
            }}
          >
            {systems
              .filter((item: any) => item.system === 'evenLabs')[0]
              .models?.map((opt: any) => (
                <MenuItem
                  key={opt.id}
                  value={opt.model}
                  disabled={opt.disabledByCompany}
                >
                  {opt.model}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <FormControl
        sx={{
          width: '100%',
          mt: '16px',
        }}
      >
        <InputLabel id="demo-simple-select-label">Voice</InputLabel>
        <Select
          value={watch()?.voiceId}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Voice"
          {...register('voiceId')}
          sx={{
            width: '100%',
          }}
        >
          {voices.map(voice => (
            <MenuItem key={voice.voice_id} value={voice.voice_id}>
              {voice.name}{' '}
              <>
                {voice.labels.accent} / {voice.labels.gender}
              </>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider
        sx={{
          marginY: '16px',
        }}
      />

      <Box px={'20px'} textAlign={'center'}>
        <Typography>Stability: {makePercentage(watch().stability)}%</Typography>
        <Slider
          {...register('stability')}
          step={0.1}
          min={0}
          max={1}
          value={Number(watch()?.stability)}
        />
      </Box>

      <Box px={'20px'} textAlign={'center'} mt={'22px'}>
        <Typography>
          Similarity Boost: {makePercentage(watch().similarity_boost)}%
        </Typography>
        <Slider
          {...register('similarity_boost')}
          step={0.1}
          min={0}
          max={1}
          value={Number(watch()?.similarity_boost)}
        />
      </Box>

      <Box px={'20px'} textAlign={'center'} mt={'22px'}>
        <Typography>Style: {makePercentage(watch()?.style)}%</Typography>
        <Slider
          {...register('style')}
          step={0.1}
          min={0}
          max={1}
          value={Number(watch()?.style)}
        />
      </Box>
      <Box
        sx={{
          mt: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Controller
          name="use_speaker_boost"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              sx={{
                margin: 0,
              }}
              control={
                <AntSwitch {...field} checked={watch().use_speaker_boost} />
              }
              label="Use Speaker Boost"
              labelPlacement="start"
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ElevenlabsConfigForm;
