import React, { useState } from 'react';
import { tableDataTypes } from '../nodes/TableProcessor';
import { Box, Button, TextField } from '@mui/material';
import KitModal from '../UI/KitModal';

export const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

type Props = {
  tableData: tableDataTypes[];

  setTableData: React.Dispatch<React.SetStateAction<tableDataTypes[]>>;
  id: string;
  setIsChangingDirectly: React.Dispatch<React.SetStateAction<boolean>>;
  setWhoIsChanging: React.Dispatch<
    React.SetStateAction<{
      name: string;
      value: any;
    }>
  >;
  type?: string;
};

const Table = ({
  tableData,
  setTableData,
  id,
  setWhoIsChanging,
  setIsChangingDirectly,
  type,
}: Props) => {
  const [editable, setEditable] = useState({ rowIndex: '-1', colIndex: '-1' });
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);
  const [newColumnName, setNewColumnName] = useState('');

  const onAddRow = () => {
    const newRowId = generateUniqueId();
    const newRow = {
      id: newRowId,
      columns: tableData[0].columns.map(column => ({
        id: generateUniqueId() + Math.random(),
        name: column.name,
        value: '',
        selected: column.selected,
      })),
    };

    setTableData([...tableData, newRow]);

    setWhoIsChanging({
      name: 'tableData',
      value: [...tableData, newRow],
    });
    setIsChangingDirectly(true);
  };

  const onAddColumn = () => {
    if (newColumnName === '') {
      return alert('Please enter column name');
    }

    const newColumnDt = {
      id: generateUniqueId(),
      name: newColumnName,
      value: '',
      selected: false,
    };
    const newColumn = tableData
      .map(row => {
        return {
          ...row,
          columns: [...row.columns, newColumnDt],
        };
      })
      .map((row, index) => {
        return {
          ...row,
          columns: row.columns.map((col, colIndex) => {
            return {
              ...col,
              id: generateUniqueId(),
            };
          }),
        };
      });
    setTableData(newColumn);

    setWhoIsChanging({
      name: 'tableData',
      value: newColumn,
    });
    setIsChangingDirectly(true);

    setShowAddColumnModal(false);
    setNewColumnName('');
  };

  const onEdit = (rowIndex: string, colIndex: string) => {
    setEditable({ rowIndex, colIndex });
  };

  const onSave = () => {
    setEditable({ rowIndex: '-1', colIndex: '-1' });
  };

  const onChangeValue = (rowId: string, colId: string, value: string) => {
    const table = tableData.map(row => {
      if (row.id !== rowId) {
        return row;
      }

      return {
        ...row,
        columns: row?.columns?.map(col => {
          if (col.id !== colId) {
            return col;
          }

          return {
            ...col,
            value: value,
          };
        }),
      };
    });
    setTableData(table);

    setIsChangingDirectly(true);
    setWhoIsChanging({
      name: 'tableData',
      value: table,
    });
  };

  const onSelectColumn = (colId: string, selected: boolean) => {
    const findColIndex = tableData[0].columns.findIndex(
      col => col.id === colId
    );

    const table = tableData.map(row => {
      return {
        ...row,
        columns: row?.columns?.map((col, index) => {
          if (index !== findColIndex) {
            return {
              ...col,
            };
          }

          return {
            ...col,
            selected: selected,
          };
        }),
      };
    });

    setTableData(table);

    setIsChangingDirectly(true);
    setWhoIsChanging({
      name: 'tableData',
      value: table,
    });
  };
  const addColumnButton = (
    <Button variant='contained' onClick={() => setShowAddColumnModal(true)}>
      Add Column
    </Button>
  );
  const addRowButton = (
    <Button variant='contained' onClick={onAddRow}>
      Add Row
    </Button>
  );

  return (
    <Box>
      <table>
        <thead>
          <tr>
            {tableData?.[0]?.columns?.map((column, colIndex) => (
              <th
                style={{
                  border: '1px solid black',
                  padding: '10px',
                  textAlign: 'center',
                  backgroundColor: 'blue',
                  color: 'white',
                  width: '200px',
                }}
                onClick={() => onSelectColumn(column.id, !column.selected)}
                key={colIndex}
              >
                {column?.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row?.columns?.map((column, colIndex) => (
                <td
                  style={{
                    border: '1px solid black',
                    padding: '10px',
                    textAlign: 'center',
                    width: '150px',
                  }}
                  key={colIndex}
                >
                  {editable.rowIndex === row.id &&
                  editable.colIndex === column.id ? (
                    <input
                      type='text'
                      value={column.value}
                      style={{
                        width: '150px',
                      }}
                      onChange={e => {
                        onChangeValue(row.id, column.id, e.target.value);
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          onSave();
                        }
                      }}
                      onBlur={onSave}
                    />
                  ) : (
                    <>
                      {column?.value === '' && type !== 'output' && (
                        <input
                          type='text'
                          value={column.value}
                          style={{
                            width: '150px',
                          }}
                          onChange={e => {
                            onEdit(row.id, column.id);
                            onChangeValue(row.id, column.id, e.target.value);
                          }}
                          onBlur={onSave}
                        />
                      )}
                      <span
                        style={{
                          cursor: 'pointer',
                          color:
                            column.selected && type !== 'output'
                              ? 'red'
                              : 'white',
                        }}
                        onDoubleClick={() => onEdit(row.id, column.id)}
                      >
                        {column.value}
                      </span>
                    </>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {type !== 'output' && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          {addColumnButton}
          {addRowButton}
        </Box>
      )}
      <KitModal
        show={showAddColumnModal}
        onHide={() => setShowAddColumnModal(false)}
        title='Add New Column'
        footer={
          <>
            <Button
              variant='contained'
              onClick={() => setShowAddColumnModal(false)}
            >
              Close
            </Button>
            <Button variant='contained' onClick={onAddColumn}>
              Add Column
            </Button>
          </>
        }
      >
        <>
          <TextField
            type='text'
            placeholder='Enter Column Name'
            value={newColumnName}
            name='newColumnName'
            onChange={e => setNewColumnName(e.target.value)}
          />
        </>
      </KitModal>
    </Box>
  );
};

export default Table;
