import React from 'react';
import { inputFields } from '../AiConfigs/constants';
import { Box, FormLabel, MenuItem, Select, TextField } from '@mui/material';
import { PrettoSlider } from '../../assets/styles/styles';

type OpenAiProps = {
  formData: any;
  onChange: (e: any) => void;
};

const OpenAi = ({ formData, onChange }: OpenAiProps) => {
  return (
    <>
      <Box>
        <FormLabel
          sx={{
            mb: '4px',
          }}
          htmlFor="openAiModel"
        >
          Open Ai Modal
        </FormLabel>

        <Select
          className="nodrag"
          fullWidth
          value={formData.openAiModel}
          size="small"
          onChange={onChange}
          name="openAiModel"
        >
          <MenuItem value="gpt-4o">GPT-4o</MenuItem>
        </Select>
      </Box>
      {inputFields.map(opt => {
        const name: any = opt.name;
        const optName: 'aiTokens' | 'aiFp' | 'aiTemperature' | 'aiPp' = name;
        if (opt.name === 'aiTokens') {
          return (
            <Box
              sx={{
                gap: '30px',
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid gray',
                borderBottom: '1px solid gray',
                padding: '5px 0 ',
                mb: '10px',
                mt: '5px',
              }}
              key={optName}
            >
              <FormLabel htmlFor="aiTokens">{opt.label}</FormLabel>

              <TextField
                type={opt.type}
                style={{ marginLeft: '8px', width: '100px' }}
                value={formData[optName]}
                onChange={onChange}
                disabled={false}
                className="nodrag"
                name={opt.name}
              />
            </Box>
          );
        }
        return (
          <Box
            className="d-flex justify-content-between align-items-center"
            key={opt.name}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              justifyContent={'space-between'}
            >
              <FormLabel htmlFor={optName}>{opt.label}</FormLabel>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <PrettoSlider
                  step={Number(opt.step)}
                  min={Number(opt.min)}
                  max={Number(opt.max)}
                  style={{ marginLeft: '8px', width: '60px' }}
                  name={opt.name}
                  value={Number(formData?.[optName])}
                  onChange={onChange}
                  className="nodrag"
                />
                <TextField
                  type={'text'}
                  style={{ marginLeft: '8px', width: '100px' }}
                  value={formData[optName]}
                  onChange={onChange}
                  disabled={false}
                  className="nodrag"
                  name={opt.name}
                />
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default OpenAi;
