import React from 'react';
import { WebTrendsFrom } from '../nodes/WebTrends';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { googleTrendsGeoLocations } from '../../util/countries';
import Label from '../UI/Label/Label';
const dataTypes = {
  TIMESERIES: 'Interest over time',
  GEO_MAP: 'Compared breakdown by region',
  GEO_MAP_0: 'Interest by region',
  RELATED_TOPICS: 'Related topics',
  RELATED_QUERIES: 'Related queries',
};

const groupTypes = {
  images: 'Image Search',
  news: 'News Search',
  froogle: 'Google Shopping',
  youtube: 'Youtube Search',
};

export const dateTypes = [
  'now 1-H',
  'now 4-H',
  'now 1-d',
  'now 7-d',
  'today 1-m',
  'today 3-m',
  'today 12-m',
  'today 5-y',
  'all',
];

const timeZones = [420, 1120, -300, 660, -600];

const regionTypes = ['COUNTRY', 'REGION', 'DMA', 'CITY'];

type Props = {
  formData: WebTrendsFrom;
  onChange: (e: any) => void;
};

const WebTrendsSettings = ({ formData, onChange }: Props) => {
  return (
    <Box>
      <FormControl
        sx={{
          mb: '16px',
          mt: '16px',
        }}
        fullWidth
      >
        <InputLabel htmlFor='geo'>Location</InputLabel>
        <Select
          name='geo'
          className='nodrag'
          value={formData.geo}
          onChange={onChange}
          label='Location'
          native
        >
          {Object.entries(googleTrendsGeoLocations).map(([code, name]) => (
            <option className='nodrag' key={code} value={code}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          mb: '16px',
        }}
        fullWidth
      >
        <InputLabel htmlFor='tz'>Time Zone</InputLabel>
        <Select
          name='tz'
          label='Time Zone'
          className='nodrag'
          value={formData.tz || 'no value'}
          onChange={onChange}
          size='small'
        >
          <MenuItem value={'no value'} disabled>
            No time zone
          </MenuItem>
          {timeZones.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Label labelName='Region' isAdvanced />
      <Select
        name='region'
        className='nodrag'
        value={formData.region}
        onChange={onChange}
        size='small'
        sx={{
          bgcolor: '#EAECF0',
          borderRadius: '8px',
          border: 'none',
          height: '30px',
          textTransform: 'capitalize',

          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        <MenuItem value={''}>No region</MenuItem>
        {regionTypes.map(type => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
      <Box mt='16px' />
      <Label labelName='Date' isAdvanced />
      <Select
        name='date'
        className='nodrag'
        value={formData.date}
        onChange={onChange}
        size='small'
        sx={{
          bgcolor: '#EAECF0',
          borderRadius: '8px',
          border: 'none',
          height: '30px',
          textTransform: 'capitalize',

          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        {dateTypes.map((type, index) => (
          <MenuItem key={index} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default WebTrendsSettings;
