import { Fragment, useRef, useState } from 'react';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { Segment } from '../UI/PreviewModal/PreviewModal';
import AceEditor from 'react-ace';
import ReactMarkdown from 'react-markdown';
import { Box, Button } from '@mui/material';
import {
  extractImageUrl,
  generateDataGridFromTable,
} from '../../util/markdown';
import {
  onDownloadFile,
  onDownloadHtmlContent,
} from '../../util/fileDownloader';
import { Copy, DownloadSimple } from '@phosphor-icons/react';
import parse from 'html-react-parser';
import { ResponseDataGridTable } from '../UI/OutputTextarea/ResponseDataGridTable';
import { User } from '../../util/Types/User';
type Props = {
  segment: Segment;
  onCopy: (text: string) => void;
  user: User;
  segments: Segment[];
  index: number;
  value: string;
  autoPlay?: boolean;
};

const parseOptions = {
  replace: (domNode: any) => {
    if (domNode.name === 'img') {
      const { src, alt } = domNode.attribs;
      return (
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            maxWidth: '100%',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            // Add more styling options here if needed
          }}
        />
      );
    }
  },
};

const OutputMarkDown = ({
  segment,
  onCopy,
  user,
  segments,
  index,
  value,
  autoPlay,
}: Props) => {
  const [renderedSegments, setRenderedSegments] = useState(
    new Array(segments.length).fill(true),
  );
  const containsCode = segments.some((segment: any) => segment.type === 'code');

  const [active, setActive] = useState('preview');

  const iframeRefs = useRef<(HTMLIFrameElement | null)[]>([]);

  const executeCodeInIframe = (index: number, content: string) => {
    const updatedRenderedSegments = [...renderedSegments];
    updatedRenderedSegments[index] = true;
    setRenderedSegments(updatedRenderedSegments);

    const validHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Preview</title>
      </head>
      <body>${content}</body>
      </html>`;

    if (iframeRefs.current[index]) {
      iframeRefs.current[index]!.srcdoc = validHtml;
    }
  };

  const removeToLinkAny = (content: string) => {
    // remove this letters from link ' ""
    const regex = /['"]/g;
    return content.replace(regex, '');
  };

  const switchToCode = (index: number) => {
    const updatedRenderedSegments = [...renderedSegments];
    updatedRenderedSegments[index] = false;
    setRenderedSegments(updatedRenderedSegments);
  };

  const segmentTypeCheck = (segmentType: string, content: string) => {
    if (segmentType === 'link') {
      const imageRegex = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;
      const audioRegex = /\.(mp3|wav|ogg|flac|aac|m4a)$/i;

      if (imageRegex.test(content)) {
        return {
          type: 'img',
          content: `<img src="${content}" alt="Image" />`,
        };
      } else if (audioRegex.test(content)) {
        return {
          type: 'audio',
          content: `<audio controls ${
            autoPlay && 'autoplay'
          }><source src="${content}" type="audio/mpeg"></audio>`,
        };
      } else {
        return {
          type: 'link',
          content: content,
        };
      }
    }

    return {
      type: segmentType,
      content: segment.content,
    };
  };

  const newSegmentType = segmentTypeCheck(segment.type, segment.content);

  const checkContentHasHtml = (content: string) => {
    const htmlRegex = /<[^>]*>/g;
    return htmlRegex.test(content);
  };

  const contentIsHtml = checkContentHasHtml(newSegmentType.content);

  return (
    <Fragment>
      {newSegmentType.type === 'code' ? (
        <Box
          sx={{
            width: '99%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            backgroundColor: active === 'preview' ? '#1A1A2E' : '',
            // padding: '8px',
          }}
        >
          {contentIsHtml && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: '#1A1A2E',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                gap: '8px',
                padding: '4px 8px 8px 0',
              }}
            >
              <Button
                onClick={() => {
                  setActive('code');
                  switchToCode(index);
                }}
                sx={{
                  color: active === 'code' ? '#a3bffa' : '#a0aec0',
                  textTransform: 'none',
                  fontWeight: active === 'code' ? 'bold' : 'normal',
                  borderBottom:
                    active === 'code' ? '2px solid #a3bffa' : 'none',
                  borderRadius: 0,
                }}
              >
                Code
              </Button>
              <Button
                onClick={() => {
                  setActive('preview');
                  executeCodeInIframe(index, segment.content);
                }}
                sx={{
                  color: active === 'preview' ? '#a3bffa' : '#a0aec0',
                  textTransform: 'none',
                  fontWeight: active === 'preview' ? 'bold' : 'normal',
                  borderBottom:
                    active === 'preview' ? '2px solid #a3bffa' : 'none',
                  marginRight: '16px',
                  borderRadius: 0,
                }}
              >
                Preview
              </Button>
              <IconButtonTooltip
                onClick={() => onCopy(segment.content)}
                title="Copy Code"
              >
                <Copy color="#98A2B3" size={18} weight="bold" />
              </IconButtonTooltip>
              <IconButtonTooltip
                onClick={() => {
                  if (segment.isHtml) {
                    onDownloadHtmlContent(segment.content, 'code');
                  } else {
                    onDownloadFile(segment.content, 'code');
                  }
                }}
                title="Download Code"
              >
                <DownloadSimple color="#98A2B3" size={18} weight="bold" />
              </IconButtonTooltip>
            </Box>
          )}
          {renderedSegments[index] ? (
            <Box sx={{ position: 'relative' }}>
              {contentIsHtml ? (
                <Box
                  sx={{
                    padding: '8px',
                  }}
                >
                  {parse(newSegmentType.content)}
                </Box>
              ) : (
                <AceEditor
                  mode="javascript"
                  theme="monokai"
                  name={'editor'}
                  value={newSegmentType.content}
                  fontSize={14}
                  style={{
                    borderRadius: '8px',
                    width: '100%',
                  }}
                  lineHeight={19}
                  // showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  maxLines={Infinity}
                  minLines={1}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                  }}
                  readOnly
                />
              )}
            </Box>
          ) : (
            <Box sx={{ position: 'relative' }}>
              <AceEditor
                mode={'javascript'}
                theme="monokai"
                name={'editor'}
                value={newSegmentType.content}
                fontSize={14}
                style={{
                  borderRadius: '8px',
                  width: '100%',
                }}
                lineHeight={19}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                maxLines={Infinity}
                minLines={1}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2,
                  useWorker: false,
                }}
                readOnly
              />
            </Box>
          )}
        </Box>
      ) : newSegmentType.type === 'table' ? (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <ResponseDataGridTable
            tableData={generateDataGridFromTable(segment.content)}
          />
        </Box>
      ) : newSegmentType.type === 'img' && !containsCode ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            backgroundColor: '#E9ECF0',
            // padding: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#E9ECF0',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              gap: '8px',
              paddingRight: '8px',
              paddingBottom: '8px',
              paddingTop: '4px',
            }}
          >
            <IconButtonTooltip
              onClick={() => onCopy(extractImageUrl(segment.content))}
              title="Copy image url"
            >
              <Copy color="#98A2B3" size={18} weight="bold" />
            </IconButtonTooltip>
            <IconButtonTooltip
              onClick={() =>
                onDownloadFile(extractImageUrl(segment.content), 'image')
              }
              title="Download Image"
            >
              <DownloadSimple color="#98A2B3" size={18} weight="bold" />
            </IconButtonTooltip>
          </Box>
          {parse(newSegmentType.content, parseOptions)}
        </Box>
      ) : newSegmentType.type === 'audio' && !containsCode ? (
        <Box
          sx={{
            width: '68%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: '8px',
            border: '1px solid #D0D5DD',
            backgroundColor: '#E9ECF0',
            padding: '8px',
            // padding: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#E9ECF0',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
              gap: '8px',
              paddingRight: '8px',
              paddingBottom: '6px',
              paddingTop: '4px',
            }}
          >
            <IconButtonTooltip
              onClick={() => onCopy(extractImageUrl(segment.content))}
              title="Copy audio url"
            >
              <Copy color="#98A2B3" size={18} weight="bold" />
            </IconButtonTooltip>
            <IconButtonTooltip
              onClick={() =>
                onDownloadFile(extractImageUrl(segment.content), 'audio')
              }
              title="Download Audio"
            >
              <DownloadSimple color="#98A2B3" size={18} weight="bold" />
            </IconButtonTooltip>
          </Box>
          {parse(newSegmentType.content, parseOptions)}
        </Box>
      ) : newSegmentType.type === 'link' ? (
        <div>
          <a
            style={{
              textWrap: 'wrap',
              padding: '15px 8px',
              color: user?.theme === 'dark' ? '#98A2B3' : '#3650DE',
              maxWidth: '100%',
              wordBreak: 'break-all',
            }}
            href={removeToLinkAny(newSegmentType.content)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {removeToLinkAny(newSegmentType.content)}
          </a>
        </div>
      ) : (
        <div
          style={{
            padding: '15px 8px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            color: user?.theme === 'dark' ? '#98A2B3' : 'black',
          }}
        >
          {/* {newSegmentType === 'html' ? (
          parse(segment.content)
        ) : segment.content ? (
          <ReactMarkdown>{segment.content}</ReactMarkdown>
        ) : (
          <p>{value}</p>
        )} */}
          <ReactMarkdown>{newSegmentType.content}</ReactMarkdown>
        </div>
      )}
    </Fragment>
  );
};

export default OutputMarkDown;
