import {
  Box,
  Button,
  Collapse,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { User } from '../../../../util/Types/User';
import {
  Archive,
  CaretDown,
  CaretUp,
  Plus,
  DotsThree,
  Trash,
  PencilSimpleLine,
  NotePencil,
  CaretRight,
} from '@phosphor-icons/react';
import { UserService } from '../../../../service/UserService';
import { AuthService } from '../../../../service/AuthService';
import ObjectModal from './ObjectModal';
import { ReactComponent as DotsThreeOutlineGrayIcon } from '../../../../assets/icons/DotsThreeOutlineGrayIcon.svg';
import { ReactComponent as DotsThreeOutlineWhiteIcon } from '../../../../assets/icons/DotsThreeOutlineWhiteIcon.svg';
import { useNotificationStore } from '../../../../store/storeNotifications';
import IconButtonTooltip from '../../../UI/IconButtonTooltip/IconButtonTooltip';
type Props = {
  authUser: User;
  sidebarOpen: boolean;
  setAuthUser: (user: User) => void;
  handleCloseSideBarMobile: () => void;
  spaceId?: string;
  modifySpaceObject?: (space: any) => void;
  space?: any;
};

const FluxObjects = ({
  authUser,
  sidebarOpen,
  setAuthUser,
  handleCloseSideBarMobile,
  spaceId,
  modifySpaceObject,
  space,
}: Props) => {
  const [clickedObjectId, setClickedObjectId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isRenameClicked, setIsRenameClicked] = useState<boolean>(false);
  const [isObjectModalOpen, setIsObjectModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setClickedObjectId(id === clickedObjectId ? null : id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedObjectId(null);
  };

  const [objectsCollapsed, setObjectsCollapsed] = useState(false);
  const [objectUpdate, setObjectUpdate] = useState({
    name: '',
    isCreating: false,
    id: '',
    text: [
      {
        text: '',
        id: '',
      },
    ],
  });

  const onObjectUpdate = async () => {
    setIsLoading(true);
    try {
      let mutatedObj = null;

      if (objectUpdate.isCreating) {
        mutatedObj = [
          ...(spaceId ? space : authUser)?.objects!,
          {
            ...objectUpdate,
            id: Math.random().toString(36).substr(2, 9),
          },
        ];
      } else {
        mutatedObj = (spaceId ? space : authUser)?.objects.map(
          (object: any) => {
            if (object.id === objectUpdate.id) {
              return objectUpdate;
            }
            return object;
          },
        );
      }

      if (spaceId && modifySpaceObject && space?.id) {
        modifySpaceObject({
          ...space,
          objects: mutatedObj,
        });
      } else {
        const updatedObjects = await UserService.updateUser({
          objects: mutatedObj,
        });
        const userRes = await AuthService.getMe();
        setAuthUser(userRes?.data);
      }

      setObjectUpdate({
        isCreating: false,
        name: '',
        text: [],
        id: '',
      });
      setIsObjectModalOpen(false);
    } catch (error) {
      setNotification({
        message: 'Failed to update object',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onObjectDelete = async (id: string) => {
    const filteredObjects = authUser?.objects.filter(
      object => object.id !== id,
    );
    try {
      const result = window.confirm(
        'Are you sure you want to delete the object?',
      );

      if (!result) return;

      if (spaceId && modifySpaceObject && space?.id) {
        modifySpaceObject({
          ...space,
          objects: filteredObjects,
        });
      } else {
        const res = await UserService.updateUser({
          objects: filteredObjects,
        });
        const userRes = await AuthService.getMe();
        setAuthUser(userRes?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box
        className={`layouts_sidebar_menu--btn align-items-center `}
        onClick={() => setObjectsCollapsed(!objectsCollapsed)}
        sx={{
          pt: '4px',
          display: 'flex',
          justifyContent: !sidebarOpen ? 'center' : 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
          paddingLeft: '13px',
          // width: '14.9rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <Box
          className="menu menu--on-hover"
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            paddingRight: '7px',
            paddingBottom: '4px',
            '&:hover': {
              backgroundColor:
                authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
              borderRadius: '4px',
            },
          }}
        >
          <Box sx={{ display: 'flex', columnGap: '8px', paddingLeft: '5px' }}>
            <Archive
              className="active-menu"
              color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
              size={20}
              weight="regular"
              style={{ alignSelf: 'center' }}
            />
            {sidebarOpen && (
              <>
                <Typography
                  className="text-capitalize active-menu"
                  sx={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Objects
                </Typography>
              </>
            )}
          </Box>

          <Box sx={{ alignSelf: 'center', display: 'flex', gap: '8px' }}>
            <IconButtonTooltip
              title="Create Flux Object"
              onClick={event => {
                event.stopPropagation();
                setObjectUpdate({
                  name: '',
                  id: Math.random().toString(),
                  isCreating: true,
                  text: [
                    {
                      text: '',
                      id: Math.random().toString(),
                    },
                  ],
                });
                setIsObjectModalOpen(true);
              }}
            >
              <Plus
                size={16}
                className="menu-icons active-menu"
                style={{
                  color: authUser?.theme === 'dark' ? '#D0D5DD' : '#667085',
                  cursor: 'pointer',
                }}
              />
            </IconButtonTooltip>
            {sidebarOpen && (
              <IconButtonTooltip title="Collapse">
                {!objectsCollapsed ? (
                  <CaretDown
                    className="active-menu"
                    size={16}
                    color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  />
                ) : (
                  <CaretRight
                    className="active-menu"
                    size={16}
                    color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                  />
                )}
              </IconButtonTooltip>
            )}
          </Box>
        </Box>
      </Box>

      <Collapse
        timeout={500}
        in={objectsCollapsed}
        sx={{
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            paddingLeft: sidebarOpen ? '44px' : '',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
          }}
        >
          {(spaceId ? space : authUser)?.objects?.map((object: any) => (
            <Box
              key={object.id}
              className="menu menu--on-hover"
              sx={{
                maxWidth: '320px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                paddingLeft: '3px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': {
                  backgroundColor:
                    authUser?.theme === 'dark' ? '#101828' : '#E7F0FF',
                  borderRadius: '4px',
                },
              }}
            >
              <Tooltip title={object.name}>
                <Typography
                  variant="body1"
                  sx={{
                    color: authUser?.theme === 'dark' ? '#D0D5DD' : '#475467',
                    fontSize: '16px',
                    width: '13rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    margin: '0',
                    marginBottom: '5px',
                    '&:hover': {
                      color: '#3650DE',
                    },
                  }}
                  onClick={() => {
                    handleCloseSideBarMobile();
                    setObjectUpdate({
                      isCreating: false,
                      name: object.name,
                      id: object.id,
                      text: object.text,
                    });
                    setIsObjectModalOpen(true);
                  }}
                >
                  {object?.name}
                </Typography>
              </Tooltip>

              <IconButton
                size="medium"
                key={object.id}
                onClick={e => {
                  handleClick(e, object.id);
                }}
              >
                {authUser?.theme === 'dark' ? (
                  <DotsThreeOutlineWhiteIcon />
                ) : (
                  <DotsThreeOutlineGrayIcon />
                )}
              </IconButton>
              <Popover
                open={clickedObjectId === object.id}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: '8px',
                    background:
                      authUser?.theme === 'dark' ? '#101828' : '#FFFF',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      px: '8px',
                      gap: '8px',
                      color: '#EAECF0',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() => {
                      setIsRenameClicked(true);
                      setObjectUpdate({
                        isCreating: false,
                        name: object.name,
                        id: object.id,
                        text: object.text,
                      });
                      setIsObjectModalOpen(true);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <PencilSimpleLine
                        size={20}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                      <Typography
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'
                        }
                      >
                        Rename
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      px: '8px',
                      gap: '8px',
                      color: '#EAECF0',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() => {
                      setObjectUpdate({
                        isCreating: false,
                        name: object.name,
                        id: object.id,
                        text: object.text,
                      });
                      setIsObjectModalOpen(true);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <NotePencil
                        size={20}
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'
                        }
                      />
                      <Typography
                        color={
                          authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'
                        }
                      >
                        Edit
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      background: 'unset',
                      justifyContent: 'flex-start',
                      textTransform: 'unset',
                      fontSize: '16px',
                      boxShadow: 'unset',
                      color: '#b91c1c',
                      px: '8px',
                      gap: '8px',
                      ':hover': {
                        background:
                          authUser?.theme === 'dark' ? '#FFADB7' : '#fecdd3',
                        boxShadow: 'unset',
                      },
                    }}
                    onClick={() => {
                      onObjectDelete(object.id);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Trash size={20} color="#b91c1c" />
                      <Typography>Delete</Typography>
                    </Box>
                  </Button>
                </Box>
              </Popover>
            </Box>
          ))}
        </div>
      </Collapse>

      <ObjectModal
        onHide={() => {
          setIsObjectModalOpen(false);
          setIsRenameClicked(false);
        }}
        objectUpdate={objectUpdate}
        setObjectUpdate={setObjectUpdate}
        show={isObjectModalOpen}
        onObjectUpdate={onObjectUpdate}
        isRenameClicked={isRenameClicked}
        isLoading={isLoading}
      />
    </>
  );
};

export default FluxObjects;
