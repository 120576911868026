import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class IdeogramService extends ApiService {
  static pathName = '/ideogram';

  static createImage = (body: {
    flowId: string;
    fileUrl: string;
    prompt: string;
    aspectRatio: string;
    model: string;
    magicPromptOption: string;
    styleType: string;
    negativePrompt: string | null;
    resolution: string;
    imageWeight: number;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/image`, body);
  };

  static remix = (body: {
    flowId: string;
    fileUrl: string;
    prompt: string;
    aspectRatio: string;
    model: string;
    magicPromptOption: string;
    styleType: string;
    negativePrompt: string;
    resolution: string;
    imageWeight: number;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/remix`, body);
  };

  static describeImage = (body: {
    flowId: string;
    fileUrl: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/describe-image`, body);
  };

  static upscaleImage = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/upscale`, body);
  };
}
