import {
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import React from 'react';
import { useAuthStore } from '../../../store/storeAuth';

const IconButtonTooltip = ({
  children,
  title,
  onClick,
  placement = 'top',
  sx,
  componentProps,
}: {
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  title: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  sx?: SxProps<Theme>;
  componentProps?: any;
}) => {
  const authUser = useAuthStore(state => state.user);
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: authUser?.theme === 'dark' ? '#101828' : '#475467',
          },
        },
        arrow: {
          sx: {
            color: authUser?.theme === 'dark' ? '#101828' : '#475467',
          },
        },
        ...componentProps,
      }}
    >
      <IconButton
        sx={{
          p: '2px',
          borderRadius: '2px',
          ...sx,
        }}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default IconButtonTooltip;
