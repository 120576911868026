import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuthStore } from '../../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';
import { UploadService } from '../../../service/UploadService';
import { UserService } from '../../../service/UserService';
import ChangePasswordModal from '../ChangePasswordModal';
import { getAPIErrorMessage } from '../../../helpers/helpers';
import { Eye, EyeSlash, PencilSimpleLine } from '@phosphor-icons/react';
import { TokenUsage } from '../../../util/Types/TokenUsage';
import { ReportService } from '../../../service/ReportService';
import dayjs from 'dayjs';
import { User } from '../../../util/Types/User';

const boxStyles = {
  display: 'flex',
  padding: '12px',
  width: '80%',
  height: '60px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  borderRadius: '8px',
  color: '#fff',
};

type Props = {
  authUser: User;
  companyData: any;
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
};

type ServiceCall = (
  userId: string,
  dateParams: string,
) => Promise<{ data: any }>;

const ProfileSettings = ({ authUser, companyData }: Props) => {
  const coverUploadRef = useRef<any>(null);
  const avatarUploadRef = useRef<any>(null);
  const setAuthUser = useAuthStore((state: any) => state.setAuthUser);
  const { setNotification } = useNotificationStore((state: any) => state);

  const [generateBtnLoading, setGenerateBtnLoading] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState({
    profile: false,
    cover: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(authUser.email);

  const [usages, setUsages] = useState<Record<string, TokenUsage[]>>({
    text: [],
    apiCalls: [],
    audio: [],
    image: [],
  });

  const handleCoverUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(prev => ({
        ...prev,
        cover: true,
      }));
      const file = e.target.files![0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);

      await UserService.updateUser({
        cover: response.data.src,
      });

      setAuthUser({
        ...authUser,
        cover: response.data.src,
      });
      setNotification({
        type: 'success',
        message: 'Cover image updated successfully',
      });
    } catch (error) {
      setIsLoading(prev => ({
        ...prev,
        cover: false,
      }));
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        cover: false,
      }));
    }
  };

  const handleAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(prev => ({
        ...prev,
        profile: true,
      }));
      const file = e.target.files![0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await UploadService.uploadFile(formData);

      await UserService.updateUser({
        avatar: response.data.src,
      });

      setAuthUser({
        ...authUser,
        avatar: response.data.src,
      });
      setNotification({
        type: 'success',
        message: 'Avatar updated successfully',
      });
    } catch (error) {
      setIsLoading(prev => ({
        ...prev,
        profile: false,
      }));
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
    } finally {
      setIsLoading(prev => ({
        ...prev,
        profile: false,
      }));
    }
  };

  const onGenerateApiKey = async () => {
    setGenerateBtnLoading(true);
    try {
      const response = await UserService.generateApiKey();
      setAuthUser({
        ...authUser!,
        apiKey: response?.data?.apiKey!,
      });

      setNotification({
        type: 'success',
        message: 'Api Key generated successfully',
      });
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    } finally {
      setGenerateBtnLoading(false);
    }
  };

  const getUsage = useCallback(
    async (serviceCall: ServiceCall, usageKey: string) => {
      try {
        const today = dayjs();
        const dates = Array.from({ length: 3 }, (_, i) =>
          today.subtract(i, 'month'),
        );
        const months = dates.map(date => date.month() + 1);
        const years = Array.from(new Set(dates.map(date => date.year())));

        const response = await serviceCall(
          authUser!.id,
          `year=${years.join(',')}&months=${months.join(',')}`,
        );

        setUsages(prevUsages => ({ ...prevUsages, [usageKey]: response.data }));
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    },
    [authUser, setNotification],
  );

  const getTextUsage = useCallback(
    () => getUsage(ReportService.getTextUsage, 'text'),
    [getUsage],
  );
  const getApiCallsUsage = useCallback(
    () => getUsage(ReportService.getApiCallsUsage, 'api_calls'),
    [getUsage],
  );
  const getAudioUsage = useCallback(
    () => getUsage(ReportService.getAudioUsage, 'audio'),
    [getUsage],
  );
  const getImagesUsage = useCallback(
    () => getUsage(ReportService.getImagesUsage, 'image'),
    [getUsage],
  );

  useEffect(() => {
    // fetchCompanies();
    getTextUsage();
    getApiCallsUsage();
    getAudioUsage();
    getImagesUsage();
  }, [getApiCallsUsage, getAudioUsage, getImagesUsage, getTextUsage]);

  const totalUsedTextTokens = parseFloat(
    usages.text.reduce((total, item) => total + item.usedTokens, 1).toFixed(2),
  );
  const totalUsedApiCallsTokens = parseFloat(
    usages.apiCalls
      .reduce((total, item) => total + item.usedTokens, 1)
      .toFixed(2),
  );

  const totalUsedAudioTokens = parseFloat(
    usages.audio.reduce((total, item) => total + item.usedTokens, 1).toFixed(2),
  );

  const totalUsedImageTokens = parseFloat(
    usages.image.reduce((total, item) => total + item.usedTokens, 1).toFixed(2),
  );

  const totalNodeRunsText = parseFloat(
    usages.text
      .reduce((total, item) => total + (item.nodeRuns || 0), 1)
      .toFixed(2),
  );

  const totalNodeRunsAudio = parseFloat(
    usages.audio
      .reduce((total, item) => total + (item.nodeRuns || 0), 1)
      .toFixed(2),
  );

  const totalNodeRunsImage = parseFloat(
    usages.image
      .reduce((total, item) => total + (item.nodeRuns || 0), 1)
      .toFixed(2),
  );

  const totalNodeRunsApiCalls = parseFloat(
    usages.apiCalls
      .reduce((total, item) => total + (item.nodeRuns || 0), 1)
      .toFixed(2),
  );

  const totalNodeRuns =
    totalNodeRunsText +
    totalNodeRunsAudio +
    totalNodeRunsImage +
    totalNodeRunsApiCalls;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    }
  };

  const dataBox = [
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'AI FluxTokens',
      value: authUser.spentTokens,
    }, // authUser
    {
      background:
        'linear-gradient(53deg, #FD746C 16.71%, #FE846A 48.74%, #FF9068 80.78%)',
      label: 'API Calls',
      value: totalUsedApiCallsTokens,
    }, //reportService
    {
      background: 'linear-gradient(90deg, #142BFF 0%, #396AFC 100%)',
      label: 'Text FluxTokens',
      value: totalUsedTextTokens,
    }, //reportService
    {
      background: 'linear-gradient(59deg, #683BB8 7.2%, #A969FD 92.35%)',
      label: 'Image FluxTokens',
      value: totalUsedImageTokens,
    }, //reportService
    {
      background: 'linear-gradient(63deg, #73E49A 14.58%, #3FC36D 82.62%)',
      label: 'Audio FluxTokens',
      value: totalUsedAudioTokens,
    }, //reportService
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Flows Created',
      value: authUser.numberOfFlows,
    }, // authUser
    {
      background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
      label: 'Node Runs',
      value: totalNodeRuns,
    }, //reportService
  ];

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: 'white',
          borderBottom: '1px solid #D0D5DD',
          display: 'flex',
          height: authUser.isUserCompany ? '67px' : '64px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            marginLeft: '24px',
          }}
        >
          Profile Settings
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '24px 24px 16px 24px',
          gap: '32px',
        }}
      >
        <Box sx={{ position: 'relative', mb: 6 }}>
          {isLoading.cover ? (
            <Box
              sx={{
                width: '100%',
                height: '200px',
                backgroundColor: '#D0D5DD',
                display: 'flex',
                borderRadius: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '200px',
                backgroundColor: '#d3d3d3',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                backgroundImage: `url(${authUser?.cover})`,
              }}
            />
          )}
          <IconButton
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              backgroundColor: '#F9FAFB',
              borderRadius: '4px',
              padding: '5px',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: '#F9FAFB',
              },
            }}
            onClick={() => coverUploadRef.current?.click()}
          >
            <PencilSimpleLine size={20} color="#344054" />
          </IconButton>
          <input
            type="file"
            ref={coverUploadRef}
            hidden
            onChange={handleCoverUpload}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '100px',
              left: '35px',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Box sx={{ position: 'relative', mr: 2 }}>
              {isLoading.profile ? (
                <Box
                  sx={{
                    width: 150,
                    height: 150,
                    border: '3px solid white',
                    borderRadius: '8px',
                    backgroundSize: `${
                      Boolean(authUser?.avatar) ? 'cover' : '40%'
                    }`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: '#EAECF0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: 150,
                    height: 150,
                    backgroundColor: '#d3d3d3',
                    border: '3px solid white',
                    borderRadius: '8px',
                    backgroundImage: `url(${authUser?.avatar})`,
                    backgroundSize: `${
                      Boolean(authUser?.avatar) ? 'cover' : '40%'
                    }`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {!authUser?.avatar && (
                    <Avatar
                      alt="Avatar"
                      sx={{
                        backgroundColor: 'transparent',
                        width: 48,
                        height: 48,
                      }}
                    >
                      {authUser.name
                        .split(' ')
                        .map(n => n[0])
                        .join('')}{' '}
                    </Avatar>
                  )}
                </Box>
              )}

              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  backgroundColor: '#F9FAFB',
                  borderRadius: '4px',
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: '#F9FAFB',
                  },
                }}
                onClick={() => avatarUploadRef.current?.click()}
              >
                <PencilSimpleLine size={20} color="#344054" />
              </IconButton>
              <input
                type="file"
                ref={avatarUploadRef}
                hidden
                onChange={handleAvatarUpload}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  color: '#101828',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                }}
              >
                {authUser.name}
              </Typography>
              <Typography
                sx={{
                  color: '#667085',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                }}
              >
                {companyData?.name}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ marginTop: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '24px',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '29px',
              }}
            >
              User Stats Overview
            </Typography>

            <Box>
              <Button
                sx={{
                  borderRadius: '12px',
                  padding: '10px 20px',
                  fontSize: '16px',
                  textTransform: 'none' as const,
                  backgroundColor: '#EAECF0',
                }}
              >
                All time
              </Button>
            </Box>
          </Box>

          <Grid container sx={{ width: '101.5%' }} spacing={1.9}>
            {dataBox.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={3}
                md={1.7}
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  '&:first-of-type': {
                    justifyContent: 'flex-start',
                  },
                  '&:last-of-type': {
                    justifyContent: 'flex-end',
                  },
                }}
              >
                <Box sx={{ ...boxStyles, backgroundImage: item.background }}>
                  <Typography variant="h5">{item.value}</Typography>
                  <Typography variant="body2">{item.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#475467',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '29px',
            marginY: '25px',
          }}
        >
          Personal Information
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'space-between',
            gap: '40px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '90%',
            }}
          >
            <TextField
              label="Email"
              name="email"
              value={email}
              fullWidth
              onChange={handleInputChange}
              disabled
              sx={{
                '& .MuiInputBase-root': {
                  height: '55px',
                },
                '& .MuiOutlinedInput-input': {
                  padding: '16px 12px',
                },
              }}
            />

            {/* Change Password */}
            {/* {authUser?.registrationMethod === 'simple' && (
          <Button
            variant='contained'
            sx={{
              fontWeight: 500,
              cursor: 'pointer',
            }}
            onClick={() => setIsChangePasswordModalOpen(true)}
          >
            Change Password
          </Button>
        )} */}
          </Box>

          {/* Action Buttons showing when clicking Edit */}
          {/* {showButtons && (
             <Box sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "48.5%",
              mb: "15px",
              gap: "32px",
            }}>
            <Button 
            variant="outlined"
            onClick={() => setShowButtons(false)}
            >
              Cancel
              </Button>
            <Button 
            variant="contained"

            >
              Save
              </Button>
            </Box>
           )} */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '18px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              {!authUser?.apiKey && (
                <Button
                  variant="contained"
                  onClick={onGenerateApiKey}
                  disabled={generateBtnLoading}
                  // sx={{
                  //   width: "25%",
                  // }}
                >
                  {generateBtnLoading ? (
                    <>
                      <CircularProgress size="sm" />
                    </>
                  ) : (
                    'Generate Api Key'
                  )}
                </Button>
              )}
              {authUser?.apiKey && (
                <Box
                  sx={{
                    //  mb: 1,
                    display: 'flex',
                    gap: '4px',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    value={authUser?.apiKey}
                    label="API Key"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '55px',
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '16px 12px',
                      },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              // width: "48.5%",
            }}
          >
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '24px',
                      color: '#3650DE',
                    }}
                  >
                    Hide
                  </Typography>
                  <EyeSlash size={20} />
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 450,
                      lineHeight: '24px',
                      color: '#3650DE',
                    }}
                  >
                    Show
                  </Typography>
                  <Eye size={20} />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Box>

      <ChangePasswordModal
        isChangePasswordModalOpen={isChangePasswordModalOpen}
        setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
      />
    </>
  );
};
export default ProfileSettings;
