/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { useEdges } from 'reactflow';
import { isActiveEdge } from '../../util/findActiveHandle';
import Label from '../UI/Label/Label';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { Info } from '@phosphor-icons/react/dist/ssr';
import { useAuthStore } from '../../store/storeAuth';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { FlowService } from '../../service/FlowService';
import { useStoreHubSpotData } from '../../store/storeHubSpotData';
import { HubSpotService } from '../../service/HubSpotService';
import ConnectToHubSpot from './ConnectToHubSpot';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import TagInput from '../Test/TagInput';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { Gear } from '@phosphor-icons/react';

export type EntityProperties = {
  name: string;
  label: string;
  type: string;
  fieldType: string;
  options: any[];
};

export enum HubSpotObjectType {
  Deals = 'Deal',
  Contacts = 'Contact',
  Companies = 'Company',
}

export enum ResponseType {
  List = 'List',
  Details = 'Details',
}

export const SortOptions: Record<HubSpotObjectType, Record<string, string>[]> =
  {
    [HubSpotObjectType.Deals]: [
      {
        label: 'Create Date',
        value: 'createdate',
      },
      {
        label: 'Close Date',
        value: 'closedate',
      },
      {
        label: 'Last Modified Date',
        value: 'hs_lastmodifieddate',
      },
      {
        label: 'Deal Name',
        value: 'dealname',
      },
    ],
    [HubSpotObjectType.Contacts]: [
      {
        label: 'Create Date',
        value: 'createdate',
      },
      {
        label: 'Last Modified Date',
        value: 'hs_lastmodifieddate',
      },
      {
        label: 'First Name',
        value: 'firstname',
      },
      {
        label: 'Last Name',
        value: 'lastname',
      },
    ],
    [HubSpotObjectType.Companies]: [
      {
        label: 'Create Date',
        value: 'createdate',
      },
      {
        label: 'Last Modified Date',
        value: 'hs_lastmodifieddate',
      },
      {
        label: 'Company Name',
        value: 'name',
      },
    ],
  };

export type FormData = {
  selectedObjectType: HubSpotObjectType;
  selectedResponseType: ResponseType;
  selectedProperties: any[];
  filter?: string;
  entityId?: string;
  limit: number;
  query?: string;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  dealstatus?: 'all' | 'open' | 'won' | 'lost';
};

const PullHubSpotData: React.FC<{ id: string; type: string; data: any }> = ({
  id,
  type,
  data,
}) => {
  /**
   * @todo tipar
   * @todo verificar lógica do title
   * @todo verificar lógica do firstTime
   */
  const [propertiesOptions, setPropertiesOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [tokenFirstTime, setTokenFirstTime] = useState(true);
  const { isHubSpotConnected, setIsHubSpotConnected } = useStoreHubSpotData();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [settingsAnchorEl, setSettingsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const openSettingsPopover = Boolean(settingsAnchorEl);

  const { setNodeState, setSaving, setSchema, flowId } = useFlowsStore(
    state => state,
  );
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    selectedObjectType: data.selectedObjectType || HubSpotObjectType.Deals,
    selectedResponseType: data.selectedResponseType || ResponseType.List,
    selectedProperties: data?.selectedProperties || [],
    filter: data?.filter || '',
    entityId: data?.entityId || '',
    limit: data?.limit || 10,
    query: data?.query || '',
    sortBy: data?.sortBy || '',
    sortOrder: data?.sortOrder || 'ASC',
    dealstatus: data?.dealstatus || 'all',
  });

  const fetchOptions = async (entity: HubSpotObjectType) => {
    setIsLoading(true);
    try {
      const entityMap = {
        [HubSpotObjectType.Deals]: 'deals',
        [HubSpotObjectType.Contacts]: 'contacts',
        [HubSpotObjectType.Companies]: 'companies',
      };
      const entityType = entityMap[entity];
      const properties = await HubSpotService.getHubSpotEntityProperties(
        entityType,
      );
      setPropertiesOptions(
        properties.results.map(r => ({ label: r.label, value: r.name })),
      );
    } catch (error) {
      console.error('Error fetching options:', error);
      setPropertiesOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isHubSpotConnected && formData.selectedObjectType) {
      fetchOptions(formData.selectedObjectType);
    } else {
      setPropertiesOptions([]);
    }
  }, [isHubSpotConnected, formData.selectedObjectType]);

  const [executeCounter, setExecuteCounter] = useState(0);
  const [hasToExecute, setHasToExecute] = useState(true);

  const [isDisabled, setIsDisabled] = useState(true);

  const edges = useEdges();
  const authUser = useAuthStore(state => state);

  const disconnectHandlerHubSpot = async () => {
    if (!isHubSpotConnected) {
      setNotification({
        type: NotificationType.Error,
        message: 'Session expired. Please login again.',
      });

      return;
    }

    await refreshAuthToken();
  };

  const loadDataList = async (formData: FormData) => {
    try {
      if (
        formData.selectedResponseType === ResponseType.Details &&
        formData.entityId
      ) {
        switch (formData.selectedObjectType) {
          case HubSpotObjectType.Deals:
            return await HubSpotService.getHubSpotDealById(formData.entityId);
          case HubSpotObjectType.Contacts:
            return await HubSpotService.getHubSpotContactById(
              formData.entityId,
            );
          case HubSpotObjectType.Companies:
            return await HubSpotService.getHubSpotCompanyById(
              formData.entityId,
            );
        }
      }

      const loadHandler = {
        [HubSpotObjectType.Deals]: HubSpotService.getHubSpotDeals,
        [HubSpotObjectType.Contacts]: HubSpotService.getHubSpotContacts,
        [HubSpotObjectType.Companies]: HubSpotService.getHubSpotCompanies,
      };

      const response = await loadHandler[formData.selectedObjectType]({
        limit: formData.limit,
        properties: formData.selectedProperties,
        query: formData.query,
        sortBy: formData.sortBy,
        sortOrder: formData.sortOrder,
        dealstatus: formData.dealstatus,
      });

      return response;
    } catch (error) {
      const anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerHubSpot();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
  };

  const refreshAuthToken = async () => {
    try {
      await HubSpotService.refreshHubSpotToken();
      setIsHubSpotConnected(true);
    } catch (refreshError) {
      setNotification({
        type: NotificationType.Error,
        message: 'Failed to refresh session, please login again.',
      });
      setIsHubSpotConnected(false);
    }
  };

  const isHubSpotTokenValid = async () => {
    try {
      return await HubSpotService.getHubSpotInstallUrl();
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const validateExistingHubSpotToken = async () => {
      if (isHubSpotConnected && !(await isHubSpotTokenValid())) {
        setIsHubSpotConnected(false);
        refreshAuthToken();
      }
    };

    if (isHubSpotConnected && tokenFirstTime) {
      validateExistingHubSpotToken();
      setTokenFirstTime(false);
    }
  }, [isHubSpotConnected, tokenFirstTime]);

  useEffect(() => {
    const updateDisabledAuth = async () => {
      if (!authUser?.user) return;

      try {
        const { data } = await FlowService.getFlow(flowId);
        const { creatorId, spaceId } = data;

        if (!spaceId) {
          return setIsDisabled(false);
        }

        if (authUser.user.id !== creatorId) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    updateDisabledAuth();
  }, [authUser, flowId]);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [hasToExecute, formData, data]);

  const onSubmit = async () => {
    if (isHubSpotConnected) {
      setIsLoading(true);
      try {
        const text = ['entityId'];
        let newFormData: any = { ...formData };

        text.forEach(t => {
          if (data[t + 'Data']?.length) {
            newFormData[t] = data[t + 'Data']
              ?.map((item: { text: string }) => item.text)
              .join(' \n');
          }
        });

        setFormData(newFormData);
        const results = await loadDataList(newFormData);
        const outputResponse = JSON.stringify(results, null, 2);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          flowTriggerData: checkExecuteTrigger(data, id),
          changeType: 'execute',
          objectData: {
            text: outputResponse,
          },
          setSchema,
        });

        setExecuteCounter(0);

        setTimeout(() => {
          setHasToExecute(true);
        }, 2500);
      } catch (error) {
        let anyError = error as any;
        if (anyError.response?.status === 401) {
          disconnectHandlerHubSpot();
        } else {
          executeErrorHandle(
            executeCounter,
            setExecuteCounter,
            setHasToExecute,
            data,
            edges,
            setNodeState,
            setSaving,
            id,
            flowId,
            setSchema,
            setNotification,
            error,
            [...(user?.spaceObjects || []), ...(user?.objects || [])],
          );

          setNotification({
            type: NotificationType.Error,
            message: getAPIErrorMessage(error),
          });
        }
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const setValue = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChange = (e: { target: { name: string; value: any } }) => {
    setValue(e.target.name, e.target.value);

    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
    });

    setIsChangingDirectly(true);
  };

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });

    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };
  return (
    <>
      <Box className={isLoading ? 'node' : ''}>
        <Node
          onSubmit={onSubmit}
          btnText="Get Data"
          handleRun={handleRun}
          data={data}
          type={type}
          id={id}
          isLoading={isLoading}
          edges={edges}
          showTokensUsed={false}
        >
          <Box sx={{ cursor: isDisabled ? 'not-allowed' : 'auto' }}>
            <Box display={'flex'}>
              <Box
                p={'16px'}
                borderRight={`1px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`}
                sx={{
                  backgroundColor: isDisabled
                    ? 'rgba(250, 250, 250, 0.85)'
                    : 'white',
                  pointerEvents: isDisabled ? 'none' : 'auto',
                  cursor: isDisabled ? 'not-allowed' : 'default',
                  boxShadow: isDisabled
                    ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                    : 'none',
                  transition: 'all 0.3s ease',
                }}
              >
                {isHubSpotConnected ? (
                  <>
                    <Box
                      display={'flex'}
                      gap={4}
                      mb={'16px'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Label
                        sx={{ mb: '0' }}
                        labelName="Data to Pull"
                        isAdvanced
                      />
                      {isDisabled && (
                        <Button sx={{ marginBottom: '8px' }}>
                          No Editing Permission
                        </Button>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <IconButtonTooltip
                          title="Filter Settings"
                          onClick={event =>
                            setSettingsAnchorEl(event.currentTarget)
                          }
                        >
                          <Gear
                            size={22}
                            color={openSettingsPopover ? '#3650DE' : '#98A2B3'}
                          />
                        </IconButtonTooltip>

                        <Popover
                          open={openSettingsPopover}
                          anchorEl={settingsAnchorEl}
                          id="simple-popover"
                          onClose={() => setSettingsAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <Box
                            sx={{
                              width: '350px',
                              border: '1px solid var(--Neutral-300, #D0D5DD)',
                              padding: '16px',
                              borderRadius: '8px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '22px',
                            }}
                          >
                            <Typography variant="h6">
                              Filter Settings
                            </Typography>
                            <Box borderRadius={'4px'}>
                              <Box>
                                <FormLabel
                                  sx={{
                                    mb: '4px',
                                  }}
                                >
                                  Search Query
                                </FormLabel>
                                <TextField
                                  id="search-query-filter"
                                  type="text"
                                  label="Search Query"
                                  sx={{
                                    width: '100%',
                                  }}
                                  placeholder="Search by name..."
                                  value={formData.query}
                                  name="query"
                                  onChange={onChange}
                                />

                                <FormLabel
                                  sx={{
                                    mb: '4px',
                                  }}
                                >
                                  Sort By
                                </FormLabel>
                                <Select
                                  label="Sort By"
                                  className="nodrag"
                                  fullWidth
                                  value={formData.sortBy}
                                  size="small"
                                  onChange={(e: any) => {
                                    onChange?.(e);
                                  }}
                                  name="sortBy"
                                  placeholder="Sort By"
                                  sx={{
                                    width: '100%',
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        '& .Mui-selected': {
                                          backgroundColor:
                                            user?.theme === 'dark'
                                              ? '#667085'
                                              : '#D0D5DD',
                                        },
                                        backgroundColor:
                                          user?.theme === 'dark'
                                            ? '#1D2939'
                                            : '#F9FAFB',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(
                                    SortOptions[formData.selectedObjectType],
                                  ).map(opt => (
                                    <MenuItem
                                      key={opt.value}
                                      value={opt.value}
                                      sx={{
                                        color:
                                          user?.theme === 'dark'
                                            ? '#D0D5DD'
                                            : 'black',
                                      }}
                                    >
                                      {opt.label}
                                    </MenuItem>
                                  ))}
                                </Select>

                                <FormLabel
                                  sx={{
                                    mb: '4px',
                                  }}
                                >
                                  Sort Order
                                </FormLabel>
                                <Select
                                  label="Sort Order"
                                  className="nodrag"
                                  fullWidth
                                  value={formData.sortOrder}
                                  size="small"
                                  onChange={(e: any) => {
                                    onChange?.(e);
                                  }}
                                  name="sortOrder"
                                  placeholder="Sort Order"
                                  sx={{
                                    width: '100%',
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        '& .Mui-selected': {
                                          backgroundColor:
                                            user?.theme === 'dark'
                                              ? '#667085'
                                              : '#D0D5DD',
                                        },
                                        backgroundColor:
                                          user?.theme === 'dark'
                                            ? '#1D2939'
                                            : '#F9FAFB',
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem
                                    value="ASC"
                                    sx={{
                                      color:
                                        user?.theme === 'dark'
                                          ? '#D0D5DD'
                                          : 'black',
                                    }}
                                  >
                                    Ascending
                                  </MenuItem>
                                  <MenuItem
                                    value="DESC"
                                    sx={{
                                      color:
                                        user?.theme === 'dark'
                                          ? '#D0D5DD'
                                          : 'black',
                                    }}
                                  >
                                    Descending
                                  </MenuItem>
                                </Select>

                                {formData.selectedObjectType ===
                                  HubSpotObjectType.Deals && (
                                  <>
                                    <FormLabel sx={{ mb: '4px' }}>
                                      Deal Status
                                    </FormLabel>
                                    <Select
                                      label="Deal Status"
                                      className="nodrag"
                                      fullWidth
                                      value={formData.dealstatus}
                                      size="small"
                                      onChange={(e: any) => {
                                        onChange?.(e);
                                      }}
                                      name="dealstatus"
                                      placeholder="Deal Status"
                                      sx={{
                                        width: '100%',
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            '& .Mui-selected': {
                                              backgroundColor:
                                                user?.theme === 'dark'
                                                  ? '#667085'
                                                  : '#D0D5DD',
                                            },
                                            backgroundColor:
                                              user?.theme === 'dark'
                                                ? '#1D2939'
                                                : '#F9FAFB',
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        value="all"
                                        sx={{
                                          color:
                                            user?.theme === 'dark'
                                              ? '#D0D5DD'
                                              : 'black',
                                        }}
                                      >
                                        All
                                      </MenuItem>
                                      <MenuItem
                                        value="open"
                                        sx={{
                                          color:
                                            user?.theme === 'dark'
                                              ? '#D0D5DD'
                                              : 'black',
                                        }}
                                      >
                                        Open
                                      </MenuItem>
                                      <MenuItem
                                        value="won"
                                        sx={{
                                          color:
                                            user?.theme === 'dark'
                                              ? '#D0D5DD'
                                              : 'black',
                                        }}
                                      >
                                        Won
                                      </MenuItem>
                                      <MenuItem
                                        value="lost"
                                        sx={{
                                          color:
                                            user?.theme === 'dark'
                                              ? '#D0D5DD'
                                              : 'black',
                                        }}
                                      >
                                        Lost
                                      </MenuItem>
                                    </Select>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Popover>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Select
                        className="nodrag"
                        name="selectedObjectType"
                        value={formData.selectedObjectType}
                        onChange={onChange}
                        style={{ width: '360px' }}
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: 'none',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                        }}
                      >
                        {Object.values(HubSpotObjectType).map(obType => (
                          <MenuItem
                            key={obType}
                            value={obType}
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            {obType}
                          </MenuItem>
                        ))}
                      </Select>

                      <Box sx={{ margin: '10px 0' }}></Box>

                      <Select
                        className="nodrag"
                        name="selectedResponseType"
                        value={formData.selectedResponseType}
                        onChange={onChange}
                        style={{ width: '360px' }}
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: 'none',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                        }}
                      >
                        {Object.values(ResponseType).map(responseType => (
                          <MenuItem
                            key={responseType}
                            value={responseType}
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            {responseType}
                          </MenuItem>
                        ))}
                      </Select>

                      <Box sx={{ margin: '10px 0' }}></Box>

                      <Autocomplete
                        key={`${propertiesOptions.length}-${formData.selectedObjectType}`}
                        multiple
                        options={propertiesOptions}
                        value={formData.selectedProperties.map(prop =>
                          propertiesOptions.find(
                            option => option.value === prop,
                          ),
                        )}
                        className="nodrag"
                        style={{ width: '360px' }}
                        onChange={(event, newValue) =>
                          setFormData({
                            ...formData,
                            selectedProperties: newValue.map(
                              option => option?.value,
                            ),
                          })
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Properties"
                            placeholder="Select properties"
                          />
                        )}
                      />

                      <Box sx={{ margin: '10px 0' }}></Box>

                      <Select
                        className="nodrag"
                        name="limit"
                        value={formData.limit}
                        onChange={onChange}
                        style={{ width: '360px' }}
                        sx={{
                          '& .MuiList-root': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          },
                          '& .MuiSvgIcon-root': {
                            color:
                              user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                          },
                          borderRadius: '8px',
                          border: 'none',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          bgcolor:
                            user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .Mui-selected': {
                                backgroundColor:
                                  user?.theme === 'dark'
                                    ? '#667085'
                                    : '#D0D5DD',
                              },
                              backgroundColor:
                                user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                            },
                          },
                        }}
                      >
                        {[10, 50, 100, 500, 1000].map(limit => (
                          <MenuItem
                            key={limit}
                            value={limit}
                            sx={{
                              color:
                                user?.theme === 'dark' ? '#D0D5DD' : 'black',
                            }}
                          >
                            {limit}
                          </MenuItem>
                        ))}
                      </Select>

                      <Box sx={{ margin: '10px 0' }}></Box>

                      {formData.selectedResponseType ===
                        ResponseType.Details && (
                        <TagInput
                          onChange={onChange}
                          name="entityId"
                          key="entityId"
                          nodeId={id}
                          labelName={`${formData.selectedObjectType} ID`}
                          placeholder={`Select a HubSpot ${formData.selectedObjectType} id`}
                          dataConnected={data?.['entityIdData'] || ''}
                          value={
                            formData?.['entityId' as keyof typeof formData] ||
                            ''
                          }
                          placement={data?.placement}
                          nodeLabel={data?.label}
                          handleId="entityId"
                          isActive={isActiveEdge(
                            edges,
                            id,
                            'entityId',
                            'target',
                          )}
                          findColor={findColor}
                          isCollapsed={true}
                          onChangePlacement={onChangePlacement}
                          height={'120px'}
                        />
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Label isAdvanced labelName="Authenticate" />
                    <Box
                      sx={{
                        p: '24px 20px',
                        mb: '24px',
                        borderRadius: '4px',
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                        display: 'flex',
                        width: '386px',

                        height: '146px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Info color="#98A2B3" size={32} />
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#98A2B3',
                          textAlign: 'center',
                        }}
                      >
                        <ConnectToHubSpot /> to log into HubSpot so you can
                        access your deals, contacts and companies and put them
                        in workflows.
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              <Box p={'16px 24px 16px 16px'}>
                <OutputTextarea
                  // previewResponses={previewResponses}
                  previewIndex={data.previewIndex}
                  value={data.text}
                  activeHandle={isActiveEdge(edges, id, 'output', 'source')}
                  placement={data?.placement}
                  labelName={'Output'}
                  findColor={findColor}
                  nodeLabel={data?.label}
                  // onPreview={onPreview}
                  // findColor={findColor}
                  // onChangePlacement={onChangePlacement}
                />
              </Box>
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
};

export default memo(PullHubSpotData);
