import React from 'react';
import KitModal from '../UI/KitModal';
import { MinusCircle } from '@phosphor-icons/react';
import { Box, Button, Typography } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanySpacesService } from '../../service/CompanySpacesService';

interface Props {
  onClose: () => void;
  spaceId: string;
}

const DisableSpaceModal: React.FC<Props> = ({ onClose, spaceId }) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const disableSpace = async () => {
    try {
      await CompanySpacesService.disableSpace(spaceId);

      setNotification({
        type: NotificationType.Success,
        message: 'Space disabled successfully',
      });

      onClose();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as string),
      });
    }
  };
  return (
    <KitModal show={true} onHide={onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '40px 60px',
          paddingBottom: '20px',
        }}
      >
        <MinusCircle size={80} color="rgba(240, 84, 80, 1)" />
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontWeight: 500,
            marginTop: '10px',
          }}
        >
          Disable Space
        </Typography>
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            lineHeight: '28px',
            width: '80%',
            letterSpacing: '0.15px',
            margin: '20px 0',
            textAlign: 'center',
          }}
        >
          If you disable a Space, all users in the space will not be able to
          access or run their flows. Are you sure you want to disable the space?
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Button variant="outlined" onClick={onClose}>
            Go Back
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: '1px solid rgba(240, 84, 80, 1)',
              color: 'rgba(240, 84, 80, 1)',
              background: 'transparent',
              '&:hover': {
                border: '1px solid rgba(240, 84, 80, 1)',
                color: 'rgba(240, 84, 80, 1)',
                background: 'transparent',
              },
            }}
            onClick={disableSpace}
          >
            Disable Space
          </Button>
        </Box>
      </Box>
    </KitModal>
  );
};

export default DisableSpaceModal;
