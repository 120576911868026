import { Box, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import Label from '../UI/Label/Label';
import SearchableSelect from '../SearchableSelect';
import { useAuthStore } from '../../store/storeAuth';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { useEdges, useReactFlow } from 'reactflow';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';

export enum PushDataOption {
  BEGINNING_OF_DOC = 'Beginning of document',
  END_OF_DOC = 'End of document',
  BEGINNING_OF_DOC_AS_A_NEW_PAGE = 'Start of document as a new page',
  END_OF_DOC_AS_A_NEW_PAGE = 'End of document as a new page',
  NEW_PAGE_AFTER_SPECIFIED_PAGE = 'After a specific page',
}

const PushGoogleDocsData: React.FC<{
  formData: any;
  onChange: (e: any) => void;
  onChangeSelect: (e: any) => void;
  id: string;
  data: any;
  type: string;
}> = ({ formData, onChange, onChangeSelect, id, data, type }) => {
  const setNotification = useNotificationStore(state => state.setNotification);
  const { user } = useAuthStore(state => state);
  const edges = useEdges();
  const { setNodes: setNodeState } = useReactFlow();
  const { setSchema, flowId, setSaving } = useFlowsStore(state => state);

  useDebounceEffect(
    () => {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowId,
        setSaving,
        changeType: 'execute',
        objectData: {
          text: data?.currentDocContent,
        },
        setSchema,
      });
    },
    [data?.currentDocContent],
    1000,
  );

  useEffect(() => {
    if (formData?.selectedDoc) {
      data?.updateSelectedDoc(formData.selectedDoc);
    }
  }, [formData]);

  const [selectedPushDataOption, setSelectedPushDataOption] =
    useState<PushDataOption>(PushDataOption.END_OF_DOC);

  const updateSelectedPushDataOption = (e: any) => {
    const { value } = e.target;

    setSelectedPushDataOption(value as PushDataOption);
  };

  const specifiedPageChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;

    if (isNaN(+value) || +value < 0) {
      return setNotification({
        type: NotificationType.Error,
        message: 'Page must be a valid positive number!',
      });
    }
    onChange(e);
  };

  const docOptions = data?.docsData?.map((doc: any) => ({
    label: doc.name,
    value: doc.id,
  }));

  return (
    <Box width={'370px'}>
      <Label
        labelStyle={{ marginTop: '10px' }}
        labelName="Document"
        isAdvanced
      />
      {!data?.selectedFolderDoc && (
        <>
          <SearchableSelect
            options={docOptions}
            label="Select Document"
            onChange={value => {
              if (value?.value === data?.docs) return;
              const selectedValue = value ? value.value : '';

              onChangeSelect({
                target: { name: 'docs', value: selectedValue },
              });

              data?.updateSelectedDoc(selectedValue);
            }}
            value={
              docOptions.find(
                (option: { value: any }) => option?.value === data?.docs,
              ) || null
            }
            className="nodrag"
            style={{ width: 100, marginBottom: '20px' }}
            sx={{
              '& .MuiList-root': {
                backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
              },
              '& .MuiSvgIcon-root': {
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              },
              borderRadius: '8px',
              border: 'none',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .Mui-selected': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                  },
                  backgroundColor:
                    user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                },
              },
            }}
          />
        </>
      )}
      {!data?.docs && (
        <Box mt={'10px'}>
          <Label
            isAdvanced
            labelStyle={{
              fontSize: '14px',
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              fontWeight: 400,
            }}
            labelName="New Document Name"
          />
          <TextField
            placeholder={'Document Name'}
            onChange={onChange}
            value={formData.newDocumentName}
            name="newDocumentName"
            fullWidth
            InputProps={{
              style: {
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              },
            }}
            sx={{
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              border: `2px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`,
            }}
          />
        </Box>
      )}
      {!!data?.docs && (
        <>
          {' '}
          <Label
            labelStyle={{ marginTop: '10px' }}
            labelName="Option"
            isAdvanced
          />
          <Select
            value={selectedPushDataOption}
            onChange={updateSelectedPushDataOption}
            className="nodrag"
          >
            {Object.values(PushDataOption).map(pushDataOption => (
              <MenuItem key={pushDataOption} value={pushDataOption}>
                {pushDataOption}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {selectedPushDataOption ===
        PushDataOption.NEW_PAGE_AFTER_SPECIFIED_PAGE && (
        <>
          <Label labelName="Page Number" isAdvanced />

          <TextField
            type="number"
            placeholder="Enter Page"
            name="specifiedPage"
            value={formData.specifiedPage}
            onChange={specifiedPageChangeHandler}
          />
        </>
      )}
    </Box>
  );
};

export default PushGoogleDocsData;
