import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import KitInput from '../UI/KitInput';
import { CompanyService } from '../../service/CompanyService';
import { useNotificationStore } from '../../store/storeNotifications';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import {
  ArrowLeft,
  Buildings,
  CheckCircle,
  Empty,
  MagnifyingGlass,
  MinusCircle,
  Plus,
  SealCheck,
  Trash,
  Warning,
  XCircle,
} from '@phosphor-icons/react';
import { getMaxUsers, getNextPlanUsers } from '../../util/planUtil';
import PaywallModal from '../UI/PaywallModal';
import LoadingSpinner from '../UI/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../Modals';
import { getAPIErrorMessage } from '../../helpers/helpers';
import UserProfile from './UserProfile';
import { User } from '../../util/Types/User';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import '../../App.scss';
import { log } from 'console';
import { color } from 'html2canvas/dist/types/css/types/color';

type Props = {
  authUser: User;
  companyData: {
    id: string;
    name: string;
    logo: string;
    companyUsers: any[];
  };
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
  setAuthUser: (user: User) => void;
};

const Company = ({
  authUser,
  setSelectedMenuItem,
  companyData,
  setAuthUser,
}: Props) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const [isNewUser, setIsNewUser] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);

  const {
    register,
    handleSubmit: handleCreateCompanySubmit,
    formState: { errors: createCompanyErrors },
  } = useForm();

  const {
    handleSubmit: handleCreateUserSubmit,
    formState: { errors: createUserErrors },
    register: registerUser,
    reset,
  } = useForm();

  const navigate = useNavigate();
  // const [companies, setCompanies] = useState<any[]>([]);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [companyUsersStats, setCompanyUsersStats] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const [viewState, setViewState] = useState<'UserOverview' | 'UserProfile'>(
    'UserOverview',
  );

  const [selectedUser, setSelectedUser] = useState<User>({} as User);

  const filterUsers = useCallback(() => {
    const companyUsers = companyUsersStats || [];

    let filtered = companyUsers;

    if (searchQuery) {
      filtered = companyUsers.filter(
        (user: any) =>
          user?.user?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user?.user?.email.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    const filteredWithoutCurrentUser = filtered.filter(
      (user: any) => user?.user?.email !== authUser?.email,
    );

    const myUserData = filtered?.find(
      (user: any) => user?.user?.email === authUser?.email,
    ) || {
      user: {
        name: authUser?.name || 'Unknown',
        email: authUser?.email || 'Unknown',
      },
      status: 'active',
      role: 'admin',
      spaces: [],
      flows: 0,
      nodeRuns: 0,
      fluxTokens: 0,
      itsFake: true,
    };

    if (
      authUser &&
      (authUser.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        authUser.email?.toLowerCase().includes(searchQuery.toLowerCase()))
    ) {
      filteredWithoutCurrentUser.unshift(myUserData);
    }

    setFilteredUsers(filteredWithoutCurrentUser);
  }, [companyUsersStats, searchQuery, authUser]);

  useEffect(() => {
    filterUsers();
  }, [filterUsers]);

  const [isModalOpen, setIsModalOPen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openUser, setOpenUser] = useState(false);
  const handleCloseUser = () => setOpenUser(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const handleCloseSuccess = () => setOpenSuccess(false);

  const [openPaywall, setOpenPaywall] = useState(false);

  const handleClosePaywall = () => {
    setOpenSuccess(false);
    setOpenPaywall(false);
  };
  const maxUsers = getMaxUsers(authUser?.plan || null);

  const companyUsersCount = filteredUsers.length + 1;

  const nextPlanIfUsersLimit = useMemo(
    () => getNextPlanUsers(authUser?.plan || null),
    [authUser?.plan],
  );

  const handleCreateCompany = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await CompanyService.createCompany(data.companyName);
      setIsNewUser(true);
      setAuthUser({
        ...authUser,
        selectedCompanyId: response.data.id,
        isUserCompany: true,
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error creating company',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCompanyUser = async (userEmail: string) => {
    setIsLoading(true);
    try {
      await CompanyService.createCompanyUser({
        email: userEmail,
        companyId: companyData.id,
      });

      reset();
      setIsNewUser(true);
      setOpenSuccess(true);
    } catch (error) {
      if (maxUsers !== null && companyUsersCount >= maxUsers) {
        setOpenPaywall(true);
      }

      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error as any),
        // show error message here
      });
    } finally {
      setIsLoading(false);
      handleCloseUser();
    }
  };

  const getCompanyUsersStats = async () => {
    try {
      const res = await CompanyService.getCompanyUsersStats(companyData.id);
      const sortedUsers = res.data.sort((a, b) => {
        if (a.role === 'admin') return -1;
        if (b.role === 'admin') return 1;
        return 0;
      });
      setCompanyUsersStats(sortedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (companyData?.id) {
      getCompanyUsersStats();
    }
  }, [companyData?.id, viewState, isLoading]);

  const onUserDisabled = () => {
    setIsNewUser(true); // Set state to trigger re-fetch
  };

  // useEffect(() => {
  //   if (viewState === 'UserOverview') {
  //     getCompanyUsersStats();
  //   }
  // }, [viewState, getCompanyUsersStats]);

  const onSubmitCreateCompany = (data: any) => {
    handleClose();
    handleCreateCompany(data);
  };

  const onSubmitCreateCompanyUser = (data: any) => {
    handleCloseUser();
    handleCreateCompanyUser(data.userEmail);
  };

  if (companiesLoading) {
    return <LoadingSpinner />;
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'header-padding',
      sortable: true,
      flex: 1,
      renderCell: params => (
        <Box
          sx={{
            cursor: 'pointer',
            color: '#2C4DFF',
            fontWeight: 400,
          }}
          onClick={() => {
            if (params.row.email === authUser?.email) {
              setSelectedMenuItem?.('profileSettings');
            }
            if (params.row.status === 'pending') {
              setNotification({
                type: 'warning',
                message:
                  'User is on pending status, they need to confirm their account by email.',
              });
            } else {
              setSelectedUser(params.row);
              setViewState('UserProfile');
            }
          }}
        >
          {params.row.name}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: params => {
        switch (params.row.status) {
          case 'active':
            return (
              <Box display="flex" gap="4px" alignItems="center">
                <CheckCircle size={16} weight="fill" color="#4CAF50" />
                {params.row.status.charAt(0).toUpperCase() +
                  params.row.status.slice(1)}
              </Box>
            );
          case 'inactive':
            return (
              <Box display="flex" gap="4px" alignItems="center">
                <MinusCircle size={16} weight="fill" color="#F05450" />
                {params.row.status.charAt(0).toUpperCase() +
                  params.row.status.slice(1)}
              </Box>
            );
          case 'pending':
            return (
              <Box display="flex" gap="4px" alignItems="center">
                <Warning size={16} weight="fill" color="#F5A524" />
                {params.row.status.charAt(0).toUpperCase() +
                  params.row.status.slice(1)}
              </Box>
            );
          default:
            return (
              <Box display="flex" gap="4px" alignItems="center">
                <Empty size={16} color="#667085" />
                {params.row.status.charAt(0).toUpperCase() +
                  params.row.status.slice(1)}
              </Box>
            );
        }
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
    },
    {
      field: 'spaces',
      headerName: 'Spaces',
      flex: 1,
      renderCell: params => {
        const spaces = params.row.spaces || [];
        return spaces.length === 0
          ? '0'
          : spaces.map((space: string, index: number) => (
              <Tooltip arrow title={spaces.join(', ')} key={index}>
                <div>{spaces.join(', ')}</div>
              </Tooltip>
            ));
      },
    },
    {
      field: 'flows',
      headerName: 'Flows',
      flex: 1,
    },
    {
      field: 'nodeRuns',
      headerName: 'Node Runs',
      flex: 1,
    },
    {
      field: 'fluxTokens',
      headerName: 'FluxTokens',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Action',
      flex: 1,
      renderCell: params => (
        <>
          <IconButton
            disabled={params.row.name === authUser?.name}
            title="Delete invitation"
            onClick={async () =>
              await CompanyService.removeCompanyUser(
                companyData.id,
                params.row.email,
              ).then(() => {
                getCompanyUsersStats();
              })
            }
          >
            <Trash
              cursor="pointer"
              fontSize={20}
              color={
                params.row.email === authUser?.email
                  ? '#667085'
                  : 'rgba(222,22,33,0.7)'
              }
            />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = filteredUsers.map((user: any, i: number) => ({
    id: user?.user?.id || i + 1,
    name: user.user?.name || user.user?.email,
    status: user.status || 'Unknown',
    role: user.role
      ? user.role.charAt(0).toUpperCase() + user.role.slice(1)
      : 'Unknown',
    spaces: user.spaces || 0,
    flows: user.flows || 0,
    nodeRuns: user.nodeRuns || 0,
    fluxTokens: user.fluxTokens || 0,
    email: user.user?.email || 'Unknown',
  }));

  const currentUserRows = rows.filter(row => row.email === authUser.email);
  const rowHeight = 52;
  const headerHeight = 56;
  const maxHeight = 400;

  const gridHeight = Math.min(
    headerHeight + rows.length * rowHeight,
    maxHeight,
  );

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: 'white',
          borderBottom: '1px solid #D0D5DD',
          display: 'flex',
          height: authUser.isUserCompany ? '67px' : '64px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
            gap: '8px',
          }}
        >
          {viewState === 'UserProfile' && (
            <IconButton
              onClick={() => {
                setViewState('UserOverview');
              }}
            >
              <ArrowLeft size={20} color="#343330" />
            </IconButton>
          )}
          Organization Users
        </Typography>
      </Box>

      {/* USER OVERVIEW */}
      {viewState === 'UserOverview' ? (
        <Box
          sx={{
            padding: '24px 24px 16px 24px',
            gap: '32px',
          }}
        >
          {(companyData?.companyUsers || []).length !== 0 ? (
            <>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  {isLoadingLogo ? (
                    <Box
                      sx={{
                        position: 'relative',
                        borderRadius: '4px',
                        width: '75px',
                        height: '75px',
                        background:
                          'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress color="warning" />
                    </Box>
                  ) : companyData.logo ? (
                    <Box
                      sx={{
                        borderRadius: '4px',
                        width: '75px',
                        height: '75px',
                        backgroundImage: `${
                          Boolean(companyData?.logo)
                            ? `url(${companyData?.logo})`
                            : ``
                        }`,
                        backgroundSize: `${
                          Boolean(companyData?.logo) ? 'cover' : '40%'
                        }`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: '4px',
                          backgroundColor: alpha('#808080', 0.8),
                          px: '2px',
                          py: '1px',
                          margin: '4px',
                        }}
                      ></Box>
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          position: 'relative',
                          borderRadius: '4px',
                          width: '75px',
                          height: '75px',
                          background:
                            'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Buildings size={40} color="#ffffff" />
                        <Box
                          sx={{
                            position: 'absolute',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            background:
                              'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
                            bottom: 0,
                            right: 1,
                            px: '1px',
                            py: '1px',
                          }}
                        ></Box>
                      </Box>
                    </>
                  )}

                  {isLoadingLogo ? (
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: '22px', width: '200px' }}
                    />
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#475467',
                            fontFamily: 'Roboto',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '133.4%',
                          }}
                        >
                          {companyData?.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#475467',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '133.4%',
                            letterSpacing: '0.1px',
                          }}
                        >
                          {authUser?.plan}&nbsp;•&nbsp;
                        </Typography>
                        <Typography
                          sx={{
                            color: '#2133BA',
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '133.4%',
                            letterSpacing: '0.1px',
                          }}
                        >
                          {companyUsersStats?.length === 0
                            ? '1'
                            : companyUsersStats?.length}
                          {companyUsersStats?.length > 1
                            ? ' Organization Users'
                            : ' Organization User'}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '16px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '20px',
                    mb: '24px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#475467',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '28.8px',
                      letterSpacing: '0.1px',
                    }}
                  >
                    User Overview
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '16px',
                    }}
                  >
                    {authUser?.plan === 'Organization' ||
                    authUser?.plan === 'Enterprise' ? (
                      <TextField
                        placeholder="Search users"
                        value={searchQuery}
                        name="searchQuery"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setSearchQuery(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MagnifyingGlass
                                size="20px"
                                color="#667085"
                                weight="thin"
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : null}

                    <Button
                      variant="outlined"
                      disabled={isLoading}
                      sx={{
                        gap: '8px',
                      }}
                      type="button"
                      onClick={() => setOpenUser(true)}
                    >
                      <Plus size={18} weight="bold" />
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                        }}
                      >
                        Add User
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                {/*  */}
                <Box
                  sx={{
                    height: 320,
                    width: '100%',
                  }}
                >
                  <Box style={{ height: gridHeight, width: '100%' }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 15 },
                        },
                      }}
                      hideFooter={rows.length < 15 ? true : false}
                      pageSizeOptions={[5, 10]}
                      sx={{
                        backgroundColor: 'white',
                        '& .MuiDataGrid-columnHeaders': {
                          backgroundColor: '#f5f5f5',
                          color: '#101828',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: 19.92,
                          letterSpacing: 0.4,
                        },
                        '& .MuiDataGrid-cell': {
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 400,

                          color: '#475467',
                        },
                        '& .MuiDataGrid-filler': {
                          width: '0%',
                        },
                        '& .MuiDataGrid-root': {
                          width: '100%',
                        },
                        '& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root':
                          {
                            alignItems: 'baseline',
                          },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box onSubmit={handleCreateCompanySubmit(onSubmitCreateCompany)}>
              <Box
                className="box-b"
                sx={{
                  display: 'flex',
                  height: '202px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '24px',
                  alignSelf: 'stretch',
                  border: '1px dashed #D0D5DD',
                  borderRadius: '4px',
                  marginBottom: '20px',
                }}
              >
                <Buildings size={42} color="#667085" weight="thin" />
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                  }}
                >
                  You have not added a organization to your account.
                </Typography>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  sx={{
                    gap: '8px',
                  }}
                  onClick={() => setOpen(true)}
                >
                  <Plus weight="bold" />
                  <Typography
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                    }}
                  >
                    Add Organization
                  </Typography>
                </Button>
              </Box>

              <Typography fontSize={'18px'} color={'#475467'} mb={'24px'}>
                User Overview
              </Typography>

              <Box style={{ height: gridHeight, width: '100%' }}>
                <DataGrid
                  rows={currentUserRows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  hideFooter
                  pageSizeOptions={[5, 10]}
                  sx={{
                    backgroundColor: 'white',
                    // paddingX: '8px',
                    // '& .MuiDataGrid-columnHeaders': {
                    //   backgroundColor: '#f5f5f5',
                    //   fontWeight: 'bold',
                    //   fontFamily: 'Roboto',
                    //   fontSize: '14px',
                    //   color: 'red',
                    // },
                    // '& .MuiDataGrid-cell': {
                    //   fontFamily: 'Roboto',
                    //   fontSize: '14px',
                    //   color: 'red',
                    // },
                    '& .MuiDataGrid-filler': {
                      width: '0%',
                    },
                    '& .MuiDataGrid-root': {
                      width: '100%',
                    },
                    '& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root':
                      {
                        alignItems: 'baseline',
                      },
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        // USER PROFILE
        <UserProfile
          user={selectedUser}
          companyId={companyData.id}
          navigate={() => setViewState('UserOverview')}
          onUserDisabled={onUserDisabled}
        />
      )}
      {/* Settings */}
      <ModalComponent
        open={isModalOpen}
        onClose={() => {
          setIsModalOPen(false);
          let url = new URL(window.location.href);
          window.history.pushState({}, '', url.pathname);
        }}
      />
      {/* Create Company */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleCreateCompanySubmit(onSubmitCreateCompany)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
              padding: '24px',
              gap: '8px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: '10px' }}
              >
                Create a New Organization
              </Typography>
              <IconButton onClick={handleClose}>
                <XCircle size={24} color="#667085" />
              </IconButton>{' '}
            </Box>
            <KitInput
              register={register}
              name="companyName"
              label="Organization Name"
              placeholder="Enter Organization name"
              errorsStatus={createCompanyErrors}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%', mt: '20px' }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Modal>

      {/* Add User to Company */}
      <Modal
        open={openUser}
        onClose={handleCloseUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleCreateUserSubmit(onSubmitCreateCompanyUser)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
              padding: '24px',
              gap: '8px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: '10px' }}
              >
                Add a New User
              </Typography>
              <IconButton onClick={handleCloseUser}>
                <XCircle size={24} color="#667085" />
              </IconButton>
            </Box>

            <KitInput
              register={registerUser}
              name="userEmail"
              type="email"
              label="User Email"
              placeholder="Enter user email"
              validation={{
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              errorsStatus={createUserErrors}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%', mt: '20px' }}
            >
              Continue
            </Button>
          </Box>
        </form>
      </Modal>

      {/* Success User */}
      <Modal
        open={openSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            padding: '24px',
            gap: '8px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCloseSuccess}>
              <XCircle size={24} color="#667085" />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <CheckCircle size={100} weight="fill" color="#67BB6A" />
            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '133.4%',
                letterSpacing: '0.1px',
              }}
            >
              Success!
            </Typography>
            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '133.4%',
                letterSpacing: '0.1px',
              }}
            >
              User has been added to your organizaion.
            </Typography>

            <Button
              type="submit"
              variant="contained"
              onClick={handleCloseSuccess}
              sx={{ width: '100%', mt: '20px' }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Fail User */}
      {/* <Modal
          open={openFail}
          onClose={handleCloseFail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box 
           sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            // height: 100,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            padding: "24px",
            gap: "8px",
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCloseFail}>
            <XCircle  size={24} color="#667085"/>
            </IconButton>
             </Box>

              <Box sx={{
                display: "flex",
                // padding: "24px",
                flexDirection: "column",
                alignItems: "center",
                gap: "12px",
              }}>
             <WarningCircle size={100} weight="fill" color="#FFC107" />
             <Typography sx={{
                  color: "#475467",
                  // textAlign: "center", 
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "133.4%",
                  letterSpacing: "0.1px",
                }}> Oops!
                </Typography>
                <Typography sx={{
                  color: "#475467",
                  // textAlign: "center", 
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "133.4%",
                  letterSpacing: "0.1px",
                }}> This user is not found in our database. Please make sure they have created an account before adding them to your company. 
                </Typography>

                 <Button   
                type="submit"
                variant="contained"
                onClick={handleCloseFail}
                sx={{ width: "100%", mt: "20px" }}
                  >
                Continue            
                </Button>
                </Box>
                </Box>  
        </Modal> */}

      {/* Paywall Modal */}
      <PaywallModal
        onHide={handleClosePaywall}
        fullWidth={true}
        show={openPaywall}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#2133BA',
                border: '1px solid #2133BA',
              }}
              onClick={() => {
                handleClosePaywall();
                setIsModalOPen(true);
                navigate('?pricing=true');
              }}
            >
              See All Plans
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#2133BA',
                color: '#FFFFFF',
                outline: '1px solid #2133BA',
              }}
              onClick={() => {
                handleClosePaywall();
                setIsModalOPen(true);
                navigate(`?pricing=true&planUsers=${nextPlanIfUsersLimit}`);
              }}
            >
              Upgrade Now
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <SealCheck size={100} weight="fill" color="#2133BA" />
            {/* <Warning size={45} color='#FDA000'/> */}
            {/* <Typography color="#101828" sx={{
          textAlign: "center",
          fontFamily: "Roboto",
          fontSize: "26px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "100%",
        }}>
        Upgrade to add more users
            </Typography> */}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              Upgrade to add more users
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              Your current plan only allows for{' '}
              {maxUsers === 1 ? maxUsers + ' user' : maxUsers + ' users'}.
              Upgrade to add more users and get advanced features.
            </Typography>
          </Box>
        </Box>
      </PaywallModal>
    </>
  );
};

export default Company;
