export const formFields = [
  {
    label: 'Model:',
    inputType: 'Select',
    id: 'model-select',
    name: 'model_version',
    options: [
      'stereo-melody-large',
      'stereo-large',
      'melody-large',
      'large',
      'encode-decode',
    ],
  },
  {
    label: 'Duration:',
    inputType: 'TextField',
    id: 'duration',
    name: 'duration',
    type: 'number',
  },
  {
    label: 'Normalization Strategy:',
    inputType: 'Select',
    id: 'normalization_strategy',
    name: 'normalization_strategy',
    options: ['peak', 'rms', 'loudness', 'clip'],
  },
  {
    label: 'Top K:',
    inputType: 'TextField',
    id: 'top-k',
    name: 'top_k',
    type: 'number',
  },
  {
    label: 'Top P:',
    inputType: 'TextField',
    id: 'top-p',
    name: 'top_p',
    type: 'number',
  },
  {
    label: 'Temperature:',
    inputType: 'TextField',
    id: 'temperature',
    name: 'temperature',
    type: 'number',
  },
  {
    label: 'Classifier free guidance:',
    inputType: 'TextField',
    id: 'classifier_free_guidance',
    name: 'classifier_free_guidance',
    type: 'number',
  },
  {
    label: 'Output format:',
    inputType: 'Select',
    id: 'outputFormat',
    name: 'outputFormat',
    options: ['wav', 'mp3'],
  },
  {
    label: 'Seed:',
    inputType: 'input',
    id: 'seed',
    name: 'seed',
    type: 'number',
  },
];

export type AiMusicGeneratorFormValues = {
  model_version: string;
  prompt: string;
  duration: number;
  normalization_strategy: string;
  top_k: number;
  top_p: number;
  temperature: number;
  classifier_free_guidance: number;
  outputFormat: string;
  seed: 0;
  audioUrl: string;
};
