import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { UserService } from '../../service/UserService';
import { Screen } from './Welcome';
import PopupWithVideo from '../../components/PopupWithVideo/PopupWithVideo';
import './Welcome.css';
import PlayIcon from '../../assets/icons/Play.png';

interface Props {
  setScreen: (screen: Screen) => void;
  questionsData: {
    company: string;
    role: string;
    teamSize: number;
    industry: string;
    phoneNumber: string;
  };
  setQuestionsData: (questionsData: {
    company: string;
    role: string;
    teamSize: number;
    industry: string;
    phoneNumber: string;
  }) => void;
}
const videoUrl = 'https://www.youtube.com/watch?v=0jLYje-FECs';

const Questions: React.FC<Props> = ({
  setScreen,
  questionsData,
  setQuestionsData,
}) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const [playVideo, setPlayVideo] = useState(true);

  const [company, setCompany] = useState(questionsData.company);
  const [role, setRole] = useState(questionsData.role);
  const [teamSize, setTeamSize] = useState<number>(questionsData.teamSize);
  const [industry, setIndustry] = useState(questionsData.industry);
  const [phoneNumber, setPhoneNumber] = useState(questionsData.phoneNumber);
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);

  const companyChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  };

  const roleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRole(e.target.value);
  };

  const teamSizeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '' || (!isNaN(parseInt(value)) && parseInt(value) > 0)) {
      setTeamSize(parseInt(value));
    }
  };

  const industryChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setIndustry(value);
  };

  const phoneNumberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regex = /^\+?[0-9\s\-()]*$/;
    if (regex.test(value)) {
      setPhoneNumber(value);
      setPhoneNumberInvalid(false);
    } else {
      setPhoneNumberInvalid(true);
    }
  };

  const updateOnboardingHandler = async () => {
    try {
      await UserService.updateOnboarding({
        company,
        role,
        teamSize,
        industry,
        phoneNumber,
        page: 2,
      });

      setQuestionsData({
        company,
        role,
        teamSize,
        industry,
        phoneNumber,
      });

      setScreen(Screen.UserPlan);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  return (
    <>
      {playVideo && (
        <PopupWithVideo
          videoUrl={videoUrl}
          onClose={() => setPlayVideo(false)}
        />
      )}

      <div>
        <h2 className={'get-started-text'}>Let's get started!</h2>

        <TextField
          label="What company do you work for?"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 20 }}
          onChange={companyChangeHandler}
          value={company}
        />

        <TextField
          label="What is your role?"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 20 }}
          onChange={roleChangeHandler}
          value={role}
        />

        <TextField
          label="What’s the size of your team?"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 20 }}
          type="number"
          onChange={teamSizeChangeHandler}
          value={teamSize || ''}
        />

        <TextField
          label="Which industry or sector?"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 20 }}
          onChange={industryChangeHandler}
          value={industry}
        />

        <TextField
          label="What is your phone number?"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 20 }}
          onChange={phoneNumberChangeHandler}
          value={phoneNumber}
          type="tel"
          placeholder="+1-800-123-4567"
          helperText={phoneNumberInvalid ? 'Invalid phone number format' : ''}
          error={phoneNumberInvalid}
        />

        <div className="questions-actions-container">
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setPlayVideo(true)}
            endIcon={<img src={PlayIcon} alt="Play" />}
          >
            Play Introduction Video
          </Button>

          <Button
            fullWidth
            variant="contained"
            onClick={updateOnboardingHandler}
            disabled={phoneNumberInvalid}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default Questions;
