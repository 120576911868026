export const mergeText = (object: {
  style?: string;
  view?: string;
  subject?: string;
  environment?: string;
  tone?: string;
  style_present?: string;
  prompt?: string;
}) => {
  let mergedText = '';

  if (object.prompt) {
    mergedText += 'Prompt: ' + object.prompt + '\n ';
  }

  if (object.style && object.style_present === 'None') {
    mergedText += 'Style: ' + object.style + '\n ';
  }

  if (object.view) {
    mergedText += ' ' + 'View: ' + object.view + '\n';
  }

  if (object.subject) {
    mergedText += ' ' + 'Subject: ' + object.subject + '\n';
  }

  if (object.environment) {
    mergedText += ' ' + 'Environment: ' + object.environment + '\n';
  }

  if (object.tone) {
    mergedText += ' ' + 'Tone: ' + object.tone + '\n';
  }

  return mergedText;
};

export const stringifyAndModify = (obj: any, indent = '') => {
  let result = '';
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      result += `${indent}${key}:\n`;
      result += stringifyAndModify(obj[key], indent + '  ');
    } else {
      result += `${indent}${key}: ${obj[key]}\n`;
    }
  }
  return result;
};

export const modifyAllSearchDataToSingleOne = (searchData: any) => {
  let fullText = '';
  if (searchData?.length > 0) {
    fullText += stringifyAndModify(searchData);
  }
  if (searchData?.organic) {
    searchData?.organic?.forEach((item: any) => {
      fullText += stringifyAndModify(item);
    });
  }

  if (searchData?.relatedSearches) {
    searchData?.relatedSearches?.forEach((item: any) => {
      fullText += stringifyAndModify(item);
    });
  }

  if (searchData?.images) {
    searchData?.images?.forEach((item: any) => {
      fullText += stringifyAndModify(item);
    });
  }

  if (searchData?.places) {
    searchData?.places?.forEach((item: any) => {
      fullText += stringifyAndModify(item);
    });
  }

  if (searchData?.news) {
    searchData?.news?.forEach((item: any) => {
      fullText += stringifyAndModify(item);
    });
  }

  return fullText;
};
