import { useEffect, useMemo, useRef, useState } from 'react';
import KitModal from '../UI/KitModal';
import {
  Box,
  Button,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import {
  CheckCircle,
  FileAudio,
  Microphone,
  StopCircle,
  UploadSimple,
} from '@phosphor-icons/react';
import { useNotificationStore } from '../../store/storeNotifications';
import { VoiceService } from '../../service/VoiceService';
import PaywallModal from '../UI/PaywallModal';
import { ReactComponent as VoiceClone } from '../../assets/icons/VoiceClone.svg';
import ModalComponent from '../Modals/';
import { useNavigate } from 'react-router-dom';
import { getNextPlanToAdvanced } from '../../util/planUtil';
import { useAuthStore } from '../../store/storeAuth';
import { EvenLabsService } from '../../service/EvanLabsServce';
import useFlowsStore from '../../store/storeFlows';
import { getAPIErrorMessage } from '../../helpers/helpers';

type CloneModalProps = {
  show: boolean;
  system: string;
  onHide: () => void;
};
const mimeType = 'audio/webm';

const CloneModal = ({ show, onHide }: CloneModalProps) => {
  const { flowId } = useFlowsStore();
  const [tabs, setTabs] = useState(0);
  const [startRecording, setStartRecording] = useState({
    timer: 0,
    recording: false,
    finished: false,
  });

  const setNotification = useNotificationStore(state => state.setNotification);
  const { user } = useAuthStore(state => state);

  const [stream, setStream] = useState<MediaStream>();
  const [name, setName] = useState('');
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const mediaRecorder = useRef<any>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);

  const [permission, setPermission] = useState(false);
  const [audio, setAudio] = useState<string | null>(null);
  const [testFile, setTestFile] = useState<any>(null);
  const [audioChunks, setAudioChunks] = useState<MediaRecorderOptions[]>([]);

  const [openPaywall, setOpenPaywall] = useState(false);
  const handleClose = () => {
    setOpenPaywall(false);
  };
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOPen] = useState(false);

  const nextPlanIfStarter = useMemo(
    () => getNextPlanToAdvanced(user?.plan || null),
    [user?.plan],
  );

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setStream(streamData);

        setPermission(true);
      } catch (err) {
        setNotification({
          message: getAPIErrorMessage(err as any),
          type: 'error',
        });

        setPermission(false);
      }
    } else {
      setNotification({
        message: 'The MediaRecorder API is not supported in your browser.',
        type: 'error',
      });
      setPermission(false);
    }
  };

  const onStartRecording = async () => {
    const media = new MediaRecorder(stream!);

    mediaRecorder.current = media;

    mediaRecorder.current.start();

    let localAudioChunks: MediaRecorderOptions[] = [];

    mediaRecorder.current.ondataavailable = (event: any) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    setAudioChunks(localAudioChunks);

    setStartRecording({
      timer: 0,
      recording: true,
      finished: false,
    });
  };

  const deleteAndRerecord = () => {
    setAudio(null);
    setStartRecording({
      timer: 0,
      recording: false,
      finished: false,
    });

    onStartRecording();
  };

  const onStopRecording = async () => {
    mediaRecorder.current.stop();
    const audioContext = new AudioContext();

    await audioContext.close();

    const microphone = audioContext.createMediaStreamSource(stream!);
    microphone.disconnect();

    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks as any, { type: mimeType });
      setTestFile(audioBlob);
      const audioUrl = URL.createObjectURL(audioBlob);

      setAudio(audioUrl);

      setAudioChunks([]);
    };

    setPermission(false);
    setStartRecording({
      ...startRecording,
      recording: false,
      finished: true,
    });
  };

  useEffect(() => {
    if (startRecording.recording) {
      const interval = setInterval(() => {
        // if is 5 minutes stop recording

        if (startRecording.timer === 300) {
          clearInterval(interval);
          onStopRecording();
          return;
        }

        setStartRecording({
          ...startRecording,
          timer: startRecording.timer + 1,
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startRecording]);

  useEffect(() => {
    if (permission) {
      onStartRecording();
    }
  }, [permission]);

  const timerCalc = (timer: number) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    // add 00 if less than 10 second or minutes

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const onCloneVoice = async () => {
    if (tabs === 0) {
      if (!audio) {
        setNotification({
          message: 'Please record a voice',
          type: 'error',
        });
        return;
      }
    }

    if (tabs === 1) {
      if (!uploadedFile) {
        setNotification({
          message: 'Please upload a file',
          type: 'error',
        });
        return;
      }
    }

    try {
      if (tabs === 0) {
        const audioFile = new File([testFile as any], 'audio.webm');
        const formData = new FormData();
        formData.append('file', audioFile);

        const newAudoFileMp3 = new File([audioFile], 'audio.mp3', {
          type: 'audio/mp3',
        });

        const convertAudioToMp3 = new FormData();
        convertAudioToMp3.append('file', newAudoFileMp3);

        await VoiceService.generateClonedVoice({
          file: formData,
          voiceName: name,
          flowId,
        });

        setNotification({
          message: 'Voice cloned successfully',
          type: 'success',
        });
      }

      if (tabs === 1) {
        const formData = new FormData();
        formData.append('file', uploadedFile);

        await EvenLabsService.clone(
          {
            name: name,
            type: 'upload',
            flowId,
          },
          formData,
        );

        setNotification({
          message: 'Voice cloned successfully',
          type: 'success',
        });
      }

      setAudio(null);
      setUploadedFile(null);
      setName('');
      setStartRecording({
        timer: 0,
        recording: false,
        finished: false,
      });
      setStream(undefined);
      setPermission(false);
      setTestFile(null);
      setAudioChunks([]);
      setTabs(0);
      onHide();

      // onHide();
    } catch (err) {
      let error = err as any;
      console.log(error);
      if (error.message.includes('maximum number of cloned voices')) {
        setNotification({
          message:
            'You have reached the maximum number of cloned voices of (50)',
          type: 'error',
        });
      } else {
        setOpenPaywall(true);
      }
    }
  };

  const onHideModal = () => {
    setPermission(false);
    onHide();
  };

  return (
    <>
      <KitModal
        maxWidth={'md'}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '668px',
            borderRadius: '16px',
          },
        }}
        fullWidth
        onHide={onHideModal}
        show={show}
      >
        <Box>
          <Typography variant="h6">Clone a voice</Typography>
          <Typography mt={'5px'} variant="body2">
            Upload an audio file, video file or record yourself speaking to
            create a voice clone.
          </Typography>

          <Tabs
            value={tabs}
            onChange={(event, newValue) => {
              setTabs(newValue);
            }}
            sx={{
              mt: '24px',
            }}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{
                textTransform: 'none',
              }}
              label="Record a Voice Memo"
              {...a11yProps(0)}
            />
            <Tab
              sx={{
                textTransform: 'none',
              }}
              label="Upload a file"
              {...a11yProps(1)}
            />
          </Tabs>

          {tabs === 0 && (
            <Box mt={'32px'}>
              <Typography variant="h6">Record your voice</Typography>
              <Typography mt={'5px'} variant="body2">
                Name the voice recording, hit record and choose the voice you
                want to clone.
              </Typography>

              <TextField
                sx={{
                  mt: '24px',
                }}
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
                variant="outlined"
                label="Name your voice"
              />

              <Box
                mt={'24px'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  columnGap: '16px',
                  border: '1px dashed #667085',
                  padding: '10px',
                  background: '#F2F4F7',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  getMicrophonePermission();
                }}
              >
                {startRecording.finished === true && (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <CheckCircle size={32} weight="fill" color="#4CAF50" />
                    <Typography
                      fontFamily={'Roboto , sans-serif'}
                      textTransform={'none'}
                      variant="button"
                      mt={'8px'}
                      letterSpacing={'0.17px'}
                      fontSize={'20px'}
                      color={'#475467'}
                    >
                      Recording successful
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mt: '24px',
                        borderRadius: '6px',
                        border: '1px solid #3650DE',
                        height: '38px',
                        padding: ' 0 10px',
                        background: '#E7F0FF',
                      }}
                    >
                      <FileAudio size={16} />{' '}
                      <Typography fontSize={'12px'} margin={0} variant="body2">
                        Recorded Voice 1
                      </Typography>
                    </Box>

                    {audio ? (
                      <div className="audio-player">
                        <audio src={audio} controls></audio>
                      </div>
                    ) : null}

                    <Button
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        mb: '14px',
                      }}
                      onClick={deleteAndRerecord}
                      variant="outlined"
                    >
                      Delete and rerecord <StopCircle size={20} />
                    </Button>
                  </Box>
                )}
                {startRecording.timer !== 0 &&
                  startRecording.finished === false && (
                    <Typography
                      fontFamily={'Roboto , sans-serif'}
                      mb={'10px'}
                      mt={'15px'}
                      textTransform={'none'}
                      variant="button"
                      letterSpacing={'0.17px'}
                      fontSize={startRecording?.recording ? '20px' : '14px'}
                      color={'#98A2B3'}
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onStartRecording();
                      }}
                    >
                      {timerCalc(startRecording.timer)}
                    </Typography>
                  )}
                {!startRecording.finished && (
                  <>
                    <Box
                      p={'10px'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      border={'1px solid #7388F7'}
                      borderRadius={'50%'}
                      mb="10px"
                      mt={'10px'}
                    >
                      <Microphone size={36} color="#3650DE" />
                    </Box>

                    <Typography
                      fontFamily={'Roboto , sans-serif'}
                      mb={'10px'}
                      textTransform={'none'}
                      variant="button"
                      letterSpacing={'0.17px'}
                      fontSize={startRecording?.recording ? '20px' : '14px'}
                      color={'#98A2B3'}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {startRecording.recording
                        ? 'Recording...'
                        : 'Click to record voice'}
                    </Typography>

                    {/* {startRecording.recording && (
                    <Button
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        mt: '24px',
                      }}
                      onClick={onStopRecording}
                      variant='outlined'
                    >
                      Stop recording <StopCircle size={20} />
                    </Button>
                  )} */}
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {startRecording.recording && (
                  <Button
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: '24px',
                    }}
                    onClick={onStopRecording}
                    variant="outlined"
                  >
                    Stop recording <StopCircle size={20} />
                  </Button>
                )}
              </Box>
            </Box>
          )}

          {tabs === 1 && (
            <Box>
              <Box mt={'32px'}>
                <Typography variant="h6">Upload a file</Typography>
                <Typography mt={'5px'} variant="body2">
                  Name the voice, upload an audio or movie file and continue to
                  clone your voice.
                </Typography>

                <TextField
                  sx={{
                    mt: '24px',
                  }}
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  fullWidth
                  variant="outlined"
                  label="Name your voice"
                />

                <InputLabel htmlFor="file-upload">
                  <Box
                    mt={'24px'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      columnGap: '16px',
                      border: '1px dashed #667085',
                      padding: '24px',
                      background: '#F2F4F7',
                      borderRadius: '8px',
                    }}
                  >
                    {uploadedFile && (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                      >
                        <CheckCircle size={32} weight="fill" color="#4CAF50" />
                        <Typography
                          fontFamily={'Roboto , sans-serif'}
                          textTransform={'none'}
                          variant="button"
                          mt={'8px'}
                          letterSpacing={'0.17px'}
                          fontSize={'20px'}
                          color={'#475467'}
                        >
                          Recording successful
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            mt: '24px',
                            borderRadius: '6px',
                            border: '1px solid #3650DE',
                            height: '38px',
                            padding: ' 0 10px',
                            background: '#E7F0FF',
                          }}
                        >
                          <FileAudio size={16} />{' '}
                          <Typography
                            fontSize={'12px'}
                            margin={0}
                            variant="body2"
                          >
                            Recorded Voice 1
                          </Typography>
                        </Box>

                        {uploadedFile ? (
                          <div className="audio-player">
                            <audio
                              src={URL.createObjectURL(uploadedFile)}
                              controls
                            ></audio>
                          </div>
                        ) : null}

                        <Button
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px',
                            mb: '14px',
                          }}
                          onClick={() => setUploadedFile(null)}
                          variant="outlined"
                        >
                          Choose a different file <StopCircle size={20} />
                        </Button>
                      </Box>
                    )}
                    {!uploadedFile && (
                      <>
                        <UploadSimple color="#98A2B3" size={64} />
                        <Typography
                          fontFamily={'Roboto , sans-serif'}
                          textTransform={'none'}
                          variant="button"
                          mt={'8px'}
                          letterSpacing={'0.17px'}
                          fontSize={'20px'}
                          color={'#98A2B3'}
                        >
                          Click to upload an audio
                        </Typography>
                        <Typography
                          fontFamily={'Roboto , sans-serif'}
                          textTransform={'none'}
                          variant="button"
                          mt={'8px'}
                          letterSpacing={'0.17px'}
                          fontSize={'13px'}
                          color={'#98A2B3'}
                        >
                          Please upload an audio file (MP3).
                        </Typography>
                        <input
                          type="file"
                          id="file-upload"
                          // id={`image-upload-${data.myId}`}
                          hidden
                          onChange={e => {
                            const files = e.target.files;
                            if (files) {
                              setUploadedFile(files[0]);
                            }
                          }}
                          style={{
                            width: '100%',
                          }}
                        />
                      </>
                    )}
                  </Box>
                </InputLabel>
              </Box>
            </Box>
          )}

          <Box display={'flex'} justifyContent={'end'} mt={'60px'}>
            <Button onClick={onCloneVoice} variant="contained">
              Clone voice
            </Button>
          </Box>
        </Box>
      </KitModal>

      <ModalComponent
        open={isModalOpen}
        onClose={() => {
          setIsModalOPen(false);
          let url = new URL(window.location.href);
          window.history.pushState({}, '', url.pathname);
        }}
        // resetToPricing={true}
      />
      <PaywallModal
        onHide={handleClose}
        fullWidth={true}
        show={openPaywall}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
              }}
              onClick={() => {
                handleClose();
                setIsModalOPen(true);
                navigate('?pricing=true');
              }}
            >
              See All Plans
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#3650DE',
                color: '#FFFFFF',
                outline: '1px solid #3650DE',
              }}
              onClick={() => {
                handleClose();
                setIsModalOPen(true);
                navigate(`?pricing=true&planstarter=${nextPlanIfStarter}`);
              }}
            >
              Upgrade Now
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <VoiceClone />
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              Voice Cloning
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              Voice cloning is only available to our premium subscribers.
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              To create custom voices, upgrade to one of our premium plans.
            </Typography>
          </Box>
        </Box>
      </PaywallModal>
    </>
  );
};

export default CloneModal;
