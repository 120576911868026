import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import { FlowService } from '../../service/FlowService';
import { useNotificationStore } from '../../store/storeNotifications';
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import * as pdfjs from 'pdfjs-dist';
import { NodeProps } from '../../util/Types/NodeProps';

import { MenuItem, Select, Box } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import useFlowsStore from '../../store/storeFlows';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import Label from '../UI/Label/Label';
import { allValuesByType } from '../../util/dataStoredToType';
import { ArrowsCounterClockwise } from '@phosphor-icons/react';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import LoadingSpinner from '../UI/LoadingSpinner';
import { isActiveEdge } from '../../util/findActiveHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const FlowDataCaller = ({
  id,
  data,

  type,
}: NodeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setNodes: setNodeState } = useReactFlow();

  const [nodes, setNodes] = useState<any[]>([]);
  const { setNotification } = useNotificationStore(state => state);
  const edges = useEdges();
  const {
    flowId: flowIdTest,
    setSchema,
    setSaving,
    flows,
  } = useFlowsStore(state => state);

  const [formData, setFormData] = useState({
    flowId: 'none',
    nodeId: 'none',
    text: 'none',
    field: 'none',
  });

  useEffect(() => {
    setFormData({
      flowId: data.flowId || 'none',
      nodeId: data.nodeId || 'none',
      text: data.text || '',
      field: data.field || 'none',
    });
  }, [data]);

  const onChange = useCallback(
    async (e: { target: { value: any; name: string } }) => {
      const value = e.target.value;

      let object = {};

      if (e.target.name === 'flowId') {
        object = {
          flowId: value,
          nodeId: 'none',
          text: '',
          field: 'none',
        };
      } else if (e.target.name === 'nodeId') {
        object = {
          nodeId: value,
          text: '',
          field: 'none',
        };
      } else {
        const dataTextFromValue =
          nodes.find((node: any) => node.id === formData.nodeId)?.data?.[
            value
          ] || '';

        object = {
          field: value,
          text: dataTextFromValue || '',
        };
      }

      setFormData({
        ...formData,
        ...object,
      });

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowId: flowIdTest,
        objectData: {
          ...object,
        },
        setSchema,
      });
    },
    [formData, nodes],
  );

  useEffect(() => {
    const getFlow = async () => {
      try {
        const response = await FlowService.getFlow(data.flowId);

        setNodes(response.data.nodes);
      } catch (error) {
        setNotification({
          message: 'Error fetching flow',
          type: 'error',
        });
      } finally {
      }
    };
    if (data.flowId) {
      getFlow();
    }
  }, [data.flowId]);

  const valuesOfNodeId = useMemo(() => {
    if (formData.flowId === 'none') {
      return [];
    }

    const findNode = nodes.find((node: any) => node.id === formData.nodeId);

    if (!findNode) {
      return [];
    }

    const nodeType = findNode?.type || '';

    return allValuesByType[nodeType as keyof typeof allValuesByType] || [];
  }, [nodes, flows, formData]);

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  return (
    <Node
      id={id}
      type={type}
      data={data}
      isLoading={isLoading}
      showTokensUsed={false}
    >
      <Box display={'flex'}>
        <Box
          p={'16px'}
          borderRight={`1px solid ${
            user?.theme === 'dark' ? '#475467' : '#EAECF0'
          }`}
        >
          {isLoading ? (
            <Box width={'350px'}>
              <LoadingSpinner height="30vh" />
            </Box>
          ) : (
            <Box width={'350px'}>
              <Label labelName="Select a Flow" isAdvanced={true} />

              <Select
                name="flowId"
                value={formData.flowId}
                onChange={onChange}
                className="nodrag"
                size="small"
                sx={{
                  '& .MuiList-root': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  },
                  '& .MuiSvgIcon-root': {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                  borderRadius: '8px',
                  border: 'none',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                      },
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                  },
                }}
              >
                <MenuItem
                  value="none"
                  disabled
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  None Selected
                </MenuItem>

                {flows
                  ?.filter(flow => flow.id !== flowIdTest)
                  ?.map((option, index) => (
                    <MenuItem
                      value={option.id}
                      key={index}
                      sx={{
                        color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          )}

          <Box mt={'16px'}>
            <Label labelName="Select a Node" isAdvanced={true} />

            <Select
              name="nodeId"
              value={formData.nodeId}
              onChange={onChange}
              className="nodrag"
              size="small"
              sx={{
                '& .MuiList-root': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                },
                '& .MuiSvgIcon-root': {
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
                borderRadius: '8px',
                border: 'none',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .Mui-selected': {
                      backgroundColor:
                        user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                    },
                    backgroundColor:
                      user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  },
                },
              }}
            >
              <MenuItem
                value="none"
                disabled
                sx={{
                  color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                }}
              >
                Select a Flow
              </MenuItem>

              {nodes.map((option, index) => (
                <MenuItem
                  value={option.id}
                  key={index}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  {option?.data?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box mt={'16px'}>
            <Label labelName="Select a Node Field" isAdvanced={true} />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Select
                name="field"
                value={formData.field}
                onChange={onChange}
                className="nodrag"
                size="small"
                sx={{
                  '& .MuiList-root': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  },
                  '& .MuiSvgIcon-root': {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                  borderRadius: '8px',
                  border: 'none',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                      },
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                  },
                }}
              >
                <MenuItem
                  value="none"
                  disabled
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  None Selected
                </MenuItem>
                {valuesOfNodeId.map((option, index) => (
                  <MenuItem
                    sx={{
                      textTransform: 'capitalize',
                      color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                    }}
                    value={option}
                    key={index}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>

              <IconButtonTooltip
                onClick={() =>
                  onChange({ target: { value: formData.field, name: 'field' } })
                }
                title="Refresh if any of field has updates"
              >
                <ArrowsCounterClockwise
                  size={20}
                  color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                />
              </IconButtonTooltip>
            </Box>
          </Box>
        </Box>
        <Box p={'16px 24px 16px 16px'}>
          <OutputTextarea
            value={data.text}
            activeHandle={isActiveEdge(edges, id, 'output', 'source')}
            findColor={findColor}
            // activeHandle={
            //   edges?.find(
            //     (edge: any) =>
            //       edge?.source === id && edge?.sourceHandle === 'output'
            //   )?.source
            //     ? true
            //     : false
            // }
            activeColor={findColor}
            placement={data?.placement}
            labelName={'Output'}
            nodeLabel={data?.label}
          />
        </Box>
      </Box>
    </Node>
  );
};

export default memo(FlowDataCaller);
