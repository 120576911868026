import { Typography } from '@mui/material';
import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { Handle, Position } from 'reactflow';
import { useAuthStore } from '../../../store/storeAuth';

type InputHandleProps = {
  index: number;
  colorActive?: { background: string; color: string };
};

const InputHandle = ({ index, colorActive }: InputHandleProps) => {
  const { user } = useAuthStore(state => state);
  return (
    <Handle
      // className='flow-global-handle-input'
      type='target'
      position={Position.Left}
      id='input'
      //   isConnectable={isConnectable}
      style={{
        background: index ? colorActive?.background :(user?.theme === 'dark' ? '#1D2939' : '#fff'),
        width: index ? '32px' : '29px',
        height: index ? '32px' : '29px',
        border: index ? '0px ' : '2px solid #667085',
        borderColor: index ? 'none' : '#667085',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: '-16px',
      }}
    >
      {index ? (
        <Typography
          sx={{
            color: user?.theme === 'dark' ? '#1D2939' : '#fff',
            fontSize: '16px',
            fontWeight: 700,
            pointerEvents: 'none',
          }}
        >
          {index}
        </Typography>
      ) : (
        <ArrowRight
          weight='bold'
          color={index ? '#fff' : '#667085'}
          pointerEvents={'none'}
          size={14}
        />
      )}
    </Handle>
  );
};

export default InputHandle;
