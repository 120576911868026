import { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { stringToColor } from './utilis';
import Y from 'yjs';
import { useAuthStore } from '../../../store/storeAuth';

export type Cursor = {
  id: string;
  color: string;
  x: number;
  y: number;
  timestamp: number;
  userName: string;
};

export function useCursorStateSynced(ydoc: Y.Doc) {
  const [cursors, setCursors] = useState<Cursor[]>([]);
  const { screenToFlowPosition } = useReactFlow();
  const authUser = useAuthStore(state => state.user);

  const cursorsMap: any = ydoc.getMap<Cursor>('cursors');
  const cursorId = ydoc.clientID.toString();

  const cursorColor = stringToColor(
    cursors?.findIndex(({ id }) => id === cursorId) ?? 0,
  );

  const MAX_IDLE_TIME = 10000;
  // Flush any cursors that have gone stale.
  const flush = useCallback(() => {
    const now = Date.now();

    for (const [id, cursor] of cursorsMap) {
      if (now - cursor.timestamp > MAX_IDLE_TIME) {
        cursorsMap.delete(id);
      }
    }
  }, [cursorsMap]);

  const onMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!authUser?.id) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      cursorsMap.set(cursorId, {
        id: cursorId,
        color: cursorColor,
        x: position.x,
        y: position.y,
        timestamp: Date.now(),
        userName: authUser?.name,
      });
    },
    [screenToFlowPosition, authUser?.id, cursorsMap],
  );

  useEffect(() => {
    const timer = window.setInterval(flush, MAX_IDLE_TIME);
    const observer = () => {
      setCursors([...cursorsMap.values()]);
    };

    flush();
    setCursors([...cursorsMap.values()]);
    cursorsMap.observe(observer);

    return () => {
      cursorsMap.unobserve(observer);
      window.clearInterval(timer);
    };
  }, [flush]);

  const cursorsWithoutSelf = useMemo(
    () => cursors.filter(({ id }) => id !== cursorId),
    [cursors],
  );

  return [cursorsWithoutSelf, onMouseMove] as const;
}

export default useCursorStateSynced;
