import React from 'react';
import { Box } from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';
import SearchableSelect from '../SearchableSelect';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useEdges } from 'reactflow';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import TagInput from '../Test/TagInput';

const PushGoogleSheetsData: React.FC<{
  formData: any;
  onChange: (e: any) => void;
  onChangeSelect: (e: any) => void;
  id: string;
  data: any;
  type: string;
  spreadSheets: any[];
  workSheets: any[];
}> = ({
  formData,
  onChange,
  onChangeSelect,
  id,
  data,
  type,
  spreadSheets,
  workSheets,
}) => {
  const edges = useEdges();

  const { user } = useAuthStore(state => state);

  const spreadSheetOptions = spreadSheets.map((sheet: any) => ({
    label: sheet.name,
    value: sheet.id,
  }));

  const workSheetOptions = [
    { sheetId: 'new', title: 'Create New Sheet' },
    ...workSheets,
  ].map((sheet: any) => ({
    label: sheet.title,
    value: sheet.sheetId,
  }));

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;

  return (
    <Box width={'370px'}>
      <Box>
        <Box sx={{ margin: '10px 0' }} />
        <SearchableSelect
          options={spreadSheetOptions}
          label="Select a spreadsheet"
          onChange={value => {
            const selectedValue = value ? value.value : '';
            onChangeSelect({
              target: { name: 'spreadsheet', value: selectedValue },
            });
            data?.updateSelectedSpreadSheet(selectedValue);
          }}
          value={
            spreadSheetOptions.find(
              option => option.value === data?.selectedSpreadSheet?.id,
            ) || null
          }
          className="nodrag"
          style={{ width: 100 }}
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        />

        <Box sx={{ margin: '15px 0' }}></Box>

        <SearchableSelect
          options={workSheetOptions}
          label="Select a sheet"
          onChange={value => {
            const selectedValue = value ? value.value : '';
            onChangeSelect({
              target: { name: 'sheets', value: selectedValue },
            });
            data?.updateSelectedWorkSheet(Number(selectedValue));
          }}
          value={
            formData.sheets === 'new'
              ? workSheetOptions[0]
              : workSheetOptions.find(
                  option => option.value === data?.selectedWorkSheet?.sheetId,
                ) || null
          }
          style={{ width: 200 }}
          sx={{
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            },
            '& .MuiSvgIcon-root': {
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            },
            borderRadius: '8px',
            border: 'none',
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
          }}
        />

        <Box sx={{ margin: '10px 0' }} />

        {formData.sheets === 'new' && (
          <Box>
            <TagInput
              placeholder={'Sheet Name'}
              onChange={onChange}
              value={formData.name}
              dataConnected={data?.sheetNameData || []}
              isAdvanced={true}
              labelName="New Sheet Name"
              findColor={findColor}
              isCollapsed={true}
              showHandle={false}
              handleId={'sheetName'}
              nodeId={id}
              isActive={isActiveEdge(edges, id, 'sheetName', 'target')}
              advancedCollapsed={true}
              name="name"
              fullWidth
              InputProps={{
                style: {
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                },
              }}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                border: `2px solid ${
                  user?.theme === 'dark' ? '#475467' : '#EAECF0'
                }`,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PushGoogleSheetsData;
