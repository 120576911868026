import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Edge, useEdges } from 'reactflow';
import { ScriptingService } from '../../service/ScriptingService';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import ScriptEditor from '../Scripting/ScriptingHelper';
import { Box } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';

const Scripting = ({
  id,
  data,

  type,
}: NodeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const edges: Edge<any>[] = useEdges();
  const [btnLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });

  const { setNodeState, setSchema, setSaving, flowId } = useFlowsStore(
    state => state,
  );

  const [variables, setVariables] = useState<
    {
      id: string;
      name: string;
      value: string;
    }[]
  >([]);

  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [script, setScript] = useState('');
  const [language, setLanguage] = useState('');

  useEffect(() => {
    if (!isChangingDirectly) {
      if (data.variables) {
        setVariables(data.variables);
      } else {
        const newConditions = [
          {
            id: 'func' + Math.random().toString(36).substr(2, 9),
            name: 'varOne',
            value: '',
          },
        ];
        setVariables(newConditions);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            variables: newConditions,
          },
          setSchema,
        });
      }
    }
  }, [data.variables, id, isChangingDirectly]);

  console.log(variables);

  useMemo(() => {
    if (!isChangingDirectly) {
      setScript(data?.script || '');
      setLanguage(data?.language || '');
    }
  }, [data?.script, data?.result]);

  const addVariable = () => {
    const newVariable = {
      id: 'func' + Math.random().toString(36).substr(2, 9),
      name: '',
      value: '',
    };

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: {
        variables: [...variables, newVariable],
      },
      setSchema,
    });

    setVariables([...variables, newVariable]);
  };

  const debounceTimeoutRef = useRef<any>();
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          execute();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, variables, script, data?.canceled]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const execute = async () => {
    try {
      setIsLoading(true);

      let scriptData = script;

      if (data?.scriptData) {
        scriptData = data?.scriptData?.map((item: any) => item.text).join(' ');
      }

      const formattedVariables = variables?.map((item: any) => {
        let value;

        const isNumber = !isNaN(item?.value);
        if (isNumber) {
          value = Number(item.value);
        } else if (item.value === 'true') {
          value = true;
        } else if (item.value === 'false') {
          value = false;
        } else {
          value = item.value;
        }

        return { name: item.name, value };
      });

      const variablesString = formattedVariables
        ?.map((variable: any) => {
          if (language === 'JavaScript') {
            return `const ${variable.name} = ${JSON.stringify(
              variable.value,
            )};`;
          } else if (language === 'Python') {
            const pythonValue =
              typeof variable.value === 'string'
                ? `"${variable.value}"`
                : variable.value;
            return `${variable.name} = ${pythonValue}`;
          }
          return '';
        })
        .join('\n');

      const scriptWithVariables = `${variablesString}\n\n${scriptData}`.trim();

      const body = {
        script: scriptWithVariables,
        endpoint:
          language === 'JavaScript' ? '/script/javascript' : '/script/python',
      };

      const response = await ScriptingService.scripting(body);

      const executedResult = response.data.result.result;
      const executedConsole = response.data.result.consoleOutput;
      const typeOfObject = typeof executedResult === 'object';

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        flowId,
        objectData: {
          result: typeOfObject
            ? JSON.stringify(executedResult)
            : executedResult,
          console: executedConsole || ' ',
        },
        setSchema,
      });
      setExecuteCounter(0);

      setTimeout(() => {
        setIsLoading(false);
        setHasToExecute(true);
      }, 1000);
    } catch (error) {
      if (executeCounter < 2) {
        setExecuteCounter(executeCounter + 1);
        execute();
      } else {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,
          flowTriggerData: checkExecuteTrigger(data, id),
          objectData: {},
          changeType: 'execute',
          setSchema,
        });
        setIsLoading(false);
        setHasToExecute(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    varId: string,
  ) => {
    const updatedVariables = variables.map(variable => {
      if (variable.id === varId) {
        return { ...variable, [e.target.name]: e.target.value };
      }
      return variable;
    });

    setVariables(updatedVariables);

    setWhoIsChanging({
      name: 'variables',
      value: updatedVariables,
    });
    setIsChangingDirectly(true);
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value);

    setWhoIsChanging({
      name: 'language',
      value,
    });
    setIsChangingDirectly(true);
  };

  const handleScriptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScript(e.target.value);
    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
    });
    setIsChangingDirectly(true);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <>
      <Box className={isLoading || btnLoading ? 'node' : ''}>
        <Node
          edges={edges}
          type={type}
          handleRun={handleRun}
          btnText="Run Script"
          onSubmit={execute}
          isLoading={isLoading}
          data={data}
          id={id}
          showTokensUsed={false}
        >
          <Box display={'flex'}>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
            >
              <ScriptEditor
                variables={variables}
                data={data}
                addVariable={addVariable}
                handleValueChange={handleValueChange}
                handleLanguageChange={handleLanguageChange}
                script={script}
                language={language}
                findColor={findColor}
                edges={edges}
                handleScriptChange={handleScriptChange}
              />
            </Box>
            <Box
              p={'16px 24px 16px 16px'}
              display="flex"
              flexDirection={'column'}
            >
              <OutputTextarea
                previewIndex={data.previewIndex}
                value={data.result}
                activeHandle={
                  edges?.find(
                    (edge: any) =>
                      edge?.source === id && edge?.sourceHandle === 'output',
                  )?.source
                    ? true
                    : false
                }
                placement={data?.placement}
                labelName={'Output'}
                findColor={findColor}
                nodeLabel={data?.label}
              />
              <OutputTextarea
                value={data.console}
                handleId="console"
                activeHandle={
                  edges?.find(
                    (edge: any) =>
                      edge?.source === id && edge?.sourceHandle === 'console',
                  )?.source
                    ? true
                    : false
                }
                placement={data?.placement}
                labelName={'Console Log'}
                findColor={findColor}
                nodeLabel={data?.label}
              />
            </Box>
          </Box>
        </Node>
      </Box>
    </>
  );
};

export default memo(Scripting);
