import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { ArrowLeft, Buildings, Planet } from '@phosphor-icons/react';
import { Plus } from '@phosphor-icons/react/dist/ssr';
import { useEffect, useState } from 'react';
import { CompanyService } from '../../service/CompanyService';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { useNotificationStore } from '../../store/storeNotifications';
import { User } from '../../util/Types/User';
import PermissionsOverview from './PermissionsOverview';
import TechnologyPermissions from './TechnologyPermissions';
import TechnologiesModal from './TechnologiesModal';
import { useAuthStore } from '../../store/storeAuth';

type Props = {
  authUser: User;
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
};

export interface AiModel {
  id: string;
  model: string;
  enabled: boolean;
  disabledByCompany?: boolean;
}

export interface GridRowModel {
  disabledByCompany: boolean | undefined;
  system: string;
  models: AiModel[];
  id: number;
  systemDescription: string;
}

const AiTechnologies = ({ authUser, setSelectedMenuItem }: Props) => {
  const [viewState, setViewState] = useState<
    | 'OrganizationsAndWorkspaces'
    | 'OrganizationTechnologies'
    | 'WorkspaceTechnologies'
  >('OrganizationsAndWorkspaces');

  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<any[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [disabledCompanyTechnologies, setDisabledCompanyTechnologies] =
    useState<any>({});
  const [enabledCompanyTechnologies, setEnabledCompanyTechnologies] =
    useState<any>({});
  const [disabledSpaceTechnologies, setDisabledSpaceTechnologies] =
    useState<any>({});
  const [enabledSpaceTechnologies, setEnabledSpaceTechnologies] = useState<any>(
    {},
  );
  const [selectedCompanySpace, setSelectedCompanySpace] = useState<any>();
  const [companySpaces, setCompanySpaces] = useState<any[]>([]);
  // const [technologies, setTechnologies] = useState<GridRowModel[]>([]);
  const [reFetch, setReFetch] = useState(false);
  const [showTechnologiesModal, setShowTechnologiesModal] = useState(false);
  const [displayedTechnologies, setDisplayedTechnologies] = useState<any>({});

  const setNotification = useNotificationStore(state => state.setNotification);
  const {
    technologies,
    setTechnologies,
    companySpaceTechnologies,
    setCompanySpaceTechnologies,
  } = useAuthStore();

  const fetchAllTechnologies = async () => {
    try {
      setIsLoading(true);
      const res = await CompanySpacesService.getSpaceTechnologies(
        selectedCompanySpace.id as string,
      );

      setCompanySpaceTechnologies(res.data);
      setViewState('WorkspaceTechnologies');
    } catch (error) {
      console.error('Error fetching company technologies:', error);
    } finally {
      setIsLoading(false);
    }

    return;
  };

  useEffect(() => {
    if (selectedCompanySpace) {
      fetchAllTechnologies();
    }
  }, [selectedCompanySpace]);

  const fetchCompanyTechnologies = async () => {
    try {
      setIsLoading(true);
      const enabledPromises = companies.map(company =>
        CompanyService.getEnabledTechnologies(company.id as string).then(
          res => ({
            companyId: company.id,
            data: res.data,
          }),
        ),
      );
      const disabledPromises = companies.map(company =>
        CompanyService.getDisabledTechnologies(company.id as string).then(
          res => ({
            companyId: company.id,
            data: res.data,
          }),
        ),
      );

      const enabledResults = await Promise.all(enabledPromises);
      const disabledResults = await Promise.all(disabledPromises);

      const enabledData: any = {};
      const disabledData: any = {};

      enabledResults.forEach(result => {
        enabledData[result.companyId] = result.data;
      });

      disabledResults.forEach(result => {
        disabledData[result.companyId] = result.data;
      });

      setEnabledCompanyTechnologies(enabledData);
      setDisabledCompanyTechnologies(disabledData);
    } catch (error) {
      console.error('Error fetching company configs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companies.length > 0) {
      fetchCompanyTechnologies();
    }
  }, [companies]);

  const fetchTechnologies = async () => {
    try {
      setIsLoading(true);
      const enabledPromises = companySpaces.map(space =>
        CompanySpacesService.getEnabledTechnologies(space.id as string).then(
          res => ({
            spaceId: space.id,
            data: res.data,
          }),
        ),
      );
      const disabledPromises = companySpaces.map(space =>
        CompanySpacesService.getDisabledTechnologies(space.id as string).then(
          res => ({
            spaceId: space.id,
            data: res.data,
          }),
        ),
      );

      const enabledResults = await Promise.all(enabledPromises);
      const disabledResults = await Promise.all(disabledPromises);

      const enabledData: any = {};
      const disabledData: any = {};

      enabledResults.forEach(result => {
        enabledData[result.spaceId] = result.data;
      });

      disabledResults.forEach(result => {
        disabledData[result.spaceId] = result.data;
      });

      setEnabledSpaceTechnologies(enabledData);
      setDisabledSpaceTechnologies(disabledData);
    } catch (error) {
      console.error('Error fetching space configs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companySpaces.length > 0) {
      fetchTechnologies();
    }
  }, [companySpaces]);

  useEffect(() => {
    const getCompany = async () => {
      setIsLoading(true);
      try {
        const res = await CompanyService.getOneCompany(
          authUser?.selectedCompanyId as string,
        );
        const resCompany = res?.data;
        setCompanies([resCompany]);

        // if (res.data && res.data.length > 0 && res.data[0].id) {
        //   const firstCompanyId = res.data[0].id;
        //   setCompanyId(firstCompanyId);
        // }
      } catch (error) {
        console.log(error);
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
      }
    };
    getCompany();

    const getCompanySpaces = async () => {
      setIsLoading(true);
      try {
        const res = await CompanySpacesService.getCompanySpaces(
          authUser.selectedCompanyId as string,
        );
        setCompanySpaces(res.data);
      } catch (error) {
        console.log(error);
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
      }
    };

    getCompanySpaces();
  }, [reFetch]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: authUser?.isUserCompany ? '67px' : '64px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          borderBottom: '1px solid #D0D5DD',
          flexShrink: 0,
          mb: '10px',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
            pb: '10px',
            gap: '8px',
          }}
        >
          {(viewState === 'OrganizationTechnologies' ||
            viewState === 'WorkspaceTechnologies') && (
            <IconButton
              onClick={() => {
                setViewState('OrganizationsAndWorkspaces');
                setSelectedCompany(undefined);
                setSelectedCompanySpace(undefined);
              }}
            >
              <ArrowLeft size={20} color="#343330" />
            </IconButton>
          )}
          Ai Technologies
        </Typography>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box sx={{ paddingX: '24px', paddingY: '15px' }}>
          {viewState === 'OrganizationsAndWorkspaces' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '54px',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#3650DE',
                      borderRadius: '2px',
                      padding: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Buildings size={20} color="#F9FAFB" />
                  </Box>
                  <Typography fontSize={'20px'} color={'#000000'}>
                    Organization Ai Permissions
                  </Typography>
                </Box>
                <Typography>
                  Customize the technologies and permissions for your company.
                </Typography>
                {companies.length > 0 && (
                  <PermissionsOverview
                    setViewState={setViewState}
                    setShowTechnologiesModal={setShowTechnologiesModal}
                    setDisplayedTechnologies={setDisplayedTechnologies}
                    enabledTechnologies={enabledCompanyTechnologies}
                    disabledTechnologies={disabledCompanyTechnologies}
                    setSelectedEntity={setSelectedCompany}
                    entities={companies}
                    entityType="company"
                  />
                )}
                {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  justifyContent: 'center',
                  padding: '24px',
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                }}
              >
                <Buildings color="#667085" size={32} />
                <Box>
                  <Typography fontSize={'14px'} color={'#667085'}>
                    All Ai technologies are enabled for your Organization.
                  </Typography>
                  <Typography fontSize={'14px'} color={'#667085'}>
                    Click here to customize your technology settings.
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => setViewState('OrganizationTechnologies')}
                >
                  Customize
                </Button>
              </Box> */}
              </Box>

              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#3650DE',
                      borderRadius: '2px',
                      padding: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Planet size={20} color="#F9FAFB" />
                  </Box>
                  <Typography fontSize={'20px'}>
                    Workspace Ai Permissions
                  </Typography>
                </Box>
                {companySpaces.length > 0 ? (
                  <>
                    <Typography fontSize={'16px'}>
                      Customize the technologies and permissions for the spaces
                      in your company.
                    </Typography>
                    <PermissionsOverview
                      setViewState={setViewState}
                      setShowTechnologiesModal={setShowTechnologiesModal}
                      setDisplayedTechnologies={setDisplayedTechnologies}
                      enabledTechnologies={enabledSpaceTechnologies}
                      disabledTechnologies={disabledSpaceTechnologies}
                      setSelectedEntity={setSelectedCompanySpace}
                      entities={companySpaces}
                      entityType="space"
                    />
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '16px',
                      justifyContent: 'center',
                      padding: '24px',
                      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    }}
                  >
                    <Planet color="#667085" size={32} />
                    <Box>
                      <Typography fontSize={'14px'} color={'#667085'}>
                        You have not added a space to your organisation.
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={{ gap: '8px' }}
                      onClick={() => setSelectedMenuItem?.('spaces')}
                    >
                      <Plus size={16} weight="bold" />
                      <Typography>Create Space</Typography>
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}

          {viewState === 'OrganizationTechnologies' && (
            <TechnologyPermissions
              // updateTechnology={updateTechnology}
              setTechnologies={setTechnologies}
              setIsLoading={setIsLoading}
              setReFetch={setReFetch}
              reFetch={reFetch}
              authUser={authUser}
              technologies={technologies}
              isLoading={isLoading}
              company={selectedCompany}
              space={selectedCompanySpace}
              setViewState={setViewState}
            />
          )}
          {viewState === 'WorkspaceTechnologies' && (
            <TechnologyPermissions
              // updateTechnology={updateTechnology}
              setTechnologies={setCompanySpaceTechnologies}
              setIsLoading={setIsLoading}
              reFetch={reFetch}
              setReFetch={setReFetch}
              authUser={authUser}
              technologies={companySpaceTechnologies}
              isLoading={isLoading}
              company={selectedCompany}
              space={selectedCompanySpace}
              setViewState={setViewState}
            />
          )}
          {displayedTechnologies.length > 0 && (
            <TechnologiesModal
              show={showTechnologiesModal}
              onHide={() => setShowTechnologiesModal(false)}
              technologies={Object.entries(displayedTechnologies).map(
                ([system, models]) => models,
              )}
            />
          )}

          {/* {viewState === 'WorkspaceTechnologies' && (
          <TechnologyPermissions
            technologies={Object.entries(companyTechnologies).map(([system, models]) => ({ system, models }))}
            authUser={authUser}
            isLoading={isLoading}
            entity={company}
          />
        )} */}
        </Box>
      )}
    </>
  );
};

export default AiTechnologies;
