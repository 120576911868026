import KitModal from '../../../components/UI/KitModal';
import { Box, Button, Typography } from '@mui/material';
import { useNotificationStore } from '../../../store/storeNotifications';

type Props = {
  show: boolean;
  onHide: () => void;
  form: any;
};

const ShareFormModal = ({ show, onHide, form }: Props) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const onCopy = () => {
    navigator.clipboard.writeText(
      window.location.origin + '/published/form/' + form?.id,
    );

    setNotification({
      message: 'Link Copied',
      type: 'success',
      duration: 3000,
    });
  };

  return (
    <KitModal
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '690px',
          background: '#FFF',
        },

        '& .MuiDialogContent-root': {
          background: '#fff',

          p: '24px',
        },
      }}
      fullWidth
      show={show}
      onHide={onHide}
    >
      <>
        <Box mt={'12px'} sx={{}}>
          <Typography variant="h6">Share Form</Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: '#fff',
              mt: '24px',
              p: '8px 16px',
              borderRadius: '8px',
              border: '1px solid #EAECF0',
            }}
          >
            <Button
              sx={{
                display: 'flex',
                gap: '8px',
              }}
              onClick={onCopy}
              variant="text"
            >
              Copy published form link
            </Button>

            <Typography
              sx={{
                fontSize: '12px',
                color: '#98A2B3',
                maxWidth: '60%',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              title={window.location.origin + '/published/form/' + form?.id}
            >
              {window.location.origin + '/published/form/' + form?.id}
            </Typography>
          </Box>
        </Box>
      </>
    </KitModal>
  );
};

export default ShareFormModal;
