import { Box, Typography } from '@mui/material';
import React from 'react';
import { BoundingBox, Export } from '@phosphor-icons/react';

const NoFlows = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          height: '64px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          borderBottom: '1px solid #D0D5DD',
          flexShrink: 0,
          mb: '10px',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
            pb: '10px',
          }}
        >
          Flow Management
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '4px',
          marginTop: '20px',
          gap: '15px',
          mx: '24px',
          my: '15px',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '28.8px',
            letterspacing: '0.15px',
          }}
        >
          Flow Table View
        </Typography>
      </Box>

      <Box
        sx={{
          border: '1px dashed rgba(208, 213, 221, 1)',
          padding: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          marginTop: '30px',
          flexDirection: 'column',
          gap: '15px',
          mx: '24px',
          my: '15px',
        }}
      >
        <BoundingBox size={32} color="#3650de" />

        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '32px',
            letterSpacing: '0.15px',
            color: 'rgba(71, 84, 103, 1)',
          }}
        >
          No flows have been created in the Organization yet
        </Typography>
      </Box>
    </Box>
  );
};

export default NoFlows;
