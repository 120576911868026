export const checkExecuteTrigger = (data: any, id: string) => {
  const flowTriggerData = data?.flowTrigger;
  const arrayExecute = flowTriggerData?.arrayExecute;

  const checkIfArrayExecuteIsEqualWithEndingPoint =
    arrayExecute?.completed >= arrayExecute?.endingPoint;

  const objectData = {
    ...flowTriggerData,
    hasArrayExecute: true,
    conditionTrue: flowTriggerData?.conditionTrue,
    completed: flowTriggerData?.completed + 1,
    arrayExecute: {
      ...arrayExecute,
      goToFirstId: arrayExecute?.loopingToId === id,
      completed:
        arrayExecute?.loopingToId === id
          ? arrayExecute?.completed + 1
          : arrayExecute?.completed,
    },
  };

  const checkIfLengthIsEqualWithCompleted =
    flowTriggerData?.length === flowTriggerData?.completed + 1 &&
    checkIfArrayExecuteIsEqualWithEndingPoint;

  if (checkIfArrayExecuteIsEqualWithEndingPoint || !arrayExecute?.id) {
    return {
      completed: checkIfLengthIsEqualWithCompleted
        ? flowTriggerData?.completed + 1
        : flowTriggerData?.completed,
      hasArrayExecute: false,
      arrayExecute: null,
      id: flowTriggerData?.id,
      conditionTrue: flowTriggerData?.conditionTrue,
      length: flowTriggerData?.length,
    };
  } else {
    return {
      ...objectData,
    };
  }
};
