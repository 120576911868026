import { Avatar, Box, Button, Skeleton, Typography } from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';
import {
  Cardholder,
  CurrencyCircleDollar,
  FadersHorizontal,
  FolderUser,
  Link,
  Receipt,
  UserCircleGear,
  Users,
  Buildings,
  ToggleRight,
  BoundingBox,
} from '@phosphor-icons/react';
import { CompanyService } from '../../service/CompanyService';
import {
  Fragment,
  ReactElement,
  cloneElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationType, useNotificationStore } from '../../store/storeNotifications';

interface ICompanyUsers {
  data: {
    id: string;
    role: string;
    status: string;
    email: string;
  };
}

interface ICompanies {
  data: [
    {
      id: string;
      logo: string;
      name: string;
      companyUsers: ICompanyUsers[];
    },
  ];
}

type IModalSidebar = {
  current: string;
  setCurrentNav: (currentNav: string) => void;
  setPricingDefaultState?: (state: boolean) => void;
  pricingDefaultState?: boolean;
};

type NavItem = {
  title: string;
  key: string;
  icon: React.ReactNode;
};

type IMenuItem = {
  icon: React.ReactNode;
  title: string;
  isCurrentNav: boolean;
  key: string;
  setAsCurrentNav: (key: string) => void;
  openModal?: boolean;
};

const MenuItem = ({
  icon,
  title,
  isCurrentNav,
  setAsCurrentNav,
  key,
  openModal,
}: IMenuItem) => {
  const [openPaywallSimpleUser, setOpenPaywallSimpleUser] = useState(false);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { user: authUser } = useAuthStore(state => state);
  const setNotification = useNotificationStore(state => state.setNotification);

  const handleClosePaywallSimpleUser = () => {
    setOpenPaywallSimpleUser(false);
  };

  const handleMenuItemClick = () => {
    if (openModal === true && !authUser?.isUserCompany) {
      setNotification({
        type: "warning",
        message: 'You have to create a company first.',
      });
      navigate(window.location.pathname + '?createCompany=true');   
    } else {
      setAsCurrentNav(key);
    }
  };

  const clonedIcon = cloneElement(icon as ReactElement, {
    weight: isCurrentNav ? 'fill' : 'bold',
    sx: {
      color: isCurrentNav ? '#3650DE' : '#667085',
      transition: 'color 0.3s ease',
    },
  });

  return (
    <Fragment key={key}>
      <Box
        sx={{
          display: 'flex',
          columnGap: '.4063rem',
          alignItems: 'center',
          transition: 'background-color 0.3s ease, color 0.3s ease',
          '&:hover': {
            backgroundColor: '#EAECF0',
            borderRadius: '4px',
          },
          '.menu-item-icon > svg': {
            fill: 'red', // Change icon color on hover
          },
          // ...(isCurrentNav && {
          //   backgroundColor: 'green',
          // }),
        }}
        className={`${
          isCurrentNav && 'menu-item-settings--active'
        } menu-item-settings`}
        onClick={handleMenuItemClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {cloneElement(clonedIcon, {
          className: 'icon',
        })}
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '16px',
            fontWeight: isCurrentNav ? 500 : 400,
            lineHeight: '24px',
            color: '#475467',
          }}
        >
          {title}
        </Typography>
      </Box>
      {/* Paywall for simple user with no company , Removed*/}
      {/* <PaywallModal
        onHide={handleClosePaywallSimpleUser}
        fullWidth={true}
        show={openPaywallSimpleUser}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
              }}
              // onClick={() => {
              //   handleClosePaywallSimpleUser();
              //   setIsModalOPen(true);
              // }}
            >
              Action 1
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#3650DE',
                color: '#FFFFFF',
                outline: '1px solid #3650DE',
              }}
              //  onClick={() => {
              //    handleCloseFlows();
              //    setIsModalOPen(true);
              //    navigate(`?pricing=true&plan=${nextPlan}`);
              //  }}
            >
              Action 2
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <RobotWarningIcon />
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              Create a company to access this setting
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              Your current account only allows you to create and manage your
              user. Upgrade to add create company and get advanced statistics.
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              To access this settings you need to have a company.
            </Typography>
          </Box>
        </Box>
      </PaywallModal> */}
    </Fragment>
  );
};

const bottomNavItems: NavItem[] = [
  {
    title: 'Org Users',
    key: 'companyUsers',
    icon: <Users size={16} weight="bold" color="#667085" />,
  },
  {
    title: 'Flows',
    key: 'flows',
    icon: <BoundingBox size={18} color="rgba(102, 112, 133, 1)" />,
  },
  {
    title: 'Ai Technologies',
    key: 'aiTechnologies',
    icon: <ToggleRight size={16} weight="bold" color="#667085" />,
  },
  {
    title: 'Token Usage',
    key: 'tokenUsage',
    icon: <CurrencyCircleDollar size={16} weight="bold" color="#667085" />,
  },
  {
    title: 'Spaces',
    key: 'spaces',
    icon: <FolderUser color="rgba(102, 112, 133, 1)" />,
  },
  {
    title: 'Pricing',
    key: 'pricing',
    icon: <Receipt size={16} weight="bold" color="#667085" />,
  },
  {
    title: 'Billing',
    key: 'billing',
    icon: <Cardholder size={16} weight="bold" color="#667085" />,
  },
  {
    title: 'Integrations',
    key: 'integration',
    icon: <Link size={16} weight="bold" color="#667085" />,
  },
];

export default function ModalSidebar({
  current,
  setCurrentNav,
  setPricingDefaultState,
}: IModalSidebar) {
  const authUser = useAuthStore(state => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companyId, setCompanyId] = useState<string>(
    authUser?.selectedCompanyId || '',
  );
  const [userRole, setUserRole] = useState<string>('');

  const topNavItems: NavItem[] = [
    {
      title: 'Profile Settings',
      key: 'profileSettings',
      icon: <UserCircleGear size={16} weight="bold" color="#667085" />,
    },
    // {
    //   title: 'Org Settings',
    //   key: 'organizationSettings',
    //   icon: <Buildings size={16} weight="bold" color="#667085" />,
    // },
    // ...(authUser?.isUserCompany
    //   ? [
          {
            title: 'Org Settings',
            key: 'organizationSettings',
            icon: <Buildings size={16} weight="bold" color="#667085" />,
          },
      //   ]
      // : []),
    {
      title: 'Ai Configurations',
      key: 'aiConfigurations',
      icon: <FadersHorizontal size={16} weight="bold" color="#667085" />,
    },
  ];

  useEffect(() => {
    setCompanyId(authUser?.selectedCompanyId || '');
  }, [authUser?.selectedCompanyId, companyId]);

  useEffect(() => {
    const getUserCompany = async () => {
      try {
        if (companyId) {
          const res = await CompanyService.getUserOfCompany(companyId);
          const userData = Array.isArray(res.data) ? res.data[0] : res.data;

          if (userData && userData.role) {
            setUserRole(userData.role);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getUserCompany();
  }, [companyId]);

  const filteredTopNavItems = useMemo(() => {
    return topNavItems.filter(navItem => {
      switch (userRole) {
        case 'admin':
          return [
            'profileSettings',
            'organizationSettings',
            'aiConfigurations',
          ].includes(navItem.key);
        case 'employee':
          return [ 'profileSettings',
            'organizationSettings',
            'aiConfigurations',].includes(navItem.key);
        default:
          return [
            'profileSettings',
            'organizationSettings',
            'aiConfigurations',
          ].includes(navItem.key);
      }
    });
  }, [userRole]);

  const filteredBottomNavItems = useMemo(() => {
    return bottomNavItems.filter(navItem => {
      switch (userRole) {
        case 'admin':
          return [
            'companyUsers',
            'flows',
            'tokenUsage',
            'integration',
            'pricing',
            'billing',
            'aiTechnologies',
            'spaces',
          ].includes(navItem.key);
        case 'employee':
          return [
            'companyUsers',
            'flows',
            'tokenUsage',
            'integration',
            'pricing',
            'billing',
            // 'aiTechnologies',
            'spaces',
          ].includes(navItem.key);
        default:
          return [
            'companyUsers',
            'flows',
            'tokenUsage',
            'integration',
            'pricing',
            'billing',
            'aiTechnologies',
            'spaces',
          ].includes(navItem.key);
      }
    });
  }, [userRole]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // height: 'calc(100vh - 150px)',
        height: 'calc(100vh - 70px)',

        width: '230px',
        backgroundColor: '#F6F6F6',
        // paddingX: '10px',
        // paddingY: '31px',
        borderRight: '1px solid #D0D5DD',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: '.5rem',
          borderBottom: '1px solid #D0D5DD',
          paddingBottom: '12px',
          // marginTop: "1.5rem",
          paddingLeft: '24px',
          paddingTop: '12px',
          rowGap: '8px',
        }}
      >
        {/* {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Skeleton variant="circular" width={40} height={40} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignSelf: 'center',
                px: '25px',
              }}
            >
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                sx={{ borderRadius: '4px' }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={10}
                sx={{ borderRadius: '4px' }}
              />
            </Box>
          </Box>
        ) : ( */}
          <>
            {authUser?.avatar ? (
              <Avatar
                alt={authUser?.name}
                src={authUser?.avatar}
                sx={{ height: '2.6875rem', width: '2.6875rem' }}
              />
            ) : (
              <Avatar>
                {authUser?.name
                  .split(' ')
                  .map(part => part[0])
                  .join('')}
              </Avatar>
            )}
           <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '.0125rem',
              alignSelf: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '19.36px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '200px',
              }}
              title={authUser?.name}
            >
              {authUser?.name}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14.52px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '150px',
              }}
            >
              {authUser?.email}
              {/* thisisalongemailfordevpurposes@enhancedai.com */}
            </Typography>
          </Box>
          </>
        {/* )} */}
      </Box>

      <Box
        sx={{
          borderBottom: '1px solid #D0D5DD',
          paddingBottom: '12px',
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
        }}
      >
        {/* {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: '12px',
              paddingLeft: '24px',
            }}
          >
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
          </Box>
        ) : ( */}
          {filteredTopNavItems.map(navItem => (
            <MenuItem
              icon={navItem.icon}
              isCurrentNav={current === navItem.key}
              key={navItem.key}
              openModal={
                authUser?.isUserCompany === false &&
                ['organizationSettings'].includes(navItem.key)
              }
              title={navItem.title}
              setAsCurrentNav={() => {
                setCurrentNav(navItem.key);
                setPricingDefaultState?.(true);
              }}
            />
          ))
        }
       {/* )} */}
      </Box>

      <Box
        sx={{
          paddingBottom: '12px',
          marginTop: '12px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
        }}
      >
        {/* {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: '12px',
              paddingLeft: '24px',
            }}
          >
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />

            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={30}
              sx={{ borderRadius: '4px' }}
            />
          </Box>
        ) : ( */}
          {filteredBottomNavItems.map(navItem => (
            <MenuItem
              icon={navItem.icon}
              isCurrentNav={current === navItem.key}
              key={navItem.key}
              openModal={
                authUser?.isUserCompany === false &&
                ['flows','spaces'].includes(navItem.key)
              }
              title={navItem.title}
              setAsCurrentNav={() => {
                setCurrentNav(navItem.key);
                setPricingDefaultState?.(true);
              }}
            />
          ))
        } 
        {/* )} */}
      </Box>
    </Box>
  );
}
