export enum UserPlan {
  Intro = 'Intro',
  Starter = 'Starter',
  Advanced = 'Advanced',
  Professional = 'Professional',
  Organization = 'Organization',
  Enterprise = 'Enterprise',
}

export interface User {
  id: string;
  email: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  avatar?: string;
  accessToken: string;
  theme: string;
  cover: string;
  customerId: string;
  defaultPaymentMethod: string;
  apiKey: string;
  firstTimeLogin: boolean;
  freeTokens: number;
  status: 'active' | 'inactive' | 'pending';
  numberOfFlows: number;
  tapfiliateReferralCode: string;
  registrationMethod: 'simple' | 'apple' | 'google' | 'facebook' | 'invite';
  objects: {
    id: string;
    text: {
      text: string;
      id: string;
    }[];
    name: string;
  }[];
  backgroundColor: string[] | null;
  namespaces: {
    name: string;
    id: string;
  }[];
  voice: {
    src: string;
    id: string;
    name: string;
    type: string;
  }[];
  chatFlowId?: string;
  chatsId: string[];
  plan: UserPlan | null;
  tokensPurchaseRate: number;
  fluxTokenPrice: number;
  hasFinishedOnboarding: boolean;
  isUserCompany: boolean;
  spentTokens: number;
  totalTokens: number;
  trialTokens?: number;
  dashboardBackground: null | string;
  selectedCompanyId?: string | null;
  selectedCompanyName?: string | null;
  lastCompanyLogin: {
    id: string;
    name: string;
    domain: string;
  } | null;

  companyUserHasFinishedOnboarding: boolean;
  transferData: boolean;
  showTokens: boolean;
  companyRole: string;
  spaceObjects?: {
    id: string;
    text: {
      text: string;
      id: string;
    }[];
    name: string;
  }[];
}
