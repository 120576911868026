export interface ITemplate {
  title: string;
  description: string;
  keyInputs: string;
  keyOutputs: string;
  templateCategory: string[];
  imageUrl: string;
  recommended: boolean;
  premiumTag: boolean;
  flowId: undefined | string;
}

export const templateCategories = [
  'Content Creation',
  'Customer Service',
  'Data Analytics',
  'Design',
  'Finance',
  'Human Resources',
  'Marketing',
  'Product Management',
  'Project Management',
  'Research & Development',
  'Sales',
];

export const templates: ITemplate[] = [
  {
    title: 'Trending Topics Tracker',
    description:
      'This template monitors various sources to identify and compile trending topics, providing users with up-to-date content ideas for their marketing or editorial strategies.',
    keyInputs: 'Data sources, Keywords/Topics of interest, Update frequency',
    keyOutputs:
      'List of current trending topics, Sources of trends, Content ideas for marketing or editorial use',
    templateCategory: [
      'Content Creation',
      'Marketing',
      'Research & Development',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TrendingTopicsTracker.png',
    recommended: false,
    premiumTag: false,
    flowId: '0d7f9ad3-9344-43aa-918d-1a54cbb14264',
  },
  {
    title: 'Blog Post Creation',
    description:
      'It streamlines the process of writing blog posts by providing a structured approach, including prompts and AI assistance, to generate quality content efficiently.',
    keyInputs: 'Topic or title, Target audience, Tone of voice, Length of post',
    keyOutputs:
      'Completed blog post draft, Structured content outline, AI-generated sections or paragraphs',
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BlogPostCreation.png',
    recommended: true,
    premiumTag: false,
    flowId: '766cb0bf-c223-46eb-9595-3c8fe72aafb8',
  },
  {
    title: `LinkedIn Post Writer`,
    description: `Creates professional LinkedIn updates that cater to industry-specific audiences while promoting networking opportunities and thought leadership within one's professional community.`,
    keyInputs: `Professional topic/theme/message; Relevant industry keywords/tags; Target professional audience characteristics`,
    keyOutputs: `Professional LinkedIn updates geared towards industry networking and thought leadership.`,
    templateCategory: ['Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/LinkedInPostWriter.png',
    premiumTag: false,
    recommended: true,
    flowId: '83edb8f1-9099-4f7a-98f8-059d46d7ccd9',
  },
  {
    title: `Blog Images Generator`,
    description: `This template helps users create visually appealing images for their blog posts using AI-powered design tools that align with the post's theme and content.`,
    keyInputs: `Blog post theme, Desired image style, Image dimensions, Branding elements (logo/colors)`,
    keyOutputs: `Custom-designed images for blog posts, Image files aligned with the post's theme`,
    templateCategory: ['Content Creation', 'Design', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BlogImagesGenerator.png',
    recommended: true,
    premiumTag: false,
    flowId: 'a43af2d4-53af-4125-a26d-21379767c244',
  },
  {
    title: `AI-Powered SEO Audit`,
    description: `Users can analyze their website's SEO performance with this template, receiving insights and recommendations for optimization to improve search engine rankings.`,
    keyInputs: `Website URL, Target keywords, Competitor URLs (optional), Report frequency`,
    keyOutputs: `SEO performance report, Insights and recommendations for website optimization, Actionable steps to improve rankings`,
    templateCategory: ['Marketing', 'Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/AI-PoweredSEOAudit.png',
    recommended: false,
    premiumTag: true,
    flowId: '8cd58079-9cdf-4c10-b818-9050e95255b9',
  },
  {
    title: `Blog Post Proofreader`,
    description: `It offers an automated proofreading service that checks grammar, spelling, and style to ensure blog posts are polished and professional before publication.`,
    keyInputs: `Blog post content, Language preference, Style guide (if applicable)`,
    keyOutputs: `Grammar-checked blog post, Spelling corrections, Style and tone improvements`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BlogPostProofreader.png',
    recommended: false,
    premiumTag: false,
    flowId: '24cbfcbf-c483-40a1-b097-ffa75a1891e3',
  },
  {
    title: `Content Scheduler`,
    description: `This workflow assists in planning and scheduling content releases across various platforms, ensuring consistent engagement with the audience.`,
    keyInputs: `Content pieces to schedule, Publishing platforms, Desired posting times/dates`,
    keyOutputs: `Scheduled content calendar, Platform-specific posting schedule, Automated publishing setup`,
    templateCategory: ['Content Creation', 'Marketing', 'Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BlogPostProofreader.png',
    recommended: true,
    premiumTag: true,
    flowId: 'ee180356-2c1f-4cbd-a535-62360bc91869',
  },

  {
    title: 'Social Media Content Planner',
    description:
      'Organizes social media campaigns by scheduling posts across different platforms while suggesting optimal posting times based on audience activity patterns.',
    keyInputs:
      'Campaign goals/themes/topics; Platforms for scheduling posts; Content assets (images/videos/text); Analytics tracking requirements',
    keyOutputs: 'Social media campaign schedule across platforms',
    templateCategory: ['Marketing', 'Project Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SocialMediaContentPlanner.png',
    recommended: false,
    premiumTag: false,
    flowId: 'e017e372-3d0c-4769-8133-1c4db3cb7ea3',
  },
  {
    title: 'Twitter Post Writer',
    description: `Generates concise yet engaging tweets tailored to capture attention within Twitter's character limit constraints while maintaining brand voice consistency.`,
    keyInputs: `Tweet message/content focus; Hashtags to include; Mentioned accounts (@handles); Call-to-action`,
    keyOutputs: `Ready-to-publish tweets tailored to brand voice and Twitter format`,
    templateCategory: ['Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TwitterPostWriter.png',
    recommended: true,
    premiumTag: false,
    flowId: `9864ead6-f358-4db4-beb7-b12ad8a34e82`,
  },
  {
    title: `Facebook Post Writer`,
    description: `Designs impactful Facebook posts aimed at increasing engagement through personalized messages suited to the platform's diverse audience demographics.`,
    keyInputs: `Post message/content focus; Target audience demographics/interests; Visual assets (images/videos); Engagement features (polls/questions)`,
    keyOutputs: `Engaging Facebook post drafts personalized to audience demographics`,
    templateCategory: ['Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/FacebookPostWriter.png',
    recommended: true,
    premiumTag: false,
    flowId: '0072c536-7c0a-4dd5-921b-eda323c7d0b0',
  },
  {
    title: 'Email List Segmentation',
    description: `It organizes your email contacts into targeted groups based on specific criteria to increase the relevance and effectiveness of marketing campaigns.`,
    keyInputs: `Email Subscriber List, Segmentation Criteria (e.g., Purchase History, Location), Marketing Goals`,
    keyOutputs: `Segmented email lists, criteria for segmentation, targeted campaign strategies`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/EmailListSegmentation.png',
    recommended: false,
    premiumTag: true,
    flowId: '9e940429-001a-4d12-b350-cfcfcebc68fe',
  },
  {
    title: 'Email Personalization Engine',
    description: `This template customizes email content for each recipient, boosting open rates and conversions through tailored messaging.`,
    keyInputs: `Subscriber Data Points (e.g., Name, Past Purchases), Email Template, Personalization Parameters`,
    keyOutputs: `Customized email messages, personalized content logs, increased open rates statistics`,
    templateCategory: [
      'Marketing',
      'Sales',
      'Customer Service',
      'Human Resources',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/EmailPersonalizationEngine.png',
    recommended: false,
    premiumTag: true,
    flowId: 'b0fcee59-8ce4-4897-9896-aac9623eada6',
  },

  {
    title: `Web Analytics`,
    description: `Gathers and interprets web traffic data, providing insights into user behavior and website performance metrics for optimization purposes.`,
    keyInputs: `Web Analytics Platform Access Credentials , Specific Metrics to Track , Reporting Period`,
    keyOutputs: `Web traffic data report and interpretation with user behavior insights`,
    templateCategory: [
      'Website Content Optimization',
      'Marketing',
      'Design',
      'Data Analytics',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/WebAnalytics.png',
    recommended: false,
    premiumTag: false,
    flowId: 'f6562d8d-6ba5-4ac6-ad01-86ea0183d20b',
  },
  {
    title: `Resume Builder`,
    description: `Guides individuals through compiling professional resumes that highlight their skills, experience, and achievements tailored to specific job applications or industries.`,
    keyInputs: `Personal information work experience education skills certifications desired job industry/role`,
    keyOutputs: `Tailored professional resume for job application`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ResumeBuilder.png',
    recommended: true,
    premiumTag: false,
    flowId: '90aa9a1d-022d-4b2e-bc3a-b5e515ac1437',
  },

  {
    title: `Sales Battle Cards`,
    description: `This template helps sales teams develop concise, strategic cards that outline key product or service benefits, competitor comparisons, and rebuttals to common objections, empowering them with quick-reference material for sales pitches.`,
    keyInputs: `Product/service features, Competitor information, Common objections, Rebuttal points`,
    keyOutputs: `Concise sales battle cards, Competitor comparisons, Rebuttals to common objections`,
    templateCategory: ['Sales', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SalesBattleCards.png',
    recommended: false,
    premiumTag: false,
    flowId: 'c1d7a4b2-4cf0-473b-8756-575180d65d82',
  },
  {
    title: `Search a Handbook`,
    description: `Allows users to swiftly find specific information within a handbook or manual, enhancing accessibility and user experience.`,
    keyInputs: `Handbook or manual digital file(s), search query terms or phrases`,
    keyOutputs: `Search results from handbook, Quick access to specific information`,
    templateCategory: ['Tech', 'Design', 'Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SearchaHandbook.png',
    recommended: false,
    premiumTag: true,
    flowId: '1c8f9158-5c05-4502-9c13-e4b08df5f010',
  },
  {
    title: `Risk Assessment Report`,
    description: `Evaluates potential risks within a business or project, offering a detailed report to guide decision-making and risk mitigation strategies.`,
    keyInputs: `Project Details or Business Operation Info, Risk Factors to Consider, Assessment Scope`,
    keyOutputs: `Detailed risk report, risk mitigation strategies, decision-making guidance`,
    templateCategory: ['Finance', 'Project Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/RFPResponder.png',
    recommended: true,
    premiumTag: false,
    flowId: '5038a528-94b7-4782-802a-75188dd40d3b',
  },
  {
    title: `Press Release Writer`,
    description: `Streamlines press release creation by guiding users through crafting compelling narratives that highlight newsworthy events or announcements about their business or organization.`,
    keyInputs: `Announcement details; Company information; Quotes from company representatives; Media contact information`,
    keyOutputs: `Press release draft highlighting key announcements or events`,
    templateCategory: ['Content Creation', 'Marketing', 'Public Relations'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/PressReleaseWriter.png',
    recommended: true,
    premiumTag: true,
    flowId: '575dc6c7-177f-4754-8ef6-f6a64281de33',
  },
  {
    title: `Website Content Optimizer`,
    description: `This template enhances website content for better engagement and SEO performance by analyzing key metrics and suggesting improvements.`,
    keyInputs: `Website URL, Target Keywords, Desired Tone of Voice`,
    keyOutputs: `Enhanced content suggestions, SEO improvement recommendations, engagement metrics analysis`,
    templateCategory: ['Content Creation', 'Marketing', 'SEO'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/WebsiteContentOptimizer.png',
    recommended: false,
    premiumTag: true,
    flowId: 'e85ab447-c75f-4966-935f-8a7b90ff6d59',
  },
  {
    title: `Analyze sentiment of customer reviews`,
    description: `Processes customer feedback to determine overall sentiment, providing insights into client satisfaction and areas for improvement.`,
    keyInputs: `Source of Customer Reviews (e.g., Website, Social Media), Date Range for Analysis`,
    keyOutputs: `Sentiment analysis report, customer satisfaction insights, areas for improvement action items`,
    templateCategory: ['Customer Service', 'Data Analytics', 'Marketing'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/Analyzesentimentofcustomerreviews.png',
    recommended: false,
    premiumTag: false,
    flowId: 'eb55feee-fd0c-452b-8dd7-368d3b7cd62d',
  },
  {
    title: `Learning Pathway Designer`,
    description: `This template helps users create structured educational or training programs tailored to specific roles or skills, enhancing professional development and career progression.`,
    keyInputs: `Role or skill focus, current competency levels, desired learning outcomes, content resources`,
    keyOutputs: `Customized educational program, Training roadmap, Professional development plan`,
    templateCategory: ['Human Resources', 'Learning Pathway Designer'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/LearningPathwayDesigner.png',
    recommended: false,
    premiumTag: true,
    flowId: '8f268096-daea-4a10-bbc7-81e1877fca20',
  },
  {
    title: `Customer Journey Mapper`,
    description: `Visualizes the path taken by customers from initial contact to purchase, highlighting opportunities to enhance the buying experience.`,
    keyInputs: `Customer Interaction Data Points (e.g., First Contact Method), Conversion Milestones, Sales Funnel Stages`,
    keyOutputs: `Customer journey map visualization, touchpoint effectiveness scores, buying experience enhancement opportunities`,
    templateCategory: [
      'Marketing',
      'Sales',
      'Customer Service',
      'Product Management',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/CustomerJourneyMapper.png',
    recommended: false,
    premiumTag: false,
    flowId: '36a5add7-881b-4ec8-9f97-976cdc93e0c5',
  },

  {
    title: `Test Plan Builder`,
    description: `Facilitates the creation of detailed test plans that outline testing strategies, resources needed, and metrics for success in software development or product testing scenarios.`,
    keyInputs: `Testing scope and objectives; testing methods and tools to be used; success metrics; resource allocation plan`,
    keyOutputs: `Comprehensive test plan document , Testing strategy outline , Success metrics and benchmarks`,
    templateCategory: ['Research & Development', 'Quality Assurance'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TestPlanBuilder.png',
    recommended: false,
    premiumTag: false,
    flowId: 'b77cadfd-08b2-4784-972c-2b981fd61589',
  },

  {
    title: `Seasonal Trends`,
    description: `Analyzes data to identify trends that vary with seasons or events, assisting businesses in anticipating changes in demand or consumer behavior.`,
    keyInputs: `Historical Sales or Traffic Data, Time Frame for Trend Analysis, Industry Type`,
    keyOutputs: `Seasonal trend analysis report, demand variation charts by season/event`,
    templateCategory: ['Marketing', 'Sales', 'Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SeasonalTrends.png',
    recommended: false,
    premiumTag: false,
    flowId: '9de3266b-ff78-4847-bc42-3658dfc816f0',
  },
  {
    title: `A/B Test Analyzer`,
    description: `Compares two versions of a web page or app feature to determine which one performs better in terms of user engagement or conversion rates.`,
    keyInputs: `Performance Metrics to Compare (e.g., Click-through Rate), A Variant Data Set, B Variant Data Set`,
    keyOutputs: `Performance comparison results between two variants, engagement and conversion rate statistics`,
    templateCategory: [
      'Research & Development',
      'Product Management',
      'Sales',
      'Data Analytics',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BTestAnalyzer.png',
    recommended: true,
    premiumTag: true,
    flowId: '6699c0e2-6f94-4fba-9e22-b2666a13571a',
  },

  {
    title: `Test Outcome Recommendations`,
    description: `Provides actionable recommendations based on the results of A/B tests or other experiments to optimize performance further.`,
    keyInputs: `Test Results Data Set, Testing Parameters (e.g., Sample Size), Success Criteria`,
    keyOutputs: `Actionable optimization recommendations based on test results`,
    templateCategory: [
      'Product Management',
      'Research & Development',
      'Data Analytics',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TestOutcomeRecommendations.png',
    recommended: false,
    premiumTag: false,
    flowId: 'faf1d1cf-3955-4de3-b554-c04ada8194aa',
  },

  {
    title: `Meeting Notes Summarizer`,
    description: `Condenses the key points from meeting transcripts into concise summaries, facilitating better meeting outcomes and follow-ups.`,
    keyInputs: `Meeting transcript text data, summary length preference, key points to include (e.g., decisions made)`,
    keyOutputs: `Summary of meeting highlights, Actionable follow-up points`,
    templateCategory: ['Project Management', 'Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/MeetingNotesSummarizer.png',
    recommended: true,
    premiumTag: false,
    flowId: 'd7919c37-852e-45c6-8a85-90ef6465b124',
  },
  {
    title: `Competitive Analysis`,
    description: `Compiles a comprehensive overview of competitors' strengths and weaknesses relative to your business, informing strategic planning.`,
    keyInputs: `Competitor Identification , Comparison Metrics (Sales Volume , Market Share ) , Industry Benchmarks`,
    keyOutputs: `Competitor's strengths and weaknesses overview relative to your business`,
    templateCategory: ['Research & Development', 'Marketing', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/CompetitiveAnalysis.png',
    recommended: true,
    premiumTag: false,
    flowId: '327d6f87-4e11-4128-8952-a2b153bf475f',
  },

  {
    title: `SWOT Analysis`,
    description: `Facilitates a structured assessment of an organization's Strengths, Weaknesses, Opportunities, and Threats for informed decision-making.`,
    keyInputs: `Company Strengths and Weaknesses Information , Identified Opportunities and Threats in the Market`,
    keyOutputs: `SWOT assessment chart with identified strengths, weaknesses opportunities threats`,
    templateCategory: ['Research & Development', 'Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SWOTAnalysis.png',
    recommended: true,
    premiumTag: false,
    flowId: '4d8f798a-8109-4b3e-b8c2-f23956548742',
  },
  {
    title: `Academic Paper Summary`,
    description: `Summarizes key points from scholarly articles or papers quickly while maintaining the core message and findings intact.`,
    keyInputs: `Full Text of Academic Paper or Link to Publication , Summary Length Requirement`,
    keyOutputs: `Condensed summary of academic papers maintaining core message`,
    templateCategory: ['Research & Development', 'Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SWOTAnalysis.png',
    recommended: true,
    premiumTag: false,
    flowId: '14757a1c-f0f9-4fc8-b2e0-5b1c6128a05f',
  },

  {
    title: `Tech Release Alerts`,
    description: `This template automatically notifies you about new technology releases, ensuring you stay updated on the latest industry advancements and product updates.`,
    keyInputs: `Technology categories of interest, preferred notification channels (e.g., email, SMS), frequency of alerts`,
    keyOutputs: `Notifications of new tech releases, List of product updates`,
    templateCategory: [
      'Product Management',
      'Research & Development',
      'Marketing',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TechReleaseAlerts.png',
    recommended: false,
    premiumTag: false,
    flowId: '119785f4-34a8-483f-83f1-37afcfb763ea',
  },

  {
    title: `Tech Bug Writer`,
    description: `Simplifies reporting technical issues by structuring bug descriptions clearly and concisely for developers to understand and address efficiently.`,
    keyInputs: `Bug description/details of issue encountered steps to reproduce the bug environment in which the bug occurs`,
    keyOutputs: `Structured bug reports for development team use`,
    templateCategory: ['Product Management', 'Research & Development'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TechBugWriter.png',
    recommended: false,
    premiumTag: false,
    flowId: '7e08694c-e639-4034-aa01-1e8ac7da192f',
  },

  {
    title: `Video Scriptwriter`,
    description: `Users can generate compelling scripts for video content using this tool, which structures narrative flow and suggests dialogue for different video formats.`,
    keyInputs: `Video topic or purpose, Intended audience demographic, Video length or time limit`,
    keyOutputs: `Draft video script, Narrative structure guidance, Dialogue suggestions for different scenes`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/VideoScriptwriter.png',
    recommended: false,
    premiumTag: false,
    flowId: '3fbe9b55-d5b9-4c72-b52f-f7552febad24',
  },
  {
    title: `Book Outline`,
    description: `Ideal for authors, this template helps organize thoughts and plot points into a coherent book outline, setting the foundation for a well-structured manuscript.`,
    keyInputs: `Book genre or category, Main plot points/themes/ideas to include`,
    keyOutputs: `Structured book outline with plot points, Chapter organization framework, Thematic development guide`,
    templateCategory: ['Content Creation'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BookOutline.png',
    recommended: false,
    premiumTag: false,
    flowId: 'a3ebb025-9fad-4c59-8c60-7acfe02d412f',
  },
  {
    title: `Book Chapter Writer`,
    description: `It aids authors in writing individual book chapters by offering structural guidance and creative input from AI-driven writing assistants.`,
    keyInputs: `Chapter outline/synopsis/summary, Character details (if fiction), Tone and style preferences`,
    keyOutputs: `Drafts of individual book chapters, Structural guidance on chapter layout, AI-driven creative writing assistance`,
    templateCategory: ['Content Creation'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BookChapterWriter.png',
    recommended: false,
    premiumTag: false,
    flowId: '402e3b90-9239-4f6b-93b8-76439e8dc448',
  },
  {
    title: `Podcast Episode Script Writer`,
    description: `This tool helps create full podcast episode scripts, complete with intros, segues, interviews, and conclusions for a smooth production process.`,
    keyInputs: `Podcast episode outline or key points to cover; Guest bios (if interviews); Ad spots and placements; Intro/outro scripts`,
    keyOutputs: `Complete podcast episode script with intros and outros, Segues and interview questions if applicable, Concluding remarks for episodes`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/PodcastEpisodeScriptWriter.png',
    recommended: true,
    premiumTag: true,
    flowId: 'f157abc0-0fd7-4561-ac37-5c3490baa892',
  },
  {
    title: `Podcast Episode Outline`,
    description: `Podcasters can use this template to craft detailed outlines for episodes, ensuring all key points are covered during recording sessions.`,
    keyInputs: `Episode topic/theme/title, Interview questions (if applicable), Call-to-action items`,
    keyOutputs: `Detailed podcast episode outline with key points, Segments planning for recording sessions, Timeline for episode flow`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/PodcastEpisodeOutline.png',
    recommended: true,
    premiumTag: false,
    flowId: '510a49f8-b180-4622-8b08-10fe020df2dd',
  },

  {
    title: `Blog Post Brainstorm`,
    description: `The template provides creative prompts and AI suggestions to help users come up with new and interesting blog post topics quickly.`,
    keyInputs: `General subject area or niche, Desired blog post length and format, Audience demographics`,
    keyOutputs: `List of potential blog topics, Creative prompts for inspiration, AI-suggested topic ideas`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BlogPostBrainstorm.png',
    recommended: false,
    premiumTag: false,
    flowId: 'd9d97a0d-f65d-4062-9778-d05e5c28ec68',
  },
  {
    title: `Blog Outline Generator`,
    description: `Generates structured outlines for blog posts, helping writers create coherent and well-organized content efficiently.`,
    keyInputs: `Blog Topic, Target Audience, Length of Post`,
    keyOutputs: `Structured blog post outlines, coherence and organization metrics`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BlogOutlineGenerator.png',
    recommended: false,
    premiumTag: false,
    flowId: 'b61c6a10-b583-402c-881d-945c169e851d',
  },

  {
    title: `Audio Transcriber`,
    description: `Automatically transcribes spoken words from audio files into text format, making it easier to repurpose audio content or create written records of conversations.`,
    keyInputs: `Audio file(s) to transcribe; Language spoken in audio; Timestamps needed (yes/no)`,
    keyOutputs: `Text transcription of audio file content, Editable transcript document with timestamps if necessary`,
    templateCategory: [
      'Content Creation',
      'Research & Development',
      'Data Analytics',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/AudioTranscriber.png',
    recommended: true,
    premiumTag: false,
    flowId: 'a0838454-7039-4b1c-88be-d921aeb5748d',
  },
  {
    title: `Personalized Email Campaigns`,
    description: `Designs targeted email marketing campaigns that cater to individual preferences, increasing engagement and conversion rates.`,
    keyInputs: `Customer data points (e.g., name, purchase history), email template design choices, personalization criteria`,
    keyOutputs: `Individualized email messages, Customer engagement metrics`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/PersonalizedEmailCampaigns.png',
    recommended: false,
    premiumTag: false,
    flowId: '938d80e4-cc67-4ad2-b8d4-47beea36ac01',
  },
  {
    title: `FAQs Writer`,
    description: `Generates frequently asked questions along with their answers for your product or service, saving time and improving customer support quality.`,
    keyInputs: `Product/service information, target customer queries, tone of voice guidelines`,
    keyOutputs: `Set of FAQs for a product or service, Answers to common customer questions`,
    templateCategory: [
      'Customer Service',
      'Content Creation',
      'Human Resources',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/FAQsWriter.png',
    recommended: false,
    premiumTag: false,
    flowId: '7396d799-5931-4fb2-8a13-ae1f824c0575',
  },
  {
    title: `Knowledge Base Article Writer`,
    description: `Helps you quickly create detailed articles for your knowledge base, making it easier to maintain a helpful resource for users.`,
    keyInputs: `Article topics, keywords for SEO, content length requirements, formatting preferences`,
    keyOutputs: `Detailed knowledge base articles, Updated resource sections`,
    templateCategory: ['Customer Service', 'Content Creation', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/KeyFindingsExtractor.png',
    recommended: false,
    premiumTag: true,
    flowId: '559ab4df-775e-4043-abd0-fff307807d4c',
  },
  {
    title: `Language Translator`,
    description: `Automates the translation of text between different languages, enabling easy communication in global markets.`,
    keyInputs: `Source text or document, source language, target language(s)`,
    keyOutputs: `Translated text in chosen languages, Multilingual communication support`,
    templateCategory: [
      'Marketing, Sales',
      'Customer Service',
      'Human Resources',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/LanguageTranslator.png',
    recommended: false,
    premiumTag: false,
    flowId: '384741a1-8dbe-4371-a199-5a953c3cb378',
  },
  {
    title: `Lead Scoring Workflow`,
    description: `Evaluates potential leads using AI-driven scoring mechanisms to prioritize sales efforts towards the most promising prospects.`,
    keyInputs: `Lead data points (e.g., demographic information), scoring rules and thresholds`,
    keyOutputs: `Ranked list of potential leads, Lead prioritization data`,
    templateCategory: ['Sales', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/LeadScoringWorkflow.png',
    recommended: false,
    premiumTag: true,
    flowId: '123a87fe-e1be-455f-8d0e-66b2d598a324',
  },
  {
    title: `Competitor Activity Monitor`,
    description: `Keeps track of competitors' actions in real-time, helping you make informed strategic decisions based on their movements in the market.`,
    keyInputs: `Competitors' names and details (websites, social media handles), monitoring parameters (e.g., product launches)`,
    keyOutputs: `Real-time competitor activity reports, Market movement analysis`,
    templateCategory: ['Sales', 'Marketing', 'Research & Development'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/CompetitorActivityMonitor.png',
    recommended: false,
    premiumTag: true,
    flowId: '8b63ed8f-ef5b-41cb-a256-4884b400a964',
  },
  {
    title: `Exit Interview Analyzer`,
    description: `Evaluates departing employees' feedback using AI tools to uncover patterns and insights that could inform retention strategies.`,
    keyInputs: `Exit interview responses text data , analysis parameters such as key reasons for departure`,
    keyOutputs: `Feedback patterns and trends report from exiting employees, Retention strategy insights`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ExitInterviewAnalyzer.png',
    recommended: true,
    premiumTag: false,
    flowId: '0752c1a3-1619-4e0a-9274-a6c2fd01e104',
  },
  {
    title: `Statement of Work Generator`,
    description: `Automates the creation of detailed documents outlining the scope, objectives, and deliverables of a project to ensure clear expectations and agreements.`,
    keyInputs: `Project scope details, specific objectives, deliverables, timelines, stakeholder responsibilities`,
    keyOutputs: `Detailed statement of work document, Project scope outline, Deliverables and milestones list`,
    templateCategory: [
      'Product Management',
      'Sales',
      'Customer Service',
      'Sales',
      'Marketing',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SocialMediaContentPlanner.png',
    recommended: true,
    premiumTag: false,
    flowId: '28119526-b5cd-4f31-a54c-26f6b16ed8ca',
  },
  {
    title: `Project Charter Builder`,
    description: `Provides a framework for defining the vision, objectives, and stakeholders of a new project, facilitating alignment and authorization.`,
    keyInputs: `Project vision statement, high-level objectives, key stakeholders and roles, project constraints and assumptions`,
    keyOutputs: `Project charter document, Defined project vision and objectives, Identified stakeholders and roles`,
    templateCategory: ['Product Management', 'Marketing', 'Design'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ProjectCharterBuilder.png',
    recommended: false,
    premiumTag: true,
    flowId: 'b7b87db7-24a3-45f6-98e7-68a9d97a0da4',
  },
  {
    title: `Project Timeline Planner`,
    description: `Assists in mapping out key milestones and deadlines for a project to ensure timely completion and resource allocation.`,
    keyInputs: `Project milestones, task dependencies, resource availability dates, deadline constraints`,
    keyOutputs: `Project timeline chart, Scheduled milestones and deadlines, Resource allocation plan`,
    templateCategory: ['Product Management', 'Marketing', 'Design'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ProjectTimelinePlanner.png',
    recommended: true,
    premiumTag: false,
    flowId: '98b818f2-f370-4bad-9d9f-e6685e246a16',
  },
  {
    title: `Request for Proposal (RFP) Creator`,
    description: `Streamlines the process of generating formal documents to solicit bids from potential vendors or service providers for a project.`,
    keyInputs: `Project requirements and specifications, evaluation criteria for bids, submission deadlines`,
    keyOutputs: `Formal RFP document, Criteria for vendor selection, Submission instructions for bidders`,
    templateCategory: ['Sales', 'Marketing', 'Product Management'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/RequestforProposal(RFP)Creator.png',
    recommended: true,
    premiumTag: true,
    flowId: 'b2d2611c-a083-462c-b69c-18da5e6f028b',
  },
  {
    title: `User Persona Builder`,
    description: `Enables the design of detailed user personas that represent target audience segments for guiding product development and marketing strategies.`,
    keyInputs: `Demographic information; user goals and motivations; pain points/challenges in usage scenarios`,
    keyOutputs: `Detailed user personas profiles , Behavioral characteristics mapping , User needs and goals identification`,
    templateCategory: ['Marketing', 'Design', 'Product Management', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/UserPersonaBuilder.png',
    recommended: true,
    premiumTag: false,
    flowId: 'b585d61b-5830-42c4-b3ba-849aed1d8011',
  },
  {
    title: `Demand Forecaster`,
    description: `Utilizing historical data and AI algorithms, this template predicts future product demand to inform inventory management and production planning.`,
    keyInputs: `Historical sales data, market trends, seasonal factors`,
    keyOutputs: `Demand forecast report, inventory recommendations`,
    templateCategory: ['Sales', 'Finance', 'Data Analytics', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/DemandForecaster.png',
    recommended: false,
    premiumTag: true,
    flowId: '7a0079da-8d20-4569-9060-9111fd215ded',
  },
  {
    title: `Annual Earnings Analyzer`,
    description: `By examining yearly earnings results, this template helps stakeholders gain insights into overall financial success and long-term trends.`,
    keyInputs: `Annual earnings report file/link`,
    keyOutputs: `Yearly earnings analysis, strategic decision-making data`,
    templateCategory: ['Finance', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/AnnualEarningsAnalyzer.png',
    recommended: false,
    premiumTag: true,
    flowId: '1656106a-c618-4066-9fab-22a2d10abbe3',
  },
  {
    title: `Email Drip Campaign`,
    description: `Design a series of automated emails that nurture leads by providing timely information tailored to their interests at each stage of the customer journey from initial contact to conversion.`,
    keyInputs: `Lead segmentation criteria, Email content for each stage of the funnel journey stages`,
    keyOutputs: `Automated email sequence, Tailored content for lead nurturing`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/EmailDripCampaign.png',
    recommended: true,
    premiumTag: false,
    flowId: 'bad9fb07-4391-4ff9-931f-015f3c8c52e8',
  },
  {
    title: `Event Planner`,
    description: `Organize every aspect of an event from venue selection to attendee management with a comprehensive workflow ensuring no detail is overlooked in creating successful gatherings.`,
    keyInputs: `Event type and theme; Venue preferences; Attendee list and special requirements`,
    keyOutputs: `Detailed event plan, Venue and attendee management checklist`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/EventPlanner.png',
    recommended: false,
    premiumTag: true,
    flowId: 'bd8c7f2c-b48f-42ee-bcc4-aa18ed0fa410',
  },
  {
    title: `Tech Story Writer`,
    description: `Facilitates the creation of user stories in software development that capture functionality requirements from an end-user perspective in a manageable format.`,
    keyInputs: `User role/persona functional requirement acceptance criteria`,
    keyOutputs: `User stories for agile development process integration`,
    templateCategory: ['Research & Development', 'Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/TechStoryWriter.png',
    recommended: true,
    premiumTag: false,
    flowId: 'd8d551f3-76c2-416e-8f66-8ad9e544dfa8',
  },
  {
    title: `Data Cleanser`,
    description: `Helps maintain high-quality datasets by identifying inconsistencies or errors within data sets for improved accuracy in analysis or machine learning applications.`,
    keyInputs: `Dataset to be cleansed rules for data validation/correction`,
    keyOutputs: `Cleaned dataset free from errors or inconsistencies`,
    templateCategory: ['Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/DataCleanser.png',
    recommended: true,
    premiumTag: true,
    flowId: '639a6f32-ff52-4bbb-a4a9-c7ff0b695f5e',
  },

  {
    title: `Sales Funnel`,
    description: `Assists in designing an effective sales funnel strategy that nurtures leads through each stage towards conversion into paying customers systematically.`,
    keyInputs: `Target Audience, Funnel Stages Definition`,
    keyOutputs: `Campaign overvi, execution strategy, sales funnel timeline`,
    templateCategory: ['Sales', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SalesFunnel.png',
    recommended: true,
    premiumTag: true,
    flowId: '6f10bafa-1d41-44b9-a237-2b6cb909d75d',
  },
  {
    title: `Presentation Image Builder`,
    description: `Streamline the creation of visually engaging presentation slides by using this template to easily source and integrate relevant images that align with your content and message.`,
    keyInputs: `Presentation topic, Desired image themes, Content keywords`,
    keyOutputs: `Engaging slides with relevant images`,
    templateCategory: [
      'Content Creation',
      'Design',
      'Marketing',
      'Product Management',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/PresentationImageBuilder.png',
    recommended: true,
    premiumTag: false,
    flowId: '809f1646-a191-4673-a3bc-e8989f0131c0',
  },

  {
    title: `Meeting Preparation Workflow`,
    description: `Optimize meeting efficiency with a template that organizes agenda items, compiles necessary documents, and outlines participant roles to ensure productive and well-structured discussions.`,
    keyInputs: `Meeting agenda, Participant list and roles, Required documents`,
    keyOutputs: `Organized agenda, Compiled documents, Outlined participant roles`,
    templateCategory: [
      'Human Resources',
      'Marketing',
      'Sales',
      'Product Management',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/MeetingPreparationWorkflow.png',
    recommended: false,
    premiumTag: false,
    flowId: '4b0b265f-a8bd-461d-9a68-28802e5d1ad9',
  },
  {
    title: `Job Description Writer`,
    description: `Streamlines crafting compelling job descriptions to attract qualified candidates while ensuring clarity on role expectations and qualifications needed.`,
    keyInputs: `Job title, responsibilities list, required qualifications and skills`,
    keyOutputs: `Job description document with roles and responsibilities`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/JobDescriptionWriter.png',
    recommended: true,
    premiumTag: false,
    flowId: 'c9a696a9-1ad1-4ab8-b597-ccb735734ef9',
  },
  {
    title: `Key Findings Extractor`,
    description: `Identifies and extracts critical findings from complex datasets or documents simplifying analysis and reporting.`,
    keyInputs: `Large Dataset or Document for Extraction , Criteria for Defining 'Key Findings`,
    keyOutputs: `Critical findings from datasets or documents extracted for simplified reporting`,
    templateCategory: [
      'Research & Development',
      'Data Analytics',
      'Marketing',
      'Sales',
      'Product Management',
      'Finance',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/KeyFindingsExtractor.png',
    recommended: false,
    premiumTag: false,
    flowId: 'e41ab7d2-7b00-4962-b1f6-15cdaf13bcb2',
  },
  {
    title: `New Employee Onboarding Checklist`,
    description: `Provides a comprehensive checklist to ensure new hires have a smooth transition into their roles within an organization.`,
    keyInputs: `Role-specific tasks and milestones for new hires' onboarding process`,
    keyOutputs: `Comprehensive onboarding tasks list, New hire orientation progress tracking`,
    templateCategory: ['Human Resources'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/NewEmployeeOnboardingChecklist.png',
    recommended: false,
    premiumTag: false,
    flowId: 'c63d0699-e820-4e07-a2ec-b6ff45e713b9',
  },
  {
    title: `Employee Satisfaction Analyzer`,
    description: `Analyzes employee feedback to gauge job satisfaction levels and identify areas where workplace improvements can be made.`,
    keyInputs: `Employee feedback survey results or comments text data`,
    keyOutputs: `Employee satisfaction levels report, Workplace improvement suggestions`,
    templateCategory: ['Human Resources'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/EmployeeSatisfactionAnalyzer.png',
    recommended: true,
    premiumTag: true,
    flowId: '428b0d7b-fc4c-4cef-8a5d-e8290400dbfd',
  },
  {
    title: `Skill Gap Assessment`,
    description: `It identifies the differences between current skill levels and desired competencies, enabling targeted learning interventions.`,
    keyInputs: `Current skills inventory, desired skill set, assessment criteria, performance data`,
    keyOutputs: `Skill gap report, Personalized learning recommendations, Competency heatmap`,
    templateCategory: [
      'Human Resources',
      'Research & Development',
      'Sales',
      'Design',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SkillGapAssessment.png',
    recommended: false,
    premiumTag: false,
    flowId: '7bb3e210-17ef-4398-b968-7398cb346b06',
  },
  {
    title: `Post-Project Evaluator`,
    description: `Offers a structured approach to analyzing project performance post-completion, identifying successes and areas for improvement.`,
    keyInputs: `Project goals vs. actual results; key performance indicators (KPIs); feedback from stakeholders; lessons learned documentation`,
    keyOutputs: `Post-project review report , Lessons learned documentation , Performance metrics analysis`,
    templateCategory: [
      'Product Management',
      'Research & Development',
      'Data Analytics',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/Post-ProjectEvaluator.png',
    recommended: false,
    premiumTag: false,
    flowId: 'e9375767-c76b-426e-bbb2-2a72cf564fc4',
  },
  {
    title: `Usability Testing Planner`,
    description: `Organizes usability testing sessions with pre-defined goals, procedures, participant criteria, and reporting metrics to enhance product design.`,
    keyInputs: `Usability testing goals/objectives; test scenarios/tasks to perform by participants; participant recruitment criteria`,
    keyOutputs: `Usability testing protocol  , Participant recruitment criteria  , Testing session reporting format`,
    templateCategory: ['Product Management', 'Design'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/UsabilityTestingPlanner.png',
    recommended: false,
    premiumTag: false,
    flowId: '70bab043-80fc-48c3-b0fc-ce05cb92ac1b',
  },
  {
    title: `Design Evaluator`,
    description: `Provides criteria-based assessments to critique design elements objectively, ensuring they meet established usability standards and aesthetic values.`,
    keyInputs: `Design elements to be evaluated (e.g., layout/color scheme); usability standards checklist or guidelines`,
    keyOutputs: `Design evaluation report  , Objective design critique checklist  , Improvement recommendations list`,
    templateCategory: ['Design'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/DesignEvaluator.png',
    recommended: true,
    premiumTag: false,
    flowId: '18f4d77f-324a-4aa9-ab7f-e372a329e58f',
  },
  {
    title: `Industry Trend Analyzer`,
    description: `Examines market data to identify emerging trends that can influence strategic decision-making in business development or product innovation.`,
    keyInputs: `Market segment for analysis focus area(s); data sources for trend identification (reports/surveys); time frame for trend observation`,
    keyOutputs: `Emerging trends analysis report  , Strategic implications summary  , Data-driven decision-making insights`,
    templateCategory: ['Marketing', 'Research & Development'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/IndustryTrendAnalyzer.png',
    recommended: true,
    premiumTag: true,
    flowId: '0c753615-3f67-4029-8ad9-e01436fd2ded',
  },
  {
    title: `Feature Prioritization`,
    description: `Assists teams in ranking product features based on value delivery, customer needs, resource availability ensuring efficient roadmap execution.`,
    keyInputs: `List of proposed features/functions customer needs assessments resource availability constraints business strategy alignment considerations`,
    keyOutputs: `Ranked feature list based on priority  , Decision rationale documentation  , Aligned product development focus`,
    templateCategory: ['Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/FeaturePrioritization.png',
    recommended: true,
    premiumTag: true,
    flowId: 'f179ab57-3dbd-4921-9edc-7ee51b684e77',
  },
  {
    title: `Vision Statement Writer`,
    description: `Craft a clear and inspirational vision statement for your organization or project using this template which guides you through identifying long-term goals and aspirations.`,
    keyInputs: `Company values, Long-term goals, Core purpose`,
    keyOutputs: `Inspirational vision statement`,
    templateCategory: [
      'Human Resources',
      'Product Management',
      'Research & Development',
      'Customer Service',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/VisionStatementWriter.png',
    recommended: false,
    premiumTag: false,
    flowId: '04864f37-dcf2-4023-b773-9d7aad54cb7e',
  },
  {
    title: `Whiteboard Notes Summarizer`,
    description: `Convert messy whiteboard brainstorming sessions into organized summaries capturing all critical points discussed ensuring ideas are preserved post-meeting.`,
    keyInputs: `Photographs or scans of whiteboard notes ; Main discussion topics`,
    keyOutputs: `Organized whiteboard session summaries`,
    templateCategory: [
      'Product Management',
      'Design',
      'Research & Development',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/WhiteboardNotesSummarizer.png',
    recommended: true,
    premiumTag: false,
    flowId: '86e21a43-4e40-4d88-a01f-e7197c4bc157',
  },
  {
    title: `Daily News Updater`,
    description: `Stay informed with automatically aggregated news content tailored to your interests or industry delivered daily so you're always up-to-date on the latest developments.`,
    keyInputs: `Preferred news categories/topics; Specific keywords or companies to track`,
    keyOutputs: `Personalized daily news updates`,
    templateCategory: ['Research & Development', 'Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/DailyNewsUpdater.png',
    recommended: true,
    premiumTag: false,
    flowId: 'f9495419-1d91-471d-9084-96e62df19415',
  },
  {
    title: `Cluster Analysis`,
    description: `Groups data points with similar characteristics together, aiding in market segmentation or identifying patterns within datasets.`,
    keyInputs: `Dataset to be Analyzed (e.g., Customer Data), Number of Clusters Desired, Clustering Algorithm Preferences`,
    keyOutputs: `Data clusters identification, characteristic patterns within data groups`,
    templateCategory: [
      'Data Analytics',
      'Marketing',
      'Customer Service',
      'Sales',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ClusterAnalysis.png',
    recommended: false,
    premiumTag: true,
    flowId: '837a9d77-1661-432a-89ad-52559201df38',
  },
  {
    title: `Demographic Segmentation`,
    description: `Sorts customers into categories based on demographic data like age, gender, or income, enabling more precise targeting in marketing efforts.`,
    keyInputs: `Market Data Set, Demographic Variables (Age Groups, Gender etc.), Segmentation Goals`,
    keyOutputs: `Demographic-based customer categories, targeted marketing plans`,
    templateCategory: ['Marketing', 'Sales', 'Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/DemographicSegmentation.png',
    recommended: false,
    premiumTag: true,
    flowId: 'f1adbcba-cdbf-475c-baa5-d7f5782c79f8',
  },
  {
    title: `KPI Selection Guide`,
    description: `Helps select the most relevant Key Performance Indicators (KPIs) for monitoring and improving business processes or objectives.`,
    keyInputs: `Business Objectives and Goals , Industry Sector , Available Data Sources`,
    keyOutputs: `Selected KPIs list for business monitoring and improvement processes`,
    templateCategory: ['Data Analytics', 'Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/KPISelectionGuide.png',
    recommended: true,
    premiumTag: true,
    flowId: '184cfd83-1259-4a29-894f-ea4f3574623f',
  },
  {
    title: `Academic Paper Summary`,
    description: `Summarizes key points from scholarly articles or papers quickly while maintaining the core message and findings intact.`,
    keyInputs: `Full Text of Academic Paper or Link to Publication , Summary Length Requirement`,
    keyOutputs: `Condensed summary of academic papers maintaining core message`,
    templateCategory: ['Research & Development', 'Data Analytics'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/AcademicPaperSummary.png',
    recommended: true,
    premiumTag: false,
    flowId: '14757a1c-f0f9-4fc8-b2e0-5b1c6128a05f',
  },
  {
    title: `User Survey Designer`,
    description: `It streamlines the process of creating engaging and effective user surveys to gather valuable customer feedback efficiently.`,
    keyInputs: `Survey objectives, target audience demographics, question types (multiple-choice, open-ended, etc.), branding elements`,
    keyOutputs: `Custom user survey, Questions tailored to target audience`,
    templateCategory: [
      'Marketing',
      'Customer Service',
      'Research & Development',
      'Product Managment',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/UserSurveyDesigner.png',
    recommended: false,
    premiumTag: false,
    flowId: 'a246e0ff-3cfe-4933-a309-5ac340eca7c2',
  },

  {
    title: `Survey Sentiment Analysis`,
    description: `This template utilizes AI to analyze responses from surveys, providing insights into the emotions and opinions of your respondents.`,
    keyInputs: `Survey responses text data, sentiment analysis parameters (e.g., positivity threshold), report format preferences`,
    keyOutputs: `Sentiment scores for responses, Emotional insights report`,
    templateCategory: [
      'Marketing',
      'Customer Service',
      'Data Analytics',
      'Product Managment',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SurveySentimentAnalysis.png',
    recommended: true,
    premiumTag: false,
    flowId: '8f7da0f4-b20d-4c82-b550-5a43794278e0',
  },
  {
    title: `Sales Forecaster`,
    description: `Predicts future sales trends based on historical data analysis, aiding in more accurate sales planning and inventory management.`,
    keyInputs: `Historical sales data sets, forecasting time frame (e.g., quarterly), relevant market factors`,
    keyOutputs: `Predicted sales trends report, Sales planning recommendations`,
    templateCategory: ['Sales', 'Finance', 'Data Analytics', 'Marketing'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SalesForecaster.png',
    recommended: false,
    premiumTag: true,
    flowId: '0ddbef65-6124-49dc-8290-047c1f9701bc',
  },
  {
    title: `Sales Performance Analyzer`,
    description: `Assesses sales activities and performance metrics to identify areas for improvement and drive revenue growth effectively.`,
    keyInputs: `Sales activity logs and performance metrics data sets`,
    keyOutputs: `Sales performance metrics analysis, Improvement and growth strategies`,
    templateCategory: ['Sales', 'Data Analytics', 'Marketing', 'Finance'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SalesPerformanceAnalyzer.png',
    recommended: false,
    premiumTag: true,
    flowId: 'da4ad341-4e16-463b-9328-5cc206b33a60',
  },
  {
    title: `Pre-Employment Assessment`,
    description: `Streamlines candidate evaluation by automating pre-employment testing to identify top talent quickly during the hiring process.`,
    keyInputs: `Job role requirements and specifications, assessment questions library or templates`,
    keyOutputs: `Candidate evaluation scores, Shortlist of top talent candidates`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/SalesPerformanceAnalyzer.png',
    recommended: false,
    premiumTag: false,
    flowId: 'fec73ce6-c55f-475d-8ecc-5595a9732abe',
  },
  {
    title: `Job Interview Questionnaire`,
    description: `Creates structured interview questionnaires that ensure consistent candidate assessment across all interviews.`,
    keyInputs: `Job description and competencies required for the role, questionnaire structure preferences`,
    keyOutputs: `Structured interview questionnaire template, Consistent candidate assessment framework`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/JobInterviewQuestionnaire.png',
    recommended: true,
    premiumTag: false,
    flowId: 'd3e4ffa7-00cc-41d4-aba6-b0e265859383',
  },
  {
    title: `Exit Interview Builder`,
    description: `Facilitates the creation of customized exit interviews that help understand why employees leave and how retention can be improved.`,
    keyInputs: `Company-specific exit interview questions themes or topics to cover`,
    keyOutputs: `Customized exit interview template, Questions to uncover reasons for departure`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ExitInterviewBuilder.png',
    recommended: false,
    premiumTag: false,
    flowId: '396d100c-9815-42f4-b52d-28bc34c2f4ca',
  },
  {
    title: `Task Breakdown Structure (TBS)`,
    description: `Helps decompose complex projects into smaller, manageable tasks for better organization and delegation.`,
    keyInputs: `Overall project scope, major deliverables, hierarchical task list`,
    keyOutputs: `Hierarchical task list, Assigned responsibilities, Task dependencies map`,
    templateCategory: ['Product Management', 'Marketing', 'Design'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/TaskBreakdownStructure(TBS).png',
    recommended: false,
    premiumTag: true,
    flowId: 'f938f188-aca1-440d-86ad-a6b72bc506cb',
  },
  {
    title: `RFP Responder`,
    description: `Aids businesses in crafting comprehensive and competitive responses to RFPs they receive, increasing their chances of winning contracts.`,
    keyInputs: `RFP details received, company's qualifications and experience relevant to the RFP requirements`,
    keyOutputs: `Tailored proposal response to RFPs, Highlighted value proposition and differentiators, Compliance matrix with RFP requirements`,
    templateCategory: ['Sales', 'Marketing', 'Finance'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/RFPResponder.png',
    recommended: true,
    premiumTag: true,
    flowId: '6cc6c23e-336c-4d1e-851a-316dce154b80',
  },
  {
    title: `Budget Estimation Calculator`,
    description: `Simplifies the financial planning process by providing tools to accurately estimate costs associated with a project or initiative.`,
    keyInputs: `Cost items breakdown (labor/materials), quantity estimates per item; overhead costs; contingency percentage`,
    keyOutputs: `Detailed budget estimate breakdown , Cost projections and forecasts , Funding requirement summary`,
    templateCategory: ['Finance'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/BudgetEstimationCalculator.png',
    recommended: false,
    premiumTag: true,
    flowId: '35ff3cfb-b685-4b68-9d80-08c6b03be312',
  },
  {
    title: `User Testing Interview Builder`,
    description: `Creates custom interview guides for conducting user tests that gather valuable feedback on product usability and customer satisfaction.`,
    keyInputs: `User testing objectives; target user demographics for interviewees; questions script based on product features/usability concerns`,
    keyOutputs: `Custom interview script , User testing questions guide , Feedback collection framework`,
    templateCategory: [
      'Design',
      'Research & Development',
      'Marketing',
      'Product Managment',
    ],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/UserTestingInterviewBuilder.png',
    recommended: false,
    premiumTag: false,
    flowId: '45c184d0-fca4-43fd-9063-45c399c3170d',
  },
  {
    title: `UX Content Writer`,
    description: `Generates user-focused content that enhances the overall user experience by being both informative and engaging within digital platforms.`,
    keyInputs: `Target audience characteristics/demographics; content tone/style preferences; key messages to convey within content`,
    keyOutputs: `Engaging UX content pieces  , Content strategy alignment documentation  , User engagement metrics improvement plan`,
    templateCategory: ['Design', 'Content Creation'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/UXContentWriter.png',
    recommended: false,
    premiumTag: false,
    flowId: 'fa49f4bd-e5eb-4977-a628-50b782953f3a',
  },
  {
    title: `Product Requirements Document (PRD) Writer`,
    description: `This template streamlines the process of creating detailed PRDs, outlining features, functionality, and system requirements necessary for new product development.`,
    keyInputs: `Product name, target audience, product features, technical specifications, success criteria`,
    keyOutputs: `Detailed PRD with specifications, goals`,
    templateCategory: [
      'Product Management',
      'Design',
      'Research & Development',
    ],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/ProductRequirementsDocument(PRD)Writer.png',
    recommended: true,
    premiumTag: false,
    flowId: 'ae3afaf5-e790-4b37-803d-4ef0b1944a7f',
  },
  {
    title: `Product Launch Readiness Planner`,
    description: `It helps teams prepare for a product launch by organizing tasks, timelines, and responsibilities to ensure all aspects of the launch are covered.`,
    keyInputs: `Product details, launch date, marketing strategy, inventory levels, distribution channels`,
    keyOutputs: `Launch checklist completion, task status report`,
    templateCategory: ['Marketing', 'Sales', 'Product Management'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/ProductLaunchReadinessPlanner.png',
    recommended: false,
    premiumTag: false,
    flowId: 'eeda02a5-1835-4c3c-872f-40e38e5f1578',
  },
  {
    title: `Net Promoter Score (NPS) Analyzer`,
    description: `This template automatically collects and analyzes NPS data to provide insights into customer loyalty and satisfaction levels.`,
    keyInputs: `Customer survey responses, segmentation data`,
    keyOutputs: `NPS score, customer loyalty insights report`,
    templateCategory: ['Customer Service', 'Marketing', 'Sales'],
    imageUrl:
      'https://assets-dev.fluxprompt.ai/NetPromoterScore(NPS)Analyzer.png',
    recommended: true,
    premiumTag: false,
    flowId: '7e0ed0c5-2cfc-4989-b130-0f00d49a84c7',
  },
  {
    title: `Release Notes Writer`,
    description: `It simplifies the creation of clear and concise release notes for software updates, ensuring users are informed about new features and changes.`,
    keyInputs: `Version number, new features description, bug fixes list, enhancements details`,
    keyOutputs: `Release notes document with new features, bug fixes`,
    templateCategory: ['Design', 'Product Management'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/ReleaseNotesWriter.png',
    recommended: false,
    premiumTag: false,
    flowId: 'cfc94dd2-1088-4f13-a57d-6c8980988d37',
  },
  {
    title: `Feature Discovery`,
    description: `This tool aids in identifying key product features that resonate with users by analyzing usage patterns and feedback to guide future development priorities.`,
    keyInputs: `User feedback data, market research results, competitor feature sets`,
    keyOutputs: `List of potential new features, prioritization matrix`,
    templateCategory: [
      'Product Management',
      'Research & Development',
      'Design',
    ],
    imageUrl: 'https://assets-dev.fluxprompt.ai/FeatureDiscovery.png',
    recommended: false,
    premiumTag: true,
    flowId: 'a62746e0-95db-4489-99e4-e8dcf8c080eb',
  },
  {
    title: `Cash Flow Analyzer`,
    description: `The template provides an overview of cash inflows and outflows over a period, helping businesses manage their financial health more effectively.`,
    keyInputs: `Income statements, expense reports, investment records`,
    keyOutputs: `Cash flow analysis report, financial health indicators`,
    templateCategory: ['Finance'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/CashFlowAnalyzer.png',
    recommended: false,
    premiumTag: false,
    flowId: '5a2d3cac-6080-409a-abad-c610c9f48291',
  },
  {
    title: `Offboarding Checklist`,
    description: `Provides a structured outline for the offboarding process to ensure all necessary steps are taken when an employee leaves the company smoothly.`,
    keyInputs: `Employee information, company assets checklist to be returned by employee`,
    keyOutputs: `Completed offboarding steps checklist, final offboarding status`,
    templateCategory: ['Human Resources'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/OffboardingChecklist.png',
    recommended: false,
    premiumTag: false,
    flowId: 'c9a696a9-1ad1-4ab8-b597-ccb735734ef9',
  },
  {
    title: `One Pager Writer`,
    description: `Quickly generate compelling one-page summaries that highlight the core value proposition of a product or service, perfect for handouts or email attachments in marketing campaigns.`,
    keyInputs: `Product/service description, Target audience, Unique selling points`,
    keyOutputs: `One-page product/service summaries`,
    templateCategory: ['Content Creation', 'Marketing', 'Sales'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/OnePagerWriter.png',
    recommended: true,
    premiumTag: false,
    flowId: '1e7b02cf-4339-4294-a450-b703deaf2878',
  },
  {
    title: `MVP Feature Generator`,
    description: `Utilize this template to brainstorm and prioritize features for a Minimum Viable Product (MVP), ensuring you focus on delivering a product with just enough features to satisfy early adopters.`,
    keyInputs: `Target customer needs, Proposed product features, Priority levels`,
    keyOutputs: `Prioritized list of MVP features`,
    templateCategory: ['Product Management', 'Research & Development'],
    imageUrl: 'https://assets-dev.fluxprompt.ai/MVPFeatureGenerator.png',
    recommended: false,
    premiumTag: true,
    flowId: '24ad5413-e909-4e16-aec1-05b1ab14f74e',
  },
  {
    title: `Porter's Five Forces`,
    description: `Analyze the competitive forces in your industry with this template that walks you through assessing threats from new entrants, bargaining power of suppliers/customers, threat of substitutes, and competitive rivalry.`,
    keyInputs: `Industry details, Potential entrants info, Supplier/customer power dynamics, Substitute products/services information`,
    keyOutputs: `Analysis of industry competitive forces`,
    templateCategory: ['Marketing', 'Research & Development'],
    imageUrl: `https://assets-dev.fluxprompt.ai/Porter'sFiveForces.png`,
    recommended: false,
    premiumTag: false,
    flowId: 'fe66ec6a-d8f6-4181-a4a6-7bbb9c70f8ee',
  },
  {
    title: `Training Video Script Writer`,
    description: `Create effective scripts for training videos that engage learners and clearly explain concepts, processes, or skills with well-structured narratives and instructional cues.`,
    keyInputs: `Training objectives, Target audience characteristics, Instructional content topics`,
    keyOutputs: `Engaging training video scripts, Instructional cues`,
    templateCategory: ['Content Creation', 'Human Resources'],
    imageUrl: `https://assets-dev.fluxprompt.ai/TrainingVideoScriptWriter.png`,
    recommended: false,
    premiumTag: true,
    flowId: '08e83404-cd31-4380-a64f-8de8247dec00',
  },
  {
    title: `Value Proposition Writer`,
    description: `Define the unique value your product or service offers customers by using this template to articulate key benefits, advantages over competitors, and reasons why customers should choose you.`,
    keyInputs: `Product/service benefits, Competitor comparison details, Customer testimonials or data points`,
    keyOutputs: `Articulated value proposition, Competitive advantages summary`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: `https://assets-dev.fluxprompt.ai/ValuePropositionWriter.png`,
    recommended: false,
    premiumTag: false,
    flowId: '37e85992-9b79-4cbc-89d5-3dc449fefcb6',
  },
  {
    title: `Article Paraphaser`,
    description: `Rephrase existing articles to create original content while maintaining the original meaning; ideal for avoiding plagiarism or refreshing web content without changing its intent.`,
    keyInputs: `Original article text content to rephrase`,
    keyOutputs: `Rephrased original content articles`,
    templateCategory: [
      'Research & Development',
      'Marketing',
      'Product Management',
    ],
    imageUrl: `https://assets-dev.fluxprompt.ai/ArticleParaphaser.png`,
    recommended: true,
    premiumTag: false,
    flowId: '47cb14fb-6570-4112-ab83-f743976ff40a',
  },
  {
    title: `Facebook Ad Writer`,
    description: `Generate compelling ad copy specific to Facebook's platform guidelines designed to grab attention, drive engagement, and encourage clicks from your target audience.`,
    keyInputs: `Target audience demographics/interests; Desired call-to-action; Brand voice/style guidelines`,
    keyOutputs: `Facebook-specific ad copy, Engagement-driven content`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: `https://assets-dev.fluxprompt.ai/FacebookAdWriter.png`,
    recommended: false,
    premiumTag: false,
    flowId: 'b962f001-433f-499a-bf50-bbf835ade0b7',
  },
  {
    title: `Email Newsletter Writer`,
    description: `Produce engaging newsletters effortlessly with templates guiding content curation, layout design, call-to-action placement making it simple to keep subscribers informed and interested.`,
    keyInputs: `Newsletter topic/themes; Content pieces for curation; Subscriber demographics/preferences`,
    keyOutputs: `Curated newsletter content, Newsletter layout design`,
    templateCategory: ['Content Creation', 'Marketing'],
    imageUrl: `https://assets-dev.fluxprompt.ai/EmailNewsletterWriter.png`,
    recommended: false,
    premiumTag: false,
    flowId: '66725dcd-3017-48fc-8c93-8444bf8a00dd',
  },
  {
    title: `Business Canvas Writer`,
    description: `Develop a clear business model canvas outlining key components such as value propositions, customer segments, revenue streams, channels, cost structure etc., essential for strategizing business plans.`,
    keyInputs: `Value propositions descriptions; Customer segments profiles; Revenue streams identification; Distribution channels strategies; Cost structure analysis`,
    keyOutputs: `Business model canvas components outline`,
    templateCategory: ['Product Management', 'Finance', 'Strategy Planning'],
    imageUrl: `https://assets-dev.fluxprompt.ai/BusinessCanvasWriter.png`,
    recommended: false,
    premiumTag: false,
    flowId: '24ddafff-c629-477a-b43b-cda182093158',
  },
  {
    title: `YouTube Video Transcriber`,
    description: `Converts YouTube video dialogue into accurate transcripts that can be used for closed captions or as part of video descriptions for enhanced accessibility and SEO.`,
    keyInputs: `YouTube video URL/link; Language spoken in video; Formatting preferences for captions`,
    keyOutputs: `Accurate transcripts of YouTube video dialogue for captions or descriptions`,
    templateCategory: [
      'Content Creation',
      'Research & Development',
      'Data Analytics',
    ],
    imageUrl: `https://assets-dev.fluxprompt.ai/YouTubeVideoTranscriber.png`,
    recommended: true,
    premiumTag: false,
    flowId: 'e7d8e9dc-4a76-405e-9702-7c00bb1e70b3',
  },
  {
    title: `Portfolio Risk Analyzer`,
    description: `Assesses investment portfolios to identify risk levels across assets, offering insights for balanced financial management.`,
    keyInputs: `Investment Portfolio Details , Market Volatility Measures , Investor Risk Tolerance Level`,
    keyOutputs: `Investment portfolio risk levels report across assets`,
    templateCategory: ['Finance'],
    imageUrl: `https://assets-dev.fluxprompt.ai/PortfolioRiskAnalyzer.png`,
    recommended: false,
    premiumTag: true,
    flowId: 'a20698c7-1fb3-4c28-a5fc-cbb87eb583e5',
  },
  {
    title: `Customer Segmentation`,
    description: `Divides your customer base into distinct groups based on behavior and demographics for more targeted marketing strategies.`,
    keyInputs: `Customer database file(s), segmentation criteria (behavioral patterns and demographics)`,
    keyOutputs: `Groups based on customer behavior and demographics, Targeted marketing strategy outline`,
    templateCategory: ['Marketing', 'Sales'],
    imageUrl: `https://assets-dev.fluxprompt.ai/CustomerSegmentation.png`,
    recommended: true,
    premiumTag: false,
    flowId: 'f6dd6c3f-7596-48b6-a9cb-dc8f39c4b15d',
  },
  {
    title: `Project Risk Analyzer`,
    description: `Evaluates potential risks associated with a project, offering insights into mitigation strategies to minimize impact on success.`,
    keyInputs: `Identified risks list with probability and impact ratings; risk mitigation strategies; risk monitoring plans`,
    keyOutputs: `Risk assessment report, Risk mitigation strategies, Priority risk matrix`,
    templateCategory: [
      'Product Management',
      'Research & Development',
      'Finance',
      'Human Resources',
    ],
    imageUrl: `https://assets-dev.fluxprompt.ai/ProjectRiskAnalyzer.png`,
    recommended: false,
    premiumTag: false,
    flowId: 'b27c0c43-864a-4cb6-8f88-880dbe499e19',
  },
  {
    title: `Data Analyzer`,
    description: `Offers tools for deep analysis of complex datasets enabling businesses to uncover patterns, trends, or insights critical for informed decision-making processes.`,
    keyInputs: `Dataset for analysis specific questions or goals for analysis types of analysis to perform (e.g., statistical summary visualization)`,
    keyOutputs: `Data insights report with actionable conclusions`,
    templateCategory: ['Data Analytics'],
    imageUrl: `https://assets-dev.fluxprompt.ai/DataAnalyzer.png`,
    recommended: true,
    premiumTag: true,
    flowId: '0355f079-d2fd-4457-823e-2e55512c67c7',
  },
  // {
  //   title: `Cold Email Writer`,
  //   description: `Helps create personalized cold emails designed to engage potential clients or partners with tailored messaging aimed at initiating business relationships.`,
  //   keyInputs: ``,
  //   keyOutputs: ``,
  //   templateCategory: ['Sales', 'Marketing'],
  //   imageUrl: `https://assets-dev.fluxprompt.ai/DataAnalyzer.png`,
  //   recommended: false,
  //   premiumTag: false,
  //   flowId: 'ede1a84d-7080-472b-be5c-2d7aabf5b08b',
  // },

  // {
  //   title: `Quarterly Earnings Analyzer`,
  //   description: `Designed to dissect quarterly earnings information, this tool assists in understanding revenue trends and other financial metrics per quarter.`,
  //   keyInputs: `Earnings call transcript or summary document`,
  //   keyOutputs: `Revenue growth analysis, operational efficiency report`,
  //   templateCategory: ['Finance', 'Marketing'],
  //   imageUrl: ``,
  //   recommended: false,
  //   premiumTag: true,
  //   flowId: '80fc82e9-5e3f-42b4-8caf-f6f70d97a41e',
  // },
  // {
  //   title: `10-K Financial Statement Analyzer`,
  //   description: `It automates the analysis of annual 10-K financial reports, offering insights into a company's financial performance for stakeholders.`,
  //   keyInputs: `Company's 10-K report file/link`,
  //   keyOutputs: `Extracted key financial metrics, performance insights summary`,
  //   templateCategory: ['Finance', 'Data Analytics'],
  //   imageUrl: `https://assets-dev.fluxprompt.ai/10-KFinancialStatementAnalyzer.png`,
  //   recommended: true,
  //   premiumTag: true,
  //   flowId: '33729ad6-5095-4d69-ba74-869090c1000a',
  // },
  // {
  //   title: `10-Q Quarterly Report Analyzer`,
  //   description: `This template facilitates quick analysis of quarterly financial data from 10-Q reports to monitor company performance throughout the year.`,
  //   keyInputs: `Company's 10-Q report file/link`,
  //   keyOutputs: `Quarterly financial health snapshot, profit and loss overview`,
  //   templateCategory: ['Finance', 'Data Analytics'],
  //   imageUrl: `https://assets-dev.fluxprompt.ai/10-QQuarterlyReportAnalyzer.png`,
  //   recommended: false,
  //   premiumTag: true,
  //   flowId: undefined,
  // },
  // {
  //   title: `Product Roadmap Planner`,
  //   description: `Helps plot out the strategic direction of a product's development including feature releases timelines aligning stakeholders around future plans.`,
  //   keyInputs: ``,
  //   keyOutputs: `Product roadmap visualization   , Feature release schedule   , Stakeholder communication plan`,
  //   templateCategory: ['Product Management'],
  //   imageUrl: `https://assets-dev.fluxprompt.ai/ProductRoadmapPlanner.png`,
  //   recommended: true,
  //   premiumTag: true,
  //   flowId: '899eaf73-3aa1-4f31-8ea4-67ab8d1af382',
  // },

  // {
  //   title: `Bounce Rate Reduction Strategy`,
  //   description: `Offers strategies to decrease the percentage of visitors who navigate away after viewing only one page, aiming to improve user engagement and retention rates.`,
  //   keyInputs: `Current Bounce Rate Statistics , User Experience Factors , Engagement Tactics`,
  //   keyOutputs: `Bounce rate reduction tactics and strategy implementation guide`,
  //   templateCategory: ['Marketing', 'Research & Development', 'Data Analytics'],
  //   imageUrl: '',
  //   recommended: false,
  //   premiumTag: false,
  //   flowId: '5c74aa96-15d1-4746-9a1b-d859776f5338',
  // },
];
