import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
export class VectorService extends ApiService {
  static pathName = '/vector';

  static addFile = (
    query: {
      nameSpace: string;
      indexName: string;
      url?: string;
      spaceId?: string;
    },
    body?: FormData,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/add?nameSpace=${query.nameSpace}&indexName=${
        query.indexName
      }${query.url ? `&url=${query.url}` : ''}${
        query.spaceId ? `&spaceId=${query.spaceId}` : ''
      }`,
      body,
    );
  };

  static deleteFile = (query: {
    nameSpace: string;
    fileName?: string;
    spaceId?: string;
  }) => {
    return ApiService.delete(
      `${this.pathName}/document/${query.fileName}?nameSpace=${query.nameSpace}${query.spaceId ? `&spaceId=${query.spaceId}` : ''}`,
    );
  };

  static deleteNameSpace = (query: {
    nameSpace: string;
    indexName?: string;
    spaceId?: string;
  }) => {
    return ApiService.delete(
      `${this.pathName}/delete-nameSpace?name=${query.nameSpace}&indexName=${
        query.indexName
      }${query.spaceId ? `&spaceId=${query.spaceId}` : ''}`,
    );
  };

  static searchFile = (
    body: {
      prompt: string;
      model: string;
      topK: number;
      maxTokenModel: number;
      isNERSearch: boolean;
    },
    query: {
      nameSpace: string;
      indexName: string;
    },
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/search-queue?nameSpace=${query.nameSpace}&indexName=${query.indexName}`,
      body,
    );
  };

  static search = (
    body: {
      prompt: string;
      model: string;
      topK: number;
      maxTokenModel: number;
      isNERSearch: boolean;
    },
    query: {
      nameSpace: string;
      indexName: string;
    },
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/search?nameSpace=${query.nameSpace}&indexName=${query.indexName}`,
      body,
    );
  };

  static similaritySearch = (query: {
    nameSpace: string;
    indexName: string;
    query: string;
    size?: number;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/similarity-search?nameSpace=${
        query.nameSpace
      }&indexName=${query.indexName}&query=${query.query}${
        query.size ? `&size=${query.size}` : ''
      }`,
    );
  };
}
