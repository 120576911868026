import { useCallback, useEffect, useState, useRef } from 'react';
import { type Edge, type OnEdgesChange, applyEdgeChanges } from 'reactflow';

import Y from 'yjs';

function useEdgesStateSynced(
  ydoc: Y.Doc,
): [Edge[], React.Dispatch<React.SetStateAction<Edge[]>>, OnEdgesChange] {
  const [edges, setEdges] = useState<Edge[]>([]);
  const edgesMapRef = useRef(ydoc.getMap<Edge>('edges'));

  const setEdgesSynced = useCallback(
    (edgesOrUpdater: React.SetStateAction<Edge[]>) => {
      ydoc.transact(() => {
        const edgesMap = edgesMapRef.current;
        const seen = new Set<string>();
        const next =
          typeof edgesOrUpdater === 'function'
            ? edgesOrUpdater(Array.from(edgesMap.values())) // Convert to array here
            : edgesOrUpdater;

        for (const edge of next) {
          seen.add(edge.id);
          edgesMap.set(edge.id, edge);
        }

        for (const edge of Array.from(edgesMap.values())) {
          if (!seen.has(edge.id)) {
            edgesMap.delete(edge.id);
          }
        }
      });
    },
    [ydoc],
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    changes => {
      ydoc.transact(() => {
        const edgesMap = edgesMapRef.current;
        const edges = Array.from(edgesMap.values());
        const nextEdges = applyEdgeChanges(changes, edges as Edge[]);

        for (const change of changes) {
          if (change.type === 'add' || change.type === 'reset') {
            edgesMap.set(change.item.id, change.item);
          } else if (change.type === 'remove' && edgesMap.has(change.id)) {
            edgesMap.delete(change.id);
          } else {
            edgesMap.set(change.id, nextEdges.find(e => e.id === change.id)!);
          }
        }
      });
    },
    [ydoc],
  );

  useEffect(() => {
    const edgesMap = edgesMapRef.current;

    const observer = () => {
      setEdges(Array.from(edgesMap.values()));
    };

    edgesMap.observe(observer);

    return () => {
      edgesMap.unobserve(observer);
    };
  }, []);

  return [edges, setEdgesSynced, onEdgesChange];
}

export default useEdgesStateSynced;
