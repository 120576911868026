import { Box, Typography } from '@mui/material';

const Title = ({ data }: any) => {
  return (
    <Box
      sx={{
        padding: '16px',

        ...data?.style,
        bgcolor: data?.fullData?.bgColor || '#fff',
        textAlign: data?.fullWidth?.titleStyle?.textAlign || 'center',

        borderRadius: data?.fullData?.borderRadius || '0px',
        height: 'fit-content',
      }}
    >
      {data?.label && (
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '600',
            color: '#475467',
            ...data?.fullData?.titleStyle,
          }}
          variant="h1"
        >
          {data?.label ? data.label : 'Title Component'}
        </Typography>
      )}
    </Box>
  );
};

export default Title;
