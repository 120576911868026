import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class PerplexityService extends ApiService {
  static pathName = '/perplexity';

  static response = (
    body: any,
    type: string
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/${type}`, body);
  };
}
