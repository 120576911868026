import { Box } from '@mui/material';
import { PlayCircle, SkipBack, SkipForward } from '@phosphor-icons/react';
import React, { memo, useEffect, useRef, useState } from 'react';

import PlaySvg from '../../../assets/icons/Playing.svg';

const AudioComponent = ({ audioUrl }: { audioUrl: string }) => {
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const seekInputRef = useRef<HTMLInputElement>(null);
  const toggleAudio = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (audioUrl) {
      const newAudio = new Audio(audioUrl);
      setAudio(newAudio);
    }
  }, [audioUrl]);

  const handleSeek = () => {
    const seekTime = parseFloat(seekInputRef?.current?.value!);

    if (!isNaN(seekTime) && seekTime >= 0 && seekTime <= audio.duration) {
      audio.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  };
  useEffect(() => {
    const updateProgress = () => {
      const currentProgress = (audio.currentTime || 0) / audio.duration;
      setProgress(currentProgress);
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener('play', () => {
      setIsPlaying(true);
    });

    audio.addEventListener('pause', () => {
      setIsPlaying(false);
    });

    audio.addEventListener('ended', () => {
      setIsPlaying(false);
      setProgress(0);
      setCurrentTime(0);
    });

    audio.addEventListener('timeupdate', () => {
      updateProgress();
    });

    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration);
    });

    const progressInterval = setInterval(() => {
      updateProgress();
    }, 100);

    return () => {
      clearInterval(progressInterval);
      audio.src = '';
    };
  }, [audio]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <>
      {audioUrl && (
        <Box>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'20px'}
            mb={'24px'}
          >
            <SkipBack size={16} color='#667085' />
            {/* <button className='mb-2' onClick={toggleAudio}> */}
            {isPlaying ? (
              <img onClick={toggleAudio} src={PlaySvg} alt='playing' />
            ) : (
              <PlayCircle onClick={toggleAudio} size={32} color='#667085' />
            )}

            <SkipForward size={16} color='#667085' />
          </Box>

          <Box
            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            className='nodrag'
          >
            <Box
              style={{
                width: '100%',
                height: '10px',
                cursor: 'pointer',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                className={'custom-audio-range'}
              >
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#475467',
                    lineHeight: '0',
                  }}
                >
                  {currentTime ? formatTime(currentTime) : '0:00'}
                </span>

                <input
                  type='range'
                  min='0'
                  className='custom-range'
                  max={duration}
                  step='0.1'
                  value={currentTime}
                  onChange={e => handleSeek()}
                  style={{ width: '256px' }}
                  ref={seekInputRef}
                />
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#475467',
                    lineHeight: '0',
                  }}
                >
                  {formatTime(duration)}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(AudioComponent);
