import { IGetAllResponse } from '../util/Types/Generic';
import { ApiService } from './ApiService';

export class SummarizeService extends ApiService {
  static pathName = '/summarize';

  static summarize = (body: {
    numberOfTopics: number;
    wordsForTopic: number;
    file: FormData;
    flowId?: string;
  }): Promise<IGetAllResponse<any>> => {
    return ApiService.post(
      `${this.pathName}?numberOfTopics=${body.numberOfTopics}&wordsForTopic=${
        body.wordsForTopic
      }${body.flowId ? `&flowId=${body.flowId}` : ''}`,
      body.file,
    );
  };
}
