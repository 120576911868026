import React, { useEffect, useMemo, useState } from 'react';
import { ProductService } from '../../../service/ProductService';
import LoadingSpinner from '../../UI/LoadingSpinner';
import { Product } from '../../../util/Types/Product';
import Tap from '@tapfiliate/tapfiliate-js';

import './Subscriptions.scss';
import { useAuthStore } from '../../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';
import { Box } from '@mui/material';
import PaymentPlans from './PaymentPlans';
import PaymentForm from './PaymentForm';
import { UserService } from '../../../service/UserService';
import PaymentSuccesfully from './PaymentSuccesfully';
import { useLocation } from 'react-router-dom';
import {
  getNextPlan,
  getNextPlanAtLeastProfessional,
  getNextPlanToAdvanced,
  getNextPlanUsers,
} from '../../../util/planUtil';
import { getAPIErrorMessage } from '../../../helpers/helpers';

interface Props {
  welcomePageCallback?: () => Promise<void>;
  setPricingDefaultState?: (state: boolean) => void;
  pricingDefaultState?: boolean;
}

const Pricing: React.FC<Props> = ({
  welcomePageCallback,
  pricingDefaultState,
  setPricingDefaultState,
}) => {
  const [mySubscriptions, setMySubscription] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [onOpen, setOnOpen] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Product | undefined>(
    undefined,
  );
  const [isPurchased, setIsPurchased] = useState(false);

  const setNotifications = useNotificationStore(state => state.setNotification);
  const authUser = useAuthStore(state => state.user);

  const [isSelectedTimePlanYearly, setIsSelectedTimePlanYearly] =
    useState(false);

  const location = useLocation();

  const onSelectPlan = (plan: any) => {
    setSelectedPlan(plan);
    setPricingDefaultState?.(false);
  };

  useMemo(() => {
    if (pricingDefaultState) {
      setSelectedPlan(undefined);
    }
  }, [pricingDefaultState]);

  const activeSubscription = useMemo(() => {
    let newSub: any = mySubscriptions;
    if (
      'You don’t have any managed Subscriptions at this time' ===
      newSub?.message
    ) {
      return null;
    }

    if (mySubscriptions?.length === 0) {
      return null;
    }

    const activeSubscription = mySubscriptions?.find(
      subscription =>
        subscription.name === authUser?.plan && subscription?.active,
    );

    return activeSubscription || null;
  }, [mySubscriptions, authUser]);

  useEffect(() => {
    const getMySubscriptions = async () => {
      setIsLoading(true);
      try {
        const response = await ProductService.getMyProducts();

        if (response?.data?.length !== 0) {
          setMySubscription(response?.data);
        }
      } catch (error) {
        setNotifications({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
      }
    };
    const getProducts = async () => {
      setIsLoading(true);
      try {
        const response = await ProductService.getProducts();

        const mapProduct = pricingDefaultTitleAndDesc.map((plan, i) => {
          const product = response?.data?.find(
            prd => prd.name?.toLowerCase() === plan.name?.toLowerCase(),
          );
          return {
            ...plan,
            ...product,
          };
        });

        setProducts(mapProduct as Product[]);
      } catch (error) {
        setNotifications({
          type: 'error',
          message: 'Failed to load products',
        });
      } finally {
        setIsLoading(false);
      }
    };

    getProducts();

    getMySubscriptions();
  }, [isSubscribed, isPurchased]);

  useEffect(() => {
    const createStripeCustomer = async () => {
      try {
        const res = await UserService.createStripeCustomer();
      } catch (error) {
        setNotifications({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as string),
        });
      }
    };

    if (!authUser?.customerId) {
      createStripeCustomer();
    }
  }, [authUser?.customerId]);

  const isWelcomePage = window.location.pathname === '/welcome';

  const onSubscribe = async (body: Product, couponId: string | undefined) => {
    setPaymentLoading(true);
    try {
      await ProductService.subscribeProduct({
        ...body,
        yearly: isSelectedTimePlanYearly,
        couponId,
      });

      Tap.conversion(
        authUser?.id,
        isSelectedTimePlanYearly
          ? String(body?.year_price)
          : String(body?.price),
        { customer_id: authUser?.id },
      );

      if (isWelcomePage) {
        welcomePageCallback?.();
      } else {
        setIsPurchased(true);
      }
      setIsSubscribed(!isSubscribed);
    } catch (error) {
      let anyError = error as any;
      if (anyError.message === 'User does not have any payment methods!') {
        setNotifications({
          type: 'error',
          message: 'Please you need to add a payment method to your account',
        });
        setOnOpen(true);
      } else {
        setNotifications({
          type: 'error',
          message: getAPIErrorMessage(error as any),
        });
      }
    } finally {
      // setSelectedPlan(undefined);
      setPaymentLoading(false);
    }
  };

  const nextPlan = useMemo(
    () => getNextPlan(authUser?.plan || null),
    [authUser?.plan],
  );
  const nextPlanAtLeastProfessional = useMemo(
    () => getNextPlanAtLeastProfessional(authUser?.plan || null),
    [authUser?.plan],
  );
  const nextPlanIfStarter = useMemo(
    () => getNextPlanToAdvanced(authUser?.plan || null),
    [authUser?.plan],
  );
  const nextPlanIfUsersLimit = useMemo(
    () => getNextPlanUsers(authUser?.plan || null),
    [authUser?.plan],
  );

  useEffect(() => {
    if (location.search.includes(`pricing=true&plan=${nextPlan}`)) {
      setSelectedPlan(products.find(prd => prd.name === `${nextPlan}`));
    }
    if (
      location.search.includes(
        `pricing=true&proplan=${nextPlanAtLeastProfessional}`,
      )
    ) {
      setSelectedPlan(
        products.find(prd => prd.name === `${nextPlanAtLeastProfessional}`),
      );
    }
    if (
      location.search.includes(`pricing=true&planstarter=${nextPlanIfStarter}`)
    ) {
      setSelectedPlan(
        products.find(prd => prd.name === `${nextPlanIfStarter}`),
      );
    }
    if (
      location.search.includes(`pricing=true&planUsers=${nextPlanIfUsersLimit}`)
    ) {
      setSelectedPlan(
        products.find(prd => prd.name === `${nextPlanIfUsersLimit}`),
      );
    }
  }, [
    location.search,
    nextPlan,
    nextPlanAtLeastProfessional,
    nextPlanIfStarter,
    nextPlanIfUsersLimit,
    products,
  ]);

  if (isLoading) {
    return <LoadingSpinner height="60vh" />;
  }

  return (
    <Box>
      {paymentLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <LoadingSpinner />
        </Box>
      )}
      {isPurchased ? (
        <PaymentSuccesfully
          planName={selectedPlan?.name}
          setIsPurchased={setIsPurchased}
          setSelectedPlan={setSelectedPlan}
        />
      ) : (
        <>
          {!!selectedPlan ? (
            <PaymentForm
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              onSubscribe={(couponId?: string) =>
                onSubscribe(selectedPlan, couponId)
              }
              isSelectedTimePlanYearly={isSelectedTimePlanYearly}
              authUser={authUser}
            />
          ) : (
            <PaymentPlans
              products={products}
              activeSubscriptionId={activeSubscription?.id}
              setSelectedPlan={onSelectPlan}
              selectedPlan={selectedPlan}
              setIsSelectedTimePlanYearly={setIsSelectedTimePlanYearly}
              isSelectedTimePlanYearly={isSelectedTimePlanYearly}
            />
          )}
        </>
      )}
      {/* <CreatePaymentMethodModal
        show={onOpen}
        authUser={authUser!}
        setAuthUser={setAuthUser}
        setNewMethod={setNewMethod}
        onHide={() => setOnOpen(false)}
      /> */}
    </Box>
  );
};

export default Pricing;

const pricingDefaultTitleAndDesc = [
  {
    name: 'Starter',
    index: 0,
    title: 'Explore and experiment with Ai Automation through workflows.',
    activePlan: false,
    price: null,
    descriptions: [
      'FluxPrompt No-Code Editor for creating AI-based workflows.',
      'LLM models from Google, Anthropic, Perplexity, OpenAI, Meta, and more.',
      'Other features such as General Templates, Auto-triggers, Ai Text, Ai Images, Ai Audio, and more.',
      'Only one user.',
      'Only 5 Ai workflows',
      'Only 3 internal FluxBots',
    ],
    discounts: ['$0.003 '],

    tokensText: '1500 free monthly test Ai Tokens',
  },

  {
    index: 1,
    name: 'Advanced',
    title: 'Greatly expanded power of Ai Automation for everyday usage.',
    activePlan: false,
    discounts: ['$0.002 (33% discount) '],
    tokensText: '3,000 free monthly test Ai Tokens',
    descriptions: [
      'All features in Starter.',
      '25 Ai Workflows.',
      '15 Ai FluxBots.',
      '10 published FluxBots.',
      'Custom Ai audio voices.',
      'Access to Professional Workflow Templates.',
      'Professional services to build 1 custom Ai Workflow.',
      'Only one user.',
    ],
  },

  {
    index: 2,
    name: 'Professional',
    title:
      'Empowering small teams to operationalize Ai capabilities with cross-internet calls and collaboration tools.',
    price: null,
    activePlan: false,
    discounts: ['$0.0015 (50% discount)'],
    tokensText: '10,000 free monthly test Ai Tokens',
    descriptions: [
      'All features in Advanced.',
      'Unlimited internal and published FluxBots.',
      'Premium Templates',
      'Access to all API features to run unlimited flows from anywhere.',
      'Collaborate with up to 3 users and enjoy access to 3 dedicated workspaces.',
      'Professional services to build 3 custom Ai Workflows.',
    ],
  },

  {
    index: 3,
    name: 'Organization',
    title:
      'Organization-level Ai systems, automation, chat-bots, advanced security features, and admin control across teams.',
    price: null,
    activePlan: false,
    discounts: ['$0.00125 (58% discount)'],
    tokensText: '20,000 free monthly test Ai Tokens',
    descriptions: [
      'All features in Professional.',
      'Supports up to 15 for advanced collaboration.',
      'Admin Controls for advance oversight and Org management.',
      'Capability to add Custom Models tailored to your business needs.',
      'Professional services to build 5 custom Ai Workflows.',
    ],
  },

  {
    index: 4,
    name: 'Enterprise',
    title:
      'Enterprise-scale security, speed, customized Ai models, dedicated support, private hosting, and advanced training for complex automation needs.',
    price: null,
    contactSales: true,
    descriptions: [
      'All features in Organization Plan.',
      'Unlimited users and department features for ultimate scalability and collaboration.',
      'Private LLM hosting & training for secure and personalized experiences.',
      'Dedicated support ensuring top-tier service response times and assistance.',
      'Professional services to build 10 custom Ai Workflows.',
    ],
  },
];
