import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuthStore } from "../../../store/storeAuth";
import { LocalStorageService } from "../../../service/LocalStorageService";
import { UserService } from "../../../service/UserService";

const Tapfiliate = () => {
  const authUser = useAuthStore((state) => state.user);
  const isLoggedIn = LocalStorageService.getItem("accessToken");
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if (isLoggedIn) {
      if (authUser?.id && authUser?.tapfiliateReferralCode) {
        navigate("/");
      }

      if (authUser?.id && !authUser?.tapfiliateReferralCode) {
        const updateRefCode = async () => {
          try {
            await UserService.updateUser({
              tapfiliateReferralCode: location.search?.replace("?ref=", ""),
            });
          } catch (error) {}
        };

        updateRefCode();
      }
    } else {
      navigate("/register" + location.search);
    }
  }, [location.search, authUser?.id]);

  return <></>;
};

export default Tapfiliate;
