import '../index.css';

import { Box, Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Connection,
  Edge,
  Node,
  ReactFlowProvider,
  addEdge,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useViewport,
} from 'reactflow';
import { ReactComponent as PremiumToolIcon } from '../assets/icons/PremiumToolIcon.svg';
import { coreEdgeStyle } from '../components/edges/coreEdgeStyleDef';
import ModalComponent from '../components/Modals';
import PopupWithVideo from '../components/PopupWithVideo/PopupWithVideo';
import ReactFlowBody from '../components/ReactFlow/ReactFlowBody';
import RemainingTokens from '../components/RemainingTokens/RemainingTokens';
import SavingStatus from '../components/SavingStatus/SavingStatus';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import PaywallModal from '../components/UI/PaywallModal';
import { FlowService } from '../service/FlowService';
import { useAuthStore } from '../store/storeAuth';
import useStore from '../store/storeFlows';
import { useNodesStore } from '../store/storeNodesEdeges';
import { useNotificationStore } from '../store/storeNotifications';
import { nodeDataAutoSaveDynamic } from '../util/autosave/nodedata_autosave';
import { checkHandleConnectable } from '../util/checkHandlesConnectable';
import { getNodePositionInsideParent } from '../util/getNodePositionInsideParent';
import {
  isNodeOverlapping,
  onAddNodeFc,
  onConnectFc,
} from '../util/myFunctionsCoreDisplay';
import { getNextPlanAtLeastProfessional } from '../util/planUtil';
import { useDebounceEffect } from '../util/useDebounceEffect';
import Sidebar from './Sidebar';

import ContextMenu, { ContextMenuProps } from '../components/ContextMenu';

const CoreNodeElement = () => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const edgeUpdateSuccessful = useRef(true);
  const {
    setStateNodes,
    setEdgeState,
    setSchema,
    setSaving,
    setOnEdgeDelete,
    setFlowId,
    flowId: activeFlowId,
    spaceId,
    setSpaceId,
    setIsPublic,
    setIsWebhook,
  } = useStore(state => state);

  const [isLoading, setIsLoading] = useState(false);
  const { configs } = useAuthStore(state => state);
  const [openPaywall, setOpenPaywall] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const setNotification = useNotificationStore(state => state.setNotification);

  const { setViewport, screenToFlowPosition } = useReactFlow();
  const navigate = useNavigate();
  const { deleteNode, setDeleteNode } = useNodesStore(state => state);
  const [shouldBackgroundClick, setShouldBackgroundClick] = useState(true);
  const { user } = useAuthStore(state => state);

  const searchParams = useSearchParams();
  const [showOnboardingVideo, setShowOnboardingVideo] = useState(
    searchParams[0].get('showVideo') === '1',
  );
  const { flowId } = useParams();
  const position = useViewport();

  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [type, setType] = useState<string>('paste');
  // const [type, setType] = useState<string>('paste');

  const [isModalOpen, setIsModalOPen] = useState(false);
  const nextPlanAtLeastProfessional = useMemo(
    () => getNextPlanAtLeastProfessional(user?.plan || null),
    [user?.plan],
  );
  const [updateNodeEdge, setUpdateNodeEdge] = useState<{
    type: string | null;
    id: string | null;
    data: {
      x: number;
      y: number;
    } | null;
    initial: boolean;
  }>({
    type: null,
    id: null,
    data: null,
    initial: true,
  });
  const [menu, setMenu] = useState<Partial<ContextMenuProps>>({});

  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
    setShouldBackgroundClick(false);
  }, []);

  const onEdgeDelete = useCallback(
    (edge: Edge[], id: string) => {
      const deleteEdge = () => {
        try {
          const deleteEdgesWithDelay = async (edgeArray: Edge[]) => {
            for (const edg of edgeArray) {
              // Make a request to delete the edge
              await new Promise(resolve => setTimeout(resolve, 500)); // Wait for 500ms
              await FlowService.deleteEdge({ flowId: flowId!, edgeId: edg.id });
              setEdges(eds => eds.filter(e => e.id !== edg.id));
            }
          };

          deleteEdgesWithDelay(edge);

          return true;
        } catch (error) {
          setNotification({
            type: 'error',
            message: 'Failed to delete edge please try again latter',
          });
          return false;
        }
      };

      const deleted = deleteEdge();

      if (!deleted) return;

      nodeDataAutoSaveDynamic({
        setNodeState: setNodes,
        id: edge?.[0].source,
        flowId,
        changeType: 'delete-edges',
        setSchema,
        setSaving,
        newEdges: edges,
        deleteEdges: edge,
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
      });
    },
    [setEdges, setNodes, user],
  );

  useEffect(() => {
    const giveStates = () => {
      if (setNodes && setEdges) {
        setStateNodes(setNodes);
        setEdgeState(setEdges);
        setOnEdgeDelete(onEdgeDelete as any);
      }
    };

    giveStates();
  }, []);

  const params = useParams();

  useEffect(() => {
    const getFlow = async () => {
      setIsLoading(true);
      try {
        const response = await FlowService.getFlow(params.flowId!);

        const edges = response?.data?.edges;
        setFlowId(params.flowId!);

        const filterDuplicates = (arr: any[]) => {
          return arr.filter(
            (item, index, self) =>
              index ===
              self.findIndex(
                t =>
                  t.source === item.source &&
                  t.sourceHandle === item.sourceHandle &&
                  t.target === item.target &&
                  t.targetHandle === item.targetHandle,
              ),
          );
        };

        const filteredEdges = filterDuplicates(edges);

        if (edges?.length !== filteredEdges?.length) {
          await FlowService.updateFlow(params.flowId!, {
            edges: filteredEdges,
          });
        }

        setSpaceId(response?.data?.spaceId);
        setIsPublic(
          response?.data?.public === null ? false : response?.data?.public,
        );

        setIsWebhook(response?.data?.webhook);
        const nodes = response.data.nodes?.filter(
          (nd: any) =>
            nd?.type !== 'webNews' &&
            nd?.type !== 'webLocation' &&
            nd?.type !== 'webMedia',
        );

        setEdges(filterDuplicates(edges));
        setNodes(nodes);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.id && !isLoading && params.flowId) {
      if (params.flowId !== activeFlowId || !activeFlowId) {
        setIsLoading(true);
        setTimeout(() => {
          getFlow();
        }, 1000);
      }
    }
  }, [params.flowId, activeFlowId, user?.id, isLoading, spaceId]);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      let newEdges: Edge[] = [];
      setEdges(els => {
        newEdges = [...updateEdge(oldEdge, newConnection, els)];
        return updateEdge(oldEdge, newConnection, els);
      });

      nodeDataAutoSaveDynamic({
        setNodeState: setNodes,
        flowId,
        changeType: 'update-edge',
        setSchema,
        setSaving,
        newEdges,
        updateEdge: {
          oldEdge,
          newConnection,
        },
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
      });

      const onUpdateEdge = async () => {
        try {
          await FlowService.updateEdge(flowId!, {
            id: oldEdge.id,
            ...newConnection,
          });

          return true;
        } catch (error) {
          setNotification({
            type: 'error',
            message: 'Failed to update edge please try again latter',
          });
          return false;
        }
      };

      edgeUpdateSuccessful.current = true;
      onUpdateEdge();
    },
    [edges, nodes, setEdges, setNodes],
  );
  const onEdgeUpdateEnd = useCallback(
    (_: MouseEvent | TouchEvent, edge: { id: string }) => {
      setTimeout(() => {
        setShouldBackgroundClick(true);
      }, 700);

      if (!edgeUpdateSuccessful.current) {
        const deleteEdge = async () => {
          try {
            await FlowService.deleteEdge({ flowId: flowId!, edgeId: edge.id });

            return true;
          } catch (error) {
            setNotification({
              type: 'error',
              message: 'Failed to delete edge please try again latter',
            });
            return false;
          }
        };

        deleteEdge();

        nodeDataAutoSaveDynamic({
          setNodeState: setNodes,
          flowId,
          changeType: 'delete-edges',
          setSchema,
          setSaving,
          newEdges: edges,
          deleteEdges: [edge],
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],
        });

        edgeUpdateSuccessful.current = true;

        setEdges(eds => eds.filter(e => e.id !== edge.id));
      }
    },
    [setEdges, setNodes, edgeUpdateSuccessful.current, edges],
  );

  const excludedIdPrefix = 'flow-global-starter';

  const onConnect = useCallback(
    (params: {
      target: string | string[];
      source: string | string[];
      sourceHandle: string;
      targetHandle: string;
    }) => {
      setShouldBackgroundClick(false);
      onConnectFc(
        setDeleteNode,
        params,
        setSchema,
        setEdges,
        edges,
        addEdge,
        coreEdgeStyle,
        setNodes,
        nodes,
        flowId!,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
        setSaving,
        setNotification,
      );
    },
    [edges, nodes, setEdges, setNodes, user],
  );

  const onAddNode = useCallback(
    (type: {
      type: string;
      label: {
        label: string;
        color: string;
      };
      myType: string;
      title: string;
      position: { x: number; y: number };
      extent: string | undefined;
      parentNode: string | undefined;
      data?: any;
    }) => {
      onAddNodeFc(
        type,
        setNodes,
        setViewport,
        setEdges,
        position,
        configs,
        params.flowId!,
        setNotification,
      );
    },
    [setNodes, setViewport, position, configs, params.flowId],
  );

  const onGenerateApi = useMemo(() => {
    const getAllVarInputNodes = () => {
      const varInputNodes =
        nodes?.filter(node => node?.type === 'varInputNode') ?? [];

      if (!varInputNodes?.length) {
        return 'No var input nodes found';
      }

      return JSON.stringify(
        varInputNodes.map((node, idx) => ({
          inputId: node?.id,
          inputText: `Example  ${idx + 1} Text For Input `,
        })),
      );
    };

    return getAllVarInputNodes() ?? 'No var input nodes found';
  }, [nodes]);

  useEffect(() => {
    if (!shouldBackgroundClick) {
      setTimeout(() => {
        setShouldBackgroundClick(true);
      }, 1100);
    }
  }, [shouldBackgroundClick]);

  useDebounceEffect(
    () => {
      if (deleteNode.id) {
        const nodesGroup: any = nodes.find(node => node.id === deleteNode.id);
        const onDeleteNode = () => {
          if (nodesGroup?.id === deleteNode.id) {
            setNodes(nds =>
              nds.filter(node => node.parentNode !== nodesGroup.id),
            );
          }

          nodeDataAutoSaveDynamic({
            setNodeState: setNodes,
            id: deleteNode.id,
            changeType: 'delete-edges',
            setSchema,
            setSaving,
            newEdges: edges,
            deleteEdges: edges.filter(
              edge =>
                edge.source === deleteNode.id || edge.target === deleteNode.id,
            ),
            objectCallerData: [
              ...(user?.spaceObjects || []),
              ...(user?.objects || []),
            ],
          });

          setNodes(nds => nds.filter(node => node.id !== deleteNode.id));
          setEdges(eds =>
            eds.filter(
              edge =>
                edge.source !== deleteNode.id && edge.target !== deleteNode.id,
            ),
          );

          const deleteNodeRes = async () => {
            try {
              await FlowService.deleteNode({
                flowId: flowId!,
                nodeId: deleteNode.id!,
              });
            } catch (error) {
              setNotification({
                message: 'Failed to delete node try again',
                type: 'error',
              });
            }
          };

          deleteNodeRes();
        };
        if (nodesGroup?.data?.isEmptyState) {
          onDeleteNode();
        } else {
          const result = window.confirm(
            'Are you sure you want to delete this node?',
          );

          if (result === true) {
            onDeleteNode();
          }
        }
        setDeleteNode(null);
      }
    },
    [deleteNode?.id, setEdges, setNodes],
    0,
  );

  useDebounceEffect(
    () => {
      const check = updateNodeEdge.data;
      if (
        updateNodeEdge.type &&
        updateNodeEdge.id &&
        check &&
        updateNodeEdge.initial === false
      ) {
        const updateNode = async () => {
          const saving = {
            finished: false,
            lastSaved: new Date().toISOString(),
            status: 'loading',
            flowId: flowId,
          };

          setSaving(saving);
          try {
            let findNode = {
              ...nodes.find(node => node.id === updateNodeEdge.id),
            };

            if (findNode.data) {
              delete findNode.data;
            }

            await FlowService.updateNode(flowId!, {
              id: updateNodeEdge.id,
              ...findNode,
            });

            const saving = {
              finished: true,
              lastSaved: new Date().toISOString(),
              status: 'saved',
              flowId: flowId,
            };
            setSaving(saving);
            setUpdateNodeEdge({
              type: null,
              id: null,
              data: null,
              initial: false,
            });
          } catch (error) {
            setNotification({
              type: 'error',
              message: 'Failed to update the node',
            });

            const saving = {
              finished: false,
              lastSaved: new Date().toISOString(),
              status: 'error',
              flowId: flowId,
            };

            setSaving(saving);
          }
        };

        updateNode();
      }
    },
    [updateNodeEdge],
    300,
  );

  const isValidConnection = useCallback(
    (params: Connection) => {
      return checkHandleConnectable(params, edges);
    },
    [nodes, edges],
  );

  const onDragOver = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { dropEffect: string };
    }) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    },
    [],
  );

  const onDrop = (event: {
    preventDefault: () => void;
    dataTransfer: { getData: (arg0: string) => any };
    clientX: number;
    clientY: number;
  }) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');

    if (!type) return;
    if (
      type.includes('apiCaller') &&
      (user?.plan === 'Intro' ||
        user?.plan === 'Starter' ||
        user?.plan === 'Advanced')
    ) {
      setOpenPaywall(true);
    } else {
      const parseType = JSON?.parse(type);
      const position = reactFlowInstance?.project({
        x: event.clientX - (reactFlowBounds?.left || 0),
        y: event.clientY - (reactFlowBounds?.top || 0),
      });

      const intersections = reactFlowInstance
        ?.getIntersectingNodes({
          x: position?.x,
          y: position?.y,
          width: 40,
          height: 40,
        })
        .filter((n: { type: string }) => n.type === 'nodesGroup');

      const groupNode = intersections[0];
      const newType = {
        ...parseType,
        position,
      };

      if (groupNode) {
        newType.position = getNodePositionInsideParent(
          {
            position,
            width: 40,
            height: 40,
          },
          groupNode,
        ) ?? { x: 0, y: 0 };
        newType.parentNode = groupNode?.id;
        newType.extent = groupNode ? 'parent' : undefined;
      }
      onAddNode(newType);
    }
  };

  const onClickAddNode = useCallback(
    (data: { type: string; label: string; store: any }) => {
      if (
        data.type === 'apiCaller' &&
        (user?.plan === 'Intro' ||
          user?.plan === 'Starter' ||
          user?.plan === 'Advanced')
      ) {
        setOpenPaywall(true);
      } else {
        const {
          height,
          width,
          transform: [transformX, transformY, zoomLevel],
        } = data?.store;

        const zoomMultiplier = 1 / zoomLevel;

        const centerX =
          -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
        const centerY =
          -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;

        const nodeWidthOffset = 80 / 2;
        const nodeHeightOffset = 80 / 2;

        const type = {
          type: data.type,
          label: data.label,
          position: {
            x: centerX - nodeWidthOffset,
            y: centerY - nodeHeightOffset,
          },
        };

        onAddNode(type as any);
      }
    },
    [onAddNode, user?.plan, params.flowId, position.x, position.y],
  );

  const handleSelectedNodeChange = (node: any) => {
    setSelectedNode(node);
  };

  // console.log('selectedNode', selectedNode);

  const onContextMenu = (event: any) => {
    const target = event.target as HTMLElement;

    // role 'presentation' is used to prevent the default context menu from appearing'

    const isModal = target.getAttribute('role') === 'presentation';
    const isInputOrTextarea =
      target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
    if (isModal || isInputOrTextarea) {
      setMenu({});
      return;
    } else {
      event.preventDefault();

      const isNodeClicked = event.target.closest('.react-flow__node');

      if (!isNodeClicked && selectedNode?.id) {
        const position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        setMenu({
          id: selectedNode.id,
          top: position.y,
          left: position.x,
          type: 'paste',
        });
      }
    }
  };

  const onConnectBs = useCallback(
    (connection: Connection) => {
      setEdges(oldEdges => addEdge(connection, oldEdges));
    },
    [setEdges],
  );

  const handlePaste = useCallback(
    (event?: any) => {
      if (!event) {
        setNotification({
          message: 'Invalid event or missing clientX/clientY.',
          type: 'error',
        });

        return;
      }

      // console.log('selectedNode handlePaste', selectedNode);
      if (selectedNode) {
        // Check if the node ID starts with the excluded prefix
        if (selectedNode.id && selectedNode.id.startsWith(excludedIdPrefix)) {
          setNotification({
            message: 'You cannot copy or paste the global node.',
            type: 'info',
          });
          return;
        }
      }
      if (selectedNode && event) {
        const reactFlowBounds =
          reactFlowWrapper?.current?.getBoundingClientRect();

        const position = reactFlowInstance?.project({
          x: event.clientX - (reactFlowBounds?.left || 0),
          y: event.clientY - (reactFlowBounds?.top || 0),
        });
        const newNode = {
          id: `${selectedNode.id}-copy`,
          type: selectedNode.type,
          label: selectedNode.data.label,
          position: position,
          data: {
            ...selectedNode.data,
            text: null,
            previewResponses: null,
            promptData: null,
            previewIndex: 0,
          },
        };

        onAddNode(newNode as any);
      } else {
        setNotification({
          message:
            'Something went wrong with pasting the node. Please try again.',
          type: 'error',
        });
      }
      setMenu({});
    },
    [selectedNode, setNotification, screenToFlowPosition, onAddNode],
  );

  const onNodeDragStop = useCallback(
    (node: Node) => {
      if (node?.parentNode) return;

      const overlappingGroups = nodes.filter(
        n =>
          n.type === 'nodesGroup' && isNodeOverlapping(n as any, node as any),
      );
      if (overlappingGroups.length > 0 && node.type !== 'nodesGroup') {
        const groupNode = overlappingGroups[0];

        const updatedNodes = nodes.map(n => {
          if (n.id === node.id) {
            return {
              ...n,
              position: {
                x: node?.position?.x - groupNode?.position?.x,
                y: node?.position?.y - groupNode?.position?.y,
              },
              data: {
                ...n?.data,
                collapsed: groupNode.data.collapsed,
              },
              parentNode: groupNode.id,
              extent: 'parent',
            };
          }
          return n;
        });
        setNodes(updatedNodes as any);
      }
    },
    [reactFlowInstance?.getIntersectingNodes, setNodes, nodes],
  );

  const handleClick = () => {
    if (shouldBackgroundClick) {
      const baseFormEdges = edges?.map(edg => {
        return {
          ...edg,
          style: {
            ...edg.style,
            stroke: '#6e75ff',
            strokeWidth: 1,
          },
          type: edg.type === 'custom' ? 'custom' : '',
          markerEnd: coreEdgeStyle.markerEnd,
          data: {
            coloredEdge: false,
          },
        };
      });
      setEdges(baseFormEdges);
    }
    setShouldBackgroundClick(true);
  };

  return (
    <div className="dndflow" style={{ padding: '0' }}>
      {showOnboardingVideo && (
        <PopupWithVideo
          onClose={() => setShowOnboardingVideo(false)}
          videoUrl="https://youtu.be/UB8O7bIy8Oc"
        />
      )}

      <ReactFlowProvider>
        {isLoading && <LoadingSpinner height="100vh" />}
        {!isLoading && nodes?.length !== 0 && (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
            onContextMenu={onContextMenu}
            onClick={handleClick}
          >
            <ReactFlowBody
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              setReactFlowInstance={setReactFlowInstance}
              onConnect={onConnect}
              onEdgeDelete={onEdgeDelete}
              onEdgeUpdate={onEdgeUpdate}
              onEdgeUpdateStart={onEdgeUpdateStart}
              onEdgeUpdateEnd={onEdgeUpdateEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
              updateNodeEdge={updateNodeEdge}
              setUpdateNodeEdge={setUpdateNodeEdge}
              onNodeDragStop={onNodeDragStop}
              user={user}
              onAddNode={onAddNode}
              isValidConnection={isValidConnection}
              onNodeSelected={handleSelectedNodeChange}
              handlePasteNode={handlePaste}
              select={selectedNode}
            />
          </div>
        )}
        <Sidebar
          onClickAddNode={onClickAddNode}
          onGenerateApi={onGenerateApi}
          flowId={flowId!}
        />
      </ReactFlowProvider>

      {/* 
      <Button
        onClick={onSave}
        variant="contained"
        sx={{
          position: 'absolute',
          top: '-37px',
          right: '20px',
          zIndex: '999',
          py: '5px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {'Reconnect all nodes'}
        <CheckCircle color="white" size={20} />
      </Button>
        <CheckCircle color='white' size={20} />
      </Button>
*/}

      <ModalComponent
        open={isModalOpen}
        onClose={() => {
          setIsModalOPen(false);
          let url = new URL(window.location.href);
          window.history.pushState({}, '', url.pathname);
        }}
      />
      <PaywallModal
        onHide={() => setOpenPaywall(false)}
        fullWidth={true}
        show={openPaywall}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#3650DE',
                border: '1px solid #3650DE',
              }}
              onClick={() => {
                setOpenPaywall(false);
                setIsModalOPen(true);
                navigate('?pricing=true');
              }}
            >
              See All Plans
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#3650DE',
                color: '#FFFFFF',
                outline: '1px solid #3650DE',
              }}
              onClick={() => {
                setOpenPaywall(false);
                setIsModalOPen(true);
                navigate(
                  `?pricing=true&proplan=${nextPlanAtLeastProfessional}`,
                );
              }}
            >
              Upgrade Now
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <PremiumToolIcon />
            <Typography
              color="#101828"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '26px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
              }}
            >
              Premium Tool
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {/* <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              Premium Tool
            </Typography> */}
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              The API Caller is a premium tool. To access its functionality and
              more premium features, please upgrade your account.
            </Typography>
          </Box>
        </Box>
      </PaywallModal>
      {menu && (
        <ContextMenu
          id={menu.id as string}
          top={menu.top || 0}
          left={menu.left || 0}
          setMenu={setMenu}
          onCopy={() => console.log('Copying node')}
          onAddNode={onAddNode as any}
          onPaste={handlePaste}
          onDelete={() => console.log('Deleting node')}
          type={type}
          setType={setType}
        />
      )}

      <SavingStatus />
      <RemainingTokens />
    </div>
  );
};

export default memo(CoreNodeElement);
