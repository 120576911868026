import React, { useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
} from '@mui/material';
import SearchIcon from '../../assets/icons/Search.png';
import { Export } from '@phosphor-icons/react';
import moment from 'moment';

interface Props {
  flows: any[];
  setSelectedFlowId: React.Dispatch<React.SetStateAction<string | undefined>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  getFlows: () => Promise<void>;
}

const FlowsTable: React.FC<Props> = ({
  flows,
  setSelectedFlowId,
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  getFlows,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredFlows = useMemo(() => {
    return flows.filter(flow =>
      flow.creator?.toLowerCase().includes(searchValue),
    );
  }, [searchValue, flows]);

  const start = page * rowsPerPage;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const exportToCsv = (data: any[], fileName: string) => {
    const csvRows = [];

    const headers = [
      'Flow name',
      'Trigger Location',
      'Creator Name',
      'Space',
      'Creation Date',
      'Last Flow Run Date',
      'Flow Runs',
    ];

    csvRows.push(headers.join(','));

    data.forEach(row => {
      const values = [
        row.flowName,
        'Enhanced AI',
        row.creator,
        row.space,
        moment(row.creationDate).format('YYYY-MM-DD HH:mm:ss'),
        row.lastNodeRunDate
          ? moment(row.lastNodeRunDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
        row.nodeRuns,
        row.textTokens,
      ];
      csvRows.push(values.join(','));
    });

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          height: '64px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          borderBottom: '1px solid #D0D5DD',
          flexShrink: 0,
          mb: '10px',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
            pb: '10px',
          }}
        >
          Flow Management
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: '24px',
          paddingY: '15px',
        }}
      >
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '28.8px',
            letterspacing: '0.15px',
          }}
        >
          Flow Table View
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <FormControl sx={{ m: 1 }} variant="standard">
            <TextField
              variant="outlined"
              placeholder="Type the user’s name in here."
              onChange={handleInputChange}
              value={searchValue}
              sx={{
                width: '300px',
                padding: '4px, 12px, 4px, 12px',
                background: 'transparent',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  padding: '4px, 24px, 4px, 12px',
                  height: '34px',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search" />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Button
            variant="text"
            sx={{
              color: 'rgba(54, 80, 222, 1)',
              background: 'transparent',
              border: '1px solid rgba(54, 80, 222, 1)',
              height: '35px',
            }}
            endIcon={<Export color="rgba(54, 80, 222, 1)" />}
            onClick={() => exportToCsv(filteredFlows, 'flows.csv')}
          >
            Export Table
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: '96%', marginTop: '20px', paddingX: '24px' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table size={'medium'}>
              <TableHead>
                <TableRow>
                  <TableCell>Flow name</TableCell>
                  <TableCell align="center">Trigger Location</TableCell>
                  <TableCell align="center">Creator Name</TableCell>
                  <TableCell align="center">Space</TableCell>
                  <TableCell align="center">Creation Date</TableCell>
                  <TableCell align="center">Last Flow Run Date</TableCell>
                  <TableCell align="center">Flow Runs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFlows.slice(start, start + rowsPerPage).map(flow => {
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        setSelectedFlowId(flow.flowId);
                      }}
                      tabIndex={-1}
                      key={flow.flowId}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell
                        sx={{
                          // color: 'rgba(71, 84, 103, 1)',
                          // fontSize: '14px',
                          fontWeight: 400,
                          color: '#2C4DFF',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <Typography>{flow.flowName}</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '14px' }}
                      >
                        {`Enhanced AI`}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '14px' }}
                      >
                        {flow.creator}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flow.space}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {moment(flow.creationDate).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flow.lastNodeRunDate
                          ? moment(flow.lastNodeRunDate).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )
                          : ''}
                      </TableCell>
                      <TableCell
                        sx={{ color: 'rgba(71, 84, 103, 1)', fontSize: '12px' }}
                        align="center"
                      >
                        {flow.nodeRuns}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={filteredFlows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      '& .MuiInputBase-root': {
                        margin: '0 10px',
                        marginRight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default FlowsTable;
