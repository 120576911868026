import { TokenResponse } from '@react-oauth/google';
import { GoogleSpreadsheetWorksheet } from 'google-spreadsheet';
import { create } from 'zustand';

interface SpreadSheet {
  id: string;
  name: string;
}
enum GoogleService {
  GoogleSheets = 'Google Sheets',
  GoogleDocs = 'Google Docs',
}
type LoginSuccessTokenResponse = Omit<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;
type LoginErrorResponse = Pick<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;
export type Doc = {
  id: string;
  name: string;
};

export type Docs = Doc[];
type GoogleData = {
  googleRefreshToken: string | null;
  googleAccessToken: string | null;
  isSheetConnected: boolean;
  workSheets: GoogleSpreadsheetWorksheet[];
  spreadSheets: SpreadSheet[];
  isRefreshing: boolean;
  docs: Docs;
  title: string;
  workSheetsLoaded: boolean;
  setGoogleRefreshToken: (googleRefreshToken: string | null) => void;
  setGoogleAccessToken: (googleAccessToken: string | null) => void;
  setIsSheetConnected: (isSheetConnected: boolean) => void;
  setIsRefreshing: (isRefreshing: boolean) => void;

  loginSuccessHandler: (tokenResponse: LoginSuccessTokenResponse) => void;
  loginErrorHandler: (errorResponse: LoginErrorResponse) => void;
  setSpreadSheets: (spreadSheets: SpreadSheet[]) => void;
  setWorkSheets: (workSheets: GoogleSpreadsheetWorksheet[]) => void;

  setDocs: (docs: Docs) => void;
  setTitle: (title: string) => void;

  setWorkSheetsLoaded: (workSheetsLoaded: boolean) => void;
};

export const useStoreGoogleData = create<GoogleData>(set => ({
  googleRefreshToken: null,
  googleAccessToken: null,
  isSheetConnected: false,
  workSheets: [],
  spreadSheets: [],
  isRefreshing: false,
  docs: [],
  title: '',
  workSheetsLoaded: false,

  setGoogleRefreshToken: (googleRefreshToken: string | null) =>
    set(() => {
      return { googleRefreshToken };
    }),
  setGoogleAccessToken: (googleAccessToken: string | null) =>
    set(() => {
      return { googleAccessToken };
    }),
  setIsSheetConnected: (isSheetConnected: boolean) =>
    set(() => {
      return { isSheetConnected };
    }),
  setIsRefreshing: (isRefreshing: boolean) =>
    set(() => {
      return { isRefreshing };
    }),

  loginSuccessHandler: (tokenResponse: LoginSuccessTokenResponse) => {
    return set(() => {
      return {
        isSheetConnected: true,
        googleAccessToken: tokenResponse?.access_token,
        workSheets: [],
      };
    });
  },
  loginErrorHandler: (errorResponse: LoginErrorResponse) => {
    let errorMessage = 'Something went wrong!';
    if (errorResponse.error_description) {
      errorMessage = errorResponse.error_description;
    }

    alert(errorMessage);
  },
  setSpreadSheets: (spreadSheets: SpreadSheet[]) => {
    return set(() => {
      return {
        spreadSheets,
      };
    });
  },
  setWorkSheets: (workSheets: GoogleSpreadsheetWorksheet[]) => {
    return set(() => {
      return {
        workSheets,
      };
    });
  },
  setDocs: (docs: Docs) => {
    return set(() => {
      return {
        docs,
      };
    });
  },
  setTitle: (title: string) => {
    return set(() => {
      return {
        title,
      };
    });
  },
  setWorkSheetsLoaded: (workSheetsLoaded: boolean) => {
    return set(() => {
      return {
        workSheetsLoaded,
      };
    });
  },
}));
