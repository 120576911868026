export const checkHandleConnectable = (params: any, edges: any[]) => {
  const { source, target, sourceHandle, targetHandle } = params;

  const foundEdge = edges?.find(
    edge =>
      edge?.source === source &&
      edge?.sourceHandle === sourceHandle &&
      edge?.target === target &&
      edge?.targetHandle === targetHandle,
  );

  if (foundEdge) {
    return false;
  }

  if (source.includes('dynamicSettingsNode')) {
    if (
      target.includes('commandTextNode') &&
      targetHandle === 'modelSettings'
    ) {
      return true;
    }
    if (
      target.includes('voiceBoxNode') &&
      targetHandle === 'modelVoiceSettings'
    ) {
      return true;
    }
  }

  if (
    source.includes('textBox') ||
    source.includes('outputController') ||
    source.includes('varInputNode') ||
    source.includes('richTextBox') ||
    source.includes('output-controller') ||
    source.includes('nodesBox') ||
    source.includes('fluxObject') ||
    source.includes('voiceBoxNode') ||
    source.includes('arrayNode') ||
    source.includes('webSearch') ||
    source.includes('webMedia') ||
    source.includes('webLocations') ||
    source.includes('webNews') ||
    source.includes('commandContentNode') ||
    source.includes('commandTextNode') ||
    source.includes('command-text-node') ||
    source.includes('imageGenerator') ||
    source.includes('textCompressorNode') ||
    source.includes('outputObjectNode') ||
    source.includes('counterNode') ||
    source.includes('deepRefNode') ||
    source.includes('mathFunction') ||
    source.includes('scripting') ||
    source.includes('tableProcessor') ||
    source.includes('audioReaderNode') ||
    source.includes('commandMusicNode') ||
    source.includes('outputTable') ||
    source.includes('imageReaderNode') ||
    source.includes('webCrawling') ||
    source.includes('webTrends') ||
    source.includes('animationNode') ||
    source.includes('usCensusNode') ||
    source.includes('secApiNode') ||
    source.includes('documentSummarizer') ||
    source.includes('webAmazon') ||
    source.includes('apiCaller') ||
    source.includes('sqlQuerier') ||
    source.includes('fluxBox') ||
    source?.includes('storedDataCaller') ||
    source?.includes('pullData') ||
    source?.includes('pushData') ||
    source?.includes('fileSave') ||
    source?.includes('hubSpotCRM')
  ) {
    if (sourceHandle !== 'outputFlow') {
      if (
        target.includes('commandTextNode') ||
        target.includes('command-text-node') ||
        target.includes('voiceBoxNode') ||
        target.includes('imageGenerator') ||
        target.includes('arrayNode') ||
        target.includes('webSearch') ||
        target.includes('webMedia') ||
        target.includes('conditionNode') ||
        target.includes('webLocations') ||
        target.includes('counterNode') ||
        target.includes('outputTable') ||
        target.includes('mathFunction') ||
        target.includes('scripting') ||
        target.includes('imageReaderNode') ||
        target.includes('webCrawling') ||
        target.includes('webTrends') ||
        target.includes('animationNode') ||
        target.includes('usCensusNode') ||
        target.includes('secApiNode') ||
        target.includes('documentSummarizer') ||
        target.includes('webAmazon') ||
        target.includes('webNews') ||
        target.includes('tableProcessor') ||
        target.includes('audioReaderNode') ||
        target.includes('commandMusicNode') ||
        target.includes('nodesBox') ||
        target.includes('apiCaller') ||
        target.includes('sqlQuerier') ||
        target.includes('fluxBox') ||
        target.includes('pullData') ||
        target.includes('pushData') ||
        target.includes('fileSave') ||
        target.includes('hubSpotCRM')
      ) {
        if (targetHandle === 'input') {
          return false;
        } else {
          return true;
        }
      }

      if (
        target.includes('textBox') ||
        target.includes('richTextBox') ||
        target.includes('outputController') ||
        target.includes('output-controller') ||
        target.includes('voiceBoxNode') ||
        target.includes('outputObjectNode') ||
        target.includes('fluxObject') ||
        target.includes('textCompressorNod') ||
        target.includes('fluxBox') ||
        target.includes('arrayNode') ||
        target.includes('webSearch') ||
        target.includes('webMedia') ||
        target.includes('webLocations') ||
        target.includes('webNews') ||
        target.includes('mathFunction') ||
        target.includes('scripting') ||
        target.includes('commandContentNode') ||
        target.includes('tableProcessor') ||
        target.includes('audioReaderNode') ||
        target.includes('commandMusicNode') ||
        target.includes('webCrawling') ||
        target.includes('outputTable') ||
        target.includes('audioReaderNode') ||
        target.includes('nodesBox') ||
        target.includes('webTrends') ||
        target.includes('animationNode') ||
        target.includes('usCensusNode') ||
        target.includes('imageReaderNode') ||
        target.includes('dynamicSettingsNode') ||
        target.includes('secApiNode') ||
        target.includes('documentSummarizer') ||
        target.includes('webAmazon') ||
        target.includes('apiCaller') ||
        target.includes('sqlQuerier') ||
        target.includes('pullData') ||
        target.includes('pushData') ||
        target.includes('fileSave') ||
        target.includes('hubSpotCRM') ||
        target?.includes('storedDataCaller')
      ) {
        return true;
      }
    }
  }

  if (
    sourceHandle?.includes('conditionHandle') &&
    targetHandle === 'input' &&
    !target.includes('outputObjectNode') &&
    !target.includes('richTextBox')
  ) {
    return true;
  }

  if (
    sourceHandle?.includes('outputHandle') &&
    targetHandle === 'input' &&
    !target.includes('outputObjectNode') &&
    !target.includes('richTextBox')
  ) {
    return true;
  }

  if (
    targetHandle === 'input' &&
    sourceHandle === 'outputFlow' &&
    !target.includes('outputObjectNode') &&
    !target.includes('richTextBox')
  ) {
    return true;
  }

  if (targetHandle === 'input' && sourceHandle === 'a') {
    return true;
  }
};
