import React from 'react';
import KitModal from '../UI/KitModal';
import { Box, Button, Typography } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';

interface Props {
  onClose: () => void;
}

const UsersAddedModal: React.FC<Props> = ({ onClose }) => {
  return (
    <KitModal show={true} onHide={onClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '40px 60px',
          paddingBottom: '20px',
          minWidth: '30vw',
        }}
      >
        <CheckCircle color="rgba(103, 187, 106, 1)" weight="fill" size={80} />

        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            fontWeight: 500,
            lineHeight: '28px',
            margin: '10px 0',
          }}
        >
          Success!
        </Typography>
        <Typography
          sx={{
            color: 'rgba(71, 84, 103, 1)',
            lineHeight: '28px',
            width: '100%',
            textAlign: 'center',

            marginBottom: '20px',
          }}
        >
          Users have been added to your space.
        </Typography>

        <Button
          type="button"
          variant="contained"
          fullWidth
          sx={{ width: '100%' }}
          onClick={onClose}
        >
          Continue
        </Button>
      </Box>
    </KitModal>
  );
};

export default UsersAddedModal;
