import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';

import { SummarizeService } from '../../service/SummarizeService';
import { Box, FormLabel, TextField } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import UploadBox from '../UI/UploadBox/UploadBox';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';

function AiTextSummarizer({ data, isConnectable, type, id }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();
  const {
    setSchema,
    flowId,
    setSocketNodeResponse,
    setSaving,
    socketNodeResponse,
  } = useFlowsStore(state => state);

  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const [nodeLoading, setNodeLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [executeCounter, setExecuteCounter] = useState(0);
  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [formData, setFormData] = useState<{
    numberOfTopics: number;
    wordsForTopic: number;
    file: FormData | any;
    inputPreview?: string;
    uploadImage?: File | null;
  }>({
    numberOfTopics: 1,
    wordsForTopic: 10,
    file: '',
  });

  const [hasToExecute, setHasToExecute] = useState(true);

  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          ...formData,
          numberOfTopics: data.numberOfTopics ?? formData.numberOfTopics,
          wordsForTopic: data.wordsForTopic ?? formData.wordsForTopic,
          file: formData.file,
        });
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onSubmit = async () => {
    try {
      setNodeLoading(true);
      const file = formData.uploadImage;
      const formDataFile = new FormData();

      formDataFile.append('file', file!);

      const res = await SummarizeService.summarize({
        numberOfTopics: formData.numberOfTopics,
        wordsForTopic: formData.wordsForTopic,
        file: formDataFile,
        flowId,
      });
      setFormData(prevState => ({
        ...prevState,
      }));

      setSocketNodeResponse(id, null);
      setExecuteCounter(0);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setSocketNodeResponse(null, null);
    } finally {
      setNodeLoading(false);
    }
  };

  useEffect(() => {
    if (
      socketNodeResponse?.nodeId === id &&
      socketNodeResponse?.finished === true
    ) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        setSaving,
        objectData: {
          text: data.text,
          previewResponses: data?.previewResponses,
          previewIndex: data?.previewIndex,
        },
        setSchema,
      });

      setHasToExecute(true);
      setNodeLoading(false);
      setSocketNodeResponse(null, null);
    }
  }, [socketNodeResponse?.nodeId, socketNodeResponse?.finished, data?.text]);

  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));

    setWhoIsChanging({
      name: evt.target.name,
      value: evt.target.value,
    });
    setIsChangingDirectly(true);
  }, []);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          setSaving,
          id,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });
        setWhoIsChanging({
          name: '',
          value: '',
        });
        setIsChangingDirectly(false);
      }
    },
    [isChangingDirectly, whoIsChanging],
    500,
  );

  const onChangePlacement = (name: string, value: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      changeType: 'changingPlacement',
      flowId,
      objectData: {
        [name]: value,
      },
      setSchema,
    });
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const onChangeUploadImage = (e: any) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setFormData({
        ...formData,
        uploadImage: file,
        inputPreview: URL.createObjectURL(file),
      });
    }
  };

  const deleteImage = () => {
    setFormData({
      ...formData,
      uploadImage: null,
      inputPreview: '',
    });

    if (formData.inputPreview) {
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        objectData: {
          inputPreview: '',
        },
        setSchema,
      });
    }
  };
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };
  const { user } = useAuthStore(state => state);

  return (
    <Box className={nodeLoading ? 'node' : ''}>
      <Node
        onSubmit={onSubmit}
        btnText="Summarize"
        handleRun={handleRun}
        isLoading={nodeLoading}
        id={id}
        type={type}
        data={data}
        edges={edges}
      >
        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
          >
            <>
              <UploadBox
                labelName="Choose File"
                value={formData.inputPreview}
                onChange={onChange}
                name="inputPreview"
                type={'document'}
                id={id}
                placement={data?.placement}
                onChangePlacement={onChangePlacement}
                onChangeUploadImage={onChangeUploadImage}
                findColor={findColor}
                uploadedFile={formData.uploadImage}
                // handleId='inputPreview'
                deleteImage={deleteImage}
              />

              <Box
                mt={'26px'}
                flexDirection={'column'}
                display={'flex'}
                gap={'4px'}
              >
                <FormLabel
                  htmlFor="numberOfTopics"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  Number of topics
                </FormLabel>
                <TextField
                  name="numberOfTopics"
                  onChange={onChange}
                  placeholder="How many topics would you like your summary to contain?"
                  value={formData.numberOfTopics}
                  disabled={data?.disabled}
                  className="nodrag mt-2"
                  InputProps={{
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  sx={{
                    borderRadius: '4px',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>

              <Box
                mt={'26px'}
                flexDirection={'column'}
                display={'flex'}
                gap={'4px'}
              >
                <FormLabel
                  htmlFor="wordsForTopic"
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  Words for topic
                </FormLabel>
                <TextField
                  name="wordsForTopic"
                  onChange={onChange}
                  placeholder="How many words for each topic?"
                  value={formData.wordsForTopic}
                  disabled={data?.disabled}
                  className="nodrag mt-2"
                  InputProps={{
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  sx={{
                    borderRadius: '4px',
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
            </>
          </Box>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              // previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data.text}
              activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              findColor={findColor}
              placement={data?.placement}
              labelName={'Output'}
              nodeLabel={data?.label}

              // onPreview={onPreview}
              // findColor={findColor}
              // onChangePlacement={onChangePlacement}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
}

export default memo(AiTextSummarizer);
