import {
  CardElement,
  useStripe,
  useElements,
  AddressElement,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { UserService } from '../../../service/UserService';

import { User } from '../../../util/Types/User';
import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';
import KitModal from '../../UI/KitModal';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import LoadingSpinner from '../../UI/LoadingSpinner';
import { useState } from 'react';
import { getAPIErrorMessage } from '../../../helpers/helpers';
type Props = {
  onHide: () => void;
  show: boolean;
  setNewMethod: (value: boolean) => void;
  setAuthUser: (value: User | null) => void;
  authUser: User;
  setIsLoading: (value: boolean) => void;
};

const CreatePaymentMethodModal = ({
  onHide,
  show,
  setNewMethod,
  setAuthUser,
  authUser,
  setIsLoading,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [modalLoading, setModalLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    readAndAgree: false,
    couponId: '',
  });

  const handleSubmit = async () => {
    setModalLoading(true);
    if (!formData.readAndAgree) {
      setIsLoading(false);
      setModalLoading(false);
      return;
    }

    if (!stripe || !elements) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
      setModalLoading(false);
      setIsLoading(false);

      return;
    }

    const card = elements.getElement(CardNumberElement);
    const ccv = elements.getElement(CardCvcElement);
    const expiry = elements.getElement(CardExpiryElement);

    if (!card || !ccv || !expiry) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });

      setModalLoading(false);
      setIsLoading(false);
      return;
    }

    const result = await stripe.createToken(card);

    if (result.error) {
      setNotification({
        type: 'error',
        message: result.error.message || 'Something went wrong',
      });

      setIsLoading(false);

      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: {
        token: result.token.id,
      },
      billing_details: {
        name: formData.name,
      },
    });

    if (error) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
      setModalLoading(false);
      setIsLoading(false);

      return;
    }

    try {
      await UserService.attachPaymentMethod(paymentMethod?.id!, {
        brand: paymentMethod?.card?.brand!,
        exp_month: paymentMethod?.card?.exp_month!,
        exp_year: paymentMethod?.card?.exp_year!,
        last4: paymentMethod?.card?.last4!,
        billing_details: {
          name: paymentMethod?.billing_details?.name!,
        },
      });

      await UserService.setDefaultPaymentMethod(paymentMethod?.id!);
      setIsLoading(true);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
      setModalLoading(false);

      setIsLoading(false);
      return;
    }

    setAuthUser({
      ...(authUser as User),
      defaultPaymentMethod: paymentMethod?.id!,
    });

    setFormData({
      name: '',
      couponId: '',
      readAndAgree: false,
    });

    setNewMethod(true);
    onHide();
    setModalLoading(false);

    setIsLoading(false);
  };

  // const handleSubmit = async (event: any) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     setNotification({
  //       type: 'error',
  //       message: 'Something went wrong',
  //     });

  //     return;
  //   }

  //   const response: any = await elements.getElement(AddressElement)?.getValue();
  //   if (response.complete === false) {
  //     return;
  //   }
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: elements.getElement(CardElement)!,
  //     billing_details: {
  //       name: response?.value.name,
  //       address: response.value.address,
  //       phone: response.value.phone,
  //     },
  //   });

  //   if (error) {
  //     setNotification({
  //       type: 'error',
  //       message: error.message || 'Something went wrong',
  //     });

  //     return;
  //   }

  //   await UserService.attachPaymentMethod(paymentMethod?.id!, {
  //     brand: paymentMethod?.card?.brand!,
  //     exp_month: paymentMethod?.card?.exp_month!,
  //     exp_year: paymentMethod?.card?.exp_year!,
  //     last4: paymentMethod?.card?.last4!,
  //     billing_details: {
  //       name: paymentMethod?.billing_details?.name!,
  //     },
  //   });
  //   await UserService.setDefaultPaymentMethod(paymentMethod?.id!);

  //   setAuthUser({
  //     ...authUser,
  //     defaultPaymentMethod: paymentMethod?.id!,
  //   });

  //   elements.getElement(AddressElement)!.clear();
  //   elements.getElement(CardElement)!.clear();

  //   setNewMethod(true);
  //   onHide();
  // };

  return (
    <KitModal
      show={show}
      onHide={onHide}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '520px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      // footer={
      //   <Box
      //     display={'flex'}
      //     justifyContent={'space-between'}
      //     alignItems={'center'}
      //     width={'100%'}
      //   >
      //     <Link target="_blank" to={'/legal/privacy-policy'}>
      //       Privacy Policy
      //     </Link>
      //     <Typography variant="h3">
      //       <StripeLogo style={{ color: '#3b5998' }} fontSize={30} /> Powered by
      //       Stripe
      //     </Typography>
      //   </Box>
      // }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ marginBottom: '10px' }}
            >
              Add Payment Method
            </Typography>
          </Box>

          <Box
            sx={{
              maxWidth: '500px',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <InputLabel>{'Card number'}</InputLabel>
              <CardNumberElement
                options={{
                  classes: {
                    base: 'base-class-element',
                  },
                  showIcon: true,
                }}
              />
            </Box>

            <Box sx={{ mt: '20px' }}>
              <TextField
                type="text"
                name="name"
                size="medium"
                label="Name on Card"
                value={formData.name}
                fullWidth
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                marginTop: '20px',
                flexWrap: 'wrap',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Box sx={{ width: { xs: '100%', sm: 'calc(50% - 10px)' } }}>
                <InputLabel>{'Expiration Date'}</InputLabel>
                <CardExpiryElement
                  className="base-class-element"
                  options={{
                    classes: {
                      base: 'base-class',
                    },
                    style: {
                      base: {
                        fontSize: '16px',
                        backgroundColor: 'white',
                        padding: '10px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: '100%', sm: 'calc(50% - 10px)' } }}>
                <InputLabel>{'CVC'}</InputLabel>
                <CardCvcElement
                  className="base-class-element"
                  options={{
                    classes: {
                      base: 'base-class',
                    },
                    style: {
                      base: {
                        fontSize: '16px',
                        backgroundColor: 'white',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            <Typography mt={'20px'} fontSize={'14px'} color={'#101828'}>
              Your personal data will be used to process your order, support
              your experience, and for other purposes described in our Privacy
              Policy.
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.readAndAgree}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      readAndAgree: e.target.checked,
                    })
                  }
                  name="readAndAgree"
                />
              } // Pass the field object to Checkbox
              label={
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: '#101828',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '2.2px',
                  }}
                >
                  I have read and agree to the{' '}
                  <span
                    style={{
                      color: '#3650DE',
                      cursor: 'pointer',
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      window.open(
                        window.location.origin + '/legal/privacy-policy',
                        '_blank',
                      );
                    }}
                  >
                    Privacy Policy
                  </span>{' '}
                  and
                  <span
                    style={{
                      color: '#3650DE',
                      cursor: 'pointer',
                      fontWeight: 600,
                    }}
                    onClick={() => {
                      window.open(
                        window.location.origin + '/legal/terms-of-service',
                        '_blank',
                      );
                    }}
                  >
                    {' '}
                    Terms of Service
                  </span>
                </Typography>
              }
              sx={{}}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              type="button"
              variant="outlined"
              sx={{ width: '48%', mt: '20px' }}
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={modalLoading}
              sx={{ width: '48%', mt: '20px', gap: '8px' }}
              onClick={handleSubmit}
            >
              {modalLoading ? (
                <LoadingSpinner height="16px" />
              ) : (
                <>
                  <Plus size={18} weight="bold" />
                  <Typography>Add</Typography>
                </>
              )}
            </Button>
          </Box>
        </Box>
        {/* )} */}
      </Box>
    </KitModal>
  );
};

export default CreatePaymentMethodModal;
