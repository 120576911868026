import { IGetAllResponse } from '../util/Types/Generic';
import { ApiService } from './ApiService';

export class CensusService extends ApiService {
  static pathName = '/census';

  static census = (body: {
    year: number;
    get: string;
    for: string;
    flowId?: string;
  }): Promise<IGetAllResponse<any>> => {
    return ApiService.get(
      `${this.pathName}/census-data?year=${body.year}&get=${body.get}&for=${body.for}&flowId=${body.flowId}`,
    );
  };
}
