import React from 'react';
import './SeeAllList.css';
import CarouselTemplateList from './CarouselTemplateList';
import { Icons } from './TemplateSystemModal';
import { templates } from './templateSystemData';
import NoTemplates from './NoTemplates';

const templatesPerPage = 2;

interface Props {
  templateCategories: string[];
  searchValue: string;
  closeTemplateSystemModal: () => void;
  seeAllHandler: (category: string) => void;
  showRecommended: boolean;
}

const SeeAllList: React.FC<Props> = ({
  templateCategories,
  searchValue,
  closeTemplateSystemModal,
  seeAllHandler,
  showRecommended,
}) => {
  let emptyResults = false;
  if (showRecommended) {
    emptyResults =
      templates.filter(
        ({ recommended, title }) =>
          recommended === true &&
          title.toLowerCase().includes(searchValue.toLowerCase())
      ).length === 0;
  } else {
    emptyResults =
      templates.filter(({ title }) =>
        title.toLowerCase().includes(searchValue.toLowerCase())
      ).length === 0;
  }

  return (
    <>
      {emptyResults && <NoTemplates />}

      <div>
        {templateCategories.map(templateCategory => {
          const templatesPerCategory = templates.filter(template => {
            if (showRecommended && !template.recommended) return false;

            return template.templateCategory.includes(templateCategory);
          });
          const filteredTemplates = templatesPerCategory.filter(({ title }) =>
            title.toLowerCase().includes(searchValue.toLowerCase())
          );

          if (filteredTemplates.length === 0) return null;

          return (
            <div style={{ width: '95%' }}>
              <CarouselTemplateList
                templateCategory={templateCategory}
                closeTemplateSystemModal={closeTemplateSystemModal}
                searchValue={searchValue}
                showRecommended={showRecommended}
                filteredTemplates={filteredTemplates}
                templatesPerPage={templatesPerPage}
                icon={Icons[templateCategory]}
                seeAllHandler={seeAllHandler}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SeeAllList;
