import React from 'react';
import './AuthCard.scss';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

type Props = {
  children: React.ReactNode;
  title?: string;
  className?: string;
};

const AuthCard = ({ children, title = 'Flux Prompt ', className }: Props) => {
  return (
    <Card className={`auth-card ${className ? className : ''} `}>
      <Box className='auth-card_header'>
        <Box className='auth-card_header--box' />
        <Typography className='auth-card_header--title'>{title}</Typography>
      </Box>
      <CardContent className='auth-card_body'>{children}</CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 1rem',
          marginTop: '1rem',
          marginBottom: '1rem',
          fontSize: '0.8rem',
        }}
      >
        <Link to={'/legal/terms-of-service'}>Terms of Service</Link>
        <Link to={'/legal/privacy-policy'}>Privacy Policy</Link>
      </CardActions>
    </Card>
  );
};

export default AuthCard;
