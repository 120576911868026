import React, { useEffect, useState } from 'react';
import AuthCard from '../../components/auth/AuthCard';
import BrandsLogin from '../../components/auth/BrandsLogin';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import KitInput from '../../components/UI/KitInput';
import EmailSvg from '../../assets/icons/Email.svg';
import { AuthService } from '../../service/AuthService';
import CheckEmail from '../../components/auth/CheckEmail';
import { useNotificationStore } from '../../store/storeNotifications';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';
import { LocalStorageService } from '../../service/LocalStorageService';
import { base64Decode } from '../../helpers/decodeToken';

type IFormInput = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  termsAndConditions: boolean;
};

const Register = () => {
  const navigate = useNavigate();
  const { setNotification } = useNotificationStore(state => state);

  const onNavigatePath = (path: string) => {
    navigate(`/${path}`);
  };

  const [completed, setCompleted] = useState(false);
  const [tokenData, setTokenData] = useState<{
    token: string;
    decoded: {
      email: string;
    };
  }>({
    token: '',
    decoded: {
      email: '',
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      setTokenData({
        token: token!,
        decoded: base64Decode(token!),
      });
      setValue('email', base64Decode(token!).email);
    }
  }, []);

  const { setAuthUser, setAccessToken } = useAuthStore(state => state);

  const onSubmitRegister = async (values: IFormInput) => {
    const terms = watch('termsAndConditions');

    if (!terms)
      return setError('termsAndConditions', {
        type: 'validate',
        message: 'You must agree to the terms and conditions',
      });

    try {
      const userData = await AuthService.register(values, tokenData.token);

      if (tokenData.token) {
        setAuthUser(userData.data);
        LocalStorageService.setItem('accessToken', userData.data.accessToken);
        setAccessToken(userData.data.accessToken);
        navigate('/');
      }

      setCompleted(true);
      setNotification({
        type: 'success',
        message: 'User registered successfully',
      });
    } catch (error: any) {
      if (error.message === 'User already exist!') {
        setError('email', {
          type: 'validate',
          message: 'Email already exists',
        });
      } else {
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      }
    }
  };

  return (
    <Box
      className=" login"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AuthCard>
        {!completed ? (
          <>
            <form className="login_form">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                className="login_form_info"
              >
                <h4 className="login_form_info--text">Register</h4>
                <Typography className="login_form_info--subtext">
                  Already User?
                  <br />
                  <span
                    role="button"
                    style={{
                      color: '#3C5A99',
                      cursor: 'pointer',
                    }}
                    onClick={() => onNavigatePath('')}
                  >
                    Login
                  </span>
                </Typography>
              </Box>
              <KitInput
                register={register}
                name="name"
                fullWidth={true}
                label="Name"
                sxStyle={{
                  marginBottom: '20px',
                  mt: '20px',
                }}
                validation={{
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                }}
                errorsStatus={errors}
              />

              <KitInput
                register={register}
                name="email"
                label="Email"
                sxStyle={{
                  marginBottom: '20px',
                }}
                type="email"
                fullWidth={true}
                disabled={tokenData?.decoded?.email ? true : false}
                validation={{
                  required: {
                    value: true,
                    message: 'email is required',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                }}
                errorsStatus={errors}
              />
              <div
                className={`d-flex gap-3 ${
                  errors?.password?.message ? 'flex-wrap' : ''
                } `}
              >
                <KitInput
                  name={'password'}
                  type="password"
                  register={register}
                  label="Password"
                  sxStyle={{
                    marginBottom: '20px',
                  }}
                  displayEnd
                  errorsStatus={errors}
                  validation={{
                    required: {
                      value: true,
                      message: 'password is required',
                    },
                    minLength: {
                      value: 8,
                      message: 'password must be at least 8 characters',
                    },

                    onChange: e => {
                      if (e.target?.value !== watch('confirmPassword')) {
                        setError('confirmPassword', {
                          type: 'validate',
                          message: 'The passwords do not match',
                        });
                      } else {
                        setError('confirmPassword', {
                          type: '',
                          message: '',
                        });
                      }
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{6,}$/,
                      message:
                        'password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&#)',
                    },
                  }}
                />

                <KitInput
                  name={'confirmPassword'}
                  type="password"
                  sxStyle={{
                    marginBottom: '20px',
                  }}
                  displayEnd={true}
                  label="Confirm Password"
                  register={register}
                  errorsStatus={errors}
                  validation={{
                    required: {
                      value: true,
                      message: 'Confirm Password is required',
                    },

                    onChange: e => {
                      if (e.target?.value !== watch('password')) {
                        setError('confirmPassword', {
                          type: 'validate',
                          message: 'The passwords do not match',
                        });
                      } else {
                        setError('confirmPassword', {
                          type: '',
                          message: '',
                        });
                      }
                    },
                    validate: value =>
                      value === watch('password') ||
                      'The passwords do not match',
                  }}
                />
              </div>

              <div className="">
                <Controller
                  name="termsAndConditions"
                  control={control}
                  rules={{
                    required: {
                      message: 'You must agree to the terms and conditions',
                      value: true,
                    },
                  }}
                  defaultValue={false} // Set the default value here
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          color={
                            errors?.termsAndConditions?.message
                              ? 'error'
                              : 'primary'
                          }
                        />
                      } // Pass the field object to Checkbox
                      label="I agree to the Terms and Conditions"
                      sx={{
                        color: errors?.termsAndConditions?.message
                          ? 'error.main'
                          : 'primary.main',
                      }}
                    />
                  )}
                />

                {errors?.termsAndConditions?.message && (
                  <Typography
                    sx={{
                      color: 'error.main',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {'Please accept our terms to register'}
                  </Typography>
                )}
              </div>
              <Button
                onClick={handleSubmit(onSubmitRegister)}
                fullWidth
                sx={{
                  mb: '20px',
                  mt: '20px',
                }}
                type="submit"
                variant="contained"
              >
                Register
              </Button>
            </form>
            <BrandsLogin type={'register'} text="or register with" />
          </>
        ) : (
          <CheckEmail
            bodyText="We have sent you an email with a link to verify your account. Please
          check your email and click on the link to verify your account."
            onNavigate={() => navigate('/')}
          />
        )}
      </AuthCard>
    </Box>
  );
};

export default Register;
