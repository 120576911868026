import { Box, Button, Typography } from '@mui/material';
import { Planet, Plus } from '@phosphor-icons/react';
import React, { useState } from 'react';
import CreateSpaceModal from './CreateSpaceModal';
import { useAuthStore } from '../../store/storeAuth';
import { UserPlan } from '../../util/Types/User';
import UpgradeModal from './UpgradeModal';
import KitModal from '../UI/KitModal';
import Pricing from '../settings/Pricing/Pricing';

interface Props {
  getCompanySpaces: () => Promise<void>;
}
const NoSpaces: React.FC<Props> = ({ getCompanySpaces }) => {
  const { user } = useAuthStore(state => state);

  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showPricing, setShowPricing] = useState(false);

  const proOrAbovePlan =
    user?.plan === UserPlan.Enterprise ||
    user?.plan === UserPlan.Organization ||
    user?.plan === UserPlan.Professional;

  const openCreateSpaceModal = () => {
    if (proOrAbovePlan) {
      setShowCreateSpaceModal(true);
    } else {
      setShowUpgradeModal(true);
    }
  };

  return (
    <>
      {showCreateSpaceModal && (
        <CreateSpaceModal
          onClose={() => setShowCreateSpaceModal(false)}
          getCompanySpaces={getCompanySpaces}
        />
      )}

      {showUpgradeModal && (
        <UpgradeModal
          onClose={() => setShowUpgradeModal(false)}
          showPricingHandler={() => setShowPricing(true)}
        />
      )}

      {showPricing && (
        <KitModal
          show={true}
          onHide={() => {
            setShowPricing(false);
            setShowUpgradeModal(false);
          }}
          maxWidth="xl"
          fullWidth
          sx={{
            '& .MuiPaper-root': {
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              height: '100vh',
            },
          }}
        >
          <Pricing />
        </KitModal>
      )}

      <Box>
        <Box
          sx={{
            display: 'flex',
            height: '64px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            borderBottom: '1px solid #D0D5DD',
            flexShrink: 0,
            mb: '10px',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              color: '#000',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '32px',
              margin: '24px',
              pb: '10px',
            }}
          >
            Spaces
          </Typography>
        </Box>
        <Box
          sx={{
            border: '1px dashed rgba(208, 213, 221, 1)',
            padding: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            marginTop: '20px',
            flexDirection: 'column',
            gap: '15px',
            mx: '24px',
            my: '15px',
          }}
        >
          <Planet color="rgba(102, 112, 133, 1)" size={30} />

          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: 'rgba(102, 112, 133, 1)',
            }}
          >
            You have not added a space to your account.
          </Typography>

          <Button
            startIcon={<Plus color="white" />}
            variant="outlined"
            sx={{
              background: 'rgba(54, 80, 222, 1)',
              color: 'white',
              fontWeight: 500,
              '&:hover': {
                background: 'rgba(54, 80, 222, 1)',
                color: 'white',
              },
            }}
            onClick={openCreateSpaceModal}
          >
            Create Space
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NoSpaces;
