import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class VoiceService extends ApiService {
  static pathName = '/voice';

  static generateVoice = (body: {
    text: string;
    voice: string;
    quality: string;
    outputFormat: string;
    speed: number;
    sampleRate: number;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static generateClonedVoice = (body: {
    voiceName: string;
    companyId?: string;
    file: FormData;
    flowId?: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/clone?voiceName=${body.voiceName}${
        body.companyId ? `&companyId=${body.companyId}` : ''
      }${body.flowId ? `&flowId=${body.flowId}` : ''}`,
      body.file,
    );
  };

  static getVoices = (): Promise<
    IGetAllResponse<{
      name: string;
      language: string;
    }>
  > => {
    return ApiService.get(`${this.pathName}`);
  };
}
