import React, { ReactNode, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import styled from 'styled-components';
import { XCircle } from '@phosphor-icons/react';

interface Props {
  onClose: () => void;
  modalTitle: string;
  footer: ReactNode;
  children: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  sx?: SxProps<Theme> | undefined;
  fullWidth?: boolean;
}

const BootstrapDialog = styled(Dialog)(({}) => ({
  '& .MuiDialogContent-root': {
    border: 0,
    p: 0,
    minWidth: '40vw',
  },
  '& .MuiDialogActions-root': {
    border: 0,
    p: 0,
  },
  '& .MuiDialogActions-root .MuiDialogContent-root MuiDialogContent-dividers css-1t4vnk2-MuiDialogContent-root':
    {
      border: 0,
      p: 0,
    },
}));

const SpacesModal: React.FC<Props> = ({
  modalTitle,
  footer,
  onClose,
  children,
  sx,
  maxWidth = 'lg',
  fullWidth = false,
}) => {
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth={maxWidth}
      sx={{
        ...sx,
      }}
      fullWidth={fullWidth}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {modalTitle}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 2,
          top: 2,
        }}
      >
        <XCircle color="rgba(102, 112, 133, 1)" />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </BootstrapDialog>
  );
};

export default SpacesModal;
