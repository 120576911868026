import React, { memo } from 'react';
import { BaseEdge, Position, getBezierPath } from 'reactflow';

function FlowGlobalEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: {
  sourceX: number;
  sourceY: number;
  sourcePosition?: Position;
  targetX: number;
  targetY: number;
  targetPosition?: Position;
  curvature?: number;
}) {
  const adjustedTargetY = targetY - 5;

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: adjustedTargetY,
    targetPosition,
  });

  const gradientId = 'gradientId';

  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1='0%' y1='0%' x2='100%' y2='0%'>
          <stop offset='0%' stopColor='white' />
          <stop offset='100%' stopColor='blue' />
        </linearGradient>
      </defs>
      <BaseEdge
        path={edgePath}
        style={{
          stroke: `url(#${gradientId})`,
          strokeDasharray: 'none',
        }}
      />
      {/* <BaseEdge
        path={edgePath2}
        markerEnd={markerEnd}
        style={{
          stroke: 'none',
          fill: 'none',
        }}
      /> */}
    </>
  );
}

export default memo(FlowGlobalEdge);
