import { ApiService } from './ApiService';
import { IGetAllResponse, IGetOneResponse } from '../util/Types/Generic';
import { Flow } from '../util/Types/Flow';
import { Folder } from '../util/Types/Folder';
// import { User } from '../Utilis/Types/User';
export class FolderService extends ApiService {
  static pathName = '/folder';

  static getFolders = (): Promise<IGetAllResponse<any>> => {
    // return ApiService.get(`${this.pathName}?page=1&pageSize=10000`);
    return ApiService.get(`${this.pathName}`);
  };

  static getOne = (id: string): Promise<IGetOneResponse<Folder>> => {
    return ApiService.get(`${this.pathName}/${id}`);
  };

  static createFolder = (body: {
    name: string;
    index?: number;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static createFolderOnSpace = (body: {
    name: string;
    index?: number;
    spaceId: string;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static createSubFolder = (
    folderId: any,
    nestedFolderId: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/add-folder?folderId=${folderId}&nestedFolderId=${nestedFolderId}`,
    );
  };

  static addFlow = (
    folderId: string,
    flowId: string,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.post(
      `${this.pathName}/add-flow?folderId=${folderId}&flowId=${flowId}`,
    );
  };

  static updateFolder = (
    id: string,
    body: any,
  ): Promise<IGetOneResponse<any>> => {
    return ApiService.patch(`${this.pathName}/${id}`, body);
  };

  static updateOrder = (body: any): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/update-order`, body);
  };

  static deleteFolder = (id: string) => {
    return ApiService.delete(`${this.pathName}/${id}`);
  };
}
