import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const Input = ({ data, input, setInputs }: any) => {
  const findInput = input?.find(
    (inp: any) => inp?.inputId === data?.fullData?.selectedFlow,
  );

  const [text, setText] = useState(findInput?.inputText || '');

  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    setText(value);

    setInputs((prev: any) =>
      prev?.map?.((inp: any) => {
        if (inp?.inputId === name) {
          return {
            ...inp,
            inputText: value,
          };
        }

        return inp;
      }),
    );
  };

  return (
    <Box
      sx={{
        padding: '16px',

        borderRadius: data?.fullData?.borderRadius || '0px',
        height: 'fit-content',
        bgcolor: data?.fullData?.bgColor || '#fff',
        ...data?.style,
      }}
    >
      {data?.label && (
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            color: '#475467',
            ...data?.fullData?.titleStyle,
          }}
          variant="h1"
          mb={'8px'}
        >
          {data?.label ? data.label : 'Title Component'}
        </Typography>
      )}
      {data?.subtitle && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#98A2B3',
            ...data?.fullData?.subtitleStyle,
          }}
          mb={'8px'}
          variant="h1"
        >
          {data?.subtitle ? data.subtitle : 'Title Component'}
        </Typography>
      )}
      <TextField
        fullWidth
        disabled={!findInput?.inputId}
        name={findInput?.inputId}
        multiline
        maxRows={10}
        value={text}
        onChange={onInputChange}
      />
    </Box>
  );
};

export default Input;
