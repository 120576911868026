/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PullGoogleSheetsData from '../GoogleSheetsIntegration/PullGoogleSheetsData';
import PullGoogleDocsData from '../GoogleDocsIntegration/PullGoogleDocsData';
import { Box, Button, MenuItem, Select, Tooltip } from '@mui/material';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { useEdges } from 'reactflow';
import { isActiveEdge } from '../../util/findActiveHandle';
import NoConnection from './NoConnection/NoConnection';
import Label from '../UI/Label/Label';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { Info } from '@phosphor-icons/react/dist/ssr';
import { useAuthStore } from '../../store/storeAuth';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { FlowService } from '../../service/FlowService';
import { useStoreGoogleData } from '../../store/storeGoogleData';
import {
  getSpreadsheets,
  isGoogleSheetsAccessTokenValid,
} from '../GoogleSheetsIntegration/helpers';
import { AuthService } from '../../service/AuthService';
import {
  GoogleSpreadsheet,
  GoogleSpreadsheetWorksheet,
} from 'google-spreadsheet';
import {
  getDocContent,
  getDocContentFilteredByParagraphs,
  getDocs,
  getPublicDocContent,
} from '../GoogleDocsIntegration/helpers';

export enum GoogleService {
  GoogleSheets = 'Google Sheets',
  GoogleDocs = 'Google Docs',
}
export type Doc = {
  id: string;
  name: string;
};

interface SpreadSheet {
  id: string;
  name: string;
}

export enum PushDataOption {
  BEGINNING_OF_DOC = 'Beginning of document',
  END_OF_DOC = 'End of document',
  BEGINNING_OF_DOC_AS_A_NEW_PAGE = 'Start of document as a new page',
  END_OF_DOC_AS_A_NEW_PAGE = 'End of document as a new page',
  NEW_PAGE_AFTER_SPECIFIED_PAGE = 'After a specific page',
}

const PullData: React.FC<{ id: string; type: string; data: any }> = ({
  id,
  type,
  data,
}) => {
  const [selectedWorkSheet, setSelectedWorkSheet] = useState<
    undefined | GoogleSpreadsheetWorksheet
  >(undefined);
  const [selectedSpreadSheet, setSelectedSpreadSheet] = useState<
    undefined | SpreadSheet
  >(data?.spreadsheet ? { id: data?.spreadsheet, name: '' } : undefined);
  const [selectedDoc, setSelectedDoc] = useState<undefined | Doc>(
    data?.docs ? { id: data?.docs, name: '' } : undefined,
  );
  const [title] = useState('');
  const [selectedPublicGoogleService, setSelectedPublicGoogleService] =
    useState(GoogleService.GoogleSheets);
  const [workSheetsLoaded, setWorkSheetsLoaded] = useState(false);
  const [publicRangeFilter, setPublicRangeFilter] = useState('');

  const [firstTime, setFirstTime] = useState(true);
  const [tokenFirstTime, setTokenFirstTime] = useState(true);

  const [spreadSheets, setSpreadSheets] = useState<any[]>([]);
  const [workSheets, setWorkSheets] = useState<any[]>([]);
  const [docs, setDocs] = useState<any[]>([]);
  const {
    // spreadSheets,
    isRefreshing,
    setIsRefreshing,
    googleAccessToken,
    googleRefreshToken,
    setGoogleAccessToken,
    setGoogleRefreshToken,
    setIsSheetConnected,
    // setSpreadSheets,
    // setWorkSheets,
    // workSheets,
    // docs,
    // setDocs,
    isSheetConnected,
  } = useStoreGoogleData();
  ///////// Sheet Context Actions //////////////////////////////////////////////////////////////////////////

  const disconnectHandlerGoogle = async () => {
    if (!googleAccessToken) {
      setNotification({
        type: NotificationType.Error,
        message: 'Session expired. Please login again.',
      });
      return;
    }

    await refreshAuthToken(googleRefreshToken as string);
  };

  const loadWorkSheets = useCallback(
    async (spreadSheetId: string) => {
      setWorkSheetsLoaded(true);
      try {
        const doc = new GoogleSpreadsheet(spreadSheetId, {
          token: googleAccessToken!,
        });

        await doc.loadInfo();

        const workSheets: GoogleSpreadsheetWorksheet[] = doc.sheetsByIndex;
        setWorkSheets(workSheets);
        setWorkSheetsLoaded(true);
        return workSheets;
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          setNotification({
            type: NotificationType.Error,
            message: getAPIErrorMessage(error),
          });
        }
      } finally {
        setWorkSheetsLoaded(false);
      }
    },
    [googleAccessToken],
  );

  const refreshAuthToken = async (refreshToken: string) => {
    try {
      const newTokens = await AuthService.googleRefreshToken({
        refresh_token: refreshToken,
      });

      setGoogleAccessToken(newTokens.data.access_token);
      setGoogleRefreshToken(newTokens.data.refresh_token);
      setIsSheetConnected(true);

      // const docs = await getSpreadsheets(newTokens.data.access_token);
      // setSpreadSheets(docs);
    } catch (refreshError) {
      // setNotification({
      //   type: NotificationType.Error,
      //   message: 'Failed to refresh session, please login again.',
      // });
      // localStorage.removeItem('googleSheetsAccessToken');
      // localStorage.removeItem('googleRefreshToken');
      // setGoogleToken(null);
    }
  };

  const getSpreadSheetsHandler = useCallback(async () => {
    if (!googleAccessToken) {
      return disconnectHandlerGoogle();
    }

    try {
      const spreadSheets = await getSpreadsheets(googleAccessToken);
      setSpreadSheets(spreadSheets);

      if (data?.spreadsheet && spreadSheets?.length) {
        initialSpreadSheetSelection({
          spreadsheet: data?.spreadsheet,
          sheets: data?.sheets,
          spreadSheets,
        });
      }
    } catch (error) {
      let anyError = error as any;

      if (anyError.response.status === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
  }, [googleAccessToken, data?.spreadsheet, data?.sheets]);

  const initialSpreadSheetSelection = async ({
    spreadsheet,
    sheets,
    spreadSheets,
  }: any) => {
    const selectedSpreadSheet = spreadSheets.find(
      ({ id }: any) => id === spreadsheet,
    );
    try {
      if (selectedSpreadSheet !== undefined && selectedSpreadSheet !== null) {
        setSelectedSpreadSheet(selectedSpreadSheet);
        const workSheets = await loadWorkSheets(spreadsheet);

        if (sheets !== undefined && sheets !== null) {
          await updateSelectedWorkSheet(sheets, workSheets || []);
        } else {
          setSelectedWorkSheet(undefined);
        }
      } else {
        setSelectedSpreadSheet(undefined);
        setSelectedWorkSheet(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshSpreadSheets = useCallback(async () => {
    setIsRefreshing(true);
    try {
      await getSpreadSheetsHandler();
      if (selectedSpreadSheet) {
        await loadWorkSheets(selectedSpreadSheet.id);
      }
    } catch (error) {
      let anyError = error as any;
      if (anyError.response.status === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
    setIsRefreshing(false);
  }, [selectedSpreadSheet]);

  const updateSelectedSpreadSheet = useCallback(
    (selectedSpreadSheetId: string) => {
      const selectedSpreadSheet = spreadSheets.find(
        ({ id }) => id === selectedSpreadSheetId,
      );

      if (selectedSpreadSheet) {
        setSelectedSpreadSheet(selectedSpreadSheet);
        loadWorkSheets(selectedSpreadSheetId);

        setSelectedWorkSheet(undefined);
      } else {
        setSelectedSpreadSheet(undefined);
        setSelectedWorkSheet(undefined);
      }
    },
    [spreadSheets],
  );

  const updateSelectedWorkSheet = useCallback(
    async (
      selectedWorkSheetId: number,
      newWorkSheets?: GoogleSpreadsheetWorksheet[],
    ) => {
      if (selectedWorkSheetId === -1) {
        setSelectedWorkSheet(undefined);
        setWorkSheets([]);

        return;
      }

      setWorkSheetsLoaded(true);
      try {
        const initalWorksheets = newWorkSheets?.length
          ? newWorkSheets
          : workSheets;

        const selectedWorkSheet = initalWorksheets.find(
          ({ sheetId }) => sheetId === selectedWorkSheetId,
        );

        if (selectedWorkSheet) {
          setSelectedWorkSheet(selectedWorkSheet);

          await selectedWorkSheet.loadCells();
        } else {
          setSelectedWorkSheet(undefined);
        }
      } catch (error) {
        let anyError = error as any;
        if (anyError.code === 401) {
          disconnectHandlerGoogle();
        } else {
          setNotification({
            type: NotificationType.Error,
            message: getAPIErrorMessage(error),
          });
        }
      } finally {
        setWorkSheetsLoaded(false);
      }
    },

    [workSheets],
  );

  const getWorkSheetData = useCallback(
    async (rangeFilter: string): Promise<string> => {
      if (!selectedWorkSheet) return 'You have not selected a worksheet';

      let startRow = 0;
      let endRow = selectedWorkSheet.rowCount - 1;
      let startCol = 0;
      let endCol = selectedWorkSheet.columnCount - 1;

      if (rangeFilter !== '') {
        let match;

        match = rangeFilter.match(/^([A-Z]+)(\d+):([A-Z]+)(\d+)$/);
        if (match) {
          const [, startColStr, startRowStr, endColStr, endRowStr] = match;
          startCol = startColStr.charCodeAt(0) - 65;
          startRow = parseInt(startRowStr, 10) - 1;
          endCol = endColStr.charCodeAt(0) - 65;
          endRow = parseInt(endRowStr, 10) - 1;
        } else {
          match = rangeFilter.match(/^([A-Z]+):([A-Z]+)$/);
          if (match) {
            const [, startColStr, endColStr] = match;
            startCol = startColStr.charCodeAt(0) - 65;
            endCol = endColStr.charCodeAt(0) - 65;
          } else {
            match = rangeFilter.match(/^(\d+):(\d+)$/);
            if (match) {
              const [, startRowStr, endRowStr] = match;
              startRow = parseInt(startRowStr, 10) - 1;
              endRow = parseInt(endRowStr, 10) - 1;
            } else {
              return 'Invalid range format. Please provide the range in the format B1:E5, A:A, or 1:1';
            }
          }
        }
      }

      await selectedWorkSheet.loadCells();

      let textData: string = '';

      for (let row = startRow; row <= endRow; row++) {
        let rowData: string[] = [];

        let numberOfColumnsWithValuePerRow = 0;
        for (let col = startCol; col <= endCol; col++) {
          const cell = selectedWorkSheet.getCell(row, col);

          const cellValue = cell.value === null ? '' : cell.value;

          if (cellValue !== '') {
            numberOfColumnsWithValuePerRow++;
          }
          rowData.push(cellValue as string);
        }

        if (numberOfColumnsWithValuePerRow !== 0) {
          textData += rowData.join(',') + '\n';
        }
      }

      return textData;
    },
    [selectedWorkSheet],
  );

  const updatePublicWorkSheets = async (publicSheetId: string) => {
    try {
      setWorkSheets([]);
      const doc = new GoogleSpreadsheet(publicSheetId, {
        apiKey: 'AIzaSyA5ruxonya48UJ6XuhmNcC7x82gmvESeLU',
      });

      await doc.loadInfo();

      const workSheets: GoogleSpreadsheetWorksheet[] = doc.sheetsByIndex;

      setWorkSheets(workSheets);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const loadSpreadSheetsInfo = useCallback(async () => {
    try {
      await Promise.all([getSpreadSheetsHandler()]);
    } catch (error) {
      console.log(error);

      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error),
      });
    }
  }, []);

  // useEffect(() => {
  //   if (googleAccessToken) {
  //     setGoogleAccessToken(googleAccessToken);
  //     setIsSheetConnected(true);
  //   } else {
  //     setGoogleAccessToken(null);
  //     setIsSheetConnected(false);
  //   }
  // }, [googleAccessToken]);

  useEffect(() => {
    const validateExistingGoogleSheetsAccessToken = async () => {
      if (
        googleAccessToken &&
        (await isGoogleSheetsAccessTokenValid(googleAccessToken))
      ) {
        setGoogleAccessToken(googleAccessToken);
        setIsSheetConnected(true);
      } else if (googleRefreshToken) {
        await refreshAuthToken(googleRefreshToken);
      }
    };

    if (googleAccessToken && tokenFirstTime) {
      validateExistingGoogleSheetsAccessToken();
      setTokenFirstTime(false);
    }
  }, [googleAccessToken, tokenFirstTime]);

  useEffect(() => {
    if (googleAccessToken && firstTime) {
      loadSpreadSheetsInfo();
      getDocsHandler();
      setFirstTime(false);
    }
  }, [googleAccessToken, firstTime]);

  ////////////////// Doc Context Actions /////////////////////////////////////////////////////
  const getDocsHandler = useCallback(async () => {
    if (!googleAccessToken) {
      return disconnectHandlerGoogle();
    }

    try {
      const docs = await getDocs(googleAccessToken, title);

      setDocs(docs);

      updateSelectedDoc(data?.docs, docs);
    } catch (error) {
      let anyError = error as any;
      if (anyError.response.status === 401) {
        if (!googleRefreshToken) {
          setNotification({
            type: NotificationType.Error,
            message: 'Session expired. Please login again.',
          });
          return;
        }

        await refreshAuthToken(googleRefreshToken);
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
  }, [googleAccessToken, googleRefreshToken, title, data?.docs]);

  const refreshDocsHandler = async () => {
    setIsRefreshing(true);
    try {
      await getDocsHandler();
    } catch (error) {
      let anyError = error as any;
      if (anyError.response.status === 401) {
        disconnectHandlerGoogle();
        if (!googleRefreshToken) {
          setNotification({
            type: NotificationType.Error,
            message: 'Session expired. Please login again.',
          });
          return;
        }

        await refreshAuthToken(googleRefreshToken);
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error),
      });
    }
    setIsRefreshing(false);
  };

  const updateSelectedDoc = useCallback(
    (selectedDocId: string, doc: Doc[]) => {
      const newDocs = doc?.length ? doc : docs;
      const selectedDoc = newDocs.find(({ id }) => id === selectedDocId);

      if (selectedDoc) {
        setSelectedDoc(selectedDoc);
      } else {
        setSelectedDoc(undefined);
      }
    },
    [docs],
  );

  const getFullContent = async () => {
    if (!googleAccessToken || !selectedDoc)
      throw new Error('You have not selected a doc!');

    try {
      const fullContent = await getDocContent(
        googleAccessToken,
        selectedDoc.id,
      );

      return fullContent;
    } catch (error) {
      let anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
      throw error;
    }
  };

  const getPublicContent = async () => {
    if (!selectedDoc) throw new Error('You have not selected a doc!');

    try {
      const fullContent = await getPublicDocContent(selectedDoc.id);

      return fullContent;
    } catch (error) {
      let anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
      throw error;
    }
  };

  const getFilteredDocContentByParagraphsHandler = async (
    paragraphStart: number,
    paragraphEnd: number,
  ) => {
    if (!googleAccessToken || !selectedDoc)
      throw new Error('You have not selected a doc!');

    try {
      const content = await getDocContentFilteredByParagraphs(
        googleAccessToken,
        selectedDoc.id,
        paragraphStart,
        paragraphEnd,
      );

      return content;
    } catch (error) {
      let anyError = error as any;
      if (anyError.code === 401) {
        disconnectHandlerGoogle();
      }
      throw error;
    }
  };

  const updateSelectedPublicDoc = async (publicDocId: string) => {
    setSelectedDoc({ id: publicDocId, name: '' });
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const setNotification = useNotificationStore(state => state.setNotification);
  const { setNodeState, setSaving, setSchema, flowId } = useFlowsStore(
    state => state,
  );
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    selectedGoogleService:
      data.selectedGoogleService || GoogleService.GoogleSheets,
    rangeFilter: data?.rangeFilter || '',
    spreadsheet: data.spreadsheet || 'none',
    sheets: data.sheets !== undefined ? data.sheets : 'new',
    paragraphStart: data.paragraphStart || '',
    paragraphEnd: data.paragraphEnd || '',
    selectedDoc: data.docs || '',
    docsType: data.docsType || 'fullContent',
  });

  const [hasToExecute, setHasToExecute] = useState(true);

  const [isDisabled, setIsDisabled] = useState(true);

  const edges = useEdges();
  const authUser = useAuthStore(state => state);

  const refreshBasedOnService = () => {
    if (formData.selectedGoogleService === GoogleService.GoogleSheets) {
      refreshSpreadSheets();
    }
    if (formData.selectedGoogleService === GoogleService.GoogleDocs) {
      refreshDocsHandler();
    }
  };

  useEffect(() => {
    const updateDisabledAuth = async () => {
      if (!authUser?.user) return;

      try {
        const { data } = await FlowService.getFlow(flowId);
        const { creatorId, spaceId } = data;

        if (!spaceId) {
          return setIsDisabled(false);
        }

        if (authUser.user.id !== creatorId) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } catch (error) {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error as any),
        });
      }
    };

    updateDisabledAuth();
  }, [authUser, flowId]);

  const debounceTimeoutRef = useRef<any>(null);

  // useEffect(() => {
  //   const googleSheetsAccessToken = LocalStorageService.getItem(
  //     'googleSheetsAccessToken',
  //   );
  //   if (googleSheetsAccessToken) {
  //     setIsConnected(true);
  //   } else {
  //     setIsConnected(false);
  //   }
  // }, []);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [hasToExecute, formData, data]);

  const onSubmit = async () => {
    if (!isSheetConnected) {
      try {
        if (selectedPublicGoogleService === GoogleService.GoogleSheets) {
          let rangeFilter = publicRangeFilter;

          if (data['rangeFilterData']?.length) {
            rangeFilter = data['rangeFilterData']
              ?.map((item: { text: string }) => item.text)
              .join('');
          }

          const text = await getWorkSheetData(rangeFilter);
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            setSaving,
            flowTriggerData: checkExecuteTrigger(data, id),
            changeType: 'execute',
            objectData: {
              text: text,
            },
            setSchema,
          });
        } else {
          const { data: text } = await getPublicContent();

          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            setSaving,
            flowTriggerData: checkExecuteTrigger(data, id),
            changeType: 'execute',
            objectData: {
              text: text,
            },
            setSchema,
          });
        }
      } catch (error) {
        let anyError = error as any;
        if (anyError.response?.status === 401) {
          disconnectHandlerGoogle();
        }
      } finally {
        setTimeout(() => {
          setHasToExecute(true);
        }, 2500);
      }
    } else {
      setIsLoading(true);
      try {
        let text = '';

        if (
          (!formData.spreadsheet || formData.spreadsheet === 'none') &&
          formData.selectedGoogleService === GoogleService.GoogleSheets
        ) {
          return setNotification({
            type: NotificationType.Error,
            message: 'Please select a spreadsheet!',
          });
        }

        if (formData.selectedGoogleService === GoogleService.GoogleSheets) {
          let rangeFilter = formData.rangeFilter;

          if (data['rangeFilterData']?.length) {
            rangeFilter = data['rangeFilterData']
              ?.map((item: { text: string }) => item.text)
              .join('');
          }

          text = await getWorkSheetData(rangeFilter);
        }

        if (formData.selectedGoogleService === GoogleService.GoogleDocs) {
          if (formData.docsType === 'fullContent') {
            text = await getFullContent();
          } else {
            if (
              !formData.paragraphStart ||
              isNaN(+formData.paragraphStart) ||
              +formData.paragraphStart < 0 ||
              !formData.paragraphEnd ||
              isNaN(+formData.paragraphEnd) ||
              +formData.paragraphEnd < 0
            ) {
              return setNotification({
                type: NotificationType.Error,
                message:
                  'Paragraph Start and Paragraph End are required as positive numbers!',
              });
            }

            text = await getFilteredDocContentByParagraphsHandler(
              parseInt(formData.paragraphStart),
              parseInt(formData.paragraphEnd),
            );
          }
        }
        // setWorkSheetData(textData);
        // setShowWorkSheetData(true);

        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          flowTriggerData: checkExecuteTrigger(data, id),
          changeType: 'execute',
          objectCallerData: [
            ...(user?.spaceObjects || []),
            ...(user?.objects || []),
          ],
          objectData: {
            text: text,
          },
          setSchema,
        });
      } catch (error) {
        let anyError = error as any;
        if (anyError.response?.status === 401) {
          disconnectHandlerGoogle();
        }

        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      } finally {
        setIsLoading(false);
        setTimeout(() => {
          setHasToExecute(true);
        }, 2500);
      }
    }
  };

  const setValue = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChange = (e: { target: { name: string; value: any } }) => {
    setValue(e.target.name, e.target.value);
    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
    });
    setIsChangingDirectly(true);
  };

  const onChangeSelect = (e: { target: { name: string; value: any } }) => {
    setValue(e.target.name, e.target.value);
    let object = {};

    if (e.target.name === 'spreadsheet') {
      object = {
        spreadsheet: e.target.value,
        sheets: 'none',
      };
    } else if (e.target.name === 'sheets') {
      object = {
        spreadsheet: selectedSpreadSheet?.id,
        sheets: e.target.value,
      };
    } else if (e.target.name === 'docs') {
      object = {
        selectedDoc: e.target.value,
        docs: e.target.value,
      };
    }

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      flowId,
      setSaving,
      objectData: {
        [e.target.name]: e.target.value,
        ...object,
      },
      setSchema,
    });
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };
  return (
    <Node
      onSubmit={onSubmit}
      btnText="Get Data"
      handleRun={handleRun}
      data={data}
      type={type}
      id={id}
      isLoading={isLoading}
      edges={edges}
      showTokensUsed={false}
    >
      <Box sx={{ cursor: isDisabled ? 'not-allowed' : 'auto' }}>
        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
            sx={{
              backgroundColor: isDisabled
                ? 'rgba(250, 250, 250, 0.85)'
                : 'white',
              pointerEvents: isDisabled ? 'none' : 'auto',
              cursor: isDisabled ? 'not-allowed' : 'default',
              boxShadow: isDisabled ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
              transition: 'all 0.3s ease',
            }}
          >
            <NoConnection
              data={{
                ...data,
                setSelectedPublicGoogleService,
                selectedPublicGoogleService,
                updateSelectedWorkSheet,
                updatePublicWorkSheets,
                selectedWorkSheet,
                publicRangeFilter,
                setPublicRangeFilter,
                updateSelectedPublicDoc,
              }}
              connection={isSheetConnected}
              nodeType="pullData"
            />
            {isSheetConnected && (
              <>
                <Box display={'flex'} gap={4}>
                  <Label labelName="Data to Pull" isAdvanced />
                  {isDisabled && (
                    <Button sx={{ marginBottom: '8px' }}>
                      No Editing Permission
                    </Button>
                  )}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Label
                    labelName="Data Type"
                    isAdvanced
                    labelStyle={{
                      fontSize: '14px',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      fontWeight: 400,
                    }}
                  />

                  <Select
                    className="nodrag"
                    name="selectedGoogleService"
                    value={formData.selectedGoogleService}
                    onChange={onChange}
                    sx={{
                      width: '60%',
                      '& .MuiList-root': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      },
                      '& .MuiSvgIcon-root': {
                        color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                      },
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                      bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .Mui-selected': {
                            backgroundColor:
                              user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                          },
                          backgroundColor:
                            user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                        },
                      },
                    }}
                  >
                    {Object.values(GoogleService).map(googleService => (
                      <MenuItem
                        key={googleService}
                        value={googleService}
                        sx={{
                          color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                        }}
                      >
                        {googleService}
                      </MenuItem>
                    ))}
                  </Select>

                  <Box sx={{ margin: '10px 0' }}></Box>

                  {formData.selectedGoogleService ===
                  GoogleService.GoogleSheets ? (
                    <PullGoogleSheetsData
                      formData={formData}
                      data={{
                        ...data,
                        workSheetsLoaded,
                        selectedWorkSheet,
                        selectedSpreadSheet,
                        updateSelectedSpreadSheet,
                        updateSelectedWorkSheet,
                      }}
                      spreadSheets={spreadSheets}
                      workSheets={workSheets}
                      onChange={onChange}
                      onChangeSelect={onChangeSelect}
                      nodeLabel={data?.label}
                    />
                  ) : (
                    <PullGoogleDocsData
                      formData={formData}
                      data={{
                        updateSelectedDoc,
                        selectedDoc,
                        docsData: docs || [],
                        ...data,
                      }}
                      onChange={onChange}
                      onChangeSelect={onChangeSelect}
                    />
                  )}
                </Box>
                {isSheetConnected && !isRefreshing ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '20px',
                    }}
                  >
                    <Tooltip
                      title="Files are up to date"
                      arrow
                      placement="bottom"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: '#80C683',
                            color: 'white',
                          },
                        },
                        arrow: {
                          sx: {
                            color: '#80C683',
                          },
                        },
                      }}
                    >
                      <Button onClick={refreshBasedOnService}>
                        Refresh Google
                      </Button>
                    </Tooltip>
                    <IconButtonTooltip
                      title={`
                    The data to pull can be from Google Sheets or Google Docs. New Documents or Spreadsheets can be added by refreshing the Google connection. 
                    `}
                    >
                      <Info size={16} />
                    </IconButtonTooltip>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '20px',
                      height: '29px',
                    }}
                  ></Box>
                )}
              </>
            )}
          </Box>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              // previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data.text}
              onChangeSelect
              activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              placement={data?.placement}
              labelName={'Output'}
              findColor={findColor}
              nodeLabel={data?.label}
              // onPreview={onPreview}
              // findColor={findColor}
              // onChangePlacement={onChangePlacement}
            />
          </Box>
        </Box>
      </Box>
    </Node>
  );
};

export default memo(PullData);
