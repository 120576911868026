import { EdgeLabelRenderer, useViewport } from 'reactflow';
import { type Cursor } from './useCursorStateSynced';
import { stringToColor } from './utilis';
import useFlowsStore from '../../../store/storeFlows';

function Cursors({ cursors }: { cursors: Cursor[] }) {
  const viewport = useViewport();

  const colorsIndex = useFlowsStore(state => state.colorsIndex);

  return (
    <EdgeLabelRenderer>
      {cursors.map(({ id, color, x, y, userName }, index) => {
        const translate = `translate(${x}px, ${y}px)`;
        const scale = `scale(${1.5 / viewport.zoom})`;

        const result = colorsIndex.map(innerArray => innerArray[0].toString());

        const findIndex = result?.findIndex(element => element === id) || 0;

        const newColor = stringToColor(findIndex);

        return (
          <div key={id}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                transform: translate,
                zIndex: 99999,
                position: 'absolute',
              }}
            >
              <g
                style={{ transform: scale }}
                filter="url(#filter0_d_4923_168169)"
              >
                <path
                  d="M19.7181 9.58383L4.65556 3.03884C4.47896 2.96212 4.27346 3.00118 4.13731 3.1373C4.00118 3.27346 3.96212 3.47902 4.03884 3.65559L10.5839 18.7181C10.6585 18.8899 10.8279 19 11.0136 19C11.0225 19 11.0314 18.9998 11.0402 18.9993C11.2363 18.9882 11.4046 18.8561 11.462 18.6683L12.8655 14.0794C13.1902 13.0178 14.0178 12.1902 15.0794 11.8655L19.6683 10.462C19.8561 10.4046 19.9882 10.2362 19.9992 10.0402C20.0103 9.84423 19.8981 9.66208 19.7181 9.58383Z"
                  fill={newColor}
                />
                <path
                  d="M19.9173 9.12526L19.9173 9.12525L4.85482 2.58026L4.85479 2.58024C4.48982 2.42169 4.06512 2.50245 3.78379 2.78371L3.78371 2.78379C3.50244 3.06512 3.42168 3.48989 3.58026 3.85484L3.58026 3.85485L10.1253 18.9173L10.1253 18.9173C10.2797 19.2726 10.6297 19.5 11.0136 19.5C11.0318 19.5 11.0501 19.4995 11.0684 19.4985L11.0685 19.4985C11.4735 19.4755 11.8215 19.2026 11.9401 18.8146C11.9402 18.8146 11.9402 18.8146 11.9402 18.8145L13.3436 14.2256L12.8655 14.0794L13.3437 14.2256C13.6197 13.323 14.3229 12.6197 15.2256 12.3436L19.8146 10.9402L19.8146 10.9401C20.2025 10.8214 20.4755 10.4736 20.4984 10.0685C20.5214 9.66341 20.2894 9.28696 19.9173 9.12526Z"
                  stroke="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_4923_168169"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="1.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_4923_168169"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_4923_168169"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>

            <div
              style={{
                position: 'absolute',
                top: y + 30,
                left: x + 50,
                color: 'white',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                fontSize: '12px',
                fontWeight: 'bold',
                zIndex: 99999,

                background: newColor,
                borderRadius: '4px',
                padding: '2px 4px',
                width: 'fit-content',
                textWrap: 'nowrap',
              }}
            >
              {userName}
            </div>
          </div>
        );
      })}
    </EdgeLabelRenderer>
  );
}

const cursorPath = `
  M3.29227 0.048984C3.47033 -0.032338 3.67946 -0.00228214 3.8274 0.125891L12.8587
  7.95026C13.0134 8.08432 13.0708 8.29916 13.0035 8.49251C12.9362 8.68586 12.7578
  8.81866 12.5533 8.82768L9.21887 8.97474L11.1504 13.2187C11.2648 13.47 11.1538
  13.7664 10.9026 13.8808L8.75024 14.8613C8.499 14.9758 8.20255 14.8649 8.08802
  14.6137L6.15339 10.3703L3.86279 12.7855C3.72196 12.934 3.50487 12.9817 3.31479
  12.9059C3.1247 12.8301 3 12.6461 3 12.4414V0.503792C3 0.308048 3.11422 0.130306 
  3.29227 0.048984ZM4 1.59852V11.1877L5.93799 9.14425C6.05238 9.02363 6.21924 8.96776
  6.38319 8.99516C6.54715 9.02256 6.68677 9.12965 6.75573 9.2809L8.79056 13.7441L10.0332
  13.178L8.00195 8.71497C7.93313 8.56376 7.94391 8.38824 8.03072 8.24659C8.11753
  8.10494 8.26903 8.01566 8.435 8.00834L11.2549 7.88397L4 1.59852z
`;

export default Cursors;
