import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import useFlowsStore from '../../store/storeFlows';
import { Box, Button, InputLabel, Typography } from '@mui/material';
import KitModal from '../UI/KitModal';
import { useNotificationStore } from '../../store/storeNotifications';

const FlowFormModal = ({ isFlowModalOpen, setIsFlowModalOpen }: any) => {
  const [activeFlow, setActiveFlow] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const setNotification = useNotificationStore(state => state.setNotification);

  const { setNodeState, setStateHasUnsavedChanges } = useFlowsStore(
    state => state
  );
  const { register, handleSubmit, setValue, watch } = useForm({});

  const varInputNodes = activeFlow?.nodes?.filter(
    (item: { type: string }) => item.type === 'varInputNode'
  );

  const getOutputObjectNode = activeFlow?.nodes?.filter(
    (item: { type: string }) => item.type === 'outputObjectNode'
  );

  const onChange = (event: { target: { name: any; value: any } }) => {
    setValue(event.target.name.replace(/\./g, '_'), event.target.value);
  };

  const executeFlow = async (content: any) => {
    try {
      setIsLoading(true);
      setNodeState((nds: any) => {
        return nds.map((nd: any) => {
          if (nd.type === 'varInputNode') {
            return {
              ...nd,
              data: {
                ...nd.data,
                text: content[nd.id.replace(/\./g, '_')] ?? undefined,
              },
            };
          }
          return nd;
        });
      });

      setStateHasUnsavedChanges(true);
    } catch (error) {
      setIsLoading(false);
      setNotification({
        type: 'error',
        message: 'Failed to execute flow',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <KitModal
      show={isFlowModalOpen.isOpen}
      onHide={() => {
        setIsFlowModalOpen({ isOpen: false, flowId: '' });
        setActiveFlow({});
      }}
    >
      <>
        {varInputNodes?.length >= 0 && getOutputObjectNode?.length >= 0 ? (
          <form onSubmit={handleSubmit(executeFlow)} action='submit'>
            {varInputNodes?.map((item: any, i: any) => (
              <>
                <InputLabel htmlFor='promptInput'>
                  {item.data.label} {item.id}
                </InputLabel>
                <textarea
                  key={i}
                  className='nodrag'
                  defaultValue={item?.data?.text}
                  maxLength={500}
                  {...register(item.id, {
                    onChange: onChange,
                  })}
                  style={{
                    //   resize: 'both',
                    //   overflow: 'auto',
                    width: '100%',
                  }}
                />
              </>
            ))}
            {getOutputObjectNode?.map((item: any, i: any) => (
              <>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  key={i}
                >
                  <InputLabel htmlFor='promptInput'>
                    {item.data.label} {item.id}
                  </InputLabel>
                </Box>
                <textarea
                  className='nodrag'
                  maxLength={500}
                  {...register(item.id)}
                  value={item?.data?.text}
                  disabled
                  style={{
                    resize: 'both',
                    overflow: 'auto',
                    width: '100%',
                  }}
                />{' '}
              </>
            ))}{' '}
            <Button type='submit' disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Submit'}
            </Button>
          </form>
        ) : (
          <Typography>Loading</Typography>
        )}
      </>
    </KitModal>
  );
};

export default FlowFormModal;
