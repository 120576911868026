import React, { useState } from 'react';
import AuthCard from '../../components/auth/AuthCard';
import KitInput from '../../components/UI/KitInput';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CheckEmail from '../../components/auth/CheckEmail';
import { AuthService } from '../../service/AuthService';
import { useNotificationStore } from '../../store/storeNotifications';
import { Box, Button } from '@mui/material';
type IFormInput = {
  email: string;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IFormInput>();

  const [completed, setCompleted] = useState(false);

  const { setNotification } = useNotificationStore(state => state);

  const onSubmitForgot = async (values: IFormInput) => {
    try {
      const response = await AuthService.forgotPassword(values.email);
      setCompleted(true);
      setNotification({
        type: 'success',
        message: 'Reset link sent succesfully',
      });
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: 'Something went wrong',
      });
    }
  };

  return (
    <Box
      className='login'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AuthCard>
        {!completed ? (
          <Box textAlign={'center'} className='text-center '>
            <h4 className='login_form_info--text'>Forgot your password?</h4>
            <p
              style={{
                color: '#5D6E82',
              }}
            >
              Enter your email and we'll send you a reset link.
            </p>
            <KitInput
              errorsStatus={errors}
              register={register}
              placeholder='Enter your email'
              className='mt-2 mb-3'
              name='email'
              type='email'
              sxStyle={{
                mb: '15px',
                mt: '15px',
              }}
              validation={{
                required: {
                  value: true,
                  message: 'Email is required',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              }}
            />
            <Button
              variant='contained'
              onClick={handleSubmit(onSubmitForgot)}
              fullWidth
              style={{
                backgroundColor: '#2A7BE4',
              }}
              type='submit'
            >
              Send reset link
            </Button>
            <p
              style={{
                color: '#5D6E82',
                margin: '10px 0 0 0',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
              role='button'
            >
              Return to login page
            </p>{' '}
          </Box>
        ) : (
          <CheckEmail
            bodyText={`An email has been sent to ${
              watch('email') || 'your email'
            }. Please click on the included link to reset your password.`}
            onNavigate={() => navigate('/')}
          />
        )}
      </AuthCard>
    </Box>
  );
};

export default ForgotPassword;
