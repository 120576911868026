import { Box, Button, ListItem, Popover, Typography } from '@mui/material';
import {
  ArrowSquareOut,
  BoundingBox,
  Copy,
  FileText,
  Link as LinkIcon,
  PencilSimpleLine,
  Trash,
} from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FlowService } from '../../service/FlowService';
import { UserService } from '../../service/UserService';
import { useAuthStore } from '../../store/storeAuth';
import useFlowsStore from '../../store/storeFlows';
import { useNotificationStore } from '../../store/storeNotifications';
import { useStoreTabs } from '../../store/storeTabs';
import { ReactComponent as DotsThreeOutlineGrayIcon } from '../../assets/icons/DotsThreeOutlineGrayIcon.svg';
import { ReactComponent as DotsThreeOutlineWhiteIcon } from '../../assets/icons/DotsThreeOutlineWhiteIcon.svg';
import { ReactComponent as DotsThreeOutlineBlueIcon } from '../../assets/icons/DotsThreeOutlineBlueIcon.svg';
import CreateFormModal from '../Forms/CreateFormModal';
import { FormsService } from '../../service/FormService';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';

const SidebarFlow = ({
  flow,
  theme,
  onClick,
  openUpdateFlowModal,
  folderId,
  onCreateFlow,
  onDeleteFlowSuccess,
  isSpace = false,
  setFolder,
  folder,
  spaceId,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { addTab, tabs } = useStoreTabs((state: any) => state);

  const {
    user: authUser,
    setAuthUser,
    setFolders,
    folders,
  } = useAuthStore(state => state);

  const { setNotification } = useNotificationStore(state => state);
  const [isCreateFormLoading, setIsCreateFormLoading] = useState(false);
  const { flows } = useFlowsStore(state => state);
  const [openFormModal, setOpenFormModal] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const activeFlow = flows?.find(flow => flow?.id === params.flowId);
  const activeFlowFolder = folders?.find(folder => {
    const flow = folder?.flows?.find(flow => flow.id === params.flowId);
    return flow;
  });
  const tab = tabs.find(
    (tab: { flowId: string | undefined }) => tab.flowId === flow.id,
  );
  let space = isSpace ? 'spaceFlow' : 'flow';
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddTab = async (shouldNavigate: boolean) => {
    if (shouldNavigate) navigate(`/${space}/${flow?.id}`);
    const existingTab = tabs?.find(
      (tab: { flowId: string }) => tab.flowId === flow.id,
    );
    if (!!existingTab) return;
    if (tabs.length === 0) {
      if (flow.id === params.flowId) {
        addTab({ flowId: flow.id, flowName: flow.name, folderId: folderId });
        await UserService.updateUser({
          tabs: [{ flowId: flow.id, flowName: flow.name, folderId: folderId }],
        });
      } else {
        addTab({
          flowId: activeFlow?.id,
          flowName: activeFlow?.name,
          folderId: activeFlowFolder?.id,
        });
        addTab({ flowId: flow.id, flowName: flow.name, folderId: folderId });

        await UserService.updateUser({
          tabs: [
            { flowId: flow.id, flowName: flow.name, folderId: folderId },
            {
              flowId: activeFlow?.id,
              flowName: activeFlow?.name,
              folderId: activeFlowFolder?.id,
            },
          ],
        });
      }
    } else {
      addTab({ flowId: flow.id, flowName: flow.name, folderId: folderId });
      await UserService.updateUser({
        tabs: [
          { flowId: flow.id, flowName: flow.name, folderId: folderId },
          ...tabs,
        ],
      });
    }
  };

  const onCreateForm = async (name: string) => {
    setIsCreateFormLoading(true);
    try {
      const response = await FormsService.createForm({
        name: name,
        flowId: flow.id,
        folderId: folderId,
        index: folder.flows.length + 1,
      });

      navigate(
        `/form/${response.data.id}${isSpace ? '?spaceId=' + spaceId : ''}`,
      );
      setOpenFormModal(false);

      if (isSpace) {
        if (folder.id === folderId) {
          folder.flows.push({
            id: response.data.id,
            edges: [],
            nodes: response.data.nodes,
            index: 1,
            name: response.data.name,
            isForm: true,
          });
          setFolder(folder);
        }
        return;
      }

      const modifyFolders = folders.map(folder => {
        if (folder.id === folderId) {
          folder.flows.push({
            id: response.data.id,
            edges: [],
            nodes: response.data.nodes,
            index: 1,
            name: response.data.name,
            isForm: true,
          });
        }
        return folder;
      });

      setFolders(modifyFolders);
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong, creating form failed',
      });
    } finally {
      setIsCreateFormLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      const result = window.confirm(
        'Are you sure you want to delete the flow?',
      );

      if (!result) return;

      if (flow?.isForm) {
        await FormsService.deleteForm(flow?.id);
      } else {
        await FlowService.deleteFlow(flow?.id);
      }

      const modifyFolders = folders.map(folder => {
        return {
          ...folder,
          flows: folder.flows.filter(flo => flo.id !== flow.id),
        };
      });

      setFolders(modifyFolders);

      if (typeof onDeleteFlowSuccess === 'function') {
        onDeleteFlowSuccess(folderId, flow?.id);
      }

      if (params.flowId === flow?.id) {
        navigate('/');
      }
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Something went wrong , deleting failed',
      });
    }
  };

  return (
    <>
      <ListItem
        className="menu menu--on-hover"
        sx={{
          listStyleType: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '0px 4px 0px 4px',
          width: '85%',
          marginLeft: '30px',
          borderRadius: params.flowId === flow.id ? '4px' : '0px',
          backgroundColor:
            params.flowId === flow.id
              ? theme === 'dark'
                ? '#0c111d'
                : '#d6e7ff'
              : 'transparent',
          '&:hover': {
            backgroundColor: theme === 'dark' ? '#101828' : '#E7F0FF',
            borderRadius: '4px',
          },
        }}
        key={flow.id}
        onClick={onClick}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            padding: '0px',
            height: '32px',
          }}
        >
          {flow.isForm ? (
            <FileText size={16} color="#3650DE" />
          ) : (
            <BoundingBox
              style={{
                color:
                  params.flowId === flow.id
                    ? '#546BEA'
                    : theme === 'dark'
                    ? '#D0D5DD'
                    : '#475467',
                fontSize: '18px',
              }}
            />
          )}
          <Typography
            className="active-menu"
            sx={{
              color:
                params.flowId === flow.id
                  ? '#546BEA'
                  : theme === 'dark'
                  ? '#D0D5DD'
                  : '#475467',
              fontSize: '16px',
              textDecoration: 'none',
              fontWeight: 400,
              lineHeight: '24px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '7rem',
            }}
            title={flow.name}
            onClick={() => {
              if (tabs.length > 1) {
                if (tabs.length <= 9) {
                  handleAddTab(false);
                } else {
                  navigate(`/flow/${flow?.id}?folderId=${folderId}&type=flow`);
                }

                if (tab?.flowId) {
                  if (flow.isForm) {
                    navigate(
                      `/form/${flow?.id}${
                        isSpace ? '?spaceId=' + spaceId : ''
                      }`,
                    );
                    return;
                  }

                  navigate(
                    `/${space}/${flow?.id}?folderId=${folderId}&type=flow`,
                  );
                }
              } else {
                if (flow.isForm) {
                  navigate(
                    `/form/${flow?.id}${isSpace ? '?spaceId=' + spaceId : ''}`,
                  );
                  return;
                }

                navigate(
                  `/${space}/${flow?.id}?folderId=${folderId}&type=flow`,
                );
              }
            }}
          >
            {flow.name}
          </Typography>
        </div>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <IconButtonTooltip title="Flow Options" onClick={handleClick}>
            {/* {theme === 'dark' ?  <DotsThreeOutlineWhiteIcon />
        : <DotsThreeOutlineGrayIcon />}   */}
            {params.flowId === flow.id ? (
              <DotsThreeOutlineBlueIcon />
            ) : theme === 'dark' ? (
              <DotsThreeOutlineWhiteIcon />
            ) : (
              <DotsThreeOutlineGrayIcon />
            )}
          </IconButtonTooltip>
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: '8px',
              background: authUser?.theme === 'dark' ? '#101828' : '#FFFF',
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                openUpdateFlowModal(flow, folderId, false, true);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <PencilSimpleLine
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Rename
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                openUpdateFlowModal(flow, folderId, true, false);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <LinkIcon
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Share
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              disabled={tabs.length >= 10}
              onClick={() => {
                handleClose();
                if (tabs.length >= 10) {
                  return;
                }
                handleAddTab(false);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <ArrowSquareOut
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Open in new tab
                </Typography>
              </Box>
            </Button>

            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                setOpenFormModal(true);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <FileText
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Create form
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                px: '8px',
                gap: '8px',
                color: '#EAECF0',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                onCreateFlow();
                handleClose();
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Copy
                  size={20}
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                />
                <Typography
                  color={authUser?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                >
                  Duplicate
                </Typography>
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                background: 'unset',
                justifyContent: 'flex-start',
                textTransform: 'unset',
                fontSize: '16px',
                boxShadow: 'unset',
                color: '#b91c1c',
                px: '8px',
                gap: '8px',
                ':hover': {
                  background:
                    authUser?.theme === 'dark' ? '#FFADB7' : '#fecdd3',
                  boxShadow: 'unset',
                },
              }}
              onClick={() => {
                handleClose();
                onDelete();
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Trash size={20} color="#b91c1c" />
                <Typography>Delete</Typography>
              </Box>
            </Button>
          </Box>
        </Popover>

        <CreateFormModal
          flow={flow}
          isCreateFormLoading={isCreateFormLoading}
          show={openFormModal}
          onCreateForm={onCreateForm}
          onHide={() => setOpenFormModal(false)}
        />
      </ListItem>
    </>
  );
};

export default SidebarFlow;
