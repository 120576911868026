import React, { memo } from 'react';
import { useReactFlow } from 'reactflow';

const LinesNode = ({ data }: any) => {
  const reactflow = useReactFlow();

  const nodes = reactflow?.getNodes();

  return (
    <div
      className="nodrag"
      style={{
        width: '1088px',
        borderLeft: '1px solid #3650DE',
        borderRight: '1px solid #3650DE',
        height: nodes?.length * 150,
      }}
    ></div>
  );
};

export default memo(LinesNode);
