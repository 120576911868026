import { Edge, Node } from 'reactflow';
import { getIdForTableGen } from '../getIdsForTableGen';
import { findConnectedNodes } from '../executeNode/findConnectedNodes';
import { FlowService } from '../../service/FlowService';
import { allNodesTargetBasedOnType } from '../dataStoredToType';
import { processTextSegments } from '../markdown';
export const getOutputByType = ({
  edge,
  nds,
  combinedData,
  objectCallerData,
}: {
  edge: Edge;
  nds: Node[];
  combinedData: {
    [key: string]: {
      [key: string]: {
        label?: string;
        id?: string;
        text?: string;
      };
    };
  };
  combinedTexts: {
    [key: string]: {
      [key: string]: string;
    };
  };
  objectCallerData?: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}) => {
  const { source, sourceHandle, target, targetHandle }: any = edge;
  const findSource = nds.find(n => n.id === edge.source);

  // if (source?.includes('outputTable')) {
  //   if (
  //     target?.includes('tableProcessor') &&
  //     targetHandle === 'tableInput'
  //   ) {
  //     const connectedText = nds.find(n => n.id === edge.source)?.data
  //       ?.tableData;

  //     combinedTexts[target][targetHandle] = connectedText?.length
  //       ? connectedText
  //       : [];
  //     return;
  //   }
  // }

  let data = {
    label: findSource?.data?.label,
    id: findSource?.id,
  };

  if (source?.includes('nodesBox')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data?.selectedId;

    if (objectCallerData?.length) {
      const findData = objectCallerData.find(item => item.id === connectedText);

      if (findData?.id) {
        const text =
          findData.text?.find(
            item => item?.id === sourceHandle?.replace('-out', ''),
          )?.text || '';

        combinedData[target][targetHandle] = {
          ...data,
          text,
          id: findSource?.id + '$' + sourceHandle,
        };
        return {
          ...data,
          text,
          id: findSource?.id + '$' + sourceHandle,
        };
      }
    }
  }

  if (source?.includes('commandContentNode')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data?.text
      ?.result;

    combinedData[target][targetHandle] = {
      ...data,
      text: connectedText || '',
    };

    return {
      ...data,
      text: connectedText || '',
    };
  }

  if (source.includes('tableProcessor')) {
    const connectedText: {
      columns: {
        id: string;
        value: string;
      }[];
    }[] = nds.find(n => n.id === edge.source)?.data?.tableData;
    const completed = nds.find(n => n.id === edge.source)?.data?.completed || 0;

    if (connectedText) {
      const { processedIds } = getIdForTableGen(connectedText);
      let value = '';
      connectedText?.map(row => {
        row?.columns?.map(col => {
          if (col.id === processedIds[completed]) {
            value = col.value;
            return col;
          }
          return col;
        });
        return value;
      });

      combinedData[target][targetHandle] = {
        ...data,
        text: value || '',
      };
      return {
        ...data,
        text: value || '',
      };
    }
  }

  if (source.includes('dynamicSettingsNode')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data;
    let data = { ...connectedText };
    delete data.myId;
    delete data.text;

    if (data?.selectedOption === 'text' || !data?.selectedOption) {
      if (target.includes('commandTextNode')) {
        combinedData[target][targetHandle] = data;
        return data;
      }
    } else {
      if (target.includes('voiceBoxNode')) {
        combinedData[target][targetHandle] = data;
        return data;
      }
    }
  }

  if (source.includes('mathFunction')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data?.result;

    if (connectedText && sourceHandle === 'output') {
      combinedData[target][targetHandle] = {
        ...data,
        text: connectedText || '',
      };
      return {
        ...data,
        text: connectedText || '',
      };
    }
  }

  if (source.includes('scripting')) {
    let connectedText = null;

    if (sourceHandle === 'output') {
      connectedText = nds.find(n => n.id === edge.source)?.data?.result;
    }
    if (sourceHandle === 'console') {
      connectedText = nds.find(n => n.id === edge.source)?.data?.console;
    }

    if (connectedText && sourceHandle === 'output') {
      combinedData[target][targetHandle] = {
        ...data,
        text: connectedText || '',
        id: findSource?.id + '$' + sourceHandle,
      };
      return {
        ...data,
        text: connectedText || '',
        id: findSource?.id + '$' + sourceHandle,
      };
    } else if (connectedText && sourceHandle === 'console') {
      combinedData[target][targetHandle] = {
        ...data,
        text: connectedText || '',
        id: findSource?.id + '$' + sourceHandle,
      };
      return {
        ...data,
        text: connectedText || '',
        id: findSource?.id + '$' + sourceHandle,
      };
    }
  }

  if (source.includes('arrayNode')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data
      ?.processorArray;

    const startingPoint =
      nds.find(n => n.id === edge.source)?.data?.completed || 0;

    combinedData[target][targetHandle] = {
      ...data,
      text: connectedText?.[startingPoint] || '',
    };

    return {
      ...data,
      text: connectedText?.[startingPoint] || '',
    };
  }

  if (source.includes('fluxObject')) {
    const outputsType = [
      'Load First X Entries',
      'Load First X Words',
      'Load Last X Entries',
      'Load Last X Words',
      'Load All Entries',
    ];

    const {
      selectedType,
      selectedNumber,
      selectedIndex: selIndex,
      chat,
      selectedChat,
    } = nds.find(n => n.id === edge.source)?.data;

    const selectedIndex = Number(selIndex || 5);
    let connectedText;

    const defaultChat = selectedChat || 'default';

    if (defaultChat === 'default') {
      connectedText = nds.find(n => n.id === edge.source)?.data?.entries || [];
    } else {
      const userSessionsChat =
        nds.find(n => n.id === edge.source)?.data?.userSessionsChat || [];
      const findSession = userSessionsChat?.find(
        (session: {
          id: string;
          entries: { content: string; timestamp: string }[];
        }) => session?.id === defaultChat,
      );
      connectedText = findSession?.entries || [];

      combinedData[target][targetHandle] = {
        ...data,
        text: connectedText ? JSON.stringify(connectedText) : '',
      };

      if (selectedType === outputsType[4]) {
        return {
          ...data,
          text: connectedText ? JSON.stringify(connectedText) : '',
        };
      }
    }

    if (selectedType) {
      if (selectedType === outputsType[0]) {
        const newArray = [...connectedText];
        const spliceArrayByIndex = newArray.splice(0, selectedIndex);

        const ifChatFiltered = spliceArrayByIndex.map(nds => {
          if (!chat) {
            return {
              content: nds.content,
              timestamp: nds.timestamp,
            };
          }
          return nds;
        });

        combinedData[target][targetHandle] = {
          ...data,
          text: JSON.stringify(ifChatFiltered) || '',
        };

        return {
          ...data,
          text: JSON.stringify(ifChatFiltered) || '',
        };
      } else if (selectedType === outputsType[1] && selectedNumber) {
        if (connectedText) {
          combinedData[target][targetHandle] = {
            ...data,
            text:
              connectedText !== null
                ? `${connectedText[0].content
                    ?.split(' ')
                    ?.slice(0, selectedNumber || 50)
                    .join(' ')}`
                : '',
          };

          return {
            ...data,
            text:
              connectedText !== null
                ? `${connectedText[0].content
                    ?.split(' ')
                    ?.slice(0, selectedNumber || 50)
                    .join(' ')}`
                : '',
          };
        }
      } else if (selectedType === outputsType[2]) {
        const newArray = [...connectedText];
        const spliceArrayByIndex = newArray.splice(
          newArray.length - selectedIndex,
          selectedIndex,
        );

        const ifChatFiltered = spliceArrayByIndex.map(nds => {
          if (!chat) {
            return {
              content: nds.content,
              timestamp: nds.timestamp,
            };
          }
          return nds;
        });

        combinedData[target][targetHandle] = {
          ...data,
          text: JSON.stringify(ifChatFiltered) || '',
        };

        return {
          ...data,
          text: JSON.stringify(ifChatFiltered) || '',
        };
      } else if (selectedType === outputsType[3]) {
        combinedData[target][targetHandle] = {
          ...data,
          text:
            connectedText !== null
              ? `${connectedText[connectedText?.length - 1].content
                  ?.split(' ')
                  ?.slice(0, selectedNumber || 50)
                  .join(' ')}`
              : '',
        };

        return {
          ...data,
          text:
            connectedText !== null
              ? `${connectedText[connectedText?.length - 1].content
                  ?.split(' ')
                  ?.slice(0, selectedNumber || 50)
                  .join(' ')}`
              : '',
        };
      } else if (selectedType === outputsType[4]) {
        const newArray = [...connectedText];

        combinedData[target][targetHandle] = {
          ...data,
          text: JSON.stringify(newArray) || '',
        };

        return {
          ...data,
          text: JSON.stringify(newArray) || '',
        };
      }
    } else {
      let index = selectedIndex ? selectedIndex : 5;
      const newArray = [...connectedText];
      const spliceArrayByIndex = newArray.splice(
        newArray.length - index,
        index,
      );

      const ifChatFiltered = spliceArrayByIndex.map(nds => {
        if (!chat) {
          return {
            content: nds.content,
            timestamp: nds.timestamp,
          };
        }
        return nds;
      });

      // combinedTexts[target][targetHandle] +=
      //   connectedText !== null
      //     ? `${JSON.stringify(ifChatFiltered)} \n`
      //     : '';

      combinedData[target][targetHandle] = {
        ...data,
        text: JSON.stringify(ifChatFiltered) || '',
      };

      return {
        ...data,
        text: JSON.stringify(ifChatFiltered) || '',
      };
    }
  }

  if (source.includes('voiceBoxNode')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data?.audioUrl;

    combinedData[target][targetHandle] = {
      ...data,
      text: connectedText || '',
    };

    return {
      ...data,
      text: connectedText || '',
    };
  } else if (source.includes('fluxBox')) {
    const connectedText = nds.find(n => n.id === edge.source)?.data?.[
      sourceHandle
    ];

    combinedData[target][targetHandle] = {
      ...data,
      text: connectedText || '',
    };

    return {
      ...data,
      text: connectedText || '',
    };
  } else {
    const findSource = nds.find(n => n.id === edge.source);
    let connectedText = nds.find(n => n.id === edge.source)?.data?.text;

    let text = connectedText || '';

    if (source?.includes('counterNode')) {
      text = connectedText || '0';
    }

    if (
      source?.includes('textBox') ||
      source?.includes('richTextBox') ||
      source?.includes('outputController')
    ) {
      const connectedTextData = nds.find(n => n.id === edge.source)?.data
        ?.textData;

      if (connectedTextData?.length > 0) {
        text = connectedTextData
          ?.map((item: { id: string; text: string }) => item.text)
          ?.join('\n');
      }
    }

    if (source?.includes('outputController')) {
      const connectedTextData = nds.find(n => n.id === edge.source)?.data
        ?.textData;

      const formattedTextData = connectedTextData?.map(
        (item: { text: string }) => {
          return {
            ...item,
            text: processTextSegments(item?.text),
          };
        },
      );

      const intakeFilter =
        nds.find(n => n.id === edge.source)?.data?.filter ?? 'all';

      if (intakeFilter === 'all') {
        const mergedContent = formattedTextData
          ?.flatMap((item: { text: any }) => item.text)
          ?.map((textItem: { content: any }) => textItem.content)
          ?.join('');

        text = mergedContent;
      } else {
        const mergedContent = formattedTextData
          ?.flatMap((item: { text: any }) => item.text)
          ?.filter((textItem: { type: any }) => textItem.type === intakeFilter)
          ?.map((textItem: { content: any }) => textItem.content)
          ?.join('');
        text = mergedContent;
      }
    }

    if (source?.includes('commandTextNode') && sourceHandle === 'fileLink') {
      text =
        findSource?.data?.fileLinks?.[findSource?.data?.previewIndex || 0]
          ?.src || 'No Link generated';
      combinedData[target][targetHandle] = {
        label: findSource?.data?.label,
        text,
        id: findSource?.id + '$' + sourceHandle,
      };
      return {
        label: findSource?.data?.label,
        text,
        id: findSource?.id + '$' + sourceHandle,
      };
    }

    if (source?.includes('outputController') && sourceHandle === 'fileLink') {
      text = findSource?.data?.fileLink?.src || 'No Link generated';
      combinedData[target][targetHandle] = {
        label: findSource?.data?.label,
        text,
        id: findSource?.id + '$' + sourceHandle,
      };
      return {
        label: findSource?.data?.label,
        text,
        id: findSource?.id + '$' + sourceHandle,
      };
    }

    if (source?.includes('varInputNode') && sourceHandle === 'outputFileUrl') {
      text = findSource?.data?.fileUrl;
      combinedData[target][targetHandle] = {
        label: findSource?.data?.label,
        text,
        id: findSource?.id + '$' + sourceHandle,
      };
      return {
        label: findSource?.data?.label,
        text,
        id: findSource?.id + '$' + sourceHandle,
      };
    }

    const data = {
      text,
      label: findSource?.data?.label,
      id: findSource?.id,
    };

    combinedData[target][targetHandle] = {
      ...data,
    };

    return data;
  }
};

export const flowTriggerDataHandle = ({
  flowTriggerData,
  nds,
  edges,
  id,
}: {
  flowTriggerData: {
    flowGlobalId: string;
    flowId: string;
    id: string;
    completed: number;
    length: number;
    allIds: string[];
    canceled: boolean;
    paused: boolean;
    hasArrayExecute: boolean;
    runFrom: string;
    conditionTrue: boolean;
    arrayExecute: {
      id: string;
      completed: number;
      goToFirstId: boolean;
      loopingToId: string;
      firstId: string;
    };
  };
  nds: Node[];
  edges: Edge[];
  id: string;
}) => {
  let newNodes = [...nds];

  if (flowTriggerData?.flowGlobalId) {
    const { allIds } = findConnectedNodes(
      nds,
      edges,
      flowTriggerData?.flowGlobalId,
    );

    let flowTrigger = {
      id: flowTriggerData?.flowGlobalId,
      completed: 0,
      length: allIds.length - 1,
      allIds,
    };

    const findHasToClear = nds?.filter(nd => nd?.data?.clearOnExecution);

    if (findHasToClear?.length > 0) {
      const clearOnExecution = async () => {
        try {
          await FlowService.clearSessions(flowTriggerData?.flowId);
        } catch (error) {}
      };

      clearOnExecution();
    }

    const checkIndex =
      allIds.findIndex(id => id === flowTriggerData?.runFrom) !== -1
        ? allIds.findIndex(id => id === flowTriggerData?.runFrom)
        : 0;

    if (allIds?.findIndex(id => id === flowTriggerData?.runFrom) !== -1) {
      flowTrigger = {
        ...flowTrigger,
        completed: allIds.findIndex(id => id === flowTriggerData?.runFrom),
      };
    }

    newNodes = nds.map(nd => {
      const clearOnExecution = nd?.data?.clearOnExecution || false;
      if (nd.id === allIds[checkIndex] && !clearOnExecution) {
        return {
          ...nd,
          data: {
            ...nd.data,
            flowTrigger,
            canceled: false,
          },
        };
      }

      if (nd?.id === flowTriggerData?.flowGlobalId && checkIndex !== 0) {
        return {
          ...nd,
          data: {
            ...nd.data,
            loading: true,
          },
        };
      }

      if (clearOnExecution) {
        if (nd.type === 'fluxObject') {
          const defaultChat = nd.data?.selectedChat || 'default';
          const userSessionsChat = nd?.data?.userSessionsChat;

          let object = {};

          if (defaultChat === 'default') {
            object = {
              entries: [],
            };
          } else {
            object = {
              userSessionsChat: userSessionsChat?.map((session: any) => {
                if (session?.id === nd?.data?.selectedChat) {
                  return {
                    ...session,
                    entries: [],
                  };
                }
                return session;
              }),
            };
          }
          return {
            ...nd,
            data: {
              ...nd?.data,
              ...object,
            },
          };
        }
        if (
          nd.type === 'commandTextNode' ||
          nd.type === 'voiceBoxNode' ||
          nd.type === 'imageGenerator' ||
          nd.type === 'commandContentNode' ||
          nd?.type === 'audioReaderNode'
        ) {
          let isCommandContentNode = nd.type === 'commandContentNode';
          let newData = {};
          if (isCommandContentNode) {
            newData = {
              text: {
                ...nd.data.text,
                result: '',
              },
            };
          }

          if (nd.type === 'voiceBoxNode') {
            newData = {
              audioUrl: '',
            };
          }

          if (nd?.id === allIds[checkIndex]) {
            newData = {
              ...newData,
              flowTrigger,
            };
          }
          return {
            ...nd,
            data: {
              ...nd.data,
              text: '',
              canceled: false,
              ...newData,
            },
          };
        }
      }

      return {
        ...nd,
        data: {
          ...nd.data,
          canceled: false,
          paused: false,
        },
      };
    });
  }

  if (flowTriggerData?.id && flowTriggerData?.hasArrayExecute) {
    const { allIds } = findConnectedNodes(nds, edges, flowTriggerData?.id);
    const length = allIds.length;

    const arrayExecute = flowTriggerData?.arrayExecute;
    let dataIds = allIds[flowTriggerData.completed];
    let completed = flowTriggerData.completed;

    const check =
      !arrayExecute?.goToFirstId && arrayExecute?.loopingToId !== 'default';
    if (check) {
      dataIds = allIds[flowTriggerData.completed];
      completed = flowTriggerData.completed;
    } else if (arrayExecute?.loopingToId === 'default') {
      dataIds = allIds[flowTriggerData.completed - 1];
      completed = flowTriggerData.completed - 1;
    } else if (arrayExecute?.goToFirstId) {
      dataIds = arrayExecute?.firstId;
      completed = allIds.findIndex(dt => dt === arrayExecute?.firstId);
    }

    newNodes = [
      ...nds?.map(nd => {
        if (nd.id === flowTriggerData?.arrayExecute?.id) {
          return {
            ...nd,
            data: {
              ...nd.data,
              completed: flowTriggerData?.arrayExecute?.completed,
              canceled: false,
            },
          };
        }

        if (nd.id === id && nd.id !== dataIds) {
          return {
            ...nd,
            data: {
              ...nd.data,
              canceled: false,
              flowTrigger: null,
            },
          };
        }

        if (nd.id === dataIds) {
          return {
            ...nd,
            data: {
              ...nd.data,
              canceled: false,
              flowTrigger: {
                id: flowTriggerData.id,
                length,
                canceled: flowTriggerData?.canceled,
                completed,
                conditionTrue: flowTriggerData?.conditionTrue,
                arrayExecute: flowTriggerData?.arrayExecute,
              },
            },
          };
        }

        return nd;
      }),
    ];
  }

  if (flowTriggerData?.id && !flowTriggerData?.hasArrayExecute) {
    const { allIds } = findConnectedNodes(nds, edges, flowTriggerData?.id);
    const flowTrigger = {
      ...flowTriggerData,
      id: flowTriggerData?.id,
      completed: flowTriggerData.completed + 1,
      length: allIds.length - 1,
      allIds,
    };

    newNodes = nds.map(nd => {
      if (nd.id === allIds[flowTriggerData.completed]) {
        return {
          ...nd,
          data: {
            ...nd.data,
            canceled: false,
            flowTrigger: null,
          },
        };
      }

      if (nd.id === allIds[flowTriggerData.completed + 1]) {
        return {
          ...nd,
          data: {
            ...nd.data,
            canceled: false,
            flowTrigger,
          },
        };
      }

      if (
        nd.id === flowTriggerData.id &&
        flowTriggerData.completed + 1 > flowTriggerData.length
      ) {
        return {
          ...nd,
          data: {
            ...nd.data,
            hasFinished: true,
          },
        };
      }

      return nd;
    });
  }

  return newNodes;
};

export const givingDataToNode = ({
  edge,
  nds,
  type,
  objectCallerData,
}: {
  edge: Edge;
  nds: Node[];
  type: 'delete' | 'add';
  objectCallerData?: {
    id: string;
    text: {
      id: string;
      text: string;
    }[];
  }[];
}) => {
  const findSource = nds.find(node => node.id === edge.source);

  const findTarget = nds.find(node => node.id === edge.target);

  const getTargetHandle = edge.targetHandle;

  const getHandleByType =
    allNodesTargetBasedOnType[
      findTarget?.type as keyof typeof allNodesTargetBasedOnType
    ] || '';

  const getType =
    getHandleByType[getTargetHandle as keyof typeof getHandleByType] || '';

  const data = getOutputByType({
    edge,
    nds,
    combinedData: {
      [edge.target]: {
        [String(edge.targetHandle)]: {},
      },
    },
    combinedTexts: {
      [edge.target]: {
        [String(edge.targetHandle)]: '',
      },
    },
    objectCallerData,
  });

  const targetHandleData: {
    id: string;
    text: string;
  }[] = findTarget?.data?.[getType + 'Data'] || [];

  let source = edge.source;
  if (
    edge?.source?.includes('nodesBox') ||
    edge?.source?.includes('scripting') ||
    (edge?.source?.includes('commandTextNode') &&
      edge?.sourceHandle === 'fileLink') ||
    (edge?.source?.includes('varInputNode') &&
      edge?.sourceHandle === 'outputFileUrl') ||
    (edge?.source?.includes('outputController') &&
      edge?.sourceHandle === 'fileLink')
  ) {
    source = edge.source + '$' + edge.sourceHandle;
  }
  const checkIfAlreadyConnected = targetHandleData?.find(
    item => item?.id === source,
  );

  let handleData;
  if (type === 'delete') {
    handleData = targetHandleData?.filter(item => item?.id !== source);
  } else {
    handleData = targetHandleData;
  }

  return {
    findSource,
    findTarget,
    getType,
    handleData,
    checkIfAlreadyConnected,
    data,
  };
};
