import KitModal from '../../UI/KitModal';
import { Box, Chip, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import EvenLabs from '../EvenLabs';
import PlayHt from '../PlayHt';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { FormData } from '../constants';
import { useAuthStore } from '../../../store/storeAuth';
import { systemDisplayNameMap, systemValueMap } from '../../AiConfigs/constants';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: FormData;
  onChange: (
    e:
      | React.ChangeEvent<SelectInputProps>
      | React.ChangeEvent<HTMLInputElement>
      | any
  ) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  const { technologies } = useAuthStore(state => state);

  const audioSystems = ['evenLabs', 'playHt'];

  const filteredAudioSystems = technologies
    ?.filter(system => audioSystems.includes(system.system))
    .map(system => ({
      ...system,
      models: system.models?.filter((model: any) => model.type === 'Audio'),
    }));

  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '650px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant="h6">Settings</Typography>
        <Box>
          <FormLabel
            sx={{
              mb: '4px',
            }}
          >
            System
          </FormLabel>

          <Select
            className="nodrag"
            fullWidth
            value={formData.voiceSystem}
            defaultValue={"elevenlabs.io"}
            size="small"
            onChange={onChange}
            name="voiceSystem"
            sx={{
              width: '100%',
            }}
          >
            {filteredAudioSystems.map((opt, index) => {
                const disabled = opt?.models?.every(
                  (model: any) => model.disabledByCompany,
                );
                const systemValue = systemValueMap[opt.system] || opt.system;
                const displayName = systemDisplayNameMap[systemValue] || systemValue;
                return (
                  <MenuItem
                    key={index}
                    value={systemValue}
                    disabled={disabled}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {displayName}
                    {disabled && (
                      <Chip
                        label="Disabled by admin"
                        color="error"
                        size="small"
                        variant="filled"
                        sx={{
                          borderRadius: '8px',
                        }}
                      />
                    )}
                  </MenuItem>
                );
              })}
          </Select>
        </Box>



        {formData.voiceSystem === 'elevenlabs.io' && (
          <EvenLabs onChange={onChange} formData={formData} />
        )}

        {formData.voiceSystem === 'play.ht' && (
          <PlayHt onChange={onChange} formData={formData} />
        )}
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
