import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Skeleton,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  CaretDown,
  CaretUp,
  MagnifyingGlass,
  Planet,
  TextAlignLeft
} from '@phosphor-icons/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CompanyService } from '../../service/CompanyService';
import { CompanySpacesService } from '../../service/CompanySpacesService';
import { useNotificationStore } from '../../store/storeNotifications';
import { User } from '../../util/Types/User';
import { GridRowModel } from './AiTechnologies';
import DescriptionModal from './DescriptionModal';
import { useAuthStore } from '../../store/storeAuth';

interface Props {
  authUser: User;
  isLoading: boolean;
  company: any;
  space: any;
  technologies: GridRowModel[];
  setReFetch: Dispatch<SetStateAction<boolean>>;
  reFetch: boolean;
  setViewState: Dispatch<
    SetStateAction<
      | 'OrganizationsAndWorkspaces'
      | 'OrganizationTechnologies'
      | 'WorkspaceTechnologies'
    >
  >;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setTechnologies: (technologies: any) => void;
  // updateTechnology: (id: string, enabled: boolean) => void;
}

interface FormValues {
  enabled: Record<string, boolean>;
}

const TechnologyPermissions = ({
  authUser,
  isLoading,
  company,
  space,
  technologies = [],
  setViewState,
  setIsLoading,
  setTechnologies,
  // updateTechnology,
  reFetch,
  setReFetch,
}: Props) => {
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [initialEnabledState, setInitialEnabledState] = useState<
    Record<string, boolean>
  >({});
  const [isChanged, setIsChanged] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [system, setSystem] = useState('');
  const [systemDescription, setSystemDescription] = useState('');
  const setNotification = useNotificationStore(state => state.setNotification);
  // const setTechnologies = useAuthStore(state => state.setTechnologies);

  useEffect(() => {
    const initialState = (technologies || [])
      .flatMap(tech =>
        tech?.models?.map((model: any) => ({
          id: model.id,
          enabled: model.enabled,
          disabledByCompany: model.disabledByCompany,
        })),
      )
      .reduce((acc, curr) => {
        acc[curr.id] = curr.enabled;
        return acc;
      }, {} as Record<string, boolean>);

    setInitialEnabledState(initialState);
  }, [technologies]);

  const enabledDefaults = (technologies || []).flatMap(tech =>
    tech?.models?.map((model: any) => ({
      id: model.id,
      enabled: model.enabled,
      disabledByCompany: model.disabledByCompany,
    })),
  );

  const { handleSubmit, setValue, watch, control } = useForm<FormValues>({
    defaultValues: {
      enabled: enabledDefaults.reduce((acc, curr) => {
        acc[curr.id] = curr.enabled;
        return acc;
      }, {} as Record<string, boolean>),
    },
  });

  useEffect(() => {
    const currentValues = watch();
    const changes = Object.keys(currentValues.enabled).some(
      key => currentValues.enabled[key] !== initialEnabledState[key],
    );
    setIsChanged(changes);
  }, [watch, initialEnabledState]);

  const toggleRowExpansion = (id: number) => {
    setExpandedRowIds(prevExpandedRowIds =>
      prevExpandedRowIds.includes(id)
        ? prevExpandedRowIds.filter(rowId => rowId !== id)
        : [...prevExpandedRowIds, id],
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'system',
      headerName: 'System',
      headerClassName: 'header-padding',
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowModel>) => {
        const isExtendedRow = params.value && params.value.startsWith('(');
        return (
          <div style={{ cursor: 'pointer' }}>
            {params.row.system !== '' && (
              <div
                onClick={() => {
                  if (!isExtendedRow) {
                    toggleRowExpansion(params.row.id);
                    setSystem(params.row.system);
                  }
                }}
                style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: isExtendedRow ? '20px' : '0' }}
              >
                {params.value}
                {!isExtendedRow &&
                  (expandedRowIds.includes(params.row.id) ? (
                    <CaretUp size={16} />
                  ) : (
                    <CaretDown size={16} />
                  ))}
              </div>
            )}
            {params.row.system === '' && params.value}
          </div>
        );
      },
    },
    {
      field: 'models',
      headerName: 'Model',
      headerClassName: 'header-padding',
      sortable: false,
      flex: 1,
      valueGetter: (params: any) => {
        if (typeof params === 'string') return params;
        const modelsCount = params.length;
        return modelsCount === 1
          ? `${modelsCount} model`
          : `${modelsCount} models`;
      },
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowModel>) => {
        if (Array.isArray(params.row?.models)) {
          const systemEnabled = params.row?.models?.every(model =>
            watch(`enabled.${model.id}`),
          );
          const systemId = params.row.id;

          return (
            <Controller
              name={`enabled.${systemId}`}
              control={control}
              render={({ field }) => (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    space &&
                    params.row?.models?.some(model => model.disabledByCompany)
                      ? 'Disabled by company'
                      : ''
                  }
                >
                  <span>
                    <Switch
                      disabled={
                        space &&
                        params.row?.models?.some(model => model.disabledByCompany)
                      }
                      {...field}
                      checked={systemEnabled}
                      onChange={e => {
                        const value = e.target.checked;
                        params.row?.models?.forEach(model => {
                          setValue(`enabled.${model.id}`, value, {
                            shouldValidate: true,
                          });
                        });
                        setIsChanged(true);
                        field.onChange(e);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </span>
                </Tooltip>
              )}
            />
          );
        } else {
          const modelId = params.row.id;
          return (
            <Controller
              name={`enabled.${modelId}`}
              control={control}
              render={({ field }) => (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    space && params.row.disabledByCompany
                      ? 'Disabled by company'
                      : ''
                  }
                >
                  <span>
                    <Switch
                      {...field}
                      checked={watch(`enabled.${modelId}`)}
                      onChange={e => {
                        setValue(`enabled.${modelId}`, e.target.checked, {
                          shouldValidate: true,
                        });
                        field.onChange(e);
                        setIsChanged(true);
                      }}
                      disabled={space && params.row.disabledByCompany}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </span>
                </Tooltip>
              )}
            />
          );
        }
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridRowModel>) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            padding: '10px',
          }}
        >
          <Typography
            whiteSpace={'normal'}
            fontSize={'12px'}
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              width: '80%',
            }}
          >
            {params.value}
          </Typography>
          {params.value && (
            <IconButton
              onClick={() => {
                setShowDescriptionModal(true);
                setSystemDescription(params.value);
                !system && setSystem(params.row.system);
              }}
            >
              <TextAlignLeft size={16} />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const rows = (technologies || []).map((row, index) => ({
    id: index + 1,
    system: row.system,
    models: row.models || [],
    enabled: row.models?.every(model => model.enabled),
    description: row.systemDescription || '',
  }));

  const filteredRows = rows.filter(row =>
    row.system.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const expandedRows = filteredRows.flatMap(row => [
    row,
    ...(expandedRowIds?.includes(row.id)
      ? row.models?.map((model: any, idx: number) => ({
          id: model.id,
          system: `(${(model.type).toUpperCase()})`,
          models: model.model,
          enabled: model.enabled,
          description: model.description,
          disabledByCompany: model.disabledByCompany,
        }))
      : []),
  ]);

  const onSave = async (values: FormValues) => {
    const changedModels = (technologies || []).flatMap(tech =>
      tech.models
        .filter(
          (model: any) =>
            values.enabled[model.id] !== initialEnabledState[model.id],
        )
        .map((model: any) => ({
          companyId: company?.id,
          spaceId: space?.id,
          modelName: model.model,
          enabled: values.enabled[model.id],
          id: model.id,
        })),
    );

    const enabledModels = changedModels.filter(model => model.enabled);
    const disabledModels = changedModels.filter(model => !model.enabled);
    setViewState('OrganizationsAndWorkspaces');
    setIsLoading(true);

    try {
      for (const model of enabledModels) {
        if (company) {
          await CompanyService.enableCompanyTechnology({
            companyId: model.companyId as string,
            modelName: model.modelName,
          });
        } else if (space) {
          await CompanySpacesService.enableSpaceTechnology({
            spaceId: model.spaceId as string,
            modelName: model.modelName,
          });
        }
        // updateTechnology(model.id, true);
      }

      for (const model of disabledModels) {
        if (company) {
          await CompanyService.disableCompanyTechnology({
            companyId: model.companyId as string,
            modelName: model.modelName,
          });
        } else if (space) {
          await CompanySpacesService.disableSpaceTechnology({
            spaceId: model.spaceId as string,
            modelName: model.modelName,
          });
        }
        // updateTechnology(model.id, false);
      }

      setNotification({
        type: 'success',
        message: 'Technologies updated successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Failed to update technologies',
      });
    } finally {
      const updatedCompanyTechnologies = technologies.map(tech => ({
        ...tech,
        models: tech.models?.map(model =>
          changedModels.find(changedModel => changedModel.id === model.id)
            ? { ...model, enabled: values.enabled[model.id], disabledByCompany: !values.enabled[model.id] }
            : model
        ),
      }));
      const updatedCompanySpaceTechnologies = technologies.map(tech => ({
        ...tech,
        models: tech.models?.map(model =>
          changedModels.find(changedModel => changedModel.id === model.id)
            ? { ...model, enabled: values.enabled[model.id] }
            : model
        ),
      }));
      setTechnologies(company ? updatedCompanyTechnologies : updatedCompanySpaceTechnologies);
      
      setReFetch(!reFetch);
      setIsChanged(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              position: 'relative',
              borderRadius: '4px',
              width: '75px',
              height: '75px',
              background: 'linear-gradient(90deg, #1436FF 0%, #396AFC 100%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="warning" />
          </Box>
        ) : company ? (
          company?.logo ? (
            <Box
              sx={{
                borderRadius: '4px',
                width: '75px',
                height: '75px',
                backgroundImage: `${`url(${company?.logo})`}`,
                backgroundSize: `${Boolean(company?.logo) ? 'cover' : '40%'}`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            ></Box>
          ) : (
            <>
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '4px',
                  width: '64px',
                  height: '64px',
                  background: '#3650DE',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '24px',
                  }}
                >
                  {company?.name.charAt(0).toUpperCase()}
                </Typography>
              </Box>
            </>
          )
        ) : space ? (
          <>
            <Box
              sx={{
                position: 'relative',
                borderRadius: '4px',
                width: '64px',
                height: '64px',
                background: '#661EFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '24px',
                }}
              >
                <Planet size={38} />
              </Typography>
            </Box>
          </>
        ) : null}

        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: '22px', width: '200px' }} />
        ) : (
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Typography
                sx={{
                  color: '#475467',
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '133.4%',
                }}
              >
                {company ? company?.name : space?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {company && (
                <Typography
                  sx={{
                    color: '#475467',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '133.4%',
                    letterSpacing: '0.1px',
                  }}
                >
                  {authUser?.plan} Plan&nbsp;•&nbsp;
                </Typography>
              )}
              <Typography
                sx={{
                  color: '#475467',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '133.4%',
                  letterSpacing: '0.1px',
                }}
              >
                {company
                  ? company?.companyUsers?.length === 0
                    ? '1'
                    : company?.companyUsers?.length
                  : space?.users}
                {company?.companyUsers?.length > 1 || space?.users > 1
                  ? ' Organization Users'
                  : ' Organization User'}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          marginTop: '54px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography fontWeight={500} fontSize={'20px'}>
                Enable or Disable Technologies
              </Typography>
              {/* <Info size={18} color="#667085" /> */}
            </Box>
            <TextField
              sx={{
                padding: 'var(--borderRadius, 4px) 24px',
                width: '297px',
                height: '34px',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MagnifyingGlass size={18} color="#667085" />
                  </InputAdornment>
                ),
              }}
              placeholder={'Search Technologies'}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </Box>
        </Box>
        <DataGrid
          rows={expandedRows}
          columns={columns}
          pageSizeOptions={[5, 10]}
          hideFooter
          rowHeight={56}
          autoHeight={true}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-filler': {
              width: '0%',
            },
            '& .MuiDataGrid-root': {
              width: '100%',
            },
            '& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root':
              {
                alignItems: 'baseline',
              },
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit(onSave)} disabled={!isChanged}>
            Save Changes
          </Button>
        </Box>
      </Box>

      <DescriptionModal
        show={showDescriptionModal}
        onHide={() => setShowDescriptionModal(false)}
        systemDescription={systemDescription}
        system={system}
      />
    </>
  );
};

export default TechnologyPermissions;
