import { Box, Typography } from '@mui/material';
import KitModal from '../UI/KitModal';

interface Props {
  show: boolean;
  onHide: () => void;
  technologies: any;
}

const TechnologiesModal = ({ show, onHide, technologies }: Props) => {

const enabled = technologies.every((technology: any) =>
    technology?.models?.every((model: any) => model.enabled)
);

  return (
    <KitModal
      show={show}
      onHide={onHide}
      sx={{
        '& .MuiPaper-root': { maxHeight: '600px', width: '700px' },
        '& .MuiDialogContent-root': {
          padding: '20px',
          paddingTop: '0px',
        },
      }}
      title={
        <Typography fontWeight={600} color={'black'}>
          {enabled ? 'Enabled Technologies' : 'Disabled Technologies'}
        </Typography>
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {technologies.map((technology: any) => (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              flexDirection: 'column',
              borderRadius: '8px',
              p: '16px',
              border: '1px solid #D0D5DD',
              background: '#F9FAFB',
              width: '95%',
            }}
          >
            <Typography fontWeight={500}>
              {technology.system.charAt(0).toUpperCase() +
                technology.system.slice(1)}
            </Typography>
            <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'row' }}>
              <Typography>
                {technology?.models?.map((model: any, index: number) => (
                  <span key={index}>
                    {model.model}
                    {index !== technology?.models?.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </KitModal>
  );
};

export default TechnologiesModal;
