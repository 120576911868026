import React, { useEffect, useState } from 'react';
import KitModal from '../UI/KitModal';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useNotificationStore } from '../../store/storeNotifications';
import { AuthService } from '../../service/AuthService';
import { useForm } from 'react-hook-form';
import KitInput from '../UI/KitInput';
import { CompanyService } from '../../service/CompanyService';
import { XCircle } from '@phosphor-icons/react';

interface Props {
  isChangeOrganizationNameModalOpen: boolean;
  setIsChangeOrganizationNameModalOpen: any;
  companyData: any;
}
interface FormDataTypes {
  name: string;
}

const ChangeOrganizationNameModal = ({
  isChangeOrganizationNameModalOpen,
  setIsChangeOrganizationNameModalOpen,
  companyData,
}: Props) => {
  const [company, setCompany] = useState<any>({});
  // console.log(company);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormDataTypes>();

  const setNotification = useNotificationStore(state => state.setNotification);

  // useEffect(() => {
  //   const getCompany = async () => {
  //     try {
  //       const res = await CompanyService.getCompanies();
  //       const resCompany = res?.data[0];
  //       setCompany(resCompany);
  //     } catch (error) {
  //       setNotification({
  //         type: 'error',
  //         message: 'Something went wrong',
  //       });
  //     }
  //   };
  //   getCompany();
  // }, [setNotification]);

  const handleCompanyName = async (values: FormDataTypes) => {
    try {
      const res = await CompanyService.updateCompany(
        {
          name: values.name,
        },
        companyData?.id,
      );
      setCompany(res.data);
      setNotification({
        type: 'success',
        message: 'Name changed successfully',
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsChangeOrganizationNameModalOpen(false);
    }
  };

  return (
    <Modal
      open={isChangeOrganizationNameModalOpen}
      onClose={() => setIsChangeOrganizationNameModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit(handleCompanyName)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            padding: '24px',
            gap: '8px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ marginBottom: '10px' }}
            >
              Edit Organization Name
            </Typography>
            <IconButton
              onClick={() => setIsChangeOrganizationNameModalOpen(false)}
            >
              <XCircle size={24} color="#667085" />
            </IconButton>
          </Box>

          <KitInput
            register={register}
            name="name"
            type="name"
            defaultValue={companyData?.name}
            label="Organization Name"
            placeholder="Enter organization name"
            validation={{
              required: {
                value: true,
                message: 'Name is required',
              },
            }}
            errorsStatus={errors}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ width: '100%', mt: '20px' }}
            disabled={Object.keys(errors).length > 0}
          >
            Continue
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ChangeOrganizationNameModal;
