import React from 'react';
import SpacesModal from '../../../Spaces/SpacesModal';
import { Button, TextField, Typography } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../../../store/storeNotifications';

interface Props {
  spaceName: string;
  spaceEmail: string;
  onClose: () => void;
}

const CopySpaceEmailModal: React.FC<Props> = ({
  spaceName,
  spaceEmail,
  onClose,
}) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const copySpaceEmail = async () => {
    if (!spaceEmail) {
      setNotification({
        type: NotificationType.Error,
        message: 'Space Email not generated',
      });

      onClose();
      return;
    }

    try {
      navigator.clipboard.writeText(spaceEmail);
      setNotification({
        type: NotificationType.Success,
        message: 'Space Email copied successfully',
      });
      onClose();
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: 'Something went wrong',
      });
    }
  };
  return (
    <SpacesModal
      modalTitle={`Copy ${spaceName} space Email`}
      onClose={onClose}
      footer={
        <>
          <Button fullWidth variant="contained" onClick={copySpaceEmail}>
            Copy Space Email
          </Button>
        </>
      }
    >
      <Typography
        sx={{
          color: 'rgba(71, 84, 103, 1)',
          textAlign: 'center',
        }}
      >
        To share flows into this Org Space, copy and paste this email into the
        sharing pop up window of the flow.
      </Typography>

      <TextField
        label="Space Email"
        variant="outlined"
        fullWidth
        disabled
        sx={{ marginTop: '20px' }}
        value={spaceEmail}
      />
    </SpacesModal>
  );
};

export default CopySpaceEmailModal;
