import { Box, Button, Typography } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { Product } from '../../../util/Types/Product';
import Tap from '@tapfiliate/tapfiliate-js';
import { useAuthStore } from '../../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../../store/storeNotifications';
import { AuthService } from '../../../service/AuthService';
import { getAPIErrorMessage } from '../../../helpers/helpers';

interface Props {
  planName: string | undefined;
  setIsPurchased: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Product | undefined>>;
}
const PaymentSuccesfully = ({
  planName,
  setIsPurchased,
  setSelectedPlan,
}: Props) => {
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const setNotification = useNotificationStore(state => state.setNotification);

  const submitHandler = async () => {
    try {
      const res = await AuthService.getMe();
      setAuthUser(res.data);
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '500px',
        overflow: 'hidden',
      }}
    >
      <ReactConfetti
        width={dimensions.width}
        height={dimensions.height}
        numberOfPieces={500}
        recycle={false}
        run={true}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <CheckCircle size={127} color="#4CAF50" />
        <Typography
          fontSize={'34px'}
          fontWeight={500}
          lineHeight={'42px'}
          color={'#101828'}
        >
          Congrats!
        </Typography>
        <Typography fontSize={'24px'} lineHeight={'32px'} color={'#475467'}>
          You have successfully purchased the {planName}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setSelectedPlan(undefined);
            setIsPurchased(false);
            submitHandler();
          }}
        >
          Go Back to Pricing Page
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSuccesfully;
