import { useState } from 'react';
import {
  AiTextGeneratorFormValues,
  groqFields,
  groqModels,
} from '../AiConfigs/constants';
import {
  Box,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { PrettoSlider } from '../../assets/styles/styles';
import { useAuthStore } from '../../store/storeAuth';
import IconButtonTooltip from '../UI/IconButtonTooltip/IconButtonTooltip';
import { Info } from '@phosphor-icons/react';

type Props = {
  watch: AiTextGeneratorFormValues;
  onChange: (e: any) => void;
  systems?: any[];
};

const modelInputBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '4px',
};

const GroqAi = ({ onChange, watch, systems }: Props) => {
  const [isAutoSetting, setIsAutoSetting] = useState(watch.autoToken);
  const { user } = useAuthStore(state => state);

  return (
    <Box>
      <Box mt={'4px'}>
        <FormLabel
          sx={{
            mb: '4px',
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          htmlFor="model"
        >
          Ai Model
        </FormLabel>
        <Select
          className="nodrag"
          name="groqModel"
          fullWidth
          size="small"
          defaultValue={'gpt-4'}
          value={watch.groqModel}
          sx={{
            height: 'unset',
            '& .MuiList-root': {
              backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
            border: `2px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`,
          }}
          onChange={onChange}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor:
                    user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                },
                backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              },
            },
            style: {
              maxHeight: '700px',
            },
          }}
        >
          {systems
            ?.filter((item: any) => item.system === 'groq')[0]
            .models?.map((opt: any) => (
              <MenuItem
                key={opt.id}
                value={opt.model}
                disabled={opt.disabledByCompany}
              >
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  <Typography
                    variant="body2"
                    color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
                  >
                    {opt.model}
                  </Typography>
                  {opt.disabledByCompany && (
                    <Typography fontSize={'12px'} color="red">
                      (disabled by admin)
                    </Typography>
                  )}
                </Box>
              </MenuItem>
            ))}
        </Select>
      </Box>

      {groqFields.map(opt => {
        const optName = opt.name;
        if (opt.name === 'groqMaxTokens') {
          return (
            <Box
              sx={{
                ...modelInputBoxStyle,
                gap: '30px',
                alignItems: 'center',
                mt: '4px',
              }}
              key={optName}
            >
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={e => {
                        onChange({
                          target: {
                            name: 'autoToken',
                            value: !watch.autoToken,
                          },
                        });

                        setIsAutoSetting(!watch.autoToken);
                      }}
                      name="autoToken"
                      checked={watch.autoToken}
                      sx={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    />
                  }
                  label="Auto setting"
                />
              </Box>
              <>
                <FormLabel htmlFor="tokens">{opt.label}</FormLabel>
                <TextField
                  type={opt.type}
                  style={{ marginLeft: '8px', width: '100px' }}
                  onChange={onChange}
                  value={isAutoSetting ? 0 : watch.groqMaxTokens}
                  disabled={isAutoSetting}
                  className="nodrag"
                  name={opt.name}
                  InputProps={{
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </>
            </Box>
          );
        }
        return (
          <Box key={opt.name}>
            <FormLabel
              htmlFor={opt.name}
              sx={{
                color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
              }}
            >
              {opt.label}
            </FormLabel>
            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <FormLabel htmlFor={opt.name}>
                {watch?.[optName as keyof AiTextGeneratorFormValues]}
              </FormLabel>

              <PrettoSlider
                step={Number(opt.step)}
                min={Number(opt.min)}
                max={Number(opt.max)}
                name={opt.name}
                value={Number(
                  watch?.[optName as keyof AiTextGeneratorFormValues],
                )}
                onChange={onChange}
                className="nodrag"
                valueLabelDisplay="auto"
              />
            </Box>
          </Box>
        );
      })}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormLabel
          sx={{
            mb: '4px',
            bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          }}
          htmlFor="groqStop"
        >
          Groq Stop
        </FormLabel>
        <IconButtonTooltip
          title={`  A stop sequence is a predefined or user-specified text string that
 signals an AI to stop generating content, ensuring its responses
 remain focused and concise. Examples include punctuation marks and
 markers like "[end]"`}
        >
          <Info size={20} />
        </IconButtonTooltip>
      </Box>
      <TextField
        type={'text'}
        fullWidth
        onChange={onChange}
        value={watch.groqStop}
        className="nodrag"
        name={'groqStop'}
        InputProps={{
          style: {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
        }}
        sx={{
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
          border: `2px solid ${user?.theme === 'dark' ? '#475467' : '#EAECF0'}`,
        }}
      />
    </Box>
  );
};

export default GroqAi;
