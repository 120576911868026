import { Box, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { aspectRatioIdeogram } from '../AiConfigs/constants';
import {
  FormData,
  ideogramResolutions,
  ideogramStyleTypes,
  magicPromptTypes,
} from './constants';
import KitRanger from '../UI/KitRanger';

type Props = {
  formData: FormData;
  onChange: (e: any) => void;
};

const IdeogramAi = ({ formData, onChange }: Props) => {
  const ideogramModels = ['V_2_TURBO', 'V_2'];
  return (
    <>
      <Box>
        <Box>
          <FormLabel htmlFor="ideogramModel">Ideogram models</FormLabel>
          <Select
            name="ideogramModel"
            className="nodrag"
            fullWidth
            value={formData.ideogramModel}
            onChange={onChange}
          >
            <MenuItem disabled value="noValue">
              {'Choose an Engine'}
            </MenuItem>
            {ideogramModels?.map((model, index) => {
              return (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box>
          <InputLabel htmlFor="type">Aspect Ratio </InputLabel>

          <Select
            name="aspect_ratio_ideogram"
            className="nodrag"
            value={formData.aspect_ratio_ideogram || ''}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value={''}></MenuItem>
            {aspectRatioIdeogram?.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel htmlFor="type">Style Type</InputLabel>

          <Select
            name="style_type"
            className="nodrag"
            value={formData.style_type || ''}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value={''}></MenuItem>
            {ideogramStyleTypes?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel htmlFor="type">Resolution</InputLabel>

          <Select
            name="resolution"
            className="nodrag"
            value={formData.resolution || ''}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value={''}></MenuItem>
            {ideogramResolutions?.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <InputLabel htmlFor="type">Magic Prompt</InputLabel>

          <Select
            name="magicPrompt"
            className="nodrag"
            value={formData.magicPrompt || ''}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value={''}></MenuItem>
            {magicPromptTypes?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <KitRanger
          value={formData.image_weight}
          onChange={onChange}
          name="image_weight"
          label="Image Weight"
          min={50}
          max={500}
          step={1}
        />
      </Box>
    </>
  );
};

export default IdeogramAi;
