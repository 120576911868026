import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  WatchInternal,
} from 'react-hook-form';
import {
  openAiInputs,
  optionsArray,
  systemDisplayNameMap,
  systemValueMap,
} from '../../AiConfigs/constants';
import KitInput from '../../UI/KitInput';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  marginLeft: '10px',
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

interface Props {
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  systemType: string;
  setSystemType: React.Dispatch<React.SetStateAction<string>>;
  watch: WatchInternal<any>;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  systems: any[];
}

const OpenAiConfigForm = ({
  errors,
  register,
  setSystemType,
  setValue,
  systemType,
  control,
  watch,
  systems,
}: Props) => {

  const openAiSystem = systems?.filter((item: any) => item.system === 'openAi')[0];

  const modelDisabledMap: { [key: string]: boolean } = {};

  openAiSystem?.models?.forEach((item: any) => {
    modelDisabledMap[item.model] = item.enabled === false;
  });

  const modelDisabledByAdminMap: { [key: string]: boolean } = {};

  openAiSystem?.models?.forEach((item: any) => {
    modelDisabledByAdminMap[item.model] = item.disabledByAdmin;
  });


  return (
    <Box>
      {/* <KitInput
        label='Name'
        name='name'
        type='text'
        errorsStatus={errors}
        register={register}
      /> */}
      <Box
        mt={'16px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '32px',
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Ai System</InputLabel>
          <Select
            name="AiSystem"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            value={systemType}
            onChange={e => {
              setSystemType(e.target.value);
            }}
            sx={{
              width: '100%',
            }}
          >
            {systems?.map((opt, index) => {
              const disabled = opt.models?.every(
                (model: any) => model.disabledByCompany,
              );
              const systemValue = systemValueMap[opt.system] || opt.system;
              const displayName =
                systemDisplayNameMap[systemValue] || systemValue;
              return (
                <MenuItem key={index} value={systemValue} disabled={disabled}>
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            width: '100%',
          }}
        >
          <InputLabel id="demo-simple-select-label">Ai Model</InputLabel>
          <Select
            value={watch().aiModel}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Ai System"
            {...register('aiModel')}
            sx={{
              width: '100%',
            }}
          >
            {optionsArray.map((opt, index) => (
              <MenuItem
                key={index}
                value={opt.value}
                disabled={modelDisabledMap[opt.value]}
              >
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginY: '16px',
        }}
      />

      <Controller
        name="autoToken"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            sx={{
              margin: 0,
            }}
            control={<AntSwitch {...field} checked={watch().autoToken} />}
            label="Auto Token"
            labelPlacement="start"
          />
        )}
      />
      <KitInput
        label="Ai Tokens"
        name="aiTokens"
        type="number"
        errorsStatus={errors}
        register={register}
        sxStyle={{
          my: '16px',
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        {openAiInputs.map(field => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography fontSize={'14px'} fontWeight={500}>
              {field.label}
            </Typography>
            <Slider
              {...register(field.name)}
              step={Number(field.step)}
              min={Number(field.min)}
              max={Number(field.max)}
              value={Number(watch()?.[field.name])}
              onChange={(event, value) => setValue(field.name, value)}
            />
            <Typography
              fontSize={'14px'}
              fontWeight={400}
              color={'rgba(0, 0, 0, 0.60)'}
            >
              {watch()?.[field.name]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OpenAiConfigForm;
