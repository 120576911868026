import React from 'react';
import UserPlanManagement from './UserPlanManagement';

const UserPlanManagementPage: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#3650DE', minHeight: '100vh' }}>
      <h1 style={{ color: '#fff', marginTop: 0, textAlign: 'center', paddingTop: '20px' }}>User Plan Management</h1>
      <UserPlanManagement />
    </div>
  );
};

export default UserPlanManagementPage;