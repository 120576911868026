import { useCallback, useEffect, useRef, useState } from 'react';
import './Sidebar.scss';
import FormBuilderFlow from './FormBuilderFlow/FormBuilderFlow';
import {
  Node,
  NodeChange,
  OnNodesChange,
  ProOptions,
  ReactFlowProvider,
  applyNodeChanges,
} from 'reactflow';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import FormSidebar from './FormSidebar';
import { Box } from '@mui/material';
import { useAuthStore } from '../../store/storeAuth';
import { getNodePositionInsideParent } from '../../util/getNodePositionInsideParent';
import { getNodeId } from '../../util/myFunctionsCoreDisplay';
import { sortNodes } from '../../util/sortNodes';
import PreviewModal from './PreviewModal/PreviewModal';
import { FormsService } from '../../service/FormService';
import { useParams } from 'react-router-dom';
import 'reactflow/dist/style.css';
import { getHelperLines } from './utilis';
import Header from './FormComponents/Header';
import FormHeaderTools from './FormComponents/FormHeaderTools';
import useCopyPaste from './useCopyPaste';
const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

const FormBuilder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [formPreviewOpen, setFormPreviewOpen] = useState(false);
  const { user } = useAuthStore(state => state);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [nodes, setNodes] = useState<any[]>([]);
  const [form, setForm] = useState<any>({});
  const [dataSaved, setDataSaved] = useState('');

  const [undoRedo, setUndoRedo] = useState<{
    index: number;
    nodes: any[];
  }>({
    index: 0,
    nodes: [],
  });

  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const params = useParams();
  const [_, setAddNodeLoading] = useState(false);

  const [flow, setFlow] = useState<any>();
  const [flows, setFlows] = useState<any>();
  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined);
  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined);

  const dateObject = new Date(dataSaved);
  const formattedTime = dateObject.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  const formattedDate = dateObject.toLocaleDateString('en-US');

  useEffect(() => {
    const getFlow = async () => {
      setIsLoading(true);
      try {
        const response = await FormsService.getForm(params.formId!);

        const nodes = response.data.nodes;

        if (
          response?.data?.activeFlowId &&
          response?.data?.activeFlows?.length === 0
        ) {
          await FormsService.update(params?.formId!, {
            activeFlows: [response?.data?.activeFlowId],
            activeFlowId: null,
          });

          setForm({
            ...response?.data,
            activeFlows: [response?.data?.activeFlowId],
            activeFlowId: null,
          });
        } else {
          setForm(response?.data);
        }

        const flowsButtons = response?.data?.flows
          ?.map((flow: any) => {
            return {
              id: flow.id,
              name: flow.name,
            };
          })
          .flat();

        const varInputNodes = response?.data?.flows
          ?.map((flow: any) => {
            const getVarInputNodes = flow?.nodes
              ?.filter((nd: any) => nd.type === 'varInputNode')
              ?.map((nd: any) => {
                return {
                  id: nd.id,
                  label: nd.data.label,
                  flowName: flow.name,
                };
              });
            return getVarInputNodes;
          })
          .flat();

        const varOutputNodes = response?.data?.flows
          ?.map((flow: any) => {
            const getVarInputNodes = flow?.nodes
              ?.filter((nd: any) => nd.type === 'outputObjectNode')
              ?.map((nd: any) => {
                return {
                  id: nd.id,
                  label: nd.data.label,
                  flowName: flow.name,
                };
              });
            return getVarInputNodes;
          })
          .flat();

        const mapNodes = nodes.map((node: any) => {
          if (node.type === 'inputDisplay' || node?.type === 'listNode') {
            return {
              ...node,
              data: {
                ...node.data,
                varInputNodes: varInputNodes,
              },
            };
          }

          if (node.type === 'submit') {
            return {
              ...node,
              data: {
                ...node.data,
                flowsButtons: flowsButtons,
              },
            };
          }

          if (node.type === 'outputDisplay') {
            return {
              ...node,
              data: {
                ...node.data,
                varOutputNodes: varOutputNodes,
              },
            };
          }

          return {
            ...node,
          };
        });

        setUndoRedo({
          nodes: [mapNodes],
          index: 0,
        });
        setFlows(response?.data?.flows);
        setNodes(mapNodes);
        setFlow(response?.data?.flow);
        setDataSaved(response?.data?.lastSaved || response?.data?.updatedAt);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.id && params.formId) {
      getFlow();
    }
  }, [params.formId, user?.id]);

  const onSave = async () => {
    try {
      const response = await FormsService.update(params?.formId!, {
        nodes,
        lastSaved: new Date().toISOString(),
      });

      setDataSaved(response?.data?.lastSaved || response?.data?.updatedAt);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const onAddFlowOrRemove = async (activeFlows: string[]) => {
    setAddNodeLoading(true);
    try {
      await FormsService.update(params?.formId!, {
        activeFlows,
      });

      const response = await FormsService.getForm(params?.formId!);

      const varInputNodes = response?.data?.flows
        ?.map((flow: any) => {
          const getVarInputNodes = flow?.nodes
            ?.filter((nd: any) => nd.type === 'varInputNode')
            ?.map((nd: any) => {
              return {
                id: nd.id,
                label: nd.data.label,
                flowName: flow.name,
              };
            });
          return getVarInputNodes;
        })
        .flat();

      const varOutputNodes = response?.data?.flows
        ?.map((flow: any) => {
          const getVarInputNodes = flow?.nodes
            ?.filter((nd: any) => nd.type === 'outputObjectNode')
            ?.map((nd: any) => {
              return {
                id: nd.id,
                label: nd.data.label,
                flowName: flow.name,
              };
            });
          return getVarInputNodes;
        })
        .flat();

      const flowsButtons = response?.data?.flows
        ?.map((flow: any) => {
          return {
            id: flow.id,
            name: flow.name,
          };
        })
        .flat();

      const mapNodes = nodes.map((node: any) => {
        if (node.type === 'inputDisplay' || node?.type === 'listNode') {
          return {
            ...node,
            data: {
              ...node.data,
              varInputNodes: varInputNodes,
            },
          };
        }

        if (node.type === 'submit') {
          return {
            ...node,
            data: {
              ...node.data,
              flowsButtons: flowsButtons,
            },
          };
        }

        if (node.type === 'outputDisplay') {
          return {
            ...node,
            data: {
              ...node.data,
              varOutputNodes: varOutputNodes,
            },
          };
        }
        return node;
      });

      setFlows(response?.data?.flows);
      setNodes(mapNodes);
    } catch (error) {
    } finally {
      setAddNodeLoading(false);
    }
  };

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      if (changes?.[0]?.type !== 'dimensions') {
        // eslint-disable-next-line array-callback-return
        const mappedChanges = changes?.map((change: any) => {
          if (change.type === 'add' || change.type === 'reset') {
            return change.item;
          }
        });

        const filteredChanges = mappedChanges?.filter(
          (change: any) => change !== undefined,
        );

        let undoNodes = undoRedo.nodes;

        if (undoNodes?.length === 10) {
          undoNodes.shift();
        }
        if (filteredChanges?.length > 0) {
          undoNodes.push(filteredChanges);
        }

        setUndoRedo({
          nodes: [...undoNodes],
          index:
            undoRedo?.index === 0 && undoNodes?.length === 1
              ? 0
              : undoNodes?.length - 1,
        });
      }

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, nodes);
    },
    [undoRedo],
  );

  const onPreview = (type: string) => {
    if (undoRedo?.nodes.length > 0) {
      const findIndex = undoRedo?.index;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < undoRedo?.nodes.length) {
          setNodes(undoRedo?.nodes[nextIndex]);
          setUndoRedo({
            nodes: undoRedo?.nodes,
            index: nextIndex,
          });
        }
      }
    }
  };

  const onDragOver = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { dropEffect: string };
    }) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    },
    [],
  );

  const onNodesChange: OnNodesChange = useCallback(
    changes => {
      setNodes(nodes => customApplyNodeChanges(changes, nodes));
    },
    [setNodes, customApplyNodeChanges],
  );
  const onDrop = (event: {
    preventDefault: () => void;
    dataTransfer: { getData: (arg0: string) => any };
    clientX: number;
    clientY: number;
  }) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');

    const parseType = JSON?.parse(type);
    const position = reactFlowInstance?.project({
      x: event.clientX - (reactFlowBounds?.left || 0),
      y: event.clientY - (reactFlowBounds?.top || 0),
    });

    const intersections = reactFlowInstance
      ?.getIntersectingNodes({
        x: position?.x,
        y: position?.y,
        width: 40,
        height: 40,
      })
      .filter((n: { type: string }) => n.type === 'nodesGroup');

    const groupNode = intersections[0];
    const newType = {
      ...parseType,
      position,
    };

    if (groupNode) {
      newType.position = getNodePositionInsideParent(
        {
          position,
          width: 40,
          height: 40,
        },
        groupNode,
      ) ?? { x: 0, y: 0 };
      newType.parentNode = groupNode?.id;
      newType.extent = groupNode ? 'parent' : undefined;
    }

    const myId = getNodeId(newType.type);

    const nameChecking = (nds: any[], type: string, nodeType: string) => {
      const filterNodes = nds?.filter(node => node.type === nodeType);

      if (filterNodes.length > 0) {
        const existingLabels = filterNodes.map(node => node.data.label);
        const uniqueLabel = generateUniqueLabel(type, existingLabels);
        return uniqueLabel;
      }

      return type;
    };
    const generateUniqueLabel = (label: string, existingLabels: string[]) => {
      let count = 1;
      let uniqueLabel = label;

      while (existingLabels.includes(uniqueLabel)) {
        uniqueLabel = `${label} ${count}`;
        count++;
      }

      return uniqueLabel;
    };
    const newNode = {
      id: myId,
      type: newType.type,
      position: position,
    };

    const getVarInputNodes = flows
      ?.map((flow: any) => {
        const getVarInputNodes = flow?.nodes
          ?.filter((nd: any) => nd.type === 'varInputNode')
          ?.map((nd: any) => {
            return {
              id: nd.id,
              label: nd.data.label,
              flowName: flow.name,
            };
          });
        return getVarInputNodes;
      })
      .flat();
    const varOutputNodes = flows
      ?.map((flow: any) => {
        const getVarInputNodes = flow?.nodes
          ?.filter((nd: any) => nd.type === 'outputObjectNode')
          ?.map((nd: any) => {
            return {
              id: nd.id,
              label: nd.data.label,
              flowName: flow.name,
            };
          });
        return getVarInputNodes;
      })
      .flat();

    const flowsButtons = flows
      ?.map((flow: any) => {
        return {
          id: flow.id,
          name: flow.name,
        };
      })
      .flat();

    let object = {};

    if (newType.type === 'inputDisplay' || newType.type === 'listNode') {
      object = {
        varInputNodes: getVarInputNodes,
      };
    }

    if (newType.type === 'outputDisplay') {
      object = {
        varOutputNodes: varOutputNodes,
      };
    }

    if (newType.type === 'submit') {
      object = {
        flowsButtons: flowsButtons,
      };
    }

    setNodes((nds: any) => {
      const label = nameChecking(nds, newType.label, newType.type); // Ensure a unique label for the node
      const widthData = {
        w: '100%',
        maxW: '1000px',
        width: 1050,
      };

      const newNodeData = {
        isEmptyState: true,
        label: label,
        myId: myId,
        myType: newType.myType,
        title: newType.title,
        widthData,
        ...object,
      };

      const updatedNodes = nds
        .concat({
          ...newNode,
          data: newNodeData,
          width: widthData.width,
          style: {
            width: widthData.width,
          },
        })
        .sort(sortNodes);

      setUndoRedo({
        nodes: [...undoRedo?.nodes, updatedNodes],
        index: undoRedo?.nodes.length,
      });

      return updatedNodes;
    });
  };

  const hasChanges =
    undoRedo?.nodes?.length > 1 &&
    undoRedo?.index > 0 &&
    form?.status === 'published';

  if (isLoading) {
    return <LoadingSpinner height="100vh" />;
  }

  const myObject = {
    data: {
      isEmptyState: true,
    },
    dragging: false,
    id: 'lines-node-0',
    position: {
      x: 210,
      y: -435,
    },
    positionAbsolute: {
      x: 210,
      y: -435,
    },
    selected: false,

    type: 'linesNode',
  };

  return (
    <Box width={'100%'} height={'100%'} position={'relative'}>
      <Header
        flow={flow}
        form={form}
        formattedDate={formattedDate}
        formattedTime={formattedTime}
        onPreview={onPreview}
        onSave={onSave}
      />
      <FormHeaderTools
        flow={flow}
        form={form}
        hasChanges={hasChanges}
        nodes={nodes}
        openPublishModal={openPublishModal}
        openAccessModal={openAccessModal}
        openShareModal={openShareModal}
        onSave={onSave}
        setFormPreviewOpen={setFormPreviewOpen}
        setOpenPublishModal={setOpenPublishModal}
        setOpenAccessModal={setOpenAccessModal}
        onAddFlowOrRemove={onAddFlowOrRemove}
        setOpenShareModal={setOpenShareModal}
        setUndoRedo={setUndoRedo}
        setForm={setForm}
        setOpenSettingsModal={setOpenSettingsModal}
        openSettingsModal={openSettingsModal}
      />
      <FormSidebar
      // onClickAddNode={onClickAddNode}
      // onGenerateApi={onGenerateApi}
      // flowId={flowId!}
      />
      <Box
        sx={{
          gap: '40px',
          height: '84%',
          background: '#F2F4F7',
        }}
        className="form-builder"
      >
        <PreviewModal
          show={formPreviewOpen}
          form={{
            ...form,
            formStyle: {
              ...form?.formStyle,
              backgroundColor:
                form?.formStyle?.backgroundColor ?? 'rgb(231, 240, 255)',
              backgroundImage: form?.formStyle?.backgroundImage || '',
              horizontalSettings: {
                enabled: form?.formStyle?.horizontalSettings?.enabled ?? true,
                gap: form?.formStyle?.horizontalSettings?.gap ?? 16,
              },
              verticalSettings: {
                enabled: form?.formStyle?.verticalSettings?.enabled ?? true,
                gap: form?.formStyle?.verticalSettings?.gap ?? 16,
              },
            },
          }}
          onHide={() => setFormPreviewOpen(false)}
          nodes={[...nodes?.filter((node: any) => node.type !== 'linesNode')]}
          flows={flows}
          flow={flow}
        />

        <div
          style={{
            maxWidth: '100%',
            width: '100%',
            height: '100%',
          }}
        >
          <ReactFlowProvider>
            {isLoading && <LoadingSpinner height="100vh" />}
            {!isLoading && (
              <div
                style={{
                  maxWidth: '100%',
                  width: '100%',
                  height: '100%',
                }}
                className="reactflow-wrapper"
                ref={reactFlowWrapper}
              >
                <FormBuilderFlow
                  nodes={[myObject, ...nodes]}
                  onNodesChange={onNodesChange}
                  proOptions={proOptions}
                  customApplyNodeChanges={customApplyNodeChanges}
                  setReactFlowInstance={setReactFlowInstance}
                  onDragOver={onDragOver}
                  setForm={setForm}
                  onDrop={onDrop}
                  form={form}
                  helperLineHorizontal={helperLineHorizontal}
                  helperLineVertical={helperLineVertical}
                  // updateNodeEdge={updateNodeEdge}
                  // setUpdateNodeEdge={setUpdateNodeEdge}
                  // onNodeDragStop={onNodeDragStop}
                  // user={user}
                  // isValidConnection={isValidConnection}
                />
              </div>
            )}
          </ReactFlowProvider>
        </div>
      </Box>
    </Box>
  );
};

export default FormBuilder;
