import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ChatBot.scss';
import { useAuthStore } from '../../store/storeAuth';
import { FluxService } from '../../service/FluxService';
import { FlowService } from '../../service/FlowService';
import LoadingSpinner from '../UI/LoadingSpinner';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import {
  ArrowUp,
  Chats,
  Copy,
  Gear,
  NotePencil,
  Plus,
  StarFour,
  Trash,
} from '@phosphor-icons/react';
import useAutosizeTextArea from './useAutoSizeTextArea';
import { useStoreTabs } from '../../store/storeTabs';
import { useNotificationStore } from '../../store/storeNotifications';
import { processTextSegments } from '../../util/markdown';
import ChatBotSessionModal from './ChatBotSessionModal';
import {
  differenceInDays,
  format,
  isToday,
  isYesterday,
  parseISO,
  setYear,
} from 'date-fns';
import OutputMarkDown from '../OutputMarkdown/OutputMarkDown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

function generateRandomId(length = 40) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

const icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117"
    height="14"
    viewBox="0 0 117 14"
    fill="none"
  >
    <path
      d="M43.149 13.3175H40.7528L37.5227 8.05982L34.2751 13.3175H32.0193L36.2851 6.66422L32.3089 0.484955H34.6349L43.149 13.3175Z"
      fill="#3650DE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2124 2.73196H41.4011L42.8391 0.484955H40.5833L39.2124 2.73196Z"
      fill="#3650DE"
    />
    <path
      d="M2.64675 13.3175H0.557739V0.484955H7.80784V2.25798H2.64675V6.26046H7.4743V8.02471H2.64675V13.3175Z"
      fill="#3650DE"
    />
    <path
      d="M10.3673 13.3175V0.484955H12.4739V11.5269H17.9246V13.3175H10.3673Z"
      fill="#3650DE"
    />
    <path
      d="M30.4464 0.484955V8.78834C30.4464 9.68363 30.2562 10.4853 29.8759 11.1933C29.5014 11.9014 28.9338 12.4631 28.1731 12.8786C27.4124 13.2882 26.4556 13.493 25.3029 13.493C23.6586 13.493 22.4063 13.0629 21.5462 12.2027C20.6918 11.3367 20.2647 10.1869 20.2647 8.75323V0.484955H22.3712V8.5689C22.3712 9.63974 22.6229 10.4297 23.1261 10.9388C23.6293 11.4479 24.3812 11.7024 25.3819 11.7024C26.0724 11.7024 26.6341 11.5825 27.0671 11.3425C27.506 11.0968 27.8278 10.7398 28.0326 10.2717C28.2433 9.79773 28.3486 9.22721 28.3486 8.56013V0.484955H30.4464Z"
      fill="#3650DE"
    />
    <path
      d="M45.8034 13.3019C45.5997 13.3019 45.4978 13.182 45.4978 12.9423V1.06019C45.4978 0.820508 45.6176 0.700668 45.8573 0.700668H50.8367C52.0351 0.700668 52.9219 0.9763 53.4971 1.52757C54.0724 2.07883 54.36 2.83982 54.36 3.81052C54.36 4.79321 54.0724 5.56019 53.4971 6.11145C52.9219 6.66272 52.0351 6.93835 50.8367 6.93835H46.109V12.9423C46.109 13.182 46.0071 13.3019 45.8034 13.3019ZM46.109 6.30919H50.8367C51.8314 6.30919 52.5624 6.10546 53.0298 5.698C53.4971 5.29055 53.7308 4.66139 53.7308 3.81052C53.7308 2.95966 53.4971 2.33649 53.0298 1.94101C52.5624 1.53356 51.8314 1.32983 50.8367 1.32983H46.109V6.30919Z"
      fill="#3650DE"
    />
    <path
      d="M57.2982 13.3019C57.0945 13.3019 56.9926 13.182 56.9926 12.9423V1.06019C56.9926 0.820508 57.1125 0.700668 57.3522 0.700668H62.3315C63.5299 0.700668 64.4167 0.9763 64.992 1.52757C65.5672 2.07883 65.8548 2.83982 65.8548 3.81052C65.8548 4.78123 65.5732 5.54221 65.0099 6.09348C64.4587 6.63276 63.5958 6.91438 62.4214 6.93835L65.6751 12.8704C65.735 12.9903 65.753 13.0921 65.729 13.176C65.705 13.2599 65.6451 13.3019 65.5492 13.3019H65.4773C65.2736 13.3019 65.1298 13.218 65.0459 13.0502L61.6844 6.93835H57.6038V12.9423C57.6038 13.182 57.502 13.3019 57.2982 13.3019ZM57.6038 6.30919H62.3315C63.3262 6.30919 64.0572 6.10546 64.5246 5.698C64.992 5.29055 65.2257 4.66139 65.2257 3.81052C65.2257 2.95966 64.992 2.33649 64.5246 1.94101C64.0572 1.53356 63.3262 1.32983 62.3315 1.32983H57.6038V6.30919Z"
      fill="#3650DE"
    />
    <path
      d="M73.695 13.4996C72.5685 13.4996 71.5799 13.23 70.729 12.6907C69.8781 12.1514 69.213 11.3964 68.7337 10.4257C68.2663 9.44301 68.0326 8.29854 68.0326 6.99228C68.0326 5.68602 68.2663 4.54754 68.7337 3.57683C69.213 2.60613 69.8781 1.85113 70.729 1.31185C71.5799 0.760587 72.5685 0.484955 73.695 0.484955C74.8215 0.484955 75.8102 0.760587 76.6611 1.31185C77.5119 1.85113 78.1711 2.60613 78.6384 3.57683C79.1178 4.54754 79.3575 5.68602 79.3575 6.99228C79.3575 8.29854 79.1178 9.44301 78.6384 10.4257C78.1711 11.3964 77.5119 12.1514 76.6611 12.6907C75.8102 13.23 74.8215 13.4996 73.695 13.4996ZM73.695 12.8884C74.6657 12.8884 75.5286 12.6547 76.2836 12.1874C77.0506 11.72 77.6498 11.0489 78.0812 10.174C78.5126 9.28722 78.7283 8.22663 78.7283 6.99228C78.7283 5.75793 78.5126 4.70333 78.0812 3.8285C77.6498 2.95366 77.0506 2.28256 76.2836 1.81518C75.5286 1.3478 74.6657 1.11412 73.695 1.11412C72.7243 1.11412 71.8555 1.3478 71.0885 1.81518C70.3335 2.28256 69.7343 2.95366 69.2909 3.8285C68.8595 4.70333 68.6438 5.75793 68.6438 6.99228C68.6438 8.22663 68.8595 9.28722 69.2909 10.174C69.7343 11.0489 70.3335 11.72 71.0885 12.1874C71.8555 12.6547 72.7243 12.8884 73.695 12.8884Z"
      fill="#3650DE"
    />
    <path
      d="M82.4647 13.3019C82.2609 13.3019 82.1591 13.182 82.1591 12.9423V1.07816C82.1591 0.838484 82.2729 0.718643 82.5006 0.718643C82.6804 0.718643 82.8242 0.79654 82.9321 0.952332L87.444 7.4237L91.938 0.952332C92.0459 0.79654 92.1957 0.718643 92.3874 0.718643C92.6271 0.718643 92.747 0.838484 92.747 1.07816V12.9423C92.747 13.182 92.6391 13.3019 92.4234 13.3019C92.2077 13.3019 92.0998 13.182 92.0998 12.9423V1.79721L87.7676 8.08882C87.6597 8.24461 87.5579 8.3225 87.462 8.3225C87.3542 8.3225 87.2463 8.24461 87.1384 8.08882L82.7703 1.79721V12.9423C82.7703 13.182 82.6684 13.3019 82.4647 13.3019Z"
      fill="#3650DE"
    />
    <path
      d="M96.5576 13.3019C96.3539 13.3019 96.252 13.182 96.252 12.9423V1.06019C96.252 0.820508 96.3718 0.700668 96.6115 0.700668H101.591C102.789 0.700668 103.676 0.9763 104.251 1.52757C104.827 2.07883 105.114 2.83982 105.114 3.81052C105.114 4.79321 104.827 5.56019 104.251 6.11145C103.676 6.66272 102.789 6.93835 101.591 6.93835H96.8632V12.9423C96.8632 13.182 96.7613 13.3019 96.5576 13.3019ZM96.8632 6.30919H101.591C102.586 6.30919 103.317 6.10546 103.784 5.698C104.251 5.29055 104.485 4.66139 104.485 3.81052C104.485 2.95966 104.251 2.33649 103.784 1.94101C103.317 1.53356 102.586 1.32983 101.591 1.32983H96.8632V6.30919Z"
      fill="#3650DE"
    />
    <path
      d="M111.522 13.3019C111.306 13.3019 111.198 13.182 111.198 12.9423V1.32983H107.244C107.004 1.32983 106.884 1.22796 106.884 1.02424C106.884 0.820508 107.004 0.718643 107.244 0.718643H115.782C116.022 0.718643 116.142 0.820508 116.142 1.02424C116.142 1.22796 116.022 1.32983 115.782 1.32983H111.827V12.9423C111.827 13.182 111.726 13.3019 111.522 13.3019Z"
      fill="#3650DE"
    />
  </svg>
);

const PublicChatBot = () => {
  const [chat, setChat] = useState<any[]>([]);
  const { user, fluxBots, setFluxBots } = useAuthStore(state => state);
  const [session, setSession] = useState<string>();
  const selectedChatFind = chat?.find((chat: any) => chat?.id === session);
  const [sessionName, setSessionName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState<{
    id: string;
    name?: string;
    space?: string;
  }>({
    id: '',
    name: '',
    space: '',
  });
  const [socketClient, setSocketClient] = useState<any>(null);

  const onCreateNewUserSession = async (name: string) => {
    if (!name) {
      return;
    }
    const sessionChat = {
      id: Math.random().toString(36).substring(7),
      name,
      entries: [],
    };

    const newChat = [
      ...chat.filter(chat => chat?.id !== 'default'),
      sessionChat,
    ];

    setChat([...chat, sessionChat]);

    const id = flow?.nodes?.find(
      (item: any) => item.type === 'fluxObject' && item?.data?.chat,
    )?.id!;

    await FlowService.updateNodeData(flow.id, {
      id,
      data: { userSessionsChat: newChat },
      changeType: 'default',
    });
    setShowModal(false);
  };

  const [chatTyping, setChatTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');

  const [flow, setFlow] = useState<any>();
  const addTab = useStoreTabs(state => state.addTab);
  const tabs = useStoreTabs(state => state.tabs);

  const [chatSettings, setChatSettings] = useState({
    settings: false,
    flow: false,
  });
  const navigate = useNavigate();
  const setNotification = useNotificationStore(state => state.setNotification);

  const lastItemRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [openSettings, setOpenSettings] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const clearConversation = async () => {
    if (!activeTab.id || !flow) {
      setNotification({
        type: 'error',
        message: 'Active tab or flow not set',
      });
      return;
    }

    try {
      await FlowService.clearChatFlow(
        activeTab.id!,
        flow?.nodes?.find((item: any) => item.type === 'fluxObject')?.id!,
      );

      setChat(prevChat => {
        return prevChat.map(chatSession => {
          if (chatSession.id === session) {
            return { ...chatSession, entries: [] };
          }
          return chatSession;
        });
      });

      setNotification({
        type: 'success',
        message: 'Conversation cleared successfully',
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: 'Error clearing chat flow',
      });
    }
  };

  const params = useParams();

  const getFluxBots = async (session?: string) => {
    setIsLoading(true);
    try {
      const res = await FlowService.getPublicFlow(params.chatId!);
      // with 40 characters and unique

      const generateRandomSession = session ? session : generateRandomId();

      navigate(`/chatbot/${params.chatId}?session=${generateRandomSession}`);

      setFluxBots([res.data]);

      if (res.data.nodes) {
        const getChatFromNodes = res.data?.nodes?.find(
          (item: any) => item.type === 'fluxObject',
        )?.data;

        const userSessionsChat = getChatFromNodes?.userSessionsChat || [];

        const addGeneratedSession = userSessionsChat.find(
          (item: any) => item.id === generateRandomSession,
        );

        if (!addGeneratedSession) {
          userSessionsChat.push({
            id: generateRandomSession,
            name: generateRandomSession,
            entries: [],
          });
        }

        setChat([
          {
            id: 'default',
            name: 'Primary',
            entries: getChatFromNodes.entries,
          },
          ...userSessionsChat,
        ]);
        setSession(generateRandomSession);

        setFlow(res?.data);
      }

      setActiveTab({
        id: params.chatId!,
        name: res.data.name,
        space: res.data.space,
      });
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (params.chatId) {
      const getGeneratedSession = location.search.split('session=')[1];
      getFluxBots(getGeneratedSession);
    }
  }, [params.chatId, location.search]);

  useEffect(() => {
    if (
      fluxBots &&
      Array.isArray(fluxBots) &&
      fluxBots.length > 0 &&
      !activeTab.id
    ) {
      setActiveTab({
        id: fluxBots[0].id,
        name: fluxBots[0].name,
        space: fluxBots[0].space,
      });
    }
  }, [fluxBots, activeTab.id]);

  useAutosizeTextArea(textAreaRef.current, text);

  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        lastItemRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 300);
    };

    scrollToBottom();
  }, [chat]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSettings(true);
    setChatSettings({
      ...chatSettings,
      settings: !chatSettings.settings,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSettings(false);
  };

  const handleSendMessage = async () => {
    if (!activeTab || !flow) {
      setNotification({
        type: 'error',
        message: 'Active tab or flow not set',
      });
      return;
    }
    setChatTyping(false);
    textAreaRef?.current?.blur();
    const checkTextIsNotEmpty = text.trim().length > 0;

    if (!checkTextIsNotEmpty) {
      return;
    }

    let newChat = selectedChatFind?.entries;
    newChat.push({
      role: 'user',
      content: text,
      timestamp: new Date().toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      })
    });

    const filterChats = chat.filter(chatSession => chatSession.id !== session);
    setChat([
      ...filterChats,
      {
        ...selectedChatFind,
        entries: newChat,
      },
    ]);

    setChatTyping(true);
    setText('');
    if (textAreaRef.current?.style.height) {
      textAreaRef.current!.style.height = '40px';
    }
    try {
      const findGlobalNode = flow?.nodes?.find(
        (item: any) => item.type === 'globalStarter',
      )?.data?.selectedNode;

      let findVarInputNode = flow?.nodes?.find(
        (item: any) => item.type === 'varInputNode',
      );

      if (findGlobalNode) {
        findVarInputNode = flow?.nodes?.find(
          (item: any) =>
            item.type === 'varInputNode' && item?.id === findGlobalNode,
        );
      }

      let sessionId = {};
      if (session !== 'default') {
        sessionId = {
          sessionId: session,
        };
      }
      const response = await FluxService.chatApi(
        activeTab.id!,
        socketClient?.id!,
        {
          ...sessionId,
          variableInputs: [
            {
              inputId: findVarInputNode?.id,
              inputText: text,
            },
          ],
        },
      );

      setChat([...filterChats, { ...selectedChatFind, entries: newChat }]);
      setText('');
    } catch (error) {
      setText('');
    } finally {
    }
  };

  const modifyChatCallback = useCallback(
    (resData: any) => {
      setChat(prev => {
        const selectedChatFind = prev?.find(
          (chat: any) => chat?.id === session,
        );
        let newChat = selectedChatFind?.entries;

        newChat.push({
          role: 'assistant',
          content: resData?.data?.[0]?.text,
          fileUrl: resData?.data?.[0]?.fileUrl,
          timestamp: new Date().toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
          })
        });
        const filterChats = prev.filter(
          chatSession => chatSession.id !== session,
        );

        return [
          ...filterChats,
          {
            ...selectedChatFind,
            entries: newChat,
          },
        ];
      });

      setChatTyping(false);

      setText('');
      setTimeout(() => {
        lastItemRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 300);

      textAreaRef?.current?.focus();
    },
    [session],
  );

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL as string);

    socket.on('connect', () => {
      setSocketClient(socket);
    });
    socket.on('chatbot-req', (resData: any) => {
      modifyChatCallback(resData);
    });

    return () => {
      socket?.off('connect');
      socket?.off('chatbot-req');
    };
  }, [session]);

  const onChange = (e: any) => {
    // setChatTyping(true);
    setText(e.target.value);
  };

  const onCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const getDateLabel = (timestamp: string) => {
    try {
      let date = parseISO(timestamp);
      const now = new Date();

      date = setYear(date, now.getFullYear());

      if (isToday(date)) {
        return 'Today';
      } else if (isYesterday(date)) {
        return 'Yesterday';
      } else {
        const diffDays = differenceInDays(now, date);
        if (diffDays > 0 && diffDays < 7) {
          return `${diffDays} days ago`;
        } else {
          return format(date, 'MMMM d');
        }
      }
    } catch (error) {
      console.error('Invalid date:', timestamp);
      return 'Recent';
    }
  };

  const Header = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          borderBottom: user?.theme === 'dark' ? '' : '1px solid #F2F4F7',
          borderTop: '5px solid #B589FF',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              position: 'relative',
              borderRadius: '8px',
              padding: '8px',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '150px',
                color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
              }}
              title={`${activeTab?.name ?? ''} (${activeTab?.space ?? ''})`}
              color={'#FFF'}
              variant="subtitle1"
            >
              {`${activeTab?.name ?? ''}`}
            </Typography>

            <Box
              sx={{
                pl: '10px',
                ml: '10px',
                borderLeft: '1px solid #E5E7EB',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography fontSize={'12px'} fontWeight={400} color={'#475467'}>
                powered by
              </Typography>
              {icon}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={e => {
              handleClick(e);
            }}
          >
            <Gear
              cursor={'pointer'}
              color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
              size={24}
            />
          </IconButton>

          {chatSettings.settings && (
            <Popover
              open={openSettings}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: '8px',
                  background: user?.theme === 'dark' ? '#101828' : '#FFFF',
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    background: 'unset',
                    justifyContent: 'flex-start',
                    textTransform: 'unset',
                    fontSize: '16px',
                    boxShadow: 'unset',
                    px: '8px',
                    gap: '8px',
                    ':hover': {
                      background:
                        user?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                      boxShadow: 'unset',
                    },
                  }}
                  onClick={() => {
                    setChatSettings({
                      ...chatSettings,
                      flow: !chatSettings.flow,
                    });
                    clearConversation();
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Trash
                      size={20}
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                    />
                    <Typography
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                    >
                      Clear Conversation
                    </Typography>
                  </Box>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    background: 'unset',
                    justifyContent: 'flex-start',
                    textTransform: 'unset',
                    fontSize: '16px',
                    boxShadow: 'unset',
                    px: '8px',
                    gap: '8px',
                    color: '#EAECF0',
                    ':hover': {
                      background:
                        user?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                      boxShadow: 'unset',
                    },
                  }}
                  onClick={() => {
                    setChatSettings({
                      ...chatSettings,
                      flow: !chatSettings.flow,
                    });

                    if (tabs.length <= 9) {
                      addTab({
                        flowName: flow?.name,
                        flowId: flow?.id,
                        folderId: fluxBots[0].id!,
                      });
                    } else {
                      navigate(
                        `/flow/${flow?.id}?folderId=${fluxBots[0]
                          .id!}&type=flow`,
                      );
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <NotePencil
                      size={20}
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    />
                    <Typography
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                    >
                      Edit Ai FluxBot
                    </Typography>
                  </Box>
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    background: 'unset',
                    justifyContent: 'flex-start',
                    textTransform: 'unset',
                    fontSize: '16px',
                    boxShadow: 'unset',
                    px: '8px',
                    gap: '8px',
                    color: '#EAECF0',
                    ':hover': {
                      background:
                        user?.theme === 'dark' ? ' #1D2939' : '#EAECF0',
                      boxShadow: 'unset',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Plus
                      size={20}
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#667085'}
                    />
                    <Typography
                      color={user?.theme === 'dark' ? '#D0D5DD' : '#475467'}
                    >
                      Create New Ai FluxBot
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Popover>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      onClick={() => {
        if (chatSettings.settings || chatSettings.flow) {
          setChatSettings({
            settings: false,
            flow: false,
          });
        }
      }}
      sx={{
        overflowY: 'hidden',
        height: '100vh',
      }}
    >
      <Header />

      <Box
        sx={{
          backgroundColor: flow?.backgroundColor
            ? flow?.backgroundColor
            : user?.theme === 'dark'
            ? '#1D2939'
            : '#F9FAFB',
          backgroundImage: flow?.chatImage ? `url(${flow?.chatImage})` : '',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          borderRadius: '4px',
          borderLeft: '1px solid #E5E7EB',
          height: selectedChatFind?.entries?.length === 'unset' ? 0 : '100vh',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {/* Chat container */}

          {selectedChatFind?.entries?.length === 0 && (
            <Box
              sx={{
                textAlign: 'center',
                mt: '28px',
              }}
            >
              {flow?.logo && (
                <Box
                  component={'img'}
                  src={flow?.logo}
                  alt=""
                  sx={{
                    m: 'unset !important',
                    mb: '16px !important',
                    borderRadius: '10px',
                    width: '80px !important',
                    height: '80px !important',
                    float: 'unset !important',
                  }}
                />
              )}

              <Typography
                sx={{
                  mt: '8px',
                  fontSize: '32px',
                  fontWeight: 700,
                  color: user?.theme === 'dark' ? '#fff' : '#000',
                  mb: '16px',
                }}
                variant="h4"
              >
                {activeTab?.name}
              </Typography>

              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 400,
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                  maxWidth: '700px',
                  letterSpacing: '0.15px',
                  margin: '0 auto',
                  lineHeight: '28.8px',
                  textAlign: 'center',
                }}
                variant="body1"
              >
                {flow?.chatDescription}
              </Typography>
              <Box
                sx={{
                  padding: user?.theme === 'dark' ? '0' : '24px',
                  display: 'flex',
                  gap: '16px',
                }}
              >
                {flow?.chatActions?.map((item: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        cursor: 'pointer',
                        border: '2px solid #EAECF0',
                        borderRadius: '12px',
                        padding: '20px 24px',
                        maxWidth: '270px',
                        width: '100%',
                      }}
                      onClick={() => setText(item.description)}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            p: '10px',
                            borderRadius: '50%',
                            background: '#F9FAFB',
                          }}
                        >
                          <StarFour size={32} color="#7B4CFE" />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontWeight: 500,
                            textAlign: 'left',
                            fontStyle: 'normal',

                            color: user?.theme === 'dark' ? '#fff' : '#000',
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          fontFamily: 'Raleway',
                          textAlign: 'left',

                          fontStyle: 'normal',

                          color: user?.theme === 'dark' ? '#D0D5DD' : '#475467',
                        }}
                        variant="body1"
                      >
                        {item.description} →
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              m: '0 auto',
              height: '80vh',
              position: 'relative',
              overflowY: 'auto',
            }}
            className="chat-container"
          >
            <Box
              ref={lastItemRef}
              sx={{
                padding: '0 20px',
                mb: '60px',
              }}
            >
              {isLoading ? (
                <>
                  <LoadingSpinner variant="light" />
                </>
              ) : (
                <>
                  {selectedChatFind?.entries.map((item: any, index: number) => {
                    const itemsSegments = processTextSegments(item?.content);

                    return (
                      <>
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            // backgroundColor: 'red',
                            flexDirection: 'column',
                            mt: '10px',
                            marginBottom:
                              chat.length - 1 === index && !chatTyping
                                ? '45px'
                                : '0',

                            alignItems:
                              item.role === 'assistant'
                                ? 'flex-start'
                                : 'flex-end',

                            padding: '10px 50px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',

                              alignItems: 'flex-start',
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                              }}
                            >
                              {item.role === 'assistant' && (
                                <Box
                                  sx={{
                                    bgcolor: '#B589FF',
                                    display: 'flex',
                                    width: '24px',
                                    height: '24px',
                                    padding: '8px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '4px',
                                    gap: '13.5px',
                                  }}
                                >
                                  <Chats size={20} color="#fff" />
                                </Box>
                              )}
                              <Box
                                className={`${
                                  item.role === 'assistant'
                                    ? 'bg-white assistant-after'
                                    : 'bg-white user-after'
                                }`}
                                sx={{
                                  padding: '10px 15px',
                                  borderRadius:
                                    item.role === 'assistant'
                                      ? '0px 8px 8px 8px'
                                      : '8px 0px 8px 8px',
                                  backgroundColor:
                                    user?.theme === 'dark'
                                      ? item.role === 'assistant'
                                        ? '#F2F4F7'
                                        : '#F2F4F7'
                                      : item.role === 'assistant'
                                      ? '#F2F4F7'
                                      : '#F2F4F7',
                                  color:
                                    user?.theme === 'dark'
                                      ? item.role === 'assistant'
                                        ? '#F2F4F7'
                                        : '#F2F4F7'
                                      : item.role === 'assistant'
                                      ? '#475467'
                                      : '#475467',
                                  // marginBottom: '5px',
                                  fontSize: '16px',
                                  maxWidth: '80%',
                                }}
                              >
                                {itemsSegments?.length !== 0 &&
                                  itemsSegments?.map(
                                    (segment: any, index: number) => (
                                      <OutputMarkDown
                                        onCopy={onCopy}
                                        segment={segment}
                                        user={user!}
                                        key={index}
                                        index={index}
                                        value={item?.content}
                                        segments={itemsSegments}
                                      />
                                    ),
                                  )}
                              </Box>
                            </Box>
                            {item.role === 'assistant' && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  paddingLeft: '48px',
                                }}
                              >
                                <IconButton
                                  onClick={() => onCopy(item?.content)}
                                  sx={{
                                    marginBottom: '15px',
                                  }}
                                >
                                  <Copy
                                    size={20}
                                    color={
                                      user?.theme === 'dark'
                                        ? '#D0D5DD'
                                        : flow?.chatImage
                                        ? '#fff'
                                        : '#475467'
                                    }
                                  />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </>
              )}

              {chatTyping && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: '45px',
                  }}
                >
                  <Box
                    className={`${'bg-white assistant-after'}`}
                    style={{
                      padding: '10px',
                      borderRadius: '10px',

                      color: 'assistant',
                      marginBottom: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Box>
              )}
              {/* Input / text area */}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          // position: 'absolute',
          position: 'sticky',
          zIndex: 999,
          bottom: 50,
          margin: '0 16px',

          // transform: 'translateX(-50%)',
          // display: 'flex',
          // zIndex: '1',

          // '&:after': {
          //   ...isFocused,
          // },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              // flexDirection: 'row',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '1000px',
              width: '100%',

              backgroundColor:
                text.trim() !== ''
                  ? '#D0D5DD'
                  : user?.theme === 'dark'
                  ? '#101828'
                  : '#fff',

              gap: '8px',
              borderRadius: '10px',
              // width: '85%',
              border:
                user?.theme === 'dark'
                  ? '1px solid #1D2939'
                  : '1px solid #D0D5DD',
            }}
          >
            <Box
              sx={{
                padding: '0px 5px',
                borderRadius: '8px',
                maxWidth: '1000px',
                width: '85%',
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                backgroundColor:
                  text.trim() !== ''
                    ? '#D0D5DD'
                    : user?.theme === 'dark'
                    ? '#101828'
                    : '#fff',
              }}
            >
              <textarea
                className={`${
                  user?.theme === 'dark'
                    ? 'chat-textarea-dark'
                    : 'chat-textarea'
                }`}
                onChange={onChange}
                placeholder="Message FluxBot"
                ref={textAreaRef}
                // onFocus={() => setFocused(true)}
                // onBlur={() => setFocused(false)}
                onKeyDown={e => {
                  // if shift + enter, add a new line
                  // if enter, send message
                  if (e.key === 'Enter' && e.shiftKey) {
                    return;
                  }
                  if (e.key === 'Enter') {
                    if (!chatTyping) {
                      handleSendMessage();
                    }
                  }
                }}
                style={{
                  backgroundColor:
                    text.trim() !== ''
                      ? '#D0D5DD'
                      : user?.theme === 'dark'
                      ? '#101828'
                      : '#fff',
                  fontSize: '16px',
                  transition: 'width 0.2s ease',
                  width: '100%',
                }}
                rows={1}
                value={text}
              />
              <Box
                sx={{
                  p: '8px',
                  borderRadius: '4px',
                  backgroundColor:
                    text.trim() !== ''
                      ? '#344054'
                      : user?.theme === 'dark'
                      ? '#101828'
                      : '#D0D5DD',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleSendMessage}
              >
                <ArrowUp color="#fff" size={20} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              mt: '24px',
            }}
          >
            <Typography
              sx={{
                color: '#98A2B3',
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              This is powered by FluxPrompt.{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (user?.id) {
                    navigate('/');
                  } else {
                    navigate('/login');
                  }
                }}
              >
                Subscribe
              </span>{' '}
              to create your own chatbot.
            </Typography>
          </Box>
        </Box>
      </Box>

      <ChatBotSessionModal
        sessionName={sessionName}
        setSessionName={setSessionName}
        show={showModal}
        onCreateNewUserSession={onCreateNewUserSession}
        onHide={() => setShowModal(false)}
      />
    </Box>
  );
};

export default PublicChatBot;
