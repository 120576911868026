const evaluateCondition = (condition: any, conditionText: string) => {
  let result = false;

  switch (condition.type) {
    case 'charCount': {
      const text = conditionText?.replaceAll('\n', '')?.replaceAll(' ', '');
      const length = text?.length ?? 0;
      const value = parseInt(condition.value);
      if (isNaN(value)) break;

      switch (condition.operator) {
        case '>':
          result = length > value;
          break;
        case '<':
          result = length < value;
          break;
        case '=':
          result = length === value;
          break;
      }
      break;
    }

    case 'number': {
      const number = parseInt(conditionText);
      const value = parseInt(condition.value);
      if (isNaN(number) || isNaN(value)) break;

      switch (condition.operator) {
        case '>':
          result = number > value;
          break;
        case '<':
          result = number < value;
          break;
        case '=':
          result = number === value;
          break;
      }
      break;
    }

    case 'text': {
      const text = conditionText ?? '';
      const value = condition.sentimentValue ?? '';

      switch (condition.operator) {
        case 'begins':
          result = text.startsWith(value);
          break;
        case 'contains':
          result = text.includes(value);
          break;
        case 'ends':
          result = text.endsWith(value);
          break;
      }
      break;
    }

    default:
      break;
  }

  return result;
};

export const conditionsCheck = (conditions: any, conditionText = '') => {
  let result = true;

  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    const conditionResult = evaluateCondition(condition, conditionText);

    if (i > 0) {
      const logicOperator = condition?.logicOperator;
      if (logicOperator === 'AND') {
        result = result && conditionResult;
      } else if (logicOperator === 'OR') {
        result = result || conditionResult;
      }
    } else {
      result = conditionResult;
    }
  }

  return result;
};

export const singleConditionCheck = (
  condition: any,
  index: number,
  conditionText: string
) => {
  let result = false;

  if (condition.type === 'charCount') {
    const text = conditionText?.replaceAll('\n', '')?.replaceAll(' ', '');
    const operator = condition.operator;
    const value = parseInt(condition.value);

    // Perform the length comparison based on the operator

    if (operator === '>') {
      result = text?.length > value;
    } else if (operator === '<') {
      result = text?.length < value;
    } else if (operator === '=') {
      result = text?.length === value;
    }
  } else if (condition.type === 'number') {
    const number = parseInt(conditionText);
    const operator = condition.operator;
    const value = parseInt(condition.value);
    // Perform the length comparison based on the operator

    if (operator === '>') {
      result = number > value;
    } else if (operator === '<') {
      result = number < value;
    } else if (operator === '=') {
      result = number === value;
    }
  } else if (condition.type === 'text') {
    const text = conditionText;
    const operator = condition.operator;
    // 3 operators types    { value: 'begins', label: 'Begins' },
    // { value: 'contains', label: 'Contains' },
    // { value: 'ends', label: 'Ends' },

    const value = condition.sentimentValue;
    // Perform the length comparison based on the operator

    if (operator === 'begins') {
      result = text?.startsWith(value);
    }

    if (operator === 'contains') {
      result = text?.includes(value);
    }

    if (operator === 'ends') {
      result = text?.endsWith(value);
    }

    if (index > 0) {
      const logicOperator = condition.logicOperator;
      if (logicOperator === 'AND') {
        result = result && result;
      } else if (logicOperator === 'OR') {
        result = result || result;
      }
    }
  }

  return result;
};
