import React, { useEffect, useState } from 'react';
import AuthCard from '../../components/auth/AuthCard';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthService } from '../../service/AuthService';
import { useNotificationStore } from '../../store/storeNotifications';
import { Box, Button } from '@mui/material';

const ConfirmAccount = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [confirmMessage, setConfirmMessage] = useState('');
  const { setNotification } = useNotificationStore(state => state);

  useEffect(() => {
    const abortController = new AbortController();
    const confirmAccount = async (userId: string) => {
      try {
        await AuthService.confirmAccount(userId!, abortController.signal);

        setConfirmMessage('Account confirmed successfully!');
      } catch (error: any) {
        if (error?.message === 'User not found!') {
          setConfirmMessage('User not found!');
        } else if (error?.message === 'User already confirmed!') {
          setConfirmMessage('User already confirmed!');
        }
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      }
    };

    if (userId) {
      confirmAccount(userId);
    }

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Box
      className=' login'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AuthCard>
        <Box textAlign={'center'} className='text-center '>
          <h4 className='login_form_info--text'>{confirmMessage}</h4>

          <Button
            className='btn-primary register_btn-verify '
            onClick={() => navigate('/')}
          >
            Return to login
          </Button>
        </Box>
      </AuthCard>
    </Box>
  );
};

export default ConfirmAccount;
