import {
  Box,
  Button,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import InputHandle from '../InputHandle/InputHandle';
import {
  FileAudio,
  FileDoc,
  FileImage,
  TextAlignLeft,
  TrashSimple,
} from '@phosphor-icons/react';
import IconButtonTooltip from '../IconButtonTooltip/IconButtonTooltip';
import AudioComponents from '../AudioComponent/AudioComponent';
import { isActiveEdge } from '../../../util/findActiveHandle';
import { useEdges } from 'reactflow';
import { useAuthStore } from '../../../store/storeAuth';
import classes from './UploadBox.module.css';
import PreviewModal from '../../ImageGenerator/PreviewModal/PreviewModal';
import OutputExpandBackground from '../../../assets/images/OutputExpandBackground.png';

const UploadBox = ({
  onChange,
  name,
  value,
  disabled,
  id,
  type = 'image',
  labelName = '',
  onChangeUploadImage,
  handleId = '',
  findColor,
  deleteImage,
  onChangePlacement,
  uploadedFile,
  placement,
  noUrl = false,
  addMask = false,
  onAddMask = () => {},
  expandSettings = false,
  onExpandSettings = () => {},
  buttonText = 'Edit',
  expandedImagePreview,
  dimensions,
  imageNodeType,
}: any) => {
  const edges = useEdges();
  const { user } = useAuthStore(state => state);
  const [modal, setModal] = useState(false);

  function getImageStyles(): any {
    return {
      position: 'absolute',
      width: '300px',
      height: '90px',
      top: '15px',
      left: '30px',
      objectFit: 'contain',
    };
  }

  return (
    <>
      <PreviewModal
        onHide={() => {
          setModal(false);
        }}
        responses={[value]}
        active={value}
        show={modal}
      />
      <Box
        position={'relative'}
        mb={'16px'}
        className="nodrag"
        sx={{
          border: '1px solid rgba(234, 236, 240, 1)',
          padding: '10px',
          borderRadius: '8px',
        }}
      >
        {handleId && (
          <InputHandle
            activeColor={findColor?.color}
            handleId={handleId}
            isActive={isActiveEdge(edges, id, 'inputPreview', 'target')}
            type="target"
            left={-26}
          />
        )}
        {placement === 'Output' && (
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              '& :hover': {
                '&.change-placement': {
                  border: '1px solid ' + findColor?.color,
                  '& svg': {
                    fill: findColor?.color,
                  },
                },
              },
            }}
            onClick={() => onChangePlacement?.('placement', 'Prompt')}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                alignSelf: 'center',
                border: '1px solid #98A2B3',
                maxWidth: 'fit-content',
              }}
              className="change-placement"
            >
              <TextAlignLeft size={16} color="#98A2B3" />
            </Box>
            <Typography
              fontSize={'12px'}
              color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
            >
              {labelName?.split(' ')[0]}
            </Typography>
          </Box>
        )}

        {placement !== 'Output' && (
          <>
            {labelName && (
              <Typography
                fontSize={'16px'}
                color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                fontWeight={600}
              >
                {labelName}
              </Typography>
            )}

            {handleId && !noUrl && (
              <TextField
                fullWidth
                placeholder="https://assets-app.fluxprompt.ai/example.png"
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                InputProps={{
                  style: {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                  endAdornment: (value || uploadedFile) && !disabled && (
                    <InputAdornment position="end">
                      <IconButtonTooltip
                        title="delete uploaded or connected image"
                        onClick={deleteImage}
                      >
                        <TrashSimple color="#98A2B3" size={16} />
                      </IconButtonTooltip>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mt: '8px',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                  border: `2px solid ${
                    user?.theme === 'dark' ? '#475467' : '#EAECF0'
                  }`,
                }}
              />
            )}
            <Box
              onClick={() => {
                if (value) {
                  return setModal(true);
                }
                if (!value || !uploadedFile) {
                  return;
                } else if (value) {
                  // open image in new tab

                  if (type !== 'audio') {
                    setModal(true);
                    // window.open(value, '_blank');
                  }
                }
              }}
              sx={{
                borderRadius: '4px',
                border: value ? '1px solid #98A2B3' : '',
                mt: '16px',
                backgroundImage:
                  value && value.includes('http')
                    ? `url("${value}")`
                    : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                padding: expandedImagePreview && dimensions ? 0 : '15px 8px',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '118px',
                width: '360px',
                maxHeight: '480px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                zIndex: 999,
              }}
            >
              {value &&
                expandedImagePreview &&
                dimensions &&
                imageNodeType === 'Expand Image' && (
                  <div className={classes['drawingBackground']}>
                    <img
                      src={OutputExpandBackground}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1,
                        width: '364px',
                        height: '123px',
                        border: 'none',
                      }}
                      alt="exp0ung"
                    />

                    <img src={value} style={getImageStyles()} alt="exp0ung" />
                  </div>
                )}
              {!value && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: '8px',
                  }}
                >
                  <FormLabel
                    htmlFor={id}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                    }}
                  >
                    {type === 'image' ? (
                      <FileImage
                        size={'32px'}
                        color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                      />
                    ) : type === 'audio' ? (
                      <FileAudio
                        size={'32px'}
                        color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                      />
                    ) : (
                      <FileDoc
                        size={'32px'}
                        color={user?.theme === 'dark' ? '#D0D5DD' : '#101828'}
                      />
                    )}

                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#98A2B3',
                        fontWeight: 600,
                      }}
                    >
                      {!noUrl && 'Connect or '}Upload{' '}
                      {type === 'image'
                        ? 'Image'
                        : type === 'document'
                        ? 'Document'
                        : type === 'video'
                        ? 'Video'
                        : 'Audio'}
                    </Typography>
                  </FormLabel>

                  <input
                    id={id}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onChangeUploadImage}
                  />
                </Box>
              )}

              {value && type === 'audio' && (
                <AudioComponents audioUrl={value} />
              )}
            </Box>

            {(value?.includes('blob') || uploadedFile) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: '8px',
                }}
              >
                <Button>
                  <label
                    // htmlFor={id}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Upload new{' '}
                    {type === 'image'
                      ? 'Image'
                      : type === 'audio'
                      ? 'Audio'
                      : 'Document'}
                    <input
                      // id={id}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={onChangeUploadImage}
                    />{' '}
                  </label>
                </Button>

                {addMask && (
                  <Button
                    variant="text"
                    onClick={onAddMask ? () => onAddMask?.() : undefined}
                  >
                    {buttonText}
                  </Button>
                )}

                {expandSettings && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="outlined"
                      onClick={
                        onExpandSettings
                          ? () => onExpandSettings?.()
                          : undefined
                      }
                    >
                      Expand Settings
                    </Button>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: '5px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'rgba(16, 24, 40, 1)',
                          fontWeight: 500,
                          fontSize: '14px',
                        }}
                      >
                        T:{dimensions?.top || 0}px
                      </Typography>
                      <Typography
                        sx={{
                          color: 'rgba(16, 24, 40, 1)',
                          fontWeight: 500,
                          fontSize: '14px',
                        }}
                      >
                        L:{dimensions?.left || 0}px
                      </Typography>
                      <Typography
                        sx={{
                          color: 'rgba(16, 24, 40, 1)',
                          fontWeight: 500,
                          fontSize: '14px',
                        }}
                      >
                        R:{dimensions?.right || 0}px
                      </Typography>
                      <Typography
                        sx={{
                          color: 'rgba(16, 24, 40, 1)',
                          fontWeight: 500,
                          fontSize: '14px',
                        }}
                      >
                        B:{dimensions?.bottom || 0}px
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {!(value?.includes('blob') || uploadedFile) && addMask && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',

                  mt: '8px',
                }}
              >
                <Button
                  variant="text"
                  onClick={onAddMask ? () => onAddMask?.() : undefined}
                >
                  {buttonText}
                </Button>
              </Box>
            )}

            {!(value?.includes('blob') || uploadedFile) && expandSettings && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: '8px',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    flexWrap: 'wrap',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(16, 24, 40, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    T:{dimensions?.top || 0}px
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(16, 24, 40, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    L:{dimensions?.left || 0}px
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(16, 24, 40, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    R:{dimensions?.right || 0}px
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(16, 24, 40, 1)',
                      fontWeight: 500,
                      fontSize: '14px',
                    }}
                  >
                    B:{dimensions?.bottom || 0}px
                  </Typography>
                </Box>

                <Button
                  variant="outlined"
                  onClick={
                    onExpandSettings ? () => onExpandSettings?.() : undefined
                  }
                >
                  Expand Settings
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default UploadBox;
