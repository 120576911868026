import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import KitModal from '../UI/KitModal';
import { FormData, baseModels } from './constants';

type Props = {
  onHide: () => void;
  show: boolean;
  formData: FormData;
  onChange: (e: any) => void;
};

const SettingsModal = ({ onHide, show, formData, onChange }: Props) => {
  return (
    <KitModal
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '680px',
          borderRadius: '16px',
        },
        '& .MuiDialogContent-root': {
          padding: '16px',
        },
        '& .MuiDialogActions-root': {
          padding: '16px',
        },
      }}
      showCloseButton={false}
      onHide={onHide}
      show={show}
    >
      <Box>
        <Typography variant="h6">Advanced Settings</Typography>
        <Box mt={'16px'} p={'8px'} bgcolor={'#EAECF0'} borderRadius={'4px'}>
          <Typography fontSize={'14px'} variant="h6">
            Video Generator Settings
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <FormLabel htmlFor="prompt">Base Model:</FormLabel>
            <Select
              name="baseModel"
              value={formData.baseModel}
              onChange={onChange}
              className="nodrag"
            >
              {baseModels.map((model, index) => (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {formData.baseModel === 'luma-labs' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <FormLabel htmlFor="aspect_ratio">Aspect Ratio:</FormLabel>
                <TextField
                  value={formData.aspect_ratio}
                  name="aspect_ratio"
                  onChange={onChange}
                  type="text"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <FormLabel htmlFor="loop">Loop</FormLabel>
                <Switch
                  onChange={onChange}
                  name="loop"
                  value={Boolean(formData.loop)}
                  checked={Boolean(formData.loop)}
                />
              </Box>
            </>
          )}

          {formData.baseModel === 'stability' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <FormLabel htmlFor="seed">Seed:</FormLabel>
                <TextField
                  value={formData.seed}
                  name="seed"
                  onChange={onChange}
                  type="number"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <FormLabel htmlFor="cfg_scale">CFG Scale:</FormLabel>
                <TextField
                  value={formData.cfg_scale}
                  name="cfg_scale"
                  onChange={onChange}
                  type="number"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <FormLabel htmlFor="motion_bucket_id">Motion:</FormLabel>
                <TextField
                  value={formData.motion_bucket_id}
                  name="motion_bucket_id"
                  onChange={onChange}
                  type="number"
                />
              </Box>
            </>
          )}
          {formData.baseModel === 'runway-ml' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <FormLabel htmlFor="duration">Duration:</FormLabel>
                <TextField
                  value={formData.duration}
                  name="duration"
                  onChange={onChange}
                  type="number"
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </KitModal>
  );
};

export default SettingsModal;
