import { CheckCircle } from "@phosphor-icons/react";
import KitModal from "../UI/KitModal";
import { Box, Button, Typography } from "@mui/material";

type Props = {
    confirmChanges: boolean
    closeModal: () => void
    goBack: () => void
    onConfirm: () => void
};

const SuccessfulChangeModal = ({confirmChanges, closeModal, goBack, onConfirm}: Props) => {
    return (
      <KitModal
        show={confirmChanges}
        onHide={() => closeModal()}
        maxWidth={"sm"}
        sx={{
          "& .MuiPaper-root": {
            width: "527px",
            maxHeight: "292px",
            borderRadius: "8px",
            pb: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "24px",
            mt: "20px",
          }}
        >
          <CheckCircle size={50} weight="fill" fill="#67BB6A" />

          <Typography color={"#475467"} fontWeight={"500"}>
            Change configuration settings
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Typography color={"#475467"} fontWeight={"400"}>
              All the new nodes used will contain the edited default settings.
            </Typography>
            <Typography color={"#475467"} fontWeight={"400"}>
              Your current nodes in your flows will not change.
            </Typography>
          </Box>
          <Box sx={{display: 'flex', gap: '30px', width: '100%', justifyContent: 'center' }}>
            <Button variant="outlined" sx={{width: '100%'}} onClick={goBack}>No, go back</Button>
            <Button variant="contained" sx={{width: '100%'}} onClick={onConfirm}>Yes, continue</Button>
          </Box>
        </Box>
      </KitModal>
    );
};

export default SuccessfulChangeModal;