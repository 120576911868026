import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { NodeProps } from '../../util/Types/NodeProps';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import Node from '../UI/Node/Node';
import { Position, useEdges, useReactFlow } from 'reactflow';
import { isActiveEdge } from '../../util/findActiveHandle';
import InputHandle from '../UI/InputHandle/InputHandle';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';

const ProcessCounter = memo(({ data, id, type }: NodeProps) => {
  const { setNodes: setNodeState } = useReactFlow();

  const { setSchema, setSaving, flowId } = useFlowsStore(state => state);
  const [isIncreasing, setIsIncreasing] = useState(true);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState({
    value: '',
    name: '',
  });
  const [hasToExecute, setHasToExecute] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const debounceTimeoutRef = useRef<any>(null);
  const edges = useEdges();

  const onSubmit = (values: any) => {
    setIsLoading(true);

    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      changeType: 'execute',
      objectCallerData: [
        ...(user?.spaceObjects || []),
        ...(user?.objects || []),
      ],

      flowTriggerData: checkExecuteTrigger(data, id),
      flowId,
      objectData: {
        text: isIncreasing ? Number(count) + 1 : Number(count) - 1,
      },
      setSchema,
    });

    setTimeout(() => {
      setHasToExecute(true);
    }, 1000);

    setIsLoading(false);
  };

  const onChange = (e: { target: { name: any; value: any } }) => {
    setCount(e.target.value);
    setWhoIsChanging({
      name: 'text',
      value: e.target.value,
    });
    setIsChangingDirectly(true);
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          changeType: 'changeText',
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const onExecuteNode = useMemo(() => {
    const values = {
      ...data,
    };

    setCount(data?.text ? parseInt(data?.text) : 0);

    if (data?.isIncreasing !== undefined) {
      setIsIncreasing(data?.isIncreasing);
    }

    return values;
  }, [data]);

  const onClickIncrease = (value: boolean) => {
    setIsIncreasing(value);
    nodeDataAutoSaveDynamic({
      setNodeState,
      id,
      setSaving,
      newEdges: edges,
      flowId,
      objectData: {
        isIncreasing: value,
      },
      setSchema,
    });
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          const myValues = onExecuteNode;

          if (myValues) {
            onSubmit(myValues);
          }
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, onExecuteNode, data?.canceled]);

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        edges={edges}
        isGlobal
        isLoading={isLoading}
        handleRun={handleRun}
        type={type}
        id={id}
        data={data}
        showTokensUsed={false}
      >
        {!data?.collapsed && (
          <>
            <Box p={'16px'}>
              <Select
                value={isIncreasing ? 'true' : 'false'}
                onChange={e => onClickIncrease(e.target.value === 'true')}
                className="nodrag"
                sx={{
                  '& .MuiList-root': {
                    backgroundColor:
                      user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  },
                  '& .MuiSvgIcon-root': {
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                  },
                  borderRadius: '8px',
                  border: 'none',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
                  color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '& .Mui-selected': {
                        backgroundColor:
                          user?.theme === 'dark' ? '#667085' : '#D0D5DD',
                      },
                      backgroundColor:
                        user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    },
                  },
                }}
                name="openAiModels"
              >
                <MenuItem
                  value={'true'}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  Count up
                </MenuItem>
                <MenuItem
                  value={'false'}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
                  }}
                >
                  Count down
                </MenuItem>
              </Select>
              <Box mt={'8px'}>
                <TextField
                  className="nodrag"
                  type="number"
                  value={count}
                  name="count"
                  placeholder="Set starting number (optional)"
                  onChange={onChange}
                  InputProps={{
                    style: {
                      color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    },
                  }}
                  sx={{
                    color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
                    bgcolor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
                    border: `2px solid ${
                      user?.theme === 'dark' ? '#475467' : '#EAECF0'
                    }`,
                  }}
                />
              </Box>
            </Box>
          </>
        )}

        <InputHandle
          activeColor={findColor?.color}
          handleId={'textOutput'}
          isActive={isActiveEdge(edges, id, 'textOutput', 'source')}
          right={-8}
          top={'70%'}
          position={Position.Right}
          type="source"
        />
      </Node>
    </Box>
  );
});

export default memo(ProcessCounter);
