import React, { useEffect, useState } from 'react';
import { UserService } from '../../../service/UserService';
import { useAuthStore } from '../../../store/storeAuth';
import './Payments.scss';
import CreatePaymentMethodModal from './CreatePaymentMethodModal';
import { PaymentInfoDetails } from '../../../util/Types/PaymentInfoDetails';
import { User } from '../../../util/Types/User';
import LoadingSpinner from '../../UI/LoadingSpinner';
import { useNotificationStore } from '../../../store/storeNotifications';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { CalendarBlank } from '@phosphor-icons/react';
import { ProductService } from '../../../service/ProductService';
import { Product } from '../../../util/Types/Product';

import PaymentCardBox from './PaymentCardBox';
import { getAPIErrorMessage } from '../../../helpers/helpers';
import { DataGridExportableTable, Invoice } from './DataGridExportableTable';

type Props = {
  authUser: User;
  setSelectedMenuItem?:
    | React.Dispatch<React.SetStateAction<string>>
    | undefined;
};

const Billing = ({ authUser, setSelectedMenuItem }: Props) => {
  const setAuthUser = useAuthStore(state => state.setAuthUser);
  const setNotification = useNotificationStore(state => state.setNotification);

  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isRetrying, setIsRetrying] = useState(false);
  const [activePlan, setActivePlan] = useState<Product | undefined>(undefined);
  const [newMethod, setNewMethod] = useState(true);
  const [methods, setMethods] = useState<PaymentInfoDetails[]>([]);
  const [openPaymentMethods, setOpenPaymentMethods] = useState(false);
  const [openCouponModal, setOpenCouponModal] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    readAndAgree: false,
    couponId: '',
  });

  const handleClosePaymentMethods = () => setOpenPaymentMethods(false);

  useEffect(() => {
    const getMyPaymentMethods = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getPaymentMethods();

        setMethods(response.data);
      } catch (error: any) {
        setNotification({
          type: 'error',
          message: error.message || 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
        setNewMethod(false);
      }
    };
    const getMySubscriptions = async () => {
      setIsLoading(true);
      try {
        const response = await ProductService.getMyProducts();
        const resActivePlan = response?.data?.find(plan => plan.active);
        setActivePlan(resActivePlan);
      } catch (error: any) {
        setNotification({
          type: 'error',
          message: 'Something went wrong',
        });
      } finally {
        setIsLoading(false);
      }
    };

    getMySubscriptions();

    if (newMethod) {
      getMyPaymentMethods();
    }
  }, [newMethod]);

  useEffect(() => {
    const getInvoices = async () => {
      try {
        const res = await ProductService.getProductInvoices();
        setInvoices(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    getInvoices();
  }, []);

  useEffect(() => {
    const createStripeCustomer = async () => {
      try {
        await UserService.createStripeCustomer();
      } catch (error: any) {
        setNotification({
          type: 'error',
          message: error.message || 'Something went wrong',
        });
      }
    };

    if (!authUser.customerId) {
      createStripeCustomer();
    }
  }, [authUser.customerId]);

  if (isLoading) {
    return <LoadingSpinner height="60vh" />;
  }

  const onRemovePaymentMethod = async (id: string) => {
    try {
      const response = await UserService.removePaymentMethod(id);

      if (response.status === 'success') {
        setMethods(methods.filter(method => method.paymentMethodId !== id));
      }
    } catch (error: any) {
      setNotification({
        type: 'error',
        message: error.message || 'Something went wrong',
      });
    }
  };

  const handleRetry = async (id: string) => {
    setIsRetrying(true);
    try {
      const res = await ProductService.retryPayment({
        id,
      });
      setNotification({
        type: 'success',
        message: res.message,
      });
    } catch (error) {
      setNotification({
        type: 'error',
        message: getAPIErrorMessage(error),
      });
    } finally {
      setIsRetrying(false);
    }
  };

  const handleCouponApply = async () => {
    if (!formData.couponId) {
      setNotification({ type: 'error', message: 'Coupon code is required' });
      return;
    }
    try {
      const res = await ProductService.applyCoupon({
        productId: activePlan?.id as string,
        couponId: formData.couponId,
      });
      setNotification({ type: 'success', message: res.data.message });
    } catch (error) {
      setNotification({ type: 'error', message: 'Coupon not found' });
    } finally {
      setOpenCouponModal(false);
      setFormData({
        ...formData,
        couponId: '',
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 99,
          backgroundColor: 'white',
          borderBottom: '1px solid #D0D5DD',
          display: 'flex',
          height: authUser.isUserCompany ? '67px' : '64px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: '#000',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px',
            margin: '24px',
          }}
        >
          Billing
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '24px 24px 16px 24px',
          gap: '24px',
        }}
      >
        {/* First Box */}
        <Box
          sx={{
            display: 'flex',
            padding: '24px 24px 0px 24px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',
            borderRadius: '8px',
            border: '1px solid #EAECF0',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  color: '#475467',
                  fontFamily: 'Roboto',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                }}
              >
                {activePlan?.name} Plan
              </Typography>
              <Typography
                sx={{
                  color: '#4CAF50',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                • Monthly Subscription
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => setOpenCouponModal(true)}
              >
                Apply Coupon
              </Button>

              <Button
                variant="contained"
                onClick={() => setSelectedMenuItem?.('pricing')}
              >
                Upgrade
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                <CalendarBlank size={16} weight="fill" color="#3650DE" />
                <Typography
                  sx={{
                    color: '#3650DE',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    gap: '8px',
                    lineHeight: '24px',
                  }}
                >
                  Renewal Date
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: '#3650DE',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  gap: '8px',
                  lineHeight: '24px',
                }}
              >
                {activePlan?.next_charge
                  ? new Date(activePlan.next_charge).toLocaleDateString()
                  : 'N/A'}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              paddingBottom: '16px',
            }}
          >
            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20.02px',
              }}
            >
              {activePlan?.description}
            </Typography>
          </Box>
        </Box>

        {/* Second Box */}
        <Box
          sx={{
            display: 'flex',
            padding: '24px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch',
            borderRadius: '8px',
            border: '1px solid #EAECF0',
            maxHeight: '400px',
            overflowY: methods.length > 3 ? 'auto' : 'hidden',
          }}
        >
          <Typography
            sx={{
              color: '#475467',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 600,
            }}
          >
            Payment Methods
          </Typography>

          {/* Group of Cards */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {/* Cards */}
            {methods
              .sort((a, b) =>
                a.paymentMethodId === authUser.defaultPaymentMethod ? -1 : 0,
              )
              .map((card: PaymentInfoDetails) => (
                <PaymentCardBox
                  key={card.id}
                  paymentMethodId={card.paymentMethodId}
                  brand={card.brand}
                  primary={
                    card.paymentMethodId === authUser.defaultPaymentMethod
                  }
                  last4={card.last4}
                  handleDeleteCard={onRemovePaymentMethod}
                />
              ))}
          </Box>

          <Button
            sx={{
              border: '1px solid #3650DE',
              boxShadow: 'none',
              fontSize: '16px',
              fontWeight: 500,
              fontStyle: 'normal',
              padding: '8px 10px',
              backgroundColor: '#fff',
              color: '#3650DE',
            }}
            onClick={() => setOpenPaymentMethods(true)}
          >
            Add Payment Methods
          </Button>
        </Box>

        {/* Invoice Table */}

        <DataGridExportableTable
          rows={invoices}
          authUser={authUser}
          handleRetry={handleRetry}
          isRetrying={isRetrying}
        />
      </Box>

      {openPaymentMethods && (
        <CreatePaymentMethodModal
          show={openPaymentMethods}
          setAuthUser={setAuthUser}
          authUser={authUser}
          setNewMethod={setNewMethod}
          setIsLoading={setIsLoading}
          onHide={() => setOpenPaymentMethods(false)}
        />
      )}

      {/* Add Payment Methods */}

      <Modal
        open={openCouponModal}
        onClose={() => setOpenCouponModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            padding: '24px',
            gap: '8px',
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ marginBottom: '10px', fontSize: '20px' }}
          >
            FluxPrompt Coupon
          </Typography>
          <Typography mb={'16px'}>
            Insert coupon code to recieve discounts on your subscription
          </Typography>
          <TextField
            type="text"
            name="couponId"
            size="medium"
            label="Coupon Code"
            value={formData.couponId}
            fullWidth
            onChange={e =>
              setFormData({ ...formData, couponId: e.target.value })
            }
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleCouponApply}
            sx={{
              mt: '16px',
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Billing;
