import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PainIcon from '../../assets/icons/Paint.png';
import PlusIcon from '../../assets/icons/Plus.png';
import { ReactComponent as CheetahSuggestionIconMobile } from '../../assets/icons/cheetahmobile.svg';
import { ReactComponent as SwitchProfiles } from '../../assets/icons/SwitchProfiles.svg';
import DashboardHeader from '../../assets/images/DashboardHeader.png';
import TemplateSystemModal from '../../components/TemplateSystemModal/TemplateSystemModal';
import PaywallModal from '../../components/UI/PaywallModal';
import { getAPIErrorMessage } from '../../helpers/helpers';
import { CompanyService } from '../../service/CompanyService';
import { UploadService } from '../../service/UploadService';
import { UserService } from '../../service/UserService';
import { useAuthStore } from '../../store/storeAuth';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import CreateFlow from './CreateFlow';
import Flows from './Flows';
import FluxTutorials from './FluxTutorials';
import SearchMenu from './SearchMenu';
import { base64Decode } from '../../helpers/decodeToken';
import { LocalStorageService } from '../../service/LocalStorageService';
import useFlowsStore from '../../store/storeFlows';

const Dashboard = () => {
  const dashboardBackgroundUploadRef = useRef<any>(null);
  const {
    user: authUser,
    setAuthUser,
    setAccessToken,
  } = useAuthStore(state => state);
  const { setNotification } = useNotificationStore(state => state);
  const location = useLocation();
  const navigate = useNavigate();
  const { setFlowId } = useFlowsStore(state => state);

  const [showTemplates, setShowTemplates] = useState(false);
  const [showCreateFlowModal, setShowCreateFlowModal] = useState(false);
  const [company, setCompany] = useState<any>();
  const [openCongratulations, setOpenCongratulations] = useState(false);

  const openTemplatesModal = () => {
    setShowTemplates(true);
  };
  const [openInfoWall, setOpenInfoWall] = useState(false);

  const acceptCompanyInvite = async (
    token: string,
    companyId: string,
    email: string,
  ) => {
    try {
      if (email !== authUser?.email) {
        return;
      }
      await CompanyService.acceptCompanyInvite(token);

      setAuthUser({
        ...authUser!,
        selectedCompanyId: companyId,
      });

      getCompany(companyId);
    } catch (error: any) {
      if (error.statusCode === 404) {
        setNotification({
          type: NotificationType.Success,
          message: 'You have accepted this invite.',
        });
      } else {
        setNotification({
          type: NotificationType.Error,
          message: getAPIErrorMessage(error),
        });
      }
    }
    navigate(location.pathname, { replace: true, state: {} });
  };

  const getCompany = async (selectedCompanyId: string | undefined) => {
    try {
      if (selectedCompanyId) {
        const res = await CompanyService.getCompany(selectedCompanyId);
        setCompany(res.data);
        if (authUser?.registrationMethod === 'simple') {
          setOpenCongratulations(true);
        }
      }
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    sessionStorage.setItem('visitedBefore', 'false');
    let visitedBefore = sessionStorage.getItem('visitedBefore') === 'true';
    setFlowId('');
    if (isMobile && !visitedBefore) {
      setOpenInfoWall(true);
      sessionStorage.setItem('visitedBefore', 'true');
    }
  }, []);

  useEffect(() => {
    if (
      location.state?.from === 'company-accept-invite' &&
      location.state?.token
    ) {
      const token = location.state.token;
      if (token) {
        try {
          const decoded = base64Decode(token);
          acceptCompanyInvite(token, decoded.companyId, decoded.email);
        } catch (error) {
          setNotification({
            type: NotificationType.Error,
            message: getAPIErrorMessage(error as any),
          });
        }
      }
    }
  }, [acceptCompanyInvite, location]);

  const handleClose = () => {
    setOpenInfoWall(false);
  };

  const handleDashboardBackgroundUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const file = e.target.files![0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await UploadService.uploadFile(formData);
      await UserService.updateUser({
        cover: response.data.src,
      });
      setAuthUser({
        ...authUser!,
        cover: response.data.src,
      });
    } catch (error) {
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  return (
    <Box sx={{ background: 'white', position: 'relative', padding: '20px' }}>
      {showTemplates && (
        <TemplateSystemModal onClose={() => setShowTemplates(false)} />
      )}

      {showCreateFlowModal && (
        <CreateFlow onHide={() => setShowCreateFlowModal(false)} />
      )}

      <Box sx={{ marginBottom: '170px' }}>
        <img
          src={authUser?.cover || DashboardHeader}
          alt="Header"
          style={{
            position: 'absolute',
            top: 0,
            left: '20px',
            right: '20px',
            height: 200,
            width: '100%',
            objectFit: 'cover',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
          }}
        />

        <Box
          sx={{
            background: 'rgba(249, 250, 251, 1)',
            boxShadow: `2px 4px 35px 0px rgba(0, 0, 0, 0.25)`,
            padding: '10px',
            position: 'absolute',
            top: '150px',
            right: '3%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          onClick={() => dashboardBackgroundUploadRef.current?.click()}
        >
          <img src={PainIcon} alt="Edit" />
          <input
            type="file"
            ref={dashboardBackgroundUploadRef}
            hidden
            onChange={handleDashboardBackgroundUpload}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            position: 'relative',
            top: '100px',
            left: '70%',
            transform: 'translate(-50%,-50%)',
            zIndex: 12,
          }}
        >
          <SearchMenu />

          <Button
            variant="outlined"
            sx={{
              background: 'white',
              color: 'rgba(54, 80, 222, 1)',
              '&:hover': {
                background: 'white',
                color: 'rgba(54, 80, 222, 1)',
                boxShadow: 'none',
              },
            }}
            onClick={openTemplatesModal}
          >
            Templates
          </Button>
          <Button
            onClick={() => setShowCreateFlowModal(true)}
            variant="contained"
            endIcon={<img src={PlusIcon} alt="Plus" />}
          >
            New Flow
          </Button>
        </Box>
      </Box>
      <FluxTutorials />
      <Flows />
      <PaywallModal onHide={handleClose} fullWidth={true} show={openInfoWall}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {/* CHEETAH SVG HERE */}
            <CheetahSuggestionIconMobile />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '143%',
                letterSpacing: '0.17px',
              }}
            >
              Please use desktop for best results
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '166%',
                letterSpacing: '0.4px',
              }}
            >
              While in beta, we encourage you to use FluxPrompt on your desktop.
              We have amazing plans for mobile though, so stay tuned.
            </Typography>
          </Box>
        </Box>
      </PaywallModal>
      <PaywallModal
        maxWidth="md"
        onHide={() => setOpenCongratulations(false)}
        show={openCongratulations}
        showCloseButton={true}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '623px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '32px',
          }}
        >
          <SwitchProfiles />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography fontWeight={600} fontSize={24}>
              Congratulations!
            </Typography>
            <Typography fontWeight={400}>
              You are now part of the <b>{company?.name}</b> Organization. To
              switch back to your
            </Typography>
            <Typography fontWeight={400}>
              personal account, select the Company Button on the left navigation
              menu.
            </Typography>
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setOpenCongratulations(false)}
          >
            Continue
          </Button>
        </Box>
      </PaywallModal>
    </Box>
  );
};

export default Dashboard;
