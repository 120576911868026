import { Folder } from './Types/Folder';

export function updateIndexForNewFlowOrFolder(
  data: Folder[],
  folderId?: string
): number {
  let maxIndex = 0;

  function getMaxIndex(folder: Folder) {
    folder.flows?.forEach(flow => {
      if (flow.index !== undefined && flow.index > maxIndex) {
        maxIndex = flow.index;
      }
    });

    folder.folders?.forEach(subfolder => {
      getMaxIndex(subfolder);
    });
  }

  function updateIndex(folder: Folder): number {
    getMaxIndex(folder);

    const newIndex = maxIndex + 1;

    folder.flows?.forEach(flow => {
      if (flow.index === undefined) {
        flow.index = newIndex;
      }
    });

    if (folder.folders && folder.folders.length > 0) {
      folder.folders.forEach((subfolder, index) => {
        if (subfolder.index === undefined) {
          subfolder.index = newIndex + index;
        }
        updateIndex(subfolder);
      });
    }

    return newIndex;
  }

  if (folderId) {
    const targetFolder = data.find(folder => folder.id === folderId);
    if (targetFolder) {
      maxIndex = targetFolder.index || 0;
      return updateIndex(targetFolder);
    } else {
      return -1; // Return -1 if the folderId is not found in the data.
    }
  } else {
    data.forEach(folder => {
      if (folder.index !== undefined && folder.index > maxIndex) {
        maxIndex = folder.index;
      }
    });
    const newIndex = maxIndex + 1;

    data.forEach(folder => {
      folder.flows?.forEach(flow => {
        if (flow.index === undefined) {
          flow.index = newIndex;
        }
      });

      if (folder.folders && folder.folders.length > 0) {
        folder.folders.forEach((subfolder, index) => {
          if (subfolder.index === undefined) {
            subfolder.index = newIndex + index;
          }
          updateIndex(subfolder);
        });
      }
    });

    return newIndex;
  }
}
