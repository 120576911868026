import { ApiService } from './ApiService';
import { IGetOneResponse } from '../util/Types/Generic';
// import { User } from '../Utilis/Types/User';
export class RunwayService extends ApiService {
  static pathName = '/runwayml';

  static create = (body: {
    flowId: string;
    url?: string;
    prompt: string;
    duration: number;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}`, body);
  };

  static createVideoToVideo = (body: {
    flowId: string;
    url?: string;
    prompt: string;
    duration: number;
    promptImage: any;
  }): Promise<IGetOneResponse<any>> => {
    return ApiService.post(`${this.pathName}/video-to-video`, body);
  };

  static getId = (id: string): Promise<IGetOneResponse<any>> => {
    return ApiService.get(`${this.pathName}/${id}`);
  };
}
