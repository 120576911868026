import React from 'react';
import './Header.css';
import HeaderImage from '../../assets/images/Header.png';
import WelcomImage from '../../assets/images/Welcome.png';
import BackIcon from '../../assets/icons/Back.png';
import { Screen } from './Welcome';

interface Props {
  screen: Screen;
  setScreen: (screen: Screen) => void;
  isSelectedPlanIntroScreen: boolean;
  isPricingScreen: boolean;
}

const Header: React.FC<Props> = ({
  screen,
  setScreen,
  isSelectedPlanIntroScreen,
  isPricingScreen,
}) => {
  const headerTexts: { [key: string]: string } = {
    [Screen.Questions]: `We’re excited for you to experience our vibrant community of AI enthusiasts, where you'll find inspiration, support, and the tools to elevate your work to new heights.`,
    [Screen.UserPlan]: `Prepare to experience a whole new way of working, where AI becomes your trusted partner, empowering you to achieve more than ever before.`,
  };

  const goBack = () => {
    if (screen === Screen.UserPlan) {
      setScreen(Screen.Questions);
    } else if (screen === Screen.SelectedPlan) {
      setScreen(Screen.UserPlan);
    }
  };

  return (
    <header className={'header'}>
      {screen !== Screen.Questions && (
        <img
          src={BackIcon}
          alt="Back"
          className={'back-icon'}
          onClick={goBack}
        />
      )}

      <img src={HeaderImage} alt="Welcome" className={'header-image'} />

      <img src={WelcomImage} alt="Welcome" className={'welcome-image'} />

      <h3 className={'welcome-text'}>Welcome to FluxPrompt</h3>

      {isSelectedPlanIntroScreen && (
        <p className={'header-description'}>
          To jumpstart your AI journey, we are rewarding you with 5,000
          FluxTokens (one time only) to fully immerse yourself in our platform's
          powerful automation capabilities.
        </p>
      )}
      {isPricingScreen && (
        <p className={'header-description'}>
          Harness the full potential of AI with a FluxPrompt subscription plan
          tailored to your needs. Unlock a world of creativity and unparalleled
          productivity with 10,000 Free FluxTokens (one time only).
        </p>
      )}
      {screen && (
        <p className={'header-description'}>{headerTexts[screen as Screen]}</p>
      )}
    </header>
  );
};

export default Header;
