import { memo, useEffect, useCallback, useRef, useState } from 'react';
import { NodeResizer, useEdges, useReactFlow } from 'reactflow';
import { useNotificationStore } from '../../store/storeNotifications';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { checkExecuteTrigger } from '../../util/checkExecute';
import { ScraperService } from '../../service/ScraperService';
import { modifyTheStringifiedData } from '../../util/modifyTheStringifiedData';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import Node from '../UI/Node/Node';
import TagInput from '../Test/TagInput';
import { AmazonLogo, CaretDown } from '@phosphor-icons/react';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { amazonSystems } from '../WebAmazon/constants';
import SettingsModal from '../WebAmazon/SettingsModal/SettingsModal';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { isActiveEdge } from '../../util/findActiveHandle';
import { arrayDataToText } from '../../util/dataToText';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { useObserveElementWidth } from '../../util/useObserveElement';

function WebAmazon({ data, id, type }: NodeProps) {
  const { setNodes: setNodeState } = useReactFlow();

  const { setSchema, setSaving, flowId } = useFlowsStore(state => state);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [previewResponses, setPreviewResponses] = useState([]);

  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const edges = useEdges();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [executeCounter, setExecuteCounter] = useState(0);

  const [formData, setFormData] = useState<{
    system: string;
    url: string;
    query?: string;
    start_page?: number;
    pages?: number;
    domain?: string;
    text?: string;
  }>({
    system: 'amazon-url',
    query: '',
    start_page: 1,
    pages: 1,
    domain: 'com',
    text: '',
    url: '',
  });

  const [hasToExecute, setHasToExecute] = useState(true);
  const debounceTimeoutRef = useRef<any>(null);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          onSubmit();
        }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, formData, data?.canceled]);

  useDebounceEffect(
    () => {
      if (data && !isChangingDirectly) {
        setFormData({
          system: data.system ?? 'amazon-url',
          url: data.url ?? '',
          query: data.query ?? '',
          start_page: data.start_page ?? 1,
          pages: data.pages ?? 1,
          text: data.text ?? '',
          domain: data.domain ?? 'com',
        });
        setPreviewResponses(data.previewResponses ?? []);
      }
    },
    [data, isChangingDirectly],
    300,
  );

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      let response = null;

      let query = formData.query;

      if (data?.urlData?.length > 0) {
        query = arrayDataToText(data?.urlData);
      }

      if (formData.system === 'amazon-url') {
        const res = await ScraperService.scraper(formData.system, {
          url: query,
          flowId,
        });
        response = {
          message: modifyTheStringifiedData(res?.data),
          usedTokens: res.data.usedTokens,
        };
      } else {
        const res = await ScraperService.scraper(formData.system, {
          query: query,
          start_page: +formData.start_page!,
          pages: +formData.pages!,
          domain: formData.domain,
          flowId,
        });
        response = {
          message: modifyTheStringifiedData(res?.data),
          usedTokens: res.data.usedTokens,
        };
      }
      let responses = [...previewResponses];

      if (responses.length === 10) {
        responses.shift();
      }

      setFormData(prevState => ({
        ...prevState,
        text: response.message,
      }));
      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],

        flowTriggerData: checkExecuteTrigger(data, id),
        flowId,
        objectData: {
          text: response.message ?? '',
          previewResponses: [...responses, response.message],
          previewIndex: responses.length,
          usedTokens: data?.flowTrigger?.id ? response.usedTokens : undefined,
          nodeUsedTokens: response.usedTokens,
        },
        setSchema,
      });

      setExecuteCounter(0);

      setTimeout(() => {
        setHasToExecute(true);
      }, 1000);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onChange = useCallback((evt: { target: { name: any; value: any } }) => {
    setFormData(prevState => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));

    setWhoIsChanging({
      name: evt.target.name,
      value: evt.target.value,
    });
    setIsChangingDirectly(true);
  }, []);

  const onPreview = (type: string) => {
    if (data?.text !== undefined && previewResponses?.length > 0) {
      const findIndex =
        data?.previewIndex !== undefined ? data?.previewIndex : 0;

      if (findIndex !== -1) {
        const nextIndex = type === 'next' ? findIndex + 1 : findIndex - 1;

        if (nextIndex >= 0 && nextIndex < previewResponses.length) {
          nodeDataAutoSaveDynamic({
            newEdges: edges,
            setNodeState,
            id,
            flowId,
            setSaving,
            changeType: 'changingPreviewIndex',
            objectData: {
              text: previewResponses[nextIndex],
              previewIndex: nextIndex,
            },
            setSchema,
          });
        }
      }
    }
  };

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: {
            [whoIsChanging.name]: whoIsChanging.value,
          },
          setSchema,
        });

        setWhoIsChanging({
          value: '',
          name: '',
        });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const onChangePlacement = (name: string, value: string) => {
    setWhoIsChanging({
      name: name,
      value: value,
    });
    setIsChangingDirectly(true);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);

  const { height: maxOutputHeight, ref: inputRef } =
    useObserveElementWidth<HTMLDivElement>();
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading ? 'node' : ''}>
      <Node
        edges={edges}
        type={type}
        onChangePlacement={onChangePlacement}
        btnText="Run Prompt"
        onSubmit={onSubmit}
        isLoading={isLoading}
        handleRun={handleRun}
        data={data}
        id={id}
      >
        <NodeResizer isVisible={false} />

        <Box display={'flex'}>
          <div>
            <Box
              p={'16px'}
              borderRight={`1px solid ${
                user?.theme === 'dark' ? '#475467' : '#EAECF0'
              }`}
              ref={inputRef}
            >
              <TagInput
                name="query"
                placeholder="Enter query"
                labelName={
                  formData.system === 'amazon-url'
                    ? 'URL'
                    : formData.system === 'amazon-best-sellers'
                    ? 'Node ID'
                    : 'ASIN CODE'
                }
                handleId={'queryUrl'}
                isCollapsed={true}
                nodeId={id}
                isActive={isActiveEdge(edges, id, 'queryUrl', 'target')}
                isAdvanced={true}
                findColor={findColor}
                advancedCollapsed={true}
                onChange={onChange}
                dataConnected={data?.urlData || []}
                onChangePlacement={onChangePlacement}
                placement={data?.placement}
                value={formData.query}
                nodeLabel={data?.label}
              />

              {data?.placement !== 'Output' && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '360px',
                      gap: '8px',
                      mb: '24px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {amazonSystems.map(value => (
                      <Chip
                        key={value}
                        sx={{
                          borderRadius: '8px',
                          fontSize: '12px',
                          color:
                            formData.system === value
                              ? '#101828'
                              : user?.theme === 'dark'
                              ? '#F2F4F7'
                              : '#101828',
                          p: '4px 4px',
                          height: 'unset',
                          background:
                            formData.system === value
                              ? '#FECDD2'
                              : user?.theme === 'dark'
                              ? '#101828'
                              : '#F2F4F7',
                          '&:hover': {
                            background: '#FECDD2',
                            color: '#101828',
                          },
                        }}
                        onClick={() => {
                          onChange({
                            target: {
                              value: value,
                              name: 'system',
                            },
                          });
                        }}
                        label={value?.replace('amazon-', '')?.replace('-', ' ')}
                      />
                    ))}
                  </Box>
                  <Divider
                    sx={{
                      // mt: !collapsed ? '20px' : '200px',
                      mt: '114px',
                      mb: '10px',
                    }}
                  />
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      bgcolor={user?.theme === 'dark' ? '#101828' : '#F9FAFB'}
                      borderRadius={'4px'}
                      sx={{ display: 'flex', padding: '8px', gap: '8px' }}
                      onClick={() => setSettingsModalOpen(true)}
                    >
                      <AmazonLogo size={18} color="#667085" />
                      <Typography
                        fontSize={'12px'}
                        color="#667085"
                        variant="h6"
                      >
                        OxyLabs
                      </Typography>

                      <CaretDown size={'16px'} color="#667085" />
                    </Box>
                  </Box>
                </>
              )}
              <SettingsModal
                onHide={() => setSettingsModalOpen(false)}
                show={settingsModalOpen}
                formData={formData}
                onChange={onChange}
              />
            </Box>
          </div>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              maxOutputHeight={maxOutputHeight}
              previewResponses={previewResponses}
              previewIndex={data.previewIndex}
              value={data.text}
              activeHandle={isActiveEdge(edges, id, 'output', 'source')}
              placement={data?.placement}
              labelName={'Output'}
              onPreview={onPreview}
              findColor={findColor}
              onChangePlacement={onChangePlacement}
              nodeLabel={data?.label}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
}

export default memo(WebAmazon);
