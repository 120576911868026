import React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import {
  NotificationType,
  useNotificationStore,
} from '../../store/storeNotifications';
import Label from '../UI/Label/Label';
import { useAuthStore } from '../../store/storeAuth';
import SearchableSelect from '../SearchableSelect';
import TagInput from '../Test/TagInput';

const PullGoogleDocsData: React.FC<{
  formData: any;
  onChange: (e: any) => void;
  onChangeSelect: (e: any) => void;
  data: any;
}> = ({ formData, onChange, onChangeSelect, data }) => {
  const setNotification = useNotificationStore(state => state.setNotification);

  const paragraphStartChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;

    if (isNaN(+value) || +value < 0) {
      setNotification({
        type: NotificationType.Error,
        message: 'Paragraph Start must be a positive number',
      });
    }

    onChange({
      target: {
        name: 'paragraphStart',
        value,
      },
    });
  };
  const paragraphEndChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;

    if (isNaN(+value) || +value < 0) {
      setNotification({
        type: NotificationType.Error,
        message: 'Paragraph End must be a positive number',
      });
    }

    onChange({
      target: {
        name: 'paragraphEnd',
        value,
      },
    });
  };

  const { user } = useAuthStore(state => state);
  const docOptions = data?.docsData?.map((doc: any) => ({
    label: doc?.name,
    value: doc?.id,
  }));

  return (
    <Box width={'360px'}>
      {/* <Box>
        <Button onClick={refreshDocsHandler}>Refresh Docs</Button>
      </Box> */}

      <Label
        labelStyle={{
          fontSize: '14px',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          fontWeight: 400,
        }}
        labelName="Document"
        isAdvanced
      />

      <SearchableSelect
        options={docOptions}
        label="Select Document"
        onChange={value => {
          if (value?.value === data?.selectedDoc) return;
          const selectedValue = value ? value.value : '';

          onChangeSelect({
            target: { name: 'docs', value: selectedValue },
          });
          data?.updateSelectedDoc(selectedValue);
        }}
        value={
          docOptions.find(
            (option: { label: string; value: string }) =>
              option?.value === data?.selectedDoc,
          ) || null
        }
        className="nodrag"
        style={{ width: 100, marginBottom: '20px' }}
        sx={{
          '& .MuiList-root': {
            backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
          border: 'none',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      />

      <Select
        onChange={e => onChangeSelect(e)}
        value={formData.docsType}
        name="docsType"
        className="nodrag"
        style={{ width: 200, marginTop: '20px' }}
        sx={{
          '& .MuiList-root': {
            backgroundColor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          },
          '& .MuiSvgIcon-root': {
            color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
          },
          borderRadius: '8px',
          border: 'none',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          bgcolor: user?.theme === 'dark' ? '#101828' : '#F9FAFB',
          color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                backgroundColor: user?.theme === 'dark' ? '#667085' : '#D0D5DD',
              },
              backgroundColor: user?.theme === 'dark' ? '#1D2939' : '#F9FAFB',
            },
          },
        }}
      >
        <MenuItem
          value={'fullContent'}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}
        >
          Full Document
        </MenuItem>
        <MenuItem
          value={'pullCert'}
          sx={{
            color: user?.theme === 'dark' ? '#D0D5DD' : 'black',
          }}
        >
          Pull Certain Paragraphs
        </MenuItem>
      </Select>

      {formData?.docsType === 'pullCert' && (
        <>
          <Label
            labelStyle={{
              fontSize: '14px',
              color: user?.theme === 'dark' ? '#D0D5DD' : '#101828',
              fontWeight: 400,
            }}
            labelName="Paragraphs"
            isAdvanced
          />

          <TagInput
            onChange={paragraphStartChangeHandler}
            placeholder="Paragraph Start"
            value={formData.paragraphStart}
            handleId="paragraphStart"
            dataConnected={data?.paragraphStartData || []}
            isAdvanced
            isCollapsed={false}
            labelName="Paragraph Start"
            advancedCollapsed
            handleSettings={{
              top: '22px',
            }}
            type="number"
            name="paragraphStart"
          />
          <br />
          <TagInput
            onChange={paragraphEndChangeHandler}
            placeholder="Paragraph End"
            name="paragraphEnd"
            dataConnected={data?.paragraphEndData || []}
            isAdvanced
            isCollapsed={false}
            advancedCollapsed
            labelName="Paragraph End"
            handleSettings={{
              top: '22px',
            }}
            handleId="paragraphEnd"
            value={formData.paragraphEnd}
            type="number"
          />
        </>
      )}
    </Box>
  );
};

export default PullGoogleDocsData;
