import {
  Box,
  Divider,
  Drawer,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  DropSlash,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextItalic,
  TextUnderline,
} from '@phosphor-icons/react';

const maxWidth = 1048; // Full width of the row
const gap = 16; // Gap between items
const availableWidth = maxWidth - 3 * gap; // Available width after accounting for gaps

export const widthData = [
  {
    w: '25%',
    width: (availableWidth * 25) / 100, // 25% of the available space
    maxW: (availableWidth * 25) / 100, // Maximum width for the column
  },
  {
    w: '50%',
    width: (availableWidth * 50) / 100, // 50% of the available space
    maxW: (availableWidth * 50) / 100, // 50% of the available space
  },
  {
    w: '75%',
    width: (availableWidth * 75) / 100, // 75% of the available space
    maxW: (availableWidth * 75) / 100, // 75% of the available space
  },
  {
    w: '100%',
    width: maxWidth, // Full available space
    maxW: maxWidth, // Full available space
  },
];

const PopoverEdit = ({
  open,
  handleClose,
  selectedStyle,
  modalStyle,
  onChange,
  editingType,
}: {
  open: boolean;
  handleClose: () => void;
  selectedStyle: any;
  modalStyle: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
  editingType: string;
}) => {
  // Resulting widths

  const [selAlignment, setSelAlignment] = useState(selectedStyle?.textAlign);
  const [selFont, setSelFont] = useState(selectedStyle?.fontFamily);
  const [selStyle, setSelStyle] = useState(selectedStyle?.selStyle || []);

  const [selSize, setSelSize] = useState(selectedStyle?.fontSize);
  const [defaultColor, setDefaultColor] = useState(selectedStyle?.color);
  const [selWidthData, setSelWidthData] = useState(modalStyle?.widthData);
  const [selBorderRadius, setSelBorderRadius] = useState(
    modalStyle?.borderRadius || '8px',
  );

  console.log(selWidthData);

  const [selBgColor, setSelBgColor] = useState(modalStyle?.bgColor);

  const fontFamily = [
    'Roboto, sans-serif',
    'Inter, sans-serif',
    'Arial, sans-serif',
    'Arial Black, sans-serif',
    'Comic Sans MS, cursive',
    'Courier New, monospace',
    'Georgia, serif',
    'Impact, sans-serif',
    'Lucida Console, monospace',
    'Lucida Sans Unicode, sans-serif',
    'Palatino Linotype, serif',
    'Tahoma, sans-serif',
    'Times New Roman, serif',
    'Trebuchet MS, sans-serif',
    'Verdana, sans-serif', // Added missing fonts
    'Garamond, serif', // Added missing fonts
    'Brush Script MT, cursive', // Added missing fonts
  ];

  const style = [
    {
      type: 'bold',
      icon: TextB,
      default: 500,
      value: 'fontWeight',
    },
    {
      type: 'italic',
      icon: TextItalic,
      default: 'normal',
      value: 'fontStyle',
    },
    {
      type: 'underline',
      icon: TextUnderline,
      default: 'none',
      value: 'textDecoration',
    },
  ];

  const textPixels = [
    '8px',
    '10px',
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '28px',
    '32px',
    '36px',
    '40px',
    '44px',
    '48px',
    '52px',
  ];

  const borderRadius = [
    '0px',
    '4px',
    '8px',
    '12px',
    '16px',
    '20px',
    '24px',
    '28px',
    '32px',
    '36px',
    '40px',
    '44px',
    '48px',
    '52px',
  ];

  const palette = [
    '#F2F4F7',
    '#101828',
    '#475467',
    '#D0D5DD',
    '#66BB6A',
    '#FECA26',
    '#EF5350',
    '#3650DE',
    '#9AAAFF',
    '#661EFF',
    '#B589FF',
  ];

  const backgroundPalette = ['#F2F4F7', '#101828', '#475467', '#D0D5DD'];

  const alignments = [
    {
      icon: TextAlignLeft,
      value: 'left',
    },
    {
      icon: TextAlignCenter,
      value: 'center',
    },
    {
      icon: TextAlignRight,
      value: 'right',
    },
    {
      icon: TextAlignJustify,
      value: 'justify',
    },
  ];

  const idNode = open ? 'simple-popover' : undefined;

  return (
    <Drawer
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: 'unset',
        },
      }}
      id={idNode}
      open={open}
      onClose={handleClose}
      anchor="right"
    >
      <Box
        sx={{
          p: '24px',
          width: '300px',
        }}
      >
        <Typography fontSize={'12px'} variant="h6">
          Edit Text
        </Typography>

        <Typography
          sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}
        >
          Font
        </Typography>
        <Box mt={'8px'} display={'flex'} gap="8px">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selFont}
            onChange={e => {
              setSelFont(e.target.value);

              onChange({
                target: {
                  name: editingType,
                  value: {
                    ...selectedStyle,
                    fontFamily: e.target.value,
                  },
                },
              });
            }}
            fullWidth
            variant="outlined"
            sx={{
              fontSize: '12px',
              color: '#475467',
              bgcolor: '#F9FAFB',
            }}
          >
            {fontFamily
              .sort((a: string, b: string) => a.localeCompare(b))
              .map((font, index) => (
                <MenuItem key={index} value={font}>
                  {font
                    ?.replace(', sans-serif', '')
                    ?.replace(', serif', '')
                    ?.replace(', cursive', '')}
                </MenuItem>
              ))}
          </Select>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selSize}
            onChange={e => {
              setSelSize(e.target.value);

              onChange({
                target: {
                  name: editingType,
                  value: {
                    ...selectedStyle,
                    fontSize: e.target.value,
                  },
                },
              });
            }}
            variant="outlined"
            sx={{
              fontSize: '12px',
              color: '#475467',
              bgcolor: '#F9FAFB',
            }}
          >
            {textPixels.map((font, index) => (
              <MenuItem key={index} value={font}>
                {font}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Typography
            sx={{
              mt: '24px',
              fontSize: '12px',
              color: '#475467',
              fontWeight: 400,
            }}
          >
            Style
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              mt: '4px',
            }}
          >
            {style.map((style, index) => (
              <Box
                sx={{
                  cursor: 'pointer',
                  borderRadius: '4px',
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: selStyle?.includes(style.type)
                    ? '#7388F7'
                    : 'transparent',
                  color: selStyle?.includes(style.type) ? '#fff' : '#475467',
                }}
                key={index}
              >
                <style.icon
                  onClick={() => {
                    const index = selStyle.indexOf(style.type);
                    let newStyle = selStyle;
                    if (index > -1) {
                      newStyle.splice(index, 1);
                    } else {
                      newStyle.push(style.type);
                    }

                    setSelStyle(newStyle);

                    onChange({
                      target: {
                        name: editingType,
                        value: {
                          ...selectedStyle,
                          selStyle: newStyle,
                          [style.value]: newStyle.includes(style.type)
                            ? style.type
                            : 'normal',
                        },
                      },
                    });
                  }}
                  key={index}
                  size={24}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              mt: '24px',
              fontSize: '12px',
              color: '#475467',
              fontWeight: 400,
            }}
          >
            Alignment
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              mt: '4px',
            }}
          >
            {alignments.map((align, index) => (
              <align.icon
                onClick={() => {
                  setSelAlignment(align.value);

                  onChange({
                    target: {
                      name: editingType,
                      value: {
                        ...selectedStyle,
                        textAlign: align.value,
                      },
                    },
                  });
                }}
                key={index}
                size={24}
                weight={selAlignment === align.value ? 'bold' : undefined}
                color={selAlignment === align.value ? '#667085' : '#D0D5DD'}
              />
            ))}
          </Box>
        </Box>

        <Divider
          sx={{
            mt: '16px',
          }}
        />
        <Box>
          <Typography
            mt={'24px'}
            sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}
          >
            Text Color
          </Typography>
          <Box
            sx={{
              mt: '4px',
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
              padding: '2px 5px',
              bgcolor: '#f2f4f7',
              width: 'fit-content',
              p: '8px 4px 8px 5px',
              borderRadius: '4px',
            }}
            mt={'2px'}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: '#475467',
              }}
            >
              {defaultColor}
            </Typography>
            {/* color picker */}
            <input
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '4px',
                padding: '0',
                border: 'none',
                outline: 'none',
                appearance: 'none', // This removes the default styling of the color input.
                WebkitAppearance: 'none', // Ensures compatibility with WebKit browsers.
                MozAppearance: 'none', // Ensures compatibility with Mozilla browsers.
                backgroundColor: `${defaultColor}`,
              }}
              className="color-picker"
              onChange={e => {
                setDefaultColor(e.target.value);

                onChange({
                  target: {
                    name: editingType,
                    value: {
                      ...selectedStyle,
                      color: e.target.value,
                    },
                  },
                });
              }}
              value={defaultColor}
              type="color"
            />
          </Box>

          <Typography
            mt={'16px'}
            sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}
          >
            Text Palette
          </Typography>
          <Box mt={'11px'}>
            {palette.map((color, index) => (
              <Box
                key={index}
                onClick={() => {
                  setDefaultColor(color);

                  onChange({
                    target: {
                      name: editingType,
                      value: {
                        ...selectedStyle,
                        color: color,
                      },
                    },
                  });
                }}
                sx={{
                  display: 'inline-block',
                  width: '24px',
                  height: '24px',
                  borderRadius: '4px',
                  backgroundColor: color,
                  margin: '0 8px 8px 0',
                  cursor: 'pointer',
                }}
              ></Box>
            ))}

            <DropSlash
              style={{
                display: 'inline-block',
                margin: '0 8px 8px 0',
                cursor: 'pointer',
              }}
              color={'#98A2B3'}
              onClick={() => {
                setDefaultColor('#ffffff');

                onChange({
                  target: {
                    name: editingType,
                    value: {
                      ...selectedStyle,
                      color: '#ffffff',
                    },
                  },
                });
              }}
              size={24}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            mb: '40px',
          }}
        />
        <Box>
          <Typography fontSize={'12px'} variant="h6">
            Edit Modal
          </Typography>
          <Typography
            mt={'16px'}
            sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}
          >
            Modal Size
          </Typography>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selWidthData.w}
            onChange={e => {
              const index = widthData.findIndex(
                item => item.w === e.target.value,
              );
              setSelWidthData(widthData[index]);

              onChange({
                target: {
                  name: 'widthData',
                  value: widthData[index],
                },
              });
            }}
            fullWidth
            variant="outlined"
            sx={{
              fontSize: '12px',
              color: '#475467',
              bgcolor: '#F9FAFB',
              mt: '4px',
            }}
          >
            {widthData.map((data, index) => (
              <MenuItem key={index} value={data.w}>
                {data.w}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Typography
            mt={'16px'}
            sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}
          >
            Background Color
          </Typography>
          <Box
            sx={{
              mt: '4px',
              display: 'flex',
              gap: '30px',
              alignItems: 'center',
              padding: '2px 5px',
              bgcolor: '#f2f4f7',
              width: 'fit-content',
              p: '8px 4px 8px 5px',
              borderRadius: '4px',
            }}
            mt={'2px'}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: '#475467',
              }}
            >
              {selBgColor}
            </Typography>
            {/* color picker */}
            <input
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '4px',
                padding: '0',
                border: 'none',
                outline: 'none',
                appearance: 'none', // This removes the default styling of the color input.
                WebkitAppearance: 'none', // Ensures compatibility with WebKit browsers.
                MozAppearance: 'none', // Ensures compatibility with Mozilla browsers.
                backgroundColor: `${selBgColor}`,
              }}
              className="color-picker"
              onChange={e => {
                setSelBgColor(e.target.value);
                onChange({
                  target: {
                    name: 'bgColor',
                    value: e.target.value,
                  },
                });
              }}
              value={selBgColor}
              type="color"
            />
          </Box>

          <Typography
            mt={'16px'}
            sx={{ fontSize: '12px', color: '#475467', fontWeight: 400 }}
          >
            Background Palette
          </Typography>
          <Box mt={'11px'}>
            {backgroundPalette.map((color, index) => (
              <Box
                key={index}
                onClick={() => {
                  setSelBgColor(color);
                  onChange({
                    target: {
                      name: 'bgColor',
                      value: color,
                    },
                  });
                }}
                sx={{
                  display: 'inline-block',
                  width: '24px',
                  height: '24px',
                  borderRadius: '4px',
                  backgroundColor: color,
                  margin: '0 8px 8px 0',
                  cursor: 'pointer',
                }}
              ></Box>
            ))}

            <DropSlash
              style={{
                display: 'inline-block',
                margin: '0 8px 8px 0',
                cursor: 'pointer',
              }}
              color={'#98A2B3'}
              onClick={() => {
                setSelBgColor('#ffffff');
                onChange({
                  target: {
                    name: 'bgColor',
                    value: '#fff',
                  },
                });
              }}
              size={24}
            />
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              mt: '24px',
              fontSize: '12px',
              color: '#475467',
              fontWeight: 400,
            }}
          >
            Border Radius
          </Typography>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selBorderRadius}
            onChange={e => {
              setSelBorderRadius(e.target.value);

              onChange({
                target: {
                  name: 'borderRadius',
                  value: e.target.value,
                },
              });
            }}
            variant="outlined"
            sx={{
              fontSize: '12px',
              color: '#475467',
              bgcolor: '#F9FAFB',
            }}
          >
            {borderRadius.map((font, index) => (
              <MenuItem key={index} value={font}>
                {font}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Divider
          sx={{
            mt: '16px',
          }}
        />
      </Box>
    </Drawer>
  );
};

export default PopoverEdit;
