import { useState } from 'react';
import KitModal from '../../../components/UI/KitModal';
import { Box, Button, Radio, Typography } from '@mui/material';
import {
  CheckCircle,
  EyeSlash,
  Link,
  LockKey,
  LockKeyOpen,
  WarningCircle,
} from '@phosphor-icons/react';
import { useNotificationStore } from '../../../store/storeNotifications';
import { FormsService } from '../../../service/FormService';

type Props = {
  show: boolean;
  onHide: () => void;
  form: any;
  setForm: (form: any) => void;
  nodes: any;
  hasChanges: boolean;
  setUndoRedo: (val: any) => void;
};

const PublishModal = ({
  show,
  onHide,
  form,
  setForm,
  nodes,
  hasChanges,
  setUndoRedo,
}: Props) => {
  const [selected, setSelected] = useState(form?.publishType || 'internal');
  const [finishType, setFinishType] = useState('');
  const setNotification = useNotificationStore(state => state.setNotification);

  const onPublish = async (type: string) => {
    try {
      const response = await FormsService.update(form?.id!, {
        status: type,
        publishType: selected,
        publishedNodes: nodes,
        nodes: nodes,
      });

      setUndoRedo({
        index: 0,
        nodes: [nodes],
      });

      setForm({
        ...form,
        status: type,
        publishType: selected,
      });

      setFinishType(type);
    } catch (error) {
      setFinishType('error');
    } finally {
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(
      window.location.origin + '/published/form/' + form?.id,
    );

    setNotification({
      message: 'Link Copied',
      type: 'success',
      duration: 3000,
    });
  };

  const publishType = [
    {
      name: 'Internal',
      type: 'internal',
      description:
        'Only available to FluxPrompt users who can complete and submit the form. ',
    },

    {
      name: 'External',
      type: 'external',
      description:
        'Available to anyone with whom you share the link. Also accessible to FluxPrompt users.',
    },
  ];

  const finishTypeTexts = [
    {
      type: 'draft',
      title: 'Your form has been unpublished.',
      description:
        'This means that neither internal nor external users can access it or submit responses.',
      icon: <EyeSlash size={100} color="#FFC107" />,
    },
    {
      type: 'published',
      title: 'Success!',
      description: `Your form has been successfully published ${
        selected === 'internal' ? 'internally' : 'externally'
      }.`,
      icon: <CheckCircle size={100} weight="fill" color="#67BB6A" />,
    },
    {
      type: 'error',
      title: 'Error',
      description: 'Your form wasn’t published. Please try again.',
      icon: <WarningCircle size={100} weight="fill" color="#E47373" />,
    },
  ];

  return (
    <KitModal
      sx={{
        '& .MuiPaper-root': {
          maxWidth: finishType !== '' ? '540px' : '640px',
          background: '#fff',
        },

        '& .MuiDialogContent-root': {
          background: '#fff',

          p: '24px',
        },
      }}
      fullWidth
      show={show}
      onHide={
        finishType === ''
          ? onHide
          : () => {
              onHide();
              setFinishType('');
            }
      }
    >
      <>
        {finishType !== '' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
            mt={'12px'}
          >
            <Box mt={'24px'}>
              {finishTypeTexts.find(f => f.type === finishType)?.icon}
            </Box>
            <Typography color={'#475467'} fontSize={'16px'} variant="h6">
              {finishTypeTexts.find(f => f.type === finishType)?.title}
            </Typography>
            <Typography color={'#475467'} fontSize={'16px'} mt={'12px'}>
              {finishTypeTexts.find(f => f.type === finishType)?.description}
            </Typography>

            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onHide();
                setFinishType('');
              }}
              sx={{
                mt: '24px',
              }}
            >
              Continue
            </Button>
          </Box>
        ) : (
          <Box mt={'12px'} sx={{}}>
            <Typography variant="h6">Publish Form</Typography>

            <Box mt={'24px'}>
              {publishType.map(type => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                    borderRadius: '8px',
                    p: '8px 24px',
                    cursor: 'pointer',
                    mb: '24px',
                    border: `1px solid  ${
                      selected === type.type ? '#3650DE' : '#EAECF0'
                    }`,
                    background: selected === type.type ? '#E7F0FF' : '#F9FAFB',
                  }}
                  key={type.type}
                  onClick={() => setSelected(type.type)}
                >
                  <Box>
                    {selected === type.type ? (
                      <LockKeyOpen color="#3650DE" size={16} />
                    ) : (
                      <LockKey color="#667085" size={16} />
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: '#101828',
                          lineHeight: '20px',
                        }}
                        fontWeight={500}
                      >
                        {type.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#475467',
                        }}
                      >
                        {type.description}
                      </Typography>
                    </Box>
                    <Radio
                      size="small"
                      onChange={() => setSelected(type.type)}
                      value={type.type}
                      checked={selected === type?.type}
                    />
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: '#fff',
                p: '4px 16px',
                borderRadius: '8px',
                border: '1px solid #EAECF0',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: '#98A2B3',
                }}
              >
                {window.location.origin + '/published/form/' + form?.id}
              </Typography>

              <Button
                sx={{
                  display: 'flex',
                  gap: '8px',
                }}
                onClick={onCopy}
                variant="text"
              >
                Copy Link <Link size={16} color="#3650DE" />
              </Button>
            </Box>

            <Box
              mt={'24px'}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '16px',
              }}
            >
              <Button
                disabled={form?.status === 'draft'}
                color="error"
                variant="outlined"
                onClick={() => onPublish('draft')}
              >
                Unpublish
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onPublish('published')}
              >
                Publish {hasChanges ? 'Changes' : ''}
              </Button>
            </Box>
          </Box>
        )}
      </>
    </KitModal>
  );
};

export default PublishModal;
