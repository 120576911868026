import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Edge, useEdges } from 'reactflow';
import '../../assets/styles/animatedBorder.scss';
import { NodeProps } from '../../util/Types/NodeProps';
import useFlowsStore from '../../store/storeFlows';
import { useDebounceEffect } from '../../util/useDebounceEffect';
import * as Parser from 'hot-formula-parser';

import { Box } from '@mui/material';
import { nodeDataAutoSaveDynamic } from '../../util/autosave/nodedata_autosave';
import { checkExecuteTrigger } from '../../util/checkExecute';
import Node from '../UI/Node/Node';
import OutputTextarea from '../UI/OutputTextarea/OutputTextarea';
import { nodeColorBasedOnType } from '../UI/Node/nodeConstants';
import { useAuthStore } from '../../store/storeAuth';
import { executeErrorHandle } from '../../util/executeErrorHandle';
import { useNotificationStore } from '../../store/storeNotifications';
import VariableEditor from '../MathFunction/Varaibles';

const parser = new Parser.Parser();

const MathFunction = ({ id, data, type }: NodeProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const edges: Edge<any>[] = useEdges();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isChangingDirectly, setIsChangingDirectly] = useState(false);
  const [whoIsChanging, setWhoIsChanging] = useState<{
    value: any;
    name: string;
  }>({
    value: '',
    name: '',
  });

  const { setNodeState, setSchema, setSaving, flowId } = useFlowsStore(
    state => state,
  );
  const setNotification = useNotificationStore(state => state.setNotification);

  const [variables, setVariables] = useState<
    { id: string; name: string; value: string }[]
  >([]);
  const [hasToExecute, setHasToExecute] = useState(true);
  const [executeCounter, setExecuteCounter] = useState(0);
  const [formula, setFormula] = useState('');
  const [result, setResult] = useState<any>(null);

  useEffect(() => {
    if (!isChangingDirectly) {
      if (data.variables) {
        setVariables(data.variables);
      } else {
        const newConditions = [
          {
            id: 'func' + Math.random().toString(36).substr(2, 9),
            name: 'varOne',
            value: '',
          },
        ];
        setVariables(newConditions);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          setSaving,
          flowId,
          objectData: { variables: newConditions },
          setSchema,
        });
      }
    }
  }, [data?.variables]);

  useEffect(() => {
    if (!isChangingDirectly) {
      setFormula(data?.formula || '');
    }
  }, [data?.formula, data?.result]);

  const addVariable = () => {
    const newVariable = {
      id: 'func' + Math.random().toString(36).substr(2, 9),
      name: '',
      value: '',
    };
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      id,
      setSaving,
      flowId,
      objectData: { variables: [...variables, newVariable] },
      setSchema,
    });
    setVariables([...variables, newVariable]);
  };

  const debounceTimeoutRef = useRef<any>();
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (data?.flowTrigger?.id && !data?.canceled && !data?.paused) {
      if (hasToExecute) {
        debounceTimeoutRef.current = setTimeout(() => {
          setHasToExecute(false);
          calculate();
        }, 1000);
      }
    }

    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [data?.flowTrigger, hasToExecute, variables, formula, data?.canceled]);

  useDebounceEffect(
    () => {
      if (isChangingDirectly) {
        setIsChangingDirectly(false);
        nodeDataAutoSaveDynamic({
          newEdges: edges,
          setNodeState,
          id,
          flowId,
          setSaving,
          objectData: { [whoIsChanging.name]: whoIsChanging.value },
          setSchema,
        });
        setWhoIsChanging({ value: '', name: '' });
      }
    },
    [isChangingDirectly, whoIsChanging],
    300,
  );

  const calculate = () => {
    try {
      setIsLoading(true);
      let formulaData = formula;

      if (data?.formulaData) {
        formulaData = data?.formulaData.map((item: any) => item.text).join(' ');
      }
      variables?.forEach((item: any) => {
        let value;

        const isNumber = !isNaN(item?.value);
        if (isNumber) {
          value = Number(item.value);
        } else if (item.value === 'true') {
          value = true;
        } else if (item.value === 'false') {
          value = false;
        } else {
          value = item.value;
        }
        parser.setVariable(item.name, value);
      });

      const calculatedResult = parser.parse(formulaData);
      // console.log('calculatedResult', calculatedResult);
      setResult(calculatedResult.result);

      if (calculatedResult.result === null) {
        setNotification({
          message: 'Variable you are trying to call does not exist',
          type: 'error',
        });
      }

      const typeOfObject = typeof calculatedResult.result === 'object';

      nodeDataAutoSaveDynamic({
        newEdges: edges,
        setNodeState,
        id,
        setSaving,
        flowTriggerData: checkExecuteTrigger(data, id),
        changeType: 'execute',
        objectCallerData: [
          ...(user?.spaceObjects || []),
          ...(user?.objects || []),
        ],
        flowId,
        objectData: {
          result: typeOfObject
            ? JSON.stringify(calculatedResult.result)
            : calculatedResult.result,
        },
        setSchema,
      });
      setExecuteCounter(0);

      setTimeout(() => {
        setIsLoading(false);
        setHasToExecute(true);
      }, 1000);
    } catch (error) {
      executeErrorHandle(
        executeCounter,
        setExecuteCounter,
        setHasToExecute,
        data,
        edges,
        setNodeState,
        setSaving,
        id,
        flowId,
        setSchema,
        setNotification,
        error,
        [...(user?.spaceObjects || []), ...(user?.objects || [])],
      );
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    varId: string,
  ) => {
    const isNameValid = /^[a-zA-Z]+$/.test(e.target.value);

    if (e.target.name === 'name' && !isNameValid) {
      setNotification({
        message: 'Variable names can only contain alphabetical characters.',
        type: 'error',
      });
      return;
    }

    const updatedVariables = variables.map(variable => {
      if (variable.id === varId) {
        return { ...variable, [e.target.name]: e.target.value };
      }
      return variable;
    });

    setVariables(updatedVariables);

    setWhoIsChanging({
      name: 'variables',
      value: updatedVariables,
    });
    setIsChangingDirectly(true);
  };

  const handleFormulaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormula(e.target.value);

    setWhoIsChanging({
      name: e.target.name,
      value: e.target.value,
    });
    setIsChangingDirectly(true);
  };

  const nodeType =
    nodeColorBasedOnType[type as keyof typeof nodeColorBasedOnType];
  const findColor = nodeType.colors;
  const { user } = useAuthStore(state => state);
  const handleRun = (run: string) => {
    nodeDataAutoSaveDynamic({
      newEdges: edges,
      setNodeState,
      setSaving,
      id,
      flowId,
      objectData: {
        run,
      },
      setSchema,
    });
  };

  return (
    <Box className={isLoading || btnLoading ? 'node' : ''}>
      <Node
        edges={edges}
        type={type}
        btnText="Run Function"
        onSubmit={calculate}
        isLoading={isLoading}
        data={data}
        handleRun={handleRun}
        id={id}
        showTokensUsed={false}
      >
        <Box display={'flex'}>
          <Box
            p={'16px'}
            borderRight={`1px solid ${
              user?.theme === 'dark' ? '#475467' : '#EAECF0'
            }`}
          >
            <VariableEditor
              variables={variables}
              data={data}
              addVariable={addVariable}
              handleValueChange={handleValueChange}
              formula={formula}
              findColor={findColor}
              edges={edges}
              handleFormulaChange={handleFormulaChange}
            />
          </Box>
          <Box p={'16px 24px 16px 16px'}>
            <OutputTextarea
              previewIndex={data.previewIndex}
              value={result}
              activeHandle={
                !!edges?.find(
                  (edge: any) =>
                    edge?.source === id && edge?.sourceHandle === 'output',
                )
              }
              placement={data?.placement}
              labelName={'Output'}
              findColor={findColor}
              nodeLabel={data?.label}
            />
          </Box>
        </Box>
      </Node>
    </Box>
  );
};

export default memo(MathFunction);
