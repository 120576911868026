import React, { useEffect, useMemo, useState } from 'react';
import './Template.css';
import { ITemplate } from './templateSystemData';
import CreateTemplateFlowModal from '../CreateTemplateFlowModal/CreateTemplateFlowModal';
import { useAuthStore } from '../../store/storeAuth';
import { UserPlan } from '../../util/Types/User';
import PremiumModalUpgrade from './PremiumModalUpgrade';
import Pricing from '../settings/Pricing/Pricing';
import KitModal from '../UI/KitModal';
import { Product } from '../../util/Types/Product';
import PaymentForm from '../settings/Pricing/PaymentForm';
import { ProductService } from '../../service/ProductService';
import Tap from '@tapfiliate/tapfiliate-js';
import { useNotificationStore } from '../../store/storeNotifications';
import { getAPIErrorMessage } from '../../helpers/helpers';

interface Props {
  template: ITemplate;
  closeTemplateSystemModal: () => void;
}

const Template: React.FC<Props> = ({ template, closeTemplateSystemModal }) => {
  const setNotifications = useNotificationStore(state => state.setNotification);
  const { user } = useAuthStore(state => state);
  const {
    title,
    description,
    keyInputs,
    keyOutputs,
    imageUrl,
    flowId,
    premiumTag,
  } = template;

  const [showUseTemplate, setShowUseTemplate] = useState(false);
  const [showPremiumModalUpgrade, setShowPremiumModalUpgrade] = useState(false);
  const [isSelectedTimePlanYearly, setIsSelectedTimePlanYearly] = useState(
    false
  );
  const [
    showCreateTemplateFlowModal,
    setShowCreateTemplateFlowModal,
  ] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [professionalPlan, setProfessionalPlan] = useState<
    undefined | Product
  >();
  const [showProfessionalPlan, setShowProfessionalPlan] = useState(false);
  const [proOrAbovePlan, setProOrAbovePlan] = useState(false);

  
  const checkProOrAbovePlan = async () => {
    let proOrAbovePlan =
    user?.plan === UserPlan.Enterprise ||
    user?.plan === UserPlan.Organization ||
    user?.plan === UserPlan.Professional;

    const response = await ProductService.getMyProducts();
          
    const plan = response.data[0].name;
    
    proOrAbovePlan =
    plan === UserPlan.Enterprise ||
    plan === UserPlan.Organization ||
    plan === UserPlan.Professional;

    setProOrAbovePlan(proOrAbovePlan)
  }
  const isProOrAbove = useMemo(() => checkProOrAbovePlan(), []);

  const openCreateTemplateFlowModal = async () => {
    if (!flowId) return;

    if (premiumTag) {
      if (proOrAbovePlan) {
        setShowCreateTemplateFlowModal(true);
      } else {
        setShowPremiumModalUpgrade(true);
      }
    } else {
      setShowCreateTemplateFlowModal(true);
    }
  };

  const onSubscribe = async (body: Product) => {
    try {
      await ProductService.subscribeProduct({
        ...body,
        yearly: isSelectedTimePlanYearly,
      });

      Tap.conversion(
        user?.id,
        isSelectedTimePlanYearly
          ? String(body?.year_price)
          : String(body?.price),
        { customer_id: user?.id }
      );
    } catch (error) {
      setNotifications({
        type: 'error',
        message: getAPIErrorMessage(error as string),
      });
    }
  };

  return (
    <>
      {showCreateTemplateFlowModal && (
        <CreateTemplateFlowModal
          flowId={flowId!}
          onHide={() => setShowCreateTemplateFlowModal(false)}
          closeTemplateSystemModal={closeTemplateSystemModal}
          templateTitle={title}
        />
      )}

      {showPremiumModalUpgrade && (
        <PremiumModalUpgrade
          setProfessionalPlan={setProfessionalPlan}
          setShowProfessionalPlan={setShowProfessionalPlan}
          showPricingHandler={() => setShowPricing(true)}
          onClose={() => setShowPremiumModalUpgrade(false)}
        />
      )}

      {professionalPlan && showProfessionalPlan && (
        <KitModal
          show={true}
          onHide={() => {
            setShowProfessionalPlan(false);
            setShowPremiumModalUpgrade(false);
          }}
          maxWidth="xl"
          fullWidth
          sx={{
            '& .MuiPaper-root': {
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              height: '100vh',
            },
          }}
        >
          <PaymentForm
            selectedPlan={professionalPlan}
            setSelectedPlan={() => setShowProfessionalPlan(false)}
            onSubscribe={() => onSubscribe(professionalPlan)}
            isSelectedTimePlanYearly={isSelectedTimePlanYearly}
          />
        </KitModal>
      )}

      {showPricing && (
        <KitModal
          show={true}
          onHide={() => {
            setShowPricing(false);
            setShowPremiumModalUpgrade(false);
          }}
          maxWidth="xl"
          fullWidth
          sx={{
            '& .MuiPaper-root': {
              maxWidth: '100%',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              height: '100vh',
            },
          }}
        >
          <Pricing />
        </KitModal>
      )}

      <div
        className={`template-container ${
          showUseTemplate ? 'use-template' : undefined
        }`}
      >
        <div
          onClick={() => setShowUseTemplate(true)}
          className="template-image-container"
        >
          <img
            src={imageUrl}
            alt={title}
            className={`template-image ${
              premiumTag ? 'template-premium-image' : undefined
            }`}
          />
          {!proOrAbovePlan && premiumTag && (
            <div className="template-type-container template-pro-container">
              <p className="template-pro-text">PRO</p>
            </div>
          )}
        </div>

        <div
          onClick={() => setShowUseTemplate(show => !show)}
          className="template-info"
        >
          <h3 className="template-title">{title}</h3>
          <p className="template-description">{description}</p>

          <p className="key-inputs">
            Key Inputs: <span className="key-inputs-span">{keyInputs}</span>
          </p>
          <p className="key-outputs">
            Key Outputs: <span className="key-outputs-span">{keyOutputs}</span>
          </p>
        </div>

        {showUseTemplate && (
          <div
            onClick={openCreateTemplateFlowModal}
            className="use-template-container"
          >
            {flowId ? <p>Use Template</p> : <p>Coming soon</p>}
          </div>
        )}
      </div>
    </>
  );
};

export default Template;
