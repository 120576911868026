export const amazonSystems = [
  'amazon-url',
  'amazon-best-sellers',
  'amazon-pricing',
  'amazon-product',
  'amazon-qa',
  'amazon-review',
  'amazon-search',
  'amazon-seller',
];

export const domains = [
  'ae',
  'ca',
  'cn',
  'co.jp',
  'co.uk',
  'com',
  'com.au',
  'com.br',
  'com.mx',
  'com.tr',
  'de',
  'es',
  'fr',
  'in',
  'it',
  'nl',
  'pl',
  'se',
];
