import React, { useMemo, useState } from 'react';
import SpacesModal from '../../../Spaces/SpacesModal';
import { Box, Button, TextField, Typography } from '@mui/material';
import { CompanySpacesService } from '../../../../service/CompanySpacesService';
import {
  NotificationType,
  useNotificationStore,
} from '../../../../store/storeNotifications';
import { getAPIErrorMessage } from '../../../../helpers/helpers';
import { useAuthStore } from '../../../../store/storeAuth';
import { useNavigate } from 'react-router-dom';
import PaywallModal from '../../../UI/PaywallModal';
import {
  Images,
  RocketLaunch,
  SealCheck,
  UploadSimple,
} from '@phosphor-icons/react';
import { getMaxSpaces, getSpacePlan } from '../../../../util/planUtil';
import KitModal from '../../../UI/KitModal';

interface Props {
  onClose: () => void;
  getSpaces: () => Promise<void>;
  onOpenModal?: () => void;
  setSelectedSpaceId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const CreateSpaceModal: React.FC<Props> = ({
  onClose,
  getSpaces,
  onOpenModal,
  setSelectedSpaceId,
}) => {
  const authUser = useAuthStore(state => state.user);
  const navigate = useNavigate();
  const setNotification = useNotificationStore(state => state.setNotification);
  const [openPaywall, setOpenPaywall] = useState(false);
  const [spaceName, setSpaceName] = useState('');
  const nextPlanIfUsersLimit = useMemo(
    () => getSpacePlan(authUser?.plan || null),
    [authUser?.plan],
  );

  const maxUsers = getMaxSpaces(authUser?.plan || null);

  const saveHandler = async () => {
    if (!authUser?.selectedCompanyId) {
      navigate(window.location.pathname + '?createCompany=true');
      onOpenModal?.();
      return setNotification({
        type: NotificationType.Error,
        message:
          'You have to switch to company account or create a company first.',
      });
    }

    try {
      const res = await CompanySpacesService.createCompanySpace({
        company: { id: authUser?.selectedCompanyId },
        name: spaceName,
      });
      await getSpaces();
      if (setSelectedSpaceId) {
        setSelectedSpaceId(res?.data?.id as string);
      }
      onClose();
    } catch (error: any) {
      if (error.message?.includes('You have reached the limit')) {
        setOpenPaywall(true);
        return setNotification({
          type: NotificationType.Error,
          message: error.message,
        });
      }

      // console.log(error?.message?.includes(`You do not have access`));

      if (error?.message?.includes(`You do not have access`)) {
        setOpenPaywall(true);
        return setNotification({
          type: NotificationType.Error,
          message: error.message,
        });
      }
      setNotification({
        type: NotificationType.Error,
        message: getAPIErrorMessage(error as any),
      });
    }
  };

  const handleClosePaywall = () => {
    setOpenPaywall(false);
  };

  return (
    <KitModal
      onHide={onClose}
      show={true}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '459px',
          borderRadius: '8px',
        },
        '& .MuiDialogContent-root': {
          padding: '24px',
        },
        '& .MuiDialogActions-root': {
          padding: '24px',
        },
      }}
      footer={
        <>
          <Button variant="contained" fullWidth onClick={saveHandler}>
            Continue
          </Button>
        </>
      }
    >
      <PaywallModal
        onHide={handleClosePaywall}
        fullWidth={true}
        show={openPaywall}
        footer={
          <>
            <Button
              variant="outlined"
              sx={{
                color: '#2133BA',
                border: '1px solid #2133BA',
              }}
              onClick={() => {
                handleClosePaywall();
                onOpenModal?.();
                navigate('?pricing=true');
              }}
            >
              View Pricing Plans
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#2133BA',
                color: '#FFFFFF',
                outline: '1px solid #2133BA',
              }}
              onClick={() => {
                handleClosePaywall();
                onOpenModal?.();
                navigate(`?pricing=true&planUsers=${nextPlanIfUsersLimit}`);
              }}
            >
              Upgrade Now
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <SealCheck size={100} weight="fill" color="#2133BA" />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '175%',
                letterSpacing: '0.15px',
              }}
            >
              Upgrade to add spaces
            </Typography>
            <Typography
              color="#475467"
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '126%',
              }}
            >
              {maxUsers === 3 &&
                'Professional plan only allows you to create 3 spaces. Upgrade to create more spaces and get advanced features'}

              {!maxUsers &&
                'Upgrade to Pro, Org, or Enterprise to create Spaces'}
            </Typography>
          </Box>
        </Box>
      </PaywallModal>
      <Box mt={'32px'}>
        <Box mb="32px">
          <Box
            sx={{
              p: '4px',
              bgcolor: '#E7F0FF',
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <RocketLaunch color="#3650DE" size={24} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              mt: '8px',
              color: '#475467',
              fontSize: '20px',
              fontWeight: 500,
            }}
          >
            Add new space
          </Typography>
        </Box>{' '}
        <TextField
          label="Name your space"
          variant="outlined"
          fullWidth
          size="medium"
          onChange={e => setSpaceName(e.target.value)}
          value={spaceName}
          placeholder="Enter Space Name"
        />
        <Box
          sx={{
            mt: '16px',
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: '16px',
            cursor: 'pointer',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                p: '20px',
                background: ` var(--Blue, linear-gradient(90deg, #142BFF 0%, #396AFC 100%))`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'fit-content',
                borderRadius: '4px',
                position: 'relative',
              }}
            >
              <Images size={40} color={'#fff'} />

              <Box
                sx={{
                  position: 'absolute',
                  bottom: '5px',
                  right: '5px',
                }}
              >
                <UploadSimple color="#fff" size={13} />
              </Box>
            </Box>
            <Typography variant="caption">Upload a space picture</Typography>
          </Box>
        </Box>
      </Box>
    </KitModal>
  );
};

export default CreateSpaceModal;
