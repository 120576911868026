import React from 'react';
import './TemplatesList.css';
import { ITemplate } from './templateSystemData';
import Template from './Template';
import { Icons } from './TemplateSystemModal';
import NoTemplates from './NoTemplates';

interface Props {
  templates: ITemplate[];
  searchValue: string;
  closeTemplateSystemModal: () => void;
  selectedCategory: string;
}

const TemplatesList: React.FC<Props> = ({
  templates,
  searchValue,
  closeTemplateSystemModal,
  selectedCategory,
}) => {
  const filteredTemplates = templates.filter(({ title }) =>
    title.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="templates-list-content">
      {filteredTemplates.length > 0 && (
        <div className="template-icon-category-container">
          {Icons[selectedCategory]}
          <h2 className="template-category-text">{selectedCategory}</h2>
        </div>
      )}
      {filteredTemplates.length === 0 ? (
        <NoTemplates />
      ) : (
        <div className="templates-list">
          {filteredTemplates.map(template => (
            <Template
              key={template.title}
              template={template}
              closeTemplateSystemModal={closeTemplateSystemModal}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TemplatesList;
