import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Empty, Export } from '@phosphor-icons/react';
import { FC } from 'react';
import { formatActualDate } from '../../../util/dateUtil';
import { User } from '../../../util/Types/User';

export interface Invoice {
  id: string;
  invoiceDate: string;
  status: string;
  type: string;
  amount: string;
  cardUsed: string;
}

interface DataGridExportableTableProps {
  rows: Invoice[];
  authUser: User;
  handleRetry: (id: string) => void;
  isRetrying: boolean;
}

const convertToCSV = (rows: Invoice[]) => {
  const header = Object.keys(rows[0]) as (keyof Invoice)[];
  const csvRows = [
    header.join(','),
    ...rows.map(row =>
      header.map(fieldName => JSON.stringify(row[fieldName] || '')).join(','),
    ),
  ];
  return csvRows.join('\n');
};

const downloadCSV = (rows: Invoice[]) => {
  const csvData = convertToCSV(rows);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const ExportButton = ({ rows }: { rows: Invoice[] }) => {
  const handleExport = () => {
    downloadCSV(rows);
  };

  return (
    <Button
      onClick={handleExport}
      endIcon={<Export size={18} weight="regular" />}
      sx={{
        border: '1px solid #3650DE',
        boxShadow: 'none',
        fontSize: '16px',
        fontWeight: 500,
        fontStyle: 'normal',
        padding: '8px 10px',
        backgroundColor: '#fff',
        color: '#3650DE',
      }}
    >
      Export Invoices
    </Button>
  );
};

export const DataGridExportableTable: FC<DataGridExportableTableProps> = ({
  rows,
  authUser,
  handleRetry,
  isRetrying,
}) => {
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      renderCell: params => {
        const status = params.value as string;
        let color = '';
        switch (status) {
          case 'Processing':
          case 'Past_due':
          case 'Trialing':
            color = '#FFC107';
            break;
          case 'Active':
            color = '#4CAF50';
            break;
          case 'Failed':
          case 'Unpaid':
          case 'Canceled':
          case 'Incomplete':
          case 'Incomplete_expired':
            color = '#F44336';
            break;
          default:
            color = '#000000';
            break;
        }

        return <div style={{ color }}>{status}</div>;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'amount',
      headerName: 'Total Amount',
      flex: 1,
      //   type: 'number',
      disableColumnMenu: true,
    },
    {
      field: 'cardUsed',
      headerName: 'Card Used',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'payNow',
      headerName: '',
      width: 93,
      //   type: 'actions',
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (
          params.row.status === 'Failed' ||
          params.row.status === 'Unpaid' ||
          params.row.status === 'Canceled' ||
          params.row.status === 'Incomplete' ||
          params.row.status === 'Incomplete_expired'
        ) {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  color: '#fff',
                  backgroundColor: '#4CAF50',
                  textTransform: 'none',
                  fontSize: '16px',
                  padding: '5px 10px',
                  height: '32px',
                  '&:hover': {
                    backgroundColor: '#4CAF50',
                  },
                }}
                onClick={() => handleRetry(params.row.id)}
                disabled={isRetrying}
              >
                Pay Now
              </Button>
            </Box>
          );
        }
        return null;
      },
    },
    // {
    //   field: 'action',
    //   headerName: '',
    //   //   type: 'actions',
    //   sortable: false,
    //   width: 60,
    //   disableColumnMenu: true,
    //   renderCell: params => (
    //     <>
    //       <IconButton
    //         aria-label="more"
    //         id={params.id.toString()}
    //         aria-controls="basic-menu"
    //         aria-haspopup="true"
    //         onClick={handleClick}
    //         sx={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //         }}
    //       >
    //         <DotsThreeVertical size={20} weight="bold" color="#343330" />
    //       </IconButton>
    //       <Menu
    //         id={params.id.toString()}
    //         anchorEl={anchorElMenu}
    //         open={open}
    //         onClose={handleClose}
    //         MenuListProps={{
    //           'aria-labelledby': 'basic-button',
    //         }}
    //       >
    //         <MenuList>
    //           <MenuItem>
    //             <ListItemIcon>
    //               <Trash size={16} color="#101828" />
    //             </ListItemIcon>
    //             <ListItemText>Delete ???</ListItemText>
    //           </MenuItem>
    //         </MenuList>
    //       </Menu>
    //     </>
    //   ),
    // },
  ];

  const rowsData = rows.map((row: any, i: number) => ({
    id: i + 1,
    amount: '$' + row.amount,
    cardUsed: 'Card ending in ' + row.cardUsed,
    invoiceDate: formatActualDate(row.invoiceDate),
    // status: `${row.status.charAt(0).toUpperCase()}${row.status.slice(1)}`,
    status: `${row.status.charAt(0).toUpperCase()}${row.status.slice(1)}`,
    type: row.type,
  }));

  const rowHeight = 52;
  const headerHeight = 56;
  const maxHeight = 400;

  const gridHeight = Math.min(
    headerHeight + rows.length * rowHeight,
    maxHeight,
  );

  return (
    <Box>
      <Box
        sx={{
          mt: '8px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            color: '#475467',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
          }}
        >
          Invoice Table
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '12px',
          }}
        >
          <ExportButton rows={rows} />
        </Box>
      </Box>

      <Box style={{ width: '100%' }}>
        {rowsData && rowsData.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              gap: '20px',
            }}
          >
            <Empty size={48} color="#475467" />
            <Typography
              sx={{
                color: '#475467',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
              }}
            >
              No invoices found
            </Typography>
          </Box>
        ) : (
          <DataGrid
            rows={rowsData}
            columns={columns as any}
            disableRowSelectionOnClick
            loading={!rowsData}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            sx={{
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
                color: '#101828',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 19.92,
                letterSpacing: 0.4,
              },
              '& .MuiDataGrid-cell': {
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,

                color: '#475467',
              },
              '& .MuiDataGrid-filler': {
                width: '0%',
              },
              '& .MuiDataGrid-root': {
                width: '100%',
              },
              '& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiToolbar-root':
                {
                  alignItems: 'baseline',
                },
            }}
          />
        )}
      </Box>
    </Box>
  );
};
