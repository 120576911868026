import axios from 'axios';
import fileDownload from 'js-file-download';

export const onDownloadFile = async (url: string, fileName: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/downloader?url=${url}`,
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    );

    const contentType = response.headers['content-type'];

    fileDownload(
      response.data,
      `${fileName}_${new Date().getTime() + Math.random() * 1000}.${
        contentType.split('/')[1]
      }`,
    );
  } catch (error) {
    throw new Error('Error downloading image');
  }
};

export const onDownloadHtmlContent = (
  htmlContent: string,
  fileName: string,
) => {
  const blob = new Blob([htmlContent], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}_${
    new Date().getTime() + Math.random() * 1000
  }.html`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
