import { Box, Button, TextField, Typography } from '@mui/material';
import { SquareSplitHorizontal, TextAlignLeft } from '@phosphor-icons/react';
import InputHandle from '../InputHandle/InputHandle';
import { Position } from 'reactflow';
import PreviewModal from '../PreviewModal/PreviewModal';
import { useState } from 'react';
import { useAuthStore } from '../../../store/storeAuth';

const OutputContent = ({
  value,
  labelName = '',
  findColor,
  activeHandle = false,
  placement,
  onChangePlacement,
  isCollapsed,
  edges,
  id,
  getNode,
}: any) => {
  const [onPreviewModal, setOnPreviewModal] = useState(false);
  const [previewSection, setPreviewSection] = useState({
    isOpen: false,
    id: 0,
  });

  const findEdge = edges?.find(
    (edge: any) => edge?.target === id && edge?.targetHandle === 'content',
  )?.source;

  const { user } = useAuthStore(state => state);

  return (
    <Box position={'relative'} mb={'16px'}>
      <InputHandle
        activeColor={findColor?.color}
        handleId={'output'}
        isActive={activeHandle}
        position={Position.Right}
        type="source"
      />

      {placement === 'Prompt' && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,

            '& :hover': {
              '&.change-placement': {
                border: '1px solid ' + findColor?.color,
                '& svg': {
                  fill: findColor?.color,
                },
              },
            },
          }}
          onClick={() => onChangePlacement?.('placement', 'Output')}
        >
          <Box
            className="change-placement"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              alignSelf: 'center',
              border: '1px solid #98A2B3',
              maxWidth: 'fit-content',
            }}
          >
            <TextAlignLeft size={16} color="#98A2B3" />
          </Box>
          <Typography fontSize={'12px'} color="#475467">
            {labelName?.split(' ')[0]}
          </Typography>
        </Box>
      )}

      {placement !== 'Prompt' && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              position: 'relative',
              '& svg': { cursor: 'pointer' },
              mb: '8px',
            }}
          >
            <Typography
              fontSize={'16px'}
              color={user?.theme === 'dark' ? '#D0D5DD' : 'black'}
              fontWeight={600}
            >
              {labelName}
            </Typography>
            <TextAlignLeft
              onClick={() => {
                setOnPreviewModal(true);
              }}
              size={16}
              color="#98A2B3"
            />
          </Box>
          {!value?.length && (
            <Box
              sx={{
                mt: '20px',
                padding: '16px',
                height: isCollapsed ? '405px' : '200px',
                width: '360px',
                borderRadius: '8px',
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2398A2B3FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <SquareSplitHorizontal size={32} color="#98A2B3" />
                <Typography fontSize={'14px'} color="#98A2B3">
                  The content you connect will be split up here so you can run
                  each part independently through workflows.
                </Typography>
              </Box>
            </Box>
          )}

          {value?.length !== 0 && (
            <Box mt={'20px'}>
              {value?.length !== 0 &&
                value
                  ?.slice(0, isCollapsed ? 10 : 5)
                  ?.map((paragraph: string, index: number) => (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      gap={'8px'}
                      mb={'20px'}
                      key={index}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#98A2B3',
                        }}
                      >
                        {index + 1}
                      </Typography>
                      <TextField
                        id="textInput"
                        name={'paragraph' + index}
                        value={paragraph}
                        // onChange={onChange}
                        fullWidth
                        className="nodrag mt-2"
                        InputProps={{
                          readOnly: true,
                          style: {
                            borderColor: '#98A2B3',
                            color: '#98A2B3',
                            fontSize: '12px',
                          },
                          endAdornment: (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <TextAlignLeft
                                size={16}
                                weight="fill"
                                color="#98A2B3"
                                onClick={() => {
                                  setPreviewSection({
                                    isOpen: true,
                                    id: index,
                                  });
                                }}
                              />
                            </Box>
                          ),
                        }}
                        sx={{
                          width: '360px',
                        }}
                      />
                    </Box>
                  ))}

              {value?.length && value?.length > 6 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    mt: '20px',
                  }}
                >
                  <Button
                    onClick={() => setOnPreviewModal(true)}
                    sx={{
                      color: '#667085',
                      fontSize: '12px',
                    }}
                  >
                    See all {value?.length} sections
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </>
      )}

      <PreviewModal
        data={{
          data: value?.map((item: string, index: number) => ({
            text: item,
            label: `Section ${index + 1}`,
          })),
          labelName: getNode(findEdge)?.data?.label,
          nodeLabel: `${'Sequence Node'}  •${value?.length} Sections`,
        }}
        onHide={() => setOnPreviewModal(false)}
        show={onPreviewModal}
      />
      <PreviewModal
        data={{
          value: value?.find(
            (item: string, index: number) => index === previewSection.id,
          ),
          readOnly: true,
          labelName: `${labelName} Section ${
            value?.findIndex(
              (item: string, index: number) => index === previewSection.id,
            ) + 1
          }`,
          nodeLabel: getNode(findEdge)?.data?.label,
        }}
        onHide={() => setPreviewSection({ isOpen: false, id: 0 })}
        show={previewSection.isOpen}
      />
    </Box>
  );
};

export default OutputContent;
