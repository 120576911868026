import dayjs from 'dayjs';
import { Range } from 'react-date-range';
import { FilterBy } from './TokenUsage';
import { TokenUsage } from '../../util/Types/TokenUsage';
import {
  addWeeks,
  endOfWeek,
  format,
  getWeekOfMonth,
  isBefore,
} from 'date-fns';

export const generateLabels = (
  filteredBy: FilterBy,
  selectedDateRange: Range,
  differentYearsSelected: number[]
): {
  xAxisLabels: {
    month: number;
    week?: number;
    year: number;
    label: string;
  }[];
  selectBy: 'month' | 'week';
} => {
  let selectBy: 'month' | 'week';
  let xAxisLabels: {
    month: number;
    week?: number;
    year: number;
    label: string;
  }[] = [];

  if (filteredBy === FilterBy.Week) {
    const startDate = selectedDateRange.startDate!;
    let startDateToChange = selectedDateRange.startDate!;
    const endDate = selectedDateRange.endDate!;
    let currentDate = endOfWeek(startDate, { weekStartsOn: 0 });

    while (isBefore(currentDate, endDate)) {
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const weekNumber = getWeekOfMonth(currentDate);

      if (startDateToChange.getMonth() !== currentDate.getMonth()) {
        xAxisLabels.push({
          month: startDateToChange.getMonth() + 1,
          week: getWeekOfMonth(startDateToChange),
          year: startDateToChange.getFullYear(),
          label: `${format(startDateToChange, 'MMMM')} week ${getWeekOfMonth(
            startDateToChange
          )}`,
        });
      }
      xAxisLabels.push({
        month: currentMonth + 1,
        week: weekNumber,
        year: currentYear,
        label: `${format(currentDate, 'MMMM')} week ${weekNumber}`,
      });

      currentDate = addWeeks(currentDate, 1);
      startDateToChange = addWeeks(startDateToChange, 1);
    }

    if (startDateToChange.getMonth() !== currentDate.getMonth()) {
      xAxisLabels.push({
        month: startDateToChange.getMonth() + 1,
        week: getWeekOfMonth(startDateToChange),
        year: startDateToChange.getFullYear(),
        label: `${format(startDateToChange, 'MMMM')} week ${getWeekOfMonth(
          startDateToChange
        )}`,
      });
    }

    if (currentDate >= endDate) {
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const weekNumber = getWeekOfMonth(currentDate);
      xAxisLabels.push({
        month: currentMonth + 1,
        week: weekNumber,
        year: currentYear,
        label: `${format(currentDate, 'MMMM')} week ${weekNumber}`,
      });
    }

    selectBy = 'week';
  } else {
    const startDate = dayjs(selectedDateRange.startDate!);
    const endDate = dayjs(selectedDateRange.endDate!);
    let currentMonth = startDate;

    while (
      currentMonth.isBefore(endDate) ||
      currentMonth.isSame(endDate, 'month')
    ) {
      xAxisLabels.push({
        month: currentMonth.month() + 1,
        year: currentMonth.year(),
        label:
          differentYearsSelected.length > 0
            ? `${currentMonth.format('MMMM')}, ${currentMonth.year()}`
            : currentMonth.format('MMMM'),
      });
      currentMonth = currentMonth.add(1, 'month').startOf('month');
    }

    selectBy = 'month';
  }

  return { xAxisLabels, selectBy };
};

export const generateData = (
  selectBy: 'week' | 'month',
  xAxisLabels: {
    month: number;
    week?: number;
    year: number;
    label: string;
  }[],
  usage: TokenUsage[],
  label: string
) => {
  const updatedUsage: TokenUsage[] = [];

  if (selectBy === 'week') {
    xAxisLabels.forEach((xAxisLabel, i) => {
      const { week, month, year } = xAxisLabel;
      const match = usage.find(el => {
        return el.week === week && el.year === year && el.month === month;
      });

      const currentWeekUsage = usage.filter(el => {
        return el.week === week && el.year === year && el.month === month;
      });

      let usedTokens = 0;
      let price = 0;
      currentWeekUsage.forEach(el => {
        usedTokens += el.usedTokens;
        price += el.price;
      });

      if (match)
        updatedUsage.push({
          ...match,
          usedTokens,
          price,
        });
      else
        updatedUsage[i] = {
          label,
          month,
          usedTokens: 0,
          price: 0,
          week,
          year,
        };
    });
  } else {
    xAxisLabels.forEach((xAxisLabel, i) => {
      const { month, year } = xAxisLabel;
      const match = usage.find(el => {
        return el.year === year && el.month === month;
      });

      const currentMonthImagesUsage = usage.filter(el => {
        return el.year === year && el.month === month;
      });
      let usedTokens = 0;
      let price = 0;
      currentMonthImagesUsage.forEach(el => {
        usedTokens += el.usedTokens;
        price += el.price;
      });

      if (match)
        updatedUsage.push({
          ...match,
          usedTokens,
          price,
        });
      else
        updatedUsage[i] = {
          label,
          month,
          usedTokens: 0,
          price: 0,
          year,
        };
    });
  }

  return updatedUsage;
};

export const getChartOptions = (
  xAxisLabels: {
    month: number;
    week?: number | undefined;
    year: number;
    label: string;
  }[],
  colors: string[],
  prices: number[] = [],
  stacked: boolean = false
): ApexCharts.ApexOptions => {
  return {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: true,

        tools: {
          download: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="26" height="26" rx="2" fill="#EAECF0"/>
<path d="M19.023 9.78953L15.0855 5.85203C15.0332 5.79981 14.9711 5.7584 14.9029 5.73017C14.8346 5.70194 14.7614 5.68744 14.6875 5.6875H7.9375C7.63913 5.6875 7.35298 5.80603 7.142 6.017C6.93103 6.22798 6.8125 6.51413 6.8125 6.8125V19.1875C6.8125 19.4859 6.93103 19.772 7.142 19.983C7.35298 20.194 7.63913 20.3125 7.9375 20.3125H18.0625C18.3609 20.3125 18.647 20.194 18.858 19.983C19.069 19.772 19.1875 19.4859 19.1875 19.1875V10.1875C19.1876 10.1136 19.1731 10.0404 19.1448 9.97215C19.1166 9.90386 19.0752 9.84181 19.023 9.78953ZM15.25 7.60773L17.2673 9.625H15.25V7.60773ZM18.0625 19.1875H7.9375V6.8125H14.125V10.1875C14.125 10.3367 14.1843 10.4798 14.2898 10.5852C14.3952 10.6907 14.5383 10.75 14.6875 10.75H18.0625V19.1875ZM15.0855 14.852C15.1378 14.9043 15.1793 14.9663 15.2076 15.0346C15.2359 15.1029 15.2504 15.1761 15.2504 15.25C15.2504 15.3239 15.2359 15.3971 15.2076 15.4654C15.1793 15.5337 15.1378 15.5957 15.0855 15.648L13.398 17.3355C13.3457 17.3878 13.2837 17.4293 13.2154 17.4576C13.1471 17.4859 13.0739 17.5004 13 17.5004C12.9261 17.5004 12.8529 17.4859 12.7846 17.4576C12.7163 17.4293 12.6543 17.3878 12.602 17.3355L10.9145 15.648C10.809 15.5424 10.7497 15.3993 10.7497 15.25C10.7497 15.1007 10.809 14.9576 10.9145 14.852C11.0201 14.7465 11.1632 14.6872 11.3125 14.6872C11.4618 14.6872 11.6049 14.7465 11.7105 14.852L12.4375 15.5798V12.4375C12.4375 12.2883 12.4968 12.1452 12.6023 12.0398C12.7077 11.9343 12.8508 11.875 13 11.875C13.1492 11.875 13.2923 11.9343 13.3977 12.0398C13.5032 12.1452 13.5625 12.2883 13.5625 12.4375V15.5798L14.2895 14.852C14.3418 14.7997 14.4038 14.7582 14.4721 14.7299C14.5404 14.7016 14.6136 14.6871 14.6875 14.6871C14.7614 14.6871 14.8346 14.7016 14.9029 14.7299C14.9712 14.7582 15.0332 14.7997 15.0855 14.852Z" fill="#667085"/>
</svg>`,
        },
        offsetX: 10,
        offsetY: -10,
      },
      stacked,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,

      formatter: function (val, opts) {
        let price = 0;
        if (prices[opts.dataPointIndex]) {
          price = prices[opts.dataPointIndex];
        }
        if (price === 0) return '';

        return '$' + price.toFixed(2);
      },

      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#101828'],
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xAxisLabels.map(({ label }) => label),
    },
    yaxis: {
      title: {
        text: 'Tokens',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + ' Tokens';
        },
      },
    },
    colors,
  };
};
