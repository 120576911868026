import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageService } from '../service/LocalStorageService';
import { useAuthStore } from '../store/storeAuth';

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const accessToken = LocalStorageService.getItem('accessToken');
  const authUser = useAuthStore(state => state.user);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.from === 'register' && location.state?.token) {
      navigate(`/register?token=${location.state?.token}`, {
        replace: true,
        state: null,
      });
      return;
    }

    if (
      location.state?.from === 'company-accept-invite' &&
      location.state?.token
    ) {
      navigate(`/login`, { replace: true, state: location.state });
      return;
    }

    if (!accessToken) {
      navigate('/login');
    }

    if (authUser?.id) {
      if (
        authUser?.registrationMethod === 'invite' &&
        !authUser?.companyUserHasFinishedOnboarding
      ) {
        return navigate('/onboarding');
      }
      if (
        authUser?.registrationMethod !== 'invite' &&
        !authUser?.hasFinishedOnboarding
      ) {
        navigate('/welcome');
      }
    }
  }, [authUser, accessToken]);

  return accessToken ? <Outlet /> : <Navigate to={'/login'} />;
};

export default PrivateRoutes;
