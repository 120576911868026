import React from "react";
import KitModal from "../UI/KitModal";
import { Box, Button, Typography } from "@mui/material";
import { useNotificationStore } from "../../store/storeNotifications";
import { AuthService } from "../../service/AuthService";
import { useForm } from "react-hook-form";
import KitInput from "../UI/KitInput";

interface Props {
  isChangePasswordModalOpen: boolean;
  setIsChangePasswordModalOpen: any;
}

interface FormDataTypes {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordModal = ({
  isChangePasswordModalOpen,
  setIsChangePasswordModalOpen,
}: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormDataTypes>();

  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );

  const handleChangePassword = async (values: FormDataTypes) => {
    try {
      const res = await AuthService.updatePassword({
        password: values.currentPassword,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmPassword,
      });
      setNotification({
        type: "success",
        message: res?.data?.message,
      });
      setIsChangePasswordModalOpen(false);
    } catch (error: any) {
      setNotification({
        type: "error",
        message: error?.message || "Something went wrong",
      });
    }
  };

  return (
    <KitModal
      show={isChangePasswordModalOpen}
      onHide={() => setIsChangePasswordModalOpen(false)}
      maxWidth="xs"
      fullWidth
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        p={"24px"}
        borderRadius={"4px"}
        gap={"24px"}
      >
        <Typography variant="h1">Change password</Typography>
        <form action="submit" onSubmit={handleSubmit(handleChangePassword)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <KitInput
              label="Current Password"
              name="currentPassword"
              type="password"
              errorsStatus={errors}
              register={register}
            />
            <KitInput
              label="New Password"
              name="newPassword"
              type="password"
              errorsStatus={errors}
              register={register}
              validation={{
                required: {
                  value: true,
                  message: "password is required",
                },
                minLength: {
                  value: 8,
                  message: "password must be at least 8 characters",
                },

                pattern: {
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                  message:
                    "password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                },
              }}
            />
            <KitInput
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              errorsStatus={errors}
              register={register}
              validation={{
                required: {
                  value: true,
                  message: "Confirm Password is required",
                },

                validate: (value) =>
                  value === watch("newPassword") ||
                  "The passwords do not match",
              }}
            />

            <Button
              sx={{
                background: "#3293DB",
                color: "#fff",
                maxWidth: "100%",
                boxShadow: "unset",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              type="submit"
              variant="contained"
            >
              Change Password
            </Button>
          </Box>
        </form>
      </Box>
    </KitModal>
  );
};

export default ChangePasswordModal;
